import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import "./warehouse-list.js";
import apiUrl from "../../url/apiUrl";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RefreshIcon from "@mui/icons-material/Refresh";
import axios from "axios";
import { Alert, CircularProgress } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Search, Close } from "@mui/icons-material";
import { Drawer, MenuItem, TextField } from "@mui/material";
import { Edit } from "@mui/icons-material";
import Box from "@mui/material/Box";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import {
  createWarehousePost,
  getUserTypeEnums,
  getVillagesList,
  getWarehouseList,
  updateWarehousePut,
} from "./warehousesServices";
import WarehouseMiniCard from "../../components/warehouseMiniCard/WarehouseMiniCard";
// import { wareHouseUserPost } from "../../components/warehouseMiniCard/warehouseMiniService";
import { useParams } from "react-router-dom";
import COMPANY from "../../assets/placeholdercompany5f3438282f524800f1d49cd2921bb0a56101e1aa16097ebd313b64778fc7c4bd1611448792.png";
import * as yup from "yup";
import {
  getWarehouseUsersList,
  updateWarehouseUserPut,
  wareHouseUserPost,
} from "../../components/warehouseMiniCard/warehouseMiniService";
import { useSelector, useDispatch } from "react-redux";
import { updateWarehouseUserEditInvoke } from "../../redux/tripSlice";
import { searchWarehouse } from "../../redux/searchSlice";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";
import axiosInterceptor from "../../config/https";
import { readToken } from "../../helper/dataDecryptionHelper";
import {
  CreateNewUser,
  WareHouse_list_main_container,
  Warehouse_list_warehouse_drawer_container,
} from "./warehouse-list.js";
import { ButtonRectangle } from "../../themes/components/button";
import Topbar from "../../components/topbar/Topbar.jsx";
import { DarkModeContext } from "../../context/darkModeContext.js";
import { colorPalette, colorPaletteDark } from "../../themes/theme.js";
import { MyContext } from "../../components/enumContext/EnumContextComponent.jsx";
import SomethingWentWrong from "../../components/somethingWentWrong/SomethingWentWrong.jsx";
import { Modal as ModalAntd } from "antd";
import Pincode from "../../components/countryBasePincode/Pincode.jsx";

function WarehouseList() {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const { enums } = useContext(MyContext);
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const [warehouseData, setWarehouseData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [userExistError, setUserExistError] = useState("");
  const [userDoesNotExistError, setUserDoesNotExistError] = useState("");
  const [minCharacterUserName, setMinCharacterUserName] = useState("");
  //
  const [loadingPage, setLoadingPage] = useState(true);
  const [errCreateUser, setErrCreateUser] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedWarehouse, setSelectedWarehouse] = useState({});
  const [selectedWarehouseId, setSelectedWarehouseId] = useState("");
  const [selectedDrawer, setSelectedDrawer] = useState("warehouse");
  const [loadingMore, setLoadingMore] = useState(true);
  //
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingButtonUser, setLoadingButtonUser] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [villagesList, setVillagesList] = useState([]);
  const searchTerm = useSelector((data) => data.searchValues.searchWarehouse);
  // console.log("villgarssss", villagesList)

  const { warehouseUserEditInvoke } = useSelector((state) => state.trip);
  const [userTypeList, setUserTypeList] = useState([]);
  const [editModeUser, setEditModeUser] = useState(false);
  const [warehouseUserList, setWarehouseUserList] = useState([]);
  const [notServiceableError, setNotServiceableError] = useState("");
  // const [currentPage, setCurrentPage] = useState(1);
  const [createWarehouseDetails, setCreateWarehouseDetails] = useState({
    name: "",
    postcode: "",
    village: "0",
    postoffice: "",
    district: "",
    state: "",
    sub_district: "",
    customers_id: customerId,
    gstin_number: "",
  });
  const [errTotalWarehouse, setErrTotalWarehouse] = useState("");

  const [errWarehouseDetails, setErrWarehouseDetails] = useState({
    errWarehouseName: "",
    errPincode: "",
    errVillage: "",
    errGstin: "",
  });

  const [errWarehouseUserDetails, setErrWarehouseUserDetails] = useState({
    errFirstName: "",
    errDesignation: "",
    errEmployeeCode: "",
    errMobileNumber: "",
    errEmail: "",
    errUserType: "",
    errUserName: "",
  });

  const [userDetails, setUserDetails] = useState({
    warehouse_id: selectedWarehouseId,
    first_name: "",
    emp_code: "",
    mobile_number: "",
    designations: "",
    emp_code: "",
    last_name: "",
    email: "",
    user_type: "0",
    is_active: "true",
    user_name: "",
    department: "",
  });

  const [state, setState] = React.useState({
    right: false,
  });
  const gstinRegex =
    /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z|1-9|A-J|S]{1}[0-9A-Z]{1})$/;

  const observer = useRef();
  const lastItem = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage]
  );

  const resetForm = () => {
    setCreateWarehouseDetails({
      name: "",
      postcode: "",
      village: "0",
      postoffice: "",
      district: "",
      state: "",
      sub_district: "",
      customers_id: customerId,
    });
    setEditMode(false);
    setVillagesList([]);
    setErrWarehouseDetails({
      errWarehouseName: "",
      errPincode: "",
      errVillage: "",
      errGstin: "",
    });
    setLoadingButton(false);
  };

  const resetFormUser = () => {
    setUserDetails({
      warehouse_id: selectedWarehouseId,
      first_name: "",
      emp_code: "",
      mobile_number: "",
      designations: "",
      emp_code: "",
      last_name: "",
      email: "",
      user_type: "0",
      user_name: "",
    });
    setErrWarehouseUserDetails({
      errFirstName: "",
      errDesignation: "",
      errEmployeeCode: "",
      errMobileNumber: "",
      errEmail: "",
      errUserType: "",
      errUserName: "",
    });
    setEditModeUser(false);
    setLoadingButtonUser(false);
  };

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
    if (open === false) {
      resetForm();
      setErrCreateUser("");
      resetFormUser();
      setUserDoesNotExistError("");
      setUserExistError("");
      dispatch(updateWarehouseUserEditInvoke({}));
    }
  };

  const handleOpenCreateWarehouseDrawer = () => {
    setSelectedDrawer("warehouse");
    toggleDrawer("right", true);
  };
  const handleOpenCreateWarehouseDrawerMobile = () => {
    setSelectedDrawer("warehouseMobile");
    toggleDrawer("right", true);
  };

  useEffect(() => {
    setUserTypeList(enums?.user_type || []);
  }, [enums]);

  const handleGetAddressFromPincode = async (pincode, edit) => {
    setNotServiceableError("");
    setErrWarehouseDetails({
      ...errTotalWarehouse,
      errPincode: "",
    });
    const response = await getVillagesList(pincode);
    console.log("the response pincode", response);
    if (response === 400) {
      setErrWarehouseDetails({
        ...errTotalWarehouse,
        errPincode: "This Pincode is not serviceable",
      });
      // setNotServiceableError("This Pincode is not serviceable");
      return;
    }
    if (edit) {
      setVillagesList(response.area);
    } else {
      setCreateWarehouseDetails({
        ...createWarehouseDetails,
        postcode: response.pincode,
        state: response.state,
        district: response.district,
        sub_district: response.district,
        postoffice: response.postoffice,
      });
      setVillagesList(response.area);
    }
  };

  const handleOpenCreateUserDrawer = () => {
    setSelectedDrawer("user");
    toggleDrawer("right", true);
  };
  const handleClose = () => {
    setMinCharacterUserName("");
    setErrorUserName({});
    setErrTotalWarehouse("");
    // if (isMobile) {
    //   // resetForm();
    //   resetFormUser();
    //   dispatch(updateWarehouseUserEditInvoke({}));
    //   handleOpenCreateWarehouseDrawerMobile();

    //   return;
    // }
    setSelectedDrawer("warehouse");
    toggleDrawer("right", false);
  };

  const handleUserEdit = (user) => {
    setUserDetails(user);

    setEditModeUser(true);
    handleOpenCreateUserDrawer();
  };

  const handleEditWarehouse = (e, warehouse) => {
    console.log("warehouese================>", warehouse);
    setEditMode(true);
    setCreateWarehouseDetails(warehouse);
    handleGetAddressFromPincode(warehouse.postcode, true);
    handleOpenCreateWarehouseDrawer();
    e.stopPropagation();
  };

  const WarehouseSchema = yup.object().shape({
    warehouse_name: yup.string().required(),
    pincode: yup.number().required(),
    village: yup.string().min(2).required(),
  });

  useEffect(() => {
    if (Object.keys(warehouseUserEditInvoke).length > 0) {
      setUserDetails(warehouseUserEditInvoke);
      handleOpenCreateUserDrawer();
      setEditModeUser(true);
    }
  }, [warehouseUserEditInvoke]);

  const WarehouseUserSchema = yup.object().shape({
    first_name: yup.string().required(),
    designation: yup.string().required(),
    employee_code: yup.string().required(),
    mobile_number: yup.number().min(5000000000).max(9999999999).required(),
    email: yup.string().email(),
    user_type: yup.string().min(2).required(),
  });

  const WarehouseFormData = {
    warehouse_name: createWarehouseDetails.name,
    pincode: createWarehouseDetails.postcode,
    village: createWarehouseDetails.village,
  };
  console.log("WarehouseFormData==========>", WarehouseFormData);

  const WarehouseUsersFormData = {
    first_name: userDetails.first_name,
    designation: userDetails.designations,
    employee_code: userDetails.emp_code,
    mobile_number: userDetails.mobile_number,
    email: userDetails.email,
    user_type: userDetails.user_type,
    user_name: userDetails.user_name,
  };

  const handleCreateWarehouse = async () => {
    const isWarehouseValid = await WarehouseSchema.isValid(WarehouseFormData);
    const isValid = formValidationGstin();
    if (isWarehouseValid && isValid) {
      setLoadingButton(true);
      const response = await createWarehousePost(
        customerId,
        createWarehouseDetails
      );

      if (response === 200) {
        setErrTotalWarehouse("");
        setLoadingButton(false);
        getWarehouseListFromApi(customerId, 1);
        toggleDrawer("right", false);
        handleClose();
      } else if (response.status == 400) {
        setErrTotalWarehouse(response.data.detail);
        setLoadingButton(false);
      } else if (response.status === 403) {
        localStorage.clear();
        window.location.reload();
      } else if (response.status === 401) {
        setLoadingButton(false);
        setErrTotalWarehouse("Unauthorized!");
      } else {
        setErrTotalWarehouse(`ERROR : ${response}`);
        setLoadingButton(false);
      }
    } else {
      setErrWarehouseDetails({
        errWarehouseName: "Please enter valid warehouse name",
        errPincode: "Please enter Pincode",
        errVillage: "Please select village",
        errGstin: "Please enter Gstin",
      });
    }
  };

  const handleEditWarehouseApi = async () => {
    const isWarehouseValid = await WarehouseSchema.isValid(WarehouseFormData);
    const isValid = formValidationGstin();
    console.log(
      "isWarehouseValid==========>",
      isWarehouseValid,
      "isValid===============>",
      isValid
    );
    if (isWarehouseValid && isValid) {
      setLoadingButton(true);
      const response = await updateWarehousePut(
        customerId,
        selectedWarehouse.id,
        createWarehouseDetails
      );

      if (response === 200) {
        setErrTotalWarehouse("");
        setLoadingButton(false);
        getWarehouseListFromApi(customerId, 1);
        toggleDrawer("right", false);
      } else if (response.status == 400) {
        setErrTotalWarehouse(response.data.detail);
        setLoadingButton(false);
      } else if (response.status === 403) {
        localStorage.clear();
        window.location.reload();
      } else if (response.status === 401) {
        setLoadingButton(false);
        setErrTotalWarehouse("Unauthorized!");
      } else {
        setErrTotalWarehouse(`ERROR : ${response.data.detail[0].msg}`);
        setLoadingButton(false);
      }
    } else {
      setErrWarehouseDetails({
        errWarehouseName: "Please enter valid warehouse name",
        // errPincode: "Please enter valid Pincode",
        errVillage: "Please select village",
        errGstin: "Please enter Gstin",
      });
    }
  };

  const handleSelectWarehouse = (warehouse, index) => {
    setSelectedWarehouse(warehouse);
    setSelectedIndex(index);
    setSelectedWarehouseId(warehouse.id);
    // handleOpenCreateWarehouseDrawerMobile();
  };

  const handleSearchWarehouse = async (customerid, values, page) => {
    if (values && values.length >= 1) {
      try {
        // setLoading(true);
        const productResponse = await axiosInterceptor.get(
          `${apiUrl.customersBaseUrlV2}/${prefixSalt()}-${convertToBase64(
            customerid
          )}/warehouses?current_page=${page}&name=${values}`
        );

        if (productResponse.data.warehouses_list.length > 0) {
          setWarehouseData(productResponse.data.warehouses_list);
          setIsLastPage(productResponse.data.is_last_page);
        } else {
          setWarehouseData([]);
        }

        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        setWarehouseData([]);
      }
    }
    // else if (searchValue.length <= 1) {
    //   getWarehouseListFromApi(customerId, 1);
    // }
  };
  const handleCancelSearch = () => {
    dispatch(searchWarehouse(""));
    getWarehouseListFromApi(customerId, 1);
  };
  useEffect(() => {
    if (searchTerm) {
      handleSearchWarehouse(customerId, searchTerm, currentPage);
      dispatch(searchWarehouse(searchTerm));
    } else {
      dispatch(searchWarehouse(""));
      getWarehouseListFromApi(customerId, 1);
    }
  }, [searchTerm]);
  // const handleCancelSearch = () => {
  //   setSearchValue("");
  // };
  useEffect(() => {
    if (searchTerm.length > 0) {
      dispatch(searchWarehouse(searchTerm));
    }
  }, [searchTerm]);
  const GetWarehouseUsersApi = async (warehouseId) => {
    const usersResponse = await getWarehouseUsersList(warehouseId);
    setWarehouseUserList(usersResponse.warehouse_users_list);
  };

  useEffect(() => {
    if (selectedWarehouseId) {
      GetWarehouseUsersApi(selectedWarehouseId);
    }
  }, [selectedWarehouseId]);

  const getWarehouseListFromApi = async (customerId, page) => {
    const response = await getWarehouseList(customerId, page);

    if (response) {
      setWarehouseData(response.warehouses_list);
      setIsLastPage(response.is_last_page);
      setLoadingPage(false);
      if (response.warehouses_list.length > 0) {
        setSelectedWarehouse(response.warehouses_list[0]);
        setSelectedIndex(0);
        setSelectedWarehouseId(response.warehouses_list[0].id);
      }
    }
  };

  useEffect(() => {
    getWarehouseListFromApi(customerId, currentPage);
  }, [customerId, currentPage]);

  // const createWarehouseUserPut = () => {};

  const handleWarehouseUser = async () => {
    const isUserValid = await WarehouseUserSchema.isValid(
      WarehouseUsersFormData
    );
    console.log(
      "isUserValid===========>",
      isUserValid,
      "isValidUser============>",
      isValidUser
    );
    const isValidUser = formValidation();

    if (isUserValid && isValidUser) {
      setLoadingButtonUser(true);
      if (editModeUser) {
        const responseUser = await updateWarehouseUserPut(
          selectedWarehouseId,
          userDetails.first_name,
          userDetails.mobile_number,
          userDetails.designations,
          userDetails.emp_code,
          userDetails.last_name,
          userDetails.email,
          userDetails.user_type,
          userDetails.id,
          userDetails.user_name,
          userDetails.is_active,
          userDetails.department
        );

        if (responseUser === 200) {
          setLoadingButtonUser(false);
          setUserDoesNotExistError("");
          setUserExistError("");
          toggleDrawer("right", false);
          GetWarehouseUsersApi(selectedWarehouseId);
          setErrCreateUser("");
        } else if (responseUser.status === 400) {
          //
          setErrCreateUser(responseUser.data.detail);
          setLoadingButtonUser(false);
        } else if (responseUser.status === 401) {
          setErrCreateUser("Unauthorized!");
          setLoadingButtonUser(false);
        } else if (responseUser.status === 422) {
          setLoadingButtonUser(false);
        }
      } else {
        const responseUser = await wareHouseUserPost(
          selectedWarehouseId,
          userDetails.first_name,
          userDetails.mobile_number,
          userDetails.designations,
          userDetails.emp_code,
          userDetails.last_name,
          userDetails.email,
          userDetails.user_type,
          userDetails.user_name,
          userDetails.department
        );

        if (responseUser === 201) {
          setLoadingButtonUser(false);
          toggleDrawer("right", false);
          setUserDoesNotExistError("");
          setUserExistError("");
          GetWarehouseUsersApi(selectedWarehouseId);
          setErrCreateUser("");
        } else if (responseUser.status === 400) {
          //
          setErrCreateUser(responseUser.data.detail);
          setLoadingButtonUser(false);
        } else if (responseUser.status === 422) {
          //
          setErrCreateUser(
            responseUser.data.detail.map((data, index) => (
              <span key={index}>{data.msg}</span>
            ))
          );
          setLoadingButtonUser(false);
        } else if (responseUser.status === 401) {
          setErrCreateUser("Unauthorized!");
          setLoadingButtonUser(false);
        }
      }
    } else {
      setErrWarehouseUserDetails({
        errFirstName: "Please enter valid first name",
        errDesignation: "Please enter valid designation",
        errEmployeeCode: "Please enter valid employee code",
        errMobileNumber: "Please enter valid mobile number",
        errEmail: "Please enter valid email id",
        errUserType: "Please enter valid user type",
        errUserName: "Please enter user name",
      });
    }
  };
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  const getUserName = async (userName) => {
    try {
      const userResponse = await axios.get(
        `${apiUrl.loginUserVerify}/${userName}`,
        { headers: { "x-app-token": readToken() } }
      );

      if (userResponse.status == 200) {
        return userResponse;
      }
    } catch (error) {
      return error.response;
    }
  };

  const verifyUsers = async () => {
    const users = await getUserName(userDetails.user_name);

    if (users.status == 200) {
      setUserExistError("User already exist!");
      setUserDoesNotExistError("");
    }
    if (users.status == 401) {
      // setErrorEmail("");
    }
    if (users.status == 400) {
      setUserDoesNotExistError("Available");
      setUserExistError("");
      // setErrorEmail("");
    }
  };
  const [errorUserName, setErrorUserName] = useState("");
  const [errorEmailName, setErrorEmailName] = useState("");
  const [errorLocationGst, setErrorLocationGst] = useState("");

  const formValidation = () => {
    const errorUserName = {};
    // const errorLocationGst = {};
    const errorEmailName = {};
    const userNameRegex = /(^[a-z]+([_\-0-9a-z]{0,}){1,}$)/;

    let isValid = true;
    if (!userNameRegex.test(userDetails.user_name) && userDetails.user_name) {
      errorUserName.errorUser =
        "User Name should only contain lower case letter.";
      isValid = false;
    }
    if (userDetails.email == "") {
      errorEmailName.errorUserEmail = "Please enter Email Id.";
      isValid = false;
    }
    // const gstinRegex =
    //   /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/;

    // if (createWarehouseDetails.gstin_number == "") {
    //   errorLocationGst.errorGstinLocation = "GSTIN is mandatory.";
    //   isValid = false;
    // }
    // if (
    //   !gstinRegex.test(createWarehouseDetails.gstin_number) &&
    //   createWarehouseDetails.gstin_number !== ""
    // ) {
    //   errorLocationGst.errorGstinLocation = "Invalid GSTIN.";
    //   isValid = false;
    // }

    setErrorUserName(errorUserName);
    // setErrorLocationGst(errorLocationGst);
    setErrorEmailName(errorEmailName);

    return isValid;
  };
  const formValidationGstin = () => {
    const errorLocationGst = {};

    let isValid = true;

    const gstinRegex =
      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z|1-9|A-J|S]{1}[0-9A-Z]{1})$/;

    if (createWarehouseDetails.gstin_number == "") {
      // errorLocationGst.errorGstinLocation = "GSTIN is mandatory.";
      isValid = false;
    }
    if (
      !gstinRegex.test(createWarehouseDetails.gstin_number) &&
      createWarehouseDetails.gstin_number !== ""
    ) {
      errorLocationGst.errorGstinLocation = "Invalid GSTIN.";
      isValid = false;
    }

    setErrorLocationGst(errorLocationGst);

    return isValid;
  };
  const handlePincode = (data, errorMsg) => {
    console.log("data=========>", data);
    if (data) {
      setCreateWarehouseDetails({
        ...createWarehouseDetails,
        postcode: data,
      });
    }
    if (errorMsg) {
      setErrWarehouseDetails({
        ...errTotalWarehouse,
        errPincode: errorMsg,
      });
    } else {
      handleGetAddressFromPincode(data);
      setErrWarehouseDetails({
        ...errTotalWarehouse,
        errPincode: "",
      });
    }
  };

  return (
    <WareHouse_list_main_container
      className="warehouse-list-main-container"
      mode={darkMode}
    >
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={99999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong
            backButtonLink={`/customers/${customerId}/warehouses`}
            buttonText="Close"
          />
        </div>
      </ModalAntd>
      <div className="warehouse-list-sidebar-container">
        <SideBarMain />{" "}
      </div>
      <div className="warehouse-list-right-main-container">
        {isMobile ? (
          <div
            className="trip-list-mobile-header_warehouse"
            // style={{ padding: "0px 30px" }}
          >
            <MobileHeader headerTitle="Warehouses" />
          </div>
        ) : null}
        {/*drawer starts*/}
        {selectedDrawer === "warehouse" ? (
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => {
              toggleDrawer("right", false);
              setErrTotalWarehouse("");
            }}
          >
            <Warehouse_list_warehouse_drawer_container
              className="warehouse-list-warehouse-drawer-container"
              mode={darkMode}
            >
              <div className="warehouse-list-drawer-head">
                Warehouse details{" "}
                <Close
                  onClick={handleClose}
                  style={{ color: "grey", cursor: "pointer" }}
                />
              </div>
              <div className="warehouse-list-drawer-input-container">
                <div className="warehouse-list-drawer-row">
                  <div className="warehouse-list-input-group">
                    <h4 className="warehouse-list-input-label">
                      Warehouse Name <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      name="name"
                      autoComplete="off"
                      className="warehouse-list-input"
                      variant="outlined"
                      placeholder="Enter warehouse name"
                      value={createWarehouseDetails.name}
                      onChange={(e) => {
                        setCreateWarehouseDetails({
                          ...createWarehouseDetails,
                          name: e.target.value,
                        });
                      }}
                    />
                    <div className="warehouse-error">
                      {createWarehouseDetails.name
                        ? null
                        : errWarehouseDetails.errWarehouseName}
                    </div>
                  </div>
                </div>
                <div className="warehouse-list-drawer-row">
                  <div className="warehouse-list-input-group">
                    <h4 className="warehouse-list-input-label">
                      GSTIN <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      name="gstin"
                      autoComplete="off"
                      className="warehouse-list-input"
                      variant="outlined"
                      placeholder="Enter GSTIN"
                      value={createWarehouseDetails.gstin_number}
                      onChange={(e) => {
                        setCreateWarehouseDetails({
                          ...createWarehouseDetails,
                          gstin_number: e.target.value,
                        });
                        setErrorLocationGst({});
                      }}
                    />
                    <div className="warehouse-error">
                      {createWarehouseDetails.gstin_number
                        ? null
                        : errWarehouseDetails.errGstin}
                    </div>
                    {Object.keys(errorLocationGst).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorLocationGst[key]}
                        </h6>
                      );
                    })}
                  </div>
                </div>
                <Pincode
                  parentStyle={{ display: "flex", flexDirection: "column" }}
                  handlePincode={handlePincode}
                  pincodeError={errWarehouseDetails.errPincode}
                  inputDesign={{ marginTop: 0, width: "100%" }}
                  lastPincode={createWarehouseDetails.postcode}
                />

                {/* <div className="warehouse-list-drawer-row">
                  <div className="warehouse-list-input-group">
                    <h4 className="warehouse-list-input-label">
                      Pincode <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="warehouse-list-input"
                      variant="outlined"
                      placeholder="Enter Pincode"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      value={createWarehouseDetails.postcode}
                      onChange={(e) => {
                        // setCreateWarehouseDetails({
                        //   ...createWarehouseDetails,
                        //   postcode: e.target.value,
                        // });
                        // if (e.target.value.length === 6) {
                        //   handleGetAddressFromPincode(e.target.value);
                        // }
                        // setNotServiceableError("");
                      }}
                    />
                    <div className="warehouse-error">
                      {createWarehouseDetails.postcode.length !== 6
                        ? errWarehouseDetails.errPincode
                        : null}
                      {notServiceableError ? notServiceableError : null}
                    </div>
                  </div>
                </div> */}
                <div className="warehouse-list-drawer-row">
                  <div className="warehouse-list-input-group">
                    <h4 className="warehouse-list-input-label">
                      Village <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="warehouse-list-input"
                      select
                      variant="outlined"
                      placeholder="Enter warehouse name"
                      value={createWarehouseDetails.village}
                      onChange={(e) => {
                        setCreateWarehouseDetails({
                          ...createWarehouseDetails,
                          village: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value="0" disabled>
                        Select village
                      </MenuItem>
                      {villagesList.map((village) => {
                        return <MenuItem value={village}>{village}</MenuItem>;
                      })}
                    </TextField>
                    <div className="warehouse-error">
                      {createWarehouseDetails.village.length >= 2
                        ? null
                        : errWarehouseDetails.errVillage}
                    </div>
                  </div>
                </div>
                <div className="warehouse-list-drawer-row">
                  <div className="warehouse-list-input-group">
                    <h4 className="warehouse-list-input-label">Landmark</h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="warehouse-list-input"
                      variant="outlined"
                      placeholder="Enter landmark"
                      value={createWarehouseDetails.landmark}
                      onChange={(e) => {
                        setCreateWarehouseDetails({
                          ...createWarehouseDetails,
                          landmark: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="warehouse-list-drawer-row">
                  <div className="warehouse-list-input-group">
                    <h4 className="warehouse-list-input-label">
                      Sub District <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="warehouse-list-input"
                      variant="outlined"
                      disabled
                      placeholder="Enter sub district"
                      value={createWarehouseDetails.sub_district}
                    />
                  </div>
                </div>
                <div className="warehouse-list-drawer-row">
                  <div className="warehouse-list-input-group">
                    <h4 className="warehouse-list-input-label">
                      District <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="warehouse-list-input"
                      variant="outlined"
                      disabled
                      placeholder="Enter district"
                      value={createWarehouseDetails.district}
                    />
                  </div>
                </div>
                <div className="warehouse-list-drawer-row">
                  <div className="warehouse-list-input-group">
                    <h4 className="warehouse-list-input-label">
                      State <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="warehouse-list-input"
                      variant="outlined"
                      disabled
                      placeholder="Enter state"
                      value={createWarehouseDetails.state}
                    />
                  </div>
                </div>
                <div className="warehouse-list-drawer-row">
                  <div className="warehouse-list-input-group">
                    <h4 className="warehouse-list-input-label">
                      Post Office <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="warehouse-list-input"
                      variant="outlined"
                      disabled
                      placeholder="Enter post office"
                      value={createWarehouseDetails.postoffice}
                    />
                  </div>
                </div>
                <div className="warehouse-list-drawer-row">
                  {errTotalWarehouse ? (
                    <Alert severity="error">{errTotalWarehouse}</Alert>
                  ) : null}
                </div>
                <div className="warehouse-list-drawer-row">
                  <ButtonRectangle
                    // className="warehouse-list-save-button"
                    style={{ width: "80%" }}
                    onClick={
                      editMode ? handleEditWarehouseApi : handleCreateWarehouse
                    }
                  >
                    {loadingButton ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </ButtonRectangle>
                </div>
              </div>
            </Warehouse_list_warehouse_drawer_container>
          </Drawer>
        ) : selectedDrawer === "user" ? (
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => {
              toggleDrawer("right", false);
              setMinCharacterUserName("");
              setErrorUserName({});
            }}
            style={{ height: "100vh" }}
            // style={{ backgroundColor: darkMode ? "black" : "grey" }}
          >
            <CreateNewUser
              className="create-trip-drawer-container"
              style={{ width: "400px", height: "100vh" }}
              mode={darkMode}
            >
              <div className="user_heading">
                <h4
                  style={{
                    color: darkMode ? colorPaletteDark.white : "",
                  }}
                >
                  Create New Warehouse User
                </h4>
                {/* {isMobile ? ( */}
                <Close
                  onClick={handleClose}
                  style={{
                    color: darkMode ? "white" : "grey",
                    cursor: "pointer",
                  }}
                />
                {/* // ) : null} */}
              </div>
              <div
                className="usersContainer"
                style={{
                  alignItems: "center",
                  justifyContent: "flex-start",
                  margin: "0px",
                }}
              >
                {" "}
                <div>
                  <h4
                    className="form_label_oem"
                    style={{
                      color: darkMode ? colorPaletteDark.white : "",
                    }}
                  >
                    First Name
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter First Name"
                    variant="outlined"
                    sx={{
                      width: "30ch",
                      height: "4ch",
                    }}
                    // sx={{
                    //   input: {
                    //     color: darkMode
                    //       ? colorPaletteDark.white
                    //       : colorPalette.black,
                    //   },
                    // }}
                    style={{
                      backgroundColor: darkMode ? colorPaletteDark.white : "",
                    }}
                    value={userDetails.first_name}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        first_name: e.target.value,
                      });
                    }}
                  />
                  <div className="warehouse-error">
                    {userDetails.first_name
                      ? null
                      : errWarehouseUserDetails.errFirstName}
                  </div>
                </div>
                <div>
                  <h4
                    className="form_label_oem"
                    style={{
                      color: darkMode ? colorPaletteDark.white : "",
                    }}
                  >
                    Last Name
                  </h4>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter Last Name"
                    variant="outlined"
                    sx={{
                      width: "30ch",
                      height: "4ch",
                    }}
                    style={{
                      backgroundColor: darkMode ? colorPaletteDark.white : "",
                    }}
                    value={userDetails.last_name}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        last_name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <h4
                    className="form_label_oem"
                    style={{
                      color: darkMode ? colorPaletteDark.white : "",
                    }}
                  >
                    User Name<sup style={{ color: "red" }}>*</sup>{" "}
                    {/* <RefreshIcon
                      style={{
                        marginLeft: "100px",
                        cursor: "pointer",
                        fontSize: "20px",
                      }}
                    /> */}
                  </h4>
                  <div className="user-name">
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter User Name"
                      variant="outlined"
                      sx={{
                        width: "28ch",
                        height: "4ch",
                      }}
                      style={{
                        backgroundColor: darkMode ? colorPaletteDark.white : "",
                      }}
                      disabled={editModeUser ? true : false}
                      value={userDetails.user_name}
                      color="success"
                      size="small"
                      onChange={(e) => {
                        setErrorUserName({});
                        if (e.target.value.length < 8) {
                          setMinCharacterUserName(
                            "Username must be of min.8 char."
                          );
                        } else {
                          setMinCharacterUserName("");
                        }
                        setUserDetails({
                          ...userDetails,
                          user_name: e.target.value,
                        });
                        setUserExistError("");
                        setUserDoesNotExistError("");
                      }}
                    />
                    {editModeUser ? null : (
                      <RefreshIcon
                        onClick={verifyUsers}
                        style={{
                          // marginLeft: "100px",
                          cursor: "pointer",
                          fontSize: "20px",
                          color: darkMode ? colorPaletteDark.white : "",
                        }}
                      />
                    )}
                  </div>
                  <div className="warehouse-error">
                    {userDetails.user_name
                      ? null
                      : errWarehouseUserDetails.errUserName}
                  </div>
                  <div className="warehouse-error">
                    {minCharacterUserName ? minCharacterUserName : null}
                  </div>
                  {Object.keys(errorUserName).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>{errorUserName[key]}</h6>
                    );
                  })}
                  {/* const [userExistError, setUserExistError] = useState(""); */}
                  {/* const [userDoesNotExistError, setUserDoesNotExistError] = useState(""); */}
                  {userExistError ? (
                    <span
                      style={{
                        color: "red",
                        display: "flex",
                        fontSize: "15px",
                        alignItems: "center",
                      }}
                    >
                      <CancelIcon style={{ fontSize: "20px" }} />{" "}
                      {userExistError}
                    </span>
                  ) : null}
                  {userDoesNotExistError ? (
                    <span
                      style={{
                        color: "green",
                        display: "flex",
                        fontSize: "15px",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleIcon style={{ fontSize: "20px" }} />{" "}
                      {userDoesNotExistError}
                    </span>
                  ) : null}
                </div>
                <div>
                  <h4
                    className="form_label_oem"
                    style={{
                      color: darkMode ? colorPaletteDark.white : "",
                    }}
                  >
                    Department
                    {/* <sup style={{ color: "red" }}>*</sup> */}
                  </h4>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter Department"
                    variant="outlined"
                    sx={{
                      width: "30ch",
                      height: "4ch",
                    }}
                    style={{
                      backgroundColor: darkMode ? colorPaletteDark.white : "",
                    }}
                    value={userDetails.department}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        department: e.target.value,
                      });
                    }}
                  />
                  {/* <div className="warehouse-error">
                    {userDetails.department
                      ? null
                      : errWarehouseUserDetails.errDepartment}
                  </div> */}
                </div>
                <div>
                  <h4
                    className="form_label_oem"
                    style={{
                      color: darkMode ? colorPaletteDark.white : "",
                    }}
                  >
                    Designation
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter Designation"
                    variant="outlined"
                    sx={{
                      width: "30ch",
                      height: "4ch",
                    }}
                    style={{
                      backgroundColor: darkMode ? colorPaletteDark.white : "",
                    }}
                    value={userDetails.designations}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        designations: e.target.value,
                      });
                    }}
                  />
                  <div className="warehouse-error">
                    {userDetails.designations
                      ? null
                      : errWarehouseUserDetails.errDesignation}
                  </div>
                </div>
                {/* <div>
                  <h4
                    className="form_label_oem"
                    style={{
                      color: darkMode ? colorPaletteDark.white : "",
                    }}
                  >
                    Designation
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter Designation"
                    variant="outlined"
                    sx={{
                      width: "30ch",
                      height: "4ch",
                    }}
                    style={{
                      backgroundColor: darkMode ? colorPaletteDark.white : "",
                    }}
                    value={userDetails.designations}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        designations: e.target.value,
                      });
                    }}
                  />
                  <div className="warehouse-error">
                    {userDetails.designations
                      ? null
                      : errWarehouseUserDetails.errDesignation}
                  </div>
                </div> */}
                <div>
                  <h4
                    className="form_label_oem"
                    style={{
                      color: darkMode ? colorPaletteDark.white : "",
                    }}
                  >
                    Employee Code
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter Employee Code"
                    variant="outlined"
                    sx={{
                      width: "30ch",
                      height: "4ch",
                    }}
                    style={{
                      backgroundColor: darkMode ? colorPaletteDark.white : "",
                    }}
                    value={userDetails.emp_code}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        emp_code: e.target.value,
                      });
                    }}
                  />
                  <div className="warehouse-error">
                    {userDetails.emp_code
                      ? null
                      : errWarehouseUserDetails.errEmployeeCode}
                  </div>
                </div>
                <div>
                  <h4
                    className="form_label_oem"
                    style={{
                      color: darkMode ? colorPaletteDark.white : "",
                    }}
                  >
                    Mobile Number
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter Mobile Number"
                    variant="outlined"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    sx={{
                      width: "30ch",
                      height: "4ch",
                    }}
                    style={{
                      backgroundColor: darkMode ? colorPaletteDark.white : "",
                    }}
                    value={userDetails.mobile_number}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        setUserDetails({
                          ...userDetails,
                          mobile_number: e.target.value,
                        });
                      }
                    }}
                  />
                  <div className="warehouse-error">
                    {userDetails.mobile_number.length === 10
                      ? null
                      : errWarehouseUserDetails.errMobileNumber}
                  </div>
                </div>
                <div>
                  <h4
                    className="form_label_oem"
                    style={{
                      color: darkMode ? colorPaletteDark.white : "",
                    }}
                  >
                    E-mail<sup style={{ color: "#E06666" }}>*</sup>
                  </h4>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter E-mail"
                    variant="outlined"
                    sx={{
                      width: "30ch",
                      height: "4ch",
                    }}
                    style={{
                      backgroundColor: darkMode ? colorPaletteDark.white : "",
                    }}
                    value={userDetails.email}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setUserDetails({ ...userDetails, email: e.target.value });
                      setErrorEmailName("");
                    }}
                  />
                  {Object.keys(errorEmailName).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>
                        {errorEmailName[key]}
                      </h6>
                    );
                  })}
                  <div className="warehouse-error">
                    {userDetails.email === ""
                      ? null
                      : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                          userDetails.email
                        )
                      ? null
                      : errWarehouseUserDetails.errEmail}
                  </div>
                </div>
                <div>
                  <h4
                    className="form_label_oem"
                    style={{
                      color: darkMode ? colorPaletteDark.white : "",
                    }}
                  >
                    User Type
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    id="outlined-basic"
                    placeholder="Enter Type"
                    select
                    variant="outlined"
                    sx={{
                      width: "30ch",
                      height: "4ch",
                    }}
                    style={{
                      backgroundColor: darkMode ? colorPaletteDark.white : "",
                    }}
                    value={userDetails.user_type}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        user_type: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value="0" disabled>
                      Select user type
                    </MenuItem>
                    {userTypeList.map((type) => {
                      return <MenuItem value={type}>{type}</MenuItem>;
                    })}
                  </TextField>
                  <div className="warehouse-error">
                    {userDetails.user_type.length >= 2
                      ? null
                      : errWarehouseUserDetails.errUserType}
                  </div>
                </div>
                {editModeUser ? (
                  <div>
                    <h4
                      className="form_label_oem"
                      style={{
                        color: darkMode ? colorPaletteDark.white : "",
                      }}
                    >
                      Active
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Type"
                      select
                      variant="outlined"
                      sx={{
                        width: "30ch",
                        height: "4ch",
                      }}
                      style={{
                        backgroundColor: darkMode ? colorPaletteDark.white : "",
                      }}
                      value={userDetails.is_active}
                      color="success"
                      size="small"
                      onChange={(e) => {
                        setUserDetails({
                          ...userDetails,
                          is_active: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value={"true"}>True</MenuItem>
                      <MenuItem value={"false"}>False</MenuItem>
                    </TextField>
                    {/* <div className="warehouse-error">
                      {userDetails.user_type.length >= 2
                        ? null
                        : errWarehouseUserDetails.errUserType}
                    </div> */}
                  </div>
                ) : null}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "1rem 0rem",
                  }}
                >
                  {errCreateUser ? (
                    <Alert
                      severity="warning"
                      style={isMobile ? { width: "95%" } : { width: "100%" }}
                    >
                      <h5 style={{ textAlign: "center" }}>{errCreateUser}</h5>
                    </Alert>
                  ) : null}
                </div>
                <div style={{ marginTop: ".5rem", width: "100%" }}>
                  <button
                    className="warehouse-list-add-button_user"
                    onClick={handleWarehouseUser}
                  >
                    {loadingButtonUser ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </CreateNewUser>
          </Drawer>
        ) : null}
        {selectedDrawer === "warehouseMobile" && isMobile ? (
          <Drawer
            className="drawer"
            anchor={"right"}
            open={state["right"]}
            onClose={() => toggleDrawer("right", false)}
          >
            <div
              style={{
                width: "100vw",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {isMobile ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "85%",
                    padding: ".5rem 0rem",
                  }}
                >
                  <Close
                    onClick={handleClose}
                    style={{ color: "grey", cursor: "pointer" }}
                  />
                </div>
              ) : null}
              <WarehouseMiniCard
                users={selectedWarehouse}
                onclick={handleOpenCreateUserDrawer}
                selectedWarehouseId={selectedWarehouseId}
                warehouseUserList={warehouseUserList}
              />
            </div>
          </Drawer>
        ) : null}

        {/*drawer ends*/}

        <div className="warehouse-list-right-wrapper">
          <div className="search_container">
            <div className="search_div" id="search_div_onboard">
              <input
                type="text"
                name=""
                id="search_input_onboard"
                onChange={(event) => {
                  dispatch(searchWarehouse(event.target.value));
                  setCurrentPage(1);
                }}
                value={searchTerm}
                placeholder="search"
                className="search_input"
              />
              {searchTerm && !warehouseData.length == 0 ? (
                <Close
                  onClick={handleCancelSearch}
                  style={{ color: "grey", cursor: "pointer" }}
                />
              ) : (
                <Search style={{ color: "grey", cursor: "pointer" }} />
              )}
            </div>
            {isMobile ? null : (
              <ButtonRectangle
                // className="warehouse-list-add-button"
                onClick={handleOpenCreateWarehouseDrawer}
              >
                Add Warehouse
              </ButtonRectangle>
            )}
          </div>
          {!isMobile ? <h3 style={{ color: "#016938" }}>Warehouses</h3> : null}

          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {isMobile ? (
              <ButtonRectangle
                // className="warehouse-list-add-button"
                onClick={handleOpenCreateWarehouseDrawer}
                style={{ marginBottom: "10px" }}
              >
                Add Warehouse
              </ButtonRectangle>
            ) : null}
          </div>
          {loadingPage ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                // backgroundColor: "grey",
              }}
            >
              {isMobile ? (
                <Box sx={{ width: 300, height: 500 }}>
                  <Skeleton style={{ height: "150px" }} animation="wave" />
                  <Skeleton style={{ height: "150px" }} animation="wave" />
                  <Skeleton style={{ height: "150px" }} animation="wave" />
                </Box>
              ) : (
                <Box sx={{ width: 600, height: 500 }}>
                  <Skeleton
                    style={{
                      height: "150px",
                      backgroundColor: darkMode
                        ? colorPaletteDark.secondaryColor
                        : "",
                    }}
                    animation="wave"
                  />
                  <Skeleton
                    style={{
                      height: "150px",
                      backgroundColor: darkMode
                        ? colorPaletteDark.secondaryColor
                        : "",
                    }}
                    animation="wave"
                  />
                  <Skeleton
                    style={{
                      height: "150px",
                      backgroundColor: darkMode
                        ? colorPaletteDark.secondaryColor
                        : "",
                    }}
                    animation="wave"
                  />
                </Box>
              )}
            </div>
          ) : warehouseData.length > 0 ? (
            <div className="warehouse-list-grid-container">
              {warehouseData.map((warehouse, index) => {
                return (
                  <div
                    className="warehouse-list-single-container"
                    style={
                      selectedIndex === index
                        ? { border: "2px solid #016938" }
                        : null
                    }
                    onClick={() => {
                      handleSelectWarehouse(warehouse, index);
                      handleOpenCreateWarehouseDrawerMobile();
                    }}
                  >
                    <div>
                      {isMobile ? null : (
                        // <div
                        //   style={{
                        //     width: "70%",
                        //     height: "70%",
                        //     backgroundColor:"blue"
                        //     // margin: "5px 0px",
                        //   }}
                        // >
                        //   <img width="80%" height="80%" src={COMPANY} alt="" />
                        // </div>
                        <div
                          style={{
                            width: "100%",
                            height: "100px",
                            margin: "10px 0px",
                          }}
                        >
                          <img
                            width="100%"
                            height="100px"
                            src={COMPANY}
                            alt=""
                          />
                        </div>
                      )}

                      {/* <img
                      style={{
                        backgroundColor: "red",
                        width: "100px",
                        height: "100px",
                      }}
                      src=""
                      alt=""
                    /> */}
                    </div>
                    <div className="warehouse-list-warehouse-name-conatiner">
                      {isMobile ? (
                        <div>
                          {" "}
                          <div className="warehouse-list-warehouse-name">
                            {warehouse.name ? warehouse.name : " - "}
                          </div>
                          <div className="warehouse-list-warehouse-code">
                            warehouse code : <br></br>
                            {warehouse.warehouse_code
                              ? warehouse.warehouse_code
                              : " - "}
                          </div>
                          <div className="warehouse-list-warehouse-code">
                            GSTIN : <br></br>
                            {warehouse.gstin_number
                              ? warehouse.gstin_number
                              : " - "}
                          </div>
                          <div className="warehouse-list-warehouse-state">
                            state : {warehouse.state ? warehouse.state : " - "}
                          </div>
                        </div>
                      ) : (
                        <>
                          {" "}
                          {warehouseData.length == index + 1 ? (
                            <div
                              className="warehouse-list-warehouse-name"
                              ref={lastItem}
                            >
                              {warehouse.name ? warehouse.name : " - "}
                            </div>
                          ) : (
                            <div className="warehouse-list-warehouse-name">
                              {warehouse.name ? warehouse.name : " - "}
                            </div>
                          )}
                          <div className="warehouse-list-warehouse-code">
                            Warehouse Code : <br></br>
                            <span
                              style={{
                                fontWeight: "600",
                                color: darkMode ? "white" : "rgb(82, 81, 81)",
                              }}
                            >
                              {warehouse.warehouse_code
                                ? warehouse.warehouse_code
                                : " - "}
                            </span>
                          </div>
                          <div className="warehouse-list-warehouse-code">
                            GSTIN : <br></br>
                            <span
                              style={{
                                fontWeight: "600",
                                color: darkMode ? "white" : "rgb(82, 81, 81)",
                              }}
                            >
                              {warehouse.gstin_number
                                ? warehouse.gstin_number
                                : " - "}
                            </span>
                          </div>
                          <div className="warehouse-list-warehouse-state">
                            state : {warehouse.state ? warehouse.state : " - "}
                          </div>
                        </>
                      )}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                        id="edit_warehouse"
                      >
                        {/* <button className="warehouse-list-edit-button"> */}{" "}
                        <Edit
                          style={{
                            margin: "5px",
                            color: darkMode ? "white" : "",
                          }}
                          onClick={(e) => handleEditWarehouse(e, warehouse)}
                        />{" "}
                        {/* </button> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              className="warehouse-not-found"
              style={{ color: darkMode ? "white" : "" }}
            >
              No warehouse data found
            </div>
          )}
        </div>
      </div>
      <div className="warehouse-list-right-mini-details-container">
        <WarehouseMiniCard
          users={selectedWarehouse}
          onclick={handleOpenCreateUserDrawer}
          onEdit={(user) => handleUserEdit(user)}
          selectedWarehouseId={selectedWarehouseId}
          warehouseUserList={warehouseUserList}
        />
      </div>
    </WareHouse_list_main_container>
  );
}

export default WarehouseList;
