import React, { useState, useEffect, useContext } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import logo from "../../assets/logo/leap_supply_logo.svg";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { Alert, Badge, Snackbar } from "@mui/material";
import changePassword from "../../assets/dashboard_icons/change_password.svg";
import logout from "../../assets/dashboard_icons/logout_icon.svg";
import Customers from "../../assets/dashboard_icons/greyIcons/Customer(1).svg";
import Customers_replace from "../../assets/dashboard_icons/replace_icons/new/Customer.svg";
import dashboard from "../../assets/dashboard_icons/greyIcons/Dashboad(1).svg";
import dashboard_replace from "../../assets/dashboard_icons/replace_icons/new/Dashboad.svg";
import selected_teams from "../../assets/selected_teams.jpeg";
import unselected_teams from "../../assets/unselected_teams.jpeg";
import escalation_unselected from "../../assets/escalation_unselected.jpeg";
import escalation_selected from "../../assets/escalation_selected.jpeg";
import payments from "../../assets/dashboard_icons/greyIcons/Paymentys(1).svg";
import payments_replace from "../../assets/dashboard_icons/replace_icons/new/Paymentys.svg";
// import SidebarToggle from "./SidebarToggle";
import LOGISTICS_FAARMS from "../../assets/logo/logo(3).png";
import OEM_ICON from "../../assets/dashboard_icons/greyIcons/VehicelManufacturerActive.svg";
import oem_replace from "../../assets/dashboard_icons/replace_icons/new/VehicelManufacturerInactive.svg";
import TRIP_ICON from "../../assets/dashboard_icons/greyIcons/Trip(2).svg";
import trip_replace from "../../assets/dashboard_icons/replace_icons/new/Trip(1).svg";
import VEHICLE_ICON_GREEN from "../../assets/dashboard_icons/vehicle.svg";
import VEHICLE_ICON from "../../assets/dashboard_icons/greyIcons/Vehicles(1).svg";
import vehicle_replace from "../../assets/dashboard_icons/replace_icons/new/Vehicles.svg";
import CARRIER_ICON from "../../assets/dashboard_icons/greyIcons/Transporter(1).svg";
import carrier_replace from "../../assets/dashboard_icons/replace_icons/new/Transporter.svg";
import INDENTGRAY from "../../assets/dashboard_icons/greyIcons/Indent(1).svg";
import INDENTWHITE from "../../assets/dashboard_icons/replace_icons/new/Indent.svg";
import QUOTEWHITE from "../../assets/dashboard_icons/replace_icons/new/Quotation.svg";
import QUOTEGRAY from "../../assets/dashboard_icons/greyIcons/Quotation(1).svg";
import RECEIVABLESWHITE from "../../assets/dashboard_icons/replace_icons/new/Paymentreceivable.svg";
import RECEIVABLESGRAY from "../../assets/dashboard_icons/greyIcons/Paymentreceivable-1.svg";
import { useDispatch } from "react-redux";
import CARRIER_ICON_GREEN from "../../assets/dashboard_icons/carrier.svg";
import { NavLink } from "react-router-dom";
import { CircularProgress, Menu, TextField } from "@mui/material";
//modal dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { readLoginData } from "../../helper/dataDecryptionHelper";
import { argon2id } from "hash-wasm";
import { changeUserPassword } from "../../pages/login/LoginServices";
import { updateOnFlag, updateOnFlagUpdate } from "../../redux/indentSlice";
import { Sidebar_style } from "./sidebarMain.js";
import { DarkModeContext } from "../../context/darkModeContext";

function SideBarMain({ pageHeader }) {
  const dispatch = useDispatch();
  let history = useHistory();
  const is_temporary_password =
    readLoginData() !== undefined && readLoginData().temporary_password
      ? readLoginData().temporary_password
      : null;
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [changedPassword, setChangedPassword] = useState("");
  const [misMatchedPassword, setMisMatchedPassword] = useState("");
  const [loginData, setLoginData] = useState();
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [fullCurrentPath, setFullCurrentPath] = useState("");

  const handleGoHome = () => {
    history.push("/dashboard");
  };
  const handleLogout = () => {
    if (
      localStorage.getItem("website_url") ===
      process.env.REACT_APP_CUSTOMER_WEBSITE_URL
    ) {
      history.push("/customer/login");
      localStorage.clear();
      window.location.reload();
    } else {
      history.push("/login");
      localStorage.clear();
      window.location.reload();
    }
    // localStorage.clear();
    // window.location.reload();
    // history.push("/login");
  };

  useEffect(() => {
    setLoginData(readLoginData());
  }, []);

  useEffect(() => {
    if (history.location.pathname.split("/").length > 1) {
      setCurrentPath(history.location.pathname.split("/")[1]);
    }
    setFullCurrentPath(history.location.pathname);
  }, []);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  //Modal Related code starts
  const [open, setOpen] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenChangePassword = () => {
    history.push("/change-passwords");
    dispatch(updateOnFlagUpdate(false));
    dispatch(updateOnFlag(false));
  };
  useEffect(() => {
    if (is_temporary_password) {
      if (
        localStorage.getItem("website_url") ===
        process.env.REACT_APP_CUSTOMER_WEBSITE_URL
      ) {
        history.push("/customer/change-passwords");
      } else {
        history.push("/change-passwords");
      }
      // history.push("/change-passwords");
    }
  }, [is_temporary_password]);
  //Modal Related code ends
  async function argon2HashPassword(user, userPassword) {
    // let decodedUserName = atob(user);
    //
    // setUserNameFromApi(ddecodedUserName);
    const salt = user;
    const hash = await argon2id({
      password: userPassword,
      salt,
      parallelism: 1,
      iterations: 2,
      memorySize: 16,
      hashLength: 16,
      outputType: "encoded",
    });
    //
    return hash;
  }

  const handlePasswordChange = async () => {
    const argon = await argon2HashPassword(
      loginData.USER_NAME,
      changedPassword
    );
    if (confirmPassword !== changedPassword) {
      setMisMatchedPassword("Password does not matches.");
    }

    if (argon && confirmPassword == changedPassword) {
      setLoading(true);

      const response = await changeUserPassword(argon);

      if (response.status == 200) {
        setLoading(false);
        setSuccessMsg(`A password has been changed successfully.`);
        setOpenSnackbar(true);
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 2000);

        // setRegistredEmail("");
        // setWrongEmailMsg("");
      }
      if (response == 422) {
        setSuccessMsg("");
        setLoading(false);

        // setWrongEmailMsg("Please enter correct email id.");
      }
    }
    // setLoading(true);
  };
  const [state, setState] = React.useState({
    right: false,
  });
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "left",
  });
  const { vertical, horizontal } = snackbarPosition;
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  JSON.parse(localStorage.getItem("opneNewFeature"));
  const { toggle, darkMode } = useContext(DarkModeContext);
  return isMobile ? null : (
    <Sidebar_style className="sidebar_onboard_container" mode={darkMode}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={4000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        key={vertical + horizontal}
      >
        <Alert severity="success">{successMsg}</Alert>
      </Snackbar>
      <div className="sidebar_onboard_wrapper">
        {/* Modal DO YOU REALLY WANT TO LOGOUT */}
        <div>
          {/* <Button variant="outlined" onClick={handleClickOpen}>
            Open alert dialog
          </Button> */}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Do you really want to Logout?"}
            </DialogTitle>
            <DialogContent style={{ width: "390px" }}>
              <DialogContentText id="alert-dialog-description"></DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleLogout}>Yes</Button>
              <Button onClick={handleClose} autoFocus>
                No
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          {/* <Button variant="outlined" onClick={handleClickOpen}>
            Open alert dialog
          </Button> */}
          {/* {is_temporary_password?} */}
          <Dialog
            open={openChangePassword}
            onClose={is_temporary_password ? null : handleCloseChangePassword}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <span>Change Password</span>
              </div>
            </DialogTitle>
            <DialogContent style={{ width: "500px", height: "180px" }}>
              <DialogContentText
                id="alert-dialog-description"
                style={{ marginTop: "20PX" }}
              >
                <TextField
                  id="outlined-basic"
                  label="Enter New password"
                  variant="outlined"
                  fullWidth
                  value={changedPassword}
                  onChange={(e) => {
                    setChangedPassword(e.target.value);
                    setMisMatchedPassword("");
                    // setErrLogin("");
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Confirm password"
                  variant="outlined"
                  style={{ marginTop: "15px" }}
                  fullWidth
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setMisMatchedPassword("");
                    // setErrLogin("");
                  }}
                />
                {misMatchedPassword ? (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {misMatchedPassword}
                  </span>
                ) : null}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0px",
              }}
            >
              <span
                onClick={handlePasswordChange}
                className="change_password_botton"
              >
                {loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  "Change Password"
                )}
              </span>
              {/* {is_temporary_password ? null : (
                <Button onClick={handleCloseChangePassword} autoFocus>
                  Discard
                </Button>
              )} */}
            </DialogActions>
          </Dialog>
        </div>
        {/* Modal DO YOU REALLY WANT TO LOGOUT */}

        <div className="sidebar_onboard_Right_box">
          <div className="sidebar-top-container">
            <div className="logo_logistics">
              <div className="company_name_div" onClick={handleGoHome}>
                <div className="logo_div">
                  <img src={logo} alt="Logo" className="logo" />
                </div>
                {/* <h3 className="name">Faarms Global</h3> */}
              </div>
            </div>

            <div className="onboarding">
              <div className="sidebar-categories" mode={darkMode}>
                <h5>Home</h5>
              </div>

              <NavLink
                to="/dashboard"
                activeClassName="sidebarListItem active"
                className="link"

                // onClick={setImageColor("Carriers")}
              >
                {currentPath === "dashboard" ? (
                  <>
                    {" "}
                    <img
                      src={dashboard_replace}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={dashboard}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                )}

                <h4
                  className="vehicles"
                  onClick={() => {
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                  }}
                >
                  Dashboard
                </h4>
              </NavLink>

              <NavLink
                to="/teams"
                activeClassName="sidebarListItem active"
                className="link"

                // onClick={setImageColor("Carriers")}
              >
                {currentPath === "teams" ? (
                  <>
                    {" "}
                    <img
                      src={selected_teams}
                      style={{ height: 20, width: 20, marginLeft: "1rem" }}
                      alt=""
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={unselected_teams}
                      alt=""
                      style={{ height: 20, width: 20, marginLeft: "1rem" }}
                    />
                  </>
                )}

                <h4 className="vehicles" onClick={() => {}}>
                  Teams
                </h4>
              </NavLink>
              <NavLink
                to="/escalation-matrix"
                activeClassName="sidebarListItem active"
                className="link"

                // onClick={setImageColor("Carriers")}
              >
                {currentPath === "escalation-matrix" ? (
                  <>
                    {" "}
                    <img
                      src={escalation_selected}
                      style={{ height: 20, width: 20, marginLeft: "1rem" }}
                      alt=""
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={escalation_unselected}
                      alt=""
                      style={{ height: 20, width: 20, marginLeft: "1rem" }}
                    />
                  </>
                )}

                <h4 className="vehicles" onClick={() => {}}>
                  Escalation Matrix
                </h4>
              </NavLink>
              <div className="sidebar-categories">
                <h5>Customer</h5>
              </div>
              {/* <div className="sidebar-single-container"> */}
              <NavLink
                to="/customers"
                activeClassName="sidebarListItem active"
                className="link sidebarSingle"
              >
                {/* <img src={TRIP_ICON_GREEN} alt="trip-icon" /> */}
                {/* Onboarding */}
                {currentPath === "customers" ? (
                  <>
                    {" "}
                    <img
                      src={Customers_replace}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={Customers}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                )}

                <h4
                  className="vehicles"
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                  }}
                >
                  Customers
                </h4>
              </NavLink>
              {/* <NavLink
                to="/indents"
                activeClassName="sidebarListItem active"
                className="link"

                // onClick={setImageColor("Trips")}
              >
                {currentPath === "indents" ? (
                  <>
                    {" "}
                    <img
                      src={INDENTWHITE}
                      alt=""
                      style={{
                        marginLeft: "1rem",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={INDENTGRAY}
                      alt=""
                      style={{
                        marginLeft: "1rem",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  </>
                )}

                <h4
                  className="vehicles"
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                  }}
                >
                  Indents
                </h4>
              </NavLink> */}
              {/* <NavLink
                to="/trip-indents"
                activeClassName="sidebarListItem active"
                className="link"

                // onClick={setImageColor("Trips")}
              >
                {currentPath === "trip-indents" ? (
                  <>
                    {" "}
                    <img
                      src={INDENTWHITE}
                      alt=""
                      style={{
                        marginLeft: "1rem",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={INDENTGRAY}
                      alt=""
                      style={{
                        marginLeft: "1rem",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  </>
                )}

                <h4
                  className="vehicles"
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                  }}
                >
                  Trip-Indents
                </h4>
              </NavLink> */}
              <NavLink
                to="/trips-indents"
                activeClassName="sidebarListItem active"
                className="link"

                // onClick={setImageColor("Trips")}
              >
                {currentPath === "quotations" ? (
                  <>
                    {" "}
                    <img
                      src={QUOTEWHITE}
                      alt=""
                      style={{
                        marginLeft: "1rem",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={QUOTEGRAY}
                      alt=""
                      style={{
                        marginLeft: "1rem",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  </>
                )}

                <h4
                  className="vehicles "
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                  }}
                >
                  Trip Indents
                </h4>
              </NavLink>
              {/* <NavLink
                to="/quotations"
                activeClassName="sidebarListItem active"
                className="link"

              >
                {currentPath === "quotations" ? (
                  <>
                    {" "}
                    <img
                      src={QUOTEWHITE}
                      alt=""
                      style={{
                        marginLeft: "1rem",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={QUOTEGRAY}
                      alt=""
                      style={{
                        marginLeft: "1rem",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  </>
                )}

                <h4
                  className="vehicles "
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                  }}
                >
                  Quotations
                </h4>
              </NavLink> */}
              <NavLink
                to="/payment-receivables"
                activeClassName="sidebarListItem active"
                className="link"

                // onClick={setImageColor("Trips")}
              >
                {currentPath === "payment-receivables" ? (
                  <>
                    {" "}
                    <img
                      src={RECEIVABLESWHITE}
                      alt=""
                      style={{
                        marginLeft: "1rem",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={RECEIVABLESGRAY}
                      alt=""
                      style={{
                        marginLeft: "1rem",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  </>
                )}

                <h4
                  className="vehicles"
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                  }}
                >
                  Payment Receivables
                </h4>
              </NavLink>

              <div className="sidebar-categories">
                <h5>Transporter</h5>
              </div>
              <NavLink
                to="/transporters"
                activeClassName="sidebarListItem active"
                className="link"

                // onClick={setImageColor("Carriers")}
              >
                {currentPath === "transporters" ? (
                  <>
                    {" "}
                    <img
                      src={carrier_replace}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={CARRIER_ICON}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                )}

                <h4
                  className="vehicles"
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                  }}
                >
                  Transporters
                </h4>
              </NavLink>
              <NavLink
                to="/vehicles"
                activeClassName="sidebarListItem active"
                className="link"

                // onClick={setImageColor("Vehicles")}
              >
                {currentPath === "vehicles" ? (
                  <>
                    {" "}
                    <img
                      src={vehicle_replace}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={VEHICLE_ICON}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                )}

                <h4
                  className="vehicles"
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                  }}
                >
                  Vehicles
                </h4>
              </NavLink>

              <NavLink
                to="/driver"
                activeClassName="sidebarListItem active"
                className="link"
              >
                {currentPath === "driver" ? (
                  <>
                    {" "}
                    <img
                      src={carrier_replace}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={CARRIER_ICON}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                )}

                <h4
                  className="vehicles"
                  onClick={() => {
                    // localStorage.removeItem("IntransiteStatus");
                    // localStorage.removeItem("tripType");
                    // localStorage.removeItem("selectedTransporter");
                    // localStorage.removeItem("selectedIndex");
                  }}
                >
                  Drivers
                </h4>
              </NavLink>

              <NavLink
                to="/vehicles-manufacturers"
                activeClassName="sidebarListItem active"
                className="link sidebarSingle"
                // onClick={localStorage.removeItem("reloadPage")}
              >
                {/* <img src={TRIP_ICON_GREEN} alt="trip-icon" /> */}
                {/* Onboarding */}
                {currentPath === "vehicles-manufacturers" ? (
                  <>
                    {" "}
                    <img
                      src={oem_replace}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img src={OEM_ICON} alt="" style={{ marginLeft: "1rem" }} />
                  </>
                )}

                <h4
                  className="vehicles"
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                  }}
                >
                  Vehicle Manufacturers
                </h4>
              </NavLink>
              {/* {currentPath === "trips"?():null} */}
              <NavLink
                to={
                  localStorage.getItem("createTripSource") == "/trips"
                    ? "/trips"
                    : "/ftl-trips"
                }
                activeClassName="sidebarListItem active"
                className="link"
                onClick={() => {
                  localStorage.setItem("FlagForApiCall", false);
                }}
              >
                {(currentPath === "trips" || currentPath === "ftl-trips") &&
                fullCurrentPath !== "/faarms-trips/create-trip" ? (
                  <>
                    {" "}
                    <img
                      src={trip_replace}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={TRIP_ICON}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                )}

                <h4
                  className="vehicles"
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                    localStorage.setItem("Trip_Type", "ftl_trip");
                  }}
                >
                  FTL Trips
                </h4>
              </NavLink>
              <NavLink
                to="/faarms-trips"
                activeClassName={"sidebarListItem active"}
                className="link sidebarSingle"
                // onClick={localStorage.removeItem("reloadPage")}
              >
                {/* <img src={TRIP_ICON_GREEN} alt="trip-icon" /> */}
                {/* Onboarding */}
                {fullCurrentPath === "/faarms-trips/create-trip" ||
                currentPath === "faarms-trips" ? (
                  <>
                    {" "}
                    <img
                      src={trip_replace}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={TRIP_ICON}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                )}

                <h4
                  className="vehicles"
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                    localStorage.removeItem("createTripSource");
                    localStorage.setItem("Trip_Type", "faarms_trip");
                  }}
                >
                  Faarms Trips
                </h4>
              </NavLink>
              <NavLink
                to="/old-trips"
                activeClassName={"sidebarListItem active"}
                className="link sidebarSingle"
                // onClick={localStorage.removeItem("reloadPage")}
              >
                {/* <img src={TRIP_ICON_GREEN} alt="trip-icon" /> */}
                {/* Onboarding */}
                {fullCurrentPath === "/old-trips/create-trip" ||
                currentPath === "old-trips" ? (
                  <>
                    {" "}
                    <img
                      src={trip_replace}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img
                      src={TRIP_ICON}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                )}

                <h4
                  className="vehicles"
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                    localStorage.removeItem("createTripSource");
                    localStorage.setItem("Trip_Type", "old_trip");
                  }}
                >
                  Old Trips
                </h4>
              </NavLink>

              {/* <NavLink
                to="/payments"
                activeClassName="sidebarListItem active"
                className="link"
              >
                {currentPath === "payments" ? (
                  <>
                    {" "}
                    <img
                      src={payments_replace}
                      alt=""
                      style={{ marginLeft: "1rem" }}
                    />
                  </>
                ) : (
                  <>
                    {" "}
                    <img src={payments} alt="" style={{ marginLeft: "1rem" }} />
                  </>
                )}

                <h4
                  className="vehicles"
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                  }}
                >
                  Payments
                </h4>
              </NavLink> */}
            </div>
          </div>
          {/* <div className="btn_logout">
            <h6 className="user_name">
              <AccountCircleIcon />
              <span style={{ marginLeft: "3px", fontSize: "12px" }}>
                {loginData ? loginData.USER_NAME : "Guest"}
              </span>
            </h6>
            <div
              style={{
                marginTop: ".5rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div className="changePassword">
                <img src={changePassword} alt="" />{" "}
                <span
                  onClick={handleClickOpenChangePassword}
                  style={{ fontSize: "12px" }}
                >
                  Change Password
                </span>
              </div>
              <div className="logout-div">
                <img src={logout} alt="" />{" "}
                <span onClick={handleClickOpen} style={{ fontSize: "12px" }}>
                  Logout
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Sidebar_style>
  );
}

export default SideBarMain;
