import styled from "styled-components";
import { colorPalette, typography } from "../../themes/theme";

export const Payment_success_container = styled.div`
  /* .payment_success_container { */
  width: 100vw;
  height: 100vh;
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* } */

  .payment_success_wrapper {
    width: 30%;
    height: 85%;
    margin: 0 auto;
    border-radius: 20px;
    /*background-color: blue;
    */
    box-shadow: ${colorPalette.boxShadowPrimary};
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    /* background-color: aquamarine; */
  }
  .online_headings_success {
    padding: 2rem 0.5rem;
    width: 100%;
    height: 10%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background-color: #a94457; */
  }
  .headings_payments_failed {
    font-size: 1.3rem;
    /* color: green; */
    text-align: center;
    width: 100%;
    color: red;
  }
  .online_success_icon {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    /* background-color: aqua; */
  }
  .online_transaction_id {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
  }
  .online_delivered_container {
    width: 90%;
    margin: 0 auto;
    height: 10%;
    display: flex;
    padding: 2rem 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: rgb(89, 255, 0); */
    margin-top: 2rem;
  }
  .online_delivered_items {
    width: 90%;
    margin: 0 auto;
    height: 10%;
    display: flex;
    padding: 1rem 0rem;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    /* background-color: rgb(89, 255, 0); */
  }
  .delivered_heading {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .payment_success_invoice {
    /* padding: 1rem 1rem; */
    width: 80%;
    height: 6%;
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    justify-content: space-between;
    /* background-color: rebeccapurple; */
    margin: 0 auto;
  }
  .payment_invoice_icon {
    /* width: 80%; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .payment_success_button {
    /* margin: 1rem 0rem; */
    width: 80%;
    height: 10%;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    justify-content: center;
    /* background-color: rebeccapurple; */
    /* margin: 0 auto; */
  }
  .payment_button {
    padding: 0.5rem 3rem;
    background-color: green;
    color: ${colorPalette.white};
    outline: none;
    border: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
  }
  .sucess-icons {
    width: 30%;
    height: 70%;
    margin: 0 auto;
    /* width: 100%; */
  }
  @media only screen and (max-width: 499px) {
    /* .payment_success_container { */
    width: 100vw;
    height: 100vh;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* } */

    .payment_success_wrapper {
      width: 90%;
      height: 90%;
      margin: 0 auto;
      /* background-color: aquamarine; */
      border-radius: 20px;
      /*background-color: blue;
        */
      box-shadow: ${colorPalette.boxShadowPrimary};
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      /* background-color: aquamarine; */
    }
    .online_headings_success {
      padding: 2rem 0.5rem;
      width: 100%;
      height: 10%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: ${colorPalette.secondaryWhite};
    }
    .headings_payments_success {
      font-size: ${typography.heading4};
      color: green;
      text-align: center;
      width: 100%;
    }
    .online_success_icon {
      width: 100%;
      height: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      /* background-color: aqua; */
    }
    .online_transaction_id {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      /* background-color: aqua; */
    }
    .online_delivered_container {
      width: 90%;

      margin: 0 auto;
      height: 100px;
      overflow-y: scroll;
      display: flex;
      padding: 3rem 1rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      /* background-color: rgb(89, 255, 0); */
      margin-top: 2rem;
    }
    .online_delivered_items {
      width: 90%;
      margin: 0 auto;
      height: 10%;
      display: flex;
      padding: 1rem 0rem;
      /* flex-direction: column; */
      align-items: center;
      justify-content: space-between;
      /* background-color: rgb(89, 255, 0); */
    }
    .delivered_heading {
      width: 90%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .payment_success_invoice {
      /* padding: 1rem 1rem; */
      width: 80%;
      height: 6%;
      display: flex;
      align-items: center;
      /* flex-direction: column; */
      justify-content: space-between;
      /* background-color: rebeccapurple; */
      margin: 0 auto;
    }
    .payment_invoice_icon {
      /* width: 80%; */
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .payment_success_button {
      /* margin: 1rem 0rem; */
      width: 80%;
      height: 10%;
      margin: 1rem auto;
      display: flex;
      align-items: center;
      /* flex-direction: column; */
      justify-content: center;
      /* background-color: rebeccapurple; */
      /* margin: 0 auto; */
    }
    .payment_button {
      padding: 0.5rem 3rem;
      background-color: green;
      color: ${colorPalette.white};
      outline: none;
      border: none;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }
    .sucess-icons {
      width: 25%;
      height: 100%;
      margin: 0 auto;
      /* width: 100%; */
    }
  }
`;
