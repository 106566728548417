import { React, useEffect, useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import success from "../../assets/dashboard_icons/icons8-truck.gif";

import { Component_style } from "../../themes/component_style";

import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { colorPalette } from "../../themes/theme";
import { VehicleStyle } from "./VehicleStyle.js";

function OnboardSuccessPublic() {
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);

  const name = queryParams.get("name");
  const transporter_code = queryParams.get("transporter_code");

  return (
    <VehicleStyle className="success_onboard_container">
      <div className="success_onboard_wrapper">
        <img src={success} alt="" width="120px" height="90px" />
        <h3 className="success_text">
          <VerifiedIcon style={{ marginRight: "1rem" }} /> Onboarded
          Successfully!
        </h3>
        <div
          className="trasnporter-details"
          style={{
            fontWeight: "500",
            color: colorPalette.paragraphColor,
            marginTop: "20px",
          }}
        >
          <p>Transporter Name : {name}</p>
          <p>Transporter Code : {transporter_code}</p>
        </div>
        <NavLink to="/" style={{ textDecoration: "none" }}>
          {" "}
          <h6 className="details">Home</h6>
        </NavLink>
      </div>
    </VehicleStyle>
  );
}

export default OnboardSuccessPublic;
