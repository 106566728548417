import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../themes/theme";

export const Mini_details_carrier_card_style = styled.div`
  /* .mini-details-carrier-card-main-container { */
  width: 400px;
  height: 100%;
  margin: 3% auto;
  background: ${colorPalette.white};
  box-shadow: ${colorPalette.boxShadowPrimary};
  border-radius: 14px;
  /* } */

  .bank_holder {
    display: flex;
    align-items: center;
    justify-content: center;
    /* justify-content: flex-start; */
  }

  .mini-details-carrier-tab-container {
    /* background-color: red; */
    overflow: scroll;
    position: relative;
    height: 57vh;
    /* width: 100%; */
  }

  .accodion_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
  }

  .mini-details-carrier-tab-container-bank {
    /* background-color: red; */
    overflow: scroll;
    position: relative;
    height: 51vh;
    /* width: 100%; */
  }

  .bank_container {
    width: 28vw;
    height: 100%;
  }

  .bank_wrapper {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    /* background-color: red; */
  }

  .bank_headings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2rem 0rem;
  }

  .bank-input {
    margin: 10px 0px;
    width: 90%;
  }

  .bankDetails {
    width: 120%;
    /* padding: .5rem 0rem; */
    /* background-color: blueviolet; */
  }

  .bank_button {
    padding: 0.8rem 3rem;
    width: 90%;
    margin: 2rem auto;
    background-color: ${colorPalette.primaryColor};
    border: none;
    outline: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: ${colorPalette.white};
    cursor: pointer;
  }

  .bank_button_add {
    padding: 0.8rem 1rem;
    width: 100%;
    /* margin-top: -5rem; */
    background-color: ${colorPalette.primaryColor};
    border: none;
    outline: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: ${colorPalette.white};
    cursor: pointer;
  }

  .bank-drawer-row {
    padding: 0.5rem 0rem;
  }

  .attachment {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .attachment_btn {
    border: 1px solid ${colorPalette.primaryColor};
    text-decoration: none;
    padding: 0.3rem 1rem;
    color: ${colorPalette.primaryColor};
    transition: ease all 0.5s;
    display: flex;
    align-items: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .attachment_btn:hover {
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    transition: ease all 0.5s;
  }

  .mini-details-carrier-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30%;
  }

  .MuiTabs-indicator {
    background-color: ${colorPalette.primaryColor} !important;
    color: ${colorPalette.white} !important;
  }

  .mini-details-carrier-single-list {
    margin: 1.5rem 5px;
    display: flex;
    overflow-wrap: hidden;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: ${typography.paragraph} !important;
    font-weight: ${FontWeight.semibold} !important;
  }

  .mini-details-carrier-single-list-left {
    font-size: ${typography.heading5};
    margin: 0px 5px;
    position: relative;
  }

  .mini-details-carrier-single-list-right {
    font-size: ${typography.heading5};
    margin: 0px 5px;
    position: absolute;
    width: 100%;
    color: ${colorPalette.paragraphSecondaryolor};
    left: 45%;
  }

  @media only screen and (max-width: 480px) {
    .bank_container {
      width: 100vw;
      height: 100%;
    }

    /* .mini-details-carrier-card-main-container { */
    width: 96%;
    height: 0%;
    margin: 5% auto;
    background: ${colorPalette.white};
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 14px;
    margin-left: 1.2rem;
    /* } */

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
      font-size: ${typography.paragraph_secondary} !important;
      font-weight: ${FontWeight.semibold} !important;
    }

    .mini-details-carrier-single-list-left {
      font-size: ${typography.paragraph};
      margin: 0px 5px;
      position: relative;
    }

    .mini-details-carrier-single-list-right {
      font-size: ${typography.paragraph_secondary};
      margin: 0px 5px;
      position: absolute;
      color: ${colorPalette.paragraphColor};
      left: 55%;
    }
  }
`;
