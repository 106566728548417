import styled from "styled-components";
import {
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";

export const Main_onboard_right_box = styled.div`
  .trip_buttons_div {
    width: 100%;
    height: 12%;
    margin-top: 1rem;
    /* margin: 0 auto; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background-color: rebeccapurple; */
  }
  .trip-list-path {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .search_modal {
    margin-top: 8%;
    margin-left: 5.8%;
    width: 100%;
  }
  .monthly_wise_filter {
    width: 95%;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.white};
  }
  .date_picker_div {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vehicle_number_div {
    flex: 4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .date_picker {
    height: 1rem;
    width: 30%;
  }
  .monthly_wise_buttons {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .trip_button {
    box-shadow: ${colorPalette.boxShadowPrimary};
    /* flex: 1; */
    padding: 1rem 2rem;
    width: 32%;
    font-size: ${typography.heading3};
    font-weight: ${FontWeight.bold};
    /* margin-left: 2rem; */
    /* background-color: ; */
    /* border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px; */
    border: none;
    outline: none;
    /* color: white; */
    cursor: pointer;
  }
  .trip_list_constainer {
    /* background-color: rebeccapurple; */
    height: 70vh;
    width: 95%;
    padding: 0.5rem 0rem;
    margin: 0 auto;
    overflow-y: scroll;
  }
  .Left_details_wrapper_payments {
    background-color: ${colorPalette.primaryColor};
    /* background-color: aqua; */
  }
  .payments_card {
    width: 95%;
    height: 50%;
    cursor: pointer;
    margin: 0.8rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 1rem;
    border-radius: 20px;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
  .payment_section1 {
    flex: 3;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* padding: .5rem 0rem; */
    /* background-color: rgb(147, 183, 214); */
  }
  .payment_section2 {
    flex: 8;
    height: 100%;
    padding: 0.5rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    /* border: 1px solid black; */
    /* background-color: rgb(95, 74, 14); */
  }
  .payment_section3 {
    flex: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    /* background-color: rgb(30, 82, 127); */
  }
  .payment_img {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .pay_payment {
    outline: none;
    border: none;
    width: 90%;
    margin: 0 auto;
    background-color: ${colorPalette.primaryColor};
    padding: 0.3rem 0.6rem;
    color: white;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    cursor: pointer;
  }
  .monthly_valuesss {
    color: ${colorPalette.paragraphColor};
    position: absolute;
    left: 10rem;
    width: 100%;
  }
  /* .search_modal{
    margin-top: 6%;
} */
  #search_modal {
    width: 90%;
  }
  .trip_code {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-size: ${typography.paragraph};
    width: 100%;
  }
  .monthly_key {
    font-size: ${typography.paragraph_secondary};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  @media only screen and (max-width: 480px) {
    .monthly_key {
      font-size: ${typography.paragraph_secondary};
      color: ${(props) =>
        props.mode ? colorPaletteDark.white : colorPalette.black};
    }
    .search_modal {
      margin-top: 2%;
      width: 80%;
      margin: 0 auto;
    }
    .monthly_valuesss {
      color: grey;
      position: absolute;
      left: 8rem;
      width: 100%;
    }
    .trip_buttons_div {
      width: 100%;
      height: 8%;
      margin-top: 1rem;
      /* background-color: ${colorPalette.primaryColor}; */
      /* margin: 0 auto; */
      display: flex;
      align-items: center;
      justify-content: flex-start;
      /* background-color: rebeccapurple; */
    }
    .search_modal {
      margin-top: 11%;
      /* margin-left: 6.8%; */
      width: 100%;
    }
    #search_modal {
      width: 90%;
    }
    .monthly_wise_filter {
      width: 100%;
      margin: 0.5rem auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .monthly_wise_filter_mobile {
      width: 100%;
      margin: 0.5rem auto;
      display: flex;
      height: 5%;
      align-items: center;
      justify-content: flex-end;
    }
    .date_picker_div {
      flex: 4;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .vehicle_number_div {
      flex: 4;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .date_picker {
      height: 1rem;
      width: 30%;
    }
    .monthly_wise_buttons {
      flex: 2;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .monthly_wise_buttons_mobile {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .trip_button {
      box-shadow: ${colorPalette.boxShadowPrimary};
      /* flex: 1; */
      padding: 1rem 1rem;
      width: 90%;
      font-size: ${typography.heading4};
      font-weight: ${FontWeight.bold};
      /* margin-left: 2rem; */
      /* background-color: ; */
      /* border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px; */
      border: none;
      outline: none;
      /* color: white; */
      cursor: pointer;
    }
    .trip_list_constainer {
      /* background-color: rebeccapurple; */
      height: 80vh;
      width: 95%;
      padding: 0.5rem 0rem;
      margin: 0 auto;
      overflow-y: scroll;
    }
    .Left_details_wrapper_payments {
      /* background-color: aqua; */
    }
    .payments_card {
      width: 98%;
      height: 35%;
      cursor: pointer;
      margin: 0.8rem auto;
      /*background-color: aquamarine;
        */
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.8rem 1rem;
      border-radius: 20px;
      background-color: ${(props) =>
        props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
      /* box-shadow: ${colorPalette.boxShadowPrimary}; */
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
    }
    .payment_section1 {
      flex: 3;
      /* display: none; */
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      /* padding: .5rem 0rem; */
      /* background-color: rgb(147, 183, 214); */
    }
    .payment_section2 {
      flex: 8;
      height: 100%;
      padding: 0.5rem 0rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      /* border: 1px solid black; */
      /* background-color: rgb(95, 74, 14); */
    }
    .payment_section3 {
      flex: 3;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      /* background-color: rgb(30, 82, 127); */
    }
    .payment_img {
      width: 100%;
      height: 70%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
    .pay_payment {
      outline: none;
      border: none;
      width: 90%;
      margin: 0 auto;
      background-color: ${colorPalette.primaryColor};
      padding: 0.3rem 0.6rem;
      color: ${colorPalette.white};
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      cursor: pointer;
    }
    .trip_code {
      color: ${(props) =>
        props.mode ? colorPaletteDark.white : colorPalette.black};
      font-size: ${typography.paragraph};
      width: 100%;
    }
  }
`;
