import {
  React,
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import * as yup from "yup";
import {
  addCustomerEntity,
  createCreditApi,
  customerCreditExport,
  customersEdit,
  customersPost,
  editCustomerEntity,
  getCustomerCreditById,
  updateCreditApi,
} from "./CustomersServices";
import EditIcon from "@mui/icons-material/Edit";
import { customersList } from "./CustomersServices";
import SearchIcon from "@mui/icons-material/Search";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import { Checkbox, InputAdornment, Modal, Snackbar } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
import { Drawer } from "@mui/material";
import loader from "../../assets/dashboard_icons/icons8-truck.gif";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Carrier from "../../assets/dashboard_icons/icon-0.8s-215px.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { onboardRegisterPost } from "../onboard/OnboardServices";
import axios from "axios";
import UploadImage from "../../assets/upload.svg";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { Component_style } from "../../themes/component_style";
import { useHistory } from "react-router-dom";
import apiUrl from "../../url/apiUrl";
import { searchData } from "../../redux/searchSlice";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { onboardData } from "../../redux/onboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { getCarrierList } from "../onboard/OnboardServices";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { oemPost } from "../oem/OemServices";
import { getOemList } from "../oem/OemServices";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import CopyText from "../../components/copyText/CopyText";
import axiosInterceptor from "../../config/https";
import ImageComponents from "../../components/imageComponent/ImageCompoents";
import { Add, Cancel, Close } from "@mui/icons-material";
import customSnackbarToast from "../../components/customSnackbarToast/customSnackbarToast";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import imageloader from "../../assets/tripIcons/ZKZg.gif";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";
import {
  ButtonPrimary,
  ButtonPrimary2,
  ButtonPrimary3,
  ButtonRectangle,
  ButtonRectangleSecondary,
  ButtonSecondary2,
} from "../../themes/components/button";
import Success from "../../components/customSnackbarToast/customSnackbarToast";
import Topbar from "../../components/topbar/Topbar.jsx";
import { DarkModeContext } from "../../context/darkModeContext.js";
import { green } from "@mui/material/colors";
import { CustomersModal } from "./customer.js";
import { CreateNewUser } from "../warehouses/warehouse-list.js";
import { GetAddressFromPincode } from "../ftl_trips/createTrip/createTripServices.js";
import { getUserTypeEnums } from "../warehouses/warehousesServices.js";
import NotAuthorized from "../../components/notAuthorized/NotAuthorized.jsx";
import SomethingWentWrong from "../../components/somethingWentWrong/SomethingWentWrong.jsx";
import {
  DatePicker,
  Input,
  Modal as ModalAntd,
  Popover,
  Alert as AntdAlert,
} from "antd";
import { readLoginData } from "../../helper/dataDecryptionHelper.js";
import { MyContext } from "../../components/enumContext/EnumContextComponent.jsx";
import { saveBlob } from "../../utility/saveBlobImage.js";
import { showOrDownlaodAttachmentsUtility } from "../../utility/FetchImage.js";
import showIcons from "../../assets/tripIcons/view.svg";
import downloadIcons from "../../assets/tripIcons/download.svg";
import ImageWithZoom from "../../components/ImageWithZoom/ImageWithZoom.jsx";
import { imageModalStyle, pdfEmbedStyle } from "../../themes/commonStyle.js";
import Pincode from "../../components/countryBasePincode/Pincode.jsx";

function Customers() {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const { enums } = useContext(MyContext);
  const [remarks, setRemarks] = useState("");
  const [customers, setCustomers] = useState("");
  const [cinNumber, setCinNumber] = useState("");
  const [cinNumberError, setCinNumberError] = useState("cinerror");
  const [customerCode, setCustomerCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorUnuthorizedMsg, setErrorUnuthorizedMsg] = useState("");
  const [success, setSuccess] = useState("");
  const [oemResponse, setOemResponse] = useState({});
  const [customersData, setCustomersData] = useState([]);
  const [loadingButtonUser, setLoadingButtonUser] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editModeCustomer, setEditModeCustomer] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [loadingCreditButton, setLoadingCreditButton] = useState(false);
  const [errorOemName, setErrorOemName] = useState("");
  const [errorCin, setErrorCin] = useState({});
  const [errorLocation, setErrorLocation] = useState({});
  const [errorPrimaryDetails, setErrorPrimaryDetails] = useState({});
  const [errorSecondaryDetails, setErrorSecondaryDetails] = useState({});
  const [isUserNotAuthorized, setIsUserNotAuthorized] = useState(false);

  const [errorLocationName, setErrorLocationName] = useState("");
  const [errorLocationPostcode, setErrorLocationPostcode] = useState("");
  const [errorLocationGst, setErrorLocationGst] = useState("");
  const [errorLocationVillage, setErrorLocationVillage] = useState("");

  const [errorPhoto, setErrorPhoto] = useState({});
  const [addCreditMode, setAddCreditMode] = useState(false);
  const [buttonMode, setButtonMode] = useState(false);

  const [loadingButton, setLoadingButton] = useState(false);
  const [errCreateUser, setErrCreateUser] = useState("");
  const [loadingMore, setLoadingMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [searchValue, setSearchValue] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [id, setId] = useState("");
  const [is400Error, setIs400Error] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = useState({ right: false });
  const [locationDrawer, setLocationDrawer] = useState({ right: false });
  const [wareHouseDrawer, setWareHouseDrawer] = useState({ right: false });
  const observer = useRef();
  const [customersPhoto, setCustomersPhoto] = useState({});
  const [customersAgreement, setCustomersAgreement] = useState({});
  const [customerCreditExportEmail, setCustomerCreditExportEmail] =
    useState("");
  const [customerCreditExportEmailError, setCustomerCreditExportEmailError] =
    useState("");
  const [exportButtonLoading, setExportButtonLoading] = useState(false);
  const [isCreateButtonClicked, setIsCreateButtonClicked] = useState(false);
  const [openToastSuccess, setOpenToastSuccess] = useState(false);
  const [openToastError, setOpenToastError] = useState(false);
  const [creditEditMode, setCreditEditMode] = useState(false);
  const [editCreditId, setEditCreditId] = useState(null);
  const [creditList, setCreditList] = useState([]);
  const [sourceVillagesList, setSourceVillagesList] = useState([]);
  const [addressFromPincode, setAddressFromPincode] = useState({});
  const [isReverseChargeMechanismChecked, setIsReverseChargeMechanismChecked] =
    useState(false);
  const [addressDetails, setAddressDetails] = useState({
    name: "",
    postcode: "",
    village: "",
    gstin_number: "",
    landmark: "",
  });
  const [userTypeList, setUserTypeList] = useState([]);

  const [imageUrl, setImageUrl] = useState("");
  const [imageUrlPdf, setImageUrlPdf] = useState("");
  const locationDetails = {
    ...addressDetails,
    postoffice: addressFromPincode.postoffice,
    district: addressFromPincode.district,
    state: addressFromPincode.state,
    sub_district: addressFromPincode.sub_district,
  };

  const [userDetails, setUserDetails] = useState({
    first_name: "",
    emp_code: "",
    mobile_number: "",
    designations: "",
    emp_code: "",
    last_name: "",
    email: "",
    user_type: "0",
    is_active: "true",
    user_name: "",
    department: "",
  });

  const [userDetailsSecondary, setUserDetailsSecondary] = useState({
    first_name: "",
    emp_code: "",
    mobile_number: "",
    designations: "",
    emp_code: "",
    last_name: "",
    email: "",
    user_type: "0",
    is_active: "true",
    user_name: "",
    department: "",
  });
  //
  const searchTerm = useSelector((data) => data.searchValues.searchItem);
  const [customerCreditForm, setCustomerCreditForm] = useState({
    credit_limit: "",
    credit_period: "",
  });
  const [creditMsg, setCreditMsg] = useState("");

  const vertical = "bottom";
  const horizontal = "right";
  const cinNumberRegex =
    /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
  const lastItem = useCallback(
    (node) => {
      // if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage]
  );

  // const handleCustomers = async () => {
  //   handleCloseBtnLocation();
  //   // if(oemResponse.state==)
  // };
  const [addQuoteSuccessToast, setAddQuoteSuccessToast] = useState(false);
  const [currentDrawer, setCurrentDrawer] = useState("");
  const [pincodeNotServiceable, setPincodeNotServiceable] = useState("");
  const [primaryCustomerDetails, setPrimaryCustomerDetails] = useState({});
  const [secondaryCustomerDetails, setSecondaryCustomerDetails] = useState({});
  const [customerEntityEditMode, setCustomerEntityEditMode] = useState(false);
  const [openTripDocs, setOpenTripDocs] = useState(false);
  const handleOpenImage = () => setOpenTripDocs(true);
  const [loadingImage, setLoadingImage] = useState(false);
  const handleCloseImage = () => setOpenTripDocs(false);
  const [selectedEntityCustomerIndex, setSelectedEntityCustomerIndex] =
    useState("");
  const [entityError, setEntityError] = useState("");
  const [customerEntityData, setCustomerEntityData] = useState([
    {
      entity_type: "",
      customer_id: "",
      year: "",
      series_prefix: "",
      id: "",
    },
  ]);

  useEffect(() => {
    if (
      selectedCustomer?.customer_entity_series &&
      selectedCustomer?.customer_entity_series.length > 0
    ) {
      setCustomerEntityData([]);
      setCustomerEntityEditMode(false);
      setSelectedEntityCustomerIndex(0);
      selectedCustomer.customer_entity_series?.map((item) => {
        setCustomerEntityData((entityObj) => [
          ...entityObj,
          {
            entity_type: item.entity_type,
            customer_id: item.customer_id,
            year: item.year,
            series_prefix: item.series_prefix,
            id: item.id,
          },
        ]);
      });
    } else {
      setCustomerEntityEditMode(true);
      setSelectedEntityCustomerIndex(0);
      setCustomerEntityData([
        {
          entity_type: "indent",
          customer_id: selectedCustomer?.id,
          year: "",
          series_prefix: "",
          id: "",
        },
      ]);
    }
  }, [selectedCustomer]);

  const handleCloseToastSuccess = () => {
    setOpenToastSuccess(false);
    setCreditMsg("");
  };

  const handleCloseToastError = () => {
    setOpenToastError(false);
    setCreditMsg("");
  };

  const handleGetCustomerCreditDetails = async (customerId) => {
    const response = await getCustomerCreditById(customerId);

    if (
      response?.status === 200 &&
      response.data?.customer_credits_list?.length > 0
    ) {
      setCreditList(response.data?.customer_credits_list);
      setEditCreditId(response.data?.customer_credits_list[0]?.id);
    } else {
      setEditCreditId(null);
    }
  };

  const handleSubmitCredit = (customerId) => {
    setLoadingCreditButton(true);
    if (creditEditMode) {
      //
      handleUpdateCreditDetails(customerId);
    } else {
      handleAddCreditDetails(customerId);
    }
  };

  useEffect(() => {
    setUserTypeList(enums?.user_type || []);
  }, [enums]);
  useEffect(() => {
    setCustomerCreditExportEmail(readLoginData().USER_EMAIL);
  }, []);
  const handleUpdateCreditDetails = async (customerId) => {
    const response = await updateCreditApi(
      customerId,
      customerCreditForm?.credit_limit,
      customerCreditForm?.credit_period
    );
    setLoadingCreditButton(false);
    setCurrentPage(1);
    getCustomersListData(1);

    if (response && response.status === 200) {
      handleCloseBtn();
      setOpenToastSuccess(true);
      setCreditMsg("Successfully updated customer credit terms");
    } else if (response?.status === 400) {
      setOpenToastError(true);
      setCreditMsg(response?.data?.detail);
    } else if (response?.status === 401) {
      toast.error("Not enough permissions");
    } else {
      setOpenToastError(true);
      setCreditMsg(response?.data?.detail);
    }
  };

  const handleAddCreditDetails = async (customerId) => {
    const response = await createCreditApi(
      customerId,
      customerCreditForm?.credit_limit,
      customerCreditForm?.credit_period
    );

    setLoadingCreditButton(false);
    setCurrentPage(1);
    getCustomersListData(1);
    if (response && response.status === 200) {
      setOpenToastSuccess(true);
      setCreditMsg("Successfully created customer credit terms");
    } else if (response?.status === 400) {
      setOpenToastError(true);
      setCreditMsg(response?.data?.detail);
    } else if (response?.status === 401) {
      toast.error("Not enough permissions");
    } else {
      setOpenToastError(true);
      setCreditMsg(`ERROR : ${response.status}`);
    }
  };

  const handleCustomerSelect = (customer, index) => {
    handleOpenMenu();
    setButtonMode(false);
    setSuccess("");
    setSelectedCustomer(customer);
    setCustomerCreditForm({
      credit_limit: customer.credit_limit ? customer.credit_limit : 0,
      credit_period: customer.credit_period ? customer.credit_period : 0,
    });
    if (customer.credit_limit || customer.credit_limit === 0) {
      setAddCreditMode(true);
      setCreditEditMode(true);
    } else {
      setAddCreditMode(false);
    }
    setSelectedIndex(index);
    setEditMode(true);
    setId(customer.id);
    if (isMobile) {
      handleOpenMenu();

      setButtonMode(false);
      setSuccess("");
      setSelectedCustomer(customer);
      setCustomerCreditForm({
        credit_limit: customer.credit_limit ? customer.credit_limit : 0,
        credit_period: customer.credit_period ? customer.credit_period : 0,
      });
      if (customer.credit_limit || customer.credit_limit === 0) {
        setAddCreditMode(true);
        setCreditEditMode(true);
      } else {
        setAddCreditMode(false);
      }
      setSelectedIndex(index);
      setEditMode(true);
      setId(customer.id);
    }
  };

  const [errorUserName, setErrorUserName] = useState({});
  const [errorUserNameScondary, setErrorUserNameScondary] = useState({});

  const formValidationUserName = () => {
    const errorUserName = {};
    const userNameRegex = /(^[a-z]+([_\-0-9a-z]{0,}){1,}$)/;

    let isValid = true;
    if (
      currentDrawer == "primary_details" &&
      !userNameRegex.test(userDetails.user_name)
    ) {
      errorUserName.errorUser =
        "User Name should only contain lower case letter.";
      isValid = false;
    }
    if (
      currentDrawer == "secondary_details" &&
      !userNameRegex.test(userDetailsSecondary.user_name)
    ) {
      errorUserNameScondary.errorUserSec =
        "User Name should only contain lower case letter.";
      isValid = false;
    }

    setErrorUserName(errorUserName);
    setErrorUserNameScondary(errorUserNameScondary);
    return isValid;
  };

  const formValidation = () => {
    const errorOemName = {};

    const errorCin = {};
    const errorPhoto = {};
    const errorLocation = {};
    const errorPrimaryDetails = {};
    const errorSecondaryDetails = {};
    let isValid = true;
    if (customers == "") {
      errorOemName.errorOem = "Customer name is mandatory.";
      isValid = false;
    }
    if (cinNumber == "") {
      errorCin.erroCinNumber = "CIN is mandatory.";
      isValid = false;
    }
    if (locationDetails.postcode == "") {
      errorLocation.erroEmptyLocation = "Please add customer location.";
      isValid = false;
    }
    if (Object.keys(primaryCustomerDetails).length == 0) {
      errorPrimaryDetails.erroEmptyPrimary = "Please add Primary details.";
      isValid = false;
    }
    if (Object.keys(secondaryCustomerDetails).length == 0) {
      errorSecondaryDetails.erroEmptySecondary =
        "Please add Secondary details.";
      isValid = false;
    }
    if (editMode == false) {
      if (customersPhoto.name == undefined) {
        errorPhoto.Photomissing = "Customer logo is missing";
        isValid = false;
      }
    }
    setErrorOemName(errorOemName);
    setErrorPhoto(errorPhoto);
    setErrorCin(errorCin);
    setErrorLocation(errorLocation);
    setErrorPrimaryDetails(errorPrimaryDetails);
    setErrorSecondaryDetails(errorSecondaryDetails);
    return isValid;
  };
  const formValidationForLocation = () => {
    const errorLocationName = {};
    const errorLocationPostcode = {};
    const errorLocationGst = {};
    const errorLocationVillage = {};
    let isValid = true;
    if (addressDetails.name == "") {
      errorLocationName.errorLocation = "Name is mandatory.";
      isValid = false;
    }
    if (addressDetails.postcode == "") {
      errorLocationPostcode.errorpostcodeLocation = "Pincode is mandatory.";
      setPincodeNotServiceable("Pincode is mandatory.");
      isValid = false;
    }
    if (addressDetails.village == "") {
      errorLocationVillage.errorpostcodeLocationVillage =
        "Please select village.";
      isValid = false;
    }

    const gstinRegex =
      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z|1-9|A-J|S]{1}[0-9A-Z]{1})$/;

    if (addressDetails.gstin_number == "") {
      errorLocationGst.errorGstinLocation = "GSTIN is mandatory.";
      isValid = false;
    }
    if (
      !gstinRegex.test(addressDetails.gstin_number) &&
      addressDetails.gstin_number !== ""
    ) {
      errorLocationGst.errorGstinLocation = "Invalid GSTIN.";
      isValid = false;
    }
    setErrorLocationName(errorLocationName);
    setErrorLocationPostcode(errorLocationPostcode);
    setErrorLocationGst(errorLocationGst);
    setErrorLocationVillage(errorLocationVillage);
    return isValid;
  };
  const [invalidAttachment, setInvalidAttachment] = useState("");
  const WarehouseUserSchema = yup.object().shape({
    first_name: yup.string().required(),
    designation: yup.string().required(),
    employee_code: yup.string().required(),
    mobile_number: yup.number().min(5000000000).max(9999999999).required(),
    email: yup.string().email(),
    user_type: yup.string().min(2).required(),
  });
  const WarehouseUsersFormData = {
    first_name: userDetails.first_name,
    designation: userDetails.designations,
    employee_code: userDetails.emp_code,
    mobile_number: userDetails.mobile_number,
    email: userDetails.email,
    user_type: userDetails.user_type,
    user_name: userDetails.user_name,
  };

  const [errWarehouseUserDetails, setErrWarehouseUserDetails] = useState({
    errFirstName: "",
    errDesignation: "",
    errEmployeeCode: "",
    errMobileNumber: "",
    errEmail: "",
    errUserType: "",
    errUserName: "",
  });
  const WarehouseUsersFormDataSecondary = {
    first_name: userDetailsSecondary.first_name,
    designation: userDetailsSecondary.designations,
    employee_code: userDetailsSecondary.emp_code,
    mobile_number: userDetailsSecondary.mobile_number,
    email: userDetailsSecondary.email,
    user_type: userDetailsSecondary.user_type,
    user_name: userDetailsSecondary.user_name,
  };
  const [
    errWarehouseUserDetailsSecondary,
    setErrWarehouseUserDetailsSecondary,
  ] = useState({
    errFirstName: "",
    errDesignation: "",
    errEmployeeCode: "",
    errMobileNumber: "",
    errEmail: "",
    errUserType: "",
    errUserName: "",
  });

  const handleWarehouseUser = async () => {
    const isValidUser = formValidationUserName();

    const isWarehouseValidPrimary = await WarehouseUserSchema.isValid(
      WarehouseUsersFormData
    );
    const isWarehouseValidSecondary = await WarehouseUserSchema.isValid(
      WarehouseUsersFormDataSecondary
    );

    if (
      isValidUser &&
      isWarehouseValidPrimary &&
      currentDrawer == "primary_details"
    ) {
      setPrimaryCustomerDetails(userDetails);

      toggleWareHouseUserDrawer("right", false);
      setErrWarehouseUserDetails({
        errFirstName: "",
        errDesignation: "",
        errEmployeeCode: "",
        errMobileNumber: "",
        errEmail: "",
        errUserType: "",
        errUserName: "",
      });
      setErrWarehouseUserDetailsSecondary({
        errFirstName: "",
        errDesignation: "",
        errEmployeeCode: "",
        errMobileNumber: "",
        errEmail: "",
        errUserType: "",
        errUserName: "",
      });
    } else {
      setErrWarehouseUserDetailsSecondary({
        errFirstName: "Please enter first name",
        errDesignation: "Please enter valid designation",
        errEmployeeCode: "Please enter valid employee code",
        errMobileNumber: "Please enter valid mobile number",
        errEmail: "Please enter valid email id",
        errUserType: "Please enter valid user type",
        errUserName: "Please enter user name",
      });
    }
    if (
      isValidUser &&
      isWarehouseValidSecondary &&
      currentDrawer == "secondary_details"
    ) {
      setSecondaryCustomerDetails(userDetailsSecondary);

      toggleWareHouseUserDrawer("right", false);
      setErrWarehouseUserDetailsSecondary({
        errFirstName: "",
        errDesignation: "",
        errEmployeeCode: "",
        errMobileNumber: "",
        errEmail: "",
        errUserType: "",
        errUserName: "",
      });
      setErrWarehouseUserDetails({
        errFirstName: "",
        errDesignation: "",
        errEmployeeCode: "",
        errMobileNumber: "",
        errEmail: "",
        errUserType: "",
        errUserName: "",
      });
    } else {
      setErrWarehouseUserDetails({
        errFirstName: "Please enter first name",
        errDesignation: "Please enter valid designation",
        errEmployeeCode: "Please enter valid employee code",
        errMobileNumber: "Please enter valid mobile number",
        errEmail: "Please enter valid email id",
        errUserType: "Please enter valid user type",
        errUserName: "Please enter user name",
      });
    }
  };

  const handleChangeCredit = (e) => {
    const creditTemp = { ...customerCreditForm };
    creditTemp[e.target.name] = e.target.value;

    setCustomerCreditForm(creditTemp);
  };

  // const getCustomersListData = async (current) => {
  //   setLoading(true);
  //   const customersList = await customersList(current);
  //
  //   if (current > 1) {
  //     setCustomersData([...customersData, ...customersList.customers_list]);
  //     setIsLastPage(customersList.is_last_page);
  //   } else {
  //     setCustomersData(customersList.customers_list);
  //     setIsLastPage(customersList.is_last_page);
  //   }
  //   setLoading(false);
  // };
  const getCustomersListData = async (current) => {
    setLoading(true);
    const customersListData = await customersList(current);
    if (customersListData.status === 200) {
      if (current > 1) {
        setCustomersData([
          ...customersData,
          ...customersListData.data.customers_list,
        ]);
        setIsLastPage(customersListData.data.is_last_page);
      } else {
        setCustomersData(customersListData.data.customers_list);
        setIsLastPage(customersListData.data.is_last_page);
      }
      setLoading(false);
    }
    if (customersListData.status === 401) {
      toast.error("Not enough permissions");
    }
  };

  useEffect(() => {
    if (searchTerm) {
      handleSearchCustomers(searchTerm, currentPage);
      dispatch(searchData(searchTerm));
    } else {
      dispatch(searchData(""));
      getCustomersListData(currentPage);
    }
  }, [currentPage, searchTerm]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      dispatch(searchData(searchTerm));
    }
  }, [searchTerm]);
  // useEffect(() => {
  //   getCustomersListData(currentPage);
  // }, [currentPage]);

  useEffect(() => {
    if (Object.keys(selectedCustomer).length > 0) {
      // setEditMode(true);

      if (selectedCustomer.customer_code != null) {
        setCustomerCode(selectedCustomer.customer_code);
      }
      if (selectedCustomer.description != null) {
        setRemarks(selectedCustomer.description);
        // setEditMode(true);
      }
      if (selectedCustomer.is_reverse_charge_mechanism_applicable != null) {
        setIsReverseChargeMechanismChecked(
          selectedCustomer.is_reverse_charge_mechanism_applicable
        );
        // setEditMode(true);
      }
      if (selectedCustomer.cin != null) {
        setCinNumber(selectedCustomer.cin);
        // setEditMode(true);
      } else {
        setRemarks("");
      }

      setCustomers(selectedCustomer.name);
    }
  }, [selectedCustomer]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleSearchCustomers = async (values, page) => {
    if (values && values.length >= 1) {
      try {
        // setLoading(true);
        const productResponse = await axiosInterceptor.get(
          `${
            apiUrl.onboardingBaseUrl
          }/v2/customers?name=${values.trim()}&current_page=${page}`
        );

        if (productResponse.data.customers_list.length > 0) {
          setCustomersData(productResponse.data.customers_list);

          setIsLastPage(productResponse.data.is_last_page);
        } else {
          setCustomersData([]);
        }
      } catch (error) {
        setCustomersData([]);
      }
    }
  };
  const handlecancelSearch = () => {
    dispatch(searchData(""));
    getCustomersListData(1);
    setCurrentPage(1);
  };
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  useEffect(() => {}, []);

  function changehandle(e) {
    setAddressDetails({
      ...addressDetails,
      [e.target.name]: e.target.value,
    });
  }

  const handlePincode = (data, errorMsg) => {
    if (data) {
      setErrorLocationPostcode({});
      setPincodeNotServiceable("");
      setAddressDetails({
        ...addressDetails,
        ["postcode"]: data,
      });
    }
    if (errorMsg) {
      setPincodeNotServiceable(errorMsg);
    } else {
      handleGetAddressSource(data);
    }
  };
  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };
  const toggleLocationDrawer = (anchor, open) => {
    setLocationDrawer({ ...locationDrawer, [anchor]: open });
  };
  const toggleWareHouseUserDrawer = (anchor, open) => {
    setWareHouseDrawer({ ...wareHouseDrawer, [anchor]: open });
  };
  const handleOpenWarehouseUserDrawer = () => {
    toggleWareHouseUserDrawer("right", true);
    setSuccess("");
    setErrorMsg("");
    setErrorPhoto({});
    setErrorOemName("");
    // setCustomersPhoto({});
    // setCustomersAgreement({});
    // setRemarks("");

    setEditMode(false);
    // setRemarks("");
    // setCustomers("");
    // setSelectedIndex(null);
    setAddCreditMode(false);
  };
  const handleCloseBtnWarehouseUser = () => {
    toggleWareHouseUserDrawer("right", false);
    setErrorUserName({});
    setErrorUserNameScondary({});
    setMinCharacterUserName("");
    setErrWarehouseUserDetails({
      errFirstName: "",
      errDesignation: "",
      errEmployeeCode: "",
      errMobileNumber: "",
      errEmail: "",
      errUserType: "",
      errUserName: "",
    });
    setErrWarehouseUserDetailsSecondary({
      errFirstName: "",
      errDesignation: "",
      errEmployeeCode: "",
      errMobileNumber: "",
      errEmail: "",
      errUserType: "",
      errUserName: "",
    });
    // setErrorPhoto({});
    // setRemarks("");
    // setCustomers("");
    setSelectedIndex(null);
    setSelectedCustomer({});
  };
  const handleOpenLocationDrawer = () => {
    toggleLocationDrawer("right", true);
    // setSuccess("");
    // setErrorMsg("");
    // setErrorPhoto({});
    // setErrorOemName("");
    // setCustomersPhoto({});
    // setRemarks("");

    setEditMode(false);
    // setRemarks("");
    // setCustomers("");
    // setSelectedIndex(null);
    setAddCreditMode(false);
  };
  const handleCloseBtnLocation = () => {
    toggleLocationDrawer("right", false);
    setErrorLocationName("");
    setErrorLocationPostcode("");
    setErrorLocationGst("");
    setErrorLocationVillage("");
    setPincodeNotServiceable("");
    // setErrorPhoto({});
    // setRemarks("");
    // setCustomers("");
    // setSelectedIndex(null);
    // setSelectedCustomer({});
  };
  const handleOpenMenu = () => {
    toggleDrawer("right", true);
    setSuccess("");
    setErrorMsg("");
    setErrorPhoto({});
    setErrorOemName("");
    // setCustomersPhoto({});
    setRemarks("");
    // setCustomersAgreement({});

    setEditMode(false);
    setRemarks("");
    setCustomers("");
    // setSelectedIndex(null);
    setAddCreditMode(false);
  };
  const handleCloseBtn = () => {
    setIsCreateButtonClicked(false);
    toggleDrawer("right", false);
    setErrorPhoto({});
    setRemarks("");
    setCustomers("");
    setErrorCin({});
    setErrorLocation({});
    setErrorPrimaryDetails({});
    setErrorSecondaryDetails({});
    setCustomersPhoto({});
    setCustomersAgreement({});
    setSelectedIndex(null);
    setEditModeCustomer(false);
    setSelectedCustomer({});
    setEditModeCustomer(false);
    setCinNumber("");
    setAddressDetails({
      name: "",
      postcode: "",
      village: "",
      gstin_number: "",
    });

    setAddressFromPincode({});
    setPrimaryCustomerDetails({});
    setUserDetailsSecondary({
      first_name: "",
      emp_code: "",
      mobile_number: "",
      designations: "",
      emp_code: "",
      last_name: "",
      email: "",
      user_type: "0",
      is_active: "true",
      user_name: "",
    });
    setUserDetails({
      first_name: "",
      emp_code: "",
      mobile_number: "",
      designations: "",
      emp_code: "",
      last_name: "",
      email: "",
      user_type: "0",
      is_active: "true",
      user_name: "",
    });
    setSecondaryCustomerDetails({});
    // setSecondaryCustomerDetails(userDetailsSecondary);
    setInvalidAttachment("");
    setIsCreateButtonClicked(false);
  };
  const onFileChange = async (event) => {
    const newFile = await saveBlob(event.target.files[0]);
    setCustomersPhoto(newFile);
    setErrorPhoto("");
    // setErrorPhoto({});
  };

  const onFileChangeAgreement = async (event) => {
    const newFile = await saveBlob(event.target.files[0]);
    setCustomersAgreement(newFile);
    if (newFile?.type !== "application/pdf") {
      setInvalidAttachment("*Invalid file format");
    } else {
      setInvalidAttachment("");
    }

    setErrorPhoto("");
    // setErrorPhoto({});
  };

  const handleGetAddressSource = async (pincode) => {
    //
    const response = await GetAddressFromPincode(pincode);
    if (response) {
      setAddressFromPincode({
        district: response.district,
        sub_district: response.district,
        state: response.state,
        postoffice: response.postoffice,
      });

      // const source = sourceDetails;
      // source[index]["district"] = response.district;
      // source[index]["sub_district"] = response.sub_district;
      // source[index]["state"] = response.state;
      // source[index]["postoffice"] = response.office[0].name;
      // source[index]["postcode"] = response.pincode;

      setSourceVillagesList(response.area);
    } else {
      setPincodeNotServiceable("This pincode is not serviceable.");
    }
  };

  const handleCustomers = async () => {
    const isValid = formValidation();

    // setErrorOemName("");
    if (editMode == true) {
      setLoadingButton(true);
      const responseEdit = await customersEdit(
        customers,
        remarks,
        cinNumber,
        customersPhoto,
        customersAgreement,
        id,
        isReverseChargeMechanismChecked
      );

      if (responseEdit.status == 200) {
        // setEditMode(false);
        getCustomersListData(1);
        setCurrentPage(1);
        setCustomers("");
        setRemarks("");
        setCinNumber("");
        setErrorMsg("");
        setCustomersAgreement({});
        setLoadingButton(false);
        setSuccess("Customer Updated successfully!");
      } else if (responseEdit.status == 403) {
        localStorage.clear();
        window.location.reload();
      } else if (responseEdit.status == 400) {
        setLoadingButton(false);
        setErrorMsg(responseEdit.data.detail);
      } else if (responseEdit.status == 401) {
        setLoadingButton(false);
        toast.error("Not enough permissions");
      }
    } else {
      setLoadingButton(true);
      // name,
      // cin,
      // logo,
      // location,
      // primary_contact,
      // secondary_contact,
      // description,
      // agreement
      if (isValid) {
        const customersResponse = await customersPost(
          customers,
          cinNumber,
          customersPhoto,
          locationDetails,
          primaryCustomerDetails,
          secondaryCustomerDetails,
          remarks,
          customersAgreement,
          isReverseChargeMechanismChecked
        );
        console.log(customersResponse, "customersResponse");
        if (customersResponse == 200) {
          setErrorMsg("");
          setSuccess("Customer created successfully!");
          setErrorMsg("");
          handleCloseBtn();
          setCustomersAgreement({});
          // getOemListData(1);
          setLoadingButton(false);
          // toggleDrawer("right", false);
          //   setSuccess("");
          setCustomers("");
          setCustomersPhoto({});

          setCinNumber("");
          setCustomersAgreement({});
          setRemarks("");
          getCustomersListData(1);
          setCurrentPage(1);
        } else if (customersResponse.status == 400) {
          setLoadingButton(false);
          setErrorMsg(customersResponse.data.detail);

          setSuccess("");
          // setCustomers("");
        } else if (customersResponse.status == 401) {
          setLoadingButton(false);
          toast.error("Not enough permissions");
        } else {
          setLoadingButton(false);
        }
      }
    }

    setLoadingButton(false);
    // if(oemResponse.state==)
  };
  const handleSubmitLocation = () => {
    const isValidLocation = formValidationForLocation();
    if (isValidLocation) {
      handleCloseBtnLocation();
    }
  };
  const handleEditCustomer = () => {
    setEditModeCustomer(true);
  };

  const handleEntityInputChange = (e, index) => {
    const { name, value } = e.target;
    const obj = [...customerEntityData];

    obj[index] = { ...obj[index], [name]: value };

    setCustomerEntityData(obj);
  };

  const handleCustomerEntitySeries = async (data) => {
    setLoading(true);
    try {
      const response = await editCustomerEntity(data);
      if (response.status === 401) {
        toast.error("Not enough permissions");
      } else if (response) {
        setCustomerEntityEditMode(false);
        setCustomerEntityData(
          selectedCustomer?.customer_entity_series.map((item) => {
            return {
              customer_id: item.customer_id,
              entity_type: item.entity_type,
              series_prefix: item.series_prefix,
              year: item.year,
              id: item.id,
            };
          })
        );
        getCustomersListData(1);
        toggleDrawer("right", false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const [minCharacterUserName, setMinCharacterUserName] = useState("");

  const handleCloseToast = () => {
    // setQuoteToastError("");
    setAddQuoteSuccessToast(false);
    setOpenToast(false);
    // setAddQuoteErrorToast(false);
  };

  const handleExportCustomerCredit = async () => {
    try {
      setExportButtonLoading(true);
      const faarmsAndConnectIndiaEmailRegex =
        /\b[A-Za-z0-9._%+-]+@(faarms\.com|bscxpress\.com|connectindia\.com)\b/;

      if (faarmsAndConnectIndiaEmailRegex.test(customerCreditExportEmail)) {
        setCustomerCreditExportEmailError("");
        const response = await customerCreditExport(customerCreditExportEmail);
        if (response.status === 202) {
          setOpenToast(true);
        } else if (response.status === 401) {
          toast.error("401: Not enough permissions");
        }
      } else {
        setCustomerCreditExportEmailError("Please enter valid email");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setExportButtonLoading(false);
      setCustomerCreditExportEmail("");
    }
  };

  useEffect(() => {
    setIs400Error(sessionStorage.getItem("IS_400_ERROR"));
  }, []);

  useEffect(() => {}, [is400Error]);

  const showCustomerAttachments = async (id) => {
    try {
      setLoadingImage(true);
      const response = await showOrDownlaodAttachmentsUtility(
        id,
        "customer-agreement"
      );
      if (response.status === 401) {
        toast.error("401: Not enough permissions");
      } else if (
        response.file &&
        (response.attachmentType === "image/png" ||
          response.attachmentType === "image/jpeg")
      ) {
        setImageUrlPdf("");
        setImageUrl(response.file);
      } else {
        setImageUrl("");
        setImageUrlPdf(response.file);
      }
    } catch (error) {
    } finally {
      setLoadingImage(false);
    }
  };

  const download = async (id) => {
    try {
      const response = await showOrDownlaodAttachmentsUtility(
        id,
        "customer-agreement",
        "downloadOnly"
      );
      if (response.status === 401) {
        toast.error("401: Not enough permissions");
      }
    } catch (error) {}
  };

  return (
    <Component_style className="main_onboard_container" mode={darkMode}>
      <ToastContainer position="bottom-right" />
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseToast}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          Customer credit report exported successfully
        </Alert>
      </Snackbar>
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={9999999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong backButtonLink="/customers" buttonText="Close" />
        </div>
      </ModalAntd>
      <Modal
        open={openTripDocs}
        onClose={handleCloseImage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={imageModalStyle}>
          {loadingImage ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <img src={imageloader} alt="" width="20%" height="20%" />
              <div>Loading...</div>
            </div>
          ) : (
            <>
              {imageUrlPdf ? (
                <embed
                  src={imageUrlPdf}
                  type="application/pdf"
                  style={pdfEmbedStyle}
                ></embed>
              ) : imageUrl ? (
                <ImageWithZoom image={imageUrl} />
              ) : null}
            </>
          )}
        </Box>
      </Modal>
      <Snackbar
        open={addQuoteSuccessToast}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseToast}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseToast}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {errorUnuthorizedMsg}
        </Alert>
      </Snackbar>
      <Drawer
        className="drawer"
        anchor={"right"}
        open={state["right"]}
        onClose={() => {
          toggleDrawer("right", false);
          setSelectedCustomer({});
          setEditModeCustomer(false);
          setErrorCin({});
          setCustomersPhoto({});
          setCustomersAgreement({});
          setErrorLocation({});
          setErrorPrimaryDetails({});
          setErrorSecondaryDetails({});
          setCinNumber("");
          setAddressDetails({
            name: "",
            postcode: "",
            village: "",
            gstin_number: "",
          });
          setAddressFromPincode({});
          setPrimaryCustomerDetails({});
          setUserDetailsSecondary({
            first_name: "",
            emp_code: "",
            mobile_number: "",
            designations: "",
            emp_code: "",
            last_name: "",
            email: "",
            user_type: "0",
            is_active: "true",
            user_name: "",
          });
          setSecondaryCustomerDetails({});
          setInvalidAttachment("");
          setUserDetails({
            first_name: "",
            emp_code: "",
            mobile_number: "",
            designations: "",
            emp_code: "",
            last_name: "",
            email: "",
            user_type: "0",
            is_active: "true",
            user_name: "",
          });
          setCustomerEntityEditMode(false);
          setCustomerEntityData([
            {
              customer_id: "",
              year: "",
              entity_type: "",
              series_prefix: "",
              id: "",
            },
          ]);
          setEntityError("");
          setIsCreateButtonClicked(false);
        }}
      >
        <CustomersModal
          style={isMobile ? { width: "100vw" } : { width: "30vw" }}
          className="right_details_wrapper_customer"
          mode={darkMode}
        >
          <div className="oem_div_customer" style={{ padding: "20px" }}>
            {!isCreateButtonClicked ? (
              <div>
                <div
                  style={{
                    color: colorPalette.primaryColor,
                    fontWeight: FontWeight.medium,
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Transaction Series{" "}
                  <div>
                    {" "}
                    <CloseIcon
                      style={{
                        color: darkMode ? colorPaletteDark.white : "",
                      }}
                      onClick={handleCloseBtn}
                    />
                  </div>
                </div>
                <div className="entity-table table-head">
                  <div>Entity</div>
                  <div>Year</div>
                  <div>Series</div>
                  <div>Action</div>
                </div>
                {customerEntityData.length > 0
                  ? customerEntityData.map((item, index) => (
                      <div className="entity-table">
                        {console.log(item, "new item ====>")}
                        <div>{item.entity_type}</div>
                        {customerEntityEditMode &&
                        selectedEntityCustomerIndex === index ? (
                          <select
                            name="year"
                            id="year"
                            defaultValue={item.year}
                            value={item.year}
                            onChange={(e) => handleEntityInputChange(e, index)}
                            style={{
                              width: "100%",
                              padding: "5px",
                              outline: "none",
                              border: "none",
                              background: "none",
                              borderBottom: "1px solid #232323",
                            }}
                          >
                            <option value="">Select Year</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                          </select>
                        ) : (
                          item.year
                        )}
                        <div>
                          {customerEntityEditMode &&
                          selectedEntityCustomerIndex === index ? (
                            <input
                              type="text"
                              name="series_prefix"
                              placeholder="Enter Series"
                              value={item.series_prefix}
                              onChange={(e) =>
                                handleEntityInputChange(e, index)
                              }
                              style={{
                                width: "100%",
                                padding: "5px",
                                outline: "none",
                                border: "none",
                                background: "none",
                                borderBottom: "1px solid #232323",
                              }}
                            />
                          ) : (
                            item.series_prefix
                          )}
                        </div>
                        <div>
                          {loading && selectedEntityCustomerIndex === index ? (
                            <CircularProgress
                              style={{
                                width: "24px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            />
                          ) : !customerEntityEditMode ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                onClick={() => {
                                  setCustomerEntityEditMode(true);
                                  setSelectedEntityCustomerIndex(index);
                                }}
                              >
                                <EditIcon style={{ cursor: "pointer" }} />
                              </div>
                            </div>
                          ) : selectedEntityCustomerIndex === index ? (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {console.log(item, "item ===>")}
                              <div
                                onClick={() => handleCustomerEntitySeries(item)}
                              >
                                <CheckCircleIcon
                                  style={{
                                    cursor: "pointer",
                                    color: colorPalette.primaryColor,
                                  }}
                                />
                              </div>
                              <div
                                onClick={() => {
                                  setCustomerEntityEditMode(false);
                                  setEntityError(false);
                                  setCustomerEntityData(
                                    selectedCustomer?.customer_entity_series.map(
                                      (item) => {
                                        return {
                                          customer_id: item.customer_id,
                                          entity_type: item.entity_type,
                                          series_prefix: item.series_prefix,
                                          year: item.year,
                                          id: item.id,
                                        };
                                      }
                                    )
                                  );
                                }}
                              >
                                <CancelIcon
                                  style={{
                                    cursor: "pointer",
                                    color: colorPalette.primaryRed,
                                  }}
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ))
                  : null}

                <div
                  style={{
                    width: "100%",
                    color: "red",
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ height: "20px" }}>
                    {entityError ? entityError : ""}
                  </div>
                </div>
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                // backgroundColor: "red",
              }}
            >
              {selectedIndex !== null ? (
                <h5 style={{ color: colorPalette.primaryColor }}>
                  Update Customer
                </h5>
              ) : (
                <h5 style={{ color: colorPalette.primaryColor }}>
                  Add New Customer
                </h5>
              )}

              <CloseIcon
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                }}
                onClick={handleCloseBtn}
              />
            </div>
            {selectedIndex !== null && !editModeCustomer ? (
              <div className="customer-onboarded-details-container">
                <div className="customer-details-onboarded-heading">
                  <span style={{ fontSize: "15px" }}>Customer Details</span>{" "}
                  {/* <span> */}
                  <EditIcon
                    onClick={handleEditCustomer}
                    style={{ fontSize: "20px", cursor: "pointer" }}
                  />
                  {/* </span>{" "} */}
                </div>
                <div className="customer-onboarded-details">
                  <div
                    style={{ wordBreak: "break-all" }}
                    // className="customer-details-values-container"
                  >
                    <h5 style={{ display: "inline" }}> Name:</h5>
                    <span className="customer-details-values">
                      {selectedCustomer.name ? selectedCustomer.name : ""}
                    </span>
                  </div>

                  <div>
                    <h5 style={{ display: "inline" }}> Code: </h5>
                    <span className="customer-details-values">
                      {selectedCustomer.customer_code
                        ? selectedCustomer.customer_code
                        : ""}
                    </span>
                  </div>

                  <div style={{ wordBreak: "break-all" }}>
                    <h5 style={{ display: "inline" }}> CIN:</h5>
                    <span className="customer-details-values">
                      {selectedCustomer.cin ? selectedCustomer.cin : "--"}
                    </span>
                  </div>

                  <div style={{ wordBreak: "break-all" }}>
                    <h5 style={{ display: "inline" }}> Description: </h5>
                    <span className="customer-details-values-description">
                      {" "}
                      {selectedCustomer.description
                        ? selectedCustomer.description
                        : "--"}
                    </span>
                  </div>

                  <div style={{ wordBreak: "break-all" }}>
                    <h5 style={{ display: "inline" }}>
                      {" "}
                      Is Reverse Charge Mechanism Applicable :{" "}
                    </h5>
                    <span className="customer-details-values-description">
                      {" "}
                      {selectedCustomer
                        ? String(
                            selectedCustomer.is_reverse_charge_mechanism_applicable
                              ? "Yes"
                              : "No"
                          )
                        : "--"}
                    </span>
                  </div>

                  {/* <h5>
                    {" "}
                    Name: {selectedCustomer.name ? selectedCustomer.name : ""}
                  </h5> */}
                </div>

                {/* {selectedCustomer.name ? selectedCustomer.name : ""} */}
              </div>
            ) : (
              <div className="form_create_customer">
                <h4 className="form_label_customers">
                  Name
                  <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  id="outlined-basic"
                  placeholder="Enter Customer's Name"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    margin: "0rem 0rem 0rem 0rem",
                    // height: "6ch",
                  }}
                  style={{
                    backgroundColor: darkMode ? colorPaletteDark.white : "",
                  }}
                  value={customers}
                  color="success"
                  size="small"
                  onChange={(e) => {
                    setCustomers(e.target.value);
                    setErrorOemName("");
                  }}
                />
                {Object.keys(errorOemName).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>{errorOemName[key]}</h6>
                  );
                })}
                <h4 className="form_label_customers">
                  CIN Number
                  <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  id="outlined-basic"
                  type="text"
                  placeholder="Enter Customer's Cin no."
                  variant="outlined"
                  sx={{
                    width: "100%",

                    // height: "6ch",
                  }}
                  style={{
                    backgroundColor: darkMode ? colorPaletteDark.white : "",
                  }}
                  value={cinNumber}
                  color="success"
                  size="small"
                  onChange={(e) => {
                    setCinNumber(e.target.value);
                    setErrorCin("");
                  }}
                />

                {Object.keys(errorCin).map((key) => {
                  return <h6 style={{ color: "#E06666" }}>{errorCin[key]}</h6>;
                })}
                {editModeCustomer ? null : (
                  <>
                    {" "}
                    <h4 className="form_label_customers">
                      Customer Location
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Customer's Location"
                      variant="outlined"
                      sx={{
                        width: "100%",

                        // height: "6ch",
                      }}
                      style={{
                        backgroundColor: darkMode ? colorPaletteDark.white : "",
                      }}
                      value={
                        locationDetails.name &&
                        locationDetails.state &&
                        locationDetails.village &&
                        locationDetails.sub_district &&
                        locationDetails.district &&
                        locationDetails.gstin_number &&
                        locationDetails.postoffice &&
                        locationDetails.sub_district
                          ? `${locationDetails.village}, ${locationDetails.sub_district},${locationDetails.landmark}, ${locationDetails.district}, ${locationDetails.state}`
                          : ""
                      }
                      color="success"
                      size="small"
                      onClick={() => {
                        handleOpenLocationDrawer();
                        setErrorLocation({});
                        // setEditMode(false);
                        // setErrorPhoto({});
                        // setRemarks("");
                        // setCustomers("");
                        // setSelectedIndex(null);
                        // setAddCreditMode(false);
                      }}
                      // onChange={(e) => {
                      //   setCustomers(e.target.value);
                      //   setErrorOemName("");
                      // }}
                    />
                    {Object.keys(errorLocation).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorLocation[key]}
                        </h6>
                      );
                    })}
                    <h4 className="form_label_customers">
                      Primary Contact Details
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Primary details"
                      variant="outlined"
                      sx={{
                        width: "100%",

                        // height: "6ch",
                      }}
                      style={{
                        backgroundColor: darkMode ? colorPaletteDark.white : "",
                      }}
                      value={
                        primaryCustomerDetails.first_name &&
                        primaryCustomerDetails.mobile_number &&
                        primaryCustomerDetails.email &&
                        primaryCustomerDetails.emp_code &&
                        primaryCustomerDetails.user_name &&
                        primaryCustomerDetails.user_type
                          ? `${primaryCustomerDetails.first_name}, ${primaryCustomerDetails.mobile_number}, ${primaryCustomerDetails.email}, ${primaryCustomerDetails.emp_code},${primaryCustomerDetails.user_name},${primaryCustomerDetails.user_type}`
                          : ""
                      }
                      color="success"
                      size="small"
                      onClick={() => {
                        setCurrentDrawer("primary_details");
                        handleOpenWarehouseUserDrawer();
                        setErrorPrimaryDetails({});
                      }}
                      // onChange={(e) => {
                      //   setCustomers(e.target.value);
                      //   setErrorOemName("");
                      // }}
                    />
                    {Object.keys(errorPrimaryDetails).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorPrimaryDetails[key]}
                        </h6>
                      );
                    })}
                    <h4 className="form_label_customers">
                      Secondary Contact Details
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Secondary Details"
                      variant="outlined"
                      sx={{
                        width: "100%",

                        // height: "6ch",
                      }}
                      style={{
                        backgroundColor: darkMode ? colorPaletteDark.white : "",
                      }}
                      value={
                        secondaryCustomerDetails.first_name &&
                        secondaryCustomerDetails.mobile_number &&
                        secondaryCustomerDetails.email &&
                        secondaryCustomerDetails.emp_code &&
                        secondaryCustomerDetails.user_name &&
                        secondaryCustomerDetails.user_type
                          ? `${secondaryCustomerDetails.first_name}, ${secondaryCustomerDetails.mobile_number}, ${secondaryCustomerDetails.email}, ${secondaryCustomerDetails.emp_code},${secondaryCustomerDetails.user_name},${secondaryCustomerDetails.user_type}`
                          : ""
                      }
                      color="success"
                      size="small"
                      onClick={() => {
                        setCurrentDrawer("secondary_details");
                        handleOpenWarehouseUserDrawer();
                        setErrorSecondaryDetails({});
                        // setCustomers(e.target.value);
                        // setErrorOemName("");
                      }}
                    />
                    {Object.keys(errorSecondaryDetails).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorSecondaryDetails[key]}
                        </h6>
                      );
                    })}
                  </>
                )}
                <h4 className="form_label_customers">
                  Upload Customer's Logo
                  <sup style={{ color: "red" }}>*</sup> (png,jpg file only)
                </h4>
                <TextField
                  size="small"
                  id="outlined-basic"
                  disabled
                  sx={{
                    width: "100%",

                    // height: "6ch",
                  }}
                  style={{
                    backgroundColor: darkMode ? colorPaletteDark.white : "",
                  }}
                  // style={{ width: "14rem" }}
                  // select
                  autoComplete="off"
                  // className="vehicle-input-attachment dropdown-container "
                  variant="outlined"
                  placeholder="Customer's Logo"
                  name="attachment_tag"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          color: colorPalette.primaryColor,
                          fontWeight: "600",
                        }}
                      >
                        <button className="upload_button">
                          <input
                            // name="upload"
                            name="attachment_file"
                            id="insurance"
                            type="file"
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={onFileChange}
                            hidden
                            style={{
                              backgroundColor: colorPalette.secondaryWhite,
                            }}
                          />
                          <label
                            htmlFor="insurance"
                            style={{
                              backgroundColor: colorPalette.secondaryWhite,
                            }}
                          >
                            <img
                              src={UploadImage}
                              alt=""
                              width="20px"
                              height="20px"
                              style={{
                                backgroundColor: colorPalette.secondaryWhite,
                              }}
                            />
                          </label>
                        </button>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                <div
                  style={{
                    fontSize: ".8rem",
                    width: "100%",
                  }}
                >
                  {customersPhoto ? customersPhoto.name : null}

                  {/* {customersPhoto.size} */}
                </div>
                <div
                  style={{
                    fontSize: ".8rem",
                    width: "100%",
                    color: "red",
                  }}
                >
                  {customersPhoto.type == "application/pdf"
                    ? "Invalid file format"
                    : null}

                  {/* {customersPhoto.size} */}
                </div>
                {Object.keys(errorPhoto).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                      {errorPhoto[key]}
                    </h6>
                  );
                })}
                {customersPhoto ? (
                  customersPhoto.size > process.env.REACT_APP_MAX_FILE_SIZE ? (
                    <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                      Please upload file size less than{" "}
                      {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
                    </h6>
                  ) : null
                ) : null}
                <h4 className="form_label_customers">
                  Upload Agreement Attachment (pdf file only)
                  {/* <sup style={{ color: "red" }}>*</sup> */}
                </h4>
                <TextField
                  size="small"
                  id="outlined-basic"
                  disabled
                  sx={{
                    width: "100%",

                    // height: "6ch",
                  }}
                  style={{
                    backgroundColor: darkMode ? colorPaletteDark.white : "",
                  }}
                  // style={{ width: "14rem" }}
                  // select
                  autoComplete="off"
                  // className="vehicle-input-attachment dropdown-container "
                  variant="outlined"
                  placeholder="Customer's agreement"
                  name="agreement_tag"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          color: colorPalette.primaryColor,
                          fontWeight: "600",
                        }}
                      >
                        <button className="upload_button">
                          <input
                            // name="upload"
                            name="agreement_file"
                            id="insur"
                            type="file"
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={onFileChangeAgreement}
                            hidden
                            style={{
                              backgroundColor: colorPalette.secondaryWhite,
                            }}
                          />
                          <label
                            htmlFor="insur"
                            style={{
                              backgroundColor: colorPalette.secondaryWhite,
                            }}
                          >
                            <img
                              src={UploadImage}
                              alt=""
                              width="20px"
                              height="20px"
                              style={{
                                backgroundColor: colorPalette.secondaryWhite,
                              }}
                            />
                          </label>
                        </button>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                <div
                  style={{
                    fontSize: ".8rem",
                    width: "100%",
                  }}
                >
                  {customersAgreement ? customersAgreement.name : null}

                  {/* {customersPhoto.size} */}
                </div>
                <div
                  style={{
                    fontSize: ".8rem",
                    width: "100%",
                    color: "red",
                  }}
                >
                  {invalidAttachment ? invalidAttachment : null}
                  {/* {customersPhoto.size} */}
                </div>
                {customersAgreement ? (
                  customersAgreement.size >
                  process.env.REACT_APP_MAX_FILE_SIZE ? (
                    <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                      Please upload file size less than{" "}
                      {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
                    </h6>
                  ) : null
                ) : null}
                {/* {Object.keys(errorPhoto).map((key) => {
                return (
                  <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                    {errorPhoto[key]}
                  </h6>
                );
              })} */}
                <h4 className="form_label_customers">
                  Customer's Description
                  {/* <sup style={{ color: "red" }}>*</sup> */}
                </h4>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  value={remarks}
                  placeholder="write description.."
                  style={{
                    width: "100%",
                    margin: "0rem 0rem .5rem 0rem",
                    // height: 100,
                    // marginBottom: ".5rem",
                    padding: ".2rem",
                  }}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={isReverseChargeMechanismChecked}
                    onChange={() =>
                      setIsReverseChargeMechanismChecked(
                        !isReverseChargeMechanismChecked
                      )
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <span
                    className="form_label_customers"
                    style={{ fontSize: "12px", marginBottom: "10px" }}
                  >
                    Is Reverse Charge Mechanism Applicable
                  </span>
                </div>
                {editMode == true ? (
                  <ButtonRectangle
                    // className="oem_btn"
                    onClick={handleCustomers}
                    disabled={loadingButton}
                    style={{
                      width: "100%",
                      color: "green",

                      backgroundColor: colorPalette.white,
                      border: `1px solid ${colorPalette.primaryColor}`,
                    }}
                  >
                    {loadingButton ? (
                      <CircularProgress size={20} style={{ color: "green" }} />
                    ) : selectedIndex !== null ? (
                      "UPDATE CUSTOMER"
                    ) : (
                      "ADD CUSTOMER"
                    )}
                  </ButtonRectangle>
                ) : (
                  <ButtonRectangle
                    // disabled
                    style={{
                      width: "100%",
                      marginTop: "20px",
                    }}
                    onClick={handleCustomers}
                  >
                    {loadingButton ? (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    ) : selectedIndex !== null ? (
                      "UPDATE CUSTOMER"
                    ) : (
                      "ADD CUSTOMER"
                    )}
                  </ButtonRectangle>
                )}
                {success.length > 0 ? (
                  <h6
                    style={{
                      marginTop: "1rem",
                      color: colorPalette.primaryColor,
                    }}
                  >
                    <Alert severity="success">{success}</Alert>
                  </h6>
                ) : null}
                {errorMsg.length > 0 ? (
                  <h6 style={{ marginTop: "1rem", color: "red" }}>
                    <Alert severity="error">{errorMsg}</Alert>
                  </h6>
                ) : null}
              </div>
            )}
            {selectedIndex !== null ? (
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h5 style={{ color: colorPalette.primaryColor }}>Agreement</h5>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={showIcons}
                    alt=""
                    width="30px"
                    height="30px"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleOpenImage();
                      showCustomerAttachments(selectedCustomer.id);
                    }}
                  />
                  <img
                    src={downloadIcons}
                    alt=""
                    width="30px"
                    height="30px"
                    style={{ cursor: "pointer" }}
                    onClick={() => download(selectedCustomer.id)}
                  />
                  {/* <div className="indent-stop-document">agreement</div> */}
                </div>
              </div>
            ) : null}

            <div
              style={{
                // border: "1px solid black",
                marginTop: editModeCustomer ? "10px" : "300px",
                width: "100%",
              }}
            >
              {selectedIndex == null ? null : <hr></hr>}
              {addCreditMode ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <div style={{ fontWeight: "600", color: "#016938" }}>
                    {creditEditMode ? "Update" : "Add"} Customer credit Terms
                  </div>
                  {buttonMode == true ? (
                    <Cancel
                      style={{ cursor: "pointer" }}
                      onClick={() => setAddCreditMode(false)}
                    />
                  ) : null}
                </div>
              ) : selectedIndex !== null ? (
                <button
                  className="customer-add-credit"
                  style={{
                    color: "#016938",
                    fontWeight: "600",
                    background: "transparent",
                    border: "none",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "95%",
                    margin: "5px",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setAddCreditMode(true);
                    setButtonMode(true);
                  }}
                >
                  <Add /> Add credit limit
                </button>
              ) : null}
              {addCreditMode ? (
                <div style={{ width: "100%", height: "100%" }}>
                  <div style={{ width: "100%" }}>
                    <h4
                      className="form_label_customers"
                      style={{ margin: ".5rem 0rem 0rem 0rem" }}
                    >
                      Customer Credit limit
                      {/* <sup style={{ color: "red" }}>*</sup> */}
                    </h4>
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter credit limit amount"
                      variant="outlined"
                      name="credit_limit"
                      style={{
                        width: "100%",
                        margin: "0rem 0rem 1rem 0rem",
                        backgroundColor: "white",
                      }}
                      type="number"
                      value={
                        customerCreditForm?.credit_limit
                          ? customerCreditForm?.credit_limit
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      color="success"
                      size="small"
                      onChange={handleChangeCredit}
                    />
                  </div>
                  <div>
                    <h4 className="form_label_customers">
                      Credit period (days)
                    </h4>
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter credit period"
                      variant="outlined"
                      style={{
                        width: "100%",
                        margin: "0rem 0rem 1rem 0rem",
                        backgroundColor: "white",
                      }}
                      name="credit_period"
                      value={
                        customerCreditForm?.credit_period
                          ? customerCreditForm?.credit_period
                          : ""
                      }
                      onWheel={(e) => e.target.blur()}
                      color="success"
                      size="small"
                      onChange={handleChangeCredit}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        width: "40%",
                        background: "#016938",
                        color: "white",
                        border: "none",
                        marginTop: "10px",
                        padding: "10px 0px",
                        borderRadius: "50px",
                        height: "40px",
                      }}
                      onClick={() => handleSubmitCredit(id)}
                    >
                      {loadingCreditButton ? (
                        <CircularProgress size={25} color="inherit" />
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </CustomersModal>
      </Drawer>
      <Drawer
        className="drawer"
        anchor={"right"}
        open={locationDrawer["right"]}
        onClose={() => {
          toggleLocationDrawer("right", false);
          setSelectedCustomer({});
          setErrorLocationName("");
          setErrorLocationPostcode("");
          setErrorLocationGst("");
          setErrorLocationVillage("");
          setPincodeNotServiceable("");
        }}
        // style={{
        //   color: darkMode
        //     ? colorPaletteDark.secondaryColor
        //     : colorPalette.white,
        // }}
      >
        <CustomersModal
          className="right_details_wrapper_customer"
          mode={darkMode}
        >
          <div className="oem_div_customer" style={{ padding: "20px" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <h5 style={{ color: colorPalette.primaryColor }}>Add Location</h5>

              <CloseIcon
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                }}
                onClick={handleCloseBtnLocation}
              />
            </div>
            {/* <h6 style={{ textAlign: "center", fontSize: "1rem" }}>
                Create OEM
              </h6> */}
            <div className="form_create_customer">
              <h4 className="form_label_customers">
                Name
                <sup style={{ color: "red" }}>*</sup>
              </h4>

              <TextField
                id="outlined-basic"
                placeholder="Enter Customer's Name"
                variant="outlined"
                name="name"
                sx={{
                  width: "100%",
                  margin: "0rem 0rem 0rem 0rem",
                  // height: "6ch",
                }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                value={addressDetails.name}
                color="success"
                size="small"
                onChange={(e) => {
                  changehandle(e);
                  setErrorLocationName({});
                }}
              />
              {Object.keys(errorLocationName).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorLocationName[key]}</h6>
                );
              })}
              <h4 className="form_label_customers">
                GSTIN
                <sup style={{ color: "red" }}>*</sup>
              </h4>

              <TextField
                id="outlined-basic"
                placeholder="Enter GSTIN"
                variant="outlined"
                name="gstin_number"
                sx={{
                  width: "100%",
                  margin: "0rem 0rem 0rem 0rem",
                  // height: "6ch",
                }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                value={addressDetails.gstin_number}
                color="success"
                size="small"
                onChange={(e) => {
                  changehandle(e);
                  setErrorLocationGst({});
                }}
              />
              {Object.keys(errorLocationGst).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorLocationGst[key]}</h6>
                );
              })}
              {/* <h4 className="form_label_customers">
                Pincode
                <sup style={{ color: "red" }}>*</sup>
              </h4> */}

              {/* <TextField
                id="outlined-basic"
                type="number"
                name="postcode"
                placeholder="Enter Pincode"
                variant="outlined"
                sx={{
                  width: "100%",
                  margin: "0rem 0rem .5rem 0rem",
                  // height: "6ch",
                }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                value={addressDetails.postcode}
                color="success"
                size="small"
                onChange={(e) => {
                  if (e.target.value.length <= 6) {
                    // setErrPincodeNotServiceable("");
                    changehandle(e);
                    setErrorLocationPostcode({});
                    setPincodeNotServiceable("");
                    // setErrorAddressPincode("");
                    if (e.target.value.length == 6) {
                      handleGetAddressSource(e.target.value);
                    }
                  }
                }}
              /> */}
              {/* {Object.keys(errorLocationPostcode).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>
                    {errorLocationPostcode[key]}
                  </h6>
                );
              })} */}
              {/* {pincodeNotServiceable ? (
                <h6 style={{ color: "#E06666" }}>{pincodeNotServiceable}</h6>
              ) : null} */}
              <div style={{ width: "100%" }}>
                <Pincode
                  parentStyle={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                  handlePincode={handlePincode}
                  pincodeError={pincodeNotServiceable}
                  inputDesign={{ marginTop: 0, width: "100%" }}
                  lastPincode={addressDetails.postcode}
                  inputWidth={"100%"}
                />
              </div>

              <h4 className="form_label_customers">
                Village
                <sup style={{ color: "red" }}>*</sup>
              </h4>

              <TextField
                type="number"
                sx={{
                  width: "100%",
                  margin: "0rem 0rem .5rem 0rem",
                  // height: "6ch",
                }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                className="create-trip-input"
                id="outlined-select-currency"
                name="village"
                select
                size="small"
                value={addressDetails.village}
                // onChange={(e) => handleOnChangeSource(e)}
                onChange={(e) => {
                  changehandle(e);
                  setErrorLocationVillage({});
                }}
                label="Select"
                // value={currency}
                // onChange={handleChange}
              >
                <MenuItem value="0" disabled>
                  Select Village
                </MenuItem>
                {sourceVillagesList.map((village, index) => {
                  return (
                    <MenuItem key={index} value={village}>
                      {village}
                    </MenuItem>
                  );
                })}
              </TextField>
              {Object.keys(errorLocationVillage).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>
                    {errorLocationVillage[key]}
                  </h6>
                );
              })}
              <h4 className="form_label_customers">
                District
                <sup style={{ color: "red" }}>*</sup>
              </h4>

              <TextField
                id="outlined-basic"
                placeholder="Enter district"
                variant="outlined"
                sx={{
                  width: "100%",
                  margin: "0rem 0rem .5rem 0rem",
                  // height: "6ch",
                }}
                name="district"
                disabled
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                value={addressFromPincode.district}
                color="success"
                size="small"
                onChange={(e) => {
                  changehandle(e);
                }}
              />
              {Object.keys(errorOemName).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorOemName[key]}</h6>
                );
              })}
              <h4 className="form_label_customers">
                Sub-district
                <sup style={{ color: "red" }}>*</sup>
              </h4>

              <TextField
                id="outlined-basic"
                placeholder="Enter sub-district"
                variant="outlined"
                name="sub_district"
                sx={{
                  width: "100%",
                  margin: "0rem 0rem .5rem 0rem",
                  // height: "6ch",
                }}
                disabled
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                value={addressFromPincode.sub_district}
                color="success"
                size="small"
                onChange={(e) => {
                  changehandle(e);
                }}
              />
              {Object.keys(errorOemName).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorOemName[key]}</h6>
                );
              })}
              <h4 className="form_label_customers">
                State
                <sup style={{ color: "red" }}>*</sup>
              </h4>

              <TextField
                id="outlined-basic"
                placeholder="Enter state"
                variant="outlined"
                sx={{
                  width: "100%",
                  margin: "0rem 0rem .5rem 0rem",
                  // height: "6ch",
                }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                name="state"
                value={addressFromPincode.state}
                color="success"
                disabled
                size="small"
                onChange={(e) => {
                  changehandle(e);
                }}
              />
              {Object.keys(errorOemName).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorOemName[key]}</h6>
                );
              })}
              <h4 className="form_label_customers">Landmark</h4>

              <TextField
                id="outlined-basic"
                placeholder="Enter landmark"
                variant="outlined"
                name="landmark"
                sx={{
                  width: "100%",
                  margin: "0rem 0rem 0rem 0rem",
                  // height: "6ch",
                }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                value={addressDetails.landmark}
                color="success"
                size="small"
                onChange={(e) => {
                  changehandle(e);
                }}
              />
              {Object.keys(errorLocationName).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorLocationName[key]}</h6>
                );
              })}
              <ButtonRectangle
                // disabled
                style={{
                  width: "100%",
                  marginTop: "1rem",
                }}
                onClick={handleSubmitLocation}
              >
                Add Location
              </ButtonRectangle>

              {/* {success.length > 0 ? (
                <h6
                  style={{
                    marginTop: "1rem",
                    color: colorPalette.primaryColor,
                  }}
                >
                  <Alert severity="success">{success}</Alert>
                </h6>
              ) : null} */}
              {/* {errorMsg.length > 0 ? (
                <h6 style={{ marginTop: "1rem", color: "red" }}>
                  <Alert severity="error">{errorMsg}</Alert>
          
                </h6>
              ) : null} */}
              <div
                style={{
                  // border: "1px solid black",
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                {selectedIndex == null ? null : <hr></hr>}
                {addCreditMode ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <div style={{ fontWeight: "600", color: "#016938" }}>
                      {creditEditMode ? "Update" : "Add"} Customer credit Terms
                    </div>
                    {buttonMode == true ? (
                      <Cancel
                        style={{ cursor: "pointer" }}
                        onClick={() => setAddCreditMode(false)}
                      />
                    ) : null}
                  </div>
                ) : selectedIndex !== null ? (
                  <button
                    className="customer-add-credit"
                    style={{
                      color: "#016938",
                      fontWeight: "600",
                      background: "transparent",
                      border: "none",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      width: "95%",
                      margin: "5px",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAddCreditMode(true);
                      setButtonMode(true);
                    }}
                  >
                    <Add /> Add credit limit
                  </button>
                ) : null}
                {addCreditMode ? (
                  <div style={{ width: "100%", height: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <h4
                        className="form_label_customers"
                        style={{ margin: ".5rem 0rem 0rem 0rem" }}
                      >
                        Customer Credit limit
                        {/* <sup style={{ color: "red" }}>*</sup> */}
                      </h4>
                      <TextField
                        id="outlined-basic"
                        placeholder="Enter credit limit amount"
                        variant="outlined"
                        name="credit_limit"
                        style={{
                          width: "100%",
                          margin: "0rem 0rem 1rem 0rem",
                          backgroundColor: "white",
                        }}
                        type="number"
                        value={
                          customerCreditForm?.credit_limit
                            ? customerCreditForm?.credit_limit
                            : ""
                        }
                        onWheel={(e) => e.target.blur()}
                        color="success"
                        size="small"
                        onChange={handleChangeCredit}
                      />
                    </div>
                    <div>
                      <h4 className="form_label_customers">
                        Credit period (days)
                      </h4>
                      <TextField
                        id="outlined-basic"
                        placeholder="Enter credit period"
                        variant="outlined"
                        style={{
                          width: "100%",
                          margin: "0rem 0rem 1rem 0rem",
                          backgroundColor: "white",
                        }}
                        name="credit_period"
                        value={
                          customerCreditForm?.credit_period
                            ? customerCreditForm?.credit_period
                            : ""
                        }
                        onWheel={(e) => e.target.blur()}
                        color="success"
                        size="small"
                        onChange={handleChangeCredit}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        style={{
                          width: "40%",
                          background: "#016938",
                          color: "white",
                          border: "none",
                          marginTop: "10px",
                          padding: "10px 0px",
                          borderRadius: "50px",
                          height: "40px",
                        }}
                        onClick={() => handleSubmitCredit(id)}
                      >
                        {loadingCreditButton ? (
                          <CircularProgress size={25} color="inherit" />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </CustomersModal>
      </Drawer>
      <Drawer
        anchor={"right"}
        open={wareHouseDrawer["right"]}
        onClose={() => {
          toggleWareHouseUserDrawer("right", false);
          setErrWarehouseUserDetails({
            errFirstName: "",
            errDesignation: "",
            errEmployeeCode: "",
            errMobileNumber: "",
            errEmail: "",
            errUserType: "",
            errUserName: "",
          });
          setErrWarehouseUserDetailsSecondary({
            errFirstName: "",
            errDesignation: "",
            errEmployeeCode: "",
            errMobileNumber: "",
            errEmail: "",
            errUserType: "",
            errUserName: "",
          });
          setMinCharacterUserName("");
          setErrorUserName({});
          setErrorUserNameScondary({});
        }}
        style={{ height: "100vh" }}
        // style={{ backgroundColor: darkMode ? "black" : "grey" }}
      >
        <CreateNewUser
          className="create-trip-drawer-container"
          style={{ width: isMobile ? "320px" : "380px", height: "100vh" }}
          mode={darkMode}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h5
              style={{ color: colorPalette.primaryColor, padding: "10px 5px " }}
            >
              Add{" "}
              {currentDrawer == "primary_details" ? "Primary " : "Secondary "}
              Details
            </h5>

            <Close
              onClick={handleCloseBtnWarehouseUser}
              style={{
                color: darkMode ? "white" : "black",
                cursor: "pointer",
                // padding: "10px",
                marginRight: "10px",
              }}
            />
          </div>

          <div
            className="form_create_customer"
            // className="usersContainer"
            style={{
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "0px",
              padding: "5px",
            }}
          >
            {" "}
            <div style={{ padding: "5px 0px" }}>
              <h4
                className="form_label_customers"
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                  fontWeight: "normal",
                }}
              >
                First Name
                <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                id="outlined-basic"
                placeholder="Enter First Name"
                variant="outlined"
                sx={{
                  width: "30ch",
                  height: "4ch",
                }}
                // sx={{
                //   input: {
                //     color: darkMode
                //       ? colorPaletteDark.white
                //       : colorPalette.black,
                //   },
                // }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                value={
                  currentDrawer == "primary_details"
                    ? userDetails.first_name
                    : userDetailsSecondary.first_name
                }
                color="success"
                size="small"
                onChange={(e) => {
                  currentDrawer == "primary_details"
                    ? setUserDetails({
                        ...userDetails,
                        first_name: e.target.value,
                      })
                    : setUserDetailsSecondary({
                        ...userDetailsSecondary,
                        first_name: e.target.value,
                      });
                }}
              />
              {currentDrawer == "primary_details" ? (
                <div className="warehouse-error">
                  {userDetails.first_name
                    ? null
                    : errWarehouseUserDetails.errFirstName}
                </div>
              ) : (
                <div className="warehouse-error">
                  {userDetailsSecondary.first_name
                    ? null
                    : errWarehouseUserDetailsSecondary.errFirstName}
                </div>
              )}
            </div>
            <div style={{ padding: "5px 0px" }}>
              <h4
                className="form_label_customers"
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                  fontWeight: "normal",
                }}
              >
                Last Name
              </h4>
              <TextField
                id="outlined-basic"
                placeholder="Enter Last Name"
                variant="outlined"
                sx={{
                  width: "30ch",
                  height: "4ch",
                }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                value={
                  currentDrawer == "primary_details"
                    ? userDetails.last_name
                    : userDetailsSecondary.last_name
                }
                // value={userDetails.last_name}
                color="success"
                size="small"
                onChange={(e) => {
                  currentDrawer == "primary_details"
                    ? setUserDetails({
                        ...userDetails,
                        last_name: e.target.value,
                      })
                    : setUserDetailsSecondary({
                        ...userDetailsSecondary,
                        last_name: e.target.value,
                      });
                }}
              />
            </div>
            <div style={{ padding: "5px 0px" }}>
              <h4
                className="form_label_customers"
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                  fontWeight: "normal",
                }}
              >
                User Name<sup style={{ color: "red" }}>*</sup>{" "}
                {/* <RefreshIcon
                      style={{
                        marginLeft: "100px",
                        cursor: "pointer",
                        fontSize: "20px",
                      }}
                    /> */}
              </h4>
              <div className="user-name">
                <TextField
                  id="outlined-basic"
                  placeholder="Enter User Name"
                  variant="outlined"
                  sx={{
                    width: "30ch",
                    height: "4ch",
                  }}
                  style={{
                    backgroundColor: darkMode ? colorPaletteDark.white : "",
                  }}
                  // disabled={editModeUser ? true : false}
                  // value={userDetails.user_name}
                  value={
                    currentDrawer == "primary_details"
                      ? userDetails.user_name
                      : userDetailsSecondary.user_name
                  }
                  color="success"
                  size="small"
                  onChange={(e) => {
                    setErrorUserName({});
                    setErrorUserNameScondary({});
                    if (e.target.value.length < 8) {
                      setMinCharacterUserName(
                        "Username must be of min. 8 char. long"
                      );
                    } else if (e.target.value.length >= 16) {
                      setMinCharacterUserName(
                        "Username can only be less than 16 char long."
                      );
                    } else {
                      setMinCharacterUserName("");
                    }
                    currentDrawer == "primary_details"
                      ? setUserDetails({
                          ...userDetails,
                          user_name: e.target.value,
                        })
                      : setUserDetailsSecondary({
                          ...userDetailsSecondary,
                          user_name: e.target.value,
                        });
                  }}
                  // setUserExistError("");
                  // setUserDoesNotExistError("");
                />
                {/* {editModeUser ? null : (
                      <RefreshIcon
                        onClick={verifyUsers}
                        style={{
                          // marginLeft: "100px",
                          cursor: "pointer",
                          fontSize: "20px",
                          color: darkMode ? colorPaletteDark.white : "",
                        }}
                      />
                    )} */}
              </div>

              {currentDrawer == "secondary_details" ? (
                <>
                  {Object.keys(errorUserNameScondary).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>
                        {errorUserNameScondary[key]}
                      </h6>
                    );
                  })}
                </>
              ) : (
                <>
                  {" "}
                  {Object.keys(errorUserName).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>{errorUserName[key]}</h6>
                    );
                  })}
                </>
              )}

              {currentDrawer == "secondary_details" ? (
                <div className="warehouse-error">
                  {userDetailsSecondary.user_name
                    ? null
                    : errWarehouseUserDetailsSecondary.errUserName}
                </div>
              ) : (
                <div className="warehouse-error">
                  {userDetails.user_name
                    ? null
                    : errWarehouseUserDetails.errUserName}
                </div>
              )}
              {currentDrawer == "secondary_details" ? (
                <div className="warehouse-error">
                  {minCharacterUserName ? minCharacterUserName : null}
                </div>
              ) : (
                <div className="warehouse-error">
                  {minCharacterUserName ? minCharacterUserName : null}
                </div>
              )}

              {/* {userExistError ? (
                    <span
                      style={{
                        color: "red",
                        display: "flex",
                        fontSize: "15px",
                        alignItems: "center",
                      }}
                    >
                      <CancelIcon style={{ fontSize: "20px" }} />{" "}
                      {userExistError}
                    </span>
                  ) : null}
                  {userDoesNotExistError ? (
                    <span
                      style={{
                        color: "green",
                        display: "flex",
                        fontSize: "15px",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleIcon style={{ fontSize: "20px" }} />{" "}
                      {userDoesNotExistError}
                    </span>
                  ) : null} */}
            </div>
            <div style={{ padding: "5px 0px" }}>
              <h4
                className="form_label_customers"
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                  fontWeight: "normal",
                }}
              >
                Designation
                <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                id="outlined-basic"
                placeholder="Enter Designation"
                variant="outlined"
                sx={{
                  width: "30ch",
                  height: "4ch",
                }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                // value={userDetails.designations}
                value={
                  currentDrawer == "primary_details"
                    ? userDetails.designations
                    : userDetailsSecondary.designations
                }
                color="success"
                size="small"
                // onChange={(e) => {
                //   setUserDetails({
                //     ...userDetails,
                //     designations: e.target.value,
                //   });
                // }}
                onChange={(e) => {
                  currentDrawer == "primary_details"
                    ? setUserDetails({
                        ...userDetails,
                        designations: e.target.value,
                      })
                    : setUserDetailsSecondary({
                        ...userDetailsSecondary,
                        designations: e.target.value,
                      });
                }}
              />
              {currentDrawer == "primary_details" ? (
                <div className="warehouse-error">
                  {userDetails.designations
                    ? null
                    : errWarehouseUserDetails.errDesignation}
                </div>
              ) : (
                <div className="warehouse-error">
                  {userDetailsSecondary.designations
                    ? null
                    : errWarehouseUserDetailsSecondary.errDesignation}
                </div>
              )}
            </div>
            <div style={{ padding: "5px 0px" }}>
              <h4
                className="form_label_customers"
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                  fontWeight: "normal",
                }}
              >
                Department
                {/* <sup style={{ color: "red" }}>*</sup> */}
              </h4>
              <TextField
                id="outlined-basic"
                placeholder="Enter Department"
                variant="outlined"
                sx={{
                  width: "30ch",
                  height: "4ch",
                }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                value={
                  currentDrawer == "primary_details"
                    ? userDetails.department
                    : userDetailsSecondary.department
                }
                color="success"
                size="small"
                onChange={(e) => {
                  currentDrawer == "primary_details"
                    ? setUserDetails({
                        ...userDetails,
                        department: e.target.value,
                      })
                    : setUserDetailsSecondary({
                        ...userDetailsSecondary,
                        department: e.target.value,
                      });
                }}
              />
            </div>
            <div style={{ padding: "5px 0px" }}>
              <h4
                className="form_label_customers"
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                  fontWeight: "normal",
                }}
              >
                Employee Code
                <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                id="outlined-basic"
                placeholder="Enter Employee Code"
                variant="outlined"
                sx={{
                  width: "30ch",
                  height: "4ch",
                }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                // value={userDetails.emp_code}
                value={
                  currentDrawer == "primary_details"
                    ? userDetails.emp_code
                    : userDetailsSecondary.emp_code
                }
                color="success"
                size="small"
                // onChange={(e) => {
                //   setUserDetails({
                //     ...userDetails,
                //     emp_code: e.target.value,
                //   });
                // }}
                onChange={(e) => {
                  currentDrawer == "primary_details"
                    ? setUserDetails({
                        ...userDetails,
                        emp_code: e.target.value,
                      })
                    : setUserDetailsSecondary({
                        ...userDetailsSecondary,
                        emp_code: e.target.value,
                      });
                }}
              />
              {currentDrawer == "primary_details" ? (
                <div className="warehouse-error">
                  {userDetails.emp_code
                    ? null
                    : errWarehouseUserDetails.errEmployeeCode}
                </div>
              ) : (
                <div className="warehouse-error">
                  {userDetailsSecondary.emp_code
                    ? null
                    : errWarehouseUserDetailsSecondary.errEmployeeCode}
                </div>
              )}
            </div>
            <div style={{ padding: "5px 0px" }}>
              <h4
                className="form_label_customers"
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                  fontWeight: "normal",
                }}
              >
                Mobile Number
                <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                id="outlined-basic"
                placeholder="Enter Mobile Number"
                variant="outlined"
                type="number"
                maxlength="10"
                pattern="\d{10}"
                onWheel={(e) => e.target.blur()}
                sx={{
                  width: "30ch",
                  height: "4ch",
                }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                // value={userDetails.mobile_number}
                value={
                  currentDrawer == "primary_details"
                    ? userDetails.mobile_number
                    : userDetailsSecondary.mobile_number
                }
                color="success"
                size="small"
                // onChange={(e) => {
                //   if (e.target.value.length <= 10) {
                //     setUserDetails({
                //       ...userDetails,
                //       mobile_number: e.target.value,
                //     });
                //   }
                // }}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    currentDrawer == "primary_details"
                      ? setUserDetails({
                          ...userDetails,
                          mobile_number: e.target.value,
                        })
                      : setUserDetailsSecondary({
                          ...userDetailsSecondary,
                          mobile_number: e.target.value,
                        });
                  }
                }}
              />
              {currentDrawer == "primary_details" ? (
                <div className="warehouse-error">
                  {userDetails.mobile_number.length === 10
                    ? null
                    : errWarehouseUserDetails.errMobileNumber}
                </div>
              ) : (
                <div className="warehouse-error">
                  {userDetailsSecondary.mobile_number.length === 10
                    ? null
                    : errWarehouseUserDetailsSecondary.errMobileNumber}
                </div>
              )}
            </div>
            <div style={{ padding: "5px 0px" }}>
              <h4
                className="form_label_customers"
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                  fontWeight: "normal",
                }}
              >
                E-mail<sup style={{ color: "#E06666" }}>*</sup>
              </h4>
              <TextField
                id="outlined-basic"
                placeholder="Enter E-mail"
                variant="outlined"
                type="email"
                sx={{
                  width: "30ch",
                  height: "4ch",
                }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                // value={userDetails.email}
                value={
                  currentDrawer == "primary_details"
                    ? userDetails.email
                    : userDetailsSecondary.email
                }
                color="success"
                size="small"
                // onChange={(e) => {
                //   setUserDetails({ ...userDetails, email: e.target.value });
                //   // setErrorEmailName("");
                // }}
                onChange={(e) => {
                  currentDrawer == "primary_details"
                    ? setUserDetails({
                        ...userDetails,
                        email: e.target.value,
                      })
                    : setUserDetailsSecondary({
                        ...userDetailsSecondary,
                        email: e.target.value,
                      });
                }}
              />
              {/* {Object.keys(errorEmailName).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorEmailName[key]}</h6>
                );
              })} */}

              {currentDrawer == "primary_details" ? (
                <div className="warehouse-error">
                  {userDetails.email === ""
                    ? null
                    : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                        userDetails.email
                      )
                    ? null
                    : errWarehouseUserDetails.errEmail}
                </div>
              ) : (
                <div className="warehouse-error">
                  {userDetailsSecondary.email === ""
                    ? null
                    : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                        userDetailsSecondary.email
                      )
                    ? null
                    : errWarehouseUserDetailsSecondary.errEmail}
                </div>
              )}
            </div>
            <div style={{ padding: "5px 0px" }}>
              <h4
                className="form_label_customers"
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                  fontWeight: "normal",
                }}
              >
                User Type
                <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                id="outlined-basic"
                placeholder="Enter Type"
                select
                variant="outlined"
                sx={{
                  width: "30ch",
                  height: "4ch",
                }}
                style={{
                  backgroundColor: darkMode ? colorPaletteDark.white : "",
                }}
                // value={userDetails.user_type}
                value={
                  currentDrawer == "primary_details"
                    ? userDetails.user_type
                    : userDetailsSecondary.user_type
                }
                color="success"
                size="small"
                // onChange={(e) => {
                //   setUserDetails({
                //     ...userDetails,
                //     user_type: e.target.value,
                //   });
                // }}
                onChange={(e) => {
                  currentDrawer == "primary_details"
                    ? setUserDetails({
                        ...userDetails,
                        user_type: e.target.value,
                      })
                    : setUserDetailsSecondary({
                        ...userDetailsSecondary,
                        user_type: e.target.value,
                      });
                }}
              >
                <MenuItem value="0" disabled>
                  Select user type
                </MenuItem>
                {userTypeList.map((type) => {
                  return <MenuItem value={type}>{type}</MenuItem>;
                })}
              </TextField>
              {currentDrawer == "primary_details" ? (
                <div className="warehouse-error">
                  {userDetails.user_type.length >= 2
                    ? null
                    : errWarehouseUserDetails.errUserType}
                </div>
              ) : (
                <div className="warehouse-error">
                  {userDetailsSecondary.user_type.length >= 2
                    ? null
                    : errWarehouseUserDetailsSecondary.errUserType}
                </div>
              )}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "1rem 0rem",
              }}
            >
              {errCreateUser ? (
                <Alert
                  severity="warning"
                  style={isMobile ? { width: "95%" } : { width: "100%" }}
                >
                  <h5 style={{ textAlign: "center" }}>{errCreateUser}</h5>
                </Alert>
              ) : null}
            </div>
            <div style={{ marginTop: ".5rem", width: "85%" }}>
              <button
                className="warehouse-list-add-button_user"
                onClick={handleWarehouseUser}
              >
                {loadingButtonUser ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </CreateNewUser>
      </Drawer>
      <div className="main_onboard_wrapper">
        {isMobile ? (
          <div className="trip-list-mobile-header">
            <MobileHeader headerTitle="Customers" />
          </div>
        ) : (
          <div className="main_onboard_Left_box">
            <SideBarMain />
          </div>
        )}

        <div className="main_onboard_Right_box">
          {isMobile ? null : <Topbar />}

          <div className="progress_container_details">
            <div className="Left_details_wrapper">
              <div className="search_container">
                <div className="search_div" id="search_div_onboard">
                  <input
                    type="text"
                    name=""
                    id="search_input_onboard"
                    onChange={(event) => {
                      dispatch(searchData(event.target.value));
                      setCurrentPage(1);
                    }}
                    value={searchTerm}
                    placeholder="search customer"
                    className="search_input"
                  />
                  {searchTerm && !customersData.length == 0 ? (
                    <CloseIcon
                      onClick={handlecancelSearch}
                      style={{ color: "grey", cursor: "pointer" }}
                    />
                  ) : (
                    <SearchIcon style={{ color: "grey", cursor: "pointer" }} />
                  )}
                </div>
                {isMobile ? null : (
                  <>
                    <div
                      className="onboard_btnDiv"
                      style={{ marginRight: "20px" }}
                    >
                      <Popover
                        placement="bottomRight"
                        title={"Customer Credit Report"}
                        trigger={["click"]}
                        content={
                          <div
                            style={{
                              width: "250px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                            }}
                          >
                            <Input
                              type="email"
                              placeholder="Enter email"
                              value={customerCreditExportEmail}
                              onChange={(e) =>
                                setCustomerCreditExportEmail(e.target.value)
                              }
                            />
                            <ButtonRectangle
                              onClick={() => {
                                handleExportCustomerCredit();
                              }}
                              style={{
                                width: "100px",
                                height: "30px",
                                marginTop: "15px",
                              }}
                            >
                              {exportButtonLoading ? "Loading..." : "Export"}
                            </ButtonRectangle>
                            {customerCreditExportEmailError !== "" ? (
                              <AntdAlert
                                message={customerCreditExportEmailError}
                                type="error"
                                showIcon
                                style={{
                                  width: "100%",
                                  marginTop: "15px",
                                  padding: "2px 10px",
                                }}
                              />
                            ) : null}
                          </div>
                        }
                      >
                        <ButtonRectangleSecondary className="add_manufacturer">
                          Export
                        </ButtonRectangleSecondary>
                      </Popover>
                    </div>
                    <div className="onboard_btnDiv">
                      <ButtonRectangle
                        onClick={() => {
                          handleOpenMenu();
                          setEditMode(false);
                          setErrorPhoto({});
                          setRemarks("");
                          setCustomers("");
                          setSelectedIndex(null);
                          setAddCreditMode(false);
                          setIsCreateButtonClicked(true);
                        }}
                      >
                        Create
                      </ButtonRectangle>
                    </div>
                    <hr style={{ marginTop: ".4rem", color: "grey" }} />
                  </>
                )}
              </div>
              {isMobile ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="onboard_btnDiv"
                    style={{ marginRight: "20px" }}
                  >
                    <Popover
                      placement="bottomRight"
                      title={"Customer Credit Report"}
                      trigger={["click"]}
                      content={
                        <div
                          style={{
                            width: "250px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                          }}
                        >
                          <Input
                            type="email"
                            placeholder="Enter email"
                            defaultValue={customerCreditExportEmail}
                            onChange={(e) =>
                              setCustomerCreditExportEmail(e.target.value)
                            }
                          />
                          <ButtonRectangle
                            onClick={() => {
                              handleExportCustomerCredit();
                            }}
                            style={{
                              width: "100px",
                              height: "30px",
                              marginTop: "15px",
                            }}
                          >
                            {exportButtonLoading ? "Loading..." : "Export"}
                          </ButtonRectangle>
                          {customerCreditExportEmailError !== "" ? (
                            <AntdAlert
                              message={customerCreditExportEmailError}
                              type="error"
                              showIcon
                              style={{
                                width: "100%",
                                marginTop: "15px",
                                padding: "2px 10px",
                              }}
                            />
                          ) : null}
                        </div>
                      }
                    >
                      <ButtonRectangleSecondary>
                        Export
                      </ButtonRectangleSecondary>
                    </Popover>
                  </div>
                  <div className="onboard_btnDiv">
                    <ButtonRectangle
                      onClick={() => {
                        handleOpenMenu();
                        setIsCreateButtonClicked(true);
                      }}
                      // className="add_manufacturer"
                    >
                      Create
                    </ButtonRectangle>
                  </div>
                  <hr style={{ marginTop: ".4rem", color: "grey" }} />
                </div>
              ) : null}
              {isMobile ? (
                <div className="card_container">
                  {isUserNotAuthorized ? (
                    <NotAuthorized />
                  ) : customersData.length !== 0 ? (
                    <>
                      {customersData.map((data, index) => (
                        <>
                          <div
                            className="card"
                            key={index}
                            // onClick={() => handleCustomerSelect(data, index)}
                          >
                            <div className="content_div">
                              <div className="name">
                                {customersData.length == index + 1 ? (
                                  <>
                                    <span className="card_customer_code">
                                      <CopyText text={data.customer_code} />
                                    </span>
                                    <h6
                                      className="name_text_vendors"
                                      ref={lastItem}
                                    >
                                      <CopyText
                                        text={data.name.toUpperCase()}
                                      />
                                    </h6>
                                    <h6 className="name_text_vendors">
                                      Credit limit : ₹{" "}
                                      {data?.credit_limit
                                        ? data?.credit_limit
                                        : "0"}
                                    </h6>
                                    <h6 className="name_text_vendors">
                                      Credit period :{" "}
                                      {data?.credit_period
                                        ? data?.credit_period
                                        : "0"}
                                      {"   "}
                                      days
                                    </h6>
                                  </>
                                ) : (
                                  <>
                                    <span className="card_customer_code">
                                      <CopyText text={data.customer_code} />
                                    </span>
                                    <h6 className="name_text_vendors">
                                      <CopyText
                                        text={data.name.toUpperCase()}
                                      />
                                    </h6>
                                    <h6 className="name_text_vendors">
                                      Credit limit : ₹{" "}
                                      {data?.credit_limit
                                        ? data?.credit_limit
                                        : "0"}
                                    </h6>
                                    <h6 className="name_text_vendors">
                                      Credit period :{" "}
                                      {data?.credit_period
                                        ? data?.credit_period
                                        : "0"}
                                      {"   "}
                                      days
                                    </h6>
                                  </>
                                )}
                              </div>
                              {/* <div className="card_customer_credit_contain">
                                <div className="card_customer_credit_key">
                                  Credit limit :{" "}
                                  <div className="card_customer_credit_value">
                                    {" "}
                                    <span
                                      style={{
                                        fontFamily: "monospace",
                                      }}
                                    >
                                      {" "}
                                      ₹
                                    </span>{" "}
                                    {data?.credit_limit
                                      ? data?.credit_limit
                                      : "0"}
                                  </div>
                                </div>
                                <div className="card_customer_credit_key">
                                  Credit period :{"  "}
                                  <div className="card_customer_credit_value">
                                    {"   "}
                                    {data?.credit_period
                                      ? data?.credit_period
                                      : "0"}
                                    {"   "}
                                    days
                                  </div>
                                </div>
                              </div> */}
                            </div>
                            <div
                              style={{ width: "10%" }}
                              onClick={() => setIsCreateButtonClicked(false)}
                            >
                              <EditIcon
                                onClick={() =>
                                  handleCustomerSelect(data, index)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            {isMobile ? (
                              <div>
                                <NavLink
                                  style={{ textDecoration: "none" }}
                                  to={`/customers/${data.id}/warehouses`}
                                >
                                  <ArrowForwardIcon
                                    style={{
                                      marginRight: "10px",
                                      fontSize: "30px",
                                      color: "#016938",
                                      fontSize: "1.2rem",
                                    }}
                                  />
                                </NavLink>
                              </div>
                            ) : null}
                          </div>
                        </>
                      ))}
                    </>
                  ) : loading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        // backgroundColor: "grey",
                      }}
                    >
                      <Box sx={{ width: 600, height: 500 }}>
                        <Skeleton
                          style={{
                            height: "150px",
                            color: darkMode ? "grey" : "",
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          style={{ height: "150px" }}
                          animation="wave"
                        />
                        <Skeleton
                          style={{ height: "150px" }}
                          animation="wave"
                        />
                      </Box>{" "}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "3rem",
                      }}
                    >
                      <h6 style={{ color: darkMode ? "white" : "" }}>
                        No customer found!
                      </h6>
                    </div>
                  )}
                </div>
              ) : (
                <div className="card_container_manufacturer">
                  {isUserNotAuthorized ? (
                    <NotAuthorized />
                  ) : customersData.length !== 0 ? (
                    <>
                      {customersData.map((data, index) => (
                        <>
                          {" "}
                          <div
                            className="card_manufacturer_customers"
                            key={index}
                            style={
                              index === selectedIndex
                                ? { border: "2px solid #016938" }
                                : null
                            }
                            onClick={() => {
                              handleCustomerSelect(data, index);
                            }}
                          >
                            {
                              isMobile ? (
                                <div className="image_div">
                                  <img src={Carrier} alt="" />
                                </div>
                              ) : null
                              // <div className="image_div_vendors">
                              //   <ImageComponents
                              //     imageId={data.id}
                              //     source="vendors"
                              //     name={data.name}
                              //   />

                              // </div>
                            }

                            {/* <div className="content_div_manufacturer">
                            <div className="name">
                     
                              {oemData.length == index + 1 ? (
                                <h6 className="name_text" ref={lastItem}>
                                  {" "}
                                  {data.name}
                                </h6>
                              ) : (
                                <h6 className="name_text"> {data.name}</h6>
                              )}
                            </div>
                          </div> */}
                            {isMobile ? (
                              // <div className="button_div">
                              <div>
                                <NavLink
                                  to={`/vehicles-manufacturers/oemdetails/${data.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <h6
                                    style={{
                                      marginRight: ".5rem",
                                      backgroundColor:
                                        colorPalette.primaryColor,
                                      padding: ".5rem .8rem",
                                      color: "white",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    Details
                                    <ArrowForwardIosIcon
                                      style={{
                                        fontSize: "1rem",
                                      }}
                                    />
                                  </h6>
                                </NavLink>
                              </div>
                            ) : (
                              <div className="button_div_customers">
                                <div className="name" style={{ width: "100%" }}>
                                  {customersData.length == index + 1 ? (
                                    <>
                                      <span className="card_customer_code">
                                        <CopyText text={data.customer_code} />
                                      </span>
                                      <h6
                                        className="name_text_customer"
                                        ref={lastItem}
                                      >
                                        <CopyText
                                          text={data.name.toUpperCase()}
                                        />
                                      </h6>
                                      <div className="card_customer_credit_contain">
                                        <div className="card_customer_credit_key">
                                          Credit limit :{" "}
                                          <div className="card_customer_credit_value">
                                            {" "}
                                            <span
                                              style={{
                                                fontFamily: "monospace",
                                              }}
                                            >
                                              {" "}
                                              ₹
                                            </span>{" "}
                                            {data?.credit_limit
                                              ? data?.credit_limit
                                              : "0"}
                                          </div>
                                        </div>
                                        <div className="card_customer_credit_key">
                                          Credit period :{"  "}
                                          <div className="card_customer_credit_value">
                                            {"   "}
                                            {data?.credit_period
                                              ? data?.credit_period
                                              : "0"}
                                            {"   "}
                                            days
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card_customer_button">
                                        <NavLink
                                          style={{ textDecoration: "none" }}
                                          to={`/customers/${data.id}/warehouses`}
                                        >
                                          Locations
                                        </NavLink>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <span className="card_customer_code">
                                        <CopyText text={data.customer_code} />
                                      </span>
                                      <h6 className="name_text_customer">
                                        <CopyText
                                          text={data.name.toUpperCase()}
                                        />
                                      </h6>
                                      <div className="card_customer_credit_contain">
                                        <div className="card_customer_credit_key">
                                          Credit limit :{" "}
                                          <div className="card_customer_credit_value">
                                            {" "}
                                            <span
                                              style={{
                                                fontFamily: "monospace",
                                              }}
                                            >
                                              {" "}
                                              ₹
                                            </span>{" "}
                                            {data?.credit_limit
                                              ? data?.credit_limit
                                              : "0"}
                                          </div>
                                        </div>
                                        <div className="card_customer_credit_key">
                                          Credit period :{"  "}
                                          <div className="card_customer_credit_value">
                                            {"   "}
                                            {data?.credit_period
                                              ? data?.credit_period
                                              : "0"}
                                            {"   "}
                                            days
                                          </div>
                                        </div>
                                      </div>

                                      {/* <h6 className="name_text_vendors"> */}

                                      {/* </h6> */}
                                      <div className="card_customer_button">
                                        <NavLink
                                          style={{ textDecoration: "none" }}
                                          to={`/customers/${data.id}/warehouses`}
                                        >
                                          Locations
                                        </NavLink>
                                      </div>
                                    </>
                                  )}
                                </div>
                                {/* <div>
                                  <NavLink
                                    style={{ textDecoration: "none" }}
                                    to={`/vehicles-manufacturers/oemdetails/${data.id}`}
                                  >
                                    <h6 className="btn_manufacturer">
                                      <ArrowForwardIcon
                                        style={{
                                          fontSize: "25px",
                                          fontWeight: "bolder",
                                          fontSize: "1.2rem",
                                          marginLeft: "1rem",
                                        }}
                                      />
                                    </h6>
                                  </NavLink>
                                </div> */}
                              </div>
                            )}
                          </div>
                        </>
                      ))}
                    </>
                  ) : customersData.length == 0 && !loading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "200%",
                        height: "100%",
                        marginLeft: "6rem",
                      }}
                    >
                      <h4
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginLeft: "10%",
                          fontSize: "1rem",
                          color: darkMode ? "white" : "",
                        }}
                      >
                        No Customer Found!
                      </h4>
                    </div>
                  ) : loading && customersData.length == 0 ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "100%",
                        marginLeft: "6rem",
                        // backgroundColor: "grey",
                      }}
                    >
                      <Box sx={{ width: 900, height: 500 }}>
                        <Skeleton
                          style={{
                            height: "150px",
                            backgroundColor: darkMode ? "#211E2B" : "",
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          style={{
                            height: "150px",
                            backgroundColor: darkMode ? "#211E2B" : "",
                          }}
                          animation="wave"
                        />
                        <Skeleton
                          style={{
                            height: "150px",
                            backgroundColor: darkMode ? "#211E2B" : "",
                          }}
                          animation="wave"
                        />
                        {/* <Skeleton animation="wave" /> */}
                        {/* <Skeleton animation={false} /> */}
                      </Box>{" "}
                      {/* <img
                  src={loader}
                  alt=""
                  width="120px"
                  height="90px"
                  style={{ marginTop: "200px" }}
                />
                <h6 style={{ color: "grey" }}>loading data...</h6> */}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "200%",
                      }}
                    >
                      <h6
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginLeft: "10%",
                          fontSize: ".6rem",
                        }}
                      >
                        No data found,Please add one.
                      </h6>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <Snackbar
            open={openToastSuccess}
            autoHideDuration={8000}
            anchorOrigin={{ vertical, horizontal }}
            onClose={handleCloseToastSuccess}
          >
            <Alert
              variant="filled"
              elevation={6}
              onClose={handleCloseToastSuccess}
              severity="success"
              sx={{ width: "100%" }}
            >
              {creditMsg ? creditMsg : ""}
            </Alert>
          </Snackbar>
          <Snackbar
            open={openToastError}
            autoHideDuration={8000}
            anchorOrigin={{ vertical, horizontal }}
            onClose={handleCloseToastError}
          >
            <Alert
              variant="filled"
              elevation={6}
              onClose={handleCloseToastError}
              severity="error"
              sx={{ width: "100%" }}
            >
              {creditMsg ? creditMsg : ""}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </Component_style>
  );
}

export default Customers;
