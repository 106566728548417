import React, { useState, useEffect } from "react";
import UploadImage from "../../../assets/upload.svg";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import success from "../../../assets/dashboard_icons/icons8-truck.gif";
import {
  LocationOn,
  ArrowBack,
  Close,
  AddCircle,
  Add,
  Cancel,
  Search,
} from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";

import axios from "axios";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import * as moment from "moment";
import AsyncSelect from "react-select/async";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import MONTHLYICON from "../../../assets/monthly_icon.svg";
import TRIPWISEICON from "../../../assets/trip_wise_icon.svg";
import TICKICON from "../../../assets/green_tick.svg";
import WRONGICON from "../../../assets/wrong.svg";
import {
  TextField,
  MenuItem,
  CircularProgress,
  InputAdornment,
  TextareaAutosize,
} from "@mui/material";
import {
  GetAddressFromPincode,
  CreateTripPost,
  GetUnitList,
  GetAvailableVehiclesList,
  GetPurchaseOrders,
  GetInvoices,
  UpdateTripPut,
  GetVehicles,
  GetDrivers,
  CreateDriverPost,
  GetDocumentEnums,
  GetCustomersList,
  getZohoInvoiceItemsDetails,
  GetEntityTypeEnums,
  GetCustomerSearchList,
  GetCustomerById,
  CreateTripPostV1,
  CreateTripPostForFaarms,
  UpdateTripPutForFaarms,
} from "./createTripServices";
import { Box, Drawer } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  updateDriverDetails,
  updateTripDetails,
} from "../../../redux/tripSlice";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import apiUrl from "../../../url/apiUrl";
import { TripAttachmentPost } from "../tripList/tripListServices";
import { getWarehouseList } from "../../warehouses/warehousesServices";
import { getWarehouseUsersList } from "../../../components/warehouseMiniCard/warehouseMiniService";
import { getTripDetailsFromId } from "../deliveryInvoicePayment/DeliveryInvoiceService";
import { useParams } from "react-router-dom";
// import { vendorsPost } from "../../vendors/VendorsServices";
import {
  Create_Trip_Main_Container,
  Create_trip_drawer_container,
  Create_trip_vehicle_single,
} from "./create-trip.js";
import { colorPalette } from "../../../themes/theme";
import { customersPost } from "../../customers/CustomersServices";
import Topbar from "../../../components/topbar/Topbar.jsx";
import axiosInterceptor from "../../../config/https";

function CreateTrip() {
  const history = useHistory();
  const { tripId } = useParams();
  const dispatch = useDispatch();
  const [sourceVillagesList, setSourceVillagesList] = useState([]);
  const [toPay, setToPay] = useState("");
  const [tripfor, setTripFor] = useState("");
  const [toBilled, setToBilled] = useState("");
  const [drivingPhoto, setDrivingPhoto] = useState(null);
  const [driverPanImage, setDriverPanImage] = useState();
  const [permission, setPermission] = useState("");
  const [driverId, setDriverId] = useState("");
  const [invoiceTotalQuantity, setInvoiceTotalQuantity] = useState([]);
  const [wareHouseList, setWareHouseList] = useState([]);
  const [totalQty, setTotalQty] = useState("");

  const [wareHouse, setWareHouse] = useState({});

  const [referenceNumberSearchValue, setReferenceNumberSearchValue] =
    useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [destinationVillagesList, setDestinationVillagesList] = useState([]);
  const [sourceDetails, setSourceDetails] = useState([
    {
      concern_person_name: "",
      concern_person_mobile_number: "",
      postcode: "",
      type: "source",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    },
  ]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [destinationDetails, setDestinationDetails] = useState([
    {
      concern_person_name: "",
      concern_person_mobile_number: "",
      postcode: "",
      type: "destination",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    },
  ]);
  const [driverDetails, setDriverDetails] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    pan_number: "",
    driving_licence_number: "",
    alternate_mobile_number: "",
  });

  const [vehicleNumber, setVehicleNumber] = useState("0");
  const [transporterNameId, setTransporterNameId] = useState("");

  const [selectedTransporterName, setSelectedTransporterName] = useState("");

  const [availableVehicleList, setAvailableVehicleList] = useState([]);
  const [vehiclePayoutPeriod, setVehiclePayoutPeriod] = useState("");
  const [loadSource, setLoadSource] = useState("trip_invoice");
  // const [perKmRate, setPerKmRate] = useState("");

  const [loadUnit, setLoadUnit] = useState("ton");
  const [invoiceId, setInvoiceId] = useState([]);
  const [searchInvoiceLoading, setSearchInvoiceLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [perKmRate, setPerKmRate] = useState("");
  const [tripStartMeterReading, setTripStartMeterReading] = useState("");
  // const [errValueOfGoods, setErrValueOfGoods] = useState("");

  const [searchResultsList, setSearchResultsList] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState({});
  const [selectedWarehouseUser, setSelectedWarehouseUser] = useState({});
  const [errTotalDriver, setErrTotalDriver] = useState("");
  const [errCustomerFinal, setErrCustomerFinal] = useState("");
  const [addCustomerData, setAddCustomerData] = useState({});

  const [purchaseOrderId, setPurchaseOrderId] = useState([]);

  const [zohoId, setZohoId] = useState("");
  const [perBagCost, setPerBagCost] = useState("");
  // const [paymentOptions, setPaymentOptions] = useState({
  //   reference_id: "",
  //   payment_mode: "0",
  // });
  const [editMode, setEditMode] = useState(false);
  const [errSourceAddress, setErrSourceAddress] = useState({
    errPersonName: "",
    errPersonMobileNumber: "",
    errPostcode: "",
    errVillage: "",
    errorWarehouse: "",
    errorWarehouseUser: "",
  });
  const [errTrip, setErrTrip] = useState({
    errLoadSource: "",
    errReferenceId: "",
    errVehicleNumber: "",
    errTransporter: "",
    errLoadUnit: "",
    errTripDistance: "",
    errPaymentStatus: "",
    errPaymentTerms: "",
    errTotalCost: "",
    errAdvanceCost: "",
    errTotalQty: "",
    errCustomer: "",
    errConsignee: "",
    errBillToParty: "",

    errTripStartMeterReading: "",
    errTripStartMeterReadingAttachment: "",
    errPerKmRate: "",
    errValueOfGoods: "",
    errTripType: "",
    errCustomerInvoice: "",
    errCustomerInvoiceAttachment: "",
    errBhadaChitiAttachment: "",
  });
  const [paymentTerms, setPaymentTerms] = useState("to_be_billed");
  const [errDestinationAddress, setErrDestinationAddress] = useState({
    errPersonName: "",
    errPersonMobileNumber: "",
    errPostcode: "",
    errVillage: "",
  });
  const [attachmentListArray, setAttachmentListArray] = useState([
    {
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    },
  ]);

  const [ewayBillAttachment, setEwayBillAttachment] = useState(null);
  const [tripStartMeterReadingAttachment, setTripStartMeterReadingAttachment] =
    useState({
      attachment_tag: "trip_start_meter_reading_attachment",
      attachment_type: "",
      attachment_file: null,
    });

  const [bhadaChitiAttachment, setBhadaChitiAttachment] = useState({
    attachment_tag: "bhada_chithi_attachment",
    attachment_type: "",
    attachment_file: null,
  });
  const [currentStatusUpdateInProgress, setCurrentStatusUpdateInProgress] =
    useState("");
  // const [errPaymentOptions, setErrPaymentOptions] = useState({
  //   errReferenceId: "",
  //   errPaymentMode: "",
  // });
  const [searchPurchaseOrders, setSearchPurchaseOrders] = useState("");
  const [currentDestinationId, setCurrentDestinationId] = useState(null);
  const [currentSourceId, setCurrentSourceId] = useState(null);
  const [tripStatus, setTripStatus] = useState("created");
  const [tripDistance, setTripDistance] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("0");
  const [advanceByCustomer, setAdvanceByCustomer] = useState("");
  const [billedByCustomer, setBilledByCustomer] = useState("");
  const [errSourceFinal, setErrSourceFinal] = useState("");
  const [errDestinationFinal, setErrDestinationFinal] = useState("");
  const [errSourceArray, setErrSourceArray] = useState([]);
  const [errDestinationArray, setErrDestinationArray] = useState([]);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [errDriverFinal, setErrDriverFinal] = useState("");
  const [costKeys, setCostKeys] = useState([]);
  const [errEwayBill, setErrEwayBill] = useState({
    errEwayNumber: "",
    errEwayFrom: "",
    errEwayTo: "",
  });
  const [valueOfGoods, setValueOfGoods] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [invoiceButtonPressed, setInvoiceButtonPressed] = useState(false);
  const [totalQuantityForPBC, setTotalQuantityForPBC] = useState([]);
  const [pbcCostPurchase, setPbcCostPurchase] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedConsignee, setSelectedConsignee] = useState({});
  const [selectedBillToParty, setSelectedBillToParty] = useState({});

  const [totalValueOfGoodsFromInvoice, setTotalValueOfGoodsFromInvoice] =
    useState();

  const [driverLoadingButton, setDriverLoadingButton] = useState(false);
  const [customerLoadingButton, setCustomerLoadingButton] = useState(false);
  const [errCreateTripFinal, setErrCreateTripFinal] = useState("");
  const [ewayBillNumber, setEwayBillNumber] = useState("");

  const [ewayBillFrom, setEwayBillFrom] = useState("");
  const [ewayBillTo, setEwayBillTo] = useState("");
  const [tripCost, setTripCost] = useState([
    {
      cost_type: "advance",
      cost_amount: "",
    },
    {
      cost_type: "trip_cost",
      cost_amount: "",
    },
  ]);
  const [errDriverDetails, setErrDriverDetails] = useState({
    errFirstName: "",
    errMobileNumber: "",
    errPanNumber: "",
    errDLNumber: "",
    errPanDocs: "",
    errDLDocs: "",
  });

  const [errCustomerDetails, setCustomerDetails] = useState({
    errName: "",
    errLogo: "",
  });
  const [allChargesList, setAllChargesList] = useState([
    "labour_charges",
    "freight_charges",
    "toll_charges",
    "night_halt_charges",
    "others_expenses",
    "trip_cost",
  ]);

  const [entityTypeList, setEntityTypeList] = useState([]);

  // const entityTypeList = [
  //   "transfer_order",
  //   "vendor_credit_notes",
  //   "sales_return",
  //   "others",
  // ];
  // const [approxCostDetails, setApproxCostDetails] = useState({
  //   z: "",
  // });
  const [tripIdEdit, setTripIdEdit] = useState(null);
  const [errPincodeNotServiceable, setErrPincodeNotServiceable] = useState("");
  const [loadSourceList, setLoadSourceList] = useState([
    "trip_purchase_order",
    "trip_invoice",
  ]);
  const [openToast, setOpenToast] = useState(false);
  const [entityType, setEntityType] = useState("0");
  const [paymentModeList, setPaymentModeList] = useState(["online", "offline"]);
  const [invoiceItemsDetails, setInvoiceItemsDetails] = useState([]);
  const [loadUnitList, setLoadUnitList] = useState([]);
  const [tripType, setTripType] = useState("last_mile");
  const [costTypeList, setCostTypeList] = useState([
    "approx_trip_cost",
    "actual_trip_cost",
  ]);
  const [attachmentTypeList, setAttachmentTypeList] = useState([
    "image",
    "pdf",
  ]);
  const [currentTab, setCurrentTab] = useState(null);
  const [warehouseUserList, setWarehouseUserList] = useState([]);
  const [referenceNumberManual, setReferenceNumberManual] = useState("");
  const [customerSearchValue, setCustomerSearchValue] = useState("");
  const [consigneeSearchValue, setConsigneeSearchValue] = useState("");
  const [billToSearchValue, setBillToSearchValue] = useState("");
  const [quantityInTonnes, setQuantityInTonnes] = useState("");
  const [customerInvoiceNumber, setCustomerInvoiceNumber] = useState("");
  const [customerInvoiceAttachment, setCustomerInvoiceAttachment] = useState({
    attachment_tag: "customer_invoice_attachment",
    attachment_type: "",
    attachment_file: null,
  });

  const [paymentStatusList, setPaymentStatusList] = useState([
    "unpaid",
    "partially_paid",
  ]);

  const tripDetailsFromRedux = useSelector((state) => state.trip.tripDetails);

  const driverDetailsFromRedux = useSelector(
    (state) => state.trip.driverDetails
  );

  const handleSourceFocus = (e) => {
    e.preventDefault();
    setErrSourceFinal("");
    // toggleDrawer("right", true)(e);
    setCurrentTab("source");
  };

  const resetFormDriver = () => {
    setErrDriverDetails({
      errFirstName: "",
      errMobileNumber: "",
      errPanNumber: "",
      errDLNumber: "",
      errPanDocs: "",
      errDLDocs: "",
    });
    setDrivingPhoto(null);
    setDriverDetails({
      first_name: "",
      last_name: "",
      mobile_number: "",
      pan_number: "",
      driving_licence_number: "",
      alternate_mobile_number: "",
    });
  };

  const getTripDetailsFromApi = async (tripId) => {
    const response = await getTripDetailsFromId(tripId);

    dispatch(updateTripDetails(response));
    dispatch(updateDriverDetails(response.driver));
  };

  useEffect(() => {
    if (tripId) {
      getTripDetailsFromApi(tripId);
    }
  }, [tripId]);

  const handleAttachmentChange = (e, index) => {
    e.preventDefault();
    //
    let key = e.target.name;
    let value;
    if (key == "attachment_file") {
      value = e.target.files[0];
      //
    } else {
      value = e.target.value;
    }
    //
    //

    let attachmentArrayTemp = [...attachmentListArray];

    if (index == 0 && loadSource != "trip_invoice") {
      attachmentArrayTemp[0]["attachment_tag"] = "bilty_copy";
    }

    attachmentArrayTemp[index][key] = value;
    setAttachmentListArray(attachmentArrayTemp);
    //
    //
  };

  const getWareHouseListApi = async () => {
    var wareHouseList = await getWarehouseList(selectedCustomer.id, 1);
    setWareHouseList(wareHouseList.warehouses_list);
    //

    // setState(address.data.state);
    // setDistrict(address.data.district);
    // setVillageList(address.data.office[0].villages);

    // setNewVillagesList(address.data.office[0].villages);
    // }
  };

  const vertical = "bottom";
  const horizontal = "right";

  useEffect(() => {
    if (!(vehiclePayoutPeriod == "monthly_wise")) {
      let tempperKm =
        (tripCost.length > 0 ? tripCost[1].cost_amount : 0) / tripDistance;
      setPerKmRate(tempperKm.toFixed(2));
    }
  }, [tripDistance, tripCost]);

  useEffect(() => {
    //
    if (Object.keys(selectedCustomer).length > 0) {
      //
      getWareHouseListApi();
    }
  }, [selectedCustomer]);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.title,
  });
  //

  useEffect(() => {
    //

    if (Object.keys(tripDetailsFromRedux).length > 0) {
      setEditMode(true);
      setSourceDetails(tripDetailsFromRedux.source);
      setTripIdEdit(tripDetailsFromRedux.id);
      setSelectedCustomer(tripDetailsFromRedux.customers_details);
      setSelectedConsignee(
        tripDetailsFromRedux.consignee_details
          ? tripDetailsFromRedux.consignee_details
          : {}
      );
      setSelectedBillToParty(tripDetailsFromRedux.bill_to_party_details);
      // setApproxCostDetails(tripDetailsFromRedux.approx_trip_cost);
      setLoadSource(tripDetailsFromRedux.load_source);
      setDestinationDetails(tripDetailsFromRedux.destination);
      setPaymentStatus(tripDetailsFromRedux.payments_status);
      setPaymentTerms(tripDetailsFromRedux.payment_terms);

      setTripType(
        tripDetailsFromRedux.trip_type ? tripDetailsFromRedux.trip_type : ""
      );

      setPerKmRate(tripDetailsFromRedux.per_km_rate);
      setValueOfGoods(tripDetailsFromRedux.value_of_goods);
      if (tripDetailsFromRedux.trip_eway_bill) {
        setEwayBillNumber(
          tripDetailsFromRedux.trip_eway_bill?.eway_bill_number
        );
        setEwayBillFrom(
          tripDetailsFromRedux.trip_eway_bill?.eway_bill_valid_from
        );
        setEwayBillTo(
          tripDetailsFromRedux.trip_eway_bill?.eway_bill_valid_till
        );
      }
      // setEwayBillNumber(tripDetailsFromRedux.value_of_goods);
      setVehiclePayoutPeriod(tripDetailsFromRedux.vehicle_payout_period);
      setTripStartMeterReadingAttachment({
        ...tripStartMeterReadingAttachment,
        attachment_file: "sample-file",
      });
      setTripStartMeterReading(tripDetailsFromRedux.trip_start_meter_reading);
      if (tripDetailsFromRedux.trip_cost.length > 1) {
        //
        let tripCostTemp = [
          ...tripDetailsFromRedux.trip_cost.map((item) => ({
            ...item,
            cost_amount: `${item.cost_amount}`,
          })),
        ];

        //
        if (tripCostTemp[0]["cost_type"] == "trip_cost") {
          tripCostTemp.reverse();
          //
        }
        setTripCost([...tripCostTemp]);
        // console.log(
        //   "trip cost type in useeffect",
        //   tripCostTemp[0]["cost_type"]
        // );
      } else if (tripDetailsFromRedux.trip_cost.length == 1) {
        //
        setTripCost([
          {
            cost_type: "advance",
            cost_amount: "",
          },
          ...tripDetailsFromRedux.trip_cost.map((item) => ({
            ...item,
            cost_amount: `${item.cost_amount}`,
          })),
        ]);
      } else {
        //

        setTripCost([...tripDetailsFromRedux.trip_cost]);
      }

      setTotalQty(tripDetailsFromRedux.number_of_pieces);
      // setPaymentOptions(tripDetailsFromRedux.trip_payment);
      setDriverDetails(driverDetailsFromRedux);
      setDriverId(driverDetailsFromRedux.id);

      if (tripDetailsFromRedux.trip_customer_invoice.length > 0) {
        setAdvanceByCustomer(
          tripDetailsFromRedux.trip_customer_invoice[0].advance_paid_by_customer
        );
        setBilledByCustomer(
          tripDetailsFromRedux.trip_customer_invoice[0].customer_billed_amount
        );
      }

      // setAvailableVehicleList([
      //   { vehicle_number: tripDetailsFromRedux.vehicle_number },
      // ]);
      setLoadUnit(
        loadUnitList.length > 0
          ? loadUnitList.filter(
            (item) => item.id == tripDetailsFromRedux.load_unit_id
          )[0].unit_code
          : null
      );

      // console.log(
      //   "load unit id",
      //   loadUnitList.length > 0
      //     ? loadUnitList.filter(
      //         (item) => item.id == tripDetailsFromRedux.load_unit_id
      //       )[0].unit_code
      //     : null
      // );

      // setPaymentOptions({payment_mode:})

      setVehicleNumber(tripDetailsFromRedux.vehicle_number);
      setReferenceNumberManual(tripDetailsFromRedux.reference_number_for_trip);
      setEntityType(tripDetailsFromRedux.entity_type);
      setSelectedTransporterName(tripDetailsFromRedux.transporter_name);
      setTransporterNameId(tripDetailsFromRedux.transporter_id);
      setTripDistance(tripDetailsFromRedux.estimated_kms);
      setQuantityInTonnes(tripDetailsFromRedux.quantity_in_tonnes);
      setCustomerInvoiceNumber(tripDetailsFromRedux.customer_invoice);

      if (tripDetailsFromRedux.load_source == "trip_purchase_order") {
        setPurchaseOrderId(tripDetailsFromRedux.reference_number);
        //
      } else if (tripDetailsFromRedux.load_source == "trip_invoice") {
        setInvoiceId(tripDetailsFromRedux.reference_number);
        //
        // setInvoiceId([
        //   {
        //     reference_number: tripDetailsFromRedux.reference_number,
        //     reference_id: tripDetailsFromRedux.purchaseorder_id,
        //   },
        // ]);
      }
    }
  }, [tripDetailsFromRedux, driverDetailsFromRedux, loadUnitList]);

  useEffect(() => {
    toggleDrawer("right", true);
  }, [currentTab]);

  const handleDriverSelect = (e) => {
    setDriverDetails(e);
    setDriverId(e.id);
    //
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleAddCustomer = () => {
    setCurrentTab("customer");
    toggleDrawer("right", true);
  };

  const handleAddAttachment = (e) => {
    let attachmentTemp = [...attachmentListArray];
    attachmentTemp.push({
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    });
    setAttachmentListArray(attachmentTemp);
    e.preventDefault();
  };

  const handleGetCustomerSearch = async (searchTerm) => {
    setIsLoading(true);
    const response = await GetCustomerSearchList("faarms");
    if (Object.keys(tripDetailsFromRedux).length > 0) {
      const filtered_cutomer = response.customers_list.filter(
        (item) => !item.name.toLowerCase().includes("faarm")
      );
      setCustomerList(filtered_cutomer);
    } else {
      setCustomerList(response.customers_list);
    }

    const customer = response.customers_list.filter(
      (item) => item.name === "Faarms"
    )[0];

    setSelectedCustomer(customer);
    setSelectedConsignee(customer);
    setSelectedBillToParty(customer);
    setIsLoading(false);
  };

  // const handleGetCustomerSearch = async (searchTerm) => {
  //   const response = await GetCustomerSearchList(searchTerm);
  //   setCustomerList(response.customers_list);
  // };

  useEffect(() => {
    handleGetCustomerSearch("faarms");
  }, []);

  // useEffect(() => {
  //   if (consigneeSearchValue) {
  //     handleGetCustomerSearch(consigneeSearchValue);
  //   } else {
  //     // handleGetCustomerSearch("");
  //     setCustomerList([]);
  //   }
  // }, [consigneeSearchValue]);

  // useEffect(() => {
  //   if (billToSearchValue) {
  //     handleGetCustomerSearch(billToSearchValue);
  //   } else {
  //     // handleGetCustomerSearch("");
  //     setCustomerList([]);
  //   }
  // }, [billToSearchValue]);

  // const handleSourceBlur = (e) => {
  //   e.preventDefault();
  //   toggleDrawer("right", false);
  //   setCurrentTab(null);
  // };

  const handleDriverFocus = (e) => {
    e.preventDefault();
    setErrDriverFinal("");
    setCurrentTab("driver");
  };
  //
  // const focusAsync = () => {
  //
  //   const asyncRef!.focus();
  // };

  const handleSearchButtonInvoices = () => {
    if (referenceNumberSearchValue.length > 5) {
      if (loadSource === "trip_invoice") {
        handleFetchInvoices(referenceNumberSearchValue);
      } else if (loadSource === "trip_purchase_order") {
        handleFetchPurchaseOrders(referenceNumberSearchValue);
      }
    }

    // setIsMenuOpen(true);
    // setInvoiceButtonPressed(true);
  };

  //

  const handleChangeTripMeterReadingAttachment = (e) => {
    setTripStartMeterReadingAttachment({
      ...tripStartMeterReadingAttachment,
      attachment_file: e.target.files[0],
    });
  };

  const handleBhadaChitiAttachment = (e) => {
    setBhadaChitiAttachment({
      ...bhadaChitiAttachment,
      attachment_file: e.target.files[0],
    });
  };

  const handleCustomerInvoiceAttachment = (e) => {
    setCustomerInvoiceAttachment({
      ...customerInvoiceAttachment,
      attachment_file: e.target.files[0],
    });
  };

  const handleGetCustomerById = async (customerId) => {
    const response = await GetCustomerById(customerId);
    if (response) {
      return response;
    }
  };

  // useEffect(()=>{
  //   handleGetCustomerById()
  // },[])

  const handleFetchCustomersList = async (tripDetailsFromRedux) => {
    const customersReponse = await GetCustomersList();
    setCustomerList(customersReponse.customers_list);
    if (Object.keys(tripDetailsFromRedux).length > 0) {
      const selectedCustomerTemp = customersReponse.customers_list.filter(
        (customer) => customer.id === tripDetailsFromRedux.customer_id
      );
      if (selectedCustomerTemp.length > 0) {
        setSelectedCustomer(selectedCustomerTemp[0]);
      }

      const selectedConsigneeTemp = customersReponse.customers_list.filter(
        (customer) => customer.id === tripDetailsFromRedux.customer_id
      );
      if (selectedCustomerTemp.length > 0) {
        setSelectedCustomer(selectedCustomerTemp[0]);
      }
    }
  };

  // useEffect(() => {
  //
  //   setCostKeys(Object.keys(approxCostDetails));
  // }, [approxCostDetails]);

  useEffect(() => {
    return () => {
      dispatch(updateTripDetails({}));
      dispatch(updateDriverDetails({}));
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      const unitData = await GetUnitList();
      setLoadUnitList(unitData.unit_list);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (totalQuantityForPBC.length > 0) {
      //
      const totalTemp = totalQuantityForPBC
        .map((item) => Object.values(item)[0])
        .reduce((a, b) => a + b);
      //

      // console.log(
      //   "array of values",
      //   (tripCost[1].cost_amount / totalTemp).toFixed(2)
      // );
      if (tripCost.length > 0) {
        setPbcCostPurchase((tripCost[1].cost_amount / totalTemp).toFixed(2));
      } else {
        setPbcCostPurchase("0");
      }
    } else if (totalQty > 0) {
      if (tripCost.length > 0) {
        setPbcCostPurchase((tripCost[1].cost_amount / totalQty).toFixed(2));
      } else {
        setPbcCostPurchase("0");
      }
    } else {
      setPbcCostPurchase("0.0");
    }
  }, [totalQuantityForPBC, tripCost, totalQty]);

  //
  //

  useEffect(() => {
    async function fetchData() {
      const documentTypeData = await GetDocumentEnums();
      setDocumentTypeList(documentTypeData);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const entityTypeData = await GetEntityTypeEnums();
      setEntityTypeList(entityTypeData);
    }
    fetchData();
  }, []);

  // useEffect(() => {
  //   if (Object.keys(tripDetailsFromRedux).length > 0) {
  //     handleFetchCustomersList(tripDetailsFromRedux);
  //   }
  // }, [tripDetailsFromRedux]);

  const checkValidationAll = async () => {
    //driver validation
    const isDriverValid = await DriverSchema.isValid(driverFormData);
    if (!isDriverValid) {
      setErrDriverFinal("Please enter driver details");
    }

    //destination validation
    let destinationValid = [];
    const destinationValidArray = destinationDetails.map(
      async (address, index) => {
        //
        const destinationFormData = {
          concern_person_name: address.concern_person_name,
          concern_person_mobile_number: address.concern_person_mobile_number,
          postcode: address.postcode,
          village: address.village,
        };
        //
        const isDestinationValid = await DestinationAddressSchema.isValid(
          destinationFormData
        );

        //
        destinationValid.push({ index: index, valid: isDestinationValid });
        if (!isDestinationValid) {
          setErrDestinationFinal("Please enter destination address");
        }
      }
    );

    //source validation
    let sourceValid = [];
    const sourceValidArray = sourceDetails.map(async (address, index) => {
      const sourceFormData = {
        concern_person_name: address.concern_person_name,
        concern_person_mobile_number: address.concern_person_mobile_number,
        postcode: address.postcode,
        village: address.village,
      };
      const isSourceValid = await SourceAddressSchema.isValid(sourceFormData);
      sourceValid.push({ index: index, valid: isSourceValid });
      if (!isSourceValid) {
        setErrSourceFinal("Please enter source address");
      }
    });
    const waitingForPromiseDestination = await Promise.all(
      destinationValidArray
    );
    const waitingForPromiseSource = await Promise.all(sourceValidArray);
    //
    //
    setErrSourceArray(sourceValid);
    setErrDestinationArray(destinationValid);
    // console.log("sourceValid.indexOf(false)>-1", sourceValid[0].valid);

    const allFieldsValid = sourceValid[0].valid && destinationValid[0].valid;
    const tripTypeValid = tripType;
    //
    let tripAdvanceValid = false;
    if (vehiclePayoutPeriod == "monthly_wise") {
      tripAdvanceValid = true;
    } else {
      tripAdvanceValid =
        tripCost[0].cost_amount === ""
          ? true
          : parseInt(tripCost[0].cost_amount) == 0
            ? false
            : parseInt(tripCost[0].cost_amount) < 0
              ? false
              : parseInt(tripCost[0].cost_amount) <=
                parseInt(tripCost[1].cost_amount)
                ? true
                : false;
    }
    let isEwayBillValid = true;
    if (ewayBillFrom || ewayBillNumber || ewayBillTo) {
      isEwayBillValid =
        ewayBillFrom &&
        ewayBillNumber &&
        ewayBillTo &&
        ewayBillFrom <= ewayBillTo;
    }
    const fileSizeValid = tripStartMeterReadingAttachment.attachment_file
      ? tripStartMeterReadingAttachment.attachment_file.size <
        process.env.REACT_APP_MAX_FILE_SIZE
        ? true
        : false
      : false;

    const customerInvoiceValid = customerInvoiceAttachment.attachment_file
      ? customerInvoiceAttachment.attachment_file.size <
        process.env.REACT_APP_MAX_FILE_SIZE
        ? true
        : false
      : false;

    const isCustomerValid = Object.keys(selectedCustomer).length > 0;
    const isConsigneeValid =
      Object.keys(selectedCustomer).length > 0
        ? selectedCustomer?.name?.toLowerCase().includes("faarms")
          ? true
          : Object.keys(selectedConsignee).length > 0
        : true;

    const isBillToPartyValid =
      Object.keys(selectedCustomer).length > 0
        ? selectedCustomer?.name?.toLowerCase().includes("faarms")
          ? true
          : Object.keys(selectedBillToParty).length > 0
        : true;

    const isBhadaChitiValid = bhadaChitiAttachment.attachment_file
      ? true
      : false;

    //

    return (
      allFieldsValid &&
      tripTypeValid &&
      driverId &&
      transporterNameId &&
      driverDetails.mobile_number.length === 10 &&
      tripAdvanceValid &&
      tripStartMeterReading &&
      tripStartMeterReadingAttachment.attachment_file &&
      perKmRate &&
      (!fileSizeValid && editMode ? true : fileSizeValid) &&
      (!customerInvoiceValid && editMode ? true : customerInvoiceValid) &&
      valueOfGoods &&
      isEwayBillValid &&
      isConsigneeValid &&
      isCustomerValid &&
      isBillToPartyValid &&
      isBhadaChitiValid
    );
  };

  useEffect(() => {
    if (totalQuantityForPBC.length > 0) {
      const totalTemp = totalQuantityForPBC
        .map((item) => Object.values(item)[0])
        .reduce((a, b) => a + b);
      setTotalQty(totalTemp);
    } else {
      setTotalQty(0);
    }
  }, [totalQuantityForPBC]);

  const handleAddDriver = () => {
    toggleDrawer("right", true);
    setErrDriverFinal("");
    setCurrentTab("driver");
  };

  const handleRemoveInvoice = (invoice) => {
    setInvoiceId(
      invoiceId.filter((item) => {
        setValueOfGoods(valueOfGoods - item[2]);
        return item != invoice;
      })
    );
    invoiceQuantityRemove(invoice[1]);
  };

  const handleRemovePurchaseOrder = (invoice) => {
    setPurchaseOrderId(
      purchaseOrderId.filter((item) => {
        return item != invoice;
      })
    );
    let totalQuantityTemp = totalQuantityForPBC;

    setTotalQuantityForPBC(
      totalQuantityTemp.filter((item) => !item.hasOwnProperty(invoice))
    );
  };

  const handleGetWarehouseUsers = async (warehouseId) => {
    const response = await getWarehouseUsersList(warehouseId, 1);

    setWarehouseUserList(response.warehouse_users_list);
  };

  const handleSelectWarehouseUser = async (
    warehouseUser,
    index,
    addressType
  ) => {
    setSelectedWarehouseUser(warehouseUser);
    if (addressType == "source") {
      let warehouseUserTemp = {};
      warehouseUserTemp["concern_person_name"] = `${warehouseUser.first_name} ${warehouseUser.last_name ? warehouseUser.last_name : ""
        }`;
      warehouseUserTemp["concern_person_mobile_number"] =
        warehouseUser.mobile_number;
      let sourceDetailsTemp = [...sourceDetails];
      sourceDetailsTemp[index] = {
        ...sourceDetailsTemp[index],
        ...warehouseUserTemp,
      };
      setSourceDetails([...sourceDetailsTemp]);
    } else {
      let warehouseUserTemp = {};
      warehouseUserTemp["concern_person_name"] = `${warehouseUser.first_name} ${warehouseUser.last_name ? warehouseUser.last_name : ""
        }`;
      warehouseUserTemp["concern_person_mobile_number"] =
        warehouseUser.mobile_number;
      let destinationDetailsTemp = [...destinationDetails];
      destinationDetailsTemp[index] = {
        ...destinationDetailsTemp[index],
        ...warehouseUserTemp,
      };
      setDestinationDetails([...destinationDetailsTemp]);
    }
  };

  const handleSelectWarehouse = async (
    warehouseDetails,
    index,
    addressType
  ) => {
    handleGetWarehouseUsers(warehouseDetails.id);
    setSelectedWarehouse(warehouseDetails);

    if (addressType == "source") {
      let sourceDetailsTemp = [...sourceDetails];
      warehouseDetails["type"] = "source";
      sourceDetailsTemp[index] = {
        ...sourceDetailsTemp[index],
        ...warehouseDetails,
      };
      setSourceDetails([...sourceDetailsTemp]);
      const response = await GetAddressFromPincode(warehouseDetails.postcode);
      // handleGetAddressSource(warehouseDetails.postcode, index);
      setSourceVillagesList(response.office[0].villages);
    } else {
      let destinationDetailsTemp = [...destinationDetails];
      destinationDetailsTemp[index] = {
        ...destinationDetailsTemp[index],
        ...warehouseDetails,
      };
      warehouseDetails["type"] = "destination";
      setDestinationDetails([...destinationDetailsTemp]);
      const response = await GetAddressFromPincode(warehouseDetails.postcode);
      // handleGetAddressSource(warehouseDetails.postcode, index);
      setDestinationVillagesList(response.office[0].villages);
      // handleGetAddressDestination(warehouseDetails.postcode, index);
    }
  };
  const newSourceAddressForEdit = {
    new: [],
    changed: [],
    deleted: [],
    unchanged: sourceDetails,
  };
  const newDestinationAddressForEdit = {
    new: [],
    changed: [],
    deleted: [],
    unchanged: destinationDetails,
  };
  const handleCreateTrip = async () => {
    const isTripValid = await TripSchema.isValid(TripFormData);

    if (isTripValid && (await checkValidationAll())) {
      if (
        attachmentListArray
          .map((item) => {
            if (item.attachment_file) {
              if (
                item.attachment_file.size > process.env.REACT_APP_MAX_FILE_SIZE
              ) {
                return "greater";
              }
            }
          })
          .indexOf("greater") > -1
      ) {
        return;
      }
      setLoadingButton(true);

      if (!loadingButton) {
        if (editMode) {
          //
          const response = await UpdateTripPutForFaarms(
            newSourceAddressForEdit,
            newDestinationAddressForEdit,
            vehicleNumber,
            transporterNameId,
            tripDistance,
            tripIdEdit,
            selectedCustomer.id,
            driverId,
            paymentTerms,
            totalQty,
            tripCost,
            loadSource,
            perKmRate,
            tripStartMeterReading,
            advanceByCustomer,
            billedByCustomer,
            valueOfGoods,
            ewayBillNumber,
            ewayBillFrom,
            ewayBillTo,
            ewayBillAttachment,
            tripType,
            selectedConsignee?.id
              ? selectedConsignee?.id
              : selectedCustomer?.id,
            selectedBillToParty?.id
              ? selectedBillToParty?.id
              : selectedCustomer?.id,
            customerInvoiceAttachment,
            customerInvoiceNumber,
            quantityInTonnes,
            bhadaChitiAttachment,
            tripStartMeterReadingAttachment
          );
          //
          if (response?.status === 400) {
            setLoadingButton(false);
            setErrCreateTripFinal(response.data.detail);
          } else if (response?.status === 200) {
            localStorage.setItem("FlagForApiCall", false);
            // localStorage.setItem("reloadPage", true);
            // localStorage.removeItem("localStorageTripList");
            history.push("/old-trips");
            setLoadingButton(false);
          } else if (response.status == 422) {
            if (response.data.detail.length > 0) {
              setErrCreateTripFinal(
                `Error 422 : ${response.data.detail[0].msg}`
              );
            }
            setLoadingButton(false);
          } else {
            setLoadingButton(false);
            setErrCreateTripFinal(`ERROR : ${response?.status}`);
          }
        } else {
          // approxCostDetails["cost_type"] = "approx_trip_cost";
          const response = await CreateTripPostForFaarms(
            sourceDetails,
            destinationDetails,
            driverDetails,
            // approxCostDetails,
            vehicleNumber,
            transporterNameId,
            driverId,
            driverDetails.mobile_number,
            loadSource,
            loadUnit,
            loadSource == "trip_invoice"
              ? invoiceId.length > 0
                ? invoiceId.map((item) => ({
                  reference_number: item[0],
                  reference_id: item[1],
                }))
                : []
              : loadSource == "trip_purchase_order"
                ? purchaseOrderId.length > 0
                  ? purchaseOrderId.map((item) => ({
                    reference_number: item[0],
                    reference_id: item[1],
                  }))
                  : []
                : referenceNumberManual.length > 0
                  ? referenceNumberManual.split(",").map((item) => {
                    if (item) {
                      return { reference_id: item, reference_number: item };
                    }
                  })
                  : [],

            tripStatus,
            tripDistance,
            paymentStatus,
            paymentTerms,
            tripCost,
            totalQty,
            selectedCustomer.id,
            advanceByCustomer,
            billedByCustomer,
            tripStartMeterReading,
            tripStartMeterReadingAttachment,
            perKmRate,
            !selectedCustomer.name.toLowerCase().includes("faarms")
              ? "others"
              : entityType,
            valueOfGoods,
            ewayBillNumber,
            ewayBillFrom,
            ewayBillTo,
            ewayBillAttachment,
            tripType,
            selectedConsignee?.id
              ? selectedConsignee?.id
              : selectedCustomer?.id,
            selectedCustomer,
            selectedBillToParty?.id
              ? selectedBillToParty?.id
              : selectedCustomer?.id,
            bhadaChitiAttachment,
            quantityInTonnes,
            customerInvoiceNumber,
            customerInvoiceAttachment
          );
          if (response == "failed") {
            setLoadingButton(false);
          } else if (response.status == 201) {
            localStorage.setItem("FlagForApiCall", false);
            // localStorage.setItem("reloadPage", true);
            // localStorage.removeItem("localStorageTripList");

            const attachmentResponse = TripAttachmentPost(
              "kk",
              response.data.id,
              "lso",
              "lsp",
              "start_trip",
              attachmentListArray
            );

            const resolved = await Promise.all(attachmentResponse);
            history.push("/old-trips");

            // const url = "/trips";
            // window.open(url, "_blank");
            setLoadingButton(false);
          } else if (response.status == 401) {
            setPermission("Not authorized to create trip");
            setLoadingButton(false);
          } else if (response.status == 400) {
            setErrCreateTripFinal(response.data.detail);
            setLoadingButton(false);
          } else if (response.status == 422) {
            if (response.data.detail.length > 0) {
              setErrCreateTripFinal(
                `Error 422 : ${response.data.detail[0].msg}`
              );
            }

            setLoadingButton(false);
          } else {
            setErrCreateTripFinal(`Error : ${response.status}`);
            setLoadingButton(false);
          }
        }
      }
    } else {
      setErrTrip({
        errLoadSource: "Please select source",
        errReferenceId: "Please select reference Id",
        errVehicleNumber: "Please select vehicle",
        errTransporter: "Please select Transporter",
        errLoadUnit: "Please select load unit",
        errTripDistance: "Please enter trip distance",
        errPaymentTerms: "Please enter payment terms",
        errTotalCost: "Please enter cost",
        errAdvanceCost: "Please enter advance",
        errTotalQty: "Please enter quantity",
        errCustomer: "Please select consignor",
        errConsignee: "Please select consignee",
        errBillToParty: "Please select bill to party",
        errTripStartMeterReading: "Please enter meter reading",
        errTripStartMeterReadingAttachment: "Please select attachment",
        errPerKmRate: "Please enter per Km Rate",
        errValueOfGoods: "Please enter value of goods",
        errTripType: "Please select trip type",
        // errPaymentStatus: "Please select payment status",
        errCustomerInvoice: "Please enter Customer Invoice Number",
        errCustomerInvoiceAttachment: "Please upload attachment",
        errBhadaChitiAttachment: "Please upload attachment",
      });
      setErrEwayBill({
        errEwayNumber: "Please enter E-way number",
        errEwayFrom: "Please enter E-way from date",
        errEwayTo: "Please enter E-way till date",
      });
    }
  };

  const handleGetAddressSource = async (pincode, index) => {
    const response = await GetAddressFromPincode(pincode);


    if (response) {
      const source = sourceDetails;
      source[index]["district"] = response.district;
      source[index]["sub_district"] = response.sub_district;
      source[index]["state"] = response.state;
      source[index]["postoffice"] = response.office[0].name;
      source[index]["postcode"] = response.pincode;

      setSourceVillagesList(response.office[0].villages);
    } else {
      setErrPincodeNotServiceable("This Pincode not serviceable");
    }
  };

  const handleGoBack = () => {
    localStorage.setItem("FlagForApiCall", false);
    // localStorage.setItem("doNotReloadPage", true);
    history.push("/old-trips");
  };

  const SourceAddressSchema = yup.object().shape({
    concern_person_name: yup.string().required(),
    concern_person_mobile_number: yup
      .number()
      .min(6000000000)
      .max(9999999999)
      .required(),
    postcode: yup.number().min(100000).max(999999).required(),
    village: yup.string().min(2).required(),
  });

  const DestinationAddressSchema = yup.object().shape({
    concern_person_name: yup.string().required(),
    concern_person_mobile_number: yup
      .number()
      .min(6000000000)
      .max(9999999999)
      .required(),
    postcode: yup.number().min(100000).max(999999).required(),
    village: yup.string().min(2).required(),
  });

  const DriverSchema = yup.object().shape({
    first_name: yup.string().required(),
    mobile_number: yup.number().min(6000000000).max(9999999999).required(),

    driving_licence_number: yup.string().min(6).required(),
    dl_docs: yup.mixed().required(),
  });

  const TripSchema = yup.object().shape({
    trip_source: yup.string().min(2),
    vehicle_number: yup.string().min(2).required(),
    trip_distance: yup.number().min(1),
    load_unit: yup.string().min(2).required(),
    payment_terms: yup.string().min(2).required(),
    trip_cost: yup.number().min(10).required(),
  });

  const TripFormData = {
    trip_source: loadSource,
    load_unit: loadUnit,
    vehicle_number: vehicleNumber,
    trip_distance: tripDistance,
    payment_terms:
      vehiclePayoutPeriod == "monthly_wise" ? "nonee" : paymentTerms,
    trip_cost:
      vehiclePayoutPeriod == "monthly_wise"
        ? "1000"
        : tripCost.length > 1
          ? tripCost[1].cost_amount
          : null,
  };

  const handleOnChangeDestination = (e, index) => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;

    const destination = [...destinationDetails];

    destination[index][name] = value;

    setDestinationDetails(destination);
    setErrDestinationFinal("");
  };

  const handleOnChangeSource = (e, index) => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;

    const source = [...sourceDetails];

    source[index][name] = value;

    setSourceDetails(source);
    setErrSourceFinal("");
  };

  const invoiceQuantityUpdate = async (id) => {
    const invoiceItems = await getZohoInvoiceItemsDetails(id);

    const totalQty = invoiceItems.total_items
      .map((item) => item.item_quantity)
      .reduce((a, b) => a + b);

    const invoiceQtyTemp = [...invoiceTotalQuantity];
    invoiceQtyTemp.push(totalQty);
    setInvoiceTotalQuantity(invoiceQtyTemp);
    setTotalQty(invoiceQtyTemp.reduce((a, b) => a + b));
  };

  useEffect(() => {
    setQuantityInTonnes(totalQty * 0.001);
  }, [totalQty]);

  const invoiceQuantityRemove = async (id) => {
    const invoiceItems = await getZohoInvoiceItemsDetails(id);

    const totalQtyTemp = invoiceItems.total_items
      .map((item) => item.item_quantity)
      .reduce((a, b) => a + b);

    const invoiceQtyTemp = [...invoiceTotalQuantity];
    //remove from array
    const indexTemp = invoiceQtyTemp.indexOf(totalQtyTemp);
    if (indexTemp > -1) {
      invoiceQtyTemp.splice(indexTemp, 1);
    }

    //remove from array
    // invoiceQtyTemp.push(totalQtyTemp);
    setInvoiceTotalQuantity(invoiceQtyTemp);

    if (invoiceQtyTemp.length > 0) {
      setTotalQty(invoiceQtyTemp.reduce((a, b) => a + b));
    } else {
      setTotalQty(0);
    }

    // console.log(
    //   "totall",
    //   invoiceQtyTemp.reduce((a, b) => a + b)
    // );
  };

  const sourceFormData = {
    concern_person_name: sourceDetails.concern_person_name,
    concern_person_mobile_number: sourceDetails.concern_person_mobile_number,
    postcode: sourceDetails.postcode,
    village: sourceDetails.village,
  };

  const handleDestinationSave = async () => {
    const destinationFormData = {
      concern_person_name:
        destinationDetails[currentDestinationId - 1].concern_person_name,
      concern_person_mobile_number:
        destinationDetails[currentDestinationId - 1]
          .concern_person_mobile_number,
      postcode: destinationDetails[currentDestinationId - 1].postcode,
      village: destinationDetails[currentDestinationId - 1].village,
    };
    const isDestinationValid = await DestinationAddressSchema.isValid(
      destinationFormData
    );
    if (isDestinationValid) {
      toggleDrawer("right", false);
    } else {
      setErrDestinationAddress({
        errPersonName: "Please enter name",
        errPersonMobileNumber: "Please enter mobile number",
        errPostcode: "Please enter pincode",
        errVillage: "Please select village",
      });
    }
  };

  const handleSourceSave = async () => {
    const sourceFormData = {
      concern_person_name:
        sourceDetails[currentSourceId - 1].concern_person_name,
      concern_person_mobile_number:
        sourceDetails[currentSourceId - 1].concern_person_mobile_number,
      postcode: sourceDetails[currentSourceId - 1].postcode,
      village: sourceDetails[currentSourceId - 1].village,
    };
    const isSourceValid = await SourceAddressSchema.isValid(sourceFormData);
    if (isSourceValid) {
      toggleDrawer("right", false);
    } else {
      setErrSourceAddress({
        errPersonName: "Please enter name",
        errPersonMobileNumber: "Please enter mobile number",
        errPostcode: "Please enter pincode",
        errVillage: "Please select village",
        errorWarehouse: "Please select warehouse",
        errorWarehouseUser: "Please select warehouse user",
      });
    }
    //
  };

  const driverFormData = {
    first_name: driverDetails.first_name,
    mobile_number: driverDetails.mobile_number,
    // pan_number: driverDetails.pan_number,
    driving_licence_number: driverDetails.driving_licence_number,
    // pan_docs: driverPanImage,
    dl_docs: drivingPhoto,
  };
  const handleDrivinPan = (event) => {
    setDriverPanImage(event.target.files[0]);
  };

  const handleSourceRemove = (index) => {
    let sourceDetailsTemp = [...sourceDetails];
    sourceDetailsTemp.splice(index, 1);
    setSourceDetails(sourceDetailsTemp);
  };

  const handleDestinationRemove = (index) => {
    let destinationDetailsTemp = [...destinationDetails];
    destinationDetailsTemp.splice(index, 1);
    setDestinationDetails(destinationDetailsTemp);
  };

  const handleDrivingPhoto = (event) => {
    setDrivingPhoto(event.target.files[0]);
  };

  const handleCustomerLogo = (event) => {
    setAddCustomerData({ ...addCustomerData, logo: event.target.files[0] });
  };

  const handleDriverSave = async () => {
    const isDriverValid = await DriverSchema.isValid(driverFormData);
    const isDLSizeValid = drivingPhoto
      ? drivingPhoto.size > process.env.REACT_APP_MAX_FILE_SIZE
        ? false
        : true
      : true;

    if (isDriverValid && isDLSizeValid) {
      setDriverLoadingButton(true);

      const driverPostResponse = await CreateDriverPost(
        driverDetails.first_name,
        driverDetails.last_name,
        driverDetails.mobile_number,
        driverDetails.driving_licence_number,
        driverDetails.pan_number,
        driverPanImage,
        drivingPhoto,
        driverDetails.alternate_mobile_number
      );
      if (driverPostResponse.status === 200) {
        setDriverId(driverPostResponse.data.id);
        toggleDrawer("right", false);
        setDriverLoadingButton(false);
      } else if (driverPostResponse.status === 400) {
        setDriverLoadingButton(false);
        setErrTotalDriver(driverPostResponse.data.detail);
      } else {
        setDriverLoadingButton(false);
        setErrTotalDriver(`ERROR : ${driverPostResponse.status}`);
      }
      //
    } else {
      setErrDriverDetails({
        errFirstName: "Please enter first name",
        errMobileNumber: "Please enter mobile number",
        errPanNumber: "Please enter PAN number",
        errDLNumber: "Please enter DL number",
        errPanDocs: "Please upload PAN image/pdf",
        errDLDocs: "Please upload DL image/pdf",
      });
    }
  };

  const handleCustomerSave = async () => {
    // const isDriverValid = await DriverSchema.isValid(driverFormData);
    const isLogoSizeValid = addCustomerData
      ? addCustomerData.size > process.env.REACT_APP_MAX_FILE_SIZE
        ? false
        : true
      : true;
    //
    if (addCustomerData?.name && addCustomerData?.logo && isLogoSizeValid) {
      setCustomerLoadingButton(true);

      const customerPostResponse = await customersPost(
        addCustomerData?.name,
        addCustomerData?.logo,
        "image",
        addCustomerData?.description
      );
      if (customerPostResponse === 200) {
        handleCloseCustomerDrawer();
        // toggleDrawer("right", false);

        setCustomerLoadingButton(false);
      } else if (customerPostResponse?.status === 400) {
        setCustomerLoadingButton(false);
        setErrCustomerFinal(customerPostResponse.data.detail);
      } else {
        setCustomerLoadingButton(false);
        setErrCustomerFinal(`ERROR : ${customerPostResponse?.status}`);
      }
      //
    } else {
      setCustomerDetails({
        errName: "Please enter name",
        errLogo: "Please select logo file",
      });
    }
  };

  //

  const handleAddDestination = (e) => {
    // handleShow();
    setDestinationDetails([
      ...destinationDetails,
      {
        concern_person_name: "",
        concern_person_mobile_number: "",
        postcode: "",
        type: "destination",
        village: "0",
        postoffice: "",
        sub_district: "",
        district: "",
        state: "",
        landmark: "",
        latitude: "",
        longitude: "",
      },
    ]);
    // e.stopPropagation();
  };

  const handleAddSource = (e) => {
    e.stopPropagation();
    setSourceDetails([
      ...sourceDetails,
      {
        concern_person_name: "",
        concern_person_mobile_number: "",
        postcode: "",
        type: "source",
        village: "0",
        postoffice: "",
        sub_district: "",
        district: "",
        state: "",
        landmark: "",
        latitude: "",
        longitude: "",
      },
    ]);
  };

  const handleFetchPurchaseOrders = async (searchValue) => {
    setSearchInvoiceLoading(true);
    const response = await GetPurchaseOrders(searchValue);
    setSearchInvoiceLoading(false);

    setSearchResultsList(
      response.purchaseorders.map((item) => {
        return {
          value: [item.purchaseorder_number, item.purchaseorder_id],
          label: item.purchaseorder_number,
          quantity: item.total_ordered_quantity,
        };
      })
    );
  };

  function capitalizeLetter(str) {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags;
  }
  const handleFetchVehicles = async (searchValue, source) => {
    const response = await GetVehicles(searchValue, source);

    if (Object.keys(tripDetailsFromRedux).length > 0) {
      return [
        {
          value: tripDetailsFromRedux.vehicle_number,
          label: tripDetailsFromRedux.vehicle_number,
        },
        ...response.vehicle_list.map((item) => {
          const dateToday = moment().format("YYYY-MM-DD");

          let startDate = moment(dateToday, "YYYY-MM-DD");
          let expireDateRC = moment(
            item.registration_certificate_expiry_date,
            "YYYY-MM-DD"
          );
          let expireDateInsurance = moment(
            item.insurance_certificate_expiry_date,
            "YYYY-MM-DD"
          );
          let expireDatePermit = moment(item.permit_expiry_date, "YYYY-MM-DD");
          let expireDatePollution = moment(
            item.pollution_control_certificate_expiry_date,
            "YYYY-MM-DD"
          );
          let insuranceExpires = expireDateInsurance.diff(startDate, "days");
          let permitExpires = expireDatePermit.diff(startDate, "days");
          let pollutionExpires = expireDatePollution.diff(startDate, "days");
          let rcExpires = expireDateRC.diff(startDate, "days");
          return {
            value: item.vehicle_number,
            payout_period: item.payout_period,
            vehicle_manufacturer_name: item.vehicle_manufacturer_name,
            vehicle_type: item.vehicle_model_list.vehicle_type,
            extra_per_km_rate: item.extra_per_km_rate,
            insuranceExpires: expireDateInsurance.diff(startDate, "days"),
            permitExpires: expireDatePermit.diff(startDate, "days"),
            pollutionExpires: expireDatePollution.diff(startDate, "days"),
            rcExpires: expireDateRC.diff(startDate, "days"),
            label: (
              <div>
                <div>{item.vehicle_number}</div>
                <div>{item.vehicle_manufacturer_name}</div>
              </div>
            ),
          };
        }),
      ];
    }

    // console.log("doksdos", moment().format("MM"));
    return response.vehicle_list.map((item) => {
      const dateToday = moment().format("YYYY-MM-DD");

      let startDate = moment(dateToday, "YYYY-MM-DD");
      let expireDateRC = moment(
        item.registration_certificate_expiry_date,
        "YYYY-MM-DD"
      );
      let expireDateInsurance = moment(
        item.insurance_certificate_expiry_date,
        "YYYY-MM-DD"
      );
      let expireDatePermit = moment(item.permit_expiry_date, "YYYY-MM-DD");
      let expireDatePollution = moment(
        item.pollution_control_certificate_expiry_date,
        "YYYY-MM-DD"
      );
      let insuranceExpires = expireDateInsurance.diff(startDate, "days");
      let permitExpires = expireDatePermit.diff(startDate, "days");
      let pollutionExpires = expireDatePollution.diff(startDate, "days");
      let rcExpires = expireDateRC.diff(startDate, "days");
      return {
        value: item.vehicle_number,
        payout_period: item.payout_period,
        vehicle_manufacturer_name: item.vehicle_manufacturer_name,
        vehicle_type: item.vehicle_model_list.vehicle_type,
        extra_per_km_rate: item.extra_per_km_rate,
        insuranceExpires: expireDateInsurance.diff(startDate, "days"),
        permitExpires: expireDatePermit.diff(startDate, "days"),
        pollutionExpires: expireDatePollution.diff(startDate, "days"),
        rcExpires: expireDateRC.diff(startDate, "days"),
        label: (
          <Create_trip_vehicle_single
            style={{ display: "flex", width: "100%" }}
          // className="create-trip-vehicle-single"
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span className="create-trip-vehicle-number-text">
                    {item.vehicle_number}
                  </span>
                </div>
                <div>
                  {item.payout_period &&
                    item.payout_period == "monthly_wise" ? (
                    <div className="create-trip-monthly-container">
                      <img
                        src={MONTHLYICON}
                        className="create-trip-monthly-img"
                        alt=""
                      />
                      <h4 className="create-trip-monthly-text">Monthly</h4>
                    </div>
                  ) : (
                    <div className="create-trip-monthly-container">
                      <img
                        src={TRIPWISEICON}
                        className="create-trip-monthly-img"
                        alt=""
                      />
                      <h4 className="create-trip-monthly-text">Tripwise</h4>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginLeft: isMobile ? "10px" : "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                    color: "white",
                    padding: "1px 4px",
                    background:
                      item.status === "available"
                        ? colorPalette.primaryColor
                        : item.status === "blocked"
                          ? "#FF6666"
                          : item.status === "in_transit"
                            ? "#E5B35D"
                            : "#65c368",
                    borderTopLeftRadius: "3px",
                    borderBottomLeftRadius: "3px",
                  }}
                >
                  {item.status ? capitalizeLetter(item.status) : "--"}
                </div>
              </div>

              <div>
                <span className="create-trip-sub-text">
                  {item.vehicle_manufacturer_name} (
                  {item.vehicle_model_list.vehicle_type})
                </span>
              </div>
              <div>
                <span className="create-trip-sub-text">
                  {item.transporters_details}{" "}
                </span>
              </div>
              <div className="vehicle-expiry-check-container">
                <div className="vehile-expiry-single-container">
                  <div>
                    <img
                      src={rcExpires >= 0 ? TICKICON : WRONGICON}
                      className="create-trip-right-wrong-img"
                      alt=""
                    />
                  </div>
                  <div className="vehicle-expiry-text"> RC</div>
                </div>
                <div className="vehile-expiry-single-container">
                  <div>
                    <img
                      src={insuranceExpires >= 0 ? TICKICON : WRONGICON}
                      alt=""
                      className="create-trip-right-wrong-img"
                    />
                  </div>
                  <div className="vehicle-expiry-text"> Insurance</div>
                </div>
                <div className="vehile-expiry-single-container">
                  <div>
                    <img
                      src={permitExpires >= 0 ? TICKICON : WRONGICON}
                      alt=""
                      className="create-trip-right-wrong-img"
                    />
                  </div>
                  <div className="vehicle-expiry-text"> Permit</div>
                </div>
                <div className="vehile-expiry-single-container">
                  <div>
                    <img
                      src={pollutionExpires >= 0 ? TICKICON : WRONGICON}
                      alt=""
                      className="create-trip-right-wrong-img"
                    />
                  </div>
                  <div className="vehicle-expiry-text"> Pollution</div>
                </div>
              </div>
            </div>
          </Create_trip_vehicle_single>
        ),
      };
    });
  };
  function isNumber(value) {
    return /^[0-9]*$/.test(value);
  }
  const handleFetchTransporter = async (values) => {
    const integerValue = isNumber(values);

    const productResponse = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v3/transporter?name=${integerValue == false ? values.trim() : ""
      }&mobile=${integerValue ? values : ""}&status=approved&is_active=true`
    );

    if (productResponse.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return productResponse.data.transporter_list.map((item) => {
      return {
        // {
        //   "transporter_code": "FTR-1002",
        //   "name": "testing897i687",
        //   "mobile": "6789786874",
        //   "address": {
        //     "district": "PATIALA",
        //     "state": "PUNJAB",
        //   },

        // },

        value: item.id,
        transporter_code: item.transporter_code,
        name: item.name,
        mobile: item.mobile,
        // district: item.address.district,
        // state: item.address.state,

        label: (
          <div
            style={{ display: "flex" }}
            className="create-trip-vehicle-single"
          >
            <div>
              <div>
                <div>
                  <span className="create-trip-transporter-number-text">
                    {item.transporter_code}
                  </span>
                </div>
              </div>

              <div>
                <span className="create-trip-sub-text">
                  <PersonOutlineOutlinedIcon className="trip_transporter_icons" />
                  {item.name}
                </span>
              </div>
              <div>
                <span className="create-trip-sub-text">
                  {" "}
                  <PhoneAndroidOutlinedIcon className="trip_transporter_icons" />
                  {item.mobile}{" "}
                </span>
              </div>
              {/* <div>
                <span className="create-trip-sub-text">
                  <LocationOnOutlinedIcon className="trip_transporter_icons" />{" "}
                  {item.address.district},{item.address.state}
                </span>
              </div> */}
            </div>
          </div>
        ),
      };
    });

    // return productResponse.data.transporter_list.map((datas) => {
    //   return {
    //     value: datas.id,
    //     label: datas.name,
    //   };
    // });
  };
  const transportersOptions = (inputValue) =>
    new Promise((resolve) => {
      const response = handleFetchTransporter(inputValue);
      //

      resolve(response);
    });
  const handleFetchDrivers = async (searchValue) => {
    const response = await GetDrivers(searchValue);

    return response.drivers_list.map((item) => {
      return {
        value: item.driving_licence_number,
        label: (
          <div>
            <div>
              <span className="create-trip-select-list-right-text">
                {item.first_name + " "}
                {item.last_name ? item.last_name : ""}
              </span>
            </div>
            <div>
              <span className="create-trip-select-list-left-text">
                DL no :{" "}
              </span>
              <span className="create-trip-select-list-right-text">
                {item.driving_licence_number}
              </span>
            </div>
            <div>
              <span className="create-trip-select-list-left-text">
                Mobile :{" "}
              </span>
              <span className="create-trip-select-list-right-text">
                {item.mobile_number}
              </span>
            </div>
          </div>
        ),
        first_name: item.first_name,
        last_name: item.last_name,
        mobile_number: item.mobile_number,
        alternate_mobile_number: item.alternate_mobile_number,
        pan_number: item.pan_number,
        driving_licence_number: item.driving_licence_number,
        id: item.id,
      };
    });
  };

  const handleClearDriver = () => {
    setDriverDetails({
      first_name: "",
      last_name: "",
      mobile_number: "",
      pan_number: "",
      driving_licence_number: "",
      alternate_mobile_number: "",
    });
  };

  const handleTripCostChange = (e, index) => {
    let key = e.target.name;
    let value = e.target.value;

    let tripCostTemp = [...tripCost];
    tripCostTemp[index][key] = value;
    setTripCost([...tripCostTemp]);

    setErrTrip({ ...errTrip, errTotalCost: "", errAdvanceCost: "" });
  };

  const handleCloseDriverDrawer = () => {
    toggleDrawer("right", false);
  };

  const handleCloseCustomerDrawer = () => {
    toggleDrawer("right", false);
  };

  const handleInvoiceChange = async (e) => {
    // console.log("existing>>",invoiceId,"new>>>",e.value)
    // console.log("inoice dup>>>",invoiceId.filter((invoice)=>invoice[0]===e.value[0]))
    const duplicateInvoice = invoiceId.filter(
      (invoice) => invoice[0] === e.value[0]
    );
    if (duplicateInvoice.length === 0) {
      setInvoiceId([...invoiceId, e.value]);
    } else {
      setErrorMsg(`Invoice Number ${e.value[0]} already added`);
      setOpenToast(true);
      // console.log("Invoice already added")
    }
    if (
      invoiceId.length > destinationDetails.length - 1 &&
      loadSource == "trip_invoice"
    ) {
      handleAddDestination();
    }
    const tempIndex = invoiceId.length;

    handleGetAddressDestination(
      e.address.zipcode,
      tempIndex,
      e.address.attention,
      e.contactNumber,
      e.address.address
    );
    invoiceQuantityUpdate(e.value[1]);
    setSearchResultsList([]);
    setReferenceNumberSearchValue("");

    setValueOfGoods(Number(valueOfGoods) + Number(e.value[2]));
    e.stopPropagation();
  };

  const handleClearReferenceSearch = () => {
    setReferenceNumberSearchValue("");
    setSearchResultsList([]);
  };

  // useEffect(()=>{
  //   invoiceId?.map((d)=>setValueOfGoods(Number(valueOfGoods) + Number(d[2])))
  // },[invoiceId])

  // useEffect(async () => {
  //   invoiceQuantityUpdate();
  // }, [invoiceId]);

  const handleFetchInvoices = async (searchValue) => {
    setSearchInvoiceLoading(true);
    const response = await GetInvoices(searchValue);

    setSearchInvoiceLoading(false);
    setSearchResultsList(
      response.invoices
        .filter(
          (invoice) =>
            invoice.status.toLowerCase() === "draft" ||
            invoice.status.toLowerCase() === "overdue" ||
            invoice.status.toLowerCase() === "sent"
        )
        .map((item) => {
          return {
            value: [item.invoice_number, item.invoice_id, item.total],
            // value: item.invoice_number,
            label: item.invoice_number,
            address: item.shipping_address,
            contactNumber: item.custom_field_hash.cf_customer_contact,
          };
        })
    );

    return response.invoices
      .filter(
        (invoice) =>
          invoice.status.toLowerCase() === "draft" ||
          invoice.status.toLowerCase() === "overdue" ||
          invoice.status.toLowerCase() === "sent"
      )
      .map((item) => {
        return {
          value: [item.invoice_number, item.invoice_id, item.total],
          // value: item.invoice_number,
          label: item.invoice_number,
          address: item.shipping_address,
          contactNumber: item.custom_field_hash.cf_customer_contact,
        };
      });
  };

  const handleGetAddressDestination = async (
    pincode,
    index,
    name,
    phone,
    city
  ) => {
    const response = await GetAddressFromPincode(pincode);

    if (response) {
      const destination = [...destinationDetails];
      if (index >= destination.length) {
        destination.push({
          concern_person_name: "",
          concern_person_mobile_number: "",
          postcode: "",
          type: "destination",
          village: "0",
          postoffice: "",
          sub_district: "",
          district: "",
          state: "",
          landmark: "",
          latitude: "",
          longitude: "",
        });
      }

      destination[index]["district"] = response.district;
      destination[index]["sub_district"] = response.sub_district;
      destination[index]["state"] = response.state;
      destination[index]["postoffice"] = response.office[0].name;
      destination[index]["postcode"] = response.pincode;
      if (city || phone || name) {
        destination[index]["concern_person_name"] = name;
        destination[index]["concern_person_mobile_number"] = phone;
        destination[index]["village"] = city;
      }

      setDestinationVillagesList(response.office[0].villages);
      setDestinationDetails(destination);
    } else {
      setErrPincodeNotServiceable("This Pincode not serviceable");
    }
  };

  //Drawer starts

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
    // if (currentTab == "driver" && open == false) {
    //   resetFormDriver();
    // }
  };

  //Drawer ends

  //react-select starts//
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      const response = handleFetchPurchaseOrders(inputValue);
      resolve(response);
    });

  const promiseOptionsInvoices = (inputValue) =>
    new Promise((resolve) => {
      //
      let response = [];
      if (invoiceButtonPressed) {
        response = handleFetchInvoices(inputValue);
      }

      resolve(response);
    });
  //react-select-ends//

  const vehicleOptions = (inputValue, source) =>
    new Promise((resolve) => {
      const response = handleFetchVehicles(inputValue, source);

      resolve(response);
    });

  const driverOptions = (inputValue) =>
    new Promise((resolve) => {
      var response = handleFetchDrivers(inputValue);

      resolve(response);
    });

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  return (
    <Create_Trip_Main_Container
      className="create-trip-main-container"
      style={{ position: "relative" }}
    >
      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Create trip" />
        </div>
      ) : (
        <div className="create-trip-sidebar-container">
          <SideBarMain />
        </div>
      )}

      <div
        className="create-trip-right-container"
        style={{ position: "relative" }}
      >
        {/* {isMobile ? null : <Topbar />} */}
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              // width: "100%",
              // marginLeft: "50%",
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgb(0, 0, 0, 0.3)",
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: "9999",
            }}
          >
            <img
              src={success}
              alt=""
              width="120px"
              height="90px"
              style={{ marginTop: "100px" }}
            />
            <h6 style={{ color: "grey" }}>loading data...</h6>
          </div>
        ) : (
          ""
        )}
        <h4
          style={{
            fontSize: "20px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "30px",
            fontWeight: "500",
          }}
        >
          <ArrowBack
            onClick={handleGoBack}
            style={{
              color: colorPalette.primaryColor,
              marginRight: "14px",
              cursor: "pointer",
            }}
          />
          Enter trip details{" "}
        </h4>

        {/* <div className="create-trip-group-card"> */}
        <div className="create-trip-from-head"></div>

        <Snackbar
          open={openToast}
          autoHideDuration={8000}
          anchorOrigin={{ vertical, horizontal }}
          onClose={handleCloseToast}
        >
          <Alert
            variant="filled"
            elevation={6}
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMsg ? errorMsg : ""}
          </Alert>
        </Snackbar>

        <div className="create-trip-from-body">
          <div className="create-trip-from-row">
            {/* <div className="create-trip-input-container">
              <h4 className="create-trip-input-head">
                {" "}
                {Object.keys(selectedCustomer).length > 0
                  ? selectedCustomer?.name?.toLowerCase().includes("faarms")
                    ? "Customer"
                    : "Consignor"
                  : "Customer"}{" "}
                <sup style={{ color: "red" }}>*</sup>
              </h4>
              <div style={{ display: "flex" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={customerList}
                  // value={
                  //   Object.keys(selectedCustomer).length > 0
                  //     ? selectedCustomer
                  //     : ""
                  // }
                  defaultValue={
                    tripDetailsFromRedux?.customers_details
                      ? tripDetailsFromRedux?.customers_details
                      : { name: "" }
                  }
                  onChange={(event, newValue) => {
                    // setValue(newValue);
                     
                    if (newValue) {
                      setSelectedCustomer(newValue);
                      setCustomerSearchValue(newValue.name);
                      setCustomerList([]);
                      if (!newValue.name.toLowerCase().includes("faarms")) {
                        setLoadSource("manual");
                      }
                       
                    } else {
                      setSelectedCustomer({});
                    }
                     
                  }}
                  onInputChange={(event, newInputValue) => {
                     
                    if (event?.type === "change") {
                      setCustomerSearchValue(newInputValue);
                    }
                  }} // sx={{ width: 300 }}
                  style={{ width: "90%" }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </div> */}
          </div>
          {Object.keys(selectedCustomer).length > 0 ? (
            selectedCustomer?.name?.toLowerCase().includes("faarms") ? null : (
              <>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Consignee <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <div style={{ display: "flex" }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={customerList}
                        defaultValue={
                          tripDetailsFromRedux?.consignee_details
                            ? tripDetailsFromRedux?.consignee_details
                            : { name: "" }
                        }
                        // value={selectedCustomer ? selectedCustomer : ""}
                        // value={
                        //   Object.keys(selectedConsignee).length > 0
                        //     ? selectedConsignee
                        //     : ""
                        // }
                        onChange={(event, newValue) => {
                          // setValue(newValue);
                          if (newValue) {
                            setSelectedConsignee(newValue);
                            setConsigneeSearchValue(newValue.name);
                            setCustomerList([]);

                            // if (!newValue.name.toLowerCase().includes("faarms")) {
                            //   setLoadSource("manual");
                            // }
                          } else {
                            setSelectedConsignee({});
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          // console.log(
                          //   "new value>>>",
                          //   newInputValue,
                          //   event.type === "change"
                          // );
                          if (event?.type === "change") {
                            setConsigneeSearchValue(newInputValue);
                          }
                        }} // sx={{ width: 300 }}
                        style={{ width: "90%" }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      {/* <button
                        onClick={handleAddCustomer}
                        style={{
                          border: "none",
                          cursor: "pointer",
                          marginLeft: "10px",
                          fontSize: "13px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                          background: colorPalette.primaryColor,
                          color: "white",
                          flexDirection: "column",
                          padding: "2px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          height: "80%",
                        }}
                      >
                        <AddCircle />
                        Add
                      </button> */}
                    </div>

                    <div className="create-trip-error">
                      {Object.keys(selectedConsignee).length > 0
                        ? null
                        : errTrip.errConsignee}
                    </div>
                  </div>
                </div>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Bill To Party <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <div style={{ display: "flex" }}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={customerList}
                        defaultValue={
                          tripDetailsFromRedux?.bill_to_party_details
                            ? tripDetailsFromRedux?.bill_to_party_details
                            : { name: "" }
                        }
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedBillToParty(newValue);
                            setBillToSearchValue(newValue.name);
                            setCustomerList([]);
                          } else {
                            setSelectedBillToParty({});
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          if (event?.type === "change") {
                            setBillToSearchValue(newInputValue);
                          }
                        }}
                        style={{ width: "90%" }}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                      />

                      {/* <button
                        onClick={handleAddCustomer}
                        style={{
                          border: "none",
                          cursor: "pointer",
                          marginLeft: "10px",
                          fontSize: "13px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                          background: colorPalette.primaryColor,
                          color: "white",
                          flexDirection: "column",
                          padding: "2px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          height: "80%",
                        }}
                      >
                        <AddCircle />
                        Add
                      </button> */}
                    </div>
                    <div className="create-trip-error">
                      {Object.keys(selectedBillToParty).length > 0
                        ? null
                        : errTrip.errBillToParty}
                    </div>
                  </div>
                </div>
              </>
            )
          ) : null}
          {/* {Object.keys(selectedCustomer).length > 0 &&
          selectedCustomer?.name?.toLowerCase().includes("faarms") ? (
          
          ) : null} */}
          {/* {Object.keys(selectedCustomer).length > 0 ? (
            selectedCustomer.name.toLowerCase().includes("faarms") ? (
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Source <sup style={{ color: "red" }}>*</sup>
                  </h4>

                  <RadioGroup
                    className="create-trip-input-source"
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={loadSource}
                    onChange={(e) => setLoadSource(e.target.value)}
                  >
                    <div className="create-trip-source-radio">
                      <FormControlLabel
                        value="trip_invoice"
                        control={<Radio />}
                        label="Trip Invoice"
                        disabled={editMode ? true : false}
                      />

                      <FormControlLabel
                        value="trip_purchase_order"
                        control={<Radio />}
                        label="Trip Purchase Order"
                        disabled={editMode ? true : false}
                      />
                      <FormControlLabel
                        value="manual"
                        control={<Radio />}
                        label="Others"
                        disabled={editMode ? true : false}
                      />
                    </div>
                  </RadioGroup>
                  <div className="create-trip-error">
                    {loadSource == "0" ? errTrip.errLoadSource : null}
                  </div>
                </div>
              </div>
            ) : null
          ) : null} */}
          {loadSource === "manual" &&
            (Object.keys(selectedCustomer).length > 0
              ? selectedCustomer.name.toLowerCase().includes("faarms")
              : false) ? (
            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">Entity type</h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  select
                  disabled={editMode ? true : false}
                  className="create-trip-input"
                  variant="outlined"
                  value={entityType}
                  onChange={(e) => setEntityType(e.target.value)}
                >
                  <MenuItem value="0" disabled>
                    Select entity type
                  </MenuItem>
                  {entityTypeList.map((item) => {
                    return (
                      <MenuItem value={item}>
                        {item.split("_").join(" ")}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>

              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head"> Reference number</h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  disabled={editMode ? true : false}
                  // style={{ width: "91%" }}
                  placeholder="Enter reference number"
                  value={referenceNumberManual}
                  onChange={(e) => setReferenceNumberManual(e.target.value)}
                />
              </div>
            </div>
          ) : null}

          {(loadSource == "trip_invoice" ||
            loadSource == "trip_purchase_order") &&
            (Object.keys(selectedCustomer).length > 0
              ? selectedCustomer.name.toLowerCase().includes("faarms")
              : false) ? (
            <div
              style={{
                // border: "2px solid green",
                width: "90%",
                backgroundColor: colorPalette.secondaryWhite,
              }}
            >
              <div className="create-trip-from-row">
                {loadSource == "trip_invoice" ||
                  loadSource == "trip_purchase_order" ? (
                  <div
                    className="create-trip-input-container"
                  // id="purchase_main"
                  >
                    <h4 className="create-trip-input-head">
                      {" "}
                      {loadSource == "trip_invoice"
                        ? "Invoice"
                        : "Purchase Order"}{" "}
                      Number
                    </h4>

                    {loadSource == "trip_invoice" ? (
                      <div className="create-trip-reference-search-row">
                        <TextField
                          className="create-trip-input"
                          style={{
                            backgroundColor: "white",
                            position: "relative",
                          }}
                          variant="outlined"
                          isDisabled={editMode ? true : false}
                          placeholder="Search invoices"
                          size="small"
                          InputProps={
                            referenceNumberSearchValue.length > 0
                              ? {
                                endAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Close
                                      onClick={handleClearReferenceSearch}
                                    />
                                  </InputAdornment>
                                ),
                              }
                              : null
                          }
                          value={referenceNumberSearchValue}
                          onChange={(e) => {
                            setReferenceNumberSearchValue(e.target.value);
                            if (e.target.value.length == 0) {
                              setSearchResultsList([]);
                            }
                          }}
                        />
                        {referenceNumberSearchValue.length > 0 &&
                          searchResultsList.length > 0 ? (
                          <div className="create-trip-search-results-container">
                            {searchResultsList.map((item) => {
                              return (
                                <div
                                  className="create-trip-search-results-list"
                                  onClick={() => handleInvoiceChange(item)}
                                >
                                  {item.label}
                                </div>
                              );
                            })}
                          </div>
                        ) : null}

                        <div
                          onClick={() => handleSearchButtonInvoices()}
                          className={
                            referenceNumberSearchValue.length > 5
                              ? "create-trip-reference-search"
                              : "create-trip-reference-search-disabled"
                          }
                        >
                          {searchInvoiceLoading ? (
                            <CircularProgress color="inherit" size={25} />
                          ) : (
                            <>
                              <Search />{" "}
                              {isMobile ? null : <span> search</span>}
                            </>
                          )}
                        </div>
                      </div>
                    ) : loadSource == "trip_purchase_order" &&
                      (Object.keys(selectedCustomer).length > 0
                        ? selectedCustomer.name.toLowerCase().includes("faarms")
                        : false) ? (
                      <div className="create-trip-reference-search-row">
                        <TextField
                          className="create-trip-input"
                          style={{
                            backgroundColor: "white",
                            position: "relative",
                          }}
                          variant="outlined"
                          isDisabled={editMode ? true : false}
                          placeholder="Search purchase order number"
                          size="small"
                          value={referenceNumberSearchValue}
                          InputProps={
                            referenceNumberSearchValue.length > 0
                              ? {
                                endAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <Close
                                      onClick={handleClearReferenceSearch}
                                    />
                                  </InputAdornment>
                                ),
                              }
                              : null
                          }
                          onChange={(e) => {
                            setReferenceNumberSearchValue(e.target.value);
                            if (e.target.value.length == 0) {
                              setSearchResultsList([]);
                            }
                          }}
                        />
                        {referenceNumberSearchValue.length > 0 &&
                          searchResultsList.length > 0 ? (
                          <div className="create-trip-search-results-container">
                            {searchResultsList.map((item) => {
                              return (
                                <div
                                  className="create-trip-search-results-list"
                                  onClick={() => {
                                    handleInvoiceChange(item);
                                    setPurchaseOrderId([
                                      ...purchaseOrderId,
                                      item.value,
                                    ]);
                                    let objTemp = {};
                                    objTemp[item.value] = item.quantity;
                                    setTotalQuantityForPBC([
                                      ...totalQuantityForPBC,
                                      objTemp,
                                    ]);
                                    setSearchResultsList([]);
                                    setReferenceNumberSearchValue("");
                                  }}
                                >
                                  {item.label}
                                </div>
                              );
                            })}
                          </div>
                        ) : null}

                        <div
                          onClick={() => handleSearchButtonInvoices()}
                          className={
                            referenceNumberSearchValue.length > 5
                              ? "create-trip-reference-search"
                              : "create-trip-reference-search-disabled"
                          }
                        >
                          {searchInvoiceLoading ? (
                            <CircularProgress color="inherit" size={25} />
                          ) : (
                            <>
                              <Search />{" "}
                              {isMobile ? null : <span> search</span>}
                            </>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="create-trip-input-container"></div>
                )}
              </div>

              {/* </div> */}

              {/* <div className="create-trip-from-row"></div> */}
              {loadSource == "trip_invoice" ? (
                <div className="create-trip-invoice-row-grid">
                  {invoiceId.map((item) => {
                    return (
                      <div className="create-trip-invoice-list">
                        <div className="create-trip-invoice-text">
                          {" "}
                          {editMode ? item : item[0]}
                        </div>
                        {editMode ? null : (
                          <Close
                            style={{ cursor: "pointer", fontSize: "10px" }}
                            onClick={() => handleRemoveInvoice(item)}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : loadSource == "trip_purchase_order" ? (
                <div className="create-trip-purchase-row-grid">
                  {purchaseOrderId.map((item) => {
                    return (
                      <div className="create-trip-invoice-list">
                        <div className="create-trip-invoice-text">
                          {" "}
                          {item[0]}
                        </div>
                        {editMode ? null : (
                          <Close
                            style={{ cursor: "pointer", fontSize: "10px" }}
                            onClick={() => handleRemovePurchaseOrder(item)}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          ) : null}

          {/* <div className=""> */}
          {/* <div className="create-trip-from-row">
            <div className="create-trip-input-container">
              <h4 className="create-trip-input-head">
                {" "}
                Trip type <sup style={{ color: "red" }}>*</sup>
              </h4>

              <RadioGroup
                className="create-trip-input-source"
                aria-labelledby="demo-radio-buttons-group-label"
                value={tripType}
                onChange={(e) => {
                  setTripType(e.target.value);

                  if (
                    e.target.value === "last_mile" ||
                    e.target.value === "middle_mile"
                  ) {
                    setPaymentTerms("to_be_billed");
                  } else {
                    setPaymentTerms("");
                  }
                }}
              >
                <div className="create-trip-source-radio">
                  <FormControlLabel
                    value="first_mile"
                    control={<Radio />}
                    label="First Mile"
                    // disabled={editMode ? true : false}
                  />

                  <FormControlLabel
                    value="middle_mile"
                    control={<Radio />}
                    label="Middle Mile"
                    // disabled={editMode ? true : false}
                  />
                  <FormControlLabel
                    value="last_mile"
                    control={<Radio />}
                    label="Last Mile"
                    // disabled={editMode ? true : false}
                  />
                </div>
              </RadioGroup>
              <div className="create-trip-error">
                {tripType ? null : errTrip.errTripType}
              </div>
            </div>
            {selectedCustomer.name == "faarms" ||
            selectedCustomer.name == "Faarms" ||
            selectedCustomer.name == "FAARMS" ? null : (
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head"> Reference number</h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  disabled={editMode ? true : false}
                  // style={{ width: "91%" }}
                  placeholder="Enter reference number"
                  value={referenceNumberManual}
                  onChange={(e) => setReferenceNumberManual(e.target.value)}
                />
              </div>
            )}
          </div> */}

          <div className="create-trip-from-row-grid">
            {sourceDetails.map((item, index) => {
              return (
                <>
                  <div className="create-trip-input-container">
                    <h4
                      className="create-trip-input-head"
                      style={{ display: "flex" }}
                    >
                      {" "}
                      Trip source {index + 1}{" "}
                      <sup style={{ color: "red" }}>*</sup>
                      {index > 0 ? (
                        <div className="create-trip-destination-remove">
                          {editMode ? null : (
                            <Cancel
                              style={{ color: "gray", cursor: "pointer" }}
                              onClick={() => handleSourceRemove(index)}
                            />
                          )}
                        </div>
                      ) : null}
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      disabled={editMode ? true : false}
                      placeholder="Enter trip source"
                      onFocus={() => {
                        setCurrentSourceId(index + 1);
                        setCurrentTab(`source`);
                      }}
                      InputProps={
                        sourceDetails.length == index + 1
                          ? {
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{
                                  cursor: "pointer",
                                  color: colorPalette.primaryColor,
                                  fontWeight: "600",
                                  display: editMode ? "none" : "",
                                }}
                                onClick={handleAddSource}
                              >
                                {" "}
                                + Add{" "}
                              </InputAdornment>
                            ),
                          }
                          : null
                      }
                      onClick={() => toggleDrawer("right", true)}
                      value={
                        item.state &&
                          item.village &&
                          item.sub_district &&
                          item.district &&
                          item.concern_person_name &&
                          item.concern_person_mobile_number > 6000000000
                          ? `${item.village}, ${item.sub_district}, ${item.district}, ${item.state}`
                          : ""
                      }
                    />

                    <div className="create-trip-error">
                      {errSourceFinal ? errSourceFinal : null}
                    </div>
                  </div>
                  {sourceDetails.length == index + 1 ? (
                    <div className="create-trip-input-container">
                      <h4 className="create-trip-input-head">
                        {" "}
                        Select vehicle<sup style={{ color: "red" }}>*</sup>
                      </h4>
                      <AsyncSelect
                        className="create-trip-input"
                        defaultOptions
                        key={loadSource}
                        placeholder="search vehicle number..."
                        value={
                          vehicleNumber !== "0"
                            ? { value: vehicleNumber, label: vehicleNumber }
                            : ""
                        }
                        onChange={(e) => {
                          setVehicleNumber(e.value);
                          setVehiclePayoutPeriod(e.payout_period);
                          if (e.payout_period == "monthly_wise") {
                            setPerKmRate(e.extra_per_km_rate);
                          } else {
                            let tempperKm =
                              (tripCost.length > 0
                                ? tripCost[1].cost_amount
                                : 0) / tripDistance;
                            setPerKmRate(tempperKm.toFixed(2));
                          }
                        }}
                        loadOptions={(inputValue) =>
                          vehicleOptions(inputValue, loadSource)
                        }
                      />

                      <div className="create-trip-error">
                        {vehicleNumber == "0" ? errTrip.errVehicleNumber : null}
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>

          <div className="create-trip-from-row-grid">
            {destinationDetails.map((item, index) => {
              return (
                <>
                  <div className="create-trip-input-container">
                    <h4
                      className="create-trip-input-head"
                      style={{ display: "flex" }}
                    >
                      {" "}
                      Trip destination {index + 1}{" "}
                      <sup style={{ color: "red" }}>*</sup>
                      {index > 0 ? (
                        <div className="create-trip-destination-remove">
                          {editMode ? null : (
                            <Cancel
                              style={{ color: "gray", cursor: "pointer" }}
                              onClick={() => handleDestinationRemove(index)}
                            />
                          )}
                        </div>
                      ) : null}
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      disabled={editMode ? true : false}
                      placeholder="Enter trip destination"
                      onFocus={() => {
                        setCurrentDestinationId(index + 1);
                        setCurrentTab(`destination`);
                      }}
                      onClick={() => toggleDrawer("right", true)}
                      value={
                        item.state &&
                          item.village &&
                          item.sub_district &&
                          item.district &&
                          item.concern_person_name &&
                          item.concern_person_mobile_number > 6000000000
                          ? `${item.village}, ${item.sub_district}, ${item.district}, ${item.state}`
                          : ""
                      }
                      InputProps={
                        destinationDetails.length == index + 1
                          ? {
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{
                                  cursor: "pointer",
                                  color: colorPalette.primaryColor,
                                  fontWeight: "600",
                                  display: editMode ? "none" : "",
                                }}
                                onClick={handleAddDestination}
                              >
                                {" "}
                                + Add{" "}
                              </InputAdornment>
                            ),
                          }
                          : null
                      }
                    // onChange={(e) => {
                    // setTripDistance(e.target.value)
                    // setCurrentTab("destination");
                    // }}
                    />
                    <div className="create-trip-error">
                      {errDestinationFinal ? errDestinationFinal : null}
                    </div>
                  </div>
                  {destinationDetails.length == index + 1 ? (
                    <div className="create-trip-input-container">
                      <h4 className="create-trip-input-head">
                        {" "}
                        Select Transporter<sup style={{ color: "red" }}>*</sup>
                      </h4>
                      <AsyncSelect
                        className="create-trip-input"
                        defaultOptions
                        key={transporterNameId}
                        placeholder="search transporter..."
                        value={
                          transporterNameId
                            ? {
                              value: transporterNameId,
                              label: selectedTransporterName,
                            }
                            : ""
                        }
                        onChange={(e) => {
                          setTransporterNameId(e.value);
                          setSelectedTransporterName(e.name);
                        }}
                        loadOptions={(inputValue) =>
                          handleFetchTransporter(inputValue)
                        }
                      />

                      <div className="create-trip-error">
                        {!transporterNameId ? errTrip.errTransporter : null}
                      </div>
                    </div>
                  ) : // <div className="create-trip-input-container">
                    //   <h4 className="create-trip-input-head">
                    //     {" "}
                    //     Load unit <sup style={{ color: "red" }}>*</sup>
                    //   </h4>
                    //   <TextField
                    //     size="small"
                    //     select
                    //     autoComplete="off"
                    //     className="create-trip-input"
                    //     variant="outlined"
                    //     placeholder="load unit"
                    //     value={loadUnit}
                    //     onChange={(e) => setLoadUnit(e.target.value)}
                    //   >
                    //     <MenuItem value="0" disabled>
                    //       Select load unit
                    //     </MenuItem>
                    //     {loadUnitList.map((item) => {
                    //       return (
                    //         <MenuItem value={item.unit_code}>
                    //           {item.name}
                    //         </MenuItem>
                    //       );
                    //     })}
                    //   </TextField>
                    //   <div className="create-trip-error">
                    //     {loadUnit == "0" ? errTrip.errLoadUnit : null}
                    //   </div>
                    // </div>
                    null}
                </>
              );
            })}
          </div>
          <div className="create-trip-from-row">
            <div className="create-trip-input-container">
              <h4 className="create-trip-input-head">
                {" "}
                Trip start meter reading (with attachment){" "}
                <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                size="small"
                id="dropdown"
                autoComplete="off"
                className="create-trip-input dropdown-container"
                variant="outlined"
                placeholder="Enter meter reading"
                name="attachment_tag"
                value={tripStartMeterReading}
                onChange={(e) => setTripStartMeterReading(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        cursor: "pointer",
                        color: colorPalette.primaryColor,
                        fontWeight: "600",
                      }}
                    // onClick={handleAddAttachment}
                    >
                      <button className="upload_button">
                        <input
                          // name="upload"
                          name="attachment_file"
                          id={`upload`}
                          type="file"
                          accept="image/png, image/jpeg, application/pdf"
                          onChange={(e) => {
                            handleChangeTripMeterReadingAttachment(e);
                          }}
                          hidden
                          style={{
                            backgroundColor: colorPalette.secondaryWhite,
                          }}
                        />
                        <label
                          htmlFor={`upload`}
                          style={{
                            backgroundColor: colorPalette.secondaryWhite,
                          }}
                        >
                          <img
                            src={UploadImage}
                            alt=""
                            width="20px"
                            height="20px"
                            style={{
                              backgroundColor: colorPalette.secondaryWhite,
                              // width: "100%",
                              // height: "100%",
                            }}
                          />
                        </label>
                      </button>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="create-trip-attachment-name">
                {tripStartMeterReadingAttachment.attachment_file != null
                  ? tripStartMeterReadingAttachment.attachment_file.name
                  : null}
              </div>
              <div className="create-trip-error">
                {tripStartMeterReadingAttachment.attachment_file
                  ? tripStartMeterReadingAttachment.attachment_file.size >
                    process.env.REACT_APP_MAX_FILE_SIZE
                    ? `File size is too large.( <  ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                    : null
                  : null}
              </div>

              <div className="create-trip-error">
                {tripStartMeterReading
                  ? null
                  : errTrip.errTripStartMeterReading}
              </div>
              <div className="create-trip-error">
                {tripStartMeterReadingAttachment.attachment_file
                  ? null
                  : errTrip.errTripStartMeterReadingAttachment}
              </div>
            </div>

            <div className="create-trip-input-container">
              <h4 className="create-trip-input-head">
                {" "}
                Per KM Rate <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                size="small"
                autoComplete="off"
                className="create-trip-input"
                variant="outlined"
                placeholder="Enter Per KM Rate"
                type="number"
                onWheel={(e) => e.target.blur()}
                disabled={true}
                value={perKmRate}
              // onChange={(e) => setPerKmRate(e.target.value)}
              />
              <div className="create-trip-error">
                {perKmRate > 0 ? null : errTrip.errPerKmRate}
              </div>
            </div>
          </div>
          <div className="create-trip-from-row">
            <div className="create-trip-input-container">
              <h4 className="create-trip-input-head">
                {" "}
                Estimated distance (in kms){" "}
                <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                size="small"
                autoComplete="off"
                className="create-trip-input"
                variant="outlined"
                placeholder="Enter Estimated Distance"
                type="number"
                // onwheel="return false;"
                onWheel={(e) => e.target.blur()}
                value={tripDistance}
                onChange={(e) => setTripDistance(e.target.value)}
              />
              <div className="create-trip-error">
                {tripDistance > 0 ? null : errTrip.errTripDistance}
              </div>
            </div>

            <div className="create-trip-input-container">
              <h4 className="create-trip-input-head">
                {" "}
                Number of Pieces <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                size="small"
                autoComplete="off"
                className="create-trip-input"
                variant="outlined"
                placeholder="Enter Number of pieces"
                disabled={loadSource !== "trip_invoice" ? false : true}
                type="number"
                onWheel={(e) => e.target.blur()}
                value={totalQty}
                onChange={(e) => setTotalQty(e.target.value)}
              />
              <div className="create-trip-error">
                {totalQty > 0 ? null : errTrip.errTotalQty}
              </div>
            </div>
          </div>

          <div className="create-trip-from-row">
            <div className="create-trip-input-container">
              <h4 className="create-trip-input-head">
                {" "}
                Value of goods <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                size="small"
                variant="outlined"
                name="cost_amount"
                id="trip_cost"
                placeholder="Enter value of goods"
                className="create-trip-input"
                type="number"
                disabled={loadSource == "trip_invoice" ? true : false}
                onWheel={(e) => e.target.blur()}
                value={valueOfGoods}
                onChange={(e) => setValueOfGoods(e.target.value)}
              />
              <div className="create-trip-error">
                {valueOfGoods > 0 ? null : errTrip.errValueOfGoods}
              </div>
            </div>
            <div className="create-trip-input-container">
              <h4 className="create-trip-input-head">
                {" "}
                Load unit <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                size="small"
                select
                autoComplete="off"
                className="create-trip-input"
                variant="outlined"
                placeholder="load unit"
                value={loadUnit}
                onChange={(e) => setLoadUnit(e.target.value)}
              >
                <MenuItem value="0" disabled>
                  Select load unit
                </MenuItem>
                {loadUnitList.map((item) => {
                  return (
                    <MenuItem value={item.unit_code}>{item.name}</MenuItem>
                  );
                })}
              </TextField>
              <div className="create-trip-error">
                {loadUnit == "0" ? errTrip.errLoadUnit : null}
              </div>
            </div>
          </div>

          <div className="create-trip-from-row">
            <div className="create-trip-input-container">
              <h4 className="create-trip-input-head">
                {" "}
                Quantity in tonnes <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                size="small"
                autoComplete="off"
                className="create-trip-input"
                variant="outlined"
                placeholder="Enter Quantity in tonnes"
                type="number"
                // onwheel="return false;"
                onWheel={(e) => e.target.blur()}
                disabled={loadSource !== "trip_invoice" ? false : true}
                value={quantityInTonnes}
                onChange={(e) => setQuantityInTonnes(e.target.value)}
              />
              <div className="create-trip-error">
                {/* {quantityInTonnes > 0 ? null : errTrip.errTripDistance} */}
              </div>
            </div>
            <div className="create-trip-input-container">
              <h4 className="create-trip-input-head">
                {" "}
                Customer Invoice Attachment{" "}
                <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                size="small"
                id="dropdown"
                autoComplete="off"
                className="create-trip-input dropdown-container"
                variant="outlined"
                placeholder="Enter Customer Invoice"
                name="attachment_tag"
                value={customerInvoiceNumber}
                onChange={(e) => setCustomerInvoiceNumber(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        cursor: "pointer",
                        color: colorPalette.primaryColor,
                        fontWeight: "600",
                      }}
                    // onClick={handleAddAttachment}
                    >
                      <button className="upload_button">
                        <input
                          // name="upload"
                          name="attachment_file"
                          id={`upload-customer-invoice`}
                          type="file"
                          accept="image/png, image/jpeg, application/pdf"
                          onChange={(e) => {
                            handleCustomerInvoiceAttachment(e);
                          }}
                          hidden
                          style={{
                            backgroundColor: colorPalette.secondaryWhite,
                          }}
                        />
                        <label
                          htmlFor={`upload-customer-invoice`}
                          style={{
                            backgroundColor: colorPalette.secondaryWhite,
                          }}
                        >
                          <img
                            src={UploadImage}
                            alt=""
                            width="20px"
                            height="20px"
                            style={{
                              backgroundColor: colorPalette.secondaryWhite,
                              // width: "100%",
                              // height: "100%",
                            }}
                          />
                        </label>
                      </button>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="create-trip-attachment-name">
                {customerInvoiceAttachment.attachment_file != null
                  ? customerInvoiceAttachment.attachment_file.name
                  : null}
              </div>
              <div className="create-trip-error">
                {customerInvoiceAttachment.attachment_file
                  ? customerInvoiceAttachment.attachment_file.size >
                    process.env.REACT_APP_MAX_FILE_SIZE
                    ? `File size is too large.( <  ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                    : null
                  : null}
              </div>

              <div className="create-trip-error">
                {customerInvoiceNumber ? null : errTrip.errCustomerInvoice}
              </div>
              <div className="create-trip-error">
                {customerInvoiceAttachment.attachment_file
                  ? null
                  : errTrip.errCustomerInvoiceAttachment}
              </div>
            </div>
          </div>

          <div className="create-trip-from-row">
            <div className="create-trip-input-container">
              <h4 className="create-trip-input-head">
                {" "}
                Select driver <sup style={{ color: "red" }}>*</sup>
              </h4>
              {driverDetails.first_name &&
                driverDetails.mobile_number.length == 10 &&
                driverDetails.driving_licence_number ? (
                <div className="create-trip-drivers-box">
                  <div className="create-trip-driver-heaad">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Driver's details
                    </h4>
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={handleClearDriver}
                    />
                  </div>
                  <div className="create-trip-drivers-box-row">
                    <h4 className="create-trip-drivers-left">First Name </h4>
                    <h4 className="create-trip-drivers-right">
                      {" "}
                      : {driverDetails.first_name}
                    </h4>
                  </div>
                  <div className="create-trip-drivers-box-row">
                    <h4 className="create-trip-drivers-left">Last Name </h4>
                    <h4 className="create-trip-drivers-right">
                      {" "}
                      :{" "}
                      {driverDetails.last_name
                        ? driverDetails.last_name
                        : " - "}
                    </h4>
                  </div>
                  <div className="create-trip-drivers-box-row">
                    <h4 className="create-trip-drivers-left">Mobile Number </h4>
                    <h4 className="create-trip-drivers-right">
                      {" "}
                      : {driverDetails.mobile_number}
                    </h4>
                  </div>
                  <div className="create-trip-drivers-box-row">
                    <h4 className="create-trip-drivers-left">
                      Alternate Mobile Number{" "}
                    </h4>
                    <h4 className="create-trip-drivers-right">
                      {" "}
                      :{" "}
                      {driverDetails.alternate_mobile_number
                        ? driverDetails.alternate_mobile_number
                        : " - "}
                    </h4>
                  </div>

                  <div className="create-trip-drivers-box-row">
                    <h4 className="create-trip-drivers-left">DL Number </h4>
                    <h4 className="create-trip-drivers-right">
                      {" "}
                      : {driverDetails.driving_licence_number}
                    </h4>
                  </div>
                </div>
              ) : (
                <>
                  <div className="create-trip-driver-input">
                    <AsyncSelect
                      className="create-trip-input-driver"
                      defaultOptions
                      placeholder="Search Name or DL No."
                      onChange={(e) => {
                        //
                        // setDriverDetails(e);
                        handleDriverSelect(e);
                      }}
                      loadOptions={driverOptions}
                    />

                    <div className="create-trip-addd" onClick={handleAddDriver}>
                      {" "}
                      <AddCircle style={{ fontSize: "19px" }} />
                      <div>add</div>{" "}
                    </div>
                  </div>
                  <div className="create-trip-error">
                    {errDriverFinal ? errDriverFinal : null}
                  </div>
                </>
              )}
            </div>
            {vehiclePayoutPeriod == "monthly_wise" &&
              loadSource == "trip_invoice" ? null : (
              <div className="create-trip-input-container">
                <div className="create-trip-drivers-box">
                  {vehiclePayoutPeriod == "monthly_wise" ? null : (
                    <div className="create-trip-input-container">
                      <h4 className="create-trip-input-head">
                        {" "}
                        Payment Terms <sup style={{ color: "red" }}>*</sup>
                      </h4>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        onChange={(e) => setPaymentTerms(e.target.value)}
                        value={paymentTerms}
                      >
                        <div style={{ display: "flex" }}>
                          <FormControlLabel
                            value="to_payee"
                            control={<Radio />}
                            label="To Pay"
                            onChange={(e) => {
                              setToPay(e.target.value);
                            }}
                            disabled={
                              tripType === "last_mile" ||
                                tripType === "middle_mile"
                                ? true
                                : false
                            }
                          />

                          <FormControlLabel
                            value="to_be_billed"
                            control={<Radio />}
                            label="To be billed"
                            onChange={(e) => {
                              setToBilled(e.target.value);
                              setToPay("");
                            }}
                            disabled={
                              tripType === "last_mile" ||
                                tripType === "middle_mile"
                                ? true
                                : false
                            }
                          />
                        </div>
                      </RadioGroup>
                      <div className="create-trip-error">
                        {paymentTerms
                          ? paymentTerms.length > 2 ||
                            vehiclePayoutPeriod == "monthly_wise"
                            ? null
                            : errTrip.errPaymentTerms
                          : null}
                      </div>
                    </div>
                  )}

                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head"> Cost details:</h4>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {vehiclePayoutPeriod == "monthly_wise" ? null : (
                        <>
                          <div
                            className="create-trip-cost-input-contain"
                            style={{ marginTop: ".5rem" }}
                          >
                            <div>
                              Total transport cost(₹){" "}
                              <sup
                                style={{ color: "red", position: "relative" }}
                              >
                                *
                              </sup>{" "}
                            </div>
                            <TextField
                              size="small"
                              variant="outlined"
                              name="cost_amount"
                              id="trip_cost"
                              placeholder="Enter total amount"
                              className="create-trip-cost-input"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              value={
                                tripCost.length > 0
                                  ? tripCost[1].cost_amount
                                  : null
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {" "}
                                    ₹{" "}
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => handleTripCostChange(e, 1)}
                            />
                            <div className="create-trip-error">
                              {tripCost.length > 0
                                ? tripCost[1].cost_amount > 10 ||
                                  vehiclePayoutPeriod == "monthly_wise"
                                  ? null
                                  : errTrip.errTotalCost
                                : null}
                            </div>
                          </div>
                          <div
                            className="create-trip-cost-input-contain"
                            style={{
                              display: "flex",

                              margin: ".5rem 0rem",
                              // justifyContent: "center",
                            }}
                          >
                            <div>
                              Transport Advance(₹){" "}
                              <sup
                                style={{ color: "white", position: "relative" }}
                              >
                                *
                              </sup>{" "}
                            </div>
                            <TextField
                              size="small"
                              variant="outlined"
                              name="cost_amount"
                              id="trip_cost"
                              placeholder="Enter advance amount"
                              value={
                                tripCost.length > 0
                                  ? tripCost[0].cost_amount
                                  : null
                              }
                              onChange={(e) => handleTripCostChange(e, 0)}
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              className="create-trip-cost-input"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {" "}
                                    ₹{" "}
                                  </InputAdornment>
                                ),
                              }}
                            // style={{
                            //   // marginLeft: ".5rem",
                            //   position: "absolute",
                            //   left: "45rem",
                            // }}
                            />

                            <div className="create-trip-error">
                              {tripCost.length > 0
                                ? tripCost[0].cost_amount === ""
                                  ? true
                                  : parseInt(tripCost[0].cost_amount) < 0
                                    ? errTrip.errAdvanceCost
                                    : parseInt(tripCost[0].cost_amount) == 0
                                      ? errTrip.errAdvanceCost
                                      : parseInt(tripCost[0].cost_amount) <=
                                        parseInt(tripCost[1].cost_amount)
                                        ? true
                                        : errTrip.errAdvanceCost
                                : null}
                            </div>
                          </div>
                        </>
                      )}
                      {loadSource == "manual" ||
                        loadSource == "trip_purchase_order" ? (
                        <>
                          {" "}
                          <div
                            className="create-trip-cost-input-contain"
                            style={{
                              display: "flex",

                              margin: ".5rem 0rem",
                              // justifyContent: "center",
                            }}
                          >
                            <div>
                              Advance Paid By Customer(₹){" "}
                              <sup
                                style={{ color: "red", position: "relative" }}
                              >
                                *
                              </sup>{" "}
                            </div>
                            <TextField
                              size="small"
                              autoComplete="off"
                              className="create-trip-cost-input"
                              variant="outlined"
                              placeholder="Enter Amount"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              value={advanceByCustomer}
                              onChange={(e) =>
                                setAdvanceByCustomer(e.target.value)
                              }
                            />
                            {/* <div className="create-trip-error">
                            {tripDistance > 0 ? null : errTrip.errTripDistance}
                          </div> */}
                          </div>
                          <div
                            className="create-trip-cost-input-contain"
                            style={{
                              display: "flex",

                              margin: ".5rem 0rem",
                              // justifyContent: "center",
                            }}
                          >
                            <div>
                              Customer Billed Amount(₹){" "}
                              <sup
                                style={{ color: "red", position: "relative" }}
                              >
                                *
                              </sup>{" "}
                            </div>
                            <TextField
                              size="small"
                              autoComplete="off"
                              className="create-trip-cost-input"
                              variant="outlined"
                              placeholder="Enter Amount"
                              // disabled={loadSource === "manual" ? false : true}
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              value={billedByCustomer}
                              onChange={(e) =>
                                setBilledByCustomer(e.target.value)
                              }
                            />
                            {/* <div className="create-trip-error">
                            {totalQty > 0 ? null : errTrip.errTotalQty}
                          </div> */}
                          </div>
                        </>
                      ) : null}

                      <div
                        className="create-trip-cost-input-contain"
                        style={{
                          display: "flex",

                          margin: "2rem 0rem",
                          // justifyContent: "center",
                        }}
                      >
                        {pbcCostPurchase > 0 ? (
                          <div>
                            <span style={{ fontWeight: "500" }}>
                              {" "}
                              Per bag cost :
                            </span>{" "}
                            <sup
                              style={{ color: "white", position: "relative" }}
                            >
                              *
                            </sup>{" "}
                            ₹{" "}
                            {`${pbcCostPurchase > 0 ? pbcCostPurchase : "0.00"
                              }`}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {Object.keys(selectedCustomer).length > 0 ? (
            selectedCustomer?.name.toLowerCase().includes("faarms") ? null : (
              <div className="create-trip-eway-box">
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      E-Way Bill Number
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter e-way bill no."
                      value={ewayBillNumber}
                      onChange={(e) => setEwayBillNumber(e.target.value)}
                    />
                    <div className="create-trip-error">
                      {ewayBillNumber ? null : errEwayBill.errEwayNumber}
                    </div>
                  </div>

                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      E-Way Bill Valid From
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter total quantity"
                      // disabled={loadSource !== "trip_invoice" ? false : true}
                      type="date"
                      value={ewayBillFrom}
                      onChange={(e) => setEwayBillFrom(e.target.value)}
                    />
                    <div className="create-trip-error">
                      {ewayBillFrom && ewayBillFrom <= ewayBillTo
                        ? null
                        : errEwayBill.errEwayFrom}
                    </div>
                  </div>
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      E-Way Bill Valid Till
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter total quantity"
                      // disabled={loadSource !== "trip_invoice" ? false : true}
                      type="date"
                      value={ewayBillTo}
                      onChange={(e) => setEwayBillTo(e.target.value)}
                    />
                    <div className="create-trip-error">
                      {ewayBillTo && ewayBillFrom <= ewayBillTo
                        ? null
                        : errEwayBill.errEwayTo}
                    </div>
                  </div>
                </div>
                <div
                  className="trip-list-modal-row"
                  style={{ margin: "5px 10px" }}
                >
                  <div
                    className="trip-list-modal-input-group"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <h4 className="create-trip-input-head">
                      E-Way Bill Attachment{" "}
                    </h4>

                    <TextField
                      size="small"
                      id="dropdown"
                      select
                      autoComplete="off"
                      style={isMobile ? { width: "85%" } : { width: "60%" }}
                      className="create-trip-input-attachment dropdown-container"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      disabled={true}
                      value={"e_way_bill"}
                      // onChange={(e) => {
                      //   handleAttachmentChange(e, index);
                      // }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          // onClick={handleAddAttachment}
                          >
                            <button className="upload_button">
                              <input
                                // name="upload"
                                name="attachment_file"
                                id={`upload-eway`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={(e) => {
                                  setEwayBillAttachment(e.target.files[0]);
                                }}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label
                                htmlFor={`upload-eway`}
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              >
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                    // width: "100%",
                                    // height: "100%",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    >
                      <MenuItem value="0" disabled>
                        Select document type
                      </MenuItem>
                      {/* {console.log("load unit", loadUnitList)} */}
                      {documentTypeList.map((item) => {
                        return (
                          <MenuItem value={item}>
                            {item
                              .split("_")
                              .join(" ")
                              .split("")
                              .map((item, index) => {
                                if (index == 0) {
                                  return item.toUpperCase();
                                } else {
                                  return item;
                                }
                              })
                              .join("")}
                          </MenuItem>
                        );
                      })}
                    </TextField>

                    <div className="create-trip-attachment-name">
                      {ewayBillAttachment != null
                        ? ewayBillAttachment.name
                        : null}
                    </div>
                    <div className="error-trip">
                      {ewayBillAttachment
                        ? ewayBillAttachment.size >
                          process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size is too large.( <  ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                          : null
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : null}

          <div className="create-trip-attachments-box">
            <h4
              style={{
                fontWeight: "500",
                margin: "10px 10px 0px 10px",
                fontSize: "18px",
              }}
            >
              Attachments{" "}
            </h4>
            {/* <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <div className="trip-list-modal-attachment-container">
                  {attachmentListArray.map((item, index) => {
                    return (
                      <div key={index} className="trip-list-modal-row">
                        <div
                          className="trip-list-modal-input-group"
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <h4 className="create-trip-modal-head">
                            Document type {index + 1}{" "}
                          </h4>

                          <TextField
                            size="small"
                            id="dropdown"
                            select
                            autoComplete="off"
                            className="create-trip-input-attachment dropdown-container"
                            variant="outlined"
                            placeholder="Enter document type"
                            name="attachment_tag"
                            disabled={
                              index == 0 && loadSource != "trip_invoice"
                                ? true
                                : false
                            }
                            value={
                              loadSource != "trip_invoice" && index == 0
                                ? "bilty_copy"
                                : item.attachment_tag
                            }
                            onChange={(e) => {
                              handleAttachmentChange(e, index);
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  style={{
                                    cursor: "pointer",
                                    color: colorPalette.primaryColor,
                                    fontWeight: "600",
                                  }}
                                 
                                >
                                  <button className="upload_button">
                                    <input
                                      name="attachment_file"
                                      id={`upload${index}`}
                                      type="file"
                                      accept="image/png, image/jpeg, application/pdf"
                                      onChange={(e) => {
                                        handleAttachmentChange(e, index);
                                        handleAddAttachment(e);
                                         
                                      }}
                                      hidden
                                      style={{
                                        backgroundColor:
                                          colorPalette.secondaryWhite,
                                      }}
                                    />
                                    <label
                                      htmlFor={`upload${index}`}
                                      style={{
                                        backgroundColor:
                                          colorPalette.secondaryWhite,
                                      }}
                                    >
                                      <img
                                        src={UploadImage}
                                        alt=""
                                        width="20px"
                                        height="20px"
                                        style={{
                                          backgroundColor:
                                            colorPalette.secondaryWhite,
                                        }}
                                      />
                                    </label>
                                  </button>
                                </InputAdornment>
                              ),
                            }}
                          >
                            <MenuItem value="0" disabled>
                              Select document type
                            </MenuItem>
                            {documentTypeList.map((item) => {
                              return (
                                <MenuItem value={item}>
                                  {item
                                    .split("_")
                                    .join(" ")
                                    .split("")
                                    .map((item, index) => {
                                      if (index == 0) {
                                        return item.toUpperCase();
                                      } else {
                                        return item;
                                      }
                                    })
                                    .join("")}
                                </MenuItem>
                              );
                            })}
                          </TextField>

                          <div className="create-trip-attachment-name">
                            {item.attachment_file != null
                              ? item.attachment_file.name
                              : null}
                          </div>
                          <div className="error-trip">
                            {item.attachment_file
                              ? item.attachment_file.size >
                                process.env.REACT_APP_MAX_FILE_SIZE
                                ? `File size is too large.( <  ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                                : null
                              : null}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {isMobile ? null : (
                    <div className="trip-list-modal-row">
                      <div className="trip-list-modal-input-group"></div>
                      <div className="trip-list-modal-input-group"></div>
                      <div className="trip-list-modal-input-group"></div>
                    </div>
                  )}
                </div>
              </div>
            </div> */}

            <div className="create-trip-input-container">
              <h4 className="create-trip-input-head">
                {" "}
                Bhada Chiti / Transporter Invoice{" "}
                <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                size="small"
                id="dropdown"
                autoComplete="off"
                className="create-trip-input dropdown-container"
                variant="outlined"
                placeholder="Bhada Chiti"
                name="attachment_tag"
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        cursor: "pointer",
                        color: colorPalette.primaryColor,
                        fontWeight: "600",
                      }}
                    // onClick={handleAddAttachment}
                    >
                      <button className="upload_button">
                        <input
                          // name="upload"
                          name="attachment_file"
                          id={`upload-bhada-chiti`}
                          type="file"
                          accept="image/png, image/jpeg, application/pdf"
                          onChange={(e) => {
                            handleBhadaChitiAttachment(e);
                          }}
                          hidden
                          style={{
                            backgroundColor: colorPalette.secondaryWhite,
                          }}
                        />
                        <label
                          htmlFor={`upload-bhada-chiti`}
                          style={{
                            backgroundColor: colorPalette.secondaryWhite,
                          }}
                        >
                          <img
                            src={UploadImage}
                            alt=""
                            width="20px"
                            height="20px"
                            style={{
                              backgroundColor: colorPalette.secondaryWhite,
                              // width: "100%",
                              // height: "100%",
                            }}
                          />
                        </label>
                      </button>
                    </InputAdornment>
                  ),
                }}
              />
              <div className="create-trip-attachment-name">
                {bhadaChitiAttachment.attachment_file != null
                  ? bhadaChitiAttachment.attachment_file.name
                  : null}
              </div>
              <div className="create-trip-error">
                {bhadaChitiAttachment.attachment_file
                  ? bhadaChitiAttachment.attachment_file.size >
                    process.env.REACT_APP_MAX_FILE_SIZE
                    ? `File size is too large.( <  ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                    : null
                  : null}
              </div>

              <div className="create-trip-error">
                {bhadaChitiAttachment ? null : errTrip.errBhadaChitiAttachment}
              </div>
              <div className="create-trip-error">
                {bhadaChitiAttachment.attachment_file
                  ? null
                  : errTrip.errBhadaChitiAttachment}
              </div>
            </div>
          </div>
        </div>
        {errCreateTripFinal ? (
          <div className="create-trip-alert">
            <Alert severity="error">{errCreateTripFinal}</Alert>
          </div>
        ) : null}
        <div className="create-trip-button-container">
          <button className="create-trip-button" onClick={handleCreateTrip}>
            {loadingButton ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {permission ? <Alert severity="error">{permission}</Alert> : null}
        </div>
      </div>
      <div className="create-trip-right-most-container">
        {/*Source details start*/}
        {currentTab == "source"
          ? sourceDetails
            .filter((item, indexs) => indexs == currentSourceId - 1)
            .map((item, index) => {
              return (
                <Drawer
                  anchor={"right"}
                  open={state["right"]}
                  onClose={() => toggleDrawer("right", false)}
                >
                  <Create_trip_drawer_container className="create-trip-drawer-container">
                    <div
                      className="create-trip-right-most-head"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Add Trip source - {currentSourceId}{" "}
                      <Close
                        color="action"
                        onClick={handleCloseDriverDrawer}
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                    {loadSource == "trip_invoice" ? (
                      <>
                        <div className="create-trip-from-row">
                          <div className="create-trip-input-container">
                            <h4 className="create-trip-input-head">
                              {" "}
                              Available Warehouses{" "}
                              <sup style={{ color: "red" }}>*</sup>
                            </h4>

                            <TextField
                              id="outlined-select-currency"
                              select
                              variant="outlined"
                              className="create-trip-input"
                              // value={selectedWarehouse}
                              color="success"
                              size="small"
                              onChange={(e) => {
                                setErrSourceFinal("");
                                // setSourceDetails([e.target.value]);
                                handleSelectWarehouse(
                                  e.target.value,
                                  currentSourceId - 1,
                                  "source"
                                );
                                setErrSourceAddress({
                                  ...errSourceAddress,
                                  errorWarehouse: "",
                                });
                              }}
                            >
                              {wareHouseList.map((option, index) => (
                                <MenuItem
                                  key={index}
                                  value={option}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <div>
                                    {" "}
                                    {option.name} ({option.warehouse_code}){" "}
                                  </div>
                                  <div>
                                    {" "}
                                    {option.state} - {option.postcode}{" "}
                                  </div>
                                </MenuItem>
                              ))}
                            </TextField>
                            <div className="create-trip-error">
                              {!destinationDetails.concern_person_name
                                ? errSourceAddress.errorWarehouse
                                : null}
                            </div>
                          </div>
                        </div>

                        <div className="create-trip-from-row">
                          <div className="create-trip-input-container">
                            <h4 className="create-trip-input-head">
                              {" "}
                              Available Warehouse Users{" "}
                              <sup style={{ color: "red" }}>*</sup>
                            </h4>

                            <TextField
                              id="outlined-select-currency"
                              select
                              variant="outlined"
                              className="create-trip-input"
                              // value={selectedWarehouseUser}
                              color="success"
                              size="small"
                              onChange={(e) => {
                                setErrSourceFinal("");
                                // setSourceDetails([e.target.value]);
                                handleSelectWarehouseUser(
                                  e.target.value,
                                  currentSourceId - 1,
                                  "source"
                                );
                                setErrSourceAddress({
                                  ...errSourceAddress,
                                  errorWarehouseUser: "",
                                });
                              }}
                            >
                              {warehouseUserList.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option.first_name}{" "}
                                  {option.last_name ? option.last_name : ""}
                                </MenuItem>
                              ))}
                            </TextField>
                            <div className="create-trip-error">
                              {!destinationDetails.concern_person_name
                                ? errSourceAddress.errorWarehouseUser
                                : null}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Concern person name{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          name="concern_person_name"
                          placeholder="Enter the name"
                          value={item.concern_person_name}
                          onChange={(e) =>
                            handleOnChangeSource(e, currentSourceId - 1)
                          }
                        />
                        <div className="create-trip-error">
                          {!item.concern_person_name
                            ? errSourceAddress.errPersonName
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Concern person Mobile Number{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="concern_person_mobile_number"
                          placeholder="Enter the mobile number"
                          value={item.concern_person_mobile_number}
                          onChange={(e) => {
                            if (e.target.value.length <= 10) {
                              handleOnChangeSource(e, currentSourceId - 1);
                            }
                          }}
                        />
                        <div className="create-trip-error">
                          {item.concern_person_mobile_number < 6000000000
                            ? errSourceAddress.errPersonMobileNumber
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Pincode <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="Enter Pincode"
                          name="postcode"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          value={item.postcode}
                          disabled
                          onChange={(e) => {
                            if (e.target.value.length <= 6) {
                              setErrPincodeNotServiceable("");
                              handleOnChangeSource(e, currentSourceId - 1);
                              if (e.target.value.length == 6) {
                                setErrSourceAddress({
                                  ...errSourceAddress,
                                  errPostcode: "",
                                });
                                handleGetAddressSource(
                                  e.target.value,
                                  currentSourceId - 1
                                );
                              }
                            }
                          }}
                        />
                        <div className="create-trip-error">
                          {errPincodeNotServiceable
                            ? errPincodeNotServiceable
                            : null}
                          {/* {item.postcode.length != 6
                              ? errSourceAddress.errPostcode
                              : null} */}
                        </div>
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Village <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          className="create-trip-input"
                          id="outlined-select-currency"
                          name="village"
                          select
                          size="small"
                          value={item.village}
                          disabled
                          onChange={(e) => {
                            handleOnChangeSource(e, currentSourceId - 1);
                            setErrSourceAddress({
                              errVillage: "",
                            });
                          }}
                        // label="Select"
                        // value={currency}
                        // onChange={handleChange}
                        >
                          <MenuItem value="0" disabled>
                            Select Village
                          </MenuItem>
                          {sourceVillagesList.map((village, index) => {
                            return (
                              <MenuItem key={index} value={village}>
                                {village}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <div className="create-trip-error">
                          {item.village == "0"
                            ? errSourceAddress.errVillage
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Sub District <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          name="sub_district"
                          placeholder="sub district"
                          value={item.sub_district}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          District <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="District"
                          name="district"
                          value={item.district}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          State <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          name="state"
                          placeholder="state"
                          value={item.state}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head"> Landmark</h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="Enter the landmark"
                          name="landmark"
                          value={item.landmark}
                          onChange={(e) =>
                            handleOnChangeSource(e, currentSourceId - 1)
                          }
                        />
                      </div>
                    </div>
                    <div className="create-trip-input-container">
                      <button
                        className="create-trip-save-btn"
                        onClick={() => handleSourceSave()}
                      >
                        Save
                      </button>
                    </div>
                  </Create_trip_drawer_container>
                </Drawer>
              );
            })
          : null}
        {/*Source details end*/}
        {/*Destination details start*/}
        {currentTab == `destination`
          ? destinationDetails
            .filter((items, indexs) => indexs == currentDestinationId - 1)
            .map((item, index) => {
              return (
                <Drawer
                  anchor={"right"}
                  open={state["right"]}
                  onClose={() => toggleDrawer("right", false)}
                >
                  <Create_trip_drawer_container className="create-trip-drawer-container">
                    <div
                      className="create-trip-right-most-head"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Add Trip destination - {currentDestinationId}
                      <Close
                        color="action"
                        onClick={handleCloseDriverDrawer}
                        style={{ marginRight: "10px" }}
                      />
                    </div>
                    {loadSource == "trip_purchase_order" ? (
                      <>
                        <div className="create-trip-from-row">
                          <div className="create-trip-input-container">
                            <h4 className="create-trip-input-head">
                              {" "}
                              Available Warehouses{" "}
                              <sup style={{ color: "red" }}>*</sup>
                            </h4>
                            <TextField
                              id="outlined-select-currency"
                              select
                              variant="outlined"
                              // sx={{
                              //   width: "18ch",
                              //   height: "6ch",
                              // }}
                              className="create-trip-input"
                              // value={item}
                              color="success"
                              size="small"
                              onChange={(e) => {
                                setErrDestinationFinal("");
                                // setDestinationDetails([e.target.value]);
                                handleSelectWarehouse(
                                  e.target.value,
                                  currentDestinationId - 1,
                                  "destination"
                                );
                              }}
                            >
                              {/* <MenuItem disabled>Select warehouse</MenuItem> */}
                              {wareHouseList.map((option, index) => (
                                <MenuItem
                                  key={index}
                                  value={option}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <div>
                                    {" "}
                                    {option.name} ({option.warehouse_code}){" "}
                                  </div>
                                  <div>
                                    {" "}
                                    {option.state} - {option.postcode}{" "}
                                  </div>
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        </div>
                        <div className="create-trip-from-row">
                          <div className="create-trip-input-container">
                            <h4 className="create-trip-input-head">
                              {" "}
                              Available Warehouse Users{" "}
                              <sup style={{ color: "red" }}>*</sup>
                            </h4>
                            <TextField
                              id="outlined-select-currency"
                              select
                              variant="outlined"
                              // sx={{
                              //   width: "18ch",
                              //   height: "6ch",
                              // }}
                              className="create-trip-input"
                              // value={item}
                              color="success"
                              size="small"
                              onChange={(e) => {
                                setErrDestinationFinal("");
                                // setDestinationDetails([e.target.value]);
                                handleSelectWarehouseUser(
                                  e.target.value,
                                  currentDestinationId - 1,
                                  "destination"
                                );
                              }}
                            >
                              {/* <MenuItem disabled>Select warehouse</MenuItem> */}
                              {warehouseUserList.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option.first_name}{" "}
                                  {option.last_name ? option.last_name : ""}
                                </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Concern person name{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          name="concern_person_name"
                          placeholder="Enter the name"
                          value={item.concern_person_name}
                          onChange={(e) =>
                            handleOnChangeDestination(
                              e,
                              currentDestinationId - 1
                            )
                          }
                        />
                        <div className="create-trip-error">
                          {!destinationDetails[currentDestinationId - 1]
                            .concern_person_name
                            ? errDestinationAddress.errPersonName
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Concern person Mobile Number{" "}
                          <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          name="concern_person_mobile_number"
                          placeholder="Enter the mobile number"
                          value={item.concern_person_mobile_number}
                          onChange={(e) => {
                            if (e.target.value.length <= 10) {
                              setErrDestinationAddress({
                                ...errDestinationAddress,
                                errPersonMobileNumber: "",
                              });
                              handleOnChangeDestination(
                                e,
                                currentDestinationId - 1
                              );
                            }
                          }}
                        />
                        <div className="create-trip-error">
                          {destinationDetails[currentDestinationId - 1]
                            .concern_person_mobile_number < 6000000000
                            ? errDestinationAddress.errPersonMobileNumber
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Pincode <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          name="postcode"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="Enter Pincode"
                          disabled
                          value={item.postcode}
                          onChange={(e) => {
                            if (e.target.value.length <= 6) {
                              setErrPincodeNotServiceable("");
                              handleOnChangeDestination(
                                e,
                                currentDestinationId - 1
                              );
                              if (e.target.value.length == 6) {
                                handleGetAddressDestination(
                                  e.target.value,
                                  currentDestinationId - 1
                                );
                              }
                            }
                          }}
                        />
                        <div className="create-trip-error">
                          {errPincodeNotServiceable
                            ? errPincodeNotServiceable
                            : null}
                          {destinationDetails[currentDestinationId - 1]
                            .postcode.length != 6
                            ? errDestinationAddress.errPostcode
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Village <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          className="create-trip-input"
                          id="outlined-select-currency"
                          select
                          size="small"
                          name="village"
                          value={item.village}
                          disabled
                          onChange={(e) =>
                            handleOnChangeDestination(
                              e,
                              currentDestinationId - 1
                            )
                          }
                        // label="Select"
                        // value={currency}
                        // onChange={handleChange}
                        >
                          <MenuItem value="0" disabled>
                            Select Village
                          </MenuItem>
                          {destinationVillagesList.map((village, index) => {
                            return (
                              <MenuItem key={index} value={village}>
                                {village}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <div className="create-trip-error">
                          {destinationDetails[currentDestinationId - 1]
                            .village == "0"
                            ? errDestinationAddress.errVillage
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Sub District <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="sub district"
                          value={item.sub_district}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          District <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="District"
                          value={item.district}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          State <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="state"
                          value={item.state}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head"> Landmark</h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          name="landmark"
                          placeholder="Enter the landmark"
                          value={item.landmark}
                          onChange={(e) =>
                            handleOnChangeDestination(
                              e,
                              currentDestinationId - 1
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="create-trip-input-container">
                      <button
                        className="create-trip-save-btn"
                        onClick={() => handleDestinationSave()}
                      >
                        Save
                      </button>
                    </div>
                  </Create_trip_drawer_container>
                </Drawer>
              );
            })
          : null}

        {/*Destination details end*/}
        {/*Driver's details start*/}
        {currentTab == "driver" ? (
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => toggleDrawer("right", false)}
          >
            <Create_trip_drawer_container className="create-trip-drawer-container">
              <div
                className="create-trip-right-most-head"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4>Driver's details:</h4>{" "}
                <Close
                  onClick={handleCloseDriverDrawer}
                  style={{ marginRight: "20px", cursor: "pointer" }}
                />
              </div>
              <div className="create-trip-from-body">
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      First name <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter first name"
                      value={driverDetails.first_name}
                      onChange={(e) => {
                        setDriverDetails({
                          ...driverDetails,
                          first_name: e.target.value,
                        });
                      }}
                    />
                    <div className="create-trip-error">
                      {!driverDetails.first_name
                        ? errDriverDetails.errFirstName
                        : null}
                    </div>
                  </div>
                </div>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head"> Last name</h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter last name"
                      value={driverDetails.last_name}
                      onChange={(e) => {
                        setDriverDetails({
                          ...driverDetails,
                          last_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Mobile number <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter the mobile number"
                      value={driverDetails.mobile_number}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          setDriverDetails({
                            ...driverDetails,
                            mobile_number: e.target.value,
                          });
                        }
                      }}
                    />
                    <div className="create-trip-error">
                      {driverDetails.mobile_number < 6000000000
                        ? errDriverDetails.errMobileNumber
                        : null}
                    </div>
                  </div>
                </div>

                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Alternate Mobile number{" "}
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter the mobile number"
                      value={driverDetails.alternate_mobile_number}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          setDriverDetails({
                            ...driverDetails,
                            alternate_mobile_number: e.target.value,
                          });
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Driving license number{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter the driving licence number"
                      value={driverDetails.driving_licence_number}
                      onChange={(e) => {
                        setDriverDetails({
                          ...driverDetails,
                          driving_licence_number: e.target.value,
                        });
                      }}
                    />
                    <div className="create-trip-error">
                      {driverDetails.driving_licence_number.length >= 6
                        ? null
                        : errDriverDetails.errDLNumber}
                    </div>
                  </div>
                </div>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    {/* <h4 className="create-trip-input-head">
                      {" "}
                      Upload Driving License{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4> */}
                    <TextField
                      size="small"
                      id="dropdown"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      disabled
                      // disabled={index == 0 ? true : false}
                      value={"Upload Driving license"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          // onClick={handleAddAttachment}
                          >
                            <button className="upload_button">
                              <input
                                // name="upload"
                                name="attachment_file"
                                id={`uploaddl`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={handleDrivingPhoto}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label
                                htmlFor={`uploaddl`}
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              >
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                    // backgroundColor: "red",
                                    // width: "100%",
                                    // height: "100%",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <input
                      type="file"
                      className="create-trip-input"
                      accept="image/png, image/jpeg, application/pdf"
                      onChange={handleDrivingPhoto}
                    /> */}
                    <div className="create-trip-dl-name">
                      {drivingPhoto ? drivingPhoto.name : null}
                    </div>
                    <div className="create-trip-error">
                      {!drivingPhoto ? errDriverDetails.errDLDocs : null}
                    </div>
                    <div className="create-trip-error">
                      {drivingPhoto
                        ? drivingPhoto.size >
                          process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size should be less than  ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB`
                          : null
                        : null}
                    </div>
                  </div>
                </div>
                <div>
                  {errTotalDriver ? (
                    <Alert severity="error">{errTotalDriver}</Alert>
                  ) : null}
                </div>
                <div className="create-trip-input-container">
                  <button
                    className="create-trip-save-btn"
                    onClick={() => handleDriverSave()}
                  >
                    {driverLoadingButton ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Create_trip_drawer_container>
          </Drawer>
        ) : null}

        {currentTab === "customer" ? (
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => toggleDrawer("right", false)}
          >
            <Create_trip_drawer_container className="create-trip-drawer-container">
              <div
                className="create-trip-right-most-head"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4>Add Customer </h4>{" "}
                <Close
                  onClick={handleCloseDriverDrawer}
                  style={{ marginRight: "20px", cursor: "pointer" }}
                />
              </div>
              <div className="create-trip-from-body">
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Name <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter customer name"
                      // value={addCustomerData?.name}
                      onChange={(e) => {
                        setAddCustomerData({
                          ...addCustomerData,
                          name: e.target.value,
                        });
                      }}
                    />
                    {/* <div className="create-trip-error">
                      {!driverDetails.first_name
                        ? errDriverDetails.errFirstName
                        : null}
                    </div> */}
                  </div>
                </div>

                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Upload Customer's logo{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      id="dropdown"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      disabled
                      // disabled={index == 0 ? true : false}
                      value={"Upload Customer's logo"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          // onClick={handleAddAttachment}
                          >
                            <button className="upload_button">
                              <input
                                // name="upload"
                                name="attachment_file"
                                id={`uploadcustlogo`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={handleCustomerLogo}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label
                                htmlFor={`uploadcustlogo`}
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              >
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                    // backgroundColor: "red",
                                    // width: "100%",
                                    // height: "100%",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <div className="create-trip-dl-name">
                      {addCustomerData?.logo
                        ? addCustomerData?.logo?.name
                        : null}
                    </div>
                    <div className="create-trip-error">
                      {!drivingPhoto ? errDriverDetails.errDLDocs : null}
                    </div>
                    <div className="create-trip-error">
                      {drivingPhoto
                        ? drivingPhoto.size >
                          process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size should be less than  ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB`
                          : null
                        : null}
                    </div>
                  </div>
                </div>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Description <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      // value={remarks}
                      placeholder="Write description.."
                      style={{
                        width: "80%",
                        // height: 100,
                        // marginBottom: "2rem",
                        padding: ".4rem",
                      }}
                      onChange={(e) => {
                        setAddCustomerData({
                          ...addCustomerData,
                          description: e.target.value,
                        });
                      }}
                    />
                    <div className="create-trip-error">
                      {!driverDetails.first_name
                        ? errDriverDetails.errFirstName
                        : null}
                    </div>
                  </div>
                </div>
                <div>
                  {errCustomerFinal ? (
                    <Alert severity="error">{errCustomerFinal}</Alert>
                  ) : null}
                </div>
                <div className="create-trip-input-container">
                  <button
                    className="create-trip-save-btn"
                    onClick={() => handleCustomerSave()}
                  >
                    {customerLoadingButton ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Create_trip_drawer_container>
          </Drawer>
        ) : null}

        {/*Driver's details end*/}
      </div>
    </Create_Trip_Main_Container>
  );
}

export default CreateTrip;
