import React from "react";
import { AboutStyle } from "./about";

const About = () => {
  return (
    <AboutStyle className="about">
      <div className="about-dis">
        <span>
          <img src="/assets/landingPageAssets/quotes-start.png" alt="quotes" />
        </span>{" "}
        We provide supply chain solutions to a diverse base of e-commerce
        marketplaces, direct-to-customer, SMEs accross several verticals
        connected digitally till end consumer{"  "}
        <span>
          <img src="/assets/landingPageAssets/quotes-end.png" alt="quotes" />
        </span>
      </div>
    </AboutStyle>
  );
};

export default About;
