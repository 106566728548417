import React from "react";
import { MaintenancepageStyle } from "./MaintenancePageStyle";
import underMaintenanceImg from "../../assets/website_under_maintenance.png";

const MaintenancePage = () => {
  return (
    <MaintenancepageStyle className="maintenance-container">
      <img src={underMaintenanceImg} alt="Under Maintenance" />
      <h2>Leap Supply</h2>
      <h1>The Site is currently down for maintenance.</h1>
      <p>Sorry for the inconvenience. We'll be back soon!</p>
      <div className="cool-animation-container">
        <div className="cool-animation-ball"></div>
        <div className="cool-animation-ball"></div>
        <div className="cool-animation-ball"></div>
      </div>
    </MaintenancepageStyle>
  );
};

export default MaintenancePage;
