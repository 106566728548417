import styled from "styled-components";
import { FontFamily, colorPalette, colorPaletteDark } from "../../themes/theme";

export const VehicleStyle = styled.div`
  //vehicle.jsx styling

  width: 100%;
  height: 100vh;
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

  .success_onboard_wrapper {
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .success_text {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .details {
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid
      ${(props) => (props.mode ? colorPaletteDark.white : colorPalette.black)};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .details_onboard_wrapper {
    width: 100%;
    height: 100%;
    flex-direction: column;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  success_onboard_wrapper .details {
    font-size: 14px;
    margin-top: 10px;
    border: 1px solid green;
    padding: 10px;
  }

  .details_onboard_Left_box {
    flex: 1.7;
    background-color: ${colorPalette.secondaryWhite};
    height: 100%;
    box-shadow: ${colorPalette.boxShadowPrimary};
    margin: 0 auto;
  }

  .details_onboard_Right_box {
    flex: 9.5;
    /* position: sticky;
    overflow: scroll; */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 90%;
  }

  .vehicles_container_details {
    width: 95%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
  }

  .vehicleName {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .arrow_icon {
    font-size: 2rem;
    color: ${colorPalette.primaryColor};
    font-weight: bolder;
  }

  .name_vehicle {
    font-size: 1rem;
    font-family: ${FontFamily.primaryFontFamily};
    margin-left: 1rem;
  }

  .vehicleId {
    display: flex;
    padding: 0 2rem;
    /* width: 4rem; */
    align-items: center;
    justify-content: center;
    /* background-color: red; */
  }

  .vehicle_id {
    margin-right: 0.5rem;
    font-size: 1rem;
  }

  .id_value {
    font-size: 1rem;
    color: grey;
  }

  .main_details_container {
    width: 95%;
    margin: 0 auto;
    padding: 1rem 0rem;
  }

  .Mui-selected {
    background-color: ${colorPalette.primaryColor} !important;
    color: white !important;
    font-family: ${FontFamily.primaryFontFamily};
  }
  .tab {
    width: 20%;
    font-family: ${FontFamily.primaryFontFamily} !important;
  }
  .tab1 {
    width: 20%;
    font-family: ${FontFamily.primaryFontFamily} !important;
  }
  .tab2 {
    width: 20%;
    font-family: ${FontFamily.primaryFontFamily} !important;
  }
  .tab3 {
    width: 20%;
    font-family: ${FontFamily.primaryFontFamily} !important;
  }
  .tab4 {
    width: 20%;
    font-family: ${FontFamily.primaryFontFamily} !important;
  }

  .vehicles_container {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    grid-auto-rows: min-content;
    height: 80vh;
    /* width: 100%; */
    gap: 10px;
    /* background-color: ${colorPalette.primaryColor}; */
    /* width: 100%; */
    /* padding: 1rem 0rem; */
    /* position: sticky; */
    /* height: 500px; */
    overflow-y: scroll;
    /* background-color: bisque; */
  }

  .vehicle_card {
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    /*box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  */
    width: 100%;
    border-radius: 5px;
    display: flex;
    /* height: 35%; */
    align-items: center;
    justify-content: center;
    /* margin: 1rem auto; */
    /*background-color: red;
  */ /*height: 20%;
  */
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .vehicle_left {
    /* flex: 6; */
    padding: 0.5rem;
    min-width: auto;
    width: 100%;
    /* background-color: aliceblue; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .vehicles_div {
    /* height: 90%; */
    padding: 0.2rem 0rem;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .vehicles_attributes {
    font-size: 10px;
    font-family: "${FontFamily.primaryFontFamily}";
    font-weight: normal;
    /* color: #000000; */
  }

  .vehicles_value {
    font-size: 10px;
    font-family: "${FontFamily.primaryFontFamily}";
    color: grey;
    font-weight: normal;
    /* margin-left: 0.5rem; */
  }

  .vehicle_right {
    /* flex: 4; */
    /* padding: 1rem; */
    /* background-color: aliceblue; */
    display: flex;
    padding: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }

  /*/=============================================================================*/

  @media only screen and (max-width: 499px) {
    width: 100%;
    height: 100vh;

    .details_onboard_wrapper {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .details_onboard_Left_box {
      flex: 1.7;
      background-color: ${colorPalette.secondaryWhite};
      height: 100%;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
        rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
      margin: 0 auto;
    }

    .details_onboard_Right_box {
      /* flex: 12; */
      /* position: sticky;
      overflow: scroll; */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      /* background-color: ${colorPalette.primaryColor}; */
    }

    .vehicles_container_details {
      width: 98%;
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* background-color: red;s */
      /* margin-top: -12rem;
    margin-bottom: -1rem; */
    }

    .vehicleName {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .arrow_icon {
      font-size: 2rem;
      color: ${colorPalette.primaryColor};
      font-weight: bolder;
    }

    .name_vehicle {
      font-size: 0.9rem;
      font-family: ${FontFamily.primaryFontFamily};
      margin-left: 1rem;
    }

    .vehicleId {
      display: flex;
      padding: 0 2rem;
      /* width: 4rem; */
      align-items: center;
      justify-content: center;
      /* background-color: red; */
    }

    .vehicle_id {
      margin-right: 0.5rem;
      font-size: 0.9rem;
    }

    .id_value {
      font-size: 1rem;
      color: grey;
    }

    .main_details_container {
      width: 100%;
      margin: 0 auto;
      padding: 1rem 0rem;
    }

    .Mui-selected {
      background-color: ${colorPalette.primaryColor} !important;
      color: white !important;
      font-family: ${FontFamily.primaryFontFamily};
    }
    .tab {
      width: 50% !important;
      font-family: ${FontFamily.primaryFontFamily} !important;
    }
    .tab1 {
      width: 20%;
      font-family: ${FontFamily.primaryFontFamily} !important;
    }
    .tab2 {
      width: 20%;
      font-family: ${FontFamily.primaryFontFamily} !important;
    }
    .tab3 {
      width: 100% !important;
      margin-left: 2rem !important;
      font-family: ${FontFamily.primaryFontFamily} !important;
    }
    .tab4 {
      width: 20%;
      font-family: ${FontFamily.primaryFontFamily} !important;
    }

    .vehicles_container {
      width: 100%;
      /* margin-left: -2rem; */
      padding: 1rem 0rem;
      margin: 0 auto;
      position: sticky;
      height: 500px;
      overflow-y: scroll;
      /* background-color: bisque;s */
    }

    .vehicle_card {
      box-shadow: ${colorPalette.boxShadowPrimary};
      width: 100%;
      display: flex;
      /* background-color: #99cc99; */
      height: 35%;
      align-items: center;
      justify-content: center;
      /* margin: 1rem auto; */
      /* background-color: rgb(220, 184, 184);s */
      /* height: 20%; */
    }

    .vehicle_left {
      /* margin-left: 3rem; */
      flex: none;
      width: 50%;
      padding: 1rem;
      /* background-color: aliceblue; */

      /* display: flex;
    flex-direction: column;
    align-items: flex-start */
    }

    .vehicles_div {
      /* height: 90%; */
      padding: 0.2rem 0rem;
      /* background-color: red; */
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }

    .vehicles_attributes {
      font-size: 0.6rem;
      /* width: 100%; */
      /* background-color: red ; */
      /* color: #000000; */
    }

    .vehicles_value {
      font-size: 0.6rem;
      color: grey;
      margin-left: 1rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .vehicle_right {
      flex: none;
      width: 50%;
      /* background-color: aliceblue; */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
