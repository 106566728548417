const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

export const imageModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? "90%" : "auto",
  height: "auto",
  // border: `1px solid ${colorPalette.black}`,
  borderRadius: 1,
  boxShadow: 24,
  p: 0.2,
  overflow: "hidden",
  backgroundColor: "#fff",
};

export const pdfEmbedStyle = isMobile
  ? {
      maxWidth: "100%",
      width: "100%",
      height: "100%",
    }
  : {
      maxWidth: "70vw",
      width: "50vw",
      height: "80vh",
    };
