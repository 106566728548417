import styled from "styled-components";

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

export const SomethingWentWrongStyle = styled.div`
  /* ErrorPage.css */

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .close-icon {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #454545;
    cursor: pointer;
  }

  .error-png {
    width: "200px";
    height: 200px;
  }

  .error-content {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* max-width: 800px; */
  }

  .error-content .error-heading {
    font-size: 28px;
    margin-bottom: 16px;
  }

  .error-content p {
    margin-bottom: 12px;
  }

  .error-content a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }

  .text-bold {
    font-weight: 500;
  }

  .developer_container {
    width: 100%;
  }

  .developer_content {
    width: 100%;
    display: grid;
    grid-template-columns: ${isMobile ? "32% 68%" : "120px auto"};
    text-align: left;
  }

  @media only screen and (max-width: 480px) {
    .button-close {
      width: 100px;
    }
  }
`;
