import styled from "styled-components";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";

export const Login_container_changePassword = styled.div`
  /* .login_container_changePassword {
    width: 100vw; */
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  height: 100vh;

  /* } */

  .arrow-back {
    width: 100%;
    display: flex;
    align-content: center;
    background-color: ${colorPalette.primaryColor};
    padding: 20px 40px;
    color: ${colorPalette.white};
  }

  .blur {
    width: 100%;
    -webkit-filter: blur(10x);
  }

  .change-password-form {
    margin-top: 0;
    padding: 0.4rem 0.8rem;
    background-color: white;
    height: 70%;
    width: 27%;
    z-index: 3;
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    border: 1px solid green;
  }

  .login_right {
    z-index: 9999;
    flex: 6;
    border-radius: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  .logo_leap {
    width: 100%;
    height: 5%;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  .login_input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98%;
    flex-direction: column;
    margin: 1rem auto;
    /* background-color: #016938; */
    /* height: 70%; */
  }

  .headings_login-forget {
    width: 100%;
    align-items: center;
    height: 10%;
    padding: 1.5rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login_text_forget_password {
    font-size: ${typography.heading4};
    /* font-size: ${typography.heading2}; */
    font-family: ${FontFamily.primaryFontFamily};
    color: black;
    font-weight: ${FontWeight.bold};
    /* color: red; */
  }

  .button_container_forget_password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
  }

  @media only screen and (max-width: 480px) {
    .blur {
      /* background: rgba(0, 0, 0, 0.5); */
      /* filter: blur(10x); */
      width: 100%;
      -webkit-filter: blur(10x);
    }

    .login_right {
      z-index: 9999;
      flex: 12;
      border-radius: 20px;
      height: 100vh;
      /* width: 100vw; */
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
    }

    .change-password-form {
      /* flex: 11; */
      /* margin-top: 8rem; */
      /* padding: .4rem .8rem; */
      background-color: white;
      /* background-color: antiquewhite; */
      height: 70%;
      width: 90%;
      margin-bottom: 10rem;
      z-index: 3;
      box-shadow: ${colorPalette.boxShadowPrimary};
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border: 1px solid green;
    }

    .logo_leap {
      width: 100%;
    }

    .login_input {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
`;
