import React, { useState, useEffect, useContext } from "react";
import logo from "../../assets/logo/leap_supply_logo.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Menu } from "@mui/icons-material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import changePassword from "../../assets/dashboard_icons/change_password.svg";
import logout from "../../assets/dashboard_icons/logout_icon.svg";
import INDENTGRAY from "../../assets/dashboard_icons/indent-gray.svg";
import INDENTWHITE from "../../assets/dashboard_icons/replace_icons/new/Indent.svg";
import QUOTEWHITE from "../../assets/dashboard_icons/replace_icons/new/Quotation.svg";
import QUOTEGRAY from "../../assets/dashboard_icons/quote-gray.svg";
import dashboard from "../../assets/dashboard_icons/new/dashboard icon.svg";
import dashboard_replace from "../../assets/dashboard_icons/replace_icons/new/Dashboad.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, Drawer, Button, FormControlLabel } from "@mui/material";
import selected_teams from "../../assets/selected_teams.jpeg";
import unselected_teams from "../../assets/unselected_teams.jpeg";
import RECEIVABLESWHITE from "../../assets/dashboard_icons/payment-receivables-white.svg";
import RECEIVABLESGRAY from "../../assets/dashboard_icons/payment-receivables-gray.svg";
import receivable_replace from "../../assets/dashboard_icons/replace_icons/new/Paymentreceivable.svg";

import OEM_ICON from "../../assets/dashboard_icons/replace_icons/OEMicon.svg";
import oem_replace from "../../assets/dashboard_icons/replace_icons/new/VehicelManufacturerActive.svg";
import customer_icon from "../../assets/dashboard_icons/new/customers.svg";
import customer_replace from "../../assets/dashboard_icons/replace_icons/new/Customer.svg";
import TRIP_ICON from "../../assets/dashboard_icons/trip_icon.svg";
import payments from "../../assets/dashboard_icons/new/payment_inactive_icon.svg";
import payments_replace from "../../assets/dashboard_icons/replace_icons/new/Paymentys.svg";
import trip_replace from "../../assets/dashboard_icons/replace_icons/new/Trip(1).svg";
import VEHICLE_ICON_GREEN from "../../assets/dashboard_icons/vehicle.svg";
import VEHICLE_ICON from "../../assets/dashboard_icons/vehicle_icon.svg";
import vehicle_replace from "../../assets/dashboard_icons/replace_icons/new/Vehicles.svg";
import CARRIER_ICON from "../../assets/dashboard_icons/carrier_icon.svg";
import carrier_replace from "../../assets/dashboard_icons/replace_icons/new/Transporter.svg";
import CARRIER_ICON_GREEN from "../../assets/dashboard_icons/carrier.svg";
import { NavLink, useHistory } from "react-router-dom";
import { readLoginData } from "../../helper/dataDecryptionHelper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Sidebar_style } from "../sideBarMain/sidebarMain";
import { Mobile_header_main_container } from "./mobile-header";
import { DarkModeContext } from "../../context/darkModeContext";

function MobileHeader({ headerTitle }) {
  const history = useHistory();
  const [state, setState] = useState({ left: false });
  const [loginData, setLoginData] = useState();
  const [currentPath, setCurrentPath] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenMenu = () => {
    toggleDrawer("left", true);
  };

  useEffect(() => {
    if (history.location.pathname.split("/").length > 1) {
      setCurrentPath(history.location.pathname.split("/")[1]);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    // window.location.reload();
    history.push("/login");
  };

  useEffect(() => {
    if (readLoginData()) {
      setLoginData(readLoginData());
    }
  }, []);

  const handleGoHome = () => {
    history.push("/dashboard");
  };

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };
  const handleClickOpenChangePassword = () => {
    if (
      localStorage.getItem("website_url") === "https://leapsupply.in/customer"
    ) {
      history.push("/customer/change-passwords");
    } else {
      history.push("/change-passwords");
    }
    // history.push("/change-passwords");
  };
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    transition: "10s ease all",
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        transition: "10s ease all",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "white",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      // backgroundColor:
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#F3CE5A"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#F3CE5A",
      borderRadius: 20 / 2,
    },
  }));
  const { toggle, darkMode } = useContext(DarkModeContext);
  return (
    <>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={() => toggleDrawer("left", false)}
      >
        <Sidebar_style
          className="sidebar_onboard_container"
          style={{ width: "70vw" }}
        >
          <div className="sidebar_onboard_wrapper">
            <div className="sidebar_onboard_Right_box">
              <div className="sidebar-top-container">
                <div className="logo_logistics">
                  <div className="company_name_div" onClick={handleGoHome}>
                    {/* <div className="logo_div">
              <img src={LOGISTICS_FAARMS} alt="Logo" className="logo" />
            </div> */}
                    <img src={logo} alt="Logo" className="logo" />
                  </div>
                </div>

                <div className="onboarding">
                  {/* <div className="sidebar-single-container"> */}
                  <NavLink
                    to="/dashboard"
                    activeClassName="sidebarListItem active"
                    className="link sidebarSingle"

                    // onClick={setImageColor("Carriers")}
                  >
                    {currentPath === "dashboard" ? (
                      <>
                        {" "}
                        <img
                          src={dashboard_replace}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={dashboard}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    )}

                    <h4
                      className="vehicles"
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                      }}
                    >
                      Dashboard
                    </h4>
                  </NavLink>
                  <NavLink
                    to="/teams"
                    activeClassName="sidebarListItem active"
                    className="link"

                    // onClick={setImageColor("Carriers")}
                  >
                    {currentPath === "teams" ? (
                      <>
                        {" "}
                        <img
                          src={selected_teams}
                          style={{ height: 20, width: 20, marginLeft: "1rem" }}
                          alt=""
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={unselected_teams}
                          alt=""
                          style={{ height: 20, width: 20, marginLeft: "1rem" }}
                        />
                      </>
                    )}

                    <h4 className="vehicles" onClick={() => {}}>
                      Teams
                    </h4>
                  </NavLink>
                  <NavLink
                    to="/payment-receivables"
                    activeClassName="sidebarListItem active"
                    className="link"
                  >
                    {currentPath === "payment-receivables" ? (
                      <>
                        {" "}
                        <img
                          src={receivable_replace}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={RECEIVABLESGRAY}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    )}

                    <h4
                      className="vehicles"
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                        localStorage.removeItem("selectedTransporter");
                        localStorage.removeItem("selectedIndex");
                      }}
                    >
                      Payment Receivables
                    </h4>
                  </NavLink>
                  <NavLink
                    to="/trips-indents"
                    activeClassName="sidebarListItem active"
                    className="link"

                    // onClick={setImageColor("Trips")}
                  >
                    {currentPath === "trips-indents" ? (
                      <>
                        {" "}
                        <img
                          src={INDENTWHITE}
                          alt=""
                          style={{
                            marginLeft: "1rem",
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={INDENTGRAY}
                          alt=""
                          style={{
                            marginLeft: "1rem",
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      </>
                    )}

                    <h4
                      className="vehicles "
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                        localStorage.removeItem("selectedTransporter");
                        localStorage.removeItem("selectedIndex");
                      }}
                    >
                      Trip Indents
                    </h4>
                  </NavLink>
                  <NavLink
                    to="/customers"
                    activeClassName="sidebarListItem active"
                    className="link sidebarSingle"
                  >
                    {currentPath === "customers" ? (
                      <>
                        {" "}
                        <img
                          src={customer_replace}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={customer_icon}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    )}

                    <h4
                      className="vehicles"
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                      }}
                    >
                      Customers
                    </h4>
                  </NavLink>
                  <NavLink
                    to="/vehicles-manufacturers"
                    activeClassName="sidebarListItem active"
                    className="link sidebarSingle"
                  >
                    {/* <img src={TRIP_ICON_GREEN} alt="trip-icon" /> */}
                    {/* Onboarding */}
                    {currentPath === "vehicles-manufacturers" ? (
                      <>
                        {" "}
                        <img
                          src={oem_replace}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={OEM_ICON}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    )}

                    <h4
                      className="vehicles"
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                      }}
                    >
                      Vehicles Manufacturer
                    </h4>
                  </NavLink>

                  <NavLink
                    to="/transporters"
                    activeClassName="sidebarListItem active"
                    className="link"
                  >
                    {currentPath === "transporters" ? (
                      <>
                        {" "}
                        <img
                          src={carrier_replace}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={CARRIER_ICON}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    )}

                    <h4
                      className="vehicles"
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                      }}
                    >
                      Transporters
                    </h4>
                  </NavLink>

                  <NavLink
                    to="/vehicles"
                    activeClassName="sidebarListItem active"
                    className="link"
                  >
                    {currentPath === "vehicles" ? (
                      <>
                        {" "}
                        <img
                          src={vehicle_replace}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={VEHICLE_ICON}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    )}

                    <h4
                      className="vehicles"
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                      }}
                    >
                      Vehicles
                    </h4>
                  </NavLink>

                  <NavLink
                    to="/driver"
                    activeClassName="sidebarListItem active"
                    className="link"
                  >
                    {currentPath === "driver" ? (
                      <>
                        {" "}
                        <img
                          src={carrier_replace}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={CARRIER_ICON}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    )}

                    <h4
                      className="vehicles"
                      onClick={() => {
                        // localStorage.removeItem("IntransiteStatus");
                        // localStorage.removeItem("tripType");
                        // localStorage.removeItem("selectedTransporter");
                        // localStorage.removeItem("selectedIndex");
                      }}
                    >
                      Drivers
                    </h4>
                  </NavLink>

                  {/* <NavLink
                    to={
                      localStorage.getItem("createTripSource") == "/trips"
                        ? "/trips"
                        : "/ftl-trips"
                    }
                    activeClassName="sidebarListItem active"
                    className="link"
                  >
                    {currentPath === "trips" || currentPath === "ftl-trips" ? (
                      <>
                        {" "}
                        <img
                          src={trip_replace}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={TRIP_ICON}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    )}

                    <h4
                      className="vehicles"
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                      }}
                    >
                      Trips
                    </h4>
                  </NavLink> */}

                  <NavLink
                    to={
                      localStorage.getItem("createTripSource") == "/trips"
                        ? "/trips"
                        : "/ftl-trips"
                    }
                    activeClassName="sidebarListItem active"
                    className="link"
                    onClick={() => {
                      localStorage.setItem("FlagForApiCall", false);
                    }}
                  >
                    {currentPath === "trips" || currentPath === "ftl-trips" ? (
                      // &&
                      // fullCurrentPath !== "/faarms-trips/create-trip"
                      <>
                        {" "}
                        <img
                          src={trip_replace}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={TRIP_ICON}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    )}

                    <h4
                      className="vehicles"
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                        localStorage.removeItem("selectedTransporter");
                        localStorage.removeItem("selectedIndex");
                        localStorage.setItem("Trip_Type", "ftl_trip");
                      }}
                    >
                      FTL Trips
                    </h4>
                  </NavLink>
                  <NavLink
                    to="/faarms-trips"
                    activeClassName={"sidebarListItem active"}
                    className="link sidebarSingle"
                    // onClick={localStorage.removeItem("reloadPage")}
                  >
                    {/* <img src={TRIP_ICON_GREEN} alt="trip-icon" /> */}
                    {/* Onboarding */}
                    {
                      // fullCurrentPath === "/faarms-trips/create-trip"
                      // ||
                      currentPath === "faarms-trips" ? (
                        <>
                          {" "}
                          <img
                            src={trip_replace}
                            alt=""
                            style={{ marginLeft: "1rem" }}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <img
                            src={TRIP_ICON}
                            alt=""
                            style={{ marginLeft: "1rem" }}
                          />
                        </>
                      )
                    }

                    <h4
                      className="vehicles"
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                        localStorage.removeItem("selectedTransporter");
                        localStorage.removeItem("selectedIndex");
                        localStorage.removeItem("createTripSource");
                        localStorage.setItem("Trip_Type", "faarms_trip");
                      }}
                    >
                      Faarms Trips
                    </h4>
                  </NavLink>
                  <NavLink
                    to="/old-trips"
                    activeClassName={"sidebarListItem active"}
                    className="link sidebarSingle"
                    // onClick={localStorage.removeItem("reloadPage")}
                  >
                    {/* <img src={TRIP_ICON_GREEN} alt="trip-icon" /> */}
                    {/* Onboarding */}
                    {
                      // fullCurrentPath === "/old-trips/create-trip" ||
                      currentPath === "old-trips" ? (
                        <>
                          {" "}
                          <img
                            src={trip_replace}
                            alt=""
                            style={{ marginLeft: "1rem" }}
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <img
                            src={TRIP_ICON}
                            alt=""
                            style={{ marginLeft: "1rem" }}
                          />
                        </>
                      )
                    }

                    <h4
                      className="vehicles"
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                        localStorage.removeItem("selectedTransporter");
                        localStorage.removeItem("selectedIndex");
                        localStorage.removeItem("createTripSource");
                        localStorage.setItem("Trip_Type", "old_trip");
                      }}
                    >
                      Old Trips
                    </h4>
                  </NavLink>
                  {/* <NavLink
                    to="/indents"
                    activeClassName="sidebarListItem active"
                    className="link"

                    // onClick={setImageColor("Trips")}
                  >
                    {currentPath === "indents" ? (
                      <>
                        {" "}
                        <img
                          src={INDENTWHITE}
                          alt=""
                          style={{
                            marginLeft: "1rem",
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={INDENTGRAY}
                          alt=""
                          style={{
                            marginLeft: "1rem",
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      </>
                    )}

                    <h4
                      className="vehicles"
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                      }}
                    >
                      Indents
                    </h4>
                  </NavLink> */}

                  {/* <NavLink
                    to="/quotations"
                    activeClassName="sidebarListItem active"
                    className="link"

                    // onClick={setImageColor("Trips")}
                  >
                    {currentPath === "quotations" ? (
                      <>
                        {" "}
                        <img
                          src={QUOTEWHITE}
                          alt=""
                          style={{
                            marginLeft: "1rem",
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={QUOTEGRAY}
                          alt=""
                          style={{
                            marginLeft: "1rem",
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      </>
                    )}

                    <h4
                      className="vehicles"
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                      }}
                    >
                      Quotations
                    </h4>
                  </NavLink> */}

                  {/* <NavLink
                    to="/payments"
                    activeClassName="sidebarListItem active"
                    className="link"
                  >
                    {currentPath === "payments" ? (
                      <>
                        {" "}
                        <img
                          src={payments_replace}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={payments}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    )}

                    <h4
                      className="vehicles"
                      onClick={() => {
                        localStorage.removeItem("IntransiteStatus");
                        localStorage.removeItem("tripType");
                      }}
                    >
                      Payments
                    </h4>
                  </NavLink> */}
                </div>
              </div>
              <div className="btn_logout">
                <h6 className="user_name">
                  <AccountCircleIcon />
                  <span style={{ marginLeft: "3px" }}>
                    {loginData ? loginData.USER_NAME : "Guest"}
                  </span>
                </h6>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div className="changePassword">
                    <img src={changePassword} alt="" />{" "}
                    <span onClick={handleClickOpenChangePassword}>
                      Change Password
                    </span>
                  </div>
                  <div className="logout-div">
                    <img src={logout} alt="" />{" "}
                    <span onClick={handleClickOpen}>Logout</span>
                  </div>
                </div>
                {/* <button className="button_logout" onClick={handleClickOpen}>
                  LOGOUT
                </button> */}
              </div>
            </div>
          </div>
        </Sidebar_style>
      </Drawer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you really want to Logout?"}
        </DialogTitle>
        <DialogContent style={{ width: "70%" }}>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout}>Yes</Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Mobile_header_main_container className="mobile-header-main-container">
        {/* <NavLink exact to={`${routing}`}>
          <ArrowBackIosNewIcon />
        </NavLink> */}
        <div className="hamberger_menu">
          <Menu
            style={{ margin: "0px 10px" }}
            color="inherit"
            fontSize="large"
            onClick={handleOpenMenu}
          />
          <div className="mobile-header-title">{headerTitle}</div>
        </div>

        {/* <div className="mode_icons"> */}
        <FormControlLabel
          control={
            <MaterialUISwitch
              sx="small"
              checked={darkMode}
              onClick={() => {
                toggle();
              }}
            />
          }
          label=""
          size="small"
        />
        {/* </div> */}
      </Mobile_header_main_container>
    </>
  );
}

export default MobileHeader;
