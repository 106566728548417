import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchItem: "",
  searchWarehouse: "",
  searchManufacturer: "",
  searchVehicleModel: "",
  searchTransporter: "",
  searchVehicles: "",
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    searchData(state, action) {
      state.searchItem = action.payload;
    },
    searchWarehouse(state, action) {
      state.searchWarehouse = action.payload;
    },
    searchManufacturer(state, action) {
      state.searchManufacturer = action.payload;
    },
    searchVehicleModel(state, action) {
      state.searchVehicleModel = action.payload;
    },
    searchTransporter(state, action) {
      state.searchTransporter = action.payload;
    },
    searchVehicles(state, action) {
      state.searchVehicles = action.payload;
    },
  },
});

export const {
  searchData,
  searchWarehouse,
  searchManufacturer,
  searchVehicleModel,
  searchTransporter,
  searchVehicles,
} = searchSlice.actions;
export default searchSlice.reducer;
