import React, { useEffect, useState } from "react";
import { AcceptQuotesDrawer, RightbarIndentStyle } from "./rightbarIndentStyle";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import attachement from "../../../assets/logisticsCustomer/attachement.png";
import deleteIcon from "../../../assets/logisticsCustomer/delete.svg";
import DownloadIcon from "@mui/icons-material/Download";
import demoImage from "../../../assets/login/background image.jpg";
import {
  fetchIndentById,
  getIndentAttachementById,
  getAttachementImage,
} from "../../../pages/logisticsCustomerPages/customerIndentsPage/customerIndentServices";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Drawer, Modal, Typography } from "@mui/material";
import moment from "moment";
import apiUrl from "../../../url/apiUrl";
import axiosInterceptor from "../../../config/https";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import { Box } from "@mui/system";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  indentId,
  updateOnFlag,
  updateOnFlagUpdate,
} from "../../../redux/indentSlice";
import CopyText from "../../copyText/CopyText";
import { readLoginData, readToken } from "../../../helper/dataDecryptionHelper";
import { colorPalette } from "../../../themes/theme";

const RIghtbarIndent = ({ currentTab, selectedIndent }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);
  //
  const [unit, setUnit] = useState();
  const [indentAttachement, setIndentAttachement] = useState([]);
  const [indentImage, setIndentImage] = useState();
  const [openAttachment, setOpenAttachment] = useState(false);

  const handleCloseAttachement = () => {
    setOpenAttachment(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: colorPalette.white,
    border: `1px solid ${colorPalette.white}`,
    boxShadow: 24,
    p: 0,
  };
  const styleQuotationBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: colorPalette.white,
    borderRadius: "10px",
    // border: "1px solid #fff",
    boxShadow: 24,
    p: 0,
  };
  const getUnitById = async (id) => {
    const response = await axiosInterceptor.get(
      `${apiUrl.unitByIdBaseUrl}/${prefixSalt()}-${convertToBase64(id)}`
    );
    try {
      if (response.status == 200) {
        setUnit(response.data.name);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (selectedIndent?.unit_id) {
      getUnitById(selectedIndent?.unit_id);
    }
  }, [selectedIndent?.unit_id]);

  // const getIndentAttachement = async (id) => {
  //   const response = await getIndentAttachementById(id);
  //   if (response.status >= 200 && response.status < 300) {
  //     setIndentAttachement(response.data);
  //   }
  // };

  // const getIndentImage = async (id) => {
  //   const response = await getAttachementImage(id);
  //   try {
  //     var blobA = response.replace("blob:", "");
  //     var blobB = blobA.replace('"', "");
  //     setIndentImage(blobB);
  //   } catch (err) {}
  // };

  // useEffect(() => {
  //   getIndentAttachement(selectedIndent?.id);
  //   getIndentImage(selectedIndent?.id);
  // }, [selectedIndent]);

  const handleEdit = (id) => {
    dispatch(indentId({ id: id, editMode: true }));
    history.push("/customer/create-indent");
  };
  const [open, setOpen] = React.useState(false);
  const [quotationDetails, setQuotationDetails] = useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let tokensData = readToken();
  const getIndentImage = async (id) => {
    fetch(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/indent/${prefixSalt()}-${convertToBase64(id)}`,
      // { responseType: "application/pdf" },
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
          // Accept: "application/pdf",
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        var file = window.URL.createObjectURL(blob);

        // window.location.assign(file);
        window.open(file, "_blank");
      })
      .catch((err) => {});
  };
  return (
    <RightbarIndentStyle>
      <Drawer
        open={drawerOpen}
        anchor={"right"}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: 440,
          },
        }}
      >
        <AcceptQuotesDrawer className="accept_drawer">
          <div className="accept_drawer_heading">Remarks</div>
          <div className="add_remark">
            <textarea name="accept_remark" placeholder="Add Remark"></textarea>
          </div>
          <div className="accept_button">
            <button>Accept</button>
          </div>
        </AcceptQuotesDrawer>
      </Drawer>
      <Modal
        open={openAttachment}
        onClose={handleCloseAttachement}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            src={"blob:" + indentImage}
            alt="Indent Attachement"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleQuotationBox}>
          <div className="quotation-details-headings">
            {" "}
            <h4 style={{ textAlign: "center", padding: "10px 0px" }}>
              Quotation Details
            </h4>{" "}
          </div>

          <div
            style={{
              padding: "15px 50px",
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>
              {" "}
              Quotation Code:{" "}
              <span style={{ fontWeight: "bold" }}>
                <CopyText text={quotationDetails?.quotation_code} />
              </span>{" "}
            </span>
            <span>
              Created On:{" "}
              <span style={{ fontWeight: "bold" }}>
                {moment(quotationDetails?.created_on).format("DD MMM YYYY")}
              </span>
            </span>
          </div>

          <div
            style={{
              padding: "15px 50px",
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>
              <span>
                Quotation Amount:{" "}
                <span style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  Rs. {quotationDetails?.quotation_amount}
                </span>
              </span>
            </span>
            <span>
              <span>
                Advance Amount:
                <span style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  Rs. {quotationDetails?.advance_amount}
                </span>
              </span>
            </span>
          </div>
        </Box>
      </Modal>
      <div className="indent_address_container">
        <div className="indent_heading">
          <div>{selectedIndent?.indent_status}</div>
          {currentTab === "sent-indents" ||
          currentTab === "fulfilled-indents" ||
          currentTab === "unfulfilled-indents" ||
          currentTab === "quotaion-accepted-indents" ? null : (
            <div className="indent_edit">
              <EditIcon
                onClick={() => {
                  handleEdit(selectedIndent.id);
                  dispatch(updateOnFlagUpdate(false));
                  dispatch(updateOnFlag(false));
                }}
              />{" "}
              Change
            </div>
          )}
        </div>
        <div className="indent_address_details">
          <div className="from">
            <div className="indent_address_icon">
              <LocationOnIcon />
            </div>
            {currentTab === "sent-indents" ||
            currentTab === "draft-indents" ||
            currentTab === "fulfilled-indents" ||
            currentTab === "unfulfilled-indents" ||
            currentTab === "quotaion-accepted-indents" ? (
              <input
                type="text"
                disabled
                className="indent_address"
                value={
                  selectedIndent
                    ? `${selectedIndent?.source[0].village}, ${selectedIndent?.source[0].sub_district}, ${selectedIndent?.source[0].state}, ${selectedIndent?.source[0].sub_district}, ${selectedIndent?.source[0].postcode}`
                    : ""
                }
              />
            ) : (
              <input
                type="text"
                className="indent_address"
                value={
                  selectedIndent
                    ? `${selectedIndent?.source[0].village}, ${selectedIndent?.source[0].sub_district}, ${selectedIndent?.source[0].state}, ${selectedIndent?.source[0].sub_district}, ${selectedIndent?.source[0].postcode}`
                    : ""
                }
              />
            )}

            <div className="location_dotted_line"></div>
          </div>
          <div className="to">
            <div className="indent_address_icon">
              <RadioButtonCheckedIcon />
            </div>
            {currentTab === "sent-indents" ||
            currentTab === "draft-indents" ||
            currentTab === "fulfilled-indents" ||
            currentTab === "unfulfilled-indents" ||
            currentTab === "quotaion-accepted-indents" ? (
              <input
                type="text"
                disabled
                className="indent_address"
                value={
                  selectedIndent
                    ? `${selectedIndent?.destination[0].village}, ${selectedIndent?.source[0].sub_district}, ${selectedIndent?.source[0].state}, ${selectedIndent?.source[0].sub_district}, ${selectedIndent?.source[0].postcode}`
                    : ""
                }
              />
            ) : (
              <input
                type="text"
                className="indent_address"
                value={
                  selectedIndent
                    ? `${selectedIndent?.destination[0].village}, ${selectedIndent?.source[0].sub_district}, ${selectedIndent?.source[0].state}, ${selectedIndent?.source[0].sub_district}, ${selectedIndent?.source[0].postcode}`
                    : ""
                }
              />
            )}
          </div>
        </div>
        <div className="date_and_quantity">
          <div className="indent_date">
            Date : {moment(selectedIndent?.created_on).format("MMM Do YY")}
          </div>
          <div className="indent_qty">
            Goods QTY :{" "}
            {selectedIndent
              ? `${selectedIndent?.quantity_of_goods} ${unit}`
              : "-"}
          </div>
        </div>
      </div>
      <div className="indents_details">
        <div className="indent_details_heading">Indent details</div>
        <div className="indent_details_discription">
          <div className="indent_detail_left_right">
            <div className="indent_detail_left">Indent Code</div>
            <div className="colon">:</div>
            <div className="indent_detail_right">
              {selectedIndent?.indent_code}
            </div>
          </div>
          <div className="indent_detail_left_right">
            <div className="indent_detail_left">Stop</div>
            <div className="colon">:</div>
            <div className="indent_detail_right">
              {selectedIndent
                ? selectedIndent?.source.length > 1 ||
                  selectedIndent?.destination.length > 1
                  ? "Multiple Stop"
                  : "Single Stop"
                : "-"}
            </div>
          </div>
          <div className="indent_detail_left_right">
            <div className="indent_detail_left">Vehicle Type</div>
            <div className="colon">:</div>
            <div className="indent_detail_right">
              {" "}
              {selectedIndent
                ? selectedIndent.vehicle_type.replace(/_/g, " ").toUpperCase()
                : "-"}
            </div>
          </div>
          <div className="indent_detail_left_right">
            <div className="indent_detail_left">Type of material</div>
            <div className="colon">:</div>
            <div className="indent_detail_right">
              <div
                style={{
                  // width: "100px",
                  height: "20px",
                  textoverflow: "ellipsis",
                  whitespace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {selectedIndent ? selectedIndent.material_type : "-"}
              </div>
            </div>
          </div>
          <div className="indent_detail_left_right">
            <div className="indent_detail_left">Invoice Value</div>
            <div className="colon">:</div>
            <div className="indent_detail_right">
              ₹ {selectedIndent ? selectedIndent?.value_of_goods : "-"}
            </div>
          </div>
        </div>
      </div>
      {currentTab === "draft-indents" ? (
        <div className="attachements">
          {/* <div className="attachements_icon"> */}
          <ArticleIcon style={{ color: "green" }} />
          {/* <img src={attachement} alt="attachement" /> */}
          {/* </div> */}
          <div className="attachements_heading">
            <span className="attachement_title">
              Download File
              {/* <DownloadIcon /> */}
            </span>
            {/* <p className="img_name">{indentAttachement?.document_name}</p> */}
          </div>
          {/* <div className="attachements_heading">
            <span
              className="attachement_title"
              onClick={() => setOpenAttachment(true)}
            >
              Download File
            </span>
            <p className="img_name">{indentAttachement?.document_name}</p>
          </div> */}
          <div className="attachements_button">
            <DownloadIcon
              onClick={() => getIndentImage(selectedIndent?.id)}
              style={{ color: "green" }}
            />
          </div>
        </div>
      ) : null}

      {currentTab === "sent-indents" ? (
        <div className="sent_section_message">
          <div className="icon">
            <ErrorOutlineIcon />
          </div>
          {/* <div className="sent_message">
            <p>
              Indent Sent on{" "}
              <b>{moment(selectedIndent?.modified_on).format("DD MMM YY")}</b>
            </p>
            <p>
              Contact <b>Leap Supply</b> team for more details
            </p>
          </div> */}
          {selectedIndent !== undefined &&
          selectedIndent.quotations !== null ? (
            <div>
              {Object.keys(selectedIndent.quotations).length > 0 ? (
                <div className="unfulfilled_message">
                  <p id="sent-idents-quotes">
                    <p>Quotation is created</p>{" "}
                    <button
                      className="quotaion-button"
                      onClick={() => {
                        handleOpen();
                        setQuotationDetails(selectedIndent.quotations);
                      }}
                    >
                      View Quotation
                    </button>
                  </p>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="unfulfilled_message">
              <p id="sent-idents-quotes">
                <p>Quotation is not created.</p>{" "}
              </p>
            </div>
          )}
        </div>
      ) : null}

      {currentTab === "quotaion-accepted-indents" ? (
        <div className="unfulfilled_section_message">
          <div className="icon">
            <ErrorOutlineIcon />
          </div>
          <div className="unfulfilled_message">
            <p>
              Indent fulfilled on{" "}
              <b>{moment(selectedIndent?.modified_on).format("DD MMM YY")}</b>
            </p>
            <p>
              by <b>{selectedIndent?.modified_by}</b>
            </p>
          </div>
        </div>
      ) : null}

      {currentTab === "unfulfilled-indents" ? (
        <div className="unfulfilled_section_message">
          <div className="icon">
            <ErrorOutlineIcon />
          </div>
          <div className="unfulfilled_message">
            <p>
              Indent unfulfilled on{" "}
              <b>{moment(selectedIndent?.modified_on).format("DD MMM YY")}</b>
            </p>
            <p>
              by <b>{selectedIndent?.modified_by}</b>
            </p>
          </div>
        </div>
      ) : null}
    </RightbarIndentStyle>
  );
};

export default RIghtbarIndent;
