import axios from "axios";
import apiUrl from "../url/apiUrl";
const axiosApiInstance = axios.create();

// axios.interceptors.request.use((config) => {
//   config.headers["data"] = encodedString;
//   return config;
// });

function b64EncodeUnicode(str) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode("0x" + p1);
    })
  );
}

export function fetchAppToken(website_url, client, app_version) {
  //
  let headerData = JSON.stringify({
    app_version: app_version,
    website_url: website_url,
    client: client,
    // app_name: appName,
  });
  const encodedString = b64EncodeUnicode(headerData);

  var config = {
    method: "get",
    url: `${apiUrl.userServicesBaseUrl}`,
    headers: { data: encodedString },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        resolve(error.response);
      });
  });
}
