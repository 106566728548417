import React from "react";
import { TripListDrawerStyle } from "./tripListDrawerDetailStyle.js";
import TripListDrawerCard from "./TripListDrawerCard.jsx";
import { Timeline } from "antd";
import { colorPalette } from "../../themes/theme";

const TripListDrawerDetails = ({
  tripDetail,
  tripCostTotal,
  tripAdvanceCost,
}) => {
  const tripDetails = [
    { title: "Trip Code", description: tripDetail.trip_code },
    {
      title: "Facilitated By",
      description: tripDetail.trip_additional_data.facilitated_by,
    },
    { title: "Consignor", description: tripDetail.consignor },
    { title: "Consignee", description: tripDetail.consignee },
    { title: "Bill To", description: tripDetail.bill_to_party_details.name },
    {
      title: "Operations SPOC Email",
      description: tripDetail.trip_additional_data.operations_spoc,
    },
    { title: "LR Number", description: tripDetail.reference_number[0] },
    {
      title: "Trip Start Date",
      description: tripDetail.trip_start_date,
    },
  ];

  const invoiceDetail = [
    {
      title: "Invoice Number",
      description: tripDetail.zoho_customer_invoice_number,
    },
    {
      title: "Invoice Status",
      description: tripDetail.zoho_customer_invoice_status
        ?.split("_")
        .join(" ")
        .toUpperCase(),
    },
    {
      title: "Invoice Generated Date",
      description: tripDetail.trip_additional_data?.date_of_invoice_generated,
    },
    {
      title: "Invoice Sent Date",
      description: tripDetail.trip_additional_data.date_of_invoice_sent,
    },
    {
      title: "Invoice Submitted Date",
      description: tripDetail.trip_additional_data.date_of_invoice_submitted,
    },
    {
      title: "Invoice Closed Date",
      description: tripDetail.trip_additional_data.date_of_invoice_closed,
    },
  ];

  const deliveryDetail = [
    {
      title: "Expected Delivery Date",
      description: tripDetail.expected_delivery_date,
    },
    {
      title: "Customer Invoice No.",
      description: tripDetail.customer_invoice_number?.toUpperCase(),
    },
    { title: "Number of Pieces", description: tripDetail.number_of_pieces },
    {
      title: "Source",
      description: tripDetail.load_source.split("_").join(" "),
    },
    { title: "Invoice Value", description: tripDetail.value_of_goods },
    { title: "Estimated Distance", description: tripDetail.estimated_kms },
    { title: "Quantity in Tonnes", description: tripDetail.quantity_in_tonnes },
    {
      title: "Per Bag Cost",
      description:
        tripCostTotal && tripDetail.unloaded_quantity != 0
          ? (tripCostTotal / tripDetail.unloaded_quantity).toFixed(2)
          : tripCostTotal && tripDetail.quantity
          ? (tripCostTotal / tripDetail.quantity).toFixed(2)
          : " - ",
    },
    {
      title: "Payment Terms",
      description: tripDetail.payment_terms?.split("_").join(" ").toUpperCase(),
    },
  ];

  const transporterDetails = [
    { title: "Transporter Name", description: tripDetail.transporter_name },
    { title: "Transporter Code", description: tripDetail.transporter_code },
    { title: "Transporter Cost", description: tripCostTotal },
    { title: "Transporter Advance", description: tripAdvanceCost },
    {
      title: "Transporter Invoice Number",
      description: tripDetail.trip_additional_data.transporter_invoice_number,
    },
  ];

  const customerDetails = [
    {
      title: "Customer Billed Amt",
      description: tripDetail.trip_customer_invoice[0]?.customer_billed_amount,
    },
    {
      title: "Customer Advance",
      description:
        tripDetail.trip_customer_invoice[0]?.advance_paid_by_customer,
    },
  ];

  const vehicleDetails = [
    {
      title: "Vehicle Number",
      description: tripDetail.vehicle_number,
    },
    {
      title: "Start Odometer Reading",
      description: tripDetail.trip_start_meter_reading,
    },
    {
      title: "Complete Odometer Reading",
      description: tripDetail.trip_complete_meter_reading,
    },
    {
      title: "Per KM Rate",
      description: tripDetail.per_km_rate,
    },
  ];

  console.log(tripDetail, "tripDetailssdfs");

  return (
    <TripListDrawerStyle>
      <TripListDrawerCard heading={"TRIP DETAILS"} data={tripDetails} />
      <TripListDrawerCard heading={"INVOICE"} data={invoiceDetail} />
      <TripListDrawerCard heading={"DELIVERY"} data={deliveryDetail} />
      <TripListDrawerCard heading={"TRANSPORTER"} data={transporterDetails} />
      <TripListDrawerCard heading={"REVENUE"} data={customerDetails} />
      <TripListDrawerCard heading={"VEHICLE"} data={vehicleDetails} />
      <div
        style={{
          width: "100%",
          height: "3px",
          backgroundColor: "#4e4e4e",
          margin: "10px 0",
        }}
      ></div>
      <div style={{ padding: "15px" }}>
        <Timeline>
          {tripDetail.source.map((source) => {
            return (
              <Timeline.Item color={colorPalette.primaryCodeColor}>
                <span style={{ fontWeight: "600" }}>From:</span>{" "}
                {source.village === source.district
                  ? source.village
                  : `${source.village}, ${source.district}`}{" "}
                , {source.state}, {source.postcode}
              </Timeline.Item>
            );
          })}
          {tripDetail.destination.map((destinations) => {
            return (
              <Timeline.Item color={colorPalette.primaryCodeColor}>
                <span style={{ fontWeight: "600" }}>To: </span>{" "}
                {destinations.village === destinations.district
                  ? destinations.district
                  : `${destinations.village}, ${destinations.district}`}
                , {destinations.state}, {destinations.postcode}
              </Timeline.Item>
            );
          })}
        </Timeline>
      </div>
    </TripListDrawerStyle>
  );
};

export default TripListDrawerDetails;
