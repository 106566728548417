import styled from "styled-components";
import { colorPalette, colorPaletteDark } from "../../themes/theme";

export const DataMap_header = styled.div`
  /* .datamap-header { */
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.primaryColor : colorPalette.white};

  /* } */
  .back_button_map {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
  .mode_icons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.white};
  }
  .mode_text {
    margin-right: 10px;
  }
  .datamap-navbar {
    cursor: pointer;
    width: 100%;
    height: 60px;
    padding: 20px 60px;
    display: flex;
    gap: 10px;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.primaryColor};
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    color: white;
    overflow: hidden;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .map-section-main {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: auto 500px;
  }

  .map-section {
    width: 100%;
    height: 100vh;
  }

  .map-section svg {
    margin-left: 40px;
    margin-top: 40px;
    /* width: 100%; */
    height: 93%;
  }

  .details-section {
    /* background-color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.white}; */
    width: 100%;
    height: 100%;
    padding: 100px 60px 20px 20px;
  }

  .date-picker {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.white};
    /* width: 100%; */
    display: flex;
    gap: 80px;
    /* border: 1px solid green; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 90px;
  }
  /* .date-picker input{
  width: 150px;
} */

  .bar-chart {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.white};
    width: 100%;
    height: 350px;
  }

  @media only screen and (max-width: 600px) {
    .map-section-main {
      grid-template-columns: 100%;
    }

    .map-section {
      width: 100%;
      height: 100%;
      margin-bottom: 300px;
    }
  }
`;
