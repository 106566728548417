import { useContext, useEffect, useState } from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import {
  getEsclationMatrix,
  saveEscalation,
  editEscalation,
} from "./esclationService.jsx";
import { DarkModeContext } from "../../context/darkModeContext.js";
import { EscalationStyle } from "./EscalationStyle.jsx";
import MobileHeader from "../../components/MobileHeader/MobileHeader.jsx";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import { Table, Modal as ModalAntd } from "antd";
import Topbar from "../../components/topbar/Topbar.jsx";
import { colorPalette } from "../../themes/theme.js";
import { Alert, CircularProgress, Drawer, TextField } from "@mui/material";
import ReactSelect from "react-select";
import { Close, Edit } from "@mui/icons-material";
import { ButtonRectangle } from "../../themes/components/button.js";
import AddIcon from "@mui/icons-material/Add";
import { Create_trip_drawer_container } from "../ftl_trips/createTrip/create-tripv2.js";
import { getTeams } from "../teams/TeamsService.jsx";
import * as yup from "yup";
import SomethingWentWrong from "../../components/somethingWentWrong/SomethingWentWrong.jsx";

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

function EscalationMatrix() {
  const { darkMode } = useContext(DarkModeContext);
  const [loadingButton, setLoadingButton] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [tableData, setTableData] = useState([
    {
      target_trip_status: "",
      esclation_required: "",
      resolution_time_in_hours: "",
      responsible_person: "",
      show_target_status: "",
      level1_responsible_person: "",
      level2_responsible_person: "",
      level3_responsible_person: "",
    },
  ]);
  const [escalationData, setEscalationData] = useState({
    target_trip_status: "",
    remarks: "",
    // ===========person==================
    responsible_person: "",
    responsible_person_email: "",
    resolution_time_in_hours: "",
    // ===========level====================
    level1_responsible_person: "",
    level1_responsible_person_email: "",
    level1_resolution_time_in_hours: "",
    // ===========level2====================
    level2_responsible_person: "",
    level2_responsible_person_email: "",
    level2_resolution_time_in_hours: "",
    // ===========level3====================
    level3_responsible_person: "",
    level3_responsible_person_email: "",
    level3_resolution_time_in_hours: "",
  });
  const [teamSearchTerm, setTeamSearchTerm] = useState("");
  const [tableLoading, setTableLoading] = useState(true);
  const [escalationEdit, setEscalationEdit] = useState(false);
  const [errEscalationData, setErrEscalationData] = useState({});
  const columns = [
    {
      title: "Action",
      width: 80,
      dataIndex: "action",
      render: (text, rowItem) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <div onClick={() => editItem(rowItem)}>
              <Edit />
            </div>
          </div>
        );
      },
    },
    {
      title: "Trip status",
      width: 170,
      dataIndex: "show_target_status",
    },
    {
      title: "Responsible person",

      children: [
        {
          title: "Person",
          dataIndex: "responsible_person",
          key: "person",
        },
        {
          title: "Resolution in hrs",
          dataIndex: "resolution_time_in_hours",
          key: "street",
          width: 110,
          render: (text, rowItem) => {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>{text}</div>
              </div>
            );
          },
        },
      ],
    },

    {
      title: " Level 1",

      children: [
        {
          title: "Responsible person",
          dataIndex: "level1_responsible_person",
          key: "person",
          //
        },
        {
          title: "Resolution in hrs",
          dataIndex: "level1_resolution_time_in_hours",
          key: "street",
          width: 110,
          render: (text, rowItem) => {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>{text}</div>
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Level 2",
      children: [
        {
          title: "Responsible person ",
          dataIndex: "level2_responsible_person",
          key: "person",
          //
        },
        {
          title: "Resolution in hrs",
          dataIndex: "level2_resolution_time_in_hours",
          key: "level2_resolution_time_in_hours",
          width: 110,
          render: (text, rowItem) => {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>{text}</div>
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Level 3",
      children: [
        {
          title: "Responsible person",
          dataIndex: "level3_responsible_person",
          key: "level3_responsible_person",
          //
        },
        {
          title: "Resolution in hrs",
          dataIndex: "level3_resolution_time_in_hours",
          key: "level3_resolution_time_in_hours",
          width: 110,
          render: (text, rowItem) => {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>{text}</div>
              </div>
            );
          },
        },
      ],
    },
  ];
  const [state, setState] = useState({ right: false });
  const [teamsListArray, setTeamsListArray] = useState([]);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [tripStatusArray] = useState([
    "rejected",
    "reopen",
    "approved",
    "intransit",
    "arrived_at_destination",
    "goods_delivered",
    "documents_verified",
    "hardcopy_pod_received",
    "invoice_generated",
    "invoice_submitted",
    "invoice_closed",
    "requested_for_completion",
    "completed",
    "trip_disputed",
    "invoice_sent",
  ]);

  const schema = yup.object().shape({
    target_trip_status: yup.string().required(),
    // remarks: yup.string().required(),
    // ===========person==================
    responsible_person: yup.string().required(),
    responsible_person_email: "",
    resolution_time_in_hours: yup.number().min(1).max(168).required(),
    // ===========level====================
    level1_responsible_person: yup.string().required(),
    level1_responsible_person_email: "",
    level1_resolution_time_in_hours: yup.number().min(1).max(168).required(),
    // ===========level2====================
    level2_responsible_person: yup.string().required(),
    level2_responsible_person_email: "",
    level2_resolution_time_in_hours: yup.number().min(1).max(168).required(),
    // ===========level3====================
    level3_responsible_person: yup.string().required(),
    level3_responsible_person_email: "",
    level3_resolution_time_in_hours: yup.number().min(1).max(168).required(),
  });

  const formData = {
    target_trip_status: escalationData.target_trip_status,
    remarks: "",
    // ===========person==================
    responsible_person: escalationData.responsible_person,
    responsible_person_email: escalationData.responsible_person_email,
    resolution_time_in_hours: escalationData.resolution_time_in_hours,
    // ===========level====================
    level1_responsible_person: escalationData.level1_responsible_person,
    level1_responsible_person_email:
      escalationData.level1_responsible_person_email,
    level1_resolution_time_in_hours:
      escalationData.level1_resolution_time_in_hours,
    // ===========level2====================
    level2_responsible_person: escalationData.level2_responsible_person,
    level2_responsible_person_email:
      escalationData.level2_responsible_person_email,
    level2_resolution_time_in_hours:
      escalationData.level2_resolution_time_in_hours,
    // ===========level3====================
    level3_responsible_person: escalationData.level3_responsible_person,
    level3_responsible_person_email:
      escalationData.level3_responsible_person_email,
    level3_resolution_time_in_hours:
      escalationData.level3_resolution_time_in_hours,
  };

  useEffect(() => {
    callEsclationListApi();
  }, []);

  function capitalizeLetter(str) {
    let i,
      splittedArray = str?.split("_");
    for (i = 0; i < splittedArray?.length; i++) {
      splittedArray[i] =
        splittedArray[i].charAt(0).toUpperCase() + splittedArray[i].slice(1);
    }
    return splittedArray?.join(" ");
  }

  const getTeamsData = async (searchTerm, isActive, currentPage) => {
    let responseFromTeamsApi = await getTeams(
      searchTerm,
      isActive,
      currentPage
    );
    if (responseFromTeamsApi.status === 200) {
      let filterArray = [];
      if (
        responseFromTeamsApi.data.teams_list &&
        responseFromTeamsApi.data.teams_list.length > 0
      ) {
        filterArray = responseFromTeamsApi.data.teams_list.filter(
          (item) => item.name !== ""
        );
      }
      setTeamsListArray(filterArray);
    }
  };

  useEffect(() => {
    if (teamSearchTerm.length > 2 || teamSearchTerm === "") {
      getTeamsData(teamSearchTerm, true, 1);
    }
  }, [teamSearchTerm]);

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const clearState = () => {
    setApiErrorMsg("");
    setEscalationData({
      target_trip_status: "",
      remarks: "",
      // ===========person==================
      responsible_person: "",
      responsible_person_email: "",
      resolution_time_in_hours: "",
      // ===========level====================
      level1_responsible_person: "",
      level1_responsible_person_email: "",
      level1_resolution_time_in_hours: "",
      // ===========level2====================
      level2_responsible_person: "",
      level2_responsible_person_email: "",
      level2_resolution_time_in_hours: "",
      // ===========level3====================
      level3_responsible_person: "",
      level3_responsible_person_email: "",
      level3_resolution_time_in_hours: "",
    });
    setErrEscalationData({});
  };

  const editItem = (item) => {
    setEscalationEdit(true);
    setSelectedId(item.id);
    setEscalationData({
      target_trip_status: item.target_trip_status,
      remarks: item.remarks,
      // ===========person==================
      responsible_person: item.responsible_person,
      responsible_person_email: item.responsible_person,
      resolution_time_in_hours: item.resolution_time_in_hours,
      // ===========level====================
      level1_responsible_person: item.level1_responsible_person,
      level1_responsible_person_email: item.level1_responsible_person,
      level1_resolution_time_in_hours: item.level1_resolution_time_in_hours,
      // ===========level2====================
      level2_responsible_person: item.level2_responsible_person,
      level2_responsible_person_email: item.level2_responsible_person,
      level2_resolution_time_in_hours: item.level2_resolution_time_in_hours,
      // ===========level3====================
      level3_responsible_person: item.level3_responsible_person,
      level3_responsible_person_email: item.level3_responsible_person,
      level3_resolution_time_in_hours: item.level3_resolution_time_in_hours,
    });
    toggleDrawer("right", true);
  };

  const callEsclationListApi = async () => {
    try {
      setTableLoading(true);
      let responseFromListApi = await getEsclationMatrix();
      if (responseFromListApi.status === 200) {
        setTableLoading(false);
        if (responseFromListApi.data.escalation_matrix_list.length > 0) {
          let filterArray = responseFromListApi.data.escalation_matrix_list.map(
            (item) => {
              return {
                ...item,
                show_target_status: capitalizeLetter(item.target_trip_status),
              };
            }
          );
          setTableData(filterArray);
        } else {
          setTableLoading(false);
          setTableData(responseFromListApi.data.escalation_matrix_list);
        }
      }
    } catch (error) {
    } finally {
      setTableLoading(false);
    }
  };

  function handleCloseDrawer() {
    toggleDrawer("right", false);
  }

  console.log(escalationData);

  const handleBtnSave = async () => {
    let formValid = await schema.isValid(formData);
    console.log(formValid);
    console.log("awat form data=========>", formValid);
    if (formValid) {
      if (escalationEdit) {
        await handleEditSave();
      } else {
        await callApiSave();
      }
    } else {
      let error = {};
      if (!escalationData.target_trip_status) {
        error.errStatus = "Please select trip status";
      }
      if (!escalationData.responsible_person) {
        error.responsible_person = "Please enter responsible person";
      }
      if (escalationData.resolution_time_in_hours == "") {
        error.resolution_time_in_hours = "Please enter resolution hrs";
      } else if (escalationData.resolution_time_in_hours == 0) {
        error.resolution_time_in_hours = "Please enter greater then 0";
      }
      if (escalationData.resolution_time_in_hours > 169) {
        error.resolution_time_in_hours = "Please enter less then one weak";
      }
      if (!escalationData.level1_responsible_person) {
        error.errlevelOne = "Please select level 1 responsible person";
      }
      if (escalationData.level1_resolution_time_in_hours == "") {
        error.level1_resolution_time_in_hours = "Please enter resolution hrs";
      } else if (escalationData.level1_resolution_time_in_hours == 0) {
        error.level1_resolution_time_in_hours = "Please enter greater then 0";
      }
      if (escalationData.level1_resolution_time_in_hours > 169) {
        error.level1_resolution_time_in_hours =
          "Please enter less then one weak";
      }
      if (!escalationData.level2_responsible_person) {
        error.errlevelTwo = "Please select level 2 responsible person";
      }
      if (escalationData.level2_resolution_time_in_hours == "") {
        error.level2_resolution_time_in_hours = "Please enter resolution hrs";
      } else if (escalationData.level2_resolution_time_in_hours == 0) {
        error.level2_resolution_time_in_hours = "Please enter greater then 0";
      }
      if (escalationData.level2_resolution_time_in_hours > 169) {
        error.level2_resolution_time_in_hours =
          "Please enter less then one weak";
      }
      if (!escalationData.level3_responsible_person) {
        error.errlevelThree = "Please select level 3 responsible person";
      }
      if (escalationData.level3_resolution_time_in_hours == "") {
        error.level3_resolution_time_in_hours = "Please enter resolution hrs";
      } else if (escalationData.level3_resolution_time_in_hours == 0) {
        error.level3_resolution_time_in_hours = "Please enter greater then 0";
      }
      if (escalationData.level3_resolution_time_in_hours > 169) {
        error.level3_resolution_time_in_hours =
          "Please enter less then one weak";
      }
      console.log("error======outside=======>", error);
      setErrEscalationData(error);
    }
  };

  const handleEditSave = async () => {
    if (selectedId > 0) {
      setLoadingButton(true);
      let responseFromEditEscalation = await editEscalation(
        escalationData,
        selectedId
      );
      console.log(
        "responseFromEditEscalation==============>",
        responseFromEditEscalation
      );
      if (
        responseFromEditEscalation.status === 200 ||
        responseFromEditEscalation.status === 201
      ) {
        toggleDrawer("right", false);
        setLoadingButton(false);
        callEsclationListApi();
      } else if (responseFromEditEscalation.status === 400) {
        setLoadingButton(false);
        setApiErrorMsg(responseFromEditEscalation.data.detail);
      } else if (responseFromEditEscalation.status === 401) {
        setLoadingButton(false);
        setApiErrorMsg(`401: Not enough permission`);
      } else {
        setLoadingButton(false);
        setApiErrorMsg(`ERROR : ${responseFromEditEscalation.status}`);
      }
    }
    setLoadingButton(false);
  };

  const callApiSave = async () => {
    setLoadingButton(true);
    let responseFromCreateEscalation = await saveEscalation(escalationData);
    if (
      responseFromCreateEscalation.status === 200 ||
      responseFromCreateEscalation.status === 201
    ) {
      toggleDrawer("right", false);
      setLoadingButton(false);
      callEsclationListApi();
    } else if (responseFromCreateEscalation.status === 400) {
      setLoadingButton(false);
      setApiErrorMsg(responseFromCreateEscalation.data.detail);
    } else if (responseFromCreateEscalation.status === 401) {
      setLoadingButton(false);
      setApiErrorMsg(`401: Not enough permission`);
    } else {
      setLoadingButton(false);
      setApiErrorMsg(`ERROR : ${responseFromCreateEscalation.status}`);
    }
    setLoadingButton(false);
  };

  function onChangeTripStatus(data) {
    setEscalationData({
      ...escalationData,
      target_trip_status: data.value.toLowerCase(),
    });
    setErrEscalationData({
      ...errEscalationData,
      errStatus: "",
    });
  }

  const onChangeTime = (e) => {
    const { name, value } = e.target;
    setEscalationData({
      ...escalationData,
      [name]: value,
    });
    if (value) {
      if (value < 169) {
        setErrEscalationData({
          ...errEscalationData,
          [name]: "",
        });
      } else {
        setErrEscalationData({
          ...errEscalationData,
          [name]: "Please enter less then one weak",
        });
      }
    } else {
      setErrEscalationData({
        ...errEscalationData,
        [name]: "",
      });
    }
  };

  const onTeamChange = (data, e) => {
    const { name } = e;
    const tempObj = {};

    if (name === "responsible_person") {
      tempObj[name] = data.name;
      tempObj["responsible_person_email"] = data.value;
      setErrEscalationData({ ...errEscalationData, responsible_person: "" });
    }
    if (name === "level1_responsible_person") {
      tempObj[name] = data.name;
      tempObj["level1_responsible_person_email"] = data.value;
      setErrEscalationData({ ...errEscalationData, errlevelOne: "" });
    }
    if (name === "level2_responsible_person") {
      tempObj[name] = data.name;
      tempObj["level2_responsible_person_email"] = data.value;
      setErrEscalationData({ ...errEscalationData, errlevelTwo: "" });
    }
    if (name === "level3_responsible_person") {
      tempObj[name] = data.name;
      tempObj["level3_responsible_person_email"] = data.value;
      setErrEscalationData({ ...errEscalationData, errlevelThree: "" });
    }

    setEscalationData({
      ...escalationData,
      ...tempObj,
    });
  };

  const renderFormDrawer = () => {
    return (
      <div>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={() => {
            clearState();
            toggleDrawer("right", false);
          }}
        >
          <Create_trip_drawer_container
            style={isMobile ? { width: "90vw" } : {}}
            className="create-trip-drawer-container"
          >
            <div
              className="create-trip-right-most-head"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h4>Escalation matrix:</h4>{" "}
              <Close
                onClick={handleCloseDrawer}
                style={{ marginRight: "20px", cursor: "pointer" }}
              />
            </div>
            <div className="create-trip-from-body">
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Trip status <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <ReactSelect
                    value={
                      escalationData.target_trip_status
                        ? {
                            label: capitalizeLetter(
                              escalationData.target_trip_status
                                ?.split("_")
                                .join(" ")
                            ),
                            value: escalationData.target_trip_status,
                          }
                        : ""
                    }
                    options={tripStatusArray.map((status) => ({
                      value: status,
                      label: capitalizeLetter(status),
                    }))}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "80%",
                      }),
                    }}
                    s
                    placeholder="Select trip status"
                    onChange={(data) => onChangeTripStatus(data)}
                    isDisabled={escalationEdit}
                  ></ReactSelect>
                  <div className="create-trip-error">
                    {errEscalationData.errStatus
                      ? errEscalationData.errStatus
                      : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    Responsible person <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <ReactSelect
                    name="responsible_person"
                    value={
                      escalationData.responsible_person
                        ? {
                            label: escalationData.responsible_person,
                            value: escalationData.responsible_person_email,
                          }
                        : ""
                    }
                    options={teamsListArray.map((team) => ({
                      value: team.email,
                      name: team.name,
                      email: team.email,
                      mobile_number: team.mobile_number,
                      label: (
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "grid",
                              width: "100%",
                              alignItems: "center",
                              gridTemplateColumns: "60px auto",
                            }}
                          >
                            <div
                              style={{ fontWeight: "500", color: "#454545" }}
                            >
                              Name:
                            </div>
                            <div>{team.name}</div>
                          </div>
                          <div
                            style={{
                              display: "grid",
                              width: "100%",
                              alignItems: "center",
                              gridTemplateColumns: "60px auto",
                            }}
                          >
                            <div
                              style={{ fontWeight: "500", color: "#454545" }}
                            >
                              Phone:
                            </div>
                            <div>{team.mobile_number}</div>
                          </div>
                          <div
                            style={{
                              display: "grid",
                              width: "100%",
                              alignItems: "center",
                              gridTemplateColumns: "60px auto",
                            }}
                          >
                            <div
                              style={{ fontWeight: "500", color: "#454545" }}
                            >
                              Email:
                            </div>
                            <div>{team.email}</div>
                          </div>
                        </div>
                      ),
                    }))}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "80%",
                      }),
                    }}
                    onInputChange={(event) => {
                      setTeamSearchTerm(event);
                    }}
                    placeholder="Please select responsible person"
                    onChange={(data, e) => onTeamChange(data, e)}
                  ></ReactSelect>
                  <div className="create-trip-error">
                    {errEscalationData.responsible_person
                      ? errEscalationData.responsible_person
                      : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    Resolution in hrs <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    name="resolution_time_in_hours"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder="Enter the time in hrs"
                    value={escalationData.resolution_time_in_hours}
                    onChange={(e) => {
                      onChangeTime(e);
                    }}
                  />
                  <div className="create-trip-error">
                    {errEscalationData.resolution_time_in_hours
                      ? errEscalationData.resolution_time_in_hours
                      : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    Level 1 <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <ReactSelect
                    name="level1_responsible_person"
                    value={
                      escalationData.level1_responsible_person
                        ? {
                            label: escalationData.level1_responsible_person,
                            value:
                              escalationData.level1_responsible_person_email,
                          }
                        : ""
                    }
                    options={teamsListArray.map((team) => ({
                      value: team.email,
                      name: team.name,
                      email: team.email,
                      mobile_number: team.mobile_number,
                      label: (
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "grid",
                              width: "100%",
                              alignItems: "center",
                              gridTemplateColumns: "60px auto",
                            }}
                          >
                            <div
                              style={{ fontWeight: "500", color: "#454545" }}
                            >
                              Name:
                            </div>
                            <div>{team.name}</div>
                          </div>
                          <div
                            style={{
                              display: "grid",
                              width: "100%",
                              alignItems: "center",
                              gridTemplateColumns: "60px auto",
                            }}
                          >
                            <div
                              style={{ fontWeight: "500", color: "#454545" }}
                            >
                              Phone:
                            </div>
                            <div>{team.mobile_number}</div>
                          </div>
                          <div
                            style={{
                              display: "grid",
                              width: "100%",
                              alignItems: "center",
                              gridTemplateColumns: "60px auto",
                            }}
                          >
                            <div
                              style={{ fontWeight: "500", color: "#454545" }}
                            >
                              Email:
                            </div>
                            <div>{team.email}</div>
                          </div>
                        </div>
                      ),
                    }))}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "80%",
                      }),
                    }}
                    onInputChange={(event) => {
                      setTeamSearchTerm(event);
                    }}
                    placeholder="Please select responsible person 1"
                    onChange={(data, e) => onTeamChange(data, e)}
                  ></ReactSelect>

                  <div className="create-trip-error">
                    {errEscalationData.errlevelOne
                      ? errEscalationData.errlevelOne
                      : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    Level 1 resolution in hrs{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    type="number"
                    name="level1_resolution_time_in_hours"
                    onWheel={(e) => e.target.blur()}
                    placeholder="Enter the time in hrs"
                    value={escalationData.level1_resolution_time_in_hours}
                    onChange={(e) => {
                      onChangeTime(e);
                    }}
                  />
                  <div className="create-trip-error">
                    {errEscalationData.level1_resolution_time_in_hours
                      ? errEscalationData.level1_resolution_time_in_hours
                      : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    Level 2 <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <ReactSelect
                    name="level2_responsible_person"
                    value={
                      escalationData.level2_responsible_person
                        ? {
                            label: escalationData.level2_responsible_person,
                            value:
                              escalationData.level2_responsible_person_email,
                          }
                        : ""
                    }
                    options={teamsListArray.map((team) => ({
                      value: team.email,
                      name: team.name,
                      email: team.email,
                      mobile_number: team.mobile_number,
                      label: (
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "grid",
                              width: "100%",
                              alignItems: "center",
                              gridTemplateColumns: "60px auto",
                            }}
                          >
                            <div
                              style={{ fontWeight: "500", color: "#454545" }}
                            >
                              Name:
                            </div>
                            <div>{team.name}</div>
                          </div>
                          <div
                            style={{
                              display: "grid",
                              width: "100%",
                              alignItems: "center",
                              gridTemplateColumns: "60px auto",
                            }}
                          >
                            <div
                              style={{ fontWeight: "500", color: "#454545" }}
                            >
                              Phone:
                            </div>
                            <div>{team.mobile_number}</div>
                          </div>
                          <div
                            style={{
                              display: "grid",
                              width: "100%",
                              alignItems: "center",
                              gridTemplateColumns: "60px auto",
                            }}
                          >
                            <div
                              style={{ fontWeight: "500", color: "#454545" }}
                            >
                              Email:
                            </div>
                            <div>{team.email}</div>
                          </div>
                        </div>
                      ),
                    }))}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "80%",
                      }),
                    }}
                    onInputChange={(event) => {
                      setTeamSearchTerm(event);
                    }}
                    placeholder="Please select responsible person 2"
                    onChange={(data, e) => onTeamChange(data, e)}
                  ></ReactSelect>
                  <div className="create-trip-error">
                    {errEscalationData.errlevelTwo
                      ? errEscalationData.errlevelTwo
                      : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    Level 2 resolution in hrs{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    type="number"
                    name="level2_resolution_time_in_hours"
                    onWheel={(e) => e.target.blur()}
                    placeholder="Enter the time in hrs"
                    value={escalationData.level2_resolution_time_in_hours}
                    onChange={(e) => {
                      onChangeTime(e);
                    }}
                  />
                  <div className="create-trip-error">
                    {errEscalationData.level2_resolution_time_in_hours
                      ? errEscalationData.level2_resolution_time_in_hours
                      : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    Level 3 <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <ReactSelect
                    name="level3_responsible_person"
                    value={
                      escalationData.level3_responsible_person
                        ? {
                            label: escalationData.level3_responsible_person,
                            value:
                              escalationData.level3_responsible_person_email,
                          }
                        : ""
                    }
                    options={teamsListArray.map((team) => ({
                      value: team.email,
                      name: team.name,
                      email: team.email,
                      mobile_number: team.mobile_number,
                      label: (
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "grid",
                              width: "100%",
                              alignItems: "center",
                              gridTemplateColumns: "60px auto",
                            }}
                          >
                            <div
                              style={{ fontWeight: "500", color: "#454545" }}
                            >
                              Name:
                            </div>
                            <div>{team.name}</div>
                          </div>
                          <div
                            style={{
                              display: "grid",
                              width: "100%",
                              alignItems: "center",
                              gridTemplateColumns: "60px auto",
                            }}
                          >
                            <div
                              style={{ fontWeight: "500", color: "#454545" }}
                            >
                              Phone:
                            </div>
                            <div>{team.mobile_number}</div>
                          </div>
                          <div
                            style={{
                              display: "grid",
                              width: "100%",
                              alignItems: "center",
                              gridTemplateColumns: "60px auto",
                            }}
                          >
                            <div
                              style={{ fontWeight: "500", color: "#454545" }}
                            >
                              Email:
                            </div>
                            <div>{team.email}</div>
                          </div>
                        </div>
                      ),
                    }))}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: "80%",
                      }),
                    }}
                    onInputChange={(event) => {
                      setTeamSearchTerm(event);
                    }}
                    placeholder="Please select responsible person 2"
                    onChange={(data, e) => onTeamChange(data, e)}
                  ></ReactSelect>
                  <div className="create-trip-error">
                    {errEscalationData.errlevelThree
                      ? errEscalationData.errlevelThree
                      : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    Level 3 resolution in hrs{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    type="number"
                    name="level3_resolution_time_in_hours"
                    onWheel={(e) => e.target.blur()}
                    placeholder="Enter the time in hrs"
                    value={escalationData.level3_resolution_time_in_hours}
                    onChange={(e) => {
                      onChangeTime(e);
                    }}
                  />
                  <div className="create-trip-error">
                    {errEscalationData.level3_resolution_time_in_hours
                      ? errEscalationData.level3_resolution_time_in_hours
                      : null}
                  </div>
                </div>
              </div>
              <div>
                {apiErrorMsg ? (
                  <Alert severity="error">{apiErrorMsg}</Alert>
                ) : null}
              </div>
              <div className="create-trip-input-container">
                <button
                  className="create-trip-save-btn"
                  onClick={() => handleBtnSave()}
                >
                  {loadingButton ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </Create_trip_drawer_container>
        </Drawer>
      </div>
    );
  };

  function handleAddConfigration() {
    clearState();
    setEscalationEdit(false);
    toggleDrawer("right", true);
  }

  return (
    <EscalationStyle mode={darkMode}>
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={99999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong
            backButtonLink="/escalation-matrix"
            buttonText="Close"
          />
        </div>
      </ModalAntd>

      {renderFormDrawer()}
      <div className="main_onboard_wrapper">
        {isMobile ? (
          <div className="trip-list-mobile-header">
            <MobileHeader headerTitle="Escalation-matrix" />
          </div>
        ) : (
          <div className="main_onboard_Left_box">
            <SideBarMain />
          </div>
        )}
        <div className="main_onboard_Right_box">
          {isMobile ? null : <Topbar />}
          <div style={{ maxWidth: "83vw", width: "96%", marginLeft: "2vw" }}>
            <div className="add_btn_div">
              <ButtonRectangle onClick={handleAddConfigration}>
                <AddIcon style={{ fontSize: isMobile ? "10px" : "20px" }} />
                <span style={{ fontWeight: "500" }}>Escalation</span>
              </ButtonRectangle>
            </div>
            <div className="table_main_div">
              {tableLoading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    // width: "100%",
                    // marginLeft: "50%",
                    width: "100vw",
                    height: "100vh",
                    backgroundColor: "rgb(0, 0, 0, 0.3)",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "9999",
                  }}
                >
                  <img
                    src={success}
                    alt=""
                    width="120px"
                    height="90px"
                    style={{ marginTop: "100px" }}
                  />
                  <h6 style={{ color: "grey" }}>loading data...</h6>
                </div>
              ) : (
                <Table
                  className="custom_table_header"
                  columns={columns}
                  dataSource={tableData}
                  scroll={{
                    x: "100%",
                    y: "65vh",
                  }}
                  bordered
                  pagination={false}
                  style={darkMode ? { background: colorPalette.red } : {}}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </EscalationStyle>
  );
}

export default EscalationMatrix;
