import { React, useContext, useEffect, useState } from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import { onboardRegisterPost } from "./OnboardServices";
import axios from "axios";
import Alert from "@mui/material/Alert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Component_style } from "../../themes/component_style";
import { useHistory } from "react-router-dom";
import apiUrl from "../../url/apiUrl";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { onboardData, updateCarrierId } from "../../redux/onboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { getCarrierListById, onboardRegisterPut } from "./OnboardServices";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Input, InputAdornment } from "@mui/material";
import UploadImage from "../../assets/upload.svg";
import leapSupplyLogo from "../../assets/logo/logo.svg";
import axiosInterceptor from "../../config/https";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";
import { fetchAppToken } from "../../helper/AppTokenHelper";
import CryptoJS from "crypto-js";
import { readLoginData, readToken } from "../../helper/dataDecryptionHelper";
import { colorPalette } from "../../themes/theme";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BankList } from "../../helper/BankList.js";
import { MyContext } from "../../components/enumContext/EnumContextComponent.jsx";
import { allRegex } from "../../utility/regex.js";
import {
  verifyAadhaar,
  verifyBank,
  verifyGST,
  verifyPan,
} from "../../helper/coreServices.js";
import { saveBlob } from "../../utility/saveBlobImage.js";
import { useDebounce } from "../../hooks/useDebounce.js";
import { BankListDetails } from "../../helper/BankListWithAccountLenght.js";
import Pincode from "../../components/countryBasePincode/Pincode.jsx";

function OnboardingPublic() {
  const dispatch = useDispatch();
  let history = useHistory();
  const [name, setName] = useState("");
  const [carrierType, setCarrierType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [pan, setPan] = useState("");
  const [transporterCode, setTransporterCode] = useState("");
  const [gstin, setGstin] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [bankAccNumber, setBankAccNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [selctedBankDetails, setSelctedBankDetails] = useState("0");
  console.log(selctedBankDetails, "slectedBankName");
  const [ifsc, setIfsc] = useState("");
  const [holderName, setHolderName] = useState("");
  const [accType, setAccType] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [village, setVillage] = useState("");
  const [errorpincodeNotAvailable, setErrorpincodeNotAvailable] = useState("");
  const [landmark, setLandmark] = useState("");
  const [villageList, setVillageList] = useState([]);
  const [bankAccTypeList, setBankAccTypeList] = useState([]);
  const [active, setActive] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [circularProgress, setCircularProgress] = useState(false);
  const [dataExistMsg, setDataExistMsg] = useState("");
  const [submitButtonEnable, setSubmitButtonEnable] = useState(false);
  const carrierId = useSelector((state) => state.onboard.carriedIdEdit);
  const [uniqueFileError, setUniqueFileError] = useState(false);
  const [termsMessage, setTermsMessage] = useState("");
  const [largeFileSizeError, setLargeFileSizeError] = useState(false);
  const { enums } = useContext(MyContext);
  const deboucedBankHolderName = useDebounce(holderName);
  const deboucedBankAccNumber = useDebounce(bankAccNumber);
  const deboucedName = useDebounce(name);

  // ++++++++++++===acc type+++++++++++++
  const getAccType = async () => {
    // var address = await axiosInterceptor.get(
    //   `${apiUrl.onboardingBaseUrl}/v1/enums/bank-account-type`
    // );
    // setBankAccTypeList(address.data);
    setBankAccTypeList(enums?.bank_account_type ? enums.bank_account_type : []);
  };
  useEffect(() => {
    getAccType();
  }, [enums]);
  //++++++++++++++++++++++++++

  const getCarrierDataByIdEdit = async (ids) => {
    setEditMode(true);
    const carrierResponseDataEdit = await getCarrierListById(ids);
    setName(carrierResponseDataEdit.name);
    setCarrierType(carrierResponseDataEdit.transporter_type);
    setPhoneNumber(carrierResponseDataEdit.mobile);
    setEmail(
      carrierResponseDataEdit.email == null ? "" : carrierResponseDataEdit.email
    );
    setPan(carrierResponseDataEdit.pan_number);
    setGstin(carrierResponseDataEdit.gstin_number);
    setAadhar(carrierResponseDataEdit.aadhar_number);
    setBankAccNumber(
      carrierResponseDataEdit.transporter_bank_details[0].bank_account_number
    );
    setHolderName(
      carrierResponseDataEdit.transporter_bank_details[0].account_holder_name
    );
    setBankName(carrierResponseDataEdit.transporter_bank_details[0].bank_name);
    setIfsc(carrierResponseDataEdit.transporter_bank_details[0].ifsc_code);
    setAccType(
      carrierResponseDataEdit.transporter_bank_details[0].bank_account_type
    );
    setLandmark(carrierResponseDataEdit.landmark);
    setActive(carrierResponseDataEdit.is_active);
    setPincode(carrierResponseDataEdit.transporter_address.postcode);
    setVillage(carrierResponseDataEdit.transporter_address.village);
    setLandmark(carrierResponseDataEdit.transporter_address.landmark);
    setTransporterCode(carrierResponseDataEdit.transporter_code);

    // setName(carrierResponseDataEdit.state);
    // setName(carrierResponseDataEdit.district);
    // setName(carrierResponseDataEdit.village);
    // setName(carrierResponseDataEdit.landmark);

    // setCarrierListDataById(carrierResponseData);
  };
  useEffect(() => {
    if (carrierId) {
      getCarrierDataByIdEdit(carrierId);
    }
    return () => {
      dispatch(updateCarrierId(""));
    };
  }, []);

  // token

  const isLoggedIn = readLoginData()?.IS_LOGGED_IN;

  // const website_url = "https://leapsupply.in";
  useEffect(() => {
    sessionStorage.setItem("website_url", process.env.REACT_APP_WEBSITE_URL);
  }, []);

  const fetchAppTokenData = async (url, client, version) => {
    const response = await fetchAppToken(url, client, version);

    if (response.status == 200) {
      const xToken = {
        X_TOKEN: response.data.token,
      };
      let encrypted_data = CryptoJS.AES.encrypt(
        JSON.stringify(xToken),
        // .SALT
        apiUrl.SALT
      ).toString();

      localStorage.setItem("x_token", encrypted_data);
    } else if (response == 401) {
      // window.location.reload();
    }
  };

  const website_url = sessionStorage.getItem("website_url");

  useEffect(() => {
    if (website_url) {
      fetchAppTokenData(website_url, "web", "1.0");
    }
  }, [website_url]);

  //+++++++++++++address from pincode+++++++++++++

  const getAddressFromPincode = async (pincode) => {
    try {
      var address = await axiosInterceptor.get(
        `${apiUrl.vilagePincodeUrl}/${prefixSalt()}-${convertToBase64(pincode)}`
      );

      setState(address.data.state.toLowerCase());
      setDistrict(address.data.district);
      setVillageList(address.data.area);
      console.log("tehdgdgdgd", address);
    } catch (e) {
      if (e.response.status == 400) {
        setErrorpincode("Pincode is not Serviceable.");
        // setErrorpincodeNotAvailable("Pincode is not Serviceable.");
      }
    }

    // setNewVillagesList(address.data.office[0].villages);
    // }
  };

  useEffect(() => {
    if (pincode.length == 6) {
      // getAddressFromPincode(pincode);
    }
  }, [pincode]);

  // +++++++++++++++++++++++++++++++++++++++

  // POST FOR ONBOARD=================
  const handleSubmit = async () => {
    setTermsMessage("");
    const isValid = formValidation();

    if (
      isValid &&
      signature?.type !== "application/pdf" &&
      panCard?.type !== "application/pdf" &&
      aadharBack?.type !== "application/pdf" &&
      aadharFront?.type !== "application/pdf" &&
      cancelCheque?.type !== "application/pdf"
    ) {
      setCircularProgress(true);
      if (editMode == true) {
        const responseEdit = await onboardRegisterPut(
          transporterCode,
          name,
          carrierType,
          phoneNumber,
          email,
          pan,
          carrierType == "registered_transporter" ? gstin : "",
          aadhar ? aadhar : "",
          bankAccNumber,
          bankName,
          ifsc,
          holderName,
          accType,
          pincode,
          state,
          district,
          village,
          landmark,
          aadharFront,
          aadharBack,
          panCard,
          signature,
          cancelCheque,
          moreThan10VehicleAttachment,
          gstRegistrationCertificate,
          nonGstRegistrationCertificate,
          msmeCertificate,
          carrierId,
          tdsFile
        );

        // dispatch(onboardData(response.data));
        if (responseEdit.status == 200) {
          history.push("/transporters");
          dispatch(updateCarrierId(""));
          toast.success("Transporter updated successfully");
        } else if (responseEdit.response.status == 400) {
          setDataExistMsg(responseEdit.response.data.detail);
          setCircularProgress(false);
        } else if (responseEdit.response.status == 401) {
          toast.error("401: Not enough permissions");
          setCircularProgress(false);
        }
      } else {
        const response = await onboardRegisterPost(
          name,
          carrierType,
          phoneNumber,
          email,
          pan,
          carrierType == "registered_transporter" ? gstin : "",
          aadhar,
          bankAccNumber,
          bankName,
          ifsc,
          holderName,
          accType,
          pincode,
          state,
          district,
          village,
          landmark,
          aadharFront,
          aadharBack,
          panCard,
          signature,
          cancelCheque,
          moreThan10VehicleAttachment,
          carrierType == "registered_transporter"
            ? gstRegistrationCertificate
            : "",
          carrierType == "registered_transporter"
            ? ""
            : nonGstRegistrationCertificate,
          msmeCertificate,
          tdsFile
        );

        if (response.status == 200) {
          if (isLoggedIn) {
            history.push(
              `/onboard-success?name=${response.data.name}&transporter_code=${response.data.transporter_code}`
            );
            dispatch(onboardData(response.data));
            setCircularProgress(false);
          } else if (isLoggedIn == undefined) {
            history.push(
              `/transporter-onboard-success?name=${response.data.name}&transporter_code=${response.data.transporter_code}`
            );
            sessionStorage.removeItem("website_url");
          }
        } else if (response.response.status == 422) {
          toast.error(response.response.data.detail[0].msg);
        } else if (response.response.status == 400) {
          setDataExistMsg(response.response.data.detail);
          setCircularProgress(false);
        } else if (response.response.status == 401) {
          toast.error("401: Not enough permissions");
          setCircularProgress(false);
        }
      }

      // setLoadingOnboard(true);

      // }
      setCircularProgress(false);
    }
    // if (submitButtonEnable) {
    //   setTermsMessage("");
  };
  const handleTerms = () => {
    setTermsMessage("Please accept Terms and Conditons to proceed further.");
  };

  //================================
  //++++++++++++=form validation++++++++++++++++++++++
  const [errorName, setErrorName] = useState({});
  const [carrierTypeError, setCarrierTypeError] = useState({});
  const [emailError, setEmailError] = useState({});
  const [mobileError, setMobileError] = useState({});
  const [errorPan, setErrorPan] = useState({});
  const [errorBankName, setErrorBankName] = useState({});
  const [errorgstin, setErrorgstin] = useState({});
  const [erroraadhar, setErroraadhar] = useState({});
  const [errorbankAccNumber, setErrorbankAccNumber] = useState({});
  const [errorifsc, setErrorifsc] = useState({});
  const [invalidIFSC, setInvalidIFSC] = useState("");
  const [errorholderName, setErrorholderName] = useState({});
  const [erroraccType, setErroraccType] = useState({});
  const [errorpincode, setErrorpincode] = useState("");
  const [errorstate, setErrorstate] = useState({});
  const [errorlandmark, setErrorlandmark] = useState({});
  const [errorvillage, setErrorVillage] = useState({});

  const [errorphoneNumber, setErrorphoneNumber] = useState({});
  const [aadharFront, setAadharFront] = useState({});
  const [aadharBack, setAadharBack] = useState({});
  const [tdsFile, setTdsFile] = useState({});

  const [panCard, setPanCard] = useState({});
  const [signature, setSignature] = useState({});
  const [cancelCheque, setCancelCheque] = useState({});
  const [moreThan10VehicleAttachment, setMoreThan10VehicleAttachment] =
    useState();
  const [gstRegistrationCertificate, setGstRegistrationCertificate] = useState(
    {}
  );
  const [nonGstRegistrationCertificate, setNonGstRegistrationCertificate] =
    useState({});
  const [msmeCertificate, setMsmeCertificate] = useState();
  const [errorAadharFront, setErrorAadharFront] = useState({});
  const [errorAadharBack, setErrorAadharBack] = useState({});
  const [errorTdsFile, setErrorTdsFile] = useState({});
  const [errorPanCard, setErrorPanCard] = useState({});
  const [errorSignature, setErrorSignature] = useState({});
  const [errorCancelCheque, setErrorCancelCheque] = useState({});
  const [errorGstRegistration, setErrorGstRegistration] = useState({});
  const [errorNonGstRegistration, setErrorNonGstRegistration] = useState({});
  const [isInputValueValid, setIsInputValueValid] = useState(false);
  useEffect(() => {
    // if (!editMode) {
    checkIfAttachmentsAreUnique();
    // }
  }, [
    panCard,
    signature,
    cancelCheque,
    gstRegistrationCertificate,
    nonGstRegistrationCertificate,
    aadharFront,
    aadharBack,
  ]);

  useEffect(() => {
    setAadharBack({});
    setAadharFront({});
    setNonGstRegistrationCertificate({});
    setGstRegistrationCertificate({});
    setAadhar("");
    setGstin("");
  }, [carrierType]);

  const checkIfAttachmentsAreUnique = () => {
    let flag = false;
    let arr = [
      panCard?.name,
      aadharFront?.name,
      aadharBack?.name,
      signature?.name,
      cancelCheque?.name,
      nonGstRegistrationCertificate?.name,
      gstRegistrationCertificate?.name,
    ];
    arr.map((ele, index) => {
      arr.map((e, i) => {
        if (index != i && ele && e) {
          if (ele === e) {
            if (index === 1 && i === 2) {
            } else if (index === 2 && i === 1) {
            } else {
              flag = true;
            }
          }
        }
      });
    });
    setUniqueFileError(flag);
  };

  const verifyAadhaarNumber = async (aadhaarNumber) => {
    const { response, error } = await verifyAadhaar(aadhaarNumber);
    setErroraadhar({});
    if (response) {
      !response.data.is_active
        ? setErroraadhar({
            ...erroraadhar,
            aadhaarError: "This aadhaar number is not acitve",
          })
        : setErroraadhar({});
    } else {
      setErroraadhar({
        ...erroraadhar,
        aadharError:
          error.data.detail ||
          "Something went wrong while fetching Aadhaar details",
      });
    }
  };

  const handleAadhaarInput = (e) => {
    if (e.target.value.length < 12) {
      setErroraadhar({
        ...erroraadhar,
        aadharError: "Aadhaar Number should be 12 digits",
      });
      setIsInputValueValid(false);
    } else {
      verifyAadhaarNumber(e.target.value);
      setErroraadhar({ ...erroraadhar, aadharError: "" });
      setIsInputValueValid(true);
    }

    setAadhar(e.target.value);
  };

  const verifyPanNumber = async (panNubmer) => {
    const { response, error } = await verifyPan(panNubmer);

    if (response) {
      !response.data.is_active || !response.data.status
        ? setErrorPan({
            ...errorPan,
            panerrorChar: "This PAN number is not acitve",
          })
        : setErrorPan({});

      response.data.full_name !== name
        ? setErrorName({
            ...errorName,
            panerrorChar: "Transporter name not matched with PAN details",
          })
        : setErrorName({});
    } else {
      setErrorPan({
        ...errorPan,
        panerrorChar:
          error.data.detail ||
          "Something went wrong while fetching PAN details",
      });
    }
  };

  // useEffect(() => {
  //   if (allRegex.panRegex.test(pan) && deboucedName) {
  //     verifyPanNumber(pan);
  //   }
  // }, [pan, deboucedName]);

  const handlePanChange = async (e) => {
    setPan(e.target.value);

    if (allRegex.panRegex.test(e.target.value)) {
      setIsInputValueValid(true);
      setErrorPan({});
      // verifyPanNumber(e.target.value);
    } else {
      setErrorPan({
        ...errorPan,
        panerrorChar: "Please enter valid PAN Number (ABCDE1234F)",
      });

      setIsInputValueValid(false);
    }
  };

  useEffect(async () => {
    const minAccLength = selctedBankDetails?.minBankAccountLength || 0;
    const maxAccLength = selctedBankDetails?.maxBankAccountLength || 20;
    if (
      bankName &&
      allRegex.ifscRegex.test(ifsc) &&
      deboucedBankAccNumber.length >= minAccLength &&
      deboucedBankAccNumber.length <= maxAccLength &&
      accType &&
      deboucedBankHolderName
    ) {
      console.log("debounced value");
      const { response, error } = await verifyBank(
        deboucedBankHolderName,
        bankName,
        ifsc,
        deboucedBankAccNumber
      );

      if (error) {
        setErrorBankName({
          ...errorBankName,
          nameError: Array.isArray(error.data.detail)
            ? error.data.detail[0].msg
            : error.data.detail,
        });
      } else {
        setErrorBankName({});
      }

      if (response.data && holderName) {
        const tempHolderName = holderName.toLowerCase().replace(/\s/g, "");
        const tempResponse = response.data.response.full_name
          .toLowerCase()
          .replace(/\s/g, "");
        if (tempHolderName.includes(tempResponse)) {
          setErrorholderName({});
        } else if (tempResponse.includes(tempHolderName)) {
          setErrorholderName({});
        } else if (response.data.error) {
          setErrorholderName({
            ...errorholderName,
            holderNameError: response.data.error,
          });
        } else {
          setErrorholderName({
            ...errorholderName,
            holderNameError: "Name not matched with bank details",
          });
        }
      } else {
        setErrorholderName({});
      }

      console.log(error);
    }
  }, [deboucedBankHolderName, ifsc, deboucedBankAccNumber, accType, bankName]);

  useEffect(async () => {
    if (gstin.length === 15) {
      const { response, error } = await verifyGST(gstin);

      if (error) {
        return setErrorgstin({
          ...errorgstin,
          gstinError: Array.isArray(error.data.detail)
            ? error.data.detail[0].msg
            : error.data.detail || "Something went wrong",
        });
      } else {
        setErrorgstin({});
      }
      console.log(response);
      if (response.data.gstin_status.toLowerCase() !== "active") {
        setErrorgstin({
          ...errorgstin,
          gstinError: "GST number is not active",
        });
      } else if (
        response.data.business_name.toLowerCase() !== deboucedName.toLowerCase()
      ) {
        setErrorName({
          ...errorName,
          nameError: "Transporter name not matched with GST details",
        });
      } else {
        setErrorgstin({});
      }
    }
  }, [gstin, deboucedName]);

  useEffect(() => {
    if (
      pan &&
      gstin &&
      pan !== gstin.substring(2, 12) &&
      carrierType == "registered_transporter"
    ) {
      setErrorPan({
        ...errorPan,
        panError: "PAN number not matched with GSTIN number",
      });
    } else {
      setErrorPan({});
    }
  }, [pan, gstin]);

  const formValidation = () => {
    const errorName = {};
    const carrierTypeError = {};
    const emailError = {};
    const errorPan = {};
    const errorBankName = {};
    const erroraadhar = {};
    const errorgstin = {};
    const errorifsc = {};
    const errorholderName = {};
    const errorbankAccNumber = {};
    const erroraccType = {};
    const errorstate = {};
    const errordistrict = {};
    const errorvillage = {};
    const errorphoneNumber = {};
    const errorlandmark = {};
    const errorAadharFront = {};
    let isValid = true;

    // if (name.trim().length < 5) {
    //   errorName.shortName = "first name too short";
    //   isValid = false;
    // }
    const nameRegex = /^[a-zA-Z0-9) / (.-]+$/g;
    const validName = nameRegex.test(name);
    if (name == "") {
      errorName.emptyName = "Name is mandatory";
      isValid = false;
    } else if (name.length <= 3) {
      errorName.emptyLength = "Name should be min. 3 characters long.";
    } else if (!validName) {
      errorName.invalidName = `Only alphabets, numbers and special characters . , - / ( ) are allowed`;
      isValid = false;
    }
    if (carrierType == "") {
      carrierTypeError.emptyType = "please select transporter type";
      isValid = false;
    }
    if (!pincode) {
      setErrorpincode("Please enter pincode");
      isValid = false;
    }

    // if (email == "") {
    //   emailError.missingemail = "email is empty.";
    //   isValid = false;
    // }

    if (email) {
      const regexpEmail =
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
      if (!regexpEmail.test(email)) {
        emailError.missingChar = "Invalid Email";
        isValid = false;
      }
      // else {
      //   isValid = true;
      // }
    }
    const panRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
    if (!pan) {
      errorPan.panerrorChar = "Please enter PAN";
      isValid = false;
    }
    if (pan && !panRegex.test(pan)) {
      errorPan.panerrorChar = "Invalid Pan";
      isValid = false;
    }
    // const bankRegex = /^[A-Za-z\s]+$/;
    if (bankName == "") {
      errorBankName.missingBankName = "Bank Name is mandatory";
      isValid = false;
    }
    const phoneRegex = /^[6-9]{1}[0-9]{9}$/;
    if (!phoneNumber.length) {
      errorphoneNumber.phonemissing = "Please enter your mobile number.";
      isValid = false;
    }
    if (phoneNumber.length && phoneNumber.length < 10) {
      errorphoneNumber.phonemissing = "Phone Number should be of 10 digit.";
      isValid = false;
    } else if (!phoneRegex.test(phoneNumber) && phoneNumber.length) {
      errorphoneNumber.phonemissingFormat = "Invalid Mobile Number";
      isValid = false;
    }
    if (carrierType == "individual_transporter") {
      if (!aadhar.length) {
        erroraadhar.aadharlength = "Please enter aadhaar";
        isValid = false;
      }
      if (aadhar.length && aadhar.length < 12) {
        erroraadhar.aadharlength = "Invalid Aadhaar";
        isValid = false;
      }

      if (aadharFront?.size > process.env.REACT_APP_MAX_FILE_SIZE) {
        isValid = false;
      }

      if (aadharBack?.size > process.env.REACT_APP_MAX_FILE_SIZE) {
        isValid = false;
      }
    }
    if (
      aadhar?.length &&
      aadhar?.length < 12 &&
      carrierType == "individual_transporter"
    ) {
      erroraadhar.aadharlength = "Invalid Aadhaar";
      isValid = false;
    }
    if (panCard?.name) {
      if (panCard?.size > process.env.REACT_APP_MAX_FILE_SIZE) {
        isValid = false;
      }
    }

    if (signature?.name) {
      if (signature.size > process.env.REACT_APP_MAX_FILE_SIZE) {
        isValid = false;
      }
    }
    //
    if (cancelCheque?.name) {
      if (cancelCheque.size > process.env.REACT_APP_MAX_FILE_SIZE) {
        isValid = false;
      }
    }
    if (nonGstRegistrationCertificate?.name) {
      if (
        nonGstRegistrationCertificate.size >
          process.env.REACT_APP_MAX_FILE_SIZE &&
        carrierType == "individual_transporter"
      ) {
        isValid = false;
      }
    }
    if (carrierType == "registered_transporter") {
      if (
        gstRegistrationCertificate?.size > process.env.REACT_APP_MAX_FILE_SIZE
      ) {
        isValid = false;
      }
    }
    if (msmeCertificate?.name) {
      if (
        msmeCertificate.size > process.env.REACT_APP_MAX_FILE_SIZE &&
        msmeCertificate
      ) {
        isValid = false;
      }
    }
    // if (gstin.length < 15) {
    //   errorgstin.gsterror = "Invalid GSTIN number.";
    // }

    const gstinRegex =
      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z|1-9|A-J|S]{1}[0-9A-Z]{1})$/;

    if (gstin == "" && carrierType == "registered_transporter") {
      if ((gstin === "", !gstin)) {
        errorgstin.errorgstinNumMiss = "Please enter GSTIN.";
        isValid = false;
      } else {
        errorgstin.errorgstinNumMiss = "Invalid GSTIN.";
        isValid = false;
      }
    } else if (
      !gstinRegex.test(gstin) &&
      carrierType == "registered_transporter"
    ) {
      errorgstin.errorgstinNum = "Invalid GSTIN format";
      isValid = false;
    }

    if (ifsc == "") {
      errorifsc.ifscError = "IFSC code is mandatory";
    } else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifsc)) {
      errorifsc.invalidIfsc = "Please enter valid IFSC code (AAAA0999999).";
    }
    if (holderName?.length == "") {
      errorholderName.holdernameerror =
        "Account holder name attatchment is required.";
    }
    if (bankAccNumber == "") {
      errorbankAccNumber.accNumber = "A/c number attatchment is required.";
    } else if (!/^[0-9]{9,18}$/.test(bankAccNumber)) {
      errorbankAccNumber.invalidAccNumber =
        "Minimum 9 and maximum 18 digits are allowed.";
    }
    if (accType == "") {
      erroraccType.errorTypeMissing = "A/C type attatchment is required.";
      isValid = false;
    }
    // if (pincode?.length < 6 || pincode == "") {
    //   errorpincode.errorPincodes = "Pincode is Requied.";
    //   isValid = false;
    // }
    if (village == "") {
      errorvillage.villageError = "Village attatchment is required.";
      isValid = false;
    }

    if (
      aadharFront?.name == undefined &&
      !editMode &&
      carrierType == "individual_transporter"
    ) {
      errorAadharFront.aadharFrontErr = "Aadhaar attatchment is required.";
      isValid = false;
    }
    if (
      aadharBack?.name == undefined &&
      !editMode &&
      carrierType == "individual_transporter"
    ) {
      errorAadharBack.aadharBackErr = "Aadhaar attatchment is required.";
      isValid = false;
    }
    if (!panCard && !editMode) {
      errorPanCard.panCardErr = "Pan attatchment is required.";
      isValid = false;
    }
    if (!signature && !editMode) {
      errorSignature.signErr = "Signature attatchment is required";
      isValid = false;
    }
    if (!cancelCheque && !editMode) {
      errorCancelCheque.chequeError = "Cancel Cheque attatchment is required";
      isValid = false;
    }

    if (
      carrierType === "individual_transporter" &&
      !nonGstRegistrationCertificate &&
      !editMode
    ) {
      errorNonGstRegistration.nonGstError =
        "Non GST declaration certificate attatchment is required";
      setErrorGstRegistration({});
      isValid = false;
    }

    if (
      carrierType === "registered_transporter" &&
      !gstRegistrationCertificate &&
      !editMode
    ) {
      errorGstRegistration.gstError =
        "GST registered certificate attatchment is required";
      setErrorNonGstRegistration({});
      isValid = false;
    }

    // if (landmark == "") {
    //   errorlandmark.landmarkerror = "Landmark attatchment is required.";
    // }
    setErrorName(errorName);
    setCarrierTypeError(carrierTypeError);
    setEmailError(emailError);
    setErrorPan(errorPan);
    setErrorBankName(errorBankName);
    setErrorphoneNumber(errorphoneNumber);
    setErroraadhar(erroraadhar);
    setErrorgstin(errorgstin);
    setErrorifsc(errorifsc);
    setErrorholderName(errorholderName);
    setErrorbankAccNumber(errorbankAccNumber);
    setErroraccType(erroraccType);
    setErrorVillage(errorvillage);
    setErrorAadharFront(errorAadharFront);
    setErrorAadharBack(errorAadharBack);

    // setErrorlandmark(errorlandmark);
    return isValid;
  };

  const validateField = (field) => {
    if (field.target.name === "transporter_name") {
      // if(field.target.value)
      const nameRegex = /^[a-zA-Z0-9)/ (.-]+$/g;
      if (nameRegex.test(field.target.value)) {
        setErrorName({ ...errorName, nameError: "" });
        setIsInputValueValid(true);
      } else {
        setErrorName({
          ...errorName,
          nameError: `Only alphabets, numbers and special characters . , - ( ) / are allowed`,
        });
        setIsInputValueValid(false);
      }
    }
    if (field.target.name === "gstin") {
      const gstinRegex =
        /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z|1-9|A-J|S]{1}[0-9A-Z]{1})$/;
      if (gstinRegex.test(field.target.value)) {
        setErrorgstin({ ...errorgstin, gstinError: "" });
        setIsInputValueValid(true);
      } else {
        setErrorgstin({
          ...errorgstin,
          gstinError: `Please Enter valid GSTIN (22AAAAA0000A1Z5)`,
        });
        setIsInputValueValid(false);
      }
    }
    if (field.target.name === "mobile") {
      if (field.target.value.length !== 10) {
        setMobileError({
          ...mobileError,
          invalidMobile: "Please Enter Valid Mobile Number",
        });
        setIsInputValueValid(false);
      } else {
        setMobileError({ ...mobileError, invalidMobile: "" });
        setIsInputValueValid(true);
      }
    }
    if (field.target.name === "email" && field.target.value !== "") {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      if (!emailRegex.test(field.target.value)) {
        setEmailError({
          ...emailError,
          invalidMobile: "Please Enter Valid Email",
        });
        setIsInputValueValid(false);
      } else {
        setEmailError({ ...emailError, invalidMobile: "" });
        setIsInputValueValid(true);
      }
    }
    if (field.target.name === "pan") {
      const panRegex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
      if (panRegex.test(field.target.value)) {
        setErrorPan({ ...errorPan, panerrorChar: "" });
        setIsInputValueValid(true);
      } else {
        setErrorPan({
          ...errorPan,
          panerrorChar: "Please enter valid PAN Number (ABCDE1234F)",
        });
        setIsInputValueValid(false);
      }
    }
    if (field.target.name === "aadhaar_card") {
      if (field.target.value.length < 12) {
        setErroraadhar({
          ...erroraadhar,
          aadharError: "Aadhaar Number should be 12 digits",
        });
        setIsInputValueValid(false);
      } else {
        setErroraadhar({ ...erroraadhar, aadharError: "" });
        setIsInputValueValid(true);
      }
    }
    if (field.target.name === "ifsc") {
      const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
      if (ifscRegex.test(field.target.value)) {
        setErrorifsc({ ...errorifsc, ifscError: "" });
        setIsInputValueValid(true);
      } else {
        if (field.target.value.length <= 11)
          setErrorifsc({
            ...errorifsc,
            ifscError: "Please Enter Valid IFSC (AAAA0123456)",
          });
        setIsInputValueValid(false);
      }
    }
    if (field.target.name === "account_no") {
      const bankAccountRegex = /^[0-9]{9,18}$/;
      if (bankAccountRegex.test(field.target.value)) {
        setErrorbankAccNumber({ ...errorbankAccNumber, invalidAccNumber: "" });
        setIsInputValueValid(true);
      } else {
        if (field.target.value.length <= 18)
          setErrorbankAccNumber({
            ...errorbankAccNumber,
            invalidAccNumber: "Minimum 9 and maximum 18 digits are allowed.",
          });
        setIsInputValueValid(false);
      }
    }
    // if (field.target.name === "pincode") {
    //   if (field.target.value.length < 6) {
    //     setErrorpincode({
    //       ...errorpincode,
    //       pincodeError: "Please enter valid Pincode",
    //     });
    //     setIsInputValueValid(false);
    //   } else {
    //     setErrorpincode({ ...errorpincode, pincodeError: "" });
    //     setIsInputValueValid(true);
    //   }
    // }
  };
  // document attachments

  const onFileChange = async (event) => {
    const newFile = await saveBlob(event.target.files[0]);

    if (event.target.id === "aadhar-front") {
      setAadharFront(newFile);
      setErrorAadharFront("");
    } else if (event.target.id === "aadhar-back") {
      setAadharBack(newFile);
      setErrorAadharBack("");
    } else if (event.target.id === "pan-card") {
      setPanCard(newFile);
      setErrorPanCard("");
    } else if (event.target.id === "signature") {
      setSignature(newFile);
      setErrorSignature("");
    } else if (event.target.id === "cancel-cheque") {
      setCancelCheque(newFile);
      setErrorCancelCheque("");
    } else if (event.target.id === "more-than-ten-vehicles") {
      setMoreThan10VehicleAttachment(newFile);
    } else if (event.target.id === "GST-registration") {
      setGstRegistrationCertificate(newFile);
      setErrorGstRegistration({});
    } else if (event.target.id === "non-GST-registration") {
      setNonGstRegistrationCertificate(newFile);
      setErrorNonGstRegistration({});
    } else if (event.target.id === "msme-certificate") {
      setMsmeCertificate(newFile);
    }
    if (event.target.id === "tds-file") {
      setTdsFile(newFile);
      setErrorTdsFile("");
    }
  };

  const handlePincode = (data, errorMsg) => {
    if (data) {
      setPincode(data);
    }
    if (errorMsg) {
      setErrorpincode(errorMsg);
    } else {
      setErrorpincode("");
      setErrorpincodeNotAvailable("");
      getAddressFromPincode(data);
    }
  };

  const handleSelectBankDetails = (e) => {
    console.log(e.target.value, "bank nameeee");

    setSelctedBankDetails(e.target.value);
    setErrorBankName({});
  };

  useEffect(() => {
    if (editMode) {
      const selectedBank = BankListDetails.filter(
        (item) => item.bankName === bankName
      );
      setSelctedBankDetails(selectedBank[0]);
    }
  }, [bankName, editMode]);

  const handleCheckIfscValid = (e) => {
    if (e.target.value.length === 11) {
      const slicedValue = e.target.value.slice(0, 4);
      console.log(slicedValue, selctedBankDetails.ifsc, "ifsc sliced value");
      if (selctedBankDetails.ifsc === "") return setInvalidIFSC("");
      if (slicedValue.toLowerCase() !== selctedBankDetails.ifsc.toLowerCase()) {
        setInvalidIFSC(
          "Wrong IFSC code. It should start with " + selctedBankDetails.ifsc
        );
      } else {
        setInvalidIFSC("");
      }
    }
  };

  const showSaveButton = () => {
    if (!editMode) {
      if (carrierType === "individual_transporter" || carrierType === "") {
        if (
          name &&
          (carrierType ? carrierType : true) &&
          phoneNumber &&
          pan &&
          panCard?.name &&
          aadhar &&
          aadharFront?.name &&
          aadharBack?.name &&
          nonGstRegistrationCertificate?.name &&
          pincode &&
          bankName &&
          ifsc &&
          bankAccNumber &&
          bankName &&
          holderName &&
          cancelCheque.name &&
          state &&
          district &&
          village &&
          signature?.name &&
          (Object.keys(errorName).length === 0 ||
            !Object.values(errorName).some((item) => item !== "")) &&
          (Object.keys(errorBankName).length === 0 ||
            !Object.values(errorBankName).some((item) => item !== "")) &&
          (Object.keys(errorPan).length === 0 ||
            !Object.values(errorPan).some((item) => item !== "")) &&
          (Object.keys(errorholderName).length === 0 ||
            !Object.values(errorholderName).some((item) => item !== "")) &&
          invalidIFSC === ""
        ) {
          return { display: "block" };
        } else {
          return { display: "none" };
        }
      }
      if (carrierType === "registered_transporter") {
        if (
          name &&
          (carrierType ? carrierType : true) &&
          phoneNumber &&
          pan &&
          panCard?.name &&
          gstRegistrationCertificate?.name &&
          gstin &&
          pincode &&
          bankName &&
          ifsc &&
          bankAccNumber &&
          bankName &&
          holderName &&
          cancelCheque.name &&
          state &&
          district &&
          village &&
          signature?.name &&
          (Object.keys(errorgstin).length === 0 ||
            !Object.values(errorgstin).some((item) => item !== "")) &&
          (Object.keys(errorName).length === 0 ||
            !Object.values(errorName).some((item) => item !== "")) &&
          (Object.keys(errorholderName).length === 0 ||
            !Object.values(errorholderName).some((item) => item !== ""))
        ) {
          return { display: "block" };
        } else {
          return { display: "none" };
        }
      }
    } else {
      return { display: "block" };
    }
  };

  //+++++++++++++++++++++++++++++++++

  const carrier = ["registered_transporter", "individual_transporter"];
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const handleBack = () => {
    setEditMode(false);
    sessionStorage.removeItem("website_url");
  };
  return (
    <Component_style className="main_onboard_container">
      <ToastContainer position="bottom-right" />
      {isLoggedIn ? null : (
        <div className="transporter-nav">
          <img src={leapSupplyLogo} alt="" />
        </div>
      )}
      <div className="main_onboard_wrapper">
        <div className="main_onboard_Right_box">
          {isMobile ? (
            <>
              <div
                style={{
                  display: "flex",
                  width: "109%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: colorPalette.primaryColor,
                  padding: ".8rem .5rem",
                }}
              >
                <div style={{ marginLeft: "1rem" }}>
                  <NavLink to="/transporters" className="navLink">
                    <ArrowBackIcon
                      style={{ color: "white" }}
                      onClick={handleBack}
                    />
                    {/* <h5 style={{ color: "#060606", color: "white" }}>Back</h5> */}
                  </NavLink>
                </div>
                {/* <div className="progress_container"> */}
                <div style={{ width: "100%" }}>
                  <h4
                    // className="progress_text"
                    style={{
                      color: "white",
                      // width: "100%",
                      marginLeft: "1rem",
                      // fontSize: "1rem",/
                    }}
                  >
                    Transporter's Details
                  </h4>
                </div>
                {/* </div> */}
              </div>
            </>
          ) : null}

          <div className="form_container">
            <div style={{ paddingBottom: "140px" }} className="form_wrapper">
              {isMobile ? null : (
                <div className="form_row">
                  <NavLink to="/transporters" className="navLink">
                    <ArrowBackIcon className="back" onClick={handleBack} />
                    <h4
                      className="progress_text"
                      style={{ fontSize: "1.5rem", color: "black" }}
                    >
                      Transporter's Details
                    </h4>
                  </NavLink>
                </div>
              )}
              <div className="transporter-container">
                <div className="form_row">
                  <div>
                    <h4 className="form_label">
                      Transporter Name<sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      id="outlined-basic"
                      // label="Enter Your Transporter Name"
                      placeholder="Enter Your Transporter Name"
                      variant="outlined"
                      className="input_field_form"
                      // sx={{
                      //   width: "40ch",
                      //   height: "10ch",
                      // }}
                      color="success"
                      size="small"
                      value={name}
                      name="transporter_name"
                      onChange={(e) => {
                        setName(e.target.value);
                        setHolderName(e.target.value);
                        setErrorName("");
                        validateField(e);
                      }}
                    />
                    {Object.keys(errorName).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>{errorName[key]}</h6>
                      );
                    })}
                  </div>
                  <div>
                    <h4 className="form_label">
                      Transporters Type<sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      id="outlined-select-currency"
                      select
                      label="Please Select One Transporter"
                      disabled={editMode}
                      value={carrierType}
                      onChange={(e) => {
                        setCarrierType(e.target.value);
                        setCarrierTypeError("");
                      }}
                      // variant="filled"
                      className="input_field_form"
                      // sx={{
                      //   width: "40ch",
                      //   height: "8ch",
                      // }}
                      color="success"
                      size="small"
                    >
                      {carrier.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option.split("_").join(" ").toUpperCase()}
                        </MenuItem>
                      ))}
                    </TextField>
                    {Object.keys(carrierTypeError).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {carrierTypeError[key]}
                        </h6>
                      );
                    })}
                  </div>
                </div>

                <div className="form_row">
                  <div>
                    <h4 className="form_label">
                      Mobile Number<sup style={{ color: "#E06666" }}>*</sup>
                    </h4>

                    <TextField
                      id="outlined-basic"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter Your Mobile Number"
                      variant="outlined"
                      className="input_field_form"
                      color="success"
                      size="small"
                      name="mobile"
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                        setErrorphoneNumber("");
                        validateField(e);
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                    />
                    {Object.keys(errorphoneNumber).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorphoneNumber[key]}
                        </h6>
                      );
                    })}
                    {Object.keys(mobileError).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>{mobileError[key]}</h6>
                      );
                    })}
                  </div>
                  <div>
                    <h4 className="form_label">E-Mail</h4>

                    <TextField
                      id="outlined-basic"
                      type="email"
                      placeholder="Enter Your E-mail"
                      variant="outlined"
                      className="input_field_form"
                      value={email}
                      color="success"
                      size="small"
                      name="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError("");
                        validateField(e);
                      }}
                    />
                    {Object.keys(emailError).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>{emailError[key]}</h6>
                      );
                    })}
                  </div>
                </div>

                <div className="form_row">
                  <div>
                    <h4 className="form_label">
                      PAN<sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Your PAN"
                      variant="outlined"
                      value={pan}
                      className="input_field_form"
                      size="small"
                      name="pan"
                      onChange={(e) => {
                        handlePanChange(e);
                      }}
                      // style={{ position: "relative" }}
                    />
                    {Object.keys(errorPan).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>{errorPan[key]}</h6>
                      );
                    })}
                    {/* <DriveFolderUploadIcon
                    style={{ position: "absolute", right: "500px" }}
                  /> */}
                  </div>
                  <div>
                    <h4 className="form_label">
                      Pan Card<sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      size="small"
                      id="dropdown"
                      diebled
                      autoComplete="off"
                      className="input_field_form "
                      variant="outlined"
                      placeholder="Upload Pan Card"
                      name="attachment_tag"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          >
                            <button
                              className="upload_button"
                              style={{
                                width: "40px",
                                height: "40px",
                                marginRight: "-14px",
                              }}
                            >
                              <input
                                // name="upload"
                                name="attachment_file"
                                id="pan-card"
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={onFileChange}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label htmlFor="pan-card">
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <span
                      style={{
                        fontSize: ".8rem",
                        display: "block",
                      }}
                    >
                      {panCard ? panCard.name : null}
                    </span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        display: "block",
                        color: "red",
                      }}
                    >
                      {panCard?.type === "application/pdf"
                        ? "*Invalid file format,Only png or jpg is allowed."
                        : null}
                    </span>
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {panCard
                        ? panCard.size > process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size should be less than ${
                              process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                            }MB`
                          : null
                        : null}
                    </span>
                    {Object.keys(errorPanCard).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorPanCard[key]}
                        </h6>
                      );
                    })}
                  </div>
                </div>

                <div className="form_row">
                  {carrierType === "registered_transporter" ? (
                    <div>
                      <h4 className="form_label">
                        GSTIN<sup style={{ color: "red" }}>*</sup>
                        {/* {carrierType == "individual_transporter" ? (
                          <> (Not required for Individual.)</>
                        ) : (
                          ""
                        )} */}
                      </h4>

                      <TextField
                        id="outlined-basic"
                        placeholder="Enter Your GSTIN"
                        variant="outlined"
                        value={gstin !== "null" ? gstin : ""}
                        className="input_field_form"
                        color="success"
                        name="gstin"
                        size="small"
                        onChange={(e) => {
                          if (e.target.value.length < 16) {
                            setGstin(e.target.value);
                            setErrorgstin("");
                          }

                          validateField(e);
                        }}
                        // disabled
                      />
                      {Object.keys(errorgstin).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorgstin[key]}
                          </h6>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <h4 className="form_label">
                        Aadhaar Number
                        {carrierType == "registered_transporter" ? null : (
                          <sup style={{ color: "red" }}>*</sup>
                        )}
                      </h4>

                      <TextField
                        id="outlined-basic"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        placeholder="Enter Your Aadhaar Number"
                        variant="outlined"
                        className="input_field_form"
                        value={aadhar}
                        color="success"
                        name="aadhaar_card"
                        size="small"
                        onChange={(e) => {
                          handleAadhaarInput(e);
                        }}
                        onInput={(e) => {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            .toString()
                            .slice(0, 12);
                        }}
                      />
                      {Object.keys(erroraadhar).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {erroraadhar[key]}
                          </h6>
                        );
                      })}
                    </div>
                  )}
                  {carrierType === "registered_transporter" ? (
                    <div>
                      <h4 className="form_label">
                        GST Registration Certificate
                        <sup style={{ color: "red" }}>*</sup>
                      </h4>

                      <TextField
                        size="small"
                        id="dropdown"
                        disabled
                        autoComplete="off"
                        className="input_field_form "
                        variant="outlined"
                        placeholder="Upload GST Registration Certificate"
                        name="attachment_tag"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button
                                className="upload_button"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  marginRight: "-14px",
                                }}
                              >
                                <input
                                  // name="upload"
                                  name="attachment_file"
                                  id="GST-registration"
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={onFileChange}
                                  hidden
                                  disabled={
                                    carrierType == "individual_transporter"
                                  }
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label htmlFor="GST-registration">
                                  <img
                                    src={UploadImage}
                                    alt=""
                                    width="20px"
                                    height="20px"
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                      <span
                        style={{
                          fontSize: ".8rem",
                          display: "block",
                        }}
                      >
                        {gstRegistrationCertificate
                          ? gstRegistrationCertificate.name
                          : null}
                      </span>
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {gstRegistrationCertificate
                          ? gstRegistrationCertificate.size >
                            process.env.REACT_APP_MAX_FILE_SIZE
                            ? `File size should be less than ${
                                process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                              }MB`
                            : null
                          : null}
                      </span>
                      {/* <span
                        style={{
                          fontSize: ".8rem",
                          display: "block",
                          color: "red",
                        }}
                      >
                        {gstRegistrationCertificate?.type === "application/pdf"
                          ? "*Invalid file format,Only png or jpg is allowed."
                          : null}
                      </span> */}
                      {Object.keys(errorGstRegistration).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorGstRegistration[key]}
                          </h6>
                        );
                      })}
                    </div>
                  ) : (
                    <div>
                      <h4 className="form_label">
                        Non GST Self Declaration Certificate
                        <sup style={{ color: "red" }}>*</sup>
                      </h4>

                      <TextField
                        size="small"
                        id="dropdown"
                        disabled
                        autoComplete="off"
                        className="input_field_form"
                        variant="outlined"
                        placeholder="Upload Non GST Registration Certificate"
                        name="attachment_tag"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button
                                className="upload_button"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  marginRight: "-14px",
                                }}
                              >
                                <input
                                  // name="upload"
                                  name="attachment_file"
                                  id="non-GST-registration"
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={onFileChange}
                                  hidden
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label htmlFor="non-GST-registration">
                                  <img
                                    src={UploadImage}
                                    alt=""
                                    width="20px"
                                    height="20px"
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                      <span
                        style={{
                          fontSize: ".8rem",
                          display: "block",
                        }}
                      >
                        {nonGstRegistrationCertificate
                          ? nonGstRegistrationCertificate.name
                          : null}
                      </span>
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {nonGstRegistrationCertificate
                          ? nonGstRegistrationCertificate.size >
                            process.env.REACT_APP_MAX_FILE_SIZE
                            ? `File size should be less than ${
                                process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                              }MB`
                            : null
                          : null}
                      </span>
                      {/* <span
                        style={{
                          fontSize: ".8rem",
                          display: "block",
                          color: "red",
                        }}
                      >
                        {nonGstRegistrationCertificate?.type ===
                        "application/pdf"
                          ? "*Invalid file format,Only png or jpg is allowed."
                          : null}
                      </span> */}
                      {Object.keys(errorNonGstRegistration).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorNonGstRegistration[key]}
                          </h6>
                        );
                      })}
                    </div>
                  )}
                </div>

                {carrierType == "registered_transporter" ? null : (
                  <div className="form_row">
                    <div>
                      <h4 className="form_label">
                        Aadhaar Front Photo{" "}
                        <sup style={{ color: "red" }}>*</sup>
                      </h4>

                      <TextField
                        size="small"
                        id="dropdown"
                        disabled
                        autoComplete="off"
                        className="input_field_form"
                        variant="outlined"
                        placeholder="Upload Aadhaar Front"
                        name="attachment_tag"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button
                                className="upload_button"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  marginRight: "-14px",
                                }}
                              >
                                <input
                                  // name="upload"
                                  name="attachment_file"
                                  id="aadhar-front"
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={onFileChange}
                                  hidden
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label htmlFor="aadhar-front">
                                  <img
                                    src={UploadImage}
                                    alt=""
                                    width="20px"
                                    height="20px"
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                      <span
                        style={{
                          fontSize: ".8rem",
                          display: "block",
                        }}
                      >
                        {aadharFront ? aadharFront.name : null}
                      </span>
                      <span
                        style={{
                          fontSize: ".8rem",
                          display: "block",
                          color: "red",
                        }}
                      >
                        {aadharFront?.type === "application/pdf"
                          ? "*Invalid file format,Only png or jpg is allowed."
                          : null}
                      </span>
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {aadharFront
                          ? aadharFront.size >
                            process.env.REACT_APP_MAX_FILE_SIZE
                            ? `File size should be less than ${
                                process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                              }MB`
                            : null
                          : null}
                      </span>

                      {Object.keys(errorAadharFront).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorAadharFront[key]}
                          </h6>
                        );
                      })}
                    </div>
                    <div>
                      <h4 className="form_label">
                        Aadhaar Back Photo{" "}
                        {carrierType == "registered_transporter" ? null : (
                          <sup style={{ color: "red" }}>*</sup>
                        )}
                      </h4>

                      <TextField
                        size="small"
                        id="dropdown"
                        disabled
                        autoComplete="off"
                        variant="outlined"
                        className="input_field_form"
                        placeholder="Upload Aadhaar Back"
                        name="attachment_tag"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button
                                className="upload_button"
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  marginRight: "-14px",
                                }}
                              >
                                <input
                                  // name="upload"
                                  name="attachment_file"
                                  id="aadhar-back"
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={onFileChange}
                                  hidden
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label htmlFor="aadhar-back">
                                  <img
                                    src={UploadImage}
                                    alt=""
                                    width="20px"
                                    height="20px"
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                      <span
                        style={{
                          fontSize: ".8rem",
                          display: "block",
                        }}
                      >
                        {aadharBack ? aadharBack.name : null}
                      </span>
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {aadharBack
                          ? aadharBack.size >
                            process.env.REACT_APP_MAX_FILE_SIZE
                            ? `File size should be less than ${
                                process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                              }MB`
                            : null
                          : null}
                      </span>
                      <span
                        style={{
                          fontSize: ".8rem",
                          display: "block",
                          color: "red",
                        }}
                      >
                        {aadharBack?.type === "application/pdf"
                          ? "*Invalid file format,Only png or jpg is allowed."
                          : null}
                      </span>
                      {Object.keys(errorAadharBack).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorAadharBack[key]}
                          </h6>
                        );
                      })}
                    </div>
                  </div>
                )}

                <div className="form_row">
                  <Pincode
                    parentStyle={{
                      display: "flex",
                      flexDirection: "column",
                      width: isMobile ? "115%" : "43%",
                      marginLeft: isMobile ? "23%" : "",
                    }}
                    handlePincode={handlePincode}
                    pincodeError={errorpincode}
                    inputDesign={{ marginTop: 0, width: "100%" }}
                    lastPincode={pincode}
                    inputWidth={""}
                  />
                  <div>
                    <h4 className="form_label">
                      TDS Non Deduction Declaration{" "}
                    </h4>

                    <TextField
                      size="small"
                      id="dropdown"
                      disabled
                      autoComplete="off"
                      variant="outlined"
                      className="input_field_form"
                      placeholder="Upload tds non declaration"
                      name="attachment_tag"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          >
                            <button
                              className="upload_button"
                              style={{
                                width: "40px",
                                height: "40px",
                                marginRight: "-14px",
                              }}
                            >
                              <input
                                // name="upload"
                                name="attachment_file"
                                id="tds-file"
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={onFileChange}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label htmlFor="tds-file">
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <span
                      style={{
                        fontSize: ".8rem",
                        display: "block",
                      }}
                    >
                      {tdsFile ? tdsFile.name : null}
                    </span>
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {tdsFile
                        ? tdsFile.size > process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size should be less than ${
                              process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                            }MB`
                          : null
                        : null}
                    </span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        display: "block",
                        color: "red",
                      }}
                    >
                      {tdsFile?.type === "application/pdf"
                        ? "*Invalid file format,Only png or jpg is allowed."
                        : null}
                    </span>
                    {Object.keys(errorTdsFile).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorTdsFile[key]}
                        </h6>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div
                style={
                  !editMode
                    ? name &&
                      carrierType &&
                      phoneNumber.length == 10 &&
                      pan &&
                      (carrierType === "individual_transporter"
                        ? true
                        : gstin) &&
                      (carrierType === "individual_transporter"
                        ? aadhar
                        : true) &&
                      (carrierType === "individual_transporter"
                        ? aadharFront?.name
                        : gstRegistrationCertificate) &&
                      (carrierType === "individual_transporter"
                        ? aadharBack?.name
                        : gstRegistrationCertificate) &&
                      (carrierType === "individual_transporter"
                        ? nonGstRegistrationCertificate
                        : gstRegistrationCertificate) &&
                      pincode
                      ? { display: "block" }
                      : { display: "none" }
                    : { display: "block" }
                }
                className="transporter-container"
              >
                <div className="form_row">
                  <div>
                    <h4 className="form_label">
                      Bank Name<sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      id="filled-select-currency"
                      select
                      label=""
                      value={selctedBankDetails}
                      onChange={(e) => {
                        setBankName(e.target.value.bankName);
                        handleSelectBankDetails(e);
                      }}
                      // variant="filled"
                      className="input_field_form"
                      color="success"
                      size="small"
                    >
                      <MenuItem value="0" disabled>
                        Select Bank Name
                      </MenuItem>
                      {BankListDetails.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option.bankName}
                        </MenuItem>
                      ))}
                    </TextField>
                    {Object.keys(errorBankName).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorBankName[key]}
                        </h6>
                      );
                    })}
                  </div>
                  <div>
                    <h4 className="form_label">
                      IFSC Code<sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      id="outlined-basic"
                      placeholder="Enter IFSC Code"
                      variant="outlined"
                      className="input_field_form"
                      value={ifsc}
                      disabled={!bankName}
                      color="success"
                      size="small"
                      name="ifsc"
                      onChange={(e) => {
                        if (e.target.value.length < 12) {
                          setIfsc(e.target.value);
                        }
                        handleCheckIfscValid(e);
                        validateField(e);
                      }}
                    />
                    {Object.keys(errorifsc).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>{errorifsc[key]}</h6>
                      );
                    })}

                    {invalidIFSC ? (
                      <h6 style={{ color: "#E06666" }}>{invalidIFSC}</h6>
                    ) : null}
                  </div>
                </div>

                <div className="form_row">
                  <div>
                    <h4 className="form_label">
                      Account No.<sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Your Bank Name"
                      variant="outlined"
                      className="input_field_form"
                      value={bankAccNumber}
                      disabled={!bankName}
                      color="success"
                      size="small"
                      name="account_no"
                      onChange={(e) => {
                        if (e.target.value.length <= 18) {
                          setBankAccNumber(e.target.value);
                        }
                        setErrorbankAccNumber("");
                        validateField(e);
                      }}
                      onInput={(e) => {
                        e.target.value.toString();
                      }}
                    />
                    {Object.keys(errorbankAccNumber).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorbankAccNumber[key]}
                        </h6>
                      );
                    })}
                  </div>
                  <div>
                    <h4 className="form_label">
                      Bank Account Type<sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      id="filled-select-currency"
                      select
                      label="Please Select account type"
                      value={accType}
                      onChange={(e) => {
                        setAccType(e.target.value);
                        setErroraccType("");
                      }}
                      // variant="filled"
                      className="input_field_form"
                      color="success"
                      size="small"
                    >
                      {bankAccTypeList
                        // .filter((data) => data.split("_").join(" ").toUpperCase())
                        .map((option, index) => (
                          <MenuItem key={index} value={option}>
                            {option.split("_").join(" ").toUpperCase()}
                          </MenuItem>
                        ))}
                    </TextField>
                    {Object.keys(erroraccType).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {erroraccType[key]}
                        </h6>
                      );
                    })}
                  </div>
                </div>

                <div className="form_row">
                  <div>
                    <h4 className="form_label">
                      Bank Account Holder Name
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      id="outlined-basic"
                      placeholder="Enter A/C Holder Name"
                      variant="outlined"
                      className="input_field_form"
                      value={name}
                      color="success"
                      size="small"
                      disabled="true"
                      onChange={(e) => {
                        setHolderName(e.target.value);
                        setErrorholderName({
                          errorholderName:
                            "A/C holder name cannot be other than Transporter name",
                        });
                      }}
                    />
                    {Object.keys(errorholderName).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorholderName[key]}
                        </h6>
                      );
                    })}
                  </div>
                  <div>
                    <h4 className="form_label">
                      Cancel Cheque<sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      size="small"
                      id="dropdown"
                      disabled
                      autoComplete="off"
                      className="input_field_form "
                      variant="outlined"
                      placeholder="Upload Cancel Cheque"
                      name="attachment_tag"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          >
                            <button
                              className="upload_button"
                              style={{
                                width: "40px",
                                height: "40px",
                                marginRight: "-14px",
                              }}
                            >
                              <input
                                // name="upload"
                                name="attachment_file"
                                id="cancel-cheque"
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={onFileChange}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label htmlFor="cancel-cheque">
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <span
                      style={{
                        fontSize: ".8rem",
                        display: "block",
                      }}
                    >
                      {cancelCheque ? cancelCheque.name : null}
                    </span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        display: "block",
                        color: "red",
                      }}
                    >
                      {cancelCheque?.type === "application/pdf"
                        ? "*Invalid file format,Only png or jpg is allowed."
                        : null}
                    </span>
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {cancelCheque
                        ? cancelCheque.size >
                          process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size should be less than ${
                              process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                            }MB`
                          : null
                        : null}
                    </span>
                    {Object.keys(errorCancelCheque).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorCancelCheque[key]}
                        </h6>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div
                style={
                  name &&
                  carrierType &&
                  phoneNumber.length == 10 &&
                  pan &&
                  (carrierType === "individual_transporter" ? true : gstin) &&
                  (carrierType === "individual_transporter" ? aadhar : true) &&
                  pincode &&
                  bankName &&
                  bankAccNumber &&
                  ifsc &&
                  holderName &&
                  accType
                    ? { display: "block" }
                    : { display: "none" }
                }
                className="transporter-container"
              >
                <div className="form_row">
                  <div>
                    <h4 className="form_label">
                      State<sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      id="outlined-basic"
                      placeholder="Enter State"
                      variant="outlined"
                      value={state.toUpperCase()}
                      className="input_field_form"
                      color="success"
                      size="small"
                      disabled
                      onChange={(e) => {
                        setState(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <h4 className="form_label">
                      District<sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      id="outlined-basic"
                      placeholder="Enter District"
                      variant="outlined"
                      value={district}
                      disabled
                      className="input_field_form"
                      color="success"
                      size="small"
                      onChange={(e) => {
                        setDistrict(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="form_row">
                  <div>
                    <h4 className="form_label">
                      Village<sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      id="filled-select-currency"
                      select
                      label="Please Select village"
                      value={village}
                      // disabled
                      onChange={(e) => {
                        setVillage(e.target.value);
                        setErrorVillage("");
                      }}
                      // variant="filled"
                      className="input_field_form"
                      color="success"
                      size="small"
                    >
                      {villageList.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                    {Object.keys(errorvillage).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorvillage[key]}
                        </h6>
                      );
                    })}
                  </div>
                  <div>
                    <h4 className="form_label">Landmark</h4>
                    {/* {Object.keys(errorlandmark).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>{errorlandmark[key]}</h6>
                    );
                  })} */}
                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Your LankMark"
                      variant="outlined"
                      className="input_field_form"
                      value={landmark}
                      color="success"
                      size="small"
                      onChange={(e) => {
                        setLandmark(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                style={
                  name &&
                  carrierType &&
                  phoneNumber &&
                  pan &&
                  (carrierType === "individual_transporter" ? true : gstin) &&
                  (carrierType === "individual_transporter" ? aadhar : true) &&
                  pincode &&
                  state &&
                  district &&
                  village
                    ? { display: "block" }
                    : { display: "none" }
                }
                className="transporter-container"
              >
                <div className="form_row" style={{ marginTop: "20px" }}>
                  <div>
                    <h4 className="form_label">
                      Signature<sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      size="small"
                      id="dropdown"
                      disabled
                      autoComplete="off"
                      className="input_field_form"
                      variant="outlined"
                      placeholder="Upload Signature"
                      name="attachment_tag"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          >
                            <button
                              className="upload_button"
                              style={{
                                width: "40px",
                                height: "40px",
                                marginRight: "-14px",
                              }}
                            >
                              <input
                                // name="upload"
                                name="attachment_file"
                                id="signature"
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={onFileChange}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label htmlFor="signature">
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <span
                      style={{
                        fontSize: ".8rem",
                        display: "block",
                      }}
                    >
                      {signature ? signature.name : null}
                    </span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        display: "block",
                        color: "red",
                      }}
                    >
                      {signature?.type === "application/pdf"
                        ? "*Invalid file format,Only png or jpg is allowed."
                        : null}
                    </span>
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {signature
                        ? signature.size > process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size should be less than ${
                              process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                            }MB`
                          : null
                        : null}
                    </span>
                    {Object.keys(errorSignature).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorSignature[key]}
                        </h6>
                      );
                    })}
                  </div>
                  <div>
                    <h4 className="form_label">MSME Certificate</h4>
                    <TextField
                      size="small"
                      id="dropdown"
                      disabled
                      autoComplete="off"
                      className="input_field_form "
                      variant="outlined"
                      placeholder="Upload MSME Certificate"
                      name="attachment_tag"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          >
                            <button
                              className="upload_button"
                              style={{
                                width: "40px",
                                height: "40px",
                                marginRight: "-14px",
                              }}
                            >
                              <input
                                // name="upload"
                                name="attachment_file"
                                id="msme-certificate"
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={onFileChange}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label htmlFor="msme-certificate">
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <span
                      style={{
                        fontSize: ".8rem",
                        display: "block",
                      }}
                    >
                      {msmeCertificate ? msmeCertificate.name : null}
                    </span>
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {msmeCertificate
                        ? msmeCertificate.size >
                          process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size should be less than ${
                              process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                            }MB`
                          : null
                        : null}
                    </span>
                  </div>
                </div>

                <div className="form_row" style={{ marginTop: "20px" }}>
                  <div>
                    <h4 className="form_label">
                      Self Declaration for less than 10 Vehicles
                    </h4>

                    <TextField
                      size="small"
                      id="dropdown"
                      disabled
                      autoComplete="off"
                      className="input_field_form"
                      variant="outlined"
                      placeholder="Upload Self Declaration Certificate"
                      name="attachment_tag"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          >
                            <button
                              className="upload_button"
                              style={{
                                width: "40px",
                                height: "40px",
                                marginRight: "-14px",
                              }}
                            >
                              <input
                                // name="upload"
                                name="attachment_file"
                                id="more-than-ten-vehicles"
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={onFileChange}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label htmlFor="more-than-ten-vehicles">
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <span
                      style={{
                        fontSize: ".8rem",
                        display: "block",
                      }}
                    >
                      {moreThan10VehicleAttachment
                        ? moreThan10VehicleAttachment.name
                        : null}
                    </span>
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {moreThan10VehicleAttachment
                        ? moreThan10VehicleAttachment.size >
                          process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size should be less than ${
                              process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                            }MB`
                          : null
                        : null}
                    </span>
                    {/* <span
                      style={{
                        fontSize: ".8rem",
                        display: "block",
                        color: "red",
                      }}
                    >
                      {moreThan10VehicleAttachment?.type === "application/pdf"
                        ? "*Invalid file format,Only png or jpg is allowed."
                        : null}
                    </span> */}
                    {/* {Object.keys(errorSignature).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>
                        {errorSignature[key]}
                      </h6>
                    );
                  })} */}
                  </div>
                </div>
              </div>

              <div style={{ fontSize: "12px", textAlign: "center" }}>
                *attachments should not be greater than{" "}
                {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
              </div>
              <div style={{ fontSize: "12px", textAlign: "center" }}>
                * allowed file type should be JPEG/JPG/PNG/PDF.
              </div>

              {uniqueFileError ? (
                <div
                  style={{
                    fontSize: "14px",
                    textAlign: "center",
                    marginTop: "10px",
                    paddingBottom: "25px",
                    color: "red",
                  }}
                >
                  *Every attachments should be unique.
                </div>
              ) : null}

              {isMobile ? (
                <div
                  style={
                    uniqueFileError ? { display: "none" } : showSaveButton()
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      marginTop: "2rem",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{ marginRight: ".5rem" }}
                      checked={submitButtonEnable}
                      onChange={() => {
                        setSubmitButtonEnable(!submitButtonEnable);
                      }}
                    />
                    <h6>
                      By checking this box you agree to{" "}
                      <NavLink
                        target="_blank"
                        rel="opener"
                        to={{
                          pathname: "/terms-conditon",
                        }}
                      >
                        all terms and condition{" "}
                      </NavLink>
                      of Faarms Global
                    </h6>
                  </div>
                </div>
              ) : (
                <div
                  style={
                    uniqueFileError ? { display: "none" } : showSaveButton()
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      margin: "2% 0%",

                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{ marginRight: ".5rem" }}
                      checked={submitButtonEnable}
                      onChange={() => {
                        setSubmitButtonEnable(!submitButtonEnable);
                      }}
                    />
                    <h6>
                      By checking this box you agree to{" "}
                      <NavLink
                        target="_blank"
                        rel="opener"
                        to={{
                          pathname: "/terms-conditon",
                        }}
                      >
                        all terms and condition{" "}
                      </NavLink>
                      of Faarms Global
                    </h6>
                  </div>
                </div>
              )}

              {dataExistMsg ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Alert severity="error">{dataExistMsg}</Alert>
                </div>
              ) : null}
              {termsMessage ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {submitButtonEnable ? null : (
                    <Alert severity="error">{termsMessage}</Alert>
                  )}
                  {/* <Alert severity="error">{termsMessage}</Alert> */}
                </div>
              ) : null}
              <div
                style={uniqueFileError ? { display: "none" } : showSaveButton()}
              >
                {submitButtonEnable ? (
                  <div className="form_row" id="button">
                    <div style={{ marginBottom: "60px" }} className="btn_div">
                      {editMode ? (
                        <>
                          {" "}
                          <button
                            className="btn"
                            onClick={handleSubmit}
                            disabled={circularProgress}
                          >
                            {circularProgress ? (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            ) : (
                              "SAVE"
                            )}
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn"
                            onClick={handleSubmit}
                            disabled={circularProgress}
                          >
                            {circularProgress ? (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            ) : (
                              "SAVE"
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="form_row" id="button">
                    <div className="btn_div">
                      {editMode ? (
                        <>
                          {" "}
                          <button
                            className="btn_disable"
                            onClick={handleTerms}
                            disabled={circularProgress}
                          >
                            {circularProgress ? (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            ) : (
                              "SAVE"
                            )}
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button
                            className="btn_disable"
                            onClick={handleTerms}
                            disabled={circularProgress}
                          >
                            {circularProgress ? (
                              <CircularProgress
                                size={20}
                                style={{ color: "white" }}
                              />
                            ) : (
                              "SAVE"
                            )}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Component_style>
  );
}

export default OnboardingPublic;
