import axios from "axios";
import axiosInterceptor from "../../../config/https";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import apiUrl from "../../../url/apiUrl";
// import axiosInterceptor from "../../../config/httpsForCustomer";

export const createIndentPost = async (
  indentSource,
  indentDestination,
  indentPickupDate,
  indentVehicleType,
  indentQuantity,
  indentValue,
  indentMaterialType,
  indentAttachment,
  attachmentName,
  attachmentType,
  customerId,
  indentstatus
) => {
  try {
    const data = new FormData();
    data.append("sources", indentSource);
    data.append("destinations", indentDestination);
    data.append("pickup_date", indentPickupDate);
    data.append("vehicle_type", indentVehicleType);
    data.append("quantity_of_goods", indentQuantity);
    data.append("image", indentAttachment);
    data.append("material_type", indentMaterialType);
    data.append("value_of_goods", indentValue);
    data.append("document_name", attachmentName);
    data.append("customer_id", customerId);
    data.append("document_type", attachmentType);
    // data.append("created_by", createdby);
    // data.append("modified_by", modifiedby);
    data.append("indent_status", indentstatus);
    //hard coded values
    data.append("currency_code", process.env.REACT_APP_CURRECY_CODE);
    data.append("unit_id", "1");
    data.append("remarks", "indent");

    const response = await axiosInterceptor.post(apiUrl.indentBaseUrl, data, {
      headers: { "Content-type": "multipart/form-data" },
    });

    return response;
  } catch (e) {
    return e.response;
  }
};

export const changeIndentPut = async (
  indentId,
  indentSource,
  indentDestination,
  indentPickupDate,
  indentVehicleType,
  indentQuantity,
  indentValue,
  indentMaterialType,
  // indentAttachment,
  // attachmentName,
  customerId,
  indentStatus
) => {
  try {
    const data = new FormData();
    data.append("sources", indentSource);
    data.append("destinations", indentDestination);
    data.append("pickup_date", indentPickupDate);
    data.append("vehicle_type", indentVehicleType);
    data.append("quantity_of_goods", indentQuantity);
    // data.append("image", indentAttachment);
    data.append("material_type", indentMaterialType);
    data.append("value_of_goods", indentValue);
    // data.append("document_name", attachmentName);
    data.append("customer_id", customerId);
    data.append("indent_status", indentStatus);
    // data.append("document_type", "image");

    //hard coded values
    data.append("currency_code", process.env.REACT_APP_CURRECY_CODE);
    data.append("unit_id", "1");
    data.append("document_type", "image");
    data.append("remarks", "indent");

    const response = await axiosInterceptor.put(
      `${apiUrl.indentBaseUrl}/${prefixSalt()}-${convertToBase64(indentId)}`,
      data,
      {
        headers: { "Content-type": "multipart/form-data" },
      }
    );

    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchAddressFromPincodeApi = async (pincode) => {
  try {
    const response = await axiosInterceptor.get(`${apiUrl.vilagePincodeUrl}/${prefixSalt()}-${convertToBase64(pincode)}`);
    if (response) {
      return response;
    }
  } catch (e) {
    return e.response;
  }
};
export const fetchIndentById = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.indentBaseUrl}/${prefixSalt()}-${convertToBase64(id)}`
    );
    if (response) {
      if (response.status === 200) {
        return response.data;
      }
    }
  } catch (e) {
    if (e.response.status === 403) {
      // localStorage.clear();
      localStorage.removeItem("CUSTOMER_DATA");
      localStorage.removeItem("x_token");
      localStorage.removeItem("website_url");
      localStorage.removeItem("LOGIN_DATA");
      window.location.reload();
    }
  }
};
export const indentAttachmentsPost = (
  document_type,
  image,
  document_name,
  id
) => {
  let data = new FormData();
  data.append("document_type", document_type);
  // data.append("permit_expiry_date_str", `${permit_expiry_date}`);
  data.append("image", image);
  data.append("document_name", document_name);

  var config = {
    method: "post",
    url: `${apiUrl.indentBaseUrl}/${prefixSalt()}-${convertToBase64(
      id
    )}/attachments`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          // localStorage.clear();
          localStorage.removeItem("CUSTOMER_DATA");
          localStorage.removeItem("x_token");
          localStorage.removeItem("website_url");
          localStorage.removeItem("LOGIN_DATA");
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};
export const indentAttachmentsPut = (
  document_type,
  image,
  document_name,
  id,
  attachmentId
) => {
  let data = new FormData();
  data.append("document_type", document_type);
  // data.append("permit_expiry_date_str", `${permit_expiry_date}`);
  data.append("image", image);
  data.append("document_name", document_name);

  var config = {
    method: "put",
    url: `${apiUrl.indentBaseUrl}/${prefixSalt()}-${convertToBase64(
      id
    )}/attachments/${prefixSalt()}-${convertToBase64(attachmentId)}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          // localStorage.clear();
          localStorage.removeItem("CUSTOMER_DATA");
          localStorage.removeItem("x_token");
          localStorage.removeItem("website_url");
          localStorage.removeItem("LOGIN_DATA");
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};

export const fetchIndentAttchmentsId = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.indentBaseUrl}/${prefixSalt()}-${convertToBase64(
        id
      )}/attachments`
    );
    if (response) {
      if (response.status === 200) {
        return response;
      }
    }
  } catch (e) {
    if (e.response.status === 403) {
      // localStorage.clear();
      localStorage.removeItem("CUSTOMER_DATA");
      localStorage.removeItem("x_token");
      localStorage.removeItem("website_url");
      localStorage.removeItem("LOGIN_DATA");
      window.location.reload();
    }
  }
};
