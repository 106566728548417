import React from "react";
import apiUrl from "../../url/apiUrl.js";
import axios from "axios";
import axiosInterceptor from "../../config/https.js";

export const getPaymentDetails = async (orderId) => {
  try {
    const PaymentsResponse = await axiosInterceptor.get(
      `${apiUrl.paymentBaseUrl}/v1/ccavenue/orders?order_id=${orderId}`
    );
    //
    if (PaymentsResponse.status == 200) {
      return PaymentsResponse.data;
    }
  } catch (e) {}
};
