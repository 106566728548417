import { React, useContext, useEffect, useState } from "react";
import apiUrl from "../../url/apiUrl.js";
import axios from "axios";
import UploadImage from "../../assets/upload.svg";
import Alert from "@mui/material/Alert";
import { Component_style } from "../../themes/component_style";
import AsyncSelect from "react-select/async";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Select from "@mui/material/Select";
// import { Input } from "@material-ui/core";
import * as moment from "moment";
import { updateRoutes } from "../../redux/onboardSlice.js";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import CircularProgress from "@mui/material/CircularProgress";
import { updateRoutesAddVehicle } from "../../redux/onboardSlice.js";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  vehiclesRegisterPost,
  vehiclesRegisterPut,
  getVehicleManufacturerById,
  getVehiclesModelById,
  verifyRc,
} from "./VehiclesServices.jsx";
import { InputAdornment } from "@mui/material";
import { updateVehicleId } from "../../redux/onboardSlice.js";
import {
  getVehiclesModels,
  getOemId,
  vehiclesPermitPost,
  getVehiclesListById,
} from "./VehiclesServices.jsx";
import { getVehiclesDetails } from "./VehiclesServices";
import {
  FormatColorResetOutlined,
  FormatColorResetRounded,
} from "@mui/icons-material";
import axiosInterceptor from "../../config/https.js";
import { colorPalette } from "../../themes/theme.js";
import { MyContext } from "../../components/enumContext/EnumContextComponent.jsx";
import SomethingWentWrong from "../../components/somethingWentWrong/SomethingWentWrong.jsx";
import { Modal as ModalAntd } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactSelect from "react-select";
import { allRegex } from "../../utility/regex.js";
import { saveBlob } from "../../utility/saveBlobImage.js";

function VehiclesDetails() {
  const dispatch = useDispatch();
  let history = useHistory();
  const { transporterId } = useParams();
  const { enums } = useContext(MyContext);
  console.log(enums, "enums===");

  const [dataExistMsg, setDataExistMsg] = useState("");
  const [selectedVehicleManufacturerName, setSelectedVehicleManufacturerName] =
    useState("");

  console.log(
    selectedVehicleManufacturerName,
    "selectedVehicleManufacturerName"
  );
  const [selectedVehicleModelName, setSelectedVehicleModelName] = useState("");
  const [selectedstate, setSelectedstate] = useState([]);

  const [oemListData, setOemListData] = useState([]);
  const [freightType, setFreightType] = useState("");

  const [vehicleModelData, setVehicleModelData] = useState([]);

  const [vehicleTripListData, setVehicleTripListData] = useState([]);

  const carrierIdFromRedux = useSelector((state) => state.onboard.carrierId.id);

  const carrierIdd = useSelector((state) => state.onboard.carriedIdEdit);

  const [registration, setRegistration] = useState("");
  const [nationalPermit, setNationalPermit] = useState("");

  const { routes } = useSelector((state) => state.routesChange);

  const { routesAddVehicles } = useSelector((state) => state.routesAddVehicle);

  const [statePermit, setStatePermit] = useState("");

  const [permitNumber, setPermitNumber] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");

  const [status, setStatus] = useState("");

  const [pollutionExpiresOn, setPollutionExpiresOn] = useState("");
  const p = new Date(pollutionExpiresOn);
  const datePollution = moment(p).format("YYYY-MM-DD");

  const [insuranceExpiresOn, setInsuranceExpiresOn] = useState("");
  const i = new Date(insuranceExpiresOn);
  const dateInsurance = moment(i).format("YYYY-MM-DD");

  const [rcExpiresOn, setRcExpiresOn] = useState("");
  const r = new Date(rcExpiresOn);
  const dateRc = moment(r).format("YYYY-MM-DD");

  const [expiresOn, setExpiresOn] = useState("");
  const d = new Date(expiresOn);
  const date = moment(d).format("YYYY-MM-DD");

  const [carrierId, setCarrierId] = useState("");
  const [vehicleModelId, setVehicleModelId] = useState("");

  const [oemId, setOemId] = useState("");

  const [permitState, setPermitState] = useState("");

  const [statusList, setStatusList] = useState([]);

  const [circularProgress, setCircularProgress] = useState(false);
  const [stateListData, setStateListData] = useState([]);
  const vehicleId = useSelector((state) => state.onboard.vehicleId);

  const [active, setActive] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [file, setFile] = useState("");
  const [permitFiles, setPermitFiles] = useState({});
  const [pollution, setPollution] = useState({});
  const [payout, setPayout] = useState([]);
  //
  const [reg, setReg] = useState({});
  const [backPhoto, setBackPhoto] = useState({});
  const [frontPhoto, setFrontPhoto] = useState({});
  const [freightList, setFreightList] = useState([]);
  const [permitFiless, setPermitFiless] = useState([
    {
      file: "",
    },
  ]);
  const [permitsFilesUpload, setPermitsFilesUpload] = useState();

  const addPermitFile = async (event) => {
    const newFile = await saveBlob(event.target.files[0]);
    setPermitsFilesUpload(newFile);
    setErrorPermitFile({});
  };
  const [payoutPeriods, setPayoutPeriods] = useState("");
  const [peKmRate, setPeKmRate] = useState("");
  const [fixedKms, setFixedKms] = useState("");
  const [payoutAmount, setPayoutAmount] = useState("");
  const [cutOffDay, setCutOffDay] = useState("");
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [modalOptions, setModalOptions] = useState([]);
  const [manufacturerInput, setManufacturerInput] = useState("");
  const [modalInput, setModalInput] = useState("");

  var state_List = [];
  var final_stateList = state_List.push(permitState);

  const getStatus = async () => {
    setStatusList(enums?.vehicle_status_list ? enums.vehicle_status_list : []);
  };
  const getFreight = async () => {
    setFreightList(enums?.freight_type ? enums.freight_type : []);
  };

  useEffect(() => {
    getStatus();
    getFreight();
    getStateList();
  }, [enums]);

  useEffect(() => {
    setPayoutPeriods("trip_wise");
  }, []);
  const getStateList = async () => {
    setStateListData(enums?.permitted_states ? enums.permitted_states : []);
  };

  const getVehicleDataByIdEdit = async (id) => {
    setEditMode(true);
    const vehicleResponseDataEdit = await getVehiclesListById(id);

    setRegistration(vehicleResponseDataEdit.registration);
    setNationalPermit(vehicleResponseDataEdit.is_national_permit);
    setStatePermit(vehicleResponseDataEdit.is_state_permit);
    setPermitNumber(vehicleResponseDataEdit.permit_number);
    setVehicleNumber(vehicleResponseDataEdit.vehicle_number);
    setStatus(vehicleResponseDataEdit.status);
    setFreightType(vehicleResponseDataEdit.freight_type);

    // setManufacturerId(vehicleResponseDataEdit.)
    // setExpiresOn(vehicleResponseDataEdit.permit_expiry_date);
    setVehicleModelId(vehicleResponseDataEdit.vehicle_model_id);
    setOemId(vehicleResponseDataEdit.vehicle_manufacturer_id);
    setPayoutPeriods(vehicleResponseDataEdit.payout_period);
    setPeKmRate(vehicleResponseDataEdit.extra_per_km_rate);
    setFixedKms(vehicleResponseDataEdit.fixed_kms);
    setPayoutAmount(vehicleResponseDataEdit.basic_payout_amount);
    setCutOffDay(vehicleResponseDataEdit.payout_cutoff_day_of_month);

    if (vehicleResponseDataEdit.permitted_states) {
      setSelectedstate(vehicleResponseDataEdit.permitted_states.split(","));
    }
    const d = new Date(vehicleResponseDataEdit.permit_expiry_date);

    setExpiresOn(moment(d).format("YYYY-MM-DD"));
    setActive(vehicleResponseDataEdit.is_active);
  };

  useEffect(() => {
    if (vehicleId.id) {
      getVehicleDataByIdEdit(vehicleId.id);
    }
    return () => {
      dispatch(updateVehicleId({}));
    };
  }, []);

  const getVehiclesManufacturerFetch = async (searchValues) => {
    if (editMode) return;
    try {
      const responseOem = await getOemId(searchValues);
      const data = responseOem.vehicle_manufacturer_list;

      const formattedOptions = data.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      setManufacturerOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getVehiclesManufacturerFetch(manufacturerInput);
  }, [manufacturerInput]);

  const getVehiclesModelsFetch = async (searchValue) => {
    if (!oemId) return;
    try {
      const responseData = await getVehiclesModels(oemId, searchValue);
      const data = responseData.model_list;

      const formattedOptions = data.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      setModalOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getVehiclesModelsFetch(modalInput);
  }, [oemId, modalInput]);

  useEffect(() => {
    if (editMode && oemId && manufacturerOptions.length > 0) {
      setSelectedVehicleManufacturerName(
        manufacturerOptions.filter((item) => item.value === oemId)[0]?.label
      );
    }
  }, [manufacturerOptions, oemId]);

  useEffect(() => {
    if (editMode && vehicleModelId && manufacturerOptions.length > 0) {
      setSelectedVehicleModelName(
        modalOptions.filter((item) => item.value === vehicleModelId)[0]?.label
      );
    }
  }, [modalOptions, vehicleModelId]);

  const handleSubmitDetails = async () => {
    let vehicleNumberTemp = vehicleNumber;
    // /(^[A-Z]{3}\s[0-9]{4}$)|(^[A-Z]{3}[0-9]{4}$)|(^[A-Z]{2}\s[0-9]{2}\s[A-Z]{2}\s[0-9]{4}$)|(^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$)/;
    if (/^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/.test(vehicleNumber)) {
      if (vehicleNumber.length == 10) {
        vehicleNumberTemp = `${vehicleNumber.slice(0, 2)} ${vehicleNumber.slice(
          2,
          4
        )} ${vehicleNumber.slice(4, 6)} ${vehicleNumber.slice(6, 10)}`;
      } else if (vehicleNumber.length == 9) {
        vehicleNumberTemp = `${vehicleNumber.slice(0, 2)} ${vehicleNumber.slice(
          2,
          4
        )} ${vehicleNumber.slice(4, 5)} ${vehicleNumber.slice(5, 9)}`;
      }
    } else if (/^[A-Z]{3}[0-9]{4}$/.test(vehicleNumber)) {
      vehicleNumberTemp = `${vehicleNumber.slice(0, 3)} ${vehicleNumber.slice(
        3,
        7
      )}`;
    }

    const isValid = formValidation();

    //
    if (isValid) {
      setCircularProgress(true);
      if (editMode) {
        const responseVehicleEdit = await vehiclesRegisterPut(
          // registration,
          nationalPermit,
          statePermit,
          nationalPermit == true ? "" : selectedstate,
          permitNumber,
          vehicleNumberTemp,
          status,
          expiresOn,
          vehicleModelId,
          oemId,
          // active,
          transporterId,
          // vehicleId.carrier,
          vehicleId.id,
          freightType,
          payoutPeriods,
          payoutAmount,
          peKmRate,
          fixedKms,
          cutOffDay
        );

        // dispatch(onboardData(response.data));
        if (responseVehicleEdit.status === 401) {
          toast.error("401: Not enough permissions");
        } else if (responseVehicleEdit.status == 200) {
          if (routes) {
            history.push(`/transporters/details/${vehicleId.carrier}`);
          } else {
            history.push("/vehicles");
          }

          dispatch(updateVehicleId(""));
        } else if (responseVehicleEdit.response.status == 400) {
          setDataExistMsg(responseVehicleEdit.response.data.detail);
          setCircularProgress(false);
        }
        setCircularProgress(false);
      } else {
        setCircularProgress(true);
        //
        const response = await vehiclesRegisterPost(
          // registration,
          // nationalPermit,
          nationalPermit,
          statePermit,
          nationalPermit == true ? "" : selectedstate,
          permitNumber,
          vehicleNumberTemp,
          // status,
          date,
          transporterId,
          vehicleModelId,
          oemId,
          permitFiles,
          reg,
          frontPhoto,
          backPhoto,
          pollution,
          permitFiles.type == "application/pdf" ? "pdf" : "image",
          reg.type == "application/pdf" ? "pdf" : "image",
          frontPhoto.type == "application/pdf" ? "pdf" : "image",
          backPhoto.type == "application/pdf" ? "pdf" : "image",
          pollution.type == "application/pdf" ? "pdf" : "image",
          dateRc,
          datePollution,
          dateInsurance,
          freightType,
          payoutPeriods,
          payoutAmount,
          peKmRate,
          fixedKms,
          cutOffDay
        );

        //
        //
        // dispatch(onboardData(response.data));
        if (response.status == 200) {
          const permitResponse = await vehiclesPermitPost(
            permitsFilesUpload,
            permitNumber,
            date,
            permitsFilesUpload.type == "application/pdf" ? "pdf" : "image",
            nationalPermit == true ? null : selectedstate,
            transporterId,
            // carrierIdFromRedux ? carrierIdFromRedux : carrierIdd,
            response.data.id
          );
          if (permitResponse.status == 201) {
            history.push("/vehicle-success");
            if (routesAddVehicles) {
              dispatch(
                updateRoutesAddVehicle("from_carrier_add_vehicle_to_success")
              );
              dispatch(updateVehicleId({ carrier: vehicleId.carrier }));
            }
            setCircularProgress(false);
          } else if (permitResponse.response.status == 400) {
            setDataExistMsg(response.response.data.detail);
            setCircularProgress(false);
            //
            //
          }
          // }
        } else if (response.response.status == 400) {
          setDataExistMsg(response.response.data.detail);
          setCircularProgress(false);
        } else if (response.response.status === 401) {
          toast.error("401: Not enough permissions");
          setCircularProgress(false);
        }
      }

      // setCircularProgress(false);
      // setLoadingOnboard(true);
    }
    // setCircularProgress(false);
    // setLoadingComplaintButton(false);
  };

  //++++++++++++++++validations+++++++++++++++
  const [errorRegister, setErrorRegister] = useState({});
  const [errorPermitNational, setErrorPermitNational] = useState({});
  const [errorPermitState, setErrorPermitState] = useState({});
  const [errorPermitNumber, setErrorPermitNumber] = useState({});
  const [errorvehicleNumber, setErrorvehicleNumber] = useState({});
  const [errorExpiry, setErrorExpiry] = useState({});
  const [errorCarrierCode, setErrorCarrierCode] = useState({});
  const [erroStatus, setErrorStatus] = useState({});
  const [errorVehicleModel, setErrorVehicleModel] = useState({});
  const [errorOem, setErrorOem] = useState({});
  const [errorpermitStateSelect, setErrorpermitStateSelect] = useState("");
  const [errorPollution, setErrorPollution] = useState({});
  const [errorInsurance, setErrorInsurance] = useState({});
  const [errorRegistrationCertificate, setErrorRegistrationCertificate] =
    useState({});
  const [errorFront, setErrorFront] = useState({});
  const [errorBack, setErrorBack] = useState({});
  const [errorPermitFile, setErrorPermitFile] = useState({});
  const [errorFreight, SetErrorFreight] = useState({});
  const [errorPollutionDate, setErrorPollutionDate] = useState({});
  const [errorInsuranceDate, setErrorInsuranceDate] = useState({});
  const [errorRcDate, setErrorRcDate] = useState({});
  const [rcNumberError, setRcNumberError] = useState("");
  const [rcDataFromApi, setRcDataFromApi] = useState("");
  const [loading, setLoading] = useState(false);
  const [rcDataFetched, setRcDataFetched] = useState({
    permitNumberFetched: false,
    insuranceExpiresOnFetched: false,
    pollutionExpiresOnFetched: false,
    rcExpiresOnFetched: false,
    permitExpiresOnFetched: false,
    isNationalPermitFetched: false,
  });

  const formValidation = () => {
    // const errorRegister = {};
    const errorPermitNational = {};
    const errorPermitState = {};
    const errorPermitNumber = {};
    const errorvehicleNumber = {};
    const errorExpiry = {};
    const pollutionError = {};

    const errorCarrierCode = {};
    const erroStatus = {};
    const errorVehicleModel = {};
    const errorOem = {};
    const errorpermitStateSelect = {};
    const errorPollutionUpload = {};
    const errorInsuranceObject = {};
    const errorRegistrationObject = {};
    const errorFrontVehicle = {};
    const errorBackVehicle = {};
    const errorPermitFileUpload = {};
    const errorFreightObject = {};
    const insuranceError = {};
    const rcDate = {};
    let isValid = true;

    // if (name.trim().length < 5) {
    //   errorName.shortName = "first name too short";
    //   isValid = false;
    // }
    // if (registration == "") {
    //   errorRegister.emptyRegister = "Registration No. is mandatory";
    //   isValid = false;
    // }
    // if (nationalPermit == "") {
    //   errorPermitNational.emptyNational = "please select one option.";
    //   isValid = false;
    // }
    // if (nationalPermit == null || nationalPermit == "") {
    // if (nationalPermit == "") {
    // if (nationalPermit == "") {
    // if (nationalPermit == null || nationalPermit == "") {
    //   errorPermitNational.emptyState = "please select one option.";
    //   isValid = false;
    // }
    //   if (statePermit == "") {
    //     errorPermitNational.emptyState = "please select one option.";
    //     isValid = false;
    // }

    // if (nationalPermit == true) {
    //   if (statePermit == "") {
    //     isValid = true;
    //   }
    // } else {
    //   errorPermitState.emptyState = "please select one option.";
    //   isValid = false;
    // }
    if (freightType == "") {
      errorFreightObject.freightMissing = "Freight type is missing.";
      isValid = false;
    }
    // if (nationalPermit == true) {
    if (permitNumber == "") {
      errorPermitNumber.missingpermit = "permit number is mandatory";
      isValid = false;
    }

    if (vehicleNumber == "") {
      errorvehicleNumber.vehiclenum = "Vehicle Number is mandatory.";
      isValid = false;
    } else if (
      !allRegex.rcNumberRegex.test(vehicleNumber.replace(/\s+/g, ""))
    ) {
      errorvehicleNumber.vehiclenumFormat = "Invalid Vehicle Number";
      isValid = false;
    }
    // if (status == "") {
    //   erroStatus.statusmissing = "Status is mandatory.";
    //   isValid = false;
    // }

    if (vehicleModelId == "") {
      errorVehicleModel.vehicleModmissing = "Vehicle Type is mandatory.";
      isValid = false;
    }
    if (oemId == "") {
      errorOem.oemmissing = "vehicle manufacturer is mandatory.";
      isValid = false;
    }
    // if (nationalPermit == true) {
    if (selectedstate == "" && nationalPermit == false) {
      errorpermitStateSelect.missingSelelectedstate =
        "Permit state is mandatory.";
      isValid = false;
    }
    if (!editMode) {
      if (pollution.name == undefined) {
        errorPollutionUpload.errorpollutionfile = "file is missing";
        isValid = false;
      }
      if (permitFiles.name == undefined) {
        errorInsurance.errorInsuranceText = "file is missing";
        isValid = false;
      }
      if (reg.name == undefined) {
        errorRegistrationCertificate.errorRegisterText = "file is missing";
        isValid = false;
      }
      if (backPhoto.name == undefined) {
        errorBackVehicle.backPhotomissing = "field is missing";
        isValid = false;
      }
      if (frontPhoto.name == undefined) {
        errorFrontVehicle.backPhotomissing = "field is missing";
        isValid = false;
      }
      if (permitsFilesUpload == undefined) {
        errorPermitFileUpload.permitmissing = "field is missing";
        isValid = false;
      }
      if (expiresOn == "") {
        errorExpiry.expirysmissing = "Expiry Date is mandatory.";
        isValid = false;
      }
      if (pollutionExpiresOn == "") {
        pollutionError.pollution = "Expiry Date is mandatory.";
        isValid = false;
      }
      if (insuranceExpiresOn == "") {
        insuranceError.insuranceError = "Expiry Date is mandatory.";
        isValid = false;
      }
      if (rcExpiresOn == "") {
        rcDate.rcError = "Expiry Date is mandatory.";
        isValid = false;
      }
    }

    // }

    // if (gstin.length < 15) {
    //   errorgstin.gsterror = "Invalid GSTIN number.";
    // }

    setErrorPermitNational(errorPermitNational);
    setErrorPermitState(errorPermitState);
    // setErrorRegister(errorRegister);
    setErrorPermitNumber(errorPermitNumber);
    setErrorvehicleNumber(errorvehicleNumber);
    // setErrorStatus(erroStatus);
    setErrorExpiry(errorExpiry);
    setErrorPollutionDate(pollutionError);
    setErrorVehicleModel(errorVehicleModel);
    setErrorOem(errorOem);
    setErrorpermitStateSelect(errorpermitStateSelect);
    setErrorPollution(errorPollutionUpload);
    setErrorRegistrationCertificate(errorRegistrationCertificate);
    setErrorBack(errorBackVehicle);
    setErrorFront(errorFrontVehicle);
    setErrorPermitFile(errorPermitFileUpload);
    SetErrorFreight(errorFreightObject);
    setErrorInsuranceDate(insuranceError);
    setErrorRcDate(rcDate);
    return isValid;
  };
  //++++++++++++++++++++++++++++++

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedstate(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setErrorpermitStateSelect("");
  };

  function getStyles(name, selectedstate, theme) {
    return {
      fontWeight:
        selectedstate.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const styles = {
    hidden: {
      display: "none",
    },
    importLabel: {
      color: "black",
    },
  };
  const [tripAttachmentFile, setTripAttachmentFile] = useState(null);

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const onFileChange = async (event) => {
    const newFile = await saveBlob(event.target.files[0]);
    setPermitFiles(newFile);
    setErrorInsurance({});
  };

  const handleGoBackButton = () => {
    history.goBack();
    dispatch(updateRoutesAddVehicle(""));
  };
  const handleAddDocss = (event) => {
    // setTripAttachmentFile(event.target.files);

    setPermitFiless([...permitFiless, { file: event.target.files[0] }]);
  };

  const handleAddDocs = async (event) => {
    const newFile = await saveBlob(event.target.files[0]);
    setPollution(newFile);
    setErrorPollution({});
  };

  const handleRcInput = (e) => {
    const rcWithoutSpace = e.target.value.replace(/\s+/g, "");
    if (allRegex.rcNumberRegex.test(rcWithoutSpace)) {
      setRcNumberError("");
      setRcDataFromApi({});
      setErrorvehicleNumber({});

      handleVerifyRcNumber(rcWithoutSpace);
    } else {
      setRcNumberError("Invalid RC Number");
    }
    setVehicleNumber(e.target.value);
  };

  const isValidRCNumber = async (
    rc_status,
    fit_upto_date,
    tax_paid_upto_date,
    vehicle_category
  ) => {
    let isRcValid = false;
    if (rc_status.toLowerCase() === "active") isRcValid = true;
    else if (
      moment(tax_paid_upto_date) === "LTT" ||
      moment(tax_paid_upto_date) === "OTT" ||
      moment(tax_paid_upto_date) >= moment()
    ) {
      isRcValid =
        moment(fit_upto_date) >= moment() &&
        (vehicle_category.toLowerCase().includes("hgmv") ||
          vehicle_category.toLowerCase().includes("hmv") ||
          vehicle_category.toLowerCase().includes("mgv") ||
          vehicle_category.toLowerCase().includes("trailer") ||
          vehicle_category.toLowerCase().includes("hgv") ||
          vehicle_category.toLowerCase().includes("lgv"));
    }
    return isRcValid;
  };
  const handleVerifyRcNumber = async (rc) => {
    setLoading(true);
    // setLoading(true);
    try {
      setErrorvehicleNumber({});
      const response = await verifyRc(rc);

      if (response.status === 200) {
        if (
          !!response?.data?.detail?.includes("Unable to obtain information")
        ) {
          setRcDataFetched({
            insuranceExpiresOnFetched: false,
            pollutionExpiresOnFetched: false,
            permitExpiresOnFetched: false,
            rcExpiresOnFetched: false,
            permitNumberFetched: false,
            isNationalPermitFetched: false,
          });
          setRcDataFromApi("");
          setInsuranceExpiresOn("");
          setPollutionExpiresOn("");
          setPermitNumber("");
          setNationalPermit("");
          setExpiresOn("");
          setRcExpiresOn("");
          return;
        }

        if (
          isValidRCNumber(
            response.data.rc_status,
            response.data.fit_up_to,
            response.data.tax_paid_upto,
            response.data.vehicle_category
          )
        ) {
          setRcDataFetched({
            insuranceExpiresOnFetched: response.data.insurance_upto
              ? true
              : false,
            pollutionExpiresOnFetched: response.data.pucc_upto ? true : false,
            permitExpiresOnFetched: response.data.permit_valid_upto
              ? true
              : false,
            rcExpiresOnFetched: response.data.fit_up_to,
            permitNumberFetched: response.data.permit_number ? true : false,
            isNationalPermitFetched: response.data.permit_type
              .toLowerCase()
              .includes("national permit"),
          });
          setRcDataFromApi(response.data);
          setInsuranceExpiresOn(response.data.insurance_upto);
          setPollutionExpiresOn(response.data.pucc_upto);
          setPermitNumber(response.data.permit_number);
          setNationalPermit(
            response.data.permit_type.toLowerCase().includes("national permit")
          );
          setExpiresOn(response.data.permit_valid_upto);
          setRcExpiresOn(response.data.fit_up_to);
        } else if (moment(response.data.fit_up_to) < moment()) {
          setRcDataFetched({
            permitNumberFetched: false,
            insuranceExpiresOnFetched: false,
            pollutionExpiresOnFetched: false,
            rcExpiresOnFetched: false,
            permitExpiresOnFetched: false,
            isNationalPermitFetched: false,
          });
          setRcDataFromApi({});
          setErrorvehicleNumber({
            ...errorvehicleNumber,
            notActive: "Vehicle fitness validity has expired",
          });
        } else if (moment(response.data.tax_paid_upto) < moment()) {
          setRcDataFetched({
            permitNumberFetched: false,
            insuranceExpiresOnFetched: false,
            pollutionExpiresOnFetched: false,
            rcExpiresOnFetched: false,
            permitExpiresOnFetched: false,
            isNationalPermitFetched: false,
          });
          setRcDataFromApi({});
          setErrorvehicleNumber({
            ...errorvehicleNumber,
            notActive: "The tax paid validity has expired",
          });
        } else {
          setRcDataFetched({
            permitNumberFetched: false,
            insuranceExpiresOnFetched: false,
            pollutionExpiresOnFetched: false,
            rcExpiresOnFetched: false,
            permitExpiresOnFetched: false,
            isNationalPermitFetched: false,
          });
          setRcDataFromApi({});
          setErrorvehicleNumber({
            ...errorvehicleNumber,
            notActive: "This RC is not valid",
          });
        }
      }
    } catch (error) {
      if (error.status == 422) {
      } else {
        setErrorvehicleNumber({
          ...errorvehicleNumber,
          notActive: error.data.detail || "Error while fetching RC details",
        });
      }

      setRcDataFetched({
        insuranceExpiresOnFetched: false,
        pollutionExpiresOnFetched: false,
        permitExpiresOnFetched: false,
        rcExpiresOnFetched: false,
        permitNumberFetched: false,
        isNationalPermitFetched: false,
      });
      setRcDataFromApi({});
      setInsuranceExpiresOn("");
      setPollutionExpiresOn("");
      setPermitNumber("");
      setNationalPermit("");
      setExpiresOn("");
      setRcExpiresOn("");
    } finally {
      setLoading(false);
    }
  };
  console.log(rcDataFromApi, "rcDataFromApi");

  // const handleRegistration = (event) => {
  //   setReg(event.target.files[0]);
  const handleRegistration = async (event) => {
    const newFile = await saveBlob(event.target.files[0]);
    setReg(newFile);
    setErrorRegistrationCertificate({});
  };
  const handleFrontPhoto = async (event) => {
    const newFile = await saveBlob(event.target.files[0]);
    setFrontPhoto(newFile);
    setErrorFront({});
  };
  const handleBackPhoto = async (event) => {
    const newFile = await saveBlob(event.target.files[0]);
    setBackPhoto(newFile);
    setErrorBack({});
  };
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const frieghtType = ["First Mile", "Last Mile", "Both"];

  const getVehicleTripDetails = async (n) => {
    const response = await axiosInterceptor.get(
      `${apiUrl.tripV7BaseUrl}?vehicle_number=${n}`
    );

    try {
      if (response.status == 200) {
        setVehicleTripListData(response.data.trip_list);
        //
      } else if (response.status === 401) {
        toast.error("401: Not enough permissions");
      }
    } catch (err) { }
  };

  useEffect(() => {
    if (vehicleNumber !== "" && editMode) {
      getVehicleTripDetails(vehicleNumber);
    }
  }, [vehicleNumber, editMode]);

  return (
    <Component_style className="main_onboard_container">
      <ToastContainer position="bottom-right" />
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={9999999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong
            backButtonLink="/vehicles-onboard"
            buttonText="Close"
          />
        </div>
      </ModalAntd>
      <div className="main_onboard_wrapper">
        {isMobile ? null : (
          <div className="main_onboard_Left_box">
            <SideBarMain />
          </div>
        )}

        <div className="main_onboard_Right_box">
          {isMobile ? (
            <>
              <div
                style={{
                  display: "flex",
                  width: "109%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: colorPalette.primaryColor,
                  padding: ".8rem .5rem",
                }}
              >
                {/* <div className="back_btnDiv"> */}
                {routesAddVehicles == "from_carrier_add_vehicle" ? (
                  <NavLink
                    to={`transporters/details/${vehicleId.carrier}`}
                    className="navLink"
                  >
                    <ArrowBackIcon
                      style={{ color: "white" }}
                    // onClick={handleGoBackButton}
                    />
                    <h5 style={{ color: "white" }}>Back</h5>
                  </NavLink>
                ) : (
                  <NavLink to="/vehicles" className="navLink">
                    <ArrowBackIcon
                      style={{ color: "white" }}
                      onClick={handleGoBackButton}
                    />
                    <h5 style={{ color: "white" }}>Back</h5>
                  </NavLink>
                )}
                {/* </div> */}
                <div style={{ width: "100%", marginLeft: "3.2rem" }}>
                  <h4
                    style={{
                      color: "white",

                      fontSize: "1rem",
                    }}
                  >
                    Vehicle's Details
                  </h4>
                </div>
              </div>
            </>
          ) : null}

          <div className="form_container_vehicle">
            <div className="form_wrapper_vehicle">
              {isMobile ? null : (
                <div className="form_row_vehicle_back">
                  {routesAddVehicles == "from_carrier_add_vehicle" ? (
                    <NavLink
                      to={`/transporters/details/${vehicleId.carrier}`}
                      className="navLink"
                    >
                      <ArrowBackIcon
                        onClick={handleGoBackButton}
                        className="back"
                      />
                      <h5 style={{ color: colorPalette.black }}>Back</h5>
                    </NavLink>
                  ) : (
                    <NavLink to="/vehicles" className="navLink">
                      <ArrowBackIcon
                        className="back"
                        onClick={handleGoBackButton}
                      />
                      <h3 style={{ color: colorPalette.black }}>
                        {" "}
                        Vehicle's Details
                      </h3>
                    </NavLink>
                  )}
                </div>
              )}

              <div className="form_row_vehicle">
                {/* <div>
                  <h4 className="form_label">
                    Registration No.<sup style={{ color: "red" }}>*</sup>
                  </h4>
                  {Object.keys(errorRegister).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>{errorRegister[key]}</h6>
                    );
                  })}
                  <TextField
                    id="outlined-basic"
                    // label="Enter Your Full Name"
                    placeholder="Enter Vehicle Registraion no."
                    variant="outlined"
                    sx={{
                      width: "40ch",
                      height: "10ch",
                    }}
                    color="success"
                    size="small"
                    value={registration}
                    onChange={(e) => {
                      setRegistration(e.target.value);
                    }}
                  />
                </div> */}
                <div>
                  <h4 className="form_label">
                    Vehicle Number
                    <sup style={{ color: "#E06666" }}>*</sup>
                  </h4>

                  {editMode ? (
                    <>
                      {" "}
                      <TextField
                        id="outlined-basic"
                        type="text"
                        placeholder="Enter Your Vehicle Number"
                        variant="outlined"
                        className="input_field_form"
                        color="success"
                        size="small"
                        value={vehicleNumber}
                        onChange={handleRcInput}
                        disabled={vehicleTripListData.length != 0}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {" "}
                      <TextField
                        id="outlined-basic"
                        type="text"
                        placeholder="Enter Your Vehicle Number"
                        variant="outlined"
                        className="input_field_form"
                        color="success"
                        size="small"
                        value={vehicleNumber}
                        onChange={handleRcInput}
                      />
                    </div>
                  )}
                  {Object.keys(errorvehicleNumber).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>
                        {errorvehicleNumber[key]
                          ? errorvehicleNumber[key]
                          : rcNumberError
                            ? rcNumberError
                            : null}
                      </h6>
                    );
                  })}
                </div>
                <div>
                  <h4 className="form_label">
                    Select Permit
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>

                  <TextField
                    id="filled-select-currency"
                    select
                    disabled={
                      nationalPermit && rcDataFetched.isNationalPermitFetched
                    }
                    label="Please Select one"
                    value={nationalPermit}
                    onChange={(e) => {
                      setNationalPermit(e.target.value);
                      setErrorPermitNational("");
                    }}
                    // variant="filled"
                    className="input_field_form"
                    color="success"
                    size="small"
                  >
                    <MenuItem value={true}>National</MenuItem>

                    <MenuItem value={false}>State</MenuItem>
                  </TextField>
                  {Object.keys(errorPermitNational).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>
                        {errorPermitNational[key]}
                      </h6>
                    );
                  })}
                </div>
              </div>
              {nationalPermit == true || nationalPermit.length == 0 ? null : (
                <div className="form_row_vehicle">
                  <div>
                    <h4 className="form_label">
                      Select Permit States
                      <sup style={{ color: "#E06666" }}>*</sup>
                    </h4>

                    <FormControl className="input_field_form">
                      <InputLabel id="demo-multiple-chip-label">
                        State
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        size="small"
                        value={selectedstate}
                        onChange={handleChange}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((value) => (
                              <Chip key={value} label={value.toUpperCase()} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {stateListData.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={selectedstate.indexOf(name) > -1}
                            />
                            <ListItemText primary={name.toUpperCase()} />
                          </MenuItem>
                        ))}
                      </Select>
                      {Object.keys(errorpermitStateSelect).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorpermitStateSelect[key]}
                          </h6>
                        );
                      })}
                    </FormControl>
                    {/* </TextField> */}
                  </div>
                </div>
              )}

              <div className="form_row_vehicle">
                <div>
                  <h4 className="form_label">
                    Freight Type
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>

                  <TextField
                    id="filled-select-currency"
                    select
                    label="Select Freight Type"
                    value={freightType}
                    onChange={(e) => {
                      setFreightType(e.target.value);
                      SetErrorFreight("");
                      // setErrorPermitNational("");
                    }}
                    disabled={vehicleTripListData.length != 0}
                    // variant="filled"
                    className="input_field_form"
                    color="success"
                    size="small"
                  >
                    {freightList.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option.split("_").join(" ").toUpperCase()}
                      </MenuItem>
                    ))}
                  </TextField>
                  {Object.keys(errorFreight).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>{errorFreight[key]}</h6>
                    );
                  })}
                </div>
                <div>
                  <h4 className="form_label">
                    Permit Number<sup style={{ color: "red" }}>*</sup>
                  </h4>

                  <TextField
                    id="outlined-basic"
                    type="email"
                    disabled={permitNumber && rcDataFetched.permitNumberFetched}
                    placeholder="Enter Your Permit No."
                    variant="outlined"
                    className="input_field_form"
                    color="success"
                    size="small"
                    value={permitNumber}
                    onChange={(e) => {
                      setPermitNumber(e.target.value);
                      setErrorPermitNumber("");
                    }}
                  />
                  {Object.keys(errorPermitNumber).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>
                        {errorPermitNumber[key]}
                      </h6>
                    );
                  })}
                </div>
              </div>

              <div className="form_row_vehicle">
                <div className="input_field_form">
                  <h4 className="form_label">
                    Vehicle Manufacturers <sup style={{ color: "red" }}>*</sup>
                  </h4>

                  <ReactSelect
                    style={{ width: "80%" }}
                    className="async_dropdown"
                    onInputChange={(e) => setManufacturerInput(e)}
                    value={
                      oemId
                        ? {
                          value: oemId,
                          label: selectedVehicleManufacturerName,
                        }
                        : ""
                    }
                    onChange={(e) => {
                      setOemId(e.value);
                      setSelectedVehicleManufacturerName(e.label);
                      setErrorOem("");
                    }}
                    options={manufacturerOptions}
                  />

                  {Object.keys(errorOem).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>{errorOem[key]}</h6>
                    );
                  })}
                </div>

                <div className="input_field_form">
                  <h4 className="form_label">
                    Vehicle Type
                    <sup style={{ color: "#E06666" }}>*</sup>
                  </h4>

                  <ReactSelect
                    style={{ width: "80%" }}
                    className="async_dropdown"
                    onInputChange={(e) => setModalInput(e)}
                    value={
                      vehicleModelId
                        ? {
                          value: vehicleModelId,
                          label: selectedVehicleModelName,
                        }
                        : ""
                    }
                    onChange={(e) => {
                      setVehicleModelId(e.value);
                      setSelectedVehicleModelName(e.label);

                      setErrorVehicleModel("");
                    }}
                    options={modalOptions}
                  />

                  {Object.keys(errorVehicleModel).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>
                        {errorVehicleModel[key]}
                      </h6>
                    );
                  })}
                </div>
              </div>

              {editMode ? (
                <div className="form_row_vehicle">
                  <div className="input_field_form">
                    <h4 className="form_label">
                      Status<sup style={{ color: "red" }}>*</sup>
                    </h4>
                    {Object.keys(erroStatus).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>{erroStatus[key]}</h6>
                      );
                    })}
                    <TextField
                      id="filled-select-currency"
                      select
                      // label="Please Select status"
                      className="input_field_form"
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      disabled={true}
                      style={{ width: "100%" }}
                      color="success"
                      size="small"
                    >
                      {statusList.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option.split("_").join(" ").toUpperCase()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              ) : null}

              {editMode ? null : (
                <>
                  {" "}
                  <h4 className="form_label" id="docs_upload">
                    Please upload the following attachments:
                  </h4>
                  <div className="form_row_vehicle">
                    <div className="mobile_documents">
                      {/* <h4 className="form_label">
                        Insurance Certificate{" "}
                        <sup style={{ color: "red" }}>*</sup>
                      </h4> */}
                      {/* <div className="upload_permit"> */}
                      <TextField
                        size="small"
                        id="dropdown"
                        disabled
                        // style={{ width: "14rem" }}
                        // select
                        value={rcDataFromApi.insurance_company || ""}
                        autoComplete="off"
                        className="vehicle-input-attachment dropdown-container "
                        variant="outlined"
                        placeholder="Insurance"
                        name="attachment_tag"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button className="upload_button">
                                <input
                                  // name="upload"
                                  name="attachment_file"
                                  id="insurance"
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={onFileChange}
                                  hidden
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label
                                  htmlFor="insurance"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                >
                                  <img
                                    src={UploadImage}
                                    alt=""
                                    width="20px"
                                    height="20px"
                                    style={{
                                      backgroundColor:
                                        colorPalette.secondaryWhite,
                                    }}
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                      <span
                        style={{
                          fontSize: ".8rem",
                          display: "block",
                        }}
                      >
                        {permitFiles ? permitFiles.name : null}
                      </span>
                      {Object.keys(errorInsurance).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorInsurance[key]}
                          </h6>
                        );
                      })}
                      {permitFiles ? (
                        permitFiles.size >
                          process.env.REACT_APP_MAX_FILE_SIZE ? (
                          <h6
                            style={{ color: "#E06666", marginBottom: ".8rem" }}
                          >
                            Please upload file size less than {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
                          </h6>
                        ) : null
                      ) : null}

                      {/* <input
                        type="file"
                        accept="image/png, image/jpeg, application/pdf"
                        onChange={onFileChange}
                      />
                      {Object.keys(errorInsurance).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorInsurance[key]}
                          </h6>
                        );
                      })} */}
                    </div>
                    <div className="mobile_documents">
                      <TextField
                        size="small"
                        id="dropdown"
                        disabled
                        // style={{ width: "14rem" }}
                        // select
                        autoComplete="off"
                        className="vehicle-input-attachment dropdown-container"
                        variant="outlined"
                        placeholder="Pollution"
                        name="attachment_tag"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button className="upload_button">
                                <input
                                  // name="upload"
                                  name="attachment_file"
                                  id="pollution"
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={handleAddDocs}
                                  hidden
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label
                                  htmlFor="pollution"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                >
                                  <img
                                    src={UploadImage}
                                    alt=""
                                    width="20px"
                                    height="20px"
                                    style={{
                                      backgroundColor:
                                        colorPalette.secondaryWhite,
                                    }}
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                      <span style={{ fontSize: ".8rem", display: "block" }}>
                        {pollution ? pollution.name : null}
                      </span>
                      {Object.keys(errorPollution).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorPollution[key]}
                          </h6>
                        );
                      })}
                      {pollution ? (
                        pollution.size > process.env.REACT_APP_MAX_FILE_SIZE ? (
                          <h6
                            style={{ color: "#E06666", marginBottom: ".8rem" }}
                          >
                            Please upload file size less than {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
                          </h6>
                        ) : null
                      ) : null}
                    </div>
                    <div className="mobile_documents">
                      <TextField
                        size="small"
                        id="dropdown"
                        disabled
                        autoComplete="off"
                        className="vehicle-input-attachment dropdown-container"
                        variant="outlined"
                        placeholder="RC"
                        name="attachment_tag"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button className="upload_button">
                                <input
                                  // name="upload"
                                  name="attachment_file"
                                  id="rc"
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={handleRegistration}
                                  hidden
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label
                                  htmlFor="rc"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                >
                                  <img
                                    src={UploadImage}
                                    alt=""
                                    width="20px"
                                    height="20px"
                                    style={{
                                      backgroundColor:
                                        colorPalette.secondaryWhite,
                                    }}
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                      <span
                        style={{
                          fontSize: ".8rem",
                          display: "block",
                        }}
                      >
                        {reg ? reg.name : null}
                      </span>
                      {Object.keys(errorRegistrationCertificate).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorRegistrationCertificate[key]}
                          </h6>
                        );
                      })}
                      {reg ? (
                        reg.size > process.env.REACT_APP_MAX_FILE_SIZE ? (
                          <h6
                            style={{ color: "#E06666", marginBottom: ".8rem" }}
                          >
                            Please upload file size less than {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
                          </h6>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                  <div className="form_row_pic">
                    <div className="mobile_documents">
                      <h4 className="form_label">
                        Insurance Expires On
                        <sup style={{ color: "#E06666" }}>*</sup>
                      </h4>

                      <TextField
                        id="outlined-basic"
                        type="date"
                        // disabled={
                        //   rcDataFetched.insuranceExpiresOnFetched &&
                        //   insuranceExpiresOn
                        // }
                        placeholder="Enter Your Mobile Number"
                        variant="outlined"
                        className="vehicle-input-attachment dropdown-container "
                        // className="input_field_form"
                        color="success"
                        size="small"
                        value={insuranceExpiresOn}
                        onChange={(e) => {
                          setInsuranceExpiresOn(e.target.value);
                        }}
                        inputProps={{
                          min: disablePastDate(),
                          max: "2100-01-01",
                        }}
                      // min={disablePastDate()}
                      // onInput={(e) => {
                      //   e.target.value = Math.max(0, parseInt(e.target.value))
                      //     .toString()
                      //     .slice(0, 10);
                      // }}
                      />
                      {Object.keys(errorInsuranceDate).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorInsuranceDate[key]}
                          </h6>
                        );
                      })}
                    </div>
                    <div className="mobile_documents">
                      <h4 className="form_label">
                        Pollution Expires On
                        <sup style={{ color: "#E06666" }}>*</sup>
                      </h4>

                      <TextField
                        id="outlined-basic"
                        type="date"
                        placeholder="Enter Your Mobile Number"
                        // disabled={
                        //   rcDataFetched.pollutionExpiresOnFetched &&
                        //   pollutionExpiresOn
                        // }
                        variant="outlined"
                        className="vehicle-input-attachment dropdown-container "
                        // className="input_field_form"
                        color="success"
                        size="small"
                        value={pollutionExpiresOn}
                        onChange={(e) => {
                          setPollutionExpiresOn(e.target.value);
                          // setErrorPollutionDate({});
                          // setErrorPollutionDate("");
                        }}
                        inputProps={{
                          min: disablePastDate(),
                          max: "2100-01-01",
                        }}
                      // min={disablePastDate()}
                      // onInput={(e) => {
                      //   e.target.value = Math.max(0, parseInt(e.target.value))
                      //     .toString()
                      //     .slice(0, 10);
                      // }}
                      />
                      {Object.keys(errorPollutionDate).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorPollutionDate[key]}
                          </h6>
                        );
                      })}
                      {/* {Object.keys(errorExpiry).map((key) => {
                          return (
                            <h6 style={{ color: "#E06666" }}>
                              {errorExpiry[key]}
                            </h6>
                          );
                        })} */}
                    </div>
                    <div className="mobile_documents">
                      <h4 className="form_label">
                        RC Expires On
                        <sup style={{ color: "#E06666" }}>*</sup>
                      </h4>

                      <TextField
                        id="outlined-basic"
                        type="date"
                        placeholder="Enter Your Mobile Number"
                        variant="outlined"
                        disabled={
                          rcDataFetched.rcExpiresOnFetched && rcExpiresOn
                        }
                        className="vehicle-input-attachment dropdown-container "
                        // className="input_field_form"
                        color="success"
                        size="small"
                        value={rcExpiresOn}
                        onChange={(e) => {
                          setRcExpiresOn(e.target.value);
                          // setErrorExpiry("");
                        }}
                        inputProps={{
                          min: disablePastDate(),
                          max: "2100-01-01",
                        }}
                      // min={disablePastDate()}
                      // onInput={(e) => {
                      //   e.target.value = Math.max(0, parseInt(e.target.value))
                      //     .toString()
                      //     .slice(0, 10);
                      // }}
                      />
                      {Object.keys(errorRcDate).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorRcDate[key]}
                          </h6>
                        );
                      })}
                    </div>
                  </div>
                  <div className="form_row_vehicle">
                    {/* <div className="photo_back"> */}
                    <div className="mobile_documents">
                      <TextField
                        size="small"
                        id="dropdown"
                        disabled
                        // style={{ width: "14rem" }}
                        // select
                        autoComplete="off"
                        className="vehicle-input-attachment dropdown-container"
                        variant="outlined"
                        placeholder="Permit Certificate"
                        name="attachment_tag"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button className="upload_button">
                                <input
                                  // name="upload"
                                  name="attachment_file"
                                  id="permit"
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={addPermitFile}
                                  hidden
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label
                                  htmlFor="permit"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                >
                                  <img
                                    src={UploadImage}
                                    alt=""
                                    width="20px"
                                    height="20px"
                                    style={{
                                      backgroundColor:
                                        colorPalette.secondaryWhite,
                                    }}
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>

                      <span style={{ fontSize: ".8rem", display: "block" }}>
                        {permitsFilesUpload ? permitsFilesUpload.name : null}
                      </span>
                      {Object.keys(errorPermitFile).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorPermitFile[key]}
                          </h6>
                        );
                      })}
                      {permitsFilesUpload &&
                        permitsFilesUpload.size >
                        process.env.REACT_APP_MAX_FILE_SIZE ? (
                        <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                          Please upload file size less than {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
                        </h6>
                      ) : null}

                      {/* <h4 className="form_label">
                        Vehicle's Back Photo{" "}
                        <sup style={{ color: "red" }}>*</sup>
                      </h4>
               

                      <input
                        type="file"
                        accept="image/png, image/jpeg, application/pdf"
                        onChange={handleBackPhoto}
                      />
                      {Object.keys(errorBack).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>{errorBack[key]}</h6>
                        );
                      })} */}
                      {/* <button>Upload</button> */}
                      {/* </div> */}
                    </div>
                    <div className="mobile_documents">
                      <TextField
                        size="small"
                        id="dropdown"
                        disabled
                        // style={{ width: "14rem" }}
                        // select
                        autoComplete="off"
                        className="vehicle-input-attachment dropdown-container"
                        variant="outlined"
                        placeholder="Vehicle Front Photo"
                        name="attachment_tag"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button className="upload_button">
                                <input
                                  // name="upload"
                                  name="attachment_file"
                                  id="vehicle_front"
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={handleFrontPhoto}
                                  hidden
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label
                                  htmlFor="vehicle_front"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                >
                                  <img
                                    src={UploadImage}
                                    alt=""
                                    width="20px"
                                    height="20px"
                                    style={{
                                      backgroundColor:
                                        colorPalette.secondaryWhite,
                                    }}
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                      <span style={{ fontSize: ".8rem", display: "block" }}>
                        {frontPhoto ? frontPhoto.name : null}
                      </span>
                      {Object.keys(errorFront).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorFront[key]}
                          </h6>
                        );
                      })}
                      {frontPhoto ? (
                        frontPhoto.size >
                          process.env.REACT_APP_MAX_FILE_SIZE ? (
                          <h6
                            style={{ color: "#E06666", marginBottom: ".8rem" }}
                          >
                            Please upload file size less than {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
                          </h6>
                        ) : null
                      ) : null}
                    </div>
                    {/* <div className="photo_back"> */}
                    <div className="mobile_documents">
                      <TextField
                        size="small"
                        id="dropdown"
                        disabled
                        // style={{ width: "14rem" }}
                        // select
                        autoComplete="off"
                        className="vehicle-input-attachment dropdown-container"
                        variant="outlined"
                        placeholder="Vehicle Back Photo"
                        name="attachment_tag"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button className="upload_button">
                                <input
                                  // name="upload"
                                  name="attachment_file"
                                  id="vehicle_back"
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={handleBackPhoto}
                                  hidden
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label
                                  htmlFor="vehicle_back"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                >
                                  <img
                                    src={UploadImage}
                                    alt=""
                                    width="20px"
                                    height="20px"
                                    style={{
                                      backgroundColor:
                                        colorPalette.secondaryWhite,
                                    }}
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                      <span style={{ fontSize: ".8rem", display: "block" }}>
                        {backPhoto ? backPhoto.name : null}
                      </span>
                      {Object.keys(errorBack).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>{errorBack[key]}</h6>
                        );
                      })}
                      {backPhoto ? (
                        backPhoto.size > process.env.REACT_APP_MAX_FILE_SIZE ? (
                          <h6
                            style={{ color: "#E06666", marginBottom: ".8rem" }}
                          >
                            Please upload file size less than {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
                          </h6>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                  <div className="form_row_vehicle">
                    <div className="mobile_documents">
                      <h4 className="form_label">
                        Permit Expires On
                        <sup style={{ color: "#E06666" }}>*</sup>
                      </h4>

                      <TextField
                        id="outlined-basic"
                        type="date"
                        placeholder="Enter Your Mobile Number"
                        disabled={expiresOn && rcDataFetched.rcExpiresOnFetched}
                        variant="outlined"
                        className="vehicle-input-attachment dropdown-container "
                        color="success"
                        size="small"
                        value={expiresOn}
                        onChange={(e) => {
                          setExpiresOn(e.target.value);
                          setErrorExpiry("");
                        }}
                        inputProps={{
                          min: disablePastDate(),
                          max: "2100-01-01",
                        }}
                      />
                      {Object.keys(errorExpiry).map((key) => {
                        return (
                          <h6 style={{ color: "#E06666" }}>
                            {errorExpiry[key]}
                          </h6>
                        );
                      })}
                    </div>
                    <div className="mobile_documents"></div>
                    <div className="mobile_documents"></div>
                  </div>
                </>
              )}

              <div className="form_row_vehicle">
                {/* {editMode ? (
                  <>
                    {" "}
                    <div>
                      <h4 className="form_label">
                        Active<sup style={{ color: "red" }}>*</sup>
                      </h4>
                      
                      <TextField
                        id="filled-select-currency"
                        select
                        label="Please Select active state"
                     
                        sx={{
                          width: "40ch",
                          height: "8ch",
                        }}
                        color="success"
                        size="small"
                      >
                        <MenuItem value={true}>TRUE</MenuItem>
                        <MenuItem value={false}>FALSE</MenuItem>
                      </TextField>
                    </div>
                  </>
                ) : null} */}
                {dataExistMsg ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Alert severity="error">{dataExistMsg}</Alert>
                  </div>
                ) : null}
                {/* <div>
                  <h4 className="form_label">
                    Permit Number<sup style={{ color: "red" }}>*</sup>
                  </h4>
                  {Object.keys(errorPermitNumber).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>
                        {errorPermitNumber[key]}
                      </h6>
                    );
                  })}
                  <TextField
                    id="outlined-basic"
                    type="email"
                    placeholder="Enter Your Permit No."
                    variant="outlined"
                    sx={{
                      width: "40ch",
                      height: "10ch",
                    }}
                    color="success"
                    size="small"
                    value={permitNumber}
                    onChange={(e) => {
                      setPermitNumber(e.target.value);
                    }}
                  />
                </div> */}
              </div>
              {/* <div id="button"> */}
              {(permitFiles
                ? permitFiles.size < process.env.REACT_APP_MAX_FILE_SIZE
                : null) &&
                (backPhoto
                  ? backPhoto.size < process.env.REACT_APP_MAX_FILE_SIZE
                  : null) &&
                (frontPhoto
                  ? frontPhoto.size < process.env.REACT_APP_MAX_FILE_SIZE
                  : null) &&
                // permitsFilesUpload.size < 1000000 ||
                (reg ? reg.size < process.env.REACT_APP_MAX_FILE_SIZE : null) &&
                (pollution
                  ? pollution.size < process.env.REACT_APP_MAX_FILE_SIZE
                  : null) &&
                (permitFiles
                  ? permitFiles.size < process.env.REACT_APP_MAX_FILE_SIZE
                  : null) &&
                insuranceExpiresOn &&
                pollutionExpiresOn &&
                rcExpiresOn &&
                expiresOn &&
                nationalPermit !== "" ? (
                <div className="btn_div">
                  <button
                    className="btn"
                    style={{
                      padding: "1.5rem 0rem",
                      width: "50%",
                    }}
                    onClick={handleSubmitDetails}
                  >
                    {circularProgress ? (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                </div>
              ) : editMode == true ? (
                <div className="btn_div">
                  <button
                    className="btn"
                    style={{
                      padding: "1.5rem 0rem",
                      width: "50%",
                    }}
                    onClick={handleSubmitDetails}
                  >
                    {circularProgress ? (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                </div>
              ) : (
                <div className="btn_div">
                  <button
                    className="btn"
                    style={{
                      padding: "1.5rem 0rem",
                      width: "50%",
                      backgroundColor: "#B2D8B2",
                    }}
                    disabled
                    onClick={handleSubmitDetails}
                  >
                    {circularProgress ? (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    ) : (
                      "SUBMIT"
                    )}
                  </button>
                </div>
              )}

              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </Component_style>
  );
}

export default VehiclesDetails;
