import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import "./quotationPage.js";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReportIcon from "@mui/icons-material/Report";
import HistoryIcon from "@mui/icons-material/History";
import UploadImage from "../../assets/upload.svg";
import { Alert, CircularProgress, Modal } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SEARCH_GRAY from "../../assets/search_gray.svg";
import FILTER_GRAY from "../../assets/filter_gray.svg";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import { Close, DarkMode } from "@mui/icons-material";
import {
  createQuoteApi,
  getQuoteById,
  getQuoteHistory,
  getQuoteList,
  updateQuoteStatusApi,
  updateQuoteStatusSentApi,
} from "./quotationServices";
import { ModalUnstyled } from "@mui/base";
import { styled } from "@mui/system";

import { Drawer, InputAdornment, TextField, Box } from "@mui/material";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import CreateQuoteDrawer from "../../components/createQuoteDrawer/CreateQuoteDrawer";
import CopyText from "../../components/copyText/CopyText";
// import { DarkModeContext } from "../../context/darkModeContext.js";
import {
  QuotationDrawer,
  QuotationHistory,
  Quotation_Page_Style,
} from "./quotationPage.js";
import {
  IndentSidebarStyle,
  Indent_drawer_container_style,
} from "../IndentsPage/indentPage";
import { colorPalette, colorPaletteDark } from "../../themes/theme";
import { Mini_detais_Card_style } from "../../components/miniDetailsCard/mini-details-card";
import {
  ButtonRectangle,
  ButtonRectangleSecondary,
} from "../../themes/components/button";
import Topbar from "../../components/topbar/Topbar.jsx";
import { DarkModeContext } from "../../context/darkModeContext.js";

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

const tripRoutesBoxStyle = {
  width: isMobile ? "90vw" : "40%",
  height: isMobile ? "auto" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -24,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};
const styleQuotationBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: `${colorPalette.white}`,
  borderRadius: "10px",
  // border: "1px solid #fff",
  boxShadow: 24,
  p: 0,
};
const exportBoxStyle = {
  width: isMobile ? "85%" : "70%",
  height: isMobile ? "80vh" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  // marginTop: isMobile ? -10 : -20,
  border: "1px solid gray",
  borderRadius: "10px",
};

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${isMobile ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.5)`};
  -webkit-tap-highlight-color: transparent;
`;

function QuotationPage() {
  const { toggle, darkMode } = useContext(DarkModeContext);
   
  const [quoteList, setQuoteList] = useState([]);
  const [selectedQuotaion, setSelectedQuotaion] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentTab, setCurrentTab] = useState("draft");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [remarksReject, setRemarksReject] = useState("");
  const [selectedQuoteId, setSelectedQuoteId] = useState("");
  const [addQuoteDrawerOpen, setAddQuoteDrawerOpen] = useState(false);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
  const [quoteApproveDrawerOpen, setQuoteApproveDrawerOpen] = useState(false);
  const [unauthorisedMsg, setUnauthorisedMsg] = useState("");
  const [remarksSent, setRemarksSent] = useState("");
  // const [quoteAmount, setQuoteAmount] = useState("");
  // const [advanceAmount, setAdvanceAmount] = useState("");
  const [remarksAddQuote, setRemarksAddQuote] = useState("");
  // const [quoteImage, setQuoteImage] = useState(null);
  // const [loadingSaveQuote, setLoadingSaveQuote] = useState(false);
  const [loadingStatusButton, setLoadingStatusButton] = useState(false);
  const [addQuoteSuccessToast, setAddQuoteSuccessToast] = useState(false);
  const [addQuoteErrorToast, setAddQuoteErrorToast] = useState(false);
  const [quoteToastError, setQuoteToastError] = useState("");
  const [loadingPage, setLoadingPage] = useState(true);
  const [openTripRoutes, setOpenTripRoutes] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState({});
  const [indentSuccessToast, setIndentSuccessToast] = useState("");
  const [drawerSentOpen, setDrawerSentOpen] = useState(false);
  const [quoationHistoryList, setQuoationHistoryList] = useState([]);

  const handleCloseToast = () => {
    setQuoteToastError("");
    setAddQuoteSuccessToast(false);
    setAddQuoteErrorToast(false);
  };

  const handleCloseTripRoute = () => {
    setOpenTripRoutes(false);
    setRemarksAddQuote("");
  };

  const handleOpenRoutes = (quote) => {
    setSelectedQuote(quote);
    setOpenTripRoutes(true);
  };

  const vertical = "bottom";
  const horizontal = "right";

  const handleOpenApprove = (quoteId) => {
    setSelectedQuoteId(quoteId);
    setQuoteApproveDrawerOpen(true);
    setRemarksAddQuote("");
  };

  const handleOpenSent = (quoteId) => {
    //
    setSelectedQuoteId(quoteId);
    setDrawerSentOpen(true);
  };

  const handleOpenAddQuote = (quotationCode) => {
    // e.stopPropagation();
    setAddQuoteDrawerOpen(true);
    getQuotesById(quotationCode);
    // e.stopPropagation();
  };

  const handleCloseDrawer = () => {
    // setDrawerOpen(false);
    // setDrawerOpenFulfill(false);
    setAddQuoteDrawerOpen(false);
    // resetForm();
    // seteErrorQuotAmount("");
    // setErrorAdvance("");
    // setErrorAttachments("");
  };

  // const handleCreateQuote = async () => {
  //   setQuoteToastError("");
  //   setLoadingSaveQuote(true);
  //   const response = await createQuoteApi(
  //     selectedQuoteId,
  //     quoteAmount,
  //     advanceAmount,
  //     remarksAddQuote,
  //     "something",
  //     quoteImage
  //   );
  //   setLoadingSaveQuote(false);
  //   if (response.status === 200) {
  //     setAddQuoteDrawerOpen(false);
  //     setAddQuoteSuccessToast(true);
  //   } else if (response.status === 400) {
  //     setQuoteToastError(response.data.detail);
  //   } else {
  //     setQuoteToastError(`${response.status}`);
  //   }
  // };

  const handleSubmitStatus = async (quoteId, status, remarks) => {
    setLoadingStatusButton(true);
    const response = await updateQuoteStatusApi(quoteId, status, remarks);
    // setLoadingSaveQuote(false);
    if (response.status === 200) {
      setIndentSuccessToast(`Quotation is successfully ${status}!`);

      setQuoteApproveDrawerOpen(false);
      setAddQuoteSuccessToast(true);
      handleGetQuotesList(1, currentTab);
      setCurrentPage(1);
      setLoadingStatusButton(false);
      setRemarksAddQuote("");
      setDrawerOpen(false);
    } else if (response.status === 400) {
      setQuoteToastError(response.data.detail);
      setLoadingStatusButton(false);
    } else {
      setQuoteToastError(`${response.status}`);
      setLoadingStatusButton(false);
    }
  };

  const handleSubmitSentStatus = async (quoteId, status, remarks) => {
    setLoadingStatusButton(true);
    const response = await updateQuoteStatusSentApi(quoteId, status, remarks);
    // setLoadingSaveQuote(false);
    if (response.status === 200) {
      setIndentSuccessToast(`Quotation is successfully ${status}!`);
      setDrawerSentOpen(false);
      setAddQuoteSuccessToast(true);
      handleGetQuotesList(1, currentTab);
      setCurrentPage(1);
      setLoadingStatusButton(false);
      setRemarksSent("");
      setRemarksReject("");
    } else if (response.status === 400) {
      setQuoteToastError(response.data.detail);
      setLoadingStatusButton(false);
    } else {
      setQuoteToastError(`${response.status}`);
      setLoadingStatusButton(false);
    }
  };

  const handleChangeTab = (tab) => {
    if (!loadingPage) {
      setCurrentPage(1);
      setQuoteList([]);
      setLoadingPage(true);
      setCurrentTab(tab);
    }
  };

  const handleOpenRemarks = (quoteId) => {
    setDrawerOpen(true);
    setSelectedQuoteId(quoteId);
    setRemarksReject("");
    setRemarksAddQuote("");
  };

  const handleGetQuotesList = async (currentPage, status) => {
    if (currentPage == 1) {
      setLoadingPage(true);
    }
    setLoadingMore(true);
    const response = await getQuoteList(currentPage, status);
    if (currentPage > 1) {
      setQuoteList([...quoteList, ...response?.data?.quotation_list]);
      setIsLastPage(response?.data?.is_last_page);
      setLoadingPage(false);
      setLoadingMore(false);
      setUnauthorisedMsg("");
    } else {
      if (response?.status === 200) {
        setQuoteList(response?.data?.quotation_list);
        setSelectedQuotaion(response?.data.quotation_list[0]);
        setIsLastPage(response?.data?.is_last_page);
        setLoadingPage(false);
        setLoadingMore(false);
        setUnauthorisedMsg("");
      }
      if (response?.status === 401) {
        setUnauthorisedMsg(response.data.error);
        setLoadingPage(false);
      }
    }
  };

  useEffect(() => {
    handleGetQuotesList(currentPage, currentTab);
  }, [currentPage, currentTab]);

  // pagination code

  const observer = useRef();
  const lastQuote = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage(currentPage + 1);
          setLoadingMore(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage, currentPage]
  );
  const [quotationDataById, setQuotationDataById] = useState({});
  const getQuotesById = async (id) => {
    const response = await getQuoteById(id);
    if (response.status === 200) {
      setQuotationDataById(response.data);
    }
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  const quotationHistory = async (id) => {
    // total_quotation_list
    const response = await getQuoteHistory(id);
    if (response.status === 200) {
      setQuoationHistoryList(response.data.total_quotation_list);
    }
    //
  };

  const handleOpenHistory = (quote) => {
    // setSelectedIndentId(indentId);
    // e.preventDefault();
    // e.stopPropagation();
    // set;
    quotationHistory(quote.quotations_id);
    setHistoryDrawerOpen(true);
  };
  const handleCloseHistoryDrawer = () => {
    setHistoryDrawerOpen(false);
  };
  // const { toggle, darkMode } = useContext(DarkModeContext);
  return (
    <Quotation_Page_Style mode={darkMode}>
      {/* <div className="quote-sidebar">
        <SideBarMain />{" "}
      </div> */}

      <div className="quotes-wrapper">
        {isMobile ? null : (
          <div className="quote-sidebar">
            <SideBarMain />{" "}
          </div>
        )}
        <div className="quote-right-container">
          {isMobile ? null : <Topbar />}
          {isMobile ? (
            <div className="trip-list-mobile-header">
              <MobileHeader headerTitle="Quotations" />
            </div>
          ) : (
            <span
              className="indent-head-text"
              style={{ marginLeft: "2rem", padding: ".4rem 0rem" }}
            >
              {" "}
              Quotations
            </span>
          )}
          <div className="quote-head-container">
            {/* <div className="quote-head-row">
            <span className="quote-head-text"> Quotations</span>{" "}
          </div> */}

            <div className="quote-tab-menuOption-contain">
              <div
                className={"quote-tab-menu-option"}
                style={
                  currentTab === "draft"
                    ? {
                        color: `${colorPalette.primaryColor}`,
                        fontWeight: "600",
                      }
                    : null
                }
                onClick={() => handleChangeTab("draft")}
              >
                Draft
                {currentTab === "draft" ? (
                  <div className="quote-tab-bottom-rectangle"></div>
                ) : null}
              </div>
              <div
                className="quote-tab-menu-option"
                style={
                  currentTab === "approved"
                    ? {
                        color: `${colorPalette.primaryColor}`,
                        fontWeight: "600",
                      }
                    : null
                }
                onClick={() => handleChangeTab("approved")}
              >
                Approved
                {currentTab === "approved" ? (
                  <div className="quote-tab-bottom-rectangle"></div>
                ) : null}
              </div>
              <div
                className="quote-tab-menu-option"
                style={
                  currentTab === "rejected"
                    ? {
                        color: `${colorPalette.primaryColor}`,
                        fontWeight: "600",
                      }
                    : null
                }
                onClick={() => handleChangeTab("rejected")}
              >
                Rejected
                {currentTab === "rejected" ? (
                  <div className="quote-tab-bottom-rectangle"></div>
                ) : null}
              </div>
              <div
                className="quote-tab-menu-option"
                style={
                  currentTab === "sent"
                    ? {
                        color: `${colorPalette.primaryColor}`,
                        fontWeight: "600",
                      }
                    : null
                }
                onClick={() => handleChangeTab("sent")}
              >
                Sent
                {currentTab === "sent" ? (
                  <div className="quote-tab-bottom-rectangle"></div>
                ) : null}
              </div>
              <div
                className="quote-tab-menu-option"
                style={
                  currentTab === "accepted"
                    ? {
                        color: `${colorPalette.primaryColor}`,
                        fontWeight: "600",
                      }
                    : null
                }
                onClick={() => handleChangeTab("accepted")}
              >
                Accepted
                {currentTab === "accepted" ? (
                  <div className="quote-tab-bottom-rectangle"></div>
                ) : null}
              </div>
              <div
                className="quote-tab-menu-option"
                style={
                  currentTab === "denied"
                    ? {
                        color: `${colorPalette.primaryColor}`,
                        fontWeight: "600",
                      }
                    : null
                }
                onClick={() => handleChangeTab("denied")}
              >
                Denied
                {currentTab === "denied" ? (
                  <div className="quote-tab-bottom-rectangle"></div>
                ) : null}
              </div>
            </div>
          </div>
          <div></div>

          {/* search bar  */}

          {/* <div className="quote-search-row">
          <div className="quote-search-container">
            <img
              src={SEARCH_GRAY}
              alt="search"
              style={{ marginRight: "5px" }}
            />
            <input placeholder="search" className="quote-search-input" />
            <img src={FILTER_GRAY} alt="filter" />
          </div>
        </div> */}

          {/* search bar  */}

          {loadingPage ? (
            <div
              style={{
                width: "100%",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : unauthorisedMsg ? (
            <div
              style={{
                width: "100%",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
              }}
            >
              <span
                style={{
                  // border: "1px solid red",
                  padding: "1rem 3rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <ReportIcon /> {unauthorisedMsg}
              </span>
            </div>
          ) : quoteList?.length === 0 ? (
            <div
              style={{
                width: "100%",
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: darkMode ? colorPaletteDark.white : colorPalette.black,
              }}
            >
              No quote found
            </div>
          ) : (
            <div className="quote-list-container">
              <div className="quote-grid-main-container">
                {quoteList.map((quote) => {
                  return (
                    <div
                      className={
                        selectedQuotaion.quotations_id === quote.quotations_id
                          ? "quote-single-item-container indent-selected"
                          : "quote-single-item-container"
                      }
                      // className="quote-single-item-container"
                      onClick={() => {
                        setSelectedQuotaion(quote);
                        // handleOpenHistory(quote);
                      }}
                      ref={lastQuote}
                    >
                      <div className="quote-single-top-row">
                        <div className="quote-single-top-left">
                          <div className="quote-number-text">
                            <CopyText
                              text={
                                quote?.quotation_code
                                  ? quote?.quotation_code
                                  : " - "
                              }
                            />
                          </div>
                          <div className="quote-customer-text">
                            Indent code :{" "}
                            {quote?.indent_code ? quote?.indent_code : " - "}
                          </div>
                          <div className="quote-customer-text">
                            <PersonOutlineOutlinedIcon
                              style={{
                                color: "rgba(87, 87, 87, 1)",
                                fontSize: "15px",
                              }}
                            />{" "}
                            <span className="quote-customer-namee">
                              {quote?.customer_name
                                ? quote?.customer_name
                                : " - "}
                            </span>
                          </div>
                        </div>
                        <div className="quote-single-top-right">
                          <div className="quote-date-text">
                            {quote?.quotation_created_on
                              ? moment
                                  .utc(quote?.quotation_created_on)
                                  .local()
                                  .format("DD MMM YYYY hh:mm a")
                              : " - "}
                          </div>
                        </div>
                      </div>
                      <div className="quote-single-middle-row">
                        <div className="quote-single-middle-in">
                          <div className="quote-slim-text">Invoice value</div>
                          <div className="quote-bold-text">
                            Rs.{" "}
                            {quote?.value_of_goods
                              ? quote?.value_of_goods
                              : " - "}
                          </div>
                        </div>
                        <div className="quote-single-middle-in">
                          <div className="quote-slim-text">Goods qty</div>
                          <div className="quote-bold-text">
                            {quote?.quantity_of_goods
                              ? quote?.quantity_of_goods
                              : " - "}{" "}
                            Ton
                          </div>
                        </div>
                        <div className="quote-single-middle-in">
                          <div className="quote-slim-text">Vehicle type</div>
                          <div className="quote-bold-text">
                            {quote?.vehicle_type
                              ? quote?.vehicle_type
                                  .replace(/_/g, " ")
                                  .toUpperCase()
                              : " - "}
                          </div>
                        </div>

                        <div className="quote-single-middle-in">
                          <div className="quote-slim-text">
                            Type of material
                          </div>
                          <div className="quote-bold-text">
                            {" "}
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                textoverflow: "ellipsis",
                                whitespace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {quote?.material_type
                                ? quote?.material_type
                                : " - "}
                            </div>
                          </div>
                        </div>

                        <div className="quote-single-middle-in">
                          <div className="quote-slim-text">Quote amount</div>
                          <div className="quote-bold-text">
                            Rs.{" "}
                            {quote?.quotation_amount
                              ? quote?.quotation_amount
                              : " - "}
                          </div>
                        </div>
                      </div>
                      <div className="quote-single-last-row">
                        <div className="quote-single-middle-in">
                          <div className="quote-slim-text">Remark</div>
                          <div className="quote-bold-text">
                            <h6 className="remarks">
                              {quote?.remarks
                                ? quote?.remarks
                                  ? quote?.remarks
                                  : ""
                                : "--"}
                            </h6>
                          </div>
                        </div>
                        {currentTab === "draft" ? (
                          <div className="quote-single-middle-in-created-by">
                            <div className="quote-slim-text">Created by</div>
                            <div className="quote-bold-text">
                              {" "}
                              {quote?.quotation_modified_by
                                ? quote?.quotation_modified_by
                                : " - "}
                            </div>
                          </div>
                        ) : currentTab === "approved" ? (
                          <>
                            {/* <div className="quote-single-middle-in">
                            <div className="quote-slim-text">Created by</div>
                            <div className="quote-bold-text">
                              {" "}
                              {quote?.quotation_created_by
                                ? quote?.quotation_created_by
                                : " - "}
                            </div>
                          </div> */}
                            <div className="quote-single-middle-in-created-by">
                              <div className="quote-slim-text">Approved by</div>
                              <div className="quote-bold-text">
                                {" "}
                                {quote?.quotation_modified_by
                                  ? quote?.quotation_modified_by
                                  : " - "}
                              </div>
                            </div>
                          </>
                        ) : currentTab === "rejected" ? (
                          <>
                            {/* <div className="quote-single-middle-in">
                            <div className="quote-slim-text">Created by</div>
                            <div className="quote-bold-text">
                              {" "}
                              {quote?.quotation_created_by
                                ? quote?.quotation_created_by
                                : " - "}
                            </div>
                          </div> */}
                            <div className="quote-single-middle-in-created-by">
                              <div className="quote-slim-text">Rejected by</div>
                              <div className="quote-bold-text">
                                {" "}
                                {quote?.quotation_modified_by
                                  ? quote?.quotation_modified_by
                                  : " - "}
                              </div>
                            </div>
                          </>
                        ) : currentTab === "accepted" ? (
                          <>
                            {/* <div className="quote-single-middle-in">
                            <div className="quote-slim-text">Created by</div>
                            <div className="quote-bold-text">
                              {" "}
                              {quote?.quotation_created_by
                                ? quote?.quotation_created_by
                                : " - "}
                            </div>
                          </div> */}
                            <div className="quote-single-middle-in-created-by">
                              <div className="quote-slim-text">Accepted by</div>
                              <div className="quote-bold-text">
                                {" "}
                                {quote?.quotation_modified_by
                                  ? quote?.quotation_modified_by
                                  : " - "}
                              </div>
                            </div>
                          </>
                        ) : currentTab === "sent" ? (
                          <>
                            {/* <div className="quote-single-middle-in">
                            <div className="quote-slim-text">Created by</div>
                            <div className="quote-bold-text">
                              {" "}
                              {quote?.quotation_created_by
                                ? quote?.quotation_created_by
                                : " - "}
                            </div>
                          </div> */}
                            <div className="quote-single-middle-in-created-by">
                              <div className="quote-slim-text">Sent by</div>
                              <div className="quote-bold-text">
                                {" "}
                                {quote?.quotation_modified_by
                                  ? quote?.quotation_modified_by
                                  : " - "}
                              </div>
                            </div>
                          </>
                        ) : currentTab === "denied" ? (
                          <>
                            {/* <div className="quote-single-middle-in">
                            <div className="quote-slim-text">Created by</div>
                            <div className="quote-bold-text">
                              {" "}
                              {quote?.quotation_created_by
                                ? quote?.quotation_created_by
                                : " - "}
                            </div>
                          </div> */}
                            <div className="quote-single-middle-in-created-by">
                              <div className="quote-slim-text">Denied by</div>
                              <div className="quote-bold-text">
                                {" "}
                                {quote?.quotation_modified_by
                                  ? quote?.quotation_modified_by
                                  : " - "}
                              </div>
                            </div>
                          </>
                        ) : null}

                        {/* remark
                      {quote?.remarks ? (
                        <div className="quote-remarks-display">
                          {quote?.remarks ? quote?.remarks : ""}
                        </div>
                      ) : null} */}
                      </div>
                      <div className="quote-single-bottom-row">
                        <div style={{ display: "flex" }}>
                          <div
                            className="quote-single-stop-text"
                            onClick={() => handleOpenRoutes(quote)}
                          >
                            {quote?.destination?.length > 0
                              ? quote?.destination?.length == 1 &&
                                quote?.source?.length == 1
                                ? "Single stop"
                                : "Multiple stop"
                              : null}
                          </div>{" "}
                          <InfoOutlinedIcon
                            style={{
                              color: "rgba(7, 107, 56, 1)",
                              fontSize: "20px",
                              marginLeft: "2px",
                              cursor: "pointer",
                              display: isMobile ? "none" : "block",
                            }}
                            onClick={() => handleOpenRoutes(quote)}
                          />
                        </div>
                        <div
                          style={{ display: "flex" }}
                          onClick={() => handleOpenHistory(quote)}
                        >
                          <div className="quote-single-stop-text">
                            View History
                          </div>{" "}
                          <HistoryIcon
                            style={{
                              color: "rgba(7, 107, 56, 1)",
                              fontSize: "20px",
                              marginLeft: "2px",
                              cursor: "pointer",
                              display: isMobile ? "none" : "block",
                            }}
                          />
                        </div>
                        {/* <div style={{ display: "flex" }}>
                        HistoryIcon
                        <button
                          className="quote-add-history-btn"
                          onClick={() => handleOpenHistory(quote)}
                        >
                          View History
                        </button>
                      </div> */}

                        {currentTab === "draft" ? (
                          <div className="button-draft">
                            <ButtonRectangle
                              // className="quote-add-quote-btn"
                              style={{ marginRight: "10px" }}
                              onClick={() =>
                                handleOpenApprove(quote?.quotations_id)
                              }
                            >
                              Approve
                            </ButtonRectangle>
                            <ButtonRectangleSecondary
                              className="quote-reject-btn"
                              onClick={() =>
                                handleOpenRemarks(quote?.quotations_id)
                              }
                            >
                              Reject
                            </ButtonRectangleSecondary>
                          </div>
                        ) : currentTab === "approved" ? (
                          <div>
                            <ButtonRectangle
                              // className="quote-add-quote-btn"
                              onClick={() =>
                                handleOpenSent(quote?.quotations_id)
                              }
                            >
                              Send
                            </ButtonRectangle>
                          </div>
                        ) : currentTab === "sent" ? null : currentTab ===
                          "denied" ? (
                          <div>
                            <ButtonRectangle
                              // className="quote-add-quote-btn"
                              onClick={() => {
                                // e.preventDefault();
                                handleOpenAddQuote(quote?.quotations_id);
                              }}
                            >
                              Reopen
                            </ButtonRectangle>
                            {/* <button
                            className="quote-reject-btn"
                            onClick={() =>
                              handleOpenRemarks(quote?.quotations_id)
                            }
                          >
                            Reject
                          </button> */}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
                {loadingMore ? (
                  <div className="loading_more_data">Loading More ...</div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
      <Snackbar
        open={addQuoteSuccessToast}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseToast}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          {indentSuccessToast ? indentSuccessToast : ""}
        </Alert>
      </Snackbar>

      {/*Trip routes modal starts*/}
      <StyledModal
        open={openTripRoutes}
        onClose={handleCloseTripRoute}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={tripRoutesBoxStyle}
          style={{
            backgroundColor: darkMode ? colorPaletteDark.secondaryColor : "",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10px",
              backgroundColor: darkMode
                ? colorPaletteDark.secondaryColor
                : colorPalette.white,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="trip-pickup-modal-head"
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: darkMode ? colorPaletteDark.white : "",
                }}
              >
                <div> Routes </div>{" "}
                <div style={{ marginLeft: "4px" }}>
                  {" "}
                  ( quotation code :{" "}
                  {selectedQuote?.quotation_code
                    ? selectedQuote?.quotation_code
                    : " - "}{" "}
                  )
                </div>
              </div>
              <div>
                <Close
                  onClick={handleCloseTripRoute}
                  style={{
                    cursor: "pointer",
                    color: darkMode ? colorPaletteDark.white : "",
                  }}
                />{" "}
              </div>
            </div>
            <div>
              <div className="trip-list-address">
                <div className="trip-location-list">
                  {selectedQuote.source?.map((source) => {
                    return (
                      <div
                        className="trip-location-text"
                        style={{
                          color: darkMode ? colorPaletteDark.white : "",
                        }}
                      >
                        From
                      </div>
                    );
                  })}
                  {/* <div className="trip-location-text">From</div> */}
                  {selectedQuote.destination?.map((destinations) => {
                    return (
                      <div
                        className="trip-location-text"
                        style={{
                          color: darkMode ? colorPaletteDark.white : "",
                        }}
                      >
                        To
                      </div>
                    );
                  })}
                </div>
                <div className="trip-list-path">
                  {/* <div className="trip-circle"></div>
                  <div className="trip-rectangle"></div> */}
                  {selectedQuote.source?.map((source, index) => {
                    return (
                      <>
                        {index == 0 ? null : (
                          <>
                            <div className="trip-circle"></div>
                            <div
                              className="mini-trip-rectangle"
                              style={{ marginTop: "20px" }}
                            ></div>
                          </>
                        )}
                      </>
                    );
                  })}
                  {selectedQuote.destination?.map((destinations) => {
                    return (
                      <>
                        <div className="trip-circle"></div>
                        <div
                          className="mini-trip-rectangle"
                          style={{ marginTop: "20px" }}
                        ></div>
                      </>
                    );
                  })}
                  <div className="trip-circle"></div>
                </div>
                <div className="trip-location-list">
                  {/* <div className="trip-location-text"> */}
                  {selectedQuote.source?.map((source) => {
                    return (
                      <div
                        className="trip-location-text"
                        style={{
                          color: darkMode ? colorPaletteDark.white : "",
                        }}
                      >
                        {source.village === source.district
                          ? source.village
                          : `${source.village},
                          ${source.district}`}{" "}
                        , {source.state}, {source.postcode}
                      </div>
                    );
                  })}

                  {selectedQuote.destination?.map((destinations) => {
                    return (
                      <div
                        className="trip-location-text"
                        style={{
                          color: darkMode ? colorPaletteDark.white : "",
                        }}
                      >
                        {destinations.village === destinations.district
                          ? destinations.district
                          : `${destinations.village}, ${destinations.district}`}
                        , {destinations.state}, {destinations.postcode}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>
      {/*Trip routes modal ends*/}

      <Drawer
        open={drawerOpen}
        anchor={"right"}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: isMobile ? 320 : 440,
            backgroundColor: darkMode
              ? colorPaletteDark.secondaryColor
              : colorPalette.white,
          },
        }}
      >
        <QuotationDrawer className="quote-drawer-container" mode={darkMode}>
          <div className="quote-remarks-text">Remarks</div>
          <div className="quote-remarks-inputfield-conatiner">
            <textarea
              className="quote-remarks-textarea"
              name="accept_remark"
              placeholder="Add Remark"
              value={remarksReject}
              onChange={(e) => setRemarksReject(e.target.value)}
            ></textarea>
          </div>
          <div className="quote-remarks-submit-btn-cover">
            <button
              onClick={() =>
                handleSubmitStatus(selectedQuoteId, "rejected", remarksReject)
              }
              className="indent-remarks-submit-btn"
              style={{
                width: "100px",
                height: "30px",
                borderRadius: "5px",
                border: "1px solid red",
                backgroundColor: `${darkMode ? "transparent" : ""}`,
                color: `${darkMode ? "white" : "red"}`,
              }}
              disabled={loadingStatusButton == true ? true : false}
            >
              {loadingStatusButton ? (
                <CircularProgress size={19} color="inherit" />
              ) : (
                "Reject"
              )}
            </button>
          </div>
        </QuotationDrawer>
      </Drawer>
      <Drawer
        open={drawerSentOpen}
        anchor={"right"}
        onClose={() => setDrawerSentOpen(false)}
        PaperProps={{
          sx: {
            width: isMobile ? 320 : 440,
            backgroundColor: darkMode
              ? colorPaletteDark.secondaryColor
              : colorPalette.white,
          },
        }}
      >
        <QuotationDrawer className="quote-drawer-container" mode={darkMode}>
          <div className="quote-remarks-text">Remarks</div>
          <div className="quote-remarks-inputfield-conatiner">
            <textarea
              className="quote-remarks-textarea"
              name="accept_remark"
              placeholder="Add Remark"
              value={remarksSent}
              onChange={(e) => setRemarksSent(e.target.value)}
            ></textarea>
          </div>
          <div className="quote-remarks-submit-btn-cover">
            <ButtonRectangle
              onClick={() =>
                handleSubmitSentStatus(selectedQuoteId, "sent", remarksSent)
              }
              // className="quote-save-quote-btn"
              disabled={loadingStatusButton == true ? true : false}
            >
              {loadingStatusButton ? (
                <CircularProgress size={19} color="inherit" />
              ) : (
                "Send"
              )}
            </ButtonRectangle>
          </div>
        </QuotationDrawer>
      </Drawer>
      <Drawer
        open={quoteApproveDrawerOpen}
        anchor={"right"}
        onClose={() => setQuoteApproveDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: isMobile ? 320 : 440,
            backgroundColor: darkMode
              ? colorPaletteDark.secondaryColor
              : colorPalette.white,
          },
        }}
      >
        <QuotationDrawer className="quote-drawer-container" mode={darkMode}>
          <div className="quote-input-container">
            <div className="quote-input-text">Remarks</div>
            <div
              className="quote-remarks-inputfield-conatiner"
              style={{ margin: "0px" }}
            >
              <textarea
                className="quote-remarks-textarea"
                name="accept_remark"
                placeholder="Add Remark"
                value={remarksAddQuote}
                onChange={(e) => setRemarksAddQuote(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div>
            {quoteToastError ? (
              <Alert severity="error">
                {quoteToastError ? quoteToastError : ""}
              </Alert>
            ) : null}
          </div>
          <div
            className="quote-remarks-submit-btn-cover"
            style={{ width: "93%" }}
          >
            <ButtonRectangle
              onClick={() =>
                handleSubmitStatus(selectedQuoteId, "approved", remarksAddQuote)
              }
              className="quote-save-quote-btn"
              disabled={loadingStatusButton == true ? true : false}
            >
              {loadingStatusButton ? (
                <CircularProgress size={19} color="inherit" />
              ) : (
                "Approve"
              )}
            </ButtonRectangle>
          </div>
        </QuotationDrawer>
      </Drawer>
      <CreateQuoteDrawer
        addQuoteDrawerOpen={addQuoteDrawerOpen}
        handleCloseDrawer={handleCloseDrawer}
        quotationDataById={quotationDataById}
        tab={handleChangeTab}
        // selectedIndentId={selectedIndentId}
      />
      <Drawer
        open={historyDrawerOpen}
        anchor={"right"}
        onClose={() => handleCloseHistoryDrawer()}
        PaperProps={{
          sx: {
            width: isMobile ? 300 : 400,
            backgroundColor: darkMode
              ? colorPaletteDark.secondaryColor
              : colorPalette.white,
            padding: "10px 10px",
          },
        }}
      >
        <QuotationHistory className="indent-drawer-container" mode={darkMode}>
          <div className="history-conatiner">
            <h4 style={{ color: `${colorPalette.primaryColor}` }}>
              Quotation History
            </h4>
          </div>

          <Mini_detais_Card_style
            className="mini-details-tab-container-history-quoations"
            mode={darkMode}
          >
            <div className="mini-details-tab-left-timeline">
              {quoationHistoryList.length > 0 ? (
                quoationHistoryList.map((item, index) => {
                  return (
                    <>
                      {/* {tripHistoryList.length - 1 == index ? null : ( */}
                      <>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                      </>
                    </>
                  );
                })
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "83vh",
                    // backgroundColor: "red",
                  }}
                >
                  <h5 style={{ marginLeft: "5rem" }}>loading data...</h5>
                </div>
              )}
            </div>
            <div>
              {quoationHistoryList.map((item) => {
                return (
                  <div className="mini-details-single-history">
                    <h3 className="mini-details-history-head">
                      <div className="mini-trip-circle"></div>{" "}
                      {item.quotation_status
                        ? item.quotation_status
                            .split("")
                            .map((item, index) => {
                              if (index == 0) {
                                return item.toUpperCase();
                              } else {
                                return item;
                              }
                            })
                            .join("")
                        : "-"}
                    </h3>
                    <div className="mini-details-single-list">
                      {/* <h4 className="mini-details-history-left">created</h4> */}
                      <h4 className="mini-details-history-right">
                        <span className="mini-details-black-text">
                          Remarks :
                        </span>
                        <span className="mini-details-grey-text">
                          {" "}
                          {item.remarks
                            ? item.remarks.replaceAll("_", " ")
                            : "-"}
                        </span>
                      </h4>{" "}
                    </div>
                    <div className="mini-details-single-list">
                      {/* <h4 className="mini-details-history-left">created</h4> */}
                      <h4 className="mini-details-history-right">
                        <span className="mini-details-black-text">
                          {" "}
                          Last Modified :{" "}
                        </span>
                        <span className="mini-details-grey-text">
                          {item.modified_by ? item.modified_by : "-"}
                        </span>
                      </h4>{" "}
                    </div>
                    {/* <div className="mini-details-single-list">
                   
                        <h4 className="mini-details-history-right">
                          <span className="mini-details-black-text">
                            {" "}
                            Created by :{" "}
                          </span>
                          <span className="mini-details-grey-text">
                            {item.created_by ? item.created_by : "-"}
                          </span>
                        </h4>{" "}
                      </div> */}
                    <div className="mini-details-single-list">
                      <h4 className="mini-details-history-left">
                        {/* created by */}
                      </h4>
                      <h4 className="mini-details-history-right">
                        <span className="mini-details-black-text">
                          Created on :{" "}
                        </span>
                        <span className="mini-details-grey-text">
                          {item.created_on
                            ? moment
                                .utc(item.created_on)
                                .local()
                                .format("DD-MM-YYYY hh:mm a")
                            : "-"}
                        </span>
                      </h4>{" "}
                    </div>
                  </div>
                );
              })}
            </div>
          </Mini_detais_Card_style>
        </QuotationHistory>
      </Drawer>
    </Quotation_Page_Style>
  );
}

export default QuotationPage;
