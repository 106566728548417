import styled from "styled-components";
import { FontWeight, colorPaletteDark } from "../../themes/theme";

export const ProfilePopOverStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .cursor-pointer {
    cursor: pointer;
  }
  .user-name-text {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: ${(props) =>
      props.mode ? colorPaletteDark.paragraphColor : "#575757"};
  }
`;

export const DashboardStyle = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  /* grid-template-columns: 265px auto; */
  /* overflow-x: hidden; */

  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }

  .dashboard-left {
    flex: 1;
  }

  .dashboard-right {
    flex: 5;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
    padding: 40px 60px;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : "#F9F9F9"};

    @media only screen and (max-width: 480px) {
      padding: 20px;
    }

    .dashboard-username {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: 480px) {
        display: none;
      }
      .username {
        font-size: 2rem;
        font-weight: ${FontWeight.semibold};
        color: ${(props) => (props.mode ? colorPaletteDark.white : "#575757")};
      }

      .dark-mode-and-profile {
        display: flex;
        align-items: flex-end;
        gap: 15px;
        .dark-mode-button {
          cursor: pointer;
        }

        .dark-mode-button-user {
          width: 40px;
          height: 40px;
          padding: 5px;
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 9999px;
          cursor: pointer;
          background-color: ${(props) =>
            props.mode ? colorPaletteDark.white : "#fff"};
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

          img {
            width: 30px;
          }
        }
      }
    }
  }

  .dashboard-main-card {
    width: 100%;
    height: 100%;
    margin-top: 20px;

    @media only screen and (max-width: 480px) {
      height: auto;
    }

    .dashobard-total-card {
      width: 100%;
      display: grid;
      align-items: center;
      justify-content: space-between;
      grid-template-columns: auto auto;
      @media only screen and (max-width: 480px) {
        width: 100%;
        grid-template-columns: auto;
        justify-content: center;
        row-gap: 20px;
      }

      .dashboard-total-mini-card {
        width: 110%;

        min-width: 200px;
        height: 100%;
        /* background-color: red; */
        border-radius: 12px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        background-color: ${(props) =>
          props.mode ? colorPaletteDark.secondaryColor : "#fff"};

        @media only screen and (max-width: 480px) {
          width: 100vw;
        }

        .card-title {
          font-weight: ${FontWeight.medium};
          color: ${(props) =>
            props.mode ? colorPaletteDark.white : "#575757"};
        }

        .card-number {
          /* text-align: center; */
          font-size: 2rem;
          margin-top: 10px;
          font-weight: 900;
          color: ${(props) =>
            props.mode ? colorPaletteDark.white : "#8B8B8B"};
        }

        .card-helper-text {
          font-weight: ${FontWeight.semibold};
          font-size: 0.7rem;
          height: 25px;
          margin-bottom: 5px;
          color: ${(props) =>
            props.mode ? colorPaletteDark.white : "#0FBC9E"};
        }
      }
    }

    .dashobard-graph-and-card {
      width: 100%;
      height: 220px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      /* justify-content: space-between; */

      @media only screen and (max-width: 480px) {
        height: auto;
        flex-direction: column;
        gap: 20px;
      }

      .dashboard-graph {
        width: 100%;
        height: 100%;
        display: grid;

        grid-template-rows: 25px auto;
        border-radius: 12px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        background-color: ${(props) =>
          props.mode ? colorPaletteDark.secondaryColor : "#fff"};
        padding: 20px;

        @media only screen and (max-width: 480px) {
          width: 100%;
          height: auto;
        }

        .customer-receivable-table {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          overflow-x: scroll;
          @media only screen and (max-width: 480px) {
            flex-direction: column;
            justify-content: space-between;
            height: auto;
          }

          .customer-table {
            margin-top: 10px;
            display: grid;
            grid-template-columns: 360px auto;
            /* column-gap: 60px; */
            color: ${(props) =>
              props.mode ? colorPaletteDark.white : "#575757"};

            @media only screen and (max-width: 480px) {
              column-gap: 20px;
              justify-content: space-between;
              grid-template-columns: auto auto;
            }

            .table-head {
              font-weight: 600;
              color: ${(props) =>
                props.mode ? colorPaletteDark.white : "#575757"};
            }
          }
        }

        .graph-title {
          font-weight: ${FontWeight.semibold};
          color: ${(props) =>
            props.mode ? colorPaletteDark.white : "#575757"};

          @media only screen and (max-width: 480px) {
            font-size: 14px;
          }
        }

        .graph-div {
          width: 100%;
          height: 170px;
        }
      }
    }
  }

  .transporter-vehicle-main-card {
    display: flex;
    align-items: center;
    gap: 20px;

    @media only screen and (max-width: 480px) {
      width: 100vw;
      height: auto;
      flex-direction: column;
    }

    .minicard {
      width: 100%;
      height: 100%;
      background-color: ${(props) =>
        props.mode ? colorPaletteDark.secondaryColor : "#00ef751a"};
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 5px;

      @media only screen and (max-width: 480px) {
        width: 85vw;
      }

      .card-title {
        font-size: 1.2rem;
        font-weight: ${FontWeight.semibold};
        color: ${(props) => (props.mode ? colorPaletteDark.white : "#575757")};
        line-height: 26px;
      }

      .card-number {
        width: 100%;
        display: flex;
        align-items: flex-end;
        gap: 10px;

        .total-number {
          font-size: 2.5rem;
          font-weight: ${FontWeight.bold};
          color: ${(props) =>
            props.mode ? colorPaletteDark.white : "#8B8B8B"};
        }

        .today-number {
          display: flex;
          font-size: 1.1rem;
          align-items: center;
          gap: 3px;
          margin-bottom: 8px;
          font-weight: ${FontWeight.semibold};
          color: ${(props) =>
            props.mode ? colorPaletteDark.white : "#0FBC9E"};
        }
      }
    }

    .vehicle-card {
      background-color: ${(props) =>
        props.mode ? colorPaletteDark.secondaryColor : "#FBF0B9"};
    }

    .customer-card {
      background-color: ${(props) =>
        props.mode ? colorPaletteDark.secondaryColor : "#F5E1E0"};
    }
  }

  .dashboard-graph {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 25px auto;
    border-radius: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : "#fff"};
    padding: 20px;

    @media only screen and (max-width: 480px) {
      width: 100%;
      height: auto;
    }

    .pie-chart {
      display: flex;
      flex-direction: column;
      padding: 5px;
      border-radius: 8px;
      background-color: ${(props) =>
        props.mode ? colorPaletteDark.secondaryColor : "#fff"};
      /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */

      .card-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: ${FontWeight.semibold};
        color: ${(props) => (props.mode ? colorPaletteDark.white : "#575757")};

        @media only screen and (max-width: 480px) {
          margin-bottom: 10px;
        }
      }

      .dashboard-pie-chart {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 20px;

        @media only screen and (max-width: 480px) {
          width: 100%;
          grid-template-columns: auto;
          margin-bottom: 20px;
        }

        .pie-chart {
          width: 100%;
          height: 140px;
          margin-top: auto;
        }

        .payment-receivable-amount {
          /* margin-top: auto; */
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .amount {
            font-size: 2.4rem;
            font-weight: ${FontWeight.bold};
            color: ${(props) =>
              props.mode ? colorPaletteDark.white : "#8B8B8B"};
          }
        }

        .payment-helper-text {
          font-weight: ${FontWeight.medium};
          color: ${(props) =>
            props.mode ? colorPaletteDark.white : "#0FBC9E"};
        }
      }
    }
  }

  .dashboard-pie-chart {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 20px;

    @media only screen and (max-width: 480px) {
      width: 100%;
      grid-template-columns: auto;
      margin-bottom: 20px;
    }
  }

  .dashobard-card-main-section {
    display: flex;
    gap: 10px;
    justify-content: "space-between";
    height: 6rem;
    margin-top: 10px;

    @media only screen and (max-width: 480px) {
      flex-direction: column;
      height: auto;
    }
  }
`;
