import styled from "styled-components";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";

export const WareHouse_list_main_container = styled.div`
  height: 100vh;
  /* .warehouse-list-main-container { */
  display: flex;
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
  /* background-color: ${colorPalette.white}; */
  /* } */
  .warehouse-list-sidebar-container {
    flex: 2.2;
  }
  .form_label_oem {
    font-family: ${FontFamily.primaryFontFamily};
    font-weight: ${FontWeight.bold};
    margin-bottom: 0.4rem;
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .warehouse-list-grid-container {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr;
    grid-template-rows: 0.2fr 0.2fr 0.2fr;

    height: 70vh;
    overflow: scroll;
    border-top: 1px solid gray;
  }
  .search_container {
    /* flex: 6; */
    width: 100%;
    height: 10%;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search_div {
    position: relative;
    width: 70%;
    height: 2.5rem;
    background-color: ${colorPalette.white};
    display: flex;
    padding: 0 0.8rem;
    margin-right: 2rem;
    /* background-color: red; */
    margin-left: 3rem;
    /* justify-content: center; */
    align-items: center;
    border-radius: 8px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
  .search_input {
    width: 100%;
    background-color: ${colorPalette.white};
    height: 100%;
    outline: none;
    border-radius: 1rem;
    border: none;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
  }
  .user-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .warehouse-list-right-main-container {
    flex: 6;
    /* width: 53vw; */
  }
  .warehouse-list-right-wrapper {
    margin: 15px;
  }
  .warehouse-list-single-container {
    padding: 20px;
    /* border: 1px solid black; */
    border-radius: 10px;
    cursor: pointer;
    margin: 10px;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
    box-shadow: ${colorPalette.boxShadowPrimary};
  }
  .warehouse-list-search-container {
    width: 100%;
    height: 10%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .warehouse-list-search-wrapper {
    width: 100%;
  }

  .warehouse-list-search-input {
    width: 20rem;
    background-color: ${colorPalette.secondaryWhite};
    height: 100%;
    outline: none;
    border-radius: 1rem;
    border: none;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
  }
  .warehouse-list-warehouse-name {
    font-weight: ${FontWeight.semibold};
    color: ${(props) => (props.mode ? colorPaletteDark.white : "")};
  }
  .warehouse-list-warehouse-state {
    font-weight: ${FontWeight.medium};
    color: ${(props) => (props.mode ? colorPaletteDark.white : "")};
    /* color: ${colorPalette.paragraphSecondaryolor}; */
    font-size: ${typography.heading5};
  }
  .warehouse-list-add-button {
    color: ${colorPalette.white};
    background-color: ${colorPalette.primaryColor};
    border: none;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
  }

  .warehouse-list-add-button:hover {
    background-color: ${colorPalette.secondaryGreen};
  }
  .warehouse-list-right-mini-details-container {
  }

  .user_heading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 2rem;
    margin-top: 0.5rem;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    /* background-color: red; */
  }
  .warehouse-list-add-button_user {
    width: 100%;
    padding: 0.5rem 0rem;
    color: ${colorPalette.white};
    background-color: ${colorPalette.primaryColor};
    cursor: pointer;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    outline: none;
    border: none;
  }
  .warehouse-list-drawer-head {
    padding: 10px 20px;
    background-color: ${colorPalette.secondaryWhite};
    color: ${colorPalette.paragraphColor};
    font-size: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-weight: ${FontWeight.semibold};
  }
  .warehouse-list-warehouse-drawer-container {
    width: 30vw;
  }
  .warehouse-list-drawer-input-container {
    margin: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }
  .warehouse-list-input {
    margin: 10px 0px;
    width: 80%;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.white};
  }
  .warehouse-list-input-label {
    margin: 5px 0px;
    font-weight: ${FontWeight.medium};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .warehouse-list-save-button {
    color: ${colorPalette.white};
    background-color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.medium};
    padding: 10px;
    border-radius: 10px;
    border: none;
    width: 80%;
    margin: 15px 0px;
    cursor: pointer;
  }
  .warehouse-list-drawer-row {
    margin: 7px 0px;
  }

  .usersContainer {
    width: 90%;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0rem 2rem;

    height: 100%;
  }

  .warehouse-list-add-button_user {
    width: 100%;
    padding: 0.5rem 0rem;
    color: ${colorPalette.white};
    background-color: ${colorPalette.primaryColor};
    cursor: pointer;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    outline: none;
    border: none;
  }
  .warehouse-list-warehouse-code {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
    color: ${(props) => (props.mode ? colorPaletteDark.white : "")};
  }
  .warehouse-list-edit-button {
    padding: 5px;
    border: none;
    border-radius: 10px;
    color: ${colorPalette.white};
    background-color: ${colorPalette.primaryColor};
  }
  .warehouse-not-found {
    font-weight: ${FontWeight.medium};
    display: flex;
    justify-content: center;
  }
  .warehouse-error {
    color: red;
    font-size: ${typography.paragraph};
    margin-left: 5px;
  }

  @media only screen and (max-width: 480px) {
    .drawer {
      width: 98%;
    }
    .trip-list-mobile-header_warehouse {
      width: 100%;
      height: 50px;
      /* margin-top: 2rem; */
      /* background-color: red; */
      /* padding: 0px 15px; */
    }
    /* .warehouse-list-main-container { */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* margin: 0 auto; */
    /* background-color: red; */
    /* } */
    .warehouse-list-sidebar-container {
      display: none;
      /* background-color: ${colorPalette.primaryColor}; */
    }
    .warehouse-list-grid-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 0.2fr;
      grid-auto-rows: min-content;
      height: 83vh;
      margin: 0 auto;
      overflow: scroll;
      /* background-color: ${colorPalette.primaryColor}; */
    }
    .warehouse-list-right-main-container {
      margin-top: 3rem;
      flex: 6;
      width: 100%;

      /* background-color: ${colorPalette.primaryColor}; */
    }
    .warehouse-list-right-wrapper {
      margin: 5px;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      /* background-color: antiquewhite; */
    }
    .warehouse-list-single-container {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* background-color: aqua; */
      /* border: 1px solid black; */
      border-radius: 10px;
      cursor: pointer;
      margin: 10px;
      box-shadow: ${colorPalette.boxShadowPrimary};
    }

    #mobile_img {
      flex: 2;
      /* background-color: red; */
    }
    .warehouse-list-search-container {
      width: 90%;
      height: 10%;
      margin-top: 1rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: red;
    }
    .warehouse-list-search-wrapper {
      width: 100%;
      background-color: red;
    }

    .warehouse-list-search-input {
      width: 10rem;
      background-color: ${colorPalette.secondaryWhite};
      height: 100%;
      outline: none;
      border-radius: 1rem;
      border: none;
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      -ms-border-radius: 1rem;
      -o-border-radius: 1rem;
      background-color: red;
    }
    .warehouse-list-warehouse-name {
      font-weight: ${FontWeight.semibold};
    }
    .warehouse-list-warehouse-name-conatiner {
      /* flex: 10; */

      padding: 3.5rem 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      /* padding: 1rem 2rem; */
      /* background-color: red; */
    }
    #edit_warehouse {
      margin-left: 2rem;
    }
    .warehouse-list-warehouse-state {
      font-weight: ${FontWeight.medium};
      color: ${colorPalette.paragraphSecondaryolor};
      font-size: ${typography.heading5};
    }
    .warehouse-list-add-button {
      color: ${colorPalette.white};
      background-color: ${colorPalette.primaryColor};
      border: none;
      padding: 10px;
      border-radius: 10px;
      cursor: pointer;
    }

    .warehouse-list-add-button:hover {
      background-color: ${colorPalette.secondaryGreen};
    }
    .warehouse-list-right-mini-details-container {
      display: none;
    }

    .warehouse-list-add-button_user {
      width: 100%;
      padding: 0.5rem 0rem;
      color: ${colorPalette.white};
      background-color: ${colorPalette.primaryColor};
      cursor: pointer;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      outline: none;
      border: none;
    }
    .warehouse-list-drawer-head {
      padding: 10px 20px;
      background-color: ${colorPalette.secondaryWhite};
      color: ${colorPalette.paragraphColor};
      font-size: 20px;
      font-weight: ${FontWeight.semibold};
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .warehouse-list-warehouse-drawer-container {
      width: 98vw;
    }
    .warehouse-list-drawer-input-container {
      margin: 10px;
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
    }
    .warehouse-list-input {
      margin: 10px 0px;
      width: 80%;
      background-color: ${(props) =>
        props.mode ? colorPaletteDark.white : colorPalette.white};
    }
    .warehouse-list-input-label {
      margin: 5px 0px;
      font-weight: ${FontWeight.medium};
      color: ${(props) =>
        props.mode ? colorPaletteDark.white : colorPalette.black};
    }
    .warehouse-list-save-button {
      color: ${colorPalette.white};
      background-color: ${colorPalette.primaryColor};
      font-weight: ${FontWeight.medium};
      padding: 10px;
      border-radius: 10px;
      border: none;
      width: 80%;
      margin: 15px 0px;
      cursor: pointer;
    }
    .warehouse-list-drawer-row {
      margin: 7px 0px;
    }

    .usersContainer {
      width: 90%;
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 0rem 2rem;

      height: 100%;
    }
    .user_heading {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 2rem;
      margin-top: 0.5rem;
    }
    .warehouse-list-add-button_user {
      width: 100%;
      padding: 0.5rem 0rem;
      color: ${colorPalette.white};
      background-color: ${colorPalette.primaryColor};
      cursor: pointer;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      outline: none;
      border: none;
    }
    .warehouse-list-edit-button {
      padding: 5px;
      border: none;
      border-radius: 10px;
      color: ${colorPalette.white};
      background-color: ${colorPalette.primaryColor};
    }
  }
`;

export const Warehouse_list_warehouse_drawer_container = styled.div`
  /* .warehouse-list-warehouse-drawer-container { */
  width: 30vw;
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

  /* } */
  .warehouse-list-drawer-head {
    padding: 10px 20px;
    background-color: ${colorPalette.secondaryWhite};
    color: ${colorPalette.paragraphColor};
    font-size: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-weight: ${FontWeight.semibold};
  }
  .warehouse-list-drawer-input-container {
    margin: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }

  .warehouse-list-drawer-head {
    padding: 10px 20px;
    background-color: ${colorPalette.secondaryWhite};
    color: ${colorPalette.paragraphColor};
    font-size: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-weight: ${FontWeight.semibold};
  }

  .warehouse-list-drawer-row {
    margin: 7px 0px;
  }

  .warehouse-list-input-label {
    margin: 5px 0px;
    font-weight: ${FontWeight.medium};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }

  .warehouse-list-input {
    margin: 10px 0px;
    width: 80%;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.white};
  }
  .warehouse-list-input-label {
    margin: 5px 0px;
    font-weight: ${FontWeight.medium};
  }

  .warehouse-error {
    color: ${colorPalette.red};
    font-size: ${typography.paragraph};
    margin-left: 5px;
  }

  .warehouse-list-save-button {
    color: ${colorPalette.white};
    background-color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.medium};
    padding: 10px;
    border-radius: 10px;
    border: none;
    width: 80%;
    margin: 15px 0px;
    cursor: pointer;
  }
  @media only screen and (max-width: 480px) {
    /* .warehouse-list-warehouse-drawer-container { */
    width: 98vw;
    /* } */

    .warehouse-list-drawer-head {
      padding: 10px 20px;
      background-color: ${colorPalette.secondaryWhite};
      color: ${colorPalette.paragraphColor};
      font-size: 20px;
      font-weight: ${FontWeight.semibold};
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .warehouse-list-drawer-head {
      padding: 10px 20px;
      background-color: ${colorPalette.secondaryWhite};
      color: ${colorPalette.paragraphColor};
      font-size: 20px;
      font-weight: ${FontWeight.semibold};
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .warehouse-list-drawer-row {
      margin: 7px 0px;
    }

    .warehouse-list-input-label {
      margin: 5px 0px;
      font-weight: ${FontWeight.medium};
      color: ${(props) =>
        props.mode ? colorPaletteDark.white : colorPalette.black};
    }

    .warehouse-list-input {
      margin: 10px 0px;
      width: 80%;
    }
    .warehouse-list-input-label {
      margin: 5px 0px;
      font-weight: ${FontWeight.medium};
    }

    .warehouse-list-save-button {
      color: ${colorPalette.white};
      background-color: ${colorPalette.primaryColor};
      font-weight: ${FontWeight.medium};
      padding: 10px;
      border-radius: 10px;
      border: none;
      width: 80%;
      margin: 15px 0px;
      cursor: pointer;
    }
  }
`;

export const CreateNewUser = styled.div`
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

  .user_heading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    margin-top: 0.5rem;
  }

  .usersContainer {
    width: 90%;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 0rem 2rem;
    height: 100%;
  }

  .warehouse-error {
    color: red;
    font-size: ${typography.paragraph};
    margin-left: 5px;
  }

  .user-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .warehouse-list-add-button_user {
    width: 100%;
    padding: 0.5rem 0rem;
    color: ${colorPalette.white};
    background-color: ${colorPalette.primaryColor};
    cursor: pointer;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    outline: none;
    border: none;
  }

  @media only screen and (max-width: 480px) {
    .user_heading {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      padding: 1rem 2rem;
      margin-top: 0.5rem;
    }

    .usersContainer {
      width: 90%;
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 0rem 2rem;
      height: 100%;
    }

    .warehouse-list-add-button_user {
      width: 100%;
      padding: 0.5rem 0rem;
      color: ${colorPalette.white};
      background-color: ${colorPalette.primaryColor};
      cursor: pointer;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      outline: none;
      border: none;
    }
  }
`;
