import apiUrl from "../../url/apiUrl";
import axios from "axios";
import axiosInterceptor from "../../config/https";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";

export const GetDriverDetails = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.driverBaseUrl}/details/${prefixSalt()}-${convertToBase64(id)}`,
      {}
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

// export const GetCostDetails = async (id) => {
//   try {
//     const response = await axios.get(`${apiUrl.driverBaseUrl}/${id}`, {
//       headers: {
//         Authorization:
//           "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDc0MTM2OTksImlkIjoiNjk0ODg0ODAtNWU3Zi00NzFjLWIxODItMWQ0MzcwMTcxODJlIiwicm9sZSI6IkRFVkVMT1BFUiJ9.nNGToX9uxUIHWi7nG0zSpAYIrA3nmow64qcI7n3OVPA",
//       },
//     });

//     if (response.status == 200) {
//       return response.data;
//     }
//   } catch (e) {
//
//   }
// };

export const GetTripHistory = async (trip_code) => {
  var url;
  if (localStorage.getItem("Trip_Type") === "ftl_trip") {
    url = `${apiUrl.ftlTripHistoryUrl}?trip_code=${trip_code}`;
  } else if (localStorage.getItem("Trip_Type") === "faarms_trip") {
    url = `${apiUrl.faarmsTripHistoryUrl}?trip_code=${trip_code}`;
  } else {
    url = `${apiUrl.tripHistoryUrl}?trip_code=${trip_code}`;
  }
  try {
    const response = await axiosInterceptor.get(url);

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const GetTripHistoryForFaarms = async (trip_code) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.faarmsTripHistoryUrl}?trip_code=${trip_code}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const GetTripAttachments = async (trip_id) => {
  try {
    const trip_type = localStorage.getItem("Trip_Type");

    let url = `${apiUrl.tripAttachmentsUrl}?trip_id=${trip_id}`;

    if (trip_type === "ftl_trip") {
      url = `${apiUrl.ftlTripAttachmentsUrl}?trip_id=${trip_id}`;
    } else if (trip_type === "faarms_trip") {
      url = `${apiUrl.faarmsTripAttachmentsUrl}?trip_id=${trip_id}`;
    } else if (trip_type === "old_trip") {
      url = `${apiUrl.tripAttachmentsUrl}?trip_id=${trip_id}`;
    }

    const response = await axiosInterceptor.get(url);

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const GetTripInvoicePaymentByTripId = async (trip_id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.faarmsTripPaymentV1Url}?trip_id=${trip_id}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const FaarmsGetTripInvoicePaymentByTripId = async (trip_id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.faarmsTripPaymentV2Url}?trip_id=${trip_id}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};
export const GetTripPaymentHistory = async (trip_id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.tripPaymentV3Url}?trip_id=${trip_id}`
    );
    return response;
  } catch (error) {
    if (error.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return error.response;
  }
};

export const GetFaarmsTripPaymentHistory = async (trip_id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.faarmsTripPaymentV2Url}?trip_id=${trip_id}`
    );
    return response;
  } catch (error) {
    if (error.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return error.response;
  }
};

export const GetTripItemsForDeliveryInfo = async (tripCode) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.faarmsDeliveryInfoTripItems}?trip_code=${tripCode}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};
