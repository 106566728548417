import React, { useState } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Add, Remove, Rotate90DegreesCw } from "@mui/icons-material";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const ImageWithZoom = ({ image }) => {
  const [rotateImageValue, setRotateImageValue] = useState(0);
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  return (
    <TransformWrapper
      initialScale={1}
      initialPositionX={0}
      initialPositionY={0}
    >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <>
          <div
            style={{
              margin: "10px 0px",
              position: "absolute",
              top: "3px",
              right: "13px",
              zIndex: 99,
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              boxShadow:
                "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
            }}
          >
            <button
              style={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "none",
                border: "none",
                color: "#26cff0",
                cursor: "pointer",
                borderBottom: "0.5px solid #26cff0",
              }}
              onClick={() => zoomIn()}
            >
              <Add />
            </button>
            <button
              style={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "none",
                border: "none",
                color: "#26cff0",
                cursor: "pointer",
                borderBottom: "0.5px solid #26cff0",
              }}
              onClick={() => zoomOut()}
            >
              <Remove />
            </button>
            <button
              style={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "none",
                border: "none",
                color: "#26cff0",
                cursor: "pointer",
                borderBottom: "0.5px solid #26cff0",
              }}
              onClick={() => setRotateImageValue((prevData) => prevData + 90)}
            >
              <Rotate90DegreesCw />
            </button>
            <button
              style={{
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "none",
                border: "none",
                color: "#26cff0",
                cursor: "pointer",
              }}
              onClick={() => resetTransform()}
            >
              <RestartAltIcon />
            </button>
          </div>
          <TransformComponent
            wrapperStyle={{
              width: "100%",
              height: "90%",
            }}
            contentStyle={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={image}
              alt=""
              style={{
                rotate: `${rotateImageValue}deg`,
                maxWidth: "70vw",
                maxHeight: "70vh",
                objectFit: "contain",
              }}
              width={isMobile ? "100%" : "auto"}
              height={isMobile ? "200px" : "600px"}
            />
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
};

export default ImageWithZoom;
