import styled from "styled-components";
import { FontWeight } from "../../../themes/theme";

export const PartnershipStyle = styled.div`
  width: 100%;
  padding: 40px 100px;
  scroll-margin-top: 80px;

  .partnership_heading {
    font-weight: ${FontWeight.bold};
    font-size: 30px;
    line-height: 54px;
  }

  .partnership_company {
    width: 100%;
    margin-top: 30px;
  }

  .partnership_company img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .partnership_company_mobile {
    display: none;
  }

  @media only screen and (max-width: 700px) {
    padding: 0;

    .partnership_heading {
      font-size: 30px;
      line-height: 40px;
      padding: 20px;
    }

    .partnership_company {
      display: none;
    }

    .partnership_company_mobile {
      width: 100%;
      display: block;
    }

    .partnership_company_mobile img {
      width: 100%;
    }
  }
`;
