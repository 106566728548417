import styled from "styled-components";
import {
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";

export const Payment_receivables = styled.div`
  .credit-sidebar {
    flex: 1.7;
    width: 100%;
    height: 100vh;
  }
  .receivable-right-container {
    flex: 9.5;
    width: 80%;
    height: 100vh;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};

    /* padding: 20px 0px; */
    /* position: sticky; */
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }
  .receivable-head-row {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
    padding: 1% 3%;
  }
  .receivable-tab-menuOption-contain {
    display: flex;
    margin: 10px 15px;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
  }
  .receivable-head-container {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
  }
  .credit-right-container {
    flex: 9.5;
    width: 80%;
    height: 100vh;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};

    /* padding: 20px 0px; */
    /* position: sticky; */
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }
  .receivable-single-item-container {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
    padding: 15px 13px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
  }
  .receivable-bold-txt {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: 700;
    font-size: 14px;
  }
  .receivable-vehicle-txt {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: 600;
    font-size: 12px;
  }
  .receivable-amount-big-txt {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: 800;
    font-size: 18px;
  }
  .receivable-amount-txt {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: 600;
    font-size: 12px;
    margin: 3px 0px;
  }
  .receivable-vehicle-txt {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: 600;
    font-size: 12px;
  }
  .receivable-pay-utr-txt {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-family: "poppins";
    font-weight: 500;
    font-size: 12px;
  }
  .receivable-utr-txt {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: 600;
    font-size: 14px;
  }
  .receivable-inputfield {
    width: 93%;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  /* .receivable-record-head {
    color: ${(props) =>
    props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: 700;
    font-size: 16px;
  } */
  .receivable-drawer-container {
    padding: 20px;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  }
  .credit-details-btn {
    color: ${colorPalette.primaryColor};
    font-size: ${typography.paragraph_secondary};
    font-weight: ${FontWeight.semibold};
    border-radius: 50px;
    padding: 8px 16px;
    background: ${colorPalette.white};
    border: 1px solid ${colorPalette.primaryColor};
    cursor: pointer;
  }
  .credit-head-text {
    font-weight: ${FontWeight.bold};
    font-size: 32px;
    line-height: 48px;
    color: ${colorPalette.primaryColor};
  }
  .credit-head-container {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
  }
  .credit-head-row {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
    display: flex;
    justify-content: space-between;

    padding: 1% 3%;
  }
  .credit-search-row {
    padding: 10px;
  }
  .credit-single-item-container {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

    padding: 15px 13px;
    box-shadow: ${colorPalette.boxShadowPrimary};
  }
  .credit-single-top-right {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }
  .credit-customer-namee {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.black};
    /* margin-left: 6px; */
    max-width: 140px;
    /* width: ; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .credit-number-text {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.white
        : colorPalette.paragraphSecondaryolor};
    /* color: ${colorPalette.paragraphSecondaryolor}; */
    font-weight: ${FontWeight.bold};
    font-size: ${typography.heading5};
    line-height: 21px;
  }
  .credit-drawer-container {
    padding: 20px;
  }
  .credit-input-text {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
  }
  .credit-remarks-text {
    font-size: ${typography.heading3};
    font-weight: ${FontWeight.semibold};
  }
  .credit-remarks-inputfield-conatiner {
    width: 100%;
    height: 200px;
    margin-top: 20px;
  }
  .credit-input-container {
    margin: 10px 0px;
  }
  .credit-inputfield {
    width: 93%;
  }
  .credit-drawer-head-text {
    font-size: 22px;
    font-weight: ${FontWeight.semibold};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    margin-bottom: 10%;
  }

  .credit-remarks-textarea {
    width: 93%;
    height: 100%;
    /* resize: none; */
    padding: 20px;
    border: 1px solid ${colorPalette.secondaryWhite};
  }
  .credit-remarks-textarea:focus {
    border-color: ${colorPalette.paragraphColor};
  }
  .credit-remarks-submit-btn-cover {
    width: 100%;
    padding: 18px 0px;
    display: flex;
    justify-content: flex-end;
  }
  .credit-remarks-submit-btn {
    /* width: 20%; */
    background: ${colorPalette.white};
    border: 1px solid ${colorPalette.primaryRed};
    border-radius: 50px;
    color: ${colorPalette.primaryRed};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 18px;
    padding: 8px 23px;
    cursor: pointer;
  }

  .credit-customer-text {
    color: ${colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph};
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 3px 0px;
  }
  .credit-grid-main-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px 30px;
    /* width: 90%; */
    /* justify-content: center; */
  }
  .credit-list-container {
    padding: 25px;
  }
  .credit-light-text {
    font-weight: ${FontWeight.medium};
  }

  .credit-date-text {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.black};
    font-weight: ${FontWeight.semibold};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
    margin-left: 30px;
  }
  .credit-slim-text {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.paragraphSecondaryolor};
    /* color: ${colorPalette.paragraphSecondaryolor}; */
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
  }
  .credit-bold-text {
    color: ${colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 15px;
  }
  .credit-bold-text-large {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.black};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.heading3};
    /* line-height: 15px; */
  }
  .credit-single-top-row {
    display: flex;
    justify-content: space-between;
    margin: 3px 0px;
  }
  .credit-add-quote-btn {
    padding: 8px 16px;
    border: none;
    background: ${colorPalette.primaryColor};
    margin-left: 10px;
    border-radius: 50px;
    color: ${colorPalette.white};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
    cursor: pointer;
    width: 110px;
    /* height: 30px; */
  }
  .credit-save-quote-btn {
    padding: 8px 16px;
    border: none;
    background: ${colorPalette.primaryColor};
    margin-left: 10px;
    border-radius: 50px;
    color: ${colorPalette.white};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
    cursor: pointer;
    width: 110px;
    height: 40px;
  }
  .credit-tab-menuOption-contain {
    display: flex;
    margin: 10px 15px;
  }
  .credit-search-input {
    border: none;
    width: 94%;
    outline: none;
  }
  .credit-search-container {
    background: ${colorPalette.white};
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    width: 70%;
  }
  .credit-tab-menu-option {
    margin: 0px 2%;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading3};
    line-height: 27px;
    cursor: pointer;
  }
  .credit-tab-bottom-rectangle {
    background: ${colorPalette.primaryColor};
    border-radius: 5px 5px 0px 0px;
    height: 4px;
    width: 100%;
  }
  .credit-active-tab {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.semibold};
    border: 2px solid ${colorPalette.primaryColor};
    border-radius: 5px 5px 0px 0px;
  }
  .credit-single-middle-row {
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
    align-items: center;
  }
  .credit-single-bottom-row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .credit-reject-btn {
    border: 1px solid ${colorPalette.primaryRed};
    background: ${colorPalette.white};
    border-radius: 50px;
    color: ${colorPalette.primaryRed};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
    padding: 8px 16px;
    cursor: pointer;
  }
  .single-stop {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 18px;
  }
  .credit-single-stop-text {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 18px;
  }

  .loading_more_data {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${colorPalette.white};
    padding: 10px;
    font-size: ${typography.heading4};
    font-weight: ${FontWeight.semibold};
    color: ${colorPalette.primaryColor};
    width: 100%;
    text-align: center;
  }
  @media only screen and (max-width: 480px) {
    .credit-sidebar {
      flex: 1.7;
      width: 100%;
      height: 100vh;
    }
    .input-div {
      width: 100%;
      /* background-color: ${colorPalette.primaryColor}; */
    }
    .credit-right-container {
      flex: 9.5;
      width: 100vw;
      height: 100vh;
      background-color: ${colorPalette.secondaryWhite};
      /* padding: 20px 0px; */
      /* position: sticky; */
      overflow: scroll;
      display: flex;
      flex-direction: column;
    }
    .credit-head-text {
      font-weight: ${FontWeight.bold};
      font-size: 32px;
      line-height: 48px;
      color: ${colorPalette.primaryColor};
    }
    .credit-head-container {
      background: ${colorPalette.white};
    }
    .credit-head-row {
      background: ${colorPalette.white};
      display: flex;
      justify-content: space-between;

      padding: 0% 0%;
    }
    .credit-search-row {
      padding: 10px;
    }
    .credit-single-item-container {
      background: ${colorPalette.white};
      padding: 12px;
      box-shadow: ${colorPalette.boxShadowPrimary};
    }
    .credit-customer-namee {
      margin-left: 6px;
      max-width: 140px;
      /* width: ; */
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .credit-number-text {
      color: ${colorPalette.paragraphSecondaryolor};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.heading5};
      line-height: 21px;
    }
    .credit-drawer-container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding: 0px 20px;
      /* background-color: ${colorPalette.primaryColor}; */
      /* padding: 20px; */
      width: 100%;
    }
    .credit-input-text {
      font-size: ${typography.heading5};
      font-weight: ${FontWeight.medium};
      /* background-color: ${colorPalette.primaryColor}
    ; */
    }
    .credit-remarks-text {
      font-size: ${typography.heading3};
      font-weight: ${FontWeight.semibold};
      padding: 10px 20px;
    }
    .credit-remarks-inputfield-conatiner {
      width: 100%;
      height: 200px;
      margin-top: 20px;
    }
    .credit-input-container {
      margin: 10px 0px;
      width: 100%;
    }
    .credit-inputfield {
      width: 100%;
    }
    .credit-drawer-head-text {
      font-size: 22px;
      font-weight: ${FontWeight.semibold};
      color: ${colorPalette.primaryColor};
      width: 100%;
      /* background-color: ${colorPalette.primaryColor}; */
      padding: 10px 0px;
      margin-bottom: 10%;
    }

    .credit-remarks-textarea {
      width: 93%;
      height: 100%;
      /* resize: none; */
      padding: 20px;
      border: 1px solid ${colorPalette.secondaryWhite};
    }
    .credit-remarks-textarea:focus {
      border-color: ${colorPalette.paragraphColor};
    }
    .credit-remarks-submit-btn-cover {
      width: 100%;
      padding: 18px 0px;
      display: flex;
      justify-content: flex-end;
    }
    .credit-remarks-submit-btn {
      /* width: 20%; */
      background: ${colorPalette.white};
      border: 1px solid ${colorPalette.primaryRed};
      border-radius: 50px;
      color: ${colorPalette.primaryRed};
      font-weight: ${FontWeight.bold};
      margin-right: 20px;
      font-size: ${typography.paragraph};
      line-height: 18px;
      padding: 8px 23px;
      cursor: pointer;
    }

    .credit-customer-text {
      color: ${colorPalette.paragraphSecondaryolor};
      font-weight: ${FontWeight.medium};
      font-size: ${typography.paragraph};
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 3px 0px;
    }
    .credit-grid-main-container {
      display: grid;
      grid-template-columns: auto;
      gap: 30px 30px;
      /* height: 100vh; */
      /* width: 90%; */
      /* justify-content: center; */
    }
    .credit-list-container {
      padding: 25px;
    }
    .credit-date-text {
      color: ${colorPalette.paragraphSecondaryolor};
      font-weight: ${FontWeight.semibold};
      font-size: ${typography.paragraph_secondary};
      line-height: 15px;
    }
    .credit-slim-text {
      color: ${colorPalette.paragraphSecondaryolor};
      font-weight: ${FontWeight.medium};
      font-size: ${typography.paragraph_secondary};
      line-height: 15px;
    }
    .credit-bold-text {
      color: ${colorPalette.paragraphSecondaryolor};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph};
      line-height: 15px;
    }
    .credit-single-top-row {
      display: flex;
      justify-content: space-between;
      margin: 3px 0px;
    }
    .credit-add-quote-btn {
      padding: 8px 16px;
      border: none;
      background: ${colorPalette.primaryColor};
      margin-left: 10px;
      border-radius: 50px;
      color: ${colorPalette.white};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph_secondary};
      line-height: 15px;
      cursor: pointer;
      width: 110px;
      /* height: 30px; */
    }
    .credit-save-quote-btn {
      padding: 8px 16px;
      border: none;
      background: ${colorPalette.primaryColor};
      margin-left: 10px;
      border-radius: 50px;
      color: ${colorPalette.white};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph_secondary};
      line-height: 15px;
      cursor: pointer;
      width: 110px;
      height: 40px;
    }
    .credit-tab-menuOption-contain {
      display: flex;
      margin: 10px 15px;
    }
    .credit-search-input {
      border: none;
      width: 94%;
      outline: none;
    }
    .credit-search-container {
      background: ${colorPalette.white};
      padding: 15px;
      display: flex;
      justify-content: flex-start;
      width: 70%;
    }
    .credit-tab-menu-option {
      margin: 0px 2%;
      margin-bottom: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: ${colorPalette.paragraphColor};
      font-weight: ${FontWeight.medium};
      font-size: ${typography.heading3};
      line-height: 27px;
      cursor: pointer;
    }
    .credit-tab-bottom-rectangle {
      background: ${colorPalette.primaryColor};
      border-radius: 5px 5px 0px 0px;
      height: 4px;
      width: 100%;
    }
    .credit-active-tab {
      color: ${colorPalette.primaryColor};
      font-weight: ${FontWeight.semibold};
      border: 2px solid ${colorPalette.primaryColor};
      border-radius: 5px 5px 0px 0px;
    }
    .credit-single-middle-row {
      display: flex;
      justify-content: space-between;
      margin-top: 7px;
    }
    .credit-single-bottom-row {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    .credit-reject-btn {
      border: 1px solid ${colorPalette.primaryRed};
      background: ${colorPalette.white};
      border-radius: 50px;
      color: ${colorPalette.primaryRed};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph_secondary};
      line-height: 15px;
      padding: 8px 16px;
      cursor: pointer;
    }
    .single-stop {
      color: ${colorPalette.primaryColor};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph};
      line-height: 18px;
    }
    .credit-single-stop-text {
      color: ${colorPalette.primaryColor};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph};
      line-height: 18px;
    }

    .loading_more_data {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: white;
      padding: 10px;
      font-size: ${typography.heading4};
      font-weight: ${FontWeight.semibold};
      color: ${colorPalette.primaryColor};
      width: 100%;
      text-align: center;
    }
  }
`;

export const Payment_receiavble_drawer = styled.div`
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  height: 100%;
  .receivable-inputfield {
    width: 93%;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.white};
  }
  .receivable-record-head {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: 700;
    font-size: 16px;
  }
  .receivable-record-head_details {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.paragraphColor};
    font-weight: 500;
    font-size: 12px;
  }
  .receivable-record-head_details_date {
    /* background-color: red; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.paragraphColor};
    font-weight: 500;
    font-size: 12px;
  }
  .receivable-drawer-container {
    padding: 20px;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  }
  .receivable-utr-txt {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: 600;
    font-size: 14px;
  }
  .tds_deduction_add_route_msg_click {
    font-size: 10px;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .receivable-pay-utr-txt {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-family: "poppins";
    font-weight: 500;
    font-size: 12px;
  }
  .receivable-pay-amount-txt {
    font-size: 12px;
    font-weight: 500;
    font-family: "poppins";
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    /* font-weight: 600; */
  }
  .receivable-pay-remarks-text {
    font-weight: 400;
    font-size: 12px;
    width: 100%;
    word-wrap: break-word;
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.paragraphSecondaryolor};
    /* color: #575757; */
  }
  .receivable-pay-amount-big-txt {
    font-size: 14px;
    color: #454545;
    font-weight: 600;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .tds_form {
    padding: 5px;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.lightGreen : colorPalette.lightGreen};

    margin-top: 5px;
  }
  .receivable-utr-txt_tds {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: 500;
    font-size: 10px;
  }
`;
