import React, { useEffect } from "react";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import UploadImage from "../../../assets/upload.svg";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import success from "../../../assets/dashboard_icons/icons8-truck.gif";
import { ArrowBack, Add, Edit, Close } from "@mui/icons-material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { GoodsDeliveredMainSection } from "./goodsDeliveredStyle";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { colorPalette } from "../../../themes/theme";
import { ButtonRectangle } from "../../../themes/components/button";
import axiosInterceptor from "../../../config/https";
import apiUrl from "../../../url/apiUrl";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal as ModalAntd } from "antd";
import SomethingWentWrong from "../../../components/somethingWentWrong/SomethingWentWrong.jsx";
import { saveBlob } from "../../../utility/saveBlobImage.js";
import { useSelector } from "react-redux";

const GoodsDelivered = () => {
  const history = useHistory();
  const { tripId } = useParams();
  const [approxCostDetails, setApproxCostDetails] = useState({
    z: "",
  });
  const [approxCostDetailsArr, setApproxCostDetailsArr] = useState([]);

  const [loadingPage, setLoadingPage] = useState(false);
  const [podSoftCopyAttachmentFront, setPodSoftCopyAttachmentFront] = useState({
    file: "",
    attachment_type: "",
    attachment_tag: "pod_softcopy_front",
  });
  const [podSoftCopyAttachmentEnd, setPodSoftCopyAttachmentEnd] = useState({
    file: "",
    attachment_type: "",
    attachment_tag: "pod_softcopy_back",
  });

  const [odometerReadingAttachment, setOdometerReadingAttachment] = useState({
    file: "",
    attachment_type: "",
    attachment_tag: "odometer_reading",
  });

  const [goodsDeliveryFormData, setGoodsDeliveryFormData] = useState({
    remarks: "",
    unloaded_on: "",
    trip_complete_meter_reading: "",
    is_goods_damaged: null,
    is_goods_shortage: null,
    will_billing_party_pay_detention_charges: null,
    will_transporter_receive_detention_charges: null,
  });

  console.log(goodsDeliveryFormData, "goodsDeliveryFormData");

  const [labourCharges, setLabourCharges] = useState("");
  const [detensionCharges, setDetentionCharges] = useState("");
  const [numberOfPieceDelivered, setNumberOfPieceDelivered] = useState("");

  const [showSaveButton, setShowSaveButton] = useState(false);
  const [maxFileSizeError, setMaxFileSizeError] = useState(false);
  const [errUpload, setErrUpload] = useState("");
  const [errGoodsDamage, seterrGoodsDamage] = useState("");
  const [errGoodsShortage, setErrGoodsShortage] = useState("");
  const [errPodCopy, setErrPodCopy] = useState("");
  const [errOdoReading, setErrOdoReading] = useState("");
  const [errPodFront, setErrPodFront] = useState("");
  const [errPodBack, setErrPodBack] = useState("");

  const [errLabourCharge, setErrLabourCharge] = useState("");
  const [errDetensionCharge, setErrDetensionCharge] = useState("");
  const [errNumberOfPicesDeliverd, setErrNumberOfPicesDeliverd] = useState("");

  const [errRemarks, setErrRemarks] = useState("");
  const [billingPartyditentionError, setBillingPartyditentionError] =
    useState("");
  const [trasnporterDitentionError, setTransporterDitentionError] =
    useState("");
  const [errMeterReading, setErrMeterReading] = useState("");
  const [documentTypeList, setDocumentTypeList] = useState(["others"]);
  const [loadSource, setLoadSource] = useState("manual");
  const [tripStartMeterReadingError, setTripStartMeterReadingError] =
    useState("");
  const [attachmentListArray, setAttachmentListArray] = useState([
    {
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    },
  ]);
  const [
    attachmentListArrayModifiedForApiCall,
    setAttachmentListArrayModifiedForApiCall,
  ] = useState([]);
  // const [errPodRemarks, setErrPodRemarks] = useState("");
  const [errCompleteTrip, setErrCompleteTrip] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [state, setState] = React.useState({
    right: false,
  });

  const selectedTripFromRedux = useSelector((state) => state.trip.selectedTrip);
  const [tripCost, setTripCost] = useState([
    {
      cost_type: "unloading_labour_charges",
      cost_amount: 0,
      trip_id: "",
    },
    {
      cost_type: "unloading_detention_charges",
      cost_amount: 0,
      trip_id: "",
    },
  ]);

  useEffect(() => {
    let flag = false;
    if (goodsDeliveryFormData.is_goods_damaged === null) {
      flag = true;
    }
    if (goodsDeliveryFormData.is_goods_shortage === null) {
      flag = true;
    }
    if (!goodsDeliveryFormData.remarks) {
      flag = true;
    }
    if (!goodsDeliveryFormData.trip_complete_meter_reading) {
      flag = true;
    }
    if (!goodsDeliveryFormData.unloaded_on) {
      flag = true;
    }
    if (!podSoftCopyAttachmentFront.file) {
      flag = true;
    }
    if (!podSoftCopyAttachmentEnd.file) {
      flag = true;
    }
    if (!labourCharges) {
      flag = true;
    }
    if (!detensionCharges) {
      flag = true;
    }
    if (!numberOfPieceDelivered) {
      flag = true;
    }
    if (!odometerReadingAttachment.file) {
      flag = true;
    }

    setDisableSaveButton(flag);
  }, [
    goodsDeliveryFormData,
    labourCharges,
    detensionCharges,
    numberOfPieceDelivered,
    odometerReadingAttachment,
    podSoftCopyAttachmentFront,
    podSoftCopyAttachmentEnd,
  ]);

  useEffect(() => {
    let array = [];
    attachmentListArray.map((ele, index) => {
      let num = index + 3;
      let tagname = "tag_" + num;
      let attachmenttype = "attachment_type_" + num;
      let file = "file_" + num;
      if (ele.attachment_file) {
        array.push({
          [tagname]: ele.attachment_tag,
          [attachmenttype]:
            ele?.attachment_file?.type === "image/jpeg" ||
            ele?.attachment_file?.type === "image/png"
              ? "image"
              : "pdf",
          [file]: ele.attachment_file,
        });
      }
    });
    setAttachmentListArrayModifiedForApiCall(array);
  }, [attachmentListArray]);

  useEffect(() => {
    let maxfileSize;
    attachmentListArray.map((attachment) => {
      console.log(
        attachment.attachment_file?.size,
        "attachment.attachment_file"
      );
      if (
        attachment.attachment_file?.size > process.env.REACT_APP_MAX_FILE_SIZE
      ) {
        return (maxfileSize = true);
      }
      setMaxFileSizeError(maxfileSize);
    });
  }, [attachmentListArray]);

  console.log(
    attachmentListArrayModifiedForApiCall,
    "attachmentListArrayModifiedForApiCall"
  );

  useEffect(() => {
    setTripCost([
      {
        cost_type: "unloading_labour_charges",
        cost_amount: labourCharges,
        trip_id: tripId,
      },
      {
        cost_type: "unloading_detention_charges",
        cost_amount: detensionCharges,
        trip_id: tripId,
      },
    ]);
  }, [labourCharges, detensionCharges]);

  useEffect(() => {
    if (
      goodsDeliveryFormData.unloaded_on &&
      goodsDeliveryFormData.is_goods_damaged !== "" &&
      goodsDeliveryFormData.is_goods_shortage !== "" &&
      podSoftCopyAttachmentFront.file &&
      podSoftCopyAttachmentEnd.file &&
      goodsDeliveryFormData.trip_complete_meter_reading !== "" &&
      odometerReadingAttachment.file &&
      labourCharges !== "" &&
      detensionCharges !== "" &&
      numberOfPieceDelivered !== ""
    ) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }, [
    goodsDeliveryFormData,
    podSoftCopyAttachmentFront,
    podSoftCopyAttachmentEnd,

    odometerReadingAttachment,
    labourCharges,
    detensionCharges,
    numberOfPieceDelivered,
  ]);

  const handleLabourCharge = (e) => {
    if (e.target.value < 0 || e.target.value === "-0") return;
    setLabourCharges(e.target.value);
    seterrGoodsDamage("");
    setErrCompleteTrip("");
    setErrDetensionCharge("");
    setErrNumberOfPicesDeliverd("");
    setErrLabourCharge("");
    setErrMeterReading("");
    setErrOdoReading("");
    setErrPodFront("");
    setErrPodBack("");

    // setErrPodRemarks("")
    setErrRemarks("");
    setErrUpload("");
  };
  const handleDetetionCharge = (e) => {
    if (e.target.value < 0 || e.target.value === "-0") return;
    setDetentionCharges(e.target.value);
    seterrGoodsDamage("");
    setErrCompleteTrip("");
    setErrDetensionCharge("");
    setErrNumberOfPicesDeliverd("");
    setErrLabourCharge("");
    setErrMeterReading("");
    setErrOdoReading("");
    setErrPodFront("");
    setErrPodBack("");

    // setErrPodRemarks("")
    setErrRemarks("");
    setErrUpload("");
  };

  const handleDeliveryFormInput = (e) => {
    const { name, value, type, checked } = e.target;

    setGoodsDeliveryFormData({
      ...goodsDeliveryFormData,
      [name]: type === "checkbox" ? checked : value,
    });
    seterrGoodsDamage("");
    setErrCompleteTrip("");
    setErrDetensionCharge("");
    setErrNumberOfPicesDeliverd("");
    setErrLabourCharge("");
    setErrMeterReading("");
    setErrOdoReading("");
    setErrPodFront("");
    setErrPodBack("");

    // setErrPodRemarks("")
    setErrRemarks("");
    setErrUpload("");
  };

  const handleGoToTrips = () => {
    localStorage.setItem("FlagForApiCall", false);
    history.push("/ftl-trips");
  };

  const handleOnFileChange = async (e) => {
    const newFile = await saveBlob(e.target.files[0]);

    if (newFile.size > process.env.REACT_APP_MAX_FILE_SIZE) {
      setMaxFileSizeError(true);
    } else {
      setMaxFileSizeError(false);
    }
    if (e.target.name === "odometer_reading") {
      setOdometerReadingAttachment({
        ...odometerReadingAttachment,
        file: newFile,
        attachment_type: newFile.type === "application/pdf" ? "pdf" : "image",
      });
    } else if (e.target.name === "pod_softcopy_front") {
      setPodSoftCopyAttachmentFront({
        ...podSoftCopyAttachmentFront,
        file: newFile,
        attachment_type: newFile.type === "application/pdf" ? "pdf" : "image",
      });
    } else if (e.target.name === "pod_softcopy_end") {
      setPodSoftCopyAttachmentEnd({
        ...podSoftCopyAttachmentEnd,
        file: newFile,
        attachment_type: newFile.type === "application/pdf" ? "pdf" : "image",
      });
    }
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  const isFormValid = () => {
    let isValid = true;
    const errUpload = {};
    const errGoodsDamage = {};
    const errGoodsShortage = {};
    const errPodFront = {};
    const errPodBack = {};

    const errMeterReading = {};
    const errOdoReading = {};
    const errLabourCharge = {};
    const errDetensionCharge = {};
    const errNumberOfPicesDeliverd = {};
    const errBillPartyInput = {};
    const errTranporterRecieveDetention = {};
    const errRemarks = {};
    // const errPodRemarks = {};

    if (goodsDeliveryFormData.unloaded_on == "") {
      errUpload.errUploadOn = "Please select unloaded date";
      isValid = false;
    }
    if (goodsDeliveryFormData.is_goods_damaged == null) {
      errGoodsDamage.errGoods = "Please select an option";
      isValid = false;
    }
    if (goodsDeliveryFormData.is_goods_shortage == null) {
      errGoodsShortage.errShortage = "Please select an option";
      isValid = false;
    }
    if (podSoftCopyAttachmentFront.file == "") {
      errPodFront.errPODFront = "Please upload POD front copy";
      isValid = false;
    }
    if (podSoftCopyAttachmentEnd.file == "") {
      errPodBack.errPODBack = "Please upload POD end copy";
      isValid = false;
    }
    if (goodsDeliveryFormData.trip_complete_meter_reading == "") {
      errMeterReading.errTripReadin = "Please enter meter reading";
      isValid = false;
    }
    if (odometerReadingAttachment.file == "") {
      errOdoReading.errOdo = "Please upload Odometer reading";
      isValid = false;
    }
    if (labourCharges == "") {
      errLabourCharge.errLabour = "Please enter labour charges";
      isValid = false;
    }
    if (detensionCharges == "") {
      errDetensionCharge.errDetension = "Please enter detension charges";
      isValid = false;
    }
    if (numberOfPieceDelivered == "") {
      errNumberOfPicesDeliverd.errPieced =
        "Please enter no of pieces delivered charges";
      isValid = false;
    }
    if (goodsDeliveryFormData.remarks == "") {
      errRemarks.errDelevery = "Please enter remarks";
      isValid = false;
    }

    if (
      goodsDeliveryFormData.will_billing_party_pay_detention_charges == null
    ) {
      errBillPartyInput.errMsg = "Please select any one option";
      isValid = false;
    }

    if (
      goodsDeliveryFormData.will_transporter_receive_detention_charges == null
    ) {
      errTranporterRecieveDetention.errMsg = "Please select any one option";
      isValid = false;
    }
    // if (goodsDeliveryFormData.pod_remarks == "") {
    //   errPodRemarks.errPodRemark = "Please enter POD remarks";
    //   isValid = false;
    // }
    setErrUpload(errUpload);
    seterrGoodsDamage(errGoodsDamage);
    setErrGoodsShortage(errGoodsShortage);
    setErrPodCopy(errPodCopy);
    setErrMeterReading(errMeterReading);
    setErrOdoReading(errOdoReading);
    setErrLabourCharge(errLabourCharge);
    setErrDetensionCharge(errDetensionCharge);
    setErrNumberOfPicesDeliverd(errNumberOfPicesDeliverd);
    setErrRemarks(errRemarks);
    setBillingPartyditentionError(errBillPartyInput);
    setTransporterDitentionError(errTranporterRecieveDetention);
    // setErrPodRemarks(errPodRemarks);
    return isValid;
  };

  const handleFormSubmit = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("trip_status", "goods_delivered");
    formData.append("trip_cost_str", JSON.stringify(tripCost));
    formData.append("remarks", goodsDeliveryFormData.remarks);
    formData.append("pod_remarks", goodsDeliveryFormData.pod_remarks);
    formData.append(
      "unloaded_on",
      goodsDeliveryFormData.unloaded_on + "T00:00:00.000000"
    );
    formData.append(
      "trip_complete_meter_reading",
      goodsDeliveryFormData.trip_complete_meter_reading
    );
    formData.append("is_goods_damaged", goodsDeliveryFormData.is_goods_damaged);
    formData.append(
      "is_goods_shortage",
      goodsDeliveryFormData.is_goods_shortage
    );
    formData.append(
      "will_transporter_receive_detention_charges",
      goodsDeliveryFormData.will_transporter_receive_detention_charges === null
        ? false
        : goodsDeliveryFormData.will_transporter_receive_detention_charges
    );
    formData.append(
      "will_billing_party_pay_detention_charges",
      goodsDeliveryFormData.will_billing_party_pay_detention_charges === null
        ? false
        : goodsDeliveryFormData.will_billing_party_pay_detention_charges
    );
    formData.append("no_of_pieces_delivered", numberOfPieceDelivered);

    formData.append("file_1", podSoftCopyAttachmentFront.file);
    formData.append("file_2", odometerReadingAttachment.file);
    formData.append("file_3", podSoftCopyAttachmentEnd.file);

    formData.append("tag_1", podSoftCopyAttachmentFront.attachment_tag);
    formData.append("tag_2", odometerReadingAttachment.attachment_tag);
    formData.append("tag_3", podSoftCopyAttachmentEnd.attachment_tag);

    formData.append(
      "attachment_type_1",
      podSoftCopyAttachmentFront.attachment_type
    );
    formData.append(
      "attachment_type_2",
      odometerReadingAttachment.attachment_type
    );
    formData.append(
      "attachment_type_3",
      odometerReadingAttachment.attachment_type
    );
    if (attachmentListArrayModifiedForApiCall.length) {
      attachmentListArrayModifiedForApiCall.map((ele, index) => {
        const keys = Object.keys(ele);
        keys.map((e) => {
          formData.append(e, ele[e]);
        });
      });
    }

    var config = {
      method: "patch",
      url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
        tripId
      )}/goods-delivered`,
      headers: {
        "Content-Type": "Multipart/form-data",
      },
      data: formData,
    };

    return new Promise((resolve, reject) => {
      axiosInterceptor(config)
        .then(function (response) {
          if (response.status == 200) {
            resolve(response);
            history.push("/ftl-trips");
            toast.success("Status changed successfully!");
            setIsLoading(false);
          }
        })
        .catch(function (e) {
          if (e.response.status === 403) {
            localStorage.clear();
            window.location.reload();
          } else if (e.response.status === 401) {
            toast.error(401 + " " + e.response.data.error);
            setIsLoading(false);
          }
          toast.error(e.response.data.detail);
          resolve(e.response);
          setIsLoading(false);
        });
    });
  };
  const checkFormValidation = () => {
    var isValid = isFormValid();

    if (isValid) {
      handleFormSubmit();
    }
  };

  const handleAttachmentChange = async (e, index) => {
    e.preventDefault();
    const blobFile = await saveBlob(e.target.files[0]);
    //
    let key = e.target.name;
    let value;
    if (key == "attachment_file") {
      value = blobFile;
      //
    } else {
      value = e.target.value;
    }

    let attachmentArrayTemp = [...attachmentListArray];

    if (index == 0 && loadSource != "trip_invoice") {
      attachmentArrayTemp[0]["attachment_tag"] = "others";
    }
    attachmentArrayTemp[index][key] = value;
    setAttachmentListArray(attachmentArrayTemp);
    handleAddAttachment(e);
  };

  const handleAddAttachment = (e) => {
    let attachmentTemp = [...attachmentListArray];
    if (attachmentListArray.length < 3)
      attachmentTemp.push({
        attachment_tag: "others",
        attachment_type: "",
        attachment_file: null,
      });
    setAttachmentListArray(attachmentTemp);
    e.preventDefault();
  };

  return (
    <GoodsDeliveredMainSection>
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={99999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong
            backButtonLink={`/ftl-trips/${tripId}/goods-delivered`}
            buttonText="Close"
          />
        </div>
      </ModalAntd>
      <ToastContainer />
      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Goods Delivered" />
        </div>
      ) : (
        <div
          style={{ width: "15%", height: "100vh", position: "fixed" }}
          className="complete-trip-sidebar"
        >
          <SideBarMain />
        </div>
      )}

      {loadingPage ? (
        <div className="goods-delivered-loading-image">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={success}
              alt=""
              width="120px"
              height="90px"
              style={{ marginTop: "200px" }}
            />
            <h6 style={{ color: "grey" }}>loading data...</h6>
          </div>
        </div>
      ) : (
        <div className="goods-delivered-form">
          <div className="goods-delivered-form-header">
            <ArrowBack
              style={{ cursor: "pointer" }}
              onClick={handleGoToTrips}
            />{" "}
            <h4 className="complete-trip-head-text">Goods Delivery</h4>
          </div>
          <div className="goods-delivery-input-grid">
            {/* uploaded on start */}
            <div className="goods-delivery-input">
              {/* <div></div> */}
              <span className="goods-delivery-input-span">
                Unloaded on<sup>*</sup>
              </span>
              <TextField
                size="small"
                autoComplete="off"
                className="goods-delivery-input-field"
                variant="outlined"
                type="date"
                inputProps={{
                  max: new Date().toISOString().split("T")[0],
                  min: selectedTripFromRedux?.trip_additional_data?.arrived_on.split(
                    "T"
                  )[0],
                }}
                value={goodsDeliveryFormData.unloaded_on}
                name="unloaded_on"
                onChange={(e) => {
                  handleDeliveryFormInput(e);
                }}
              />

              {Object.keys(errUpload).map((key) => {
                return <h6 style={{ color: "#E06666" }}>{errUpload[key]}</h6>;
              })}
            </div>
            {/* uploaded on end */}

            {/* goods damange start */}
            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Is goods damaged?<sup>*</sup>
              </span>
              <TextField
                id="filled-select-currency"
                select
                value={goodsDeliveryFormData.is_goods_damaged}
                placeholder="select"
                className="input_field_form"
                color="success"
                size="small"
                name="is_goods_damaged"
                onChange={(e) => {
                  handleDeliveryFormInput(e);
                }}
              >
                <MenuItem key={0} value={"select"} disabled>
                  -----select-----
                </MenuItem>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
              {Object.keys(errGoodsDamage).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errGoodsDamage[key]}</h6>
                );
              })}
            </div>
            {/* goodds damange end */}

            {/* goods shortage start */}
            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Is goods shortage?<sup>*</sup>
              </span>
              <TextField
                id="filled-select-currency"
                select
                value={goodsDeliveryFormData.is_goods_shortage}
                placeholder="select"
                className="input_field_form"
                color="success"
                size="small"
                name="is_goods_shortage"
                onChange={(e) => {
                  handleDeliveryFormInput(e);
                }}
              >
                <MenuItem key={0} value={"select"} disabled>
                  -----select-----
                </MenuItem>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
              {Object.keys(errGoodsShortage).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errGoodsShortage[key]}</h6>
                );
              })}
            </div>
            {/* goods shortage end */}

            {/* trip complete meter reading start */}
            {/* <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Trip Complete Meter Reading<sup>*</sup>
              </span>
              <TextField
                id="filled-select-currency"
                type="number"
                value={goodsDeliveryFormData.trip_complete_meter_reading}
                placeholder="Enter Meter Reading"
                className="input_field_form"
                color="success"
                size="small"
                name="trip_complete_meter_reading"
                onChange={(e) => {
                  handleDeliveryFormInput(e);
                }}
              ></TextField>
              {Object.keys(errMeterReading).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errMeterReading[key]}</h6>
                );
              })}
            </div> */}
            {/* trip complete meter reading end */}

            {/* Labour Charges start */}
            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Unloading Labour Charges <sup>*</sup>
              </span>
              <TextField
                size="small"
                autoComplete="off"
                className="input_field_form "
                variant="outlined"
                placeholder={`Enter Labour charges`}
                type="number"
                value={labourCharges}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      ₹{" "}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleLabourCharge(e)}
              />
              {Object.keys(errLabourCharge).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errLabourCharge[key]}</h6>
                );
              })}
            </div>
            {/* Labour Charges end */}

            {/* Detention Charges start */}
            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Unloading Detention Charges <sup>*</sup>
              </span>
              <TextField
                size="small"
                autoComplete="off"
                className="input_field_form "
                variant="outlined"
                placeholder={`Enter Labour charges`}
                type="number"
                value={detensionCharges}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      ₹{" "}
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleDetetionCharge(e)}
              />
              {Object.keys(errDetensionCharge).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>
                    {errDetensionCharge[key]}
                  </h6>
                );
              })}
            </div>
            {/* Number of peaces dliveried */}
            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Number Of Pieces Delivered <sup>*</sup>
              </span>
              <TextField
                size="small"
                autoComplete="off"
                className="input_field_form "
                variant="outlined"
                placeholder={`Enter no of pieces delivered`}
                type="number"
                value={numberOfPieceDelivered}
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment
                //       position="start"
                //       style={{ fontFamily: "sans-serif" }}
                //     >
                //       ₹{" "}
                //     </InputAdornment>
                //   ),
                // }}
                onChange={(e) => setNumberOfPieceDelivered(e.target.value)}
              />
              {Object.keys(errNumberOfPicesDeliverd).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>
                    {errNumberOfPicesDeliverd[key]}
                  </h6>
                );
              })}
            </div>
            {/* POD Soft copy start */}
            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                POD Soft Copy Front<sup>*</sup>
              </span>
              <TextField
                size="small"
                id="dropdown"
                disabled
                autoComplete="off"
                className="input_field_form "
                variant="outlined"
                placeholder="Upload POD Soft Copy"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        cursor: "pointer",
                        color: colorPalette.primaryColor,
                        fontWeight: "600",
                      }}
                    >
                      <button
                        className="upload_button"
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "-14px",
                        }}
                      >
                        <input
                          type="file"
                          name="pod_softcopy_front"
                          id="pod_softcopy_front"
                          accept="image/png, image/jpeg, application/pdf"
                          hidden
                          onChange={(e) => handleOnFileChange(e)}
                          style={{
                            backgroundColor: colorPalette.secondaryWhite,
                          }}
                        />
                        <label htmlFor="pod_softcopy_front">
                          <img
                            src={UploadImage}
                            alt=""
                            width="20px"
                            height="20px"
                          />
                        </label>
                      </button>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              <span className="file_name">
                {podSoftCopyAttachmentFront.file.name}
              </span>
              {Object.keys(errPodFront).map((key) => {
                return <h6 style={{ color: "#E06666" }}>{errPodFront[key]}</h6>;
              })}
            </div>
            {/* POD Soft copy end */}
            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                POD Soft Copy End
                <sup>*</sup>
              </span>
              <TextField
                size="small"
                id="dropdown"
                disabled
                autoComplete="off"
                className="input_field_form "
                variant="outlined"
                placeholder="Upload POD Soft Copy Front"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        cursor: "pointer",
                        color: colorPalette.primaryColor,
                        fontWeight: "600",
                      }}
                    >
                      <button
                        className="upload_button"
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "-14px",
                        }}
                      >
                        <input
                          type="file"
                          name="pod_softcopy_end"
                          id="pod_softcopy_end"
                          accept="image/png, image/jpeg, application/pdf"
                          hidden
                          onChange={(e) => handleOnFileChange(e)}
                          style={{
                            backgroundColor: colorPalette.secondaryWhite,
                          }}
                        />
                        <label htmlFor="pod_softcopy_end">
                          <img
                            src={UploadImage}
                            alt=""
                            width="20px"
                            height="20px"
                          />
                        </label>
                      </button>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              <span className="file_name">
                {podSoftCopyAttachmentEnd.file.name}
              </span>
              {Object.keys(errPodBack).map((key) => {
                return <h6 style={{ color: "#E06666" }}>{errPodBack[key]}</h6>;
              })}
              <div className="error-trip">
                {podSoftCopyAttachmentEnd.file.name
                  ? podSoftCopyAttachmentEnd.file.size >
                    process.env.REACT_APP_MAX_FILE_SIZE
                    ? `File size is too large.( < ${
                        process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                      }MB)`
                    : null
                  : null}
              </div>
            </div>
            {/* Odometer Reading start */}
            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Trip End Odometer Reading (with attachment)<sup>*</sup>
              </span>
              <TextField
                size="small"
                // type="number"
                id="dropdown"
                autoComplete="off"
                value={goodsDeliveryFormData.trip_complete_meter_reading}
                onChange={(e) => {
                  if (
                    /^\d+$/.test(e.target.value) &&
                    parseInt(e.target.value, 10) > 0
                  ) {
                    setTripStartMeterReadingError("");
                  } else {
                    setTripStartMeterReadingError(
                      "Please enter valid Meter reading"
                    );
                  }
                  handleDeliveryFormInput(e);
                }}
                name="trip_complete_meter_reading"
                className="input_field_form "
                variant="outlined"
                placeholder="Enter Odometer Reading"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        cursor: "pointer",
                        color: colorPalette.primaryColor,
                        fontWeight: "600",
                      }}
                    >
                      <button
                        className="upload_button"
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "-14px",
                        }}
                      >
                        <input
                          type="file"
                          id="odometer_reading"
                          name="odometer_reading"
                          accept="image/png, image/jpeg, application/pdf"
                          onChange={(e) => handleOnFileChange(e)}
                          hidden
                          style={{
                            backgroundColor: colorPalette.secondaryWhite,
                          }}
                        />
                        <label htmlFor="odometer_reading">
                          <img
                            src={UploadImage}
                            alt=""
                            width="20px"
                            height="20px"
                          />
                        </label>
                      </button>
                    </InputAdornment>
                  ),
                }}
              ></TextField>{" "}
              <span className="file_name">
                {odometerReadingAttachment.file.name}
              </span>
              {Object.keys(errOdoReading).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errOdoReading[key]}</h6>
                );
              })}
              <div className="error-trip">
                {odometerReadingAttachment.file.name
                  ? odometerReadingAttachment.file.size >
                    process.env.REACT_APP_MAX_FILE_SIZE
                    ? `File size is too large.( < ${
                        process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                      }MB)`
                    : null
                  : null}
                {tripStartMeterReadingError ? tripStartMeterReadingError : null}
              </div>
            </div>
            {/* Odometer Reading end */}

            {/* <div className="goods-delivery-input">
              <div className="flex-item-center mb-20">
                <input
                  className="checkbox"
                  onChange={(e) => handleDeliveryFormInput(e)}
                  type="checkbox"
                  value={
                    goodsDeliveryFormData.will_billing_party_pay_detention_charges
                  }
                  name="will_billing_party_pay_detention_charges"
                  id="will_billing_party_pay_detention_charges"
                />
                <span className="goods-delivery-input-span">
                  Will billing party pay detention charges?
                </span>{" "}
              </div>
              <div className="flex-item-center">
                <input
                  className="checkbox"
                  onChange={(e) => handleDeliveryFormInput(e)}
                  type="checkbox"
                  value={
                    goodsDeliveryFormData.will_transporter_receive_detention_charges
                  }
                  name="will_transporter_receive_detention_charges"
                  id="will_transporter_receive_detention_charges"
                />
                <span className="goods-delivery-input-span">
                  Will transporter receive detention charges?
                </span>{" "}
              </div>
            </div> */}

            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Will billing party pay detention charges?<sup>*</sup>
              </span>
              <TextField
                id="filled-select-currency"
                select
                value={
                  goodsDeliveryFormData.will_billing_party_pay_detention_charges
                }
                placeholder="select"
                className="input_field_form"
                color="success"
                size="small"
                name="will_billing_party_pay_detention_charges"
                onChange={(e) => {
                  handleDeliveryFormInput(e);
                }}
              >
                <MenuItem key={0} value="" disabled>
                  -----select-----
                </MenuItem>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
              {Object.keys(billingPartyditentionError).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>
                    {billingPartyditentionError[key]}
                  </h6>
                );
              })}
            </div>

            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Will transporter receive detention charges?<sup>*</sup>
              </span>
              <TextField
                id="filled-select-currency"
                select
                value={
                  goodsDeliveryFormData.will_transporter_receive_detention_charges
                }
                placeholder="select"
                className="input_field_form"
                color="success"
                size="small"
                name="will_transporter_receive_detention_charges"
                onChange={(e) => {
                  handleDeliveryFormInput(e);
                }}
              >
                <MenuItem key={0} value={"select"} disabled>
                  -----select-----
                </MenuItem>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
              {Object.keys(trasnporterDitentionError).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>
                    {trasnporterDitentionError[key]}
                  </h6>
                );
              })}
            </div>

            {/* ----------------------------------multiple attachment start---------------------- */}
            {/* <div className="trip-list-modal-attachment-container"> */}
            {attachmentListArray.map((item, index) => {
              return (
                <div key={index} className="trip-list-modal-row mobile-row">
                  <div className="trip-list-modal-input-group">
                    <h4 className="trip-list-modal-head">
                      Document type {index + 1}
                    </h4>
                    {/*new attchment*/}
                    <TextField
                      size="small"
                      id="dropdown"
                      style={{ width: "100%" }}
                      // select
                      autoComplete="off"
                      disabled
                      className="trip-list-input-attachment dropdown-container"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      value={"others"}
                      onChange={(e) => {
                        handleAttachmentChange(e, index);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: `${colorPalette.primaryColor}`,
                              fontWeight: "600",
                            }}
                          >
                            <button className="upload_button">
                              <input
                                name="attachment_file"
                                id={`upload${index}`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={(e) => {
                                  handleAttachmentChange(e, index);
                                }}
                                hidden
                                style={{
                                  backgroundColor: `${colorPalette.secondaryWhite}`,
                                }}
                              />
                              <label
                                htmlFor={`upload${index}`}
                                style={{
                                  backgroundColor: `${colorPalette.secondaryWhite}`,
                                }}
                              >
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                  style={{
                                    backgroundColor: `${colorPalette.secondaryWhite}`,
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    >
                      {/* <MenuItem value="0" disabled>
                        Select document type
                      </MenuItem>
                      {documentTypeList.map((item) => {
                        return (
                          <MenuItem value={item}>
                            {item
                              .split("_")
                              .join(" ")
                              .split("")
                              .map((item, index) => {
                                if (index == 0) {
                                  return item.toUpperCase();
                                } else {
                                  return item;
                                }
                              })
                              .join("")}
                          </MenuItem>
                        );
                      })} */}
                    </TextField>

                    <div className="trip-list-attachment-name">
                      {item.attachment_file != null
                        ? item.attachment_file.name
                        : null}
                    </div>
                    <div className="error-trip">
                      {item.attachment_file
                        ? item.attachment_file.size >
                          process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size is too large.( < ${
                              process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                            }MB)`
                          : null
                        : null}
                    </div>
                  </div>
                </div>
              );
            })}
            {/* {isMobile ? null : (
              <div className="trip-list-modal-row">
                <div className="trip-list-modal-input-group"></div>
                <div className="trip-list-modal-input-group"></div>
                <div className="trip-list-modal-input-group"></div>
              </div>
            )} */}
            {/* </div> */}
            {/* ----------------------------------multiple attachment end---------------------- */}
            {/* Remarks start */}
            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Remarks<sup>*</sup>
              </span>
              <textarea
                name="remarks"
                id="remarks"
                cols="30"
                rows="2"
                style={{
                  width: "100%",
                  border: "1px solid grey",
                  padding: "15px",
                  marginBottom: "10px",
                }}
                onChange={(e) => handleDeliveryFormInput(e)}
                value={goodsDeliveryFormData.remarks}
              ></textarea>
              {Object.keys(errRemarks).map((key) => {
                return <h6 style={{ color: "#E06666" }}>{errRemarks[key]}</h6>;
              })}
            </div>
            {/* Remarks end */}

            {/* POD Remarks start */}
            {/* <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Pod Remarks
              </span>
              <textarea
                name="pod_remarks"
                id="pod_remarks"
                cols="30"
                rows="2"
                style={{
                  width: "100%",
                  border: "1px solid grey",
                  padding: "15px",
                  marginBottom: "10px",
                }}
                onChange={(e) => handleDeliveryFormInput(e)}
                value={goodsDeliveryFormData.pod_remarks}
              ></textarea>
              {Object.keys(errPodRemarks).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errPodRemarks[key]}</h6>
                );
              })}
            </div> */}

            <div className="good-delivery-save-button full-width">
              <ButtonRectangle
                onClick={checkFormValidation}
                // style={{ width: "20%" }}
                disabled={isLoading || disableSaveButton || maxFileSizeError}
                style={
                  disableSaveButton ||
                  maxFileSizeError ||
                  tripStartMeterReadingError
                    ? {
                        cursor: "not-allowed",
                        backgroundColor: "#454545",
                        marginBottom: "20px",
                      }
                    : { cursor: "pointer", marginBottom: "20px" }
                }
              >
                {isLoading ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  "Save"
                )}
              </ButtonRectangle>
            </div>
          </div>
        </div>
      )}
    </GoodsDeliveredMainSection>
  );
};

export default GoodsDelivered;
