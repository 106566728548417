import React, { useState, useEffect } from "react";
import noConnection from "../../assets/errorpage/no_connection.png";
import { NoInternetStyle } from "./nointernet.js";
import { ButtonRectangle } from "../../themes/components/button";
import { colorPalette, typography } from "../../themes/theme";

const NoInternetConnection = (props) => {
  const [isOnline, setOnline] = useState(true);
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });
  const handleReload = () => {
    window.location.reload();
  };
  if (isOnline) {
    return props.children;
  } else {
    return (
      <NoInternetStyle className="noInternet">
        {/* no connection */}
        <img src={noConnection} alt="" width="100%" />
        <h5
          style={{
            color: colorPalette.paragraphColor,
            fontSize: typography.heading4,
            padding: ".4rem 0rem",
          }}
        >
          No Internet Connection,Please try again later.
        </h5>
        <ButtonRectangle onClick={handleReload}>Try again</ButtonRectangle>
      </NoInternetStyle>
    );
  }
};

export default NoInternetConnection;
