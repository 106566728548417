import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tripDetails: {},
  driverDetails: {},
  selectedTrip: {},
  reduxTripList: [],
  selectedTripInvoices: [],
  pendingTripInvoiceList: [],
  warehouseUserEditInvoke: {},
  tripSearchValue: "",
};

const tripSlice = createSlice({
  name: "trip",
  initialState,
  reducers: {
    updateTripDetails(state, action) {
      state.tripDetails = action.payload;
    },
    updateDriverDetails(state, action) {
      state.driverDetails = action.payload;
    },
    updateSelectedTrip(state, action) {
      state.selectedTrip = action.payload;
    },
    updateSelectedTripInvoices(state, action) {
      state.selectedTripInvoices = action.payload;
    },
    updatePendingTripInvoiceList(state, action) {
      state.pendingTripInvoiceList = action.payload;
    },
    updateWarehouseUserEditInvoke(state, action) {
      state.warehouseUserEditInvoke = action.payload;
    },
    updateTripSearchValue(state, action) {
      state.tripSearchValue = action.payload;
    },
    reduxStoreTripList(state, action) {
      state.reduxTripList = action.payload;
    },
  },
});

export const {
  updateTripDetails,
  updateDriverDetails,
  updateSelectedTrip,
  updateSelectedTripInvoices,
  updatePendingTripInvoiceList,
  updateWarehouseUserEditInvoke,
  updateTripSearchValue,
  reduxStoreTripList,
} = tripSlice.actions;
export default tripSlice.reducer;
