import styled from "styled-components";

export const ApprovedModalStyle = styled.div`
  width: 650px;
  height: 600px;
  background-color: white;
  padding: 5px 20px;
  overflow-y: scroll;

  .submit-clear-button {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
  }
  @media only screen and (max-width: 499px) {
    width: 100%;

    .customerBilledAdvancePaidDiv {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .create-trip-cost-input-contain {
      width: 100%;
    }

    .submit-clear-button {
      position: static;
      bottom: 0;
    }
  }
`;
