import styled from "styled-components";
import { FontWeight, colorPalette } from "../../../themes/theme";

export const AboutStyle = styled.div`
  width: 100%;
  padding: 140px 140px;
  background-color: ${colorPalette.primaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${FontWeight.bold};
  font-size: 32px;
  line-height: 56px;
  color: ${colorPalette.secondaryWhite};

  @media only screen and (max-width: 700px) {
    padding: 50px 35px;
    font-size: 20px;
    line-height: 35px;
  }
`;
