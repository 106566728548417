import { ModalUnstyled } from "@mui/base";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import * as moment from "moment";
import { Drawer } from "@mui/material";
import {
  React,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { GetTripPaymentStatusEnums } from "../ftl_trips/tripList/tripListServices";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Stack from "@mui/material/Stack";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import vehicle from "../../assets/carrier icon.svg";
import vehicle_svg from "../../assets/dashboard_icons/vehicle.svg";
import FILTER_ICON from "../../assets/filter_icon.svg";
import PaymentMonthlyCard from "../../components/paymentMonthlyCard/PaymentMonthlyCard";
import PaymentsMiniCard from "../../components/paymentsMiniCard/PaymentsMiniCard";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import { updateSelectedTrip } from "../../redux/tripSlice";
import MenuItem from "@mui/material/MenuItem";
import { getMonthlyPaymentRecords } from "../monthlyPayments/MonthlyWisePaymentService";
import { useSelector } from "react-redux";
import { paymentMonthlyDetails } from "../../redux/paymentSlice";
import apiUrl from "../../url/apiUrl";
// import * as moment from "moment";
import { getPaymentRecords } from "../recordPayment/recordPaymentServices";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import {
  getTripPayoutTypeMonthly,
  getTripWiseList,
  // getTripPayoutTypeMonthlyNumber,
} from "./PaymentsServices";
import { setDate } from "date-fns/esm";
import { Component_style } from "../../themes/component_style";
import { colorPalette, colorPaletteDark } from "../../themes/theme";
import { Main_onboard_right_box } from "./payments.js";
import {
  ButtonRectangle,
  ButtonRectangleSecondary,
} from "../../themes/components/button";
import Topbar from "../../components/topbar/Topbar.jsx";
import { DarkModeContext } from "../../context/darkModeContext";

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

const filterBoxStyle = {
  width: isMobile ? "90vw" : "36%",
  height: isMobile ? "auto" : "53%",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -34,
  marginTop: isMobile ? -10 : -20,
  border: "1px solid gray",
  borderRadius: "10px",
};

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${isMobile ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.5)`};
  -webkit-tap-highlight-color: transparent;
`;
function Payments() {
  const dispatch = useDispatch();
  const observer = useRef();
  const [value, setValue] = useState(new Date());
  // const { tripId } = useParams();
  // const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageMonthly, setCurrentPageMonthly] = useState(1);
  const [toggle1, setToggle1] = useState(false);
  const history = useHistory();
  const [toggle2, setToggle2] = useState(false);
  const [loadingMoreTripwise, setLoadingMoreTripwise] = useState(false);
  const [loadingMoreMonthly, setLoadingMoreMonthly] = useState(false);
  const [noDataFoundTripwise, setNoDataFoundTripwise] = useState(false);
  const [noDataFoundMonthlywise, setNoDataFoundMonthlywise] = useState(false);

  // const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [isLastPageMonthly, setIsLastPageMonthly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [trip, setTrip] = useState([]);
  const [tripMonthly, setTripMonthly] = useState([]);
  const [firstIndexMonthly, setFirstIndexMonthly] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [tripData, setTripData] = useState({});
  const [loadingClear, setLoadingClear] = useState(false);
  const [tripDataMonthly, setTripDataMonthly] = useState({});
  const [firstIndex, setFirstIndex] = useState([]);
  // const [firstIndexMonthly, setFirstIndexMonthly] = useState([]);
  const [paidAmountData, setPaidAmountData] = useState([]);
  const [totalAmount, setTotalAmount] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [tripDate, setTripDate] = useState("");
  const [tripCode, setTripCode] = useState("");
  const [loadingSearchTripwise, setLoadingSearchTripwise] = useState(false);
  const [loadingSearchMonthlywise, setloadingSearchMonthlywise] =
    useState(false);
  const [searchTrip, setSearchTrip] = useState("");
  const [loadingClearTripwise, setLoadingClearTripwise] = useState(false);
  const [loadingClearMonthlywise, setLoadingClearMonthlywise] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [clearMonthly, setClearMonthly] = useState(false);
  const [isFilterOpenMonthly, setIsFilterOpenMonthly] = useState(false);
  const [selectedCardMonthly, setSelectedCardMonthly] = useState(null);
  const [months, setMonths] = useState();
  const [paidAmt, setPaidAmt] = useState("");
  const [tripStatusList, setTripStatusList] = useState([]);
  const [monthlyTripsDetails, setMonthlyTripsDetails] = useState({});
  const [state, setState] = useState({ right: false });
  const [stateMonth, setStateMonth] = useState({ right: false });

  //
  //   "vehicle+++++++++++++++++++++",
  //   tripDataMonthly.vehicle_details.vehicle_number
  // );
  const cal = (val) => {
    // if (val.moment !== undefined) {
    const calculated = moment.utc(val).local().format("YYYY-MM-DD");
    setTripDate(calculated);

    // }
  };

  useEffect(() => {
    cal(value);
  }, [value]);

  // let dates = JSON.stringify(value);
  // dates = dates.slice(1, 11);
  const reducer = (accumulator, curr) => accumulator + curr;
  const [updatedRecords, setUpdatedRecords] = useState([]);
  const getPaymentsRecord = async (vehicleNum, dateTrip) => {
    const responseGetRecord = await getMonthlyPaymentRecords(
      vehicleNum,
      dateTrip
    );
    setUpdatedRecords(responseGetRecord.data);
    // if (updatedRecords) {
    //   let totalPaidAmt = 0;
    //   totalPaidAmt = updatedRecords.map((data) => data.amount).reduce(reducer);
    //
    //     "monthly pay are+++++Rocrdds------in paymenyts",
    //     totalPaidAmt
    //   );
    //   setPaidAmt(totalPaidAmt);
    // }
    //
    //   "monthly pay are+++++Rocrdds------in paymenyts",
    //   responseGetRecord.data
    // );
  };
  useEffect(() => {
    if (tripDataMonthly.vehicle_details !== undefined) {
      getPaymentsRecord(
        tripDataMonthly.vehicle_details.vehicle_number,
        tripDate
      );
    }
  }, [tripDataMonthly, tripDate]);

  // const reducer = (accumulator, curr) => accumulator + curr;
  const CountTotalPaid = () => {
    let totalPaidAmt = 0;
    if (updatedRecords.length > 0) {
      totalPaidAmt = updatedRecords.map((data) => data.amount).reduce(reducer);
      setPaidAmt(totalPaidAmt);
    }
  };
  useEffect(() => {
    CountTotalPaid();
  }, [updatedRecords]);

  const handleToggle1 = () => {
    setToggle1(true);
    setToggle2(false);
  };

  useEffect(() => {
    handleToggle1();
  }, []);

  const handleToggle2 = () => {
    setToggle2(true);
    setToggle1(false);
  };
  const handleGetTripStatus = async () => {
    const response = await GetTripPaymentStatusEnums();
    setTripStatusList(response);
  };
  useEffect(() => {
    handleGetTripStatus();
  }, []);
  const lastItem = useCallback(
    (node) => {
      if (loadingMoreTripwise) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMoreTripwise, isLastPage]
  );

  const lastItemMonthly = useCallback(
    (node) => {
      if (loadingMoreMonthly) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPageMonthly) {
          setCurrentPageMonthly((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMoreMonthly, isLastPageMonthly]
  );

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };
  // const getOemListData = async (current) => {
  //   setLoading(true);
  //   const oemList = await getOemList(current);
  //
  //   if (current > 1) {
  //     setOemData([...oemData, ...oemList.vehicle_manufacturer_list]);
  //     setIsLastPage(oemList.is_last_page);
  //   } else {
  //     setOemData(oemList.vehicle_manufacturer_list);
  //     setIsLastPage(oemList.is_last_page);
  //   }
  //   setLoading(false);
  // };

  const getAllTripList = async (current, tripsCode, payment) => {
    setLoadingMoreTripwise(true);
    setLoading(true);
    setNoDataFoundTripwise(false);
    if (current == 1 && !tripsCode && !payment) {
      setLoadingClearTripwise(true);
    }
    if (current == 1 && (tripsCode || payment)) {
      setLoadingSearchTripwise(true);
    }

    // setLoadingClear(true);
    const response = await getTripWiseList(current, tripsCode, payment);
    handleCloseFilterModal();
    setLoading(false);
    setLoadingClearTripwise(false);
    setLoadingSearchTripwise(false);
    // setLoadingClear(false);
    //
    if (current > 1) {
      setTrip([...trip, ...response.trip_list]);
      setIsLastPage(response.is_last_page);

      // setTrip(response.trip_list);
    } else {
      setTrip(response.trip_list);
      setIsLastPage(response.is_last_page);
      if (response.trip_list.length === 0) {
        setNoDataFoundTripwise(true);
      }
      // setTrip(response.trip_list);
    }
    setLoadingMoreTripwise(false);

    setFirstIndex(response.trip_list.slice(0, 1));
    // setLoading(false);
    // if (response.trip_list.length > 0) {
    //   const filteredData = response.trip_list.filter(
    //     (trip) => trip.trip_status == "completed"
    //   );
  };

  // setFirstIndex(
  //   response.trip_list
  //     .filter((trip) => trip.trip_status == "completed")
  //     .splice(0, 1)
  // );

  useEffect(() => {
    getAllTripList(currentPage, "", "");
  }, [currentPage]);

  const getAllTripListMonthly = async (date, vehicle, current) => {
    setLoading(true);
    setLoadingMoreMonthly(true);
    setNoDataFoundMonthlywise(false);

    if (current == 1 && !vehicle) {
      setLoadingClearMonthlywise(true);
    }
    if (current == 1 && vehicle) {
      setloadingSearchMonthlywise(true);
    }

    const response = await getTripPayoutTypeMonthly(date, vehicle, current);

    setloadingSearchMonthlywise(false);
    setLoadingClearMonthlywise(false);
    setClearMonthly(false);
    if (current > 1) {
      setTripMonthly([...tripMonthly, ...response.vehicle_details]);
      setIsLastPageMonthly(response.is_last_page);
      // setTrip(response.trip_list);
    } else {
      setTripMonthly(response.vehicle_details);
      setIsLastPageMonthly(response.is_last_page);

      if (response.vehicle_details.length == 0) {
        setNoDataFoundMonthlywise(true);
      }
      // setTrip(response.trip_list);
    }
    setLoadingMoreMonthly(false);

    // if (response.vehicle_details.length > 0) {
    // setTripMonthly(response.vehicle_details);
    setFirstIndexMonthly(response.vehicle_details.slice(0, 1));
    // if (response.vehicle_details.length > 0) {
    // setFirstIndexMonthly(
    //   response.vehicle_details
    //     // .filter((trip) => trip.status == "available")
    //     .splice(0, 1)
    // );
    // }
    setLoading(false);
    // }
  };
  // const MonthlyTotalTrip = async (tripss, vNumber) => {
  //   const responseAll = await getTripPayoutTypeMonthlyNumber(tripss, vNumber);
  //   setMonthlyTripsDetails(responseAll);
  //
  // };

  useEffect(() => {
    if (tripDate) {
      getAllTripListMonthly(tripDate, vehicleNumber, currentPageMonthly);
    }
  }, [tripDate, currentPageMonthly]);

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };
  const toggleDrawerMonthly = (anchor, open) => {
    setStateMonth({ ...stateMonth, [anchor]: open });
  };

  const handleOpenMenuTripWise = () => {
    toggleDrawer("right", true);
    // setSuccess("");
    // setErrorMsg("");
  };
  const handleOpenMenu = () => {
    // if (isMobile) {
    toggleDrawerMonthly("right", true);
    // }

    // setSuccess("");
    // setErrorMsg("");
  };
  const handleSelectedCard = (items, index) => {
    setSelectedCard(index);
    dispatch(updateSelectedTrip(items));
    setTripData(items);
    if (isMobile) {
      handleOpenMenuTripWise();
    }
  };

  useEffect(() => {
    // const firstValue = trip.splice(0, 1);
    // if (firstValue.length > 0) {
    if (firstIndex.length > 0 && !isMobile) {
      handleSelectedCard(...firstIndex, 0);
    }

    // }
  }, [firstIndex.length, isMobile]);

  const handleSelectedCardMonthly = (monthlyItems, index) => {
    //
    setSelectedCardMonthly(index);
    // MonthlyTotalTrip(tripss, monthlyItems.vehicle_number);
    // dispatch(updateSelectedTrip(items));
    setTripDataMonthly(monthlyItems);
    if (isMobile) {
      handleOpenMenu();
    }
  };

  useEffect(() => {
    // const firstValue = trip.splice(0, 1);
    // if (firstValue.length > 0) {
    if (firstIndexMonthly.length > 0 && !isMobile) {
      handleSelectedCardMonthly(...firstIndexMonthly, 0);
    }
    // }
  }, [firstIndexMonthly.length, isMobile]);

  const getPayments = async () => {
    try {
      const responsePayments = await getPaymentRecords(tripData.id);
      //
      setPaidAmountData(responsePayments.data);

      totalPaid(responsePayments.data);
    } catch (e) {}
  };
  useEffect(() => {
    if (tripData.id) {
      getPayments();
    }

    // }
  }, [tripData.id]);
  const handlePayTripWise = (trip) => {
    // setSelectedTripId(trip.id);
    dispatch(updateSelectedTrip(trip));
    // updateSelectedTrip(trip)
    // setCurrentStatusUpdateInProgress("completed");
    // handleOpen();
    // /faarms-trip/:tripId/
    history.push(`/payments/${trip.id}/record-payment`);
  };
  const handlePayTrips = (trip) => {
    // setSelectedTripId(trip.id);
    dispatch(paymentMonthlyDetails(trip));
    // setCurrentStatusUpdateInProgress("completed");
    // handleOpen();
    // /faarms-trip/:tripId/

    history.push(
      `/Payments/${trip.vehicle_details.transporter_id}/monthlyWisePayments/${trip.vehicle_details.id}`
    );
  };

  const totalPaid = (paidData) => {
    let totalHistory = 0;
    if (paidData.length > 0) {
      totalHistory = paidData.map((data) => data.amount).reduce(reducer);

      setTotalAmount(totalHistory);
    }
  };
  const handleCloseFilterOptions = () => {
    setIsFilterOpen(false);
  };
  const handleCloseFilterModal = () => {
    setIsFilterOpenMonthly(false);
    setIsFilterOpen(false);
  };
  const handleOpenFilter = () => {
    setIsFilterOpen(true);
  };
  const handleOpenFilterMonthly = () => {
    setIsFilterOpenMonthly(true);
  };
  const handleClearMonthly = (tripDates, vehicleNumbers, page) => {
    // setTripDate("");
    setClearMonthly(true);
    setVehicleNumber("");
    // setVehicleNumber("");
    getAllTripListMonthly(tripDates, "", 1);
    setCurrentPageMonthly(1);
    handleCloseFilterModal();
  };

  const CurrentMonth = moment().format("MM");
  //
  const CurrentDay = moment().format("DD");
  const CurrentYear = moment().format("YYYY");
  const dateToday = moment().format("YYYY-MM-DD");
  //
  const getRemainingDays = (cutOff) => {
    let cutOffMonth = "";
    let cutOffFullDate = "";
    if (parseInt(CurrentDay) > cutOff) {
      cutOffMonth = parseInt(CurrentMonth) + 1;
      cutOffFullDate = `${CurrentYear}-${
        `${cutOffMonth}`.length < 2 ? `0${cutOffMonth}` : cutOffMonth
      }-${cutOff}`;
    } else {
      cutOffMonth = CurrentMonth;
      cutOffFullDate = `${CurrentYear}-${cutOffMonth}-${cutOff}`;
    }
    let startDate = moment(dateToday, "YYYY-MM-DD");

    let endDate = moment(cutOffFullDate, "YYYY-MM-DD");

    return endDate.diff(startDate, "days");
  };
  useEffect(() => {
    getRemainingDays();
  }, []);
  //  if()
  const handleCloseBtn = () => {
    toggleDrawer("right", false);
  };
  const handleCloseBtnMonthly = () => {
    toggleDrawerMonthly("right", false);
  };
  const clearTripWise = () => {
    getAllTripList(1, "", "");
    setCurrentPage(1);

    setTripCode("");
    setPaymentStatus("");
  };
  const { toggle, darkMode } = useContext(DarkModeContext);

  return (
    <Component_style className="main_onboard_container" mode={darkMode}>
      <StyledModal
        open={isFilterOpen}
        onClose={handleCloseFilterOptions}
        className="search_modal"
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={filterBoxStyle}>
          <div className="trip-list-filter-main" id="search_modal">
            {/* {isMobile ? (
              <div className="trip-list-filter-head">
                <span className="trip-list-filter-main-text">
                  Select filter options
                </span>{" "}
                <CloseIcon onClick={handleCloseFilterModal} />
              </div>
            ) : null} */}
            <div className="trip-list-filter-head">
              <span className="trip-list-filter-main-text">
                Select filter options
              </span>{" "}
              <CloseIcon
                onClick={handleCloseFilterModal}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="trip-list-filter-head"></div>
            <div className="trip-list-filter-container">
              <div className="trip-list-filter-row">
                Trip Code
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    className="trip-list-filter-inputfield"
                    value={tripCode}
                    onChange={(event) => {
                      setTripCode(event.target.value);
                    }}
                    variant="standard"
                  />
                </div>
              </div>
              <div className="trip-list-filter-row">
                Payment Status
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    select
                    className="trip-list-filter-inputfield"
                    value={paymentStatus}
                    onChange={(e) => setPaymentStatus(e.target.value)}
                  >
                    {tripStatusList.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option.split("_").join(" ").toUpperCase()}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>

              <div className="trip-list-filter-button-row">
                <button
                  className="trip-list-filter-button"
                  onClick={() => clearTripWise()}
                >
                  {loadingClearTripwise ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Clear"
                  )}
                </button>
                <button
                  className="trip-list-filter-button"
                  onClick={() => {
                    if (tripCode || paymentStatus) {
                      getAllTripList(1, tripCode, paymentStatus);
                      setCurrentPage(1);
                    }
                  }}
                >
                  {loadingSearchTripwise ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Search"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>
      <Drawer
        className="drawer"
        anchor={"right"}
        open={state["right"]}
        onClose={() => toggleDrawer("right", false)}
      >
        <div className="right_details_wrapper">
          <div
            style={{
              display: "flex",
              width: "100%",
              // marginTop: ".5rem",
              width: "90%",
              margin: "0 auto",
              justifyContent: "flex-end",
              // backgroundColor: "red",
              padding: "1rem .5rem",
            }}
          >
            {/* <h5 style={{ color: "#016938", fontSize: ".9rem" }}>
              Transporters details
            </h5> */}
            <CloseIcon onClick={handleCloseBtn} style={{ cursor: "pointer" }} />
          </div>
          <div className="Right_details_wrapper">
            <PaymentsMiniCard data={tripData} paid={totalAmount} />
          </div>
        </div>{" "}
        {/* <div className="right_details_wrapper">
          <div
            style={{
              display: "flex",
              width: "100%",
              // marginTop: ".5rem",
              width: "90%",
              margin: "0 auto",
              justifyContent: "space-between",
              // backgroundColor: "red",
              padding: ".5rem .5rem",
            }}
          >
            <h5 style={{ color: "#016938", fontSize: ".9rem" }}>
              vehicles details
            </h5>
            <CloseIcon onClick={handleCloseBtn} />
          </div>
          <MiniVehiclesCard vehicleDetails={selectedVehicles} />
        </div>{" "} */}
      </Drawer>
      <Drawer
        className="drawer"
        anchor={"right"}
        open={stateMonth["right"]}
        onClose={() => toggleDrawerMonthly("right", false)}
      >
        <div className="right_details_wrapper">
          <div
            style={{
              display: "flex",
              width: "100%",
              // marginTop: ".5rem",
              width: "90%",
              margin: "0 auto",
              justifyContent: "flex-end",
              // backgroundColor: "red",
              padding: "1rem .5rem",
            }}
          >
            {/* <h5 style={{ color: "#016938", fontSize: ".9rem" }}>
              Transporters details
            </h5> */}
            <CloseIcon
              onClick={handleCloseBtnMonthly}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="Right_details_wrapper">
            <PaymentMonthlyCard MonthlyDetails={tripDataMonthly} />
          </div>
        </div>{" "}
        {/* <div className="right_details_wrapper">
          <div
            style={{
              display: "flex",
              width: "100%",
              // marginTop: ".5rem",
              width: "90%",
              margin: "0 auto",
              justifyContent: "space-between",
              // backgroundColor: "red",
              padding: ".5rem .5rem",
            }}
          >
            <h5 style={{ color: "#016938", fontSize: ".9rem" }}>
              vehicles details
            </h5>
            <CloseIcon onClick={handleCloseBtn} />
          </div>
          <MiniVehiclesCard vehicleDetails={selectedVehicles} />
        </div>{" "} */}
      </Drawer>
      <div className="main_onboard_wrapper">
        {/* <div className="main_onboard_Left_box">
          <SideBarMain />
        </div> */}
        {/* <div className="main_onboard_wrapper"> */}
        {isMobile ? (
          <div className="trip-list-mobile-header">
            <MobileHeader headerTitle="Payments" />
          </div>
        ) : (
          <div className="main_onboard_Left_box">
            <SideBarMain />
          </div>
        )}
        {/* </div> */}
        <Main_onboard_right_box
          className="main_onboard_Right_box"
          mode={darkMode}
        >
          {isMobile ? null : <Topbar />}
          <div className="trip_buttons_div">
            <button
              className="trip_button"
              onClick={handleToggle1}
              style={{
                backgroundColor:
                  toggle1 == true
                    ? colorPalette.primaryColor
                    : colorPalette.white,
                border:
                  toggle1 == true
                    ? "none"
                    : `3px solid ${colorPalette.primaryColor}`,
                color:
                  toggle1 == true ? colorPalette.white : colorPalette.black,
                borderTop:
                  toggle1 == false
                    ? "none"
                    : `3px solid ${colorPalette.primaryColor}`,
                borderLeft:
                  toggle1 == false
                    ? "none"
                    : `3px solid ${colorPalette.primaryColor}`,
              }}
            >
              Trip Wise
            </button>
            <button
              className="trip_button"
              onClick={handleToggle2}
              id="secondary_button"
              style={{
                backgroundColor:
                  toggle2 == true
                    ? colorPalette.primaryColor
                    : colorPalette.white,
                border:
                  toggle2 == true
                    ? "none"
                    : `3px solid ${colorPalette.primaryColor}`,
                color:
                  toggle2 == true ? colorPalette.white : colorPalette.black,
                borderTop:
                  toggle2 == false
                    ? "none"
                    : `3px solid ${colorPalette.primaryColor}`,
                borderRight:
                  toggle2 == false
                    ? "none"
                    : `3px solid ${colorPalette.primaryColor}`,
              }}
            >
              Monthly Wise
            </button>
          </div>
          {toggle1 == true ? (
            <>
              {" "}
              <div className="progress_container_details">
                <div className="Left_details_wrapper">
                  <div className="search_container">
                    <div className="search_div" id="search_div_onboard">
                      <input
                        type="text"
                        name=""
                        id="search_input_onboard"
                        onChange={(event) => {
                          setSearchTrip(event.target.value);
                          // setCurrentPage(1);
                        }}
                        value={searchTrip}
                        placeholder="search"
                        className="search_input"
                      />
                      <img
                        src={FILTER_ICON}
                        onClick={handleOpenFilter}
                        alt=""
                        className="trip-list-filter-icon"
                      />
                      {/* <CloseIcon
                        // onClick={handlecancelSearch}
                        style={{ color: "grey", cursor: "pointer" }}
                      ></CloseIcon> */}
                    </div>
                  </div>
                  <div className="trip_list_constainer">
                    {trip.length > 0 ? (
                      trip
                        .filter((trip) => trip.trip_status == "completed")
                        .map((data, index) => (
                          <div
                            className="payments_card"
                            key={index}
                            onClick={(e) => {
                              handleSelectedCard(data, index);
                            }}
                            style={{
                              border:
                                index == selectedCard
                                  ? `2px solid ${colorPalette.primaryColor}`
                                  : null,
                              backgroundColor:
                                data.trip_payment_status == "paid"
                                  ? colorPalette.secondaryWhite
                                  : "",
                            }}
                          >
                            {isMobile ? null : (
                              <div className="payment_section1">
                                <div className="payment_img">
                                  <img
                                    src={vehicle}
                                    alt=""
                                    width="80px"
                                    height="80px"
                                  />
                                </div>
                                <div>
                                  <h6
                                    style={{ color: colorPalette.primaryColor }}
                                  >
                                    {data.trip_status}
                                  </h6>
                                </div>
                              </div>
                            )}

                            <div className="payment_section2">
                              <div>
                                {trip.length == index + 1 ? (
                                  // <h6 className="name_text" ref={lastItem}>
                                  //   {" "}
                                  //   {data.name}
                                  // </h6>
                                  <h5 ref={lastItem}>
                                    Trip Code:
                                    <span
                                      style={{
                                        marginLeft: "1rem",
                                        color: "grey",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {data.trip_code}
                                    </span>
                                  </h5>
                                ) : (
                                  <h5 className="trip_code">
                                    Trip Code:
                                    <span
                                      style={{
                                        marginLeft: "1rem",
                                        color: "grey",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {data.trip_code}
                                    </span>
                                  </h5>
                                )}
                                {/* <h5>
                                  Trip Code:
                                  <span
                                    style={{
                                      marginLeft: "1rem",
                                      color: "grey",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {data.trip_code}
                                  </span>
                                </h5> */}
                              </div>
                              <div>
                                <div style={{ display: "flex" }}>
                                  <div className="trip-list-path">
                                    <h6
                                      style={{
                                        fontSize: "13px",
                                      }}
                                    >
                                      From
                                    </h6>

                                    <div
                                      className="trip-rectangle"
                                      style={{
                                        backgroundColor: darkMode
                                          ? colorPaletteDark.secondaryColor
                                          : "white",
                                      }}
                                    ></div>

                                    <h6
                                      style={{
                                        fontSize: "13px",
                                      }}
                                    >
                                      To
                                    </h6>
                                  </div>
                                  <div className="trip-list-path">
                                    <div className="trip-circle"></div>

                                    <div className="trip-rectangle"></div>

                                    <div className="trip-circle"></div>
                                  </div>
                                  <div
                                    // className="trip-list-path"
                                    style={{
                                      // color: "grey",
                                      // fontSize: "11px",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                      marginTop: ".2rem",
                                      // justifyContent: "center",
                                    }}
                                  >
                                    <h6
                                      style={{
                                        color: "grey",
                                        fontSize: "11px",

                                        // display: "flex",
                                        // alignItems: "center",
                                        // justifyContent: "flex-start",
                                      }}
                                    >
                                      {data.source.length > 0
                                        ? `${
                                            data.source[data.source.length - 1]
                                              .sub_district
                                          }, ${
                                            data.source[data.source.length - 1]
                                              .district
                                          }, ${
                                            data.source[data.source.length - 1]
                                              .state
                                          }`
                                        : null}
                                    </h6>

                                    <div
                                      className="trip-rectangle"
                                      style={{
                                        backgroundColor: "white",
                                        backgroundColor: darkMode
                                          ? colorPaletteDark.secondaryColor
                                          : "white",
                                      }}
                                    ></div>

                                    <h6
                                      style={{
                                        color: "grey",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" "}
                                      {data.destination.length > 0
                                        ? `${
                                            data.destination[
                                              data.destination.length - 1
                                            ].sub_district
                                          }, ${
                                            data.destination[
                                              data.destination.length - 1
                                            ].district
                                          }, ${
                                            data.destination[
                                              data.destination.length - 1
                                            ].state
                                          }`
                                        : null}
                                    </h6>
                                  </div>
                                </div>
                                <div></div>
                              </div>
                              <div>
                                <h6
                                  style={{
                                    color: darkMode
                                      ? colorPaletteDark.white
                                      : colorPalette.black,
                                  }}
                                >
                                  {" "}
                                  {data.destination.length > 0
                                    ? data.destination.length == 1 &&
                                      data.source.length == 1
                                      ? "Single stop"
                                      : "Multiple stop"
                                    : null}
                                </h6>
                              </div>
                            </div>
                            <div className="payment_section3">
                              {data.trip_payment_status !== "paid" ? (
                                <>
                                  {/* <NavLink
                                    to={`/Payments/${data.transporter_id}/monthlyWisePayments/${data.id}`}
                                    style={{ textDecoration: "none" }}
                                  > */}
                                  <ButtonRectangle
                                    // className="pay_payment"
                                    onClick={() => {
                                      handlePayTripWise(data);
                                    }}
                                  >
                                    Pay
                                  </ButtonRectangle>
                                  {/* </NavLink>{" "} */}
                                </>
                              ) : (
                                <>
                                  <ButtonRectangle
                                    // className="pay_payment"
                                    onClick={() => {
                                      handlePayTripWise(data);
                                    }}
                                  >
                                    History
                                  </ButtonRectangle>{" "}
                                </>
                              )}

                              <h6
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.white
                                    : colorPalette.black,
                                }}
                              >
                                {" "}
                                {data.trip_payment_status
                                  .split("_")
                                  .join(" ")
                                  .toUpperCase()}
                              </h6>
                            </div>
                          </div>
                        ))
                    ) : noDataFoundTripwise ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        No data found{" "}
                      </div>
                    ) : (
                      <div className="trip_list_constainer">
                        <Box sx={{ width: 600, height: 500, margin: "0 auto" }}>
                          <Skeleton
                            style={{
                              height: "150px",
                              backgroundColor: darkMode
                                ? colorPaletteDark.secondaryColor
                                : "",
                            }}
                            animation="wave"
                          />
                          <Skeleton
                            style={{
                              height: "150px",
                              backgroundColor: darkMode
                                ? colorPaletteDark.secondaryColor
                                : "",
                            }}
                            animation="wave"
                          />
                          <Skeleton
                            style={{
                              height: "150px",
                              backgroundColor: darkMode
                                ? colorPaletteDark.secondaryColor
                                : "",
                            }}
                            animation="wave"
                          />
                        </Box>{" "}
                      </div>
                    )}
                    {isLastPage ? null : (
                      <div className="trip-list-loading-more">
                        Loading more..Please wait..!!!
                      </div>
                    )}
                  </div>
                </div>
                {isMobile ? null : (
                  <div className="Right_details_wrapper">
                    <PaymentsMiniCard data={tripData} paid={totalAmount} />
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="progress_container_details">
                <StyledModal
                  open={isFilterOpenMonthly}
                  onClose={handleCloseFilterOptions}
                  className="search_modal"
                  BackdropComponent={Backdrop}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={filterBoxStyle}>
                    <div className="trip-list-filter-main">
                      {isMobile ? (
                        <div className="trip-list-filter-head">
                          <span className="trip-list-filter-main-text">
                            Select filter options
                          </span>{" "}
                          <CloseIcon
                            onClick={handleCloseFilterModal}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      ) : null}
                      <div className="trip-list-filter-head">
                        <span className="trip-list-filter-main-text">
                          Select filter options
                        </span>{" "}
                        <CloseIcon
                          onClick={handleCloseFilterModal}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="trip-list-filter-head"></div>
                    </div>
                  </Box>
                </StyledModal>
                <div className="Left_details_wrapper">
                  <div className="search_container">
                    <div className="monthly_wise_filter">
                      <div className="date_picker_div">
                        {/* Select month:{" "} */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack spacing={3}>
                            <DatePicker
                              size="small"
                              openTo="year"
                              views={["year", "month"]}
                              label="select trip month"
                              value={value}
                              onChange={(newValue) => {
                                setValue(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  helperText={null}
                                  size="small"
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>

                      <div className="vehicle_number_div">
                        {/* Vehicle Number */}
                        {/* <div className="trip-list-filter-row">
                        <div className="trip-list-filter-input-right"> */}{" "}
                        <TextField
                          id="outlined-basic"
                          label="Enter Vehicle Number"
                          variant="outlined"
                          type="text"
                          size="small"
                          value={vehicleNumber}
                          onChange={(e) => {
                            setVehicleNumber(e.target.value);
                          }}
                          // select
                          // className=""
                          // value={transporterType}
                          // onChange={(e) => setTransporterType(e.target.value)}
                        >
                          {/* {carrier.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })} */}
                        </TextField>
                        {/* </div>
                      </div> */}
                      </div>
                      {isMobile ? null : (
                        <div className="monthly_wise_buttons">
                          <ButtonRectangle
                            className="trip-list-filter-button"
                            onClick={() =>
                              getAllTripListMonthly(tripDate, vehicleNumber, 1)
                            }
                          >
                            {loadingSearchMonthlywise ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              "Search"
                            )}
                          </ButtonRectangle>
                          <ButtonRectangleSecondary
                            // className="trip-list-filter-button"
                            onClick={() => {
                              handleClearMonthly(tripDate, "", 1);
                            }}
                          >
                            {loadingClearMonthlywise && clearMonthly ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : (
                              "Clear"
                            )}
                          </ButtonRectangleSecondary>
                        </div>
                      )}
                    </div>

                    {/* <div className="search_div" id="search_div_onboard">
                      <input
                        type="text"
                        name=""
                        id="search_input_onboard"
                        // onChange={(event) => {
                        //   dispatch(searchManufacturer(event.target.value));
                        //   setCurrentPage(1);
                        // }}
                        // value={searchTerm}
                        placeholder="search"
                        className="search_input"
                      />
                      <img
                        src={FILTER_ICON}
                        onClick={handleOpenFilterMonthly}
                        alt=""
                        className="trip-list-filter-icon"
                      />
                    </div> */}
                  </div>
                  {isMobile ? (
                    <div className="monthly_wise_filter_mobile">
                      <div className="monthly_wise_buttons_mobile">
                        <ButtonRectangle
                          // className="trip-list-filter-button"
                          onClick={() =>
                            getAllTripListMonthly(tripDate, vehicleNumber, 1)
                          }
                        >
                          {loadingSearchMonthlywise ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            "Search"
                          )}
                        </ButtonRectangle>
                        <ButtonRectangleSecondary
                          // className="trip-list-filter-button"
                          onClick={() => {
                            handleClearMonthly(tripDate, "", 1);
                          }}
                        >
                          {loadingClearMonthlywise && clearMonthly ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            "Clear"
                          )}
                        </ButtonRectangleSecondary>
                      </div>
                    </div>
                  ) : null}

                  <div className="trip_list_constainer">
                    {
                      tripMonthly.length > 0 ? (
                        tripMonthly
                          // .filter((trips) => trips.trip_status == "completed")
                          .map((data, index) => (
                            <div
                              className="payments_card"
                              key={index}
                              onClick={(e) => {
                                handleSelectedCardMonthly(data, index);
                              }}
                              style={{
                                border:
                                  index == selectedCardMonthly
                                    ? `2px solid ${colorPalette.primaryColor}`
                                    : null,
                                backgroundColor:
                                  data.vehicle_payment_status == "paid"
                                    ? colorPalette.secondaryWhite
                                    : "",
                                // backgroundColor:
                                //   getRemainingDays(
                                //     data.payout_cutoff_day_of_month
                                //   ) <= 26
                                //     ? "#EA9999"
                                //     : null,
                              }}
                            >
                              {isMobile ? null : (
                                <div className="payment_section1">
                                  <div className="payment_img">
                                    <img
                                      src={vehicle_svg}
                                      alt=""
                                      width="80px"
                                      height="80px"
                                    />
                                  </div>
                                  <div>
                                    <h6
                                      style={{
                                        color:
                                          data.vehicle_payment_status ==
                                          "unpaid"
                                            ? "red"
                                            : "green",
                                      }}
                                    >
                                      {data.vehicle_payment_status
                                        .replace("_", " ")
                                        .toUpperCase()}
                                    </h6>
                                  </div>
                                  <div>
                                    <h6>{months}</h6>
                                  </div>
                                  {/* {month} */}
                                </div>
                              )}

                              <div className="payment_section2">
                                <div>
                                  {tripMonthly.length == index + 1 ? (
                                    <h5
                                      className="monthly_key"
                                      style={{
                                        position: "relative",

                                        // width: "100%",
                                        // backgroundColor: "RED",
                                      }}
                                      ref={lastItemMonthly}
                                    >
                                      Vehicle Number:
                                      <span className="monthly_valuesss">
                                        : {data.vehicle_details.vehicle_number}
                                      </span>
                                    </h5>
                                  ) : (
                                    <h5
                                      className="monthly_key"
                                      style={{
                                        position: "relative",

                                        // width: "100%",
                                        // backgroundColor: "RED",
                                      }}
                                      // ref={lastItem}
                                    >
                                      Vehicle Number:
                                      <span className="monthly_valuesss">
                                        : {data.vehicle_details.vehicle_number}
                                      </span>
                                    </h5>
                                  )}
                                  {/* <h5
                                    className="monthly_key"
                                    style={{
                                      position: "relative",

                                      // width: "100%",
                                      // backgroundColor: "RED",
                                    }}
                                    ref={lastItem}
                                  >
                                    Vehicle Number:
                                    <span className="monthly_valuesss" >
                                      {data.vehicle_details.vehicle_number}
                                    </span>
                                  </h5> */}
                                  <h5
                                    className="monthly_key"
                                    style={{ position: "relative" }}
                                  >
                                    Transporter:
                                    <span className="monthly_valuesss">
                                      : {data.vehicle_details.transporter_name}
                                    </span>
                                  </h5>
                                  {/* <h5 style={{ position: "relative" }}>
                                  Payout Period:
                                  <span
                                    style={{
                                      // marginLeft: "1rem",
                                      color: "grey",
                                      position: "absolute",
                                      left: "10rem",
                                      width: "100%",
                                    }}
                                  >
                                    {data.payout_period}
                                  </span>
                                </h5> */}
                                  <h5
                                    className="monthly_key"
                                    style={{ position: "relative" }}
                                  >
                                    Payout Amount:
                                    <span className="monthly_valuesss">
                                      : Rs.{" "}
                                      {data.vehicle_details.basic_payout_amount}
                                    </span>
                                  </h5>
                                  <h5
                                    className="monthly_key"
                                    style={{ position: "relative" }}
                                  >
                                    Fixed Kms:
                                    <span className="monthly_valuesss">
                                      : {data.vehicle_details.fixed_kms}Kms
                                    </span>
                                  </h5>
                                  <h5
                                    className="monthly_key"
                                    style={{ position: "relative" }}
                                  >
                                    Total Trips:
                                    <span
                                      className="monthly_valuesss"
                                      // style={{
                                      //   // marginLeft: "1rem",
                                      //   color: "grey",
                                      //   position: "absolute",
                                      //   left: "10rem",
                                      //   width: "100%",
                                      // }}
                                    >
                                      : {data.total_trips_count}
                                    </span>
                                  </h5>
                                  <h5
                                    className="monthly_key"
                                    style={{ position: "relative" }}
                                  >
                                    Distance travelled:
                                    <span className="monthly_valuesss">
                                      : {data.total_distance_travelled} Kms
                                    </span>
                                  </h5>
                                </div>

                                {/* <div>
                                <div style={{ display: "flex" }}>
                                  <div className="trip-list-path">
                                    <h6>From</h6>

                                    <div
                                      className="trip-rectangle"
                                      style={{ backgroundColor: "white" }}
                                    ></div>

                                    <h6>To</h6>
                                  </div>
                                  <div className="trip-list-path">
                                    <div className="trip-circle"></div>

                                    <div className="trip-rectangle"></div>

                                    <div className="trip-circle"></div>
                                  </div>
                                  <div className="trip-list-path">
                                    <h6 style={{ color: "grey" }}>
                                      {data.source.length > 0
                                        ? `${
                                            data.source[data.source.length - 1]
                                              .sub_district
                                          }, ${
                                            data.source[data.source.length - 1]
                                              .district
                                          }, ${
                                            data.source[data.source.length - 1]
                                              .state
                                          }`
                                        : null}
                                    </h6>

                                    <div
                                      className="trip-rectangle"
                                      style={{ backgroundColor: "white" }}
                                    ></div>

                                    <h6 style={{ color: "grey" }}>
                                      {" "}
                                      {data.destination.length > 0
                                        ? `${
                                            data.destination[
                                              data.destination.length - 1
                                            ].sub_district
                                          }, ${
                                            data.destination[
                                              data.destination.length - 1
                                            ].district
                                          }, ${
                                            data.destination[
                                              data.destination.length - 1
                                            ].state
                                          }`
                                        : null}
                                    </h6>
                                  </div>
                                </div>
                                <div></div>
                              </div> */}
                                <div>
                                  <h6>
                                    {/* {" "}
                                  {data.destination.length > 0
                                    ? data.destination.length == 1 &&
                                      data.source.length == 1
                                      ? "Single stop"
                                      : "Multiple stop"
                                    : null} */}
                                  </h6>
                                </div>
                              </div>

                              <div className="payment_section3">
                                {data.vehicle_payment_status == "paid" ? (
                                  <>
                                    {" "}
                                    <ButtonRectangle
                                      className="pay_payment"
                                      onClick={() => {
                                        handlePayTrips(
                                          data,
                                          monthlyTripsDetails
                                        );
                                      }}
                                    >
                                      History
                                    </ButtonRectangle>
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    {data.total_distance_travelled <= 0 ||
                                    data.total_distance_travelled <
                                      data.vehicle_details.fixed_kms ? (
                                      <ButtonRectangle
                                        className="pay_payment"
                                        // style={{
                                        //   backgroundColor:darkMode?colorPaletteDark.
                                        //     colorPalette.secondaryWhite,
                                        // }}
                                        disabled
                                        onClick={() => {
                                          handlePayTrips(
                                            data,
                                            monthlyTripsDetails
                                          );
                                        }}
                                      >
                                        Pay
                                      </ButtonRectangle>
                                    ) : (
                                      <ButtonRectangle
                                        className="pay_payment"
                                        onClick={() => {
                                          handlePayTrips(
                                            data,
                                            monthlyTripsDetails
                                          );
                                        }}
                                      >
                                        Pay
                                      </ButtonRectangle>
                                    )}
                                  </>
                                )}

                                {data.vehicle_payment_status ==
                                "paid" ? null : (
                                  <>
                                    {" "}
                                    {getRemainingDays(
                                      data.vehicle_details
                                        .payout_cutoff_day_of_month
                                    ) > 0 ? (
                                      <h6
                                        style={{
                                          color:
                                            getRemainingDays(
                                              data.vehicle_details
                                                .payout_cutoff_day_of_month
                                            ) < 5
                                              ? "red"
                                              : "green",
                                        }}
                                      >
                                        Payment due in{" "}
                                        {getRemainingDays(
                                          data.vehicle_details
                                            .payout_cutoff_day_of_month
                                        )}{" "}
                                        days
                                      </h6>
                                    ) : null}
                                  </>
                                )}
                              </div>
                            </div>
                          ))
                      ) : noDataFoundMonthlywise ? (
                        <div className="trip_list_constainer">
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <h4>No data Found!</h4>
                          </div>
                        </div>
                      ) : null
                      //  (
                      //   <div className="trip_list_constainer">
                      //     <Box sx={{ width: 600, height: 500, margin: "0 auto" }}>
                      //       <Skeleton
                      //         style={{ height: "150px" }}
                      //         animation="wave"
                      //       />
                      //       <Skeleton
                      //         style={{ height: "150px" }}
                      //         animation="wave"
                      //       />
                      //       <Skeleton
                      //         style={{ height: "150px" }}
                      //         animation="wave"
                      //       />
                      //     </Box>{" "}
                      //   </div>
                      // )
                    }
                    {isLastPageMonthly ? null : (
                      <div
                        className="trip-list-loading-more"
                        style={{ color: colorPalette.primaryColor }}
                      >
                        Loading more..Please wait..!!!
                      </div>
                    )}
                  </div>
                </div>
                {isMobile ? null : (
                  <div className="Right_details_wrapper">
                    <PaymentMonthlyCard
                      MonthlyDetails={tripDataMonthly}
                      otherData={monthlyTripsDetails}
                      paidAmount={paidAmt}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </Main_onboard_right_box>
      </div>
    </Component_style>
  );
}

export default Payments;
