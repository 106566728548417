import { Alert, Snackbar } from "@mui/material";
import { Tooltip } from "antd";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
const vertical = "bottom";
const horizontal = "left";

const CopyText = (props) => {
  const [showIcon, setShowIcon] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [copy, setCopy] = useState({
    value: "",
    copied: false,
  });

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  let hideIcon;
  if (showIcon) {
    hideIcon = setTimeout(() => {
      setShowIcon(false);
    }, 2000);
  }
 

  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseToast}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          Copied Successfully
        </Alert>
      </Snackbar>
      <CopyToClipboard
        text={props.text}
        onCopy={() => {
          setCopy({ copied: true });
          setOpenToast(true);
        }}
      >
        <Tooltip
          placement="rightTop"
          title={"Click to copy"}
          style={{ height: "5px", fontSize: "5px" }}
          overlayInnerStyle={{
            fontSize: "8px",
            height: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          arrow={true}
        >
          <span
            className="copy-text-container"
            id="copy-text-container"
            style={{
              cursor: "pointer",
              width: "100%",
              position: "relative",
            }}
          >
            <span
              onMouseOver={() => setShowIcon(true)}
              style={{ fontFamily: props.fontFamily }}
            >
              {props.text}
            </span>
          </span>
        </Tooltip>
      </CopyToClipboard>
    </>
  );
};

export default CopyText;
