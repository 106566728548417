import axios from "axios";
import axiosInterceptor from "../../config/https";
import apiUrl from "../../url/apiUrl";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";

export const getQuoteList = async (currentPage, status) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.quotationBaseUrl}/${status}-quotations?current_page=${currentPage}`
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
  }
};

export const updateQuoteStatusApi = (quoteId, quoteStatus, remarks) => {
  let data = JSON.stringify({
    quotation_status: quoteStatus,
    remarks: remarks,
  });
  var config = {
    method: "patch",
    url: `${apiUrl.quotationBaseUrl}/${prefixSalt()}-${convertToBase64(
      quoteId
    )}/approved-rejected`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};

export const updateQuoteStatusSentApi = (quoteId, quoteStatus, remarks) => {
  let data = JSON.stringify({
    quotation_status: quoteStatus,
    remarks: remarks,
  });
  var config = {
    method: "patch",
    url: `${apiUrl.quotationBaseUrl}/${prefixSalt()}-${convertToBase64(
      quoteId
    )}/sent`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};

export const createQuoteApi = (
  quoteId,
  quoteAmount,
  advanceAmount,
  remarks,
  documentName,
  doctype,
  image
) => {
  let data = new FormData();

  data.append("indent_id", quoteId);
  data.append("currency_code", process.env.REACT_APP_CURRECY_CODE);
  data.append("quotation_amount", quoteAmount);
  data.append("advance_amount", advanceAmount);
  data.append("remarks", remarks);
  data.append("document_type", doctype);
  data.append("image", image);
  data.append("document_name", documentName);
  // data.append("document_name", doctype);

  var config = {
    method: "post",
    url: `${apiUrl.quotationBaseUrl}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};

export const quoteEdit = (
  quoteAmount,
  advanceAmount,
  remarks,
  // documentName,
  // image,
  quotationsId
) => {
  let data = new FormData();

  data.append("quotation_amount", quoteAmount);
  data.append("advance_amount", advanceAmount);
  data.append("remarks", remarks);
  data.append("document_type", "image");
  // data.append("image", image);
  // data.append("document_name", documentName);
  //
  var config = {
    method: "put",
    url: `${apiUrl.quotationBaseUrl}/${prefixSalt()}-${convertToBase64(
      quotationsId
    )}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};

export const getQuoteById = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.quotationBaseUrl}/${prefixSalt()}-${convertToBase64(id)}`
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
  }
};
export const getQuoteHistory = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.quotationBaseUrl}/${prefixSalt()}-${convertToBase64(
        id
      )}/history`
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
  }
};

export const quotationAttachmentsPost = (
  document_type,
  image,
  document_name,
  id
) => {
  let data = new FormData();
  data.append("document_type", document_type);
  // data.append("permit_expiry_date_str", `${permit_expiry_date}`);
  data.append("image", image);
  data.append("document_name", document_name);

  var config = {
    method: "post",
    url: `${apiUrl.quotationBaseUrl}/${prefixSalt()}-${convertToBase64(
      id
    )}/attachment`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
         
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
         
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};
