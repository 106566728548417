// Asynchronously reads the content of a Blob or File using FileReader
// and returns a Promise that resolves to a new Blob or File with the same content.

export const saveBlob = (blob) => {
  let reader = new FileReader();
  return new Promise((res, rej) => {
    reader.onload = (e) => {
      if (blob instanceof File) {
        res(new File([reader.result], blob.name, { type: blob.type }));
      } else {
        res(new Blob([reader.result], { type: blob.type }));
      }
    };
    reader.onerror = rej;
    reader.readAsArrayBuffer(blob);
  });
};
