import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
// import "./indentPage.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UploadImage from "../../assets/upload.svg";
import { Alert, CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SEARCH_GRAY from "../../assets/search_gray.svg";
import FILTER_GRAY from "../../assets/filter_gray.svg";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import { Close } from "@mui/icons-material";
// import {
//   createQuoteApi,
//   getIndentList,
//   updateIndentStatusApi,
// } from "./indentServices";
import { ModalUnstyled } from "@mui/base";
import { styled } from "@mui/system";

import { Drawer, InputAdornment, TextField, Box } from "@mui/material";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import {
  createQuoteApi,
  quotationAttachmentsPost,
  quoteEdit,
} from "../../pages/QuotationPage/quotationServices";
import {
  getIndentList,
  updateIndentStatusApi,
} from "../../pages/IndentsPage/indentServices";
import { useHistory } from "react-router-dom";
import { Indent_drawer_container } from "../../pages/IndentsPage/indentPage";
import { DarkModeContext } from "../../context/darkModeContext.js";
import { colorPaletteDark } from "../../themes/theme.js";
import { ButtonRectangle } from "../../themes/components/button.js";

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

const tripRoutesBoxStyle = {
  width: isMobile ? "90vw" : "40%",
  height: isMobile ? "auto" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -24,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};

const exportBoxStyle = {
  width: isMobile ? "85%" : "70%",
  height: isMobile ? "80vh" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  // marginTop: isMobile ? -10 : -20,
  border: "1px solid gray",
  borderRadius: "10px",
};

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${isMobile ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.5)`};
  -webkit-tap-highlight-color: transparent;
`;

function CreateQuoteDrawer({
  addQuoteDrawerOpen,
  setDrawerClose,
  handleCloseDrawer,
  selectedIndentId,
  quotationDataById,
  tab,
  // resetForm,
}) {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const history = useHistory();
  const [indentList, setIndentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentTab, setCurrentTab] = useState("sent");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpenFulfill, setDrawerOpenFulfill] = useState(false);
  const [remarksReject, setRemarksReject] = useState("");
  //   const [selectedIndentId, setSelectedIndentId] = useState("");
  //   const [addQuoteDrawerOpen, setAddQuoteDrawerOpen] = useState(false);
  const [quoteAmount, setQuoteAmount] = useState("");
  const [advanceAmount, setAdvanceAmount] = useState("");
  const [incorrectAmount, setIncorrectAmount] = useState("");
  const [remarksAddQuote, setRemarksAddQuote] = useState("");
  const [quoteImage, setQuoteImage] = useState("");
  const [loadingSaveQuote, setLoadingSaveQuote] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [addQuoteSuccessToast, setAddQuoteSuccessToast] = useState(false);
  const [addQuoteErrorToast, setAddQuoteErrorToast] = useState(false);
  const [quoteToastError, setQuoteToastError] = useState("");
  const [loadingPage, setLoadingPage] = useState(true);
  const [openTripRoutes, setOpenTripRoutes] = useState(false);
  const [selectedIndent, setSelectedIndent] = useState({});
  const [indentSuccessToast, setIndentSuccessToast] = useState();

  const handleCloseToast = () => {
    setQuoteToastError("");
    setAddQuoteSuccessToast(false);
    setAddQuoteErrorToast(false);
  };

  const handleCloseTripRoute = () => {
    setOpenTripRoutes(false);
  };
  // if (addQuoteDrawerOpen == false) {
  //   setRemarksReject("");
  //   setQuoteAmount("");
  //   setAdvanceAmount("");
  //   setRemarksAddQuote("");
  // }
  // const handleCloseDrawer = () => {
  //   resetForm();
  // };

  // handleCloseDrawer = () => {
  //   setDrawerOpen(false);
  //   // setDrawerClose(true);
  //   resetForm();
  // };

  const handleOpenRoutes = (indent) => {
    setSelectedIndent(indent);
    setOpenTripRoutes(true);
  };

  const vertical = "bottom";
  const horizontal = "right";

  const handleOpenAddQuote = (indentId) => {
    // setSelectedIndentId(indentId);
    // setAddQuoteDrawerOpen(true);
    seteErrorQuotAmount("");
    setErrorAdvance("");
    setErrorAttachments("");
  };

  const resetForm = () => {
    setRemarksReject("");
    setQuoteAmount("");
    setAdvanceAmount("");
    setRemarksAddQuote("");
    setQuoteImage(null);

    setLoadingSaveQuote(false);
    setLoadingReject(false);
    setQuoteToastError("");
  };

  const handleCreateQuote = async () => {
    const isValid = formValidation();

    if (isValid) {
      if (parseInt(advanceAmount) <= parseInt(quoteAmount)) {
        setQuoteToastError("");
        setLoadingSaveQuote(true);
        // quoteId,
        // quoteAmount,
        // advanceAmount,
        // remarks,
        // documentName,
        // doctype,
        // image
        const response = await createQuoteApi(
          selectedIndentId,
          quoteAmount,
          advanceAmount,
          remarksAddQuote ? remarksAddQuote : "",
          quoteImage ? quoteImage.name : "",
          quoteImage?.type == "image/png" || quoteImage?.type == "image/jpeg"
            ? "image"
            : quoteImage.type == "application/pdf"
              ? "pdf"
              : "",
          quoteImage ? quoteImage : ""

          // documentName,
          // image
        );
        setLoadingSaveQuote(false);
        if (response.status === 200) {
          setIncorrectAmount("");
          seteErrorQuotAmount("");
          history.push("/quotations");
          setErrorAdvance("");
          setErrorAttachments("");
          handleCloseDrawer();
          setAddQuoteSuccessToast(true);
          setIndentSuccessToast("Quotation is successfully created!");
          resetForm();
        } else if (response.status === 400) {
          setIncorrectAmount("");
          setQuoteToastError(response.data.detail);
        } else {
          setIncorrectAmount("");
          setQuoteToastError(`${response.status}`);
        }
      } else {
        setIncorrectAmount(
          "Advance Amount should be less than quotation amount"
        );
      }
    }
  };

  const handleSubmitStatus = async (indentId, status, remarks) => {
    setQuoteToastError("");

    setLoadingReject(true);
    const response = await updateIndentStatusApi(indentId, status, remarks);
    setLoadingReject(false);

    if (response.status === 200) {
      handleGetIndentList(currentPage, currentTab);
      handleCloseDrawer();
      setIndentSuccessToast("Indent is successfully rejected!");

      setAddQuoteSuccessToast(true);
      resetForm();
    } else if (response.status === 400) {
      setQuoteToastError(response.data.detail);
    } else {
      setQuoteToastError(`${response.status}`);
    }
  };

  const handleChangeTab = (tab) => {
    if (!loadingPage) {
      setCurrentPage(1);
      setIndentList([]);
      setLoadingPage(true);
      setCurrentTab(tab);
    }
  };

  const handleChangeAddQuoteImage = (e) => {
    setQuoteImage(e.target.files[0]);
  };

  const handleOpenRemarks = (indentId) => {
    setDrawerOpen(true);
    // setSelectedIndentId(indentId);
  };

  const handleOpenRemarksFulfill = (indentId) => {
    setDrawerOpenFulfill(true);
    // setSelectedIndentId(indentId);
  };

  const handleGetIndentList = async (currentPage, status) => {
    if (currentPage == 1) {
      setLoadingPage(true);
    }
    setLoadingMore(true);
    const response = await getIndentList(currentPage, status);
    if (currentPage > 1) {
      // if (response?.status === 200) {

      setIndentList([...indentList, ...response?.data?.indent_list]);
      setIsLastPage(response?.data.is_last_page);
      setLoadingPage(false);
      setLoadingMore(false);
      // }
    } else {
      setIndentList(response?.data?.indent_list);
      setIsLastPage(response?.data?.is_last_page);
      setLoadingPage(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    handleGetIndentList(currentPage, currentTab);
  }, [currentPage, currentTab]);

  // pagination code

  const observer = useRef();
  const lastIndent = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage(currentPage + 1);
          setLoadingMore(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage, currentPage]
  );
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const [errorQuotAmount, seteErrorQuotAmount] = useState("");
  const [errorAdvance, setErrorAdvance] = useState("");
  const [errorAttachments, setErrorAttachments] = useState("");

  const formValidation = () => {
    const errorQuotAmount = {};
    const errorAdvance = {};
    // const errorAttachments = {};

    let isValid = true;
    if (quoteAmount == "") {
      errorQuotAmount.erroramount = "Quotation Amount is mandatory.";
      isValid = false;
    }
    if (advanceAmount == "") {
      errorAdvance.errorAdvanceAmt = "Advance Amount is mandatory.";
      isValid = false;
    }
    // if (quoteImage == null) {
    //   errorAttachments.errorAttach = "Attachment is manadatory.";
    //   isValid = false;
    // }

    seteErrorQuotAmount(errorQuotAmount);
    setErrorAdvance(errorAdvance);
    // setErrorAttachments(errorAttachments);
    return isValid;
  };
  useEffect(() => {
    // const [quoteImage, setQuoteImage] = useState(null);
    // const [loadingSaveQuote, setLoadingSaveQuote] = useState(false);
    if (quotationDataById) {
      setQuoteAmount(quotationDataById.quotation_amount);
      setAdvanceAmount(quotationDataById.advance_amount);
      setRemarksAddQuote(quotationDataById.remarks);
    }
  }, [quotationDataById]);

  const updateQuotation = async () => {
    const isValid = formValidation();
    if (isValid) {
      setLoadingSaveQuote(true);
      const response = await quoteEdit(
        quoteAmount,
        advanceAmount,
        remarksAddQuote,
        // "image",
        // quoteImage ? quoteImage : "",
        // quoteImage ? quoteImage.name : "",
        quotationDataById.id
      );
      if (response.status === 200) {
        // if (quoteImage.name !== "") {
        //   const respnseAttachments = await quotationAttachmentsPost(
        //     "image",
        //     quoteImage ? quoteImage : "",
        //     quoteImage ? quoteImage.name : "",
        //     quotationDataById.id
        //   );
        //   if (respnseAttachments.status == 200) {
        //     // history.push("/quotations");
        //     // window.location.reload();
        //     tab("draft");
        //     seteErrorQuotAmount("");
        //     setErrorAdvance("");
        //     // setCurrentTab("draft");
        //     setErrorAttachments("");
        //     handleCloseDrawer();
        //     setLoadingSaveQuote(false);
        //     setAddQuoteSuccessToast(true);
        //     setIndentSuccessToast("Quotation is successfully created!");
        //     resetForm();
        //   } else if (respnseAttachments.status === 400) {
        //     setQuoteToastError(respnseAttachments.data.detail);
        //   } else {
        //     setQuoteToastError(`${respnseAttachments.status}`);
        //   }
        // }

        tab("draft");
        seteErrorQuotAmount("");
        setErrorAdvance("");
        setErrorAttachments("");
        handleCloseDrawer();
        setLoadingSaveQuote(false);
        setAddQuoteSuccessToast(true);
        setIndentSuccessToast("Quotation is successfully created!");
        resetForm();
      } else if (response.status === 400) {
        setLoadingSaveQuote(false);
        setQuoteToastError(response.data.detail);
      } else {
        setQuoteToastError(`${response.status}`);
        setLoadingSaveQuote(false);
      }
    }
    // documentName, image, quotationsId;
  };

  return (
    <div>
      <Drawer
        open={addQuoteDrawerOpen}
        anchor={"right"}
        onClose={() => {
          handleCloseDrawer();
          setQuoteAmount("");
          setAdvanceAmount("");
          setRemarksAddQuote("");
          // resetForm();
        }}
        PaperProps={{
          sx: {
            width: isMobile ? 340 : 440,
            backgroundColor: darkMode
              ? colorPaletteDark.secondaryColor
              : "white",
          },
        }}
      >
        <Indent_drawer_container
          className="indent-drawer-container"
          mode={darkMode}
        >
          <div className="indent-drawer-head-text"> Add Quotation</div>
          <div className="indent-input-container">
            <div className="indent-input-text">
              Quotation Amount <sup style={{ color: "red" }}>*</sup>{" "}
            </div>
            <div className="input-div">
              <TextField
                size="small"
                autoComplete="off"
                // type="number"
                className="indent-inputfield"
                variant="outlined"
                // style={{ width: "100%" }}
                placeholder="Enter quotation amount"
                value={quoteAmount}
                onChange={(e) => {
                  setQuoteAmount(e.target.value);
                  seteErrorQuotAmount("");
                }}
                onInput={(e) => {
                  e.target.value = parseInt(
                    e.target.value === "0" ? "1" : e.target.value
                  );
                }}
                type="number"
                onWheel={(e) => e.target.blur()}
              />{" "}
              {Object.keys(errorQuotAmount).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorQuotAmount[key]}</h6>
                );
              })}
            </div>
          </div>
          <div className="indent-input-container">
            <div className="indent-input-text">
              Advance Amount <sup style={{ color: "red" }}>*</sup>
            </div>
            <div>
              <TextField
                size="small"
                autoComplete="off"
                className="indent-inputfield"
                variant="outlined"
                // style={{ width: "91%" }}
                placeholder="Enter advance amount"
                value={advanceAmount}
                onChange={(e) => {
                  setAdvanceAmount(e.target.value);
                  setErrorAdvance("");
                }}
                onInput={(e) => {
                  e.target.value = parseInt(
                    e.target.value === "0" ? "1" : e.target.value
                  );
                }}
                type="number"
                onWheel={(e) => e.target.blur()}
              />{" "}
              {Object.keys(errorAdvance).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorAdvance[key]}</h6>
                );
              })}
            </div>
          </div>
          <div className="indent-input-container">
            <div className="indent-input-text">Quote attachment</div>
            <div>
              {" "}
              <TextField
                size="small"
                id="dropdown"
                autoComplete="off"
                className="indent-inputfield"
                variant="outlined"
                placeholder="Quotation attachment"
                name="attachment_tag"
                disabled={true}
                // value={tripStartMeterReading}
                // onChange={(e) => setTripStartMeterReading(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        cursor: "pointer",
                        color: "#016938",
                        fontWeight: "600",
                      }}
                    // onClick={handleAddAttachment}
                    >
                      <button className="upload_button">
                        <input
                          // name="upload"
                          name="attachment_file"
                          id={`upload`}
                          type="file"
                          disabled={
                            quotationDataById !== undefined &&
                              Object.keys(quotationDataById).length > 0
                              ? true
                              : false
                          }
                          accept="image/png, image/jpeg, application/pdf"
                          onChange={(e) => {
                            handleChangeAddQuoteImage(e);

                            setErrorAttachments("");
                          }}
                          hidden
                          style={{
                            backgroundColor: "#e8f9e0",
                          }}
                        />
                        <label
                          htmlFor={`upload`}
                          style={{
                            backgroundColor: "#e8f9e0",
                          }}
                        >
                          <img
                            src={UploadImage}
                            alt=""
                            width="20px"
                            height="20px"
                            style={{
                              backgroundColor: "#e8f9e0",
                              // width: "100%",
                              // height: "100%",
                            }}
                          />
                        </label>
                      </button>
                    </InputAdornment>
                  ),
                }}
              />
              {Object.keys(errorAttachments).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorAttachments[key]}</h6>
                );
              })}
            </div>
            <div className="create-trip-dl-name">
              {quoteImage ? quoteImage.name : null}
              {quoteImage ? (
                quoteImage.size > process.env.REACT_APP_MAX_FILE_SIZE ? (
                  <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                    Please upload file size less than {process.env.REACT_APP_MAX_FILE_SIZE / 10000000}.
                  </h6>
                ) : null
              ) : null}
            </div>
          </div>
          <div className="indent-input-container">
            <div className="indent-input-text">
              Remarks
              {quotationDataById ? (
                <sup style={{ color: "red" }}>*</sup>
              ) : null}{" "}
            </div>
            <div
              className="indent-remarks-inputfield-conatiner"
              style={{ margin: "0px" }}
            >
              <textarea
                className="indent-remarks-textarea"
                name="accept_remark"
                placeholder="Add Remark"
                value={remarksAddQuote}
                onChange={(e) => setRemarksAddQuote(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div>
            {quoteToastError ? (
              <Alert severity="error">
                {quoteToastError ? quoteToastError : ""}
              </Alert>
            ) : null}
          </div>
          <div>
            {incorrectAmount ? (
              <Alert severity="error">
                {incorrectAmount ? incorrectAmount : null}
              </Alert>
            ) : null}
          </div>

          {/* <div>{incorrectAmount ? incorrectAmount : null}</div> */}
          <div
            className="indent-remarks-submit-btn-cover"
            style={{ width: "93%" }}
          >
            {quotationDataById ? (
              <ButtonRectangle
                onClick={() => updateQuotation()}
                className="indent-save-quote-btn"
                disabled={loadingSaveQuote == true ? true : false}
              // style={
              //   advanceAmount >= quoteAmount
              //     ? { backgroundColor: "#076b38bd" }
              //     : { backgroundColor: "#076b38" }
              // }
              // disabled={advanceAmount >= quoteAmount ? true : false}
              >
                {loadingSaveQuote ? (
                  <CircularProgress size={19} color="inherit" />
                ) : (
                  "Save Quote"
                )}
              </ButtonRectangle>
            ) : (
              <ButtonRectangle
                onClick={() => handleCreateQuote()}
                className="indent-save-quote-btn"
                disabled={
                  quoteImage.size > process.env.REACT_APP_MAX_FILE_SIZE ||
                    loadingSaveQuote == true
                    ? true
                    : false
                }
              // style={
              //   advanceAmount >= quoteAmount
              //     ? { backgroundColor: "#076b38bd" }
              //     : { backgroundColor: "#076b38" }
              // }
              // disabled={advanceAmount >= quoteAmount ? true : false}
              >
                {loadingSaveQuote ? (
                  <CircularProgress size={19} color="inherit" />
                ) : (
                  "Save Quote"
                )}
              </ButtonRectangle>
            )}
            {/* <button
              onClick={() => handleCreateQuote()}
              className="indent-save-quote-btn"
              style={
                quoteAmount >= advanceAmount
                  ? { backgroundColor: "#076b38" }
                  : { backgroundColor: "#076b38bd" }
              }
              disabled={quoteAmount >= advanceAmount ? false : true}
            >
              {loadingSaveQuote ? (
                <CircularProgress size={19} color="inherit" />
              ) : (
                "Save Quote"
              )}
            </button> */}
          </div>
        </Indent_drawer_container>
      </Drawer>
    </div>
  );
}

export default CreateQuoteDrawer;
