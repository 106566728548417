import axios from "axios";
import axiosInterceptor from "../../config/https";
import apiUrl from "../../url/apiUrl";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";

export const getCustomerCreditList = async (currentPage, searchName) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.customerCreditUrl}?current_page=${currentPage}&customer_name=${searchName}`
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
  }
};

export const updatecreditStatusApi = (creditId, creditStatus, remarks) => {
  let data = JSON.stringify({
    credit_status: creditStatus,
    remarks: remarks,
  });
  var config = {
    method: "patch",
    url: `${apiUrl.creditBaseUrl}/${prefixSalt()}-${convertToBase64(
      creditId
    )}/quotation-accepted-unfulfilled`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};

export const createQuoteApi = (
  creditId,
  quoteAmount,
  advanceAmount,
  remarks,
  documentName,
  image
) => {
  let data = new FormData();

  data.append("credit_id", creditId);
  data.append("currency_code", process.env.REACT_APP_CURRECY_CODE);
  data.append("quotation_amount", quoteAmount);
  data.append("advance_amount", advanceAmount);
  data.append("remarks", remarks);
  data.append("document_type", "image");
  data.append("image", image);
  data.append("document_name", documentName);

  var config = {
    method: "post",
    url: `${apiUrl.quotationBaseUrl}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};
