//dev
const onboardingBaseUrl = process.env.REACT_APP_LOGISTICS_BASE_URL;
// const onboardingBaseUrl = process.env.REACT_APP_LOGISTICS_BASE_URL_DEV;
// const onboardingBaseUrl = `http://api.faarmsglobal.com/logistics-services`; //prod
// const onboardingBaseUrl = `https://9501-117-98-0-9.ngrok-free.app/logistics-services`; //ngrok
//
// const onboardingBaseUrl = process.env.REACT_APP_LOGISTICS_BASE_URL; //dev
// const loginBaseUrl = process.env.REACT_APP_USERS_BASE_URL;
// const loginBaseUrl = `https://storeapi.faarms.com/users`;
const userBaseUrl = process.env.REACT_APP_USERS_BASE_URL;
// const userBaseUrl = `https://storeapi.faarms.com/iam-services`;

const villagePinCodeUrl = process.env.REACT_APP_VILLAGE_PINCODE_URL;
const encryptionSalt = process.env.REACT_APP_ENCRYPTION_SALT;
const payment_url = process.env.REACT_APP_BBPS_URL;
// const notificationsBaseUrl = `https://storeapi.faarms.com/notification-services`;
const notificationsBaseUrl = process.env.REACT_APP_NOTIFICATIONS_URL;
const coreServiceBaseUrl = process.env.REACT_APP_CORE_SERVICES_URL;
const pincodeServiceBaseUrl = process.env.REACT_APP_PINCODE_SERVICE_BASE_URL;

// const payment_url = "https://storeapi.faarms.com/bbps-services";
const version = 1;
const version2 = 2;
const version3 = 3;
const version4 = 4;
const version5 = 5;
const version6 = 6;
const version7 = 7;
const version8 = 8;
const version9 = 9;
const version11 = 11;

const apiUrl = {
  SALT: encryptionSalt,
  onboardingBaseUrl: onboardingBaseUrl,
  paymentRequestCCAvenueUrl: `${payment_url}/v${version}/ccavenue/request`,
  paymentResponseCCAvenueUrl: `${payment_url}/v${version}/ccavenue/response`,
  paymentBaseUrl: payment_url,
  vilagePincodeUrl: villagePinCodeUrl,
  tripBaseUrl: `${onboardingBaseUrl}/v${version}/trip`,
  tripZohoBaseUrl: `${onboardingBaseUrl}/v${version}`,
  tripV2BaseUrl: `${onboardingBaseUrl}/v${version2}/trip`,
  tripV3BaseUrl: `${onboardingBaseUrl}/v${version3}/trip`,
  tripV4BaseUrl: `${onboardingBaseUrl}/v${version4}/trip`,
  tripV5BaseUrl: `${onboardingBaseUrl}/v${version5}/trip`,
  tripV6BaseUrl: `${onboardingBaseUrl}/v${version6}/trip`,
  tripV7BaseUrl: `${onboardingBaseUrl}/v${version7}/trip`,
  tripV8BaseUrl: `${onboardingBaseUrl}/v${version8}/trip`,
  tripV9BaseUrl: `${onboardingBaseUrl}/v${version9}/trip`,
  tripV11BaseUrl: `${onboardingBaseUrl}/v${version11}/trip`,
  countryBaseUrl: `${pincodeServiceBaseUrl}/v${version}/countries`,
  // faarms trip base urls
  faarmsTripV1BaseUrl: `${onboardingBaseUrl}/v${version}/faarms/trip`,

  // ftl trip base urls
  ftlTripV1BaseUrl: `${onboardingBaseUrl}/v${version}/ftl/trip`,

  pickupVerifyOtp: `${notificationsBaseUrl}/v${version}/otp-notification/verify-otp`,
  pickupVerificationHistory: `${onboardingBaseUrl}/v${version}/pick-up-verification-history`,

  unitActiveBaseUrl: `${onboardingBaseUrl}/v${version}/unit?is_active=true`,
  unitByIdBaseUrl: `${onboardingBaseUrl}/v${version}/unit`,
  availableVehicleListUrl: `${onboardingBaseUrl}/v${version}/vehicle?status=available`,
  driverBaseUrl: `${onboardingBaseUrl}/v${version}/drivers`,
  zohoPurchaseOrdersUrl: `${onboardingBaseUrl}/v${version}/zoho/purchaseorders`,
  zohoInvoicesUrl: `${onboardingBaseUrl}/v${version}/zoho/invoices`,
  tripAttachmentsUrl: `${onboardingBaseUrl}/v${version}/trip-attachments`,
  ftlTripAttachmentsUrl: `${onboardingBaseUrl}/v${version}/ftl/trip-attachments`,
  showFtlTripAttachmentsUrl: `${onboardingBaseUrl}/v${version}/ftl-trip-attachments`,
  faarmsTripAttachmentsUrl: `${onboardingBaseUrl}/v${version}/faarms/trip-attachments`,
  tripHistoryUrl: `${onboardingBaseUrl}/v${version}/trip-history`,
  faarmsTripHistoryUrl: `${onboardingBaseUrl}/v${version}/faarms/trip-history`,
  ftlTripHistoryUrl: `${onboardingBaseUrl}/v${version}/ftl/trip-history`,
  loginUrl: `${userBaseUrl}/v${version}/token/login`,
  attachmentTagEnumUrl: `${onboardingBaseUrl}/v${version}/enums/trip-attachment-tag`,
  completeAttachmentTagEnumUrl: `${onboardingBaseUrl}/v${version}/enums/trip-complete-attachment-tag`,
  customerListUrl: `${onboardingBaseUrl}/v${version2}/customers?page_size=50`,
  tripPaymentV1Url: `${onboardingBaseUrl}/v${version}/invoice-payment-history`,
  faarmsTripPaymentV1Url: `${onboardingBaseUrl}/v${version}/faarms/invoice-payment-history`,
  faarmsTripPaymentV2Url: `${onboardingBaseUrl}/v${version2}/faarms/invoice-payment-history`,
  tripPaymentUrl: `${onboardingBaseUrl}/v${version2}/invoice-payment-history`,
  tripPaymentV3Url: `${onboardingBaseUrl}/v${version3}/invoice-payment-history`,
  warehouseUrl: `${onboardingBaseUrl}/v${version}/warehouses`,
  warehouseUrlV2: `${onboardingBaseUrl}/v${version2}/warehouses`,
  customersBaseUrl: `${onboardingBaseUrl}/v${version}/customers`,
  customersBaseUrlV2: `${onboardingBaseUrl}/v${version2}/customers`,
  customerCreditUrl: `${onboardingBaseUrl}/v${version}/customer-credit-terms`,
  customerEntitySeries: `${onboardingBaseUrl}/v${version}/customer-entity-series`,
  journalBaseUrl: `${onboardingBaseUrl}/v${version}/journal`,
  ewayBillBaseUrl: `${onboardingBaseUrl}/v${version}/ftl/eway-bill`,

  userTypeEnumUrl: `${onboardingBaseUrl}/v${version}/enums/user-type`,
  zohoInvoiceItemsDetailsUrl: `${onboardingBaseUrl}/v${version}/zoho/invoice-id`,
  tripStatusEnumUrl: `${onboardingBaseUrl}/v${version}/enums/trip-status`,
  paymentStatusEnumUrl: `${onboardingBaseUrl}/v${version}/enums/payment-status`,
  TripAddressUpdateUrl: `${onboardingBaseUrl}/v${version}/trip-address`,
  transporterDetailsUrl: `${onboardingBaseUrl}/v${version}/vehicle/transporters`,
  transporterBaseUrl: `${onboardingBaseUrl}/v${version}/transporter`,
  tripExportBaseUrl: `${onboardingBaseUrl}/v${version}/faarms/trip/report/export`,
  ftlTripExportBaseUrl: `${onboardingBaseUrl}/v${version}/ftl/trip/report/export`,
  ftlTripReportExportBaseUrl: `${onboardingBaseUrl}/v${version}/ftl/trip/trip-tracking/report-export`,
  tripExportV2BaseUrl: `${onboardingBaseUrl}/v${version2}/trip/report/export`,
  tripExportV3BaseUrl: `${onboardingBaseUrl}/v${version3}/trip/report/export`,
  tripExportV6BaseUrl: `${onboardingBaseUrl}/v${version6}/trip/report/export`,
  tripExportV8BaseUrl: `${onboardingBaseUrl}/v${version8}/trip/report/exportv2`,
  tripExportV11BaseUrl: `${onboardingBaseUrl}/v${version11}/trip/report/export`,
  faarmsDeliveryInfoTripItems: `${onboardingBaseUrl}/v${version}/faarms/trip/trip-items`,

  deliveryInfoTripItems: `${onboardingBaseUrl}/v${version}/trip/trip-items`,
  paymentModeEnumUrl: `${onboardingBaseUrl}/v${version}/enums/invoice-payment-mode`,
  tripEntityTypeEnumUrl: `${onboardingBaseUrl}/v${version}/enums/trip-entity-type`,
  loginUserVerify: `${userBaseUrl}/v${version}/users`,
  userServicesBaseUrl: `${userBaseUrl}/v${version}/app-token/fetch-app-token`,
  userListServicesBaseUrl: `${userBaseUrl}/v${version}`,
  rcVerifyUrl: `${coreServiceBaseUrl}/v${version}/rc/verify`,
  panVerifyUrl: `${coreServiceBaseUrl}/v${version}/pan/verify`,
  aadhaarVerifyUrl: `${coreServiceBaseUrl}/v${version}/aadhaar/verify`,
  bankAccountBaseUrl: `${coreServiceBaseUrl}/v${version}/bank-account`,
  gstBaseUrl: `${coreServiceBaseUrl}/v${version}/gst`,
  dlVerifyUrl: `${coreServiceBaseUrl}/v${version}/driving-license/verify`,
  teamsBaseUrl: `${onboardingBaseUrl}/v${version}/teams`,
  escalationMatrixBaseUrl: `${onboardingBaseUrl}/v${version}/ftl/escalation-matrix`,

  //Customer Login Urls

  indentBaseUrl: `${onboardingBaseUrl}/v${version}/trip-indent`,
  tripsIndentBaseUrl: `${onboardingBaseUrl}/v${version}/trip-indent`,
  quotationBaseUrl: `${onboardingBaseUrl}/v${version}/quotations`,
};

export default apiUrl;
