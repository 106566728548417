import styled from "styled-components";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  typography,
} from "../../themes/theme";

export const Login_Container = styled.div`
  /* .login_container { */
  width: 100vw;

  height: 100vh;
  background-color: ${colorPalette.secondaryWhite};
  /* } */

  .blur {
    /* background: rgba(0, 0, 0, 0.5); */
    /* filter: blur(10x); */
    width: 100%;
    -webkit-filter: blur(10x);
  }
  .login_container_changePassword {
    width: 100vw;

    height: 100vh;
    background-color: ${colorPalette.secondaryWhite};
  }
  .arrow-back {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0.5rem;
    cursor: pointer;
  }
  .css-a88p61-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid ${colorPalette.primaryColor} !important;
  }
  .css-1480iag-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid ${colorPalette.primaryColor} !important;
  }
  .login-input-field {
    width: 80%;

    margin: 2%;
  }
  .error-message {
    color: red;
    font-size: ${typography.paragraph};
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
  .login_right {
    z-index: 9999;
    flex: 6;
    border-radius: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
  .login_right_changepassword {
    z-index: 9999;
    flex: 6;
    border-radius: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
  .change-password-form {
    /* flex: 11; */
    /* margin-top: 2rem; */
    /* padding: .4rem 0rem; */
    background-color: white;
    /* background-color: antiquewhite; */
    height: 70%;
    width: 27%;
    z-index: 3;
    box-shadow: ${colorPalette.boxShadowPrimary};
    /* border-radius: 20px; */
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    /* border: 1px solid green; */
  }
  .login_left {
    background-color: ${colorPalette.primaryColor};
    flex: 6;
  }
  .login_form {
    background-color: white;
    /* background-color: antiquewhite; */
    height: 80%;
    width: 27%;
    z-index: 3;
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
  .login_form-forget-password {
    background-color: white;
    /* background-color: antiquewhite; */
    height: 70%;
    width: 27%;
    z-index: 3;
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
  .logo_container {
    width: 100%;
    /* border-radius: 20px; */
    /*background-color: antiquewhite;
   */
    /* -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px; */
  }
  .headings_div {
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .headings {
    font-size: ${typography.heading2};
    font-weight: ${FontWeight.bold};
    font-family: ${FontFamily.primaryFontFamily};
    color: ${colorPalette.primaryColor};
  }
  .headings_login {
    width: 80%;
    align-items: center;
    height: 15%;
    padding: 1.5rem 0rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .headings_login-forget {
    width: 100%;
    align-items: center;
    height: 10%;
    padding: 1.5rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subheading-email {
    display: flex;
    width: 100%;
    margin-bottom: 2rem;
    align-items: center;
    justify-content: center;
  }
  .login_text {
    font-size: ${typography.heading3};
    font-size: ${typography.heading2};
    font-family: ${FontFamily.primaryFontFamily};
    color: black;
    font-weight: ${FontWeight.bold};
  }
  .login_text_forget_password {
    font-size: ${typography.heading4};
    /* font-size: ${typography.heading2}; */
    font-family: ${FontFamily.primaryFontFamily};
    color: black;
    font-weight: ${FontWeight.bold};
    /* color: red; */
  }
  .login_box {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 60%;

    margin: 0 auto;
    /* background-color: ${colorPalette.primaryColor}; */
    /* height: 70%; */
  }
  .login_text_mobile {
    margin-top: 3rem;
  }
  .login_input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 98%;
    flex-direction: column;
    margin: 1rem auto;
    /* background-color: ${colorPalette.primaryColor}; */
    /* height: 70%; */
  }
  .input_mobile {
    width: 80%;
  }
  .button_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }

  .button_container_forget_password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
  }
  .blur {
    /* background: rgba(0, 0, 0, 0.5); */
    /* filter: blur(10x); */
    width: 100%;
    -webkit-filter: blur(10x);
  }
  .logo_leap {
    width: 100%;
    height: 5%;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  .back_to_home_button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
    /* border: 1px solid ${colorPalette.primaryColor}; */
    padding: 5px 40px;
    border-radius: 50px;
    /* background-color: ${colorPalette.primaryColor}; */
  }

  .back_to_home_button a {
    color: ${colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.medium};
    text-decoration: none;
  }

  .back_to_home_button a:hover {
    text-decoration: underline;
  }
  .cutomer-login-button {
    height: 2rem;
    text-decoration: underline;
    padding: 0.5rem;
    cursor: pointer;
  }
  .forget-password-div {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: flex-end;
  }
  .forget-password-text {
    font-size: 0.8rem;
    /* width: 100%; */
    text-decoration: underline;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
  }
  @media only screen and (max-width: 480px) {
    .login_container_changePassword {
      width: 100vw;
      height: 100%;

      background-color: ${colorPalette.secondaryWhite};
    }
    .arrow-back {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 1rem 0.5rem;
      /* background-color: ${colorPalette.primaryColor}; */
      cursor: pointer;
    }
    .login_form-forget-password {
      background-color: white;
      /* background-color: antiquewhite; */
      /* height: 100%; */
      width: 80%;
      z-index: 3;
      box-shadow: ${colorPalette.boxShadowPrimary};
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
    }
    /* .login_container { */
    /* background-image: url("../../assets//login//background\ image.jpg");
        background-repeat: no-repeat;
        background-position: inherit;
        background-size: cover; */
    width: 100vw;
    /* filter: blur(8px); */
    height: 100vh;
    background-color: ${colorPalette.secondaryWhite};
    /* opacity: .5; */
    /* 
        display: flex;
        align-items: center;
        justify-content: center; */
    /* } */
    .logo_container {
      width: 100%;
      height: 30%;
      background-image: url("../../assets/logo/leap_supply_logo.svg");
      background-repeat: no-repeat;
      /* background-position:center;
    background-position:center; */
      /* border-radius: 20px; */
      /* background-color: antiquewhite; */
      /* -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px; */
    }
    .logo_leap {
      width: 100%;
    }
    .login_right {
      z-index: 9999;
      flex: 12;
      border-radius: 20px;
      height: 100vh;
      /* width: 100vw; */
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
    }
    .change-password-form {
      /* flex: 11; */
      /* margin-top: 8rem; */
      /* padding: .4rem .8rem; */
      background-color: white;
      /* background-color: antiquewhite; */
      height: 70%;
      width: 90%;
      margin-bottom: 10rem;
      z-index: 3;
      box-shadow: ${colorPalette.boxShadowPrimary};
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      border: 1px solid green;
    }
    .login_left {
      background-color: ${colorPalette.primaryColor};
      flex: 0;
    }
    .login_form {
      background-color: ${colorPalette.white};
      height: 100%;
      width: 100%;
      /* margin-bottom: 4rem; */
      z-index: 3;
      box-shadow: ${colorPalette.boxShadowPrimary};
      border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .headings_div {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .headings {
      font-size: ${typography.heading2};
      font-weight: ${FontWeight.bold};
      font-family: ${FontFamily.primaryFontFamily};
      color: ${colorPalette.primaryColor};
    }
    .headings_login {
      width: 80%;
      padding: 2rem 0rem;
      height: 8%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .login_text {
      font-size: ${typography.heading3};
      font-family: ${FontFamily.primaryFontFamily};
      color: black;
      font-weight: ${FontWeight.bold};
    }
    .login_box {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 70%;

      margin: 0 auto;
      /* background-color: ${colorPalette.primaryColor}; */
      /* height: 70%; */
    }
    .login_text_mobile {
      margin-top: 3rem;
    }
    .login_input {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .input_mobile {
      width: 80%;
    }
    .button_container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 2rem;
    }
    .blur {
      /* background: rgba(0, 0, 0, 0.5); */
      /* filter: blur(10x); */
      width: 100%;
      -webkit-filter: blur(10x);
    }
  }
`;
