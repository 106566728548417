import axios from "axios";
import axiosInterceptor from "../../config/https";
import apiUrl from "../../url/apiUrl";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";

export const getReceivableList = async (customerId, currentPage, status) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.journalBaseUrl}/${prefixSalt()}-${convertToBase64(
        customerId
      )}/trips?trip_payment_status=${status}&current_page=${currentPage}`
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
  }
};

export const createJournalApi = (
  tripId,
  customerId,
  creditAmount,
  remarks,
  txnRefId,
  attachment
) => {
  let data = new FormData();

  data.append("trip_id", tripId);
  data.append("credit_amount", creditAmount);
  data.append("entity_id", customerId);
  data.append("transaction_reference_id", txnRefId);
  data.append("remarks", remarks);
  // data.append("payment_mode", "net_bank");
  if (attachment) {
    data.append("payment_mode", "net_bank");
  }
  if (!attachment) {
    data.append("payment_mode", "tds_deduction");
  }
  if (attachment) {
    data.append("image_file", attachment);
  }
  if (attachment) {
    data.append("journal_type", "customer_receivables");
  }
  if (!attachment) {
    data.append("journal_type", "customer_receivables");
  }

  var config = {
    method: "post",
    url: `${apiUrl.journalBaseUrl}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};

export const updateJournalApi = (
  creditAmount,
  txnRefId,
  remarks,
  attachment,
  journalId
) => {
  let data = new FormData();

  data.append("credit_amount", creditAmount);
  data.append("transaction_reference_id", txnRefId);
  if (attachment) {
    data.append("image_file", attachment);
  }
  data.append("remarks", remarks);

  data.append("journal_type", "customer_receivables");

  var config = {
    method: "put",
    url: `${apiUrl.journalBaseUrl}/${prefixSalt()}-${convertToBase64(
      journalId
    )}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};
export const updateTdsApi = (creditAmount, remarks, journalId) => {
  let data = new FormData();

  data.append("credit_amount", creditAmount);
  data.append("remarks", remarks);
  data.append("journal_type", "customer_receivables");
  data.append("payment_mode", "tds_deduction");

  var config = {
    method: "put",
    url: `${apiUrl.journalBaseUrl}/${prefixSalt()}-${convertToBase64(
      journalId
    )}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};

export const getJournalList = async (tripId) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.journalBaseUrl}?trip_id=${tripId}&journal_type=customer_receivables`
    );
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
  }
};
export const approveRecordedPayment = async (creditId) => {
  let data = JSON.stringify({
    status: "approved",
  });
  var config = {
    method: "patch",
    url: `${apiUrl.journalBaseUrl}/${prefixSalt()}-${convertToBase64(
      creditId
    )}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};
export const rejectRecordedPayment = async (creditId) => {
  let data = JSON.stringify({
    status: "rejected",
  });
  var config = {
    method: "patch",
    url: `${apiUrl.journalBaseUrl}/${prefixSalt()}-${convertToBase64(
      creditId
    )}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};
