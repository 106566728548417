import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import "./receivablesDetailsStyle.css";
import UploadImage from "../../assets/upload.svg";
import { Alert, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Snackbar from "@mui/material/Snackbar";
import { ArrowBack, Close, DarkMode, Edit } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import {
  approveRecordedPayment,
  createJournalApi,
  getJournalList,
  getReceivableList,
  rejectRecordedPayment,
  updateJournalApi,
  updateTdsApi,
} from "./receivablesDetailsServices";
import { ModalUnstyled } from "@mui/base";
import { styled } from "@mui/system";

import { Drawer, InputAdornment, TextField, Box } from "@mui/material";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { colorPalette, colorPaletteDark } from "../../themes/theme";
import { readLoginData } from "../../helper/dataDecryptionHelper";
import Topbar from "../../components/topbar/Topbar.jsx";
import {
  Payment_receiavble_drawer,
  Payment_receivables,
} from "../paymentReceivables/paymentReceivables.js";
import { DarkModeContext } from "../../context/darkModeContext.js";
import moment from "moment";

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

const tripRoutesBoxStyle = {
  width: isMobile ? "90vw" : "40%",
  height: isMobile ? "auto" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -24,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};

const exportBoxStyle = {
  width: isMobile ? "85%" : "70%",
  height: isMobile ? "80vh" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  // marginTop: isMobile ? -10 : -20,
  border: "1px solid gray",
  borderRadius: "10px",
};

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${isMobile ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.5)`};
  -webkit-tap-highlight-color: transparent;
`;

function ReceivablesDetailsPage() {
  const { toggle, darkMode } = useContext(DarkModeContext);

  const history = useHistory();
  const { customerId, customerName } = useParams();
  const [receivableList, setReceivableList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentTab, setCurrentTab] = useState("unpaid");

  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [drawerOpenFulfill, setDrawerOpenFulfill] = useState(false);

  const [remarksReject, setRemarksReject] = useState("");
  const [selectedReceivableId, setSelectedReceivableId] = useState("");
  const [payDetailsDrawerOpen, setPayDetailsDrawerOpen] = useState(false);
  const [quoteAmount, setQuoteAmount] = useState("");
  const [unuthorized, setUnuthorized] = useState("");
  const [advanceAmount, setAdvanceAmount] = useState("");
  const [remarksAddQuote, setRemarksAddQuote] = useState("");
  const [quoteImage, setQuoteImage] = useState(null);
  const [opneTdsForm, setOpneTdsForm] = useState(false);
  const [loadingSaveQuote, setLoadingSaveQuote] = useState(false);
  const [loadingRecordButton, setLoadingRecordButton] = useState(false);
  const [loadingAddTds, setLoadingAddTds] = useState(false);
  const [editModeJournal, setEditModeJournal] = useState(false);
  const [singleReceivableData, setSingleReceivableData] = useState({});
  //

  const [addQuoteSuccessToast, setAddQuoteSuccessToast] = useState(false);
  const [addQuoteErrorToast, setAddQuoteErrorToast] = useState(false);
  const [quoteToastError, setQuoteToastError] = useState("");
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingPageApprove, setLoadingPageApprove] = useState(false);
  const [loadingPageReject, setLoadingPageReject] = useState(false);
  const [openTripRoutes, setOpenTripRoutes] = useState(false);
  const [selectedReceivable, setSelectedReceivable] = useState({});

  const [receivableSuccessToast, setReceivableSuccessToast] = useState("");

  const [loadingJournal, setLoadingJournal] = useState(true);
  const [journalForm, setJournalForm] = useState({
    txnRefId: "",
    amount: "",
    remarks: "",
    image: null,
  });

  const [errorJournalForm, setErrorJournalForm] = useState({
    errorRefId: "",
    errorAmount: "",
    errorRemarks: "",
    errorImage: "",
  });
  const [journalList, setJournalList] = useState([]);

  const tdsData = journalList.filter(
    (data) => data.payment_mode == "tds_deduction" && data.status == "approved"
  )[0];

  const handleChangeInput = (e) => {
    const tempForm = { ...journalForm };
    tempForm[e.target.name] = e.target.value;
    setJournalForm(tempForm);
  };

  const handleCloseToast = () => {
    setQuoteToastError("");
    setAddQuoteSuccessToast(false);
    setAddQuoteErrorToast(false);
  };

  const handleCloseTripRoute = () => {
    setOpenTripRoutes(false);
  };

  const handleClosePayDetailsDrawer = () => {
    setDrawerOpen(false);
    handleTdsFormClose();
    setTdsAmountExceedMsg("");
    setPayDetailsDrawerOpen(false);
    setLoadingJournal(true);
    setJournalList([]);
    resetForm();
    setLoadingPageApprove(false);
    setLoadingPageReject(false);
  };

  // const handleCloseDrawer = () => {
  //   setDrawerOpen(false);
  //   setPayDetailsDrawerOpen(false);
  //   resetForm();
  // };
  const handleOpenRecordDrawer = (receivableData) => {
    setSingleReceivableData(receivableData);

    setDrawerOpen(true);
  };

  const handleSelectReceivable = (receivable) => {
    setSelectedReceivable(receivable);
  };

  const handleCloseRecordDrawer = () => {
    resetForm();
    setEditModeJournal(false);
    setUnuthorized("");
    setDrawerOpen(false);
  };
  const handleOpenRoutes = (receivable) => {
    setSelectedReceivable(receivable);
    setOpenTripRoutes(true);
  };

  const handleChangeAttachment = (e) => {
    setJournalForm({ ...journalForm, image: e.target.files[0] });
  };

  const vertical = "bottom";
  const horizontal = "right";

  const handleOpenAddQuote = (receivableId) => {
    setSelectedReceivableId(receivableId);
    setPayDetailsDrawerOpen(true);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const resetForm = () => {
    setErrorJournalForm({
      errorRefId: "",
      errorAmount: "",
      errorRemarks: "",
      errorImage: "",
    });
    setSelectedReceivableId("");
    setRemarksReject("");
    setPayDetailsDrawerOpen(false);
    setQuoteAmount("");
    setAdvanceAmount("");
    setRemarksAddQuote("");
    setQuoteImage(null);
    setLoadingSaveQuote(false);
    setLoadingRecordButton(false);
    setQuoteToastError("");
    setJournalForm({ txnRefId: "", amount: "", remarks: "", image: null });
  };

  const isJournalFormValid = () => {
    const isImageValid =
      journalForm.image &&
      journalForm.image?.size <= process.env.REACT_APP_MAX_FILE_SIZE;
    if (editModeJournal) {
      return Boolean(journalForm.txnRefId) && Boolean(journalForm.amount > 0);
    } else {
      if (opneTdsForm) {
        return Boolean(journalForm.amount > 0) && Boolean(journalForm.remarks);
      }
      return (
        Boolean(isImageValid) &&
        Boolean(journalForm.txnRefId) &&
        Boolean(journalForm.amount > 0) &&
        Boolean(journalForm.remarks)
      );
    }
  };

  const handleCreateJournal = async () => {
    if (!isJournalFormValid()) {
      setErrorJournalForm({
        errorRefId: "Enter valid UTR number",
        errorAmount: "Enter valid amount",
        errorImage: `Select valid image file with size less than ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB`,
        errorRemarks: "Enter valid remarks",
      });
      return;
    }
    setLoadingRecordButton(true);
    setQuoteToastError("");
    var response = "";
    // if(!opneTdsForm){

    // }
    if (editModeJournal) {
      response = await updateJournalApi(
        journalForm.amount,
        journalForm.txnRefId,
        journalForm.remarks,
        journalForm.image,
        journalForm?.id
      );
      if (response.status === 200) {
        setEditModeJournal(false);
      }
    } else {
      response = await createJournalApi(
        selectedReceivable?.id,
        customerId,
        journalForm.amount,
        journalForm.remarks,
        journalForm.txnRefId,
        journalForm.image
      );
    }

    setLoadingRecordButton(false);
    if (response.status === 200) {
      setUnuthorized("");
      handleClosePayDetailsDrawer();
      setAddQuoteSuccessToast(true);
      setReceivableSuccessToast("Payment record is successfully added!");
      handleGetReceivableList(customerId, currentPage, currentTab);

      resetForm();
    } else if (response.status === 400) {
      setUnuthorized("");
      setQuoteToastError(response.data.detail);
    } else if (response.status === 401) {
      setUnuthorized("Unauthorized Access!");
      // setQuoteToastError(response.data.detail);
    } else {
      setUnuthorized("");
      setQuoteToastError(`${response.status}`);
    }
  };
  const handleCreateTds = async () => {
    setLoadingAddTds(true);
    // console.log("journal vvalid>>>>", isJournalFormValid());

    // setJournalForm({
    //   txnRefId: "yyyyy",
    //   amount: "",
    //   remarks: "",
    //   image: null,
    // });
    setLoadingRecordButton(true);
    setQuoteToastError("");
    var response = "";

    if (editModeJournal) {
      response = await updateTdsApi(
        journalForm.amount,
        journalForm.remarks,
        journalForm?.id
      );
      if (response.status == 200) {
        setEditModeJournal(false);
        //
        // quoteToastError("");
        setLoadingAddTds(false);
        // handleClosePayDetailsDrawer();
        // setAddQuoteSuccessToast(true);
        // setReceivableSuccessToast("Payment record is successfully added!");
        handleGetReceivableList(customerId, currentPage, currentTab);
        handleTdsFormClose();
        handleClosePayDetailsDrawer();
        // resetForm();
      }
      if (response.status === 401) {
        setQuoteToastError("Unauthorized Access!");
        setLoadingAddTds(false);
        // setLoadingAddTds(false);
        // setQuoteToastError(response.data.detail);
      }
    } else {
      if (!isJournalFormValid()) {
        setErrorJournalForm({
          errorRefId: "error",
          errorAmount: "Enter valid TDS amount",
          errorImage: "error",
          errorRemarks: "Enter valid remarks",
        });
        return;
      }
      const response = await createJournalApi(
        selectedReceivable?.id,
        customerId,
        journalForm.amount,
        journalForm.remarks,
        journalForm.txnRefId,
        journalForm.image
      );
      // }

      // setLoadingRecordButton(false);
      //
      if (response.status == 200) {
        //
        // quoteToastError("");
        setLoadingAddTds(false);
        // handleClosePayDetailsDrawer();
        // setAddQuoteSuccessToast(true);
        setReceivableSuccessToast("Payment record is successfully added!");
        handleGetReceivableList(customerId, currentPage, currentTab);
        handleTdsFormClose();
        handleClosePayDetailsDrawer();
        // resetForm();
      } else if (response.status === 400) {
        setLoadingAddTds(false);
        //
        setQuoteToastError(response.data.detail);
      } else if (response.status === 401) {
        setQuoteToastError("Unauthorized Access!");
        setLoadingAddTds(false);
        // setQuoteToastError(response.data.detail);
      } else {
        setLoadingAddTds(false);
        setQuoteToastError(`${response.status}`);
      }
    }
  };

  const handleClickEditJournal = (journal) => {
    setEditModeJournal(true);
    setDrawerOpen(true);
    setJournalForm({
      txnRefId: journal?.transaction_reference_id,
      amount: journal?.credit_amount,
      remarks: journal?.remarks,
      id: journal?.id,
      // image: null,
    });
  };
  const handleClickEditTds = (journal) => {
    handleTdsForm();
    setEditModeJournal(true);
    // setEditModeJournal(true);
    // setDrawerOpen(true);
    setJournalForm({
      txnRefId: journal?.transaction_reference_id,
      amount: journal?.credit_amount,
      remarks: journal?.remarks,
      id: journal?.id,
      // image: null,
    });
  };

  const handleChangeTab = (tab) => {
    if (!loadingPage) {
      setCurrentPage(1);
      setReceivableList([]);
      setLoadingPage(true);
      setCurrentTab(tab);
    }
  };

  const handleGetJournals = async (tripId) => {
    const response = await getJournalList(tripId);
    //
    if (response && response.status === 200) {
      setLoadingJournal(false);
      setJournalList(response.data?.journal_list.journal_list);
    }
  };
  const [unauthorizedMsg, setUnauthorizedMsg] = useState("");
  const handleGetReceivableList = async (customerId, currentPage, status) => {
    if (currentPage == 1) {
      setLoadingPage(true);
    }
    setLoadingMore(true);
    const response = await getReceivableList(customerId, currentPage, status);

    if (response.status == 401) {
      setLoadingPage(false);
      setUnauthorizedMsg(response.data.error);
    } else {
      if (currentPage > 1) {
        setReceivableList([
          ...receivableList,
          ...response?.data?.trip_details_list,
        ]);
        setIsLastPage(response?.data.is_last_page);
      } else {
        setReceivableList(response?.data?.trip_details_list);
        setIsLastPage(response?.data?.is_last_page);
      }
      setLoadingPage(false);
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    handleGetReceivableList(customerId, currentPage, currentTab);
  }, [currentPage, currentTab, customerId]);

  // pagination code

  const observer = useRef();
  const lastReceivable = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage(currentPage + 1);
          setLoadingMore(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage, currentPage]
  );

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const handleTdsForm = () => {
    setOpneTdsForm(true);
  };
  const handleTdsFormClose = () => {
    setQuoteToastError("");
    setEditModeJournal(false);
    setOpneTdsForm(false);
    setErrorJournalForm({
      errorRefId: "",
      errorAmount: "",
      errorRemarks: "",
      errorImage: "",
    });
    setJournalForm({
      txnRefId: "",
      amount: "",
      remarks: "",
      image: null,
    });
    // resetForm();
  };
  const [selectedApproveButton, setSelectedApproveButton] = useState(null);
  const [selectedRejectButton, setSelectedRejectButton] = useState(null);
  const [tdsAmountExceedMsg, setTdsAmountExceedMsg] = useState("");
  const acceptJournal = async (journal, e) => {
    setLoadingPageApprove(true);
    setSelectedApproveButton(parseInt(e.target.getAttribute("a-key")));
    //
    // console.log(
    //   "the journal data====////",
    //   parseInt(e.target.getAttribute("a-key"))
    // );
    const response = await approveRecordedPayment(journal.id);
    if (response.status == 200) {
      setLoadingPageApprove(false);
      handleGetJournals(journal.trip_id);
      handleGetReceivableList(customerId, currentPage, currentTab);
      setPayDetailsDrawerOpen(false);
      setTdsAmountExceedMsg("");
      // handleGetJournals(journal.trip_id);
      // handleGetReceivableList(customerId, currentPage, currentTab);
    }
    if (response.status == 400) {
      setLoadingPageApprove(false);
      setTdsAmountExceedMsg(response.data.detail);
    }
  };
  const rejectJournal = async (journal, e) => {
    setSelectedRejectButton(parseInt(e.target.getAttribute("a-key")));
    //
    setLoadingPageReject(true);
    const response = await rejectRecordedPayment(journal.id);
    if (response.status == 200) {
      setLoadingPageReject(false);
      handleGetJournals(journal.trip_id);
      setPayDetailsDrawerOpen(false);
      setTdsAmountExceedMsg("");
      // handleGetJournals(receivable?.id);
      // handleSelectReceivable();
    }
    if (response.status == 400) {
      setLoadingPageReject(false);
      setTdsAmountExceedMsg(response.data.detail);
    }
    //
  };
  function capitalizeLetter(str) {
    var i,
      splittedArray = str.split("_");
    for (i = 0; i < splittedArray.length; i++) {
      splittedArray[i] =
        splittedArray[i].charAt(0).toUpperCase() + splittedArray[i].slice(1);
    }
    return splittedArray.join(" ");
  }
  // const formValidation = () => {
  //   const errorOemName = {};
  //   const errorPhoto = {};
  //   let isValid = true;
  //   if (oem == "") {
  //     errorOemName.errorOem = "Manufacturer name is mandatory.";
  //     isValid = false;
  //   }
  //   if (editMode == false) {
  //     if (manufacturerPhoto.name == undefined) {
  //       errorPhoto.Photomissing = "Please Upload Logo";
  //       isValid = false;
  //     }
  //   }

  //   setErrorOemName(errorOemName);
  //   setErrorPhoto(errorPhoto);
  //   return isValid;
  // };
  // console.log("the data==========", readLoginData().Role);
  return (
    <Payment_receivables
      style={{ display: "flex", width: "100%" }}
      mode={darkMode}
    >
      {isMobile ? null : (
        <div className="receivable-sidebar">
          <SideBarMain />{" "}
        </div>
      )}

      <Snackbar
        open={addQuoteSuccessToast}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseToast}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          {receivableSuccessToast}
        </Alert>
      </Snackbar>

      {/*Trip routes modal starts*/}
      <StyledModal
        open={openTripRoutes}
        onClose={handleCloseTripRoute}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={tripRoutesBoxStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="trip-pickup-modal-head"
                style={{ fontSize: "16px", fontWeight: "600" }}
              >
                <div> Routes </div>{" "}
                <div style={{ marginLeft: "4px" }}>
                  {" "}
                  ( Receivable code :{" "}
                  {selectedReceivable?.receivable_code
                    ? selectedReceivable?.receivable_code
                    : " - "}{" "}
                  )
                </div>
              </div>
              <div>
                <Close
                  onClick={handleCloseTripRoute}
                  style={{
                    cursor: "pointer",
                    color: darkMode
                      ? colorPaletteDark.white
                      : colorPalette.black,
                  }}
                />{" "}
              </div>
            </div>
            <div>
              <div className="trip-list-address">
                <div className="trip-location-list">
                  {selectedReceivable.source?.map((source) => {
                    return <div className="trip-location-text">From</div>;
                  })}
                  {/* <div className="trip-location-text">From</div> */}
                  {selectedReceivable.destination?.map((destinations) => {
                    return <div className="trip-location-text">To</div>;
                  })}
                </div>
                <div className="trip-list-path">
                  {/* <div className="trip-circle"></div>
                  <div className="trip-rectangle"></div> */}
                  {selectedReceivable.source?.map((source, index) => {
                    return (
                      <>
                        {index == 0 ? null : (
                          <>
                            <div className="trip-circle"></div>
                            <div className="mini-trip-rectangle"></div>
                          </>
                        )}
                      </>
                    );
                  })}
                  {selectedReceivable.destination?.map((destinations) => {
                    return (
                      <>
                        <div className="trip-circle"></div>
                        <div className="mini-trip-rectangle"></div>
                      </>
                    );
                  })}
                  <div className="trip-circle"></div>
                </div>
                <div className="trip-location-list">
                  {/* <div className="trip-location-text"> */}
                  {selectedReceivable.source?.map((source) => {
                    return (
                      <div className="trip-location-text">
                        {source.village === source.district
                          ? source.village
                          : `${source.village},
                          ${source.district}`}{" "}
                        , {source.state}, {source.postcode}
                      </div>
                    );
                  })}

                  {selectedReceivable.destination?.map((destinations) => {
                    return (
                      <div className="trip-location-text">
                        {destinations.village === destinations.district
                          ? destinations.district
                          : `${destinations.village}, ${destinations.district}`}
                        , {destinations.state}, {destinations.postcode}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>
      {/*Trip routes modal ends*/}

      <div className="receivable-right-container">
        {isMobile ? null : <Topbar />}
        <div className="receivable-head-container">
          <div className="receivable-head-row">
            {isMobile ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div className="trip-list-mobile-header">
                  <MobileHeader headerTitle={customerName} />
                </div>
                {/* <div
                  className="receivable-head-text"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ArrowBack
                    style={{ cursor: "pointer" }}
                    onClick={handleGoBack}
                  />{" "}
                  <span style={{ marginLeft: "5px" }}>
                    {customerName ? customerName : " - "}
                  </span>
                </div> */}
              </div>
            ) : (
              <span
                className="receivable-head-text"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ArrowBack
                  style={{ fontSize: "25px", cursor: "pointer" }}
                  onClick={handleGoBack}
                />{" "}
                <span style={{ marginLeft: "5px" }}>
                  {customerName ? customerName : " - "}
                </span>
              </span>
            )}

            {/* <button
              className="receivable-add-quote-btn"
              onClick={() => handleOpenAddQuote()}
            >
              Add quote
            </button> */}
          </div>

          <div className="receivable-tab-menuOption-contain">
            <div
              className={"receivable-tab-menu-option"}
              style={
                currentTab === "unpaid"
                  ? { color: colorPalette.primaryColor, fontWeight: "600" }
                  : null
              }
              onClick={() => handleChangeTab("unpaid")}
            >
              Payment Pending
              {currentTab === "unpaid" ? (
                <div className="receivable-tab-bottom-rectangle"></div>
              ) : null}
            </div>
            <div
              className="receivable-tab-menu-option"
              style={
                currentTab === "paid"
                  ? { color: colorPalette.primaryColor, fontWeight: "600" }
                  : null
              }
              onClick={() => handleChangeTab("paid")}
            >
              Payment Received
              {currentTab === "paid" ? (
                <div className="receivable-tab-bottom-rectangle"></div>
              ) : null}
            </div>
          </div>
        </div>
        <div></div>

        {/* search bar  */}

        {/* <div className="receivable-search-row">
          <div className="receivable-search-container">
            <img
              src={SEARCH_GRAY}
              alt="search"
              style={{ marginRight: "5px" }}
            />
            <input placeholder="search" className="receivable-search-input" />
            <img src={FILTER_GRAY} alt="filter" />
          </div>
        </div> */}

        {/* search bar  */}

        {loadingPage ? (
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : receivableList.length === 0 && !unauthorizedMsg ? (
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "red",
            }}
          >
            No receivable found
          </div>
        ) : unauthorizedMsg ? (
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "red",
            }}
          >
            {unauthorizedMsg}
          </div>
        ) : (
          <div className="receivable-list-container">
            <div className="receivable-grid-main-container">
              {receivableList.map((receivable, index) => {
                return (
                  <div
                    className="receivable-single-item-container"
                    onClick={() => handleSelectReceivable(receivable)}
                    style={
                      receivable.id === selectedReceivable.id
                        ? { border: `2px solid ${colorPalette.primaryColor}` }
                        : { border: `2px solid ${colorPalette.secondaryWhite}` }
                    }
                    key={index}
                  // ref={lastReceivable}
                  >
                    <div className="receivable-top-row">
                      {receivableList?.length === index + 1 ? (
                        <div
                          className="receivable-bold-txt"
                          ref={lastReceivable}
                        >
                          Trip code :{" "}
                          {receivable?.trip_code
                            ? receivable?.trip_code
                            : " - "}
                        </div>
                      ) : (
                        <div className="receivable-bold-txt">
                          Trip code :{" "}
                          {receivable?.trip_code
                            ? receivable?.trip_code
                            : " - "}
                        </div>
                      )}

                      <div
                        className="receivable-status-txt"
                        style={{ textTransform: "capitalize" }}
                      >
                        {receivable?.trip_status
                          ? receivable?.trip_status
                          : " - "}
                      </div>
                    </div>
                    <div className="receivable-middle-row">
                      <div className="receivable-vehicle-txt">
                        Trip Start Date :{" "}
                        {moment(
                          receivable?.trip_start_date
                            ? receivable?.trip_start_date
                            : " - "
                        ).format("ll")}
                        {/* {receivable?.trip_start_date
                          ? receivable?.trip_start_date
                          : " - "} */}
                      </div>
                      <div className="receivable-vehicle-txt">
                        Trip End Date :{" "}
                        {moment(
                          receivable?.trip_end_date
                            ? receivable?.trip_end_date
                            : " - "
                        ).format("ll")}
                        {/* {receivable?.trip_end_date
                          ? receivable?.trip_end_date
                          : " - "} */}
                      </div>
                    </div>
                    <div className="receivable-middle-row">
                      <div className="receivable-vehicle-txt">
                        Vehicle number :{" "}
                        {receivable?.vehicle_number
                          ? receivable?.vehicle_number
                          : " - "}
                      </div>
                    </div>
                    <div className="receivable-bottom-row">
                      <div className="receivable-amount-contain">
                        {currentTab === "paid" ? null : (
                          <div className="receivable-amount-big-txt">
                            <span style={{ fontFamily: "monospace" }}>₹ </span>
                            {receivable?.trip_outstanding_amount
                              ? receivable?.trip_outstanding_amount
                              : "0"}
                          </div>
                        )}

                        <div className="receivable-amount-txt">
                          Total amount :
                          <span style={{ fontFamily: "monospace" }}> ₹ </span>
                          {receivable?.customer_billed_amount
                            ? receivable?.customer_billed_amount
                            : "0"}
                        </div>
                      </div>
                      <div className="receivable-button-contain">
                        {currentTab === "paid" ? null : (
                          <button
                            className="receivable-record-btn"
                            onClick={() => {
                              handleOpenRecordDrawer(receivable);
                            }}
                          >
                            Record payment
                          </button>
                        )}

                        <button
                          className="receivable-details-btn"
                          onClick={() => {
                            handleGetJournals(receivable?.id);
                            setPayDetailsDrawerOpen(true);
                          }}
                        >
                          Payment history
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
              {loadingMore ? (
                <div className="loading_more_data">Loading More ...</div>
              ) : null}
            </div>
          </div>
        )}
      </div>
      <Drawer
        open={drawerOpen}
        anchor={"right"}
        onClose={() => {
          handleCloseRecordDrawer();
        }}
        PaperProps={{
          sx: {
            width: isMobile ? 340 : 440,
            // backgroundColor: darkMode
            //   ? colorPaletteDark.secondaryColor
            //   : colorPalette.white,
          },
        }}
      >
        <Payment_receiavble_drawer
          className="receivable-drawer-container"
          mode={darkMode}
        >
          <div
            // style={{ display: "flex", justifyContent: "space-between" }}
            className="receivable-drawer-top"
          >
            <div className="receivable-record-head">Record Payment</div>
            <Close
              style={{
                cursor: "pointer",
                color: darkMode ? colorPaletteDark.white : colorPalette.black,
              }}
              onClick={handleCloseRecordDrawer}
            />
          </div>
          <div>
            <div className="receivable-record-head_details">
              Trip Code: {singleReceivableData.trip_code}
            </div>
          </div>
          <div className="receivable-record-head_details_date">
            <span>
              Start date:{" "}
              {moment(
                singleReceivableData?.trip_start_date
                  ? singleReceivableData?.trip_start_date
                  : ""
              ).format("ll")}
            </span>{" "}
            <span>
              End date:{" "}
              {moment(
                singleReceivableData?.trip_end_date
                  ? singleReceivableData?.trip_end_date
                  : ""
              ).format("ll")}
            </span>
          </div>
          <div className="receivable-input-container">
            <div className="receivable-utr-txt">
              Enter UTR<sup style={{ color: "#E06666" }}>*</sup>
            </div>
            <TextField
              size="small"
              name="txnRefId"
              autoComplete="off"
              className="receivable-inputfield"
              variant="outlined"
              value={journalForm?.txnRefId}
              placeholder="Enter UTR Number"
              onChange={(e) => handleChangeInput(e)}
            />{" "}
            <div className="error">
              {journalForm?.txnRefId ? null : errorJournalForm.errorRefId}
            </div>
          </div>
          <div className="receivable-input-container">
            <div className="receivable-utr-txt">
              Enter Amount<sup style={{ color: "#E06666" }}>*</sup>
            </div>
            <TextField
              size="small"
              name="amount"
              autoComplete="off"
              className="receivable-inputfield"
              variant="outlined"
              value={journalForm?.amount}
              placeholder="Enter amount"
              onChange={(e) => handleChangeInput(e)}
              type="number"
              onWheel={(e) => e.target.blur()}
            />{" "}
            <div className="error">
              {journalForm?.amount ? null : errorJournalForm.errorAmount}
            </div>
          </div>

          {/* {editModeJournal ? null : (
            <> */}
          <div className="receivable-input-container">
            <div className="receivable-utr-txt">
              Upload attachment<sup style={{ color: "#E06666" }}>*</sup>
            </div>
            <TextField
              size="small"
              autoComplete="off"
              className="receivable-inputfield"
              variant="outlined"
              placeholder="Upload attachment"
              type="number"
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      cursor: "pointer",
                      color: colorPalette.primaryColor,
                      fontWeight: "600",
                    }}
                  // onClick={handleAddAttachment}
                  >
                    <button className="upload_button">
                      <input
                        // name="upload"
                        name="attachment_file"
                        id={`upload`}
                        type="file"
                        accept="image/png, image/jpeg, application/pdf"
                        onChange={(e) => {
                          handleChangeAttachment(e);
                        }}
                        hidden
                        style={{
                          backgroundColor: colorPalette.secondaryWhite,
                        }}
                      />
                      <label
                        htmlFor={`upload`}
                        style={{
                          backgroundColor: colorPalette.secondaryWhite,
                        }}
                      >
                        <img
                          src={UploadImage}
                          alt=""
                          width="20px"
                          height="20px"
                          style={{
                            backgroundColor: colorPalette.secondaryWhite,
                            // width: "100%",
                            // height: "100%",
                          }}
                        />
                      </label>
                    </button>
                  </InputAdornment>
                ),
              }}
            />
            <div style={{ fontSize: "12px" }}>
              {journalForm?.image ? journalForm?.image?.name : null}
            </div>
            <div className="error">
              {journalForm?.image &&
                journalForm?.image?.size < process.env.REACT_APP_MAX_FILE_SIZE
                ? null
                : errorJournalForm.errorImage}
            </div>
          </div>
          <div className="receivable-input-container">
            <div className="receivable-utr-txt">
              Remarks<sup style={{ color: "#E06666" }}>*</sup>
            </div>
            <div className="receivable-remarks-inputfield-conatiner">
              <textarea
                className="receivable-remarks-textarea"
                name="remarks"
                placeholder="Add Remark"
                value={journalForm?.remarks}
                onChange={(e) => handleChangeInput(e)}
              ></textarea>
              <div className="error">
                {journalForm.remarks ? null : errorJournalForm.errorRemarks}
              </div>
            </div>
          </div>
          {/* </>
          )} */}

          <div>
            {quoteToastError ? (
              <Alert severity="error">
                {quoteToastError ? quoteToastError : ""}
              </Alert>
            ) : null}
            {unuthorized ? (
              <Alert severity="error">{unuthorized ? unuthorized : ""}</Alert>
            ) : null}
          </div>
          <div className="receivable-remarks-submit-btn-cover">
            <button
              onClick={() => handleCreateJournal()}
              className="receivable-details-btn"
              disabled={loadingRecordButton}
              style={{ width: "150px", height: "36px" }}
            >
              {loadingRecordButton ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Record Payment"
              )}
            </button>
          </div>
          {editModeJournal ? null : (
            <div
              // onClick={() => {
              //   handleCloseRecordDrawer();
              //   handleGetJournals(selectedReceivable?.id);
              //   setPayDetailsDrawerOpen(true);
              //   setOpneTdsForm(true);
              // }}
              className="tds_deduction_add_route_msg_click"
            >
              *Wants to capture TDS ?{" "}
              <span
                className="tds_deduction_add_route_msg"
                onClick={() => {
                  handleCloseRecordDrawer();
                  handleGetJournals(selectedReceivable?.id);
                  setPayDetailsDrawerOpen(true);
                  setOpneTdsForm(true);
                }}
              >
                Click here
              </span>
            </div>
          )}
        </Payment_receiavble_drawer>
      </Drawer>

      <Drawer
        open={payDetailsDrawerOpen}
        anchor={"right"}
        onClose={() => handleClosePayDetailsDrawer()}
        PaperProps={{
          sx: {
            width: isMobile ? 340 : 440,
          },
        }}
      >
        <Payment_receiavble_drawer
          className="receivable-drawer-container"
          mode={darkMode}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="receivable-record-head">Payment details</div>
            <Close
              style={{
                cursor: "pointer",
                color: darkMode ? colorPaletteDark.white : colorPalette.black,
              }}
              onClick={handleClosePayDetailsDrawer}
            />
          </div>
          <div className="receivable-payDetails-container">
            {journalList.length > 0 ? (
              journalList.map((journal, index) => {
                return (
                  <div className="receivable-pay-single">
                    <div className="receivable-pay-top-row">
                      {journal?.transaction_reference_id ? (
                        <div className="receivable-pay-utr-txt">
                          UTR :{" "}
                          {journal?.transaction_reference_id
                            ? journal?.transaction_reference_id
                            : " - "}
                        </div>
                      ) : (
                        <div className="receivable-pay-utr-txt">
                          TDS Amount{" "}
                        </div>
                      )}

                      <div className="receivable-pay-utr-txt">
                        <span
                          style={{ fontFamily: "poppins", fontWeight: "bold" }}
                        >
                          ₹
                        </span>{" "}
                        {journal?.credit_amount ? journal?.credit_amount : "0"}
                      </div>
                    </div>
                    <div>
                      <div className="receivable-pay-remarks-text">
                        {journal?.remarks ? journal?.remarks : " - "}
                      </div>
                    </div>
                    {/* <div className="receivable-pay-utr-txt">
                      (
                      {journal?.payment_mode === "net_bank"
                        ? "Net banking"
                        : journal?.payment_mode?.split("_").join(" ")}
                      )
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="receivable-pay-remarks-text"
                        style={{
                          color:
                            journal?.status == "approved"
                              ? "#076B38"
                              : journal?.status == "rejected"
                                ? "red"
                                : "#FFA500",
                          fontWeight: "500",
                        }}
                      >
                        {capitalizeLetter(
                          journal?.status ? journal?.status : ""
                        )}
                      </div>
                      {currentTab == "paid" ? null : (
                        <div className="button_tds_accept_reject">
                          {(readLoginData().Role == "Finance Admin" ||
                            readLoginData().Role == "Finance Manager" ||
                            readLoginData().Role == "Admin" ||
                            readLoginData().Role == "Super Admin" ||
                            readLoginData().Role.toLowerCase() ==
                            "finance admin" ||
                            readLoginData().Role.toLowerCase() ==
                            "finance manager" ||
                            readLoginData().Role.toLowerCase() == "admin" ||
                            readLoginData().Role.toLowerCase() ==
                            "super admin") &&
                            journal?.status == "draft" ? (
                            <>
                              <button
                                a-key={journal.id}
                                className="receivable-approve"
                                onClick={(e) => acceptJournal(journal, e)}
                                // onClick={()=>{handleXyz(journal)}}
                                disabled={loadingPageApprove}
                              >
                                {/* {selectedApproveButton==journal.id?():()} */}
                                {loadingPageApprove &&
                                  selectedApproveButton == journal.id ? (
                                  <CircularProgress size={10} />
                                ) : (
                                  <>
                                    <CheckOutlinedIcon
                                      style={{ fontSize: "12px" }}
                                    />
                                    Approve
                                  </>
                                  // " Approve"
                                )}
                                {/* <CheckOutlinedIcon style={{ fontSize: "12px" }} /> */}
                              </button>
                              <button
                                a-key={journal.id}
                                className="receivable-approve"
                                onClick={(e) => rejectJournal(journal, e)}
                                disabled={loadingPageReject}
                              >
                                {loadingPageReject &&
                                  selectedRejectButton == journal.id ? (
                                  <CircularProgress size={10} />
                                ) : (
                                  <>
                                    {" "}
                                    <CancelOutlinedIcon
                                      style={{ fontSize: "12px" }}
                                    />
                                    Reject
                                  </>
                                )}
                              </button>{" "}
                            </>
                          ) : null}
                          {journal?.status == "approved" ||
                            journal?.status ==
                            "rejected" ? null : !journal?.transaction_reference_id ? (
                              <button
                                className="receivable-approve"
                                onClick={() => handleClickEditTds(journal)}
                              >
                                <Edit style={{ fontSize: "12px" }} /> Edit
                              </button>
                            ) : (
                            <button
                              className="receivable-approve"
                              onClick={() => handleClickEditJournal(journal)}
                            >
                              <Edit style={{ fontSize: "12px" }} /> Edit
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : loadingJournal ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "20vh",
                }}
              >
                <CircularProgress size={20} />
              </div>
            ) : (
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "500",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "20px 0px",
                  color: darkMode ? colorPaletteDark.white : colorPalette.black,
                }}
              >
                No payment record found!
              </div>
            )}
          </div>
          <div className="receivable-pay-bottom">
            <div className="receivable-line-amount">
              {" "}
              <div className="receivable-pay-amount-txt">Total cost </div>
              <div className="receivable-pay-amount-txt">
                <span style={{ fontFamily: "monospace" }}>₹</span>{" "}
                {selectedReceivable?.customer_billed_amount
                  ? selectedReceivable?.customer_billed_amount
                  : "0"}
              </div>
            </div>
            <div className="receivable-line-amount">
              {" "}
              <div className="receivable-pay-amount-txt">Amount received </div>
              <div className="receivable-pay-amount-txt">
                <span style={{ fontFamily: "monospace" }}> ₹</span>{" "}
                {selectedReceivable?.customer_credit_amount
                  ? selectedReceivable?.customer_credit_amount
                  : "0"}
              </div>
            </div>
            {/* {journalList.filter(data=>data.payment_mode=="tds_deduction")[0].} */}
            {tdsData?.payment_mode == "tds_deduction" ? (
              <div className="receivable-line-amount">
                {" "}
                <div className="receivable-pay-amount-txt">TDS </div>
                <div className="receivable-pay-amount-txt">
                  <span style={{ fontFamily: "monospace" }}> ₹</span>
                  {tdsData?.credit_amount ? tdsData?.credit_amount : "0"}
                </div>
              </div>
            ) : null}
            {selectedReceivable?.trip_outstanding_amount == 0 ? null : (
              <>
                <hr></hr>
                <div className="receivable-line-amount">
                  {" "}
                  <div className="receivable-pay-amount-big-txt">
                    Pending amount{" "}
                  </div>
                  <div className="receivable-pay-amount-big-txt">
                    <span style={{ fontFamily: "monospace" }}>₹</span>{" "}
                    {selectedReceivable?.trip_outstanding_amount
                      ? selectedReceivable?.trip_outstanding_amount
                      : "0"}
                  </div>
                </div>
              </>
            )}
            {/* <hr></hr>
            <div className="receivable-line-amount">
              {" "}
              <div className="receivable-pay-amount-big-txt">
                Pending amount{" "}
              </div>
              <div className="receivable-pay-amount-big-txt">
                <span style={{ fontFamily: "monospace" }}>₹</span>{" "}
                {selectedReceivable?.trip_outstanding_amount
                  ? selectedReceivable?.trip_outstanding_amount
                  : "0"}
              </div>
            </div> */}
          </div>
          <hr />
          <div className="receivable-pay-bottom">
            <div className="tds_button_container">
              {currentTab == "paid" ||
                selectedReceivable?.trip_outstanding_amount == 0 ||
                tdsData?.status == "approved" ? null : opneTdsForm ? null : (
                  <div className="add_tds_button" onClick={handleTdsForm}>
                    Add TDS
                  </div>
                )}
            </div>
            {tdsAmountExceedMsg ? (
              <div className="tds_error">{tdsAmountExceedMsg}</div>
            ) : null}

            {opneTdsForm ? (
              <div className="tds_form">
                <div className="tds_close_button">
                  {" "}
                  <Close
                    style={{
                      cursor: "pointer",
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.black,
                    }}
                    onClick={handleTdsFormClose}
                  />
                </div>
                <div className="receivable-utr-txt_tds">
                  TDS Amount<sup style={{ color: "#E06666" }}>*</sup>
                </div>
                <div className="tds_fields_container">
                  <TextField
                    size="small"
                    name="amount"
                    autoComplete="off"
                    className="receivable-inputfield_tdsField"
                    variant="outlined"
                    value={journalForm?.amount}
                    placeholder="Enter amount"
                    onChange={(e) => handleChangeInput(e)}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                  />{" "}
                  <div className="error">
                    {journalForm?.amount ? null : errorJournalForm.errorAmount}
                  </div>
                </div>
                <div className="receivable-utr-txt_tds">
                  Enter Remark <sup style={{ color: "#E06666" }}>*</sup>
                </div>
                <div className="tds_fields_container">
                  <textarea
                    className="receivable-remarks-textarea_tds"
                    name="remarks"
                    placeholder="Add Remark"
                    value={journalForm?.remarks}
                    onChange={(e) => handleChangeInput(e)}
                  ></textarea>
                  <div className="error">
                    {journalForm.remarks ? null : errorJournalForm.errorRemarks}
                  </div>
                  {/* <TextField
                    size="small"
                    name="remarks"
                    autoComplete="off"
                    className="receivable-inputfield_tdsField"
                    variant="outlined"
                    value={journalForm?.remarks}
                    placeholder="Enter Remark"
                    onChange={(e) => handleChangeInput(e)}
                  />{" "} */}
                </div>
                <div>
                  {quoteToastError ? (
                    <Alert severity="error">
                      {quoteToastError ? quoteToastError : ""}
                    </Alert>
                  ) : null}
                </div>
                <div className="tds_button_container">
                  <button
                    className="add_tds_button_submit"
                    onClick={() => handleCreateTds()}
                    disabled={loadingAddTds == true ? true : false}
                  >
                    {loadingAddTds ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <>Submit</>
                    )}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </Payment_receiavble_drawer>
      </Drawer>
    </Payment_receivables>
  );
}

export default ReceivablesDetailsPage;
