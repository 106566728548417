import { React, useEffect, useState, useRef, useCallback } from "react";
import { debounce } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import FILTER_ICON from "../../assets/filter_icon.svg";
import PersonIcon from "@mui/icons-material/Person";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Carrier from "../../assets/istockphoto-1214428300-170667a.jpg";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";
import moment from "moment/moment.js";
import { Drawer } from "@mui/material";
import { ModalUnstyled } from "@mui/base";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { onboardRegisterPost } from "../onboard/OnboardServices";
// import HandshakeIcon from "@mui/icons-material/Handshake";
import axios from "axios";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { Component_style } from "../../themes/component_style";

// import "../onboard/onboarding.css";
import TransporterImg from "../../assets/transporter/Customer(4).svg";
import telephoneImg from "../../assets/transporter/mobile(2).svg";
import customerImg from "../../assets/transporter/Customer(3).svg";
import apiUrl from "../../url/apiUrl";
import Box from "@mui/material/Box";
import carrier2 from "../../assets/placeholdercompany5f3438282f524800f1d49cd2921bb0a56101e1aa16097ebd313b64778fc7c4bd1611448792.png";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { onboardData } from "../../redux/onboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { getCarrierList } from "../onboard/OnboardServices";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { updateCarrierId } from "../../redux/onboardSlice.js";
import CarrierMiniCard from "../../components/carrierMiniCard/CarrierMiniCard.jsx";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { indexSelectedData } from "../../helper/dataDecryptionHelper";
import { styled } from "@mui/system";
import { searchTransporter } from "../../redux/searchSlice";
import CopyText from "../../components/copyText/CopyText";
import axiosInterceptor from "../../config/https";
import { Modal as ModalAntd } from "antd";
import {
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";
// import commonDrawer from "../../components/commonDrawer/CommonDrawer";
import CommonDrawer from "../../components/commonDrawer/CommonDrawer.jsx";
import { OnboardListStyle } from "./onboardListStyle";
import {
  ButtonRectangle,
  ButtonRectangleSecondary,
} from "../../themes/components/button";
import Topbar from "../../components/topbar/Topbar.jsx";
import { useContext } from "react";
import { DarkModeContext } from "../../context/darkModeContext.js";
import { UserNotAuthorizedStyle } from "../ftl_trips/ftl_tripListV2/trip-listv2.js";
import { Link } from "react-router-dom/cjs/react-router-dom.js";
import NotAuthorized from "../../components/notAuthorized/NotAuthorized.jsx";
import SomethingWentWrong from "../../components/somethingWentWrong/SomethingWentWrong.jsx";
import { useDebounce } from "../../hooks/useDebounce.js";
const CryptoJS = require("crypto-js");

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

const filterBoxStyle = {
  width: isMobile ? "90vw" : "36%",
  height: isMobile ? "auto" : "43%",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -34,
  marginTop: isMobile ? -10 : -20,
  border: "1px solid gray",
  borderRadius: "10px",
};

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${isMobile ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.5)`};
  -webkit-tap-highlight-color: transparent;
`;

function OnboardList() {
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");
  const [carrierListData, setCarrierListData] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState({});
  const [clickTab, setClickTab] = useState("");
  const [cardClicked, setCardClicked] = useState("");
  const [nameSearch, setNameSearch] = useState("");
  const [filterMobileNumber, setFilterMobileNumber] = useState("");
  const [filterPincode, setFilterPincode] = useState("");
  const [filterTransporterCode, setFilterTransporterCode] = useState("");
  const [commonDrawerOpen, setCommonDrawerOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    localStorage.getItem("selectedIndex")
      ? localStorage.getItem("selectedIndex")
      : 10
  );
  // setSelectedIndex(index)
  const [selectedTabName, setSelectedTabName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("approved");
  // setSelectedTabName(tranporterName)

  const [filterApplied, setFilterApplied] = useState("");
  // localStorage.setItem("cardClicked", cardClicked);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [isLastPage, setIsLastPage] = useState(false);
  const [transporterType, setTransporterType] = useState(
    localStorage.getItem("selectedTransporter")
      ? localStorage.getItem("selectedTransporter")
      : ""
  );
  // const [searchValue, setSearchValue] = useState("");
  const [carrierSearchData, setCarrierSearchData] = useState([]);
  const [loadingFilterSearchButton, setLoadingFilterSearchButton] =
    useState(false);
  // const debounce = (func, delay) => {
  //   let timeoutId;
  //   return (...args) => {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       func(...args);
  //     }, delay);
  //   };
  // };

  const [isUserNotAuthorized, setIsUserNotAuthorized] = useState(false);

  const handleDebouncing = debounce((event) => {
    dispatch(searchTransporter(event.target.value));
  }, 1000);

  const searchTerm = useSelector((data) => data.searchValues.searchTransporter);

  const [state, setState] = useState({ right: false });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const observer = useRef();
  const lastItem = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage]
  );

  const searchInput = useRef("");
  const deboucedSearch = useDebounce(search);

  // const getCarrierData = async (current) => {
  //   setLoadingMore(true);
  //   const carrierResponse = await getCarrierList(
  //     current
  //   );

  //   if (current > 1) {
  //     setCarrierListData([
  //       ...carrierListData,
  //       ...carrierResponse.transporter_list,
  //     ]);
  //     setIsLastPage(carrierResponse.is_last_page);
  //   } else {
  //     setCarrierListData(carrierResponse.transporter_list);
  //     setIsLastPage(carrierResponse.is_last_page);
  //   }
  //   setLoadingMore(false);
  //   // setCarrierListData(carrierResponse.transporter_list);
  // };

  // useEffect(() => {
  //   handleSearchFilter(
  //     nameSearch,
  //     transporterType,
  //     filterMobileNumber,
  //     filterPincode,
  //     filterTransporterCode,
  //     selectedStatus,
  //     currentPage
  //   );
  // }, [currentPage]);

  useEffect(() => {
    if (deboucedSearch) {
      handleTransporterSearch();
    } else {
      dispatch(searchTransporter(""));
      handleSearchFilter(
        nameSearch,
        transporterType,
        filterMobileNumber,
        filterPincode,
        filterTransporterCode,
        selectedStatus,
        currentPage
      );
    }
  }, [selectedStatus, deboucedSearch, transporterType, currentPage]);

  const handleCarrierId = (carrieerId) => {
    dispatch(updateCarrierId(carrieerId));
  };
  const handleCloseCommonDrawer = () => {
    setCommonDrawerOpen(false);
  };
  const handleOpenCommonDrawer = () => {
    setCommonDrawerOpen(true);
  };
  const handleSelectCard = (carrier, index) => {
    // toggleCommonDrawer();
    // setCommonDrawerOpen(true);
    handleOpenCommonDrawer();
    // handleOpenCommonDrawer();
    setSelectedCarrier(carrier);
    setCardClicked(index);
    let encrypted_data = CryptoJS.AES.encrypt(
      JSON.stringify({ ...indexSelectedData(), transporter: index }),
      apiUrl.SALT
    ).toString();
    localStorage.setItem("indexClicked", encrypted_data);
    if (isMobile) {
      handleOpenMenu();
    }
  };

  useEffect(() => {
    if (carrierListData.length > 0) {
      //
      if (indexSelectedData()) {
        if (indexSelectedData().hasOwnProperty("transporter")) {
          setCardClicked(indexSelectedData().transporter);
          if (carrierListData.length > indexSelectedData().transporter) {
            setSelectedCarrier(
              carrierListData[indexSelectedData().transporter]
            );
            // dispatch(updateSelectedTrip(carrierListData[indexSelectedData().transporter]));
          }
        }
      } else {
        setSelectedCarrier(carrierListData[0]);
        setCardClicked(0);
      }
    }
  }, [carrierListData]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTransporterSearch = async (clear) => {
    let transporter = "";
    let mobile = "";
    let mail = "";
    let name_ = "";
    let tranpsporter_code = "";
    let status = selectedStatus;
    let page = 1;
    // const transporterCodeRegex = /\b[F-R]{3}-d{4}\b/;
    const transporterCodeRegex = /^[a-zA-Z]{3}-\d+$/;
    const mobileNumberRegex = /^[+]?[0-9]+$/;
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}\b/g;
    const nameRegex = /^[a-zA-Z-' ]+$/;
    if (clear === "clear") {
    } else if (search) {
      if (transporterCodeRegex.test(search)) {
        tranpsporter_code = search;
      } else if (mobileNumberRegex.test(search)) {
        mobile = search;
      } else if (emailRegex.test(search)) {
        mail = search;
      } else if (nameRegex.test(search)) {
        name_ = search;
      } else {
        name_ = search;
      }
    }

    // if (search || mobile || mail || tranpsporter_code) {
    //   setFilterApplied("filter applied");
    // }
    setLoadingMore(true);
    // if (values && values.length >= 3) {
    try {
      // setLoading(true);
      let productResponse;
      if (status === "blocked") {
        productResponse = await axiosInterceptor.get(
          `${
            apiUrl.onboardingBaseUrl
          }/v3/transporter?name=${name_}&transporter_type=${transporter}&mobile=${mobile}&email=${mail}&transporter_code=${tranpsporter_code}&current_page=${page}&status=${
            status !== "blocked" ? status : ""
          }&is_active=${status == "blocked" ? false : true}`
        );
      } else {
        productResponse = await axiosInterceptor.get(
          `${
            apiUrl.onboardingBaseUrl
          }/v3/transporter?name=${name_}&transporter_type=${transporter}&mobile=${mobile}&email=${mail}&transporter_code=${tranpsporter_code}&current_page=${page}&status=${
            status !== "blocked" ? status : ""
          }&is_active=${status == "blocked" ? false : true}`
        );
      }

      if (page > 1) {
        setCarrierListData([
          ...carrierListData,
          ...productResponse.data.transporter_list,
        ]);
        setIsLastPage(productResponse.data.is_last_page);
        handleCloseFilterOptions();
        setLoadingMore(false);
        // setFilterApplied("filter applied");
      } else {
        setCarrierListData(productResponse.data.transporter_list);
        setIsLastPage(productResponse.data.is_last_page);
        handleCloseFilterOptions();
        setLoadingMore(false);
        // setFilterApplied("filter applied");
      }
    } catch (error) {
      if (error.response.status === 403) {
        localStorage.clear();
        window.location.reload();
      }
      if (error.response.status === 401) {
        toast.error("The user is unauthorised");
      }
      // setCarrierListData([]);
      setLoadingMore(false);
    }
    setLoadingMore(false);
    // }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Perform the action you want when Enter is pressed
      handleTransporterSearch();
    }
  };

  const handleSearchFilter = async (
    nameSearch,
    transporter,
    mobile,
    mail,
    tranpsporter_code,
    status,
    page
  ) => {
    if (nameSearch || mobile || mail) {
      setFilterApplied("filter applied");
    }
    setLoadingMore(true);
    // if (values && values.length >= 3) {
    try {
      // setLoading(true);
      let productResponse;
      if (status === "blocked") {
        productResponse = await axiosInterceptor.get(
          `${
            apiUrl.onboardingBaseUrl
          }/v4/transporter?name=${nameSearch}&transporter_type=${transporter}&mobile=${mobile}&email=${mail}&transporter_code=${tranpsporter_code}&current_page=${page}&status=${
            status !== "blocked" ? status : ""
          }&is_active=${status == "blocked" ? false : true}`
        );
      } else {
        productResponse = await axiosInterceptor.get(
          `${
            apiUrl.onboardingBaseUrl
          }/v4/transporter?name=${nameSearch}&transporter_type=${transporter}&mobile=${mobile}&email=${mail}&transporter_code=${tranpsporter_code}&current_page=${page}&status=${
            status !== "blocked" ? status : ""
          }&is_active=${status == "blocked" ? false : true}`
        );
      }

      if (page > 1) {
        setCarrierListData([
          ...carrierListData,
          ...productResponse.data.transporter_list,
        ]);
        setIsLastPage(productResponse.data.is_last_page);
        handleCloseFilterOptions();
        setLoadingMore(false);
        // setFilterApplied("filter applied");
      } else {
        setCarrierListData(productResponse.data.transporter_list);
        setIsLastPage(productResponse.data.is_last_page);
        handleCloseFilterOptions();
        setLoadingMore(false);
        // setFilterApplied("filter applied");
      }
    } catch (error) {
      if (error.response.status === 403) {
        localStorage.clear();
        window.location.reload();
      }
      if (error.response.status === 401) {
        // toast.error("The user is unauthorised");
        setIsUserNotAuthorized(true);
      }
      // setCarrierListData([]);
      setLoadingMore(false);
    }
    setLoadingMore(false);
    // }
  };

  const handlecancelSearch = () => {
    dispatch(searchTransporter(""));
    searchInput.current.value = "";
    // getCarrierData(1);
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const handleOpenMenu = () => {
    toggleDrawer("right", true);
    // setSuccess("");
    // setErrorMsg("");
  };
  const handleCloseBtn = () => {
    toggleDrawer("right", false);
  };
  const handleCloseFilterOptions = () => {
    setIsFilterOpen(false);
  };
  const handleOpenFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  const carrier = ["registered_transporter", "individual_transporter"];
  const handleClear = () => {
    setNameSearch("");
    // setTransporterType("");
    setFilterMobileNumber("");
    setFilterPincode("");
    setFilterTransporterCode("");
    handleSearchFilter("", "", "", "", "", "", 1);
    // getCarrierData(1);
    setFilterApplied("");
  };
  const handleCloseFilterModal = () => {
    setIsFilterOpen(false);
  };

  const handleClickTransporterType = (index, tranporterName) => {
    setSelectedIndex(index);
    setTransporterType(tranporterName);
  };
  useEffect(() => {
    if (!localStorage.getItem("selectedTransporter")) {
      handleClickTransporterType(10, "");
    }
    handleClickTransporterType(selectedIndex, transporterType);
  }, []);
  const { toggle, darkMode } = useContext(DarkModeContext);
  return (
    <OnboardListStyle className="main_onboard_container" mode={darkMode}>
      <ToastContainer />
      <StyledModal
        open={isFilterOpen}
        onClose={handleCloseFilterOptions}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={filterBoxStyle}>
          <div className="trip-list-filter-main">
            {isMobile ? (
              <div className="trip-list-filter-head">
                <span className="trip-list-filter-main-text">
                  Select filter options
                </span>{" "}
                <CloseIcon onClick={handleCloseFilterModal} />
              </div>
            ) : (
              <div className="trip-list-filter-head">
                <span
                  style={{ marginLeft: "5px" }}
                  className="trip-list-filter-main-text"
                >
                  Select filter options
                </span>{" "}
                <CloseIcon
                  onClick={handleCloseFilterModal}
                  style={{ cursor: "pointer" }}
                />
              </div>
            )}
            <div className="trip-list-filter-head"></div>
            <div className="trip-list-filter-container">
              <div className="trip-list-filter-row">
                Name
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    className="trip-list-filter-inputfield"
                    value={nameSearch}
                    onChange={(event) => {
                      setNameSearch(event.target.value);
                    }}
                    variant="standard"
                  />
                </div>
              </div>

              <div className="trip-list-filter-row">
                Mobile Number
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    className="trip-list-filter-inputfield"
                    variant="standard"
                    value={filterMobileNumber}
                    onChange={(e) => setFilterMobileNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="trip-list-filter-row">
                Email
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    className="trip-list-filter-inputfield"
                    value={filterPincode}
                    onChange={(e) => setFilterPincode(e.target.value)}
                    // select
                  ></TextField>
                </div>
              </div>
              <div className="trip-list-filter-row">
                Tranporter Code
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    className="trip-list-filter-inputfield"
                    value={filterTransporterCode}
                    onChange={(e) => setFilterTransporterCode(e.target.value)}
                    // select
                  ></TextField>
                </div>
              </div>
              <div className="trip-list-filter-button-row">
                <button
                  className="trip-list-filter-button"
                  onClick={handleClear}
                >
                  Clear
                </button>
                <button
                  className="trip-list-filter-button"
                  onClick={() =>
                    handleSearchFilter(
                      nameSearch,
                      transporterType,
                      filterMobileNumber,
                      filterPincode,
                      filterTransporterCode,
                      selectedStatus,
                      1
                    )
                  }
                >
                  {loadingFilterSearchButton ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Search"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={9999999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong backButtonLink="/dashboard" />
        </div>
      </ModalAntd>

      <div className="main_onboard_wrapper">
        {isMobile ? (
          <div className="trip-list-mobile-header">
            <MobileHeader headerTitle="Transporters" />
          </div>
        ) : (
          <div className="main_onboard_Left_box">
            <SideBarMain />
          </div>
        )}

        <div className="main_onboard_Right_box">
          {isMobile ? null : <Topbar />}
          <div className="progress_container_details">
            <div
              className="Left_details_wrapper"
              id="Left_details_wrapper_onboard"
            >
              <div
                className="search_container_transporter"
                id="search_container_transporter"
              >
                <div className="search_div" id="search_div_onboard">
                  <input
                    type="text"
                    name=""
                    id="search_input_onboard"
                    placeholder="search"
                    value={search}
                    ref={searchInput}
                    onKeyPress={(e) => handleKeyPress(e)}
                    onChange={(event) => {
                      // handleDebouncing(event);
                      setSearch(event.target.value);
                      setCurrentPage(1);
                    }}
                    // value={searchTerm}
                    className="search_input"
                  />
                  {search ? (
                    <CloseIcon
                      onClick={() => {
                        setSearch("");
                        handleTransporterSearch("clear");
                      }}
                      style={{ color: "grey", cursor: "pointer" }}
                    />
                  ) : null}
                  <SearchIcon
                    onClick={() => handleTransporterSearch()}
                    style={{ color: "grey", cursor: "pointer" }}
                  />
                </div>
                {isMobile ? null : (
                  <>
                    <div className="onboard_btnDiv">
                      <NavLink
                        to="/transporters/transporters-onboard"
                        style={{ textDecoration: "none" }}
                      >
                        {" "}
                        <ButtonRectangle
                          // class="button"
                          style={{
                            fontSize: typography.paragraph,
                            fontWeight: FontWeight.bold,
                          }}
                        >
                          {" "}
                          <span>Onboard Now!</span>
                          {/* <AddIcon /> */}
                        </ButtonRectangle>
                      </NavLink>
                    </div>
                  </>
                )}
              </div>
              {isMobile ? (
                <>
                  {isMobile ? (
                    <>
                      <div
                        style={{
                          padding: "3px",
                          paddingRight: "20px",
                        }}
                        className="onboard_btnDiv"
                      >
                        <NavLink
                          to="/transporters/transporters-onboard"
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          <ButtonRectangle
                            // class="button"
                            style={{
                              fontSize: typography.paragraph,
                              fontWeight: FontWeight.bold,
                            }}
                          >
                            {" "}
                            <span>Onboard Now!</span>
                            {/* <AddIcon /> */}
                          </ButtonRectangle>
                        </NavLink>
                      </div>
                    </>
                  ) : null}
                  <div
                    className="transporter_status_container"
                    style={{ width: "100%", overflow: "hidden" }}
                  >
                    <select
                      name="transporter-status"
                      id="transporter-status"
                      onChange={(e) => {
                        setTransporterType(e.target.value);
                        setCurrentPage(1);
                      }}
                      className="transporter_status_select"
                    >
                      <option value="">All</option>
                      {carrier.map((data) => (
                        <option value={data}>
                          {data
                            .replace("_", " ")
                            .match(/\b(\w+)\b/g)[0]
                            .charAt(0)
                            .toUpperCase() +
                            data
                              .replace("_", " ")
                              .match(/\b(\w+)\b/g)[0]
                              .slice(1)}
                        </option>
                      ))}
                    </select>
                    <div
                      className="transporter_status_tabs"
                      style={{ width: "100%", overflow: "scroll" }}
                    >
                      <div
                        className="transporter_singletab"
                        style={{
                          borderBottom:
                            selectedStatus == "approved"
                              ? "3px solid #016938"
                              : "3px solid #f9f9f9",
                          color:
                            selectedStatus == "approved"
                              ? "#016938"
                              : "#999999",
                        }}
                        onClick={() => setSelectedStatus("approved")}
                      >
                        Approved
                      </div>

                      <div
                        className="transporter_singletab"
                        style={{
                          borderBottom:
                            selectedStatus == "recommended"
                              ? "3px solid #016938"
                              : "3px solid #f9f9f9",
                          color:
                            selectedStatus == "recommended"
                              ? "#016938"
                              : "#999999",
                        }}
                        onClick={() => setSelectedStatus("recommended")}
                      >
                        Recommended
                      </div>

                      <div
                        className="transporter_singletab"
                        style={{
                          borderBottom:
                            selectedStatus == "submitted"
                              ? "3px solid #016938"
                              : "3px solid #f9f9f9",
                          color:
                            selectedStatus == "submitted"
                              ? "#016938"
                              : "#999999",
                        }}
                        onClick={() => setSelectedStatus("submitted")}
                      >
                        Submitted
                      </div>

                      <div
                        className="transporter_singletab"
                        style={{
                          borderBottom:
                            selectedStatus == "rejected"
                              ? "3px solid #016938"
                              : "3px solid #f9f9f9",
                          color:
                            selectedStatus == "rejected"
                              ? "#016938"
                              : "#999999",
                        }}
                        onClick={() => setSelectedStatus("rejected")}
                      >
                        Rejected
                      </div>

                      <div
                        className="transporter_singletab"
                        style={{
                          borderBottom:
                            selectedStatus == "blocked"
                              ? "3px solid #016938"
                              : "3px solid #f9f9f9",
                          color:
                            selectedStatus == "blocked" ? "#016938" : "#999999",
                        }}
                        onClick={() => setSelectedStatus("blocked")}
                      >
                        Blocked
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              {isMobile ? null : (
                <div className="transporter_status_container">
                  <select
                    name="transporter-status"
                    id="transporter-status"
                    onChange={(e) => {
                      setTransporterType(e.target.value);
                      setCurrentPage(1);
                    }}
                    className="transporter_status_select"
                  >
                    <option value="">All</option>
                    {carrier.map((data) => (
                      <option value={data}>
                        {data
                          .replace("_", " ")
                          .match(/\b(\w+)\b/g)[0]
                          .charAt(0)
                          .toUpperCase() +
                          data
                            .replace("_", " ")
                            .match(/\b(\w+)\b/g)[0]
                            .slice(1)}
                      </option>
                    ))}
                  </select>
                  <div className="transporter_status_tabs">
                    <div
                      className="transporter_singletab"
                      style={{
                        borderBottom:
                          selectedStatus == "approved"
                            ? "3px solid #016938"
                            : "3px solid #f9f9f9",
                        color:
                          selectedStatus == "approved" ? "#016938" : "#999999",
                      }}
                      onClick={() => setSelectedStatus("approved")}
                    >
                      Approved
                    </div>
                    <div
                      className="transporter_singletab"
                      style={{
                        borderBottom:
                          selectedStatus == "recommended"
                            ? "3px solid #016938"
                            : "3px solid #f9f9f9",
                        color:
                          selectedStatus == "recommended"
                            ? "#016938"
                            : "#999999",
                      }}
                      onClick={() => setSelectedStatus("recommended")}
                    >
                      Recommended
                    </div>

                    <div
                      className="transporter_singletab"
                      style={{
                        borderBottom:
                          selectedStatus == "submitted"
                            ? "3px solid #016938"
                            : "3px solid #f9f9f9",
                        color:
                          selectedStatus == "submitted" ? "#016938" : "#999999",
                      }}
                      onClick={() => setSelectedStatus("submitted")}
                    >
                      Submitted
                    </div>

                    <div
                      className="transporter_singletab"
                      style={{
                        borderBottom:
                          selectedStatus == "rejected"
                            ? "3px solid #016938"
                            : "3px solid #f9f9f9",
                        color:
                          selectedStatus == "rejected" ? "#016938" : "#999999",
                      }}
                      onClick={() => setSelectedStatus("rejected")}
                    >
                      Rejected
                    </div>

                    <div
                      className="transporter_singletab"
                      style={{
                        borderBottom:
                          selectedStatus == "blocked"
                            ? "3px solid #016938"
                            : "3px solid #f9f9f9",
                        color:
                          selectedStatus == "blocked" ? "#016938" : "#999999",
                      }}
                      onClick={() => setSelectedStatus("blocked")}
                    >
                      Blocked
                    </div>
                  </div>
                </div>
              )}

              <div className="card_container_transporter">
                {carrierListData.length !== 0 ? (
                  <>
                    {carrierListData.map((data, index) => (
                      <>
                        {" "}
                        <div
                          className="card_transporter"
                          key={index}
                          index={index}
                          onClick={() => handleSelectCard(data, index)}
                          style={{
                            border:
                              index === cardClicked
                                ? `2px solid ${colorPalette.primaryColor}`
                                : "none",
                            cursor: "pointer",
                          }}
                        >
                          {/* {isMobile ? null : (
                            <div className="image_div">
                              <div>
                                {data.transporter_type ==
                                "registered_transporter" ? (
                                  <img src={carrier2} alt="" width="100px" />
                                ) : (
                                  <img src={Carrier} alt="" width="100px" />
                                )}
                              </div>
                            </div>
                          )} */}

                          <div className="content_div_onboard_list">
                            <div className="name_transporter">
                              {carrierListData.length == index + 1 ? (
                                <h6
                                  className="name_text_onboard_transporter"
                                  ref={lastItem}
                                >
                                  <PersonIcon
                                    style={{
                                      marginRight: "5px",
                                      fontSize: "20px",
                                      color: darkMode
                                        ? colorPaletteDark.white
                                        : colorPalette.black,
                                    }}
                                  />
                                  {/* <img
                                    src={TransporterImg}
                                    alt=""
                                    height="20px"
                                    width="20px"
                                    style={{
                                      marginRight: "5px",
                                      color: darkMode
                                        ? colorPaletteDark.white
                                        : colorPalette.black,
                                    }}
                                  />{" "} */}
                                  <CopyText text={data.name} />
                                </h6>
                              ) : (
                                <h6 className="name_text_onboard_transporter">
                                  {" "}
                                  <PersonIcon
                                    style={{
                                      marginRight: "5px",
                                      fontSize: "20px",
                                      color: darkMode
                                        ? colorPaletteDark.white
                                        : colorPalette.black,
                                    }}
                                  />
                                  {/* <img
                                    src={TransporterImg}
                                    alt=""
                                    height="20px"
                                    width="20px"
                                    style={{
                                      marginRight: "5px",
                                      color: darkMode
                                        ? colorPaletteDark.white
                                        : colorPalette.black,
                                    }}
                                  />{" "} */}
                                  <CopyText text={data.name} />
                                </h6>
                              )}
                              <h6
                                className="name_text_onboard_transporter_code"
                                id="text_mobile_onboard"
                              >
                                {<CopyText text={data.transporter_code} />}
                              </h6>
                            </div>
                            {/* 
                              <h6 className="name_text"> {data.name}</h6>
                           */}
                            <div className="capacity">
                              {/* <h6
                                className="capacity_text"
                                id="text_mobile_onboard"
                              >
                                Transporter:
                              </h6> */}
                            </div>
                            <div>
                              <div className="driver">
                                <h6
                                  className="name_text_onboard_transporter_aadhar"
                                  id="text_mobile_onboard"
                                >
                                  <SmartphoneIcon
                                    style={{
                                      marginRight: "5px",
                                      fontSize: "20px",
                                      color: darkMode
                                        ? colorPaletteDark.white
                                        : colorPalette.paragraphSecondaryolor,
                                    }}
                                  />
                                </h6>
                                <h6
                                  className="name_text_onboard_transporter_aadhar"
                                  // id="text_mobile_onboard"
                                >
                                  {data.mobile ? (
                                    <CopyText text={data.mobile} />
                                  ) : (
                                    " - "
                                  )}
                                </h6>
                              </div>
                            </div>
                            <div>
                              <div className="driver">
                                <h6
                                  className="name_text_onboard_transporter_aadhar"
                                  id="text_mobile_onboard"
                                >
                                  Pan :
                                </h6>
                                <h6
                                  className="name_text_onboard_transporter_aadhar"
                                  // id="text_mobile_onboard"
                                >
                                  {data.pan_number ? (
                                    <CopyText text={data.pan_number} />
                                  ) : (
                                    " - "
                                  )}
                                </h6>
                              </div>
                            </div>
                            <div className="aadhar_vehicles_container">
                              <div className="driver">
                                <div
                                  className="name_text_onboard_transporter_aadhar"
                                  id="text_mobile_onboard"
                                >
                                  GSTIN :
                                </div>
                                <div
                                  className="name_text_onboard_transporter_aadhar"
                                  // id="text_mobile_onboard"
                                >
                                  {data?.gstin_number ? (
                                    <CopyText text={data.gstin_number} />
                                  ) : (
                                    " - "
                                  )}
                                </div>
                              </div>
                              {/* <div>
                                <NavLink
                                  style={{
                                    textDecoration: "none",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  to={`/transporters/details/${data.id}`}
                                >
                                  <ButtonRectangleSecondary
                                    style={{ fontWeight: FontWeight.medium }}
                                  >
                                    Vehicles
                                    <ArrowForwardIosIcon
                                      style={{
                                        fontSize: ".8rem",
                                        marginLeft: ".4rem",
                                      }}
                                    />
                                  </ButtonRectangleSecondary>
                                </NavLink>
                              </div> */}
                            </div>
                            <div>
                              <div className="driver">
                                <h6
                                  className="name_text_onboard_transporter_aadhar"
                                  id="text_mobile_onboard"
                                  style={{ marginRight: "2px" }}
                                >
                                  Onboarded on :
                                </h6>
                                <h6
                                  className="name_text_onboard_transporter_aadhar"
                                  // id="text_mobile_onboard"
                                >
                                  {data.pan_number
                                    ? moment(data?.created_on).format(
                                        "DD/MM/YYYY"
                                      )
                                    : " - "}
                                </h6>
                              </div>
                            </div>

                            {/* <div>
                              <div className="driver">
                                <h6
                                  className="name_text_onboard_transporter"
                                  id="text_mobile_onboard"
                                >
                                  Transporter Code:
                                </h6>
                                <h6
                                  className="name_text_onboard_transporter"
                                  id="text_mobile_onboard"
                                >
                                  {<CopyText text={data.transporter_code} />}
                                </h6>
                              </div>
                            </div> */}
                          </div>
                          {/* <div className="button_div">
                            <div style={{ width: "100%", color: "black" }}>
                              <NavLink to="/transporters/transporters-onboard">
                                <h6
                                  className="edit_btn"
                                  onClick={() => handleCarrierId(data.id)}
                                >
                                  <EditIcon style={{ color: "black" }} />
                                </h6>
                              </NavLink>
                            </div>

                            {isMobile ? (
                              <div>
                                <NavLink
                                  style={{ textDecoration: "none" }}
                                  to={`/transporters/details/${data.id}`}
                                >
                                  <h6 className="btn_mobile">
                                    Vehicles
                                    <ArrowForwardIosIcon
                                      style={{
                                        fontSize: ".5rem",
                                      }}
                                    />
                                  </h6>
                                </NavLink>
                              </div>
                            ) : (
                              <div>
                                <NavLink
                                  style={{ textDecoration: "none" }}
                                  to={`/transporters/details/${data.id}`}
                                >
                                  <h6 className="btn">
                                    Vehicles
                                    <ArrowForwardIosIcon
                                      style={{
                                        fontSize: ".8rem",
                                        marginLeft: ".4rem",
                                      }}
                                    />
                                  </h6>
                                </NavLink>
                              </div>
                            )}
                          </div> */}
                        </div>
                      </>
                    ))}
                    {loadingMore ? (
                      <div
                        style={
                          isMobile
                            ? {
                                position: "fixed",
                                bottom: "0",
                                right: "0",
                                width: "100vw",
                                height: "80vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#ffffff9f",
                              }
                            : {
                                position: "fixed",
                                bottom: "0",
                                right: "0",
                                width: "85vw",
                                height: "80vh",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#ffffff9f",
                              }
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#fff",
                          }}
                        >
                          <img
                            src={success}
                            alt=""
                            width="120px"
                            height="90px"
                            // style={{ marginTop: "100px" }}
                          />
                          <h6 style={{ color: "grey" }}>loading data...</h6>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : isUserNotAuthorized ? (
                  <NotAuthorized />
                ) : loadingMore ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      zIndex: "9999",
                      width: "80vw",
                      height: "70vh",
                      position: "fixed",
                      bottom: "0",
                      right: "0",
                      // backgroundColor: "grey",
                    }}
                  >
                    <img
                      src={success}
                      alt=""
                      width="120px"
                      height="90px"
                      style={{ marginTop: "100px" }}
                    />
                    <h6 style={{ color: "grey" }}>loading data...</h6>
                  </div>
                ) : carrierListData.length == 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "80vw",
                      height: "70vh",
                      // marginLeft: "6rem",
                    }}
                  >
                    <h4
                      style={{
                        textAlign: "center",
                        width: "100%",
                        // marginLeft: "10%",
                        fontSize: "1rem",
                        color: darkMode
                          ? colorPaletteDark.white
                          : colorPalette.black,
                      }}
                    >
                      No Transporter Found!
                    </h4>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      // backgroundColor: "grey",
                    }}
                  >
                    {" "}
                    <h6 style={{ color: "grey" }}>No data found!</h6>
                  </div>
                )}
              </div>
            </div>
            <CommonDrawer
              commonDrawerOpen={commonDrawerOpen}
              handleCloseCommonDrawer={handleCloseCommonDrawer}
              carrierDetails={selectedCarrier}
              cardClicked={cardClicked}
              clickTab={clickTab}
              vehicleDrawer={false}
              handleSearchFilter={handleSearchFilter}
              nameSearch={nameSearch}
              transporterType={transporterType}
              filterMobileNumber={filterMobileNumber}
              filterPincode={filterPincode}
              selectedStatus={selectedStatus}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </OnboardListStyle>
  );
}

export default OnboardList;
