export const allRegex = {
  vehicleNumberRegex:
    /^[A-Za-z]{2}[ ]?[0-9]{1,2}[ ]?[A-Za-z]{0,3}[ ]?[0-9]{4}$/,
  alphanumaricRegex: /^[a-zA-Z0-9][a-zA-Z0-9_-]*$/,
  postiveNumberRegex: /^[0-9]+$/,
  alphanumaricWithCommaRegex: /^[a-zA-Z0-9-_,/-]+$/,
  ewayBillRegex: /^[0-9]{4}[ ]?[0-9]{4}[ ]?[0-9]{4}$/,
  rcNumberRegex: /^[A-Za-z]{2} ?\d{1,2} ?[A-Za-z\d]{0,3} ?\d{4}$/,
  panRegex: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
  ifscRegex: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  mobileNumberRagex: /^[6-9]{1}[0-9]{9}/,
  dlNumberRegex: /^[A-Z]{2}[0-9]{13}$/,
  emailRegex: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  allowedEmailInLeapSupply: /^[a-zA-Z0-9._%+-]+@bscxpress\.com$/,
  nameRegex: /^[a-zA-Z]+$/,
};

export const validateIFSC = (ifsc, bankCodes) => {
  var pattern = new RegExp("^(" + bankCodes.join("|") + ")[A-Z]{4}\\d{6}$");
  return pattern.test(ifsc);
};
