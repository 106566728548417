import {
  Alert,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import CustomerSidebar from "../../../components/logisticsCustomerComponents/CustomerSidebar/CustomerSidebar";
import {
  CreateIndentAddressStyle,
  CreateIndentStyle,
} from "./createIndentStyle";
import UploadImage from "../../../assets/upload.svg";
import { useDispatch } from "react-redux";
import { Box, Drawer } from "@mui/material";
import {
  Close,
  AddCircle,
  Add,
  Cancel,
  Search,
  ArrowBack,
  ArrowBackIos,
} from "@mui/icons-material";
import {
  changeIndentPut,
  createIndentPost,
  fetchAddressFromPincodeApi,
  fetchIndentAttchmentsId,
  fetchIndentById,
  indentAttachmentsPost,
  indentAttachmentsPut,
} from "./createIndentServices";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  readCustomerData,
  readLoginData,
} from "../../../helper/dataDecryptionHelper";
import { markIndentPatch } from "../customerIndentsPage/customerIndentServices";
import { updateOnFlag, updateOnFlagUpdate } from "../../../redux/indentSlice";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import { GetCustomerSearchList } from "../../ftl_trips/createTrip/createTripServices";
import { ButtonPrimary } from "../../../themes/components/button";
// import AsyncSelect from "react-select/dist/declarations/src/Async";
// import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveBlob } from "../../../utility/saveBlobImage.js";
import Pincode from "../../../components/countryBasePincode/Pincode.jsx";

function CreateTripIndentPage() {
  const dispatch = useDispatch();
  // const customerInfo = readCustomerData();

  const loginCustomer = readLoginData();
  const indentData = useSelector((state) => state.indent.indentId);

  const history = useHistory();
  const [indentAttachment, setIndentAttachment] = useState({});

  const [currentTab, setCurrentTab] = useState(null);
  const [sourceError, setSourceError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [indentImageId, setIndentImageId] = useState("");
  const [successIndent, setSuccessIndent] = useState("");
  const [numberOfVehicles, setNumberOfVehicles] = useState("");
  const [flagOn, setFlagOn] = useState(false);

  const [indentPickupDate, setIndentPickupDate] = useState("");
  const [consignorId, setConsignorId] = useState(0);
  const [selectedConsignorName, setSelectedConsignorName] = useState("");

  const [indentVehicleType, setIndentVehicleType] = useState("");
  const [indentQuantity, setIndentQuantity] = useState("");
  const [remarks, setRemarks] = useState("");
  const [indentValue, setIndentValue] = useState("");
  const [indentMaterialType, setIndentMaterialType] = useState("");
  const [villageList, setVillageList] = useState([]);
  console.log("the arara", villageList)
  const [vehicleTypeList, setVehicleTypeList] = useState([
    "1.5_ton_pickup",
    "4_ton_mini_truck",
    "5_ton_lpt",
    "7_ton_lpt",
    "10_ton_lpt",
    "32_feet_open_trailer",
    "40_feet_open_trailer",
    "18_ton_taurus_truck",
    "21_ton_taurus_truck",
    "25_ton_taurus_truck",
    "30_ton_taurus_truck",
    "20_feet_sxl_container_truck",
    "20_feet_mxl_container_truck",
    "32_feet_sxl_container_truck",
    "32_feet_mxl_container_truck",
  ]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const [openSnackbarSuccessUpdate, setOpenSnackbarSuccessUpdate] =
    useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [indentSource, setIndentSource] = useState([
    {
      postcode: "",
      type: "source",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    },
  ]);

  // console.log("the attachmentsssss", indentAttachment?.type.split("/").shift());

  const [indentDestination, setIndentDestination] = useState([
    {
      postcode: "",
      type: "destination",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    },
  ]);
  const [currentDestinationId, setCurrentDestinationId] = useState(null);
  const [currentSourceId, setCurrentSourceId] = useState(null);

  const [state, setState] = React.useState({
    right: false,
  });

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "left",
  });

  const { vertical, horizontal } = snackbarPosition;
  const [errorindentVillage, setErrorindentVillage] = useState("");
  const [errorPin, setErrorPin] = useState("");
  // const [errorindentVillage, setErrorindentVillage] = useState("");
  const handleCloseDriverDrawer = () => {
    // toggleDrawer("right", false);
    if (indentSource[0].village !== "0" && indentSource[0].postcode !== "") {
      toggleDrawer("right", false);
    } else {
      if (indentSource[0].postcode == "") {
        setErrorPin("Please enter pincode.");
      }
      if (indentSource[0].village == "0") {
        setErrorindentVillage("Please select village");
      }
    }
  };
  const handleCloseDriverDrawerDesination = () => {
    if (indentDestination[0].village !== "0") {
      toggleDrawer("right", false);
    } else {
      if (indentDestination[0].postcode == "") {
        setErrorPin("Please enter pincode.");
      }
      if (indentDestination[0].village == "0") {
        setErrorindentVillage("Please select village");
      }
    }
  };

  const handleIndentAttachment = async (event) => {
    setErrorAttachments("");
    const newFile = await saveBlob(event.target.files[0]);
    setIndentAttachment(newFile);
  };

  const handleOnChangeDestination = (e, index) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    const destination = [...indentDestination];
    //
    destination[index][name] = value;
    //
    setIndentDestination(destination);
    // setErrDestinationFinal("");
  };
  const handlePincodeSource = (data, errorMsg, index) => {
    if (data) {
      setErrorSource([]);
      setErrorPin("");
      const source = [...indentSource];
      source[index]["postcode"] = data;
      setIndentSource(source);
      // console.log("indentSource============>", data, errorMsg, index)
    }
    if (errorMsg) {
      setErrorPin(errorMsg)
    } else {
      handleGetAddress(data, index);
    }
  }
  const handlePincodeDestination = (data, errorMsg, index) => {
    if (data) {
      setErrorDestination([]);
      setErrorPin("");
      setSourceError("");
      const destination = [...indentDestination];
      destination[index]["postcode"] = data;
      setIndentDestination(destination);
      console.log("destination============>", destination)
    }
    if (errorMsg) {
      setErrorPin(errorMsg)
    } else {
      handleGetAddress(data, index);
    }
  }


  const handleGetAddress = async (pincode) => {
    const response = await fetchAddressFromPincodeApi(pincode);
    //
    console.log("the ressss", response)
    if (response) {
      if (currentTab === "source") {
        if (response.status == 500) {
          // setSourceError("Pincode not serviceable.");
          setErrorPin("Pincode not serviceable.")
        }

        handleSetAddress(
          currentTab,
          currentSourceId - 1,
          response?.data,
          indentSource
        );
      } else if (currentTab === "destination") {
        if (response.status == 500) {
          // setSourceError("Pincode not serviceable.");
          setErrorPin("Pincode not serviceable.")
        }
        handleSetAddress(
          currentTab,
          currentDestinationId - 1,
          response?.data,
          indentDestination
        );
      }
    }
  };

  const handleGoBack = () => {
    history.push("/trips-indents");
    setErrorSource([{}]);
    setErrorPickupdate("");
    setErrorVehicleType("");
    setErrorqty("");
    setErrorValueOfGoods("");
    setErrorMaterialType("");
    setErrorAttachments("");
    setErrorDestination([{}]);
  };

  const handleSetAddress = (type, index, addressData, existingData) => {
    const addressTemp = [...existingData];

    console.log("type============>", type,)
    console.log("index============>", index)
    console.log("addressData============>", addressData)
    console.log("existingData============>", existingData)

    addressTemp[index]["district"] = addressData.district;
    addressTemp[index]["sub_district"] = addressData.district;
    addressTemp[index]["state"] = addressData.state;
    console.log("addressTemp============>", addressTemp)
    // addressTemp[index]["state"] = addressData.state;setVillageList
    if (addressData.detail) {
      setErrorPin("pincode is not servicable.")
    }
    if (addressData.area) {
      setVillageList(addressData?.area);
    }

    if (type === "source") {
      setIndentSource(addressTemp);
    } else if (type === "destination") {
      setIndentDestination(addressTemp);
    }
  };

  const handleOnChangeSource = (e, index) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    const source = [...indentSource];
    source[index][name] = value;
    setIndentSource(source);
  };

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
    setErrorindentVillage("");
    setSourceError("");
    setErrorPin("")
    // if (currentTab == "driver" && open == false) {
    //   resetFormDriver();
    // }
  };

  const handleAddSource = () => {
    let sourceTemp = [...indentSource];
    sourceTemp.push({
      postcode: "",
      type: "source",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    });
    setIndentSource(sourceTemp);
  };

  const handleAddDestination = () => {
    let destinationTemp = [...indentDestination];
    destinationTemp.push({
      postcode: "",
      type: "destination",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    });
    setIndentDestination(destinationTemp);
  };

  const handleCreateIndent = async (
    indentSource,
    indentDestination,
    indentPickupDate,
    indentVehicleType,
    indentQuantity,
    indentValue,
    indentMaterialType,
    indentAttachment,
    attachmentName,
    attachmentType,
    // indentAttachment,
    consignorId,
    // createdby,
    // modifiedby,
    indentstatus,
    numberOfVehicles,
    remarks
  ) => {
    if (indentstatus == "draft") {
      setLoading(true);
    } else {
      setLoadingSend(true);
    }

    //
    const response = await createIndentPost(
      indentSource,
      indentDestination,
      indentPickupDate,
      indentVehicleType,
      indentQuantity,
      indentValue,
      indentMaterialType,
      indentAttachment,
      attachmentName,
      attachmentType,
      consignorId,
      indentstatus,
      numberOfVehicles,
      remarks
    );

    //
    if (response.status == 200) {
      if (indentstatus == "draft") {
        setLoading(false);
      } else {
        setLoadingSend(false);
      }
      // setSuccessIndent("Indent created successfully.");
      setOpenSnackbarSuccess(true);
      dispatch(updateOnFlag(true));
      // setFlagOn(true);
      history.push("/trips-indents");
    } else if (response.status === 401) {
      toast.error("401: Not enough permissions");
      if (indentstatus == "draft") {
        setLoading(false);
      } else {
        setLoadingSend(false);
      }
    } else {
      if (indentstatus == "draft") {
        setLoading(false);
      } else {
        setLoadingSend(false);
      }

      if (response.data.detail) {
        setErrMessage(`${response.data.detail}`);
      }
      if (response.data.error) {
        setErrMessage(`${response.status} ${response.data.error}`);
      }
      setOpenSnackbar(true);
    }
  };

  const handleSubmitIndent = async (indentStatus) => {
    if (indentData.editMode == true) {
      setLoading(true);
      const response = await changeIndentPut(
        // JSON.stringify(indentSource),
        // JSON.stringify(indentDestination),
        indentPickupDate,
        indentVehicleType,
        indentQuantity,
        indentValue,
        indentMaterialType,
        consignorId,
        "draft",
        numberOfVehicles,
        remarks,
        indentData.id
      );
      if (response.status === 401) {
        toast.error("Unauthorized user");
        setLoading(false);
      }
      if (response.status == 200) {
        setLoading(false);
        // if (indentAttachment.name !== "") {
        //   const respnseAttachments = await indentAttachmentsPost(
        //     "image",
        //     indentAttachment ? indentAttachment : "",
        //     indentAttachment ? indentAttachment.name : "",
        //     indentData.id
        //   );
        //
        //   if (respnseAttachments.status == 200) {
        //     setLoading(false);
        //     history.push("/customer/customer-indents");
        //   } else if (respnseAttachments.status === 400) {
        //     const response = await indentAttachmentsPut(
        //       "image",
        //       indentAttachment ? indentAttachment : "",
        //       indentAttachment ? indentAttachment.name : "",
        //       indentData.id,
        //       indentImageId
        //     );
        //     if (response.status == 200) {
        //       setLoading(false);
        //       history.push("/customer/customer-indents");
        //     }

        //   }
        // }
        setOpenSnackbarSuccessUpdate(true);
        dispatch(updateOnFlagUpdate(true));
        history.push("/trips-indents");
      }
    } else {
      const isValid = formValidation();

      if (isValid) {
        const response = await handleCreateIndent(
          JSON.stringify(indentSource),
          JSON.stringify(indentDestination),
          indentPickupDate,
          indentVehicleType,
          indentQuantity,
          indentValue,
          indentMaterialType,
          indentAttachment ? indentAttachment : "",
          indentAttachment ? indentAttachment.name : "",
          indentAttachment.type == "image/png" ||
            indentAttachment.type == "image/jpeg"
            ? "image"
            : indentAttachment.type == "application/pdf"
              ? "pdf"
              : "",
          consignorId,
          indentStatus,
          numberOfVehicles,
          remarks

          // JSON.stringify(loginCustomer.customer_id)
        );

        if (response.status == 201) {
          setLoading(false);
          // if (indentAttachment.name !== "") {
          //   const respnseAttachments = await indentAttachmentsPost(
          //     "image",
          //     indentAttachment ? indentAttachment : "",
          //     indentAttachment ? indentAttachment.name : "",
          //     indentData.id
          //   );
          //
          //   if (respnseAttachments.status == 200) {
          //     setLoading(false);
          //     history.push("/customer/customer-indents");
          //   } else if (respnseAttachments.status === 400) {
          //     const response = await indentAttachmentsPut(
          //       "image",
          //       indentAttachment ? indentAttachment : "",
          //       indentAttachment ? indentAttachment.name : "",
          //       indentData.id,
          //       indentImageId
          //     );
          //     if (response.status == 200) {
          //       setLoading(false);
          //       history.push("/customer/customer-indents");
          //     }

          //   }
          // }
          setOpenSnackbarSuccessUpdate(true);
          dispatch(updateOnFlagUpdate(true));
          history.push("/trips-indents");
        }
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleCloseSnackbarSuccess = () => {
    setOpenSnackbarSuccess(false);
  };
  const editIndent = async (id) => {
    const response = await fetchIndentById(id);

    if (response) {
      setIndentSource(response.source);
      setIndentSource(response.source);
      setIndentDestination(response.destination);
      setIndentPickupDate(response.pickup_date);
      setIndentVehicleType(response.vehicle_type);
      setIndentQuantity(response.quantity_of_goods);
      setIndentValue(response.value_of_goods);
      setIndentMaterialType(response.material_type);
      setConsignorId(response.customer_id);
      setSelectedConsignorName(response.customer_name);
      setNumberOfVehicles(response.number_of_vehicles);
      setRemarks(response.remark);
    }
  };
  useEffect(() => {
    if (indentData.id) {
      editIndent(indentData.id);
    }
  }, []);
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  // ==============================\

  //   const [indentMaterialType, setIndentMaterialType] = useState("");
  //   const [villageList, setVillageList] = useState([]);
  //   const [vehicleTypeList, setVehicleTypeList] = useState([
  //     "cargo_auto",
  //     "truck",
  //     "pickup",
  //   ]);
  // ==============================
  const [errorSource, setErrorSource] = useState("");
  const [errorDestination, setErrorDestination] = useState("");
  const [errorPickupdate, setErrorPickupdate] = useState("");
  const [errorCustomerId, setErrorCustomerId] = useState("");
  const [errorNumberVehicles, setErrorNumberVehicles] = useState("");
  const [errorVehicleType, setErrorVehicleType] = useState("");

  const [errorqty, setErrorqty] = useState("");
  const [errorValueOfGoods, setErrorValueOfGoods] = useState("");
  const [errorMaterialType, setErrorMaterialType] = useState("");
  const [errorAttachments, setErrorAttachments] = useState("");
  const [errorRemarks, setErrorRemarks] = useState("");
  const formValidation = () => {
    const errorSource = {};

    const errorDestination = {};
    const errorPickupdate = {};
    const errorCustomerId = {};
    const errorNumberVehicles = {};
    const errorVehicleType = {};
    const errorqty = {};
    const errorValueOfGoods = {};
    const errorMaterialType = {};
    const errorindentVillage = {};
    const errorAttachments = {};
    const errorRemarks = {};
    let isValid = true;
    if (indentAttachment.name == null) {
      errorAttachments.errorImage = "Please upload document.";
      isValid = false;
    }
    if (Object.keys(indentSource[0].postcode).length == 0) {
      errorSource.errorSources = "Please enter source.";
      isValid = false;
    }
    if (Object.keys(indentDestination[0].postcode).length == 0) {
      errorDestination.errorDest = "Please enter Destination.";
      isValid = false;
    }
    if (indentPickupDate == "") {
      errorPickupdate.errorPick = "Please enter pickup date.";
      isValid = false;
    }
    if (remarks == "") {
      errorRemarks.errorreamrkss = "Please enter Remark.";
      isValid = false;
    }
    if (indentVehicleType == "") {
      errorVehicleType.errorVehicles = "Please enter vehicle type.";
      isValid = false;
    }
    if (indentValue == "") {
      errorValueOfGoods.errorValue = "Please enter invoice value.";
      isValid = false;
    }
    if (indentQuantity == "") {
      errorqty.errorQuantity = "Please enter valid quantity.";
      isValid = false;
    }
    if (indentMaterialType == "") {
      errorMaterialType.errorMaterial = "Please enter material type.";
      isValid = false;
    }
    if (consignorId == "") {
      errorCustomerId.errorcustomerid = "Please select customer.";
      isValid = false;
    }
    if (numberOfVehicles == "") {
      errorNumberVehicles.errornumberofvehicles = "Please enter no.of vehicles";
      isValid = false;
    }

    // if (indentSource.village == "0") {
    //   errorindentVillage.errorvillage = "Please select village.";
    //   isValid = false;
    // }
    setErrorSource(errorSource);
    setErrorPickupdate(errorPickupdate);
    setErrorCustomerId(errorCustomerId);
    setErrorNumberVehicles(errorNumberVehicles);
    setErrorVehicleType(errorVehicleType);
    setErrorqty(errorqty);
    setErrorValueOfGoods(errorValueOfGoods);
    setErrorMaterialType(errorMaterialType);
    // setErrorindentVillage(errorindentVillage);
    setErrorAttachments(errorAttachments);
    setErrorDestination(errorDestination);
    setErrorRemarks(errorRemarks);
    // const [errorDestination, setErrorDestination] = useState("");

    return isValid;
  };

  const handleMarkIndentAsSent = async (id, markSentRemark) => {
    // setLoadingForMarkAsSent(true);
    const response = await markIndentPatch(id, markSentRemark);
    if (response.status >= 200 && response.status < 300) {
      // getIndentsList(currentTab, currentPage);
    } else {
    }
  };

  // const indentImages = async (id) => {
  //   const response = await fetchIndentAttchmentsId(id);
  //   if (response.status == 200) {
  //     setIndentImageId(response.data.indent_attachments_list[0].id);
  //   }
  // };
  // useEffect(() => {
  //   if (indentData.id) {
  //     indentImages(indentData.id);
  //   }
  // }, []);
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    //
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleFetchConsignor = async (values) => {
    const productResponse = await GetCustomerSearchList(values);

    return productResponse.customers_list
      .filter((item) => item.name !== "faarms")
      .filter((item) => item.name !== "FAARMS")
      .filter((item) => item.name !== "Faarms")
      .map((item) => {
        return {
          value: item.id,
          customer_code: item.customer_code,
          name: item.name,

          label: (
            <div
              style={
                {
                  // display: "flex",
                  // flexDirection: "column",
                  // overflowY: "scroll",
                  // height: "100px",
                }
              }
            // className="create-trip-vehicle-single"
            >
              <div
                style={
                  {
                    // display: "flex",
                    // flexDirection: "column",
                    // width: "100%",
                    // zIndex: "1000",
                  }
                }
              >
                <div>
                  <div>
                    <span className="create-trip-transporter-number-text">
                      {item.customer_code}
                    </span>
                  </div>
                </div>

                <div style={{ zIndex: "1000" }}>
                  <span
                    className="create-trip-sub-text"
                    style={{ zIndex: "1000" }}
                  >
                    {/* <PersonOutlineOutlinedIcon /> */}
                    {item.name}
                  </span>
                </div>
              </div>
            </div>
          ),
        };
      });

    // return productResponse.data.transporter_list.map((datas) => {
    //   return {
    //     value: datas.id,
    //     label: datas.name,
    //   };
    // });
  };
  const handleCloseDrawer = () => {
    toggleDrawer("right", false);
    setErrorindentVillage("");
    setErrorPin("");
  };
  return (
    <CreateIndentStyle>
      <ToastContainer position="bottom-right" />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={4000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        key={vertical + horizontal}
      >
        <Alert severity="error">{errMessage}</Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbarSuccess}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseSnackbarSuccess}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseSnackbarSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Indent created successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbarSuccess}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseSnackbarSuccess}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseSnackbarSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Indent Updated successfully!
        </Alert>
      </Snackbar>
      <div className="create-indent-main-container">
        {isMobile ? null : (
          <div className="trip-indent-sidebar">
            <div className="main_onboard_Left_box">
              <SideBarMain />
            </div>
          </div>
        )}

        <div className="create-indent-container">
          <div className="create-indent-top">
            <div className="create-indent-text">
              <ArrowBackIos
                style={{ cursor: "pointer" }}
                onClick={handleGoBack}
              />{" "}
              {indentData.editMode == true ? "Edit Indent" : "Create Indent"}
            </div>
          </div>
          <div className="create-indent-form">
            <div className="create-indent-row-grid">
              {indentSource.map((item, index) => {
                return (
                  <div className="create-indent-input-contain">
                    <h4 className="create-indent-label">
                      Source {index + 1}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-indent-input"
                      variant="outlined"
                      placeholder="Enter source address"
                      onClick={() => {
                        setCurrentTab("source");
                        setCurrentSourceId(index + 1);
                        toggleDrawer("right", true);
                      }}
                      disabled={indentData.id ? true : false}
                      value={
                        item.state &&
                          item.village &&
                          item.sub_district &&
                          item.district
                          ? `${item.village}, ${item.sub_district}, ${item.district}, ${item.state}`
                          : ""
                      }
                    />
                    {Object.keys(errorSource).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>{errorSource[key]}</h6>
                      );
                    })}
                    {indentSource.length === index + 1 ? (
                      indentData.id ? null : (
                        <div>
                          <span
                            className="indent-add-source"
                            onClick={handleAddSource}
                          >
                            <Add style={{ fontSize: "20px" }} /> Source
                          </span>
                        </div>
                      )
                    ) : null}
                  </div>
                );
              })}

              {indentDestination.map((item, index) => {
                return (
                  <div className="create-indent-input-contain">
                    <h4 className="create-indent-label">
                      Destination {index + 1}{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-indent-input"
                      variant="outlined"
                      placeholder="Enter destination address"
                      onClick={() => {
                        setCurrentTab("destination");
                        setCurrentDestinationId(index + 1);
                        toggleDrawer("right", true);
                      }}
                      disabled={indentData.id ? true : false}
                      value={
                        item.state &&
                          item.village &&
                          item.sub_district &&
                          item.district
                          ? `${item.village}, ${item.sub_district}, ${item.district}, ${item.state}`
                          : ""
                      }
                    />
                    {Object.keys(errorDestination).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorDestination[key]}
                        </h6>
                      );
                    })}
                    {indentDestination.length === index + 1 ? (
                      indentData.id ? null : (
                        <div>
                          <span
                            className="indent-add-source"
                            onClick={handleAddDestination}
                          >
                            <Add style={{ fontSize: "20px" }} /> Destination
                          </span>
                        </div>
                      )
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div className="create-indent-row">
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Select Customer <sup style={{ color: "red" }}>*</sup>
                </h4>
                <AsyncSelect
                  className="create-indent-input"
                  defaultOptions
                  key={consignorId}
                  placeholder="Search Customer"
                  value={
                    consignorId
                      ? {
                        value: consignorId,
                        label: selectedConsignorName,
                      }
                      : ""
                  }
                  onChange={(e) => {
                    setConsignorId(e.value);
                    setSelectedConsignorName(e.name);
                    // setErrorConsignorId({});
                    // setErrorConsignorId({});
                    setErrorCustomerId("");
                    // setErrorEmptyConsignor("");
                  }}
                  loadOptions={(inputValue) => handleFetchConsignor(inputValue)}
                />
                {Object.keys(errorCustomerId).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>{errorCustomerId[key]}</h6>
                  );
                })}
                {/* {Object.keys(errorConsignorId).map((key) => {
            return (
              <h6 style={{ color: "#E06666" }}>{errorConsignorId[key]}</h6>
            );
          })} */}
              </div>
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Number of vehicles<sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  type="number"
                  className="create-indent-input"
                  variant="outlined"
                  placeholder="Enter number of vehicles"
                  value={numberOfVehicles}
                  onChange={(e) => {
                    setNumberOfVehicles(e.target.value);
                    setErrorNumberVehicles("");
                  }}
                  onInput={(e) => {
                    e.target.value = parseInt(
                      e.target.value === "0" ? "1" : e.target.value
                    );
                  }}
                />
                {Object.keys(errorNumberVehicles).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>
                      {errorNumberVehicles[key]}
                    </h6>
                  );
                })}
              </div>
            </div>
            <div className="create-indent-row">
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Pickup date<sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-indent-input"
                  variant="outlined"
                  placeholder="Enter pickup dtae"
                  // type="date"
                  type="date"
                  inputProps={{
                    min: disablePastDate(),
                    max: "2100-01-01",
                  }}
                  value={indentPickupDate}
                  onChange={(e) => {
                    setIndentPickupDate(e.target.value);
                    setErrorPickupdate({});
                  }}
                />
                {Object.keys(errorPickupdate).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>{errorPickupdate[key]}</h6>
                  );
                })}
              </div>
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Vehicle type<sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  className="create-indent-input"
                  id="outlined-select-currency"
                  select
                  size="small"
                  name="village"
                  //   value={item.village}
                  //   onChange={(e) =>
                  //     handleOnChangeDestination(e, currentDestinationId - 1)
                  //   }
                  // label="Select"
                  value={indentVehicleType}
                  onChange={(e) => {
                    setIndentVehicleType(e.target.value);

                    setErrorVehicleType("");
                  }}
                >
                  <MenuItem value="0" disabled>
                    Select Vehicle type
                  </MenuItem>
                  {vehicleTypeList.map((village, index) => {
                    return (
                      <MenuItem key={index} value={village}>
                        {village.split("_").join(" ").toUpperCase()}
                      </MenuItem>
                    );
                  })}
                </TextField>
                {Object.keys(errorVehicleType).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>
                      {errorVehicleType[key]}
                    </h6>
                  );
                })}
              </div>
            </div>
            <div className="create-indent-row">
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Quantity of goods <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-indent-input"
                  variant="outlined"
                  placeholder="Enter qty of goods"
                  type="number"
                  value={indentQuantity}
                  onChange={(e) => {
                    setIndentQuantity(e.target.value);
                    setErrorqty("");
                  }}
                  onInput={(e) => {
                    e.target.value = parseInt(
                      e.target.value === "0" ? "1" : e.target.value
                    );
                  }}
                />
                {Object.keys(errorqty).map((key) => {
                  return <h6 style={{ color: "#E06666" }}>{errorqty[key]}</h6>;
                })}
              </div>
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Invoice value<sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-indent-input"
                  variant="outlined"
                  placeholder="Enter invoice value"
                  type="number"
                  value={indentValue}
                  onChange={(e) => {
                    setIndentValue(e.target.value);
                    setErrorValueOfGoods("");
                  }}
                // onInput={(e) => {
                //   e.target.value = parseInt(
                //     e.target.value === "0" ? "1" : e.target.value
                //   );
                // }}
                />
                {Object.keys(errorValueOfGoods).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>
                      {errorValueOfGoods[key]}
                    </h6>
                  );
                })}
              </div>
            </div>
            <div className="create-indent-row">
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Type of material <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-indent-input"
                  // style={{ width: "85%" }}
                  variant="outlined"
                  placeholder="Enter type of material"
                  value={indentMaterialType}
                  onChange={(e) => {
                    setIndentMaterialType(e.target.value);
                    setErrorMaterialType("");
                  }}
                />
                {Object.keys(errorMaterialType).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>
                      {errorMaterialType[key]}
                    </h6>
                  );
                })}
              </div>
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Upload attachment <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  id="dropdown"
                  autoComplete="off"
                  className="create-indent-input"
                  // style={{ width: "80%" }}
                  variant="outlined"
                  placeholder="Enter document type"
                  name="attachment_tag"
                  disabled
                  // disabled={index == 0 ? true : false}
                  value={"Upload Indent attachment"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          color: "#016938",
                          fontWeight: "600",
                        }}
                      // onClick={handleAddAttachment}
                      >
                        <button className="upload_button">
                          <input
                            // name="upload"
                            name="attachment_file"
                            id={`uploaddl`}
                            type="file"
                            disabled={indentData.editMode ? true : false}
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={handleIndentAttachment}
                            hidden
                            style={{
                              backgroundColor: "#e8f9e0",
                            }}
                          />
                          <label
                            htmlFor={`uploaddl`}
                            style={{
                              backgroundColor: "#e8f9e0",
                            }}
                          >
                            <img
                              src={UploadImage}
                              alt=""
                              width="20px"
                              height="20px"
                              style={{
                                backgroundColor: "#e8f9e0",
                              }}
                            />
                          </label>
                        </button>
                      </InputAdornment>
                    ),
                  }}
                />
                <div
                  style={{
                    fontSize: "12px",
                    width: "260px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {indentAttachment ? indentAttachment?.name : null}
                </div>
                {indentAttachment ? (
                  indentAttachment.size >
                    process.env.REACT_APP_MAX_FILE_SIZE ? (
                    <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                      Please upload file size less than {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
                    </h6>
                  ) : null
                ) : null}
                {Object.keys(errorAttachments).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>
                      {errorAttachments[key]}
                    </h6>
                  );
                })}
              </div>
            </div>
            <div className="create-indent-row">
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Remark <sup style={{ color: "red" }}>*</sup>
                </h4>
                {/* <div className="indent-remarks-inputfield-conatiner"> */}
                <textarea
                  style={{ width: "90%", height: "5rem", padding: "5px" }}
                  // className="create-indent-input"
                  name="accept_remark"
                  placeholder="Add Remark"
                  value={remarks}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                    setErrorRemarks("");
                  }}
                ></textarea>
                {Object.keys(errorRemarks).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>{errorRemarks[key]}</h6>
                  );
                })}
                {/* </div> */}
              </div>
            </div>

            <div className="create-indent-row-btn">
              <div className="indent-btn-container">
                <ButtonPrimary
                  // className="create-indent-send-button"
                  onClick={() => handleSubmitIndent("draft")}
                  disabled={
                    indentAttachment.size >
                      process.env.REACT_APP_MAX_FILE_SIZE || loading == true
                      ? true
                      : false
                  }
                >
                  {loading ? (
                    <CircularProgress size={20} style={{ color: "green" }} />
                  ) : (
                    "Create Indent"
                  )}
                </ButtonPrimary>
              </div>
              {/* {indentData.editMode == true ? null : (
                <div className="indent-btn-container">
                  <button
                    className="create-indent-send-button"
                    onClick={() => handleSubmitIndent("sent")}
                    disabled={
                      indentAttachment.size >
                        process.env.REACT_APP_MAX_FILE_SIZE || loading == true
                        ? true
                        : false
                        ? true
                        : false
                    }
                  >
                    {loadingSend ? (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    ) : (
                      "Send Indent"
                    )}
                  </button>
                </div>
              )} */}
            </div>
          </div>
        </div>
        {currentTab == `source`
          ? indentSource
            .filter((items, indexs) => indexs == currentSourceId - 1)
            .map((item, index) => {
              return (
                <Drawer
                  anchor={"right"}
                  open={state["right"]}
                  onClose={() => {
                    toggleDrawer("right", false);
                    setErrorPin("");
                  }}
                >
                  <CreateIndentAddressStyle>
                    <div
                      className="create-trip-right-most-head"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Add Trip source - {currentSourceId}
                      <Close
                        color="action"
                        onClick={handleCloseDrawer}
                        style={{ marginRight: "10px" }}
                      />
                    </div>

                    {/* <div className="create-trip-from-row">
                        <div className="create-trip-input-container">
                          <h4 className="create-trip-input-head">
                            {" "}
                            Pincode <sup style={{ color: "red" }}>*</sup>
                          </h4>
                          <TextField
                            size="small"
                            autoComplete="off"
                            className="create-indent-input"
                            variant="outlined"
                            name="postcode"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            placeholder="Enter Pincode"
                            value={item.postcode}
                            onChange={(e) => {
                              setSourceError("");
                              setErrorSource([]);
                              setErrorPin("");

                              if (e.target.value.length <= 6) {
                                // setErrPincodeNotServiceable("");
                                handleOnChangeSource(e, currentSourceId - 1);
                                if (e.target.value.length == 6) {
                                  handleGetAddress(
                                    e.target.value,
                                    currentSourceId - 1
                                  );
                                  // handleGetAddressDestination(
                                  //   e.target.value,
                                  //   currentDestinationId - 1
                                  // );
                                }
                              }
                            }}
                          />
                          <h6 style={{ color: "red" }}>
                            {" "}
                            {errorPin ? errorPin : null}
                          </h6>

                          {sourceError ? (
                            <p style={{ color: "red", fontSize: "12px" }}>
                              {sourceError}
                            </p>
                          ) : null}
                         
                        </div>
                      </div> */}
                    <div style={{ marginLeft: 10 }}>
                      <Pincode parentStyle={{ display: "flex", flexDirection: "column", width: "100%" }} handlePincode={handlePincodeSource} pincodeError={errorPin} inputDesign={{ marginTop: 0, width: "100%", }} lastPincode={item.postcode}
                        pincodeIndex={currentSourceId - 1} inputWidth={"95%"}
                      />

                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Village <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          className="create-indent-input"
                          id="outlined-select-currency"
                          select
                          size="small"
                          name="village"
                          value={item.village}
                          onChange={(e) => {
                            handleOnChangeSource(e, currentSourceId - 1);
                            setErrorindentVillage("");
                          }}
                        // label="Select"
                        // value={currency}
                        // onChange={handleChange}
                        >
                          <MenuItem value="0" disabled>
                            Select Village
                          </MenuItem>
                          {villageList.map((village, index) => {
                            return (
                              <MenuItem key={index} value={village}>
                                {village}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <h6 style={{ color: "red" }}>
                          {" "}
                          {errorindentVillage ? errorindentVillage : ""}
                        </h6>

                        {/* <div className="create-trip-error">
                            {destinationDetails[currentDestinationId - 1]
                              .village == "0"
                              ? errDestinationAddress.errVillage
                              : null}
                          </div> */}
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Sub District <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-indent-input"
                          variant="outlined"
                          placeholder="sub district"
                          value={item.sub_district}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          District <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-indent-input"
                          variant="outlined"
                          placeholder="District"
                          value={item.district}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          State <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-indent-input"
                          variant="outlined"
                          placeholder="state"
                          value={item.state}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head"> Landmark</h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-indent-input"
                          variant="outlined"
                          name="landmark"
                          placeholder="Enter the landmark"
                          value={item.landmark}
                          onChange={(e) =>
                            handleOnChangeSource(e, currentSourceId - 1)
                          }
                        />
                      </div>
                    </div>

                    <div className="create-trip-input-container">
                      <button
                        className="create-trip-save-btn"
                        style={{ width: "100%" }}
                        onClick={() => handleCloseDriverDrawer()}
                      >
                        Save
                      </button>
                    </div>
                  </CreateIndentAddressStyle>
                </Drawer>
              );
            })
          : null}
        {currentTab == `destination`
          ? indentDestination
            .filter((items, indexs) => indexs == currentDestinationId - 1)
            .map((item, index) => {
              //
              return (
                <Drawer
                  anchor={"right"}
                  open={state["right"]}
                  onClose={() => {
                    toggleDrawer("right", false);
                    setErrorPin("");
                  }}
                >
                  <CreateIndentAddressStyle>
                    <div
                      className="create-trip-right-most-head"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Add Trip destination - {currentDestinationId}
                      <Close
                        color="action"
                        onClick={handleCloseDrawer}
                        style={{ marginRight: "10px" }}
                      />
                    </div>

                    {/* <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Pincode <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-indent-input"
                          variant="outlined"
                          name="postcode"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="Enter Pincode"
                          value={item.postcode}
                          onChange={(e) => {
                            setSourceError("");
                            setErrorDestination([]);
                            setErrorPin("");
                            if (e.target.value.length <= 6) {
                              // setErrPincodeNotServiceable("");

                              handleOnChangeDestination(
                                e,
                                currentDestinationId - 1
                              );
                              if (e.target.value.length == 6) {
                                handleGetAddress(
                                  e.target.value,
                                  currentDestinationId - 1
                                )
                              }
                            }
                          }}
                        />
                        <h6 style={{ color: "red" }}>
                          {" "}
                          {errorPin ? errorPin : null}
                        </h6>
                        {sourceError ? (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {sourceError}
                          </p>
                        ) : null}
                       
                      </div>
                    </div> */}
                    <div style={{ marginLeft: 10 }}>
                      <Pincode parentStyle={{ display: "flex", flexDirection: "column", width: "100%" }} handlePincode={handlePincodeDestination} pincodeError={errorPin} inputDesign={{ marginTop: 0, width: "100%", }} lastPincode={item.postcode}
                        pincodeIndex={currentDestinationId - 1} inputWidth={"95%"}
                      />
                    </div>

                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Village <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          className="create-indent-input"
                          id="outlined-select-currency"
                          select
                          // style={{ width: "100%" }}
                          size="small"
                          name="village"
                          value={item.village}
                          onChange={(e) => {
                            handleOnChangeDestination(
                              e,
                              currentDestinationId - 1
                            );
                            setErrorindentVillage("");
                          }}
                        // label="Select"
                        // value={currency}
                        // onChange={handleChange}
                        >
                          <MenuItem value="0" disabled>
                            Select Village
                          </MenuItem>
                          {villageList.map((village, index) => {
                            return (
                              <MenuItem key={index} value={village}>
                                {village}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <h6 style={{ color: "red" }}>
                          {" "}
                          {errorindentVillage ? errorindentVillage : ""}
                        </h6>
                        {/* <div className="create-trip-error">
                            {destinationDetails[currentDestinationId - 1]
                              .village == "0"
                              ? errDestinationAddress.errVillage
                              : null}
                          </div> */}
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Sub District <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-indent-input"
                          variant="outlined"
                          placeholder="sub district"
                          value={item.sub_district}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          District <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-indent-input"
                          variant="outlined"
                          placeholder="District"
                          value={item.district}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          State <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-indent-input"
                          variant="outlined"
                          placeholder="state"
                          value={item.state}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head"> Landmark</h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-indent-input"
                          variant="outlined"
                          name="landmark"
                          placeholder="Enter the landmark"
                          value={item.landmark}
                          onChange={(e) =>
                            handleOnChangeDestination(
                              e,
                              currentDestinationId - 1
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="create-trip-input-container">
                      <button
                        className="create-trip-save-btn"
                        style={{ width: "100%" }}
                        onClick={() => handleCloseDriverDrawerDesination()}
                      >
                        Save
                      </button>
                    </div>
                  </CreateIndentAddressStyle>
                </Drawer>
              );
            })
          : null}
      </div>
    </CreateIndentStyle>
  );
}

export default CreateTripIndentPage;
