import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../themes/theme";

export const Mobile_header_main_container = styled.div`
  /* .mobile-header-main-container { */
  height: 50px;
  width: 100%;
  background-color: ${colorPalette.primaryColor};
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* } */
  .mobile-header-title {
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading4};
    margin-left: 1;
  }
  .hamberger_menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
