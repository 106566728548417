import { CheckCircle, Description } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { verifySellerOtp, GetTripPickupInvoices } from "./tripPickupServices";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import { useParams } from "react-router-dom";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { Trip_pickup_main_container } from "./trip-pickup.js";
import { colorPalette } from "../../../themes/theme";

function TripPickup({ tripId, tripCode }) {
  // const { tripId, tripCode } = useParams();
  const [tripPickupList, setTripPickupList] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [openToast, setOpenToast] = useState(false);
  const [errPickupMsg, setErrPickupMsg] = useState("");
  const [PickupMsgSuccess, setPickupMsgSuccess] = useState("");

  const vertical = "bottom";
  const horizontal = "right";

  const handlePickupVerifyOtp = async (
    otp,
    tripId,
    invoiceNumber,
    orderNumber
  ) => {
    setErrPickupMsg("");
    setPickupMsgSuccess("");
    setLoadingButton(true);

    const response = await verifySellerOtp(
      otp,
      tripId,
      invoiceNumber,
      orderNumber
    );
    setOpenToast(true);
    if (response.status >= 200 && response.status <= 300) {
      setPickupMsgSuccess("Pickup verification successfull");
      setLoadingButton(false);

      setTripPickupList([
        ...tripPickupList.map((item) => {
          if (
            item?.reference_number_details?.platform_sales_order_number ===
            orderNumber
          ) {
            item["reference_number_details"]["status"] = "picked";
          }
          return item;
        }),
      ]);
      // getTripPickupList(tripCode);
    } else if (response.status === 400) {
      setLoadingButton(false);
      setErrPickupMsg(response.data.detail);
    } else if (response.status === 401) {
      setLoadingButton(false);
      setErrPickupMsg("Not enough permission");
    } else if (response.status === 403) {
      localStorage.clear();
      window.location.reload();
    } else {
      setLoadingButton(false);
      setErrPickupMsg(`${response.status} : ERROR`);
    }
  };

  const getTripPickupList = async (tripCode) => {
    const response = await GetTripPickupInvoices(tripCode);
    if (response && response.status === 200) {
      setTripPickupList(
        response.data.map((item) => {
          return {
            ...item,
            otp: "",
          };
        })
      );
      setLoadingButton(false);
      setLoadingPage(false);
    }
    if (response.status === 400) {
      setLoadingPage(false);
    }
  };

  const handleOnchangeOtp = (otp, index) => {
    let tripPickupTemp = [...tripPickupList];
    if (otp.length <= 6) {
      tripPickupTemp[index]["otp"] = otp;
    }
    setTripPickupList([...tripPickupTemp]);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  useEffect(() => {
    getTripPickupList(tripCode);
  }, [tripCode]);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  return (
    <Trip_pickup_main_container className="trip-pickup-main-container">
      {/* {isMobile ? (
        <div className="trip-pickup-mobile-header">
          <MobileHeader headerTitle="Trips" />
        </div>
      ) : (
        <div className="trip-pickup-sidebar">
          <SideBarMain />
        </div>
      )} */}
      {PickupMsgSuccess ? (
        <Snackbar
          open={openToast}
          autoHideDuration={8000}
          anchorOrigin={{ vertical, horizontal }}
          onClose={handleCloseToast}
        >
          <Alert
            variant="filled"
            elevation={6}
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%" }}
          >
            {PickupMsgSuccess}
          </Alert>
        </Snackbar>
      ) : null}
      {errPickupMsg ? (
        <Snackbar
          open={openToast}
          autoHideDuration={8000}
          anchorOrigin={{ vertical, horizontal }}
          onClose={handleCloseToast}
        >
          <Alert
            variant="filled"
            elevation={6}
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errPickupMsg}
          </Alert>
        </Snackbar>
      ) : null}

      <div className="trip-pickup-right-container">
        {loadingPage ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "65vh",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          tripPickupList.map((trip, index) => {
            return (
              <div className="trip-pickup-single">
                <div style={{ display: "flex" }}>
                  <div className="trip-pickup-single-left">
                    <div className="trip-pickup-attach-icon">
                      <Description style={{ color: "rgba(1, 105, 56, 1)" }} />
                    </div>
                  </div>
                  <div className="trip-pickup-single-middle">
                    <h4 className="trip-pickup-invoice-text">
                      <span className="trip-pickup-left-text"> Invoice : </span>
                      <span className="trip-pickup-right-text">
                        {" "}
                        {trip?.reference_number_details?.reference_number}
                      </span>
                    </h4>
                    <h4 className="trip-pickup-order-text">
                      <span className="trip-pickup-left-text">
                        {" "}
                        Faarms Order No :
                      </span>{" "}
                      <span className="trip-pickup-right-text">
                        {
                          trip?.reference_number_details
                            ?.platform_sales_order_number
                        }
                      </span>
                    </h4>
                    <h4 className="trip-pickup-order-text">
                      <span className="trip-pickup-left-text">
                        {" "}
                        Seller Order No :
                      </span>{" "}
                      <span className="trip-pickup-right-text">
                        {trip?.seller_order_number
                          ? trip?.seller_order_number
                          : ""}
                      </span>
                    </h4>
                    <h4 className="trip-pickup-order-text">
                      <span className="trip-pickup-left-text">Seller : </span>
                      <span className="trip-pickup-right-text">
                        {trip?.seller_details?.seller_name}
                      </span>
                    </h4>
                    <h4 className="trip-pickup-order-text">
                      <span className="trip-pickup-left-text">
                        {" "}
                        Phone No :{" "}
                      </span>{" "}
                      <span className="trip-pickup-right-text">
                        {trip?.seller_details?.contact_person_mobile_number
                          ? trip?.seller_details?.contact_person_mobile_number
                          : " - "}
                      </span>
                    </h4>
                    <h4 className="trip-pickup-order-text">
                      <span className="trip-pickup-left-text"> Address : </span>{" "}
                      <span className="trip-pickup-right-text">
                        {`${trip?.seller_details?.address_details?.village}, `}
                        {`${trip?.seller_details?.address_details?.sub_district}, `}
                        {`${trip?.seller_details?.address_details?.district}, `}
                        {`${trip?.seller_details?.address_details?.state}, `}
                        {`${trip?.seller_details?.address_details?.postcode} `}
                      </span>
                    </h4>
                    {trip?.reference_number_details?.status &&
                    trip?.reference_number_details?.status ===
                      "picked" ? null : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="trip-pickup-statuss">
                          <h4
                            style={{
                              color: "#FFC455",
                              fontWeight: "600",
                              fontSize: "14px",
                              lineHeight: "18px",
                            }}
                          >
                            Unpicked
                          </h4>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {trip?.reference_number_details?.status &&
                trip?.reference_number_details?.status === "picked" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "10px",
                    }}
                  >
                    <CheckCircle
                      style={{
                        color: colorPalette.primaryColor,
                        marginLeft: "7px",
                        width: "80px",
                        height: "80px",
                      }}
                    />
                    <h4
                      style={{
                        color: colorPalette.primaryColor,
                        fontWeight: "600",
                        fontSize: "14px",
                        lineHeight: "18px",
                      }}
                    >
                      Picked
                    </h4>
                  </div>
                ) : null}
                {trip?.reference_number_details?.status !== "picked" ? (
                  <div className="trip-pickup-single-right">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <input
                        className="trip-pickup-otp-input"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        placeholder="Enter 6-digit OTP"
                        value={tripPickupList[index]["otp"]}
                        onChange={(e) =>
                          handleOnchangeOtp(e.target.value, index)
                        }
                      />
                    </div>
                    {trip?.otp?.length === 6 ? (
                      <div
                        style={{
                          marginTop: "10px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          className="trip-picker-btn"
                          disabled={loadingButton}
                          onClick={() =>
                            handlePickupVerifyOtp(
                              trip?.otp,
                              tripId,
                              trip?.reference_number_details?.reference_number,
                              trip?.reference_number_details
                                ?.platform_sales_order_number
                            )
                          }
                        >
                          {loadingButton ? (
                            <CircularProgress size={12} color="inherit" />
                          ) : (
                            "Verify"
                          )}
                        </button>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            );
          })
        )}
      </div>
    </Trip_pickup_main_container>
  );
}

export default TripPickup;
