import React, { useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { DatePicker } from "@mui/x-date-pickers";
import CircularProgress from "@mui/material/CircularProgress";

import "./cascadingMenu.css";
import {
  ButtonRectangle,
  ButtonRectangleSecondary,
} from "../../themes/components/button";
import { useState } from "react";
import { readLoginData } from "../../helper/dataDecryptionHelper";

const CascadingMenu = ({
  setExportEmail,
  setExportFromDate,
  setExportToDate,
  handleOpenExportReport,
  handleTripsExportReportApi,
  handleTripReportExportApi,
  setExportTripType,
}) => {
  const validEmailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
  const [email, setEmail] = useState(readLoginData().USER_EMAIL);
  const [isEmailValid, setIsEmailValid] = useState("vicky");
  const [fromDate, setFromDate] = useState("");
  const [isFromDateValid, setIsFromDateValid] = useState(false);
  const [toDate, setToDate] = useState("");
  const [isToDateValid, setIsToDateValid] = useState(false);
  const [isGoButtonClicked, setIsGoButtonClicked] = useState(false);

  useEffect(() => {
    setEmail(readLoginData().USER_EMAIL);
  }, []);
  const handleEmail = (e) => {
    const emailValidation = validEmailRegex.test(e.target.value);
    setEmail(e.target.value);
    setExportEmail(e.target.value);

    if (emailValidation) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  };
  const handleGo = (export_type) => {
    setIsGoButtonClicked(true);
    if (email) {
      setIsEmailValid(true);
    } else if (!isEmailValid) {
      setIsEmailValid(false);
    }
    if (
      isFromDateValid &&
      isToDateValid &&
      isEmailValid &&
      isEmailValid !== "vicky"
    ) {
      setEmail("");
      setIsEmailValid("vicky");
      setFromDate("");
      setIsFromDateValid(false);
      setToDate("");
      setIsToDateValid(false);
      setIsGoButtonClicked(false);
      if (export_type === "trip_export") {
        handleTripsExportReportApi();
      } else {
        handleTripReportExportApi();
      }
    }
  };
  const handleFromDate = (e) => {
    setExportFromDate(e.target.value);
    setFromDate(e.target.value);
    if (e.target.value) {
      setIsFromDateValid(true);
    } else {
      setIsFromDateValid(false);
    }
  };
  const handleToDate = (e) => {
    setToDate(e.target.value);
    setExportToDate(e.target.value);
    if (e.target.value) {
      setIsToDateValid(true);
    } else {
      setIsToDateValid(false);
    }
  };
  const items = [
    {
      key: "1",
      label: "Trip Export",
      children: [
        {
          key: "1-1",
          label: (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setExportTripType("first_mile");
              }}
              className="mainDiv"
            >
              <div>
                <span>
                  Email<sup style={{ color: "red" }}>*</sup>
                </span>
                <input
                  className="cascadingInput"
                  style={{ width: "100%" }}
                  type="email"
                  value={email}
                  placeholder="Please enter your email..."
                  onChange={(e) => handleEmail(e)}
                />
                {!isEmailValid && !isGoButtonClicked ? (
                  <span style={{ color: "red" }}>
                    Please enter a valid email id.
                  </span>
                ) : null}
                {email && !isEmailValid && isGoButtonClicked ? (
                  <span style={{ color: "red" }}>
                    Please enter a valid email id.
                  </span>
                ) : null}
                {!email && isGoButtonClicked ? (
                  <span style={{ color: "red" }}>Please enter email id.</span>
                ) : null}
              </div>
              <div className="fromToAndGo">
                <div className="datePicker">
                  <span>
                    From date<sup style={{ color: "red" }}>*</sup>
                  </span>
                  <input
                    className="cascadingInput"
                    onChange={(e) => handleFromDate(e)}
                    value={fromDate}
                    type="date"
                    placeholder="from"
                  />
                  <span
                    style={
                      !isFromDateValid && isGoButtonClicked
                        ? { color: "red" }
                        : { color: "white" }
                    }
                  >
                    {!isFromDateValid && isGoButtonClicked
                      ? "Please choose from date."
                      : "."}
                  </span>
                </div>
                <div className="datePicker">
                  <span>
                    To date<sup style={{ color: "red" }}>*</sup>
                  </span>
                  <input
                    className="cascadingInput"
                    onChange={(e) => handleToDate(e)}
                    type="date"
                    value={toDate}
                    placeholder="to"
                  />
                  <span
                    style={
                      !isToDateValid && isGoButtonClicked
                        ? { color: "red" }
                        : { color: "white" }
                    }
                  >
                    {!isToDateValid && isGoButtonClicked
                      ? "Please choose to date."
                      : "."}
                  </span>
                </div>
                <div>
                  <ButtonRectangle onClick={() => handleGo("trip_export")}>
                    GO
                  </ButtonRectangle>
                </div>
              </div>
              <div className="advanceOptionDiv">
                <ButtonRectangle onClick={() => handleOpenExportReport()}>
                  Advance Options
                </ButtonRectangle>
              </div>
            </div>
          ),
        },
      ],
    },
    {
      key: "2",
      label: "Log Report Export",
      children: [
        {
          key: "2-1",
          label: (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setExportTripType("first_mile");
              }}
              className="mainDiv"
            >
              <div>
                <span>
                  Email<sup style={{ color: "red" }}>*</sup>
                </span>
                <input
                  className="cascadingInput"
                  style={{ width: "100%" }}
                  type="email"
                  value={email}
                  placeholder="Please enter your email..."
                  onChange={(e) => handleEmail(e)}
                />
                {!isEmailValid && !isGoButtonClicked ? (
                  <span style={{ color: "red" }}>
                    Please enter a valid email id.
                  </span>
                ) : null}
                {email && !isEmailValid && isGoButtonClicked ? (
                  <span style={{ color: "red" }}>
                    Please enter a valid email id.
                  </span>
                ) : null}
                {!email && isGoButtonClicked ? (
                  <span style={{ color: "red" }}>Please enter email id.</span>
                ) : null}
              </div>
              <div className="fromToAndGo">
                <div className="datePicker">
                  <span>
                    From date<sup style={{ color: "red" }}>*</sup>
                  </span>
                  <input
                    className="cascadingInput"
                    onChange={(e) => handleFromDate(e)}
                    value={fromDate}
                    type="date"
                    placeholder="from"
                  />
                  <span
                    style={
                      !isFromDateValid && isGoButtonClicked
                        ? { color: "red" }
                        : { color: "white" }
                    }
                  >
                    {!isFromDateValid && isGoButtonClicked
                      ? "Please choose from date."
                      : "."}
                  </span>
                </div>
                <div className="datePicker">
                  <span>
                    To date<sup style={{ color: "red" }}>*</sup>
                  </span>
                  <input
                    className="cascadingInput"
                    onChange={(e) => handleToDate(e)}
                    type="date"
                    value={toDate}
                    placeholder="to"
                  />
                  <span
                    style={
                      !isToDateValid && isGoButtonClicked
                        ? { color: "red" }
                        : { color: "white" }
                    }
                  >
                    {!isToDateValid && isGoButtonClicked
                      ? "Please choose to date."
                      : "."}
                  </span>
                </div>
                <div>
                  <ButtonRectangle onClick={() => handleGo("report_export")}>
                    GO
                  </ButtonRectangle>
                </div>
              </div>
            </div>
          ),
        },
      ],
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
    >
      <ButtonRectangleSecondary
        onClick={(e) => e.preventDefButtonRectangleult()}
      >
        <Space>
          Export
          <DownOutlined />
        </Space>
      </ButtonRectangleSecondary>
    </Dropdown>
  );
};
export default CascadingMenu;
