import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../theme";

export const ButtonRectangle = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.primaryColor};
  border: 1px solid ${colorPalette.primaryColor};
  color: ${colorPalette.white};
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 100px;
  font-size: ${typography.paragraph};
  padding: 0 10px;
  height: 35px;
  white-space: nowrap;

  :hover {
    font-size: ${typography.paragraph};
    background-color: ${colorPalette.secondaryGreen};
    border-color: ${colorPalette.secondaryGreen};
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colorPalette.primaryColor};
    border: 1px solid ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: ${typography.heading6};
    /* padding: 0 5px; */
    height: 35px;
    max-width: min-content;
    /* font-size: 12px; */
  }
`;

export const ButtonRectangleSecondary = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.transparent};
  border: 1px solid ${colorPalette.primaryColor};
  color: ${colorPalette.primaryColor};
  border-radius: 5px;
  cursor: pointer;
  margin-left: 5px;
  /* margin-top: 10px; */
  transition: all 0.2s ease;
  min-width: 100px;
  font-size: ${typography.paragraph};
  height: 35px;

  :hover {
    font-size: ${typography.paragraph};
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
  }
`;

export const ButtonPrimary = styled.button`
  background-color: ${colorPalette.primaryColor};
  font-size: ${typography.paragraph};
  color: ${colorPalette.white};
  padding: 15px;
  font-weight: ${FontWeight.bold};
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${colorPalette.white};
  margin: 0 10px;
  transition: 0.2s ease;
  min-width: 140px;

  :hover {
    background-color: ${colorPalette.transparent};
    color: ${colorPalette.primaryColor};
    border-color: ${colorPalette.primaryColor};
  }
`;

export const ButtonSecondary = styled.button`
  background-color: ${colorPalette.transparent};
  font-size: ${typography.paragraph};
  color: ${colorPalette.white};
  border-radius: 10px;
  padding: 15px;
  font-weight: ${FontWeight.bold};
  cursor: pointer;
  border: 1px solid ${colorPalette.primaryColor};
  margin: 0 10px;
  min-width: 140px;
`;

export const ButtonPrimary2 = styled.button`
  background-color: ${colorPalette.white};
  font-size: ${typography.paragraph};
  color: ${colorPalette.primaryColor};
  padding: 15px;
  font-weight: ${FontWeight.bold};
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${colorPalette.white};
  margin: 0 10px;
  transition: 0.2s ease;
  min-width: 140px;

  :hover {
    background-color: ${colorPalette.transparent};
    color: ${colorPalette.white};
  }

  @media only screen and (max-width: 768px) {
    background-color: ${colorPalette.white};
    font-size: ${typography.heading6};
    color: ${colorPalette.primaryColor};
    padding: 10px;
    font-weight: ${FontWeight.bold};
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid ${colorPalette.white};
    margin: 0 5px;
    transition: 0.2s ease;
    min-width: 100px;
  }
`;
export const ButtonPrimary3 = styled.button`
  background-color: ${colorPalette.white};
  font-size: ${typography.paragraph};
  color: ${colorPalette.white};
  padding: 15px;
  font-weight: ${FontWeight.bold};
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${colorPalette.white};
  margin: 0 10px;
  transition: 0.2s ease;
  min-width: 140px;
   height:

  }

  @media only screen and (max-width: 768px) {
    background-color: ${colorPalette.white};
    font-size: ${typography.heading6};
    color: ${colorPalette.primaryColor};
    padding: 10px;
    font-weight: ${FontWeight.bold};
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid ${colorPalette.white};
    margin: 0 5px;
    transition: 0.2s ease;
    min-width: 100px;
  }
`;
export const ButtonSecondary2 = styled.button`
  background-color: ${colorPalette.transparent};
  font-size: ${typography.paragraph};
  color: ${colorPalette.white};
  padding: 15px;
  font-weight: ${FontWeight.bold};
  /* border-radius: 50px; */
  cursor: pointer;
  border: 1px solid ${colorPalette.white};
  margin: 0 10px;
  min-width: 140px;
`;

export const ButtonTertiary2 = styled.button`
  background-color: ${colorPalette.transparent};
  font-size: ${typography.paragraph};
  font-weight: ${FontWeight.bold};
  color: ${colorPalette.white};
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid ${colorPalette.white};
  margin: 0 10px;
  min-width: 140px;
  @media only screen and (max-width: 768px) {
    background-color: ${colorPalette.transparent};
    font-size: ${typography.heading6};
    font-weight: ${FontWeight.bold};
    color: ${colorPalette.white};
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid ${colorPalette.white};
    margin: 0 10px;
    min-width: 100px;
  }
`;
