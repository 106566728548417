import styled from "styled-components";
import { colorPalette } from "../../themes/theme";

export const NotAuthorizedStyle = styled.div`
  height: 80vh;
  width: 70vw;
  display: "flex";
  align-items: "center";
  justify-content: "center";
  background: transparent;
  margin-top: 160px;

  .unauthorized-content {
    text-align: center;
    padding: 20px;
    border-radius: 8px;
  }

  h1 {
    color: #dc3545;
    margin-bottom: 15px;
  }

  .message {
    color: #495057;
    margin-bottom: 5px;
  }

  .dashboard-button {
    background-color: ${colorPalette.primaryColor}; /* Blue color for the button */
    color: #ffffff; /* White color for the text */
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 15px;

    &:hover {
      background-color: ${colorPalette.secondaryGreen}; /* Darker blue on hover */
    }
  }

  @media only screen and (max-width: 480px) {
    width: 95vw;
    height: 70vh;
  }
  /* Add more styling as needed */
`;
