import React, { useState, useRef, useCallback } from "react";
import "../completeTrip/complete-trip.css";
import tripSettingsLoader from "../../../assets/tripIcons/settingsloader.gif";
import MobileHeader from "../../../components/MobileHeader/MobileHeader.jsx";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import Topbar from "../../../components/topbar/Topbar.jsx";
import MenuItem from "@mui/material/MenuItem";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import TextField from "@mui/material/TextField";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Trip_settings_main_Container,
  Trip_settings_wrapper_drawer,
  Trip_settings_wrapper_drawer_bottom,
  Trip_settings_wrapper_drawer_whitelistedcustomer_list,
} from "./trip-settings";
import {
  ButtonRectangle,
  ButtonRectangleSecondary,
} from "../../../themes/components/button";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, CircularProgress, Drawer, Snackbar } from "@mui/material";
// import { Trip_settings_wrapper } from "../tripListV2/trip-listv2";
import AsyncSelect from "react-select/async";
import { GetCustomerSearchList } from "../createTrip/createTripServices";
import { useEffect } from "react";
import {
  creatWhitelistedConsignor,
  createFeature,
  deleteConsignor,
  getDefaultBehaviourList,
  getFeaturesList,
  getTripSettingsList,
  getTripTypeList,
  getwhiteListedConsignorList,
  updateFeature,
} from "./tripSettingsServices";
import { AddCircle } from "@mui/icons-material";
import { colorPalette } from "../../../themes/theme";
import { useHistory } from "react-router-dom";

function TripSettings() {
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const history = useHistory();
  const [unauthorizedMsg, setUnauthorizedMsg] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [whiteListedcurrentPage, setWhiteListedcurrentPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingDeleteBtn, setLoadingDeleteBtn] = useState(false);
  const [loadingAddBtn, setLoadingAddBtn] = useState(false);
  const [errorInSettingsCreation, setErrorInSettingsCreation] = useState("");
  const [loadingWhiteListedConsignor, setLoadingWhiteListedConsignor] =
    useState(false);
  const [isWhiteListedLastPage, setIsWhiteListedLastPage] = useState(false);
  const [loadingMoreWhiteListed, setLoadingMoreWhiteListed] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [errorEmptyConsignor, setErrorEmptyConsignor] = useState("");
  const [addQuoteSuccessToast, setAddQuoteSuccessToast] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [errorFeature, setErrorFeature] = useState({});
  const [errorConsignorId, setErrorConsignorId] = useState({});
  const [errorDefault, setErrorDefault] = useState({});
  const [errorTripType, setErrorTripType] = useState({});
  const [consignorWhitelisted, setConsignorWhitelisted] = useState([]);
  const [tripSettingsId, setTripSettingsId] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [settingsId, setSettingsId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [tripSettingsList, setTripSettingsList] = useState([]);
  const [consignorId, setConsignorId] = useState(0);
  const [tripTypeList, setTripTypeList] = useState([]);
  const [tripTypeForConsignorList, setTripTypeForConsignorList] = useState("");
  const [featureForConsignorList, setFeatureForConsignorList] = useState("");
  const [errorForCreateConsignor, setErrorForCreateConsignor] = useState("");
  const [errorForCreateFeature, setErrorForCreateFeature] = useState("");
  const [tripFeaturesList, setTripFeaturesList] = useState([]);
  const [tripDefaultBehaviourList, setTripDefaultBehaviourList] = useState([]);
  const [featureType, setFeatureType] = useState("");
  const [tripType, setTripType] = useState("");
  const [defaultBehaviorType, setDefaultBehaviorType] = useState("");
  const [selectedConsignorName, setSelectedConsignorName] = useState("");
  const [tripSettingsState, setTripSettingsState] = useState({ right: false });
  const [tripSettingsCusomersListDrawer, setTripSettingsCusomersListDrawer] =
    useState({ right: false });
   
  const toggTripSettingsDrawer = (anchor, open) => {
    setTripSettingsState({ ...tripSettingsState, [anchor]: open });
  };
  const handleSettings = () => {
    toggTripSettingsDrawer("right", true);
  };
  const toggTripSettingsCustomerListDrawer = (anchor, open) => {
    setTripSettingsCusomersListDrawer({
      ...tripSettingsCusomersListDrawer,
      [anchor]: open,
    });
  };

  // PAGINATION=======
  const observer = useRef();
  const lastSettingsList = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage(currentPage + 1);
          // setLoadingMore(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage, currentPage]
  );

  // const observer = useRef();
  const lastWhiteListedsList = useCallback(
    (node) => {
      if (loadingMoreWhiteListed) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isWhiteListedLastPage) {
          setWhiteListedcurrentPage(whiteListedcurrentPage + 1);
          setLoadingMoreWhiteListed(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMoreWhiteListed, isWhiteListedLastPage, whiteListedcurrentPage]
  );
  // ===============

  const handleCloseBtn = () => {
    toggTripSettingsDrawer("right", false);
    setEditMode(false);
    setConsignorId("");
    setSelectedConsignorName("");
    setTripType("");
    setFeatureType("");
    setDefaultBehaviorType("");
    setErrorInSettingsCreation("");
  };
  const handleCloseConsignorsList = () => {
    toggTripSettingsCustomerListDrawer("right", false);
    setErrorForCreateConsignor("");
    setConsignorId("");
    setSelectedConsignorName("");
    setConsignorWhitelisted([]);
    setWhiteListedcurrentPage(1);
    setFeatureForConsignorList("");
    setTripType("");
    setErrorConsignorId({});
    setErrorEmptyConsignor("");
    // setEditMode(false);
    // setConsignorId("");
    // setSelectedConsignorName("");
    // setTripType("");
    // setFeatureType("");
    // setDefaultBehaviorType("");
    // setErrorInSettingsCreation("");
  };
  const handleFetchConsignor = async (values) => {
    const productResponse = await GetCustomerSearchList(values);

    // if (productResponse.status === 403) {
    //   localStorage.clear();
    //   window.location.reload();
    // }

    return productResponse.customers_list
      .filter((item) => item.name !== "faarms")
      .filter((item) => item.name !== "FAARMS")
      .filter((item) => item.name !== "Faarms")
      .map((item) => {
        return {
          value: item.id,
          customer_code: item.customer_code,
          name: item.name,

          label: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                zIndex: "10000000",

                // overflowY: "scroll",
                // height: "100px",
              }}
              // className="create-trip-vehicle-single"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  zIndex: "1000",
                }}
              >
                <div>
                  <div>
                    <span className="create-trip-transporter-number-text">
                      {item.customer_code}
                    </span>
                  </div>
                </div>

                <div style={{ zIndex: "1000" }}>
                  <span
                    className="create-trip-sub-text"
                    style={{ zIndex: "1000" }}
                  >
                    {/* <PersonOutlineOutlinedIcon /> */}
                    {item.name}
                  </span>
                </div>
              </div>
            </div>
          ),
        };
      });

    // return productResponse.data.transporter_list.map((datas) => {
    //   return {
    //     value: datas.id,
    //     label: datas.name,
    //   };
    // });
  };

  const tripTypeListData = async () => {
    const response = await getTripTypeList();
    if (response) {
      setTripTypeList(response);
    }
  };
  const tripFeaturesListData = async () => {
    const response = await getFeaturesList();
    if (response) {
      setTripFeaturesList(response);
    }
  };
  const tripDefaultListData = async () => {
    const response = await getDefaultBehaviourList();
    if (response) {
      setTripDefaultBehaviourList(response);
    }
  };

  useEffect(() => {
    tripTypeListData();
    tripFeaturesListData();
    tripDefaultListData();
  }, []);

  const whiteListedConsignorList = async (
    feature,
    tripType,
    whiteListedcurrentPage
  ) => {
    setLoadingMoreWhiteListed(true);
    const response = await getwhiteListedConsignorList(
      feature,
      tripType,
      whiteListedcurrentPage
    );
    if (whiteListedcurrentPage == 1) {
      setLoadingMoreWhiteListed(true);
      // setLoadingWhiteListedConsignor(true);
    }

    // if (response) {
    //   setLoadingWhiteListedConsignor(false);
    //   setConsignorWhitelisted(
    //     response.whitelisted_consignors_list.filter(
    //       (data) => data.consignor_id !== "*"
    //     )
    //   );

    // }
    if (response.status == 401) {
      setLoadingMoreWhiteListed(true);
      setUnauthorizedMsg(response?.data.error);
    }
    if (response) {
      // setTripSettingsList(response.trip_settings_list);
      // setLoadingWhiteListedConsignor(false);
      if (whiteListedcurrentPage > 1) {
        const filtredResponse = response?.whitelisted_consignors_list?.filter(
          (data) => data.consignor_id !== "*"
        );
        setConsignorWhitelisted([...consignorWhitelisted, ...filtredResponse]);
        setIsWhiteListedLastPage(response?.is_last_page);
        // setLoadingWhiteListedConsignor(false);
        setLoadingMoreWhiteListed(false);
        // setTripType()
      } else {
        const filtredResponse = response?.whitelisted_consignors_list?.filter(
          (data) => data.consignor_id !== "*"
        );
        setConsignorWhitelisted(filtredResponse);
        setIsWhiteListedLastPage(response?.is_last_page);
        // setLoadingWhiteListedConsignor(false);
        setLoadingMoreWhiteListed(false);
      }
      // setLoadingWhiteListedConsignor(false);
    }

    // setLoadingMoreWhiteListed(false);
  };
  useEffect(() => {
    // if (featureForConsignorList && tripType) {
    whiteListedConsignorList(
      featureForConsignorList,
      tripType,
      whiteListedcurrentPage
    );
    // }
  }, [whiteListedcurrentPage]);

  const handleCreateTripFeature = async () => {
    setButtonLoading(true);
    if (editMode) {
      const responseEdit = await updateFeature(
        settingsId,
        tripType,
        featureType,
        defaultBehaviorType
      );

      if (responseEdit.status == 200) {
        setButtonLoading(false);
        setConsignorId("");
        setSelectedConsignorName("");
        setTripType("");
        setFeatureType("");
        setDefaultBehaviorType("");
        toggTripSettingsDrawer("right", false);
        getAllSettingsList(1);
        setEditMode(false);
      }
      if (responseEdit.status == 400) {
        setButtonLoading(false);
        setErrorInSettingsCreation(responseEdit.data.detail);
      }
      if (responseEdit.status == 401) {
        setButtonLoading(false);
        setAddQuoteSuccessToast(true);
        setErrorForCreateFeature("Unauthorized!");
        // setErrorInSettingsCreation(response.data.detail);
      }
    } else {
      const isValid = formValidation();

      if (isValid) {
        const response = await createFeature(
          tripType,
          featureType,
          defaultBehaviorType
        );

        if (response.status == 201) {
          setButtonLoading(false);
          setConsignorId("");
          setSelectedConsignorName("");
          setTripType("");
          setFeatureType("");
          setDefaultBehaviorType("");
          toggTripSettingsDrawer("right", false);
          getAllSettingsList(1);
        }
        if (response.status == 400) {
          setButtonLoading(false);
          setErrorInSettingsCreation(response.data.detail);
        }
        if (response.status == 401) {
          setButtonLoading(false);
          setAddQuoteSuccessToast(true);
          setErrorForCreateFeature("Unauthorized!");
          // setErrorInSettingsCreation(response.data.detail);
        }
      } else {
        setButtonLoading(false);
      }
    }
  };
  const handleCreateWhitelistedConsignor = async () => {
    if (consignorId) {
      setLoadingAddBtn(true);
      const response = await creatWhitelistedConsignor(
        consignorId,
        tripSettingsId
      );
      if (response.status == 201) {
        setLoadingAddBtn(false);
        setButtonLoading(false);
        setConsignorId("");
        setSelectedConsignorName("");
        whiteListedConsignorList(
          featureForConsignorList,
          tripTypeForConsignorList,
          1
        );
        setErrorForCreateConsignor("");
      }
      if (response.status == 400) {
        setLoadingAddBtn(false);
        setErrorForCreateConsignor(response.data.detail);
      }
      if (response.status == 401) {
        setLoadingAddBtn(false);
        setAddQuoteSuccessToast(true);
        setErrorForCreateConsignor("Unauthorized!");
      }
      setLoadingAddBtn(false);
    } else {
      setErrorEmptyConsignor("Please select Consignor");
    }

    //
  };
  const getAllSettingsList = async (currentPage) => {
    setLoadingMore(true);
    const response = await getTripSettingsList(currentPage);

    if (response) {
      // setTripSettingsList(response.trip_settings_list);

      if (currentPage > 1) {
        setTripSettingsList([
          ...tripSettingsList,
          ...response?.whitelisted_consignors_list,
        ]);
        setIsLastPage(response?.is_last_page);
        setLoadingPage(false);
        setLoadingMore(false);
      } else {
        setTripSettingsList(response?.whitelisted_consignors_list);
        setIsLastPage(response?.is_last_page);
        setLoadingPage(false);
        setLoadingMore(false);
      }
    }
    //
  };
  useEffect(() => {
    getAllSettingsList(currentPage);
  }, [currentPage]);
  const handleEdit = (data, e) => {
    handleSettings();
    setEditMode(true);
    setSettingsId(data.trip_settings_id);
    setFeatureType(data.feature);
    setTripType(data.trip_type);
    setDefaultBehaviorType(data.behaviour);
    // setConsignorId(data.whitelisted_consignor.id);
    // setSelectedConsignorName(data.whitelisted_consignor.name);
  };
  const formValidation = () => {
    const errorFeature = {};
    const errorDefault = {};
    const errorTripType = {};
    const errorConsignorId = {};
    let isValid = true;
    if (tripType == "") {
      errorTripType.errortriptype = "Trip type is mandatory.";
      isValid = false;
    }
    if (defaultBehaviorType == "") {
      errorDefault.errordefaulttype = "default is mandatory.";
      isValid = false;
    }
    if (featureType == "") {
      errorFeature.errortripfeature = "Feature is mandatory.";
      isValid = false;
    }
    // if (consignorId == "") {
    //   errorConsignorId.errorconsignorId = "Please select consignor.";
    // }
    // if (editMode == false) {
    //   if (manufacturerPhoto.name == undefined) {
    //     errorPhoto.Photomissing = "Please Upload Logo";
    //     isValid = false;
    //   }
    // }

    setErrorFeature(errorFeature);
    setErrorDefault(errorDefault);
    setErrorTripType(errorTripType);
    // setErrorConsignorId(errorConsignorId);
    return isValid;
  };
  const handleGoBack = () => {
    history.push("/ftl-trips");
  };

  // useEffect(() => {
  //   whiteListedConsignorList();
  // }, []);
  const handleSettingsCustomerList = (
    feature,
    tripType,
    tripSettings_Id,
    page
  ) => {
    setTripSettingsId(tripSettings_Id);
    setTripTypeForConsignorList(tripType);
    setFeatureForConsignorList(feature);
    whiteListedConsignorList(feature, tripType, page);
    toggTripSettingsCustomerListDrawer("right", true);
  };
  const handleDelete = async (id) => {
    setLoadingDeleteBtn(true);

    const resposne = await deleteConsignor(id);
    if (resposne.status == 200) {
      setDeleteId("");
      setLoadingDeleteBtn(false);
      whiteListedConsignorList(
        featureForConsignorList,
        tripTypeForConsignorList,
        1
      );
    }
    if (resposne == 401) {
      setLoadingDeleteBtn(false);
      setAddQuoteSuccessToast(true);
      setErrorForCreateFeature("Unauthorized!");
    }
  };

  // const closeWhitelistedDrawer=()=>{
  //   setConsignorId("");
  //           setSelectedConsignorName("");
  //           setErrorForCreateConsignor("");
  //           setConsignorWhitelisted([]);
  //           setWhiteListedcurrentPage(1);
  //           setFeatureForConsignorList("");
  //           setTripType("");
  //           setErrorConsignorId({});
  // }
  const vertical = "bottom";
  const horizontal = "left";
  const handleCloseToast = () => {
    // setQuoteToastError("");
    setAddQuoteSuccessToast(false);
    // setAddQuoteErrorToast(false);
  };
  return (
    <div className="complete-trip-main-container">
      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Complete trip" />
        </div>
      ) : (
        <div className="complete-trip-sidebar">
          <SideBarMain />
        </div>
      )}

      <Snackbar
        open={addQuoteSuccessToast}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseToast}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseToast}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {errorForCreateFeature}
        </Alert>
      </Snackbar>
      <Drawer
        anchor={"right"}
        open={tripSettingsState["right"]}
        onClose={() => {
          handleCloseBtn();
          // setEditMode(false);
          // toggTripSettingsDrawer("right", false);
          // setConsignorId("");
          // setSelectedConsignorName("");
          // setTripType("");
          // setFeatureType("");
          // setDefaultBehaviorType("");
          // setErrorInSettingsCreation("");
        }}
      >
        <Trip_settings_wrapper_drawer>
          <div className="trip-settings-head-drawer">
            <div style={{ fontWeight: "bold" }}>
              {" "}
              {editMode ? "Update" : "Add"} Settings
            </div>
            <div>
              <CloseIcon
                style={{
                  // color: darkMode ? colorPaletteDark.white : "",
                  cursor: "pointer",
                }}
                onClick={handleCloseBtn}
              />
            </div>
          </div>
          <div className="settings_list_container">
            {/* <div className="trip-settings-row">
              <div className="create-trip-input-head">
                {" "}
                Select Consignor
                <sup style={{ color: "red" }}>*</sup>
              </div>
              <AsyncSelect
                className="input_field_trip_settings"
                defaultOptions
                key={consignorId}
                placeholder="search Consignor..."
                value={
                  consignorId
                    ? {
                        value: consignorId,
                        label: selectedConsignorName,
                      }
                    : ""
                }
                onChange={(e) => {
                   

                  setConsignorId(e.value);
                  setSelectedConsignorName(e.name);
                  setErrorConsignorId({});
                }}
                loadOptions={(inputValue) => handleFetchConsignor(inputValue)}
              />
              {Object.keys(errorConsignorId).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorConsignorId[key]}</h6>
                );
              })}
            </div> */}
            <div className="trip-settings-row">
              <div className="create-trip-input-head">
                {" "}
                Trip Type<sup style={{ color: "red" }}>*</sup>
              </div>
              <TextField
                id="filled-select-currency"
                select
                label="Select Trip Type"
                value={tripType}
                onChange={(e) => {
                  setTripType(e.target.value);

                  setErrorTripType({});
                  // SetErrorFreight("");
                  // setErrorPermitNational("");
                }}
                // disabled={vehicleTripListData.length != 0}
                // variant="filled"
                className="input_field_trip_settings"
                color="success"
                size="small"
              >
                {tripTypeList.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option.split("_").join(" ").toUpperCase()}
                  </MenuItem>
                ))}
                {/* {freightList.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option.split("_").join(" ").toUpperCase()}
                      </MenuItem>
                    ))} */}
                {/* <MenuItem value={true}>
                      {frieghtType.map((type) => type)}
                    </MenuItem> */}
              </TextField>
              {Object.keys(errorTripType).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorTripType[key]}</h6>
                );
              })}
            </div>
            <div className="trip-settings-row">
              <div className="create-trip-input-head">
                {" "}
                Feature<sup style={{ color: "red" }}>*</sup>
              </div>
              <TextField
                id="filled-select-currency"
                select
                label="Select feature"
                value={featureType}
                disabled={editMode ? true : false}
                onChange={(e) => {
                  setFeatureType(e.target.value);
                  setErrorFeature({});

                  // SetErrorFreight("");
                  // setErrorPermitNational("");
                }}
                // disabled={vehicleTripListData.length != 0}
                // variant="filled"
                className="input_field_trip_settings"
                color="success"
                size="small"
              >
                {tripFeaturesList.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option.split("_").join(" ").toUpperCase()}
                  </MenuItem>
                ))}
                {/* {freightList.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option.split("_").join(" ").toUpperCase()}
                      </MenuItem>
                    ))} */}
                {/* <MenuItem value={true}>
                      {frieghtType.map((type) => type)}
                    </MenuItem> */}
              </TextField>
              {Object.keys(errorFeature).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorFeature[key]}</h6>
                );
              })}
            </div>
            <div className="trip-settings-row">
              <div className="create-trip-input-head">
                {" "}
                Default Behaviour<sup style={{ color: "red" }}>*</sup>
              </div>
              <TextField
                id="filled-select-currency"
                select
                label="Select default behaviour"
                value={defaultBehaviorType}
                onChange={(e) => {
                  setDefaultBehaviorType(e.target.value);
                  setErrorDefault({});
                  // SetErrorFreight("");
                  // setErrorPermitNational("");
                }}
                disabled={editMode ? true : false}
                // disabled={vehicleTripListData.length != 0}
                // variant="filled"
                className="input_field_trip_settings"
                color="success"
                size="small"
              >
                {tripDefaultBehaviourList.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option.split("_").join(" ").toUpperCase()}
                  </MenuItem>
                ))}
                {/* {freightList.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option.split("_").join(" ").toUpperCase()}
                      </MenuItem>
                    ))} */}
                {/* <MenuItem value={true}>
                      {frieghtType.map((type) => type)}
                    </MenuItem> */}
              </TextField>
              {Object.keys(errorDefault).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorDefault[key]}</h6>
                );
              })}
            </div>
            {errorInSettingsCreation ? (
              <div
                style={{
                  color: "red",
                  backgroundColor: "#FFE0E0",
                  fontSize: "12px",
                  padding: "5px",
                }}
              >
                {errorInSettingsCreation}
              </div>
            ) : null}
            <div className="trip-settings-row-button">
              <ButtonRectangle
                style={{ width: "100%" }}
                disabled={buttonLoading ? true : false}
                onClick={handleCreateTripFeature}
              >
                {buttonLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : editMode ? (
                  "Update"
                ) : (
                  "Submit"
                )}
              </ButtonRectangle>
            </div>
          </div>
        </Trip_settings_wrapper_drawer>
        {/* <h6>JWSDJSDJDJSDSJDSJDSJDSD</h6> */}
      </Drawer>

      <Drawer
        anchor={"right"}
        open={tripSettingsCusomersListDrawer["right"]}
        onClose={() => {
          handleCloseConsignorsList();
          // setEditMode(false);
          // toggTripSettingsCustomerListDrawer("right", false);
          // setConsignorId("");
          // setSelectedConsignorName("");
          // setErrorForCreateConsignor("");
          // setConsignorWhitelisted([]);
          // setWhiteListedcurrentPage(1);
          // setFeatureForConsignorList("");
          // setTripType("");
          // setErrorConsignorId({});
        }}
      >
        <Trip_settings_wrapper_drawer_whitelistedcustomer_list>
          <div className="trip-settings-head-drawer">
            <div style={{ fontWeight: "bold" }}>Whitelisted Consignors</div>
            <div>
              <CloseIcon
                style={{
                  // color: darkMode ? colorPaletteDark.white : "",
                  cursor: "pointer",
                }}
                onClick={handleCloseConsignorsList}
              />
            </div>
          </div>
          <div className="settings_list_container">
            <ul style={{ padding: "0px 10px" }}>
              {consignorWhitelisted.length > 0 ? (
                consignorWhitelisted.map((data, index) => (
                  <li className="whitelisted_customer_list" key={index}>
                    {consignorWhitelisted?.length === index + 1 ? (
                      <div ref={lastWhiteListedsList}>{data.name}</div>
                    ) : (
                      <div>{data.name}</div>
                    )}

                    {loadingDeleteBtn && data.id == deleteId ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      <>
                        {" "}
                        <CancelOutlinedIcon
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            setDeleteId(data.id);
                            handleDelete(data.id);
                          }}
                        />
                      </>
                    )}
                  </li>
                ))
              ) : loadingMoreWhiteListed == true ? (
                <li
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  Loading data...
                </li>
              ) : consignorWhitelisted.length == 0 ? (
                <li
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  No consignor found!
                </li>
              ) : null}
            </ul>
          </div>
        </Trip_settings_wrapper_drawer_whitelistedcustomer_list>
        {/* <hr style={{ margin: "0px 0px" }} /> */}
        <Trip_settings_wrapper_drawer_bottom>
          <div className="trip-settings-topbar-customer-list-add">
            <div className="trip-settings-row_add_customer">
              {/* <div className="create-trip-input-head">
                  {" "}
                  Select Consignor
                  <sup style={{ color: "red" }}>*</sup>
                </div> */}
              <AsyncSelect
                className="input_field_trip_settings"
                defaultOptions
                key={consignorId}
                placeholder="search Consignor..."
                value={
                  consignorId
                    ? {
                        value: consignorId,
                        label: selectedConsignorName,
                      }
                    : ""
                }
                onChange={(e) => {
                  //

                  setConsignorId(e.value);
                  setSelectedConsignorName(e.name);
                  setErrorConsignorId({});
                  setErrorConsignorId({});
                  setErrorForCreateConsignor("");
                  setErrorEmptyConsignor("");
                }}
                loadOptions={(inputValue) => handleFetchConsignor(inputValue)}
              />

              {Object.keys(errorConsignorId).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorConsignorId[key]}</h6>
                );
              })}
            </div>
            {errorForCreateConsignor ? (
              <div
                className="error_trip_settings"
                style={{ padding: "5px 5px" }}
              >
                {errorForCreateConsignor}
              </div>
            ) : null}
            {errorEmptyConsignor ? (
              <div
                style={{ color: "red", fontSize: "12px", padding: "2px 5px" }}
              >
                {errorEmptyConsignor}
              </div>
            ) : null}
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <ButtonRectangle onClick={handleCreateWhitelistedConsignor}>
                {loadingAddBtn ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "+ Add"
                )}
              </ButtonRectangle>
            </div>
          </div>
        </Trip_settings_wrapper_drawer_bottom>
      </Drawer>
      <Trip_settings_main_Container>
        {isMobile ? null : <Topbar />}
        <div className="trip-settings-topbar">
          <div
            style={{
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArrowBackIcon
              style={{ cursor: "pointer", fontSize: "30px" }}
              onClick={handleGoBack}
            />{" "}
            Trip Settings
          </div>

          <ButtonRectangle onClick={handleSettings}>+ Add</ButtonRectangle>
        </div>
        <div className="trip-settins-table-container">
          <div className="trip-settins-table">
            <table>
              <tr>
                <th className="trip-settings-table-headers">Feature</th>

                <th className="trip-settings-table-headers">Trip type</th>
                <th className="trip-settings-table-headers">
                  Default behaviour
                </th>
                <th
                  className="trip-settings-table-headers"
                  style={{
                    width: "200px",
                  }}
                >
                  Actions
                </th>
              </tr>
              {tripSettingsList.length > 0 ? (
                tripSettingsList.map((data, index) => (
                  <tr key={index}>
                    {tripSettingsList?.length === index + 1 ? (
                      <td className="trip-settings-table-headers">
                        <div ref={lastSettingsList}>
                          {data.feature.replace(/_/g, " ").toUpperCase()}
                        </div>
                      </td>
                    ) : (
                      <td className="trip-settings-table-headers">
                        {data.feature.replace(/_/g, " ").toUpperCase()}
                      </td>
                    )}

                    <td className="trip-settings-table-headers">
                      {data.trip_type.replace(/_/g, " ").toUpperCase()}
                    </td>
                    <td className="trip-settings-table-headers">
                      {data.behaviour.replace(/_/g, " ").toUpperCase()}
                    </td>
                    <td
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <ModeEditOutlineOutlinedIcon
                        onClick={(e) => {
                          handleEdit(data, e);
                        }}
                        style={{
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                      />
                      <h6
                        style={{
                          fontSize: isMobile ? "10px" : "15px",
                          cursor: "pointer",

                          textDecoration: "underline",
                          fontWeight: "normal",
                          color: "blue",
                        }}
                        onClick={() => {
                          handleSettingsCustomerList(
                            data.feature,
                            data.trip_type,
                            data.trip_settings_id,
                            1
                          );
                          setWhiteListedcurrentPage(1);
                          setConsignorWhitelisted([]);
                        }}
                      >
                        {" "}
                        View Consignors
                      </h6>
                    </td>
                  </tr>
                ))
              ) : loadingMore ? (
                <div className="loader_container">
                  <img
                    src={tripSettingsLoader}
                    height="40px"
                    width="40px"
                    alt="loader"
                  />
                  Loading data...
                </div>
              ) : (
                <div className="loader_container" style={{ color: "black" }}>
                  No Settings Found , Create One.
                </div>
              )}
            </table>
          </div>
          {loadingMore && tripSettingsList.length !== 0 ? (
            <div className="loading_more_data_trip_settings">
              Loading More, please wait...
            </div>
          ) : null}
        </div>
      </Trip_settings_main_Container>
    </div>
  );
}

export default TripSettings;
