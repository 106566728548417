import styled from "styled-components";
import {
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";

export const Mini_detail_carrier_card_main_container_payment = styled.div`
  /* .mini-details-carrier-card-main-container_payments { */
  width: 400px;
  height: 85vh;
  /* background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white}; */

  box-shadow: ${colorPalette.boxShadowPrimary};
  border-radius: 14px;
  /* } */
  .bank_holder {
    display: flex;
    align-items: center;
    justify-content: center;
    /* justify-content: flex-start; */
  }
  .mini-details-carrier-tab-container {
    /* background-color: red; */
    overflow: scroll;
    position: relative;
    height: 57vh;
    /* width: 100%; */
  }
  .accodion_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
  }
  .mini-details-carrier-tab-container-bank {
    /* background-color: red; */
    overflow: scroll;
    position: relative;
    height: 51vh;
    /* width: 100%; */
  }
  .bank_container {
    width: 28vw;
    height: 100%;
  }
  .bank_wrapper {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    /* background-color: red; */
  }
  .total_paid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
    height: 10%;
    padding: 0.5rem 0.2rem;
    margin-top: 0.5rem;
    border-radius: 5px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  .bank_headings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2rem 0rem;
  }
  .bank-input {
    margin: 10px 0px;
    width: 90%;
  }
  .bankDetails {
    width: 120%;
    /* padding: .5rem 0rem; */
    /* background-color: blueviolet; */
  }
  .bank_button {
    padding: 0.8rem 3rem;
    width: 90%;
    margin: 2rem auto;
    background-color: ${colorPalette.primaryColor};
    border: none;
    outline: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: ${colorPalette.white};
    cursor: pointer;
  }
  .bank_button_add {
    padding: 0.8rem 1rem;
    width: 100%;
    /* margin-top: -5rem; */
    background-color: ${colorPalette.primaryColor};
    border: none;
    outline: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: ${colorPalette.white};
    cursor: pointer;
  }
  .bank-drawer-row {
    padding: 0.5rem 0rem;
  }
  .attachment {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .attachment_btn {
    border: 1px solid ${colorPalette.primaryColor};
    text-decoration: none;
    padding: 0.3rem 1rem;
    color: ${colorPalette.primaryColor};
    transition: ease all 0.5s;
    display: flex;
    align-items: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  .attachment_btn:hover {
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    transition: ease all 0.5s;
  }
  .mini-details-carrier-img-payments {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #c7c7c7; */
    height: 10%;
  }

  .MuiTabs-indicator {
    background-color: ${colorPalette.primaryColor} !important;
  }
  .mini-details-carrier-single-list {
    margin: 1.5rem 5px;
    display: flex;
    overflow-wrap: hidden;
    /* background-color: rebeccapurple; */
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: ${typography.paragraph} !important;
    font-weight: ${FontWeight.semibold} !important;
  }
  .mini-details-carrier-single-list-left {
    font-size: ${typography.heading5};
    margin: 0px 5px;
    position: relative;
  }
  .mini-details-carrier-single-list-right {
    font-size: ${typography.heading5};
    margin: 0px 5px;
    position: absolute;
    width: 100%;
    color: ${colorPalette.paragraphSecondaryolor};
    left: 45%;
    /* word-wrap: break-word; */
    /* overflow: hidden; */
    /* overflow-wrap: break-word; */
  }
  .payment_details {
    width: 100%;
    margin: 0 auto;
    /* height: 100%; */
    height: 430px;
    overflow: scroll;
  }
  .payments_terms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.5rem;
    margin-bottom: 0.8rem;
    box-shadow: ${colorPalette.boxShadowPrimary};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};

    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .trip_cost_details {
    width: 98%;
    overflow: scroll;
    height: 200px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    margin: 1.5rem auto;
    display: flex;
    background-color: ${colorPalette.secondaryWhite};
    border-radius: 10px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 0.5rem;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .trip_total_headings {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 0rem 0rem 1rem 0rem;
  }
  .trip_breakups {
    display: flex;
    width: 100%;

    padding: 0.5rem 0rem;
    align-items: flex-start;
    flex-direction: column;
  }
  .break_ups {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.3rem 0rem;
    justify-content: space-between;
  }
  .rupees {
    margin-right: 0.5rem;
  }
  @media only screen and (max-width: 480px) {
    .rupees {
      margin-right: 0.5rem;
    }
    /* .mini-details-carrier-card-main-container_payments { */
    width: 100%;
    height: 90vh;
    /* margin: 3% auto; */
    background: ${colorPalette.white};
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 14px;
    /* } */
    .payment_details {
      width: 100%;
      margin: 0 auto;
      height: 100%;
    }
    .bank_container {
      width: 100vw;
      height: 100%;
    }
    .mini-details-carrier-card-main-container {
      width: 96%;
      height: 0%;
      margin: 5% auto;
      background: ${colorPalette.white};
      box-shadow: ${colorPalette.boxShadowPrimary};
      border-radius: 14px;
      margin-left: 1.2rem;
    }
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
      font-size: ${typography.paragraph_secondary} !important;
      font-weight: ${FontWeight.semibold} !important;
    }
    .mini-details-carrier-single-list-left {
      font-size: ${typography.paragraph};
      margin: 0px 5px;
      position: relative;
    }
    .mini-details-carrier-single-list-right {
      font-size: ${typography.paragraph_secondary};
      margin: 0px 5px;
      position: absolute;
      color: ${colorPalette.paragraphSecondaryolor};
      left: 55%;
    }
    .mini-details-carrier-img-payments {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      /* background-color: #c7c7c7; */
      height: 15%;
      /* padding: 2rem; */
    }
  }
`;
