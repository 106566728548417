import { React, useEffect, useState, useRef, useCallback } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CodeIcon from "@mui/icons-material/Code";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import blocked from "../../assets/blocked1.png";
import INFOICON from "../../assets/infoIcon.svg";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Drawer,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import vehcilesDetails from "../../assets/vehicles/vehicle details.svg";
import ApprovalIcon from "@mui/icons-material/Approval";
// import CloseIcon from "@mui/icons-material/Close";
import imageloader from "../../assets/transporter/ZKZg.gif";
import downloadIcons from "../../assets/transporter/download.svg";
import showIcons from "../../assets/transporter/view.svg";
import docIcons from "../../assets/transporter/Group 343.svg";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import modelDetails from "../../assets/vehicles/model.svg";
import ImageIcon from "@mui/icons-material/Image";
import transporterVehicle from "../../assets/vehicles/transporterInactive.svg";
import vehcilesAttachments from "../../assets/vehicles/attachemnt.svg";
import vehcilesDetailsSelected from "../../assets/vehicles/vehicle details(1).svg";
import modelDetailsSelected from "../../assets/vehicles/model(1).svg";
import transporterVehicleSelected from "../../assets/vehicles/transporteractive.svg";
import vehcilesAttachmentsSelected from "../../assets/vehicles/attachemnt(1).svg";
import transporterDetailsSelected from "../../assets/vehicles/vehicle details(1).svg";
import transporterDetails from "../../assets/vehicles/vehicle details.svg";
import transporterBankSelected from "../../assets/transporter/bankactive.svg";
import transporterBank from "../../assets/transporter/bank.svg";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import UploadImage from "../../assets/upload.svg";
import CloseIcon from "@mui/icons-material/Close";
import FeedIcon from "@mui/icons-material/Feed";
import AccordionDetails from "@mui/material/AccordionDetails";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import {
  bankDetailsPost,
  GetBankDetails,
  GetEntityDetails,
  GetBankDetailsById,
  bankDetailsPut,
  bankDetailsPatch,
} from "../carrierMiniCard/CarrierMiniCardServices.js";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import "./commonDrawer.css";
import CopyText from "../copyText/CopyText";
import TripHistoryDetails from ".././../assets/tripIcons/newIcons/trip_history_icon-1.svg";
import TripHistoryDetailsSelected from ".././../assets/tripIcons/newIcons/trip_history_icon(1).svg";
import {
  getCarrierList,
  getCarrierListById,
  handleTransporterIsActive,
  handleTransporterStatusApproved,
  handleTransporterStatusRecommended,
  handleTransporterStatusReject,
} from "../../pages/onboard/OnboardServices";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateCarrierId,
  updateRoutesAddVehicle,
  updateVehicleId,
} from "../../redux/onboardSlice";
import { readLoginData, readToken } from "../../helper/dataDecryptionHelper";
import apiUrl from "../../url/apiUrl";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";
import axiosInterceptor from "../../config/https";
import {
  ButtonRectangle,
  ButtonRectangleSecondary,
  ButtonSecondary,
  ButtonSecondary2,
} from "../../themes/components/button";
import { Bank_Container, Transporter_Drawer } from "./commonDrawer";
import { colorPalette, colorPaletteDark } from "../../themes/theme";
import { useContext } from "react";
import { DarkModeContext } from "../../context/darkModeContext";
import moment from "moment";
import { Mini_detais_Card_style } from "../miniDetailsCard/mini-details-card";
import { FlashAuto } from "@mui/icons-material";
import { isLastDayOfMonth } from "date-fns";
import { BankList } from "../../helper/BankList";
import { MyContext } from "../enumContext/EnumContextComponent.jsx";
import { showOrDownlaodAttachmentsUtility } from "../../utility/FetchImage.js";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ImageWithZoom from "../ImageWithZoom/ImageWithZoom.jsx";
import { imageModalStyle, pdfEmbedStyle } from "../../themes/commonStyle.js";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "50%",
  maxWidht: "60%",
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  boxShadow: 24,
  p: 4,
};
function CommonDrawer({
  commonDrawerOpen,
  handleCloseCommonDrawer,
  carrierDetails,
  clickTab,
  handleSearchFilter,
  nameSearch,
  transporterType,
  filterMobileNumber,
  filterPincode,
  selectedStatus,
  currentPage,
}) {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openTransporter, setOpenTransporter] = useState(false);
  const [openTransporterStatus, setTransporterStatus] = useState(false);
  const [transporterStatusRemarks, setTransporterStatusRemarks] = useState("");
  const [openModelForRejectStatus, setOpenModelForRejectStatus] =
    useState(false);
  const [openBlockModel, setOpenBlockModel] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpenTransporter = () => setOpenTransporter(true);
  const handleCloseImage = () => setOpen(false);
  const handleCloseImageTransporter = () => setOpenTransporter(false);
  const [stateRight, setStateRight] = useState({ right: false });
  const [state, setState] = useState({
    right: false,
  });
  const [carriersData, setCarriersData] = useState([]);

  const [transporterDetailsHeading, setTransporterDetailsHeading] = useState(
    clickTab ? clickTab : "Transporter"
  );
  const [vehiclesDetailsHeading, setVehiclesDetailsHeading] = useState(
    clickTab ? clickTab : "Vehicle"
  );

  {
  }
  // const [transporterAssetsList, setTransporterAssetsList] = useState([]);
  const [value, setValue] = useState(0);
  const [bankAccTypeList, setBankAccTypeList] = useState([]);
  const [driverDetails, setDriverDetails] = useState({});
  const [costDetails, setCostDetails] = useState({});
  const [bankAcNumber, setBankAcNumber] = useState("");
  const [holderName, setHolderName] = useState(carrierDetails?.name);
  const [accType, setAccType] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [ifscCode, setIfscCode] = useState("");
  const [bankDetailsList, setBankDetailsList] = useState([]);
  const [document, setDocument] = useState("");
  const [bankName, setBankName] = useState("");
  const [trueValue, setTrueValue] = useState(false);
  const [falseValue, setFalseValue] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  // const [carriersData, setCarriersData] = useState([]);
  const [entity, setEntity] = useState("");
  const [errorNumber, setErrorNumber] = useState("");
  const [errorBankName, setErrorBankName] = useState("");
  const [errorBankHolder, setErrorBankHolder] = useState("");
  const [errorAcType, setErrorAcType] = useState("");
  const [errorIfsc, setErrorIfsc] = useState("");
  const [errorEntity, setErrorEntity] = useState("");
  const [errorPostBankDetails, setErrorPostBankDetails] = useState("");
  const [errorIsPrimary, setErrorIsPrimary] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [id, setId] = useState("");
  const [transporterAssetsList, setTransporterAssetsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [transporterHistory, setTransporterHistory] = useState([]);
  const [flag, setFlag] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [transporterAttachmentPdf, setTransporterAttachmentPdf] = useState("");
  const [transporterAttachmentImage, setTransporterAttachmentImage] =
    useState("");
  const [isInputValid, setIsInputValid] = useState(false);
  const { enums } = useContext(MyContext);

  const getAccType = async () => {
    // var address = await axiosInterceptor.get(
    //   `${apiUrl.onboardingBaseUrl}/v1/enums/bank-account-type`
    // );

    // setBankAccTypeList(address.data);
    setBankAccTypeList(enums?.bank_account_type ? enums.bank_account_type : []);
  };
  useEffect(() => {
    getAccType();
  }, [enums]);
  const toggleCommonDrawer = (anchor, open) => {
    setStateRight({ ...stateRight, [anchor]: open });
  };
  const handleTransporterDetails = (head) => {
    if (Object.keys(carrierDetails).length > 0) {
      getCarrierData(carrierDetails.id);
    }
    setTransporterDetailsHeading(head);
  };
  const handleBankDetails = (head) => {
    setTransporterDetailsHeading(head);
  };
  const handleVehicleDetails = (head) => {
    // if (Object.keys(carrierDetails).length > 0) {
    //   getCarrierData(carrierDetails.transporter_id);
    // }
    setVehiclesDetailsHeading(head);
  };

  // useEffect(() => {
  //   handleTransporterDetails(clickTab);
  // }, [clickTab]);
  useEffect(() => {
    // getAssetsList(carrierDetails?.id);
    if (carrierDetails.vehicle_manufacturer_name) {
      handleVehicleDetails("Vehicle");
    } else {
      handleTransporterDetails("Transporter");
    }
  }, [commonDrawerOpen]);
  const handleModelDetails = (head) => {
    // increment(head);
    // deIncrement(head);

    setVehiclesDetailsHeading(head);
  };
  const handleVehicleAttachmentsDetails = (head) => {
    // increment(head);
    // deIncrement(head);
    getAssetsList(carrierDetails.id);
    setVehiclesDetailsHeading(head);
  };

  const handleTransporterVehicleDetails = (head) => {
    // increment(head);
    // deIncrement(head);

    setVehiclesDetailsHeading(head);
  };

  const handleAttachmentsDetails = (head) => {
    // increment(head);
    // deIncrement(head);
    getAssetsList(carrierDetails.id);
    setTransporterDetailsHeading(head);
  };

  const getCarrierData = async (id) => {
    // setLoadingMore(true);
    const carrierResponse = await getCarrierListById(id);
    setCarriersData(carrierResponse);
    setTransporterHistory(carrierDetails.transporter_history);
  };
  // useEffect(() => {
  //   if (Object.keys(carrierDetails).length > 0) {
  //     getCarrierData(carrierDetails.id);
  //   }
  // }, [carrierDetails.id]);
  const handleAddVehicle = (id) => {
    history.push(`/vehicles-onboard`);
    dispatch(updateCarrierId(id));
    dispatch(updateRoutesAddVehicle(""));
  };
  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };
  const getBankDetailsById = async (e, bankId) => {
    e.stopPropagation();
    //
    const response = await GetBankDetailsById(bankId);
    if (response.status == 401) {
      return toast.error("401: Not enough permissions");
    }
    if (Object.keys(response).length > 0) {
      setEditMode(true);
      openDrawer();
      setBankAcNumber(response.bank_account_number);
      setHolderName(response.account_holder_name);
      setAccType(response.bank_account_type);
      setId(response.id);
      // setDocument(response.bank_account_number);
      setBankName(response.bank_name);
      setTrueValue(response.is_primary);
      setIfscCode(response.ifsc_code);
      setEntity(response.entity_type);
    }
  };

  useEffect(() => {
    document?.type === "application/pdf"
      ? setIsInputValid(false)
      : setIsInputValid(true);
  }, [document]);

  const handleBankDetailsPost = async () => {
    setLoadingButton(true);
    const isValid = formValidation();
    if (isValid) {
      if (editMode == true) {
        const responseDataEdit = await bankDetailsPut(
          bankAcNumber,
          bankName,
          carrierDetails?.name,
          accType,
          ifscCode,
          carriersData.id,
          "transporter",
          false,
          // trueValue,
          document,
          id
        );
        if (responseDataEdit == 200) {
          const patchResponse = await bankDetailsPatch(id, trueValue);

          if (Object.keys(patchResponse).length > 0) {
            getCarrierData(carrierDetails.id);
            handleClose();
            setLoadingButton(false);
          }
          setLoadingButton(false);
          getCarrierData(carrierDetails.id);
          handleClose();
          setBankAcNumber("");
          setHolderName("");
          setAccType("");
          setDocument("");
          setBankName("");
          setTrueValue("");
          setIfscCode("");
          setEntity("");
          // setDocument({});
        }
        if (responseDataEdit.status == 401) {
          setErrorPostBankDetails(responseDataEdit.data.error);
        }
        if (responseDataEdit.status == 400) {
          setErrorPostBankDetails(responseDataEdit.data.detail);
        }
      } else {
        const responseData = await bankDetailsPost(
          bankAcNumber,
          bankName,
          carrierDetails?.name,
          accType,
          ifscCode,
          carriersData.id,
          "transporter",
          false,
          trueValue,
          document
        );
        if (responseData == 200) {
          const patchResponse = bankDetailsPatch(id, trueValue);

          if (Object.keys(patchResponse).length > 0) {
            getCarrierData(carrierDetails.id);
          }
          setLoadingButton(false);
          getCarrierData(carrierDetails.id);
          handleClose();
          setBankAcNumber("");
          setHolderName("");
          setAccType("");
          setDocument("");
          setBankName("");
          setTrueValue("");
          setIfscCode("");
          setEntity("");
          setErrorPostBankDetails("");
          // setDocument({});
        }
        if (responseData.status == 401) {
          setErrorPostBankDetails(responseData.data.error);
        }
        if (responseData.status == 400) {
          setErrorPostBankDetails(responseData.data.detail);
        }
      }

      setLoadingButton(false);
    }
    setLoadingButton(false);
  };
  const openDrawer = () => {
    toggleDrawer("right", true);
  };
  const handleClose = () => {
    toggleDrawer("right", false);
    setErrorPostBankDetails("");
    setEditMode(false);
    setBankAcNumber("");
    setHolderName("");
    setAccType("");
    setDocument("");
    setBankName("");
    setTrueValue("");
    setIfscCode("");
    setEntity("");
    // setDocument({});
    setErrorNumber("");
    setErrorBankName("");
    setErrorBankHolder("");
    setErrorAcType("");
    setErrorIfsc("");
    setErrorEntity("");
    setErrorIsPrimary("");
  };

  const validateField = (field) => {
    if (field.target.name === "bank_acc_number") {
      const bankAccountRegex = /^[0-9]{9,18}$/;
      if (!bankAccountRegex.test(field.target.value)) {
        if (field.target.value.length <= 18)
          setErrorNumber({
            ...errorNumber,
            bankError: "Minimum 9 and maximum 18 digits are allowed.",
          });
        setIsInputValid(false);
      } else {
        setIsInputValid(true);
      }
    }
    if (field.target.name === "ifsc") {
      const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
      if (!ifscRegex.test(field.target.value)) {
        // if (field.target.value.length <= 11)
        setErrorIfsc({
          ...errorIfsc,
          ifscError: "Please Enter Valid IFSC (AAAA0123456)",
        });
        setIsInputValid(false);
      } else {
        setIsInputValid(true);
      }
    }
  };

  const formValidation = () => {
    const errorbankAcNumber = {};
    const errorbankName = {};
    const errorholderName = {};
    const erroraccType = {};
    const errorifscCode = {};
    const errorentity = {};
    const errortrueValue = {};
    let isValid = true;
    if (bankAcNumber == "") {
      errorbankAcNumber.errorbankNumber = "A/c Number is mandatory.";
      isValid = false;
    }
    if (bankName == "") {
      errorbankName.errorBankName = "Bank Name is mandatory.";
      isValid = false;
    }
    // const bankRegex = /^[A-Za-z\s]+$/;
    // if (bankName == "") {
    //   errorBankName.errorBankName = "Bank Name is mandatory";
    //   isValid = false;
    // } else if (!bankRegex.test(bankName)) {
    //   errorBankName.errorBankName = "Please input alphabet characters only";
    //   isValid = false;
    // }
    if (carrierDetails.name == "") {
      errorholderName.errorbankHolder = "Holder Name is mandatory.";
      isValid = false;
    }
    if (accType == "") {
      erroraccType.errorbankType = "A/c Type is mandatory.";
      isValid = false;
    }
    if (ifscCode == "") {
      errorifscCode.errorbankIfsc = "Ifsc Code is mandatory.";
      isValid = false;
    }

    // if (entity == "") {
    //   errorentity.errorEntity = "Entity Type is manadatory";
    //   isValid = false;
    // }
    // if (trueValue == "") {
    //   errortrueValue.errorTrueValue = "field is manadatory";
    //   isValid = false;
    // }
    setErrorNumber(errorbankAcNumber);
    setErrorBankName(errorbankName);
    setErrorBankHolder(errorholderName);
    setErrorAcType(erroraccType);
    setErrorIfsc(errorifscCode);
    setErrorEntity(errorentity);
    setErrorIsPrimary(errortrueValue);
    return isValid;
  };
  const handleDocument = (event) => {
    // setTripAttachmentFile(event.target.files);
    setDocument(event.target.files[0]);
    // setErrorPhoto({});
    // setErrorPhoto("");
  };
  let tokensData = readToken();

  const download = async (id) => {
    fetch(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/transporter-agreement/${prefixSalt()}-${convertToBase64(id)}`,
      // { responseType: "application/pdf" },
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
          // Accept: "application/pdf",
        },
      }
    )
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else if (response.status === 401) {
          return toast.error("401: Not enough permissions");
        }
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = window.document.createElement("a");
          link.href = url;
          // link.setAttribute("download", "file.pdf");
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);
          link.download = "document.pdf";
          link.click();
        });
        if (response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      })
      .catch((err) => {});
  };
  const showDownload = async (id) => {
    setLoadingImage(true);
    // fetch(
    //   `${
    //     apiUrl.onboardingBaseUrl
    //   }/v1/image/transporter-agreement/${prefixSalt()}-${convertToBase64(id)}`,
    //   // { responseType: "application/pdf" },
    //   {
    //     method: "GET",
    //     headers: {
    //       Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
    //       "x-app-token": `${tokensData}`,
    //       Accept: "application/pdf",
    //     },
    //   }
    // )
    //   .then((res) => {
    //     if (res.status === 401) {
    //       return toast.error("401: Not enough permissions");
    //     }
    //     return res.blob();
    //   })
    //   .then((blob) => {
    //     setLoadingImage(false);
    //     var file = window.URL.createObjectURL(blob);
    //     setTransporterPdfUrl(file);
    //     setTransporterImageUrl("");
    //     setTransporterAttachmentPdf("");
    //     setTransporterAttachmentImage("");
    //   })
    //   .catch((err) => {
    //     setLoadingImage(false);
    // });

    try {
      setLoadingImage(true);
      const response = await showOrDownlaodAttachmentsUtility(
        id,
        "transporter-agreement"
      );
      if (response.status === 401) {
        return toast.error("401: Not enough permissions");
      } else if (
        response.file &&
        (response.attachmentType === "image/png" ||
          response.attachmentType === "image/jpeg")
      ) {
        setTransporterPdfUrl(null);
        setTransporterImageUrl(response.file);
      } else {
        setTransporterImageUrl(null);
        setTransporterAttachmentPdf(null);
        setTransporterAttachmentImage(null);
        setTransporterPdfUrl(response.file);
      }
    } catch (error) {
    } finally {
      setLoadingImage(false);
    }
  };

  const downloadVehicleAttachments = async (id) => {
    fetch(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/vehicle-attachments/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
          "Content-Type": "image/jpeg",
        },
      }
    )
      .then((response) => {
        if (response.status === 403) {
          localStorage.clear();
          window.location.reload();
        } else if (response.status === 401) {
          return toast.error("401: Not enough permission");
        }
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = window.document.createElement("a");
          link.href = url;
          link.download = "image.png";
          link.click();
        });
      })
      .catch((err) => {});
  };
  const [imageUrl, setImageUrl] = useState("");
  const [blobPdfUrl, setBlobPdfUrl] = useState("");

  const showVehicleAttachments = async (id) => {
    setLoadingImage(true);
    try {
      setLoadingImage(true);
      const { file, attachmentType } = await showOrDownlaodAttachmentsUtility(
        id,
        "vehicle-attachments"
      );
      if (
        file &&
        (attachmentType === "image/png" || attachmentType === "image/jpeg")
      ) {
        setBlobPdfUrl(null);
        setImageUrl(file);
      } else {
        setBlobPdfUrl(file);
        setImageUrl(null);
      }
    } catch (error) {
      setBlobPdfUrl(null);
      setImageUrl(INFOICON);
    } finally {
      setLoadingImage(false);
    }
  };

  const getAssetsList = async (id) => {
    setIsLoading(true);
    const response = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/transporter/${prefixSalt()}-${convertToBase64(id)}/assets`
    );

    if (response.status === 200) {
      setTransporterAssetsList(response.data.transporter_assets_list);
      setIsLoading(false);
    }
  };

  const handleTransporterStatus = async (status) => {
    var response;
    if (status === "rejected") {
      setFlag2(true);
    } else {
      setFlag(true);
    }
    if (status === "recommended") {
      response = await handleTransporterStatusRecommended(
        carrierDetails.id,
        transporterStatusRemarks
      );
    } else if (status === "approved") {
      response = await handleTransporterStatusApproved(
        carrierDetails.id,
        transporterStatusRemarks
      );
    } else if (status === "rejected") {
      response = await handleTransporterStatusReject(
        carrierDetails.id,
        transporterStatusRemarks
      );
    } else if (status === "blocked") {
      response = await handleTransporterIsActive(
        carrierDetails.id,
        transporterStatusRemarks
      );
    }

    if (response.status === 200) {
      setTransporterStatus(false);
      setFlag(false);
      setFlag2(false);
      toast.success(`Status updated to ${status}!`);
      handleCloseCommonDrawer();
      setOpenBlockModel(false);
      setTransporterStatusRemarks("");
      const resp = await getCarrierList(1);
      handleSearchFilter(
        nameSearch,
        transporterType,
        filterMobileNumber,
        "",
        filterPincode,
        selectedStatus,
        currentPage
      );
    } else if (response.response.status === 401) {
      setFlag(false);
      setFlag2(false);
      toast.error("401: Not enough permissions");
      handleCloseCommonDrawer();
      setOpenBlockModel(false);
      setTransporterStatusRemarks("");
    } else {
      setFlag(false);
      setFlag2(false);
      toast.error("Status updation failed");
      handleCloseCommonDrawer();
      setOpenBlockModel(false);
      setTransporterStatusRemarks("");
    }
  };

  const downloadAssets = async (id, assetsId) => {
    try {
      const response = await showOrDownlaodAttachmentsUtility(
        assetsId,
        "transporter-assets",
        "downloadOnly"
      );
      if (response.status === 401) {
        toast.error("401: Not enough permissions");
      }

      console.log(response, "donwlindkjk fkreson");
    } catch (error) {}
  };
  const [transporterimageUrl, setTransporterImageUrl] = useState("");
  const [transporterPdfUrl, setTransporterPdfUrl] = useState("");
  const [assetsMediaType, setAssetsMediaType] = useState({});

  const showTransporterAttachments = (id, assetsId) => {
    setTransporterAttachmentImage("");
    setTransporterPdfUrl("");
    setTransporterAttachmentPdf("");
    setLoadingImage(true);
    fetch(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/transporter-assets/${prefixSalt()}-${convertToBase64(
        assetsId
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 401) {
          return toast.error("401: Not enough permissions");
        }
        setAssetsMediaType({
          ...assetsMediaType,
          [assetsId]: res.headers.get("content-type"),
        });
        return res.blob();
      })
      .then((blob) => {
        setLoadingImage(false);

        var file = window.URL.createObjectURL(blob);
        if (assetsMediaType[assetsId] === "application/pdf") {
          console.log(assetsMediaType[assetsId], "assetsMediaType");
          setTransporterAttachmentPdf(file);
          return;
        } else {
          setTransporterAttachmentImage(file);
          return;
        }
      });
  };
  const handleCarrierId = (carrieerId) => {
    dispatch(updateCarrierId(carrieerId));
  };

  const showTransporterBankAttachments = (assetsId) => {
    setLoadingImage(true);
    fetch(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/bank-details/${prefixSalt()}-${convertToBase64(assetsId)}`,
      // { responseType: "application/pdf" },
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setLoadingImage(false);
        var file = window.URL.createObjectURL(blob);
        setTransporterPdfUrl("");
        setTransporterAttachmentImage("");
        setTransporterAttachmentPdf("");
        setTransporterImageUrl(file);
      })
      .catch((err) => {
        setLoadingImage(false);
      });
  };
  const downloadBankAssets = async (assetsId) => {
    fetch(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/bank-details/${prefixSalt()}-${convertToBase64(assetsId)}`,
      // { responseType: "application/pdf" },
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
          "Content-Type": "image/jpeg",
          // Accept: "application/pdf",
        },
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = window.document.createElement("a");
          link.href = url;
          link.download = "image.png";
          link.click();
        });
        if (response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      })
      .catch((err) => {});
  };
  // useEffect(() => {
  //   getAssetsList(carrierDetails.id);
  // }, [carrierDetails.id]);
  const handleVehicleId = (id, carrier) => {
    dispatch(updateVehicleId({ id: id, carrier: carrier }));
    history.push(`/vehicles-onboard`);
  };
  function makeTransporterTypeReadable() {
    let transporterTypeText = carrierDetails?.transporter_type;
    if (transporterTypeText)
      transporterTypeText = transporterTypeText?.split("_").join(" ");
    return transporterTypeText;
  }
  function capitalizeLetter(str) {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags;
  }
  // function capitalizeLetter(str) {
  //   var i,
  //     frags = str.split(" ");
  //   for (i = 0; i < frags.length; i++) {
  //     frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  //   }
  //   return frags;
  // }
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  if (carrierDetails.vehicle_manufacturer_name) {
    return (
      <div>
        <ToastContainer />
        <Modal
          open={open}
          onClose={handleCloseImage}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={imageModalStyle}
            style={{
              height: isMobile ? "50vh" : "",
              width: isMobile ? "90vw" : "",
            }}
          >
            {loadingImage ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                  // height: isMobile ? "100vh" : "100%",
                }}
              >
                <img src={imageloader} alt="" width="20%" height="20%" />
                <div>Loading...</div>
              </div>
            ) : imageUrl ? (
              <ImageWithZoom image={imageUrl} />
            ) : (
              <embed
                src={blobPdfUrl}
                type="application/pdf"
                style={pdfEmbedStyle}
              ></embed>
            )}
          </Box>
        </Modal>
        <Drawer
          anchor={"right"}
          open={commonDrawerOpen}
          onClose={() => {
            handleCloseCommonDrawer();
          }}
          PaperProps={{
            sx: {
              width: isMobile ? 380 : 400,
            },
          }}
        >
          <Transporter_Drawer className="transporter_drawer" mode={darkMode}>
            <div
              className="transporter_drawer_headings"
              style={{ flexDirection: "row" }}
            >
              {vehiclesDetailsHeading}
              <ButtonRectangleSecondary
                // className="trip-list-edit-btn_v2"
                // style={{ padding: ".2rem .5rem" }}
                onClick={() =>
                  handleVehicleId(
                    carrierDetails.id,
                    carrierDetails.transporter_id
                  )
                }
                // onClick={() => handleDeliveryInvoicePayment(trip)}
              >
                <EditIcon style={{ fontSize: "15px" }} />
                Edit
              </ButtonRectangleSecondary>
              <CloseIcon
                onClick={() => {
                  handleCloseCommonDrawer();
                }}
                style={{
                  cursor: "pointer",
                  color: darkMode ? colorPaletteDark.white : colorPalette.black,
                }}
              />{" "}
            </div>

            <div className="transporter_drawer_content_container">
              <div className="transporter_drawer_content">
                {vehiclesDetailsHeading == "Vehicle" ? (
                  <div className="mini-details-carrier-tab-container_drawer">
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Vehicle Number
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :
                        {carrierDetails.vehicle_number ? (
                          <CopyText text={carrierDetails.vehicle_number} />
                        ) : (
                          "-"
                        )}
                      </h4>{" "}
                    </div>
                    {carrierDetails.is_national_permit == false ? null : (
                      <div className="mini-details-carrier-single-list">
                        <h4 className="mini-details-carrier-single-list-left_drawer">
                          National Permit
                        </h4>
                        <h4 className="mini-details-carrier-single-list-right_drawer">
                          :
                          {carrierDetails.is_national_permit == true
                            ? "Yes"
                            : "No"}
                        </h4>
                      </div>
                    )}

                    {carrierDetails.is_national_permit == true ? null : (
                      <div className="mini-details-carrier-single-list">
                        <h4 className="mini-details-carrier-single-list-left_drawer">
                          State Permit
                        </h4>
                        <h4 className="mini-details-carrier-single-list-right_drawer">
                          :{" "}
                          {carrierDetails.is_state_permit == true
                            ? "Yes"
                            : "No"}
                        </h4>{" "}
                      </div>
                    )}

                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Permit Number
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carrierDetails.permit_number ? (
                          <CopyText text={carrierDetails.permit_number} />
                        ) : (
                          "-"
                        )}
                      </h4>{" "}
                    </div>
                    {carrierDetails.is_national_permit == true ? null : (
                      <div className="mini-details-carrier-single-list">
                        <h4 className="mini-details-carrier-single-list-left_drawer">
                          Permitted States
                        </h4>
                        <h4 className="mini-details-carrier-single-list-right_drawer">
                          :{" "}
                          {carrierDetails.permitted_states
                            ? carrierDetails.permitted_states.toUpperCase()
                            : "-"}
                        </h4>{" "}
                      </div>
                    )}

                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Permit Expires On
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carrierDetails.permit_expiry_date
                          ? carrierDetails.permit_expiry_date.slice(0, 10)
                          : "-"}
                      </h4>
                    </div>
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Pollution Expires On
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carrierDetails.pollution_control_certificate_expiry_date
                          ? carrierDetails.pollution_control_certificate_expiry_date.slice(
                              0,
                              10
                            )
                          : "-"}
                      </h4>
                    </div>
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        RC Expires On
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carrierDetails.registration_certificate_expiry_date
                          ? carrierDetails.registration_certificate_expiry_date.slice(
                              0,
                              10
                            )
                          : "-"}
                      </h4>
                    </div>
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Insurance Expires On
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carrierDetails.insurance_certificate_expiry_date
                          ? carrierDetails.insurance_certificate_expiry_date.slice(
                              0,
                              10
                            )
                          : "-"}
                      </h4>
                    </div>
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Freight Type
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carrierDetails.freight_type
                          ? carrierDetails.freight_type
                              .split("_")
                              .join(" ")
                              .toUpperCase()
                          : "-"}
                      </h4>
                    </div>
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Status
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {capitalizeLetter(
                          carrierDetails.status ? carrierDetails.status : "-"
                        )}
                        {/* {carrierDetails.status
                          ? carrierDetails.status.toUpperCase()
                          : "-"} */}
                      </h4>
                    </div>
                    {carrierDetails.payout_period == "monthly_wise" ? (
                      <div
                        style={{
                          border: "1px solid black",
                          boxShadow: colorPalette.boxShadowPrimary,
                          borderRadius: "10px",
                          padding: "5px 0px",
                          margin: "10px 5px",
                          // width: "9%",
                          backgroundColor: `${
                            darkMode ? colorPaletteDark.lightGreen : "#CCE5CC"
                          }`,
                        }}
                      >
                        <div className="mini-details-carrier-single-list">
                          <h4 className="mini-details-carrier-single-list-left_drawer">
                            Payout Period
                          </h4>
                          <h4 className="mini-details-carrier-single-list-right_drawer">
                            :{" "}
                            {carrierDetails.payout_period
                              ? carrierDetails.payout_period
                                  .split("_")
                                  .join(" ")
                                  .toUpperCase()
                              : "-"}
                          </h4>
                        </div>
                        <div className="mini-details-carrier-single-list">
                          <h4 className="mini-details-carrier-single-list-left_drawer">
                            Extra Per Km Rate
                          </h4>
                          <h4 className="mini-details-carrier-single-list-right_drawer">
                            :{" "}
                            {carrierDetails.extra_per_km_rate
                              ? carrierDetails.extra_per_km_rate
                              : "-"}
                          </h4>
                        </div>
                        <div className="mini-details-carrier-single-list">
                          <h4 className="mini-details-carrier-single-list-left_drawer">
                            Fixed Kms
                          </h4>
                          <h4 className="mini-details-carrier-single-list-right_drawer">
                            :{" "}
                            {carrierDetails.fixed_kms
                              ? carrierDetails.fixed_kms
                              : "-"}
                          </h4>
                        </div>
                        <div className="mini-details-carrier-single-list">
                          <h4 className="mini-details-carrier-single-list-left_drawer">
                            Payout Amount
                          </h4>
                          <h4 className="mini-details-carrier-single-list-right_drawer">
                            :{" "}
                            {carrierDetails.basic_payout_amount
                              ? carrierDetails.basic_payout_amount
                              : "-"}
                          </h4>
                        </div>
                        <div className="mini-details-carrier-single-list">
                          <h4 className="mini-details-carrier-single-list-left_drawer">
                            Cut Off Day Of Month
                          </h4>
                          <h4 className="mini-details-carrier-single-list-right_drawer">
                            :{" "}
                            {carrierDetails.payout_cutoff_day_of_month
                              ? carrierDetails.payout_cutoff_day_of_month
                              : "-"}
                          </h4>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : vehiclesDetailsHeading == "Model" ? (
                  <div className="mini-details-carrier-tab-container_drawer">
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Manufacturer
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :
                        {carrierDetails.vehicle_manufacturer_name
                          ? carrierDetails.vehicle_manufacturer_name.toUpperCase()
                          : null}
                      </h4>{" "}
                    </div>

                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Vehicle Model
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :
                        {carrierDetails.vehicle_model_list
                          ? carrierDetails.vehicle_model_list.name.toUpperCase()
                          : null}
                      </h4>
                    </div>
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Vehicle Type
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carrierDetails.vehicle_model_list
                          ? carrierDetails.vehicle_model_list.vehicle_type.toUpperCase()
                          : null}
                      </h4>{" "}
                    </div>
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Vehicle Class
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carrierDetails.vehicle_model_list
                          ? carrierDetails.vehicle_model_list.class_model
                              .split("_")
                              .join(" ")
                              .toUpperCase()
                          : null}
                      </h4>{" "}
                    </div>
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Max Load Cap.
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carrierDetails.vehicle_model_list
                          ? carrierDetails.vehicle_model_list
                              .maximum_load_capacity
                          : null}{" "}
                        Ton
                      </h4>{" "}
                    </div>
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Normal Load Cap.
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carrierDetails.vehicle_model_list
                          ? carrierDetails.vehicle_model_list
                              .normal_load_capacity
                          : null}{" "}
                        Ton
                      </h4>
                    </div>
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Model No.
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carrierDetails.vehicle_model_list
                          ? carrierDetails.vehicle_model_list.model_number
                          : null}
                      </h4>
                    </div>
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Wheels
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carrierDetails.vehicle_model_list
                          ? carrierDetails.vehicle_model_list.wheels
                          : null}
                      </h4>
                    </div>
                  </div>
                ) : vehiclesDetailsHeading == "Vehicle Attachments" ? (
                  <>
                    {carrierDetails.vehicle_attachments_list.map((data) => (
                      <div className="mini-details-single-attachment_commondrawer">
                        <ul
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            // padding: "0rem .5rem",
                            justifyContent: "space-between",
                          }}
                          // id="transporter_type"
                        >
                          <li
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              fontWeight: "500",
                              fontSize: "10px",
                            }}
                          >
                            <div
                              style={{
                                // width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: darkMode
                                  ? colorPaletteDark.white
                                  : colorPalette.black,
                              }}
                            >
                              <img
                                src={docIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{ marginRight: "10px" }}
                              />
                              {data.document_type
                                ? data.document_type
                                    .split("_")
                                    .join(" ")
                                    .toUpperCase()
                                : null}
                            </div>
                            <div className="image_button_container">
                              {/* <ImageIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpen();
                                  showVehicleAttachments(data.id);
                                }}
                              /> */}
                              <img
                                src={showIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpen();
                                  showVehicleAttachments(data.id);
                                }}
                              />
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  downloadVehicleAttachments(data.id)
                                }
                                className="attachment_btn_commonDrawer"
                                // style={{ fontSize: "10px" }}
                                //   className="attachment_btn"
                                // target="_blank"
                                // download
                                style={{ fontSize: "12px" }}
                              >
                                {/* <Button >Open modal</Button> */}{" "}
                                {/* <SimCardDownloadIcon
                                 
                                />{" "} */}
                                <img
                                  src={downloadIcons}
                                  alt=""
                                  width="35px"
                                  height="35px"
                                />
                                {/* {assets?.type == "pan"
                                ? "Pan Card"
                                : assets?.type == "signature"
                                ? "Signature"
                                : assets?.type == "aadhaar_front"
                                ? "Aadhaar Card Front Side"
                                : assets?.type == "aadhaar_back"
                                ? "Aadhaar Card Back Side"
                                : ""} */}
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
              <div className="transporter_drawer_tabs">
                <ul className="icons_container">
                  {vehiclesDetailsHeading == "Vehicle" ? (
                    <li
                      className="icons_list"
                      style={{
                        borderRight: `5px solid  ${colorPalette.primaryCodeColor}`,
                      }}
                      onClick={() => handleVehicleDetails("Vehicle")}
                    >
                      <img
                        src={vehcilesDetailsSelected}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />
                      {/* <LocalShippingOutlinedIcon style={{ color: "#46ceab" }} /> */}
                    </li>
                  ) : (
                    <li
                      className="icons_list"
                      onClick={() => handleVehicleDetails("Vehicle")}
                    >
                      <img
                        src={vehcilesDetails}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />
                    </li>
                  )}
                  {vehiclesDetailsHeading == "Model" ? (
                    <li
                      className="icons_list"
                      style={{
                        borderRight: `5px solid  ${colorPalette.primaryCodeColor}`,
                      }}
                      onClick={() => handleModelDetails("Model")}
                    >
                      <img
                        src={modelDetailsSelected}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />
                    </li>
                  ) : (
                    <li
                      className="icons_list"
                      onClick={() => handleModelDetails("Model")}
                    >
                      <img
                        src={modelDetails}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />
                    </li>
                  )}

                  {vehiclesDetailsHeading == "Vehicle Attachments" ? (
                    <li
                      className="icons_list"
                      style={{
                        borderRight: `5px solid  ${colorPalette.primaryCodeColor}`,
                      }}
                      onClick={() =>
                        handleVehicleAttachmentsDetails("Vehicle Attachments")
                      }
                    >
                      {" "}
                      <img
                        src={vehcilesAttachmentsSelected}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />
                    </li>
                  ) : (
                    <li
                      className="icons_list"
                      onClick={() =>
                        handleVehicleAttachmentsDetails("Vehicle Attachments")
                      }
                    >
                      <img
                        src={vehcilesAttachments}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </Transporter_Drawer>
        </Drawer>
      </div>
    );
  } else {
    return (
      <div>
        <ToastContainer />
        <Modal
          open={openTransporter}
          onClose={handleCloseImageTransporter}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={imageModalStyle}
            style={{
              height: isMobile ? "50vh" : "",
              width: isMobile ? "90vw" : "",
            }}
          >
            {loadingImage ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <img src={imageloader} alt="" width="30%" height="30%" />
                <div>Loading...</div>
              </div>
            ) : (
              <>
                {transporterAttachmentPdf ? (
                  <embed
                    src={transporterAttachmentPdf}
                    type="application/pdf"
                    style={pdfEmbedStyle}
                  ></embed>
                ) : transporterAttachmentImage ? (
                  <ImageWithZoom image={transporterAttachmentImage} />
                ) : transporterPdfUrl ? (
                  <embed
                    src={transporterPdfUrl}
                    type="application/pdf"
                    style={pdfEmbedStyle}
                  ></embed>
                ) : transporterimageUrl ? (
                  <img
                    src={transporterimageUrl}
                    alt=""
                    style={{
                      maxWidth: "70vw",
                      maxHeight: "80vh",
                    }}
                  />
                ) : null}
              </>
            )}
          </Box>
        </Modal>

        <Modal
          open={openTransporterStatus}
          onClose={() => setTransporterStatus(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            style={{
              height: isMobile ? "50vh" : "",
              width: isMobile ? "90vw" : "",
              overflowY: isMobile ? "scroll" : "hidden",
              paddingBottom: isMobile ? "80px" : "",
              backgroundColor: darkMode ? "black" : "white",
            }}
          >
            <div className="modalCrossDiv">
              <CloseIcon onClick={() => setTransporterStatus(false)} />
            </div>
            <h3 style={{ marginBottom: "20px", color: "#076b38" }}>
              Transporter Status
            </h3>
            <div className="dialogBox">
              <div
                style={
                  isMobile
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }
                    : null
                }
                className="subDialogBox"
              >
                <div className="subDialogBoxChild">
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Name</span> :{" "}
                    {carrierDetails?.name}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Code</span> :{" "}
                    {carrierDetails?.transporter_code}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Status</span> :{" "}
                    {carrierDetails?.status}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Transporter Type</span> :{" "}
                    {makeTransporterTypeReadable()}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Mobile number</span> :{" "}
                    {carrierDetails?.mobile}
                  </h4>
                </div>
                <div className="subDialogBoxChild">
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Email</span> :{" "}
                    {carrierDetails?.email}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">GSTIN</span> :{" "}
                    {carrierDetails?.gstin_number}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Aadhaar</span> :{" "}
                    {carrierDetails?.aadhar_number}
                  </h4>
                  <h4 style={{ marginBottom: "15px" }}>
                    <span className="greyText">Pan</span> :{" "}
                    {carrierDetails?.pan_number}
                  </h4>
                </div>
              </div>

              <h4 style={{ color: "#076b38" }}>Attachments :</h4>

              <div
                style={
                  isMobile
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }
                    : null
                }
                className="subDialogBox2"
              >
                <div className="subDialogBoxChild">
                  <div className="mini-details-single-attachment_commondrawer">
                    <ul
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        padding: "0rem .2rem",
                        justifyContent: "space-between",
                      }}
                      id="transporter_type"
                    >
                      <li
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          fontWeight: "400",
                          fontFamily: "poppins",
                          fontSize: "12px",
                          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        }}
                      >
                        <div
                          className="image_button_container"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            color: `${darkMode ? colorPaletteDark.white : ""}`,
                          }}
                        >
                          <img
                            src={docIcons}
                            alt=""
                            width="35px"
                            height="35px"
                            style={{ cursor: "pointer", marginRight: "10px" }}
                          />
                          Service Agreement
                        </div>

                        <div className="image_button_container">
                          <img
                            src={showIcons}
                            alt=""
                            width="35px"
                            height="35px"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleOpenTransporter();
                              // handleOpenTransporter();
                              // handleOpen();
                              showDownload(carriersData.id);
                            }}
                          />
                          <img
                            src={downloadIcons}
                            alt=""
                            onClick={() => download(carriersData.id)}
                            width="35px"
                            height="35px"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  {transporterAssetsList.map((assets, index) =>
                    index <= transporterAssetsList.length / 2 - 1 ? (
                      <div className="mini-details-single-attachment_commondrawer">
                        <ul
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            padding: "0rem .2rem",

                            justifyContent: "space-between",
                          }}
                          id="transporter_type"
                        >
                          <li
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              fontWeight: "400",
                              fontSize: "12px",
                              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                color: `${
                                  darkMode ? colorPaletteDark.white : ""
                                }`,
                              }}
                            >
                              <img
                                src={docIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                              />
                              {assets?.type == "pan"
                                ? "Pan Card"
                                : assets?.type == "signature"
                                ? "Signature"
                                : assets?.type == "aadhaar_front"
                                ? "Aadhaar Card Front Side"
                                : assets?.type == "aadhaar_back"
                                ? "Aadhaar Card Back Side"
                                : assets?.type ==
                                  "below_10_vehicles_self_declaration"
                                ? "Attachment for less than 10 vehicles "
                                : assets?.type == "cancel_cheque"
                                ? "Cancel Cheque"
                                : assets?.type == "gst_registration_certificate"
                                ? "GST Registration Certificate"
                                : assets?.type ==
                                  "non_gst_registration_self_declaration"
                                ? "Non GST Self Declaration Certificate"
                                : assets?.type == "msme_certificate"
                                ? "MSME Certificate"
                                : ""}
                            </div>
                            <div className="image_button_container">
                              <img
                                src={showIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenTransporter();
                                  // handleOpen();
                                  showTransporterAttachments(
                                    assets?.transporters_id,
                                    assets?.id
                                  );
                                }}
                              />
                              {/* <ImageIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenTransporter();
                                  // handleOpen();
                                  showTransporterAttachments(
                                    assets?.transporters_id,
                                    assets?.id
                                  );
                                }}
                              /> */}
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  downloadAssets(
                                    assets?.transporters_id,
                                    assets?.id
                                  )
                                }
                                className="attachment_btn_commonDrawer"
                                // target="_blank"
                                // download
                                style={{ fontSize: "10px", width: "100%" }}
                              >
                                <img
                                  src={downloadIcons}
                                  alt=""
                                  width="35px"
                                  height="35px"
                                  style={{ cursor: "pointer" }}
                                />
                              </a>
                            </div>
                            {/* </div> */}
                          </li>
                        </ul>
                      </div>
                    ) : null
                  )}
                </div>
                <div
                  style={isMobile ? { marginTop: "-45px" } : null}
                  className="subDialogBoxChild"
                >
                  {transporterAssetsList.map((assets, index) =>
                    index >= transporterAssetsList.length / 2 ? (
                      <div className="mini-details-single-attachment_commondrawer">
                        <ul
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            padding: "0rem .2rem",

                            justifyContent: "space-between",
                          }}
                          id="transporter_type"
                        >
                          <li
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              fontWeight: "400",
                              fontSize: "12px",
                              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                color: `${
                                  darkMode ? colorPaletteDark.white : ""
                                }`,
                              }}
                            >
                              <img
                                src={docIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                              />
                              {assets?.type == "pan"
                                ? "Pan Card"
                                : assets?.type == "signature"
                                ? "Signature"
                                : assets?.type == "aadhaar_front"
                                ? "Aadhaar Card Front Side"
                                : assets?.type == "aadhaar_back"
                                ? "Aadhaar Card Back Side"
                                : assets?.type ==
                                  "below_10_vehicles_self_declaration"
                                ? "Attachment for less than 10 vehicles "
                                : assets?.type == "cancel_cheque"
                                ? "Cancel Cheque"
                                : assets?.type == "gst_registration_certificate"
                                ? "GST Registration Certificate"
                                : assets?.type ==
                                  "non_gst_registration_self_declaration"
                                ? "Non GST Self Registration Certificate"
                                : assets?.type == "msme_certificate"
                                ? "MSME Certificate"
                                : ""}
                            </div>
                            <div className="image_button_container">
                              <img
                                src={showIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenTransporter();
                                  // handleOpen();
                                  showTransporterAttachments(
                                    assets?.transporters_id,
                                    assets?.id
                                  );
                                }}
                              />
                              {/* <ImageIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenTransporter();
                                  // handleOpen();
                                  showTransporterAttachments(
                                    assets?.transporters_id,
                                    assets?.id
                                  );
                                }}
                              /> */}
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  downloadAssets(
                                    assets?.transporters_id,
                                    assets?.id
                                  )
                                }
                                className="attachment_btn_commonDrawer"
                                // target="_blank"
                                // download
                                style={{ fontSize: "10px", width: "100%" }}
                              >
                                <img
                                  src={downloadIcons}
                                  alt=""
                                  width="35px"
                                  height="35px"
                                  style={{ cursor: "pointer" }}
                                />
                              </a>
                            </div>
                            {/* </div> */}
                          </li>
                        </ul>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            </div>

            <span>Remarks</span>
            <textarea
              name="transporter-status"
              id="transporter-status"
              cols="30"
              rows="2"
              style={{
                width: "100%",
                border: "1px solid grey",
                padding: "15px",
                marginBottom: "10px",
                backgroundColor: darkMode ? "black" : "white",
                color: darkMode ? "white" : "black",
                borderColor: darkMode ? "white" : "black",
              }}
              onChange={(e) => setTransporterStatusRemarks(e.target.value)}
              value={transporterStatusRemarks}
            ></textarea>

            <ButtonRectangleSecondary
              onClick={() => handleTransporterStatus("rejected")}
              style={{ float: "right", marginLeft: "7px" }}
              disabled={flag || flag2}
            >
              {flag2 ? <CircularProgress size={20} /> : "Reject"}
            </ButtonRectangleSecondary>

            <ButtonRectangleSecondary
              disabled={flag || flag2}
              onClick={() =>
                handleTransporterStatus(
                  carrierDetails.status == "submitted"
                    ? "recommended"
                    : carrierDetails.status == "recommended"
                    ? "approved"
                    : ""
                )
              }
              style={{ float: "right" }}
            >
              {flag ? (
                <CircularProgress size={20} />
              ) : carrierDetails.status === "submitted" ? (
                "Recommend"
              ) : (
                "Approve"
              )}

              {/* {carrierDetails.status === "submitted" ? "Recommend" : "Approve"} */}
            </ButtonRectangleSecondary>
          </Box>
        </Modal>

        <Modal
          open={openModelForRejectStatus}
          onClose={() => setOpenModelForRejectStatus(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            style={{
              height: isMobile ? "50vh" : "",
              width: isMobile ? "90vw" : "",
              overflowY: isMobile ? "scroll" : "hidden",
              paddingBottom: isMobile ? "80px" : "",
              backgroundColor: darkMode ? "black" : "white",
            }}
          >
            <div className="modalCrossDiv">
              <CloseIcon onClick={() => setTransporterStatus(false)} />
            </div>
            <h3 style={{ marginBottom: "20px", color: "#076b38" }}>
              Transporter Status
            </h3>
            <div className="dialogBox">
              <div
                style={
                  isMobile
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }
                    : null
                }
                className="subDialogBox"
              >
                <div className="subDialogBoxChild">
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Name</span> :{" "}
                    {carrierDetails?.name}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Code</span> :{" "}
                    {carrierDetails?.transporter_code}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Status</span> :{" "}
                    {carrierDetails?.status}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Transporter Type</span> :{" "}
                    {makeTransporterTypeReadable()}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Mobile number</span> :{" "}
                    {carrierDetails?.mobile}
                  </h4>
                </div>
                <div className="subDialogBoxChild">
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Email</span> :{" "}
                    {carrierDetails?.email}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">GSTIN</span> :{" "}
                    {carrierDetails?.gstin_number}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Aadhaar</span> :{" "}
                    {carrierDetails?.aadhar_number}
                  </h4>
                  <h4 style={{ marginBottom: "15px" }}>
                    <span className="greyText">Pan</span> :{" "}
                    {carrierDetails?.pan_number}
                  </h4>
                </div>
              </div>

              <h4 style={{ color: "#076b38" }}>Attachments :</h4>

              <div
                style={
                  isMobile
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }
                    : null
                }
                className="subDialogBox2"
              >
                <div className="subDialogBoxChild">
                  <div className="mini-details-single-attachment_commondrawer">
                    <ul
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        padding: "0rem .2rem",
                        justifyContent: "space-between",
                      }}
                      id="transporter_type"
                    >
                      <li
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          fontWeight: "400",
                          fontFamily: "poppins",
                          fontSize: "12px",
                          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        }}
                      >
                        <div
                          className="image_button_container"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            color: `${darkMode ? colorPaletteDark.white : ""}`,
                          }}
                        >
                          <img
                            src={docIcons}
                            alt=""
                            width="35px"
                            height="35px"
                            style={{ cursor: "pointer", marginRight: "10px" }}
                          />
                          Service Agreement
                        </div>

                        <div className="image_button_container">
                          <img
                            src={showIcons}
                            alt=""
                            width="35px"
                            height="35px"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleOpenTransporter();
                              // handleOpenTransporter();
                              // handleOpen();
                              showDownload(carriersData.id);
                            }}
                          />
                          <img
                            src={downloadIcons}
                            alt=""
                            onClick={() => download(carriersData.id)}
                            width="35px"
                            height="35px"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  {transporterAssetsList.map((assets, index) =>
                    index <= transporterAssetsList.length / 2 - 1 ? (
                      <div className="mini-details-single-attachment_commondrawer">
                        <ul
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            padding: "0rem .2rem",

                            justifyContent: "space-between",
                          }}
                          id="transporter_type"
                        >
                          <li
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              fontWeight: "400",
                              fontSize: "12px",
                              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                color: `${
                                  darkMode ? colorPaletteDark.white : ""
                                }`,
                              }}
                            >
                              <img
                                src={docIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                              />
                              {assets?.type == "pan"
                                ? "Pan Card"
                                : assets?.type == "signature"
                                ? "Signature"
                                : assets?.type == "aadhaar_front"
                                ? "Aadhaar Card Front Side"
                                : assets?.type == "aadhaar_back"
                                ? "Aadhaar Card Back Side"
                                : assets?.type ==
                                  "below_10_vehicles_self_declaration"
                                ? "Attachment for less than 10 vehicles "
                                : assets?.type == "cancel_cheque"
                                ? "Cancel Cheque"
                                : assets?.type == "gst_registration_certificate"
                                ? "GST Registration Certificate"
                                : assets?.type ==
                                  "non_gst_registration_self_declaration"
                                ? "Non GST Self Declaration Certificate"
                                : assets?.type == "msme_certificate"
                                ? "MSME Certificate"
                                : ""}
                            </div>
                            <div className="image_button_container">
                              <img
                                src={showIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenTransporter();
                                  // handleOpen();
                                  showTransporterAttachments(
                                    assets?.transporters_id,
                                    assets?.id
                                  );
                                }}
                              />
                              {/* <ImageIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenTransporter();
                                  // handleOpen();
                                  showTransporterAttachments(
                                    assets?.transporters_id,
                                    assets?.id
                                  );
                                }}
                              /> */}
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  downloadAssets(
                                    assets?.transporters_id,
                                    assets?.id
                                  )
                                }
                                className="attachment_btn_commonDrawer"
                                // target="_blank"
                                // download
                                style={{ fontSize: "10px", width: "100%" }}
                              >
                                <img
                                  src={downloadIcons}
                                  alt=""
                                  width="35px"
                                  height="35px"
                                  style={{ cursor: "pointer" }}
                                />
                              </a>
                            </div>
                            {/* </div> */}
                          </li>
                        </ul>
                      </div>
                    ) : null
                  )}
                </div>
                <div
                  style={isMobile ? { marginTop: "-45px" } : null}
                  className="subDialogBoxChild"
                >
                  {transporterAssetsList.map((assets, index) =>
                    index >= transporterAssetsList.length / 2 ? (
                      <div className="mini-details-single-attachment_commondrawer">
                        <ul
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            padding: "0rem .2rem",

                            justifyContent: "space-between",
                          }}
                          id="transporter_type"
                        >
                          <li
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              fontWeight: "400",
                              fontSize: "12px",
                              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                color: `${
                                  darkMode ? colorPaletteDark.white : ""
                                }`,
                              }}
                            >
                              <img
                                src={docIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                              />
                              {assets?.type == "pan"
                                ? "Pan Card"
                                : assets?.type == "signature"
                                ? "Signature"
                                : assets?.type == "aadhaar_front"
                                ? "Aadhaar Card Front Side"
                                : assets?.type == "aadhaar_back"
                                ? "Aadhaar Card Back Side"
                                : assets?.type ==
                                  "below_10_vehicles_self_declaration"
                                ? "Attachment for less than 10 vehicles "
                                : assets?.type == "cancel_cheque"
                                ? "Cancel Cheque"
                                : assets?.type == "gst_registration_certificate"
                                ? "GST Registration Certificate"
                                : assets?.type ==
                                  "non_gst_registration_self_declaration"
                                ? "Non GST Self Declaration Certificate"
                                : assets?.type == "msme_certificate"
                                ? "MSME Certificate"
                                : ""}
                            </div>
                            <div className="image_button_container">
                              <img
                                src={showIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenTransporter();
                                  // handleOpen();
                                  showTransporterAttachments(
                                    assets?.transporters_id,
                                    assets?.id
                                  );
                                }}
                              />
                              {/* <ImageIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenTransporter();
                                  // handleOpen();
                                  showTransporterAttachments(
                                    assets?.transporters_id,
                                    assets?.id
                                  );
                                }}
                              /> */}
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  downloadAssets(
                                    assets?.transporters_id,
                                    assets?.id
                                  )
                                }
                                className="attachment_btn_commonDrawer"
                                // target="_blank"
                                // download
                                style={{ fontSize: "10px", width: "100%" }}
                              >
                                <img
                                  src={downloadIcons}
                                  alt=""
                                  width="35px"
                                  height="35px"
                                  style={{ cursor: "pointer" }}
                                />
                              </a>
                            </div>
                            {/* </div> */}
                          </li>
                        </ul>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            </div>
            <span>Remarks</span>
            <textarea
              name="transporter-status"
              id="transporter-status"
              cols="30"
              rows="10"
              style={{
                width: "100%",
                border: "1px solid grey",
                padding: "15px",
                backgroundColor: darkMode ? "black" : "white",
                color: darkMode ? "white" : "black",
                borderColor: darkMode ? "white" : "black",
              }}
              onChange={(e) => setTransporterStatusRemarks(e.target.value)}
              value={transporterStatusRemarks}
            ></textarea>

            <ButtonRectangleSecondary
              onClick={() => handleTransporterStatus("rejected")}
              style={{ float: "right" }}
              disabled={flag || flag2}
            >
              {flag ? <CircularProgress size={20} /> : "Reject"}
            </ButtonRectangleSecondary>
          </Box>
        </Modal>

        <Modal
          open={openBlockModel}
          onClose={() => setOpenBlockModel(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            style={{
              height: isMobile ? "50vh" : "",
              width: isMobile ? "90vw" : "",
              overflowY: isMobile ? "scroll" : "hidden",
              paddingBottom: isMobile ? "80px" : "",
              backgroundColor: darkMode ? "black" : "white",
            }}
          >
            <div className="modalCrossDiv">
              <CloseIcon onClick={() => setOpenBlockModel(false)} />
            </div>
            <h3 style={{ marginBottom: "20px", color: "#076b38" }}>
              Transporter Status
            </h3>
            <div className="dialogBox">
              <div
                style={
                  isMobile
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }
                    : null
                }
                className="subDialogBox"
              >
                <div className="subDialogBoxChild">
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Name</span> :{" "}
                    {carrierDetails?.name}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Code</span> :{" "}
                    {carrierDetails?.transporter_code}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Status</span> :{" "}
                    {carrierDetails?.status}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Transporter Type</span> :{" "}
                    {makeTransporterTypeReadable()}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Mobile number</span> :{" "}
                    {carrierDetails?.mobile}
                  </h4>
                </div>
                <div className="subDialogBoxChild">
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Email</span> :{" "}
                    {carrierDetails?.email}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">GSTIN</span> :{" "}
                    {carrierDetails?.gstin_number}
                  </h4>
                  <h4
                    style={darkMode ? { color: "white" } : { color: "black" }}
                  >
                    <span className="greyText">Aadhaar</span> :{" "}
                    {carrierDetails?.aadhar_number}
                  </h4>
                  <h4 style={{ marginBottom: "15px" }}>
                    <span className="greyText">Pan</span> :{" "}
                    {carrierDetails?.pan_number}
                  </h4>
                </div>
              </div>

              <h4 style={{ color: "#076b38" }}>Attachments :</h4>

              <div
                style={
                  isMobile
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }
                    : null
                }
                className="subDialogBox2"
              >
                <div className="subDialogBoxChild">
                  <div className="mini-details-single-attachment_commondrawer">
                    <ul
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        padding: "0rem .2rem",
                        justifyContent: "space-between",
                      }}
                      id="transporter_type"
                    >
                      <li
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          fontWeight: "400",
                          fontFamily: "poppins",
                          fontSize: "12px",
                          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                        }}
                      >
                        <div
                          className="image_button_container"
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            color: `${darkMode ? colorPaletteDark.white : ""}`,
                          }}
                        >
                          <img
                            src={docIcons}
                            alt=""
                            width="35px"
                            height="35px"
                            style={{ cursor: "pointer", marginRight: "10px" }}
                          />
                          Service Agreement
                        </div>

                        <div className="image_button_container">
                          <img
                            src={showIcons}
                            alt=""
                            width="35px"
                            height="35px"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleOpenTransporter();
                              // handleOpenTransporter();
                              // handleOpen();
                              showDownload(carriersData.id);
                            }}
                          />
                          <img
                            src={downloadIcons}
                            alt=""
                            onClick={() => download(carriersData.id)}
                            width="35px"
                            height="35px"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  {transporterAssetsList.map((assets, index) =>
                    index <= transporterAssetsList.length / 2 - 1 ? (
                      <div className="mini-details-single-attachment_commondrawer">
                        <ul
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            padding: "0rem .2rem",

                            justifyContent: "space-between",
                          }}
                          id="transporter_type"
                        >
                          <li
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              fontWeight: "400",
                              fontSize: "12px",
                              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                color: `${
                                  darkMode ? colorPaletteDark.white : ""
                                }`,
                              }}
                            >
                              <img
                                src={docIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                              />
                              {assets?.type == "pan"
                                ? "Pan Card"
                                : assets?.type == "signature"
                                ? "Signature"
                                : assets?.type == "aadhaar_front"
                                ? "Aadhaar Card Front Side"
                                : assets?.type == "aadhaar_back"
                                ? "Aadhaar Card Back Side"
                                : assets?.type ==
                                  "below_10_vehicles_self_declaration"
                                ? "Attachment for less than 10 vehicles "
                                : assets?.type == "cancel_cheque"
                                ? "Cancel Cheque"
                                : assets?.type == "gst_registration_certificate"
                                ? "GST Registration Certificate"
                                : assets?.type ==
                                  "non_gst_registration_self_declaration"
                                ? "Non GST Self Declaration Certificate"
                                : assets?.type == "msme_certificate"
                                ? "MSME Certificate"
                                : ""}
                            </div>
                            <div className="image_button_container">
                              <img
                                src={showIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenTransporter();
                                  // handleOpen();
                                  showTransporterAttachments(
                                    assets?.transporters_id,
                                    assets?.id
                                  );
                                }}
                              />
                              {/* <ImageIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenTransporter();
                                  // handleOpen();
                                  showTransporterAttachments(
                                    assets?.transporters_id,
                                    assets?.id
                                  );
                                }}
                              /> */}
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  downloadAssets(
                                    assets?.transporters_id,
                                    assets?.id
                                  )
                                }
                                className="attachment_btn_commonDrawer"
                                // target="_blank"
                                // download
                                style={{ fontSize: "10px", width: "100%" }}
                              >
                                <img
                                  src={downloadIcons}
                                  alt=""
                                  width="35px"
                                  height="35px"
                                  style={{ cursor: "pointer" }}
                                />
                              </a>
                            </div>
                            {/* </div> */}
                          </li>
                        </ul>
                      </div>
                    ) : null
                  )}
                </div>
                <div
                  style={isMobile ? { marginTop: "-45px" } : null}
                  className="subDialogBoxChild"
                >
                  {transporterAssetsList.map((assets, index) =>
                    index >= transporterAssetsList.length / 2 ? (
                      <div className="mini-details-single-attachment_commondrawer">
                        <ul
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            padding: "0rem .2rem",

                            justifyContent: "space-between",
                          }}
                          id="transporter_type"
                        >
                          <li
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              fontWeight: "400",
                              fontSize: "12px",
                              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                color: `${
                                  darkMode ? colorPaletteDark.white : ""
                                }`,
                              }}
                            >
                              <img
                                src={docIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{
                                  cursor: "pointer",
                                  marginRight: "5px",
                                }}
                              />
                              {assets?.type == "pan"
                                ? "Pan Card"
                                : assets?.type == "signature"
                                ? "Signature"
                                : assets?.type == "aadhaar_front"
                                ? "Aadhaar Card Front Side"
                                : assets?.type == "aadhaar_back"
                                ? "Aadhaar Card Back Side"
                                : assets?.type ==
                                  "below_10_vehicles_self_declaration"
                                ? "Attachment for less than 10 vehicles "
                                : assets?.type == "cancel_cheque"
                                ? "Cancel Cheque"
                                : assets?.type == "gst_registration_certificate"
                                ? "GST Registration Certificate"
                                : assets?.type ==
                                  "non_gst_registration_self_declaration"
                                ? "Non GST Self Declaration Certificate"
                                : assets?.type == "msme_certificate"
                                ? "MSME Certificate"
                                : ""}
                            </div>
                            <div className="image_button_container">
                              <img
                                src={showIcons}
                                alt=""
                                width="35px"
                                height="35px"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenTransporter();
                                  // handleOpen();
                                  showTransporterAttachments(
                                    assets?.transporters_id,
                                    assets?.id
                                  );
                                }}
                              />
                              {/* <ImageIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenTransporter();
                                  // handleOpen();
                                  showTransporterAttachments(
                                    assets?.transporters_id,
                                    assets?.id
                                  );
                                }}
                              /> */}
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  downloadAssets(
                                    assets?.transporters_id,
                                    assets?.id
                                  )
                                }
                                className="attachment_btn_commonDrawer"
                                // target="_blank"
                                // download
                                style={{ fontSize: "10px", width: "100%" }}
                              >
                                <img
                                  src={downloadIcons}
                                  alt=""
                                  width="35px"
                                  height="35px"
                                  style={{ cursor: "pointer" }}
                                />
                              </a>
                            </div>
                            {/* </div> */}
                          </li>
                        </ul>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            </div>
            <span>Remarks</span>
            <textarea
              name="transporter-status"
              id="transporter-status"
              cols="30"
              rows="2"
              style={{
                width: "100%",
                border: "1px solid grey",
                padding: "15px",
                backgroundColor: darkMode ? "black" : "white",
                color: darkMode ? "white" : "black",
                borderColor: darkMode ? "white" : "black",
              }}
              onChange={(e) => setTransporterStatusRemarks(e.target.value)}
              value={transporterStatusRemarks}
            ></textarea>

            <ButtonRectangleSecondary
              onClick={() => handleTransporterStatus("blocked")}
              style={{ float: "right" }}
              disabled={flag || flag2}
            >
              {flag ? <CircularProgress size={20} /> : "Block"}
            </ButtonRectangleSecondary>
          </Box>
        </Modal>

        <Drawer anchor={"right"} onClose={handleClose} open={state["right"]}>
          <Bank_Container className="bank_container" mode={darkMode}>
            <div className="bank_wrapper">
              <div className="bank_headings_drawer">
                <h3>Enter Bank Details</h3>{" "}
                <CloseIcon
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />{" "}
              </div>
              <div className="bank-drawer-row">
                <div className="warehouse-list-input-group_drawer">
                  <h4 className="warehouse-list-input-label-drawer">
                    Bank A/C Number <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="bank-input"
                    variant="outlined"
                    placeholder="Enter a/c number"
                    name="bank_acc_number"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={bankAcNumber}
                    onChange={(e) => {
                      if (e.target.value?.length <= 18) {
                        setBankAcNumber(e.target.value);
                      }
                      setErrorNumber("");
                      validateField(e);
                    }}
                  />
                  {Object.keys(errorNumber).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>{errorNumber[key]}</h6>
                    );
                  })}
                </div>
              </div>
              <div className="bank-drawer-row">
                <div className="warehouse-list-input-group">
                  <h4 className="warehouse-list-input-label-drawer">
                    A/C Holder Name <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="bank-input"
                    variant="outlined"
                    placeholder="Enter A/c Holder Name"
                    type="text"
                    name="account-holder-name"
                    value={carrierDetails?.name ? carrierDetails?.name : ""}
                    disabled="true"
                    onChange={(e) => {
                      setHolderName(e.target.value);
                      setErrorBankHolder("");
                      validateField(e);
                    }}
                  />
                  {Object.keys(errorBankHolder).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>
                        {errorBankHolder[key]}
                      </h6>
                    );
                  })}
                </div>
              </div>
              <div className="bank-drawer-row">
                <div className="warehouse-list-input-group">
                  <h4 className="warehouse-list-input-label-drawer">
                    Bank A/C Type <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    id="filled-select-currency"
                    select
                    label="Please Select account type"
                    value={accType}
                    onChange={(e) => {
                      setAccType(e.target.value);
                      setErrorAcType("");
                    }}
                    variant="filled"
                    className="bank-input"
                    color="success"
                    size="small"
                  >
                    {bankAccTypeList
                      // .filter((data) => data.split("_").join(" ").toUpperCase())
                      .map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option.split("_").join(" ").toUpperCase()}
                        </MenuItem>
                      ))}
                  </TextField>
                  {Object.keys(errorAcType).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>{errorAcType[key]}</h6>
                    );
                  })}
                </div>
              </div>
              {/* <div className="bank-drawer-row">
                <div className="warehouse-list-input-group">
                  <h4 className="warehouse-list-input-label-drawer">
                    Entity Type <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    id="filled-select-currency"
                    // select
                    disabled
                    // label="Please Select entity type"
                    value="TRANSPORTER"
                    // onChange={(e) => {
                    //   setEntity(e.target.value);
                    //   setErrorEntity("");
                    // }}
                    variant="filled"
                    className="bank-input"
                    color="success"
                    size="small"
                  >
                    {entityList
                      .filter((data) => data == "transporter")
                      .map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option.split("_").join(" ").toUpperCase()}
                        </MenuItem>
                      ))}
                  </TextField>
                  {Object.keys(errorEntity).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>{errorEntity[key]}</h6>
                    );
                  })}
                </div>
              </div> */}
              <div className="bank-drawer-row">
                <div className="warehouse-list-input-group">
                  <h4 className="warehouse-list-input-label-drawer">
                    Bank Name <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  {/* <TextField
                    id="filled-select-currency"
                    variant="outlined"
                    label="Enter Bank Name"
                    value={bankName}
                    onChange={(e) => {
                      setBankName(e.target.value);
                      setErrorBankName("");
                    }}
                    className="bank-input"
                    color="success"
                    size="small"
                  ></TextField> */}
                  <TextField
                    id="filled-select-currency"
                    select
                    label=""
                    value={bankName}
                    onChange={(e) => {
                      setBankName(e.target.value);
                      setErrorBankName("");
                    }}
                    // variant="filled"
                    className="input_field_form"
                    color="success"
                    size="small"
                    style={{ width: "90%" }}
                  >
                    {BankList.sort().map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  {Object.keys(errorBankName).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>{errorBankName[key]}</h6>
                    );
                  })}
                </div>
              </div>
              <div className="bank-drawer-row">
                <div className="warehouse-list-input-group">
                  <h4 className="warehouse-list-input-label-drawer">
                    IFSC Code <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="bank-input"
                    variant="outlined"
                    placeholder="Enter IFSC Code"
                    type="text"
                    name="ifsc"
                    value={ifscCode}
                    onChange={(e) => {
                      if (e.target.value.length <= 11) {
                        setIfscCode(e.target.value);
                      }
                      setErrorIfsc("");
                      validateField(e);
                    }}
                  />
                  {Object.keys(errorIfsc).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666" }}>{errorIfsc[key]}</h6>
                    );
                  })}
                </div>
              </div>
              <div className="bank-drawer-row">
                <div className="warehouse-list-input-group">
                  <p style={{ color: darkMode ? "white" : "" }}>
                    Is Primary Account?
                  </p>
                  <RadioGroup
                    className="create-trip-input-source"
                    aria-labelledby="demo-radio-buttons-group-label-drawer"
                    value={trueValue}
                    onChange={(e) => setTrueValue(e.target.value)}
                    style={{ color: darkMode ? "white" : "" }}
                  >
                    <div className="create-trip-source-radio">
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                        style={{ color: darkMode ? "white" : "" }}
                      />

                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                        style={{ color: darkMode ? "white" : "" }}
                      />
                    </div>
                  </RadioGroup>
                </div>
                {Object.keys(errorIsPrimary).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>{errorIsPrimary[key]}</h6>
                  );
                })}
              </div>

              {/* <div className="bank-drawer-row">
                <div className="warehouse-list-input-group">
                  <h4 className="warehouse-list-input-label">
                    Entity Type <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="bank-input"
                    variant="outlined"
                    placeholder="Enter a/c number"
                    type="text"
                 
                  />
                </div>
              </div> */}
              <div className="bank-drawer-row">
                <div className="warehouse-list-input-group">
                  <h4 className="warehouse-list-input-label-drawer">
                    Upload Document <span style={{ fontSize: "10px" }}></span>
                  </h4>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    disabled
                    // sx={{
                    //   width: "30ch",
                    //   height: "4ch",
                    // }}
                    // style={{ width: "14rem" }}
                    // select
                    autoComplete="off"
                    // className="vehicle-input-attachment dropdown-container "
                    variant="outlined"
                    className="bank-input"
                    placeholder="Upload document"
                    name="attachment_tag"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{
                            cursor: "pointer",
                            color: colorPalette.primaryColor,
                            fontWeight: "600",
                          }}
                        >
                          <button className="upload_button">
                            <input
                              // name="upload"
                              name="attachment_file"
                              id="insurance"
                              type="file"
                              accept="image/png, image/jpeg, application/pdf"
                              onChange={handleDocument}
                              hidden
                              style={{
                                backgroundColor: colorPalette.secondaryWhite,
                              }}
                            />
                            <label
                              htmlFor="insurance"
                              style={{
                                backgroundColor: colorPalette.secondaryWhite,
                              }}
                            >
                              <img
                                src={UploadImage}
                                alt=""
                                width="20px"
                                height="20px"
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                            </label>
                          </button>
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                  <div style={{ fontSize: "10px" }}>
                    {" "}
                    {document ? document.name : null}
                  </div>
                </div>
              </div>
              {errorPostBankDetails ? (
                <div className="error_bank_details">{errorPostBankDetails}</div>
              ) : null}

              {document?.type === "application/pdf" ? (
                <>
                  <div className="error_bank_details">
                    *Invalid file format,Only png or jpg is allowed.
                  </div>
                </>
              ) : null}
              <div className="bank-drawer-row">
                {isInputValid ? (
                  <div className="warehouse-list-input-group">
                    <button
                      className="bank_button"
                      onClick={handleBankDetailsPost}
                      disabled={flag || flag2}
                    >
                      {loadingButton ? (
                        <CircularProgress
                          size={20}
                          style={{ color: "white" }}
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </Bank_Container>
          {/* <h5 onClick={handleClose}>close</h5> */}
        </Drawer>

        <Drawer
          anchor={"right"}
          open={commonDrawerOpen}
          onClose={() => {
            handleCloseCommonDrawer();
          }}
          PaperProps={{
            sx: {
              width: isMobile ? 380 : 400,
            },
          }}
        >
          <Transporter_Drawer className="transporter_drawer" mode={darkMode}>
            <div
              className="transporter_drawer_headings"
              style={{ position: "relative" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <CloseIcon
                  onClick={() => {
                    handleCloseCommonDrawer();
                  }}
                  style={{
                    cursor: "pointer",
                    color: darkMode
                      ? colorPaletteDark.white
                      : colorPalette.black,
                  }}
                />
              </div>
              <div className="sideSlideHeading">
                <span style={{ fontSize: "larger", color: "#076b38" }}>
                  {transporterDetailsHeading}
                </span>
                <span>
                  {/* <AccountCircleIcon /> */}
                  <span className="greyText">Name</span> :{" "}
                  {carrierDetails?.name}
                </span>
                <span>
                  {/* <InfoIcon /> */}
                  <span className="greyText">Code</span> :{" "}
                  {carrierDetails?.transporter_code}
                </span>
                {/* <ApprovalIcon/> */}
                {carrierDetails?.is_active ? (
                  <span>
                    <span className="greyText">Status</span> :{" "}
                    {carrierDetails?.status}
                  </span>
                ) : null}
              </div>
              {!carrierDetails?.is_active ? (
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    position: "absolute",
                    right: "10%",
                    top: "16%",
                  }}
                >
                  <img src={blocked} width={"100%"} height={"100%"} />
                </div>
              ) : null}
            </div>

            <div
              style={{
                padding: "5px 10px",
                display: "flex",
                alignItems: "center",
                // gap: 20,
              }}
            >
              <div>
                {" "}
                <NavLink
                  to="/transporters/transporters-onboard"
                  style={{ textDecoration: "none" }}
                >
                  {/* <div className="trip-list-right-most-btn-container"> */}
                  {carrierDetails.status == "submitted" ? (
                    <ButtonRectangleSecondary
                      // className="trip-list-edit-btn_v2"
                      // style={{ padding: ".2rem .5rem" }}
                      onClick={() => handleCarrierId(carrierDetails.id)}
                      // onClick={() => handleDeliveryInvoicePayment(trip)}
                    >
                      <EditIcon style={{ fontSize: "15px" }} />
                      Edit
                    </ButtonRectangleSecondary>
                  ) : null}
                </NavLink>
              </div>
              <div>
                {carrierDetails.status == "approved" &&
                carrierDetails.is_active ? (
                  <ButtonRectangleSecondary
                    onClick={() => setOpenBlockModel(true)}
                  >
                    Block
                  </ButtonRectangleSecondary>
                ) : null}
              </div>

              {carrierDetails.status == "submitted" ? (
                <ButtonRectangleSecondary
                  onClick={() => setTransporterStatus(true)}
                >
                  Recommend
                </ButtonRectangleSecondary>
              ) : carrierDetails.status == "recommended" ? (
                <ButtonRectangleSecondary
                  onClick={() => setTransporterStatus(true)}
                >
                  Approve
                </ButtonRectangleSecondary>
              ) : null}
              {/* <div>
                {carrierDetails.status !== "rejected" &&
                carrierDetails.status !== "approved" ? (
                  <ButtonRectangleSecondary
                    onClick={() => setOpenModelForRejectStatus(true)}
                  >
                    Reject
                  </ButtonRectangleSecondary>
                ) : null}
              </div> */}
            </div>

            <div className="transporter_drawer_content_container">
              <div className="transporter_drawer_content">
                {transporterDetailsHeading == "Transporter" ? (
                  <div className="mini-details-carrier-tab-container_drawer">
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Transporter Name
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        : {carriersData?.name ? carriersData.name : "-"}
                      </h4>{" "}
                    </div>

                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Transporter Type
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carriersData?.transporter_type
                          ? carriersData?.transporter_type
                              .split("_")
                              .join(" ")
                              .toUpperCase()
                          : "-"}
                      </h4>
                    </div>
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Mobile Number
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carriersData?.mobile ? (
                          <CopyText text={carriersData?.mobile} />
                        ) : (
                          "-"
                        )}
                      </h4>{" "}
                    </div>
                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        Email
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carriersData?.email ? (
                          <CopyText text={carriersData?.email} />
                        ) : (
                          "-"
                        )}
                      </h4>{" "}
                    </div>
                    {carriersData?.transporter_type ===
                    "registered_transporter" ? (
                      <div className="mini-details-carrier-single-list">
                        <h4 className="mini-details-carrier-single-list-left_drawer">
                          GSTIN
                        </h4>
                        <h4 className="mini-details-carrier-single-list-right_drawer">
                          :{" "}
                          {carriersData?.gstin_number ? (
                            <CopyText text={carriersData?.gstin_number} />
                          ) : (
                            "-"
                          )}{" "}
                        </h4>{" "}
                      </div>
                    ) : null}

                    <div className="mini-details-carrier-single-list">
                      <h4 className="mini-details-carrier-single-list-left_drawer">
                        PAN
                      </h4>
                      <h4 className="mini-details-carrier-single-list-right_drawer">
                        :{" "}
                        {carriersData?.pan_number ? (
                          <CopyText text={carriersData?.pan_number} />
                        ) : (
                          "-"
                        )}{" "}
                      </h4>
                    </div>
                    {carriersData?.transporter_type ===
                    "individual_transporter" ? (
                      <div className="mini-details-carrier-single-list">
                        <h4 className="mini-details-carrier-single-list-left_drawer">
                          Aadhaar Number
                        </h4>
                        <h4 className="mini-details-carrier-single-list-right_drawer">
                          :{" "}
                          {carriersData?.aadhar_number ? (
                            <CopyText text={carriersData?.aadhar_number} />
                          ) : (
                            "-"
                          )}{" "}
                        </h4>
                      </div>
                    ) : null}
                    {/* {Object.keys(carriersData).length ? (
                      <div className="vehicle_button_add_drawer">
                        {" "}
                        <ButtonRectangleSecondary
                          onClick={() => handleAddVehicle(carriersData.id)}
                          className="btn_card_vehicle_drawer"
                        >
                          <AddIcon /> Add Vehicle
                        </ButtonRectangleSecondary>
                      </div>
                    ) : null} */}
                  </div>
                ) : transporterDetailsHeading == "Bank Details" ? (
                  <>
                    <div className="mini-details-carrier-tab-container-bank-drawer">
                      {carriersData?.transporter_bank_details?.length > 0 ? (
                        carriersData?.transporter_bank_details
                          .filter((data) => data.entity_type == "transporter")
                          .map((bank, index) => (
                            <>
                              <Accordion key={index} className="accordion_main">
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Typography className="accodion_heading">
                                    <h5 className="bank_holder">
                                      {bank?.account_holder_name.toUpperCase()}
                                      {bank.is_primary ? (
                                        <CheckCircleIcon
                                          style={{
                                            color: colorPalette.primaryColor,
                                            fontSize: "18px",
                                            marginLeft: "10px",
                                          }}
                                        />
                                      ) : null}
                                    </h5>{" "}
                                    {carrierDetails?.status === "submitted" &&
                                    carrierDetails?.is_active ? (
                                      <EditIcon
                                        onClick={(e) => {
                                          getBankDetailsById(e, bank.id);
                                        }}
                                        style={{
                                          fontSize: "15px",
                                          color: darkMode
                                            ? colorPaletteDark.white
                                            : colorPalette.black,
                                        }}
                                      />
                                    ) : null}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails className="mobile_users">
                                  <div className="bankDetails">
                                    <h5
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        padding: ".5rem 0rem",
                                        fontFamily: "poppins",
                                        fontSize: "12px",
                                      }}
                                    >
                                      A/c holder name{" "}
                                      <span
                                        style={{
                                          position: "absolute",
                                          marginLeft: "9rem",
                                          color:
                                            colorPalette.paragraphSecondaryolor,
                                          fontWeight: "500",
                                        }}
                                      >
                                        : {bank?.account_holder_name}
                                      </span>
                                    </h5>
                                    <h5
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        padding: ".5rem 0rem",
                                        fontSize: "12px",
                                        // justifyContent: "space-between",
                                      }}
                                    >
                                      A/c Number{" "}
                                      <span
                                        style={{
                                          position: "absolute",
                                          marginLeft: "9rem",
                                          color:
                                            colorPalette.paragraphSecondaryolor,
                                          fontWeight: "500",
                                        }}
                                      >
                                        : {}
                                        <CopyText
                                          text={bank.bank_account_number}
                                        />
                                      </span>
                                    </h5>
                                    <h5
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        padding: ".5rem 0rem",
                                        fontSize: "12px",
                                        // justifyContent: "space-between",
                                      }}
                                    >
                                      Bank Name{" "}
                                      <span
                                        style={{
                                          position: "absolute",
                                          marginLeft: "9rem",
                                          color:
                                            colorPalette.paragraphSecondaryolor,
                                          fontWeight: "500",
                                        }}
                                      >
                                        : {bank.bank_name}
                                      </span>
                                    </h5>
                                    <h5
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        padding: ".5rem 0rem",
                                        fontSize: "12px",
                                        // justifyContent: "space-between",
                                      }}
                                    >
                                      Ifsc Code{" "}
                                      <span
                                        style={{
                                          position: "absolute",
                                          marginLeft: "9rem",
                                          color:
                                            colorPalette.paragraphSecondaryolor,
                                          fontWeight: "500",
                                        }}
                                      >
                                        : {<CopyText text={bank.ifsc_code} />}
                                      </span>
                                    </h5>
                                    <h5
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        padding: ".5rem 0rem",
                                        fontSize: "12px",

                                        // justifyContent: "space-between",
                                      }}
                                    >
                                      A/c Type{" "}
                                      <span
                                        style={{
                                          position: "absolute",
                                          marginLeft: "9rem",
                                          color:
                                            colorPalette.paragraphSecondaryolor,
                                          fontWeight: "500",
                                        }}
                                      >
                                        :{" "}
                                        {bank.bank_account_type
                                          .split("_")
                                          .join(" ")
                                          .toUpperCase()}
                                      </span>
                                    </h5>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          ))
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <h5 style={{ color: darkMode ? "white" : "" }}>
                            No Bank details found!
                          </h5>
                        </div>
                      )}
                    </div>
                    {carrierDetails?.status === "submitted" ? (
                      <div className="vehicle_button_add_drawer">
                        {" "}
                        <ButtonRectangleSecondary
                          className="bank_button_add_drawer"
                          onClick={openDrawer}
                        >
                          Add Bank Details
                        </ButtonRectangleSecondary>
                      </div>
                    ) : null}
                  </>
                ) : transporterDetailsHeading == "Attachments Details" ? (
                  <>
                    <div className="mini-details-single-attachment_commondrawer">
                      <ul
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          padding: "0rem .2rem",
                          justifyContent: "space-between",
                        }}
                        id="transporter_type"
                      >
                        <li
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontWeight: "400",
                            fontFamily: "poppins",
                            fontSize: "12px",
                          }}
                        >
                          <div
                            className="image_button_container"
                            style={{
                              color: darkMode
                                ? colorPaletteDark.white
                                : colorPalette.black,
                            }}
                          >
                            <img
                              src={docIcons}
                              alt=""
                              width="35px"
                              height="35px"
                              style={{
                                cursor: "pointer",
                                marginRight: "10px",
                              }}
                            />
                            Service Agreement
                          </div>
                          {/* <a
                            href="javascript:void(0)"
                            onClick={() => download(carriersData.id)}
                            className="attachment_btn_commonDrawer"
                            target="_blank"
                            download
                            style={{ fontSize: "10px", width: "100%" }}
                          > */}

                          {/* </a> */}
                          <div>
                            <img
                              src={showIcons}
                              alt=""
                              width="35px"
                              height="35px"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleOpenTransporter();
                                // handleOpenTransporter();
                                // handleOpen();
                                showDownload(carriersData.id);
                              }}
                            />
                            <img
                              src={downloadIcons}
                              alt=""
                              onClick={() => download(carriersData.id)}
                              width="35px"
                              height="35px"
                              style={{ cursor: "pointer" }}
                            />
                          </div>

                          {/* <a
                      href={`${
                        apiUrl.onboardingBaseUrl
                      }/v1/image/transporter-agreement/${prefixSalt()}-${convertToBase64(
                        carriersData.id
                      )}`}
                      download
                      className="attachment_btn"
                    >
                      <FeedIcon
                        style={{
                          marginRight: "10px",
                        }}
                      />
                    </a> */}
                        </li>
                      </ul>
                    </div>
                    {isLoading ? (
                      <div className="attatchmentsLoadingDiv">
                        <h5>loading...</h5>
                      </div>
                    ) : (
                      transporterAssetsList
                        ?.filter((item) => "transporters_id" in item)
                        .map((assets) => (
                          <div className="mini-details-single-attachment_commondrawer">
                            <ul
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                padding: "0rem .2rem",
                                justifyContent: "space-between",
                              }}
                              id="transporter_type"
                            >
                              <li
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  fontWeight: "400",
                                  fontSize: "12px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    color: `${
                                      darkMode ? colorPaletteDark.white : ""
                                    }`,
                                  }}
                                >
                                  <img
                                    src={docIcons}
                                    alt=""
                                    width="35px"
                                    height="35px"
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "5px",
                                    }}
                                  />
                                  {assets?.type == "pan"
                                    ? "Pan Card"
                                    : assets?.type == "signature"
                                    ? "Signature"
                                    : assets?.type == "aadhaar_front"
                                    ? "Aadhaar Card Front Side"
                                    : assets?.type == "aadhaar_back"
                                    ? "Aadhaar Card Back Side"
                                    : assets?.type ==
                                      "tds_non_deduction_declaration"
                                    ? "TDS Non Deduction Declaration"
                                    : assets?.type ==
                                      "below_10_vehicles_self_declaration"
                                    ? "Attachment for less than 10 vehicles "
                                    : assets?.type == "cancel_cheque"
                                    ? "Cancel Cheque"
                                    : assets?.type ==
                                      "gst_registration_certificate"
                                    ? "GST Registration Certificate"
                                    : assets?.type ==
                                      "non_gst_registration_self_declaration"
                                    ? "Non GST Registration Certificate"
                                    : assets?.type == "msme_certificate"
                                    ? "MSME Certificate"
                                    : ""}
                                </div>
                                <div className="image_button_container">
                                  <img
                                    src={showIcons}
                                    alt=""
                                    width="35px"
                                    height="35px"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      handleOpenTransporter();
                                      // handleOpen();
                                      showTransporterAttachments(
                                        assets?.transporters_id,
                                        assets?.id
                                      );
                                    }}
                                  />
                                  {/* <ImageIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenTransporter();
                                  // handleOpen();
                                  showTransporterAttachments(
                                    assets?.transporters_id,
                                    assets?.id
                                  );
                                }}
                              /> */}
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() =>
                                      downloadAssets(
                                        assets?.transporters_id,
                                        assets?.id
                                      )
                                    }
                                    className="attachment_btn_commonDrawer"
                                    // target="_blank"
                                    // download
                                    style={{
                                      fontSize: "10px",
                                      width: "100%",
                                    }}
                                  >
                                    <img
                                      src={downloadIcons}
                                      alt=""
                                      width="35px"
                                      height="35px"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </a>
                                </div>
                                {/* </div> */}
                              </li>
                            </ul>
                          </div>
                        ))
                    )}

                    {isLoading ? (
                      <div className="attatchmentsLoadingDiv">
                        <h3></h3>
                      </div>
                    ) : (
                      transporterAssetsList
                        ?.filter((item) => "account_holder_name" in item)
                        .map((assets) => (
                          <div className="mini-details-single-attachment_commondrawer">
                            <ul
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                padding: "0rem .2rem",

                                justifyContent: "space-between",
                              }}
                              id="transporter_type"
                            >
                              <li
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  fontWeight: "400",
                                  fontSize: "12px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    color: `${
                                      darkMode ? colorPaletteDark.white : ""
                                    }`,
                                  }}
                                >
                                  <img
                                    src={docIcons}
                                    alt=""
                                    width="35px"
                                    height="35px"
                                    style={{
                                      cursor: "pointer",
                                      marginRight: "5px",
                                    }}
                                  />
                                  {assets?.type.replace(/_/g, " ")} (
                                  {capitalizeLetter(
                                    assets?.account_holder_name
                                  )}
                                  )
                                </div>
                                <div className="image_button_container">
                                  <img
                                    src={showIcons}
                                    alt=""
                                    width="35px"
                                    height="35px"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      handleOpenTransporter();
                                      // handleOpen();
                                      showTransporterBankAttachments(
                                        // assets?.transporters_id,
                                        assets?.id
                                      );
                                    }}
                                  />

                                  <a
                                    href="javascript:void(0)"
                                    onClick={() =>
                                      downloadBankAssets(
                                        // assets?.transporters_id,
                                        assets?.id
                                      )
                                    }
                                    className="attachment_btn_commonDrawer"
                                    style={{
                                      fontSize: "10px",
                                      width: "100%",
                                    }}
                                  >
                                    <img
                                      src={downloadIcons}
                                      alt=""
                                      width="35px"
                                      height="35px"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </a>
                                </div>
                              </li>
                            </ul>
                          </div>
                        ))
                    )}
                  </>
                ) : transporterDetailsHeading == "Transporter History" ? (
                  <Mini_detais_Card_style
                    style={{ width: "330px" }}
                    mode={darkMode}
                  >
                    <div
                      style={
                        darkMode
                          ? { backgroundColor: "#211E2B" }
                          : { backgroundColor: "white" }
                      }
                      className="mini-details-tab-container-history"
                    >
                      <div className="mini-details-tab-left-timeline">
                        {transporterHistory.map((item, index) => {
                          return (
                            <>
                              {/* {tripHistoryList.length - 1 == index ? null : ( */}
                              <>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                              </>
                              {/* )} */}
                            </>
                          );
                        })}
                      </div>
                      <div>
                        {transporterHistory.map((item) => {
                          return (
                            <div className="mini-details-single-history">
                              <h3 className="mini-details-history-head">
                                <div className="mini-trip-circle"></div>{" "}
                                {item.status
                                  ? item.status
                                      .split("")
                                      .map((item, index) => {
                                        if (index == 0) {
                                          return item.toUpperCase();
                                        } else {
                                          return item;
                                        }
                                      })
                                      .join("")
                                  : "-"}
                              </h3>
                              <div className="mini-details-single-list">
                                {/* <h4 className="mini-details-history-right"> */}
                                <span className="mini-details-black-text">
                                  {" "}
                                  Remarks :{" "}
                                </span>
                                <span className="mini-details-grey-text">
                                  {" "}
                                  {item.remarks ? item.remarks : "-"}
                                </span>
                                {/* </h4>{" "} */}
                              </div>
                              <div className="mini-details-single-list">
                                {/* <h4 className="mini-details-history-right"> */}
                                <span className="mini-details-black-text">
                                  {" "}
                                  Last Modified:{" "}
                                </span>
                                <span className="mini-details-grey-text">
                                  {item.created_by ? item.created_by : "-"}
                                </span>
                                {/* </h4>{" "} */}
                              </div>
                              <div className="mini-details-single-list">
                                <h4 className="mini-details-history-left">
                                  {/* created by */}
                                </h4>
                                <h4 className="mini-details-history-right">
                                  <span className="mini-details-black-text">
                                    Created on :{" "}
                                  </span>
                                  <span className="mini-details-grey-text">
                                    {item.created_on
                                      ? moment
                                          .utc(item.created_on)
                                          .local()
                                          .format("DD-MM-YYYY hh:mm a")
                                      : "-"}
                                  </span>
                                </h4>{" "}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Mini_detais_Card_style>
                ) : null}
              </div>
              <div className="transporter_drawer_tabs">
                <ul className="icons_container">
                  {transporterDetailsHeading == "Transporter" ? (
                    <li
                      className="icons_list"
                      style={{
                        borderRight: `5px solid  ${colorPalette.primaryCodeColor}`,
                      }}
                      onClick={() => handleTransporterDetails("Transporter")}
                    >
                      <img
                        src={transporterDetailsSelected}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />
                      {/* <LocalShippingOutlinedIcon style={{ color: "#46ceab" }} /> */}
                    </li>
                  ) : (
                    <li
                      className="icons_list"
                      onClick={() => handleTransporterDetails("Transporter")}
                    >
                      <img
                        src={transporterDetails}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />
                    </li>
                  )}
                  {transporterDetailsHeading == "Bank Details" ? (
                    <li
                      className="icons_list"
                      style={{
                        borderRight: `5px solid  ${colorPalette.primaryCodeColor}`,
                      }}
                      onClick={() => handleBankDetails("Bank Details")}
                    >
                      <img
                        src={transporterBankSelected}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />

                      {/* <AccountBalanceOutlinedIcon
                        style={{ color: "#46ceab" }}
                      /> */}
                    </li>
                  ) : (
                    <li
                      className="icons_list"
                      onClick={() => handleBankDetails("Bank Details")}
                    >
                      <img
                        src={transporterBank}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />
                    </li>
                  )}
                  {transporterDetailsHeading == "Attachments Details" ? (
                    <li
                      className="icons_list"
                      style={{
                        borderRight: `5px solid  ${colorPalette.primaryCodeColor}`,
                      }}
                      onClick={() =>
                        handleAttachmentsDetails("Attachments Details")
                      }
                    >
                      <img
                        src={vehcilesAttachmentsSelected}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />
                    </li>
                  ) : (
                    <li
                      className="icons_list"
                      onClick={() =>
                        handleAttachmentsDetails("Attachments Details")
                      }
                    >
                      <img
                        src={vehcilesAttachments}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />
                    </li>
                  )}

                  {transporterDetailsHeading == "Transporter History" ? (
                    <li
                      className="icons_list"
                      style={{
                        borderRight: `5px solid  ${colorPalette.primaryCodeColor}`,
                      }}
                      onClick={() =>
                        handleAttachmentsDetails("Transporter History")
                      }
                    >
                      <img
                        src={TripHistoryDetails}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />
                    </li>
                  ) : (
                    <li
                      className="icons_list"
                      onClick={() =>
                        handleAttachmentsDetails("Transporter History")
                      }
                    >
                      <img
                        src={TripHistoryDetailsSelected}
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons_commondrawer"
                        alt=""
                      />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </Transporter_Drawer>
        </Drawer>
      </div>
    );
  }
}

export default CommonDrawer;
