import { Close } from "@mui/icons-material";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { ButtonRectangle } from "../../themes/components/button";
import { Trip_list_modal_container } from "../../pages/ftl_trips/ftl_tripListV2/trip-listv2";
import UploadImage from "../../assets/upload.svg";
import { colorPalette } from "../../themes/theme";
import { handleInvoiceSent } from "../../pages/ftl_trips/ftl_tripListV2/tripListServices";
import { ApprovedModalStyle } from "./allModalExportFIleStyle";
import ReactSelect from "react-select";

const faarmsAndConnectIndiaEmailRegex =
  /\b[A-Za-z0-9._%+-]+@(faarms\.com|bscxpress\.com|connectindia\.com)\b/;

const approveRejectModal = ({
  open,
  handleClose,
  style,
  tripRemarks,
  setTripRemarks,
  errTripRemarks,
  setFaarmsAndConnectIndiaMailError,
  faarmsAndConnectIndiaMailError,
  currentStatusUpdateInProgress,
  showNegativeTripFields,
  customerBilledAmount,
  handleCustomerBilledAmountAndAdvancePaidByCustomerAtStatusChange,
  negativeRevenueTripApprovalEmail,
  setNegativeRevenueTripApprovalEmail,
  isNegativeRevenueTripApprovalEmailValid,
  advancePaidByCustomer,
  isMobile,
  colorPalette,
  setNegativeRevenueTripApprovalAttachment,
  typography,
  negativeRevenueTripApprovalAttachment,
  errTripStatus,
  selectedTrip,
  isRejectButtonClicked,
  beforeHandleSubmitRemarks,
  loadingModal,
  handleSubmitRemarks,
  handleClearApproveModalFields,
  isMaxFileSizeError,
  handleAttachmentChange,
  documentTypeList,
  isApprovedIntransit,
  teamsList,
  setTeamsSearchTerm,
  teamsSearchTerm,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ApprovedModalStyle
          style={
            currentStatusUpdateInProgress === "approved" ||
              currentStatusUpdateInProgress === "intransit"
              ? null
              : { height: "auto" }
          }
        >
          <div className="trip-list-close">
            <Close style={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
          <h4 className="trip-list-modal-head">
            Please enter the remarks<sup style={{ color: "red" }}>*</sup> (max.
            300 characters) :
          </h4>
          <textarea
            className="trip-list-modal-text-input"
            style={{ width: "100%" }}
            value={tripRemarks}
            onChange={(e) => {
              if (e.target.value?.length <= 300) {
                setTripRemarks(e.target.value);
              }
            }}
            rows={5}
          />
          <div className="trip-list-error">
            {tripRemarks.trim() ? null : errTripRemarks}
          </div>
          {currentStatusUpdateInProgress === "approved" ||
            currentStatusUpdateInProgress === "intransit" ? (
            <>
              <div
                style={{ marginBottom: "15px" }}
                className="customerBilledAdvancePaidDiv"
              >
                <div className="create-trip-cost-input-contain">
                  <div style={{ width: "max-content" }}>
                    Customer Billed Amount{" "}
                    <sup
                      style={{
                        color: "red",
                        position: "relative",
                      }}
                    >
                      *
                    </sup>
                  </div>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-cost-input"
                    variant="outlined"
                    style={{ width: "100%" }}
                    disabled={showNegativeTripFields}
                    name="customer_billed_amount"
                    // placeholder=""
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={customerBilledAmount}
                    onChange={
                      handleCustomerBilledAmountAndAdvancePaidByCustomerAtStatusChange
                    }
                  />
                </div>
                <div className="create-trip-cost-input-contain">
                  <div style={{ width: "max-content" }}>
                    Advance paid by Customer{" "}
                    <sup
                      style={{
                        color: "red",
                        position: "relative",
                      }}
                    >
                      *
                    </sup>
                  </div>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-cost-input"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={advancePaidByCustomer}
                    name="advance_paid_by_customer"
                    type="number"
                    onChange={
                      handleCustomerBilledAmountAndAdvancePaidByCustomerAtStatusChange
                    }
                    // placeholder=""
                    onWheel={(e) => e.target.blur()}
                  />
                </div>
              </div>
              <div style={{ marginBottom: "5px" }}>
                {showNegativeTripFields && customerBilledAmount ? (
                  <Alert
                    className="alertAllFieldsAreMandatory"
                    severity="error"
                  >
                    This is a negative margin trip.
                  </Alert>
                ) : null}
              </div>
              {showNegativeTripFields && customerBilledAmount ? (
                <div
                  style={{ marginBottom: "15px" }}
                  className="customerBilledAdvancePaidDiv"
                >
                  <div className="create-trip-cost-input-contain">
                    <div style={{ width: "max-content" }}>
                      Negative revenue trip approval email{" "}
                      <sup
                        style={{
                          color: "red",
                          position: "relative",
                        }}
                      >
                        *
                      </sup>
                    </div>
                    <ReactSelect
                      value={
                        negativeRevenueTripApprovalEmail
                          ? {
                            value: negativeRevenueTripApprovalEmail,
                            label: negativeRevenueTripApprovalEmail,
                          }
                          : ""
                      }
                      onChange={(event) => {
                        setNegativeRevenueTripApprovalEmail(event.value);
                      }}
                      onInputChange={(event) => {
                        setTeamsSearchTerm(event);
                      }}
                      options={teamsList.map((team) => ({
                        value: team.email,
                        label: team.email,
                      }))}
                      placeholder="Search Teams"
                    />
                    {/* {faarmsAndConnectIndiaMailError !== "" ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {faarmsAndConnectIndiaMailError}
                      </span>
                    ) : null} */}
                  </div>
                  <div
                    className="create-trip-cost-input-contain"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ width: "max-content" }}>
                      Negative trip approval attachment{" "}
                      <sup
                        style={{
                          color: "red",
                          position: "relative",
                        }}
                      >
                        *
                      </sup>
                    </div>

                    <TextField
                      size="small"
                      style={isMobile ? { width: "85%" } : { width: "100%" }}
                      className="create-trip-input-attachment dropdown-container"
                      variant="outlined"
                      placeholder="Enter negative trip approval attachment"
                      name="negative_trip_approval_attachmet"
                      disabled={true}
                      value={""}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          >
                            <button
                              className="upload_button"
                              style={{
                                backgroundColor: "#D7F2C9",
                                width: "45px",
                                height: "35px",
                                border: "none",
                              }}
                            >
                              <input
                                name="negative-trip-approval-attachment"
                                id={`negative-trip-approval-attachment`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={(e) => {
                                  setNegativeRevenueTripApprovalAttachment(
                                    e.target.files[0]
                                  );
                                }}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label
                                htmlFor={`negative-trip-approval-attachment`}
                              // style={{
                              //   backgroundColor:
                              //     colorPalette.secondaryWhite,
                              // }}
                              >
                                <img
                                  src={UploadImage}
                                  alt=""
                                  // width="20px"
                                  // height="20px"
                                  style={{
                                    backgroundColor: "#D7F2C9",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>

                    <div
                      className="create-trip-attachment-name"
                      style={{ fontSize: typography.paragraph_secondary }}
                    >
                      {negativeRevenueTripApprovalAttachment != null
                        ? negativeRevenueTripApprovalAttachment.name
                        : null}
                    </div>
                    <div className="error-trip">
                      {negativeRevenueTripApprovalAttachment
                        ? negativeRevenueTripApprovalAttachment.size >
                          process.env.REACT_APP_MAX_FILE_SIZE
                          ? ` File size is too large.( < ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                          : null
                        : null}
                    </div>
                  </div>
                </div>
              ) : null}

              <span style={{ color: "red", fontSize: "12px" }}>
                * All the fields are mandatory!
              </span>
            </>
          ) : null}

          {errTripStatus ? (
            <Alert severity="error">{errTripStatus}</Alert>
          ) : null}

          <div className="submit-clear-button">
            {selectedTrip?.trip_status === "created" &&
              !isRejectButtonClicked ? (
              customerBilledAmount &&
                advancePaidByCustomer &&
                tripRemarks &&
                (showNegativeTripFields
                  ? negativeRevenueTripApprovalAttachment &&
                  negativeRevenueTripApprovalEmail &&
                  faarmsAndConnectIndiaMailError == "" &&
                  !isMaxFileSizeError
                  : true) ? (
                <div className="trip-list-modal-btn-grp">
                  <button
                    className="trip-list-submit-btn"
                    onClick={beforeHandleSubmitRemarks}
                  >
                    {loadingModal ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              ) : (
                <div className="trip-list-modal-btn-grp">
                  <button
                    className="trip-list-submit-btn"
                    onClick={handleSubmitRemarks}
                    disabled
                    style={{
                      cursor: "not-allowed",
                      backgroundColor: "#454545",
                    }}
                  >
                    {loadingModal ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              )
            ) : null}

            {currentStatusUpdateInProgress === "approved" ||
              currentStatusUpdateInProgress === "intransit" ? (
              <div className="trip-list-modal-btn-grp">
                <button
                  className="trip-list-submit-btn"
                  onClick={handleClearApproveModalFields}
                  style={{
                    backgroundColor: "#454545",
                  }}
                >
                  Clear
                </button>
              </div>
            ) : null}
          </div>
          {isRejectButtonClicked ? (
            <div className="trip-list-modal-btn-grp">
              {" "}
              <button
                className="trip-list-submit-btn"
                onClick={handleSubmitRemarks}
                disabled={!tripRemarks}
                style={
                  !tripRemarks
                    ? { cursor: "not-allowed", backgroundColor: "#454545" }
                    : { cursor: "pointer" }
                }
              >
                {loadingModal ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          ) : null}
        </ApprovedModalStyle>
      </Box>
    </Modal>
  );
};

export const intransitCompleteModal = ({
  openIntransitCompleteModal,
  handleOpenIntransitComplete,
  handleCloseIntransitComplete,
  tripRemarks,
  setTripRemarks,
  errTripRemarks,
  style,
  attachmentListArray,
  handleAttachmentChange,
  handleAddAttachment,
  documentTypeList,
  isMobile,
  handleSubmitRemarks,
  loadingModal,
  currentStatusUpdateInProgress,
  errTripStatus,
}) => {
  return (
    <Modal
      open={openIntransitCompleteModal}
      onClose={handleCloseIntransitComplete}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} style={{ height: "auto" }}>
        <Trip_list_modal_container className="trip-list-modal-container">
          <div className="trip-list-close">
            <Close
              style={{ cursor: "pointer" }}
              onClick={handleCloseIntransitComplete}
            />
          </div>
          <h4 className="trip-list-modal-head">
            Please enter the remarks<sup style={{ color: "red" }}>*</sup> (max.
            300 characters) :
          </h4>
          <textarea
            className="trip-list-modal-text-input"
            style={{ width: "100%" }}
            value={tripRemarks}
            onChange={(e) => {
              if (e.target.value?.length <= 300) {
                setTripRemarks(e.target.value);
              }
            }}
            rows={5}
          />
          <div className="trip-list-error">
            {tripRemarks.trim() ? null : errTripRemarks}
          </div>

          {errTripStatus ? (
            <Alert severity="error">{errTripStatus}</Alert>
          ) : null}

          <div className="submit-clear-button">
            {tripRemarks ? (
              <div className="trip-list-modal-btn-grp">
                {" "}
                <button
                  className="trip-list-submit-btn"
                  onClick={handleSubmitRemarks}
                >
                  {loadingModal ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            ) : (
              <div className="trip-list-modal-btn-grp">
                {" "}
                <button
                  className="trip-list-submit-btn"
                  onClick={handleSubmitRemarks}
                  disabled
                  style={{
                    cursor: "not-allowed",
                    backgroundColor: "#454545",
                  }}
                >
                  {loadingModal ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            )}
          </div>
        </Trip_list_modal_container>
      </Box>
    </Modal>
  );
};

export const arrivedAtDestinationModal = ({
  setArrivedAtDate,
  openArrivedAtDestinationModal,
  handleCloseArrivedAtDestination,
  style,
  tripRemarks,
  setTripRemarks,
  errTripRemarks,
  currentStatusUpdateInProgress,
  arrivedAtDate,
  handleSubmitRemarks,
  loadingModal,
  errTripStatus,
  selectedTrip,
}) => {
  return (
    <Modal
      open={openArrivedAtDestinationModal}
      onClose={handleCloseArrivedAtDestination}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Trip_list_modal_container className="trip-list-modal-container">
          <div className="trip-list-close">
            <Close
              style={{ cursor: "pointer" }}
              onClick={handleCloseArrivedAtDestination}
            />
          </div>
          <h4 className="trip-list-modal-head">
            Please enter the remarks<sup style={{ color: "red" }}>*</sup> (max.
            300 characters) :
          </h4>
          <textarea
            className="trip-list-modal-text-input"
            style={{ width: "100%" }}
            value={tripRemarks}
            onChange={(e) => {
              if (e.target.value?.length <= 300) {
                setTripRemarks(e.target.value);
              }
            }}
            rows={5}
          />
          <div className="trip-list-error">
            {tripRemarks.trim() ? null : errTripRemarks}
          </div>

          <>
            <span style={{ fontSize: "12px", fontWeight: "600" }}>
              Arrived on<sup style={{ color: "red" }}>*</sup>:
            </span>
            <TextField
              id="standard-basic"
              variant="outlined"
              size="small"
              type="date"
              // placeholder="Enter payment status"
              inputProps={{
                max: new Date().toISOString().split("T")[0],
                min: selectedTrip.trip_start_date,
              }}
              className="trip-list-export-inputfield"
              style={{ width: "100%" }}
              value={arrivedAtDate}
              onChange={(e) => setArrivedAtDate(e.target.value)}
            />
          </>

          <div className="submit-clear-button">
            {tripRemarks && arrivedAtDate ? (
              <div className="trip-list-modal-btn-grp">
                {" "}
                <button
                  className="trip-list-submit-btn"
                  onClick={handleSubmitRemarks}
                >
                  {loadingModal ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            ) : (
              <div className="trip-list-modal-btn-grp">
                {" "}
                <button
                  className="trip-list-submit-btn"
                  onClick={handleSubmitRemarks}
                  disabled
                  style={{
                    cursor: "not-allowed",
                    backgroundColor: "#454545",
                  }}
                >
                  {loadingModal ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            )}
          </div>
          {errTripStatus ? (
            <Alert severity="error">{errTripStatus}</Alert>
          ) : null}
        </Trip_list_modal_container>
      </Box>
    </Modal>
  );
};

export const verifyDocumentModal = ({
  openVerifyDocumentModal,
  handleCloseVerifyDocumentModal,
  style,
  tripRemarks,
  setTripRemarks,
  errTripRemarks,
  currentStatusUpdateInProgress,
  handleSubmitRemarks,
  loadingModal,
  errTripStatus,
  isDocumentVarified,
  setIsDocumentVarified,
  newTripStatusIfDocumentNotVarified,
  setNewTripStatusIfDocumentNotVarified,
}) => {
  return (
    <Modal
      open={openVerifyDocumentModal}
      onClose={handleCloseVerifyDocumentModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Trip_list_modal_container className="trip-list-modal-container">
          <div className="trip-list-close">
            <Close
              style={{ cursor: "pointer" }}
              onClick={handleCloseVerifyDocumentModal}
            />
          </div>
          <h4 className="trip-list-modal-head">
            Please enter the remarks<sup style={{ color: "red" }}>*</sup> (max.
            300 characters) :
          </h4>
          <textarea
            className="trip-list-modal-text-input"
            style={{ width: "100%" }}
            value={tripRemarks}
            onChange={(e) => {
              if (e.target.value?.length <= 300) {
                setTripRemarks(e.target.value);
              }
            }}
            rows={5}
          />
          <div className="trip-list-error">
            {tripRemarks.trim() ? null : errTripRemarks}
          </div>

          {errTripStatus ? (
            <Alert severity="error">{errTripStatus}</Alert>
          ) : null}

          <div className="submit-clear-button">
            {tripRemarks ? (
              <div className="trip-list-modal-btn-grp">
                {" "}
                <button
                  className="trip-list-submit-btn"
                  onClick={handleSubmitRemarks}
                >
                  {loadingModal ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            ) : (
              <div className="trip-list-modal-btn-grp">
                {" "}
                <button
                  className="trip-list-submit-btn"
                  onClick={handleSubmitRemarks}
                  disabled
                  style={{
                    cursor: "not-allowed",
                    backgroundColor: "#454545",
                  }}
                >
                  {loadingModal ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            )}
          </div>
        </Trip_list_modal_container>
      </Box>
    </Modal>
  );
};

export const tripReopenModal = ({
  openReopenModal,
  handleCloseReopenModal,
  style,
  tripRemarks,
  setTripRemarks,
  errTripRemarks,
  currentStatusUpdateInProgress,
  handleSubmitRemarks,
  loadingModal,
  errTripStatus,
}) => {
  return (
    <Modal
      open={openReopenModal}
      onClose={handleCloseReopenModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Trip_list_modal_container className="trip-list-modal-container">
          <div className="trip-list-close">
            <Close
              style={{ cursor: "pointer" }}
              onClick={handleCloseReopenModal}
            />
          </div>
          <h4 className="trip-list-modal-head">
            Please enter the remarks<sup style={{ color: "red" }}>*</sup> (max.
            300 characters) :
          </h4>
          <textarea
            className="trip-list-modal-text-input"
            style={{ width: "100%" }}
            value={tripRemarks}
            onChange={(e) => {
              if (e.target.value?.length <= 300) {
                setTripRemarks(e.target.value);
              }
            }}
            rows={5}
          />
          <div className="trip-list-error">
            {tripRemarks.trim() ? null : errTripRemarks}
          </div>

          <div className="submit-clear-button">
            {tripRemarks ? (
              <div className="trip-list-modal-btn-grp">
                {" "}
                <button
                  className="trip-list-submit-btn"
                  onClick={handleSubmitRemarks}
                >
                  {loadingModal ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            ) : (
              <div className="trip-list-modal-btn-grp">
                {" "}
                <button
                  className="trip-list-submit-btn"
                  onClick={handleSubmitRemarks}
                  disabled
                  style={{
                    cursor: "not-allowed",
                    backgroundColor: "#454545",
                  }}
                >
                  {loadingModal ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            )}
          </div>
          {errTripStatus ? (
            <Alert severity="error">{errTripStatus}</Alert>
          ) : null}
        </Trip_list_modal_container>
      </Box>
    </Modal>
  );
};

export const tripDisputeModal = ({
  handleCloseDistpute,
  style,
  openDisputeModal,
  handleSubmitRemarks,
  tripRemarks,
  loadingModal,
  setTripRemarks,
  errTripStatus,
  errTripRemarks,
  handleSubmitRemarksToChangeTripStatusToDisputed,
  loadingDisputeModalButton,
  errTripDisputeModal,
  setChangedStatusAfterDisputeResolve,
  changedStatusAfterDisputeResolve,
}) => {
  return (
    <Modal
      open={openDisputeModal}
      onClose={handleCloseDistpute}
      onClick={(e) => e.stopPropagation()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Trip_list_modal_container
          className="trip-list-modal-container"
          style={{ position: "relative" }}
        >
          <div className="trip-list-close">
            <Close
              style={{ cursor: "pointer" }}
              onClick={handleCloseDistpute}
            />
          </div>

          <div style={{ width: "100%" }}>
            <h4 className="trip-list-modal-head">
              Select Resolved Status <sup style={{ color: "red" }}>*</sup>
            </h4>
            <TextField
              id=""
              select
              style={{ width: "100%", backgroundColor: "#fff" }}
              value={changedStatusAfterDisputeResolve}
              onChange={(e) => {
                setChangedStatusAfterDisputeResolve(e.target.value);
              }}
              variant="outlined"
              className="bank-input"
              color="success"
              size="small"
            >
              {["arrived_at_destination", "intransit", "goods_delivered"].map(
                (option, index) => (
                  <MenuItem key={index} value={option}>
                    {option.split("_").join(" ").toUpperCase()}
                  </MenuItem>
                )
              )}
            </TextField>
          </div>

          <h4 className="trip-list-modal-head">
            Please enter the remarks<sup style={{ color: "red" }}>*</sup> (max.
            300 characters) :
          </h4>
          <textarea
            className="trip-list-modal-text-input"
            style={{ width: "100%" }}
            value={tripRemarks}
            onChange={(e) => {
              if (e.target.value?.length <= 300) {
                setTripRemarks(e.target.value);
              }
            }}
            rows={5}
          />
          <div style={{ marginBottom: "5px" }}>
            {errTripDisputeModal ? (
              <Alert className="alertAllFieldsAreMandatory" severity="error">
                {errTripDisputeModal}
              </Alert>
            ) : null}
          </div>
          <div className="trip-list-modal-btn-grp">
            {" "}
            <button
              className="trip-list-submit-btn"
              onClick={() =>
                handleSubmitRemarksToChangeTripStatusToDisputed("trip_disputed")
              }
              disabled={!tripRemarks}
              style={
                !tripRemarks
                  ? { cursor: "not-allowed", backgroundColor: "#454545" }
                  : { cursor: "pointer" }
              }
            >
              {loadingDisputeModalButton ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
          {/* ) : null} */}
        </Trip_list_modal_container>
      </Box>
    </Modal>
  );
};

export const tripResolveModal = ({
  openResolveDisputeModal,
  setOpenResolveDisputeModal,
  style,
  tripRemarks,
  setTripRemarks,
  checked,
  handleCheckbox,
  handleResolveDisputeAndChangeStatus,
  loadingDisputeModalButton,
  errTripResolveDisputeModal,
  disputeResolveModalLoading,
}) => {
  return (
    <Modal
      open={openResolveDisputeModal}
      onClose={() => setOpenResolveDisputeModal(false)}
      onClick={(e) => e.stopPropagation()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Trip_list_modal_container
          className="trip-list-modal-container"
          style={{ position: "relative" }}
        >
          <div className="trip-list-close">
            <Close
              style={{ cursor: "pointer" }}
              onClick={() => setOpenResolveDisputeModal(false)}
            />
          </div>

          <h4 className="trip-list-modal-head">
            Please enter the remarks<sup style={{ color: "red" }}>*</sup> (max.
            300 characters) :
          </h4>
          <textarea
            className="trip-list-modal-text-input"
            style={{ width: "100%" }}
            value={tripRemarks}
            onChange={(e) => {
              if (e.target.value?.length <= 300) {
                setTripRemarks(e.target.value);
              }
            }}
            rows={5}
          />

          <div style={{ marginBottom: "5px", height: "40px" }}>
            {errTripResolveDisputeModal ? (
              <Alert className="alertAllFieldsAreMandatory" severity="error">
                {errTripResolveDisputeModal}
              </Alert>
            ) : (
              <div></div>
            )}
          </div>
          <div className="trip-list-modal-btn-grp">
            {" "}
            <button
              className="trip-list-submit-btn"
              onClick={handleResolveDisputeAndChangeStatus}
              disabled={!tripRemarks}
              style={
                !tripRemarks
                  ? { cursor: "not-allowed", backgroundColor: "#454545" }
                  : { cursor: "pointer" }
              }
            >
              {disputeResolveModalLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </Trip_list_modal_container>
      </Box>
    </Modal>
  );
};

export const tripCancleModal = ({
  openCancelModal,
  setOpenCancelModal,
  tripRemarks,
  setTripRemarks,
  errTripRemarks,
  style,
  isMobile,
  handleSubmitRemarks,
  loadingModal,
  currentStatusUpdateInProgress,
  errTripStatus,
}) => {
  return (
    <Modal
      open={openCancelModal}
      onClose={() => setOpenCancelModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Trip_list_modal_container className="trip-list-modal-container">
          <div className="trip-list-close">
            <Close
              style={{ cursor: "pointer" }}
              onClick={() => setOpenCancelModal(false)}
            />
          </div>
          <h4 className="trip-list-modal-head">
            Please enter the remarks<sup style={{ color: "red" }}>*</sup> (max.
            300 characters) :
          </h4>
          <textarea
            className="trip-list-modal-text-input"
            style={{ width: "100%" }}
            value={tripRemarks}
            onChange={(e) => {
              if (e.target.value?.length <= 300) {
                setTripRemarks(e.target.value);
              }
            }}
            rows={5}
          />
          <div className="trip-list-error">
            {tripRemarks?.trim() ? null : errTripRemarks}
          </div>
          <div className="submit-clear-button">
            {tripRemarks ? (
              <div className="trip-list-modal-btn-grp">
                {" "}
                <button
                  className="trip-list-submit-btn"
                  onClick={handleSubmitRemarks}
                >
                  {loadingModal ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            ) : (
              <div className="trip-list-modal-btn-grp">
                {" "}
                <button
                  className="trip-list-submit-btn"
                  onClick={handleSubmitRemarks}
                  disabled
                  style={{
                    cursor: "not-allowed",
                    backgroundColor: "#454545",
                  }}
                >
                  {loadingModal ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            )}
          </div>
          {errTripStatus ? (
            <Alert severity="error">{errTripStatus}</Alert>
          ) : null}
        </Trip_list_modal_container>
      </Box>
    </Modal>
  );
};

export const approvedTripCancelModal = ({
  approvedOpenCancelModal,
  setApprovedOpenCancelModal,
  tripRemarks,
  setTripRemarks,
  errTripRemarks,
  style,
  isMobile,
  handleSubmitRemarks,
  loadingModal,
  currentStatusUpdateInProgress,
  errTripStatus,
  setErrTripStatus,
}) => {
  return (
    <Modal
      open={approvedOpenCancelModal}
      onClose={() => {
        setApprovedOpenCancelModal(false);
        setTripRemarks("");
        setErrTripStatus("");
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Trip_list_modal_container className="trip-list-modal-container">
          <div className="trip-list-close">
            <Close
              style={{ cursor: "pointer" }}
              onClick={() => {
                setApprovedOpenCancelModal(false);
                setTripRemarks("");
                setErrTripStatus("");
              }}
            />
          </div>
          <h4 className="trip-list-modal-head">
            Please enter the remarks<sup style={{ color: "red" }}>*</sup> (max.
            300 characters) :
          </h4>
          <textarea
            className="trip-list-modal-text-input"
            style={{ width: "100%" }}
            value={tripRemarks}
            onChange={(e) => {
              if (e.target.value?.length <= 300) {
                setTripRemarks(e.target.value);
              }
            }}
            rows={5}
          />
          <div className="trip-list-error">
            {tripRemarks?.trim() ? null : errTripRemarks}
          </div>
          <div className="submit-clear-button">
            {tripRemarks ? (
              <div className="trip-list-modal-btn-grp">
                {" "}
                <button
                  className="trip-list-submit-btn"
                  onClick={handleSubmitRemarks}
                >
                  {loadingModal ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            ) : (
              <div className="trip-list-modal-btn-grp">
                {" "}
                <button
                  className="trip-list-submit-btn"
                  onClick={handleSubmitRemarks}
                  disabled
                  style={{
                    cursor: "not-allowed",
                    backgroundColor: "#454545",
                  }}
                >
                  {loadingModal ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            )}
          </div>
          {errTripStatus ? (
            <Alert severity="error">{errTripStatus}</Alert>
          ) : null}
        </Trip_list_modal_container>
      </Box>
    </Modal>
  );
};

export { approveRejectModal };
