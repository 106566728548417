import React from "react";
import "../onboard/termsCondition.css";
function TermsCondition() {
  return (
    <div className="container">
      <h5 className="text">
        This Terms of Use is executed at Bengaluru.(“Terms of Use”): This Terms
        of Use describe the terms and conditions applicable to Your access to
        www.faarms.com and its mobile application platform (“Platform”). This
        document is a legally binding agreement between You as a Transporter(s)
        of the Platform (referred to as “You”, “Your”, or “Carrier” hereinafter)
        and Faarms Global Tech Ventures Private Limited (referred to as “we”,
        “our” or “Faarms”, “Shipper” hereinafter). The Carrier and the Shipper
        shall be singularly referred to as “Party” and collectively, as
        “Parties”.
      </h5>

      <h4 className="heading">STANDARD TERMS AND CONDITIONS</h4>
      <ul
        style={{
          width: "95%",
          margin: "1rem auto",
        }}
      >
        <li>
          <h5 className="text">
            The Shipper shall obtain motor carrier transportation and related
            services to meet its distinct needs from the Carrier, and the
            Carrier is engaged in the business of transporting property and
            shall provide aforementioned services to the Shipper in terms of
            this Terms of Use.
          </h5>
        </li>
        <li>
          <h5 className="text">
            The Carrier shall provide the service relating to transport the
            goods anywhere to India.
          </h5>
        </li>
        <li>
          <h5 className="text">
            The Carrier shall, at its sole cost and expense, furnish all fuel,
            oil, tires and other parts, supplies and equipment necessary or
            required for the safe and efficient operation and maintenance of the
            motor vehicles and related equipment furnished by Carrier for the
            performance of its obligations hereunder, hereinafter collectively
            designated as Equipment.
          </h5>
        </li>
        <li>
          <h5 className="text">
            The Carrier shall pay all expenses of every nature, including the
            expense of road service and repair, in connection with the use and
            operation of the Equipment and shall, at its sole cost and expense,
            at all times during the term of this Terms of Use maintain the
            Equipment in good repair, mechanical condition and appearance,
            Carrier, at its sole cost and expense, shall utilize only legally
            licensed personnel in the operation of the Equipment.
          </h5>
        </li>
        <li>
          <h5 className="text">
            The Carrier shall ensure that the following documents are
            categorically on record at all times during the term of this Terms
            of Use: the Registration Certificate or the RC, the Pollution Under
            Control or the PUC Certificate, the insurance Papers, the commercial
            vehicle license of the specific vehicle of the shipment and all such
            other documents required under applicable law.
          </h5>
        </li>
        <li>
          <h5 className="text">
            The following drivers’ documents shall additionally be provided
            namely, the driver’s license and the Commercial Vehicle license.
          </h5>
        </li>
        <li>
          <h5 className="text">
            The Carrier, at its sole cost and expense, shall procure and
            maintain all licenses and permits required by local, state or
            federal authorities with respect to the transportation and related
            services rendered hereunder and shall comply with all applicable
            laws and regulations pertaining to such transportation and services.
          </h5>
        </li>
        <li>
          <h5 className="text">
            Payment mode and consideration amount shall be decided by mutual
            agreement between the Shipper and the Carrier and may be amended
            time to time.
          </h5>
        </li>
      </ul>
      <h4 className="heading">LIABILITY AND INDEMNIFICATION</h4>
      <ul
        style={{
          width: "95%",
          margin: "1rem auto",
        }}
      >
        <li>
          <h5 className="text">
            The Carrier shall be held liable to indemnify and hold harmless the
            Shipper, its parent, subsidiary and affiliates, and their respective
            directors, officers, employees and agents (each an “Indemnified
            Party”), from and against any and all liabilities, losses, damages,
            expenses in connection with:
          </h5>
        </li>
      </ul>
      <ol
        style={{
          width: "90%",
          margin: "1rem auto",
        }}
      >
        <li>
          <h5 className="text">
            {" "}
            Any loss in transit of the shipment as determined in this Terms of
            Use including the failure of the Carrier to take reasonable care for
            the proper transportation of the goods in the shipment;
          </h5>
        </li>
        <li>
          <h5 className="text">
            {" "}
            Any theft of the part or whole of the shipment as determined in this
            Terms of Use including the failure of the Carrier to take reasonable
            care for the security of the goods in the shipment;
          </h5>
        </li>
        <li>
          <h5 className="text">
            {" "}
            Any seizure of the part or whole of the shipment as determined in
            this Terms of Use, by the Regional Transport Office (RTO) or the
            District Transport Office (DTO) including any failure of the Carrier
            to ensure proper care at the time of transportation of the shipment;
          </h5>
        </li>
        <li>
          <h5 className="text">
            {" "}
            Any human injury caused during the transportation of part or whole
            of the shipment, including the failure of the Carrier to take
            reasonable care at the time of transportation of the shipment;
          </h5>
        </li>
        <li>
          <h5 className="text">
            {" "}
            Any event of battery blast or any technical harm to the
            transportation motor vehicle during the delivery of the shipment as
            determined in the Agreement, including any failure of the Carrier to
            ensure proper maintenance of the transportation motor vehicles.
          </h5>
        </li>
      </ol>
      <h4 className="heading">TERMINATION</h4>
      <ul
        style={{
          width: "95%",
          margin: "1rem auto",
        }}
      >
        <li>
          <h5 className="text">
            Faarms reserves the right to terminate this Terms of Use at any
            point of time if it observes that
          </h5>
        </li>
      </ul>
      <ol
        style={{
          width: "90%",
          margin: "1rem auto",
        }}
      >
        <li>
          <h5 className="text">Delivery of products is not within time.</h5>
        </li>
        <li>
          <h5 className="text">
            {" "}
            Products have been damaged due to bad handling.
          </h5>
        </li>
        <li>
          <h5 className="text">
            The Carrier has engaged in unprofessional behaviour.
          </h5>
        </li>
      </ol>
      <h4 className="heading">NOTICES</h4>
      <ul
        style={{
          width: "95%",
          margin: "1rem auto",
        }}
      >
        <li>
          <h5 className="text">
            Notices, demands or other communications required or permitted to be
            given or made under this Terms of Use shall be in writing and
            delivered personally or sent by courier or sent by registered letter
            or a similar instrument in writing or by telefax, addressed to the
            other Party as follows:
          </h5>
        </li>
      </ul>
      <ol
        style={{
          width: "90%",
          margin: "1rem auto",
        }}
      >
        <li>
          <h5 className="text">
            if to Carrier, to: Contact Person: [●] Email: [●] Phone: [●]{" "}
          </h5>
        </li>
        <li>
          <h5 className="text">
            if to Shipper, to: Contact Person: [●] Email: [●] Phone: [●]
          </h5>
        </li>
        <li>
          <h5 className="text">
            or to such other address or telefax number as either Party may from
            time to time duly notify to the other Party.
          </h5>
        </li>
      </ol>
      <h4 className="heading">FORCE MAJEURE</h4>
      <ul
        style={{
          width: "95%",
          margin: "1rem auto",
        }}
      >
        <li>
          <h5 className="text">
            If the performance of any part of this Terms of Use by either Party
            of any obligation under this Terms of Use is prevented, restricted,
            interfered with or delayed by reason of any cause beyond the
            reasonable control of the Party liable to perform it (including but
            not limited to acts of God, acts of governments, war, riots,
            earthquakes, floods, fire, strike, sabotage, raw materials shortage,
            breakdown of public utilities, pandemic, interruption in operation
            or inconsistency of performance or functionality of date-based
            systems or applications), unless conclusive evidence to the contrary
            is provided, the Party so affected shall inform the other Party
            immediately by giving written notice and thereupon such Party will
            be excused from its performance to the extent of such prevention,
            restriction, interference or delay, provided that the affected Party
            shall use its commercially reasonable efforts to avoid or remove
            such causes of non-performance and to mitigate the effects and shall
            continue performance with utmost dispatch whenever such causes are
            removed. All obligations of the Parties will be suspended at the
            option of the affected Party during a force majeure event.
          </h5>
        </li>
      </ul>
      <ol
        style={{
          width: "90%",
          margin: "1rem auto",
        }}
      >
        <li>
          <h5 className="text">
            Where the force majeure event shall have continued for 30 (thirty)
            days, either Party will have a right to terminate this Terms of Use.
          </h5>
        </li>
      </ol>
      <h4 className="heading">MISCELLANEOUS</h4>
      <ul
        style={{
          width: "95%",
          margin: "1rem auto",
        }}
      >
        <li>
          <h5>Governing Law</h5>
        </li>
      </ul>
      <ol
        style={{
          width: "90%",
          margin: "1rem auto",
        }}
      >
        <h5 className="text">
          This Terms of Use shall be governed by and interpreted in all respects
          in accordance with the laws of India. In case of any dispute between
          the Parties which cannot be resolved amicably, subject to arbitration
          under the Arbitration and Conciliation Act of 1996, the competent
          court located at Bengaluru, India, shall have exclusive jurisdiction.
        </h5>
      </ol>
      <ul
        style={{
          width: "95%",
          margin: "1rem auto",
        }}
      >
        <li>
          <h5>Assignment/Transfer. </h5>
        </li>
      </ul>
      <ol
        style={{
          width: "90%",
          margin: "1rem auto",
        }}
      >
        <h5 className="text">
          Faarms shall always be entitled to assign and transfer the rights and
          obligations under this Term of Use in whole or in part to an affiliate
          or to any third party without any liability whatsoever from the date
          of such transfer or assignment without Your consent.
        </h5>
      </ol>
    </div>
  );
}

export default TermsCondition;
