import React, { useEffect, useState } from "react";

import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
// import apiUrl from "../../url/apiUrl";
import UPLOAD from "../../assets/upload.svg";
import { ArrowBack } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import DeleteIcon from "@mui/icons-material/Delete";
import FeedIcon from "@mui/icons-material/Feed";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import * as moment from "moment";
import UploadImage from "../../assets/upload.svg";
// import {
//   PaymentRecordPost,
//   getPaymentRecords,
//   getPaymentAttachments,
//   getTripCost,
//   getTripDetails,
//   getTripDetailsById,
//   getbankDetailsByVehicleNumber,
// } from "../recordPayment/recordPaymentServices";
import { getbankDetailsByVehicleNumber } from "../recordPayment/recordPaymentServices";
import { updateSelectedTrip, updateTripDetail } from "../../redux/tripSlice";
import { useSelector, useDispatch } from "react-redux";
import apiUrl from "../../url/apiUrl";
import { paymentBalance } from "../../redux/paymentSlice";
// import { useDispatch } from "react-redux";
import { CircularProgress, TextField, InputAdornment } from "@mui/material";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useParams } from "react-router-dom";
import { getVehiclesListById } from "../vehicles/VehiclesServices";
import { paymentDetails } from "../../redux/paymentSlice";
// import { useSelector, useDispatch } from "react-redux";
import {
  MonthlyPaymentRecordPost,
  getMonthlyPaymentRecords,
} from "./MonthlyWisePaymentService";
import { Record_payment_main_container } from "./monthlywise.js";
import { colorPalette } from "../../themes/theme";

function MonthlyWisePayments() {
  const dataMonthly = useSelector(
    (state) => state.paymentValues.monthlyDetails
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { transporterId, vehicleId } = useParams();
  const [tripDate, setTripDate] = useState("");
  const [tripDateNew, setTripDateNew] = useState("");
  const [vehicleDetailsPay, setVehicleDetailsPay] = useState({});
  const calDate = (val) => {
    // if (val.moment !== undefined) {
    const calculated = moment.utc(val).local().format("YYYY-MM");
    const calculatedNew = moment.utc(val).local().format("YYYY-MM-DD");

    setTripDate(calculated);
    setTripDateNew(calculatedNew);

    // }
  };

  useEffect(() => {
    calDate(vehicleDetailsPay);
  }, [vehicleDetailsPay]);

  const [transporterBankDetails, setTransporterBankDetails] = useState([]);
  const [totals, setTotals] = useState("");
  //   const { id } = useParams();
  //   const dispatch = useDispatch();
  //   const history = useHistory();
  const [amount, setAmount] = useState("");
  const [unuthorized, setUnuthorized] = useState("");
  //
  const [refId, setRefId] = useState("");
  const [referencePhoto, setReferencePhoto] = useState({});
  const [successMsg, setSuccessMsg] = useState("");
  // const [paymentHistory, setPaymentHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updatedRecord, setUpdatedRecord] = useState([]);
  //   const [tripCostData, setTripCostData] = useState([]);
  //   const [tripCostTotal, setTripCostTotal] = useState("");
  //   const [perBagCost, setPerBagCost] = useState("");
  const [balance, setBalance] = useState("");
  const [exceedMsg, setExceedMsg] = useState("");
  //   const [errPaymentAmount, setErrPaymentAmount] = useState("");
  //   const [errPaymentRefId, setErrPaymentRefId] = useState("");
  //   const [transporterBankDetails, setTransporterBankDetails] = useState([]);
  //   const { selectedTrip } = useSelector((state) => state.trip);

  //   const handleGoToTrips = () => {
  //     history.push("/faarms-trip");
  //   };

  //   const [paymentListAttachments, setPaymentListAttachments] = useState();
  const getVahicleDataByIdPayments = async (carrier, id) => {
    const vehicleResponsePayment = await getVehiclesListById(carrier, id);
    setVehicleDetailsPay(vehicleResponsePayment);
  };
  useEffect(() => {
    getVahicleDataByIdPayments(transporterId, vehicleId);
  }, [transporterId, vehicleId]);

  //
  const bankDetails = async (vehicle) => {
    const response = await getbankDetailsByVehicleNumber(vehicle);

    setTransporterBankDetails(response.data.bank_details_list);
  };
  useEffect(() => {
    // if (Object.keys(selectedTrip).length > 0) {
    bankDetails(vehicleDetailsPay.vehicle_number);
  }, [vehicleDetailsPay.vehicle_number]);

  const reducer = (accumulator, curr) => accumulator + curr;
  // const submitAmounts = (monthlydata, totalDis) => {
  //   const totalSubmit =
  //     monthlydata.extra_per_km_rate *
  //     (totalDis.total_distance_travelled - monthlydata.fixed_kms);
  //   // setTotals(totalAmountKms);
  // };

  const extrakmAmounts = (monthlydata, totalDis) => {
    const totalAmountKms =
      monthlydata.extra_per_km_rate *
      (totalDis.total_distance_travelled - monthlydata.fixed_kms);
    setTotals(totalAmountKms);
  };
  useEffect(() => {
    extrakmAmounts(vehicleDetailsPay, dataMonthly);
  }, [vehicleDetailsPay, dataMonthly]);

  const handleMonthlyPayment = async () => {
    const isValid = formValidation();
    if (isValid) {
      if (amount <= vehicleDetailsPay.basic_payout_amount + totals) {
        setLoading(true);
        const responseMonthlyPay = await MonthlyPaymentRecordPost(
          referencePhoto,
          vehicleDetailsPay.id,
          "vehicle_payment",
          tripDate,
          true,
          amount,
          refId,
          referencePhoto.type
        );

        if (responseMonthlyPay.status == 200) {
          setRefId("");
          setReferencePhoto("");
          setAmount("");
          setLoading(false);
          getVahicleDataByIdPayments(transporterId, vehicleId);
          setSuccessMsg("Payment updated successfully!");
          getPaymentsRecord(vehicleDetailsPay.vehicle_number, tripDateNew);
        } else if (responseMonthlyPay.status == 401) {
          setUnuthorized("Unauthorized Access!");
          setSuccessMsg("");
          setSuccessMsg("");
        }
      } else {
        setExceedMsg("Entered amount is more than the balance.");
        setSuccessMsg("");
        setUnuthorized("");
        setSuccessMsg("");
        // setExceedMsg("");
        setLoading(false);
      }
    }
    setLoading(false);
    //
  };
  const [errorAmt, setErrorAmt] = useState("");
  const [errorReference, setErrorReference] = useState("");
  const formValidation = () => {
    const errorAmount = {};
    const errorReferenceId = {};
    let isValid = true;
    if (amount == "") {
      errorAmount.erroramt = "Please Enter Amount";
      isValid = false;
    }
    if (refId == "") {
      errorReferenceId.errorRef = "Please Enter referenceId";
      isValid = false;
    }

    setErrorAmt(errorAmount);
    setErrorReference(errorReferenceId);
    return isValid;
  };
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const onFileChange = (event) => {
    // setTripAttachmentFile(event.target.files);
    setReferencePhoto(event.target.files[0]);
    // setErrorPhoto({});

    // setErrorPhoto("");
  };
  const getPaymentsRecord = async (vehicleNum, dateTrip) => {
    const responseGetRecord = await getMonthlyPaymentRecords(
      vehicleNum,
      dateTrip
    );
    setUpdatedRecord(responseGetRecord.data);
  };
  useEffect(() => {
    getPaymentsRecord(vehicleDetailsPay.vehicle_number, tripDateNew);
    // getPaymentsRecord(vehicleDetailsPay.vehicle_number, "2022-06-01");
  }, [vehicleDetailsPay, tripDateNew]);
  useEffect(() => {
    getBalance(updatedRecord);
  }, [updatedRecord]);
  const getBalance = () => {
    let total = 0;
    let totalHistory = 0;
    let advance = 0;
    if (updatedRecord !== undefined && updatedRecord.length > 0) {
      total = updatedRecord
        .filter((advance) => advance.cost_type !== "advance")
        .filter((cost) => cost.cost_type !== "per_bag_cost")
        .map((data) => data.amount)
        .reduce(reducer);

      let advanceTemp = updatedRecord.filter(
        (data) => data.cost_type == "advance"
      );
      if (advanceTemp.length > 0) {
        advance = advanceTemp[0].amount;
      }
      if (updatedRecord.length > 0) {
        totalHistory = updatedRecord.map((data) => data.amount).reduce(reducer);
      }
    }
    setBalance(totalHistory);
    dispatch(paymentBalance(totalHistory));
  };
  const handleGoToTrips = () => {
    history.push("/payments");
  };
  return (
    <Record_payment_main_container className="record-payment-main-container">
      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Monthly Payment Record" />
        </div>
      ) : (
        <div className="record-payment-sidebar">
          <SideBarMain />
        </div>
      )}
      <div className="record-payment-right-main-container">
        <div>
          {" "}
          <div className="record-payment-top-row">
            {" "}
            <ArrowBack
              style={{ cursor: "pointer" }}
              onClick={handleGoToTrips}
            />{" "}
            <span
              style={{
                marginLeft: "10px",
                fontWeight: "500",
                fontSize: "17px",
              }}
            >
              Payment Details
            </span>
          </div>
        </div>
        <div className="monthly_payment_container">
          <div className="container1">
            <div className="monthly_payments_details">
              <h4
                style={{
                  //   marginLeft: "10px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  display: "flex",
                  color: "green",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                Vehicle details
              </h4>
              <div className="details_keys">
                <h5 className="values_payments_keys">Vehicle Number</h5>
                {Object.keys(vehicleDetailsPay).length > 0 ? (
                  <h5 style={{ color: "grey" }} className="values_payments">
                    : {vehicleDetailsPay.vehicle_number}
                  </h5>
                ) : (
                  "-"
                )}
              </div>
              <div className="details_keys">
                {" "}
                <h5 className="values_payments_keys">Permit Number</h5>
                {Object.keys(vehicleDetailsPay).length > 0 ? (
                  <h5 style={{ color: "grey" }} className="values_payments">
                    : {vehicleDetailsPay.permit_number}
                  </h5>
                ) : null}
              </div>
              <div className="details_keys">
                {" "}
                <h5 className="values_payments_keys">Basic Payout Amount</h5>
                {Object.keys(vehicleDetailsPay).length > 0 ? (
                  <h5 style={{ color: "grey" }} className="values_payments">
                    : ₹ {vehicleDetailsPay.basic_payout_amount}
                  </h5>
                ) : null}
              </div>
              <div className="details_keys">
                {" "}
                <h5 className="values_payments_keys">
                  Total distance travelled
                </h5>{" "}
                {Object.keys(vehicleDetailsPay).length > 0 ? (
                  <h5 style={{ color: "grey" }} className="values_payments">
                    : {/* {vehicleDetailsPay.permit_number} */}
                    {dataMonthly ? dataMonthly.total_distance_travelled : "--"}
                  </h5>
                ) : null}
              </div>
              <div className="details_keys">
                {" "}
                <h5 className="values_payments_keys">Extra per Kms rate</h5>
                {Object.keys(vehicleDetailsPay).length > 0 ? (
                  <h5 style={{ color: "grey" }} className="values_payments">
                    : ₹ {vehicleDetailsPay.extra_per_km_rate}
                  </h5>
                ) : null}
              </div>
              <div className="details_keys">
                {" "}
                <h5 className="values_payments_keys">Fixed Kms</h5>
                {Object.keys(vehicleDetailsPay).length > 0 ? (
                  <h5 style={{ color: "grey" }} className="values_payments">
                    : {vehicleDetailsPay.fixed_kms} Kms
                  </h5>
                ) : null}
              </div>
              <div className="details_keys">
                {" "}
                <h5 className="values_payments_keys">Cut off day of month</h5>
                {Object.keys(vehicleDetailsPay).length > 0 ? (
                  <h5 style={{ color: "grey" }} className="values_payments">
                    : {vehicleDetailsPay.payout_cutoff_day_of_month} Kms
                  </h5>
                ) : null}
              </div>
            </div>
            {transporterBankDetails.length > 0 ? (
              <div className="monthly_payments_details">
                <h4
                  style={{
                    marginLeft: "10px",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  Transporter's Primary Bank Account Details
                  <CheckCircleIcon
                    style={{
                      color: colorPalette.primaryColor,
                      fontSize: "18px",
                      marginLeft: "10px",
                    }}
                  />
                </h4>
                {transporterBankDetails.length > 0
                  ? transporterBankDetails
                    .filter(
                      (data) =>
                        data.entity_type == "transporter" &&
                        data.is_primary == true
                    )
                    .map((bank, index) => (
                      <div
                        className="record-payment-row"
                        style={{ margin: ".5rem 0rem" }}
                        key={index}
                      >
                        <h5
                          style={{
                            fontWeight: "500",
                            marginLeft: "10px",
                            position: "relative",
                            padding: ".3rem 0rem",
                          }}
                        >
                          A/c Holder Name :
                          <span className="bank_attributes">
                            : {bank.account_holder_name}
                          </span>{" "}
                        </h5>
                        <h5
                          style={{
                            fontWeight: "500",
                            marginLeft: "10px",
                            position: "relative",
                            padding: ".3rem 0rem",
                          }}
                        >
                          A/c Number :
                          <span className="bank_attributes">
                            : {bank.bank_account_number}
                          </span>{" "}
                        </h5>
                        <h5
                          style={{
                            fontWeight: "500",
                            marginLeft: "10px",
                            position: "relative",
                            padding: ".3rem 0rem",
                          }}
                        >
                          A/c Type :
                          <span className="bank_attributes">
                            :{" "}
                            {bank.bank_account_type
                              .split("_")
                              .join(" ")
                              .toUpperCase()}
                          </span>{" "}
                        </h5>
                        <h5
                          style={{
                            fontWeight: "500",
                            marginLeft: "10px",
                            position: "relative",
                            padding: ".3rem 0rem",
                          }}
                        >
                          Bank Name :
                          <span className="bank_attributes">
                            : {bank.bank_name}
                          </span>{" "}
                        </h5>
                        <h5
                          style={{
                            fontWeight: "500",
                            marginLeft: "10px",
                            position: "relative",
                            padding: ".3rem 0rem",
                          }}
                        >
                          Ifsc Code :
                          <span className="bank_attributes">
                            : {bank.ifsc_code}
                          </span>{" "}
                        </h5>
                      </div>
                    ))
                  : null}
              </div>
            ) : null}
            <div className="monthly_payments_details">
              <div className="trip_total_headings">
                <h5 style={{ width: "98%", padding: ".3rem .3rem" }}>
                  Cost Break-up:
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItem: "center",
                  justifyContent: "space-between",
                  padding: "0rem .5rem",
                }}
              >
                <h5> Extra per km rate:</h5>{" "}
                <h5 style={{ color: "green" }}>
                  ₹ {vehicleDetailsPay.extra_per_km_rate}
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItem: "center",
                  justifyContent: "space-between",
                  padding: ".5rem .5rem",
                }}
              >
                <h5> Basic Payout Amount:</h5>{" "}
                <h5 style={{ color: "green" }}>
                  ₹ {vehicleDetailsPay.basic_payout_amount}
                </h5>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItem: "center",
                  justifyContent: "space-between",
                  padding: "0 .5rem",
                }}
              >
                <h5> Extra Kms Amount:</h5>{" "}
                <h5 style={{ color: "green" }}>
                  {dataMonthly.total_distance_travelled -
                    vehicleDetailsPay.fixed_kms}
                  kms X ₹ {vehicleDetailsPay.extra_per_km_rate} = ₹ {totals}
                </h5>
              </div>
              <hr
                style={{
                  backgroundColor: "black",
                  width: "100%",
                  margin: ".3rem 0rem",
                }}
              />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItem: "center",
                  justifyContent: "space-between",
                  padding: ".5rem .5rem",
                }}
              >
                <h5>Total Payable Amount:</h5>{" "}
                {vehicleDetailsPay.basic_payout_amount + totals - balance >=
                  0 ? (
                  <>
                    {" "}
                    <h5 style={{ color: "red" }}>
                      ₹{" "}
                      {vehicleDetailsPay.basic_payout_amount + totals - balance}
                    </h5>
                  </>
                ) : (
                  "No due amount"
                )}
              </div>
            </div>
          </div>
          <div className="container2">
            {/* {vehicleDetailsPay.basic_payout_amount + totals - balance == 0 ? ( */}
            <div className="monthly_payments_details">
              <span className="record-payment-payment-details-head">
                Enter Payment Details
              </span>
              <div className="record-payment-row">
                <div className="record-payment-input-container">
                  <h4 className="record-payment-input-head">
                    Enter the amount :
                  </h4>
                  <TextField
                    className="record-payment-inputfield"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    size="small"
                    placeholder="Enter the amount"
                    value={amount}
                    autoComplete="off"
                    onChange={(e) => {
                      setAmount(e.target.value);
                      setErrorAmt("");
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ fontFamily: "sans-serif" }}
                        >
                          {" "}
                          ₹{" "}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <h5>
                    {" "}
                    {Object.keys(errorAmt).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                          {errorAmt[key]}
                        </h6>
                      );
                    })}
                  </h5>

                  {/* <input
                    className="record-payment-inputfield"
                    type="number"
                    placeholder="Enter the amount"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  /> */}

                  <div className="record-payment-error">
                    {/* {amount > 0 ? null : errPaymentAmount} */}
                  </div>
                </div>
                <div className="record-payment-input-container">
                  <h4 className="record-payment-input-head">Reference Id :</h4>
                  <TextField
                    size="small"
                    className="record-payment-inputfield"
                    placeholder="Enter Reference Id"
                    value={refId}
                    autoComplete="off"
                    onChange={(e) => {
                      setRefId(e.target.value);
                      setErrorReference("");
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{
                            fontFamily: "sans-serif",
                            color: "transparent",
                          }}
                        >
                          {" "}
                          ₹{" "}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <h5>
                    {Object.keys(errorReference).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                          {errorReference[key]}
                        </h6>
                      );
                    })}
                  </h5>
                </div>

                <div className="record-payment-input-container-reference">
                  <h4 className="record-payment-input-head">
                    Upload Reference document :
                  </h4>
                  {/* <div className="record-payment-reference-input"> */}
                  <TextField
                    size="small"
                    id="outlined-basic"
                    disabled
                    className="record-payment-inputfield"
                    sx={{
                      width: "30ch",
                      height: "6ch",
                    }}
                    // style={{ width: "14rem" }}
                    // select
                    autoComplete="off"
                    // className="vehicle-input-attachment dropdown-container "
                    variant="outlined"
                    placeholder="Upload document"
                    name="attachment_tag"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{
                            cursor: "pointer",
                            color: colorPalette.primaryColor,
                            fontWeight: "600",
                          }}
                        >
                          <button className="upload_button">
                            <input
                              // name="upload"
                              // className="record-payment-inputfield"
                              name="attachment_file"
                              id="insurance"
                              type="file"
                              accept="image/png, image/jpeg, application/pdf"
                              onChange={onFileChange}
                              hidden
                              style={{
                                backgroundColor: colorPalette.secondaryWhite,
                              }}
                            />
                            <label
                              htmlFor="insurance"
                              style={{
                                backgroundColor: colorPalette.secondaryWhite,
                              }}
                            >
                              <img
                                src={UploadImage}
                                alt=""
                                width="20px"
                                height="20px"
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                            </label>
                          </button>
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                  <h6>{referencePhoto ? referencePhoto.name : null}</h6>
                  {referencePhoto ? (
                    referencePhoto.size >
                      process.env.REACT_APP_MAX_FILE_SIZE ? (
                      <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                        Please upload file size less than {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
                      </h6>
                    ) : null
                  ) : null}
                  <div className="record-payment-error">
                    {/* {refId.length > 3 ? null : errPaymentRefId} */}
                  </div>

                  {/* <input
                          className="record-payment-inputfield"
                          placeholder="Enter Reference id"
                          value={refId}
                          onChange={(e) => {
                            setRefId(e.target.value);
                          }}
                        /> */}
                  {/* <div className="record-payment-right-upload">
                      <input
                        type="file"
                        accept="image/png, image/jpeg, application/pdf"
                        id="file"
                        aria-label="File browser example"
                        className="custom-file-input"
                        onChange={handleDocument}
                      />
                     
                    </div> */}
                  {/* </div> */}
                  {/* {console.log("exceed msg", exceedMsg)} */}
                </div>
              </div>
              <div className="record-payment-alert-container">
                {successMsg ? (
                  <Alert
                    severity="success"
                    style={isMobile ? { width: "95%" } : { width: "70%" }}
                  >
                    <h5 style={{ textAlign: "center" }}>{successMsg}</h5>
                  </Alert>
                ) : null}
                {unuthorized ? (
                  <Alert
                    severity="warning"
                    style={isMobile ? { width: "95%" } : { width: "70%" }}
                  >
                    <h5 style={{ textAlign: "center" }}>{unuthorized}</h5>
                  </Alert>
                ) : null}

                {exceedMsg ? (
                  <Alert
                    severity="warning"
                    style={isMobile ? { width: "95%" } : { width: "70%" }}
                  >
                    <h5 style={{ textAlign: "center" }}>{exceedMsg}</h5>
                  </Alert>
                ) : null}
              </div>
              <button className="update-payment" onClick={handleMonthlyPayment}>
                {loading ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "UPDATE PAYMENT"
                )}
                {/* Update Payment */}
              </button>
            </div>
            {/* ) : null} */}
            {/* {vehicleDetailsPay.basic_payout_amount + totals - balance == 0?():() } */}
            <div className="record-payment-data-box_history">
              <h6
                style={{
                  color: colorPalette.black,
                  fontSize: "17px",
                  margin: "5px 0px",
                  fontWeight: "500",
                }}
              >
                PAYMENT HISTORY
              </h6>
              <div className="record-payment-data-box_history_details">
                {updatedRecord !== undefined && updatedRecord.length > 0 ? (
                  updatedRecord.map((data, index) => (
                    <div
                      className="partial-paid"
                      key={index}
                    //   onClick={() => {
                    //     handleSingleData(data.id);
                    //   }}
                    >
                      <div className="single-item">
                        <div className="payment-reference">
                          {" "}
                          <h5>Payment Ref.id:</h5>
                          <span
                            style={{ marginLeft: "10px", fontSize: "15px" }}
                          >
                            {data.transaction_reference_id}
                          </span>
                        </div>

                        <div className="payment-reference">
                          {" "}
                          <h5>Paid On:</h5>
                          <span
                            style={{ marginLeft: "10px", fontSize: "12px" }}
                          >
                            {data.created_on.slice(0, 10)}
                          </span>
                        </div>
                      </div>
                      <div className="record-payment-docs">
                        <div style={{ marginRight: "15px", fontWeight: "500" }}>
                          <div> Rs.{data.amount} </div>
                        </div>
                        {/* <div style={{ marginRight: "15px" }}>
                          <a
                            href={`${apiUrl.onboardingBaseUrl}/v1/image/trip-payment/${data.id}`}
                            download
                            target="_blank"
                            className="attachment_btn"
                            // onClick={() => {
                            //   handleSingleData(data.id);
                            // }}
                          >
                            Doc
                          </a>
                        </div> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: colorPalette.primaryColor,
                    }}
                  >
                    <h5>No payment history found</h5>
                  </div>
                )}
              </div>

              <div className="record-payment-history-bottom-container">
                {" "}
                <div className="record-payment-balance-div">
                  {balance > 0 ? (
                    <>
                      <h5>Total Paid Amount : </h5>
                      <span
                        style={{
                          marginLeft: "10px",
                          fontSize: "15px",
                          color: "green",
                          fontWeight: "500",
                        }}
                      >
                        Rs. {balance}
                      </span>
                    </>
                  ) : (
                    <h4 style={{ color: colorPalette.primaryColor }}>
                      NO DUE AMOUNT
                    </h4>
                  )}
                </div>
                {/* <div className="record-payment-balance-div">
                  <h5>Advance:</h5>
                  <span>
                    {tripCostData
                      ? tripCostData
                          .filter((cost) => cost.cost_type == "advance")
                          .map((data) => (
                            <span
                              style={{ color: colorPalette.primaryColor, marginLeft: "1rem" }}
                            >
                              Rs.{data.cost_amount}
                            </span>
                          ))
                      : null}
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* {loadingPage ? (
          <div className="record-payment-loading-container">
            <img src={success} alt="" width="120px" height="90px" />
            <h6 style={{ color: "grey" }}>loading data...</h6>
          </div>
        ) : (
          <>
            <div className="record-payment-top-row">
              {" "}
              <ArrowBack
                style={{ cursor: "pointer" }}
                // onClick={handleGoToTrips}
              />{" "}
              <span
                style={{
                  marginLeft: "10px",
                  fontWeight: "500",
                  fontSize: "17px",
                }}
              >
                Payment Details
              </span>
            </div>
          </>
        )} */}
      </div>
    </Record_payment_main_container>
  );
}

export default MonthlyWisePayments;
