import styled from "styled-components";

export const FtlTripListStyle = styled.div`
  width: 100%;
  height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
`;
