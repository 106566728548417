import React from "react";
import apiUrl from "../../url/apiUrl.js";
import axios from "axios";
import axiosInterceptor from "../../config/https.js";

export const getDashboardData = async (page) => {
  try {
    const dashBoardData = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/summary`
    );

    if (dashBoardData.status == 200) {
      return dashBoardData.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};
