import React, { useContext, useEffect, useState } from "react";
import TRUCK_IMAGE from "../../assets/dashboard_icons/icon-0.8s-215px.png";
import "./warehouseMiniCard.css";
import { Search, Close, Edit } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import apiUrl from "../../url/apiUrl";
import FeedIcon from "@mui/icons-material/Feed";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import COMPANY from "../../assets/placeholdercompany5f3438282f524800f1d49cd2921bb0a56101e1aa16097ebd313b64778fc7c4bd1611448792.png";

import Carrier from "../../assets/dashboard_icons/icon-0.8s-215px.png";
import Tabs from "@mui/material/Tabs";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  getWarehouseUsersList,
  wareHouseUserPost,
} from "./warehouseMiniService";
import { getCarrierListById } from "../../pages/onboard/OnboardServices";
import { updateCarrierId } from "../../redux/onboardSlice";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateWarehouseUserEditInvoke } from "../../redux/tripSlice";
import { ButtonRectangle } from "../../themes/components/button";
import { DarkModeContext } from "../../context/darkModeContext";
import { colorPalette, colorPaletteDark } from "../../themes/theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function WarehouseMiniCard({
  users,
  onclick,
  selectedWarehouseId,
  warehouseUserList,
  onEdit,
}) {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const history = useHistory();
  const [state, setState] = React.useState({
    right: false,
  });
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);
  const [carrierDataById, setCarrierDataById] = useState({});

  const [costDetails, setCostDetails] = useState({});
  // const [warehouseUserList, setWarehouseUserList] = useState([]);

  // const GetWarehouseUsersApi = async (warehouseId) => {
  //   const usersResponse = await getWarehouseUsersList(warehouseId);
  //   setWarehouseUserList(usersResponse.warehouse_users_list);
  //
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
    if (open === false) {
      // resetForm();
    }
  };

  const editFunPass = (user) => {
    dispatch(updateWarehouseUserEditInvoke(user));
    onEdit(user);
  };

  const handleClose = () => {
    // setSelectedDrawer("warehouse");
    toggleDrawer("right", false);
  };
  // useEffect(() => {
  //   GetWarehouseUsersApi(selectedWarehouseId);
  // }, [selectedWarehouseId]);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  return (
    <div
      className="mini-details-vehicle-card-main-container"
      style={{
        backgroundColor: darkMode
          ? colorPaletteDark.secondaryColor
          : colorPalette.white,
      }}
    >
      <div
        className="mini-details-vehicle-body"
        style={{
          backgroundColor: darkMode
            ? colorPaletteDark.secondaryColor
            : colorPalette.white,
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons={false}
            >
              <Tab
                label="warehouse Details"
                {...a11yProps(0)}
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                }}
              />
              <Tab
                label="Users"
                {...a11yProps(1)}
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="mini-details-vehicle-tab-container-new">
              <div>
                <div className="mini-details-vehicle-single-list">
                  <h4
                    className="mini-details-vehicle-single-list-left"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    Warehouse Name
                  </h4>
                  <h4
                    className="mini-details-vehicle-single-list-right"
                    id="transporter_type"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    : {users.name ? users.name.toUpperCase() : "-"}
                  </h4>{" "}
                </div>
                <div className="mini-details-vehicle-single-list">
                  <h4
                    className="mini-details-vehicle-single-list-left"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    Warehouse Code
                  </h4>
                  <h4
                    className="mini-details-vehicle-single-list-right"
                    id="transporter_type"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    :{" "}
                    {users.warehouse_code
                      ? users.warehouse_code.toUpperCase()
                      : "-"}{" "}
                  </h4>
                </div>
                <div className="mini-details-vehicle-single-list">
                  <h4
                    className="mini-details-vehicle-single-list-left"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    Pincode
                  </h4>
                  <h4
                    className="mini-details-vehicle-single-list-right"
                    id="transporter_type"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    : {users.postcode ? users.postcode : "-"}
                  </h4>{" "}
                </div>
                <div className="mini-details-vehicle-single-list">
                  <h4
                    className="mini-details-vehicle-single-list-left"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    Village
                  </h4>
                  <h4
                    className="mini-details-vehicle-single-list-right"
                    id="transporter_type"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    : {users.village ? users.village.toUpperCase() : "-"}
                  </h4>{" "}
                </div>

                <div className="mini-details-vehicle-single-list">
                  <h4
                    className="mini-details-vehicle-single-list-left"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    Landmark
                  </h4>
                  <h4
                    className="mini-details-vehicle-single-list-right"
                    id="transporter_type"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    : {users.landmark ? users.landmark.toUpperCase() : "-"}
                  </h4>{" "}
                </div>

                <div className="mini-details-vehicle-single-list">
                  <h4
                    className="mini-details-vehicle-single-list-left"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    Sub-District
                  </h4>
                  <h4
                    className="mini-details-vehicle-single-list-right"
                    id="transporter_type"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    :{" "}
                    {users.sub_district
                      ? users.sub_district.toUpperCase()
                      : "-"}
                  </h4>{" "}
                </div>

                <div className="mini-details-vehicle-single-list">
                  <h4
                    className="mini-details-vehicle-single-list-left"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    District
                  </h4>
                  <h4
                    className="mini-details-vehicle-single-list-right"
                    id="transporter_type"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    : {users.district ? users.district.toUpperCase() : "-"}{" "}
                  </h4>
                </div>
                <div className="mini-details-vehicle-single-list">
                  <h4
                    className="mini-details-vehicle-single-list-left"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    State
                  </h4>
                  <h4
                    className="mini-details-vehicle-single-list-right"
                    id="transporter_type"
                    style={{
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    : {users.state ? users.state.toUpperCase() : "-"}{" "}
                  </h4>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            style={{
              backgroundColor: darkMode
                ? colorPaletteDark.secondaryColor
                : colorPalette.white,
            }}
          >
            <div className="mini-details-warehouseuser-tab-container-new">
              <div className="usersContainer-warehouse">
                {warehouseUserList.length > 0 ? (
                  warehouseUserList.map((user) => {
                    return (
                      <Accordion
                        style={{
                          backgroundColor: darkMode
                            ? colorPaletteDark.lightGreen
                            : colorPalette.white,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            style={{
                              color: darkMode
                                ? colorPaletteDark.paragraphSecondaryolor
                                : colorPalette.paragraphColor,
                            }}
                          >
                            {user.first_name} {user.last_name}
                          </Typography>
                          <div>
                            <Edit
                              onClick={() => {
                                editFunPass(user);
                              }}
                              style={{
                                marginLeft: "2rem",
                                cursor: "pointer",
                                color: darkMode
                                  ? colorPaletteDark.paragraphSecondaryolor
                                  : colorPalette.paragraphColor,
                              }}
                            />{" "}
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className="mobile_users">
                          <div className="mini-card-single-accordion-container">
                            <div className="mini-card-warehouse-user">
                              <h4
                                className="mini-card-warehouse-user-left"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                              >
                                First Name
                              </h4>
                              <h4
                                className="mini-card-warehouse-user-right"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                                // id="transporter_type"
                              >
                                : {user.first_name ? user.first_name : "-"}
                              </h4>{" "}
                            </div>
                            <div className="mini-card-warehouse-user">
                              <h4
                                className="mini-card-warehouse-user-left"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                              >
                                Last Name
                              </h4>
                              <h4
                                className="mini-card-warehouse-user-right"
                                // id="transporter_type"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                              >
                                : {user.last_name ? user.last_name : "-"}
                              </h4>{" "}
                            </div>

                            <div className="mini-card-warehouse-user">
                              <h4
                                className="mini-card-warehouse-user-left"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                              >
                                Mobile No.
                              </h4>
                              <h4
                                className="mini-card-warehouse-user-right"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                                // id="transporter_type"
                              >
                                :{" "}
                                {user.mobile_number ? user.mobile_number : "-"}
                              </h4>{" "}
                            </div>
                            <div className="mini-card-warehouse-user">
                              <h4
                                className="mini-card-warehouse-user-left"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                              >
                                Email Id
                              </h4>
                              <h4
                                className="mini-card-warehouse-user-right"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                                // id="transporter_type"
                              >
                                : {user.email ? user.email : "-"}
                              </h4>{" "}
                            </div>
                            <div className="mini-card-warehouse-user">
                              <h4
                                className="mini-card-warehouse-user-left"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                              >
                                Employee code
                              </h4>
                              <h4
                                className="mini-card-warehouse-user-right"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                                // id="transporter_type"
                              >
                                : {user.emp_code ? user.emp_code : "-"}
                              </h4>{" "}
                            </div>
                            <div className="mini-card-warehouse-user">
                              <h4
                                className="mini-card-warehouse-user-left"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                              >
                                Department
                              </h4>
                              <h4
                                className="mini-card-warehouse-user-right"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                                // id="transporter_type"
                              >
                                : {user.department ? user.department : "-"}
                              </h4>{" "}
                            </div>
                            <div className="mini-card-warehouse-user">
                              <h4
                                className="mini-card-warehouse-user-left"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                              >
                                Designation
                              </h4>
                              <h4
                                className="mini-card-warehouse-user-right"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                                // id="transporter_type"
                              >
                                : {user.designations ? user.designations : "-"}
                              </h4>{" "}
                            </div>
                            <div className="mini-card-warehouse-user">
                              <h4
                                className="mini-card-warehouse-user-left"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                              >
                                User Type
                              </h4>
                              <h4
                                className="mini-card-warehouse-user-right"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                                // id="transporter_type"
                              >
                                : {user.user_type ? user.user_type : "-"}
                              </h4>{" "}
                            </div>
                            <div className="mini-card-warehouse-user">
                              <h4
                                className="mini-card-warehouse-user-left"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                              >
                                Active
                              </h4>
                              <h4
                                className="mini-card-warehouse-user-right"
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.paragraphSecondaryolor
                                    : colorPalette.paragraphColor,
                                }}
                                // id="transporter_type"
                              >
                                {/* {
                                  "is Active userss",
                                  `${user.is_active}`
                                )} */}
                                : {`${user.is_active}`}
                              </h4>{" "}
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <div
                    className="warehouse-mini-no-users"
                    style={{ color: darkMode ? "white" : "" }}
                  >
                    No warehouse users found !
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                flex: "2",
              }}
            >
              {Object.keys(users).length > 0 ? (
                <ButtonRectangle style={{ width: "100%" }} onClick={onclick}>
                  ADD USER
                </ButtonRectangle>
              ) : null}
            </div>
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}

export default WarehouseMiniCard;
