import React, { useEffect, useState } from "react";
import { AcceptQuotesDrawer, RightbarIndentStyle } from "./quotesStyles";
import EditIcon from "@mui/icons-material/Edit";
import ArticleIcon from "@mui/icons-material/Article";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import attachement from "../../../assets/logisticsCustomer/attachement.png";
import deleteIcon from "../../../assets/logisticsCustomer/delete.svg";
import demoImage from "../../../assets/login/background image.jpg";
import {
  fetchIndentById,
  getAttachementById,
  getAttachementImage,
  getIndentAttachementById,
} from "../../../pages/logisticsCustomerPages/customerIndentsPage/customerIndentServices";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Drawer, Modal } from "@mui/material";
import moment from "moment";
import apiUrl from "../../../url/apiUrl";
import axiosInterceptor from "../../../config/https";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import { Box } from "@mui/system";
import {
  getAttachementImageQuotes,
  getQuotesAttachementById,
} from "../../../pages/logisticsCustomerPages/customerQuotesPage/customerQuotesService";
import { readLoginData, readToken } from "../../../helper/dataDecryptionHelper";
import { colorPalette } from "../../../themes/theme";

const QuotesRightBar = ({ currentTab, indentDetails, selectedQuotes }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [unit, setUnit] = useState();
  const [indentAttachement, setIndentAttachement] = useState([]);
  const [indentImage, setIndentImage] = useState();
  const [openAttachment, setOpenAttachment] = useState(false);

  const handleCloseAttachement = () => {
    setOpenAttachment(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: colorPalette.white,
    border: `1px solid ${colorPalette.white}`,
    boxShadow: 24,
    p: 0,
  };

  const getUnitById = async (id) => {
    const response = await axiosInterceptor.get(
      `${apiUrl.unitByIdBaseUrl}/${prefixSalt()}-${convertToBase64(id)}`
    );
    try {
      if (response.status == 200) {
        setUnit(response.data.name);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getUnitById(indentDetails?.unit_id);
  }, [indentDetails?.unit_id]);

  // const getIndentAttachement = async (id) => {
  //   const response = await getQuotesAttachementById(id);
  //   if (response.status >= 200 && response.status < 300) {
  //     setIndentAttachement(response.data);
  //   }
  // };

  // const getIndentImage = async (id) => {
  //   const response = await getAttachementImageQuotes(id);
  //   try {
  //     var blobA = response.replace("blob:", "");
  //     var blobB = blobA.replace('"', "");
  //     setIndentImage(blobB);
  //   } catch (err) {}
  // };

  //
  // useEffect(() => {
  //   getIndentAttachement(selectedQuotes?.quotations_id);
  //   getIndentImage(selectedQuotes?.quotations_id);
  // }, [selectedQuotes]);

  //
  let tokensData = readToken();
  const getQuoteImage = async (id) => {
    fetch(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/quotations/${prefixSalt()}-${convertToBase64(id)}`,
      // { responseType: "application/pdf" },
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
          // Accept: "application/pdf",
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        var file = window.URL.createObjectURL(blob);

        // window.location.assign(file);
        window.open(file, "_blank");
      })
      .catch((err) => {});
  };
  return (
    <RightbarIndentStyle>
      <Drawer
        open={drawerOpen}
        anchor={"right"}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: 440,
          },
        }}
      >
        <AcceptQuotesDrawer className="accept_drawer">
          <div className="accept_drawer_heading">Remarks</div>
          <div className="add_remark">
            <textarea name="accept_remark" placeholder="Add Remark"></textarea>
          </div>
          <div className="accept_button">
            <button>Accept</button>
          </div>
        </AcceptQuotesDrawer>
      </Drawer>
      <Modal
        open={openAttachment}
        onClose={handleCloseAttachement}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            src={"blob:" + indentImage}
            alt="Indent Attachement"
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        </Box>
      </Modal>
      <div className="indent_address_container">
        <div className="indent_heading">
          <div>{indentDetails?.indent_status}</div>

          {/* <div className="indent_edit">
            <EditIcon /> Change
          </div> */}
        </div>
        <div className="indent_address_details">
          <div className="from">
            <div className="indent_address_icon">
              <LocationOnIcon />
            </div>
            <input
              type="text"
              className="indent_address"
              disabled
              value={
                indentDetails
                  ? `${indentDetails?.source[0].village}, ${indentDetails?.source[0].sub_district}, ${indentDetails?.source[0].state}, ${indentDetails?.source[0].sub_district}, ${indentDetails?.source[0].postcode}`
                  : ""
              }
            />
            <div className="location_dotted_line"></div>
          </div>
          <div className="to">
            <div className="indent_address_icon">
              <RadioButtonCheckedIcon />
            </div>
            <input
              type="text"
              className="indent_address"
              disabled
              value={
                indentDetails
                  ? `${indentDetails?.destination[0].village}, ${indentDetails?.source[0].sub_district}, ${indentDetails?.source[0].state}, ${indentDetails?.source[0].sub_district}, ${indentDetails?.source[0].postcode}`
                  : ""
              }
            />
          </div>
        </div>
        <div className="date_and_quantity">
          <div className="indent_date">
            Date : {moment(indentDetails?.created_on).format("MMM Do YY")}
          </div>
          <div className="indent_qty">
            Goods QTY :{" "}
            {indentDetails
              ? `${indentDetails?.quantity_of_goods} ${unit}`
              : "-"}
          </div>
        </div>
      </div>
      <div className="indents_details">
        <div className="indent_details_heading">Indent details</div>
        <div className="indent_details_discription">
          <div className="indent_detail_left_right">
            <div className="indent_detail_left">Indent Code</div>
            <div className="colon">:</div>
            <div className="indent_detail_right">
              {indentDetails?.indent_code}
            </div>
          </div>
          <div className="indent_detail_left_right">
            <div className="indent_detail_left">Stop</div>
            <div className="colon">:</div>
            <div className="indent_detail_right">
              {indentDetails
                ? indentDetails?.source.length > 1 ||
                  indentDetails?.destination.length > 1
                  ? "Multiple Stop"
                  : "Single Stop"
                : "-"}
            </div>
          </div>
          <div className="indent_detail_left_right">
            <div className="indent_detail_left">Vehicle Type</div>
            <div className="colon">:</div>
            <div className="indent_detail_right">
              {indentDetails
                ? indentDetails.vehicle_type.replace(/_/g, " ").toUpperCase()
                : "-"}
            </div>
          </div>
          <div className="indent_detail_left_right">
            <div className="indent_detail_left">Type of material</div>
            <div className="colon">:</div>
            <div className="indent_detail_right">
              <div
                style={{
                  // width: "100px",
                  height: "20px",
                  textoverflow: "ellipsis",
                  whitespace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {indentDetails ? indentDetails.material_type : "-"}
              </div>
            </div>
          </div>
          <div className="indent_detail_left_right">
            <div className="indent_detail_left">Invoice Value</div>
            <div className="colon">:</div>
            <div className="indent_detail_right">
              ₹ {indentDetails ? indentDetails?.value_of_goods : "-"}
            </div>
          </div>
        </div>
      </div>
      {currentTab === "sent-quotations" ? (
        // <div className="attachements">
        //   <div className="attachements_icon">
        //     <img src={attachement} alt="attachement" />
        //   </div>
        //   <div className="attachements_heading">
        //     <span
        //       className="attachement_title"
        //       onClick={() => setOpenAttachment(true)}
        //     >
        //       Attachments
        //     </span>
        //     <p className="img_name">{indentAttachement?.document_name}</p>
        //   </div>

        // </div>
        <div className="attachements">
          {/* <div className="attachements_icon"> */}
          <ArticleIcon style={{ color: "green" }} />
          {/* <img src={attachement} alt="attachement" /> */}
          {/* </div> */}
          <div className="attachements_heading">
            <span className="attachement_title">
              Download File
              {/* <DownloadIcon /> */}
            </span>
            {/* <p className="img_name">{indentAttachement?.document_name}</p> */}
          </div>
          {/* <div className="attachements_heading">
          <span
            className="attachement_title"
            onClick={() => setOpenAttachment(true)}
          >
            Download File
          </span>
          <p className="img_name">{indentAttachement?.document_name}</p>
        </div> */}
          <div className="attachements_button">
            <DownloadIcon
              onClick={() => getQuoteImage(selectedQuotes?.quotations_id)}
              style={{ color: "green", cursor: "pointer" }}
            />
          </div>
        </div>
      ) : null}

      {currentTab === "accepted-quotations" ? (
        <div className="sent_section_message">
          <div className="icon">
            <ErrorOutlineIcon />
          </div>
          <div className="sent_message">
            <p>
              Quotation was accepted by <b>{indentDetails?.modified_by}</b> on
              <b> {moment(indentDetails?.modified_on).format("DD MMM YY")}</b>
            </p>
          </div>
        </div>
      ) : null}

      {currentTab === "denied-quotations" ? (
        <div className="unfulfilled_section_message">
          <div className="icon">
            <ErrorOutlineIcon />
          </div>
          <div className="unfulfilled_message">
            <p>
              Quotation was denied on{" "}
              <b>{moment(indentDetails?.modified_on).format("DD MMM YY")}</b>
            </p>
            <p>
              by <b>{indentDetails?.modified_by}</b>
            </p>
          </div>
        </div>
      ) : null}

      {/* {currentTab === "accepted-quotations" ? (
        <div className="fulfilled_section">
          <div className="quotation_heading">Quotation</div>
          <div className="quotation_card">
            <div className="card_title">
              <div className="quotation_title">Quotation title</div>
              <div className="quotation_price">₹ 50,000</div>
            </div>
            <div className="card_message">
              Remark : {"  "} Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Soluta, qui.
            </div>
          </div>
          <div className="quotation_button">
            <button className="accept" onClick={() => setDrawerOpen(true)}>
              Accept
            </button>
            <button className="reject">Reject</button>
          </div>
        </div>
      ) : null} */}
    </RightbarIndentStyle>
  );
};

export default QuotesRightBar;
