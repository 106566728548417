import styled from "styled-components";
import { FontFamily, colorPalette, colorPaletteDark } from "../../themes/theme";

export const OnboardListStyle = styled.div`
  // onboardlist style

  width: 100%;
  height: 100vh;

  .main_onboard_wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main_onboard_Left_box {
    flex: 1.7;
    background-color: ${colorPalette.secondaryWhite};
    height: 100%;
    box-shadow: ${colorPalette.boxShadowPrimary};
    margin: 0 auto;
  }

  .main_onboard_Right_box {
    flex: 9.5;
    /* position: sticky;
  overflow: scroll; */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

    /* width: 90%; */
  }

  .progress_container_details {
    width: 98%;
    height: 100%;
    display: flex;
    align-items: center;
    /* background-color: red; */
    justify-content: space-between;
  }

  .Left_details_wrapper {
    flex: 8;
    height: 100%;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
  }

  .search_div {
    position: relative;
    width: 70%;
    height: 2.5rem;
    background-color: ${colorPalette.secondaryWhite};
    display: flex;
    padding: 0 0.8rem;
    margin-right: 2rem;
    /* background-color: red; */
    margin-left: 3rem;
    /* justify-content: center; */
    align-items: center;
    border-radius: 8px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  .search_input {
    width: 100%;
    background-color: ${colorPalette.secondaryWhite};
    height: 100%;
    outline: none;
    border-radius: 1rem;
    border: none;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
  }

  .trip-list-filter-icon {
    margin-right: 6px;
    cursor: pointer;
  }
  .trip-list-filter-icon:hover {
    background-color: ${colorPalette.secondaryWhite};
    border-radius: 5px;
    /* padding: 5px; */
  }

  #search_container_transporter {
    height: 10%;
    width: 100%;
    margin-bottom: -0.2rem;
    display: flex;
    margin-right: 1rem;
    align-items: center;
    justify-content: center;
  }

  .search_div {
    width: 100%;
    flex: 8;
  }

  .onboard_btnDiv {
    display: flex;
    flex: 4;
    /* background-color: red; */
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-right: 3rem;
  }

  .transporter_tabs {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.8rem 0.2rem;

    /* background-color: #016938; */
    justify-content: flex-start;
  }

  .transporter_singletab {
    padding: 0.1rem 1rem;
    cursor: pointer;
    /* border: 1px solid green; */
  }

  .transporter_status_container {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  .transporter_status_tabs {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5;
    margin-left: 20px;
  }

  .transporter_status_select {
    padding: 5px 10px;
    border: 1px solid ${colorPalette.primaryCodeColor};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.black : colorPalette.white};
  }

  .card_container_transporter {
    display: grid;
    grid-template-columns: 32.5% 32.5% 32.5%;
    grid-auto-rows: min-content;
    height: 77vh;
    /* width: 98%; */
    /* width: 100%; */
    /* padding: 0px 30px; */
    position: sticky;
    overflow-y: scroll;
    /* grid-template-rows: 50%; */
    gap: 10px;
    padding: 20px;
  }

  .card_transporter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /*height: 100%;
  */ /*margin: 1rem auto;
  */
    padding: 0.6rem;
    border-radius: 0.2rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    /*box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  */
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

    -webkit-border-radius: 0.2rem;
    -moz-border-radius: 0.2rem;
    -ms-border-radius: 0.2rem;
    -o-border-radius: 0.2rem;
    /*background-color: #016938;
  */
  }

  .content_div_onboard_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    /* background-color: #016938; */
    justify-content: flex-start;
    /* gap: 10px; */
  }

  .name_transporter {
    font-family: ${FontFamily.primaryFontFamily};
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    padding: 0 0.6rem;
  }

  .name_text_onboard_transporter {
    /* font-size: 12px;
  display: flex; */
    font-family: ${FontFamily.primaryFontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }

  .name_text_onboard_transporter_code {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    text-align: right;
    letter-spacing: -0.165px;
    color: ${colorPalette.primaryCodeColor};
  }

  .capacity {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 0.6rem;
  }

  .driver {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 0.6rem;
  }

  .name_text_onboard_transporter_aadhar {
    font-family: ${FontFamily.primaryFontFamily};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.paragraphSecondaryolor};
    /* color: ${colorPalette.paragraphSecondaryolor}; */
    /* font-weight: bold; */
  }

  .aadhar_vehicles_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn_vehicles_details {
    margin: 0 auto;

    border: 1px solid grey;
    display: flex;
    padding: 0px 5px;
    border-radius: 2px;
    font-family: ${FontFamily.primaryFontFamily};
    color: ${colorPalette.black};
    font-size: 10px;
    font-weight: normal;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }

  /*=======================================================*/

  @media only screen and (max-width: 499px) {
    .main_onboard_wrapper {
      flex-direction: column;
      margin: 0px;
      height: auto;
    }

    .trip-list-mobile-header {
      width: 100%;
      height: 50px;
    }

    .main_onboard_Right_box {
      flex: 9.5;
      /* position: sticky;
    overflow: scroll; */
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      background-color: ${colorPalette.secondaryWhite};
      width: 95%;
      /* background-color: red; */
    }

    .progress_container_details {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      /* background-color: beige; */
      justify-content: space-between;
    }

    .Left_details_wrapper {
      width: 100%;
      height: 100%;
      background-color: ${colorPalette.secondaryWhite};
    }

    #Left_details_wrapper_onboard {
      width: 100%;
      height: 100vh;

      background-color: ${colorPalette.secondaryWhite};
    }

    #search_container_transporter {
      height: 10%;
      width: 100%;
      margin-bottom: -0.2rem;
      display: flex;
      margin-right: 1rem;
      align-items: center;
      justify-content: center;
    }

    .search_div {
      height: 2.5rem;
      background-color: ${colorPalette.secondaryWhite};
      display: flex;
      padding: 0 0.8rem;
      display: flex;
      align-items: center;
      margin-top: 10px;
      align-items: center;
      border-radius: 8px;
      box-shadow: ${colorPalette.boxShadowPrimary};
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
    }

    #search_div_onboard {
      width: 100%;
      height: 2.5rem;
      background-color: ${colorPalette.secondaryWhite};
      display: flex;
      padding: 0 0.8rem;
      display: flex;
      align-items: center;
      margin-left: 1.8rem;
      align-items: center;
      border-radius: 8px;
      box-shadow: ${colorPalette.boxShadowPrimary};
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
    }

    .search_input {
      width: 100%;
      background-color: ${colorPalette.secondaryWhite};
      height: 100%;
      outline: none;
      border-radius: 1rem;
      border: none;
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      -ms-border-radius: 1rem;
      -o-border-radius: 1rem;
    }

    .onboard_btnDiv {
      display: flex;
      flex: 2;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      margin-right: 0rem;
    }

    .onboard_btnDiv_mobile_transporter {
      width: 100%;
      margin: 0 auto;
      margin-bottom: 1rem;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }

    .onboard_mobile_text {
      font-size: 0.8rem;
      color: white;
      outline: none;
      border: none;
    }

    .transporter_tabs {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0.3rem 0.2rem;
      overflow-x: scroll;
      justify-content: flex-start;
    }

    .card_container_transporter {
      display: grid;
      grid-template-columns: 95%;
      grid-auto-rows: min-content;
      height: 80vh;
      position: sticky;
      overflow-y: scroll;
      gap: 10px;
      padding-bottom: 20px;
    }

    .card_transporter {
      display: flex;
      /* align-self: center; */
      justify-content: space-between;
      align-items: center;
      width: 100%;
      /* margin: 1rem auto; */
      padding: 0.6rem;
      border-radius: 0.5rem;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      -webkit-border-radius: 0.5rem;
      -moz-border-radius: 0.5rem;
      -ms-border-radius: 0.5rem;
      -o-border-radius: 0.5rem;
    }

    .name_text_onboard_transporter {
      font-family: ${FontFamily.primaryFontFamily};
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      /* line-height: 24px; */
      display: flex;
      color: ${colorPalette.black};
      /* align-items: center;
    width: 100%; */
      /* justify-content: space-between; */
    }

    #text_mobile_onboard {
      font-size: 0.6rem;
    }

    .driver {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 0.6rem;
    }

    .name_text_onboard_transporter_aadhar {
      font-family: ${FontFamily.primaryFontFamily};
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 21px;
      display: flex;
      color: ${colorPalette.paragraphSecondaryolor};
      /* font-weight: bold; */
    }

    #text_mobile_onboard {
      font-size: 0.6rem;
    }
  }
`;
