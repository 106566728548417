import { React, useEffect, useState } from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import SearchIcon from "@mui/icons-material/Search";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Carrier from "../../assets/dashboard_icons/icon-0.8s-215px.png";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import { onboardRegisterPost } from "../onboard/OnboardServices";
import axios from "axios";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import "../onboardDetails/onboardDetails.css";
import { useHistory } from "react-router-dom";
import apiUrl from "../../url/apiUrl";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { onboardData } from "../../redux/onboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getVehiclesListById } from "./VehiclesServices";
import { getCarrierListById } from "../onboard/OnboardServices";
import { VehicleStyle } from "./VehicleStyle";
import { Modal } from "antd";
import SomethingWentWrong from "../../components/somethingWentWrong/SomethingWentWrong.jsx";
function Vehicles() {
  const { carrier_id, id } = useParams();

  const [value, setValue] = useState(0);
  const [vehiclesDataById, setVehiclesDataById] = useState({});
  const [carrierDataById, setCarrierDataById] = useState({});
  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();

  const vehicleDataById = async (carrier, ids) => {
    const vehiclesResponseData = await getVehiclesListById(carrier, ids);

    setVehiclesDataById(vehiclesResponseData);
  };
  useEffect(() => {
    vehicleDataById(carrier_id, id);
  }, []);

  const getCarrierData = async (idd) => {
    const carrierResponseData = await getCarrierListById(idd);

    setCarrierDataById(carrierResponseData);
  };
  // useEffect(() => {
  //   getCarrierData(carrier_id);
  // }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  return (
    <VehicleStyle className="details_onboard_container">
      <Modal
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong backButtonLink="/dashboard" />
        </div>
      </Modal>
      <div className="details_onboard_wrapper">
        <div className="details_onboard_Left_box">
          <SideBarMain />
        </div>
        <div className="details_onboard_Right_box">
          <div className="vehicles_container_details">
            <div className="vehicleName">
              <NavLink to="/vehicles" className="navLink">
                <ArrowBackIcon className="arrow_icon" />
              </NavLink>
              <h6 className="name_vehicle">
                {vehiclesDataById.vehicle_number}
              </h6>
            </div>
            <div className="vehicleId">
              <h6 className="vehicle_id">Vehicle Id:</h6>
              <h6 className="id_value">{vehiclesDataById.id}</h6>
            </div>
          </div>
          <div className="main_details_container">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                fullWidth
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {/* <Tab
                  className="tab"
                  label="Vehicles Details"
                  {...a11yProps(1)}
                />
                <Tab
                  className="tab2"
                  label="Carrier Details"
                  {...a11yProps(2)}
                /> */}

                <Tab className="tab4" label="Recent Trip" {...a11yProps(0)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {
                Object.keys(vehiclesDataById).length == 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      // backgroundColor: "grey",
                    }}
                  >
                    {" "}
                    <img
                      src={success}
                      alt=""
                      width="120px"
                      height="90px"
                      style={{ marginTop: "200px" }}
                    />
                    <h6 style={{ color: "grey" }}>loading data...</h6>
                  </div>
                ) : null
                // <div className="customer_container">
                //   <div className="details_name">
                //     <h6 className="details_name_head">Vehicle Number</h6>
                //     <h6 className="details_name_text">
                //       : {vehiclesDataById.vehicle_number}
                //     </h6>
                //   </div>
                //   <div className="details_name">
                //     <h6 className="details_name_head">Permit</h6>
                //     <h6 className="details_name_text">
                //       :{" "}
                //       {vehiclesDataById.is_national_permit == true
                //         ? "National"
                //         : "State"}
                //     </h6>
                //   </div>
                //   <div className="details_name">
                //     <h6 className="details_name_head">Permitted State</h6>
                //     <h6 className="details_name_text">
                //       :{" "}
                //       {vehiclesDataById.permitted_states
                //         ? vehicleDataById.permitted_states
                //         : null}
                //     </h6>
                //   </div>
                //   <div className="details_name">
                //     <h6 className="details_name_head">Permit Number</h6>
                //     <h6 className="details_name_text">
                //       : {vehiclesDataById.permit_number}
                //     </h6>
                //   </div>
                //   <div className="details_name">
                //     <h6 className="details_name_head">Registration No.</h6>
                //     <h6 className="details_name_text">
                //       : {vehiclesDataById.registration}
                //     </h6>
                //   </div>
                //   <div className="details_name">
                //     <h6 className="details_name_head">Status</h6>
                //     <h6 className="details_name_text">
                //       : {vehiclesDataById.status}
                //     </h6>
                //   </div>
                //   <div className="details_name">
                //     <h6 className="details_name_head">Permit Expiry Date</h6>
                //     <h6 className="details_name_text">
                //       : {vehiclesDataById.permit_expiry_date}{" "}
                //     </h6>
                //   </div>
                // </div>
              }
            </TabPanel>
            <TabPanel value={value} index={1} className="tab_div">
              {Object.keys(carrierDataById).length !== 0 ? (
                <div className="vehicles_container">
                  {/* {vehiclesDataById.vehicles.map((vehicles, index) => ( */}
                  <div className="vehicle_card">
                    <div className="image_div">
                      <div>
                        <img src={Carrier} alt="" width="150px" />
                      </div>
                    </div>
                    <div className="vehicle_left">
                      <div className="vehicles_div">
                        <h6 className="vehicles_attributes">Carrier Name</h6>
                        <h6 className="vehicles_value">
                          : {carrierDataById.name.toUpperCase()}
                        </h6>
                      </div>
                      <div className="vehicles_div">
                        <h6 className="vehicles_attributes">Carrier Type</h6>
                        <h6 className="vehicles_value">
                          : {carrierDataById.transporter_type.toUpperCase()}
                        </h6>
                      </div>
                      <div className="vehicles_div">
                        <h6 className="vehicles_attributes"> GSTIN No.</h6>
                        <h6 className="vehicles_value">
                          :{carrierDataById.gstin_number.toUpperCase()}
                        </h6>
                      </div>
                      <div className="vehicles_div">
                        <h6 className="vehicles_attributes">Pan Number</h6>
                        <h6 className="vehicles_value">
                          :{carrierDataById.pan_number.toUpperCase()}
                        </h6>
                      </div>
                    </div>
                    <div className="vehicle_right">
                      <div className="vehicles_div">
                        <h6 className="vehicles_attributes">Bank A/c Number</h6>
                        <h6 className="vehicles_value">
                          :{carrierDataById.bank_account_number.toUpperCase()}
                        </h6>
                      </div>
                      <div className="vehicles_div">
                        <h6 className="vehicles_attributes">IFSC Code</h6>
                        <h6
                          style={{ marginRight: ".5rem" }}
                          className="vehicles_value"
                        >
                          : {carrierDataById.ifsc_code.toUpperCase()}
                        </h6>
                      </div>
                      <div className="vehicles_div">
                        <h6 className="vehicles_attributes">Bank Name</h6>
                        <h6 className="vehicles_value">
                          : {carrierDataById.bank_name.toUpperCase()}
                        </h6>
                      </div>
                      <div className="vehicles_div">
                        <h6 className="vehicles_attributes">Mobile No.</h6>
                        <h6 className="vehicles_value">
                          {carrierDataById.mobile.toUpperCase()}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {" "}
                  <img
                    src={success}
                    alt=""
                    width="120px"
                    height="90px"
                    style={{ marginTop: "200px" }}
                  />
                  <h6 style={{ color: "grey" }}>loading data...</h6>
                </div>
              )}
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
              <div className="customer_container">
                <div className="details_name">
                  <h6 className="details_name_head">Vehicle Name</h6>
                  <h6 className="details_name_text">
                    : Ashok LeyLAND 4334 Truck
                  </h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Model Number</h6>
                  <h6 className="details_name_text">: 4334</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Vehicle Number</h6>
                  <h6 className="details_name_text">: MH 09 AR 1008</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Vehicle Type</h6>
                  <h6 className="details_name_text">: Truck</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">OEM</h6>
                  <h6 className="details_name_text">: ----</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Number of Wheels</h6>
                  <h6 className="details_name_text">: 8</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Max Load Capacity</h6>
                  <h6 className="details_name_text">: 18-20 Tonnes</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Min Load Capacity</h6>
                  <h6 className="details_name_text">: 14 Tonnes</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">National/State Permit</h6>
                  <h6 className="details_name_text">: State</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Permited State</h6>
                  <h6 className="details_name_text">: Punjab,Bihar,Goa</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Permit Number</h6>
                  <h6 className="details_name_text">: SOV5363632</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Permit Expiry Date</h6>
                  <h6 className="details_name_text">: 20 Jan 2026</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Vehicle Status</h6>
                  <h6 className="details_name_text">: Good</h6>
                </div>
              </div>
            </TabPanel>
            {/* <TabPanel value={value} index={3}>
              Driver Information
            </TabPanel> */}
            <TabPanel value={value} index={0}>
              Recent Trip
            </TabPanel>
          </div>
        </div>
      </div>
    </VehicleStyle>
  );
}

export default Vehicles;
