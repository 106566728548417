import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  payment: "",
  items: {},
  monthlyDetails: {},
  monthlyBalance: "",
};

const paymentSlice = createSlice({
  name: "trip",
  initialState,
  reducers: {
    paymentDetails(state, action) {
      state.payment = action.payload;
    },
    itemDetails(state, action) {
      state.items = action.payload;
    },
    paymentMonthlyDetails(state, action) {
      state.monthlyDetails = action.payload;
    },
    paymentBalance(state, action) {
      state.monthlyBalance = action.payload;
    },
  },
});

export const {
  paymentDetails,
  itemDetails,
  paymentMonthlyDetails,
  paymentBalance,
} = paymentSlice.actions;
export default paymentSlice.reducer;
