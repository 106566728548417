import styled from "styled-components";
import { FontWeight } from "../../../themes/theme";

export const OurPresenceStyle = styled.div`
  width: 100%;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-margin-top: 80px;

  .our_presence_heading {
    font-weight: ${FontWeight.bold};
    font-size: 36px;
    line-height: 54px;
    width: 100%;
    text-align: left;
    margin-bottom: 30px;
  }

  .our_presence_heading {
    font-size: 30px;
    line-height: 54px;
    width: 100%;
    text-align: left;
    margin-bottom: 30px;
  }

  .our_presence_map {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .our_presence_map img {
    width: 50%;
    height: 50%;
    object-fit: cover;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .our_presence_heading {
      font-size: 30px;
      line-height: 54px;
      width: 100%;
      text-align: left;
      margin-bottom: 30px;
    }

    .our_presence_map {
      width: 100%;
      height: 100%;
    }

    .our_presence_map img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
