import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import success from "../../../assets/dashboard_icons/icons8-truck.gif";
import { ArrowBack, Add, Edit, Close } from "@mui/icons-material";
import {
  TextField,
  MenuItem,
  CircularProgress,
  Box,
  Drawer,
  InputAdornment,
  Alert,
} from "@mui/material";
import {
  TripAttachmentPost,
  stopTrip,
  GetCompleteTripDocumentEnums,
  purchaseOrderItemsDeliveredPost,
  requestCompleteTrip,
  getTripCompleteRequestDetails,
} from "../FaarmsCompleteTrip/faarmsCompleteTripServices";
import * as moment from "moment";
import "../FaarmsCompleteTrip/faarms-complete-trip.css";
import { useSelector, useDispatch } from "react-redux";
import UploadImage from "../../../assets/upload.svg";
import {
  updatePendingTripInvoiceList,
  updateSelectedTrip,
} from "../../../redux/tripSlice.js";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import { GetDocumentEnums } from "../createTrip/createTripServices";
import {
  TripPaymentPost,
  getPaymentZohobankAccount,
  getTripDetailsFromId,
  getTripItems,
  getTripItemsWithDiscount,
} from "../deliveryInvoicePayment/DeliveryInvoiceService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getTripDetailsById } from "../../recordPayment/recordPaymentServices";
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Collapse, Alert as AntdAlert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import {
  ButtonRectangle,
  ButtonRectangleSecondary,
} from "../../../themes/components/button.js";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Payment_drawer } from "../deliveryInvoicePayment/delivery-invoice-payment.js";
import UploadIcon from "@mui/icons-material/Upload";
import CheckIcon from "@mui/icons-material/Check";
import SomethingWentWrong from "../../../components/somethingWentWrong/SomethingWentWrong.jsx";
import { Space, Table as TableAntd, Tag } from "antd";
import { Modal as ModalAntd } from "antd";
import { colorPalette } from "../../../themes/theme.js";
const { Panel } = Collapse;
// import { Complete_Trip_Main_Container } from "../completeTrip/completeTrip";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 180,
  bgcolor: "background.paper",
  boxShadow: 45,
  p: 2,
};
function FaarmsCompleteTripRequest() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tripId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [pendingInvoiceMsgp, setPendingInvoiceMsg] = useState("");
  const [allDiscountArray, setAllDiscountArray] = useState([]);
  const [tripEntityMapping, setTripEntityMapping] = useState([]);
  const [invoicePaymentHistory, setInvoicePaymentHistory] = useState([]);
  const [sigleInvoicePaymentHistory, setSigleInvoicePaymentHistory] = useState(
    []
  );
  const [selectedReferenceIndex, setSelectedReferenceIndex] = useState();
  const [selectedInvioceNumber, setSelectedInvioceNumber] = useState();
  const [tripListItems, setTripListItems] = useState([]);
  const [tripItemLoading, setTripItemLoading] = useState(false);
  const [finalBalance, setFinalBalance] = useState(0);
  console.log(finalBalance, "discount array 1");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [invoicePaymentHistoryList, setInvoicePaymentHistoryList] = useState(
    []
  );

  const [allTripItemData, setAllTripItemData] = useState({});
  const [tripInvoiceItem, setTripInvoiceItem] = useState([
    {
      invoiceNumber: "",
      totalAmount: 0,
      totalRecommendedAmount: 0,
      totalApprovedAmount: 0,
      totalSubmittedAmount: 0,
      totalRejectedAmount: 0,
    },
  ]);

  useEffect(() => {
    tripInvoiceItem.map((item) =>
      item.totalSubmittedAmount + item.totalRecommendedAmount === 0
        ? setPendingInvoiceMsg("")
        : setPendingInvoiceMsg(
          "Please Approve or Reject Payment for these invoices"
        )
    );
  }, [tripInvoiceItem]);

  useEffect(() => {
    if (Object.keys(allTripItemData).length === 0) return;
    let arr = [];
    setTripInvoiceItem([
      {
        invoiceNumber: "",
        totalAmount: 0,
        totalRecommendedAmount: 0,
        totalApprovedAmount: 0,
        totalSubmittedAmount: 0,
        totalRejectedAmount: 0,
      },
    ]);

    allTripItemData.trip_items_list.map((item) => {
      let submittedAmount = 0;
      let recommendedAmount = 0;
      let approvedAmount = 0;
      let rejectedAmount = 0;
      item.invoice_payment_history.map((mapItem) => {
        if (mapItem.customer_payment_status === "submitted") {
          return (submittedAmount += mapItem.amount);
        }
        if (mapItem.customer_payment_status === "rejected") {
          return (rejectedAmount += mapItem.amount);
        }
        if (mapItem.customer_payment_status === "approved") {
          return (approvedAmount += mapItem.amount);
        }
        if (mapItem.customer_payment_status === "recommended") {
          return (recommendedAmount += mapItem.amount);
        }
      });

      arr.push({
        invoiceNumber: item.reference_number,
        totalAmount: item.item_qty * item.item_rate,
        totalRecommendedAmount: recommendedAmount,
        totalApprovedAmount: approvedAmount,
        totalSubmittedAmount: submittedAmount,
        totalRejectedAmount: rejectedAmount,
      });
    });

    const updatedArray1 = arr?.map((item1) => {
      const correspondingItem2 = allTripItemData?.trip_invoice_discounts?.find(
        (item2) => item1.invoiceNumber === item2.reference_number
      );
      console.log("correspondingItem2");

      if (correspondingItem2 && item1.totalAmount !== NaN) {
        const updatedAmount =
          item1.totalAmount - correspondingItem2.discount_amount;
        console.log(item1, correspondingItem2, "sdghfgfds3333");
        return { ...item1, totalAmount: updatedAmount };
      }
      return item1;
    });

    const updatedArray2 = updatedArray1?.map((item1) => {
      const correspondingItem2 = allTripItemData?.trip_entity_mapping?.find(
        (item2) =>
          item1.invoiceNumber === item2.additional_data.reference_number
      );
      console.log("correspondingItem2");

      if (correspondingItem2 && item1.totalAmount !== NaN) {
        const updatedAmount =
          item1.totalAmount + correspondingItem2.delivery_charges;
        console.log(item1, correspondingItem2, "sdghfgfds3333");
        return { ...item1, totalAmount: updatedAmount };
      }
      return item1;
    });

    console.log(updatedArray1, "sdghfgfds");
    setTripInvoiceItem(updatedArray2);

    // setTripInvoiceItem(arr);
  }, [allTripItemData]);
  console.log(tripInvoiceItem, "tripInvoiceItem");

  // useEffect(() => {
  //   if (tripInvoiceItem.length <= 0 && tripInvoiceItem[0].invoiceNumber === "")
  //     return;
  //   let arr = [];
  //   if (tripInvoiceItem.length > 0 && tripInvoiceItem[0].invoiceNumber !== "") {
  //     const updatedArray1 = tripInvoiceItem.map((item) => {
  //       const matchingItem = allTripItemData.trip_invoice_discounts.filter(
  //         (discount) => discount.reference_number === item.invoiceNumber
  //       );
  //       if (matchingItem) {
  //         console.log(item, "matching item");
  //         return {
  //           ...item,
  //           totalAmount: item.totalAmount - matchingItem[0].discount_amount,
  //         };
  //       }
  //       return item;
  //     });
  //     setTripInvoiceItem(updatedArray1);
  //   }
  //   // allTripItemData.trip_invoice_discounts.map((discount) => {
  //   //   tripInvoiceItem.map((item) => {
  //   //     if (
  //   //       item.invoiceNumber === discount.reference_number &&
  //   //       discount.discount_amount > 0
  //   //     ) {
  //   //       console.log(item.invoiceNumber, "invoice noum");
  //   //     }
  //   //   });
  //   // });
  // }, [allTripItemData]);

  // useEffect(() => {
  //   if (
  //     tripInvoiceItem.length >= 0 &&
  //     tripInvoiceItem[0].invoiceNumber !== ""
  //   ) {
  //     const updatedArray1 = tripInvoiceItem?.map((item1) => {
  //       const correspondingItem2 =
  //         allTripItemData?.trip_invoice_discounts?.find(
  //           (item2) => item1.invoiceNumber === item2.reference_number
  //         );
  //       console.log("correspondingItem2");

  //       if (correspondingItem2 && item1.totalAmount !== NaN) {
  //         const updatedAmount =
  //           item1.totalAmount - correspondingItem2.discount_amount;
  //         console.log(item1, correspondingItem2, "sdghfgfds3333");
  //         return { ...item1, totalAmount: updatedAmount };
  //       }
  //       return item1;
  //     });
  //     console.log(updatedArray1, "sdghfgfds");
  //     setTripInvoiceItem(updatedArray1);
  //   }
  // }, [
  //   allTripItemData?.trip_invoice_discounts?.length === tripInvoiceItem.length,
  // ]);
  const deliveredInvoicesList = invoicePaymentHistoryList.filter(
    (data) => data.status !== "approved"
  );

  const remoedDuplicateInvoice = deliveredInvoicesList.map(
    (data) => data.reference_number
  );
  const remoedDuplicateInvoiceStatus = deliveredInvoicesList.map(
    (data) => data.status
  );

  const undeliveredInvoices = deliveredInvoicesList.filter(
    (data) => data.status == "submitted" || data.status == "recommended"
  );

  const undeliveredInvoicesInique = undeliveredInvoices.map(
    (data) => data.reference_number
  );

  const finalFiltredArrayOfPendingInvoices = [
    ...new Set(undeliveredInvoicesInique),
  ];
  const finalFiltredArrayOfPendingInvoicesStatus = [
    ...new Set(remoedDuplicateInvoiceStatus),
  ];

  const handleIsItemPicked = () => {
    const tripPickedStaus = tripEntityMapping.find(
      (data) => data.additional_data.reference_number == selectedInvioceNumber
    );

    return tripPickedStaus?.status;
  };

  useEffect(() => {
    localStorage.setItem("SELECTED_TRIP", "FAARMS-TRIP");
  }, []);

  const columns = [
    {
      title: "",
      key: "",
      render: (item) => {
        return (
          <div
            style={
              item.totalAmount === item.totalApprovedAmount
                ? {
                  width: "8px",
                  height: "95%",
                  backgroundColor: "#016938",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  borderTopRightRadius: "999px",
                  borderBottomRightRadius: "999px",
                }
                : item.totalAmount ===
                  item.totalSubmittedAmount + item.totalRecommendedAmount
                  ? {
                    width: "8px",
                    height: "95%",
                    backgroundColor: "#FFD234",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    borderTopRightRadius: "999px",
                    borderBottomRightRadius: "999px",
                  }
                  : {
                    width: "8px",
                    height: "95%",
                    backgroundColor: "#B10505",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    borderTopRightRadius: "999px",
                    borderBottomRightRadius: "999px",
                  }
            }
          >
            { }
          </div>
        );
      },
    },
    {
      title: "Invoices",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      // style: { backgroundColor: "red" },
      width: "18%",
    },
    {
      title: "Invoice Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Payment (Approved)",
      dataIndex: "totalApprovedAmount",
      key: "totalApprovedAmount",
    },
    {
      title: "Payment (Submitted)",
      dataIndex: "totalSubmittedAmount",
      key: "totalSubmittedAmount",
    },
    {
      title: "Payment (Recommended)",
      dataIndex: "totalRecommendedAmount",
      key: "totalRecommendedAmount",
    },
    // {
    //   title: "Payment (Rejected)",
    //   dataIndex: "totalRejectedAmount",
    //   key: "totalRejectedAmount",
    // },
    {
      title: "Action",
      key: "action",
      render: (item) =>
        item.totalSubmittedAmount +
          item.totalRecommendedAmount +
          item.totalApprovedAmount !==
          item.totalAmount ? (
          <ButtonRectangle
            style={{
              width: "125px",
              backgroundColor: "white",
              borderColor: "#454545",
              color: "black",
              margin: "0px",
            }}
            onClick={() => {
              console.log(item, "text");
              handleOpenDrawer("right", "open");
              setSelectedInvioceNumber(item.invoiceNumber);
              setFinalBalance(item.totalAmount - item.totalApprovedAmount);
              handleIsItemPicked();
            }}
          >
            Record Payment
          </ButtonRectangle>
        ) : (
          <div style={{ width: "100%", textAlign: "center", fontSize: "30px" }}>
            -
          </div>
        ),
    },
  ];

  const tripItemData = tripInvoiceItem;

  // ========================Drawer Start=============================
  const [selectedInvoice, setSelectedInvoice] = useState("0");
  const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState("");
  const [deliveryPaymentDetailsArray, setDeliveryPaymentDetailsArray] =
    useState([
      {
        particular_payment_mode: "",
        particular_amount: "",
        particular_transaction_reference_id: "",
        particular_attachment: null,
        zoho_books_account_name: "",
        ledger_name_in_zoho: "",
        account_id: "",
        account_code: "",
      },
    ]);

  const runningEnvironment = process.env.REACT_APP_NODE_ENV;
  const [zohoBankAccountList, setZohoBankAccountList] = useState([]);
  const [validImageSize, setValidImageSize] = useState(false);
  const [validImageFormat, setValidImageFormat] = useState(false);
  const hiddenFileInput = useRef(null);
  const [errDeliveryPayment, setErrDeliveryPayment] = useState("");
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [paymentMode, setPaymentMode] = useState("cash");
  const [paymentAttachment, setPaymentAttachment] = useState(null);
  const [transactionId, setTransactionId] = useState("");
  const [tripData, setTripData] = useState([]);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [allDeliveryChargesArray, setAllDeliveryChargesArray] = useState([]);
  const [selectedDiscountDetails, setSelectedDiscountDetails] = useState({});
  const [selectedDeliveryCharges, setSelectedDeliveryCharges] = useState({});
  const [selectedReferenceId, setSelectedReferenceId] = useState("");
  const [errDeliveryPaymentArray, setErrDeliveryPaymentArray] = useState([
    {
      err_particular_payment_mode: "",
      err_particular_amount: "",
      err_particular_transaction_reference_id: "",
    },
  ]);
  const [loadingPaymentHistory, setLoadingPaymentHistory] = useState(false);

  const bankStaticData =
    runningEnvironment === "development"
      ? [
        {
          bankName: "STATE BANK OF INDIA-39538519674",
          id: 1,
          ledger_name_in_zoho: "STATE BANK OF INDIA-39538519674(540403)",
          modeOfPayment: [
            "cash",
            "upi",
            "net_banking",
            "bank_transfer",
            "neft",
            "rtgs",
            "cheque",
            "imps",
          ],
        },
        {
          bankName: "HDFC BANK-50200048769847",
          id: 2,
          ledger_name_in_zoho: "HDFC BANK-50200048769847(540401)",
          modeOfPayment: [
            "upi",
            "cash",
            "net_banking",
            "bank_transfer",
            "neft",
            "rtgs",
            "cheque",
            "imps",
          ],
        },
        {
          bankName: "ICICI Bank-141705003502",
          id: 3,
          ledger_name_in_zoho: "ICICI Bank-141705003502(540404)",
          modeOfPayment: [
            "cash",
            "upi",
            "net_banking",
            "bank_transfer",
            "neft",
            "rtgs",
            "cheque",
            "imps",
          ],
        },
        {
          bankName: "Phonepe Business Account",
          id: 4,
          ledger_name_in_zoho: " Phonepe Business Account(540407)",
          modeOfPayment: ["phonepe", "upi"],
        },
        {
          bankName: "Razorpay",
          id: 5,
          ledger_name_in_zoho: "Razorpay(540408)",
          modeOfPayment: ["credit_card", "upi", "net_banking"],
        },
        {
          bankName: "Paytm Accounts",
          id: 6,
          ledger_name_in_zoho: "Paytm Accounts(540405)",
          modeOfPayment: ["paytm", "other_upi"],
        },
        {
          bankName: "Airtel Payment  Bank Account",
          id: 7,
          ledger_name_in_zoho: "Airtel Payment Bank Account(540410)",
          modeOfPayment: ["cash"],
        },
        {
          bankName: "Petty Cash",
          id: 8,
          ledger_name_in_zoho: "Petty Accounts(12345)",
          modeOfPayment: ["paytm", "other_upi", "test-1", "test-2"],
        },
      ]
      : [
        {
          bankName: "STATE BANK OF INDIA-39538519674",
          id: 1,
          ledger_name_in_zoho: "STATE BANK OF INDIA-39538519674(540403)",
          modeOfPayment: [
            "cash",
            "upi",
            "net_banking",
            "bank_transfer",
            "neft",
            "rtgs",
            "cheque",
            "imps",
          ],
        },
        {
          bankName: "HDFC BANK-50200048769847",
          id: 2,
          ledger_name_in_zoho: "HDFC BANK-50200048769847(540401)",
          modeOfPayment: [
            "upi",
            "cash",
            "net_banking",
            "bank_transfer",
            "neft",
            "rtgs",
            "cheque",
            "imps",
          ],
        },
        {
          bankName: "ICICI Bank-141705003502",
          id: 3,
          ledger_name_in_zoho: "ICICI Bank-141705003502(540404)",
          modeOfPayment: [
            "cash",
            "upi",
            "net_banking",
            "bank_transfer",
            "neft",
            "rtgs",
            "cheque",
            "imps",
          ],
        },
        {
          bankName: "Phonepe Business Account",
          id: 4,
          ledger_name_in_zoho: " Phonepe Business Account(540407)",
          modeOfPayment: ["phonepe", "upi"],
        },
        {
          bankName: "Razorpay",
          id: 5,
          ledger_name_in_zoho: "Razorpay(540408)",
          modeOfPayment: ["credit_card", "upi", "net_banking"],
        },
        {
          bankName: "Paytm Accounts",
          id: 6,
          ledger_name_in_zoho: "Paytm Accounts(540405)",
          modeOfPayment: ["paytm", "other_upi"],
        },
        {
          bankName: "Airtel Payment  Bank Account",
          id: 7,
          ledger_name_in_zoho: "Airtel Payment Bank Account(540410)",
          modeOfPayment: ["cash"],
        },
      ];
  const [mappedAccountListAndModes, setMappedAccountListAndModes] = useState(
    []
  );
  const [selectedBankModes, setSelectedBankModes] = useState([]);
  const [selectedBankObj, setSelectedBankObj] = useState([]);
  const [currentImage, setCurrentImage] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [errTransactionId, setErrTransactionId] = useState("");
  const [openDrawer, setOpenDrawer] = React.useState({
    right: false,
  });

  const handleOpenDrawer = (anchor, open) => {
    setOpenDrawer({ ...openDrawer, [anchor]: open });
  };

  // function

  const handleChangeBankName = (e, index, bankList, bankStaticData) => {
    const key = e.target.name;
    let newArr = [];
    const value = e.target.value;
    let bankPaymentArrayTemp = [...deliveryPaymentDetailsArray];
    bankPaymentArrayTemp[index][key] = value;
    setDeliveryPaymentDetailsArray(bankPaymentArrayTemp);
    const filtredBankList = bankList.filter(
      (data) => data.account_name == value
    );
    if (filtredBankList.length > 0) {
      let bankPaymentArrayTempId = [...deliveryPaymentDetailsArray];
      bankPaymentArrayTempId[index]["zoho_books_account_id"] =
        filtredBankList[0].account_id;
      setDeliveryPaymentDetailsArray(bankPaymentArrayTempId);
    }

    const selectedBankObj = bankStaticData.filter(
      (option) => option.bankName === value
    );
    setSelectedBankObj(selectedBankObj);
    const selectedBankObjFronMappedBankObj = mappedAccountListAndModes.filter(
      (option) => option.zoho_books_account_name === value
    );

    newArr = deliveryPaymentDetailsArray.map((ele) => {
      if (ele.zoho_books_account_name === selectedBankObj[0].bankName) {
        return {
          ...ele,
          ["ledger_name_in_zoho"]: selectedBankObj[0].ledger_name_in_zoho,
          ["account_code"]: selectedBankObjFronMappedBankObj[0].account_code,
        };
      } else {
        return ele;
      }
    });

    if (newArr.length) {
      setDeliveryPaymentDetailsArray(newArr);
    }
  };

  const handleRemovePayment = (index) => {
    let paymentArrayTemp = [...deliveryPaymentDetailsArray];
    paymentArrayTemp.splice(index, 1);
    setDeliveryPaymentDetailsArray(paymentArrayTemp);
  };

  const handleChangeDeliveryPayment = (e, index) => {
    const key = e.target.name;
    const value = e.target.value;
    let deliveryPaymentArrayTemp = [...deliveryPaymentDetailsArray];
    deliveryPaymentArrayTemp[index][key] = value;
    setDeliveryPaymentDetailsArray(deliveryPaymentArrayTemp);

    setErrDeliveryPayment("");
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const getDeliverydata = async (getInvoice) => {
    if (selectedInvioceNumber === undefined || selectedInvioceNumber === "")
      return;
    setLoadingPaymentHistory(true);
    setTripItemLoading(true);

    try {
      const responseDelivery = await getTripItems(tripId, getInvoice);

      if (responseDelivery) {
        setSigleInvoicePaymentHistory(responseDelivery.invoice_payment_history);
        setTripListItems(responseDelivery.trip_items[0]);
        var totalPaidAmount = 0;
        var totalDiscount = 0;

        responseDelivery.invoice_payment_history
          .filter((item) => item.customer_payment_status !== "rejected")
          .map(
            (payment) => (totalPaidAmount = totalPaidAmount + payment.amount)
          );

        const totalPrice =
          responseDelivery.trip_items[0].item_rate *
          responseDelivery.trip_items[0].item_qty;
        allDiscountArray?.map(
          (discount) => (totalDiscount += discount.discount_amount)
        );

        const priceAfterDiscount = totalPrice - totalDiscount;
        const finalPrice = priceAfterDiscount - totalPaidAmount;

        // setFinalBalance(finalPrice);
      }

      if (responseDelivery) {
        setLoadingPaymentHistory(false);
        setPaymentHistory(responseDelivery.invoice_payment_history);
        setTripData(
          responseDelivery.trip_items.map((item) => ({
            ...item,
            blocked_quantity_for_invoice_payment: item.item_qty,
          }))
        );
        const filteredData = allDiscountArray.filter((item) => {
          return item.reference_number === getInvoice;
        });
        const filteredDeliveryData = allDeliveryChargesArray.filter((item) => {
          return item.additional_data.reference_number === getInvoice;
        });
        if (filteredData.length > 0) {
          setSelectedDiscountDetails(filteredData[0]);
        }
        if (filteredDeliveryData.length > 0) {
          setSelectedDeliveryCharges(filteredDeliveryData[0]);
        }
        // setSelectedDeliveryCharges()
        if (responseDelivery.trip_items.length > 0) {
          setSelectedReferenceId(
            responseDelivery.trip_items[0]?.reference_source_id
          );
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPaymentHistory(false);
      setTripItemLoading(false);
      setOpen(false);
    }
  };

  const handlePaymentAttachment = (event, index) => {
    //
    // const filtred = deliveryPaymentDetailsArray.filter(
    //   (item) => item.index == index
    // );
    console.log(index, "dfdfd");

    let deliveryPaymentTemp = [...deliveryPaymentDetailsArray];
    deliveryPaymentTemp[index]["particular_attachment"] = event.target.files[0];
    // setPaymentAttachment(event.target.files[0]);
    setDeliveryPaymentDetailsArray([...deliveryPaymentTemp]);
    setErrDeliveryPayment("");
    setCurrentImage([...deliveryPaymentTemp]);

    const isImageSizeValid = deliveryPaymentTemp
      .filter((data) => data.particular_amount !== "")
      .map((item) => {
        if (!item.particular_attachment) {
          return;
        } else if (
          item.particular_attachment.size > process.env.REACT_APP_MAX_FILE_SIZE
        ) {
          return false;
        } else {
          return true;
        }
      });

    if (isImageSizeValid.indexOf(false) >= 0) {
      setValidImageSize(false);
      setErrDeliveryPayment("Please select file with size less than 2MB");
      return false;
    } else {
      setValidImageSize(true);
    }

    // attachment valid
    const isAttachmentValid = deliveryPaymentTemp
      .filter((data) => data.particular_amount !== "")
      .map((item) => {
        if (
          item.particular_attachment.type == "image/png" ||
          item.particular_attachment.type == "image/jpeg" ||
          item.particular_attachment.type == "image/jpg"
        ) {
          return true;
        } else {
          return false;
        }
      });

    if (isAttachmentValid.indexOf(false) >= 0) {
      setValidImageFormat(false);
      setErrDeliveryPayment("Only Image/png files are allowed");
      return false;
    } else {
      setValidImageFormat(true);
    }
  };

  const handleAddDeliveryPayment = () => {
    let paymentArrayTemp = [...deliveryPaymentDetailsArray];
    paymentArrayTemp.push({
      particular_payment_mode: "0",
      particular_amount: "",
      particular_transaction_reference_id: "",
      particular_attachment: null,
    });
    setDeliveryPaymentDetailsArray(paymentArrayTemp);
    setValidImageFormat(false);
    setValidImageSize(false);
  };

  const resetForm = () => {
    setTransactionId("");
    setPaymentAttachment(null);
    setErrDeliveryPayment("");
    setDeliveryPaymentDetailsArray([
      {
        particular_payment_mode: "",
        particular_amount: "",
        particular_transaction_reference_id: "",
        particular_attachment: null,
        zoho_books_account_name: "",
        account_code: "",
        ledger_name_in_zoho: "",
      },
    ]);
    setErrDeliveryPaymentArray([
      {
        err_particular_payment_mode: "",
        err_particular_amount: "",
        err_particular_transaction_reference_id: "",
      },
    ]);
  };

  const isPaymentFormValid = (paymentArray) => {
    if (
      paymentArray[0].particular_payment_mode == "0" ||
      paymentArray[0].particular_amount == "" ||
      paymentArray[0].particular_attachment == null ||
      paymentArray[0].particular_transaction_reference_id == ""
    ) {
      // return false;
      setErrDeliveryPayment("*Please Fill All Required Fields.");
    } else {
      const isAmountValid = paymentArray
        .filter((data) => data.particular_amount !== "")
        .map((item) => parseFloat(item.particular_amount))
        .reduce((a, b) => a + b);

      if (finalBalance != isAmountValid) {
        setErrDeliveryPayment("Entered amount is not equal to total amount");
        return false;
      }

      const isImageSizeValid = paymentArray
        .filter((data) => data.particular_amount !== "")
        .map((item) => {
          if (!item.particular_attachment) {
            return;
          } else if (
            item.particular_attachment.size >
            process.env.REACT_APP_MAX_FILE_SIZE
          ) {
            return false;
          } else {
            return true;
          }
        });

      if (isImageSizeValid.indexOf(false) >= 0) {
        // setValidImageSize(false);
        setErrDeliveryPayment("Please select file with size less than 2MB");
        return false;
      }

      // attachment valid
      const isAttachmentValid = paymentArray
        .filter((data) => data.particular_amount !== "")
        .map((item) => {
          if (
            item.particular_attachment.type == "image/png" ||
            item.particular_attachment.type == "image/jpeg" ||
            item.particular_attachment.type == "image/jpg"
          ) {
            return true;
          } else {
            return false;
          }
        });

      if (isAttachmentValid.indexOf(false) >= 0) {
        // setValidImageFormat(false);
        setErrDeliveryPayment("Only Image/png files are allowed");
        return false;
      }

      // comment in the end

      return true;
    }
  };

  const handlePayNow = async () => {
    //
    if (isPaymentFormValid(deliveryPaymentDetailsArray)) {
      setLoadingButton(true);

      const response = await TripPaymentPost(
        paymentMode,
        finalBalance,
        selectedInvioceNumber,
        paymentAttachment,
        transactionId,
        tripData.map(function (item) {
          delete item.unloaded_quantity;
          return item;
        }),
        deliveryPaymentDetailsArray
      );
      if (response.status === 200) {
        setLoadingButton(false);
        resetForm();
        setPaymentStatus(true);
        setTripData([]);
        // setTotalAmount("");
        handleClosePaymentDrawer();
        getTripDetails(tripId);
        setValidImageSize(false);
        setValidImageFormat(false);
        getDeliverydata(selectedInvioceNumber);
        zohoBankAccountId();
        getTripItemWithTripCode(selectedTrip.trip_code);
      } else if (response.status === 401) {
        setLoadingButton(false);
        setErrDeliveryPayment("Not enough permissions");
      } else if (response.status === 400) {
        setLoadingButton(false);
        setErrDeliveryPayment(response.data.detail);
      } else if (response.status === 404) {
        setLoadingButton(false);
        setErrDeliveryPayment(response.data.detail);
      } else {
        setLoadingButton(false);
        setErrDeliveryPayment(`${response.status} : ERROR`);
      }
    } else {
      setErrTransactionId("Please enter valid Transaction Id");
    }
  };

  const handleClosePaymentDrawer = () => {
    handleOpenDrawer("right", false);
    resetForm();
    setValidImageSize(false);
    setValidImageFormat(false);
  };

  const getTripDetails = async (id) => {
    const response = await getTripDetailsFromId(id);

    dispatch(updateSelectedTrip(response));
    dispatch(updatePendingTripInvoiceList(response.pending_reference_numbers));
  };

  const approvedPayment = paymentHistory.filter(
    (item) => item.customer_payment_status !== "rejected"
  );

  const calulateApprovedPaymentTotal = approvedPayment.reduce((acc, item) => {
    return acc + item.amount;
  }, 0);

  useEffect(() => {
    let array = [];
    deliveryPaymentDetailsArray.map((ele) => {
      bankStaticData.map((e) => {
        if (ele.zoho_books_account_name === e.bankName) {
          array.push(e.modeOfPayment);
        }
      });
    });

    setSelectedBankModes(array);

    deliveryPaymentDetailsArray.map((object, index) => {
      let flag = true;
      if (index === 0) {
        const isValid = Object.values(object).every(
          (value) => value !== null && value !== undefined && value !== ""
        );
        flag = isValid;
      } else {
        let isAnyFieldValid = false;

        if (object.zoho_books_account_name) {
          isAnyFieldValid = true;
        }
        if (
          object.particular_payment_mode &&
          object.particular_payment_mode !== "0"
        ) {
          isAnyFieldValid = true;
        }
        if (object.particular_attachment) {
          isAnyFieldValid = true;
        }
        if (object.particular_amount) {
          isAnyFieldValid = true;
        }
        if (object.particular_transaction_reference_id) {
          isAnyFieldValid = true;
        }

        if (isAnyFieldValid) {
          const isValid = Object.values(object).every(
            (value) => value !== null && value !== undefined && value !== ""
          );
          flag = isValid;
        } else {
          flag = true;
        }
      }
      setShowSaveButton(flag);
    });
  }, [deliveryPaymentDetailsArray]);

  useEffect(() => {
    let arr = [];
    if (zohoBankAccountList.length)
      zohoBankAccountList.map((bank) => {
        bankStaticData.map((staticBank) => {
          if (bank.account_name === staticBank.bankName) {
            let sendableObject = {
              particular_payment_mode: staticBank.modeOfPayment,
              particular_amount: "",
              particular_transaction_reference_id: "",
              zoho_books_account_name: bank.account_name,
              zoho_books_account_id: bank?.account_id,
              ledger_name_in_zoho: staticBank.ledger_name_in_zoho,
              account_code: bank?.account_code,
            };
            arr.push(sendableObject);
          }
        });
      });
    setMappedAccountListAndModes(arr);
  }, [zohoBankAccountList]);

  useEffect(() => {
    if (validImageSize && validImageFormat) {
      handleAddDeliveryPayment();
    }
  }, [validImageSize, validImageFormat]);
  const zohoBankAccountId = async () => {
    const res = await getPaymentZohobankAccount();
    if (res) {
      setZohoBankAccountList(res.bankaccounts);
    }
  };
  useEffect(() => {
    if (selectedInvioceNumber != "0") {
      getDeliverydata(selectedInvioceNumber);
    }
  }, [selectedInvioceNumber, allDiscountArray]);
  useEffect(() => {
    zohoBankAccountId();
  }, []);
  function capitalizeLetter(str) {
    var i,
      splittedArray = str.split("_");
    for (i = 0; i < splittedArray.length; i++) {
      splittedArray[i] =
        splittedArray[i].charAt(0).toUpperCase() + splittedArray[i].slice(1);
    }
    return splittedArray.join(" ");
  }
  // ========================Drawer End=============================

  // ==================Trip Item API Start========================

  const getTripItemWithTripCode = async (trip_code) => {
    setOpen(true);
    try {
      const response = await getTripItemsWithDiscount(trip_code);
      console.log(response, "trip response 000");
      if (response) setAllTripItemData(response);
    } catch (error) {
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    getTripItemWithTripCode(selectedTrip.trip_code);
  }, []);

  // =================Trip Item API End=========================

  // useEffect(() => {
  //   if (
  //     finalFiltredArrayOfPendingInvoicesStatus.includes("submitted") ||
  //     finalFiltredArrayOfPendingInvoicesStatus.includes("recommended")
  //   ) {
  //     setPendingInvoiceMsg(
  //       `Please Approve or Reject Payment for these invoices ${finalFiltredArrayOfPendingInvoices.map(
  //         (data) => data
  //       )}`
  //     );
  //     // return;
  //   }
  //   if (
  //     finalFiltredArrayOfPendingInvoicesStatus.includes("approved") ||
  //     finalFiltredArrayOfPendingInvoicesStatus.includes("rejected")
  //   ) {
  //     return;
  //   }
  //   // if (finalFiltredArrayOfPendingInvoicesStatus.includes("recommended")) {
  //   //   setPendingInvoiceMsg(
  //   //     `Please clear payments for all the pending invoices ${finalFiltredArrayOfPendingInvoices.map(
  //   //       (data) => data
  //   //     )}`
  //   //   );
  //   //   // return;
  //   //

  //   //   // return;
  //   // }
  //   // else {
  //   //   setPendingInvoiceMsg(
  //   //     `Please clear payments for all the pending invoices ${finalFiltredArrayOfPendingInvoices.map(
  //   //       (data) => data
  //   //     )}`
  //   //   );
  //   // }
  // }, [deliveredInvoicesList]);
  const [tripRemarks, setTripRemarks] = useState("");
  const [tripDeliveredBills, setTripDeliveredBills] = useState([]);
  const [unloadedQuantity, setUnloadedQuantity] = useState("");
  const [costKeys, setCostKeys] = useState(["z"]);
  const [tripCompletionRequestData, setTripCompletionRequestData] = useState(
    {}
  );
  const [pendingReferenceNumbers, setPendingReferenceNumbers] = useState([]);
  const [attachmentCount, setAttachmentCount] = useState([1]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [errRemarks, setErrRemarks] = useState("");
  const [errTripCost, setErrTripCost] = useState("");
  const [tripCompleteMeterReading, setTripCompleteMeterReading] = useState("");
  const [tripCostTotal, setTripCostTotal] = useState("");
  const [errUnloadedQuantity, setErrUnloadedQuantity] = useState("");
  const [unloadedQuantityValidationArray, setUnloadedQuantityValidationArray] =
    useState([]);
  const [errTripAttachment, setErrTripAttachment] = useState("");
  const [data, setData] = useState({});

  const [allChargesList, setAllChargesList] = useState([
    "labour_charges",
    "night_halt_charges",
    "toll_charges",
    "others_expenses",
  ]);
  const [approxCostDetails, setApproxCostDetails] = useState({
    z: "",
  });

  const [currentTab, setCurrentTab] = useState(null);

  const { selectedTrip } = useSelector((state) => state.trip);
  const { pendingTripInvoiceList } = useSelector((state) => state.trip);

  const [attachmentDetailsList, setAttachmentDetailsList] = useState([
    {
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    },
  ]);
  const [newTripCost, setNewTripCost] = useState([]);
  const [otherExpenseType, setOtherExpenseType] = useState("");
  const [purchaseOrderAllItems, setPurchaseOrderAllItems] = useState([]);
  const [perBagCost, setPerBagCost] = useState("");
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [errTripCompleteMeterReading, setErrTripCompleteMeterReading] =
    useState("");
  const [
    errTripCompleteMeterReadingAttachment,
    setErrTripCompleteMeterReadingAttachment,
  ] = useState("");
  const [errCompleteTrip, setErrCompleteTrip] = useState("");
  const [
    tripCompleteMeterReadingAttachment,
    setTripCompleteMeterReadingAttachment,
  ] = useState({
    attachment_tag: "trip_complete_meter_reading_attachment",
    attachment_type: "",
    attachment_file: null,
  });

  const handleChangeTripMeterReadingAttachment = (e) => {
    setTripCompleteMeterReadingAttachment({
      ...tripCompleteMeterReadingAttachment,
      attachment_file: e.target.files[0],
    });
  };

  const getTripCompeltionRequestedData = async (id) => {
    handleOpen();
    const response = await getTripCompleteRequestDetails(id);

    if (response.status == 200) {
      setUnloadedQuantity(response.data.unloaded_quantity);
      setTripCompleteMeterReading(response.data.trip_complete_meter_reading);
      //
      setTripCompletionRequestData(response.data);
      setInvoicePaymentHistoryList(response.data.invoice_payment_history);
      const tripCostRequested = response.data.trip_cost;

      if (response.data.trip_cost.length > 0) {
        const filtredData = response.data.trip_cost.filter(
          (data) =>
            data.cost_type !== "trip_cost" && data.cost_type !== "advance"
        );
        const temp = {};
        filtredData.map((item) => (temp[item.cost_type] = item.cost_amount));

        setApproxCostDetails(temp);
        setCostKeys(Object.keys(temp));
        handleClose();
      }
      if (response.data.trip_history.length > 0) {
        const filtredDataRemarks = response.data.trip_history.filter(
          (data) => data.trip_status == "requested_for_completion"
        );
        setTripRemarks(filtredDataRemarks[0].remarks);
        //
        // const temp = {};
        // filtredData.map((item) => (temp[item.cost_type] = item.cost_amount));
        handleClose();
        // setApproxCostDetails(temp);
      }
    }
    handleClose();
  };
  useEffect(() => {
    getTripCompeltionRequestedData(tripId);
  }, [tripId]);

  const getAllItemsPurchaseOrder = async (selectedTrip) => {
    if (
      selectedTrip.reference_number &&
      selectedTrip.load_source === "trip_purchase_order"
    ) {
      const response = selectedTrip.reference_number.map(async (item) => {
        const resp = await getTripItems(tripId, item);

        return resp;
      });
      const resolved = await Promise.all(response);

      setPurchaseOrderAllItems(resolved);
    }
    setLoadingPage(false);
  };

  useEffect(() => {
    if (Object.keys(selectedTrip).length > 0) {
      getAllItemsPurchaseOrder(selectedTrip);
    }
  }, [selectedTrip]);

  const getTripDetailsByIdApi = async (tripId) => {
    const response = await getTripDetailsById(tripId);

    dispatch(updateSelectedTrip(response.data));
  };

  useEffect(() => {
    if (Object.keys(selectedTrip).length === 0) {
      getTripDetailsByIdApi(tripId);
    }
  }, [tripId]);

  useEffect(() => {
    if (selectedTrip.reference_number) {
      setTripDeliveredBills(
        selectedTrip.reference_number.map((item) => ({
          reference_number: item,
          status:
            selectedTrip.pending_reference_numbers.indexOf(item) === -1
              ? "delivered"
              : "not-delivered",
        }))
      );
    }
    // if (selectedTrip.pending_reference_numbers) {
    //   setPendingReferenceNumbers(selectedTrip.pending_reference_numbers);
    // }
  }, [selectedTrip]);

  const tripDetails = async (trip) => {
    // handleOpen();
    const tripDetailsData = await getTripDetailsFromId(trip);
    dispatch(updateSelectedTrip(tripDetailsData));
    // handleClose();
  };

  useEffect(() => {
    tripDetails(tripId);
  }, [tripId]);

  const getTripItemWithDiscountApi = async (trip_code) => {
    try {
      const response = await getTripItemsWithDiscount(trip_code);
      if (response) {
        setAllDiscountArray(response.trip_invoice_discounts);
        setInvoicePaymentHistory(response.invoice_payment_history);
        setTripEntityMapping(response.trip_entity_mapping);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpen(false);
    }
  };

  // const getTripItemApi = async (id, reference_number) => {
  //   try {
  //     const response = await getTripItems(id, reference_number);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setTripItemLoading(false);
  //   }
  // };

  useEffect(() => {
    getTripItemWithDiscountApi(selectedTrip.trip_code);
  }, [selectedTrip.trip_code]);

  // ===========================================

  const handleGoToTrips = () => {
    localStorage.setItem("FlagForApiCall", false);

    history.push("/faarms-trips");
  };

  const handleRemoveCost = (costType) => {
    let tempApproxCost = approxCostDetails;
    delete tempApproxCost[costType];

    setApproxCostDetails(tempApproxCost);
    setCostKeys(Object.keys(tempApproxCost));
  };

  //Drawer starts

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  //Drawer ends

  const handleAttachmentChange = (e, index) => {
    e.preventDefault();
    let key = e.target.name;
    let value;
    if (key == "attachment_file") {
      value = e.target.files[0];
    } else {
      value = e.target.value;
    }

    let attachmentArrayTemp = [...attachmentDetailsList];
    attachmentArrayTemp[index][key] = value;
    setAttachmentDetailsList(attachmentArrayTemp);
  };

  const onFileChange = (event) => {
    // setTripAttachmentFile(event.target.files[0]);
  };

  const handleAddNewCost = () => {
    const tempCost = approxCostDetails;

    tempCost["z"] = "";
    setApproxCostDetails(tempCost);
    setCostKeys(Object.keys(tempCost));
  };

  const handleTripCostUpdate = () => {
    setNewTripCost();
  };

  const isQuantityValid = () => { };

  const isCompleteTripValid = () => {
    const tripCostTemp =
      Object.keys(approxCostDetails).filter((item) => item !== "z").length > 0;
    let attachmentValid = false;
    if (
      attachmentDetailsList.filter((item) => item.attachment_file != null)
        .length > 0
    ) {
      attachmentValid = true;
    }
    let isMeterReadingAttachmentValid = false;
    if (tripCompleteMeterReadingAttachment.attachment_file) {
      if (tripCompleteMeterReadingAttachment.attachment_file.size < 1000000) {
        isMeterReadingAttachmentValid = true;
      }
    }

    if (selectedTrip.load_source == "manual") {
      return (
        tripRemarks.trim() &&
        attachmentValid &&
        unloadedQuantity &&
        tripCompleteMeterReading &&
        tripCompleteMeterReadingAttachment.attachment_file &&
        isMeterReadingAttachmentValid
      );
    } else {
      return (
        tripRemarks.trim() &&
        attachmentValid &&
        tripCompleteMeterReading &&
        tripCompleteMeterReadingAttachment.attachment_file &&
        isMeterReadingAttachmentValid
      );
    }
  };

  useEffect(() => {
    async function fetchData() {
      const documentTypeData = await GetCompleteTripDocumentEnums();
      setDocumentTypeList(documentTypeData);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedTrip.trip_cost) {
      if (selectedTrip.trip_cost.length > 0) {
        //trip cost
        const tripCostTemp = selectedTrip.trip_cost.filter(
          (item) => item.cost_type === "trip_cost"
        );
        if (tripCostTemp.length > 0 && selectedTrip.unloaded_quantity) {
          setTripCostTotal(tripCostTemp[0].cost_amount);
          setPerBagCost(
            (
              tripCostTemp[0].cost_amount / selectedTrip.unloaded_quantity
            ).toFixed(2)
          );
        } else if (tripCostTemp.length > 0 && selectedTrip.quantity) {
          setTripCostTotal(tripCostTemp[0].cost_amount);
          setPerBagCost(
            (tripCostTemp[0].cost_amount / selectedTrip.quantity).toFixed(2)
          );
        }
      }
    }
  }, [selectedTrip]);

  const handlePurchaseOrderItemsDeliveredPost = async (itemId, itemDetails) => {
    const response = await itemDetails.map(
      async (item) => await purchaseOrderItemsDeliveredPost(itemId, item)
    );
    const resolved = await Promise.all(response);
    return resolved;
  };

  const handleSubmitTripComplete = async () => {
    if (isCompleteTripValid() && !loadingModal) {
      if (
        attachmentDetailsList
          .map((item) => {
            if (item.attachment_file) {
              if (item.attachment_file.size > 1000000) {
                return "greater";
              }
            }
          })
          .indexOf("greater") > -1
      ) {
        return;
      }
      setLoadingModal(true);

      const PoResponse = await handlePurchaseOrderItemsDeliveredPost(
        selectedTrip.id,
        purchaseOrderAllItems
      );

      for (let i = 0; i < PoResponse.length; i++) {
        // if (PoResponse[i].status === 200) {

        // }
        if (PoResponse[i].status === 400) {
          setErrCompleteTrip(PoResponse[i].data.detail);
          setLoadingModal(false);
          return;
        } else if (PoResponse[i].status === 422) {
          setErrCompleteTrip("Unloaded Quantity is not valid");
          setLoadingModal(false);
          return;
        }
      }

      // const POItemsApiResponse = PoResponse.map((item) => item);
      // setNewTripCost(
      //   Object.keys(approxCostDetails).map((item) => ({
      //     cost_type: item,
      //     cost_amount: approxCostDetails[item],
      //     trip_id: selectedTrip.id,
      //   }))
      // );
      // const attachmentResponse = TripAttachmentPost(
      //   "jhgf",
      //   selectedTrip.id,
      //   "mn",
      //   ",kjnb",
      //   "end_trip",
      //   attachmentDetailsList
      // );

      // const resolved = await Promise.all(attachmentResponse);

      const response = await requestCompleteTrip(
        "requested_for_completion",
        tripRemarks,
        selectedTrip.id,
        tripDeliveredBills,
        Object.keys(approxCostDetails)
          .filter((item) => item !== "z")
          .map((item) => ({
            cost_type: item,
            cost_amount: approxCostDetails[item],
            trip_id: selectedTrip.id,
          })),
        unloadedQuantity,
        tripCompleteMeterReading,
        tripCompleteMeterReadingAttachment,
        "end_trip",
        attachmentDetailsList[0]
      );
      if (response.status == 400) {
        setLoadingModal(false);
        setErrCompleteTrip(response.data.detail);
      } else if (response.status == 200) {
        if (attachmentDetailsList.length > 1) {
          const attachmentListTemp = [...attachmentDetailsList];
          attachmentListTemp.shift();
          const attachmentResponse = TripAttachmentPost(
            "jhgf",
            selectedTrip.id,
            "mn",
            ",kjnb",
            "end_trip",
            attachmentListTemp
          );

          const resolved = await Promise.all(attachmentResponse);
        }

        localStorage.setItem("FlagForApiCall", false);
        setLoadingModal(false);
        history.push("/faarms-trips");
      } else if (response.status == 422) {
        setLoadingModal(false);
        setErrCompleteTrip(
          `${response?.data?.detail[0]?.loc?.slice(-1)} : ${response?.data?.detail[0]?.msg
          }`
        );
      } else {
        setLoadingModal(false);
        setErrCompleteTrip(`${response.status} : ERROR`);
      }
      //
    } else {
      setErrRemarks("Please enter valid remarks");
      setErrTripCost("Please enter valid cost");
      setErrTripAttachment("Please upload valid attachment");
      setErrUnloadedQuantity("Please enter valid unloaded quantity");
      setErrTripCompleteMeterReading("Please enter valid meter reading");
      setErrTripCompleteMeterReadingAttachment(
        "Please select valid attachment"
      );
    }
  };

  const ValidateUnloadedQuantity = () => {
    const resposnee = purchaseOrderAllItems.map((itemList) => {
      return itemList.map((item) => {
        if (item.unloaded_quantity) {
          return true;
        } else {
          return false;
        }
      });
    });
    setUnloadedQuantityValidationArray(resposnee);
  };

  const handleDeliveredQtyChange = (indexs, index, e) => {
    if (e.target.value >= 0) {
      const purchaseOrderItemsTemp = [...purchaseOrderAllItems];
      purchaseOrderItemsTemp[indexs][index]["unloaded_quantity"] =
        e.target.value;
      purchaseOrderItemsTemp[indexs][index]["status"] = "delivered";
      setPurchaseOrderAllItems(purchaseOrderItemsTemp);
    }
    ValidateUnloadedQuantity();
  };

  const handleAddAttachment = () => {
    var attachmentTemp = [...attachmentDetailsList];
    attachmentTemp.push({
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    });
    setAttachmentDetailsList(attachmentTemp);
  };

  const handleDeliveredCheckboxToggle = (orderDetail, index) => {
    let tripDeliveredBillsTemp = [...tripDeliveredBills];
    tripDeliveredBillsTemp[index]["status"] =
      tripDeliveredBillsTemp[index]["status"] == "delivered"
        ? "not-delivered"
        : "delivered";

    setTripDeliveredBills(tripDeliveredBillsTemp);
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  const uploadInputRef = useRef(null);

  return (
    <div className="complete-trip-main-container">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            src={success}
            alt=""
            width="120px"
            height="90px"
          // style={{ marginTop: "200px" }}
          />
          <div style={{ color: "black" }}>loading data...</div>
        </Box>
      </Modal>
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={9999999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong
            backButtonLink={`/faarms-trips/${tripId}/faarms-trip-complete-request`}
            buttonText="Close"
          />
        </div>
      </ModalAntd>
      {/* <RecordPaymentDrawer /> */}
      <Drawer
        className="drawer"
        anchor={"right"}
        open={openDrawer["right"]}
        onClose={handleClosePaymentDrawer}
      >
        <Payment_drawer className="payment-drawer">
          <div className="payment-wrapper">
            <div className="delivery-invoice-close">
              <Close
                style={{ cursor: "pointer" }}
                onClick={handleClosePaymentDrawer}
              />{" "}
            </div>
            <div className="payment-headings">
              <h4 className="delivery-invoice-input-head">
                Payment details for Invoice ({selectedInvioceNumber})
              </h4>
            </div>
            <div className="payment-total">
              <h4 className="delivery-invoice-input-head">
                Total Amount :{" "}
                <span style={{ fontFamily: "sans-serif" }}> ₹ </span>
                {finalBalance}
              </h4>
            </div>
            {/* new design========================== */}
            <div className="table-main-container">
              <div className="table-head">
                <div className="table-head-data">Account</div>
                <div className="table-head-data">Mode</div>
                <div className="table-head-data">
                  {isMobile ? "Amt(₹)" : "Amount(₹)"}
                </div>
                <div className="table-head-data">
                  {isMobile ? "Ref Id" : "Reference Id"}
                </div>
                <div className="table-head-data">
                  {isMobile ? "Docs" : "Document"}
                </div>
              </div>
            </div>
            {deliveryPaymentDetailsArray.map((item, index) => {
              return (
                <>
                  <div className="table-value" key={index}>
                    <div className="table-head-data-fields">
                      <TextField
                        size="small"
                        id="dropdown"
                        autoComplete="off"
                        select
                        name="zoho_books_account_name"
                        // className="create-trip-input"
                        style={{ width: isMobile ? "11ch" : "18ch" }}
                        variant="outlined"
                        // placeholder="Enter transaction reference id"
                        value={item.zoho_books_account_name}
                        onChange={(e) => {
                          handleChangeBankName(
                            e,
                            index,
                            zohoBankAccountList,
                            bankStaticData
                          );
                          // setTransactionId(e.target.value);
                        }}
                      >
                        <MenuItem
                          disabled
                          value="0"
                        // style={{ fontSize: "12px" }}
                        >
                          select
                        </MenuItem>
                        {mappedAccountListAndModes.map((item) => {
                          return (
                            <MenuItem value={item.zoho_books_account_name}>
                              {item?.zoho_books_account_name
                                .split("_")
                                .join(" ")
                                .replace(/[0-9-]/g, "")
                                .toUpperCase()}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                    <div className="table-head-data-fields">
                      {" "}
                      <TextField
                        size="small"
                        id="dropdown"
                        autoComplete="off"
                        select
                        name="particular_payment_mode"
                        // className="create-trip-input"
                        style={{ width: isMobile ? "9.5ch" : "18ch" }}
                        variant="outlined"
                        placeholder="Enter transaction reference id"
                        value={item.particular_payment_mode}
                        onChange={(e) => {
                          handleChangeDeliveryPayment(e, index);
                          // if (item.particular_payment_mode == "cash") {
                          //   handleAddDeliveryPayment();
                          // }
                          // setTransactionId(e.target.value);
                        }}
                      >
                        <MenuItem disabled value="0">
                          select
                        </MenuItem>
                        {selectedBankModes[index]?.map((item) => {
                          return (
                            <MenuItem value={item}>
                              {item.split("_").join(" ").toUpperCase()}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                    <div className="table-head-data-fields">
                      <TextField
                        size="small"
                        id="dropdown"
                        autoComplete="off"
                        // style={{ width: "96%" }}
                        style={{ width: isMobile ? "9.5ch" : "18ch" }}
                        // className="create-trip-input"
                        variant="outlined"
                        type="number"
                        name="particular_amount"
                        placeholder="Enter Amount"
                        inputProps={{
                          sx: {
                            "&::placeholder": {
                              color: "#A4A4A4",
                              fontSize: "12px",
                              opacity: 1, // otherwise firefox shows a lighter color
                            },
                          },
                        }}
                        onKeyPress={(event) => {
                          if (!/^[0-9.]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={item.particular_amount}
                        onChange={(e) => {
                          handleChangeDeliveryPayment(e, index);

                          // setTransactionId(e.target.value);
                        }}
                      />
                    </div>
                    <div className="table-head-data-fields">
                      <TextField
                        size="small"
                        id="dropdown"
                        autoComplete="off"
                        name="particular_transaction_reference_id"
                        // className="create-trip-input"
                        style={{ width: isMobile ? "9ch" : "18ch" }}
                        variant="outlined"
                        // sx={{
                        //   label: { fontSize: "5px" },
                        //   placeholder: { fontSize: "5px" },
                        // }}
                        placeholder="Enter transaction reference id"
                        inputProps={{
                          sx: {
                            "&::placeholder": {
                              color: "#A4A4A4",
                              fontSize: "12px",
                              opacity: 1, // otherwise firefox shows a lighter color
                            },
                          },
                        }}
                        value={item.particular_transaction_reference_id}
                        onChange={(e) => {
                          handleChangeDeliveryPayment(e, index);
                          // setTransactionId(e.target.value);
                        }}
                      />
                    </div>
                    <div className="table-head-data-fields">
                      <>
                        {" "}
                        {isMobile ? (
                          <div className="button-upload" onClick={handleClick}>
                            <UploadIcon /> {isMobile ? "" : "Upload"}
                            {item.particular_attachment !== null ? (
                              <CheckIcon
                                className="delivery-right-payment-button"
                                style={
                                  isMobile
                                    ? { paddingRight: "5px" }
                                    : {
                                      color: "green",
                                      fontSize: "20px",
                                      marginLeft: "10px",
                                      fontWeight: "bolder",
                                      borderRadius: "50%",
                                    }
                                }
                              />
                            ) : null}
                            {index !== 0 ? (
                              <span
                                className="delivery-add-payment-button"
                                style={{ fontWeight: "bold" }}
                                onClick={() => handleRemovePayment(index)}
                              >
                                <CancelOutlinedIcon style={{ margin: "2px" }} />
                              </span>
                            ) : null}
                          </div>
                        ) : (
                          <>
                            <button
                              className="button-upload"
                              onClick={handleClick}
                            >
                              <UploadIcon /> {isMobile ? "" : "Upload"}
                              {item.particular_attachment !== null ? (
                                <CheckIcon
                                  className="delivery-right-payment-button"
                                  style={
                                    isMobile
                                      ? {}
                                      : {
                                        color: "green",
                                        fontSize: "20px",
                                        marginLeft: "10px",
                                        fontWeight: "bolder",
                                        borderRadius: "50%",
                                      }
                                  }
                                />
                              ) : null}
                            </button>
                            {index !== 0 ? (
                              <span
                                className="delivery-add-payment-button"
                                style={{ fontWeight: "bold" }}
                                onClick={() => handleRemovePayment(index)}
                              >
                                <CancelOutlinedIcon style={{ margin: "2px" }} />
                              </span>
                            ) : null}
                          </>
                        )}
                        <input
                          type="file"
                          key={index}
                          onChange={(e) => {
                            handlePaymentAttachment(e, index);
                            if (validImageSize && validImageFormat) {
                              handleAddDeliveryPayment();
                            }
                          }}
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                        />
                      </>
                    </div>
                  </div>
                </>
              );
            })}
            {errDeliveryPayment ? (
              <div style={{ width: "100%" }}>
                <Alert severity="error"> {errDeliveryPayment}</Alert>
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <button
                className="button-recordpayment"
                style={
                  !showSaveButton || handleIsItemPicked() === "not_delivered"
                    ? {
                      cursor: "not-allowed",
                      backgroundColor: "#454545",
                      width: "150px",
                      margin: "5px",
                    }
                    : {
                      cursor: "pointer",
                      width: "150px",
                      margin: "5px",
                    }
                }
                // style={{ width: "150px", margin: "5px" }}
                onClick={handlePayNow}
                disabled={
                  loadingButton ||
                    !showSaveButton ||
                    handleIsItemPicked() === "not_delivered"
                    ? true
                    : false
                }
              >
                {loadingButton ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
            {handleIsItemPicked() === "not_delivered" ? (
              <div
                style={{
                  backgroundColor: colorPalette.snakbarColor,
                  padding: "8px 10px",
                  borderRadius: "5px",
                  color: "#454545",
                }}
              >
                Record payment cannot be done before pickup.
              </div>
            ) : null}
            {console.log(handleIsItemPicked(), "handleIsItemPicked()")}
          </div>
        </Payment_drawer>
      </Drawer>
      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Complete trip Request" />
        </div>
      ) : (
        <div className="complete-trip-sidebar">
          <SideBarMain />
        </div>
      )}
      {loadingPage ? (
        <div className="complete-trip-right-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              // backgroundColor: "grey",
            }}
          >
            {" "}
            <img
              src={success}
              alt=""
              width="120px"
              height="90px"
              style={{ marginTop: "200px" }}
            />
            <h6 style={{ color: "grey" }}>loading data...</h6>
          </div>
        </div>
      ) : (
        <div className="complete-trip-right-container">
          <div className="complete-trip-head">
            <ArrowBack
              style={{ cursor: "pointer" }}
              onClick={handleGoToTrips}
            />{" "}
            <h4 className="complete-trip-head-text">
              Enter details for complete trip request
            </h4>
          </div>
          <div className="complete-trip-form-container">
            <div className="complete-trip-row">
              <h4 className="complete-trip-head-text">
                Trip code :{" "}
                {selectedTrip.trip_code ? selectedTrip.trip_code : " - "}
              </h4>
            </div>
            <div className="complete-trip-row">
              <h4 className="complete-trip-head-text">
                Vehicle number :{" "}
                {selectedTrip.vehicle_number
                  ? selectedTrip.vehicle_number
                  : " - "}
              </h4>
            </div>
            <div className="complete-trip-row">
              <h4 className="complete-trip-head-text">
                Per bag cost : Rs. {perBagCost ? perBagCost : " - "}
              </h4>
            </div>
          </div>
          <div className="complete-trip-form-container">
            <div className="complete-trip-row">
              <div className="complete-trip-input-container">
                <h4 className="complete-trip-input-head">
                  {" "}
                  Please enter the remarks (max. 300 characters)
                  <sup style={{ color: "red" }}>*</sup>
                  {/* <span className="create-trip-destination-remove">
                        <Close />
                      </span> */}
                </h4>

                <textarea
                  className="complete-trip-textarea"
                  value={tripRemarks}
                  onChange={(e) => {
                    setTripRemarks(e.target.value);
                    setErrCompleteTrip("");
                  }}
                  rows={5}
                />
                <div className="complete-trip-error">
                  {tripRemarks.trim() ? null : errRemarks}
                </div>
              </div>
            </div>
            <div className="trip-list-reference-list">
              <h4 className="complete-trip-head">
                {selectedTrip.load_source && tripDeliveredBills.length > 0
                  ? selectedTrip.load_source == "trip_invoice"
                    ? "Delivered Invoices"
                    : null
                  : null}
              </h4>
              {/* <div className="complete-trip-invoice-grid">
                {selectedTrip.load_source == "trip_invoice"
                  ? tripDeliveredBills.map((item, index) => (
                      <div className="complete-trip-single-reference">
                        {item.status == "delivered" ? (
                          <CheckCircleIcon
                            style={{ color: "green", fontSize: "1.2rem" }}
                          />
                        ) : (
                          <input
                            id={item.reference_number}
                            name={item.reference_number}
                            type="checkbox"
                            value={item.reference_number}
                            disabled={
                              selectedTrip.load_source === "trip_invoice"
                                ? true
                                : false
                            }
                            onClick={() =>
                              handleDeliveredCheckboxToggle(item, index)
                            }
                            checked={item.status == "delivered" ? true : false}
                          />
                        )}

                        {invoicePaymentHistory?.findIndex(
                          (item) =>
                            item.reference_number === item.reference_number
                        ) ? (
                          <div
                            style={{
                              width: "100%",
                              display: "grid",
                              gridTemplateColumns: "150px 200px 200px",
                              alignItems: "center",
                            }}
                          >
                            <label
                              for={item.reference_number}
                              className="complete-trip-reference-number"
                            >
                              {item.reference_number}
                            </label>
                            <ButtonRectangleSecondary
                              style={{
                                width: "120px",
                              }}
                              disabled={
                                finalBalance !== 0 &&
                                selectedReferenceIndex === index
                              }
                              onClick={() => {
                                // getTripItemApi(
                                //   selectedTrip.id,
                                //   item.reference_number
                                // );
                                setSelectedInvioceNumber(item.reference_number);
                                setSelectedReferenceIndex(index);
                              }}
                            >
                              {tripItemLoading &&
                              selectedReferenceIndex === index ? (
                                <LoadingOutlined
                                  style={{ fontSize: 24 }}
                                  spin
                                />
                              ) : finalBalance === 0 &&
                                selectedReferenceIndex === index &&
                                !tripItemLoading ? (
                                "0"
                              ) : finalBalance &&
                                selectedReferenceIndex === index ? (
                                finalBalance
                              ) : (
                                "Check Payment"
                              )}
                            </ButtonRectangleSecondary>

                            {finalBalance &&
                            selectedReferenceIndex === index &&
                            !tripItemLoading ? (
                              <ButtonRectangle
                                style={{
                                  width: "150px",
                                }}
                                onClick={() =>
                                  handleOpenDrawer("right", "open")
                                }
                              >
                                Record Payment
                              </ButtonRectangle>
                            ) : //  : finalBalance === 0 &&
                            //   selectedReferenceIndex === index &&
                            //   !tripItemLoading ? (
                            //   "Payment Done"
                            // )
                            null}
                          </div>
                        ) : (
                          <label
                            for={item.reference_number}
                            className="complete-trip-reference-number"
                          >
                            {item.reference_number}
                          </label>
                        )}
                      </div>
                    ))
                  : null}
              </div> */}
              <div
                style={isMobile ? { width: "95vw", overflowX: "scroll" } : {}}
              >
                <TableAntd
                  rowClassName={(record, index) =>
                    record.totalAmount === record.totalApprovedAmount
                      ? "table-row-green"
                      : record.totalAmount ===
                        record.totalSubmittedAmount +
                        record.totalRecommendedAmount
                        ? "table-row-yellow"
                        : "table-row-red"
                  }
                  pagination={false}
                  columns={columns}
                  dataSource={tripItemData}
                  style={{ width: "71%" }}

                // bordered
                />
              </div>
            </div>
            {pendingInvoiceMsgp ? (
              <div
                className="complete-trip-row"
                style={{
                  color: "red",
                  backgroundColor: "#F1CCCC",
                  padding: "5px",
                  width: "70%",
                  marginLeft: "10px",
                }}
              >
                {pendingInvoiceMsgp ? pendingInvoiceMsgp : null}
                {/* {pendingReferenceNumbers.map((invoice) => invoice)} */}
              </div>
            ) : null}
            {selectedTrip.load_source === "trip_purchase_order" ? (
              <div>
                {purchaseOrderAllItems.length > 0
                  ? purchaseOrderAllItems.map((itemList, indexs) => {
                    return (
                      <div className="trip-list-reference-list-table">
                        <h4 className="complete-trip-head">
                          Purchase order number :{" "}
                          {itemList[0].reference_number}
                        </h4>
                        <div className="complete-trip-invoice-grid">
                          <table id="invoice-items-complete">
                            <thead className="thead_heading-complete">
                              <tr className="table-head-row-complete">
                                <th className="column001">Items</th>
                                <th className="column003">Delivered qty</th>
                              </tr>
                            </thead>
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              {/* {isMobile ? null : <hr></hr>} */}
                            </div>
                            <tbody className="body">
                              {itemList.map((item, index) => {
                                return (
                                  <tr>
                                    <td className="column001">
                                      {item.item_name}
                                    </td>

                                    <td className="column003">
                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        className="delivery-invoice-quantity-input-complete"
                                        onChange={(e) =>
                                          handleDeliveredQtyChange(
                                            indexs,
                                            index,
                                            e
                                          )
                                        }
                                      />
                                      {/* <div className="complete-trip-error">
                                          Enter valid quantity
                                        </div> */}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  })
                  : null}
              </div>
            ) : null}
            <div className="complete-trip-cost">
              <h4 className="complete-trip-head">Trip Cost details </h4>
              {Object.keys(approxCostDetails).filter((item) => item !== "z")
                .length > 0 ? (
                <div className="complete-trip-drivers-box-row-cost">
                  <div className="complete-trip-cost-left-side">
                    {Object.keys(approxCostDetails)
                      .filter((item) => item != "z")
                      .filter((item) => item != "id")
                      .filter((item) => item != "cost_type")
                      .filter((item) => item != "modified_on")
                      .filter((item) => item != "created_on")
                      .filter((item) => item != "is_active")
                      .map((item) => {
                        return (
                          <div
                            className="complete-trip-drivers-box-row"
                          // style={{ justifyContent: "space-evenly" }}
                          >
                            <h4 className="complete-trip-drivers-left">
                              {item
                                .split("_")
                                .join(" ")
                                .split("")
                                .map((item, index) => {
                                  if (index == 0) {
                                    return item.toUpperCase();
                                  } else {
                                    return item;
                                  }
                                })
                                .join("")}{" "}
                            </h4>
                            <h4 className="complete-trip-drivers-right">
                              {" "}
                              :
                              {/* <span style={{ fontFamily: "sans-serif" }}> */}{" "}
                              Rs. {/* </span> */}
                              {approxCostDetails[item]
                                ? approxCostDetails[item]
                                : " - "}
                            </h4>
                          </div>
                        );
                      })}
                  </div>
                  <div className="complete-trip-drivers-box-row">
                    <button
                      className="complete-trip-edit-cost-btn"
                      onClick={() => {
                        setCurrentTab("cost");
                        toggleDrawer("right", true);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          cursor: "pointer",
                        }}
                      >
                        <Edit />{" "}
                        <span style={{ fontWeight: "500", margin: "0px 10px" }}>
                          Edit
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="complete-trip-cost-input">
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="complete-trip-input-cost"
                    variant="outlined"
                    onFocus={() => setCurrentTab("cost")}
                    onClick={() => toggleDrawer("right", true)}
                    placeholder="Enter cost details"
                    value={""}
                  // onChange={(e) => {
                  //   setTripDistance(e.target.value);
                  // }}
                  />
                  {/* <div className="complete-trip-error">
                    {Object.keys(approxCostDetails).filter(
                      (item) => item !== "z"
                    ).length > 0
                      ? null
                      : errTripCost}
                  </div> */}
                </div>
              )}
            </div>
            <div className="complete-trip-cost">
              <div className="complete-trip-cost-input">
                <h4 className="complete-trip-head">
                  Trip Complete Meter Reading (with attachment){" "}
                  <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="complete-trip-input-cost"
                  variant="outlined"
                  placeholder="Enter meter reading"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={tripCompleteMeterReading}
                  onChange={(e) => {
                    setTripCompleteMeterReading(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          color: "#016938",
                          fontWeight: "600",
                        }}
                      // onClick={handleAddAttachment}
                      >
                        <button className="upload_button">
                          <input
                            // name="upload"
                            name="attachment_file"
                            id={`upload`}
                            type="file"
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={(e) => {
                              handleChangeTripMeterReadingAttachment(e);
                            }}
                            hidden
                            style={{
                              backgroundColor: "#e8f9e0",
                            }}
                          />
                          <label
                            htmlFor={`upload`}
                            style={{
                              backgroundColor: "#e8f9e0",
                            }}
                          >
                            <img
                              src={UploadImage}
                              alt=""
                              width="20px"
                              height="20px"
                              style={{
                                backgroundColor: "#e8f9e0",
                                // width: "100%",
                                // height: "100%",
                              }}
                            />
                          </label>
                        </button>
                      </InputAdornment>
                    ),
                  }}
                />

                <div className="create-trip-attachment-name">
                  {tripCompleteMeterReadingAttachment.attachment_file != null
                    ? tripCompleteMeterReadingAttachment.attachment_file.name
                    : null}
                </div>
                <div className="complete-trip-error">
                  {tripCompleteMeterReading
                    ? null
                    : errTripCompleteMeterReading}
                </div>
                <div className="complete-trip-error">
                  {tripCompleteMeterReadingAttachment.attachment_file
                    ? null
                    : errTripCompleteMeterReadingAttachment}
                </div>
                <div className="complete-trip-error">
                  {tripCompleteMeterReadingAttachment.attachment_file
                    ? tripCompleteMeterReadingAttachment.attachment_file.size >
                      1000000
                      ? "File size should be less than 10MB"
                      : null
                    : null}
                </div>
              </div>
            </div>
            {selectedTrip.load_source === "manual" ? (
              <div className="complete-trip-cost">
                <div className="complete-trip-cost-input">
                  <h4 className="complete-trip-head">
                    Unloaded Quantity <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="complete-trip-input-cost"
                    variant="outlined"
                    placeholder="Enter unloaded quantity"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={unloadedQuantity}
                    onChange={(e) => {
                      setUnloadedQuantity(e.target.value);
                      setErrCompleteTrip("");
                    }}
                  />
                  <div className="complete-trip-error">
                    {unloadedQuantity > 0 ? null : errUnloadedQuantity}
                  </div>
                </div>
              </div>
            ) : null}

            {/* <div className="complete-trip-row"> */}
            <div className="complete-trip-input-container">
              <h4 className="complete-trip-head">
                {" "}
                Add attachments <sup style={{ color: "red" }}>*</sup>
              </h4>

              {attachmentDetailsList.map((item, index) => {
                return (
                  // <div key={index} className="complete-trip-modal-row">
                  <div key={index} className="complete-trip-modal-input-group">
                    <h4 className="complete-trip-modal-head">
                      Document type {index + 1}
                    </h4>

                    <TextField
                      size="small"
                      id="dropdown"
                      select
                      autoComplete="off"
                      className="complete-trip-input-attachment"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      // disabled={index == 0 ? true : false}
                      value={item.attachment_tag}
                      onChange={(e) => {
                        handleAttachmentChange(e, index);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: "#016938",
                              fontWeight: "600",
                            }}
                          // onClick={handleAddAttachment}
                          >
                            <button className="upload_button">
                              <input
                                // name="upload"
                                name="attachment_file"
                                id={`upload${index}`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={(e) => {
                                  handleAttachmentChange(e, index);
                                  handleAddAttachment(e);
                                }}
                                hidden
                                style={{
                                  backgroundColor: "#e8f9e0",
                                }}
                              />
                              <label
                                htmlFor={`upload${index}`}
                                style={{
                                  backgroundColor: "#e8f9e0",
                                }}
                              >
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                  style={{
                                    backgroundColor: "#e8f9e0",
                                    // width: "100%",
                                    // height: "100%",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    >
                      <MenuItem value="0" disabled>
                        Select document type
                      </MenuItem>

                      {documentTypeList.map((item) => {
                        return (
                          <MenuItem value={item}>
                            {item
                              .split("_")
                              .join(" ")
                              .split("")
                              .map((item, index) => {
                                if (index == 0) {
                                  return item.toUpperCase();
                                } else {
                                  return item;
                                }
                              })
                              .join("")}
                          </MenuItem>
                        );
                      })}
                    </TextField>

                    <div className="complete-trip-attachment-name">
                      {item.attachment_file != null
                        ? item.attachment_file.name
                        : null}
                    </div>
                    <div className="complete-trip-error">
                      {index === 0 && item.attachment_file === null
                        ? errTripAttachment
                        : null}
                    </div>
                    <div className="error-trip">
                      {item.attachment_file
                        ? item.attachment_file.size > 1000000
                          ? "File size is too large.( < 10MB)"
                          : null
                        : null}
                    </div>
                  </div>
                );
              })}
              {/* </div> */}
            </div>
            <div
              className="complete-trip-input-container"
              style={{ width: "70%" }}
            >
              {errCompleteTrip ? (
                <Alert severity="error">{errCompleteTrip}</Alert>
              ) : null}
            </div>

            <div className="complete-trip-row">
              <div className="complete-trip-button-container">
                <button
                  className="complete-trip-add-attachment-submit"
                  onClick={handleSubmitTripComplete}
                  disabled={pendingInvoiceMsgp !== "" ? true : false}
                  style={{
                    color: "white",
                    backgroundColor: `${pendingInvoiceMsgp !== "" ? "#CFDEC8" : "#016938"
                      }`,
                    width: "200px",
                  }}
                >
                  {loadingModal ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    "Submit for Approval"
                  )}
                </button>
              </div>
            </div>
          </div>
          {/*Cost details start*/}
          {currentTab == "cost" ? (
            <>
              <Drawer
                anchor={"right"}
                open={state["right"]}
                onClose={() => toggleDrawer("right", false)}
              >
                <div
                  className="create-trip-drawer-container"
                // style={{ backgroundColor: "red", width: "500px" }}
                >
                  <div
                    className="create-trip-right-most-head"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4>Trip cost :</h4>
                    <Close
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() => toggleDrawer("right", false)}
                    />
                  </div>

                  {costKeys
                    .filter((item) => item != "id")
                    .filter((item) => item != "cost_type")
                    .filter((item) => item != "modified_on")
                    .filter((item) => item != "created_on")
                    .filter((item) => item != "is_active")
                    .map((item, index) => {
                      return (
                        <>
                          <div className="create-trip-from-row">
                            <div className="create-trip-input-container">
                              {" "}
                              <TextField
                                size="small"
                                select
                                autoComplete="off"
                                className="create-trip-input"
                                variant="outlined"
                                placeholder="load source"
                                value={item}
                                onChange={(e) => {
                                  const temp = approxCostDetails;
                                  temp[e.target.value] = temp[item];
                                  delete temp[item];
                                  setApproxCostDetails(temp);
                                  setCostKeys(Object.keys(temp));
                                  setErrCompleteTrip("");
                                }}
                              >
                                <MenuItem value="z" disabled>
                                  Select Cost
                                </MenuItem>
                                {allChargesList.map((item) => {
                                  return (
                                    <MenuItem value={item}>
                                      {item
                                        .split("_")
                                        .join(" ")
                                        .split("")
                                        .map((item, index) => {
                                          if (index == 0) {
                                            return item.toUpperCase();
                                          } else {
                                            return item;
                                          }
                                        })
                                        .join("")}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                              {/* {item == "others_expenses" ? (
                                <div
                                  className="create-trip-input-container"
                                  style={{ marginLeft: "0px" }}
                                >
                                  <TextField
                                    className="create-trip-input"
                                    variant="outlined"
                                    autoComplete="off"
                                    style={
                                      isMobile
                                        ? { width: "95%" }
                                        : { margin: "5px 0px", width: "85%" }
                                    }
                                    value={otherExpenseType}
                                    onChange={(e) =>
                                      setOtherExpenseType(e.target.value)
                                    }
                                    size="small"
                                    placeholder="Enter cost type"
                                  />
                                </div>
                              ) : null} */}
                            </div>
                            <div
                              className="create-trip-input-container"
                              style={{ display: "flex" }}
                            >
                              <TextField
                                size="small"
                                autoComplete="off"
                                className="create-trip-input"
                                variant="outlined"
                                placeholder={`Enter charges`}
                                defaultValue={approxCostDetails[item]}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      style={{ fontFamily: "sans-serif" }}
                                    >
                                      {" "}
                                      ₹{" "}
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  const tempCost = approxCostDetails;

                                  tempCost[item] = e.target.value;

                                  setApproxCostDetails(tempCost);
                                }}
                              />
                              {isMobile ? (
                                <div className="complete-trip-cost-remove">
                                  {" "}
                                  <Close
                                    onClick={() => handleRemoveCost(item)}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              ) : null}
                            </div>
                            {isMobile ? null : (
                              <div className="complete-trip-cost-remove">
                                {" "}
                                <Close
                                  onClick={() => handleRemoveCost(item)}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      );
                    })}

                  <div className="create-trip-add-cost-row">
                    <button
                      className="create-trip-add-cost"
                      onClick={handleAddNewCost}
                    >
                      Add new cost
                    </button>
                  </div>

                  <div className="create-trip-input-container">
                    <button
                      className="create-trip-save-btn"
                      onClick={() => toggleDrawer("right", false)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Drawer>
            </>
          ) : null}
          {/*Cost details end*/}
        </div>
      )}
    </div>
  );
}

export default FaarmsCompleteTripRequest;
