import React from "react";
import CustomerSidebar from "../../../components/logisticsCustomerComponents/CustomerSidebar/CustomerSidebar";

function CustomerDashboard() {
  return (
    <div style={{ display: "flex" }}>
      <CustomerSidebar />
      CustomerDashboard
    </div>
  );
}

export default CustomerDashboard;
