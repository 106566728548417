import React from "react";
import { FooterStyle } from "./footer";

const Footer = () => {
  return (
    <FooterStyle>
      <div className="footer-main">
        <div className="social_media">
          <a href="https://twitter.com/FaarmsIndia" target="_blank">
            <img
              src="/assets/landingPageAssets/SocialMedia.svg"
              alt="social media"
            />
          </a>
          <a href="https://www.instagram.com/faarms_official/" target="_blank">
            <img
              src="/assets/landingPageAssets/SocialMedia-1.svg"
              alt="social media"
            />
          </a>
          <a href="https://www.linkedin.com/company/faarms" target="_blank">
            <img
              src="/assets/landingPageAssets/SocialMedia-2.svg"
              alt="social media"
            />
          </a>
          <a href="https://www.facebook.com/efaarms" target="_blank">
            <img
              src="/assets/landingPageAssets/SocialMedia-3.svg"
              alt="social media"
            />
          </a>
        </div>
        <div className="footer-contact">
          <p>info@faarms.in</p>|<p>+91-8811838383</p>
        </div>
        <div className="address">
          <p>#40, 12th Main Rd, Sector 6, HSR Layout,</p>
          <p> Bengaluru, Karnataka 560102</p>
        </div>
      </div>
      <div className="footer-copyright">
        <p>Copyright © 2022 Leap supply, All Rights Reserved.</p>
      </div>
    </FooterStyle>
  );
};

export default Footer;
