import {
  Alert,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomerSidebar from "../../../components/logisticsCustomerComponents/CustomerSidebar/CustomerSidebar";
import { CreateIndentStyle } from "./createIndentStyle";
import UploadImage from "../../../assets/upload.svg";
import { useDispatch } from "react-redux";
import { Box, Drawer } from "@mui/material";
import {
  Close,
  AddCircle,
  Add,
  Cancel,
  Search,
  ArrowBack,
  ArrowBackIos,
} from "@mui/icons-material";
import {
  changeIndentPut,
  createIndentPost,
  fetchAddressFromPincodeApi,
  fetchIndentAttchmentsId,
  fetchIndentById,
  indentAttachmentsPost,
  indentAttachmentsPut,
} from "./createIndentServices";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  readCustomerData,
  readLoginData,
} from "../../../helper/dataDecryptionHelper";
import { markIndentPatch } from "../customerIndentsPage/customerIndentServices";
import { updateOnFlag, updateOnFlagUpdate } from "../../../redux/indentSlice";
import { Modal as ModalAntd } from "antd";
import SomethingWentWrong from "../../../components/somethingWentWrong/SomethingWentWrong";
// import { useSelector, useDispatch } from "react-redux";

function CreateIndentPage() {
  const dispatch = useDispatch();
  const customerInfo = readCustomerData();

  const loginCustomer = readLoginData();
  const indentData = useSelector((state) => state.indent.indentId);

  const history = useHistory();
  const [indentAttachment, setIndentAttachment] = useState("");

  const [currentTab, setCurrentTab] = useState(null);
  const [sourceError, setSourceError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [indentImageId, setIndentImageId] = useState("");
  const [successIndent, setSuccessIndent] = useState("");
  const [flagOn, setFlagOn] = useState(false);

  const [indentPickupDate, setIndentPickupDate] = useState("");
  const [indentVehicleType, setIndentVehicleType] = useState("");
  const [indentQuantity, setIndentQuantity] = useState("");
  const [indentValue, setIndentValue] = useState("");
  const [indentMaterialType, setIndentMaterialType] = useState("");
  const [villageList, setVillageList] = useState([]);
  const [vehicleTypeList, setVehicleTypeList] = useState([
    "1.5_ton_pickup",
    "4_ton_mini_truck",
    "5_ton_lpt",
    "7_ton_lpt",
    "10_ton_lpt",
    "32_feet_open_trailer",
    "40_feet_open_trailer",
    "18_ton_taurus_truck",
    "21_ton_taurus_truck",
    "25_ton_taurus_truck",
    "30_ton_taurus_truck",
    "20_feet_sxl_container_truck",
    "20_feet_mxl_container_truck",
    "32_feet_sxl_container_truck",
    "32_feet_mxl_container_truck",
  ]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const [openSnackbarSuccessUpdate, setOpenSnackbarSuccessUpdate] =
    useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [indentSource, setIndentSource] = useState([
    {
      postcode: "",
      type: "source",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    },
  ]);

  // console.log("the attachmentsssss", indentAttachment?.type.split("/").shift());

  const [indentDestination, setIndentDestination] = useState([
    {
      postcode: "",
      type: "destination",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    },
  ]);
  const [currentDestinationId, setCurrentDestinationId] = useState(null);
  const [currentSourceId, setCurrentSourceId] = useState(null);

  const [state, setState] = React.useState({
    right: false,
  });

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "left",
  });

  const { vertical, horizontal } = snackbarPosition;
  const [errorindentVillage, setErrorindentVillage] = useState("");
  // const [errorindentVillage, setErrorindentVillage] = useState("");
  const handleCloseDriverDrawer = () => {
    if (indentSource[0].village !== "0") {
      toggleDrawer("right", false);
    } else {
      setErrorindentVillage("please select village");
    }
  };
  const handleCloseDriverDrawerDesination = () => {
    if (indentDestination[0].village !== "0") {
      toggleDrawer("right", false);
    } else {
      setErrorindentVillage("please select village");
    }
  };

  const handleIndentAttachment = (event) => {
    setErrorAttachments("");

    setIndentAttachment(event.target.files[0]);
  };

  const handleOnChangeDestination = (e, index) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    const destination = [...indentDestination];
    //
    destination[index][name] = value;
    //
    setIndentDestination(destination);
    // setErrDestinationFinal("");
  };

  const handleGetAddress = async (pincode) => {
    const response = await fetchAddressFromPincodeApi(pincode);
    //

    if (response) {
      if (currentTab === "source") {
        if (response.status == 400) {
          setSourceError(response.data);
        }
        handleSetAddress(
          currentTab,
          currentSourceId - 1,
          response?.data,
          indentSource
        );
      } else if (currentTab === "destination") {
        if (response.status == 400) {
          setSourceError(response.data);
        }
        handleSetAddress(
          currentTab,
          currentDestinationId - 1,
          response?.data,
          indentDestination
        );
      }
    }
  };

  const handleGoBack = () => {
    history.push("/customer/customer-indents");
    setErrorSource([{}]);
    setErrorPickupdate("");
    setErrorVehicleType("");
    setErrorqty("");
    setErrorValueOfGoods("");
    setErrorMaterialType("");
    setErrorAttachments("");
    setErrorDestination([{}]);
  };

  const handleSetAddress = (type, index, addressData, existingData) => {
    const addressTemp = [...existingData];

    addressTemp[index]["district"] = addressData.district;
    addressTemp[index]["sub_district"] = addressData.district;
    addressTemp[index]["state"] = addressData.state;
    // addressTemp[index]["state"] = addressData.state;setVillageList
    setVillageList(addressData.area);

    if (type === "source") {
      setIndentSource(addressTemp);
    } else if (type === "destination") {
      setIndentDestination(addressTemp);
    }
  };

  const handleOnChangeSource = (e, index) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    const source = [...indentSource];
    source[index][name] = value;
    setIndentSource(source);
  };

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
    setErrorindentVillage("");
    setSourceError("");
    // if (currentTab == "driver" && open == false) {
    //   resetFormDriver();
    // }
  };

  const handleAddSource = () => {
    let sourceTemp = [...indentSource];
    sourceTemp.push({
      postcode: "",
      type: "source",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    });
    setIndentSource(sourceTemp);
  };

  const handleAddDestination = () => {
    let destinationTemp = [...indentDestination];
    destinationTemp.push({
      postcode: "",
      type: "destination",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    });
    setIndentDestination(destinationTemp);
  };

  const handleCreateIndent = async (
    indentSource,
    indentDestination,
    indentPickupDate,
    indentVehicleType,
    indentQuantity,
    indentValue,
    indentMaterialType,
    indentAttachment,
    attachmentName,
    attachmentType,
    // indentAttachment,
    customerId,
    // createdby,
    // modifiedby,
    indentstatus
  ) => {
    if (indentstatus == "draft") {
      setLoading(true);
    } else {
      setLoadingSend(true);
    }
    //
    const response = await createIndentPost(
      indentSource,
      indentDestination,
      indentPickupDate,
      indentVehicleType,
      indentQuantity,
      indentValue,
      indentMaterialType,
      indentAttachment,
      attachmentName,
      attachmentType,
      customerId,
      // createdby,
      // modifiedby,
      indentstatus
    );
    //
    if (response.status == 200) {
      if (indentstatus == "draft") {
        setLoading(false);
      } else {
        setLoadingSend(false);
      }
      // setSuccessIndent("Indent created successfully.");
      setOpenSnackbarSuccess(true);
      dispatch(updateOnFlag(true));
      // setFlagOn(true);
      history.push("/customer/customer-indents");
    } else {
      if (indentstatus == "draft") {
        setLoading(false);
      } else {
        setLoadingSend(false);
      }

      if (response.data.detail) {
        setErrMessage(`${response.data.detail}`);
      }
      if (response.data.error) {
        setErrMessage(`${response.status} ${response.data.error}`);
      }
      setOpenSnackbar(true);
    }
  };

  const handleSubmitIndent = async (indentStatus) => {
    if (indentData.editMode == true) {
      setLoading(true);
      const response = await changeIndentPut(
        indentData.id,
        JSON.stringify(indentSource),
        JSON.stringify(indentDestination),
        indentPickupDate,
        indentVehicleType,
        indentQuantity,
        indentValue,
        indentMaterialType,
        // indentAttachment ? indentAttachment : "",
        // indentAttachment.name ? indentAttachment.name : "",
        customerInfo.customer_id,
        "draft"
        // customerInfo.customer_email,
        // customerInfo.customer_email,

        // loginData.user_id
      );

      if (response.status == 200) {
        setLoading(false);
        // if (indentAttachment.name !== "") {
        //   const respnseAttachments = await indentAttachmentsPost(
        //     "image",
        //     indentAttachment ? indentAttachment : "",
        //     indentAttachment ? indentAttachment.name : "",
        //     indentData.id
        //   );
        //
        //   if (respnseAttachments.status == 200) {
        //     setLoading(false);
        //     history.push("/customer/customer-indents");
        //   } else if (respnseAttachments.status === 400) {
        //     const response = await indentAttachmentsPut(
        //       "image",
        //       indentAttachment ? indentAttachment : "",
        //       indentAttachment ? indentAttachment.name : "",
        //       indentData.id,
        //       indentImageId
        //     );
        //     if (response.status == 200) {
        //       setLoading(false);
        //       history.push("/customer/customer-indents");
        //     }

        //   }
        // }
        setOpenSnackbarSuccessUpdate(true);
        dispatch(updateOnFlagUpdate(true));
        history.push("/customer/customer-indents");
      }
    } else {
      const isValid = formValidation();

      if (isValid) {
        await handleCreateIndent(
          JSON.stringify(indentSource),
          JSON.stringify(indentDestination),
          indentPickupDate,
          indentVehicleType,
          indentQuantity,
          indentValue,
          indentMaterialType,
          indentAttachment ? indentAttachment : "",
          indentAttachment ? indentAttachment.name : "",
          indentAttachment.type == "image/png" ||
            indentAttachment.type == "image/jpeg"
            ? "image"
            : indentAttachment.type == "application/pdf"
              ? "pdf"
              : "",
          customerInfo.customer_id,

          // customerInfo.customer_email,
          // customerInfo.customer_email,
          indentStatus
          // JSON.stringify(loginCustomer.customer_id)
        );
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleCloseSnackbarSuccess = () => {
    setOpenSnackbarSuccess(false);
  };
  const editIndent = async (id) => {
    const response = await fetchIndentById(id);

    if (response) {
      setIndentSource(response.source);
      setIndentSource(response.source);
      setIndentDestination(response.destination);
      setIndentPickupDate(response.pickup_date);
      setIndentVehicleType(response.vehicle_type);
      setIndentQuantity(response.quantity_of_goods);
      setIndentValue(response.value_of_goods);
      setIndentMaterialType(response.material_type);
    }
  };
  useEffect(() => {
    if (indentData.id) {
      editIndent(indentData.id);
    }
  }, []);
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  // ==============================\

  //   const [indentMaterialType, setIndentMaterialType] = useState("");
  //   const [villageList, setVillageList] = useState([]);
  //   const [vehicleTypeList, setVehicleTypeList] = useState([
  //     "cargo_auto",
  //     "truck",
  //     "pickup",
  //   ]);
  // ==============================
  const [errorSource, setErrorSource] = useState("");
  const [errorDestination, setErrorDestination] = useState("");
  const [errorPickupdate, setErrorPickupdate] = useState("");
  const [errorVehicleType, setErrorVehicleType] = useState("");

  const [errorqty, setErrorqty] = useState("");
  const [errorValueOfGoods, setErrorValueOfGoods] = useState("");
  const [errorMaterialType, setErrorMaterialType] = useState("");
  const [errorAttachments, setErrorAttachments] = useState("");

  const formValidation = () => {
    const errorSource = {};

    const errorDestination = {};
    const errorPickupdate = {};
    const errorVehicleType = {};
    const errorqty = {};
    const errorValueOfGoods = {};
    const errorMaterialType = {};
    const errorindentVillage = {};
    // const errorAttachments = {};
    let isValid = true;
    // if (indentAttachment == null) {
    //   errorAttachments.errorImage = "Attachment is manadatory.";
    //   isValid = false;
    // }
    if (Object.keys(indentSource[0].postcode).length == 0) {
      errorSource.errorSources = "Please add source.";
      isValid = false;
    }
    if (Object.keys(indentDestination[0].postcode).length == 0) {
      errorDestination.errorDest = "Please add Destination.";
      isValid = false;
    }
    if (indentPickupDate == "") {
      errorPickupdate.errorPick = "Please add pickup date.";
      isValid = false;
    }
    if (indentVehicleType == "") {
      errorVehicleType.errorVehicles = "Please add vehicle type.";
      isValid = false;
    }
    if (indentValue == "") {
      errorValueOfGoods.errorValue = "Please add invoice value.";
      isValid = false;
    }
    if (indentQuantity == "") {
      errorqty.errorQuantity = "Please add valid quantity.";
      isValid = false;
    }
    if (indentMaterialType == "") {
      errorMaterialType.errorMaterial = "Please add material type.";
      isValid = false;
    }
    // if (indentSource.village == "0") {
    //   errorindentVillage.errorvillage = "Please select village.";
    //   isValid = false;
    // }
    setErrorSource(errorSource);
    setErrorPickupdate(errorPickupdate);
    setErrorVehicleType(errorVehicleType);
    setErrorqty(errorqty);
    setErrorValueOfGoods(errorValueOfGoods);
    setErrorMaterialType(errorMaterialType);
    // setErrorindentVillage(errorindentVillage);
    // setErrorAttachments(errorAttachments);
    setErrorDestination(errorDestination);
    // const [errorDestination, setErrorDestination] = useState("");

    return isValid;
  };

  const handleMarkIndentAsSent = async (id, markSentRemark) => {
    // setLoadingForMarkAsSent(true);
    const response = await markIndentPatch(id, markSentRemark);
    if (response.status >= 200 && response.status < 300) {
      // getIndentsList(currentTab, currentPage);
    } else {
    }
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    //
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <CreateIndentStyle>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={4000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        key={vertical + horizontal}
      >
        <Alert severity="error">{errMessage}</Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbarSuccess}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseSnackbarSuccess}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseSnackbarSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Indent created successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbarSuccess}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseSnackbarSuccess}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseSnackbarSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Indent Updated successfully!
        </Alert>
      </Snackbar>
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={99999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong
            backButtonLink={`/create-indent`}
            buttonText="Close"
          />
        </div>
      </ModalAntd>
      <div className="create-indent-main-container">
        {isMobile ? null : (
          <div>
            <CustomerSidebar />
          </div>
        )}

        <div className="create-indent-container">
          <div className="create-indent-top">
            <div className="create-indent-text">
              <ArrowBackIos
                style={{ cursor: "pointer" }}
                onClick={handleGoBack}
              />{" "}
              {indentData.editMode == true ? "Edit Indent" : "Create Indent"}
            </div>
          </div>
          <div className="create-indent-form">
            <div className="create-indent-row-grid">
              {indentSource.map((item, index) => {
                return (
                  <div className="create-indent-input-contain">
                    <h4 className="create-indent-label">
                      Source {index + 1}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-indent-input"
                      variant="outlined"
                      placeholder="Enter source address"
                      onClick={() => {
                        setCurrentTab("source");
                        setCurrentSourceId(index + 1);
                        toggleDrawer("right", true);
                      }}
                      disabled={indentData.id ? true : false}
                      value={
                        item.state &&
                          item.village &&
                          item.sub_district &&
                          item.district
                          ? `${item.village}, ${item.sub_district}, ${item.district}, ${item.state}`
                          : ""
                      }
                    />
                    {indentSource.length === index + 1 ? (
                      indentData.id ? null : (
                        <div>
                          <span
                            className="indent-add-source"
                            onClick={handleAddSource}
                          >
                            <Add style={{ fontSize: "20px" }} /> Source
                          </span>
                        </div>
                      )
                    ) : null}
                  </div>
                );
              })}

              {indentDestination.map((item, index) => {
                return (
                  <div className="create-indent-input-contain">
                    <h4 className="create-indent-label">
                      Destination {index + 1}{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-indent-input"
                      variant="outlined"
                      placeholder="Enter destination address"
                      onClick={() => {
                        setCurrentTab("destination");
                        setCurrentDestinationId(index + 1);
                        toggleDrawer("right", true);
                      }}
                      disabled={indentData.id ? true : false}
                      value={
                        item.state &&
                          item.village &&
                          item.sub_district &&
                          item.district
                          ? `${item.village}, ${item.sub_district}, ${item.district}, ${item.state}`
                          : ""
                      }
                    />
                    {indentDestination.length === index + 1 ? (
                      indentData.id ? null : (
                        <div>
                          <span
                            className="indent-add-source"
                            onClick={handleAddDestination}
                          >
                            <Add style={{ fontSize: "20px" }} /> Destination
                          </span>
                        </div>
                      )
                    ) : null}
                  </div>
                );
              })}
              {Object.keys(errorSource).map((key) => {
                return <h6 style={{ color: "#E06666" }}>{errorSource[key]}</h6>;
              })}
              {Object.keys(errorDestination).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorDestination[key]}</h6>
                );
              })}
            </div>
            <div className="create-indent-row">
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Pickup date<sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-indent-input"
                  variant="outlined"
                  placeholder="Enter pickup dtae"
                  // type="date"
                  type="date"
                  inputProps={{
                    min: disablePastDate(),
                    max: "2100-01-01",
                  }}
                  value={indentPickupDate}
                  onChange={(e) => {
                    setIndentPickupDate(e.target.value);
                    setErrorPickupdate({});
                  }}
                />
                {Object.keys(errorPickupdate).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>{errorPickupdate[key]}</h6>
                  );
                })}
              </div>
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Vehicle type<sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  className="create-indent-input"
                  id="outlined-select-currency"
                  select
                  size="small"
                  name="village"
                  //   value={item.village}
                  //   onChange={(e) =>
                  //     handleOnChangeDestination(e, currentDestinationId - 1)
                  //   }
                  // label="Select"
                  value={indentVehicleType}
                  onChange={(e) => {
                    setIndentVehicleType(e.target.value);

                    setErrorVehicleType("");
                  }}
                >
                  <MenuItem value="0" disabled>
                    Select Vehicle type
                  </MenuItem>
                  {vehicleTypeList.map((village, index) => {
                    return (
                      <MenuItem key={index} value={village}>
                        {village.split("_").join(" ").toUpperCase()}
                      </MenuItem>
                    );
                  })}
                </TextField>
                {Object.keys(errorVehicleType).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>
                      {errorVehicleType[key]}
                    </h6>
                  );
                })}
              </div>
            </div>
            <div className="create-indent-row">
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Quantity of goods <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-indent-input"
                  variant="outlined"
                  placeholder="Enter qty of goods"
                  type="number"
                  value={indentQuantity}
                  onChange={(e) => {
                    setIndentQuantity(e.target.value);
                    setErrorqty("");
                  }}
                  onInput={(e) => {
                    e.target.value = parseInt(
                      e.target.value === "0" ? "1" : e.target.value
                    );
                  }}
                />
                {Object.keys(errorqty).map((key) => {
                  return <h6 style={{ color: "#E06666" }}>{errorqty[key]}</h6>;
                })}
              </div>
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Invoice value<sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-indent-input"
                  variant="outlined"
                  placeholder="Enter invoice value"
                  type="number"
                  value={indentValue}
                  onChange={(e) => {
                    setIndentValue(e.target.value);
                    setErrorValueOfGoods("");
                  }}
                  onInput={(e) => {
                    e.target.value = parseInt(
                      e.target.value === "0" ? "1" : e.target.value
                    );
                  }}
                />
                {Object.keys(errorValueOfGoods).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>
                      {errorValueOfGoods[key]}
                    </h6>
                  );
                })}
              </div>
            </div>
            <div className="create-indent-row">
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">
                  Type of material <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-indent-input"
                  variant="outlined"
                  placeholder="Enter type of material"
                  value={indentMaterialType}
                  onChange={(e) => {
                    setIndentMaterialType(e.target.value);
                    setErrorMaterialType("");
                  }}
                />
                {Object.keys(errorMaterialType).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>
                      {errorMaterialType[key]}
                    </h6>
                  );
                })}
              </div>
              <div className="create-indent-input-contain">
                <h4 className="create-indent-label">Add attachment</h4>
                <TextField
                  size="small"
                  id="dropdown"
                  autoComplete="off"
                  className="create-indent-input"
                  variant="outlined"
                  placeholder="Enter document type"
                  name="attachment_tag"
                  disabled
                  // disabled={index == 0 ? true : false}
                  value={"Upload Indent attachment"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          color: "#016938",
                          fontWeight: "600",
                        }}
                      // onClick={handleAddAttachment}
                      >
                        <button className="upload_button">
                          <input
                            // name="upload"
                            name="attachment_file"
                            id={`uploaddl`}
                            type="file"
                            disabled={indentData.editMode ? true : false}
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={handleIndentAttachment}
                            hidden
                            style={{
                              backgroundColor: "#e8f9e0",
                            }}
                          />
                          <label
                            htmlFor={`uploaddl`}
                            style={{
                              backgroundColor: "#e8f9e0",
                            }}
                          >
                            <img
                              src={UploadImage}
                              alt=""
                              width="20px"
                              height="20px"
                              style={{
                                backgroundColor: "#e8f9e0",
                              }}
                            />
                          </label>
                        </button>
                      </InputAdornment>
                    ),
                  }}
                />
                <div
                  style={{
                    fontSize: "12px",
                    width: "260px",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {indentAttachment ? indentAttachment?.name : null}
                </div>
                {indentAttachment ? (
                  indentAttachment.size >
                    process.env.REACT_APP_MAX_FILE_SIZE ? (
                    <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                      Please upload file size less than {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
                    </h6>
                  ) : null
                ) : null}
                {/* {Object.keys(errorAttachments).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>
                      {errorAttachments[key]}
                    </h6>
                  );
                })} */}
              </div>
            </div>
            <div className="create-indent-row-btn">
              <div className="indent-btn-container">
                <button
                  className="create-indent-save-button"
                  onClick={() => handleSubmitIndent("draft")}
                  disabled={
                    indentAttachment.size >
                      process.env.REACT_APP_MAX_FILE_SIZE || loading == true
                      ? true
                      : false
                  }
                >
                  {loading ? (
                    <CircularProgress size={20} style={{ color: "green" }} />
                  ) : (
                    "Save Indent"
                  )}
                </button>
              </div>
              {indentData.editMode == true ? null : (
                <div className="indent-btn-container">
                  <button
                    className="create-indent-send-button"
                    onClick={() => handleSubmitIndent("sent")}
                    disabled={
                      indentAttachment.size >
                        process.env.REACT_APP_MAX_FILE_SIZE || loading == true
                        ? true
                        : false
                          ? true
                          : false
                    }
                  >
                    {loadingSend ? (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    ) : (
                      "Send Indent"
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {currentTab == `source`
          ? indentSource
            .filter((items, indexs) => indexs == currentSourceId - 1)
            .map((item, index) => {
              return (
                <Drawer
                  anchor={"right"}
                  open={state["right"]}
                  onClose={() => toggleDrawer("right", false)}
                >
                  <div className="create-trip-drawer-container">
                    <div
                      className="create-trip-right-most-head"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Add Trip source - {currentSourceId}
                      <Close
                        color="action"
                        onClick={handleCloseDriverDrawer}
                        style={{ marginRight: "10px" }}
                      />
                    </div>

                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Pincode <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          name="postcode"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="Enter Pincode"
                          value={item.postcode}
                          onChange={(e) => {
                            setSourceError("");
                            setErrorSource([]);

                            if (e.target.value.length <= 6) {
                              // setErrPincodeNotServiceable("");
                              handleOnChangeSource(e, currentSourceId - 1);
                              if (e.target.value.length == 6) {
                                handleGetAddress(
                                  e.target.value,
                                  currentSourceId - 1
                                );
                                // handleGetAddressDestination(
                                //   e.target.value,
                                //   currentDestinationId - 1
                                // );
                              }
                            }
                          }}
                        />
                        {sourceError ? (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {sourceError}
                          </p>
                        ) : null}
                        {/* <div className="create-trip-error">
                            {errPincodeNotServiceable
                              ? errPincodeNotServiceable
                              : null}
                            {indentDestination[currentDestinationId - 1]
                              .postcode.length != 6
                              ? errDestinationAddress.errPostcode
                              : null}
                          </div> */}
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Village <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          className="create-trip-input"
                          id="outlined-select-currency"
                          select
                          size="small"
                          name="village"
                          value={item.village}
                          onChange={(e) => {
                            handleOnChangeSource(e, currentSourceId - 1);
                            setErrorindentVillage("");
                          }}
                        // label="Select"
                        // value={currency}
                        // onChange={handleChange}
                        >
                          <MenuItem value="0" disabled>
                            Select Village
                          </MenuItem>
                          {villageList.map((village, index) => {
                            return (
                              <MenuItem key={index} value={village}>
                                {village}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <h6 style={{ color: "red" }}>
                          {" "}
                          {errorindentVillage ? errorindentVillage : ""}
                        </h6>

                        {/* <div className="create-trip-error">
                            {destinationDetails[currentDestinationId - 1]
                              .village == "0"
                              ? errDestinationAddress.errVillage
                              : null}
                          </div> */}
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Sub District <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="sub district"
                          value={item.sub_district}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          District <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="District"
                          value={item.district}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          State <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="state"
                          value={item.state}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head"> Landmark</h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          name="landmark"
                          placeholder="Enter the landmark"
                          value={item.landmark}
                          onChange={(e) =>
                            handleOnChangeSource(e, currentSourceId - 1)
                          }
                        />
                      </div>
                    </div>

                    <div className="create-trip-input-container">
                      <button
                        className="create-trip-save-btn"
                        onClick={() => handleCloseDriverDrawer()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Drawer>
              );
            })
          : null}
        {currentTab == `destination`
          ? indentDestination
            .filter((items, indexs) => indexs == currentDestinationId - 1)
            .map((item, index) => {
              //
              return (
                <Drawer
                  anchor={"right"}
                  open={state["right"]}
                  onClose={() => toggleDrawer("right", false)}
                >
                  <div className="create-trip-drawer-container">
                    <div
                      className="create-trip-right-most-head"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      Add Trip destination - {currentDestinationId}
                      <Close
                        color="action"
                        onClick={handleCloseDriverDrawerDesination}
                        style={{ marginRight: "10px" }}
                      />
                    </div>

                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Pincode <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          name="postcode"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="Enter Pincode"
                          value={item.postcode}
                          onChange={(e) => {
                            setSourceError("");
                            setErrorDestination([]);
                            if (e.target.value.length <= 6) {
                              // setErrPincodeNotServiceable("");

                              handleOnChangeDestination(
                                e,
                                currentDestinationId - 1
                              );
                              if (e.target.value.length == 6) {
                                handleGetAddress(
                                  e.target.value,
                                  currentDestinationId - 1
                                );

                                // handleGetAddressDestination(
                                //   e.target.value,
                                //   currentDestinationId - 1
                                // );
                              }
                            }
                          }}
                        />
                        {sourceError ? (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {sourceError}
                          </p>
                        ) : null}
                        {/* <div className="create-trip-error">
                            {errPincodeNotServiceable
                              ? errPincodeNotServiceable
                              : null}
                            {indentDestination[currentDestinationId - 1]
                              .postcode.length != 6
                              ? errDestinationAddress.errPostcode
                              : null}
                          </div> */}
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Village <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          className="create-trip-input"
                          id="outlined-select-currency"
                          select
                          size="small"
                          name="village"
                          value={item.village}
                          onChange={(e) => {
                            handleOnChangeDestination(
                              e,
                              currentDestinationId - 1
                            );
                            setErrorindentVillage("");
                          }}
                        // label="Select"
                        // value={currency}
                        // onChange={handleChange}
                        >
                          <MenuItem value="0" disabled>
                            Select Village
                          </MenuItem>
                          {villageList.map((village, index) => {
                            return (
                              <MenuItem key={index} value={village}>
                                {village}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        <h6 style={{ color: "red" }}>
                          {" "}
                          {errorindentVillage ? errorindentVillage : ""}
                        </h6>
                        {/* <div className="create-trip-error">
                            {destinationDetails[currentDestinationId - 1]
                              .village == "0"
                              ? errDestinationAddress.errVillage
                              : null}
                          </div> */}
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          Sub District <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="sub district"
                          value={item.sub_district}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          District <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="District"
                          value={item.district}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head">
                          {" "}
                          State <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="state"
                          value={item.state}
                          disabled
                        />
                      </div>
                    </div>

                    <div className="create-trip-from-row">
                      <div className="create-trip-input-container">
                        <h4 className="create-trip-input-head"> Landmark</h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          name="landmark"
                          placeholder="Enter the landmark"
                          value={item.landmark}
                          onChange={(e) =>
                            handleOnChangeDestination(
                              e,
                              currentDestinationId - 1
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="create-trip-input-container">
                      <button
                        className="create-trip-save-btn"
                        onClick={() => handleCloseDriverDrawerDesination()}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Drawer>
              );
            })
          : null}
      </div>
    </CreateIndentStyle>
  );
}

export default CreateIndentPage;
