import React, { useContext } from "react";
import { FtlTripListCardStyle } from "./FtlTripListV3CardStyle";
import CopyText from "../copyText/CopyText";
import { DarkModeContext } from "../../context/darkModeContext";
import moment from "moment";
import { colorPalette } from "../../themes/theme";
import { PlayArrow } from "@mui/icons-material";

const FtlTripListV3Card = ({
  cardData,
  myRef,
  selectedTrip,
  toggleDrawerMenu,
}) => {
  const { toggle, darkMode } = useContext(DarkModeContext);
  return (
    <FtlTripListCardStyle
      mode={darkMode}
      style={
        selectedTrip.id === cardData.id ? { border: "1px solid #4e4e4e" } : {}
      }
    >
      <div className="trip_code" ref={myRef}>
        <CopyText
          text={cardData.trip_code}
          fontFamily={"JetBrains Mono, monospace"}
        />
      </div>
      <div className="vehilce_number_and_trip_status">
        <div className="vehilce_number">
          {<CopyText text={cardData.vehicle_number} />}
        </div>
        <div
          className="status"
          style={
            cardData.trip_status === "cancelled"
              ? { background: colorPalette.red }
              : {}
          }
        >
          {cardData.trip_status.split("_").join(" ").toUpperCase()}
        </div>
      </div>

      {[
        {
          tilte: "Bill To",
          description: cardData.bill_to_party_details.name,
        },
        { tilte: "Consignor", description: cardData.consignor },
        { tilte: "Consignee", description: cardData.consignee },
      ].map((item) => (
        <div className="trip_detail">
          <div className="title">{item.tilte}</div>
          <div className="seperator"></div>
          <div className="description">{item.description}</div>
        </div>
      ))}

      <div className="amount_section">
        <div className="amount_wrapper">
          <div>
            <div className="amount_type">Billed Amount:</div>
            <div className="amount">
              {cardData?.trip_customer_invoice?.length > 0
                ? `Rs. ${cardData.trip_customer_invoice[0]?.customer_billed_amount}`
                : ""}
            </div>
          </div>
          <div>
            <div className="amount_type">Transport advance:</div>
            <div className="amount">
              {cardData?.trip_cost?.length > 0
                ? cardData?.trip_cost.filter(
                    (item) => item.cost_type === "advance"
                  ).length > 0
                  ? `Rs. ${
                      cardData?.trip_cost.filter(
                        (item) => item.cost_type === "advance"
                      )[0].cost_amount
                    }`
                  : "Rs. 0"
                : "Rs. 0"}
            </div>
          </div>
          <div>
            <div className="amount_type">Customer Advance:</div>
            <div className="amount">
              {cardData?.trip_customer_invoice?.length > 0
                ? `Rs. ${cardData.trip_customer_invoice[0]?.advance_paid_by_customer}`
                : "Rs. 0"}
            </div>
          </div>
          <div>
            <div className="amount_type">Transport cost:</div>
            <div className="amount">
              {cardData?.trip_cost?.length > 0
                ? cardData?.trip_cost.filter(
                    (item) => item.cost_type === "trip_cost"
                  ).length > 0
                  ? `Rs. ${
                      cardData?.trip_cost.filter(
                        (item) => item.cost_type === "trip_cost"
                      )[0].cost_amount
                    }`
                  : "Rs. 0"
                : "Rs. 0"}
            </div>
          </div>
        </div>
      </div>
      <div className="card_sub_details_section">
        <div>
          <div className="sub_detail">
            <div className="sub_detail_head">Payment Terms:</div>
            <div>
              {cardData.payment_terms
                ? cardData.payment_terms.includes("to_pay")
                  ? "TO PAY"
                  : cardData.payment_terms.split("_").join(" ").toUpperCase()
                : " - "}
            </div>
          </div>
          <div className="sub_detail">
            <div className="sub_detail_head">Created Date:</div>
            <div>
              {moment
                .utc(cardData.created_on)
                .local()
                .format("DD-MM-YYYY hh:mm A")}
            </div>
          </div>
        </div>
        <div>
          <div onClick={() => toggleDrawerMenu()}>
            <PlayArrow style={{ fontSize: "40px", cursor: "pointer" }} />
          </div>
        </div>
      </div>
    </FtlTripListCardStyle>
  );
};

export default FtlTripListV3Card;
