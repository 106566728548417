import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../../themes/theme";

export const TransportStyle = styled.div`
  width: 100%;
  padding: 40px 100px;

  .transport-heading {
    font-weight: ${FontWeight.bold};
    font-size: 30px;
    line-height: 54px;
  }

  .tracking-transport {
    width: 100%;
    height: 100%;
  }

  .tracking-transport img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }

  .transport_company_mobile {
    display: none;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
    padding: 40px 20px;

    .tracking-transport {
      display: none;
    }

    .transport_company_mobile {
      display: block;
    }
  }

  @media only screen and (max-width: 900px) {
    .tracking-transport {
      display: none;
    }

    .transport_company_mobile {
      display: block;
    }

    .transport_cards {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 20px;
    }

    .transport_card {
      width: 80%;
      padding: 20px;
      box-shadow: ${colorPalette.boxShadowPrimary};
      border-left: 5px solid ${colorPalette.primaryColor};
      margin-top: 20px;
      position: relative;
    }

    .transport_card::before {
      position: absolute;
      content: "✔";
      top: 50%;
      left: -22%;
      width: 50px;
      height: 50px;
      background-color: ${colorPalette.primaryColor};
      transform: translateY(-50%);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }

    .transport_card_heading {
      font-weight: ${FontWeight.semibold};
      font-size: ${typography.heading5};
    }

    .transport_card_text {
      font-weight: ${FontWeight.medium};
      font-size: ${typography.paragraph};
      color: ${colorPalette.paragraphColor};
    }
  }
`;
