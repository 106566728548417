import React from "react";
import CustomerSidebar from "../../../components/logisticsCustomerComponents/CustomerSidebar/CustomerSidebar";

function CustomerTripsPage() {
  return (
    <div style={{ display: "flex" }}>
      <CustomerSidebar />
      Customer Trips
    </div>
  );
}

export default CustomerTripsPage;
