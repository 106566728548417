import axiosInterceptor from "../config/https";
import { convertToBase64, prefixSalt } from "../helper/base64Converter";
import apiUrl from "../url/apiUrl";

export const showOrDownlaodAttachmentsUtility = async (
  id,
  path,
  downloadOnly
) => {
  try {
    const response = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/${path}/${prefixSalt()}-${convertToBase64(id)}`,
      {
        responseType: "blob",
      }
    );

    if (response.status === 403) {
      localStorage.clear();
      window.location.reload();
    } else {
      var file = window.URL.createObjectURL(response.data);
      if (downloadOnly === "downloadOnly") {
        const link = window.document.createElement("a");
        link.href = file;
        link.download =
          response.headers["content-type"] === "application/pdf"
            ? "document.pdf"
            : response.headers["content-type"] === "image/png"
            ? "image.png"
            : "image.jpeg";
        link.click();
      }

      return { file, attachmentType: response.headers["content-type"] };
    }
  } catch (error) {
    return error.response;
    //
  }
};
