import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Alert, Snackbar } from "@mui/material";
import { argon2id, argon2Verify } from "hash-wasm";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import apiUrl from "../../url/apiUrl";
import IconButton from "@mui/material/IconButton";

import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Button from "@mui/material/Button";
import PasswordIcon from "@mui/icons-material/Password";
import {
  changeUserPassword,
  forgetPassword,
  getCustomersInfo,
  LoginPost,
} from "../login/LoginServices";
import Input from "@mui/material/Input";
import { CircularProgress } from "@mui/material";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Link, useHistory } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MailIcon from "@mui/icons-material/Mail";
import apiUrl from "../../url/apiUrl";
import logo from "../../assets/logo/leap_supply_logo.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { readLoginData, readToken } from "../../helper/dataDecryptionHelper";
import { Login_container_changePassword } from "./changePasswordStyle";
import { Change_password_form } from "./changepassword";
import { ButtonRectangle } from "../../themes/components/button";
import { useContext } from "react";
import { DarkModeContext } from "../../context/darkModeContext";

const CryptoJS = require("crypto-js");

function ChangePasswords() {
  const { toggle, darkMode } = useContext(DarkModeContext);
  let history = useHistory();
  const loginApp =
    readLoginData() !== undefined ? readLoginData().app_name : "Leap-Supply";
  const is_temporary_password =
    readLoginData() !== undefined && readLoginData().temporary_password
      ? readLoginData().temporary_password
      : null;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changedPassword, setChangedPassword] = useState("");

  const [misMatchedPassword, setMisMatchedPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState();
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgForOldPassword, setErrorMsgForOldPassword] = useState("");
  const [errLogin, setErrLogin] = useState("");

  //   const [errorEmail, setErrorEmail] = useState("");

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  useEffect(() => {
    setLoginData(readLoginData());
  }, []);

  async function argon2HashPassword(user, userPassword) {
    // let decodedUserName = atob(user);
    //
    // setUserNameFromApi(ddecodedUserName);
    const salt = user;
    const hash = await argon2id({
      password: userPassword,
      salt,
      parallelism: 1,
      iterations: 2,
      memorySize: 16,
      hashLength: 16,
      outputType: "encoded",
    });
    //
    return hash;
  }

  const handlePasswordChange = async () => {
    const isValid = formValidation();

    if (isValid) {
      const argon = await argon2HashPassword(
        loginData.USER_NAME,
        changedPassword
      );

      if (confirmPassword !== changedPassword) {
        setMisMatchedPassword("Password does not matches.");
      }

      if (argon && confirmPassword == changedPassword) {
        setLoading(true);

        const response = await changeUserPassword(
          loginData.USER_NAME,
          currentPassword,
          argon
        );

        if (response.status == 200) {
          setLoading(false);
          setSuccessMsg(`A password has been changed successfully.`);
          setOpenSnackbar(true);
          setTimeout(() => {
            // localStorage.clear();
            // window.location.reload();
            if (
              localStorage.getItem("website_url") ===
              process.env.REACT_APP_CUSTOMER_WEBSITE_URL
            ) {
              history.push("/customer/login");
              // localStorage.clear();
              localStorage.removeItem("CUSTOMER_DATA");
              localStorage.removeItem("x_token");
              localStorage.removeItem("website_url");
              localStorage.removeItem("LOGIN_DATA");
              window.location.reload();
            } else {
              history.push("/login");
              localStorage.clear();
              window.location.reload();
            }
            //   history.push("/login");
          }, 2000);

          // setRegistredEmail("");
          // setWrongEmailMsg("");
        }
        if (response == 422) {
          setSuccessMsg("");
          setLoading(false);

          // setWrongEmailMsg("Please enter correct email id.");
        }
        if (response.status == 400) {
          setLoading(false);
          setErrorMsgForOldPassword(response.data.detail);
        }
      }
    } else {
      setMisMatchedPassword("");
    }

    // setLoading(true);
  };

  const [state, setState] = React.useState({
    right: false,
  });
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "left",
  });
  const { vertical, horizontal } = snackbarPosition;

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleBack = () => {
    if (loginApp === "leap-supply-customers") {
      history.push("/customer/customer-indents");
    } else {
      history.push("/dashboard");
    }
  };
  const [values, setValues] = useState({
    showPassword: false,
  });
  const [valuesNewPassowrd, setValuesNewPassowrd] = useState({
    showPassword: false,
  });
  const [valuesConfirmPassword, setValuesConfirmPassword] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowPasswordNew = () => {
    setValuesNewPassowrd({
      ...valuesNewPassowrd,
      showPassword: !valuesNewPassowrd.showPassword,
    });
  };
  const handleClickShowPasswordConfirm = () => {
    setValuesConfirmPassword({
      ...valuesConfirmPassword,
      showPassword: !valuesConfirmPassword.showPassword,
    });
  };
  const [errorPassword, setErrorPassword] = useState("");

  const formValidation = () => {
    const errorPassword = {};
    let isValid = true;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const isNonWhiteSpace = /^\S*$/;
    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    const isContainsNumber = /^(?=.*[0-9]).*$/;
    const isContainsSymbol =
      /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
    const isValidLength = /^.{8,}$/;
    // if (!passwordRegex.test(changedPassword)) {
    //   errorPassword.errorPasswordRegex =
    //     "Your password must conatins atleast [a-z,A-Z,]  ";
    //   isValid = false;
    // }
    if (!isNonWhiteSpace.test(changedPassword)) {
      setMisMatchedPassword("");
      errorPassword.errorPasswordwhitespace =
        "*Password must not contain spaces ";
      isValid = false;
    }
    if (!isContainsUppercase.test(changedPassword)) {
      setMisMatchedPassword("");
      errorPassword.errorPassworduppercase =
        "*Password must contain atleast one Uppercase character.";
      isValid = false;
    }
    if (!isContainsLowercase.test(changedPassword)) {
      setMisMatchedPassword("");
      errorPassword.errorPasswordlowercase =
        "*Password must contain atleast one Lowercase Character. ";
      isValid = false;
    }
    if (!isContainsNumber.test(changedPassword)) {
      setMisMatchedPassword("");
      errorPassword.errorPasswordNumber =
        "*Password must contain atleast one Digit.";
      isValid = false;
    }
    if (!isContainsSymbol.test(changedPassword)) {
      setMisMatchedPassword("");
      errorPassword.errorPasswordSymbol =
        "*Password must contain atleast one Special Symbol.";
      isValid = false;
    }
    if (!isValidLength.test(changedPassword)) {
      setMisMatchedPassword("");
      errorPassword.errorPasswordLength =
        "*Password should be minimum of 8 Characters Long.";
      isValid = false;
    }
    setErrorPassword(errorPassword);

    return isValid;
  };
  return (
    <Login_container_changePassword
      className="login_container_changePassword"
      mode={darkMode}
    >
      {is_temporary_password ? null : (
        <div className="arrow-back" onClick={handleBack}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          Back
        </div>
      )}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={4000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        key={vertical + horizontal}
      >
        <Alert severity="success">{successMsg}</Alert>
      </Snackbar>
      <div className="blur">
        {/* <div className="login_left"></div> */}
        <div className="login_right">
          <div className="change-password-form">
            <div className="logo_container">
              {isMobile ? null : (
                <img src={logo} alt="" className="logo_leap" />
              )}
            </div>

            <div className="login_input">
              <div className="headings_login-forget">
                <h5 className="login_text_forget_password">CHANGE PASSWORD</h5>{" "}
              </div>
              {is_temporary_password ? null : (
                <Input
                  type={values.showPassword ? "text" : "password"}
                  id="standard-size-normal"
                  className="login-input-field"
                  placeholder="Enter Current Password"
                  // defaultValue="Enter Mobile Number"
                  variant="standard"
                  // type="text"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        className="show_button"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={currentPassword}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                    setErrorMsgForOldPassword("");
                  }}
                />
              )}

              {/* {registredEmail.length > 0 ? null : (
                <>
                  {" "}
                  {Object.keys(errorEmail).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", margin: "4px" }}>
                        {errorEmail[key]}
                      </h6>
                    );
                  })}
                </>
              )} */}
              <Input
                id="standard-size-normal"
                type={valuesNewPassowrd.showPassword ? "text" : "password"}
                className="login-input-field"
                placeholder="Enter New Password"
                style={{ margin: "15px 0px" }}
                // defaultValue="Enter Mobile Number"
                variant="standard"
                // type="text"
                value={changedPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className="show_button"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordNew}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {valuesNewPassowrd.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => {
                  setChangedPassword(e.target.value);
                  setErrorPassword({});
                  // setErrLogin("");
                }}
              />
              {/* {registredEmail.length > 0 ? null : (
                <>
                  {" "}
                  {Object.keys(errorEmail).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", margin: "4px" }}>
                        {errorEmail[key]}
                      </h6>
                    );
                  })}
                </>
              )} */}
              <Input
                id="standard-size-normal"
                type={valuesConfirmPassword.showPassword ? "text" : "password"}
                className="login-input-field"
                placeholder="Confirm New Password"
                // defaultValue="Enter Mobile Number"
                variant="standard"
                // type="text"
                value={confirmPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className="show_button"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordConfirm}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {valuesConfirmPassword.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setMisMatchedPassword("");
                  // setErrLogin("");
                }}
              />
              {/* {registredEmail.length > 0 ? null : (
                <>
                  {" "}
                  {Object.keys(errorEmail).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", margin: "4px" }}>
                        {errorEmail[key]}
                      </h6>
                    );
                  })}
                </>
              )} */}
              {misMatchedPassword ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {misMatchedPassword}
                </span>
              ) : null}
              {Object.keys(errorPassword).map((key) => {
                return (
                  <h6
                    style={{
                      color: "red",
                      fontSize: "12px",
                      fontWeight: "light",
                      padding: "0px 10px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      // textAlign: "center",
                    }}
                  >
                    {errorPassword[key]}
                  </h6>
                );
              })}
            </div>

            {/* errorPasswordwhitespace */}
            {/* {successMsg ? <span>{successMsg}</span> : null} */}

            <div className="button_container_forget_password">
              <ButtonRectangle
                variant="contained"
                // color="success"
                onClick={handlePasswordChange}
                // style={{
                //   backgroundColor: "#016938",
                //   borderRadius: "20px",
                //   padding: ".5rem 0rem",
                //   marginTop: "15px",
                // }}
                // className="login-input-field"
              >
                {loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  "CHANGE PASSWORD"
                )}
              </ButtonRectangle>
              {/* <div className="back_to_home_button">
                <KeyboardBackspaceIcon />

                <Link to="/login">Back To Login</Link>
              </div> */}
            </div>
            <div
              style={{
                width: "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // margin: "1.5rem auto",
              }}
            >
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: ".3rem .3rem",
                // margin: "1.5rem auto",
              }}
            >
              {errorMsgForOldPassword ? (
                <h6 style={{ color: "red" }}>{errorMsgForOldPassword}</h6>
              ) : null}
            </div>
            {/* <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "1.5rem auto",
              }}
            >
              <span className="cutomer-login-button" onClick={goToCustomer}>
                Login as Customer
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </Login_container_changePassword>
  );
}

export default ChangePasswords;
