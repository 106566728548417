import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import {
  approveRejectTrip,
  GetTripList,
  GetTripListWithFilter,
  GetTripPaymentStatusEnums,
  GetTripStatusEnums,
  reopenCancelTrip,
  startTrip,
  stopTrip,
  TripAttachmentPost,
  GetCustomerListApi,
  GetTransporterByName,
  getTripExport,
} from "./tripListServices";

import Stack from "@mui/material/Stack";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import TRUCK_IMAGE from "../../../assets/dashboard_icons/icon-0.8s-215px.png";
import "./trip-list.css";
import MONTHLYICON from "../../../assets/monthly_icon.svg";
import TripPickup from "../tripPickup/TripPickup";
import TRIPWISEICON from "../../../assets/trip_wise_icon.svg";
import INFOICON from "../../../assets/infoIcon.svg";
import CloseIcon from "@mui/icons-material/Close";
import mapIcon from "../../../assets/map_icon.svg";
import { styled } from "@mui/system";
import Snackbar from "@mui/material/Snackbar";
import { GetCustomerSearchList } from "../createTrip/createTripServices";

import success from "../../../assets/dashboard_icons/icons8-truck.gif";
import { NavLink, useHistory } from "react-router-dom";
import { Search, Close, Edit, Add, Download } from "@mui/icons-material";
import MiniDetailsCard from "../../../components/miniDetailsCard/MiniDetailsCard";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FILTER_ICON from "../../../assets/filter_icon.svg";
import UploadImage from "../../../assets/upload.svg";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import {
  CircularProgress,
  TextField,
  MenuItem,
  Alert,
  Drawer,
  InputAdornment,
  Menu,
  Autocomplete,
  Collapse,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePendingTripInvoiceList,
  updateSelectedTrip,
  updateSelectedTripInvoices,
  updateTripDetails,
  updateTripSearchValue,
} from "../../../redux/tripSlice";
import axios from "axios";
import apiUrl from "../../../url/apiUrl";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import {
  indexSelectedData,
  readLoginData,
} from "../../../helper/dataDecryptionHelper";
// import { readLoginData } from "../../../helper/dataDecryptionHelper";
import { GetDocumentEnums } from "../createTrip/createTripServices";
import * as moment from "moment";
import { ModalUnstyled } from "@mui/base";
import AsyncSelect from "react-select/async";
import CopyText from "../../../components/copyText/CopyText";
import axiosInterceptor from "../../../config/https";
import QueryStatsIcon from "@mui/icons-material/QueryStats";

import closIcons from "../../../assets/tripIcons/close_icon.svg";
import {
  ButtonRectangle,
  ButtonRectangleSecondary,
} from "../../../themes/components/button";
import { colorPalette } from "../../../themes/theme";
import { MyContext } from "../../../components/enumContext/EnumContextComponent.jsx";
const CryptoJS = require("crypto-js");
// import {updateTrip}

// {
//   "source_id": 9,
//   "approx_cost_id": 5,
//   "actual_cost_id": null,
//   "id": 5,
//   "payments_status": "paid",
//   "load_source": "trip_purchase_order",
//   "created_on": "2022-03-14T11:25:26.316874",
//   "load_unit_id": null,
//   "vehicle_number": "KA-29-2125",
//   "driver_id": 5,
//   "destination_id": 10,
//   "is_active": true,
//   "modified_on": "2022-03-14T11:25:26.319086",
//   "trip_distance_in_km": 78.0,
//   "trip_code": "KU0314251647257126",
//   "zoho_id": "670003",
//   "remarks": null,
//   "trip_status": "created",
//   "attachment_count": 0
// },

// const dummyInvoiceList = [
//   "UP-22-23-0000389",
//   "UP-22-23-0000386",
//   "UP-22-23-0000364",
//   "UK-22-23-0000297",
// ];

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

const filterBoxStyle = {
  width: isMobile ? "95vw" : "36%",
  height: isMobile ? "75vh" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -34,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};

const pickupBoxStyle = {
  width: isMobile ? "90vw" : "55%",
  height: isMobile ? "auto" : "73vh",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -24,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};

const tripRoutesBoxStyle = {
  width: isMobile ? "90vw" : "40%",
  height: isMobile ? "auto" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -24,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};

const exportBoxStyle = {
  width: isMobile ? "85%" : "70%",
  height: isMobile ? "80vh" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  // marginTop: isMobile ? -10 : -20,
  border: "1px solid gray",
  borderRadius: "10px",
};

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${isMobile ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.5)`};
  -webkit-tap-highlight-color: transparent;
`;

function TripList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const validEmailRegex = /(.*?)@faarms.com/;
  const [allTripList, setAllTripList] = useState([]);
  //
  const [selectedTrip, setSelectedTrip] = useState({});
  const [selectedTripIndex, setSelectedTripIndex] = useState(null);
  const [tripRemarks, setTripRemarks] = useState("");
  const [selectedTripId, setSelectedTripId] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const [attachmentTag, setAttachmentTag] = useState("builty copy");
  const [errTotalExport, setErrTotalExport] = useState("");
  const [errTripRemarks, setErrTripRemarks] = useState("");
  const [errAttachmentTag, setErrAttachmentTag] = useState("");
  const [errAttachmentType, setErrAttachmentType] = useState("");
  const [errAttachmentFile, setErrAttachmentFile] = useState("");
  const [filterTripCode, setFilterTripCode] = useState("");
  const [filterPaymentStatus, setFilterPaymentStatus] = useState("");
  const [filterVehicleNumber, setFilterVehicleNumber] = useState("");
  const [filterCustomerId, setFilterCustomerId] = useState("");
  const [filterCustomerData, setFilterCustomerData] = useState({});
  const [filterLoadSource, setFilterLoadSource] = useState("");
  const [filterReferenceNumber, setFilterReferenceNumber] = useState("");
  const [filterEwayBillNo, setFilterEwayBillNo] = useState("");

  const [loadingFilterSearchButton, setLoadingFilterSearchButton] =
    useState(false);
  const [filterTripStatus, setFilterTripStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userRole, setUserRole] = useState("");
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [tripDeliveredBills, setTripDeliveredBills] = useState([]);
  const [tripAttachmentFile, setTripAttachmentFile] = useState(null);
  const [errTripStatus, setErrTripStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isPickupOpen, setIsPickupOpen] = useState(false);

  const [isExportOpen, setIsExportOpen] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [exportToastMessage, setExportToastMessage] = useState("");

  const [sessionExpires, setSessionExpires] = useState("");
  const [tripStatusList, setTripStatusList] = useState([
    "created",
    "rejected",
    "reopen",
    "approved",
    "intransit",
    "cancelled",
    "completed",
  ]);
  const [tripTypeList, setTripTypeList] = useState([
    "first_mile",
    "middle_mile",
    "last_mile",
  ]);
  const [filterTripType, setFilterTripType] = useState("");

  const [loadSourceList, setLoadSourceList] = useState([
    "trip_invoice",
    "trip_purchase_order",
    "manual",
  ]);
  const [tripPaymentStatusList, setTripPaymentStatusList] = useState([]);

  const [attachmentTypeList, setAttachmentTypeList] = useState([
    "image",
    "pdf",
  ]);

  const [attachmentListArray, setAttachmentListArray] = useState([
    {
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    },
  ]);
  const [errAttachmentSize, setErrAttachmentSize] = useState([]);
  const [attachmentType, setAttachmentType] = useState("0");
  const [state, setState] = useState({ right: false });
  const [loadingApproveButton, setLoadingApproveButton] = useState(false);
  const [currentStatusUpdateInProgress, setCurrentStatusUpdateInProgress] =
    useState("");
  const { tripSearchValue } = useSelector((state) => state.trip);

  //pagination starts

  const [isLastPage, setIsLastPage] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  // const [searchValue, setSearchValue] = useState("");
  const [attachmentCount, setAttachmentCount] = useState([1]);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [exportFromDate, setExportFromDate] = useState("");
  const [exportToDate, setExportToDate] = useState("");
  const [exportTripStatus, setExportTripStatus] = useState("0");
  const [exportCustomer, setExportCustomer] = useState("");
  const [exportVehicleNumber, setExportVehicleNumber] = useState("");
  const [exportTransporter, setExportTransporter] = useState("");
  const [exportVehiclePayoutPeriod, setExportVehiclePayoutPeriod] =
    useState("0");
  const [exportPaymentStatus, setExportPaymentStatus] = useState("0");
  const [exportSourceState, setExportSourceState] = useState("0");
  const [exportDestinationState, setExportDestinationState] = useState("0");
  const [exportCreatedBy, setExportCreatedBy] = useState("");
  const [exportLoadSource, setExportLoadSource] = useState("0");
  const [exportEmail, setExportEmail] = useState("");
  const [exportTripType, setExportTripType] = useState("0");
  const [errExportEmailId, setErrExportEmailId] = useState("");
  const [customerSearchValue, setCustomerSearchValue] = useState("");
  const [exportCustomerSearchValue, setExportCustomerSearchValue] =
    useState("");

  const [openToast, setOpenToast] = useState(false);
  const { enums } = useContext(MyContext);

  const [vehiclePayoutPeriodList, setVehiclePayoutPeriodList] = useState([
    "trip_wise",
    "monthly_wise",
  ]);
  const [openTripRoutes, setOpenTripRoutes] = useState(false);
  // const tripTypeList = ["first_mile", "middle_mile", "last_mile"];

  const handleOpenExportReport = () => {
    setIsExportOpen(true);
    if (readLoginData()) {
      // console.log("readLoginData>>>>", readLoginData().USER_EMAIL);
      if (readLoginData().USER_EMAIL) {
        setExportEmail(readLoginData().USER_EMAIL);
      }
    }
  };

  const observer = useRef();

  const lastItem = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage]
  );

  // const isMobile = window.matchMedia(
  //   "only screen and (max-width: 499px)"
  // ).matches;

  const handleCloseFilterOptions = () => {
    setIsFilterOpen(false);
  };

  const handleClosePickupOptions = () => {
    setIsPickupOpen(false);
  };

  useEffect(() => {
    getStateList();
  }, [enums]);

  const getStateList = async () => {
    // var stateList = await axiosInterceptor.get(
    //   `${apiUrl.onboardingBaseUrl}/v1/enums/permitted-states`
    // );
    //

    // setStateList(stateList.data);
    setStateList(enums?.permitted_states ? enums.permitted_states : [])

    // setNewVillagesList(address.data.office[0].villages);
    // }
  };

  const handleTripsExportReportApi = async () => {
    const isEmailValid = validEmailRegex.test(exportEmail);

    if (isEmailValid && !loadingExportButton) {
      setLoadingExportButton(true);
      const response = await getTripExport(
        exportEmail,
        exportFromDate,
        exportToDate,
        exportTripStatus,
        exportCustomer,
        exportVehicleNumber,
        exportTransporter,
        exportVehiclePayoutPeriod,
        exportPaymentStatus,
        exportSourceState,
        exportDestinationState,
        exportCreatedBy,
        exportLoadSource,
        exportTripType
      );
      if (response.status === 200) {
        setOpenToast(true);
        handleCloseExport();
        setExportToastMessage(response.data.message);
        // alert(response.data.message);
        setLoadingExportButton(false);
      } else if (response.status === 400) {
        setErrTotalExport(response.data.detail);
        setLoadingExportButton(false);
      } else {
        setErrTotalExport(`${response.status} : ERROR`);
        setLoadingExportButton(false);
      }
    } else {
      setErrExportEmailId("Please enter valid Email Id");
    }
  };

  const handleCloseExport = () => {
    setIsExportOpen(false);
    resetFormExport();
  };

  useEffect(() => {
    if (readLoginData()) {
      setUserRole(readLoginData().Role);
    }
  }, []);
  // console.log("the data==========", readLoginData());
  // const transporterOptions=()=>{

  // }

  const transporterOptions = (inputValue) =>
    new Promise((resolve) => {
      const response = handleTransporterVehicles(inputValue);
      resolve(response);
    });

  const handleTransporterVehicles = async (searchValue) => {
    const response = await GetTransporterByName(searchValue);
    return response.transporter_list.map((transporter) => {
      return {
        label: transporter.name,
        value: transporter.name,
      };
    });
  };
  //pagination ends

  useEffect(() => {
    if (allTripList.length > 0) {
      if (indexSelectedData()) {
        if (indexSelectedData().hasOwnProperty("trip")) {
          setSelectedTripIndex(indexSelectedData().trip);
          if (allTripList.length > indexSelectedData().trip) {
            setSelectedTrip(allTripList[indexSelectedData().trip]);
            dispatch(updateSelectedTrip(allTripList[indexSelectedData().trip]));
          } else {
            setSelectedTrip(allTripList[0]);
            dispatch(updateSelectedTrip(allTripList[0]));
            setSelectedTripIndex(0);
          }
        } else {
          setSelectedTrip(allTripList[0]);
          dispatch(updateSelectedTrip(allTripList[0]));
          setSelectedTripIndex(0);
        }
      } else {
        setSelectedTrip(allTripList[0]);
        dispatch(updateSelectedTrip(allTripList[0]));
        setSelectedTripIndex(0);
      }
    }
  }, [allTripList]);

  // useEffect(() => {
  //   if (selectedTripIndex > 0 && allTripList.length > 0) {
  //     history.push("/faarms-trip#selectedIndexTrip");
  //   }
  // }, [selectedTripIndex, allTripList]);

  //modal related starts

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleOpenFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleOpenPickup = () => {
    setIsPickupOpen(true);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const resetFormExport = () => {
    setErrTotalExport("");
    setExportFromDate("");
    setExportToDate("");
    setLoadingExportButton(false);
    setExportTripStatus("0");
    setExportCustomer("");
    setExportCustomerSearchValue("");
    setExportVehicleNumber("");
    setExportTransporter("");
    setExportVehiclePayoutPeriod("0");
    setExportPaymentStatus("0");
    setExportSourceState("0");
    setExportDestinationState("0");
    setExportCreatedBy("");
    setExportLoadSource("0");
    setExportTripType("0");
    setExportEmail("");
    setErrExportEmailId("");
  };

  const resetForm = () => {
    setTripRemarks("");
    setLoadingModal(false);
    setAttachmentTag("");
    setAttachmentType("0");
    setErrTripRemarks("");
    setErrTripStatus("");
    setErrAttachmentTag("");
    setErrAttachmentType("");
    setErrAttachmentFile("");
    setAttachmentCount([1]);
    setAttachmentListArray([
      {
        attachment_tag: "0",
        attachment_type: "",
        attachment_file: null,
      },
    ]);
  };

  //modal related ends

  const handleAddAttachment = () => {
    let attachmentTemp = [...attachmentListArray];
    attachmentTemp.push({
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    });
    setAttachmentListArray(attachmentTemp);
  };

  //scroll to selected trip code start
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  //scroll to selected trip code end

  //
  const handleGetTripList = async (page) => {
    setLoadingMore(true);
    if (page == 1) {
      setLoading(true);
    }

    const response = await GetTripList(page);
    // dispatch(storeTripList(response.trip_list))

    if (response == 403) {
      localStorage.clear();
      setSessionExpires("session has expired,please login again.");
      // window.location.reload();
      history.push("/");

      // setLoading(false);
    }
    if (response.trip_list.length > 0) {
      if (page > 1) {
        setAllTripList([...allTripList, ...response.trip_list]);
      } else {
        setAllTripList(response.trip_list);
      }
    }

    setIsLastPage(response.is_last_page);
    setLoadingMore(false);

    // if (response.trip_list.length > 0 && !isMobile) {
    //   console.log(
    //     "selected trip--completed list",
    //     response.trip_list[0].reference_number.map((item) => ({
    //       reference_number: item,
    //       status: "delivered",
    //     }))
    //   );
    //   setTripDeliveredBills(
    //     response.trip_list[0].reference_number.map((item) => ({
    //       reference_number: item,
    //       status: "delivered",
    //     }))
    //   );
    // }

    // setTripDeliveredBills();
    setLoading(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "auto", // width:
    //   currentStatusUpdateInProgress == "intransit" ||
    //   currentStatusUpdateInProgress == "completed"
    //     ? "50%"
    //     : "35%",
    // height:
    //   currentStatusUpdateInProgress == "intransit" ||
    //   currentStatusUpdateInProgress == "completed"
    //     ? "72%"
    //     : "50%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  const handleApproveTrip = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("approved");
    handleOpen();
    e.stopPropagation();
  };

  // const handleGetCustomerList = async () => {
  //   const response = await GetCustomerListApi();
  //   if (response) {
  //
  //     setCustomerList(response.customers_list);
  //   }
  // };

  // useEffect(() => {
  //   handleGetCustomerList();
  // }, []);

  const handleSearchCustomer = async (searchValue) => {
    const response = await GetCustomerSearchList(searchValue);
    if (response) {
      setCustomerList(response?.customers_list);
    }
  };

  useEffect(() => {
    if (customerSearchValue) {
      handleSearchCustomer(customerSearchValue);
    }
  }, [customerSearchValue]);

  useEffect(() => {
    if (exportCustomerSearchValue) {
      handleSearchCustomer(exportCustomerSearchValue);
    }
  }, [exportCustomerSearchValue]);

  const handleCancelTrip = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("cancelled");
    handleOpen();
    e.stopPropagation();
  };

  useEffect(() => {
    async function fetchData() {
      const documentTypeData = await GetDocumentEnums();
      setDocumentTypeList(documentTypeData);
    }
    fetchData();
  }, []);

  const onFileChange = (event) => {
    setTripAttachmentFile(event.target.files[0]);
  };

  const handleStartTrip = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("intransit");
    handleOpen();
    e.stopPropagation();
  };

  const handleGetPaymentStatus = async () => {
    const response = await GetTripPaymentStatusEnums();
    setTripPaymentStatusList(response);
  };

  useEffect(() => {
    handleGetPaymentStatus();
  }, []);

  const handleGetTripStatus = async () => {
    const response = await GetTripStatusEnums();
    setTripStatusList(response);
  };

  // useEffect(() => {
  //   handleGetTripStatus();
  // }, []);

  const handleReopenTrip = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("reopen");
    handleOpen();
    e.stopPropagation();
  };

  const handleCompleteTrip = (trip) => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("completed");
    handleOpen();
    history.push(`/faarms-trip/${trip.id}/complete-trip`);
  };

  const handleDeliveryInvoicePayment = (trip) => {
    dispatch(
      updateSelectedTripInvoices(
        trip.reference_number ? trip.reference_number : []
      )
    );
    dispatch(
      updatePendingTripInvoiceList(
        trip.pending_reference_numbers ? trip.pending_reference_numbers : []
      )
    );
    setSelectedTripId(trip.id);
    history.push(`/faarms-trip/${trip.id}/delivery-invoice-payment`);
  };

  const handlePayTrip = (trip) => {
    setSelectedTripId(trip.id);
    // setCurrentStatusUpdateInProgress("completed");
    // handleOpen();
    // /faarms-trip/:tripId/
    history.push(`/faarms-trip/${trip.id}/record-payment`);
  };

  const handleRejectTrip = (trip, e) => {
    setCurrentStatusUpdateInProgress("rejected");
    setSelectedTripId(trip.id);
    handleOpen();
    e.stopPropagation();
  };

  const handleFilterSearch = async (page) => {
    setLoadingFilterSearchButton(true);
    // setCurrentPage(1);
    const responseFilter = await GetTripListWithFilter(
      page,
      filterTripCode,
      filterPaymentStatus,
      filterVehicleNumber,
      filterTripStatus,
      filterReferenceNumber,
      filterCustomerId,
      filterLoadSource,
      filterEwayBillNo,
      filterTripType
    );
    if (responseFilter) {
      if (page > 1) {
        setAllTripList([...allTripList, ...responseFilter.trip_list]);

        setIsLastPage(responseFilter.is_last_page);
        setLoadingFilterSearchButton(false);
        handleCloseFilterOptions();
      } else {
        setAllTripList(responseFilter.trip_list);
        setLoadingFilterSearchButton(false);
        setIsLastPage(responseFilter.is_last_page);
        handleCloseFilterOptions();
      }
    }
  };

  const handleCloseFilterModal = () => {
    setIsFilterOpen(false);
  };

  const handlePickupTrip = (e, trip, index) => {
    setSelectedTripId(trip.id);
    setSelectedTrip(trip);
    dispatch(updateSelectedTrip(trip));
    setSelectedTripIndex(index);
    // history.push(`/faarms-trip/${trip?.id}/trip-pickup/${trip?.trip_code}`);
    handleOpenPickup();
    e.stopPropagation();
  };

  // const handleSubmitApproveRemarks = async () => {
  //   setLoadingModal(true);
  //   const response = await approveRejectTrip(
  //     "approved",
  //     tripRemarks,
  //     selectedTripId
  //   );
  //   setLoadingModal(false);
  //   handleClose();
  //
  // };

  // const handleSubmitReject = async () => {
  //   setLoadingModal(true);
  //   const response = await approveRejectTrip(
  //     currentStatusUpdateInProgress,
  //     tripRemarks,
  //     selectedTripId
  //   );
  //   setLoadingModal(false);
  //   handleClose();
  // };

  //   "order_details": [
  //     {
  //         "reference_number": "898678",
  //         "status": "delivered"
  //     },
  //      {
  //         "reference_number": "12345",
  //         "status": "delivered"
  //     }
  // ]

  const handleSubmitApproveReject = async () => {
    setLoadingModal(true);
    const response = await approveRejectTrip(
      currentStatusUpdateInProgress,
      tripRemarks,
      selectedTripId
    );
    if (response.status == 400) {
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      // handleGetTripList(1);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType
      ) {
        handleFilterSearch(currentPage);
      } else {
        handleGetTripList(currentPage);
      }
      handleClose();
    }
  };

  const handleSubmitReopenCancel = async () => {
    setLoadingModal(true);
    const response = await reopenCancelTrip(
      currentStatusUpdateInProgress,
      tripRemarks,
      selectedTripId
    );
    if (response.status == 400) {
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      // handleGetTripList(1);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType
      ) {
        handleFilterSearch(currentPage);
      } else {
        handleGetTripList(currentPage);
      }
      handleClose();
    }
    // setLoadingModal(false);
    // setCurrentPage(1);
    // handleGetTripList(1);

    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth", // for smoothly scrolling
    // });
    // handleClose();
  };

  const handleClearFilter = () => {
    handleGetTripList(1);
    setCurrentPage(1);
    setFilterPaymentStatus("");
    setFilterTripCode("");
    setFilterTripStatus("");
    setFilterVehicleNumber("");
    setFilterTripType("");
    setFilterReferenceNumber("");
    handleCloseFilterOptions();
    setFilterCustomerId("");
    setFilterCustomerData({});
    setFilterEwayBillNo("");
    setFilterLoadSource("");
  };

  const handleSubmitStart = async () => {
    setLoadingModal(true);
    const attachmentResponse = TripAttachmentPost(
      tripAttachmentFile,
      selectedTripId,
      attachmentTag,
      attachmentType,
      "start_trip",
      attachmentListArray
    );

    const resolved = await Promise.all(attachmentResponse);

    const response = await startTrip(
      currentStatusUpdateInProgress,
      tripRemarks,
      selectedTripId
    );
    if (response.status == 400) {
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      // handleGetTripList(1);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType
      ) {
        handleFilterSearch(currentPage);
      } else {
        handleGetTripList(currentPage);
      }
      handleClose();
    } else {
      setErrTripStatus(`ERROR : ${response.status}`);
      setLoadingModal(false);
    }
  };

  const handleOpenTripRoute = (e, trip, index) => {
    setSelectedTripId(trip.id);
    setSelectedTrip(trip);
    dispatch(updateSelectedTrip(trip));
    setSelectedTripIndex(index);
    setOpenTripRoutes(true);
    e.stopPropagation();
  };

  const handleCloseTripRoute = () => {
    setOpenTripRoutes(false);
  };

  const handleSubmitStop = async () => {
    setLoadingModal(true);
    const attachmentResponse = await TripAttachmentPost(
      tripAttachmentFile,
      selectedTripId,
      attachmentTag,
      attachmentType,
      "end_trip"
    );
    if (attachmentResponse.status == 400) {
      setErrTripStatus(attachmentResponse.data.detail);
      setLoadingModal(false);
    } else if (attachmentResponse == "success") {
      const response = await stopTrip(
        currentStatusUpdateInProgress,
        tripRemarks,
        selectedTripId,
        tripDeliveredBills
      );
      if (response.status == 400) {
        setErrTripStatus(response.data.detail);
        setLoadingModal(false);
      } else if (response.status == 200) {
        setLoadingModal(false);
        setCurrentPage(1);
        // handleGetTripList(1);
        if (tripSearchValue) {
          handleSearchTrip(tripSearchValue, currentPage);
        } else if (
          filterTripCode ||
          filterPaymentStatus ||
          filterVehicleNumber ||
          filterTripStatus ||
          filterReferenceNumber ||
          filterLoadSource ||
          filterCustomerId ||
          filterEwayBillNo ||
          filterTripType
        ) {
          handleFilterSearch(currentPage);
        } else {
          handleGetTripList(currentPage);
        }
        handleClose();
      }
    }

    // setLoadingModal(false);
    // setCurrentPage(1);
    // handleGetTripList(1);

    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth", // for smoothly scrolling
    // });
    // handleClose();
  };

  const handleSubmitRemarks = async () => {
    if (
      (currentStatusUpdateInProgress == "approved" ||
        currentStatusUpdateInProgress == "rejected") &&
      !loadingModal
    ) {
      handleSubmitApproveReject();
    } else if (
      (currentStatusUpdateInProgress == "reopen" ||
        currentStatusUpdateInProgress == "cancelled") &&
      !loadingModal
    ) {
      handleSubmitReopenCancel();
    } else if (currentStatusUpdateInProgress == "intransit" && !loadingModal) {
      if (tripRemarks.trim()) {
        if (
          attachmentListArray
            .map((item) => {
              if (item.attachment_file) {
                if (
                  item.attachment_file.size >
                  process.env.REACT_APP_MAX_FILE_SIZE
                ) {
                  return "greater";
                }
              }
            })
            .indexOf("greater") > -1
        ) {
          return;
        }

        handleSubmitStart();
      } else {
        setErrTripRemarks("Please enter the remarks");
        setErrAttachmentTag("Please enter the document type");
        setErrAttachmentType("Please select the attachment type");
        setErrAttachmentFile("Please select the attachment file");
      }
    } else if (currentStatusUpdateInProgress == "completed" && !loadingModal) {
      if (
        tripRemarks.trim() &&
        attachmentTag &&
        attachmentType.length > 1 &&
        tripAttachmentFile
      ) {
        handleSubmitStop();
      } else {
        setErrTripRemarks("Please enter the remarks");
        setErrAttachmentTag("Please enter the document type");
        setErrAttachmentType("Please select the attachment type");
        setErrAttachmentFile("Please select the attachment file");
      }
    }
  };

  const handleCloseDetailsDrawer = () => {
    toggleDrawer("right", false);
  };

  const handleCreateNewTripButton = () => {
    // const url = "/faarms-trips/create-trip";
    // window.open(url, "_blank");
    localStorage.setItem("createTripSource", "/faarms-trip");
    history.push("/faarms-trips/create-trip");
  };
  useEffect(() => {
    localStorage.setItem("createTripSource", "/faarms-trip");
  }, []);
  const handleDeliveredCheckboxToggle = (orderDetail, index) => {
    // setTripDeliveredBills([
    //   ...tripDeliveredBills.filter(
    //     (item) => item.reference_number != orderDetail.reference_number
    //   ),
    //   {
    //     reference_number: orderDetail.reference_number,
    //     status:
    //       orderDetail.status == "delivered" ? "not-delivered" : "delivered",
    //   },
    // ]);
    // console.log("order", [
    //   ...tripDeliveredBills.filter(
    //     (item) => item.reference_number != orderDetail.reference_number
    //   ),
    //   {
    //     reference_number: orderDetail.reference_number,
    //     status:
    //       orderDetail.status == "delivered" ? "not-delivered" : "delivered",
    //   },
    // ]);

    let tripDeliveredBillsTemp = [...tripDeliveredBills];
    tripDeliveredBillsTemp[index]["status"] =
      tripDeliveredBillsTemp[index]["status"] == "delivered"
        ? "not-delivered"
        : "delivered";

    setTripDeliveredBills(tripDeliveredBillsTemp);
  };

  const handleSelectTrip = (trip, index) => {
    setSelectedTrip(trip);
    dispatch(updateSelectedTrip(trip));
    handleOpenDetails();
    setSelectedTripIndex(index);
    let encrypted_data = CryptoJS.AES.encrypt(
      JSON.stringify({ ...indexSelectedData(), trip: index }),
      apiUrl.SALT
    ).toString();
    localStorage.setItem("indexClicked", encrypted_data);
    // console.log(
    //   "selected trip--completed list",
    //   trip.reference_number.map((item) => ({
    //     reference_number: item,
    //     status: "delivered",
    //   }))
    // );
    if (trip.reference_number) {
      setTripDeliveredBills(
        trip.reference_number.map((item) => ({
          reference_number: item,
          status: "delivered",
        }))
      );
    } else {
      setTripDeliveredBills([]);
    }
  };

  const handleEditTrip = (trip) => {
    dispatch(updateTripDetails(trip));
    history.push(`/faarms-trip/${trip.id}/update-trip`);
  };

  const handleOpenDetails = () => {
    toggleDrawer("right", true);
  };

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    if (tripSearchValue) {
      handleSearchTrip(tripSearchValue, currentPage);
      // dispatch(updateTripSearchValue(searchValue));
    } else if (
      filterTripCode ||
      filterPaymentStatus ||
      filterVehicleNumber ||
      filterTripStatus ||
      filterReferenceNumber ||
      filterLoadSource ||
      filterCustomerId ||
      filterEwayBillNo ||
      filterTripType
    ) {
      handleFilterSearch(currentPage);
    } else {
      // dispatch(updateTripSearchValue(""));
      handleGetTripList(currentPage);
    }
  }, [currentPage, tripSearchValue]);

  const handleAttachmentChange = (e, index) => {
    e.preventDefault();
    let key = e.target.name;
    let value;
    if (key == "attachment_file") {
      value = e.target.files[0];
    } else {
      value = e.target.value;
    }

    let attachmentArrayTemp = [...attachmentListArray];
    attachmentArrayTemp[index][key] = value;
    setAttachmentListArray(attachmentArrayTemp);
  };

  const handleSearchTrip = async (values, page) => {
    // const value = event.target.value;
    //
    // setCurrentPage(1);
    // setSearchValue(value);

    // if (searchValue) {
    //   getOemListData(1);
    // }
    if (values && values.length >= 1) {
      try {
        setLoading(true);
        const productResponse = await axiosInterceptor.get(
          `${apiUrl.tripV7BaseUrl}?trip_code=${values}&current_page=${page}`
        );

        if (productResponse.data.trip_list.length > 0) {
          setAllTripList(productResponse.data.trip_list);
          setIsLastPage(productResponse.data.is_last_page);
          setSelectedTrip(productResponse.data.trip_list[0]);
          dispatch(updateSelectedTrip(productResponse.data.trip_list[0]));
          setSelectedTripIndex(0);
        } else {
          setAllTripList([]);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setAllTripList([]);
      }
    }
    // else if (value.length == 0) {
    //   getOemListData(currentPage);
    // }
  };
  const vertical = "bottom";
  const horizontal = "right";
  const [openAlert, setOpenAlert] = React.useState(true);
  const [popUpHide, setPopUpHide] = useState(true);

  const showNewFeaturePopUp = JSON.parse(
    localStorage.getItem("opneNewFeature")
  );
  // useEffect(() => {
  //   localStorage.setItem("createTripSource", "/tripsV2");
  // }, []);
  //   useEffect(()=>{
  // localStorage.setItem("tripListCount",allTripList.length)
  //   },[])
  //
  return (
    <div className="trip-list-main-container">
      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Trips" />
        </div>
      ) : (
        <div className="trip-list-sidebar">
          <SideBarMain />
        </div>
      )}

      <Snackbar
        open={openToast}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseToast}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          {exportToastMessage}
        </Alert>
      </Snackbar>

      {/*Filter modal starts*/}
      <StyledModal
        open={isFilterOpen}
        onClose={handleCloseFilterOptions}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={filterBoxStyle}>
          <div className="trip-list-filter-main">
            {isMobile ? (
              <div className="trip-list-filter-head">
                <span className="trip-list-filter-main-text">
                  Select filter options
                </span>{" "}
                <Close onClick={handleCloseFilterModal} />
              </div>
            ) : (
              <div className="trip-list-filter-head">
                <span
                  className="trip-list-filter-main-text"
                  style={{ marginLeft: "5px" }}
                >
                  Select filter options
                </span>{" "}
                <Close
                  onClick={handleCloseFilterModal}
                  style={{ cursor: "pointer" }}
                />
              </div>
            )}

            <div className="trip-list-filter-container">
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Trip code</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip code"
                    className="trip-list-filter-inputfield"
                    value={filterTripCode}
                    onChange={(e) => setFilterTripCode(e.target.value)}
                    variant="standard"
                  />
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Payment status</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="standard"
                    select
                    // placeholder="Enter payment status"
                    className="trip-list-filter-inputfield"
                    value={filterPaymentStatus}
                    onChange={(e) => setFilterPaymentStatus(e.target.value)}
                  >
                    {tripPaymentStatusList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Vehicle number</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-filter-inputfield"
                    variant="standard"
                    value={filterVehicleNumber}
                    onChange={(e) => setFilterVehicleNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Trip status</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip status"
                    variant="standard"
                    className="trip-list-filter-inputfield"
                    select
                    value={filterTripStatus}
                    onChange={(e) => setFilterTripStatus(e.target.value)}
                  >
                    {tripStatusList.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </TextField>
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Trip Type</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip status"
                    variant="standard"
                    className="trip-list-filter-inputfield"
                    select
                    value={filterTripType}
                    onChange={(e) => setFilterTripType(e.target.value)}
                  >
                    {tripTypeList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Reference number</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-filter-inputfield"
                    variant="standard"
                    value={filterReferenceNumber}
                    onChange={(e) => setFilterReferenceNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Customer Name</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    // value={
                    //   Object.keys(selectedCustomer).length > 0
                    //     ? selectedCustomer
                    //     : ""
                    // }
                    defaultValue={
                      Object.keys(filterCustomerData).length > 0
                        ? filterCustomerData
                        : { name: "" }
                    }
                    onChange={(event, newValue) => {
                      // setValue(newValue);
                      if (newValue) {
                        setFilterCustomerId(newValue?.id);
                        setFilterCustomerData(newValue);
                        setCustomerSearchValue(newValue.name);
                        setCustomerList([]);
                        // if (!newValue.name.toLowerCase().includes("faarms")) {
                        //   setLoadSource("manual");
                        // }
                      } else {
                        // setSelectedCustomer({});
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        setCustomerSearchValue(newInputValue);
                      }
                    }} // sx={{ width: 300 }}
                    style={{ width: "90%" }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                  />
                  {/* <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    select
                    className="trip-list-filter-inputfield"
                    variant="standard"
                    value={filterCustomerId}
                    onChange={(e) => setFilterCustomerId(e.target.value)}
                  >
                    {customerList.length > 0
                      ? customerList.map((item) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })
                      : null}
                  </TextField> */}
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Load Source</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-filter-inputfield"
                    select
                    variant="standard"
                    value={filterLoadSource}
                    onChange={(e) => setFilterLoadSource(e.target.value)}
                  >
                    {loadSourceList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">E-Way Bill No.</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-filter-inputfield"
                    variant="standard"
                    value={filterEwayBillNo}
                    onChange={(e) => setFilterEwayBillNo(e.target.value)}
                  />
                </div>
              </div>
              <div className="trip-list-filter-button-row">
                <button
                  className="trip-list-filter-button"
                  onClick={handleClearFilter}
                >
                  Clear
                </button>
                <button
                  className="trip-list-filter-button"
                  onClick={() => {
                    handleFilterSearch(1);
                    setCurrentPage(1);
                  }}
                >
                  {loadingFilterSearchButton ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Search"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>
      {/*Filter modal ends*/}

      {/*Pickup modal starts*/}
      <StyledModal
        open={isPickupOpen}
        onClose={handleClosePickupOptions}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={pickupBoxStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="trip-pickup-modal-head"
                style={{ fontSize: "16px", fontWeight: "600" }}
              >
                <div> Trip Pickup </div>{" "}
                <div style={{ marginLeft: "4px" }}>
                  {" "}
                  ( Trip code : {selectedTrip?.trip_code} )
                </div>
              </div>
              <div>
                <Close
                  onClick={handleClosePickupOptions}
                  style={{ cursor: "pointer" }}
                />{" "}
              </div>
            </div>
            <TripPickup
              tripId={selectedTrip?.id}
              tripCode={selectedTrip?.trip_code}
            />
          </div>
        </Box>
      </StyledModal>
      {/*Pickup modal ends*/}

      {/*Trip routes modal starts*/}
      <StyledModal
        open={openTripRoutes}
        onClose={handleCloseTripRoute}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={tripRoutesBoxStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="trip-pickup-modal-head"
                style={{ fontSize: "16px", fontWeight: "600" }}
              >
                <div> Trip Routes </div>{" "}
                <div style={{ marginLeft: "4px" }}>
                  {" "}
                  ( Trip code : {selectedTrip?.trip_code} )
                </div>
              </div>
              <div>
                <Close
                  onClick={handleCloseTripRoute}
                  style={{ cursor: "pointer" }}
                />{" "}
              </div>
            </div>
            <div>
              <div className="trip-list-address">
                <div className="trip-location-list">
                  {selectedTrip.source?.map((source) => {
                    return <div className="trip-location-text">From</div>;
                  })}
                  {/* <div className="trip-location-text">From</div> */}
                  {selectedTrip.destination?.map((destinations) => {
                    return <div className="trip-location-text">To</div>;
                  })}
                </div>
                <div className="trip-list-path">
                  {/* <div className="trip-circle"></div>
                  <div className="trip-rectangle"></div> */}
                  {selectedTrip.source?.map((source, index) => {
                    return (
                      <>
                        {index == 0 ? null : (
                          <>
                            <div className="trip-circle"></div>
                            <div className="mini-trip-rectangle"></div>
                          </>
                        )}
                      </>
                    );
                  })}
                  {selectedTrip.destination?.map((destinations) => {
                    return (
                      <>
                        <div className="trip-circle"></div>
                        <div className="mini-trip-rectangle"></div>
                      </>
                    );
                  })}
                  <div className="trip-circle"></div>
                </div>
                <div className="trip-location-list">
                  {/* <div className="trip-location-text"> */}
                  {selectedTrip.source?.map((source) => {
                    return (
                      <div className="trip-location-text">
                        {source.village === source.district
                          ? source.village
                          : `${source.village},
                          ${source.district}`}{" "}
                        , {source.state}, {source.postcode}
                      </div>
                    );
                  })}

                  {selectedTrip.destination?.map((destinations) => {
                    return (
                      <div className="trip-location-text">
                        {destinations.village === destinations.district
                          ? destinations.district
                          : `${destinations.village}, ${destinations.district}`}
                        , {destinations.state}, {destinations.postcode}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>
      {/*Trip routes modal ends*/}

      {/* customer_name
      created_by
      load_source
      trip_status
      payment_status
      trip_cost
      item_sku
      reference_number
      vehicle_number
      vehicle_payout_period
      transporter_name
      destination_state
      source_state
      from_date
      to_date */}

      {/*Export report modal starts*/}
      <StyledModal
        open={isExportOpen}
        onClose={handleCloseExport}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={exportBoxStyle}>
          <div className="trip-list-export-main">
            <div
              className="trip-list-filter-head"
              style={{ margin: "5px 0px" }}
            >
              <span className="trip-list-filter-main-text">
                Trip Export Report
              </span>{" "}
              <Close
                onClick={handleCloseExport}
                style={{ cursor: "pointer" }}
              />
            </div>

            <div className="trip-list-export-container">
              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Email Id : </h4>
                  <TextField
                    id="standard-basic"
                    style={{ width: "98%" }}
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    placeholder="Enter email address"
                    className="trip-list-export-inputfield"
                    value={exportEmail}
                    onChange={(e) => setExportEmail(e.target.value)}
                  />
                  <div className="trip-list-error">
                    {validEmailRegex.test(exportEmail)
                      ? null
                      : errExportEmailId}
                  </div>
                </div>
              </div>
              <div className="trip-list-export-options-head">
                <h4
                  className="trip-list-export-text"
                  style={{ fontSize: "15px" }}
                >
                  Filter Options (Optional)
                </h4>
              </div>
              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">From Date</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    type="date"
                    // placeholder="Enter payment status"
                    className="trip-list-export-inputfield"
                    value={exportFromDate}
                    onChange={(e) => setExportFromDate(e.target.value)}
                  />
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">To Date</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    size="small"
                    type="date"
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    value={exportToDate}
                    onChange={(e) => setExportToDate(e.target.value)}
                  />
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Trip status</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    placeholder="Enter trip status"
                    variant="outlined"
                    className="trip-list-export-inputfield"
                    select
                    size="small"
                    value={exportTripStatus}
                    onChange={(e) => setExportTripStatus(e.target.value)}
                  >
                    <MenuItem disabled value={"0"}>
                      Select
                    </MenuItem>
                    {tripStatusList.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Customer</h4>{" "}
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    defaultValue={
                      Object.keys(filterCustomerData).length > 0
                        ? filterCustomerData
                        : { name: "" }
                    }
                    onChange={(event, newValue) => {
                      // setValue(newValue);
                      if (newValue) {
                        setExportCustomer(newValue?.name);
                        setExportCustomerSearchValue(newValue.name);
                        setCustomerList([]);
                        // if (!newValue.name.toLowerCase().includes("faarms")) {
                        //   setLoadSource("manual");
                        // }
                        //
                      } else {
                        // setSelectedCustomer({});
                      }
                      //
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        setExportCustomerSearchValue(newInputValue);
                      }
                    }} // sx={{ width: 300 }}
                    style={{ width: "90%" }}
                    // sx={{ padding: "0px" }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                  {/* <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    select
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    size="small"
                    value={exportCustomer}
                    onChange={(e) => setExportCustomer(e.target.value)}
                  >
                    <MenuItem disabled value={"0"}>
                      Select
                    </MenuItem>
                    {customerList.length > 0
                      ? customerList.map((item) => {
                          return (
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                          );
                        })
                      : null}
                  </TextField> */}
                </div>
              </div>
              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Vehicle number</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    placeholder="Enter vehicle number"
                    className="trip-list-export-inputfield"
                    value={exportVehicleNumber}
                    onChange={(e) => setExportVehicleNumber(e.target.value)}
                  />
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Transporter</h4>{" "}
                  <AsyncSelect
                    className="trip-list-export-inputfield"
                    defaultOptions
                    // key={loadSource}
                    placeholder="search..."
                    loadOptions={(inputValue) => transporterOptions(inputValue)}
                  // value={
                  //   vehicleNumber !== "0"
                  //     ? { value: vehicleNumber, label: vehicleNumber }
                  //     : ""
                  // }
                  // value={vehicleOptions.filter(
                  //   (option) => option.value === vehicleNumber
                  // )}
                  // value={purchaseOrderId}
                  // onInputChange={(newValue) =>
                  //   console.log("purchase order", newValue)
                  // }
                  // onChange={(e) => {
                  //   setVehicleNumber(e.value);
                  //   setVehiclePayoutPeriod(e.payout_period);
                  //   if (e.payout_period == "monthly_wise") {
                  //     setPerKmRate(e.extra_per_km_rate);
                  //   } else {
                  //     setPerKmRate("");
                  //   }
                  // }}
                  // loadOptions={(inputValue) =>
                  //   vehicleOptions(inputValue, loadSource)
                  // }
                  />
                  {/* <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    select
                    size="small"
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    value={exportTransporter}
                    onChange={(e) => setExportTransporter(e.target.value)}
                  >
                    <MenuItem>dnnsd</MenuItem>
                  </TextField> */}
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">
                    Vehicle payout period
                  </h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip status"
                    variant="outlined"
                    className="trip-list-export-inputfield"
                    select
                    size="small"
                    value={exportVehiclePayoutPeriod}
                    onChange={(e) =>
                      setExportVehiclePayoutPeriod(e.target.value)
                    }
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {vehiclePayoutPeriodList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Payment Status</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    select
                    size="small"
                    value={exportPaymentStatus}
                    onChange={(e) => setExportPaymentStatus(e.target.value)}
                  >
                    <MenuItem value="0" disabled>
                      Select
                    </MenuItem>
                    {tripPaymentStatusList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Source state</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    select
                    // placeholder="Enter payment status"
                    className="trip-list-export-inputfield"
                    value={exportSourceState}
                    onChange={(e) => setExportSourceState(e.target.value)}
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {stateList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Destination state</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    size="small"
                    select
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    value={exportDestinationState}
                    onChange={(e) => setExportDestinationState(e.target.value)}
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {stateList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Created By</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    placeholder="Enter created by"
                    variant="outlined"
                    className="trip-list-export-inputfield"
                    size="small"
                    value={exportCreatedBy}
                    onChange={(e) => setExportCreatedBy(e.target.value)}
                  />
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Load Source</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    size="small"
                    select
                    value={exportLoadSource}
                    onChange={(e) => setExportLoadSource(e.target.value)}
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {loadSourceList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>

              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Trip type</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    select
                    // placeholder="Enter payment status"
                    className="trip-list-export-inputfield"
                    value={exportTripType}
                    onChange={(e) => setExportTripType(e.target.value)}
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {tripTypeList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container"></div>
                <div className="trip-list-export-input-container"></div>
                <div className="trip-list-export-input-container"></div>
              </div>
              <div className="trip-list-filter-button-row">
                {errTotalExport ? (
                  <Alert severity="error" style={{ width: "100%" }}>
                    {errTotalExport}
                  </Alert>
                ) : null}
              </div>

              <div className="trip-list-filter-button-row">
                <button
                  className="trip-list-filter-button"
                  onClick={handleCloseExport}
                >
                  Cancel
                </button>
                <button
                  className="trip-list-export-button"
                  onClick={() => {
                    handleTripsExportReportApi();
                  }}
                >
                  {loadingExportButton ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Export"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>
      {/*Export report modal ends*/}

      {/* reject remarks modal starts */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="trip-list-modal-container">
            <div className="trip-list-close">
              <Close style={{ cursor: "pointer" }} onClick={handleClose} />
            </div>
            <h4 className="trip-list-modal-head">
              Please enter the remarks (max. 300 characters) :
            </h4>
            <textarea
              className="trip-list-modal-text-input"
              value={tripRemarks}
              onChange={(e) => {
                if (e.target.value.length <= 300) {
                  setTripRemarks(e.target.value);
                }
              }}
              rows={5}
            />
            <div className="trip-list-error">
              {tripRemarks.trim() ? null : errTripRemarks}
            </div>
            {currentStatusUpdateInProgress == "intransit" ||
              currentStatusUpdateInProgress == "completed" ? (
              <div className="trip-list-modal-attachment-container">
                {attachmentListArray.map((item, index) => {
                  return (
                    <div key={index} className="trip-list-modal-row">
                      <div className="trip-list-modal-input-group">
                        <h4 className="trip-list-modal-head">
                          Document type {index + 1}
                        </h4>
                        {/*new attchment*/}
                        <TextField
                          size="small"
                          id="dropdown"
                          select
                          autoComplete="off"
                          className="trip-list-input-attachment dropdown-container"
                          variant="outlined"
                          placeholder="Enter document type"
                          name="attachment_tag"
                          // disabled={index == 0 ? true : false}
                          value={item.attachment_tag}
                          onChange={(e) => {
                            handleAttachmentChange(e, index);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{
                                  cursor: "pointer",
                                  color: colorPalette.primaryColor,
                                  fontWeight: "600",
                                }}
                              // onClick={handleAddAttachment}
                              >
                                <button className="upload_button">
                                  <input
                                    // name="upload"
                                    name="attachment_file"
                                    id={`upload${index}`}
                                    type="file"
                                    accept="image/png, image/jpeg, application/pdf"
                                    onChange={(e) => {
                                      handleAttachmentChange(e, index);
                                      handleAddAttachment(e);
                                    }}
                                    hidden
                                    style={{
                                      backgroundColor: "#e8f9e0",
                                    }}
                                  />
                                  <label
                                    htmlFor={`upload${index}`}
                                    style={{
                                      backgroundColor: "#e8f9e0",
                                    }}
                                  >
                                    <img
                                      src={UploadImage}
                                      alt=""
                                      width="20px"
                                      height="20px"
                                      style={{
                                        backgroundColor: "#e8f9e0",
                                        // width: "100%",
                                        // height: "100%",
                                      }}
                                    />
                                  </label>
                                </button>
                              </InputAdornment>
                            ),
                          }}
                        >
                          <MenuItem value="0" disabled>
                            Select document type
                          </MenuItem>
                          {/* {console.log("load unit", loadUnitList)} */}
                          {documentTypeList.map((item) => {
                            return (
                              <MenuItem value={item}>
                                {item
                                  .split("_")
                                  .join(" ")
                                  .split("")
                                  .map((item, index) => {
                                    if (index == 0) {
                                      return item.toUpperCase();
                                    } else {
                                      return item;
                                    }
                                  })
                                  .join("")}
                              </MenuItem>
                            );
                          })}
                        </TextField>

                        <div className="trip-list-attachment-name">
                          {item.attachment_file != null
                            ? item.attachment_file.name
                            : null}
                        </div>
                        <div className="error-trip">
                          {item.attachment_file
                            ? item.attachment_file.size >
                              process.env.REACT_APP_MAX_FILE_SIZE
                              ? `File size is too large.( < ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                              : null
                            : null}
                        </div>
                        {/*new attchment*/}
                      </div>
                    </div>
                  );
                })}
                {isMobile ? null : (
                  <div className="trip-list-modal-row">
                    <div className="trip-list-modal-input-group"></div>
                    <div className="trip-list-modal-input-group"></div>
                    <div className="trip-list-modal-input-group"></div>
                  </div>
                )}
              </div>
            ) : null}
            {errTripStatus ? (
              <Alert severity="error">{errTripStatus}</Alert>
            ) : null}

            <div className="trip-list-modal-btn-grp">
              {" "}
              <button
                className="trip-list-submit-btn"
                onClick={handleSubmitRemarks}
              >
                {loadingModal ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </Box>
      </Modal>
      {/* reject remarks modal ends */}
      <div className="trip-list-right-container">
        {isMobile ? null : showNewFeaturePopUp == false ? null : (
          <Stack
            sx={{
              width: "100%",
              height: "10px",
              padding: "0px 0px",
              marginBottom: "2rem",
              display: openAlert == true ? "block" : "none",
              // position: "absolute",
              // top: "0",
              // left: "0",
              // right: "0",
            }}
            spacing={2}
          // style={{ position: "sticky" }}
          >
            <Collapse in={openAlert}>
              <Alert
                style={{ backgroundColor: "#fde989", padding: "0rem 2rem" }}
                // severity="FDE989"
                // variant="filled"
                // severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                      setPopUpHide(
                        localStorage.setItem("opneNewFeature", false)
                      );
                    }}
                  >
                    <CloseIcon
                      fontSize="inherit"
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "#f9f9f9",
                      }}
                    />
                  </IconButton>
                }
                sx={{ padding: "0 2" }}
              >
                <span style={{ color: "black" }}>Experience our new</span>
                <NavLink
                  style={{
                    color: "#379feb",
                    marginLeft: ".3rem",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    localStorage.setItem("FlagForApiCall", false);
                  }}
                  to="/ftl-trips"
                >
                  Trips
                </NavLink>{" "}
                <span style={{ color: "black" }}>Page</span>
              </Alert>
            </Collapse>
          </Stack>
        )}

        <div className="trip-list-inner-left">
          <div
            className="trip-list-whole"
            style={
              Object.keys(selectedTrip).length > 0 || isMobile
                ? { width: "100%" }
                : { width: "90%" }
            }
          >
            <div className="trip-list-search-row">
              <div className="trip-list-search-container">
                <input
                  className="trip-list-search"
                  placeholder="search trip code"
                  value={tripSearchValue}
                  onChange={(event) => {
                    dispatch(updateTripSearchValue(event.target.value));
                    setCurrentPage(1);
                  }}
                />
                {filterTripCode ||
                  filterPaymentStatus ||
                  filterVehicleNumber ||
                  filterTripStatus ||
                  filterReferenceNumber ||
                  filterCustomerId ||
                  filterLoadSource ||
                  filterEwayBillNo ||
                  filterTripType ? (
                  <span
                    style={{
                      fontSize: "10px",
                      color: "red",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Filter applied
                  </span>
                ) : null}

                <img
                  src={FILTER_ICON}
                  onClick={handleOpenFilter}
                  alt=""
                  className="trip-list-filter-icon"
                />

                <Search />
              </div>
              <div className="trip-list-button-container">
                <ButtonRectangleSecondary
                  onClick={handleOpenExportReport}
                  className="export-report-trip-button"
                >
                  {/* <div className="export-button-inner"> */}
                  <span className="export-button-inner">
                    <Download style={{ fontSize: "20px" }} />
                    Export
                  </span>
                  {/* </div> */}
                </ButtonRectangleSecondary>

                <ButtonRectangle
                  onClick={handleCreateNewTripButton}
                  className="create-new-trip-button"
                >
                  Create trip
                </ButtonRectangle>
                <a
                  className="coverage-button"
                  target="_blank"
                  href="/analytics"
                >
                  <img src={mapIcon} width={"20px"} height={"20px"} />
                </a>
              </div>
              {/* <a href="#selectedIndexTrip">
                {" "}
                <button>GO TO SELECTED TRIP</button>
              </a> */}
            </div>
            {/* <div className="create-new-trip-row"> */}

            {/* </div> */}
            {loading ? (
              <div
                style={{
                  display: "flex",

                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  // backgroundColor: "grey",
                }}
                className="trip-list-middle-container"
              >
                {" "}
                <img
                  src={success}
                  alt=""
                  width="120px"
                  height="90px"
                  style={{ marginTop: "200px" }}
                />
                <h6 style={{ color: "grey" }}>loading data...</h6>
              </div>
            ) : allTripList.length !== 0 ? (
              <div className="trip-list-middle-container">
                {allTripList.map((trip, index) => {
                  return (
                    <div
                      className="tl-new-single-container"
                      id={
                        index == selectedTripIndex
                          ? "selectedIndexTrip"
                          : `null${index}`
                      }
                      style={{
                        border:
                          selectedTripIndex == index
                            ? "2px solid #016938"
                            : null,
                        backgroundColor:
                          trip.trip_payment_status === "paid"
                            ? "#E1EDDB"
                            : null,
                      }}
                      onClick={() => handleSelectTrip(trip, index)}
                      ref={selectedTripIndex == index ? myRef : null}
                    >
                      <div className="tl-new-main-left">
                        <div className="tl-new-left">
                          <div className="tl-new-inner-div">
                            <h4 className="tl-new-tripcode">
                              {trip.trip_code ? (
                                <CopyText text={trip.trip_code} />
                              ) : (
                                ""
                              )}
                            </h4>
                          </div>
                          <div className="tl-new-inner-div">
                            <h4
                              className="tl-new-vehicle"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <LocalShippingOutlinedIcon
                                style={{ color: colorPalette.primaryColor }}
                              />{" "}
                              &nbsp;
                              {"   "}
                              {trip.vehicle_number
                                ? trip.vehicle_number
                                : " - "}{" "}
                              <span className="tl-new-payterm">
                                (
                                {trip.vehicle_payout_period
                                  ? trip.vehicle_payout_period.split("_")[0]
                                  : ""}
                                )
                              </span>
                            </h4>
                          </div>
                          <div className="tl-new-inner-div">
                            <h4 className="tl-new-vehicle">
                              <span style={{ fontWeight: "500" }}>
                                {" "}
                                consignor :{" "}
                              </span>
                              {trip.customers_details?.name
                                ? trip.customers_details?.name
                                : " - "}{" "}
                            </h4>
                          </div>
                          <div className="tl-new-inner-div">
                            <h4 className="tl-new-vehicle">
                              <span style={{ fontWeight: "500" }}>
                                {" "}
                                consignee :{" "}
                              </span>
                              {trip.consignee_details?.name
                                ? trip.consignee_details.name
                                : " - "}{" "}
                            </h4>
                          </div>
                          <div className="tl-new-inner-div">
                            <h4 className="tl-new-vehicle">
                              <span style={{ fontWeight: "500" }}>
                                {" "}
                                Bill To :{" "}
                              </span>
                              {trip.bill_to_party_details?.name
                                ? trip.bill_to_party_details.name
                                : " - "}{" "}
                            </h4>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                            className="tl-new-inner-div"
                          >
                            <h4 className="tl-new-payterm">
                              {trip.destination.length > 0
                                ? trip.destination.length == 1 &&
                                  trip.source.length == 1
                                  ? "Single stop"
                                  : "Multiple stop"
                                : null}
                            </h4>
                            <div
                              onClick={(e) =>
                                handleOpenTripRoute(e, trip, index)
                              }
                            >
                              <img
                                style={{ cursor: "pointer", marginLeft: "8px" }}
                                src={INFOICON}
                                alt="info icon"
                              />
                            </div>
                          </div>
                          <div className="tl-new-inner-div">
                            <h4
                              className="tl-new-vehicle"
                              style={
                                trip.trip_payment_status === "unpaid"
                                  ? { color: "#E80303" }
                                  : trip.trip_payment_status === "paid"
                                    ? { color: "green" }
                                    : { color: "#F3B002" }
                              }
                            >
                              {trip.trip_payment_status
                                .split("_")
                                .join(" ")
                                .toUpperCase()}
                            </h4>
                          </div>
                        </div>
                        <div className="tl-new-middle">
                          <div className="tl-new-inner-div">
                            {allTripList.length == index + 1 ? (
                              <h4 className="tl-new-status" ref={lastItem}>
                                <span
                                  style={{
                                    color: "white",
                                    padding: "2px",
                                    background:
                                      trip.trip_status === "completed"
                                        ? colorPalette.primaryColor
                                        : trip.trip_status === "cancelled"
                                          ? "red"
                                          : "#65c368",
                                    borderRadius: "2px",
                                  }}
                                >
                                  {trip.trip_status.toUpperCase()}{" "}
                                </span>
                                <span
                                  style={{
                                    color: "white",
                                    padding: "2px",
                                    background: "orange",
                                    borderRadius: "2px",
                                  }}
                                >
                                  {trip.trip_type
                                    ? trip.trip_type
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()
                                    : trip.vehicle_freight_type
                                      ? trip.vehicle_freight_type.toUpperCase()
                                      : " - "}
                                </span>
                              </h4>
                            ) : (
                              <h4 className="tl-new-status">
                                <span
                                  style={{
                                    color: "white",
                                    padding: "2px",
                                    background:
                                      trip.trip_status === "completed"
                                        ? colorPalette.primaryColor
                                        : trip.trip_status === "cancelled"
                                          ? "red"
                                          : "#65c368",
                                    borderRadius: "2px",
                                  }}
                                >
                                  {trip.trip_status.toUpperCase()}{" "}
                                </span>
                                <span
                                  style={{
                                    color: "white",

                                    padding: "2px",
                                    background: "orange",
                                    borderRadius: "2px",
                                  }}
                                >
                                  {trip.trip_type
                                    ? trip.trip_type
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()
                                    : trip.vehicle_freight_type
                                      ? trip.vehicle_freight_type.toUpperCase()
                                      : " - "}
                                </span>
                              </h4>
                            )}
                          </div>

                          {/* <div className="tl-new-inner-div">
                            <h4 className="tl-new-modifiedby">
                              Per bag cost :
                              <span style={{ fontWeight: "600" }}>
                                {" "}
                                Rs.{" "}
                                {trip.trip_cost.filter(
                                  (item) => item.cost_type === "trip_cost"
                                ).length > 0
                                  ? trip.unloaded_quantity
                                    ? (
                                        trip.trip_cost.filter(
                                          (item) =>
                                            item.cost_type === "trip_cost"
                                        )[0].cost_amount /
                                        trip.unloaded_quantity
                                      ).toFixed(2)
                                    : (
                                        trip.trip_cost.filter(
                                          (item) =>
                                            item.cost_type === "trip_cost"
                                        )[0].cost_amount / trip.quantity
                                      ).toFixed(2)
                                  : " - "}
                              </span>
                            </h4>
                          </div> */}

                          <div className="tl-new-inner-div">
                            <h4 className="tl-new-modifiedby">
                              Billed Amount :{" "}
                              <span style={{ fontWeight: "600" }}>
                                {trip?.trip_customer_invoice?.length > 0
                                  ? `Rs. ${trip.trip_customer_invoice[0]?.customer_billed_amount}`
                                  : " - "}
                              </span>
                            </h4>
                          </div>
                          <div className="tl-new-inner-div">
                            <h4 className="tl-new-modifiedby">
                              Customer Advance :{" "}
                              <span style={{ fontWeight: "600" }}>
                                {trip?.trip_customer_invoice?.length > 0
                                  ? `Rs. ${trip.trip_customer_invoice[0]?.advance_paid_by_customer}`
                                  : " - "}
                              </span>
                            </h4>
                          </div>
                          <div className="tl-new-inner-div">
                            <h4 className="tl-new-modifiedby">
                              Transport cost :{" "}
                              <span style={{ fontWeight: "600" }}>
                                {trip?.trip_cost?.length > 0
                                  ? trip?.trip_cost.filter(
                                    (item) => item.cost_type === "trip_cost"
                                  ).length > 0
                                    ? `Rs. ${trip?.trip_cost.filter(
                                      (item) =>
                                        item.cost_type === "trip_cost"
                                    )[0].cost_amount
                                    }`
                                    : " - "
                                  : " - "}
                              </span>
                            </h4>
                          </div>
                          <div className="tl-new-inner-div">
                            <h4 className="tl-new-modifiedby">
                              Transport advance :{" "}
                              <span style={{ fontWeight: "600" }}>
                                {trip?.trip_cost?.length > 0
                                  ? trip?.trip_cost.filter(
                                    (item) => item.cost_type === "advance"
                                  ).length > 0
                                    ? `Rs. ${trip?.trip_cost.filter(
                                      (item) => item.cost_type === "advance"
                                    )[0].cost_amount
                                    }`
                                    : " - "
                                  : " - "}
                              </span>
                            </h4>
                          </div>
                          <div className="tl-new-inner-div">
                            <h4 className="tl-new-modifiedby">
                              Payment Terms :{" "}
                              <span style={{ fontWeight: "600" }}>
                                {trip.payment_terms
                                  ? trip.payment_terms.includes("to_pay")
                                    ? "TO PAY"
                                    : trip.payment_terms
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()
                                  : " - "}
                              </span>
                            </h4>
                          </div>
                          {/* <div className="tl-new-inner-div">
                            <h4 className="tl-new-modifiedby">
                              {trip.trip_status
                                .split("")
                                .map((item, index) => {
                                  if (index == 0) {
                                    return item.toUpperCase();
                                  } else {
                                    return item;
                                  }
                                })
                                .join("") === "Intransit"
                                ? "Started"
                                : trip.trip_status
                                    .split("")
                                    .map((item, index) => {
                                      if (index == 0) {
                                        return item.toUpperCase();
                                      } else {
                                        return item;
                                      }
                                    })
                                    .join("")}{" "}
                              by :{" "}
                              {trip.last_modified_by
                                ? trip.last_modified_by
                                : " - "}
                            </h4>
                          </div> */}
                          <div className="tl-new-inner-div">
                            <h4 className="tl-new-modifiedby">
                              Created Date :{" "}
                              {trip.created_on
                                ? moment
                                  .utc(trip.created_on)
                                  .local()
                                  .format("DD-MM-YYYY hh:mm a")
                                : " - "}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="tl-new-right">
                        {trip.trip_status == "created" ? (
                          <div className="trip-list-right-most-single-container">
                            <div
                              onClick={() => handleEditTrip(trip)}
                              className="trip-list-right-most-edit"
                            >
                              <Edit />
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={(e) => handleApproveTrip(trip, e)}
                              >
                                Approve
                              </button>
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-reject-btn"
                                onClick={(e) => handleRejectTrip(trip, e)}
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                        ) : (trip.trip_status == "created" ||
                          trip.trip_status == "reopen") &&
                          userRole == "Logistics Incharge" ? (
                          <div
                            style={{ display: "flex" }}
                            className="trip-list-waiting"
                          >
                            <h5 style={{ color: "grey" }}>
                              waiting for the approval
                            </h5>
                          </div>
                        ) : trip.trip_status == "rejected" ? (
                          <div className="trip-list-right-most-single-container">
                            <div
                              onClick={() => handleEditTrip(trip)}
                              className="trip-list-right-most-edit"
                            >
                              <Edit />
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={(e) => handleReopenTrip(trip, e)}
                              >
                                Reopen
                              </button>
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-reject-btn"
                                onClick={(e) => handleCancelTrip(trip, e)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : trip.trip_status == "reopen" ? (
                          <div className="trip-list-right-most-single-container">
                            <div
                              onClick={() => handleEditTrip(trip)}
                              className="trip-list-right-most-edit"
                            >
                              <Edit />
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={(e) => handleApproveTrip(trip, e)}
                              >
                                Approve
                              </button>
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-reject-btn"
                                onClick={() => handleRejectTrip(trip)}
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                        ) : trip.trip_status == "approved" ? (
                          <div className="trip-list-right-most-single-container">
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={(e) => handleStartTrip(trip, e)}
                              >
                                Start
                              </button>
                            </div>

                            {trip.load_source === "trip_invoice" &&
                              (trip?.trip_entity_mapping_details?.length > 0
                                ? trip?.trip_entity_mapping_details
                                  .map((item) => item.status === "picked")
                                  .indexOf(false) >= 0
                                : false) ? (
                              <div className="trip-list-right-most-btn-container">
                                <button
                                  className="trip-list-reject-btn"
                                  onClick={(e) =>
                                    handlePickupTrip(e, trip, index)
                                  }
                                >
                                  Pickup
                                </button>
                              </div>
                            ) : null}
                          </div>
                        ) : trip.trip_status == "intransit" ? (
                          <div className="trip-list-right-most-single-container">
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={() => handleCompleteTrip(trip)}
                              >
                                Complete
                              </button>
                            </div>
                            {trip.load_source === "trip_invoice" &&
                              trip.pending_reference_numbers.length > 0 ? (
                              <div className="trip-list-right-most-btn-container">
                                <button
                                  className="trip-list-reject-btn"
                                  onClick={() =>
                                    handleDeliveryInvoicePayment(trip)
                                  }
                                >
                                  Delivery
                                </button>
                              </div>
                            ) : null}
                            {trip.load_source === "trip_invoice" &&
                              (trip?.trip_entity_mapping_details?.length > 0
                                ? trip?.trip_entity_mapping_details
                                  .map((item) => item.status === "picked")
                                  .indexOf(false) >= 0
                                : false) ? (
                              <div className="trip-list-right-most-btn-container">
                                <button
                                  className="trip-list-reject-btn"
                                  onClick={(e) =>
                                    handlePickupTrip(e, trip, index)
                                  }
                                >
                                  Pickup
                                </button>
                              </div>
                            ) : null}
                          </div>
                        ) : trip.trip_status == "cancelled" ? (
                          <div className="trip-list-right-most-single-container"></div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}

                {isLastPage ? null : (
                  <div className="trip-list-loading-more">
                    Loading more..Please wait..!!!
                  </div>
                )}
              </div>
            ) : (
              <div className="trip-list-middle-container">
                <h4
                  style={{
                    textAlign: "center",
                    marginTop: "30%",
                  }}
                >
                  No Trip Found! Please create one
                </h4>
              </div>
            )}
          </div>
          {isMobile ? (
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={() => toggleDrawer("right", false)}
            >
              <div className="trip-list-drawer-top-row">
                <span className="trip-list-trip-details-drawer-head">
                  Trip details
                </span>
                <Close onClick={handleCloseDetailsDrawer} />
              </div>

              <div className="trip-list-inner-right">
                <MiniDetailsCard tripDetails={selectedTrip} />
              </div>
            </Drawer>
          ) : Object.keys(selectedTrip).length > 0 ? (
            <div className="trip-list-inner-right">
              <MiniDetailsCard tripDetails={selectedTrip} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default TripList;
