import axiosInterceptor from "../../config/https";
import apiUrl from "../../url/apiUrl";

export const generateInvoiceForMultiTripForSameBillToParyId = async (
  id,
  trip_codes
) => {
  const data = {
    bill_to_party_id: id,
    trip_codes_list: trip_codes,
  };

  const response = axiosInterceptor.post(
    `${apiUrl.ftlTripV1BaseUrl}/multi-invoice/generate`,
    data
  );
  return response;
};
