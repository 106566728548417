import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../../themes/theme";

export const FooterStyle = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${colorPalette.white};

  .footer-main {
    width: 100%;
    padding: 40px 0;
  }

  .social_media {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .footer-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading4};
    color: ${colorPalette.paragraphSecondaryolor};
  }

  .address {
    text-align: center;
    margin-top: 20px;
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading5};
    line-height: 21px;
    color: ${colorPalette.paragraphSecondaryolor};
  }

  .footer-copyright {
    text-align: center;
    width: 100%;
    padding: 10px;
    background-color: white;
    font-weight: ${FontWeight.semibold};
    font-size: ${typography.paragraph};
    line-height: 18px;
    color: ${colorPalette.paragraphSecondaryolor};
  }
`;
