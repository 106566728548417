import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  indentId: {},
  flagOn: false,
  flagOnUpdate: false,
};

const indentSlice = createSlice({
  name: "indent",
  initialState,
  reducers: {
    indentId(state, action) {
      state.indentId = action.payload;
    },
    updateOnFlag(state, action) {
      state.flagOn = action.payload;
    },
    updateOnFlagUpdate(state, action) {
      state.flagOnUpdate = action.payload;
    },
  },
});

export const { indentId, updateOnFlag, updateOnFlagUpdate } =
  indentSlice.actions;
export default indentSlice.reducer;
