import styled from "styled-components";
import {
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";

export const Mini_details_carrier_card_main_container_payments = styled.div`
  /* .mini-details-carrier-card-main-container_payments { */
  width: 400px;
  height: 85vh;
  background-color: ${colorPalette.white};
  /* margin: 3% auto; */
  /* background: ${colorPalette.white}; */
  box-shadow: ${colorPalette.boxShadowPrimary};
  border-radius: 14px;
  /* } */
  .mini-details-carrier-img-payments {
    height: 10%;
  }
  .bank_holder {
    display: flex;
    align-items: center;
    justify-content: center;
    /* justify-content: flex-start; */
  }
  .mini-details-carrier-tab-container {
    /* background-color: red; */
    overflow: scroll;
    position: relative;
    height: 40vh;
    /* width: 100%; */
  }
  .accodion_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
  }
  .mini-details-carrier-tab-container-bank {
    /* background-color: red; */
    overflow: scroll;
    position: relative;
    height: 51vh;
    /* width: 100%; */
  }
  .bank_container {
    width: 28vw;
    height: 100%;
  }
  .bank_wrapper {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    /* background-color: red; */
  }
  .total_paid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
    height: 10%;
    padding: 0.5rem 0.2rem;
    margin-top: 0.5rem;
    box-shadow: ${colorPalette.boxShadowPrimary};
  }
  .bank_headings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 2rem 0rem;
  }
  .bank-input {
    margin: 10px 0px;
    width: 90%;
  }
  .bankDetails {
    width: 120%;
    /* padding: .5rem 0rem; */
    /* background-color: blueviolet; */
  }
  .bank_button {
    padding: 0.8rem 3rem;
    width: 90%;
    margin: 2rem auto;
    background-color: ${colorPalette.primaryColor};
    border: none;
    outline: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: ${colorPalette.white};
    cursor: pointer;
  }
  .bank_button_add {
    padding: 0.8rem 1rem;
    width: 100%;
    /* margin-top: -5rem; */
    background-color: ${colorPalette.primaryColor};
    border: none;
    outline: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: ${colorPalette.white};
    cursor: pointer;
  }
  .bank-drawer-row {
    padding: 0.5rem 0rem;
  }
  .attachment {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .attachment_btn {
    border: 1px solid ${colorPalette.primaryColor};
    text-decoration: none;
    padding: 0.3rem 1rem;
    color: ${colorPalette.primaryColor};
    transition: ease all 0.5s;
    display: flex;
    align-items: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  .attachment_btn:hover {
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    transition: ease all 0.5s;
  }
  .mini-details-carrier-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #c7c7c7; */
    height: 30%;
  }

  .MuiTabs-indicator {
    background-color: ${colorPalette.primaryColor} !important;
  }
  .mini-details-carrier-single-list {
    margin: 1.5rem 5px;
    display: flex;
    overflow-wrap: hidden;
    /* background-color: rebeccapurple; */
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: ${typography.paragraph} !important;
    font-weight: ${FontWeight.semibold} !important;
  }
  .mini-details-carrier-single-list-left {
    font-size: ${typography.heading5};
    margin: 0px 5px;
    position: relative;
  }
  .mini-details-carrier-single-list-right {
    font-size: ${typography.heading5};
    margin: 0px 5px;
    position: absolute;
    width: 100%;
    color: ${colorPalette.paragraphSecondaryolor};
    left: 45%;
    /* word-wrap: break-word; */
    /* overflow: hidden; */
    /* overflow-wrap: break-word; */
  }
  .payment_details {
    width: 100%;
    margin: 0 auto;
    padding: 0.5rem 0.2rem;
    /* height: 100%; */
    height: 430px;
    overflow: scroll;
    /* height: 100px; */
    /* background-color: ${colorPalette.primaryColor}; */
    /* overflow-y: scroll; */
  }
  .payments_terms {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .monthly_payment_details_tab {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .trip_cost_details {
    width: 98%;
    overflow: scroll;
    height: 200px;
    /* height: 40%; */
    margin: 0 auto;
    display: flex;
    background-color: ${colorPalette.secondaryWhite};

    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0.5rem;
  }
  .trip_cost_details_monthly {
    width: 98%;
    /*overflow: scroll;
  */
    height: 150px;
    /*height: 40%;
  */
    margin: 0 auto;
    display: flex;
    border-radius: 10px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    background-color: ${colorPalette.secondaryWhite};
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0.5rem;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .trip_total_headings {
    display: flex;
    width: 100%;
    /* height: 8%; */
    /* background-color: ${colorPalette.primaryColor}; */
    align-items: center;
    justify-content: flex-start;
    padding: 0rem 0rem 1rem 0rem;
  }
  .trip_breakups {
    display: flex;
    width: 100%;
    /* height: 70%; */
    padding: 0.5rem 0rem;
    /* background-color: ${colorPalette.primaryColor}; */
    align-items: flex-start;
    flex-direction: column;
  }
  .break_ups {
    display: flex;
    align-items: center;
    width: 100%;
    /* height: 100%; */
    /* background-color: ${colorPalette.primaryColor}; */
    padding: 0.3rem 0rem;
    justify-content: center;
  }
  .payment-warapper {
    width: 98%;
    height: 50vh;
    /* background-color: ${colorPalette.primaryColor}; */
    /* padding: 1rem 0rem; */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: center; */
  }
  .payment_values_monthly {
    position: absolute;
    left: 12rem;
    /* background-color: red; */
    /* margin-left: 10rem; */
    color: grey;
  }
  @media only screen and (max-width: 480px) {
    .bank_container {
      width: 100vw;
      height: 100%;
    }
    .mini-details-carrier-card-main-container {
      width: 96%;
      height: 0%;
      margin: 5% auto;
      background: ${colorPalette.white};
      box-shadow: ${colorPalette.boxShadowPrimary};
      border-radius: 14px;
    }
    .payment-warapper {
      width: 98%;
      height: 100%;
      /* padding: 1rem 0rem; */
      /* display: flex; */
      /* flex-direction: column; */
      /* align-items: center; */
      /* justify-content: center; */
    }
    .bank_holder {
      display: flex;
      align-items: center;
      justify-content: center;
      /* justify-content: flex-start; */
    }
    .payment_values_monthly {
      position: absolute;
      left: 10rem;
      /* background-color: red; */
      /* margin-left: 10rem; */
      color: ${colorPalette.paragraphColor};
    }
    .mini-details-carrier-tab-container {
      /* background-color: red; */
      overflow: scroll;
      position: relative;
      height: 57vh;
      /* width: 100%; */
    }
    .accodion_heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
    }
    .mini-details-carrier-tab-container-bank {
      /* background-color: red; */
      overflow: scroll;
      position: relative;
      height: 51vh;
      /* width: 100%; */
    }
    .bank_container {
      width: 28vw;
      height: 100%;
    }
    .bank_wrapper {
      width: 90%;
      height: 100%;
      margin: 0 auto;
      /* background-color: red; */
    }
    .total_paid {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 95%;
      margin: 0 auto;
      height: 10%;
      padding: 0.5rem 0.2rem;
      margin-top: 0.5rem;
      box-shadow: ${colorPalette.boxShadowPrimary};
    }
    .bank_headings {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 2rem 0rem;
    }
    .bank-input {
      margin: 10px 0px;
      width: 90%;
    }
    .bankDetails {
      width: 120%;
      /* padding: .5rem 0rem; */
      /* background-color: blueviolet; */
    }
    .bank_button {
      padding: 0.8rem 3rem;
      width: 90%;
      margin: 2rem auto;
      background-color: ${colorPalette.primaryColor};
      border: none;
      outline: none;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      color: ${colorPalette.white};
      cursor: pointer;
    }
    .bank_button_add {
      padding: 0.8rem 1rem;
      width: 100%;
      /* margin-top: -5rem; */
      background-color: ${colorPalette.primaryColor};
      border: none;
      outline: none;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      color: ${colorPalette.white};
      cursor: pointer;
    }
    .bank-drawer-row {
      padding: 0.5rem 0rem;
    }
    .attachment {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .attachment_btn {
      border: 1px solid ${colorPalette.primaryColor};
      text-decoration: none;
      padding: 0.3rem 1rem;
      color: ${colorPalette.primaryColor};
      transition: ease all 0.5s;
      display: flex;
      align-items: center;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }
    .attachment_btn:hover {
      background-color: ${colorPalette.primaryColor};
      color: ${colorPalette.white};
      transition: ease all 0.5s;
    }
    .mini-details-carrier-img {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      /* background-color: #c7c7c7; */
      height: 30%;
    }

    .MuiTabs-indicator {
      background-color: ${colorPalette.primaryColor};
    }
    .mini-details-carrier-single-list {
      margin: 1.5rem 5px;
      display: flex;
      overflow-wrap: hidden;
      /* background-color: rebeccapurple; */
    }
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
      font-size: ${typography.paragraph_secondary} !important;
      font-weight: ${FontWeight.semibold} !important;
    }
    .mini-details-carrier-single-list-left {
      font-size: ${typography.paragraph};
      margin: 0px 5px;
      position: relative;
    }
    .mini-details-carrier-single-list-right {
      font-size: ${typography.paragraph_secondary};
      margin: 0px 5px;
      position: absolute;
      color: ${colorPalette.paragraphSecondaryolor};
      left: 55%;
    }
  }
  .payments_terms {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .trip_cost_details {
    width: 98%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    background-color: ${colorPalette.secondaryWhite};

    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 0.5rem;
  }
  .trip_total_headings {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 0rem 0rem 1rem 0rem;
  }
  .trip_breakups {
    display: flex;
    width: 100%;
    padding: 0.5rem 0rem;
    align-items: flex-start;
    flex-direction: column;
  }
  .total_paid_monthly {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 98%;
    margin: 0 auto;
    height: 50%;
    border-radius: 10px;
    padding: 0.5rem 0.2rem;
    margin-top: 0.5rem;
    box-shadow: ${colorPalette.boxShadowPrimary};
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .trip_breakups_monthly {
    display: flex;
    width: 100%;
    padding: 0.5rem 0rem;
    align-items: flex-start;
    flex-direction: column;
    /* border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px; */
    padding: 0.2rem 0.5rem;
    /* background-color: #73d1a5; */
  }
  .break_ups {
    display: flex;
    align-items: center;
    width: 98%;
    margin: 0 auto;
    padding: 0.3rem 0rem;
    justify-content: space-between;
  }
  @media only screen and (max-width: 480px) {
    .bank_container {
      width: 100vw;
      height: 100%;
    }
    .mini-details-carrier-card-main-container {
      width: 96%;
      height: 0%;
      margin: 5% auto;
      background: ${colorPalette.white};
      box-shadow: ${colorPalette.boxShadowPrimary};
      border-radius: 14px;
      margin-left: 1.2rem;
    }
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
      font-size: ${typography.paragraph_secondary} !important;
      font-weight: ${FontWeight.semibold} !important;
    }
    .mini-details-carrier-single-list-left {
      font-size: ${typography.paragraph};
      margin: 0px 5px;
      position: relative;
    }
    .mini-details-carrier-single-list-right {
      font-size: ${typography.paragraph_secondary};
      margin: 0px 5px;
      position: absolute;
      color: ${colorPalette.paragraphSecondaryolor};
      left: 55%;
    }
  }
`;
