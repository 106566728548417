import React, { useState, useEffect } from "react";
import logo from "../../assets/logo/leap_supply_logo.svg";
import { Menu } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import changePassword from "../../assets/dashboard_icons/change_password.svg";
import logout from "../../assets/dashboard_icons/logout_icon.svg";
import INDENTGRAY from "../../assets/dashboard_icons/indent-gray.svg";
import INDENTWHITE from "../../assets/dashboard_icons/indent-white.svg";
import QUOTEWHITE from "../../assets/dashboard_icons/quote-white.svg";
import QUOTEGRAY from "../../assets/dashboard_icons/quote-gray.svg";
import dashboard from "../../assets/dashboard_icons/new/dashboard icon.svg";
import dashboard_replace from "../../assets/dashboard_icons/replace_icons/dashboard_icon.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, Drawer, Button } from "@mui/material";
import OEM_ICON from "../../assets/dashboard_icons/replace_icons/OEMicon.svg";
import oem_replace from "../../assets/dashboard_icons/new/Oem_icon.svg";
import customer_icon from "../../assets/dashboard_icons/new/customers.svg";
import customer_replace from "../../assets/dashboard_icons/replace_icons/customers_coloured.svg";
import TRIP_ICON from "../../assets/dashboard_icons/trip_icon.svg";
import payments from "../../assets/dashboard_icons/new/payment_inactive_icon.svg";
import payments_replace from "../../assets/dashboard_icons/replace_icons/payment_active.svg";
import trip_replace from "../../assets/dashboard_icons/new/Trip icon.svg";
import VEHICLE_ICON_GREEN from "../../assets/dashboard_icons/vehicle.svg";
import VEHICLE_ICON from "../../assets/dashboard_icons/vehicle_icon.svg";
import vehicle_replace from "../../assets/dashboard_icons/new/Vehicle_icon.svg";
import CARRIER_ICON from "../../assets/dashboard_icons/carrier_icon.svg";
import carrier_replace from "../../assets/dashboard_icons/new/carrier_icon(1).svg";
import CARRIER_ICON_GREEN from "../../assets/dashboard_icons/carrier.svg";
import { NavLink, useHistory } from "react-router-dom";
import { readLoginData } from "../../helper/dataDecryptionHelper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Mobile_header_main_container } from "./mobile-header";

function MobileHeaderCustomer({ headerTitle }) {
  const history = useHistory();
  const [state, setState] = useState({ left: false });
  const [loginData, setLoginData] = useState();
  const [currentPath, setCurrentPath] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  // const handleLogout = () => {
  //   if (
  //     localStorage.getItem("website_url") ===
  //     process.env.REACT_APP_CUSTOMER_WEBSITE_URL
  //   ) {
  //     history.push("/customer/login");
  //     localStorage.clear();
  //     window.location.reload();
  //   } else {
  //     history.push("/login");
  //     localStorage.clear();
  //     window.location.reload();
  //   }
  //   // localStorage.clear();

  //   // history.push("/login");
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenMenu = () => {
    toggleDrawer("left", true);
  };

  useEffect(() => {
    if (history.location.pathname) {
      setCurrentPath(history.location.pathname);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();

    history.push("/Customer/login");
  };

  useEffect(() => {
    if (readLoginData()) {
      setLoginData(readLoginData());
    }
  }, []);

  const handleGoHome = () => {
    history.push("/dashboard");
  };

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };
  const handleClickOpenChangePassword = () => {
    if (
      localStorage.getItem("website_url") === "https://leapsupply.in/customer"
    ) {
      history.push("/customer/change-passwords");
    } else {
      history.push("/change-passwords");
    }
    // history.push("/change-passwords");
  };
  return (
    <>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={() => toggleDrawer("left", false)}
      >
        <div className="sidebar_onboard_container" style={{ width: "70vw" }}>
          <div className="sidebar_onboard_wrapper">
            <div className="sidebar_onboard_Right_box">
              <div className="sidebar-top-container">
                <div className="logo_logistics">
                  <div className="company_name_div" onClick={handleGoHome}>
                    {/* <div className="logo_div">
              <img src={LOGISTICS_FAARMS} alt="Logo" className="logo" />
            </div> */}
                    <img src={logo} alt="Logo" className="logo" />
                  </div>
                </div>

                <div className="onboarding">
                  {/* <div className="sidebar-single-container"> */}
                  {/* <NavLink
                    to="/dashboard"
                    activeClassName="sidebarListItem active"
                    className="link sidebarSingle"

                    // onClick={setImageColor("Carriers")}
                  >
                    {currentPath === "dashboard" ? (
                      <>
                        {" "}
                        <img
                          src={dashboard_replace}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={dashboard}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    )}

                    <h4 className="vehicles">Dashboard</h4>
                  </NavLink> */}

                  {/* <NavLink
                    to="/trips"
                    activeClassName="sidebarListItem active"
                    className="link"
                  >
                    {currentPath === "trips" ? (
                      <>
                        {" "}
                        <img
                          src={trip_replace}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={TRIP_ICON}
                          alt=""
                          style={{ marginLeft: "1rem" }}
                        />
                      </>
                    )}

                    <h4 className="vehicles">Trips</h4>
                  </NavLink> */}
                  <NavLink
                    to="/customer/customer-indents"
                    activeClassName="sidebarListItem active"
                    className="link"

                    // onClick={setImageColor("Trips")}
                  >
                    {currentPath === "/customer/customer-indents" ? (
                      <>
                        {" "}
                        <img
                          src={INDENTWHITE}
                          alt=""
                          style={{
                            marginLeft: "1rem",
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={INDENTGRAY}
                          alt=""
                          style={{
                            marginLeft: "1rem",
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      </>
                    )}

                    <h4 className="vehicles">Indents</h4>
                  </NavLink>
                  <NavLink
                    to="/customer/customer-quotes"
                    activeClassName="sidebarListItem active"
                    className="link"

                    // onClick={setImageColor("Trips")}
                  >
                    {currentPath === "/customer/customer-quotes" ? (
                      <>
                        {" "}
                        <img
                          src={QUOTEWHITE}
                          alt=""
                          style={{
                            marginLeft: "1rem",
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {" "}
                        <img
                          src={QUOTEGRAY}
                          alt=""
                          style={{
                            marginLeft: "1rem",
                            width: "22px",
                            height: "22px",
                          }}
                        />
                      </>
                    )}

                    <h4 className="vehicles">Quotations</h4>
                  </NavLink>
                </div>
              </div>
              <div className="btn_logout">
                <h6 className="user_name">
                  <AccountCircleIcon />
                  <span style={{ marginLeft: "3px" }}>
                    {loginData ? loginData.USER_NAME : "Guest"}
                  </span>
                </h6>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div className="changePassword">
                    <img src={changePassword} alt="" />{" "}
                    <span onClick={handleClickOpenChangePassword}>
                      Change Password
                    </span>
                  </div>
                  <div className="logout-div">
                    <img src={logout} alt="" />{" "}
                    <span onClick={handleLogout}>Logout</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you really want to Logout?"}
        </DialogTitle>
        <DialogContent style={{ width: "70%" }}>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout}>Yes</Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Mobile_header_main_container className="mobile-header-main-container">
        {/* <NavLink exact to={`${routing}`}>
          <ArrowBackIosNewIcon />
        </NavLink> */}
        <Menu
          style={{ margin: "0px 10px" }}
          color="inherit"
          fontSize="large"
          onClick={handleOpenMenu}
        />
        <div className="mobile-header-title">{headerTitle}</div>
      </Mobile_header_main_container>
    </>
  );
}

export default MobileHeaderCustomer;
