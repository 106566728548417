import React, { useRef } from "react";
import { FtlTripListStyle } from "./FtlTripListV3Style";
import FtlTripListV3Card from "./FtlTripListV3Card";

const FtlTripListV3 = ({
  tripList,
  myRef,
  setSelectedTrip,
  selectedTrip,
  toggleDrawerMenu,
}) => {
  console.log("hello from FtlTripListV3 component");
  return (
    <FtlTripListStyle>
      {tripList.map((trip) => (
        <div
          onClick={() => {
            setSelectedTrip(trip);
          }}
        >
          <FtlTripListV3Card
            cardData={trip}
            myRef={myRef}
            selectedTrip={selectedTrip}
            toggleDrawerMenu={toggleDrawerMenu}
          />
        </div>
      ))}
    </FtlTripListStyle>
  );
};

export default FtlTripListV3;
