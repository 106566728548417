import React from "react";
import { LeapsupplyChain, SuppyChainStyle } from "./supplyChainServices.js";

const SupplyChainServices = () => {
  return (
    <SuppyChainStyle className="supplyChainServices" id="know_more">
      <LeapsupplyChain></LeapsupplyChain>
      <div className="supplyChainHeading">
        Making supply chain easier for every one
      </div>
      <div className="supplyChainCards">
        <div className="supplyChainSingleCard">
          <div className="card-title">
            <div className="card-icon">
              <img
                src="/assets/landingPageAssets/fitst-mile.svg"
                alt="card-icon"
              />
            </div>
            <div>First Mile</div>
          </div>
          <div className="card-dis">
            First mile delivery refers to the first stage of the transportation.
            where the order is picked by the courier company from the seller's
            doorstep
          </div>
        </div>
        <div className="supplyChainSingleCard">
          <div className="card-title">
            <div className="card-icon">
              <img
                src="/assets/landingPageAssets/second-mile.svg"
                alt="card-icon"
              />
            </div>
            <div>Middle Mile</div>
          </div>
          <div className="card-dis">
            Goods are hauled from a supplier's warehouse, shipper's production
            facility to a retail store
          </div>
        </div>
        <div className="supplyChainSingleCard">
          <div className="card-title">
            <div className="card-icon">
              <img
                src="/assets/landingPageAssets/third-mile.svg"
                alt="card-icon"
              />
            </div>
            <div>Last Mile</div>
          </div>
          <div className="card-dis">
            The transportation of goods from a distribution hub to the final
            delivery destination — the door of the customer.
          </div>
        </div>
      </div>
    </SuppyChainStyle>
  );
};

export default SupplyChainServices;
