import { useEffect, useState } from "react";

export const useDebounce = (value, delaly = 500) => {
  const [debounceValue, setDebounceValue] = useState(value);

  useEffect(() => {
    if (value.length < 3 && value !== "") return;
    const timeout = setTimeout(() => {
      setDebounceValue(value);
    }, delaly);

    return () => clearTimeout(timeout);
  }, [value]);

  return debounceValue;
};

export function debouncePromise(func, wait = 500) {
  let timeout;
  return function (...args) {
    const context = this;

    return new Promise((resolve, reject) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        try {
          const result = func.apply(context, args);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      }, wait);
    });
  };
}
