import styled from "styled-components";
import { colorPalette, colorPaletteDark } from "../../themes/theme";

export const FtlTripListCardStyle = styled.div`
  width: 350px;
  height: 350px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  border: 0.5px solid #eeeeee;
  border-bottom: none;

  /* border: 1px solid #4e4e4e; */

  .trip_code {
    width: 100%;
    padding: 15px 10px;
    background-color: rgba(7, 107, 56, 30%);
    color: ${(props) => (props.mode ? colorPaletteDark.white : " #003e1f")};
    font-size: 18px;
    font-weight: 500;
    font-family: "JetBrains Mono", monospace;
    font-optical-sizing: auto;
    font-style: normal;
  }

  .vehilce_number_and_trip_status {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
    margin-bottom: 10px;

    .vehilce_number {
      padding: 1px 10px;
      border-radius: 6px;
      border: 2px solid #000;
      font-weight: 600;
      background-color: #ffa500;
    }

    .status {
      background-color: ${colorPalette.primaryColor};
      font-size: 12px;
      padding: 05px;
      color: #fff;
      font-weight: 500;
      border-radius: 2px;
    }
  }

  .trip_detail {
    width: 100%;
    padding: 2px 10px;
    display: grid;
    grid-template-columns: 80px 20px auto;
    align-items: center;

    .title {
      font-weight: 500;
      color: ${(props) => (props.mode ? colorPaletteDark.white : "#464646")};
      font-size: 12px;
    }

    .seperator {
      height: 90%;
      width: 2px;
      background-color: #e3dfdf;
    }

    .description {
      font-size: 12px;
      font-weight: 500;
      color: ${(props) => (props.mode ? colorPaletteDark.white : "#000")};
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .amount_section {
    padding: 10px;

    .amount_wrapper {
      border: 0.8px solid #e3dfdf;
      padding: 10px;
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      border-radius: 10px;
    }

    .amount_type {
      color: ${(props) => (props.mode ? colorPaletteDark.white : "#333333")};
      font-weight: 500;
      font-size: 12px;
    }

    .amount {
      font-size: 13px;
      font-weight: 600;
      color: #333333;
      color: ${(props) => (props.mode ? colorPaletteDark.white : "#333333")};
    }
  }

  .card_sub_details_section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 11px;
    color: ${(props) => (props.mode ? colorPaletteDark.white : "#333333")};

    .sub_detail {
      width: 100%;
      display: grid;
      grid-template-columns: 100px auto;

      .sub_detail_head {
        font-weight: 500;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    width: 88vw;

    .vehilce_number_and_trip_status {
      .vehilce_number {
        font-size: 14px;
      }

      .status {
        padding: 03px;
      }
    }
  }
`;
