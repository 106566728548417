export function convertToBase64(data) {
  let base64Value = btoa(
    encodeURIComponent(data).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode("0x" + p1);
    })
  );
  return base64Value;
}

export function prefixSalt() {
  const staticText = process.env.REACT_APP_PATH_PARAM_STATIC_TEXT;

  return staticText;
}
