import React, { useEffect, useState } from "react";
import TRUCK_IMAGE from "../../assets/dashboard_icons/icon-0.8s-215px.png";
import "./carrierMiniCard.js";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import Drawer from "@mui/material/Drawer";
import Accordion from "@mui/material/Accordion";
import CloseIcon from "@mui/icons-material/Close";
import { InputAdornment } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import UploadImage from "../../assets/upload.svg";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuItem, TextField } from "@mui/material";
import apiUrl from "../../url/apiUrl";
import Carrier from "../../assets/istockphoto-1214428300-170667a.jpg";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import FeedIcon from "@mui/icons-material/Feed";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Tabs from "@mui/material/Tabs";
import carrier2 from "../../assets/placeholdercompany5f3438282f524800f1d49cd2921bb0a56101e1aa16097ebd313b64778fc7c4bd1611448792.png";
// import { GetDriverDetails, GetCostDetails } from "./miniDetailsCardServices";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import { updateRoutesAddVehicle } from "../../redux/onboardSlice";
import { updateCarrierId } from "../../redux/onboardSlice";
import { useHistory } from "react-router-dom";
import { getCarrierListById } from "../../pages/onboard/OnboardServices";
import { useSelector, useDispatch } from "react-redux";
import {
  bankDetailsPost,
  GetBankDetails,
  GetEntityDetails,
  GetBankDetailsById,
  bankDetailsPut,
  bankDetailsPatch,
} from "./CarrierMiniCardServices";
import { getCarrierList } from "../../pages/onboard/OnboardServices";
import CopyText from "../copyText/CopyText";
import axiosInterceptor from "../../config/https";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";
import { readLoginData, readToken } from "../../helper/dataDecryptionHelper";
import { Mini_details_carrier_card_style } from "./carrierMiniCard.js";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { MyContext } from "../enumContext/EnumContextComponent.jsx"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CarrierMiniCard({ carrierDetails }) {
  const [state, setState] = React.useState({
    right: false,
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);
  const [bankAccTypeList, setBankAccTypeList] = useState([]);
  const [driverDetails, setDriverDetails] = useState({});
  const [costDetails, setCostDetails] = useState({});
  const [bankAcNumber, setBankAcNumber] = useState("");
  const [holderName, setHolderName] = useState("");
  const [accType, setAccType] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [ifscCode, setIfscCode] = useState("");
  const [bankDetailsList, setBankDetailsList] = useState([]);
  const [document, setDocument] = useState("");
  const [bankName, setBankName] = useState("");
  const [trueValue, setTrueValue] = useState(false);
  const [falseValue, setFalseValue] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const [carriersData, setCarriersData] = useState([]);
  const [entity, setEntity] = useState("");
  const [errorNumber, setErrorNumber] = useState("");
  const [errorBankName, setErrorBankName] = useState("");
  const [errorBankHolder, setErrorBankHolder] = useState("");
  const [errorAcType, setErrorAcType] = useState("");
  const [errorIfsc, setErrorIfsc] = useState("");
  const [errorEntity, setErrorEntity] = useState("");
  const [errorIsPrimary, setErrorIsPrimary] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [id, setId] = useState("");
  const [transporterAssetsList, setTransporterAssetsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enums } = useContext(MyContext);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const EditBankDetails = (e, bankdetails) => {
  //   getBankDetailsById(e, bankdetails);
  // };
  const getBankDetailsById = async (e, bankId) => {
    e.stopPropagation();
    //
    const response = await GetBankDetailsById(bankId);

    if (Object.keys(response).length > 0) {
      setEditMode(true);
      openDrawer();
      setBankAcNumber(response.bank_account_number);
      setHolderName(response.account_holder_name);
      setAccType(response.bank_account_type);
      setId(response.id);
      // setDocument(response.bank_account_number);
      setBankName(response.bank_name);
      setTrueValue(response.is_primary);
      setIfscCode(response.ifsc_code);
      setEntity(response.entity_type);
    }
  };

  const handleAddVehicle = (id) => {
    history.push(`/vehicles-onboard`);
    dispatch(updateCarrierId(id));
    dispatch(updateRoutesAddVehicle(""));
  };
  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };
  const getCarrierData = async (id) => {
    // setLoadingMore(true);
    const carrierResponse = await getCarrierListById(id);
    setCarriersData(carrierResponse);
  };

  useEffect(() => {
    if (Object.keys(carrierDetails).length > 0) {
      getCarrierData(carrierDetails.id);
    }
  }, [carrierDetails.id]);

  const handleBankDetailsPost = async () => {
    setLoadingButton(true);
    const isValid = formValidation();
    if (isValid) {
      if (editMode == true) {
        const responseDataEdit = await bankDetailsPut(
          bankAcNumber,
          bankName,
          holderName,
          accType,
          ifscCode,
          carriersData.id,
          "transporter",
          false,
          // trueValue,
          document,
          id
        );
        if (responseDataEdit == 200) {
          const patchResponse = await bankDetailsPatch(id, trueValue);

          if (Object.keys(patchResponse).length > 0) {
            getCarrierData(carrierDetails.id);
            handleClose();
            setLoadingButton(false);
          }
          setLoadingButton(false);
          getCarrierData(carrierDetails.id);
          handleClose();
          setBankAcNumber("");
          setHolderName("");
          setAccType("");
          setDocument("");
          setBankName("");
          setTrueValue("");
          setIfscCode("");
          setEntity("");
          // setDocument({});
        }
      } else {
        const responseData = await bankDetailsPost(
          bankAcNumber,
          bankName,
          holderName,
          accType,
          ifscCode,
          carriersData.id,
          "transporter",
          false,
          trueValue,
          document
        );

        if (responseData == 200) {
          const patchResponse = bankDetailsPatch(id, trueValue);

          if (Object.keys(patchResponse).length > 0) {
            getCarrierData(carrierDetails.id);
          }
          setLoadingButton(false);
          getCarrierData(carrierDetails.id);
          handleClose();
          setBankAcNumber("");
          setHolderName("");
          setAccType("");
          setDocument("");
          setBankName("");
          setTrueValue("");
          setIfscCode("");
          setEntity("");
          // setDocument({});
        }
      }

      setLoadingButton(false);
    }
    setLoadingButton(false);
  };

  const getBankDetails = async () => {
    const getBankData = await GetBankDetails();

    setBankDetailsList(getBankData.bank_details_list);
  };
  useEffect(() => {
    getBankDetails();
  }, []);

  const getEntityType = async () => {
    const getEntityData = await GetEntityDetails();

    setEntityList(getEntityData);
  };
  useEffect(() => {
    getEntityType();
  }, []);
  const formValidation = () => {
    const errorbankAcNumber = {};
    const errorbankName = {};
    const errorholderName = {};
    const erroraccType = {};
    const errorifscCode = {};
    const errorentity = {};
    const errortrueValue = {};
    let isValid = true;
    if (bankAcNumber == "") {
      errorbankAcNumber.errorbankNumber = "A/c Number is mandatory.";
      isValid = false;
    }
    if (bankName == "") {
      errorbankName.errorBankName = "Bank Name is mandatory.";
      isValid = false;
    }
    // const bankRegex = /^[A-Za-z\s]+$/;
    // if (bankName == "") {
    //   errorBankName.errorBankName = "Bank Name is mandatory";
    //   isValid = false;
    // } else if (!bankRegex.test(bankName)) {
    //   errorBankName.errorBankName = "Please input alphabet characters only";
    //   isValid = false;
    // }
    if (holderName == "") {
      errorholderName.errorbankHolder = "Holder Name is mandatory.";
      isValid = false;
    }
    if (accType == "") {
      erroraccType.errorbankType = "A/c Type is mandatory.";
      isValid = false;
    }
    if (ifscCode == "") {
      errorifscCode.errorbankIfsc = "Ifsc Code is mandatory.";
      isValid = false;
    }

    // if (entity == "") {
    //   errorentity.errorEntity = "Entity Type is manadatory";
    //   isValid = false;
    // }
    // if (trueValue == "") {
    //   errortrueValue.errorTrueValue = "field is manadatory";
    //   isValid = false;
    // }
    setErrorNumber(errorbankAcNumber);
    setErrorBankName(errorbankName);
    setErrorBankHolder(errorholderName);
    setErrorAcType(erroraccType);
    setErrorIfsc(errorifscCode);
    setErrorEntity(errorentity);
    setErrorIsPrimary(errortrueValue);
    return isValid;
  };
  //   setErrorOemName(errorOemName);
  //   setErrorPhoto(errorPhoto);
  //   return isValid;
  // };
  //     if (tripDetails.driver_id) {
  //       getCostDetails(tripDetails.driver_id);
  //     }
  //   }, [tripDetails.driver_id]);

  //   const getDriverDetails = async (id) => {
  //     const response = await GetDriverDetails(id);
  //     setDriverDetails(response);
  //
  //   };

  //   const getCostDetails = async (id) => {
  //     const response = await GetCostDetails(id);
  //     setCostDetails(response);
  //
  //   };
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const openDrawer = () => {
    toggleDrawer("right", true);
  };
  const handleClose = () => {
    toggleDrawer("right", false);
    setEditMode(false);
    setBankAcNumber("");
    setHolderName("");
    setAccType("");
    setDocument("");
    setBankName("");
    setTrueValue("");
    setIfscCode("");
    setEntity("");
    // setDocument({});
    setErrorNumber("");
    setErrorBankName("");
    setErrorBankHolder("");
    setErrorAcType("");
    setErrorIfsc("");
    setErrorEntity("");
    setErrorIsPrimary("");
  };

  useEffect(() => {
    setBankAccTypeList(enums?.bank_account_type ? enums.bank_account_type : []);
  }, [enums])
  // useEffect(() => {
  //   getAccType();
  // }, []);
  const handleDocument = (event) => {
    // setTripAttachmentFile(event.target.files);
    setDocument(event.target.files[0]);
    // setErrorPhoto({});
    // setErrorPhoto("");
  };
  let tokensData = readToken();
  const download = async (id) => {
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/transporter-agreement/${prefixSalt()}-${convertToBase64(id)}`,
      // { responseType: "application/pdf" },
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
          // Accept: "application/pdf",
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        var file = window.URL.createObjectURL(blob);

        // window.location.assign(file);
        window.open(file, "_blank");
      })
      .catch((err) => { });
  };

  const getAssetsList = async (id) => {
    setIsLoading(true);
    const response = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl
      }/v1/transporter/${prefixSalt()}-${convertToBase64(id)}/assets`
    );

    if (response.status === 200) {
      setTransporterAssetsList(response.data.transporter_assets_list);
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   getAssetsList(carrierDetails.id);
  // }, [carrierDetails.id]);

  const downloadAssets = async (id, assetsId) => {
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/transporter-assets/${prefixSalt()}-${convertToBase64(
        assetsId
      )}`,
      // { responseType: "application/pdf" },
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
          "Content-Type": "image/jpeg",
          // Accept: "application/pdf",
        },
      }
    )
      // .then((response) => {
      //
      //   response.arrayBuffer().then(function (buffer) {
      //     const url = window.URL.createObjectURL(new Blob([buffer]));
      //     const assetsLink = document.createElement("a");
      //     assetsLink.href = url;
      //     assetsLink.download = "image.jpg";
      //     assetsLink.click();
      //   });
      //   if (response.status === 403) {
      //     localStorage.clear();
      //     window.location.reload();
      //   }
      // })
      // .catch((err) => {});

      .then((res) => res.blob())
      .then((blob) => {
        var file = window.URL.createObjectURL(blob);

        // window.location.assign(file);
        window.open(file, "_blank");
      })
      .catch((err) => { });
  };
  const { toggle, darkMode } = useContext(DarkModeContext);
  return (
    <Mini_details_carrier_card_style
      className="mini-details-carrier-card-main-container"
      style={{ position: "relative" }}
    >
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0,0,0,0.2)",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : null}
      <Drawer anchor={"right"} onClose={handleClose} open={state["right"]}>
        <div className="bank_container">
          <div className="bank_wrapper">
            <div className="bank_headings">
              <h3>Enter Bank Details</h3>{" "}
              <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />{" "}
            </div>
            <div className="bank-drawer-row">
              <div className="warehouse-list-input-group">
                <h4 className="warehouse-list-input-label">
                  Bank A/C Number <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="bank-input"
                  variant="outlined"
                  placeholder="Enter a/c number"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={bankAcNumber}
                  onChange={(e) => {
                    setBankAcNumber(e.target.value);
                    setErrorNumber("");
                  }}
                />
                {Object.keys(errorNumber).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>{errorNumber[key]}</h6>
                  );
                })}
              </div>
            </div>
            <div className="bank-drawer-row">
              <div className="warehouse-list-input-group">
                <h4 className="warehouse-list-input-label">
                  A/C Holder Name <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="bank-input"
                  variant="outlined"
                  placeholder="Enter A/c Holder Name"
                  type="text"
                  value={holderName}
                  onChange={(e) => {
                    setHolderName(e.target.value);
                    setErrorBankHolder("");
                  }}
                />
                {Object.keys(errorBankHolder).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>{errorBankHolder[key]}</h6>
                  );
                })}
              </div>
            </div>
            <div className="bank-drawer-row">
              <div className="warehouse-list-input-group">
                <h4 className="warehouse-list-input-label">
                  Bank A/C Type <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  id="filled-select-currency"
                  select
                  label="Please Select account type"
                  value={accType}
                  onChange={(e) => {
                    setAccType(e.target.value);
                    setErrorAcType("");
                  }}
                  variant="filled"
                  className="bank-input"
                  color="success"
                  size="small"
                >
                  {bankAccTypeList
                    // .filter((data) => data.split("_").join(" ").toUpperCase())
                    .map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option.split("_").join(" ").toUpperCase()}
                      </MenuItem>
                    ))}
                </TextField>
                {Object.keys(errorAcType).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>{errorAcType[key]}</h6>
                  );
                })}
              </div>
            </div>
            <div className="bank-drawer-row">
              <div className="warehouse-list-input-group">
                <h4 className="warehouse-list-input-label">
                  Entity Type <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  id="filled-select-currency"
                  // select
                  disabled
                  // label="Please Select entity type"
                  value="TRANSPORTER"
                  // onChange={(e) => {
                  //   setEntity(e.target.value);
                  //   setErrorEntity("");
                  // }}
                  variant="filled"
                  className="bank-input"
                  color="success"
                  size="small"
                >
                  {/* {entityList
                    .filter((data) => data == "transporter")
                    .map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option.split("_").join(" ").toUpperCase()}
                      </MenuItem>
                    ))} */}
                </TextField>
                {Object.keys(errorEntity).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>{errorEntity[key]}</h6>
                  );
                })}
              </div>
            </div>
            <div className="bank-drawer-row">
              <div className="warehouse-list-input-group">
                <h4 className="warehouse-list-input-label">
                  Bank Name <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  id="filled-select-currency"
                  variant="outlined"
                  label="Enter Bank Name"
                  value={bankName}
                  onChange={(e) => {
                    setBankName(e.target.value);
                    setErrorBankName("");
                  }}
                  className="bank-input"
                  color="success"
                  size="small"
                ></TextField>
                {Object.keys(errorBankName).map((key) => {
                  return (
                    <h6 style={{ color: "#E06666" }}>{errorBankName[key]}</h6>
                  );
                })}
              </div>
            </div>
            <div className="bank-drawer-row">
              <div className="warehouse-list-input-group">
                <h4 className="warehouse-list-input-label">
                  IFSC Code <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="bank-input"
                  variant="outlined"
                  placeholder="Enter IFSC Code"
                  type="text"
                  value={ifscCode}
                  onChange={(e) => {
                    setIfscCode(e.target.value);
                    setErrorIfsc("");
                  }}
                />
                {Object.keys(errorIfsc).map((key) => {
                  return <h6 style={{ color: "#E06666" }}>{errorIfsc[key]}</h6>;
                })}
              </div>
            </div>
            <div className="bank-drawer-row">
              <div
                className="warehouse-list-input-group"
                style={{ color: darkMode ? "white" : "" }}
              >
                <p>Is Primary Account?</p>
                <RadioGroup
                  className="create-trip-input-source"
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={trueValue}
                  onChange={(e) => setTrueValue(e.target.value)}
                >
                  <div className="create-trip-source-radio">
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />

                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </div>
                </RadioGroup>
              </div>
              {Object.keys(errorIsPrimary).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorIsPrimary[key]}</h6>
                );
              })}
            </div>

            {/* <div className="bank-drawer-row">
              <div className="warehouse-list-input-group">
                <h4 className="warehouse-list-input-label">
                  Entity Type <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="bank-input"
                  variant="outlined"
                  placeholder="Enter a/c number"
                  type="text"
               
                />
              </div>
            </div> */}
            <div className="bank-drawer-row">
              <div className="warehouse-list-input-group">
                <h4 className="warehouse-list-input-label">
                  Upload Document (png or jpg only)
                </h4>
                <TextField
                  size="small"
                  id="outlined-basic"
                  disabled
                  // sx={{
                  //   width: "30ch",
                  //   height: "4ch",
                  // }}
                  // style={{ width: "14rem" }}
                  // select
                  autoComplete="off"
                  // className="vehicle-input-attachment dropdown-container "
                  variant="outlined"
                  className="bank-input"
                  placeholder="Upload document"
                  name="attachment_tag"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          color: "#016938",
                          fontWeight: "600",
                        }}
                      >
                        <button className="upload_button">
                          <input
                            // name="upload"
                            name="attachment_file"
                            id="insurance"
                            type="file"
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={handleDocument}
                            hidden
                            style={{
                              backgroundColor: "#e8f9e0",
                            }}
                          />
                          <label
                            htmlFor="insurance"
                            style={{
                              backgroundColor: "#e8f9e0",
                            }}
                          >
                            <img
                              src={UploadImage}
                              alt=""
                              width="20px"
                              height="20px"
                              style={{
                                backgroundColor: "#e8f9e0",
                              }}
                            />
                          </label>
                        </button>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                {document ? document.name : null}
              </div>
            </div>
            <div className="bank-drawer-row">
              <div className="warehouse-list-input-group">
                <button className="bank_button" onClick={handleBankDetailsPost}>
                  {loadingButton ? (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <h5 onClick={handleClose}>close</h5> */}
      </Drawer>
      {isMobile ? null : (
        <div className="mini-details-carrier-img">
          {carriersData.transporter_type ? (
            carriersData.transporter_type == "registered_transporter" ? (
              <img src={carrier2} alt="" width="60%" height="80%" />
            ) : (
              <img src={Carrier} alt="" width="60%" height="80%" />
            )
          ) : null}
          {/* <img src={Carrier} alt="" width="60%" height="80%" /> */}
        </div>
      )}

      <div className="mini-details-carrier-body">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "#016938" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="all_tabs"
              variant="scrollable"
              scrollButtons={false}
            >
              <Tab label="Transporter" {...a11yProps(0)} />

              <Tab label="Bank Details" {...a11yProps(1)} />
              <Tab
                label="Attachments"
                {...a11yProps(2)}
                onClick={() => getAssetsList(carriersData.id)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="mini-details-carrier-tab-container">
              <div className="mini-details-carrier-single-list">
                <h4 className="mini-details-carrier-single-list-left">
                  Full Name
                </h4>
                <h4 className="mini-details-carrier-single-list-right">
                  : {carriersData.name ? carriersData.name : "-"}
                </h4>{" "}
              </div>

              <div className="mini-details-carrier-single-list">
                <h4 className="mini-details-carrier-single-list-left">
                  Transporter Type
                </h4>
                <h4 className="mini-details-carrier-single-list-right">
                  :
                  {carriersData.transporter_type
                    ? carriersData.transporter_type
                      .split("_")
                      .join(" ")
                      .toUpperCase()
                    : "-"}
                </h4>
              </div>
              <div className="mini-details-carrier-single-list">
                <h4 className="mini-details-carrier-single-list-left">
                  Mobile Number
                </h4>
                <h4 className="mini-details-carrier-single-list-right">
                  :{" "}
                  {carriersData.mobile ? (
                    <CopyText text={carriersData.mobile} />
                  ) : (
                    "-"
                  )}
                </h4>{" "}
              </div>
              <div className="mini-details-carrier-single-list">
                <h4 className="mini-details-carrier-single-list-left">Email</h4>
                <h4 className="mini-details-carrier-single-list-right">
                  :{" "}
                  {carriersData.email ? (
                    <CopyText text={carriersData.email} />
                  ) : (
                    "-"
                  )}
                </h4>{" "}
              </div>
              <div className="mini-details-carrier-single-list">
                <h4 className="mini-details-carrier-single-list-left">
                  GSTIN Number
                </h4>
                <h4 className="mini-details-carrier-single-list-right">
                  :{" "}
                  {carriersData.gstin_number ? (
                    <CopyText text={carriersData.gstin_number} />
                  ) : (
                    "-"
                  )}{" "}
                </h4>{" "}
              </div>
              <div className="mini-details-carrier-single-list">
                <h4 className="mini-details-carrier-single-list-left">
                  PAN Number
                </h4>
                <h4 className="mini-details-carrier-single-list-right">
                  :{" "}
                  {carriersData.pan_number ? (
                    <CopyText text={carriersData.pan_number} />
                  ) : (
                    "-"
                  )}{" "}
                </h4>
              </div>
              <div className="mini-details-carrier-single-list">
                <h4 className="mini-details-carrier-single-list-left">
                  Aadhar Number
                </h4>
                <h4 className="mini-details-carrier-single-list-right">
                  :{" "}
                  {carriersData.aadhar_number ? (
                    <CopyText text={carriersData.aadhar_number} />
                  ) : (
                    "-"
                  )}{" "}
                </h4>
              </div>
              {/* {Object.keys(carriersData).length ? (
                <>
                  {" "}
                  <h5
                    onClick={() => handleAddVehicle(carriersData.id)}
                    className="btn_card_vehicle"
                  >
                    <AddIcon /> Add Vehicle
                  </h5>
                </>
              ) : null} */}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="mini-details-carrier-tab-container-bank">
              {carriersData.bank_details !== undefined ? (
                carriersData.bank_details
                  .filter((data) => data.entity_type == "transporter")
                  .map((bank, index) => (
                    <>
                      <Accordion key={index}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography className="accodion_heading">
                            <h5 className="bank_holder">
                              {bank.account_holder_name.toUpperCase()}
                              {bank.is_primary ? (
                                <CheckCircleIcon
                                  style={{
                                    color: "#016938",
                                    fontSize: "18px",
                                    marginLeft: "10px",
                                  }}
                                />
                              ) : null}
                            </h5>{" "}
                            <EditIcon
                              onClick={(e) => {
                                getBankDetailsById(e, bank.id);
                              }}
                            />
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="mobile_users">
                          <div className="bankDetails">
                            <h5
                              style={{
                                display: "flex",
                                width: "100%",
                                padding: ".5rem 0rem",
                              }}
                            >
                              A/c holder name{" "}
                              <span
                                style={{
                                  position: "absolute",
                                  marginLeft: "9rem",
                                }}
                              >
                                : {bank.account_holder_name}
                              </span>
                            </h5>
                            <h5
                              style={{
                                display: "flex",
                                width: "100%",
                                padding: ".5rem 0rem",
                                // justifyContent: "space-between",
                              }}
                            >
                              A/c Number{" "}
                              <span
                                style={{
                                  position: "absolute",
                                  marginLeft: "9rem",
                                }}
                              >
                                : { }
                                <CopyText text={bank.bank_account_number} />
                              </span>
                            </h5>
                            <h5
                              style={{
                                display: "flex",
                                width: "100%",
                                padding: ".5rem 0rem",
                                // justifyContent: "space-between",
                              }}
                            >
                              Bank Name{" "}
                              <span
                                style={{
                                  position: "absolute",
                                  marginLeft: "9rem",
                                }}
                              >
                                : {bank.bank_name}
                              </span>
                            </h5>
                            <h5
                              style={{
                                display: "flex",
                                width: "100%",
                                padding: ".5rem 0rem",
                                // justifyContent: "space-between",
                              }}
                            >
                              Ifsc Code{" "}
                              <span
                                style={{
                                  position: "absolute",
                                  marginLeft: "9rem",
                                }}
                              >
                                : {<CopyText text={bank.ifsc_code} />}
                              </span>
                            </h5>
                            <h5
                              style={{
                                display: "flex",
                                width: "100%",
                                padding: ".5rem 0rem",
                                // justifyContent: "space-between",
                              }}
                            >
                              A/c Type{" "}
                              <span
                                style={{
                                  position: "absolute",
                                  marginLeft: "9rem",
                                }}
                              >
                                :{" "}
                                {bank.bank_account_type
                                  .split("_")
                                  .join(" ")
                                  .toUpperCase()}
                              </span>
                            </h5>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  ))
              ) : (
                <h5 style={{ color: darkMode ? "white" : "" }}>
                  No Bank details found!
                </h5>
              )}
            </div>
            {/* <div> */}
            <button className="bank_button_add" onClick={openDrawer}>
              Add Bank Details
            </button>
            {/* </div> */}
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="mini-details-single-attachment">
              <ul
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                id="transporter_type"
              >
                <li
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontWeight: "500",
                  }}
                >
                  <a
                    href="javascript:void(0)"
                    onClick={() => download(carriersData.id)}
                    className="attachment_btn"
                    target="_blank"
                    download
                    style={{ fontSize: "10px", width: "100%" }}
                  >
                    <FeedIcon
                      style={{
                        marginRight: "10px",
                      }}
                    />{" "}
                    Service Agreement
                  </a>

                  {/* <a
                    href={`${
                      apiUrl.onboardingBaseUrl
                    }/v1/image/transporter-agreement/${prefixSalt()}-${convertToBase64(
                      carriersData.id
                    )}`}
                    download
                    className="attachment_btn"
                  >
                    <FeedIcon
                      style={{
                        marginRight: "10px",
                      }}
                    />
                  </a> */}
                </li>
              </ul>
            </div>

            {transporterAssetsList.map((assets) => (
              <div className="mini-details-single-attachment">
                <ul
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  id="transporter_type"
                >
                  <li
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: "500",
                    }}
                  >
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        downloadAssets(assets?.transporters_id, assets?.id)
                      }
                      className="attachment_btn"
                      // target="_blank"
                      // download
                      style={{ fontSize: "10px", width: "100%" }}
                    >
                      <FeedIcon
                        style={{
                          marginRight: "10px",
                        }}
                      />{" "}
                      {assets?.type == "pan"
                        ? "Pan Card"
                        : assets?.type == "signature"
                          ? "Signature"
                          : assets?.type == "addhar_front"
                            ? "Aadhar Card Front Side"
                            : assets?.type == "addhar_back"
                              ? "Aadhar Card Back Side"
                              : ""}
                    </a>
                  </li>
                </ul>
              </div>
            ))}
          </TabPanel>
        </Box>
      </div>
    </Mini_details_carrier_card_style>
  );
}

// {
//   "is_active": true,
//   "id": 1,
//   "last_name": null,
//   "alternate_number": null,
//   "driving_licence_number": "AH7860051",
//   "ccreated_on": "2022-03-11T13:13:17.704420",
//   "modified_on": "2022-03-11T13:13:17.707110",
//   "first_name": "sonali",
//   "mobile_number": "9534939031",
//   "pan_number": "AAAPZ0071C"
// },

// {
//   "source_id": 9,
//   "approx_cost_id": 5,
//   "actual_cost_id": null,
//   "id": 5,
//   "payments_status": "paid",
//   "load_source": "trip_purchase_order",
//   "created_on": "2022-03-14T11:25:26.316874",
//   "load_unit_id": null,
//   "vehicle_number": "KA-29-2125",
//   "driver_id": 5,
//   "destination_id": 10,
//   "is_active": true,
//   "modified_on": "2022-03-14T11:25:26.319086",
//   "trip_distance_in_km": 78.0,
//   "trip_code": "KU0314251647257126",
//   "zoho_id": "670003",
//   "remarks": null,
//   "trip_status": "created",
//   "attachment_count": 0
// },

export default CarrierMiniCard;
