import React, { useCallback, useEffect, useRef } from "react";
import CustomerSidebar from "../../../components/logisticsCustomerComponents/CustomerSidebar/CustomerSidebar";
import EDIT_WHITE from "../../../assets/logisticsCustomer/edit_white.svg";
import SEARCH_GRAY from "../../../assets/logisticsCustomer/search_gray.svg";

import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, CircularProgress, Drawer, Snackbar } from "@mui/material";

import moment from "moment";
import RIghtbarIndent from "../../../components/logisticsCustomerComponents/rightbarIndent/RIghtbarIndent";
import { AcceptQuotesDrawer } from "../../../components/logisticsCustomerComponents/rightbarIndent/rightbarIndentStyle";
import { CustomerQuotesStyle } from "./customerQuotesStyle";
import {
  fetchCustomerQuotesList,
  getIndentDetailsById,
  quotaionAcceptPatch,
} from "./customerQuotesService";
import { useDispatch } from "react-redux";
import QuotesRightBar from "../../../components/logisticsCustomerComponents/quotesRightBar/QuotesRightBar";
import CopyText from "../../../components/copyText/CopyText";
import MobileHeaderCustomer from "../../../components/MobileHeader/MobileHeaderCustomer";
import { updateOnFlag, updateOnFlagUpdate } from "../../../redux/indentSlice";

function CustomerQuotesPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState("sent-quotations");
  const [allQuotesData, setAllQuotesData] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedQuotes, setSelectedQuotes] = useState();
  const [loadingMore, setLoadingMore] = useState(true);
  const [loadingAccetDenied, setLoadingAccetDenied] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [DrawerOpen, setDrawerOpen] = useState(false);
  const [cancelDrawerOpen, setCancelDrawerOpen] = useState(false);
  const [remarkForQotaion, setRemarkForQotaion] = useState("");
  const [rejectedRemark, setRejectedRemark] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [errType, setErrType] = useState();
  const [errorAccept, setErrorAccept] = useState("");
  const [indentDetails, setIndentDetails] = useState(null);

  const handleChangeTab = (tabName) => {
    if (!loadingPage) {
      setAllQuotesData([]);
      setLoadingPage(true);
      setCurrentTab(tabName);
      setCurrentPage(1);
    }
  };
  const getQuotationList = async (currentTab, currentPage) => {
    if (currentPage == 1) {
      setLoadingPage(true);
    }
    setLoadingMore(true);
    const response = await fetchCustomerQuotesList(currentTab, currentPage);
    if (currentPage > 1) {
      setAllQuotesData([...allQuotesData, ...response?.quotation_list]);

      setLoadingMore(false);
      setIsLastPage(response?.is_last_page);
    } else {
      setAllQuotesData(response?.quotation_list);
      setSelectedQuotes(response?.quotation_list[0]);
      setLoadingPage(false);
      setLoadingMore(false);
      setIsLastPage(response?.is_last_page);
    }
  };

  useEffect(() => {
    getQuotationList(currentTab, currentPage);
  }, [currentTab, currentPage]);

  // snackbar

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "left",
  });

  const { vertical, horizontal } = snackbarPosition;

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // pagination code

  const observer = useRef();
  const lastQuotes = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage(currentPage + 1);
          setLoadingMore(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage, currentPage]
  );
  const getIndentDetails = async (id) => {
    const response = await getIndentDetailsById(id);
    if (response) {
      setIndentDetails(response.data);
    }
  };
  useEffect(() => {
    if (selectedQuotes !== undefined) {
      getIndentDetails(selectedQuotes.indent_id);
    }
  }, [selectedQuotes]);

  const handleOpenAcceptModal = () => {
    setDrawerOpen(true);
    setErrorAccept("");
  };
  const handleOpenCancelModal = () => {
    setCancelDrawerOpen(true);
    setErrorAccept("");
  };
  const acceptQuatations = async (id, status, remark) => {
    setLoadingAccetDenied(true);

    const response = await quotaionAcceptPatch(id, status, remark);

    if (response.status == 200) {
      if (status == "accepted") {
        setOpenSnackbarSentSucccess(true);
        setDrawerOpen(false);
      }
      if (status == "denied") {
        setOpenSnackbarSuccess(true);
        setCancelDrawerOpen(false);
      }
      setLoadingAccetDenied(false);
      openSnackbarSentSucccess(true);
      setCancelDrawerOpen(false);
      setDrawerOpen(false);
      setRemarkForQotaion("");
      setRejectedRemark("");
      handleCloseSnackbarSent();
      handleCloseSnackbarSuccess();
      getQuotationList("sent-quotations", 1);
    }
    if (response.status == 400) {
      setLoadingAccetDenied(false);
      setErrorAccept(response.data.detail);
    }
  };
  const handleSubmitAcceptButton = () => {
    // id, quotation_id, modified_by, quotation_status, remarks;

    acceptQuatations(
      selectedQuotes.quotations_id,
      "accepted",
      remarkForQotaion
    );
  };
  const handleOpenRejectModal = () => {
    acceptQuatations(selectedQuotes.quotations_id, "denied", rejectedRemark);
  };
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const [openSnackbarSentSucccess, setOpenSnackbarSentSucccess] =
    useState(false);
  const handleCloseSnackbarSuccess = () => {
    setOpenSnackbarSuccess(false);
  };
  const handleCloseSnackbarSent = () => {
    setOpenSnackbarSentSucccess(false);
  };
  useEffect(() => {
    dispatch(updateOnFlagUpdate(false));
    dispatch(updateOnFlag(false));
  }, []);
  // dispatch(updateOnFlag(true));
  return (
    <CustomerQuotesStyle>
      <Snackbar
        open={openSnackbarSentSucccess}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseSnackbarSent}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseSnackbarSent}
          severity="success"
          sx={{ width: "100%" }}
        >
          Quotation Accepted!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbarSuccess}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseSnackbarSuccess}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseSnackbarSuccess}
          severity="error"
          sx={{ width: "100%" }}
        >
          Quotation Denied!
        </Alert>
      </Snackbar>
      <Drawer
        open={DrawerOpen}
        anchor={"right"}
        onClose={() => {
          setDrawerOpen(false);
          setRemarkForQotaion("");
        }}
        PaperProps={{
          sx: {
            width: isMobile ? 320 : 440,
          },
        }}
      >
        <AcceptQuotesDrawer className="accept_drawer">
          <div className="accept_drawer_heading">Remarks</div>
          <div className="add_remark">
            <textarea
              name="accept_remark"
              placeholder="Add Remark"
              value={remarkForQotaion}
              onChange={(e) => setRemarkForQotaion(e.target.value)}
            ></textarea>
          </div>
          {errorAccept ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                color: "red",
                backgroundColor: "#F0D6D6",
                padding: ".4rem .2rem",
                marginTop: "1rem",
                fontSize: ".8rem",
              }}
            >
              {errorAccept}
            </div>
          ) : null}

          <div className="accept_button">
            <button
              onClick={handleSubmitAcceptButton}
              disabled={loadingAccetDenied == true ? true : false}
            >
              {loadingAccetDenied ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                "Accept"
              )}
            </button>
          </div>
        </AcceptQuotesDrawer>
      </Drawer>
      <Drawer
        open={cancelDrawerOpen}
        anchor={"right"}
        onClose={() => {
          setCancelDrawerOpen(false);
          setRejectedRemark("");
        }}
        PaperProps={{
          sx: {
            width: isMobile ? 320 : 440,
          },
        }}
      >
        <AcceptQuotesDrawer className="accept_drawer">
          <div className="accept_drawer_heading">Remarks</div>
          <div className="add_remark">
            <textarea
              name="accept_remark"
              placeholder="Add Remark"
              value={rejectedRemark}
              onChange={(e) => setRejectedRemark(e.target.value)}
            ></textarea>
          </div>
          {errorAccept ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                color: "red",
                backgroundColor: "#F0D6D6",
                padding: ".4rem .2rem",
                marginTop: "1rem",
                fontSize: ".8rem",
              }}
            >
              {errorAccept}
            </div>
          ) : null}
          <div className="accept_button">
            <button
              onClick={handleOpenRejectModal}
              disabled={loadingAccetDenied == true ? true : false}
            >
              {loadingAccetDenied ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                "Reject"
              )}
            </button>
          </div>
        </AcceptQuotesDrawer>
      </Drawer>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={4000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        key={vertical + horizontal}
      >
        <Alert severity={errType}>{errMessage}</Alert>
      </Snackbar>
      <div style={{ display: "flex" }}>
        {isMobile ? null : <CustomerSidebar />}
        <div className="customerQuotes-container">
          {
            isMobile ? (
              <div className="trip-list-mobile-header">
                <MobileHeaderCustomer headerTitle="Quotations" />
              </div>
            ) : null
            // <span className="indent-head-text"> Indents</span>
          }
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              height: isMobile ? "44px" : "112px",
              background: "#fff",
            }}
          >
            <div className="top-container">
              <div className="tab-menuOption-contain">
                <div
                  className="tab-menu-option"
                  style={
                    currentTab === "sent-quotations"
                      ? { color: "#076b38", fontWeight: "600" }
                      : null
                  }
                  onClick={() => handleChangeTab("sent-quotations")}
                >
                  Open
                  {currentTab === "sent-quotations" ? (
                    <div className="tab-bottom-rectangle"></div>
                  ) : null}
                </div>
                <div
                  className="tab-menu-option"
                  style={
                    currentTab === "accepted-quotations"
                      ? { color: "#076b38", fontWeight: "600" }
                      : null
                  }
                  onClick={() => handleChangeTab("accepted-quotations")}
                >
                  Accepted
                  {currentTab === "accepted-quotations" ? (
                    <div className="tab-bottom-rectangle"></div>
                  ) : null}
                </div>
                <div
                  className="tab-menu-option"
                  style={
                    currentTab === "denied-quotations"
                      ? { color: "#076b38", fontWeight: "600" }
                      : null
                  }
                  onClick={() => handleChangeTab("denied-quotations")}
                >
                  Denied
                  {currentTab === "denied-quotations" ? (
                    <div className="tab-bottom-rectangle"></div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="customerQuotes-main-body">
            <div className="customerQuotes-main-body-left">
              {/* <div className="customerQuotes-search-row">
                <div className="customerQuotes-search-contain">
                  <img
                    src={SEARCH_GRAY}
                    alt="search"
                    style={{ margin: "3px" }}
                  />
                  <input
                    className="customerQuotes-search-input"
                    placeholder="Search"
                  />
                </div>
              </div> */}
              {loadingPage ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <CircularProgress />{" "}
                </div>
              ) : !loadingPage && allQuotesData.length == 0 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                    // color: darkMode ? "white" : "",
                  }}
                >
                  No Quotation found!
                </div>
              ) : (
                <div className="quotesList-container">
                  {allQuotesData?.map((quotes) => {
                    return (
                      <div
                        className={
                          selectedQuotes.quotations_id === quotes.quotations_id
                            ? "quotes-card-container quotes-selected"
                            : "quotes-card-container"
                        }
                        ref={lastQuotes}
                        onClick={() => setSelectedQuotes(quotes)}
                      >
                        <div className="quotes-card-top">
                          <div className="quotes-number-text">
                            <CopyText
                              text={
                                quotes?.quotation_code
                                  ? quotes?.quotation_code
                                  : " - "
                              }
                            />
                          </div>
                          <div className="quotes-amount-text">
                            {quotes?.created_on ? (
                              <>
                                <span>
                                  {/* {moment(quotes?.created_on).format(
                                    "DD MMM YYYY"
                                  )} */}
                                  {moment
                                    .utc(quotes?.created_on)
                                    .local()
                                    .format("DD MMM YYYY hh:mm a")}
                                </span>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div className="value_and_amount">
                          <div className="quotes_value">
                            <div className="quotes_value_heading">
                              Invoice Value
                            </div>
                            <div className="quotes_value_discription">
                              Rs. {quotes?.value_of_goods}
                            </div>
                          </div>
                          <div className="quotes_amount">
                            <div className="quotes_amount_heading">
                              Quotes Amount
                            </div>
                            <div className="quotes_amount_discription">
                              Rs. {quotes ? quotes.quotation_amount : "-"}
                            </div>
                          </div>
                        </div>
                        <div className="remarks_and_action_button">
                          <div className="remarks">
                            Remarks :-
                            <h6 className="remarks">
                              {" "}
                              {quotes?.remarks == null ? "-" : quotes?.remarks}
                            </h6>
                          </div>
                          {currentTab === "accepted-quotations" ||
                          currentTab === "denied-quotations" ? null : (
                            <div className="quotes_action_button">
                              <button
                                className="accept_quotes"
                                onClick={() => {
                                  handleOpenAcceptModal();
                                }}
                              >
                                Accept
                              </button>
                              <button
                                className="reject_quotes"
                                onClick={() => {
                                  handleOpenCancelModal();
                                }}
                              >
                                Deny
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {loadingMore ? (
                    <div className="loadingMore">Loading More ...</div>
                  ) : null}
                </div>
              )}
            </div>
            {isMobile ? null : (
              <QuotesRightBar
                indentDetails={indentDetails}
                currentTab={currentTab}
                selectedQuotes={selectedQuotes}
                // tab={setCurrentTab("sent-quotations")}
              />
            )}

            {/* <RIghtbarIndent
              selectedQuotes={selectedQuotes}
              currentTab={currentTab}
            /> */}
          </div>
        </div>
      </div>
    </CustomerQuotesStyle>
  );
}

export default CustomerQuotesPage;
