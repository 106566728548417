import React from "react";
import { OurPlatformStyle } from "./our_platform";

const Our_platform = () => {
  return (
    <OurPlatformStyle className="our_platform" id="our_platform">
      <div className="platform-heading">Our Platform</div>
      <div className="platform-card-section">
        <div className="platform-card">
          <div className="platform-card-icon">
            <img
              src="/assets/landingPageAssets/how_to_reg.svg"
              alt="card icon"
            />
          </div>
          <div className="platform-card-text">
            Trusted by 80+ brands in India
          </div>
        </div>
        <div className="platform-card">
          <div className="platform-card-icon">
            <img
              src="/assets/landingPageAssets/where_to_vote.svg"
              alt="card icon"
            />
          </div>
          <div className="platform-card-text">
            Currently Active in 24 States
          </div>
        </div>
        <div className="platform-card">
          <div className="platform-card-icon">
            <img src="/assets/landingPageAssets/custom.svg" alt="card icon" />
          </div>
          <div className="platform-card-text">
            Customized solution for user needs
          </div>
        </div>
        <div className="platform-card">
          <div className="platform-card-icon">
            <img src="/assets/landingPageAssets/devices.svg" alt="card icon" />
          </div>
          <div className="platform-card-text">Tech enabled platform</div>
        </div>
      </div>
      <div className="platform-card-section">
        <div className="platform-card">
          <div className="platform-card-icon">
            <img src="/assets/landingPageAssets/layers.svg" alt="card icon" />
          </div>
          <div className="platform-card-text">
            Higher Efficiency and Transparency
          </div>
        </div>
        <div className="platform-card">
          <div className="platform-card-icon">
            <img
              src="/assets/landingPageAssets/local_phone.svg"
              alt="card icon"
            />
          </div>
          <div className="platform-card-text">24X7 Customer Support</div>
        </div>
        <div className="platform-card">
          <div className="platform-card-icon">
            <img
              src="/assets/landingPageAssets/transporters.svg"
              alt="card icon"
            />
          </div>
          <div className="platform-card-text">600+ Registered Transporter</div>
        </div>
        <div className="platform-card">
          <div className="platform-card-icon">
            <img
              src="/assets/landingPageAssets/local_shipping.svg"
              alt="card icon"
            />
          </div>
          <div className="platform-card-text">1000+ Registered Vehicles</div>
        </div>
      </div>
    </OurPlatformStyle>
  );
};

export default Our_platform;
