import React, { useState, useEffect } from "react";

const AnimateNumber = ({ targetNumber = 0, duration = 1000 }) => {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    let startTimestamp;
    const increment = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = timestamp - startTimestamp;
      const percentage = Math.min(progress / duration, 1);
      const animatedValue = Math.floor(percentage * targetNumber);

      setCurrentNumber(animatedValue);

      if (progress < duration) {
        requestAnimationFrame(increment);
      }
    };

    const interval = setInterval(() => {
      requestAnimationFrame(increment);
    }, 16);

    return () => {
      clearInterval(interval);
    };
  }, [targetNumber, duration]);

  return currentNumber;
};

export default AnimateNumber;
