import React from "react";
import apiUrl from "../../url/apiUrl.js";
import axios from "axios";
import axiosInterceptor from "../../config/https.js";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter.js";

export const customersPost = (
  name,
  cin,
  logo,
  location,
  primary_contact,
  secondary_contact,
  description,
  agreement,
  isReverseChargeMechanismChecked
) => {
  let data = new FormData();

  data.append("name", name);
  data.append("cin", cin);
  data.append("location_str", JSON.stringify(location));
  data.append("primary_contact_str", JSON.stringify(primary_contact));
  data.append("secondary_contact_str", JSON.stringify(secondary_contact));
  data.append("logo", logo);
  if (description) {
    data.append("description", description);
  }
  if (agreement.name) {
    data.append("agreement", agreement);
  }
  data.append(
    "is_reverse_charge_mechanism_applicable",
    isReverseChargeMechanismChecked
  );

  var config = {
    method: "post",
    url: `${apiUrl.onboardingBaseUrl}/v2/customers`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        // if (response.status == 200) {
        resolve(response.status);
        // }
      })
      .catch(function (e) {
        console.log(e, "e.response");
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        // if (error.response.status === 400) {
        //
        // }

        resolve(e.response);
      });
  });
};

export const customersEdit = (
  name,
  description,
  cin,
  logo,
  agreement,
  id,
  isReverseChargeMechanismChecked
) => {
  let data = new FormData();
  if (name) {
    data.append("name", name);
  }
  if (cin) {
    data.append("cin", cin);
  }
  if (logo.name) {
    data.append("logo", logo);
  }
  if (description) {
    data.append("description", description);
  }
  if (agreement.name) {
    data.append("agreement", agreement);
  }
  data.append(
    "is_reverse_charge_mechanism_applicable",
    isReverseChargeMechanismChecked
  );

  var config = {
    method: "put",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v2/customers/${prefixSalt()}-${convertToBase64(id)}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};

export const customersList = async (page) => {
  try {
    const customersListResponse = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v2/customers?current_page=${page}`
    );

    if (customersListResponse.status == 200) {
      return customersListResponse;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
  }
};

export const getCustomerCreditById = async (customerId) => {
  try {
    const customersListResponse = await axiosInterceptor.get(
      `${apiUrl.customerCreditUrl}?customer_id=${customerId}`
    );

    if (customersListResponse.status == 200) {
      return customersListResponse;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
  }
};

export const createCreditApi = (customerId, creditLimit, creditPeriod) => {
  let data = JSON.stringify({
    customer_id: customerId,
    credit_period: creditPeriod,
    credit_limit: creditLimit,
  });

  var config = {
    method: "post",
    url: `${apiUrl.customerCreditUrl}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        // if (response.status == 200) {
        resolve(response);
        // }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        // if (error.response.status === 400) {
        //
        // }

        resolve(e.response);
      });
  });
};

export const updateCreditApi = (customerId, creditLimit, creditPeriod) => {
  let data = JSON.stringify({
    customer_id: customerId,
    credit_period: creditPeriod,
    credit_limit: creditLimit,
  });

  var config = {
    method: "put",
    url: `${apiUrl.customerCreditUrl}/${prefixSalt()}-${convertToBase64(
      customerId
    )}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        // if (response.status == 200) {
        resolve(response);
        // }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        // if (error.response.status === 400) {
        //
        // }

        resolve(e.response);
      });
  });
};

export const addCustomerEntity = (entityData) => {
  console.log(entityData, "dsfsdfsdf");
  let data = JSON.stringify(entityData);

  var config = {
    method: "post",
    url: `${apiUrl.customerEntitySeries}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);
      });
  });
};

export const editCustomerEntity = (entityData) => {
  let data = JSON.stringify({
    customer_id: entityData.customer_id,
    entity_type: entityData.entity_type,
    series_prefix: entityData.series_prefix,
    year: entityData.year,
  });

  var config = {
    method: "put",
    url: `${apiUrl.customerEntitySeries}/${prefixSalt()}-${convertToBase64(
      entityData.id !== "" ? entityData.id : undefined
    )}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);
      });
  });
};

export const customerCreditExport = async (email) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.customerCreditUrl}/report-export?email=${email}`
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};
