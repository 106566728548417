import axios from "axios";
import axiosInterceptor from "../../../config/https";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import apiUrl from "../../../url/apiUrl";

export const TripAttachmentPost = (
  image,
  tripId,
  tag,
  attachmentType,
  attachAt,
  attachmentList
) => {
  return attachmentList.map(async (attachment) => {
    let data = new FormData();
    if (!attachment.attachment_file) {
      return;
    }

    data.append("file_1", attachment.attachment_file);
    if (attachment.attachment_tag != "0") {
      data.append("tag_1", attachment.attachment_tag);
    } else {
      data.append("tag_1", "others");
    }
    data.append(
      "attachment_type_1",
      attachment.attachment_file == "application/pdf" ? "pdf" : "image"
    );
    data.append("attach_at", attachAt);
    data.append("trip_id", tripId);

    var config = {
      method: "post",
      url: `${apiUrl.ftlTripAttachmentsUrl}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
    return new Promise((resolve, reject) => {
      axiosInterceptor(config)
        .then(function (response) {
          if (response.status == 201) {
            resolve("success");
          }
        })
        .catch(function (error) {
          resolve(error.response);
          // resolve(error);
          //
        });
    });
  });
};

// export const requestCompleteTrip = (
//   tripStatus,
//   remarks,
//   tripId,
//   DeliveredBills,
//   tripCost,
//   unloadedQuantity,
//   tripCompleteMeterReading,
//   tripCompleteMeterReadingAttachment
// ) => {
//   let dataTemp = {};

//   if (unloadedQuantity) {
//     dataTemp["unloaded_quantity"] = unloadedQuantity;
//   }
//   if (tripCost.length > 0) {
//     dataTemp["trip_cost"] = tripCost;
//   }
//   if (tripCompleteMeterReading) {
//     dataTemp["trip_complete_meter_reading"] = tripCompleteMeterReading;
//   }
//   let data = JSON.stringify({
//     ...dataTemp,
//     trip_status: tripStatus,
//     remarks: remarks,
//     order_details: DeliveredBills,
//   });
//   var config = {
//     method: "patch",
//     url: `${apiUrl.tripV7BaseUrl}/${prefixSalt()}-${convertToBase64(
//       tripId
//     )}/trip-complete-request`,
//     headers: {
//       "Content-Type": "application/json",
//     },
//     data: data,
//   };
//   return new Promise((resolve, reject) => {
//     axiosInterceptor(config)
//       .then(async function (response) {
//         if (response.status == 200) {
//           const attachmentResponse = await tripAttachmentCompleteSinglePost(
//             tripCompleteMeterReadingAttachment,
//             tripId
//           );
//           if (attachmentResponse == "success") {
//             resolve(response);
//           } else {
//             resolve({ status: 500 });
//           }
//         }
//       })
//       .catch(function (error) {
//         if (error.response.status === 403) {
//           localStorage.clear();
//           window.location.reload();
//         }
//         resolve(error.response);
//         // resolve(error);
//         //
//       });
//   });
// };
export const requestCompleteTrip = (
  tripStatus,
  remarks,
  tripId,
  DeliveredBills,
  tripCost,
  unloadedQuantity,
  tripCompleteMeterReading,
  tripCompleteMeterReadingAttachment,
  attachAt,
  attachmentDetails
) => {
  // return attachmentList.map(async (attachment) => {
  let dataTemp = new FormData();

  if (unloadedQuantity) {
    dataTemp.append("unloaded_quantity", unloadedQuantity);
  }
  if (tripCost.length > 0) {
    dataTemp.append("trip_cost_details", JSON.stringify(tripCost));
  }

  if (tripCompleteMeterReading) {
    dataTemp.append("trip_complete_meter_reading", tripCompleteMeterReading);
  }
  dataTemp.append("trip_status", tripStatus);
  dataTemp.append("remarks", remarks);
  dataTemp.append("order_details_str", JSON.stringify(DeliveredBills));
  dataTemp.append("file_1", tripCompleteMeterReadingAttachment.attachment_file);
  if (tripCompleteMeterReadingAttachment.attachment_tag != "0") {
    dataTemp.append("tag_1", tripCompleteMeterReadingAttachment.attachment_tag);
  } else {
    dataTemp.append("tag_1", "others");
  }

  dataTemp.append(
    "attachment_type_1",
    tripCompleteMeterReadingAttachment.attachment_file == "application/pdf"
      ? "pdf"
      : "image"
  );

  dataTemp.append("file_2", attachmentDetails.attachment_file);
  if (attachmentDetails.attachment_tag != "0") {
    dataTemp.append("tag_2", attachmentDetails.attachment_tag);
  } else {
    dataTemp.append("tag_2", "others");
  }

  dataTemp.append(
    "attachment_type_2",
    attachmentDetails.attachment_file == "application/pdf" ? "pdf" : "image"
  );
  dataTemp.append("attach_at", attachAt);
  dataTemp.append("trip_id", tripId);

  var config = {
    method: "patch",
    url: `${apiUrl.tripV8BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/trip-complete-request`,
    headers: {
      "Content-Type": "application/json",
    },
    data: dataTemp,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(async function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
        // resolve(error);
        //
      });
  });
  // });
};
export const approveCompleteTripRequest = (tripStatus, remarks, tripId) => {
  let data = JSON.stringify({
    trip_status: tripStatus,
    remarks: remarks,
    delete_end_trip_attachments: false,
  });
  var config = {
    method: "patch",
    url: `${apiUrl.tripV8BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/accept-reject-complete-request`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};
export const rejectCompleteTripRequest = (
  tripStatus,
  remarks,
  tripId,
  deleteRequest
) => {
  let data = JSON.stringify({
    trip_status: tripStatus,
    remarks: remarks,
    delete_end_trip_attachments: deleteRequest,
  });
  var config = {
    method: "patch",
    url: `${apiUrl.tripV7BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/accept-reject-complete-request`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};

export const stopTrip = (
  tripStatus,
  remarks,
  tripId,
  DeliveredBills,
  tripCost,
  unloadedQuantity,
  tripCompleteMeterReading,
  tripCompleteMeterReadingAttachment
) => {
  let dataTemp = {};
  if (unloadedQuantity) {
    dataTemp["unloaded_quantity"] = unloadedQuantity;
  }
  if (tripCost.length > 0) {
    dataTemp["trip_cost"] = tripCost;
  }
  if (tripCompleteMeterReading) {
    dataTemp["trip_complete_meter_reading"] = tripCompleteMeterReading;
  }
  let data = JSON.stringify({
    ...dataTemp,
    trip_status: tripStatus,
    remarks: remarks,
    order_details: DeliveredBills,
  });
  var config = {
    method: "put",
    url: `${apiUrl.tripV6BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/complete`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(async function (response) {
        if (response.status == 200) {
          const attachmentResponse = await tripAttachmentCompleteSinglePost(
            tripCompleteMeterReadingAttachment,
            tripId
          );
          if (attachmentResponse == "success") {
            resolve(response);
          } else {
            resolve({ status: 500 });
          }
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
        // resolve(error);
        //
      });
  });
};

export const GetCompleteTripDocumentEnums = async () => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.completeAttachmentTagEnumUrl}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};
export const getTripCompleteRequestDetails = async (tripId) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.tripV8BaseUrl}/${prefixSalt()}-${convertToBase64(tripId)}`
    );

    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const purchaseOrderItemsDeliveredPost = (tripId, itemDetails) => {
  let data = JSON.stringify({
    item_details: itemDetails,
  });
  var config = {
    method: "put",
    url: `${apiUrl.tripBaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/purchase-order-items-receive`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);

        // resolve(error);
        //
      });
  });
};

const tripAttachmentCompleteSinglePost = (attachment, tripId) => {
  let data = new FormData();
  if (!attachment.attachment_file) {
    return;
  }

  data.append("file_1", attachment.attachment_file);
  if (attachment.attachment_tag != "0") {
    data.append("tag_1", attachment.attachment_tag);
  } else {
    data.append("tag_1", "others");
  }

  data.append(
    "attachment_type_1",
    attachment.attachment_file == "application/pdf" ? "pdf" : "image"
  );
  data.append("attach_at", "end_trip");
  data.append("trip_id", tripId);

  var config = {
    method: "post",
    url: `${apiUrl.ftlTripAttachmentsUrl}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 201) {
          resolve("success");
          // return 'success'
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }

        resolve(error.response);
        // resolve(error);
        //
      });
  });
};

export const getFtlTripDetailsFromId = async (id) => {
  try {
    const tripResponse = await axiosInterceptor.get(
      `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(id)}`
    );

    if (tripResponse.status == 200) {
      return tripResponse.data;
    }
  } catch (e) {
    if (e.response.status == 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};
