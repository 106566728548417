import React, { useContext, useEffect, useState } from "react";
import TRUCK_IMAGE from "../../assets/dashboard_icons/icon-0.8s-215px.png";
// import "../carrierMiniCard/carrierMiniCard.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import Drawer from "@mui/material/Drawer";
import Accordion from "@mui/material/Accordion";
import CloseIcon from "@mui/icons-material/Close";
import { InputAdornment } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import UploadImage from "../../assets/upload.svg";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuItem, TextField } from "@mui/material";
import apiUrl from "../../url/apiUrl";
import Carrier from "../../assets/carrier icon.svg";
// import Carrier from "../../assets/istockphoto-1214428300-170667a.jpg";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import FeedIcon from "@mui/icons-material/Feed";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Tabs from "@mui/material/Tabs";
import carrier2 from "../../assets/placeholdercompany5f3438282f524800f1d49cd2921bb0a56101e1aa16097ebd313b64778fc7c4bd1611448792.png";
// import { GetDriverDetails, GetCostDetails } from "./miniDetailsCardServices";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { updateRoutesAddVehicle } from "../../redux/onboardSlice";
import { updateCarrierId } from "../../redux/onboardSlice";
import { useHistory } from "react-router-dom";
import { getCarrierListById } from "../../pages/onboard/OnboardServices";
import { useSelector, useDispatch } from "react-redux";

import { getCarrierList } from "../../pages/onboard/OnboardServices";
import { Mini_detail_carrier_card_main_container_payment } from "./paymentMinicard";
import { DarkMode } from "@mui/icons-material";
import { DarkModeContext } from "../../context/darkModeContext";
import { colorPalette, colorPaletteDark } from "../../themes/theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function PaymentsMiniCard({ data, paid }) {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  const [value, setValue] = React.useState(0);
  const [total, setTotal] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const reducer = (accumulator, curr) => accumulator + curr;
  useEffect(() => {
    totalBalance(data);
  }, [data]);
  const totalBalance = () => {
    let total = 0;
    if (data.trip_cost !== undefined) {
      total = data.trip_cost
        .filter((advance) => advance.cost_type !== "advance")
        .filter((cost) => cost.cost_type !== "per_bag_cost")
        .map((data) => data.cost_amount)
        .reduce(reducer);
      setTotal(total);
    }
  };
  //   useEffect(() => {
  //     totalBalance();
  //   }, [data.trip_cost.length]);
  return (
    <Mini_detail_carrier_card_main_container_payment
      className="mini-details-carrier-card-main-container_payments"
      mode={DarkMode}
      style={{
        backgroundColor: darkMode
          ? colorPaletteDark.secondaryColor
          : colorPalette.white,
      }}
    >
      {/* {isMobile ? null : (
        <div className="mini-details-carrier-img-payments">
          <img src={Carrier} alt="" width="40%" height="90%" />
        </div>
      )} */}

      <div className="mini-details-carrier-body">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label="Payment Details"
                disabled
                style={{ width: "100%", margin: "0 auto" }}
                {...a11yProps(0)}
              />
              {/* <Tab label="Item Two" {...a11yProps(1)} />
              <Tab label="Item Three" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="payment_details">
              <div className="payments_terms">
                <h5>Trip Code:</h5>
                <h5 style={{ color: "green" }}>{data.trip_code}</h5>
              </div>
              <div className="payments_terms">
                <h5>
                  Payment Terms:{" "}
                  <span style={{ color: "grey" }}>To Be billed</span>
                </h5>
                <h5 style={{ color: "green" }}>
                  <span className="rupees">₹</span> {total}
                </h5>
              </div>
              <div className="trip_cost_details">
                <div className="trip_total_headings">
                  <h5>Trip Total Cost:</h5>
                </div>
                <div className="trip_breakups">
                  {data.trip_cost !== undefined
                    ? data.trip_cost.map((items, index) => (
                        <div className="break_ups" key={index}>
                          <h6>
                            {items.cost_type.split("_").join(" ").toUpperCase()}
                            :
                          </h6>{" "}
                          <h6>
                            <span className="rupees">₹</span>
                            {items.cost_amount}
                          </h6>
                        </div>
                      ))
                    : null}
                </div>
                <hr style={{ backgroundColor: "black", width: "100%" }} />
                <div className="trip_breakups">
                  <div className="break_ups">
                    <h6>Total :</h6>{" "}
                    <h6>
                      {" "}
                      <span className="rupees">₹</span>
                      {total}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="total_paid">
                <h5> Paid Amount:</h5>{" "}
                <h5 style={{ color: "green" }}>
                  <span className="rupees">₹</span>
                  {paid}
                </h5>
              </div>
              <div className="total_paid">
                <h5>Due Amount:</h5>{" "}
                <h5 style={{ color: "red" }}>
                  {" "}
                  {total - paid == 0 ? (
                    "No due Amount"
                  ) : (
                    <>
                      {" "}
                      <span className="rupees">₹</span>
                      {total - paid}
                    </>
                  )}
                </h5>
              </div>
            </div>

            {/* <h6></h6> */}
          </TabPanel>
        </Box>
      </div>
    </Mini_detail_carrier_card_main_container_payment>
  );
}

// {
//   "is_active": true,
//   "id": 1,
//   "last_name": null,
//   "alternate_number": null,
//   "driving_licence_number": "AH7860051",
//   "ccreated_on": "2022-03-11T13:13:17.704420",
//   "modified_on": "2022-03-11T13:13:17.707110",
//   "first_name": "sonali",
//   "mobile_number": "9534939031",
//   "pan_number": "AAAPZ0071C"
// },

// {
//   "source_id": 9,
//   "approx_cost_id": 5,
//   "actual_cost_id": null,
//   "id": 5,
//   "payments_status": "paid",
//   "load_source": "trip_purchase_order",
//   "created_on": "2022-03-14T11:25:26.316874",
//   "load_unit_id": null,
//   "vehicle_number": "KA-29-2125",
//   "driver_id": 5,
//   "destination_id": 10,
//   "is_active": true,
//   "modified_on": "2022-03-14T11:25:26.319086",
//   "trip_distance_in_km": 78.0,
//   "trip_code": "KU0314251647257126",
//   "zoho_id": "670003",
//   "remarks": null,
//   "trip_status": "created",
//   "attachment_count": 0
// },

export default PaymentsMiniCard;
