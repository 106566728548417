import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { readLoginData } from "./helper/dataDecryptionHelper";

function LoggedInRoute({ component: Component, ...restOfProps }) {
  const loginApp =
    readLoginData() !== undefined ? readLoginData().app_name : "Leap-Supply";
  //   const isAuthenticated = JSON.parse(
  //     localStorage.getItem("IS_LOGGED_IN")
  //   ).IS_LOGGED_IN;

  let isAuthenticated = "";

  if (readLoginData()) {
    isAuthenticated = readLoginData().IS_LOGGED_IN;
  } else {
    isAuthenticated = false;
  }
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default LoggedInRoute;
