import React from "react";
import "./faarmspayment.css";
import ArticleIcon from "@mui/icons-material/Article";
import apiUrl from "../../url/apiUrl";
import { useSelector } from "react-redux";
function FaarmsGlobalPayment() {
  const onlineData = useSelector((state) => state.paymentValues.payment);
  const items = useSelector((state) => state.paymentValues.items);

  // const stringDataOrderId = String(JSON.stringify(onlineData));
  const stringDataOrderId = JSON.stringify(items);

  return (
    <div className="online_payement_container">
      <div className="online_payement_wrapper">
        <div className="online_headings">
          <h5 className="headings_payments">Faarms-Global Online Payment</h5>
        </div>
        <div className="payment_record_id">
          <h6 className="payment_record_text">Order id:</h6>{" "}
          <h6 className="payment_id">{onlineData.uniqueId}</h6>
        </div>
        <div className="payment_record_name">
          <div className="payment_name_div">
            <h6 className="payment_record_amount">
              {/* {items.data.map((name) => name.customer_name)} */}
              {items.data[0].customer_name}
            </h6>
            <h6 className="payment_record_amount">
              ₹ <span></span>
              {onlineData.total}
            </h6>
          </div>
          <div className="payment_name_div">
            <h6 className="payment_phone">
              {" "}
              {/* {items.data.map((name) => name.customer_contact)} */}
              {items.data[0].customer_contact}
            </h6>
          </div>
        </div>
        <div className="payment_record_invoice">
          <ArticleIcon style={{ color: "green" }} />

          <div className="invoice">
            <h6>Invoice number</h6>{" "}
            <h6 style={{ color: "grey" }}>{onlineData.invoice_id}</h6>
          </div>
        </div>
        <div className="button_div">
          <form
            method="POST"
            name="customerData"
            action={apiUrl.paymentRequestCCAvenueUrl}
            className="confirm-online-form-container"
            // class="table"
          >
            <div className="confirm-online-payment-container">
              <input
                type="text"
                name="transaction_source"
                value="faarms_global"
                // value="faarms_commerce"
                readOnly
                hidden
              />
              <input
                type="text"
                name="customer_name"
                value={items.data.map((name) => name.customer_name)}
                readOnly
                hidden
              />
              <input type="text" name="currency" value="inr" readOnly hidden />
              <input
                type="text"
                name="billing_tel"
                value={items.data.map((name) => name.customer_contact)}
                readOnly
                hidden
              />
              <input
                type="text"
                name="order_id"
                value={onlineData.uniqueId}
                // value="16482163578_7257096526_INR"
                readOnly
                hidden
              />
              <input
                type="text"
                name="biller_name"
                value="Faarms"
                // value={`${stringDataOrderId}`}
                readOnly
                hidden
              />

              <input
                type="text"
                name="json_obj"
                value={`${stringDataOrderId}`}
                readOnly
                hidden
              />
              <input
                type="text"
                name="billing_name"
                value={items.data.map((name) => name.customer_name)}
                readOnly
                hidden
              />
              <input
                type="text"
                name="billing_notes"
                value="faarms_global_logistics"
                // value="logistics"
                // value='{"app":"Last-mile-delivery-payment-for-invoice-PB-22-23-0002380","trip_code":"AM0604101654322472","invoice_id":"PB-22-23-0002380","uniqueId":"AM0604101654322472_28824_INR","total":1436,"items":{"trip_items":[{"item_sku":"GALIVRU50KG","is_active":true,"reference_source_id":"575059000023413240","item_name":"VRUDDHI 50kg","created_on":"2022-06-04T06:01:13.843186","item_id":"575059000002372928","item_qty":1,"reference_customer_id":"575059000015411964","item_rate":1436,"modified_on":"2022-06-04T06:01:13.846210","status":"not_delivered","type":"trip_invoice","id":16,"customer_name":"Gurjas ","unloaded_quantity":1,"trip_id":11,"customer_contact":"9810821705","reference_number":"PB-22-23-0002380","reference_source":"zoho"}]}}'
                readOnly
                hidden
              />

              <input
                type="text"
                name="merchant_id"
                value="257375"
                readOnly
                hidden
              />
              <input
                type="text"
                name="customer_id"
                value={items.data.map((name) => name.customer_contact)}
                readOnly
                hidden
              />
              <input
                type="text"
                name="amount"
                value={onlineData.total}
                readOnly
                hidden
              />
              <input
                type="text"
                name="integration_type"
                value="iframe_normal"
                readOnly
                hidden
              />

              <input
                type="text"
                name="redirect_url"
                value={apiUrl.paymentResponseCCAvenueUrl}
                readOnly
                hidden
              />

              <input
                type="text"
                name="cancel_url"
                value={apiUrl.paymentResponseCCAvenueUrl}
                readOnly
                hidden
              />
              {/* <div className="checkout-btn">
                <input
                  type="submit"
                  value="Pay now"
                  className="confirm-online-button"
                  // class="btn btn-success btn-sm"
                />
              </div> */}

              {/* <select hidden name="integration_type">
              <option hidden value="iframe_normal">
                iframe_normal
              </option>
            </select> */}

              <input type="submit" value="Pay Now" className="button_payment" />
            </div>
          </form>
        </div>
      </div>
      <div className="form_main_container"></div>
    </div>
  );
}

export default FaarmsGlobalPayment;
