import { configureStore } from "@reduxjs/toolkit";
import onboardReducer from "./onboardSlice";
import indentReducer from "./indentSlice";
import tripReducer from "./tripSlice";
import routesReducer from "./onboardSlice";
import routesAddReducer from "./onboardSlice";
import searchReducer from "./searchSlice";
import paymentReducer from "./paymentSlice";

const store = configureStore({
  reducer: {
    onboard: onboardReducer,
    trip: tripReducer,
    routesChange: routesReducer,
    routesAddVehicle: routesAddReducer,
    searchValues: searchReducer,
    paymentValues: paymentReducer,
    indent: indentReducer,
  },
});
export default store;
