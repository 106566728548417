import axiosInterceptor from "../config/https";
import apiUrl from "../url/apiUrl";

export const verifyPan = async (panNumber) => {
  try {
    const data = { pan_number: panNumber };
    const res = await axiosInterceptor.post(`${apiUrl.panVerifyUrl}`, data);

    return { response: res, error: null };
  } catch (error) {
    return { response: null, error: error.response };
  }
};

export const verifyAadhaar = async (aadhaarNumber) => {
  try {
    const data = { aadhaar_number: aadhaarNumber };

    const res = await axiosInterceptor.post(`${apiUrl.aadhaarVerifyUrl}`, data);

    return { response: res, error: null };
  } catch (error) {
    return { response: null, error: error.response };
  }
};

export const verifyBank = async (holderName, bankName, ifsc, bankAccNumber) => {
  try {
    const data = {
      account_holder: holderName,
      bank_name: bankName,
      ifsc: ifsc,
      account_number: bankAccNumber,
      entity_type: "transporter",
      requested_by: "admin",
      microservice_source: "core-services",
    };

    const res = await axiosInterceptor.post(
      `${apiUrl.bankAccountBaseUrl}/verify`,
      data
    );

    return { response: res, error: null };
  } catch (error) {
    return { response: null, error: error.response };
  }
};

export const verifyGST = async (gstNumber) => {
  try {
    const data = {
      gstin_number: gstNumber,
    };

    const res = await axiosInterceptor.post(
      `${apiUrl.gstBaseUrl}/verify`,
      data
    );

    return { response: res, error: null };
  } catch (error) {
    return { response: null, error: error.response };
  }
};
