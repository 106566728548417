import axios from "axios";
import axiosInterceptor from "../../../config/https";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import apiUrl from "../../../url/apiUrl";

export const verifySellerOtp = (otp, tripId, invoiceNumber, orderNumber) => {
  let data = JSON.stringify({
    otp: otp,
    platform_sales_order_number: orderNumber,
    invoice_number: invoiceNumber,
    trip_id: tripId,
  });
  var config = {
    method: "post",
    url: `${apiUrl.pickupVerificationHistory}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status >= 200 && response.status <= 300) {
          resolve(response);
        }
      })
      .catch(function (error) {
        resolve(error.response);
      });
  });
};

export const GetTripPickupInvoices = async (tripCode) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.faarmsTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
        tripCode
      )}/details`
    );

    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    return e.response;
    // throw e;
  }
};
