export const BankListDetails = [
  {
    bankName: "Bank of Baroda",
    minBankAccountLength: 14,
    maxBankAccountLength: 14,
    ifsc: "BARB",
  },
  {
    bankName: "Bank of India",
    minBankAccountLength: 15,
    maxBankAccountLength: 15,
    ifsc: "BKID",
  },
  {
    bankName: "Bank of Maharashtra",
    minBankAccountLength: 11,
    maxBankAccountLength: 11,
    ifsc: "MAHB",
  },
  {
    bankName: "Canara Bank",
    minBankAccountLength: 13,
    maxBankAccountLength: 13,
    ifsc: "CNRB",
  },
  {
    bankName: "Central Bank of India",
    minBankAccountLength: 10,
    maxBankAccountLength: 10,
    ifsc: "CBIN",
  },
  {
    bankName: "Indian Bank",
    minBankAccountLength: 9,
    maxBankAccountLength: 9,
    ifsc: "IDIB",
  },
  {
    bankName: "Indian Overseas Bank",
    minBankAccountLength: 15,
    maxBankAccountLength: 15,
    ifsc: "IOBA",
  },
  {
    bankName: "Punjab & Sind Bank",
    minBankAccountLength: 14,
    maxBankAccountLength: 14,
    ifsc: "PSIB",
  },
  {
    bankName: "Punjab National Bank",
    minBankAccountLength: 14,
    maxBankAccountLength: 16,
    ifsc: "PUNB",
  },
  {
    bankName: "State Bank of India",
    minBankAccountLength: 11,
    maxBankAccountLength: 11,
    ifsc: "SBIN",
  },
  {
    bankName: "UCO Bank",
    minBankAccountLength: 14,
    maxBankAccountLength: 14,
    ifsc: "UCBA",
  },
  {
    bankName: "Union Bank of India",
    minBankAccountLength: 13,
    maxBankAccountLength: 15,
    ifsc: "UBIN",
  },
  {
    bankName: "Axis Bank Ltd.",
    minBankAccountLength: 12,
    maxBankAccountLength: 15,
    ifsc: "UTIB",
  },
  {
    bankName: "Bandhan Bank Ltd.",
    minBankAccountLength: 14,
    maxBankAccountLength: 14,
    ifsc: "BDBL",
  },
  {
    bankName: "CSB Bank Limited",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "CSBK",
  },
  {
    bankName: "City Union Bank Ltd.",
    minBankAccountLength: 15,
    maxBankAccountLength: 15,
    ifsc: "CIUB",
  },
  {
    bankName: "DCB Bank Ltd.",
    minBankAccountLength: 14,
    maxBankAccountLength: 14,
    ifsc: "DCBL",
  },
  {
    bankName: "Dhanlaxmi Bank Ltd.",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "DLXB",
  },
  {
    bankName: "Federal Bank Ltd.",
    minBankAccountLength: 14,
    maxBankAccountLength: 14,
    ifsc: "FDRL",
  },
  {
    bankName: "HDFC Bank Ltd",
    minBankAccountLength: 12,
    maxBankAccountLength: 14,
    ifsc: "HDFC",
  },
  {
    bankName: "ICICI Bank Ltd.",
    minBankAccountLength: 12,
    maxBankAccountLength: 12,
    ifsc: "ICIC",
  },
  {
    bankName: "IndusInd Bank Ltd",
    minBankAccountLength: 12,
    maxBankAccountLength: 13,
    ifsc: "INDB",
  },
  {
    bankName: "IDFC FIRST Bank Limited",
    minBankAccountLength: 11,
    maxBankAccountLength: 11,
    ifsc: "IDFB",
  },
  {
    bankName: "Jammu & Kashmir Bank Ltd.",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "JAKA",
  },
  {
    bankName: "Karnataka Bank Ltd.",
    minBankAccountLength: 16,
    maxBankAccountLength: 16,
    ifsc: "KARB",
  },
  {
    bankName: "Karur Vysya Bank Ltd.",
    minBankAccountLength: 16,
    maxBankAccountLength: 16,
    ifsc: "KVBL",
  },
  {
    bankName: "Kotak Mahindra Bank Ltd",
    minBankAccountLength: 10,
    maxBankAccountLength: 14,
    ifsc: "KKBK",
  },
  {
    bankName: "Nainital bank Ltd.",
    minBankAccountLength: 16,
    maxBankAccountLength: 16,
    ifsc: "",
  },
  {
    bankName: "RBL Bank Ltd.",
    minBankAccountLength: 12,
    maxBankAccountLength: 12,
    ifsc: "RATN",
  },
  {
    bankName: "South Indian Bank Ltd.",
    minBankAccountLength: 16,
    maxBankAccountLength: 16,
    ifsc: "SIBL",
  },
  {
    bankName: "Tamilnad Mercantile Bank Ltd.",
    minBankAccountLength: 6,
    maxBankAccountLength: 15,
    ifsc: "TMBL",
  },
  {
    bankName: "YES Bank Ltd.",
    minBankAccountLength: 15,
    maxBankAccountLength: 15,
    ifsc: "YESB",
  },
  {
    bankName: "IDBI Bank Limited",
    minBankAccountLength: 12,
    maxBankAccountLength: 12,
    ifsc: "IBKL",
  },
  {
    bankName: "Coastal Local Area Bank Ltd",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Krishna Bhima Samruddhi LAB Ltd",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Au Small Finance Bank Ltd.",
    minBankAccountLength: 16,
    maxBankAccountLength: 16,
    ifsc: "AUBL",
  },
  {
    bankName: "Capital Small Finance Bank Ltd",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "CLBL",
  },
  {
    bankName: "Fincare Small Finance Bank Ltd.",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "FSFB",
  },
  {
    bankName: "Equitas Small Finance Bank Ltd",
    minBankAccountLength: 12,
    maxBankAccountLength: 12,
    ifsc: "ESFB",
  },
  {
    bankName: "ESAF Small Finance Bank Ltd.",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "ESMF",
  },
  {
    bankName: "Suryoday Small Finance Bank Ltd.",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "SURY",
  },
  {
    bankName: "Ujjivan Small Finance Bank Ltd.",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "UJVN",
  },
  {
    bankName: "Utkarsh Small Finance Bank Ltd.",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "UTKS",
  },
  {
    bankName: "North East Small finance Bank Ltd",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "NESF",
  },
  {
    bankName: "Jana Small Finance Bank Ltd",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "JSFB",
  },
  {
    bankName: "Shivalik Small Finance Bank Ltd",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Unity Small Finance Bank Ltd",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Airtel Payments Bank Ltd",
    minBankAccountLength: 14,
    maxBankAccountLength: 14,
    ifsc: "AIRP",
  },
  {
    bankName: "India Post Payments Bank Ltd",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "IPOS",
  },
  {
    bankName: "FINO Payments Bank Ltd",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "FINO",
  },
  {
    bankName: "Paytm Payments Bank Ltd",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "PYTM",
  },
  {
    bankName: "Jio Payments Bank Ltd",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "JIOP",
  },
  {
    bankName: "NSDL Payments Bank Limited",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "NSPB",
  },
  {
    bankName: "Assam Gramin Vikash Bank",
    minBankAccountLength: 13,
    maxBankAccountLength: 13,
    ifsc: "",
  },
  {
    bankName: "Andhra Pradesh Grameena Vikas Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "APGV",
  },
  {
    bankName: "Andhra Pragathi Grameena Bank",
    minBankAccountLength: 11,
    maxBankAccountLength: 11,
    ifsc: "APGB",
  },
  {
    bankName: "Arunachal Pradesh Rural Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Aryavart Bank",
    minBankAccountLength: 15,
    maxBankAccountLength: 15,
    ifsc: "",
  },
  {
    bankName: "Bangiya Gramin Vikash Bank",
    minBankAccountLength: 11,
    maxBankAccountLength: 11,
    ifsc: "",
  },
  {
    bankName: "Baroda Gujarat Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Baroda Rajasthan Kshetriya Gramin Bank",
    minBankAccountLength: 14,
    maxBankAccountLength: 14,
    ifsc: "",
  },
  {
    bankName: "Baroda UP Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Chaitanya Godavari GB",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Chhattisgarh Rajya Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Dakshin Bihar Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Ellaquai Dehati Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Himachal Pradesh Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "J&K Grameen Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Jharkhand Rajya Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Karnataka Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "PKGB",
  },
  {
    bankName: "Karnataka Vikas Gramin Bank",
    minBankAccountLength: 16,
    maxBankAccountLength: 16,
    ifsc: "KVGB",
  },
  {
    bankName: "Kerala Gramin Bank",
    minBankAccountLength: 14,
    maxBankAccountLength: 14,
    ifsc: "KLGB",
  },
  {
    bankName: "Madhya Pradesh Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Madhyanchal Gramin Bank",
    minBankAccountLength: 18,
    maxBankAccountLength: 18,
    ifsc: "",
  },
  {
    bankName: "Maharashtra Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "MAHG",
  },
  {
    bankName: "Manipur Rural Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Meghalaya Rural Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Mizoram Rural Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Nagaland Rural Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Odisha Gramya Bank",
    minBankAccountLength: 11,
    maxBankAccountLength: 11,
    ifsc: "",
  },
  {
    bankName: "Paschim Banga Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Prathama U.P. Gramin Bank",
    minBankAccountLength: 14,
    maxBankAccountLength: 14,
    ifsc: "",
  },
  {
    bankName: "Puduvai Bharathiar Grama Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Punjab Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Rajasthan Marudhara Gramin Bank",
    minBankAccountLength: 12,
    maxBankAccountLength: 12,
    ifsc: "RMGB",
  },
  {
    bankName: "Saptagiri Grameena Bank",
    minBankAccountLength: 11,
    maxBankAccountLength: 11,
    ifsc: "",
  },
  {
    bankName: "Sarva Haryana Gramin Bank",
    minBankAccountLength: 14,
    maxBankAccountLength: 14,
    ifsc: "",
  },
  {
    bankName: "Saurashtra Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Tamil Nadu Grama Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Telangana Grameena Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Tripura Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Uttar Bihar Gramin Bank",
    minBankAccountLength: 16,
    maxBankAccountLength: 16,
    ifsc: "",
  },
  {
    bankName: "Utkal Grameen Bank",
    minBankAccountLength: 12,
    maxBankAccountLength: 12,
    ifsc: "",
  },
  {
    bankName: "Uttarbanga Kshetriya Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Vidharbha Konkan Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Uttarakhand Gramin Bank",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "",
  },
  {
    bankName: "Citibank N.A.",
    minBankAccountLength: 10,
    maxBankAccountLength: 10,
    ifsc: "CITI",
  },
  {
    bankName: "DBS Bank India Limited",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "DBSS",
  },
  {
    bankName: "Deutsche Bank A.G.",
    minBankAccountLength: 0,
    maxBankAccountLength: null,
    ifsc: "DEUT",
  },
  {
    bankName: "Standard Chartered Bank",
    minBankAccountLength: 11,
    maxBankAccountLength: 11,
    ifsc: "SCBL",
  },
  {
    bankName: "Shri Swami Samarth Sahakari Bank Ltd.",
    minBankAccountLength: 15,
    maxBankAccountLength: 15,
    ifsc: "HDFC",
  },
];
