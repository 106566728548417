const darkTheme = {
  primaryColor: "#121212",
  secondaryColor: "#211E2B",
  paragraphColor: "#282828",
  white: "#f9f9f9",
  lightWhite: "#FFFFFFDE",
  lightBlack: "#8B8B8B",
  secondaryWhite: "#c7ddcc",
  transparent: "transparent",
  secondaryGreen: "#5047aa",
  paragraphSecondaryolor: "#CACACA",
  boxShadowPrimary: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
  red: "red",
  primaryRed: "#a52527",
  black: "#282828",
  snakbarColor: "#fde989",
  primaryCodeColor: "#0fbc9e",
  transition: "9s ease all",
  lightGreen: "#242d30",
};

const lightTheme = {
  primaryColor: "#076b38",

  secondaryColor: "#ffd53e",
  paragraphColor: "#454545",
  lightBlack: "#f9f9f9",
  white: "#fff",
  secondaryWhite: "#f9f9f9",
  transparent: "transparent",
  secondaryGreen: "#65C368",
  paragraphSecondaryolor: "rgb(65, 65, 64)",
  boxShadowPrimary: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
  red: "red",
  primaryRed: "#a52527",
  black: "#000",
  snakbarColor: "#fde989",
  primaryCodeColor: "#0fbc9e",
  lightGreen: "#e8f9e0",
};

// ==================================================

export const typography = {
  heading1: "32px",
  heading2: "24px",
  heading3: "18px",
  heading4: "16px",
  heading5: "14px",
  heading6: "10px",

  paragraph: "12px",
  paragraph2: "10px",
  paragraph_secondary: "11px",
};

export const FontWeight = {
  bold: "700",
  semibold: "600",
  medium: "500",
  regular: "400",
};

export const FontFamily = {
  primaryFontFamily: "Poppins",
};

// ==================================================

export const colorPalette = lightTheme;
export const colorPaletteDark = darkTheme;
