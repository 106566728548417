import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../../themes/theme";

export const CreateIndentStyle = styled.div`
  .create-indent-main-container {
    display: flex;
  }
  .create-indent-container {
    background: ${colorPalette.secondaryWhite};
    width: 100%;
    @media only screen and (max-width: 480px) {
      background: ${colorPalette.secondaryWhite};
      width: 100%;
    }
  }
  .create-indent-text {
    color: ${colorPalette.paragraphColor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.heading2};
    line-height: 36px;
    margin-left: 4%;
    @media only screen and (max-width: 480px) {
      color: ${colorPalette.paragraphColor};
      font-weight: ${FontWeight.bold};
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: ${typography.heading5};
      line-height: 36px;
      margin-left: 4%;
    }
  }
  .create-indent-input {
    width: 80%;
    @media only screen and (max-width: 480px) {
      width: 100%;
      /* margin: 10px; */
    }
  }
  .create-indent-input-contain {
    flex: 1;
    margin: 10px;
    @media only screen and (max-width: 480px) {
      flex: 1;
      /* margin: 10px; */
    }
  }
  .create-indent-save-button {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.heading5};
    cursor: pointer;
    line-height: 21px;
    border: 1px solid ${colorPalette.primaryColor};
    border-radius: 50px;
    padding: 10px 20px;
    width: 200px;
    background-color: ${colorPalette.white};
    @media only screen and (max-width: 480px) {
      /* flex: 1; */
      /* margin: 10px; */
      color: ${colorPalette.primaryColor};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.heading5};
      cursor: pointer;
      line-height: 21px;
      border: 1px solid ${colorPalette.primaryColor};
      border-radius: 50px;
      padding: 10px 20px;
      width: 150px;
      background-color: ${colorPalette.white};
    }
  }
  .create-indent-send-button {
    color: ${colorPalette.white};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.heading5};
    line-height: 21px;
    border: none;
    cursor: pointer;
    border-radius: 50px;
    width: 150px;
    border: 1px solid ${colorPalette.primaryColor};
    /* width: 150px; */
    padding: 10px 20px;
    background-color: ${colorPalette.primaryColor};
  }
  .indent-btn-container {
    margin: 10px;
    @media only screen and (max-width: 480px) {
      margin: 0px;
      margin-top: 20px;
      padding: 10px;
    }
  }
  .create-indent-row-btn {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width: 480px) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .create-indent-label {
    color: ${colorPalette.paragraphColor};
    font-weight: ${FontWeight.semibold};
    font-size: ${typography.heading5};
    line-height: 21px;
  }
  .create-indent-row {
    display: flex;
    width: 100%;
  }
  .create-indent-form {
    background-color: ${colorPalette.white};
    margin: 50px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border-radius: 5px;
    @media only screen and (max-width: 480px) {
      background-color: ${colorPalette.white};
      margin: 10px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px;
      border-radius: 5px;
    }
  }
  .create-indent-top {
    background: ${colorPalette.white};
    width: 100%;
    height: 112px;
    display: flex;

    /* justify-content: center; */
    align-items: center;
    @media only screen and (max-width: 480px) {
      background: ${colorPalette.white};
      width: 100%;
      height: 40px;
      padding: 2rem 0rem;
      display: flex;

      /* justify-content: center; */
      align-items: center;
    }
  }
  .indent-add-source {
    color: ${colorPalette.paragraphColor};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading5};
    line-height: 21px;
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: flex-end;
    cursor: pointer;
  }
  .create-indent-row-grid {
    display: grid;
    grid-template-columns: auto auto;
    @media only screen and (max-width: 480px) {
      display: grid;
      grid-template-columns: auto;
    }
  }
`;
