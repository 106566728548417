import React from "react";
import { TransportStyle } from "./transport";

const Transport = () => {
  return (
    <TransportStyle className="transport">
      <div className="transport-heading">Transport process</div>
      <div className="tracking-transport">
        <img
          src="/assets/landingPageAssets/LogisticsFlow_2.gif"
          alt="trackin logistics"
        />
      </div>
      <div className="transport_company_mobile">
        <div className="stepper"></div>
        <div className="transport_cards">
          <div className="transport_card">
            <div className="transport_card_heading">Create Trip</div>
            <div className="transport_card_text">
              You can create a trip accross all the pincode in india.
            </div>
          </div>
          <div className="transport_card">
            <div className="transport_card_heading">Approved</div>
            <div className="transport_card_text">
              Our platform provides multiple user roles where admin can controll
              on appoval of trip
            </div>
          </div>
          <div className="transport_card">
            <div className="transport_card_heading">InTransist</div>
            <div className="transport_card_text">
              Now you can track your orders through Sattus tracking.
            </div>
          </div>
          <div className="transport_card">
            <div className="transport_card_heading">Delivered</div>
            <div className="transport_card_text">
              Track all the delivery status and get details instantly{" "}
            </div>
          </div>
        </div>
      </div>
    </TransportStyle>
  );
};

export default Transport;
