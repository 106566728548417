import { React, useContext, useEffect, useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import axios from "axios";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Component_style } from "../../themes/component_style";
import { useHistory } from "react-router-dom";
import apiUrl from "../../url/apiUrl";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { VehicleStyle } from "./VehicleStyle.js";
import { DarkModeContext } from "../../context/darkModeContext.js";
function OnboardSuccess() {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const dispatch = useDispatch();
  let history = useHistory();
  const searchParams = new URLSearchParams(document.location.search)
  return (
    <VehicleStyle className="success_onboard_container" mode={darkMode}>
      <div className="success_onboard_wrapper">
        <img src={success} alt="" width="120px" height="90px" />
        <h3 className="success_text">
          <VerifiedIcon style={{ marginRight: "1rem", color: "green" }} />{" "}
          Onboarded Successfully!
        </h3>
        <div style={{marginTop:"10px", marginBottom:"10px", fontSize:"14px", fontWeight:"500", textAlign:"center"}}>
          <div>Transporter Name : {searchParams.get('name')}</div>
          <div>Transporter Code : {searchParams.get('transporter_code')}</div>
        </div>
        <NavLink to="/transporters" style={{ textDecoration: "none" }}>
          {" "}
          <h6 className="details"> View Details</h6>
        </NavLink>
      </div>
    </VehicleStyle>
  );
}

export default OnboardSuccess;
