import React from "react";
import { TripListDrawerCardStyle } from "./tripListDrawerCard.js";

const TripListDrawerCard = ({ heading, data }) => {
  return (
    <TripListDrawerCardStyle>
      <div className="card-heading">{heading}</div>
      <div className="seprator"></div>
      <table className="data-table">
        {data.map((item) => (
          <tr>
            <td style={{ width: "140px" }} className="table-head">
              {item.title}
            </td>
            <td className="table-body">{item.description}</td>
          </tr>
        ))}
      </table>
    </TripListDrawerCardStyle>
  );
};

export default TripListDrawerCard;
