import React, { useCallback, useContext, useEffect, useRef } from "react";
import CustomerSidebar from "../../../components/logisticsCustomerComponents/CustomerSidebar/CustomerSidebar";
import { IndentDrawer, TripIndentStyle } from "./TripIndentStyle";
import INFOICON from "../../../assets/infoIcon.svg";
import EDIT_WHITE from "../../../assets/logisticsCustomer/edit_white.svg";
import showIcons from "../../../assets/tripIcons/view.svg";
import downloadIcons from "../../../assets/tripIcons/download.svg";
import EditIcon from "@mui/icons-material/Edit";
import SEARCH_GRAY from "../../../assets/logisticsCustomer/search_gray.svg";

import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Box,
  CircularProgress,
  Drawer,
  Modal,
  Snackbar,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  fetchIndentListApi,
  getAttachementById,
  markIndentPatch,
} from "./customerIndentServices";
import moment from "moment";
import IndentInfoCard from "../../../components/logisticsCustomerComponents/IndentInfoCard/IndentInfoCard";
import RIghtbarIndent from "../../../components/logisticsCustomerComponents/rightbarIndent/RIghtbarIndent";
import { AcceptQuotesDrawer } from "../../../components/logisticsCustomerComponents/rightbarIndent/rightbarIndentStyle";
import {
  indentId,
  updateOnFlag,
  updateOnFlagUpdate,
} from "../../../redux/indentSlice";
import { useDispatch } from "react-redux";
import { ModalUnstyled } from "@mui/base";
import CopyText from "../../../components/copyText/CopyText";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import MobileHeaderCustomer from "../../../components/MobileHeader/MobileHeaderCustomer";
// import AsyncSelect from "react-select/dist/declarations/src/Async";
import AsyncSelect from "react-select/async";
import {
  ButtonPrimary,
  ButtonRectangle,
  ButtonRectangleSecondary,
  ButtonSecondary,
} from "../../../themes/components/button";
import styled from "styled-components";
import { Close } from "@mui/icons-material";
import imageloader from "../../../assets/tripIcons/ZKZg.gif";
import { DarkModeContext } from "../../../context/darkModeContext";
import Topbar from "../../../components/topbar/Topbar.jsx";
import {
  changeAssignee,
  createAssignee,
  fetchAssignmentNames,
  indentExport,
} from "../createIndentPage/createIndentServices";
import { readLoginData, readToken } from "../../../helper/dataDecryptionHelper";
import apiUrl from "../../../url/apiUrl";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../../themes/theme";
import NotAuthorized from "../../../components/notAuthorized/NotAuthorized.jsx";
import SomethingWentWrong from "../../../components/somethingWentWrong/SomethingWentWrong.jsx";
import { Input, Modal as ModalAntd, Alert as AntdAlert, Popover } from "antd";
import { showOrDownlaodAttachmentsUtility } from "../../../utility/FetchImage.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { imageModalStyle, pdfEmbedStyle } from "../../../themes/commonStyle.js";
import ImageWithZoom from "../../../components/ImageWithZoom/ImageWithZoom.jsx";

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;
const styleQuotationBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  borderRadius: "10px",
  // border: "1px solid #fff",
  boxShadow: 24,
  p: 0,
};
const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${isMobile ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0.5)"};
  -webkit-tap-highlight-color: transparent;
`;
const tripRoutesBoxStyle = {
  width: isMobile ? "90vw" : "50%",
  height: isMobile ? "auto" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -24,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};
function TripsIndentsPage() {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const [openTripRoutes, setOpenTripRoutes] = useState(false);
  const [selectedAssignmentId, setSelectedAssignmentId] = useState("");
  const [openTripDocs, setOpenTripDocs] = useState(false);
  const handleOpenImage = () => setOpenTripDocs(true);
  const [loadingImage, setLoadingImage] = useState(false);
  const handleCloseImage = () => setOpenTripDocs(false);
  const [selectedIndentId, setSelectedIndentId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [selectedAssigneeName, setSelectedAssigneeName] = useState("");
  const [errorInAssigneeCreation, setErrorInAssigneeCreation] = useState("");
  const [assigneeId, setAssigneeId] = useState(0);
  const indentSuccess = useSelector((state) => state.indent.flagOn);
  const indentSuccessUpdate = useSelector((state) => state.indent.flagOnUpdate);
  const [currentTab, setCurrentTab] = useState("draft-indents");
  const [selectedTrip, setSelectedTrip] = useState({});
  const [allIndentData, setAllIndentData] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedIndent, setSelectedIndent] = useState();
  const [loadingMore, setLoadingMore] = useState(true);
  const [loadingForMarkAsSent, setLoadingForMarkAsSent] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [changeTrue, setChangeTrue] = useState(false);
  const [DrawerOpen, setDrawerOpen] = useState(false);
  const [markSentRemark, setMarkSentRemark] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isUserNotAuthorized, setIsUserNotAuthorized] = useState(false);
  // const [errMessage, setErrMessage] = useState("");
  const [errType, setErrType] = useState();
  const [IndentExportEmail, setIndentExportEmail] = useState("");
  const [IndentExportEmailError, setIndentExportEmailError] = useState("");
  const [ExportButtonLoading, setExportButtonLoading] = useState(false);

  const handleChangeTab = (tabName) => {
    if (!loadingPage) {
      setAllIndentData([]);
      setLoadingPage(true);
      setCurrentTab(tabName);
      setCurrentPage(1);
    }
  };
  useEffect(() => {
    setIndentExportEmail(readLoginData().USER_EMAIL);
  }, []);

  const handleExportIndent = async () => {
    console.log("email", IndentExportEmail);
    try {
      setExportButtonLoading(true);
      const faarmsAndConnectIndiaEmailRegex =
        /\b[A-Za-z0-9._%+-]+@(faarms\.com|bscxpress\.com|connectindia\.com)\b/;

      if (faarmsAndConnectIndiaEmailRegex.test(IndentExportEmail)) {
        setIndentExportEmailError("");
        const response = await indentExport(IndentExportEmail);
        console.log("response", response);
        if (response.status === 202) {
          // setOpenToast(true);
          toast.success("Exported Successfully");
        } else if (response.status === 401) {
          toast.error("401: Not enough permissions");
        }
      } else {
        setIndentExportEmailError("Please enter valid email");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setExportButtonLoading(false);
      setIndentExportEmail("");
    }
  };
  const getIndentsList = async (currentPage) => {
    if (currentPage == 1) {
      setLoadingPage(true);
    }
    setLoadingMore(true);
    const response = await fetchIndentListApi(currentPage);
    if (response.status === 200) {
      if (currentPage > 1) {
        setAllIndentData([
          ...allIndentData,
          ...response.data?.trip_indent_list,
        ]);
        setLoadingMore(false);
        setIsLastPage(response.data?.is_last_page);
      } else {
        setAllIndentData(response.data?.trip_indent_list);
        // setSelectedIndent(response.data?.trip_indent_list[0]);
        setLoadingPage(false);
        setLoadingMore(false);
        setIsLastPage(response.data?.is_last_page);
      }
    }
    if (response.status === 401) {
      setIsUserNotAuthorized(true);
    }
  };

  useEffect(() => {
    getIndentsList(currentPage);
  }, [currentPage]);

  const getStopType = (indentData) => {
    if (indentData?.source?.length > 1) {
      return "Multiple stop";
    }
    if (indentData?.destination?.length > 1) {
      return "Multiple stop";
    }
    return "Single stop";
  };

  const handleCreateIndent = () => {
    setIndentExportEmailError("")
    dispatch(indentId({}));
    dispatch(updateOnFlagUpdate(false));
    dispatch(updateOnFlag(false));
    history.push("/create-indent");
  };

  const handleMarkIndentAsSent = async () => {
    setLoadingForMarkAsSent(true);
    const response = await markIndentPatch(selectedIndent?.id, markSentRemark);
    if (response.status >= 200 && response.status < 300) {
      setOpenSnackbarSentSucccess(true);
      setLoadingForMarkAsSent(false);
      // setErrMessage("Successfully Mark as Sent");
      setOpenSnackbar(true);
      setErrType("success");
      setMarkSentRemark("");
      setDrawerOpen(false);
      getIndentsList(currentPage);
    } else {
      setLoadingForMarkAsSent(false);
      // setErrMessage("Error");
      setOpenSnackbar(true);
      setErrType("error");
    }
  };

  // snackbar

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "left",
  });

  const { vertical, horizontal } = snackbarPosition;

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // pagination code

  const observer = useRef();
  const lastIndent = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage(currentPage + 1);
          setLoadingMore(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage, currentPage]
  );
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  // const handleQuotes = () => {
  //   history.push("/customer/customer-quotes");
  // };
  const [open, setOpen] = React.useState(false);
  const [quotationDetails, setQuotationDetails] = useState({});

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const [openSnackbarSentSucccess, setOpenSnackbarSentSucccess] =
    useState(false);
  const [openSnackAssigneeSucccess, setOpenSnackAssigneeSucccess] =
    useState(false);
  const handleCloseSnackbarSuccess = () => {
    setOpenSnackbarSuccess(false);
  };
  const handleCloseSnackbarSent = () => {
    setOpenSnackbarSentSucccess(false);
  };
  const handleCloseSnackbarAssignee = () => {
    setOpenSnackAssigneeSucccess(false);
  };
  useEffect(() => {
    if (indentSuccess == true) {
      setOpenSnackbarSuccess(true);
    } else {
      return;
    }
  }, []);
  useEffect(() => {
    if (indentSuccessUpdate == true) {
      setOpenSnackbarSentSucccess(true);
    } else {
      return;
    }
  }, []);

  const handleOpenTripRoute = (e, trip) => {
    setSelectedTrip(trip);
    setOpenTripRoutes(true);
    e.stopPropagation();
  };
  const handleCloseTripRoute = () => {
    setOpenTripRoutes(false);
  };
  const handleEdit = (id) => {
    dispatch(indentId({ id: id, editMode: true }));
    history.push("/create-indent");
  };
  const handleFetchAssignee = async (values) => {
    //
    const assigneeResponse = await fetchAssignmentNames(values);

    return assigneeResponse.data.users_list.map((item) => {
      return {
        value: item.id,
        name: item.first_name,
        email: item.email,
        last_name: item.last_name,

        label: (
          <div>
            <div>
              <div>
                <div>
                  <span className="create-trip-transporter-number-text">
                    {item.first_name}
                    <span style={{ marginLeft: "3px" }}> {item.last_name}</span>
                  </span>
                </div>
              </div>

              {/* <div style={{ zIndex: "1000" }}>
                <span
                  className="create-trip-sub-text"
                  style={{ zIndex: "1000" }}
                >
                  {item.name}
                </span>
              </div> */}
            </div>
          </div>
        ),
      };
    });
  };
  const createAssigneePost = async () => {
    if (changeTrue) {
      setLoadingForMarkAsSent(true);
      const response = await changeAssignee(
        selectedIndentId,
        userEmail,
        selectedAssignmentId
      );
      if (response.status == 200) {
        setLoadingForMarkAsSent(false);
        closeDrawer();
        setOpenSnackAssigneeSucccess(true);
        getIndentsList(1);
      }
      if (response.status == 400) {
        setLoadingForMarkAsSent(false);
        setErrorInAssigneeCreation(response.data.detail);
      }
      if (response.status == 401) {
        setLoadingForMarkAsSent(false);
        setErrorInAssigneeCreation("Unauthorized!");
        // setErrorInSettingsCreation(response.data.detail);
      }
    } else {
      setLoadingForMarkAsSent(true);
      const response = await createAssignee(
        selectedIndentId,
        userEmail,
        selectedAssigneeName
      );
      if (response.status == 200) {
        setLoadingForMarkAsSent(false);
        closeDrawer();
        setOpenSnackAssigneeSucccess(true);
        getIndentsList(1);
      }
      if (response.status == 400) {
        setLoadingForMarkAsSent(false);
        setErrorInAssigneeCreation(response.data.detail);
      }
      if (response.status == 401) {
        setLoadingForMarkAsSent(false);
        setErrorInAssigneeCreation("Unauthorized!");
        // setErrorInSettingsCreation(response.data.detail);
      }
    }

    //
  };
  // useEffect(() => {
  //   handleFetchAssignee();
  // }, []);
  const closeDrawer = () => {
    setDrawerOpen(false);
    setChangeTrue(false);
    setAssigneeId("");
    setSelectedAssigneeName("");
    setErrorInAssigneeCreation("");
  };

  // ==============downloads=================
  let tokensData = readToken();
  const [imageUrl, setImageUrl] = useState("");
  const [imageUrlPdf, setImageUrlPdf] = useState("");

  const showTripAttachments = async (id) => {
    try {
      setLoadingImage(true);
      const response = await showOrDownlaodAttachmentsUtility(id, "indent");
      if (response.status === 401) {
        toast.error("401: Not enough permissions");
      } else if (
        response.file &&
        (response.attachmentType === "image/png" ||
          response.attachmentType === "image/jpeg")
      ) {
        setImageUrlPdf("");
        setImageUrl(response.file);
      } else {
        setImageUrl("");
        setImageUrlPdf(response.file);
      }
    } catch (error) {
    } finally {
      setLoadingImage(false);
    }
  };

  const download = async (id) => {
    try {
      const response = await showOrDownlaodAttachmentsUtility(
        id,
        "indent",
        "downloadOnly"
      );
      if (response.status === 401) {
        toast.error("401: Not enough permissions");
      }
    } catch (error) { }
  };
  // =========================================
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "auto", // width:
    bgcolor: "background.paper",
    border: `1px solid ${colorPalette.black}`,
    borderRadius: 1,
    boxShadow: 24,
    p: 2,
  };

  const handleChange = () => {
    setChangeTrue(true);
    setAssigneeId("");
    setSelectedAssigneeName("");
    setUserEmail();
    setErrorInAssigneeCreation("");
  };
  return (
    <TripIndentStyle>
      <ToastContainer position="bottom-right" />
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={9999999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong
            backButtonLink="/trips-indents"
            buttonText="Close"
          />
        </div>
      </ModalAntd>
      <Modal
        open={openTripDocs}
        onClose={handleCloseImage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={imageModalStyle}>
          {loadingImage ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <img src={imageloader} alt="" width="20%" height="20%" />
              <div>Loading...</div>
            </div>
          ) : (
            <>
              {imageUrlPdf ? (
                <embed
                  src={imageUrlPdf}
                  type="application/pdf"
                  style={pdfEmbedStyle}
                ></embed>
              ) : imageUrl ? (
                <ImageWithZoom image={imageUrl} />
              ) : null}
            </>
          )}
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbarSuccess}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseSnackbarSuccess}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseSnackbarSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Indent created successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbarSentSucccess}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseSnackbarSent}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseSnackbarSent}
          severity="success"
          sx={{ width: "100%" }}
        >
          Indent Updated successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackAssigneeSucccess}
        autoHideDuration={2000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseSnackbarAssignee}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseSnackbarAssignee}
          severity="success"
          sx={{ width: "100%" }}
        >
          Assigned successfully!
        </Alert>
      </Snackbar>
      <Drawer
        open={DrawerOpen}
        anchor={"right"}
        onClose={() => {
          closeDrawer();
          // setRemark("");
        }}
        PaperProps={{
          sx: {
            width: isMobile ? 320 : 440,
          },
        }}
      >
        <IndentDrawer className="accept_drawer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => {
                closeDrawer();
              }}
            >
              X
            </h4>
          </div>
          {changeTrue ? (
            <div className="accept_drawer_heading">Change Assignee </div>
          ) : (
            <div className="accept_drawer_heading">Assign </div>
          )}

          <div
            className="trip-settings-row_add_customer"
            style={{ marginTop: "10px" }}
          >
            {/* <div className="create-trip-input-head">
                  {" "}
                  Select Consignor
                  <sup style={{ color: "red" }}>*</sup>
                </div> */}
            <AsyncSelect
              className="input_field_trip_settings"
              defaultOptions
              key={assigneeId}
              placeholder="search Assignee..."
              value={
                assigneeId
                  ? {
                    value: assigneeId,
                    label: selectedAssigneeName,
                  }
                  : ""
              }
              onChange={(e) => {
                setAssigneeId(e.value);
                setSelectedAssigneeName(e.name);
                setUserEmail(e.email);
                setErrorInAssigneeCreation("");
              }}
              loadOptions={(inputValue) => handleFetchAssignee(inputValue)}
            />
          </div>

          {errorInAssigneeCreation ? (
            <div className="error-assignee">{errorInAssigneeCreation}</div>
          ) : null}
          { }
          {selectedAssigneeName ? (
            <div className="indent-button-assignee">
              <ButtonRectangle
                onClick={() => createAssigneePost()}
                disabled={loadingForMarkAsSent == true ? true : false}
              >
                {loadingForMarkAsSent ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  "Submit"
                )}
              </ButtonRectangle>
            </div>
          ) : null}
          <div className="edit-assigned">
            Already Assigned ?{" "}
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={handleChange}
            >
              Change
            </span>
          </div>
        </IndentDrawer>
      </Drawer>
      <StyledModal
        open={openTripRoutes}
        onClose={handleCloseTripRoute}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={tripRoutesBoxStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="trip-pickup-modal-head"
                style={{ fontSize: "16px", fontWeight: "600" }}
              >
                <div> Trip Routes </div>{" "}
                <div style={{ marginLeft: "4px" }}>
                  ( Trip code : {selectedTrip?.trip_code} )
                </div>
              </div>
              <div>
                <Close
                  onClick={handleCloseTripRoute}
                  style={{ cursor: "pointer" }}
                />{" "}
              </div>
            </div>
            <div>
              <div className="trip-list-address">
                <div className="trip-location-list">
                  {selectedTrip.source?.map((source) => {
                    return <div className="trip-location-text">From</div>;
                  })}

                  {selectedTrip.destination?.map((destinations) => {
                    return <div className="trip-location-text">To</div>;
                  })}
                </div>
                <div className="trip-list-path">
                  {selectedTrip.source?.map((source, index) => {
                    return (
                      <>
                        {index == 0 ? null : (
                          <>
                            <div className="trip-circle"></div>
                            <div
                              className="mini-trip-rectangle"
                              style={{
                                backgroundColor: "grey",
                                height: "40px",
                                width: "2px",
                              }}
                            ></div>
                          </>
                        )}
                      </>
                    );
                  })}
                  {selectedTrip.destination?.map((destinations) => {
                    return (
                      <>
                        <div className="trip-circle"></div>
                        <div
                          className="mini-trip-rectangle"
                          style={{
                            backgroundColor: "grey",
                            height: "40px",
                            width: "2px",
                          }}
                        ></div>
                      </>
                    );
                  })}
                  <div className="trip-circle"></div>
                </div>
                <div className="trip-location-list">
                  {selectedTrip.source?.map((source) => {
                    return (
                      <div className="trip-location-text">
                        {source.village === source.district
                          ? source.village
                          : `${source.village},
                          ${source.district}`}{" "}
                        , {source.state}, {source.postcode}
                      </div>
                    );
                  })}

                  {selectedTrip.destination?.map((destinations) => {
                    return (
                      <div className="trip-location-text">
                        {destinations.village === destinations.district
                          ? destinations.district
                          : `${destinations.village}, ${destinations.district}`}
                        , {destinations.state}, {destinations.postcode}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>
      {/* <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={4000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        key={vertical + horizontal}
      >
        <Alert severity={errType}>{errMessage}</Alert>
      </Snackbar> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={styleQuotationBox}
          style={{ width: isMobile ? "300px" : "700px" }}
        >
          <div className="quotation-details-headings">
            {" "}
            <h4 style={{ textAlign: "center", padding: "10px 0px" }}>
              Quotation Details
            </h4>{" "}
          </div>

          <div
            style={{
              padding: "15px 50px",
              display: "flex",
              width: "100%",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "flex-start" : "center",
              fontSize: isMobile ? "10px" : "14px",
              justifyContent: "space-between",
            }}
          >
            <span className="quotes-text">
              {" "}
              Quotation Code:{" "}
              <span style={{ fontWeight: "bold" }}>
                <CopyText text={quotationDetails?.quotation_code} />{" "}
              </span>{" "}
            </span>
            <span>
              Created On:{" "}
              <span style={{ fontWeight: "bold" }}>
                {/* {moment(quotationDetails?.created_on).format("DD MMM YYYY")} */}
                {moment
                  .utc(quotationDetails?.created_on)
                  .local()
                  .format("DD MMM YYYY hh:mm a")}
              </span>
            </span>
          </div>

          <div
            style={{
              padding: "15px 50px",
              display: "flex",
              width: "100%",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "flex-start" : "center",
              fontSize: isMobile ? "10px" : "14px",
              justifyContent: "space-between",
            }}
          >
            <span>
              <span>
                Quotation Amount:{" "}
                <span style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  Rs. {quotationDetails?.quotation_amount}
                </span>
              </span>
            </span>
            <span>
              <span>
                Advance Amount:
                <span style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  Rs. {quotationDetails?.advance_amount}
                </span>
              </span>
            </span>
          </div>
        </Box>
      </Modal>
      <div style={{ display: "flex", width: "100%" }}>
        {isMobile ? null : (
          <div className="trip-indent-sidebar">
            <div className="main_onboard_Left_box">
              <SideBarMain />
            </div>
          </div>
        )}

        <div className="customerIndent-container">
          {
            isMobile ? (
              <div className="trip-list-mobile-header">
                <MobileHeader headerTitle="Trip Indents" />
              </div>
            ) : (
              <Topbar />
            )
            // <span className="indent-head-text"> Indents</span>
          }
          {isMobile ? (
            <>
              <div className="mobile_button">
                {" "}
                <button className="edit-button" onClick={handleCreateIndent}>
                  <img src={EDIT_WHITE} alt="edit" /> Create Indent
                </button>
              </div>
            </>
          ) : null}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              height: isMobile ? "40px" : "50px",
              background: "#fff",
            }}
          >
            <div className="top-container">
              <div className="tab-menuOption-contain">
                {/* <div
                  className="tab-menu-option"
                  style={
                    currentTab === "draft-indents"
                      ? { color: "#076b38", fontWeight: "600" }
                      : null
                  }
                  onClick={() => handleChangeTab("draft-indents")}
                >
                  Draft
                  {currentTab === "draft-indents" ? (
                    <div className="tab-bottom-rectangle"></div>
                  ) : null}
                </div> */}
                {/* <div
                  className="tab-menu-option"
                  style={
                    currentTab === "sent-indents"
                      ? { color: "#076b38", fontWeight: "600" }
                      : null
                  }
                  onClick={() => handleChangeTab("sent-indents")}
                >
                  Sent
                  {currentTab === "sent-indents" ? (
                    <div className="tab-bottom-rectangle"></div>
                  ) : null}
                </div> */}
                {/* <div
                  className="tab-menu-option"
                  style={
                    currentTab === "unfulfilled-indents"
                      ? { color: "#076b38", fontWeight: "600" }
                      : null
                  }
                  onClick={() => handleChangeTab("unfulfilled-indents")}
                >
                  Unfulfilled
                  {currentTab === "unfulfilled-indents" ? (
                    <div className="tab-bottom-rectangle"></div>
                  ) : null}
                </div> */}
                {/* <div
                  className="tab-menu-option"
                  style={
                    currentTab === "quotaion-accepted-indents"
                      ? { color: "#076b38", fontWeight: "600", width: "350px" }
                      : { width: "350px" }
                  }
                  onClick={() => handleChangeTab("quotaion-accepted-indents")}
                >
                  Fulfilled
                  {currentTab === "quotaion-accepted-indents" ? (
                    <div className="tab-bottom-rectangle"></div>
                  ) : null}
                </div> */}
              </div>

              <div className="create-indent">
                {
                  readLoginData().Role ==
                    "Logistics Executive" ? null : isMobile ? null : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Popover
                          placement="bottomRight"
                          title={"Indent Report"}
                          trigger={["click"]}
                          content={
                            <div
                              style={{
                                width: "250px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                              }}
                            >
                              <Input
                                type="email"
                                placeholder="Enter email"
                                value={IndentExportEmail}
                                onChange={(e) =>
                                  setIndentExportEmail(e.target.value)
                                }
                              />
                              <ButtonRectangle
                                onClick={() => {
                                  handleExportIndent();

                                }}
                                style={{
                                  width: "100px",
                                  height: "30px",
                                  marginTop: "15px",
                                }}
                              >
                                {ExportButtonLoading ? "Loading..." : "Export"}

                              </ButtonRectangle>
                              {IndentExportEmailError !== "" ? (
                                <AntdAlert
                                  message={IndentExportEmailError}
                                  type="error"
                                  showIcon
                                  style={{
                                    width: "100%",
                                    marginTop: "15px",
                                    padding: "2px 10px",
                                  }}
                                />
                              ) : null}
                            </div>
                          }
                        >
                          <ButtonRectangleSecondary
                            style={{ padding: "15px 15px" }}
                            onClick={() => { setIndentExportEmailError("") }}
                          >
                            Export
                          </ButtonRectangleSecondary>
                        </Popover>
                        <button
                          className="edit-button"
                          onClick={handleCreateIndent}
                        >
                          <img src={EDIT_WHITE} alt="edit" /> Create Indent
                        </button>
                      </div>
                    )
                  // <span className="indent-head-text"> Indents</span>
                }
              </div>
            </div>
          </div>
          <div className="customerIndent-main-body">
            <div className="customerIndent-main-body-left">
              {/* <div className="customerIndent-search-row">
                <div className="customerIndent-search-contain">
                  <img
                    src={SEARCH_GRAY}
                    alt="search"
                    style={{ margin: "3px" }}
                  />
                  <input
                    className="customerIndent-search-input"
                    placeholder="Search"
                  />
                </div>
              </div> */}
              {isUserNotAuthorized ? (
                <NotAuthorized />
              ) : loadingPage ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <CircularProgress />{" "}
                </div>
              ) : !loadingPage && allIndentData.length === 0 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  No Indents found!
                </div>
              ) : (
                <div className="indentList-container">
                  {allIndentData?.map((indent) => {
                    return (
                      <div
                        className="intend-card-container"
                        // className={
                        //   selectedIndent.id === indent.id
                        //     ? "intend-card-container indent-selected"
                        //     : "intend-card-container"
                        // }
                        ref={lastIndent}
                        onClick={() => setSelectedIndent(indent)}
                      >
                        {
                          readLoginData().Role ==
                            "Logistics Executive" ? null : isMobile ? null : (
                              <div className="intend-card-edit">
                                <EditIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleEdit(indent.id);
                                  }}
                                />
                              </div>
                            )
                          // <span className="indent-head-text"> Indents</span>
                        }

                        <div className="intend-card-top">
                          <div className="intend-number-text">
                            <CopyText
                              text={
                                indent?.indent_code
                                  ? indent?.indent_code
                                  : " - "
                              }
                            />
                          </div>
                          <div className="intend-amount-text">
                            {indent?.pickup_date ? (
                              <>
                                <span style={{ color: "black" }}>
                                  Pickup Date :{" "}
                                </span>
                                <span>
                                  {moment
                                    .utc(indent?.pickup_date)
                                    .local()
                                    .format("DD MMM YYYY")}
                                </span>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div className="intend-card-top">
                          <div className="intend-amount-text">
                            <span style={{ marginRight: "5px" }}>
                              Vehicle type:
                            </span>
                            {indent?.vehicle_type
                              ? indent?.vehicle_type
                                .replace(/_/g, " ")
                                .toUpperCase()
                              : " - "}
                          </div>
                          <div className="intend-amount-text">
                            <span style={{ marginRight: "5px" }}>
                              Invoice value:
                            </span>
                            ₹{" "}
                            {indent?.value_of_goods
                              ? indent?.value_of_goods
                              : " - "}
                          </div>
                        </div>
                        <div className="intend-card-top">
                          <div className="intend-amount-text">
                            <span style={{ marginRight: "5px" }}>
                              Quantity:
                            </span>
                            <div>
                              {indent?.quantity_of_goods
                                ? indent?.quantity_of_goods
                                : " - "}
                            </div>
                          </div>
                          <div className="intend-amount-text">
                            <span style={{ marginRight: "5px" }}>
                              Material type:
                            </span>
                            <div
                              style={{
                                width: "40px",
                                height: "20px",
                                textoverflow: "ellipsis",
                                whitespace: "nowrap",
                                overflow: "hidden",
                                display: "inline-block",
                              }}
                            >
                              {indent?.material_type
                                ? indent?.material_type
                                : " - "}
                            </div>
                          </div>
                        </div>
                        <div className="intend-card-top">
                          <div className="intend-amount-text">
                            <span style={{ marginRight: "5px" }}>
                              No.of vehicles:
                            </span>
                            <div>
                              {indent?.number_of_vehicles
                                ? indent?.number_of_vehicles
                                : " - "}
                            </div>
                          </div>
                          <div className="intend-amount-text">
                            <span style={{ marginRight: "5px" }}>Remark:</span>
                            <div
                              style={{
                                width: "40px",
                                height: "20px",
                                textoverflow: "ellipsis",
                                whitespace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {" "}
                              {indent?.remark ? indent?.remark : " - "}
                            </div>
                          </div>
                        </div>
                        <div className="intend-card-top">
                          <div className="intend-amount-text">
                            <span style={{ marginRight: "5px" }}>
                              Created by:
                            </span>
                            <div>
                              {indent?.created_by ? indent?.created_by : " - "}
                            </div>
                          </div>
                          <div className="intend-amount-text">
                            <span style={{ marginRight: "5px" }}>
                              Modified by:
                            </span>
                            <div>
                              {indent?.modified_by
                                ? indent?.modified_by
                                : " - "}
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: isMobile ? "5px" : "10px",
                            border: "1px solid grey",
                            borderRadius: "5px",
                          }}
                        >
                          <div onClick={(e) => handleOpenTripRoute(e, indent)}>
                            <img
                              style={{
                                cursor: "pointer",
                                marginRight: "5px",
                                backgroundColor: darkMode
                                  ? colorPaletteDark.white
                                  : "",
                                borderRadius: "50%",
                              }}
                              src={INFOICON}
                              alt="info icon"
                            />
                          </div>
                          <div className="indent-stop-text">
                            {getStopType(indent)}
                          </div>
                          {readLoginData().Role ==
                            "Logistics Executive" ? null : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                // backgroundColor: "red",
                              }}
                            >
                              <img
                                src={showIcons}
                                alt=""
                                width="30px"
                                height="30px"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleOpenImage();
                                  showTripAttachments(indent.id);
                                }}
                              />
                              <img
                                src={downloadIcons}
                                alt=""
                                width="30px"
                                height="30px"
                                style={{ cursor: "pointer" }}
                                onClick={() => download(indent.id)}
                              />
                              <div className="indent-stop-document">
                                Document
                              </div>
                            </div>
                          )}

                          <div className="indents-button-divs">
                            {readLoginData().Role == "Logistics Executive" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={showIcons}
                                  alt=""
                                  width="30px"
                                  height="30px"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleOpenImage();
                                    showTripAttachments(indent.id);
                                  }}
                                />
                                <img
                                  src={downloadIcons}
                                  alt=""
                                  width="30px"
                                  height="30px"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => download(indent.id)}
                                />
                                <div className="indent-stop-document">
                                  Document
                                </div>
                              </div>
                            ) : (
                              <ButtonRectangleSecondary
                                onClick={() => {
                                  setDrawerOpen(true);
                                  setSelectedIndentId(indent.id);
                                  setSelectedAssignmentId(
                                    indent.indent_assignment_id
                                  );
                                }}
                              >
                                Assign
                              </ButtonRectangleSecondary>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {loadingMore ? (
                    <div className="loadingMore">Loading More ...</div>
                  ) : null}
                </div>
              )}
            </div>
            {/* {isMobile ? null : (
              <RIghtbarIndent
                selectedIndent={selectedIndent}
                currentTab={currentTab}
              />
            )} */}
          </div>
        </div>
      </div>
    </TripIndentStyle>
  );
}

export default TripsIndentsPage;
