import apiUrl from "../../../url/apiUrl";
import axios from "axios";
import axiosInterceptor from "../../../config/https";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import { current } from "@reduxjs/toolkit";

export const createFeature = (trip_type, feature, defaultBehaviour) => {
  let data = JSON.stringify({
    trip_type: `${trip_type}`,
    feature: `${feature}`,
    default_behaviour: `${defaultBehaviour}`,
  });
  var config = {
    method: "post",
    url: `${apiUrl.onboardingBaseUrl}/v1/trip-settings/create-consignor-settings`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 201) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        // if (error.response.status === 401) {
        //   localStorage.clear();
        //   window.location.reload();
        // }
        resolve(error.response);
      });
  });
};
export const updateFeature = (id, trip_type, feature, defaultBehaviour) => {
  let data = JSON.stringify({
    trip_type: `${trip_type}`,
    feature: `${feature}`,
    default: `${defaultBehaviour}`,
  });
  var config = {
    method: "put",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v1/trip-settings/${prefixSalt()}-${convertToBase64(id)}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        // if (error.response.status === 401) {
        //   localStorage.clear();
        //   window.location.reload();
        // }
        resolve(error.response);
      });
  });
};

export const getTripTypeList = async (page) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/enums/trip-type`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    if (e.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response.status;
    // throw e;
  }
};
export const getFeaturesList = async (page) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/enums/trip-setting-features`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    if (e.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response.status;
    // throw e;
  }
};
export const getDefaultBehaviourList = async (page) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/enums/trip-setting-default-options`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    if (e.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response.status;
    // throw e;
  }
};

export const getTripSettingsList = async (page) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/whitelisted-consignor?consignor_id=*&current_page=${page}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    if (e.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response.status;
    // throw e;
  }
};
export const getwhiteListedConsignorList = async (
  feature,
  tripType,
  currentPage
) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/whitelisted-consignor?feature=${feature}&trip_type=${tripType}&current_page=${currentPage}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    if (e.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
    // throw e;
  }
};

export const creatWhitelistedConsignor = (
  consignorId,
  tripsettingsId
  // defaultBehaviour
) => {
  let data = JSON.stringify({
    trip_settings_id: `${tripsettingsId}`,
    consignor_id: `${consignorId}`,
    // default_behaviour: `${defaultBehaviour}`,
  });

  var config = {
    method: "post",
    url: `${apiUrl.onboardingBaseUrl}/v1/whitelisted-consignor`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 201) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        // if (error.response.status === 401) {
        //   localStorage.clear();
        //   window.location.reload();
        // }
        resolve(error.response);
      });
  });
};

export const deleteConsignor = async (consignorId) => {
  try {
    const response = await axiosInterceptor.delete(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/whitelisted-consignor/${prefixSalt()}-${convertToBase64(
        consignorId
      )}`
    );

    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    // if (e.response.status === 401) {
    //   localStorage.clear();
    //   window.location.reload();
    // }
    return e.response.status;
    // throw e;
  }
};
