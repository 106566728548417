import axiosInterceptor from "../../config/https";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";
import apiUrl from "../../url/apiUrl";
import { allRegex } from "../../utility/regex";

export const getTeams = async (searchTerm, status, currentPage) => {
  let findSearchType = Number(searchTerm);
  let url = `${apiUrl.teamsBaseUrl}?is_active=${status}&current_page=${currentPage}`;
  if (searchTerm) {
    if (!isNaN(findSearchType)) {
      url = `${url}&mobile_number=${searchTerm}`;
    } else if (allRegex.emailRegex.test(searchTerm)) {
      url = `${url}&email=${searchTerm}`;
    } else {
      url = `${url}&name=${searchTerm}`;
    }
  }

  try {
    const response = await axiosInterceptor.get(url);
    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    return e.response;
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const getPersonById = async (id) => {
  try {
    const vehiclesListById = await axiosInterceptor.get(
      `${apiUrl.teamsBaseUrl}/${prefixSalt()}-${convertToBase64(id)}`
    );

    if (vehiclesListById.status == 200) {
      return vehiclesListById.data;
    }
  } catch (e) {}
};

export const saveTeam = (teamObj) => {
  let data = JSON.stringify(teamObj);
  var config = {
    method: "post",
    url: `${apiUrl.teamsBaseUrl}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    timeout: 30000,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (!error.response) {
          resolve(error);
        }
        resolve(error.response);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      });
  });
};

export const verifyDlNumber = async (driverDetails) => {
  console.log("verifyDlNumber=====verifyDlNumber===>", driverDetails);
  let data = JSON.stringify({
    driving_license_number: driverDetails.driving_licence_number,
    dob: driverDetails.dob,
  });
  var config = {
    method: "POST",
    url: `${apiUrl.dlVerifyUrl}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    console.log("config====verifyDlNumber===>", config);
    axiosInterceptor(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (error?.response?.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};

export const deleteTeamsById = async (id) => {
  try {
    const vehiclesListById = await axiosInterceptor.delete(
      `${apiUrl.teamsBaseUrl}/${prefixSalt()}-${convertToBase64(id)}`
    );

    if (vehiclesListById.status == 200) {
      return vehiclesListById;
    }
  } catch (e) {
    return e.response;
  }
};
