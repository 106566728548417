import { useEffect, useRef, useState } from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import SearchIcon from "@mui/icons-material/Search";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import UploadImage from "../../assets/upload_icon.svg";
import downloadIcons from "../../assets/tripIcons/download.svg";
import PropTypes from "prop-types";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import BadgeIcon from "@mui/icons-material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Typography from "@mui/material/Typography";
import imageloader from "../../assets/tripIcons/ZKZg.gif";
import { TeamStyle, TeamsDrawerStyle } from "./TeamsStyle.jsx";
import { Modal as ModalAntd } from "antd";
import AddIcon from "@mui/icons-material/Add";
import SomethingWentWrong from "../../components/somethingWentWrong/SomethingWentWrong.jsx";
import Topbar from "../../components/topbar/Topbar.jsx";
import { ButtonRectangle } from "../../themes/components/button.js";
import bussiness_icon from "../../assets/bussiness_icon.jpeg";
import CloseIcon from "@mui/icons-material/Close";
import NotAuthorized from "../../components/notAuthorized/NotAuthorized.jsx";
import { useCallback } from "react";
import CopyText from "../../components/copyText/CopyText.jsx";
import { DarkModeContext } from "../../context/darkModeContext.js";
import { colorPalette, colorPaletteDark } from "../../themes/theme";
import { useContext } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Drawer,
  Menu,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import CommonDrawer from "../../components/commonDrawer/CommonDrawer.jsx";
import { styled } from "@mui/system";
import { ModalUnstyled } from "@mui/base";
import { TextField } from "@mui/material";
import MobileHeader from "../../components/MobileHeader/MobileHeader.jsx";
import { Create_trip_drawer_container } from "../ftl_trips/createTrip/create-tripv2.js";
import { Close, Delete } from "@mui/icons-material";
import * as yup from "yup";
import moment from "moment";
import { allRegex } from "../../utility/regex.js";
import {
  getTeams,
  saveTeam,
  getPersonById,
  deleteTeamsById,
} from "./TeamsService.jsx";
import { showOrDownlaodAttachmentsUtility } from "../../utility/FetchImage.js";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useDebounce } from "../../hooks/useDebounce.js";
import { stateList } from "../../helper/StateList.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;
const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const filterBoxStyle = {
  width: isMobile ? "90vw" : "36%",
  height: isMobile ? "auto" : "65%",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -34,
  marginTop: isMobile ? -10 : -20,
  border: "1px solid gray",
  borderRadius: "10px",
};

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${isMobile ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.5)`};
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? "90%" : "32%",
  height: "80vh",
  border: `1px solid ${colorPalette.black}`,
  borderRadius: 1,
  boxShadow: 24,
  p: 2,
  backgroundColor: "#fff",
};

function Teams() {
  const [loadingButton, setLoadingButton] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [errTotalTeams, setErrTotalTeams] = useState("");
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentpage] = useState(1);
  const [isUserNotAuthorized, setIsUserNotAuthorized] = useState(false);
  const [teamsListData, setTeamsListData] = useState("");
  const [commonDrawerOpen, setCommonDrawerOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [dlFrontFile, setDlFrontFile] = useState(null);
  const [activeTab, setActiveTab] = useState(true);
  const [dlAttachmentPdf, setDlAttachmentPdf] = useState(null);
  const [dlImage, setDlImage] = useState(null);
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const [deleteTeamModal, setDeleteTeamModal] = useState(false);
  const [createTeamsObj, setCreateTeamsObj] = useState({
    name: "",
    email: "",
    mobile_number: "",
    business_type: "0",
    state: "0",
    pincode: "",
  });
  const [errorTeams, setErrorTeams] = useState({
    errorName: "",
    errorEmail: "",
    errorMobileNumber: "",
    errorBussinessType: "",
    errorState: "",
    errorPincode: "",
  });
  const [selectedDetails, setSelectedDetails] = useState({});
  console.log(selectedDetails, "selectedDetails");
  const [state, setState] = useState({ right: false });
  const [showDriverDrawer, setShowDriverDrawer] = useState({ right: false });
  const [cardClicked, setCardClicked] = useState("");
  const { darkMode } = useContext(DarkModeContext);
  const observer = useRef();
  const debouncedSearchTerm = useDebounce(searchTerm);
  const stateListArray = stateList;

  useEffect(() => {
    getTeamsList(debouncedSearchTerm, activeTab, currentPage);
  }, [currentPage, debouncedSearchTerm]);

  const getTeamsList = async (searchTerm, status, currentPage) => {
    setLoader(true);
    try {
      let responseFromTeamsListApi = await getTeams(
        searchTerm,
        status,
        currentPage
      );
      if (responseFromTeamsListApi.status === 200) {
        setLoader(false);
        if (currentPage > 1) {
          setTeamsListData([
            ...teamsListData,
            ...responseFromTeamsListApi.data.teams_list,
          ]);
        } else {
          setTeamsListData(responseFromTeamsListApi.data.teams_list);
        }
        setIsLastPage(responseFromTeamsListApi.data.is_last_page);
      } else if (responseFromTeamsListApi.status === 401) {
        setIsUserNotAuthorized(true);
        setLoader(false);
      } else if (responseFromTeamsListApi.status === 400) {
        setLoader(false);
        setErrTotalTeams(responseFromTeamsListApi.data.detail);
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const schema = yup.object({
    name: yup.string().required(),
    // email: yup.string().required(),
    email: yup
      .string()
      .matches(/^[a-zA-Z0-9._%+-]+@bscxpress\.com$/, {
        message: "Invalid email. Only Gmail addresses are allowed.",
        excludeEmptyString: true, // Treat empty string as invalid
      })
      .required("Email is required"),
    mobile_number: yup.number().min(6000000000).max(9999999999).required(),
    business_type: yup.mixed().required(),
  });

  const handlecancelSearch = () => {
    setCurrentpage(1);
    setSearchTerm("");
    getTeamsList("", activeTab, 1);
  };

  const onChangeSearch = async (search) => {
    setSearchTerm(search);
    setCurrentpage(1);
  };

  function onChangeName(name) {
    setErrTotalTeams("");
    name = name
      .trimStart()
      .replace(/[$&+,:;=?[\]@#|{}'<>.^*_()%_!-/1234567890]/, "");

    setCreateTeamsObj({
      ...createTeamsObj,
      name: name,
    });
    setErrorTeams({
      ...errorTeams,
      errorName: "",
    });
  }

  const handleSelectCard = async (person, index) => {
    // setCardClicked(index);
    // setSelectedDetails({})
    // getdriverDrawer("right", true)
    // let responseFromDriverGetById = await getPersonById(person.id);
    // setSelectedDetails(responseFromDriverGetById)
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function clearState() {
    setCreateTeamsObj({
      name: "",
      email: "",
      mobile_number: "",
      business_type: "0",
      state: "0",
      pincode: "",
    });
    setErrTotalTeams("");
    setErrorTeams({
      errorName: "",
      errorEmail: "",
      errorMobileNumber: "",
      errorBussinessType: "",
      errorState: "",
      errorPincode: "",
    });
  }

  const handleAddDriver = () => {
    toggleDrawer("right", true);
    clearState();
  };

  const lastItem = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentpage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage]
  );

  const handleCloseCommonDrawer = () => {
    setCommonDrawerOpen(false);
  };

  const handleCloseFilterOptions = () => {
    setIsFilterOpen(false);
  };

  const handledlFrontFile = (event) => {
    setDlFrontFile(event.target.files[0]);
  };

  const handleCloseDriverDrawer = () => {
    toggleDrawer("right", false);
  };

  const handleCloseGetDriverDrawer = () => {
    getdriverDrawer("right", false);
  };

  const onchangeEmail = (email) => {
    setErrTotalTeams("");
    setCreateTeamsObj({
      ...createTeamsObj,
      email: email,
    });
    if (email) {
      if (allRegex.allowedEmailInLeapSupply.test(email)) {
        setErrorTeams({
          ...errorTeams,
          errorEmail: "",
        });
      } else {
        setErrorTeams({
          ...errorTeams,
          errorEmail: "Please enter valid email",
        });
      }
    } else {
      setErrorTeams({
        ...errorTeams,
        errorEmail: "",
      });
    }
  };

  const onChangeMobilenumber = (mobileNumber) => {
    setErrTotalTeams("");
    setCreateTeamsObj({
      ...createTeamsObj,
      mobile_number: mobileNumber,
    });
    setErrorTeams({
      ...errorTeams,
      errorMobileNumber: "",
      email: "",
    });
  };
  const onChangeType = (type) => {
    setErrTotalTeams("");
    setCreateTeamsObj({
      ...createTeamsObj,
      business_type: type,
    });
    setErrorTeams({
      ...errorTeams,
      errorBussinessType: "",
    });
  };

  const onChangeState = (state) => {
    setErrTotalTeams("");
    setCreateTeamsObj({
      ...createTeamsObj,
      state: state,
    });
    setErrorTeams({
      ...errorTeams,
      errorState: "",
    });
  };

  const handleFormData = {
    name: createTeamsObj.name,
    email: createTeamsObj.email,
    mobile_number: Number(createTeamsObj.mobile_number),
    business_type: createTeamsObj.business_type,
    State: createTeamsObj.state,
    pincode: createTeamsObj.pincode,
  };

  const handleSave = async () => {
    let errorMsg = {};
    const isDriverValid = await schema.isValid(handleFormData);

    if (
      isDriverValid &&
      createTeamsObj.business_type !== "0" &&
      createTeamsObj.state !== "0"
    ) {
      setLoadingButton(true);
      callApiForCreateTeam();
    } else {
      if (!createTeamsObj.name) {
        errorMsg.errorName = "Please enter name";
      }
      if (!allRegex.nameRegex.test(createTeamsObj.name)) {
        errorMsg.errorName = "Please enter valid name";
      }
      if (!allRegex.allowedEmailInLeapSupply.test(createTeamsObj.email)) {
        errorMsg.errorEmail = "Please enter valid email";
      }
      if (
        !createTeamsObj.mobile_number ||
        createTeamsObj.mobile_number.length !== 10 ||
        !allRegex.mobileNumberRagex.test(createTeamsObj.mobile_number)
      ) {
        errorMsg.errorMobileNumber = "Please enter valid mobile number";
      }
      if (createTeamsObj.business_type === "0") {
        errorMsg.errorBussinessType = "Please select bussiness type";
      }
      if (createTeamsObj.state === "0") {
        errorMsg.errorState = "Please select state";
      }
      console.log(errorMsg, "error===handleSave====>");
    }
    setErrorTeams(errorMsg);
  };

  const callApiForCreateTeam = async () => {
    setLoadingButton(true);
    const responseFromTeamCreateAPi = await saveTeam(createTeamsObj);
    if (
      responseFromTeamCreateAPi.status === 200 ||
      responseFromTeamCreateAPi.status === 201
    ) {
      toggleDrawer("right", false);
      setLoadingButton(false);
      getTeamsList(searchTerm, activeTab, currentPage);
    } else if (responseFromTeamCreateAPi.status === 400) {
      setLoadingButton(false);
      setErrTotalTeams(responseFromTeamCreateAPi.data.detail);
    } else if (responseFromTeamCreateAPi.status === 401) {
      setLoadingButton(false);
      setErrTotalTeams(`401: Not enough permissions`);
    } else {
      setLoadingButton(false);
      setErrTotalTeams(`ERROR : ${responseFromTeamCreateAPi.status}`);
    }
    setLoadingButton(false);
  };

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const getdriverDrawer = (anchor, open) => {
    setShowDriverDrawer({ ...showDriverDrawer, [anchor]: open });
  };

  const onChangeTab = async (tab) => {
    setActiveTab(tab);
    setCurrentpage(1);
    getTeamsList(searchTerm, tab, 1);
    setTeamsListData([]);
  };

  function onChangeName(name) {
    setErrTotalTeams("");
    setCreateTeamsObj({
      ...createTeamsObj,
      name: name,
    });
    setErrorTeams({
      ...errorTeams,
      errorName: "",
    });
  }

  const handleDeleteTeam = async (id) => {
    try {
      const response = await deleteTeamsById(id);
      if (response.status === 200) {
        toast.success("Team deleted successfully");
        getTeamsList("", true, 1);
        setDeleteTeamModal(false);
      } else if (response.status === 401) {
        toast.error("401: Not enough permission");
        setDeleteTeamModal(false);
      }
    } catch (error) {
      console.log("error while deleting teams", error);
    }
  };

  return (
    <TeamStyle mode={darkMode}>
      <ToastContainer position="bottom-right" />
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={() => {
          toggleDrawer("right", false);
        }}
      >
        <Create_trip_drawer_container className="create-trip-drawer-container">
          <div
            className="create-trip-right-most-head"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h4>Team's details:</h4>{" "}
            <Close
              onClick={handleCloseDriverDrawer}
              style={{ marginRight: "20px", cursor: "pointer" }}
            />
          </div>
          <div className="create-trip-from-body">
            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  {" "}
                  Name <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Enter name"
                  value={createTeamsObj.name}
                  onChange={(e) => {
                    onChangeName(e.target.value);
                  }}
                />
                <div className="create-trip-error">
                  {errorTeams.errorName ? errorTeams.errorName : null}
                </div>
              </div>
            </div>
            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  E-mail <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Enter email"
                  value={createTeamsObj.email}
                  onChange={(e) => onchangeEmail(e.target.value)}
                />
                <div className="create-trip-error">
                  {errorTeams.errorEmail ? errorTeams.errorEmail : null}
                </div>
              </div>
            </div>
            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  {" "}
                  Mobile number <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  placeholder="Enter the mobile number"
                  value={createTeamsObj.mobile_number}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      onChangeMobilenumber(e.target.value);
                    }
                  }}
                />
                <div className="create-trip-error">
                  {errorTeams.errorMobileNumber
                    ? errorTeams.errorMobileNumber
                    : null}
                </div>
              </div>
            </div>

            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  Bussiness type <sup style={{ color: "red" }}>*</sup>
                </h4>
                <Select
                  className="create-trip-input-select"
                  id="demo-simple-select"
                  value={createTeamsObj.business_type}
                  style={{ color: colorPalette.black }}
                  placeholder="Select bussiness type"
                  onChange={(e) => {
                    console.log("value===>", e.target.value);
                    onChangeType(e.target.value);
                  }}
                >
                  <MenuItem value={"0"} disabled>
                    Select bussiness type{" "}
                  </MenuItem>
                  <MenuItem value={"Commercial"}>Commercial</MenuItem>
                  <MenuItem value={"Control tower"}>Control tower</MenuItem>
                  <MenuItem value={"Express"}>Express</MenuItem>
                  <MenuItem value={"Finance"}>Finance</MenuItem>
                  <MenuItem value={"FTL"}>FTL -Full truck load</MenuItem>
                  <MenuItem value={"LMD"}>LMD -Last mile delivery</MenuItem>
                </Select>
                <div className="create-trip-error">
                  {errorTeams.errorBussinessType
                    ? errorTeams.errorBussinessType
                    : null}
                </div>
              </div>
            </div>

            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  State <sup style={{ color: "red" }}>*</sup>
                </h4>
                <Select
                  className="create-trip-input-select"
                  id="demo-simple-select"
                  value={createTeamsObj.state}
                  style={{ color: colorPalette.black }}
                  placeholder="Select bussiness type"
                  onChange={(e) => onChangeState(e.target.value)}
                >
                  <MenuItem value={"0"} disabled>
                    Select state{" "}
                  </MenuItem>
                  {stateListArray.map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>;
                  })}
                </Select>
                <div className="create-trip-error">
                  {errorTeams.errorState ? errorTeams.errorState : null}
                </div>
              </div>
            </div>

            <div>
              {errTotalTeams ? (
                <Alert severity="error">{errTotalTeams}</Alert>
              ) : null}
            </div>
            <div className="create-trip-input-container">
              <button
                className="create-trip-save-btn"
                onClick={() => handleSave()}
              >
                {loadingButton ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </Create_trip_drawer_container>
      </Drawer>
      <Drawer
        anchor={"right"}
        open={showDriverDrawer["right"]}
        onClose={() => {
          handleCloseGetDriverDrawer();
        }}
      >
        <TeamsDrawerStyle className="mini-details-tab-container_v2">
          <h3
            style={{
              marginLeft: "15px",
              color: colorPaletteDark.primaryColor,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Teams details
            <Close
              onClick={handleCloseGetDriverDrawer}
              style={{ marginRight: "20px", cursor: "pointer" }}
            />
          </h3>

          <div className="mini-details-single-list">
            <h4 className="mini-details-single-list-left">Name</h4>
            <h4 className="mini-details-single-list-right">
              : {selectedDetails?.name ? selectedDetails?.name : "-"}
            </h4>{" "}
          </div>

          <div className="mini-details-single-list">
            <h4 className="mini-details-single-list-left">Mobile number</h4>
            <h4 className="mini-details-single-list-right">
              :{" "}
              {selectedDetails?.mobile_number ? (
                <CopyText text={selectedDetails.mobile_number} />
              ) : (
                "-"
              )}
            </h4>{" "}
          </div>

          <div className="mini-details-single-list">
            <h4 className="mini-details-single-list-left">email</h4>
            <h4 className="mini-details-single-list-right">
              :{" "}
              {selectedDetails?.email ? (
                <CopyText text={selectedDetails?.email} />
              ) : (
                "-"
              )}
            </h4>{" "}
          </div>
        </TeamsDrawerStyle>
      </Drawer>
      <StyledModal
        open={isFilterOpen}
        onClose={handleCloseFilterOptions}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={filterBoxStyle}>
          <div className="trip-list-filter-main">
            {isMobile ? (
              <div className="trip-list-filter-head">
                <span className="trip-list-filter-main-text">
                  Select filter options
                </span>{" "}
                <CloseIcon onClick={handleCloseFilterOptions} />
              </div>
            ) : (
              <div className="trip-list-filter-head">
                <span
                  className="trip-list-filter-main-text"
                  style={{ marginLeft: "5px" }}
                >
                  Select filter options
                </span>{" "}
                <CloseIcon
                  onClick={handleCloseFilterOptions}
                  style={{ cursor: "pointer" }}
                />
              </div>
            )}

            <div className="trip-list-filter-container">
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Trip code</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip code"
                    className="trip-list-filter-inputfield"
                    // value={filterTripCode}
                    // onChange={(e) => setFilterTripCode(e.target.value)}
                    variant="standard"
                  />
                </div>
              </div>

              <div className="trip-list-filter-button-row">
                <button
                  className="trip-list-filter-button"
                  // onClick={handleClearFilter}
                >
                  Clear
                </button>
                <button
                  className="trip-list-filter-button"
                  onClick={() => {
                    // handleFilterSearch(1);
                    // setCurrentPage(1);
                  }}
                ></button>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>

      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={9999999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong backButtonLink="/teams" buttonText="Close" />
        </div>
      </ModalAntd>

      <Modal
        open={attachmentModal}
        onClose={() => setAttachmentModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            height: isMobile ? "50vh" : "",
            width: isMobile ? "90vw" : "",
            overflowY: isMobile ? "scroll" : "hidden",
            paddingBottom: isMobile ? "80px" : "",
            backgroundColor: darkMode ? "black" : "white",
          }}
        >
          {loadingImage ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <img src={imageloader} alt="" width="30%" height="30%" />
              <div>Loading...</div>
            </div>
          ) : (
            <>
              {dlAttachmentPdf ? (
                <embed
                  src={dlAttachmentPdf}
                  type="application/pdf"
                  width={isMobile ? "100%" : "600px"}
                  height={isMobile ? "100%" : "600px"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></embed>
              ) : dlImage ? (
                <TransformWrapper
                  initialScale={1}
                  initialPositionX={0}
                  initialPositionY={0}
                >
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                      <div style={{ margin: "10px 0px" }}>
                        <button
                          style={{ padding: "3px" }}
                          onClick={() => zoomIn()}
                        >
                          Zoom In
                        </button>
                        <button
                          style={{ padding: "3px", marginLeft: "5px" }}
                          onClick={() => zoomOut()}
                        >
                          Zoom Out
                        </button>
                        <button
                          style={{ padding: "3px", marginLeft: "5px" }}
                          onClick={() => resetTransform()}
                        >
                          Reset
                        </button>
                      </div>
                      <TransformComponent>
                        <img
                          src={dlImage}
                          alt=""
                          width="500px"
                          height="600px"
                        />
                      </TransformComponent>
                    </>
                  )}
                </TransformWrapper>
              ) : null}
            </>
          )}
        </Box>
      </Modal>

      <ModalAntd
        title="Are you sure you want to delete the team?"
        centered
        open={deleteTeamModal}
        onOk={() => handleDeleteTeam(selectedDetails.id)}
        onCancel={() => setDeleteTeamModal(false)}
      >
        <div style={{ padding: "10px" }}></div>
      </ModalAntd>

      <div className="main_onboard_wrapper">
        {isMobile ? (
          <div className="trip-list-mobile-header">
            <MobileHeader headerTitle="Teams" />
          </div>
        ) : (
          <div className="main_onboard_Left_box">
            <SideBarMain />
          </div>
        )}

        <div className="main_onboard_Right_box">
          <div className="main_onboard_Right_box">
            {isMobile ? null : <Topbar />}
            <div className="progress_container_details">
              <div className="Left_details_wrapper">
                <div className="search_container_vehicle">
                  <div className="search_div" id="search_div_onboard">
                    <input
                      type="text"
                      name=""
                      id="search_input_onboard"
                      onChange={(e) => {
                        onChangeSearch(e.target.value);
                      }}
                      value={searchTerm}
                      placeholder="Search team with name, Number, email"
                      className="search_input"
                    />
                    {searchTerm ? (
                      <CloseIcon
                        onClick={handlecancelSearch}
                        style={{ color: "grey", cursor: "pointer" }}
                      />
                    ) : (
                      <SearchIcon
                        style={{ color: "grey", cursor: "pointer" }}
                      />
                    )}
                  </div>
                  <ButtonRectangle
                    onClick={handleAddDriver}
                    // className="add_manufacturer"
                  >
                    <AddIcon style={{ fontSize: isMobile ? "10px" : "" }} />
                    Teams
                  </ButtonRectangle>
                </div>
                <div className="driver_tabs">
                  <button
                    className="driver_singletab"
                    onClick={() => {
                      onChangeTab(true);
                    }}
                    disabled={loader}
                    style={{
                      fontSize: "16px",
                      borderBottom: activeTab
                        ? `3px solid ${colorPalette.primaryColor}`
                        : `3px solid ${colorPalette.secondaryWhite}`,
                      fontWeight: activeTab ? "bold" : "normal",
                      color: activeTab
                        ? colorPalette.primaryColor
                        : colorPalette.paragraphColor,
                    }}
                  >
                    Active
                  </button>
                  <button
                    className="driver_singletab"
                    onClick={() => onChangeTab(false)}
                    disabled={loader}
                    style={{
                      fontSize: "16px",
                      borderBottom: !activeTab
                        ? `3px solid ${colorPalette.primaryColor}`
                        : `3px solid ${colorPalette.secondaryWhite}`,
                      fontWeight: !activeTab ? "bold" : "normal",
                      color: !activeTab
                        ? colorPalette.primaryColor
                        : colorPalette.paragraphColor,
                    }}
                  >
                    Inactive
                  </button>
                </div>
                <div className="card_container_vehicles">
                  {isUserNotAuthorized ? (
                    <NotAuthorized />
                  ) : teamsListData?.length !== 0 ? (
                    <>
                      {teamsListData.map((data, index) => {
                        let fullName = `${data.name}`;
                        return (
                          <>
                            {" "}
                            <div
                              className="card_vehicles"
                              key={index}
                              index={index}
                              onClick={() => handleSelectCard(data, index)}
                              style={{
                                border:
                                  index === cardClicked
                                    ? "2px solid green"
                                    : "none",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                className="content_div_vehicle"
                                style={{ position: "relative" }}
                              >
                                <div
                                  className="delete-team"
                                  onClick={() => {
                                    setDeleteTeamModal(true);
                                    setSelectedDetails(data);
                                  }}
                                >
                                  <Delete
                                    style={{ fontSize: "16px", color: "red" }}
                                  />
                                </div>
                                <div
                                  // className="name"
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "0 0.6rem",
                                  }}
                                >
                                  <div>
                                    {teamsListData.length == index + 1 ? (
                                      <h6
                                        className="name_text_vehicle"
                                        ref={lastItem}
                                      >
                                        {" "}
                                        <PersonIcon />
                                        {<CopyText text={fullName} />}
                                      </h6>
                                    ) : (
                                      <h6 className="name_text_vehicle">
                                        {" "}
                                        <PersonIcon />
                                        {<CopyText text={fullName} />}
                                      </h6>
                                    )}
                                  </div>
                                </div>
                                <div className="capacity">
                                  {" "}
                                  <h6 className="capacity_text">
                                    <MailIcon style={{ fontSize: "15px" }} />
                                    <CopyText text={data.email} />
                                  </h6>
                                </div>

                                <div className="capacity">
                                  {" "}
                                  <h6 className="capacity_text">
                                    <img
                                      src={bussiness_icon}
                                      style={{ height: 15, width: 15 }}
                                    />
                                    {data.business_type}
                                  </h6>
                                </div>
                                <div className="capacity">
                                  {" "}
                                  <h6 className="capacity_text">
                                    <PhoneIcon style={{ fontSize: "15px" }} />
                                    {<CopyText text={data.mobile_number} />}
                                  </h6>
                                </div>

                                <div className="capacity">
                                  {" "}
                                  <h6 className="capacity_text">
                                    <DateRangeIcon
                                      style={{ fontSize: "15px" }}
                                    />
                                    {data.created_on
                                      ? moment
                                          .utc(data.created_on)
                                          .local()
                                          .format("DD-MM-YYYY hh:mm a")
                                      : "-"}
                                  </h6>
                                </div>
                                <div></div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : loadingMore ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "80vw",
                        height: "70vh",
                        // backgroundColor: "grey",
                      }}
                    >
                      <img
                        src={success}
                        alt=""
                        width="120px"
                        height="90px"
                        style={{ marginTop: "100px" }}
                      />
                      <h6 style={{ color: "grey" }}>loading data...</h6>
                    </div>
                  ) : teamsListData.length == 0 ? (
                    <div
                      // className="card_container_vehicles"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "80vw",
                        height: "70vh",
                        // marginLeft: "6rem",
                      }}
                    >
                      <h4
                        style={{
                          textAlign: "center",
                          width: "100%",
                          // marginLeft: "10%",
                          fontSize: "1rem",
                          color: darkMode
                            ? colorPaletteDark.white
                            : colorPalette.black,
                        }}
                      >
                        No teams Found!
                      </h4>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h6 style={{ color: "grey" }}>No data found!</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TeamStyle>
  );
}

export default Teams;
