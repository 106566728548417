import React from "react";
import About from "../../components/landingPageComponents/about/About.jsx";
import Footer from "../../components/landingPageComponents/footer/Footer.jsx";
import Header from "../../components/landingPageComponents/header/Header.jsx";
import Navbar from "../../components/landingPageComponents/navbar/Navbar.jsx";
import Our_platform from "../../components/landingPageComponents/our_platform/Our_platform.jsx";
import Our_Presence from "../../components/landingPageComponents/our_presence/Our_Presence.jsx";
import Our_Services from "../../components/landingPageComponents/our_service/Our_Services.jsx";
import Partnership from "../../components/landingPageComponents/partnership/Partnership.jsx";
import SupplyChainServices from "../../components/landingPageComponents/supply-chain-services/SupplyChainServices.jsx";
import Transport from "../../components/landingPageComponents/transport-tracking/Transport.jsx";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";

import ScrollToTop from "react-scroll-to-top";
import { colorPalette } from "../../themes/theme";

const LandingPage = () => {
  return (
    <div>
      <Navbar />
      <Header />
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <SupplyChainServices />
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <About />
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <Our_Services />
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <Our_platform />
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <Transport />
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <Our_Presence />
      </AnimationOnScroll>

      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <Partnership />
      </AnimationOnScroll>

      {/* <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}> */}
      <Footer />
      {/* </AnimationOnScroll> */}

      <ScrollToTop
        smooth
        viewBox="0 0 24 24"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: `${colorPalette.primaryColor}`,
          color: `${colorPalette.white}`,
        }}
        component={<ArrowDropUpIcon fontSize="large" />}
      />
    </div>
  );
};

export default LandingPage;
