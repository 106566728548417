import apiUrl from "../../../url/apiUrl";
import axios from "axios";
import axiosInterceptor from "../../../config/https";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
// import axiosInterceptor from "../../../config/httpsForCustomer";

export const fetchIndentListApi = async (currentTab, currentPage) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.indentBaseUrl}/${currentTab}?current_page=${currentPage}`
    );
    if (response) {
      if (response.status === 200) {
        return response.data;
      }
    }
  } catch (e) {
    if (e.response.status === 403) {
      // localStorage.clear();
      localStorage.removeItem("CUSTOMER_DATA");
      localStorage.removeItem("x_token");
      localStorage.removeItem("website_url");
      localStorage.removeItem("LOGIN_DATA");
      window.location.reload();
    }
  }
};

export const getAttachementImage = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/indent/${prefixSalt()}-${convertToBase64(id)}`,
      { responseType: "blob" }
    );
    if (response) {
      if (response.status === 200) {
        const imgUrl = URL.createObjectURL(response.data);
        return imgUrl;
      }
    }
  } catch (err) {}
};
// export const getAttachementImageIndent = async (id) => {
//   try {
//     const response = await axiosInterceptor.get(
//       `${
//         apiUrl.onboardingBaseUrl
//       }/v1/image/quotations/${prefixSalt()}-${convertToBase64(id)}`,
//       { responseType: "blob" }
//     );
//     if (response) {
//       if (response.status === 200) {
//         const imgUrl = URL.createObjectURL(response.data);
//         return imgUrl;
//       }
//     }
//   } catch (err) {}
// };
// export const fetchIndentById = async (id) => {
//   try {
//     const response = await axiosInterceptor.get(
//       `${apiUrl.indentBaseUrl}/${process.env.REACT_APP_PATH_PARAM_STATIC_TEXT}-MQ==`
//     );
//     if (response) {
//       if (response.status === 200) {
//         return response.data;
//       }
//     }
//   } catch (e) {
//     if (e.response.status === 403) {
//       localStorage.clear();
//       window.location.reload();
//     }
//   }
// };

export const getIndentAttachementById = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/indent/${prefixSalt()}-${convertToBase64(id)}`
    );

    if (response.status === 200) {
      return response;
    }
  } catch (err) {}
};

export const markIndentPatch = async (id, remarks) => {
  let data = JSON.stringify({
    indent_status: "sent",
    remarks: remarks,
  });

  var config = {
    method: "patch",
    url: `${apiUrl.indentBaseUrl}/${prefixSalt()}-${convertToBase64(id)}/sent`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status >= 200 && response.status <= 300) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status == 403) {
          // localStorage.clear();
          localStorage.removeItem("CUSTOMER_DATA");
          localStorage.removeItem("x_token");
          localStorage.removeItem("website_url");
          localStorage.removeItem("LOGIN_DATA");
          window.location.reload();
        }
        resolve(error.response);
        //
      });
  });
};
