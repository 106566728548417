import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";

import { CircularProgress } from "@mui/material";
import SEARCH_GRAY from "../../assets/search_gray.svg";
import FILTER_GRAY from "../../assets/filter_gray.svg";
import {
  getCustomerCreditList,
  updatecreditStatusApi,
} from "./paymentReceivablesServices";
import { useHistory } from "react-router-dom";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { Payment_receivables } from "./paymentReceivables.js";
import Topbar from "../../components/topbar/Topbar.jsx";
import { ButtonRectangleSecondary } from "../../themes/components/button";
import { DarkModeContext } from "../../context/darkModeContext.js";
import { colorPalette, colorPaletteDark } from "../../themes/theme.js";
import NotAuthorized from "../../components/notAuthorized/NotAuthorized.jsx";
import { Modal as ModalAntd } from "antd";
import SomethingWentWrong from "../../components/somethingWentWrong/SomethingWentWrong.jsx";

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

function PaymentReceivablesPage() {
  const { toggle, darkMode } = useContext(DarkModeContext);

  const history = useHistory();
  const [creditList, setcreditList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentTab, setCurrentTab] = useState("sent");
  const [loadingPage, setLoadingPage] = useState(true);
  const [searchName, setSearchName] = useState("");
  const [isUserNotAuthorized, setIsUserNotAuthorized] = useState(false);

  const handleOpenDetails = (customerId, customerName) => {
    history.push(`/payment-receivables/${customerId}/${customerName}/details`);
  };

  const handleSearchOnChange = (e) => {
    setCurrentPage(1);
    setSearchName(e.target.value);
  };

  const handleGetCreditList = async (currentPage, searchName) => {
    if (currentPage == 1) {
      setLoadingPage(true);
    }
    setLoadingMore(true);
    const response = await getCustomerCreditList(currentPage, searchName);
    if (response.status === 200) {
      if (currentPage > 1) {
        setcreditList([
          ...creditList,
          ...response?.data?.customer_credits_list,
        ]);
        setIsLastPage(response?.data.is_last_page);
        setLoadingPage(false);
        setLoadingMore(false);
      } else {
        setcreditList(response?.data?.customer_credits_list);
        setIsLastPage(response?.data?.is_last_page);
        setLoadingPage(false);
        setLoadingMore(false);
      }
    } else if (response.status === 403) {
      localStorage.clear();
      window.location.reload();
    } else if (response.status === 401) {
      setIsUserNotAuthorized(true);
    }
  };

  useEffect(() => {
    handleGetCreditList(currentPage, searchName);
  }, [currentPage, searchName]);

  // pagination code

  const observer = useRef();
  const lastItem = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage(currentPage + 1);
          setLoadingMore(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage, currentPage]
  );

  return (
    <Payment_receivables
      style={{ display: "flex", width: "100%" }}
      mode={darkMode}
    >
      {isMobile ? null : (
        <div className="credit-sidebar">
          <SideBarMain />{" "}
        </div>
      )}
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={9999999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong backButtonLink="/dashboard" />
        </div>
      </ModalAntd>
      <div className="credit-right-container">
        {isMobile ? null : <Topbar />}
        <div className="credit-head-container">
          <div className="credit-head-row">
            {isMobile ? (
              <div className="trip-list-mobile-header">
                <MobileHeader headerTitle="Payment receivables" />
              </div>
            ) : (
              <span className="credit-head-text" style={{ fontSize: "24px" }}>
                Payment Receivables
              </span>
            )}
          </div>
        </div>
        <div></div>

        {/* search bar  */}

        <div className="credit-search-row">
          <div className="credit-search-container">
            <img
              src={SEARCH_GRAY}
              alt="search"
              style={{ marginRight: "5px" }}
            />
            <input
              placeholder="Search Customer Name"
              className="credit-search-input"
              onChange={(e) => handleSearchOnChange(e)}
            />
            <img src={FILTER_GRAY} alt="filter" />
          </div>
        </div>

        {/* search bar  */}

        {isUserNotAuthorized ? (
          <NotAuthorized />
        ) : loadingPage ? (
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : creditList.length === 0 ? (
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: darkMode ? colorPaletteDark.white : colorPalette.black,
            }}
          >
            No credit data found
          </div>
        ) : (
          <div className="credit-list-container">
            <div className="credit-grid-main-container">
              {creditList.map((credit, index) => {
                return (
                  <div
                    className="credit-single-item-container"
                    // ref={lastcredit}
                  >
                    <div className="credit-single-top-row">
                      <div className="credit-single-top-left">
                        <div className="credit-number-text">
                          {credit?.name ? credit?.name : " - "}
                        </div>
                        <div className="credit-customer-text">
                          {index === creditList.length - 1 ? (
                            <span
                              className="credit-customer-namee"
                              ref={lastItem}
                            >
                              {credit?.customer_code
                                ? credit?.customer_code
                                : " - "}
                            </span>
                          ) : (
                            <span className="credit-customer-namee">
                              {credit?.customer_code
                                ? credit?.customer_code
                                : " - "}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="credit-single-top-right">
                        <div className="credit-date-text">
                          <div className="credit-light-text">credit limit</div>

                          <div>
                            <span style={{ fontFamily: "monospace" }}> ₹</span>{" "}
                            {credit?.credit_limit ? credit?.credit_limit : "0"}{" "}
                          </div>
                        </div>
                        <div className="credit-date-text">
                          <div className="credit-light-text">credit period</div>
                          <div>
                            {credit?.credit_period
                              ? credit?.credit_period
                              : "0"}{" "}
                            days
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="credit-single-middle-row">
                      <div className="credit-single-middle-in">
                        <div className="credit-slim-text">
                          Outstanding amount
                        </div>
                        <div className="credit-bold-text-large">
                          <span style={{ fontFamily: "monospace" }}> ₹</span>{" "}
                          {credit?.current_outstanding_amount
                            ? credit?.current_outstanding_amount
                            : "0"}
                        </div>
                      </div>
                      <div>
                        <ButtonRectangleSecondary
                          // className="credit-details-btn"
                          onClick={() =>
                            handleOpenDetails(credit.customer_id, credit.name)
                          }
                        >
                          View details
                        </ButtonRectangleSecondary>
                      </div>
                    </div>
                  </div>
                );
              })}
              {loadingMore ? (
                <div className="loading_more_data">Loading More ...</div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </Payment_receivables>
  );
}

export default PaymentReceivablesPage;
