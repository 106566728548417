import axios from "axios";
import axiosInterceptor from "../../../config/https";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import apiUrl from "../../../url/apiUrl";

export const GetAddressFromPincode = async (pincode) => {
  try {
    const addressResponse = await axiosInterceptor.get(
      `${apiUrl.vilagePincodeUrl}/${prefixSalt()}-${convertToBase64(pincode)}`
      // {
      //   headers: {
      //     "cache-control": "max-age",
      //   },
      // }
      // "headers":{"a_custom_header":"custom_value"}
    );

    if (addressResponse.status == 200) {
      return addressResponse.data;
    }
  } catch (e) {}
};

export const GetUnitList = async () => {
  try {
    const unitResponse = await axiosInterceptor.get(
      `${apiUrl.unitActiveBaseUrl}`
    );

    if (unitResponse.status == 200) {
      return unitResponse.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const GetAvailableVehiclesList = async () => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.availableVehicleListUrl}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const GetPurchaseOrders = async (searchTerm) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.zohoPurchaseOrdersUrl}?search=${searchTerm}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const GetVehicles = async (searchTerm, source) => {
  let freightType = "";
  if (source === "trip_invoice") {
    freightType = "&freight_type=last mile,both";
  } else if (source === "trip_purchase_order") {
    freightType = "&freight_type=first mile,both";
  }
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.availableVehicleListUrl}&vehicle_number=${searchTerm}${freightType}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const GetDrivers = async (searchTerm) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.driverBaseUrl}?name_or_driving_licence_number=${searchTerm}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const GetInvoices = async (searchTerm) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.zohoInvoicesUrl}?search=${searchTerm}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

// ========== create trip for faarms ==============

export const CreateTripPostForFaarms = (
  sourceDetails,
  destinationDetails,
  driverDetails,
  // approxCost,
  vehicleNumber,
  transporterId,
  driverId,
  driverMobile,
  loadSource,
  loadUnit,
  zohoId,
  tripStatus,
  tripDistance,
  paymentStatus,
  paymentTerms,
  tripCost,
  totalQty,
  customer_id,
  advance_by_customer,
  amount_billed,
  tripStartMeterReading,
  tripStartMeterReadingAttachment,
  perKmRate,
  entityType,
  valueOfGoods,
  ewayBillNumber,
  ewayBillFrom,
  ewayBillTo,
  ewayBillAttachment,
  tripType,
  consigneeId,
  selectedCustomer,
  billToPartyId,
  bhadaChitiAttachment,
  quantityInTonnes
  // customerInvoiceNumber,
  // customerInvoiceAttachment
) => {
  //
  let myData = new FormData();

  if (tripDistance) {
    myData.append("estimated_kms", parseInt(tripDistance));
  }
  if (entityType && entityType != "0") {
    myData.append("entity_type", entityType);
  }
  // if (tripDistance) {
  //   myData["estimated_kms"] = tripDistance;
  // }
  if (loadUnit.length > 1) {
    myData.append("load_unit", loadUnit);
  }

  if (zohoId) {
    myData.append("reference_details_str", JSON.stringify(zohoId));
  }
  if (tripStartMeterReading) {
    myData.append("trip_start_meter_reading", parseInt(tripStartMeterReading));
    myData.append(
      "trip_start_meter_attachment",
      tripStartMeterReadingAttachment.attachment_file
    );
    myData.append(
      "trip_start_meter_attachment_tag",
      "trip_start_meter_reading_attachment"
    );
    myData.append("trip_start_meter_attachment_type", "image");
  }

  if (bhadaChitiAttachment) {
    myData.append(
      "bhada_chithi_attachment",
      bhadaChitiAttachment.attachment_file
    );
    myData.append("bhada_chithi_tag", "bhada_chithi_attachment");
    myData.append("bhada_chithi_attachment_type", "image");
  }

  //
  // if (customerInvoiceAttachment.attachment_file) {
  //   myData.append("customer_invoice", parseInt(customerInvoiceNumber));
  //   myData.append(
  //     "customer_invoice_attachment",
  //     customerInvoiceAttachment.attachment_file
  //   );
  //   myData.append(
  //     "customer_invoice_attachment_tag",
  //     "customer_invoice_attachment"
  //   );
  //   myData.append("customer_invoice_attachment_type", "image");
  // }

  if (perKmRate) {
    myData.append("per_km_rate", parseInt(perKmRate));
  }
  if (paymentTerms) {
    myData.append("payment_terms", paymentTerms);
  }
  if (driverDetails) {
    delete driverDetails.label;
    myData.append("driver", JSON.stringify(driverDetails));
  }
  if (tripCost.filter((item) => item.cost_amount).length > 0) {
    myData.append(
      "trip_cost_str",
      JSON.stringify(tripCost.filter((item) => item.cost_amount))
    );
  }
  if (advance_by_customer) {
    myData.append("advance_paid_by_customer", parseInt(advance_by_customer));
  }
  if (amount_billed) {
    myData.append("customer_billed_amount", parseInt(amount_billed));
  }
  if (ewayBillNumber) {
    myData.append("eway_bill_number", ewayBillNumber);
  }
  if (ewayBillFrom) {
    myData.append("eway_bill_valid_from", ewayBillFrom);
  }
  if (ewayBillTo) {
    myData.append("eway_bill_valid_till", ewayBillTo);
  }
  if (tripType) {
    myData.append("trip_type", tripType);
  }
  if (billToPartyId) {
    myData.append("bill_to_party_id", billToPartyId);
  }

  myData.append("load_source", loadSource);
  myData.append("trip_status", tripStatus);
  myData.append("source_str", JSON.stringify(sourceDetails));
  myData.append("destination_str", JSON.stringify(destinationDetails));
  myData.append("vehicle_number", vehicleNumber);
  myData.append("transporter_id", transporterId);
  myData.append("driver_id", parseInt(driverId));
  myData.append("driver_mobile_number", parseInt(driverMobile));
  myData.append("number_of_pieces", parseInt(totalQty));
  myData.append("customer_id", parseInt(customer_id));
  myData.append("value_of_goods", valueOfGoods);
  myData.append("consignee_id", parseInt(consigneeId));
  myData.append("consignor_id", parseInt(customer_id));
  myData.append("quantity_in_tonnes", quantityInTonnes);

  if (ewayBillAttachment) {
    myData.append("eway_bill_attachment", ewayBillAttachment);
    myData.append("eway_bill_attachment_tag", "e_way_bill");
    myData.append("eway_bill_attachment_type", "image");
  }

  // new field
  // myData.append("quantity_in_tonnes", qunatityInTonnes);
  // myData.append("customer_invoice", customerInvoiceNumber);
  // myData.append(
  //   "customer_invoice_attachment_tag",
  //   "customer_invoice_attachment"
  // );
  // myData.append("customer_invoice_attachment_type", "image");
  // myData.append("customer_invoice_attachment", customerInvoiceAttachment);
  // myData.append("expected_delivery_date", expectedDeliveryDate);

  var config = {
    method: "post",
    url: `${apiUrl.faarmsTripV1BaseUrl}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: myData,
    timeout: 50000,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(async function (response) {
        if (response.status == 201) {
          //Odometer Reading attachment
          // const attachmentResponse = await tripAttachmentSinglePost(
          //   tripStartMeterReadingAttachment,
          //   response.data.id
          // );
          // if (ewayBillAttachment) {
          //   const attachmentEwayResponse = await tripAttachmentSinglePost(
          //     {
          //       attachment_file: ewayBillAttachment,
          //       attachment_tag: "e_way_bill",
          //     },
          //     response.data.id
          //   );
          // }

          resolve(response);
        }
      })
      .catch(function (error) {
        if (!error.response) {
          resolve(error);
        }
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        // resolve(error);
        if (error.response.status == 400) {
          resolve(error.response);
        } else if (error.response.status == 401) {
          resolve(error.response);
        } else {
          resolve(error.response);
        }
      });
  });
};

// trip edit for faarm last mile

export const UpdateTripPutForFaarms = (
  sourceAddressList,
  destinationAddressList,
  vehicleNumber,
  transporterId,
  tripDistance,
  tripId,
  customerId,
  driverId,
  paymentTerms,
  quantity,
  tripCost,
  loadSource,
  perKmRate,
  tripStartMeterReading,
  advance_by_customer,
  amount_billed,
  valueOfGoods,
  ewayBillNumber,
  ewayBillFrom,
  ewayBillTo,
  ewayBillAttachment,
  tripType,
  consigneeId,
  billToPartyId,
  // customerInvoiceAttachment,
  // customerInvoiceNo,
  qunatityInTonnes,
  bhadaChitiAttachment,
  tripStartMeterReadingAttachment
) => {
  let myData = new FormData();
  if (vehicleNumber) {
    myData.append("vehicle_number", vehicleNumber);
  }
  if (transporterId) {
    myData.append("transporter_id", transporterId);
  }
  if (consigneeId) {
    myData.append("consignee_id", consigneeId);
  }
  if (billToPartyId) {
    myData.append("bill_to_party_id", billToPartyId);
  }
  if (valueOfGoods) {
    myData.append("value_of_goods", valueOfGoods);
  }
  if (ewayBillNumber) {
    myData.append("eway_bill_number", ewayBillNumber);
  }
  if (ewayBillFrom) {
    myData.append("eway_bill_valid_from", ewayBillFrom);
  }
  if (ewayBillTo) {
    myData.append("eway_bill_valid_till", ewayBillTo);
  }
  if (tripDistance) {
    myData.append("estimated_kms", tripDistance);
  }
  if (perKmRate) {
    myData.append("per_km_rate", perKmRate);
  }
  if (tripStartMeterReading) {
    myData.append("trip_start_meter_reading", parseInt(tripStartMeterReading));
  }

  if (customerId) {
    myData.append("customer_id", customerId);
  }
  // if (consigneeId) {
  //   myData["consignee_id"] = consigneeId;
  // }
  if (tripType) {
    myData.append("trip_type", tripType);
  }
  if (driverId) {
    myData.append("driver_id", driverId);
  }
  if (paymentTerms) {
    myData.append("payment_terms", paymentTerms);
  }
  if (quantity && loadSource == "manual") {
    myData.append("number_of_pieces", quantity);
  }

  if (tripCost) {
    myData.append(
      "trip_cost_str",
      JSON.stringify(tripCost.filter((item) => item.cost_amount))
    );
  }
  if (advance_by_customer) {
    myData.append("advance_paid_by_customer", advance_by_customer);
  }
  if (amount_billed) {
    myData.append("customer_billed_amount", amount_billed);
  }
  if (sourceAddressList) {
    myData.append("source", JSON.stringify(sourceAddressList));
  }
  if (destinationAddressList) {
    myData.append("destination", JSON.stringify(destinationAddressList));
  }
  //
  // if (customerInvoiceNo) {
  //   myData.append("customer_invoice", customerInvoiceNo);
  // }
  // if (customerInvoiceAttachment.attachment_file) {
  //

  //   myData.append("image_files", customerInvoiceAttachment.attachment_file);
  //   myData.append("tags", "customer_invoice_attachment");
  //   myData.append("attachment_types", "image");
  // }
  if (bhadaChitiAttachment.attachment_file) {
    myData.append("image_files", bhadaChitiAttachment.attachment_file);
    myData.append("tags", "bhada_chithi_attachment");
    myData.append("attachment_types", "image");
  }
  if (ewayBillAttachment) {
    myData.append("image_files", ewayBillAttachment);
    myData.append("tags", "e_way_bill");
    myData.append("attachment_types", "image");
  }

  if (tripStartMeterReadingAttachment.attachment_file !== "sample-file") {
    myData.append(
      "image_files",
      tripStartMeterReadingAttachment.attachment_file
    );
    myData.append("tags", "trip_start_meter_reading_attachment");
    myData.append("attachment_types", "image");
  }

  myData.append("quantity_in_tonnes", parseFloat(qunatityInTonnes));

  var config = {
    method: "put",
    url: `${apiUrl.faarmsTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: myData,
    timeout: 30000,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(async function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        //
        if (!error.response) {
          resolve(error);
        }

        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        // resolve(error);
        if (error.response.status == 400) {
          resolve(error.response);
        }
        if (error.response.status == 422) {
          resolve(error.response);
        }
      });
  });
};

export const CreateDriverPost = (
  first_name,
  last_name,
  mobile_number,
  driving_licence,
  pan_number,
  pan_image,
  driving_licence_image,
  alternate_number
) => {
  let data = new FormData();

  data.append("first_name", `${first_name}`);
  data.append("last_name", `${last_name}`);
  data.append("mobile_number", `${mobile_number}`);
  data.append("driving_licence_number", `${driving_licence}`);
  if (pan_number) {
    data.append("pan_number", `${pan_number}`);
  }
  if (pan_image) {
    data.append("pan_number_image_file", pan_image);
  }
  data.append("driving_licence_image_file", driving_licence_image);
  if (alternate_number) {
    data.append("alternate_number", alternate_number);
  }

  var config = {
    method: "post",
    url: `${apiUrl.onboardingBaseUrl}/v1/drivers`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    timeout: 30000,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (!error.response) {
          resolve(error);
        }
        resolve(error.response);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      });
  });
};

export const GetDocumentEnums = async () => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.attachmentTagEnumUrl}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const GetEntityTypeEnums = async () => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.tripEntityTypeEnumUrl}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const GetCustomerSearchList = async (searchTerm) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.customerListUrl}&name=${searchTerm}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const GetCustomersList = async () => {
  try {
    const response = await axiosInterceptor.get(`${apiUrl.customerListUrl}`);

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const GetCustomerById = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.customersBaseUrl}/${id}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const getZohoInvoiceItemsDetails = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.zohoInvoiceItemsDetailsUrl}?search=${id}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const UpdateTripAddressPut = (
  vehicleNumber,
  tripDistance,
  tripId,
  customerId,
  driverId,
  paymentTerms,
  quantity,
  tripCost
) => {
  let myData = {};
  if (vehicleNumber) {
    myData["vehicle_number"] = vehicleNumber;
  }
  if (tripDistance) {
    myData["estimated_kms"] = tripDistance;
  }

  if (customerId) {
    myData["customer_id"] = customerId;
  }
  if (driverId) {
    myData["driver_id"] = driverId;
  }
  if (paymentTerms) {
    myData["payment_terms"] = paymentTerms;
  }
  if (quantity) {
    myData["quantity"] = quantity;
  }
  if (tripCost) {
    myData["trip_cost"] = tripCost.filter((item) => item.cost_amount);
  }

  // "vehicle_number": "KA 05 LF 3607",
  // "customer_id": 1,
  // "driver_id":1,
  // "remarks":"this is a remarks",
  // "trip_distance_in_kms": 20.0,
  // "is_active": false,
  // "payment_terms": "to_be_billed",
  // "trip_payment_status": "unpaid",
  // "quantity":78,
  // "trip_cost":[
  let data = JSON.stringify({
    ...myData,
  });

  var config = {
    method: "put",
    url: `${apiUrl.tripBaseUrl}/${tripId}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        // resolve(error);
        if (error.response.status == 400) {
          resolve(error.response);
        }
      });
  });
};

const tripAttachmentSinglePost = (attachment, tripId) => {
  let data = new FormData();
  if (!attachment.attachment_file) {
    return;
  }

  data.append("file_1", attachment.attachment_file);
  if (attachment.attachment_tag != "0") {
    data.append("tag_1", attachment.attachment_tag);
  } else {
    data.append("tag_1", "others");
  }

  data.append(
    "attachment_type_1",
    attachment.attachment_file == "application/pdf" ? "pdf" : "image"
  );
  data.append("attach_at", "start_trip");
  data.append("trip_id", tripId);

  var config = {
    method: "post",
    url: `${apiUrl.faarmsTripAttachmentsUrl}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 201) {
          resolve("success");
          // return 'success'
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }

        resolve(error.response);
        // resolve(error);
        //
      });
  });
};

export const getSettingsListByCustomerId = async (customerId, tripType) => {
  try {
    const unitResponse = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/whitelisted-consignor?consignor_id=${customerId}&trip_type=${tripType}`
    );

    if (unitResponse.status == 200) {
      return unitResponse.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const getTripDetailsFromId = async (id) => {
  try {
    const tripResponse = await axiosInterceptor.get(
      `${apiUrl.faarmsTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(id)}`
    );

    if (tripResponse.status == 200) {
      return tripResponse.data;
    }
  } catch (e) {
    if (e.response.status == 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};
