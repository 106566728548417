import { useEffect, useRef, useState } from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import SearchIcon from "@mui/icons-material/Search";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import UploadImage from "../../assets/upload_icon.svg";
import downloadIcons from "../../assets/tripIcons/download.svg";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import BadgeIcon from "@mui/icons-material/Badge";
import dlIcon from "../../assets/dl_icon.png";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import imageloader from "../../assets/tripIcons/ZKZg.gif";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getCarrierListById } from "../onboard/OnboardServices";
import { DriverDrawerStyle, DriverStyle } from "./DriverStyle.jsx";
import { Modal as ModalAntd } from "antd";
import AddIcon from "@mui/icons-material/Add";
import SomethingWentWrong from "../../components/somethingWentWrong/SomethingWentWrong.jsx";
import Topbar from "../../components/topbar/Topbar.jsx";
import { ButtonRectangle } from "../../themes/components/button.js";
import fileIcons from "../../assets/tripIcons/Group 343.svg";
import showIcons from "../../assets/tripIcons/view.svg";
import CloseIcon from "@mui/icons-material/Close";
import NotAuthorized from "../../components/notAuthorized/NotAuthorized.jsx";
import { useCallback } from "react";
import CopyText from "../../components/copyText/CopyText.jsx";
import { DarkModeContext } from "../../context/darkModeContext.js";
import { colorPalette, colorPaletteDark } from "../../themes/theme";
import { useContext } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  Drawer,
  InputAdornment,
  Modal,
} from "@mui/material";
import CommonDrawer from "../../components/commonDrawer/CommonDrawer.jsx";
import { styled } from "@mui/system";
import { ModalUnstyled } from "@mui/base";
import { TextField } from "@mui/material";
import MobileHeader from "../../components/MobileHeader/MobileHeader.jsx";
import { Create_trip_drawer_container } from "../ftl_trips/createTrip/create-tripv2.js";
import { Close } from "@mui/icons-material";
import * as yup from "yup";
import moment from "moment";
import { allRegex } from "../../utility/regex.js";
import {
  CreateDriver,
  getDriverById,
  getDrivers,
  verifyDlNumber,
} from "./DriverListService.jsx";
import { readToken } from "../../helper/dataDecryptionHelper.js";
import { showOrDownlaodAttachmentsUtility } from "../../utility/FetchImage.js";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useDebounce } from "../../hooks/useDebounce.js";
import ImageWithZoom from "../../components/ImageWithZoom/ImageWithZoom.jsx";
import { imageModalStyle } from "../../themes/commonStyle.js";

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const filterBoxStyle = {
  width: isMobile ? "90vw" : "36%",
  height: isMobile ? "auto" : "65%",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -34,
  marginTop: isMobile ? -10 : -20,
  border: "1px solid gray",
  borderRadius: "10px",
};

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${isMobile ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.5)`};
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: isMobile ? "90%" : "32%",
  height: "80vh",
  border: `1px solid ${colorPalette.black}`,
  borderRadius: 1,
  boxShadow: 24,
  p: 2,
  backgroundColor: "#fff",
};

function DriverList() {
  let tokensData = readToken();
  const { carrier_id, id } = useParams();
  const [driverLoadingButton, setDriverLoadingButton] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [errTotalDriver, setErrTotalDriver] = useState("");
  const [value, setValue] = useState(0);
  const [vehiclesDataById, setVehiclesDataById] = useState({});
  const [carrierDataById, setCarrierDataById] = useState({});
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentpage] = useState(1);
  const [isUserNotAuthorized, setIsUserNotAuthorized] = useState(false);
  const [driverListData, setDriverListData] = useState("");
  const [commonDrawerOpen, setCommonDrawerOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [dlFrontFile, setDlFrontFile] = useState(null);
  const [dlBackFile, setDlBackFile] = useState(null);
  const [activeTab, setActiveTab] = useState(true);
  const [searchUrl, setSearchUrl] = useState("name_or_driving_licence_number=");
  const [dlAttachmentPdf, setDlAttachmentPdf] = useState(null);
  const [dlImage, setDlImage] = useState(null);
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const [minorError, setMinorError] = useState("");
  const [driverDetails, setDriverDetails] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    pan_number: "",
    driving_licence_number: "",
    alternate_mobile_number: "",
    dob: "",
  });

  const [selectedDriverDetails, setSelectedDriverDetails] = useState({});
  const [errDriverDetails, setErrDriverDetails] = useState({
    errFirstName: "",
    errMobileNumber: "",
    errPanNumber: "",
    errDLNumber: "",
    errPanDocs: "",
    errDLDocs: "",
    errDob: "",
  });

  const [state, setState] = useState({
    right: false,
  });
  const [showDriverDrawer, setShowDriverDrawer] = useState({
    right: false,
  });
  const [cardClicked, setCardClicked] = useState("");
  const dispatch = useDispatch();
  const { toggle, darkMode } = useContext(DarkModeContext);
  const observer = useRef();
  const debouncedSearchTerm = useDebounce(searchTerm);

  useEffect(() => {
    getDriverList(debouncedSearchTerm, activeTab, currentPage);
    setCardClicked("");
  }, [currentPage, debouncedSearchTerm]);

  const getDriverList = async (searchTerm, status, currentPage) => {
    setLoader(true);
    try {
      let responseFromDriverListApi = await getDrivers(
        searchTerm,
        status,
        currentPage
      );
      if (responseFromDriverListApi.status === 200) {
        setLoader(false);
        if (currentPage > 1) {
          setDriverListData([
            ...driverListData,
            ...responseFromDriverListApi.data.drivers_list,
          ]);
        } else {
          setDriverListData(responseFromDriverListApi.data.drivers_list);
        }
        setIsLastPage(responseFromDriverListApi.data.is_last_page);
      } else if (responseFromDriverListApi.status === 401) {
        setIsUserNotAuthorized(true);
        setLoader(false);
      } else if (responseFromDriverListApi.status === 400) {
        setLoader(false);
        setErrTotalDriver(responseFromDriverListApi.data.detail);
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };
  const handleFetchAttachment = async () => {
    setLoadingImage(true);
    setDlImage(null);
    setDlAttachmentPdf(null);
    const { file, attachmentType } = await showOrDownlaodAttachmentsUtility(
      selectedDriverDetails.id,
      "driver-licence"
    );
    if (attachmentType === "application/pdf") {
      setDlAttachmentPdf(file);
    } else {
      setDlImage(file);
    }
    setLoadingImage(false);
  };

  const DriverSchema = yup.object().shape({
    first_name: yup.string().required(),
    mobile_number: yup.number().min(6000000000).max(9999999999).required(),
    driving_licence_number: yup.string().min(6).required(),
    dl_docs: yup.mixed().required(),
    dob: yup.mixed().required(),
  });

  const getCarrierData = async (idd) => {
    const carrierResponseData = await getCarrierListById(idd);

    setCarrierDataById(carrierResponseData);
  };
  // useEffect(() => {
  //   getCarrierData(carrier_id);
  // }, []);

  const handlecancelSearch = () => {
    setCurrentpage(1);
    setSearchTerm("");
    getDriverList("", activeTab, 1);
  };

  const onChangeSearch = async (search) => {
    setSearchTerm(search);
    setCurrentpage(1);
  };

  const handleSelectCard = async (driver, index) => {
    setCardClicked(index);
    // setSelectedDriverDetails(driver)
    setSelectedDriverDetails({});
    getdriverDrawer("right", true);
    let responseFromDriverGetById = await getDriverById(driver.id);
    setSelectedDriverDetails(responseFromDriverGetById);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddDriver = () => {
    toggleDrawer("right", true);
  };

  const handleOpenDriver = () => {
    getdriverDrawer("right", true);
  };

  const lastItem = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentpage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage]
  );
  function capitalizeLetter(str) {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags;
  }

  const handleCloseCommonDrawer = () => {
    setCommonDrawerOpen(false);
  };

  const handleCloseFilterOptions = () => {
    setIsFilterOpen(false);
  };

  const handledlFrontFile = (event) => {
    setDlFrontFile(event.target.files[0]);
  };

  const handleDlBackFile = (e) => {
    setDlBackFile(e.target.files[0]);
  };

  const handleCloseDriverDrawer = () => {
    clearState();
    toggleDrawer("right", false);
  };

  const handleCloseGetDriverDrawer = () => {
    getdriverDrawer("right", false);
  };

  const driverFormData = {
    first_name: driverDetails.first_name,
    mobile_number: driverDetails.mobile_number,
    // pan_number: driverDetails.pan_number,
    driving_licence_number: driverDetails.driving_licence_number,
    // pan_docs: driverPanImage,
    dl_docs: dlFrontFile,
    dob: driverDetails.dob,
  };
  const handleDrivinPan = (event) => {
    // setDriverPanImage(event.target.files[0]);
  };

  const handleDriverSave = async () => {
    const isDriverValid = await DriverSchema.isValid(driverFormData);
    const isDLSizeValid = dlFrontFile
      ? dlFrontFile.size > process.env.REACT_APP_MAX_FILE_SIZE
        ? false
        : true
      : true;

    if (isDriverValid && isDLSizeValid) {
      setDriverLoadingButton(true);
      createDriver();
    } else {
      let error = {};
      if (!driverDetails.first_name) {
        error.errFirstName = "Please enter valid first name";
      }
      if (!driverDetails.mobile_number) {
        error.errMobileNumber = "Please enter valid mobile number";
      }

      if (!driverDetails.driving_licence_number) {
        error.errDLNumber = "Please enter valid DL number";
      }
      if (!driverDetails.dlFrontFile) {
        error.errDLDocs = "Please upload valid DL image/pdf";
      }
      if (minorError) {
        error.errDob = "Minors are not allowed";
      } else {
        if (!driverDetails.dob) {
          error.errDob = "Please enter DOB";
        }
      }
      let finalError = { ...errDriverDetails, ...error };
      setErrDriverDetails(error);
    }
  };

  const createDriver = async () => {
    setDriverLoadingButton(true);
    const driverPostResponse = await CreateDriver(
      driverDetails.first_name,
      driverDetails.last_name,
      driverDetails.mobile_number,
      driverDetails.driving_licence_number,
      dlFrontFile,
      driverDetails.alternate_mobile_number
    );
    if (driverPostResponse.status === 200) {
      toggleDrawer("right", false);
      setDriverLoadingButton(false);
      getDriverList(searchTerm, activeTab, currentPage);
    } else if (driverPostResponse.status === 400) {
      setDriverLoadingButton(false);
      setErrTotalDriver(driverPostResponse.data.detail);
    } else if (driverPostResponse.status === 401) {
      setDriverLoadingButton(false);
      setErrTotalDriver(`401: Not enough permissions`);
    } else {
      setDriverLoadingButton(false);
      setErrTotalDriver(`ERROR : ${driverPostResponse.status}`);
    }
    setDriverLoadingButton(false);
  };

  const dlVerify = async () => {
    setDriverLoadingButton(true);
    setErrTotalDriver("");
    let responseFroDLverifyApi = await verifyDlNumber(driverDetails);
    if (responseFroDLverifyApi.status === 200) {
      // createDriver()
      setDriverLoadingButton(false);
    } else if (responseFroDLverifyApi.status === 400) {
      setDriverLoadingButton(false);
      setErrTotalDriver(responseFroDLverifyApi.data.detail);
    } else {
      setDriverLoadingButton(false);
      setErrTotalDriver(`ERROR : ${responseFroDLverifyApi.status}`);
    }
  };

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const getdriverDrawer = (anchor, open) => {
    setShowDriverDrawer({ ...showDriverDrawer, [anchor]: open });
  };

  const onChangeTab = async (tab) => {
    setActiveTab(tab);
    setCurrentpage(1);
    getDriverList(searchTerm, tab, 1);
    setDriverListData([]);
  };

  const onChangeDlNumber = (e) => {
    setDriverDetails({
      ...driverDetails,
      driving_licence_number: e.target.value,
    });
    if (allRegex.dlNumberRegex.test(e.target.value)) {
      setErrDriverDetails({
        ...errDriverDetails,
        errDLNumber: "",
      });
    } else {
      setErrDriverDetails({
        ...errDriverDetails,
        errDLNumber: "Please enter valid DL number",
      });
    }
  };

  useEffect(() => {
    if (
      allRegex.dlNumberRegex.test(driverDetails.driving_licence_number) &&
      driverDetails.dob
    ) {
      dlVerify();
    }
  }, [driverDetails.driving_licence_number, driverDetails.dob]);

  function onChangeDob(dob) {
    setDriverDetails({ ...driverDetails, dob: dob });
    let year = moment(todayDate).format("YYYY") - moment(dob).format("YYYY");
    if (year > 18) {
      setErrDriverDetails({
        ...errDriverDetails,
        errDob: "",
      });
      setMinorError("");
    } else {
      setErrDriverDetails({
        ...errDriverDetails,
        errDob: "Minors are not allowed",
      });
      setMinorError("minor are not allowed");
    }
  }

  function clearState() {
    setDriverDetails({
      first_name: "",
      last_name: "",
      mobile_number: "",
      pan_number: "",
      driving_licence_number: "",
      alternate_mobile_number: "",
      dob: "",
    });
    setErrDriverDetails({
      errFirstName: "",
      errMobileNumber: "",
      errPanNumber: "",
      errDLNumber: "",
      errPanDocs: "",
      errDLDocs: "",
      errDob: "",
    });
    setErrTotalDriver("");
    // set
  }

  return (
    <DriverStyle mode={darkMode}>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={() => {
          clearState();
          toggleDrawer("right", false);
        }}
      >
        <Create_trip_drawer_container
          style={isMobile ? { width: "90vw" } : {}}
          className="create-trip-drawer-container"
        >
          <div
            className="create-trip-right-most-head"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h4>Driver's details:</h4>{" "}
            <Close
              onClick={handleCloseDriverDrawer}
              style={{ marginRight: "20px", cursor: "pointer" }}
            />
          </div>
          <div className="create-trip-from-body">
            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  {" "}
                  First name <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Enter first name"
                  value={driverDetails.first_name}
                  onChange={(e) => {
                    setDriverDetails({
                      ...driverDetails,
                      first_name: e.target.value,
                    });
                  }}
                />
                <div className="create-trip-error">
                  {!driverDetails.first_name
                    ? errDriverDetails.errFirstName
                    : null}
                </div>
              </div>
            </div>
            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head"> Last name</h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Enter last name"
                  value={driverDetails.last_name}
                  onChange={(e) => {
                    setDriverDetails({
                      ...driverDetails,
                      last_name: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  {" "}
                  Mobile number <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  placeholder="Enter the mobile number"
                  value={driverDetails.mobile_number}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setDriverDetails({
                        ...driverDetails,
                        mobile_number: e.target.value,
                      });
                    }
                  }}
                />
                <div className="create-trip-error">
                  {errDriverDetails.errMobileNumber
                    ? errDriverDetails.errMobileNumber
                    : null}
                </div>
              </div>
            </div>

            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  {" "}
                  Alternate Mobile number{" "}
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  placeholder="Enter the mobile number"
                  value={driverDetails.alternate_mobile_number}
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setDriverDetails({
                        ...driverDetails,
                        alternate_mobile_number: e.target.value,
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  {" "}
                  DOB {`(as per driving license number)`}
                  <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  type="date"
                  size="large"
                  autoComplete="off"
                  className="create-trip-input-dob"
                  variant="outlined"
                  placeholder="select DOB"
                  // style={{ color: "black" }}
                  value={driverDetails.dob}
                  onChange={(e) => {
                    onChangeDob(e.target.value);
                  }}
                />
                <div className="create-trip-error">
                  {errDriverDetails.errDob ? errDriverDetails.errDob : null}
                </div>
              </div>
            </div>

            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  {" "}
                  Driving license number <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Enter the driving licence number"
                  value={driverDetails.driving_licence_number}
                  onChange={(e) => {
                    onChangeDlNumber(e);
                  }}
                />
                <div className="create-trip-error">
                  {errDriverDetails.errDLNumber
                    ? errDriverDetails.errDLNumber
                    : null}
                </div>
              </div>
            </div>

            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                {/* <h4 className="create-trip-input-head">
                      {" "}
                      Upload Driving License{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4> */}
                <TextField
                  size="small"
                  id="dropdown"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Enter document type"
                  name="attachment_tag"
                  disabled
                  // disabled={index == 0 ? true : false}
                  value={"Upload Driving license"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          color: colorPalette.primaryColor,
                          fontWeight: "600",
                        }}
                      // onClick={handleAddAttachment}
                      >
                        <button className="upload_button">
                          <input
                            // name="upload"
                            name="attachment_file"
                            id={`uploaddl`}
                            type="file"
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={handledlFrontFile}
                            hidden
                            style={{
                              backgroundColor: colorPalette.secondaryWhite,
                            }}
                          />
                          <label
                            htmlFor={`uploaddl`}
                            style={{
                              backgroundColor: colorPalette.secondaryWhite,
                            }}
                          >
                            <img
                              src={UploadImage}
                              alt=""
                              width="20px"
                              height="20px"
                              style={{
                                backgroundColor: colorPalette.secondaryWhite,
                                // backgroundColor: "red",
                                // width: "100%",
                                // height: "100%",
                              }}
                            />
                          </label>
                        </button>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <input
                      type="file"
                      className="create-trip-input"
                      accept="image/png, image/jpeg, application/pdf"
                      onChange={handledlFrontFile}
                    /> */}
                <div className="create-trip-dl-name">
                  {dlFrontFile ? dlFrontFile.name : null}
                </div>
                <div className="create-trip-error">
                  {!dlFrontFile ? errDriverDetails.errDLDocs : null}
                </div>
                <div className="create-trip-error">
                  {dlFrontFile
                    ? dlFrontFile.size > process.env.REACT_APP_MAX_FILE_SIZE
                      ? `File size should be less than ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB`
                      : null
                    : null}
                </div>
              </div>
            </div>

            <div>
              {errTotalDriver ? (
                <Alert severity="error">{errTotalDriver}</Alert>
              ) : null}
            </div>
            <div className="create-trip-input-container">
              <button
                className="create-trip-save-btn"
                onClick={() => handleDriverSave()}
              >
                {driverLoadingButton ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </Create_trip_drawer_container>
      </Drawer>
      <Drawer
        anchor={"right"}
        open={showDriverDrawer["right"]}
        onClose={() => {
          handleCloseGetDriverDrawer();
        }}
      >
        <DriverDrawerStyle
          style={isMobile ? { width: "100vw" } : {}}
          className="mini-details-tab-container_v2"
        >
          <h3
            style={{
              marginLeft: "15px",
              color: colorPaletteDark.primaryColor,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Driver details
            <Close
              onClick={handleCloseGetDriverDrawer}
              style={{ marginRight: "20px", cursor: "pointer" }}
            />
          </h3>

          <div className="mini-details-single-list">
            <h4 className="mini-details-single-list-left">First Name</h4>
            <h4 className="mini-details-single-list-right">
              :{" "}
              {selectedDriverDetails?.first_name
                ? selectedDriverDetails?.first_name
                : "-"}
            </h4>{" "}
          </div>

          <div className="mini-details-single-list">
            <h4 className="mini-details-single-list-left">Last name</h4>
            <h4 className="mini-details-single-list-right">
              :{" "}
              {selectedDriverDetails?.last_name
                ? selectedDriverDetails?.last_name
                : "-"}
            </h4>{" "}
          </div>
          <div className="mini-details-single-list">
            <h4 className="mini-details-single-list-left">Mobile number</h4>
            <h4 className="mini-details-single-list-right">
              :{" "}
              {selectedDriverDetails?.mobile_number ? (
                <CopyText text={selectedDriverDetails.mobile_number} />
              ) : (
                "-"
              )}
            </h4>{" "}
          </div>

          <div className="mini-details-single-list">
            <h4 className="mini-details-single-list-left">DL number</h4>
            <h4 className="mini-details-single-list-right">
              :{" "}
              {selectedDriverDetails?.driving_licence_number ? (
                <CopyText text={selectedDriverDetails.driving_licence_number} />
              ) : (
                "-"
              )}
            </h4>{" "}
          </div>
          <div className="mini-details-single-list">
            <h4 className="mini-details-single-list-left">Alternate number</h4>
            <h4 className="mini-details-single-list-right">
              :{" "}
              {selectedDriverDetails?.alternate_number ? (
                <CopyText text={selectedDriverDetails.alternate_number} />
              ) : (
                "-"
              )}
            </h4>{" "}
          </div>
          <div
            className="mini-details-single-attachment_download"
            style={{ marginLeft: "5px" }}
          >
            {" "}
            <ul
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: `${darkMode ? colorPaletteDark.primaryColor : colorPalette.white
                  }`,
                color: `${darkMode ? colorPaletteDark.white : colorPalette.black
                  }`,
              }}
            >
              <li
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontWeight: "500",
                  backgroundColor: `${darkMode
                    ? colorPaletteDark.primaryColor
                    : colorPalette.white
                    }`,
                  marginLeft: "5px",
                }}
              >
                <div className="trip_buttons_download">
                  <img
                    src={fileIcons}
                    alt=""
                    width="35px"
                    height="35px"
                    style={{
                      cursor: "pointer",
                      backgroundColorcolor: `${darkMode ? colorPaletteDark.secondaryColor : ""
                        }`,
                    }}
                  />
                  <span
                    style={{
                      color: `${darkMode ? colorPaletteDark.white : colorPalette.black
                        }`,
                      marginLeft: "5px",
                    }}
                  >
                    Driving Licence
                  </span>
                </div>

                <div className="trip_buttons_download">
                  <img
                    src={showIcons}
                    alt=""
                    width="35px"
                    height="35px"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setAttachmentModal(true);
                      handleFetchAttachment();
                    }}
                  />
                  <img
                    src={downloadIcons}
                    alt=""
                    width="35px"
                    height="35px"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      showOrDownlaodAttachmentsUtility(
                        selectedDriverDetails.id,
                        "driver-licence",
                        "downloadOnly"
                      )
                    }
                  />
                </div>
              </li>
            </ul>
          </div>
        </DriverDrawerStyle>
      </Drawer>
      <StyledModal
        open={isFilterOpen}
        onClose={handleCloseFilterOptions}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={filterBoxStyle}>
          <div className="trip-list-filter-main">
            {isMobile ? (
              <div className="trip-list-filter-head">
                <span className="trip-list-filter-main-text">
                  Select filter options
                </span>{" "}
                <CloseIcon onClick={handleCloseFilterOptions} />
              </div>
            ) : (
              <div className="trip-list-filter-head">
                <span
                  className="trip-list-filter-main-text"
                  style={{ marginLeft: "5px" }}
                >
                  Select filter options
                </span>{" "}
                <CloseIcon
                  onClick={handleCloseFilterOptions}
                  style={{ cursor: "pointer" }}
                />
              </div>
            )}

            <div className="trip-list-filter-container">
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Trip code</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    className="trip-list-filter-inputfield"
                    // value={filterTripCode}
                    // onChange={(e) => setFilterTripCode(e.target.value)}
                    variant="standard"
                  />
                </div>
              </div>

              <div className="trip-list-filter-button-row">
                <button
                  className="trip-list-filter-button"
                // onClick={handleClearFilter}
                >
                  Clear
                </button>
                <button
                  className="trip-list-filter-button"
                  onClick={() => {
                    // handleFilterSearch(1);
                    // setCurrentPage(1);
                  }}
                ></button>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>

      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={9999999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong backButtonLink="/driver" buttonText="Close" />
        </div>
      </ModalAntd>

      <Modal
        open={attachmentModal}
        onClose={() => setAttachmentModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={imageModalStyle}
          style={{
            height: isMobile ? "50vh" : "",
            width: isMobile ? "90vw" : "",
            overflowY: isMobile ? "scroll" : "hidden",
            paddingBottom: isMobile ? "80px" : "",
            backgroundColor: darkMode ? "black" : "white",
          }}
        >
          {loadingImage ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <img src={imageloader} alt="" width="30%" height="30%" />
              <div>Loading...</div>
            </div>
          ) : (
            <>
              {dlAttachmentPdf ? (
                <embed
                  src={dlAttachmentPdf}
                  type="application/pdf"
                  width={isMobile ? "100%" : "600px"}
                  height={isMobile ? "100%" : "600px"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></embed>
              ) : dlImage ? (
                <ImageWithZoom image={dlImage} />
              ) : null}
            </>
          )}
        </Box>
      </Modal>

      <div className="main_onboard_wrapper">
        {isMobile ? (
          <div className="trip-list-mobile-header">
            <MobileHeader headerTitle="Driver" />
          </div>
        ) : (
          <div className="main_onboard_Left_box">
            <SideBarMain />
          </div>
        )}

        <div className="main_onboard_Right_box">
          <div className="main_onboard_Right_box">
            {isMobile ? null : <Topbar />}
            <div className="progress_container_details">
              <div className="Left_details_wrapper">
                <div
                  className="search_container_vehicle"
                  style={isMobile ? { width: "90vw" } : {}}
                >
                  <div
                    style={{}}
                    className="search_div"
                    id="search_div_onboard"
                  >
                    <input
                      type="text"
                      name=""
                      id="search_input_onboard"
                      onChange={(e) => {
                        onChangeSearch(e.target.value);
                      }}
                      value={searchTerm}
                      placeholder="Search driver with name, Number, DL number"
                      className="search_input"
                    />
                    {searchTerm && !driverListData.length == 0 ? (
                      <CloseIcon
                        onClick={handlecancelSearch}
                        style={{ color: "grey", cursor: "pointer" }}
                      />
                    ) : (
                      <SearchIcon
                        style={{ color: "grey", cursor: "pointer" }}
                      />
                    )}
                  </div>
                  <ButtonRectangle
                    onClick={handleAddDriver}
                  // className="add_manufacturer"
                  >
                    <AddIcon style={{ fontSize: isMobile ? "10px" : "" }} />
                    Driver
                  </ButtonRectangle>
                </div>
                <div className="driver_tabs">
                  <button
                    className="driver_singletab"
                    onClick={() => {
                      onChangeTab(true);
                    }}
                    disabled={loader}
                    style={{
                      borderBottom: activeTab
                        ? `3px solid ${colorPalette.primaryColor}`
                        : `3px solid ${colorPalette.secondaryWhite}`,

                      color: activeTab
                        ? colorPalette.primaryColor
                        : colorPalette.paragraphColor,
                    }}
                  >
                    <h3
                      style={{
                        fontSize: "16px",
                        fontWeight: activeTab ? "bold" : "normal",
                      }}
                    >
                      Active
                    </h3>
                  </button>
                  <button
                    className="driver_singletab"
                    onClick={() => onChangeTab(false)}
                    disabled={loader}
                    style={{
                      borderBottom: !activeTab
                        ? `3px solid ${colorPalette.primaryColor}`
                        : `3px solid ${colorPalette.secondaryWhite}`,

                      color: !activeTab
                        ? colorPalette.primaryColor
                        : colorPalette.paragraphColor,
                    }}
                  >
                    <h3
                      style={{
                        fontSize: "16px",
                        fontWeight: !activeTab ? "bold" : "normal",
                      }}
                    >
                      Inactive
                    </h3>
                  </button>
                </div>
                <div className="card_container_vehicles">
                  {isUserNotAuthorized ? (
                    <NotAuthorized />
                  ) : driverListData.length !== 0 ? (
                    <>
                      {driverListData.map((data, index) => {
                        let fullName = `${data.first_name} ${data.last_name ? " " + data.last_name : ""
                          }`;
                        return (
                          <>
                            {" "}
                            <div
                              className="card_vehicles"
                              key={index}
                              index={index}
                              onClick={() => handleSelectCard(data, index)}
                              style={{
                                border:
                                  index === cardClicked
                                    ? `2px solid ${colorPalette.primaryColor}`
                                    : "none",
                                cursor: "pointer",
                              }}
                            >
                              <div className="content_div_vehicle">
                                <div
                                  // className="name"
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: "0 0.6rem",
                                  }}
                                >
                                  <div>
                                    {driverListData.length == index + 1 ? (
                                      <h6
                                        className="name_text_vehicle"
                                        ref={lastItem}
                                      >
                                        {" "}
                                        {/* <PersonIcon /> */}
                                        {<CopyText text={fullName} />}
                                      </h6>
                                    ) : (
                                      <h6 className="name_text_vehicle">
                                        {" "}
                                        <PersonIcon />
                                        {<CopyText text={fullName} />}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="driver_trip_payout">
                                    <div className="tl-new-status_drawer">
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          // fontSize: "8px",
                                          color: "white",
                                          padding: "1px 4px",
                                          borderTopLeftRadius: "3px",
                                          borderBottomLeftRadius: "3px",
                                        }}
                                      >
                                        {
                                          <CopyText
                                            text={data.driving_licence_number}
                                          />
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="capacity">
                                  {" "}
                                  <h6 className="capacity_text">
                                    <img
                                      src={dlIcon}
                                      style={{ height: 15, width: 15 }}
                                    />
                                    {
                                      <CopyText
                                        text={data.driving_licence_number}
                                      />
                                    }
                                  </h6>
                                </div>
                                <div className="capacity">
                                  {" "}
                                  <h6 className="capacity_text">
                                    <PhoneIcon style={{ fontSize: "15px" }} />
                                    {<CopyText text={data.mobile_number} />}
                                  </h6>
                                </div>
                                {/* <div className="capacity">
                                  {" "}
                                  <h6 className="capacity_text">
                                    {<CopyText text={data.alternate_number ? data.alternate_number : ""} />}
                                  </h6>
                                </div> */}

                                {/* <div className="capacity">
                                  {" "}
                                  <h6 className="capacity_text">
                                    {data.pan_number}
                                  </h6>
                                </div> */}

                                <div className="capacity">
                                  {" "}
                                  <h6 className="capacity_text">
                                    <DateRangeIcon
                                      style={{ fontSize: "15px" }}
                                    />
                                    {data.created_on
                                      ? moment
                                        .utc(data.created_on)
                                        .local()
                                        .format("DD-MM-YYYY hh:mm a")
                                      : "-"}
                                  </h6>
                                </div>
                                <div></div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : loadingMore ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "80vw",
                        height: "70vh",
                        // backgroundColor: "grey",
                      }}
                    >
                      <img
                        src={success}
                        alt=""
                        width="120px"
                        height="90px"
                        style={{ marginTop: "100px" }}
                      />
                      <h6 style={{ color: "grey" }}>loading data...</h6>
                    </div>
                  ) : driverListData.length == 0 ? (
                    <div
                      // className="card_container_vehicles"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "80vw",
                        height: "70vh",
                        // marginLeft: "6rem",
                      }}
                    >
                      <h4
                        style={{
                          textAlign: "center",
                          width: "100%",
                          // marginLeft: "10%",
                          fontSize: "1rem",
                          color: darkMode
                            ? colorPaletteDark.white
                            : colorPalette.black,
                        }}
                      >
                        No Driver Found!
                      </h4>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h6 style={{ color: "grey" }}>No data found!</h6>
                    </div>
                  )}
                </div>
              </div>

              <CommonDrawer
                commonDrawerOpen={commonDrawerOpen}
                handleCloseCommonDrawer={handleCloseCommonDrawer}
                carrierDetails={selectedDriver}
                cardClicked=""
                clickTab=""
                toggleCommonDrawer=""
                drawerState=""
              />

              {/* // )} */}
            </div>
          </div>
        </div>
      </div>
    </DriverStyle>
  );
}

export default DriverList;
