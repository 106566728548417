import styled from "styled-components";
import { FontFamily, colorPalette, colorPaletteDark } from "../../themes/theme";

export const VehicleListStyle = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

  .main_onboard_wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  }

  .trip-list-mobile-header {
    width: 100%;
    height: 50px;
  }

  .main_onboard_Left_box {
    flex: 1.7;
    background-color: ${colorPalette.secondaryWhite};
    height: 100%;
    box-shadow: ${colorPalette.boxShadowPrimary};
    margin: 0 auto;
  }

  .main_onboard_Right_box {
    flex: 9.5;
    /* position: sticky;
    overflow: scroll; */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
    width: 90%;
  }

  .progress_container_details {
    width: 98%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

    /* background-color: red; */
    justify-content: space-between;
  }

  .Left_details_wrapper {
    flex: 8;
    height: 100%;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
  }

  .search_container_vehicle {
    /* flex: 6; */
    width: 100%;
    height: 10%;
    margin-top: 1.5rem;
    /* margin-bottom: 2rem; */
    /* background-color: beige; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search_div {
    position: relative;
    width: 70%;
    height: 2.5rem;
    background-color: ${colorPalette.white};
    display: flex;
    padding: 0 0.8rem;
    margin-right: 2rem;
    /* background-color: red; */
    margin-left: 3rem;
    /* justify-content: center; */
    align-items: center;
    border-radius: 8px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  .search_input {
    width: 100%;
    background-color: ${colorPalette.white};
    height: 100%;
    outline: none;
    border-radius: 1rem;
    border: none;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
  }

  .vehicle_tabs {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.8rem 0.2rem;
    margin-bottom: 10px;
    justify-content: flex-start;
  }

  .vehicle_singletab {
    padding: 0.1rem 1rem;
    cursor: pointer;
    /* border: 1px solid green; */
  }

  .card_container_vehicles {
    display: grid;
    grid-template-columns: 32.5% 32.5% 32.5%;
    grid-auto-rows: min-content;
    height: 70vh;
    padding-bottom: 40px;
    /* width: 100%; */
    /* width: 100%; */
    /* padding: 0px 30px; */
    position: sticky;
    overflow-y: scroll;
    /* grid-template-rows: 50%; */
    gap: 10px;
  }

  .card_vehicles {
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    align-items: center;
    width: 100%;
    padding: 0.6rem;
    border-radius: 0.3rem;
    background-color: ${(props) =>
      props.mode
        ? colorPaletteDark.secondaryColor
        : colorPalette.boxShadowPrimary};
    /* background-color: white; */
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    -ms-border-radius: 0.3rem;
    -o-border-radius: 0.3rem;
  }

  .content_div_vehicle {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* background-color: ${colorPalette.primaryColor}; */
    justify-content: center;
    /* background-color: wheat; */
  }

  .name_text_vehicle {
    font-family: ${FontFamily.primaryFontFamily};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    /* line-height: 24px; */
    display: flex;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }

  .vehicle_trip_payout {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    /* background-color: ${colorPalette.black}; */
    justify-content: center;
  }

  .tl-new-status_drawer {
    color: ${colorPalette.primaryColor};
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
    line-height: 18px;
    /* padding: 0px 10px; */
  }

  .vehicle-list-monthly-text {
    font-family: ${FontFamily.primaryFontFamily};
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* text-align: left; */
    letter-spacing: -0.165px;

    color: ${colorPalette.secondaryWhite};
    /* background-color: #0FBC9E; */
  }

  .capacity_vehicle {
    font-family: ${FontFamily.primaryFontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    display: flex;
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.paragraphSecondaryolor};

    padding: 0 0.6rem;
  }

  .weight_vehicle {
    font-family: ${FontFamily.primaryFontFamily};
    font-size: 0.8rem;
    font-weight: normal;
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.paragraphSecondaryolor};
    /* margin-left: 0.5rem; */
  }

  .capacity {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 0.6rem;
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.paragraphSecondaryolor};
  }

  .capacity_text {
    font-family: ${FontFamily.primaryFontFamily};
    font-size: 14px;
    font-weight: normal;
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.paragraphSecondaryolor};
  }

  .vehicle-expiry-check-container_vehicles {
    display: flex;
    width: 100%;
    align-items: center;
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.paragraphSecondaryolor};
    /* background-color:rebeccapurple; */
    justify-content: flex-start;
  }

  .vehile-expiry-single-container {
    display: flex;
    align-items: center;
    /* width: 100%; */
    justify-content: space-between;
    margin: 5px;
  }

  .right-wrong-icon-img {
    width: 15px;
    height: 15px;
  }

  /*/=============================================================================*/

  @media only screen and (max-width: 499px) {
    width: 100vw;
    overflow-x: hidden;
    .main_onboard_wrapper {
      flex-direction: column;
      margin: 0px;
      height: auto;
    }

    .main_onboard_Right_box {
      flex: 9.5;
      /* position: sticky;
      overflow: scroll; */
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      background-color: ${colorPalette.secondaryWhite};
      width: 95%;
      /* background-color: red; */
    }

    .progress_container_details {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      /* background-color: beige; */
      justify-content: space-between;
    }

    .Left_details_wrapper {
      width: 100%;
      height: 100%;
      background-color: ${colorPalette.secondaryWhite};
    }

    .search_container_vehicle {
      /* flex: 12; */
      width: 100%;
      /* align-items: center; */
      height: 10%;
      margin-top: 1rem;
      /* margin-right: .5rem; */
      /* margin-bottom: 1rem; */
      /* background-color: beige;s */
      display: flex;
      align-items: center;
      justify-content: center;
      /* background-color: blue; */
    }

    #search_div_onboard {
      /* position: relative; */
      width: 100%;
      /* align-items: center; */
      height: 2.5rem;
      /* margin-right: 6.5rem; */
      background-color: ${colorPalette.white};
      display: flex;
      padding: 0 0.8rem;
      display: flex;
      align-items: center;
      margin-left: 1.8rem;
      /* background-color: ${colorPalette.primaryColor}; */
      /* margin-right: 2rem; */
      /* background-color: red; */
      /* margin-left: 3rem; */
      /* justify-content: center; */
      align-items: center;
      border-radius: 8px;
      box-shadow: ${colorPalette.boxShadowPrimary};
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      /* background-color: red; */
    }

    .search_div {
      /* position: relative; */
      /* width: 0%; */
      /* align-items: center; */
      height: 2.5rem;
      background-color: ${colorPalette.white};
      display: flex;
      padding: 0 0.8rem;
      display: flex;
      align-items: center;
      /* margin-right: 2rem; */
      /* background-color: red; */
      /* margin-left: 3rem; */
      /* justify-content: center; */
      align-items: center;
      border-radius: 8px;
      box-shadow: ${colorPalette.boxShadowPrimary};
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      /* background-color: red; */
    }

    .search_input {
      width: 100%;
      background-color: ${colorPalette.white};
      height: 100%;
      outline: none;
      border-radius: 1rem;
      /* margin-right: -1rem; */
      border: none;
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      -ms-border-radius: 1rem;
      -o-border-radius: 1rem;
    }

    .vehicle_tabs {
      width: 100vw;
      display: flex;
      align-items: center;
      padding: 0.8rem 0.2rem;
      margin-bottom: 5px;
      overflow-x: scroll;

      /* background-color: ${colorPalette.primaryColor}; */
      justify-content: flex-start;
    }

    .vehicle_singletab {
      font-size: 10px;
      /* width: 100%; */
      min-width: fit-content;
      /* text-align: center; */
      /* padding: 0px 30px; */
      /* background-color: ${colorPalette.primaryColor}; */
      /* display: block; */
      display: flex;
      /* align-items: center; */
      justify-content: flex-start;
      flex-wrap: nowrap;
      /* width: 100%; */

      /* background-color: ${colorPalette.primaryColor}; */
      /* padding: 0px 20px; */
    }

    .card_container_vehicles {
      display: grid;
      grid-template-columns: 100%;
      grid-auto-rows: min-content;
      height: 80vh;
      /* width: 100%; */
      /* width: 100%; */
      /* padding: 0px 30px; */
      position: sticky;
      overflow-y: scroll;
      /* grid-template-rows: 50%; */
      gap: 10px;
    }

    .card_vehicles {
      display: flex;
      /*align-self: center;
      */
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      /*margin: 1rem auto;
      */
      padding: 0.6rem;
      border-radius: 0.3rem;
      background-color: ${(props) =>
        props.mode
          ? colorPaletteDark.secondaryColor
          : colorPalette.boxShadowPrimary};
      /* background-color: white; */
      -webkit-border-radius: 0.3rem;
      -moz-border-radius: 0.3rem;
      -ms-border-radius: 0.3rem;
      -o-border-radius: 0.3rem;
    }

    .content_div_vehicle {
      height: 100%;
      /* width: 100%; */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      /* background-color: ${colorPalette.primaryColor}; */
      justify-content: center;
      /* background-color: wheat; */
    }

    .vehicle-list-monthly-text {
      font-family: ${FontFamily.primaryFontFamily};
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 0px;
      display: flex;
      align-items: center;
      text-align: left;
      letter-spacing: -0.165px;

      color: ${colorPalette.secondaryWhite};
    }

    .vehicle-expiry-text {
      font-size: 13px;
      font-weight: 500;
      color: ${colorPalette.paragraphSecondaryolor};
      margin-left: 2px;
    }
  }
`;
