import React, { useContext, useEffect, useState } from "react";
import TRUCK_IMAGE from "../../assets/dashboard_icons/icon-0.8s-215px.png";
// import "../carrierMiniCard/carrierMiniCard.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import Drawer from "@mui/material/Drawer";
import Accordion from "@mui/material/Accordion";
import CloseIcon from "@mui/icons-material/Close";
import { InputAdornment } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import UploadImage from "../../assets/upload.svg";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MenuItem, TextField } from "@mui/material";
import apiUrl from "../../url/apiUrl";
import Carrier from "../../assets/istockphoto-1214428300-170667a.jpg";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import FeedIcon from "@mui/icons-material/Feed";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Tabs from "@mui/material/Tabs";
import carrier2 from "../../assets/placeholdercompany5f3438282f524800f1d49cd2921bb0a56101e1aa16097ebd313b64778fc7c4bd1611448792.png";
// import { GetDriverDetails, GetCostDetails } from "./miniDetailsCardServices";
import Tab from "@mui/material/Tab";
import vehicles from "../../assets/dashboard_icons/vehicle.svg";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { updateRoutesAddVehicle } from "../../redux/onboardSlice";
import { updateCarrierId } from "../../redux/onboardSlice";
import { useHistory } from "react-router-dom";
import { getCarrierListById } from "../../pages/onboard/OnboardServices";
import { useSelector, useDispatch } from "react-redux";

import { getCarrierList } from "../../pages/onboard/OnboardServices";
import { Mini_details_carrier_card_main_container_payments } from "./paymentmonthly";
import { DarkModeContext } from "../../context/darkModeContext";
import { colorPalette, colorPaletteDark } from "../../themes/theme";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function PaymentMonthlyCard({ MonthlyDetails, paidAmount }) {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const [value, setValue] = React.useState(0);
  const [total, setTotal] = useState("");
  const [totals, setTotals] = useState("");
  const balanceDue = useSelector((state) => state.paymentValues.monthlyBalance);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const reducer = (accumulator, curr) => accumulator + curr;

  const extrakmAmounts = (monthlydata, totaldistance) => {
    // if (totaldistance) {
    const totalAmountKms =
      monthlydata.vehicle_details.extra_per_km_rate *
      (monthlydata.total_distance_travelled -
        monthlydata.vehicle_details.fixed_kms);
    setTotals(totalAmountKms);
    // }
  };
  useEffect(() => {
    if (MonthlyDetails.vehicle_details) {
      extrakmAmounts(MonthlyDetails);
    }
  }, [MonthlyDetails]);
  // const totalBalance = () => {
  //   let total = 0;
  //   if (data.trip_cost !== undefined) {
  //     total = data.trip_cost
  //       .filter((advance) => advance.cost_type !== "advance")
  //       .filter((cost) => cost.cost_type !== "per_bag_cost")
  //       .map((data) => data.cost_amount)
  //       .reduce(reducer);
  //     setTotal(total);
  //   }
  // };

  //   useEffect(() => {
  //     totalBalance();
  //   }, [data.trip_cost.length]);
  return (
    <Mini_details_carrier_card_main_container_payments
      className="mini-details-carrier-card-main-container_payments"
      style={{
        backgroundColor: darkMode
          ? colorPaletteDark.secondaryColor
          : colorPalette.white,
      }}
    >
      {/* <div className="mini-details-carrier-img-payments">
        <img src={vehicles} alt="" width="40%" height="90%" />
      </div> */}

      <div className="mini-details-carrier-body">
        <Box
          sx={{
            width: "100%",
            backgroundColor: darkMode
              ? colorPaletteDark.secondaryColor
              : colorPalette.white,
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{ width: "100%" }}
            >
              <Tab
                label="Details"
                sx={{
                  color: darkMode ? colorPaletteDark.white : colorPalette.black,
                }}
                // style={{ width: "100%", margin: "0 auto" }}
                {...a11yProps(0)}
              />
              <Tab
                label="Payment Details"
                {...a11yProps(1)}
                sx={{
                  color: darkMode ? colorPaletteDark.white : colorPalette.black,
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {/* <div className="payment_details"></div> */}
            <div className="payment-warapper">
              <h5
                style={{
                  position: "relative",
                  color: darkMode ? colorPaletteDark.white : colorPalette.black,
                }}
              >
                Vehicle Number{" "}
                <span className="payment_values_monthly">
                  :{" "}
                  {MonthlyDetails.vehicle_details !== undefined
                    ? MonthlyDetails.vehicle_details.vehicle_number
                    : "--"}
                </span>
              </h5>
              <h5
                style={{
                  position: "relative",
                  margin: ".5rem 0rem",
                  color: darkMode ? colorPaletteDark.white : colorPalette.black,
                  // textAlign: "center",
                }}
              >
                Permit Number{" "}
                <span className="payment_values_monthly">
                  :{" "}
                  {MonthlyDetails.vehicle_details !== undefined
                    ? MonthlyDetails.vehicle_details.permit_number
                    : "--"}
                </span>
              </h5>
              <h5
                style={{
                  position: "relative",
                  margin: ".5rem 0rem",
                  color: darkMode ? colorPaletteDark.white : colorPalette.black,
                }}
              >
                Transporter{" "}
                <span className="payment_values_monthly">
                  :{" "}
                  {MonthlyDetails.vehicle_details !== undefined
                    ? MonthlyDetails.vehicle_details.transporter_name
                    : "--"}
                </span>
              </h5>
              <h5
                style={{
                  position: "relative",
                  margin: ".5rem 0rem",
                  color: darkMode ? colorPaletteDark.white : colorPalette.black,
                }}
              >
                Payout Period{" "}
                <span className="payment_values_monthly">
                  :{" "}
                  {MonthlyDetails.vehicle_details !== undefined
                    ? MonthlyDetails.vehicle_details.payout_period
                    : "--"}
                </span>
              </h5>
              <h5
                style={{
                  position: "relative",
                  margin: ".5rem 0rem",
                  color: darkMode ? colorPaletteDark.white : colorPalette.black,
                }}
              >
                Extra per Km rate{" "}
                <span className="payment_values_monthly">
                  : ₹{" "}
                  {MonthlyDetails.vehicle_details !== undefined
                    ? MonthlyDetails.vehicle_details.extra_per_km_rate
                    : "--"}
                </span>
              </h5>
              <h5
                style={{
                  position: "relative",
                  margin: ".5rem 0rem",
                  color: darkMode ? colorPaletteDark.white : colorPalette.black,
                }}
              >
                Fixed Kms{" "}
                <span className="payment_values_monthly">
                  :{" "}
                  {MonthlyDetails.vehicle_details !== undefined
                    ? MonthlyDetails.vehicle_details.fixed_kms
                    : "--"}
                  Kms
                </span>
              </h5>
              <h5
                style={{
                  position: "relative",
                  margin: ".5rem 0rem",
                  color: darkMode ? colorPaletteDark.white : colorPalette.black,
                }}
              >
                Basic Payout Amount{" "}
                <span className="payment_values_monthly">
                  : ₹{" "}
                  {MonthlyDetails.vehicle_details !== undefined
                    ? MonthlyDetails.vehicle_details.basic_payout_amount
                    : "--"}
                </span>
              </h5>
              <h5
                style={{
                  position: "relative",
                  margin: ".5rem 0rem",
                  color: darkMode ? colorPaletteDark.white : colorPalette.black,
                }}
              >
                cut of day of month{" "}
                <span className="payment_values_monthly">
                  :{" "}
                  {MonthlyDetails.vehicle_details !== undefined
                    ? MonthlyDetails.vehicle_details.payout_cutoff_day_of_month
                    : "--"}
                </span>
              </h5>
              {/* <h5 style={{ position: "relative", margin: ".5rem 0rem" }}>
                Vehicle Number{" "}
                <span className="payment_values">
                  : {MonthlyDetails.vehicle_number}
                </span>
              </h5> */}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="payment_details">
              <div className="payments_terms">
                <h5
                  style={{
                    color: darkMode
                      ? colorPaletteDark.white
                      : colorPalette.paragraphColor,
                  }}
                >
                  Vehicle Number:{" "}
                  {/* <span style={{ color: "grey" }}>To Be billed</span> */}
                </h5>
                <h5 style={{ color: "green" }}>
                  {" "}
                  {MonthlyDetails.vehicle_details !== undefined
                    ? MonthlyDetails.vehicle_details.vehicle_number
                    : "--"}
                </h5>
              </div>
              <div className="payments_terms">
                <h5
                  style={{
                    color: darkMode
                      ? colorPaletteDark.white
                      : colorPalette.paragraphColor,
                  }}
                >
                  Basic Payout Amount:{" "}
                  {/* <span style={{ color: "grey" }}>To Be billed</span> */}
                </h5>
                <h5 style={{ color: "green" }}>
                  ₹{" "}
                  {MonthlyDetails.vehicle_details !== undefined
                    ? MonthlyDetails.vehicle_details.basic_payout_amount
                    : "--"}
                </h5>
              </div>
              <div className="trip_cost_details_monthly">
                <div className="trip_breakups">
                  <div className="break_ups">
                    <h6>Fixed Kms :</h6>{" "}
                    <h6>
                      {MonthlyDetails.vehicle_details !== undefined
                        ? MonthlyDetails.vehicle_details.fixed_kms
                        : "--"}{" "}
                      Kms
                    </h6>
                  </div>
                  <div className="break_ups">
                    <h6>Total distance travelled :</h6>{" "}
                    {MonthlyDetails.total_distance_travelled >= 0 ? (
                      <h6>
                        {MonthlyDetails.vehicle_details !== undefined
                          ? MonthlyDetails.total_distance_travelled
                          : "--"}
                        kms
                      </h6>
                    ) : (
                      "--"
                    )}
                  </div>
                  <hr
                    style={{
                      backgroundColor: "black",
                      width: "100%",
                      marginBottom: ".5rem",
                    }}
                  />
                  {/* {otherData ? ( */}
                  {MonthlyDetails.vehicle_details ? (
                    <div className="break_ups">
                      <h6>Extra distance travelled:</h6> {/* {otherData?} */}
                      {MonthlyDetails.total_distance_travelled -
                        MonthlyDetails.vehicle_details.fixed_kms >
                      0 ? (
                        <h6>
                          {MonthlyDetails.total_distance_travelled -
                            MonthlyDetails.vehicle_details.fixed_kms}{" "}
                          Kms
                        </h6>
                      ) : (
                        <h6>--</h6>
                      )}
                    </div>
                  ) : null}
                </div>
                {/* <div className="break_ups">
                  <h6>Total distance :</h6>{" "}
                  <h6>{100 - MonthlyDetails.fixed_kms} Kms</h6>
                </div> */}
              </div>
              {/* <hr style={{ backgroundColor: "black", width: "100%" }} /> */}
              {/* <div className="trip_breakups_monthly">
                <div className="break_ups">
                  <h6>Extra distance travelled:</h6>{" "}
                  <h6>{100 - MonthlyDetails.fixed_kms} Kms</h6>
                </div>
              </div> */}
              <div className="total_paid_monthly">
                <div className="trip_total_headings">
                  <h5
                    style={{
                      width: "98%",
                      padding: ".3rem .3rem",
                      color: darkMode
                        ? colorPaletteDark.white
                        : colorPalette.paragraphColor,
                    }}
                  >
                    Cost Break-up:
                  </h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItem: "center",
                    justifyContent: "space-between",
                    padding: "0rem .5rem",
                    color: darkMode
                      ? colorPaletteDark.white
                      : colorPalette.paragraphColor,
                  }}
                >
                  <h5> Extra per km rate:</h5>{" "}
                  <h5 style={{ color: "green" }}>
                    ₹{" "}
                    {MonthlyDetails.vehicle_details !== undefined
                      ? MonthlyDetails.vehicle_details.extra_per_km_rate
                      : "--"}
                  </h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItem: "center",
                    justifyContent: "space-between",
                    padding: ".5rem .5rem",
                    color: darkMode
                      ? colorPaletteDark.white
                      : colorPalette.paragraphColor,
                  }}
                >
                  <h5> Basic Payout Amount:</h5>{" "}
                  <h5 style={{ color: "green" }}>
                    ₹{" "}
                    {MonthlyDetails.vehicle_details !== undefined
                      ? MonthlyDetails.vehicle_details.basic_payout_amount
                      : "--"}
                  </h5>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItem: "center",
                    justifyContent: "space-between",
                    padding: "0 .5rem",
                    color: darkMode
                      ? colorPaletteDark.white
                      : colorPalette.paragraphColor,
                  }}
                >
                  <h5> Extra Kms Amount:</h5> {/* {otherData ? ( */}
                  {MonthlyDetails.vehicle_details &&
                  MonthlyDetails.total_distance_travelled > 0 ? (
                    <h5 style={{ color: "green" }}>
                      {MonthlyDetails.total_distance_travelled -
                        MonthlyDetails.vehicle_details.fixed_kms}
                      kms X ₹ {MonthlyDetails.vehicle_details.extra_per_km_rate}{" "}
                      = ₹ {totals ? totals : "--"}
                    </h5>
                  ) : (
                    "--"
                  )}
                </div>
                <hr
                  style={{
                    backgroundColor: "black",
                    width: "100%",
                    margin: ".3rem 0rem",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItem: "center",
                    justifyContent: "space-between",
                    padding: ".5rem .5rem",
                    color: darkMode
                      ? colorPaletteDark.white
                      : colorPalette.paragraphColor,
                  }}
                >
                  <h5>Total Payable Amount:</h5>{" "}
                  {MonthlyDetails.vehicle_details !== undefined &&
                  MonthlyDetails.vehicle_details.basic_payout_amount > 0 &&
                  totals > 0 ? (
                    <h5 style={{ color: "red" }}>
                      ₹{" "}
                      {MonthlyDetails.vehicle_details.basic_payout_amount +
                        totals -
                        paidAmount}
                    </h5>
                  ) : (
                    "--"
                  )}
                </div>
              </div>
            </div>
            {/* <div className="trip_cost_details">
                <div className="trip_total_headings">
                  <h5>Trip Total Cost:</h5>
                </div>
                <div className="trip_breakups">
                
                </div>
                <hr style={{ backgroundColor: "black", width: "100%" }} />
                <div className="trip_breakups">
                  <div className="break_ups">
                    <h6>Total :</h6> <h6>₹{total}</h6>
                  </div>
                </div>
              </div> */}
            {/* <div className="total_paid">
                <h5> Paid Amount:</h5>{" "}
                <h5 style={{ color: "green" }}>₹{paid}</h5>
              </div>
              <div className="total_paid">
                <h5>Due Amount:</h5>{" "}
                <h5 style={{ color: "red" }}>₹{total - paid}</h5>
              </div> */}

            {/* <h6></h6> */}
          </TabPanel>
        </Box>
      </div>
    </Mini_details_carrier_card_main_container_payments>
  );
}

// {
//   "is_active": true,
//   "id": 1,
//   "last_name": null,
//   "alternate_number": null,
//   "driving_licence_number": "AH7860051",
//   "ccreated_on": "2022-03-11T13:13:17.704420",
//   "modified_on": "2022-03-11T13:13:17.707110",
//   "first_name": "sonali",
//   "mobile_number": "9534939031",
//   "pan_number": "AAAPZ0071C"
// },

// {
//   "source_id": 9,
//   "approx_cost_id": 5,
//   "actual_cost_id": null,
//   "id": 5,
//   "payments_status": "paid",
//   "load_source": "trip_purchase_order",
//   "created_on": "2022-03-14T11:25:26.316874",
//   "load_unit_id": null,
//   "vehicle_number": "KA-29-2125",
//   "driver_id": 5,
//   "destination_id": 10,
//   "is_active": true,
//   "modified_on": "2022-03-14T11:25:26.319086",
//   "trip_distance_in_km": 78.0,
//   "trip_code": "KU0314251647257126",
//   "zoho_id": "670003",
//   "remarks": null,
//   "trip_status": "created",
//   "attachment_count": 0
// },

export default PaymentMonthlyCard;
