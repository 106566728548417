import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { argon2id, argon2Verify } from "hash-wasm";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import apiUrl from "../../url/apiUrl";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Button from "@mui/material/Button";
import PasswordIcon from "@mui/icons-material/Password";
import { getCustomersInfo, LoginPost } from "./LoginServices";
import Input from "@mui/material/Input";
import { CircularProgress } from "@mui/material";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Link, useHistory } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MailIcon from "@mui/icons-material/Mail";
import apiUrl from "../../url/apiUrl";
import logo from "../../assets/logo/leap_supply_logo.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { fetchAppToken } from "../../helper/AppTokenHelper";
import { Login_Container } from "./login";
import { colorPalette } from "../../themes/theme";

const CryptoJS = require("crypto-js");

function LoginAsCustomer() {
  let history = useHistory();
  const [loginEmail, setLoginEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");

  const [loginPassword, setLoginPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [errLogin, setErrLogin] = useState("");

  const [values, setValues] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const getUserName = async (userName) => {
    try {
      const userResponse = await axios.get(
        `${apiUrl.loginUserVerify}/${userName}`
      );

      if (userResponse.status == 200) {
        return userResponse;
      }
    } catch (error) {
      return error.response;
    }
  };
  async function argon2HashPassword(user, userPassword) {
    let decodedUserName = atob(user);

    // setUserNameFromApi(ddecodedUserName);
    const salt = decodedUserName;
    const hash = await argon2id({
      password: userPassword,
      salt,
      parallelism: 1,
      iterations: 2,
      memorySize: 16,
      hashLength: 16,
      outputType: "encoded",
    });
    return hash;
  }
  const verifyUsers = async (loginUsers) => {
    const users = await getUserName(loginUsers);

    if (users.status == 200) {
      const argon = await argon2HashPassword(users.data, loginPassword);

      // setUserNameFromApi(users.data);
      // if (argon) {
      LoginCustomerPost(users.data, argon);

      // }
    }
    if (users.status == 401) {
      setErrLogin(users.data.detail);
      setLoadingLogin(false);
      // setErrorEmail("");
    }
    if (users.status == 400) {
      setErrLogin(users.data.detail);
      setLoadingLogin(false);
      // setErrorEmail("");
    }
  };
  const handleLogin = async (ev) => {
    ev.preventDefault();
    if (email) {
      verifyUsers(email);
    }
    setLoadingLogin(true);
  };

  //old users prod
  const website_url = process.env.REACT_APP_CUSTOMER_WEBSITE_URL;
  // const website_url = "https://customers.leapsupply.in";
  const LoginCustomerPost = (username, argondata) => {
    const isValid = formValidation();

    if (isValid) {
      setLoadingLogin(true);
      var data = JSON.stringify({
        user_name: username ? username : "",
        password: argondata,
        app_name: process.env.REACT_APP_CUSTOMER_APP_NAME,
        // app_name: "Leap-Supply",
      });
      //
      var config = {
        method: "post",
        url: apiUrl.loginUrl,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          if (response.status === 200) {
            fetchAppTokenData(
              website_url,
              process.env.REACT_APP_CLIENT,
              process.env.REACT_APP_VERSION
            );
            localStorage.setItem(
              "loginApp",
              process.env.REACT_APP_CUSTOMER_APP_NAME
            );
            // localStorage.setItem("AUTH_TOKEN", response.data.access_token);

            localStorage.setItem(
              "website_url",
              process.env.REACT_APP_CUSTOMER_WEBSITE_URL
            );
            const login_data = {
              AUTH_TOKEN: response.data.last_access_token,
              IS_LOGGED_IN: "true",
              USER_NAME: response.data.user_name,
              USER_EMAIL: response.data.email,
              GROUPS: response.data.group_names,
              Role: response.data.role,
              app_name: response.data.app_name,
              temporary_password:
                response.data.logged_in_with_temporary_password,
              customer_id: response.data.user_id,
            };
            // getCustomerData();
            let encrypted_data = CryptoJS.AES.encrypt(
              JSON.stringify(login_data),
              apiUrl.SALT
            ).toString();

            localStorage.setItem("LOGIN_DATA", encrypted_data);
            setLoadingLogin(false);
          }
          // if (response.data.app_name == "leap-supply-customers") {
          history.push("/customer/customer-indents");
          // }
          //
          // history.push("/vehicles-manufacturers");
          window.location.reload();
          // setLoginPassword("");
          // setEmail("");
        })
        .catch(function (error) {
          //
          if (error.response.status == 400) {
            setErrorMsg(error.response.data.detail);
            setLoadingLogin(false);
            // setCircularProgress(false);
            //
            //
          }
          // setErrLogin("email id/password is wrong");
          // setLoadingLogin(false);
          // alert(error);
        });
    }
    setLoadingLogin(false);
    // setLoadingLogin(false);
  };

  // const handleLogin = async (ev) => {
  //   ev.preventDefault();
  //   // setLoadingLogin(true);
  //   LoginUserPost();
  //   // setLoadingLogin(false);
  // };
  const [errorEmail, setErrorEmail] = useState("");
  //
  const [errorpassword, setErrorPassword] = useState("");
  const formValidation = () => {
    const errorEmail = {};
    const errorPassword = {};
    let isValid = true;
    if (email == "") {
      errorEmail.emptyemail = "Please Enter Email Id or Mobile No.";
      isValid = false;
    }
    if (loginPassword == "") {
      errorPassword.emptyPassword = "Please Enter Password.";
    }
    setErrorEmail(errorEmail);
    setErrorPassword(errorPassword);
    return isValid;
  };
  const handleOnkeyPress = (e) => {
    if (e.key == "Enter") {
      handleLogin(e);
    }
  };
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const forgetPassword = () => {
    localStorage.setItem("loginApp", process.env.REACT_APP_CUSTOMER_APP_NAME);
    history.push("/forget-password");
  };
  // =========x-app-token==========

  const fetchAppTokenData = async (url, client, version) => {
    const response = await fetchAppToken(url, client, version);

    if (response.status == 200) {
      const xToken = {
        X_TOKEN: response.data.token,
      };

      let encrypted_data = CryptoJS.AES.encrypt(
        JSON.stringify(xToken),
        // .SALT
        apiUrl.SALT
      ).toString();

      localStorage.setItem("x_token", encrypted_data);
    } else if (response == 401) {
      // window.location.reload();
    }
  };
  useEffect(() => {
    localStorage.removeItem("loginApp");
    // window.location.reload();
  }, []);
  // ==============================
  const backToHome = () => {
    // localStorage.removeItem("loginApp");
    // window.location.reload();
    history.push("/");
  };
  return (
    <Login_Container className="login_container">
      <div className="blur">
        <div className="login_left"></div>
        <div className="login_right">
          <div className="login_form">
            <div className="logo_container">
              {isMobile ? null : (
                <img src={logo} alt="" className="logo_leap" />
              )}
            </div>
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#016938"
              fill-opacity="1"
              d="M0,256L40,240C80,224,160,192,240,154.7C320,117,400,75,480,90.7C560,107,640,181,720,181.3C800,181,880,107,960,117.3C1040,128,1120,224,1200,245.3C1280,267,1360,213,1400,186.7L1440,160L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
            ></path>
          </svg> */}
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#016938"
                fill-opacity="1"
                d="M0,288L80,272C160,256,320,224,480,208C640,192,800,192,960,197.3C1120,203,1280,213,1360,218.7L1440,224L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
              ></path>
            </svg> */}
            {/* 
            <div className="headings_div">
              <h5 className="headings">FAARMS GLOBAL</h5>{" "}
            </div> */}

            {/* <div className="login_box">
                <h5 className="login_text_mobile">
                  Please Enter 10 Digit Mobile Number To Login.
                </h5>{" "}
              </div> */}
            <div className="login_input">
              <div className="headings_login">
                <h5 className="login_text">Customer Login</h5>{" "}
              </div>
              <TextField
                id="standard-size-normal"
                className="login-input-field"
                placeholder="Enter Email or Mobile No."
                // defaultValue="Enter Mobile Number"
                variant="standard"
                type="text"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrLogin("");
                }}
                // onInput={(e) => {
                //
                //   if (typeof e.target.value == "number") {
                //
                //     e.target.value = Math.max(0, parseInt(e.target.value))
                //       .toString()
                //       .slice(0, 4);
                //   }
                // }}
              />
              {email.length > 0 ? null : (
                <>
                  {" "}
                  {Object.keys(errorEmail).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", margin: "4px" }}>
                        {errorEmail[key]}
                      </h6>
                    );
                  })}
                </>
              )}
            </div>
            <div className="login_input">
              <Input
                id="standard-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={loginPassword}
                placeholder="Enter Password"
                className="login-input-field"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      className="show_button"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      // onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={(e) => {
                  setLoginPassword(e.target.value);
                  setErrLogin("");
                  // setErrLogin("");
                }}
                onKeyPress={(e) => handleOnkeyPress(e)}
              />
              {loginPassword.length > 0 ? null : (
                <>
                  {" "}
                  {Object.keys(errorpassword).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", marginLeft: ".3rem" }}>
                        {errorpassword[key]}
                      </h6>
                    );
                  })}
                </>
              )}
            </div>
            {/* <div className="login_input">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="standard-size-normal"
                  placeholder="Enter OTP received."
                  variant="standard"
                  type="number"
                  className="input_mobile"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 4);
                  }}
                />
              </Box>
            </div> */}
            <div className="error-message">{errLogin ? errLogin : null}</div>
            <div className="forget-password-div">
              {" "}
              <span className="forget-password-text" onClick={forgetPassword}>
                <HelpOutlineIcon style={{ fontSize: "1rem" }} />
                Forgot password?
              </span>{" "}
            </div>
            <div className="button_container">
              <Button
                variant="contained"
                // color="success"
                onClick={handleLogin}
                style={{
                  backgroundColor: colorPalette.primaryColor,
                  borderRadius: "5px",
                  padding: ".5rem 0rem",
                }}
                className="login-input-field"
              >
                {loadingLogin ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  "LOGIN"
                )}
              </Button>
              <div className="back_to_home_button">
                <KeyboardBackspaceIcon />

                <Link onClick={backToHome}>Back To Home</Link>
              </div>
            </div>
            <div
              style={{
                width: "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "1.5rem auto",
              }}
            >
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
          </div>
        </div>
      </div>
    </Login_Container>
  );
}

export default LoginAsCustomer;
