import React, { useEffect, useState, useRef, useCallback } from "react";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import TripAttachmentsDetails from "../../../assets/tripIcons/newIcons/trip_attachment_icon(1).svg";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import TripPickup from "../tripPickup/TripPickup.jsx";
import INFOICON from "../../../assets/infoIcon.svg";
import mapIcon from "../../../assets/map_icon.svg";
import NoDetailsFound from "../../../assets/tripIcons/no_data_found.png";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import crashIcon from "../../../assets/tripIcons/crash_7901559.png";
import {
  Input,
  InputNumber,
  Modal as ModalAntd,
  Popover,
  Switch,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import VerifiedSharpIcon from "@mui/icons-material/VerifiedSharp";
import "./tripListV2.css";
import {
  approveRejectModal,
  approvedTripCancelModal,
  arrivedAtDestinationModal,
  intransitCompleteModal,
  invoiceSubmissionModal,
  tripCancleModal,
  tripDisputeModal,
  tripReopenModal,
  tripResolveModal,
  verifyDocumentModal,
} from "../../../components/separatedModalsForTrips/allModalExportFile.jsx";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Timeline } from "antd";
import { DatePicker, TimePicker } from "antd";
import {
  approveRejectTrip,
  GetTripList,
  GetTripListWithFilter,
  GetTripPaymentStatusEnums,
  GetTripStatusEnums,
  reopenCancelTrip,
  startTrip,
  stopTrip,
  TripAttachmentPost,
  GetCustomerListApi,
  GetTransporterByName,
  getTripExport,
  GetTripCostApi,
  RecommendRejectPayment,
  arrivedAtDestination,
  InvoiceGeneration,
  InvoiceSubmitted,
  ManualInvoiceGeneration,
  InvoiceSent,
  GetZohoInvoiceCustomerList,
  GetTripLog,
  TripLogsPost,
  GetTripLogEnum,
  changeStatusToDisputed,
  resolveDisputeAndChangeStatus,
  GetTransporterPaymentHistory,
  TransporterPaymentHistoryPost,
  verifyDocument,
  approvedTripCancel,
  GetEwayBill,
  EwayBillChange,
  getTripAttachmentsDispute,
  getTripReportExport,
  InvoiceRejected,
} from "./tripListServices.js";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NegetiveTripIcon from "../../../assets/negative.png";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Checkbox from "@mui/material/Checkbox";
import { Dropdown, Space } from "antd";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import imageloader from "../../../assets/tripIcons/ZKZg.gif";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import showIcons from "../../../assets/tripIcons/view.svg";
import downloadIcons from "../../../assets/tripIcons/download.svg";
import fileIcons from "../../../assets/tripIcons/Group 343.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import Typography from "@mui/material/Typography";
import FeedIcon from "@mui/icons-material/Feed";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ApprovalIcon from "@mui/icons-material/Approval";
import DangerousIcon from "@mui/icons-material/Dangerous";
import PanToolIcon from "@mui/icons-material/PanTool";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloseIcon from "@mui/icons-material/Close";
import negetiveGraph from "../../../assets/fall.svg";
import positiveGraph from "../../../assets/rise.svg";

//icons

import tripDetalsIcon from "../../../assets/tripListIcons/icon_trip_trip_inactive.svg";
import tripDetalsIconActive from "../../../assets/tripListIcons/icon_trip_trip_active.svg";
import tripDriverIcon from "../../../assets/tripListIcons/icon_trip_driver_inactive.svg";
import tripDriverIconActive from "../../../assets/tripListIcons/icon_trip_driver_active.svg";
import tripHistoryIcon from "../../../assets/tripListIcons/icon_trip_history_inactive.svg";
import tripHistoryIconActive from "../../../assets/tripListIcons/icon_trip_history_active.svg";
import tripAttachmentIcon from "../../../assets/tripListIcons/icon_trip_attach_inactive.svg";
import tripAttachmentIconActive from "../../../assets/tripListIcons/icon_trip_attach_active.svg";
import tripCostIcon from "../../../assets/tripListIcons/icon_trip_cost_inactive.svg";
import tripCostIconActive from "../../../assets/tripListIcons/icon_trip_cost_active.svg";
import tripLogIcon from "../../../assets/tripListIcons/icon_trip_log_inactive.svg";
import tripLogIconActive from "../../../assets/tripListIcons/icon_trip_log_active.svg";
import tripPaymentIcon from "../../../assets/tripListIcons/icon_trip_payment_inactive.svg";
import tripPaymentIconActive from "../../../assets/tripListIcons/icon_trip_payment_active.svg";
import tripEwayBillIcon from "../../../assets/tripListIcons/icon_trip_eway_inactive.svg";
import tripEwayBillIconActive from "../../../assets/tripListIcons/icon_trip_eway_active.svg";

import AccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/system";
import Snackbar from "@mui/material/Snackbar";
import {
  GetCustomerSearchList,
  GetDrivers,
  UpdateDriverPost,
} from "../createTrip/createTripServices.js";
import success from "../../../assets/dashboard_icons/icons8-truck.gif";
import { NavLink, useHistory } from "react-router-dom";
import {
  Search,
  Close,
  Edit,
  Add,
  Download,
  AudioFileOutlined,
  FileUpload,
  Visibility,
  Description,
  CheckCircle,
  DoneAll,
  Done,
  TripOrigin,
  PanoramaFishEye,
  PeopleAlt,
  ZoomIn,
  ZoomOut,
  Remove,
  Rotate90DegreesCw,
} from "@mui/icons-material";
import MiniDetailsCard from "../../../components/miniDetailsCard/MiniDetailsCard.jsx";
import Modal from "@mui/material/Modal";
import FILTER_ICON from "../../../assets/filter_icon.svg";
import UploadImage from "../../../assets/upload.svg";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { fontTheme, tripCardLabel } from "../../../helper/themeClass.js";
import {
  CircularProgress,
  TextField,
  MenuItem,
  Alert,
  Drawer,
  InputAdornment,
  Menu,
  Autocomplete,
  Accordion,
  AccordionSummary,
  Stack,
  IconButton,
  Collapse,
  Dialog,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  Select,
  selectClasses,
  OutlinedInput,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  reduxStoreTripList,
  updateDriverDetails,
  updatePendingTripInvoiceList,
  updateSelectedTrip,
  updateSelectedTripInvoices,
  updateTripDetails,
  updateTripSearchValue,
} from "../../../redux/tripSlice.js";
import axios from "axios";
import MobileHeader from "../../../components/MobileHeader/MobileHeader.jsx";
import {
  indexSelectedData,
  readLoginData,
  readToken,
} from "../../../helper/dataDecryptionHelper.js";
import { GetDocumentEnums } from "../createTrip/createTripServices.js";
import * as moment from "moment";
import { ModalUnstyled } from "@mui/base";
import AsyncSelect from "react-select/async";
import CopyText from "../../../components/copyText/CopyText.jsx";
import axiosInterceptor from "../../../config/https.js";
import {
  GetDriverDetails,
  GetTripAttachments,
  GetTripHistory,
  GetTripInvoicePaymentByTripId,
  GetTripItemsForDeliveryInfo,
  GetTripPaymentHistory,
} from "../../../components/miniDetailsCard/miniDetailsCardServices.js";
import {
  convertToBase64,
  prefixSalt,
} from "../../../helper/base64Converter.js";
import {
  Trip_Pickup_modal,
  Trip_list_drawer_top_row,
  Trip_list_filter_main,
  Trip_list_main_container,
  Trip_list_modal_container,
  Trip_settings_wrapper,
  UserNotAuthorizedStyle,
} from "./trip-listv2.js";
import {
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../../themes/theme.js";
import {
  ButtonRectangle,
  ButtonRectangleSecondary,
} from "../../../themes/components/button.js";
import {
  approveCompleteTripRequest,
  rejectCompleteTripRequest,
} from "../completeTrip/completeTripServices.js";
import Topbar from "../../../components/topbar/Topbar.jsx";
import { useContext } from "react";
import { DarkModeContext } from "../../../context/darkModeContext.js";
import CascadingMenu from "../../../components/cascadingMenu/CascadingMenu.jsx";
import { getVillagesList } from "../../warehouses/warehousesServices.js";
import apiUrl from "../../../url/apiUrl.js";
import { Link } from "react-router-dom/cjs/react-router-dom.js";
import NotAuthorized from "../../../components/notAuthorized/NotAuthorized.jsx";
import SomethingWentWrong from "../../../components/somethingWentWrong/SomethingWentWrong.jsx";
import { MyContext } from "../../../components/enumContext/EnumContextComponent.jsx";
import { allRegex } from "../../../utility/regex.js";
import {
  showAttachmentsUtility,
  showOrDownlaodAttachmentsUtility,
} from "../../../utility/FetchImage.js";
import { saveBlob } from "../../../utility/saveBlobImage.js";
import MultiTripInvoice from "../../../components/multiTripInvoice/MultiTripInvoice.jsx";
import { getTeams } from "../../teams/TeamsService.jsx";
import { imageModalStyle, pdfEmbedStyle } from "../../../themes/commonStyle.js";
import FtlTripListV3 from "../../../components/FtlTripListV3/FtlTripListV3.jsx";
import TripListDrawerDetails from "../../../components/tripListDrawerDetails/TripListDrawerDetails.jsx";
import { FtlTripListContext } from "../../../context/ftlListVersionContext.js";
import Pincode from "../../../components/countryBasePincode/Pincode.jsx";

const CryptoJS = require("crypto-js");
const { TextArea } = Input;

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

const filterBoxStyle = {
  width: isMobile ? "95vw" : "36%",
  height: isMobile ? "75vh" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -34,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};

const pickupBoxStyle = {
  width: isMobile ? "90vw" : "55%",
  height: isMobile ? "auto" : "73vh",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -24,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};

const tripRoutesBoxStyle = {
  width: isMobile ? "90vw" : "50%",
  height: isMobile ? "auto" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -24,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};

const exportBoxStyle = {
  width: isMobile ? "85%" : "70%",
  height: isMobile ? "80vh" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  border: "1px solid gray",
  borderRadius: "10px",
};

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${isMobile ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.5)`};
  -webkit-tap-highlight-color: transparent;
`;
const progressSx = {
  borderRadius: "50%",
  boxShadow: `inset 0 0 0 0`,
};
function TripListV2() {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const { newFtlListVersion, tripListVersionToggle } =
    useContext(FtlTripListContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const validEmailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
  const [allTripList, setAllTripList] = useState([]);
  const [isRejectButtonClicked, setIsRejectButtonClicked] = useState(false);
  const [pincode, setPincode] = useState("");
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [openTripDocs, setOpenTripDocs] = useState(false);
  const handleOpenImage = () => setOpenTripDocs(true);
  const [loadingImage, setLoadingImage] = useState(false);
  const handleCloseImage = () => {
    setOpenTripDocs(false);
    setRotateImageValue(0);
  };
  const [selectedTrip, setSelectedTrip] = useState({});
  console.log(selectedTrip, "invoice generation selected trip ==>");
  const [selectedTripIndex, setSelectedTripIndex] = useState(null);
  const [tripRemarks, setTripRemarks] = useState("");
  const [selectedTripStatus, setselectedTripStatus] = useState(15);
  const { enums } = useContext(MyContext);

  const [selectedTripStatusText, setSelectedTripStatusText] = useState(
    localStorage.getItem("IntransiteStatus")
      ? localStorage.getItem("IntransiteStatus")
      : ""
  );
  console.log(selectedTripStatusText, selectedTripStatus, "status");
  const [villagesList, setVillagesList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [notServiceableError, setNotServiceableError] = useState("");
  const [pincodeDetails, setPincodeDetails] = useState({
    state: "",
    district: "",
  });
  const [tripLogs, setTripLogs] = useState({
    trip_id: "",
    time_of_log: "",
    expected_time_to_destination: "",
    street_address: "",
    remarks: "",
    ...pincodeDetails,
  });
  const [transporterPaymentHistory, setTransporterPaymentHistory] = useState({
    utr_reference_number: "",
    amount_paid: "",
    remarks: "",
    image: "",
  });

  const [disableHistorySaveButton, setDisableHistorySaveButton] =
    useState(true);

  const [disableCreateLogSubmitButton, setDisableCreateLogSubmitButton] =
    useState(true);
  const [incidentType, setIncidentType] = useState("");
  const [paymentRemarks, setPaymentRemarks] = useState("");
  const [tripLogList, setTripLogList] = useState([]);
  const [transporterPaymentHistoryList, setTransporterPaymentHistoryList] =
    useState([]);
  const [filteredAttachmentsWithTag, setFilteredAttachmentsWithTag] = useState(
    []
  );
  const [ewayBillDrawerLoading, setEwayBillDrawerLoading] = useState(false);
  const [ewayBillButtonLoading, setEwayBillButtonLoading] = useState(false);
  const [tripEwayBillDetails, setTripEwayBillDetails] = useState([]);
  const [ewayBillError, setEwayBillError] = useState("");
  const [ewayBillAttachmentError, setEwayBillAttachmentError] = useState("");
  const [unauthorizeError, setUnauthorizeError] = useState("");
  const [updatedEwayBillDetails, setUpdatedEwayBillDetails] = useState({
    remarks: "",
    eway_bill_number: "",
    eway_bill_valid_from: "",
    eway_bill_valid_till: "",
    trip_status: "",
    eway_bill_attachment: "",
    eway_bill_attachment_tag: "",
    eway_bill_attachment_type: "",
  });
  const [currentButton, setCurrentButton] = useState("");
  const [selectedTripId, setSelectedTripId] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const [attachmentTag, setAttachmentTag] = useState("builty copy");
  const [errTotalExport, setErrTotalExport] = useState("");
  const [errTripRemarks, setErrTripRemarks] = useState("");
  const [errAttachmentTag, setErrAttachmentTag] = useState("");
  const [errAttachmentType, setErrAttachmentType] = useState("");
  const [errAttachmentFile, setErrAttachmentFile] = useState("");
  const [filterTripCode, setFilterTripCode] = useState("");
  const [filterPaymentStatus, setFilterPaymentStatus] = useState("");
  const [filterVehicleNumber, setFilterVehicleNumber] = useState("");
  const [filterCustomerId, setFilterCustomerId] = useState("");
  const [filterCustomerData, setFilterCustomerData] = useState({});
  const [filterLoadSource, setFilterLoadSource] = useState("");
  const [filterReferenceNumber, setFilterReferenceNumber] = useState("");
  const [filterEwayBillNo, setFilterEwayBillNo] = useState("");
  const [tripExportTripTypeError, setTripExportTripTypeError] = useState("");

  const [loadingFilterSearchButton, setLoadingFilterSearchButton] =
    useState(false);
  const [filterTripStatus, setFilterTripStatus] = useState("");
  const [tripType, setTripType] = React.useState(
    localStorage.getItem("tripType") ? localStorage.getItem("tripType") : ""
  );
  const [currentPage, setCurrentPage] = useState(1);
  localStorage.setItem("tripType", tripType);
  const [userRole, setUserRole] = useState("");
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [tripDeliveredBills, setTripDeliveredBills] = useState([]);
  const [tripAttachmentFile, setTripAttachmentFile] = useState(null);
  const [errTripStatus, setErrTripStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isPickupOpen, setIsPickupOpen] = useState(false);
  const [isExportOpen, setIsExportOpen] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [exportToastMessage, setExportToastMessage] = useState("");
  const [faarmsAndConnectIndiaMailError, setFaarmsAndConnectIndiaMailError] =
    useState("");
  const [advanceSearchError, setAdvanceSearchError] = useState({
    lr_number_error: "",
    vehicle_number_error: "",
    eway_bill_no_error: "",
  });
  const [OpenInvoiceModal, setopenInvoiceModal] = useState(false);
  const [openInvoiceSubmitModal, setopenInvoiceSubmitModal] = useState(false);
  const [openInvoiceRejectModal, setopenInvoiceRejectModal] = useState(false);
  const [openInvoiceGenerationModal, setOpenInvoiceGenerationModal] =
    useState(false);
  const [loadingGenerateInvoice, setLoadingGenerateInvoice] = useState(false);
  const [errZohoCustomerInvoiceNumber, setErrZohoCustomerInvoiceNumber] =
    useState("");

  const [tripSettingsState, setTripSettingsState] = useState({ right: false });
  const toggTripSettingsDrawer = (anchor, open) => {
    setTripSettingsState({ ...tripSettingsState, [anchor]: open });
  };
  const [arrivedAtDate, setArrivedAtDate] = useState("");
  const [sessionExpires, setSessionExpires] = useState("");
  const [tripStatusList, setTripStatusList] = useState([
    "created",
    "approved",
    "intransit",
    "arrived_at_destination",
    "goods_delivered",
    "documents_verified",
    "trip_disputed",
    "hardcopy_pod_received",
    "invoice_generated",
    "invoice_sent",
    "invoice_submitted",
    "invoice_closed",
    "invoice_rejected",
    "rejected",
    "cancelled",
  ]);
  const [tripTypeList, setTripTypeList] = useState([
    "first_mile",
    "middle_mile",
    "last_mile",
  ]);
  const [filterTripType, setFilterTripType] = useState("");
  const [loadSourceList, setLoadSourceList] = useState([
    "trip_invoice",
    "trip_purchase_order",
    "manual",
  ]);
  const [isUserNotAuthorized, setIsUserNotAuthorized] = useState(false);
  const [tripPaymentStatusList, setTripPaymentStatusList] = useState([]);
  const [attachmentTypeList, setAttachmentTypeList] = useState([
    "image",
    "pdf",
  ]);
  const [attachmentListArray, setAttachmentListArray] = useState([
    {
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    },
  ]);
  const [zohoInvoiceNumberSearch, setZohoInvoiceNumberSearch] = useState("");

  const [errAttachmentSize, setErrAttachmentSize] = useState([]);
  const [attachmentType, setAttachmentType] = useState("0");
  const [state, setState] = useState({ right: false });
  const [stateLog, setStateLog] = useState({ right: false });
  const [stateTripPaymentHistory, setStateTripPaymentHistory] = useState({
    right: false,
  });
  const [loadingApproveButton, setLoadingApproveButton] = useState(false);
  const [currentStatusUpdateInProgress, setCurrentStatusUpdateInProgress] =
    useState("");
  const { tripSearchValue } = useSelector((state) => state.trip);
  const { reduxTripList } = useSelector((state) => state.trip);
  useEffect(() => {
    dispatch(updateTripSearchValue(""));
    localStorage.setItem("SELECTED_TRIP_STATUS", "");
    localStorage.setItem("SELECTED_TRIP_STATUS_INDEX", 13);
  }, []);
  const [isLastPage, setIsLastPage] = useState(false);
  const [triCostDetails, setTriCostDetails] = useState([]);
  const [loadingTripCost, setLoadingTripCost] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [currentSelectedTripStatus, setCurrentSelectedTripStatus] =
    useState("");
  const [openResolveDisputeModal, setOpenResolveDisputeModal] = useState(false);
  const [approvedOpenCancelModal, setApprovedOpenCancelModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openDisputeModal, setOpenDisputeModal] = useState(false);
  const [openDisputeDetailsModal, setOpenDisputeDetailModal] = useState(false);
  const handleOpenDistpute = () => setOpenDisputeModal(true);
  const handleCloseDistpute = () => setOpenDisputeModal(false);
  const [loadingDisputeModalButton, setLoadingDisputeModalButton] =
    useState(false);
  const handleDisputeModalButtonLoadingOn = () =>
    setLoadingDisputeModalButton(true);
  const handleDisputeModalButtonLoadingOff = () =>
    setLoadingDisputeModalButton(false);
  const [attachmentCount, setAttachmentCount] = useState([1]);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [exportFromDate, setExportFromDate] = useState("");
  const [exportToDate, setExportToDate] = useState("");
  const [exportTripStatus, setExportTripStatus] = useState("0");
  const [exportCustomer, setExportCustomer] = useState("");
  const [exportVehicleNumber, setExportVehicleNumber] = useState("");
  const [exportTransporter, setExportTransporter] = useState("");
  const [exportVehiclePayoutPeriod, setExportVehiclePayoutPeriod] =
    useState("0");
  const [exportPaymentStatus, setExportPaymentStatus] = useState("0");
  const [driverDetails, setDriverDetails] = useState({});
  const [secondaryDriverDetails, setSecondaryDriverDetails] = useState({});
  const [isSecondaryDriverEditMode, setIsSecondaryDriverEditMode] =
    useState(false);
  const [secondaryButtonLoading, setSecondaryButtonLoading] = useState(false);
  const [secondaryDriverId, setSecondaryDriverId] = useState("");
  const [secondaryDriverError, setSecondaryDriverError] = useState("");
  const [secondaryDriverDetailsInput, setSecondaryDriverDetailsInput] =
    useState({
      first_name: "",
      last_name: "",
      mobile_number: "",
      pan_number: "",
      driving_licence_number: "",
      alternate_mobile_number: "",
    });
  const [driverListOptions, setDriverListOptions] = useState([]);
  const [exportSourceState, setExportSourceState] = useState("0");
  const [exportDestinationState, setExportDestinationState] = useState("0");
  const [exportCreatedBy, setExportCreatedBy] = useState("");
  const [exportLoadSource, setExportLoadSource] = useState("0");
  const [exportEmail, setExportEmail] = useState("");
  const [exportTripType, setExportTripType] = useState("0");
  const [errExportEmailId, setErrExportEmailId] = useState("");
  const [customerSearchValue, setCustomerSearchValue] = useState("");
  const [exportCustomerSearchValue, setExportCustomerSearchValue] =
    useState("");
  const [openToast, setOpenToast] = useState(false);
  const [isApprovedIntransit, setIsApprovedIntransit] = useState(false);
  const [openPaymentApproveRejectToast, setOpenPaymentApproveRejectToast] =
    useState(false);
  const [vehiclePayoutPeriodList, setVehiclePayoutPeriodList] = useState([
    "trip_wise",
    "monthly_wise",
  ]);
  const [openTripRoutes, setOpenTripRoutes] = useState(false);
  const [date, setDate] = useState("");
  const [ackNumber, setAckNumber] = useState("");
  const [courierCompany, setCourierCompany] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [invoiceSubmitLoading, setInvoiceSubmitLoading] = useState(false);
  const [invoiceSubmittedLoading, setInvoiceSubmittedLoading] = useState(false);
  const [invoiceAutomaticGenLoading, setInvoiceAutomaticGenLoading] =
    useState(false);
  const [errInvoiceAckNo, setErrInvoiceAckNo] = useState("");
  const [errInvoiceCourier, setErrInvoiceCourier] = useState("");
  const [errInvoiceDate, setErrInvoiceDate] = useState("");
  const [
    transporterPaymentAttachmentModal,
    setTransporterPaymentAttachmentModal,
  ] = useState(false);
  const [transporterPaymentHistoryImage, setTransporterPaymentHistoryImage] =
    useState();
  const [transporterAttachmentPdf, setTransporterAttachmentPdf] = useState();
  const [invoiceSubmissionDate, setInvoiceSubmissionDate] = useState("");
  const [invoiceRejectionDate, setInvoiceRejectionDate] = useState("");
  const [invoiceRejectionRemarks, setInvoiceRejectionRemarks] = useState("");
  const [
    disableSubmitInvoiceModalSubmitButton,
    setDisableSubmitInvoiceModalSubmitButton,
  ] = useState(true);
  const [invSubmissionAttachment, setInvSubmissionAttachment] = useState({
    file: "",
    attachment_type: "",
    attachment_tag: "inv_submission_attachment",
  });
  const [errTripResolveDisputeModal, setErrTripResolveDisputeModal] =
    useState("");
  const [errInvSubDate, setErrSubDate] = useState("");
  const [errInvAttach, setErrInvAttach] = useState("");
  const [errTripDisputeModal, setErrTripDisputeModal] = useState("");
  const [disputeResolveModalLoading, setDisputeResolveModalLoading] =
    useState(false);
  const [zohoInvGenerationOption, setzohoInvGenerationOption] = useState([]);
  const [zohoInvGenerationList, setZohoInvGenerationList] = useState({});
  const [customerBilledAmount, setCustomerBilledAmount] = useState("");
  const [advancePaidByCustomer, setAdvancePaidByCustomer] = useState("");
  const [isMaxFileSizeError, setIsMaxFileSizeError] = useState(false);
  const [teamsList, setTeamsList] = useState([]);
  const [teamsSearchTerm, setTeamsSearchTerm] = useState("");
  const [rotateImageValue, setRotateImageValue] = useState(0);
  const [
    isMaxFileSizeErrorInInvoiceSubmit,
    setIsMaxFileSizeErrorInInvoiceSubmit,
  ] = useState(false);
  useEffect(() => {
    if (!openDisputeModal) {
      setErrTripDisputeModal("");
      setTripRemarks("");
    }
  }, [openDisputeModal]);
  useEffect(() => {
    if (!openResolveDisputeModal) {
      setErrTripResolveDisputeModal("");
      setChangedStatusAfterDisputeResolve("");
      setTripRemarks("");
    }
  }, [openResolveDisputeModal]);
  useEffect(() => {
    let flag = false;
    if (!transporterPaymentHistory?.amount_paid) {
      flag = true;
    }
    if (!transporterPaymentHistory?.image) {
      flag = true;
    }
    if (!transporterPaymentHistory?.remarks) {
      flag = true;
    }
    if (!transporterPaymentHistory?.utr_reference_number) {
      flag = true;
    }
    setDisableHistorySaveButton(flag);
  }, [transporterPaymentHistory]);
  const [
    negativeTripApprovalAttachmentFile,
    setNegativeTripApprovalAttachmentFile,
  ] = useState({
    attachment_tag: "0",
    attachment_type: "",
    attachment_file: null,
  });
  const [
    negativeRevenueTripApprovalEmail,
    setNegativeRevenueTripApprovalEmail,
  ] = useState("");
  const [
    isNegativeRevenueTripApprovalEmailValid,
    setIsNegativeRevenueTripApprovalEmailValid,
  ] = useState("vicky");
  const [
    negativeRevenueTripApprovalAttachment,
    setNegativeRevenueTripApprovalAttachment,
  ] = useState("");
  const [
    isNegativeRevenueTripApprovalAttachmentValid,
    setIsNegativeRevenueTripApprovalAttachmentValid,
  ] = useState(true);
  const handleOpenExportReport = (tripType) => {
    setExportTripType(tripType);
    setIsExportOpen(true);
    if (readLoginData()) {
      if (readLoginData().USER_EMAIL) {
        setExportEmail(readLoginData().USER_EMAIL);
      }
    }
  };
  const handleCustomerBilledAmountAndAdvancePaidByCustomerAtStatusChange = (
    e
  ) => {
    if (e.target.name === "customer_billed_amount") {
      setCustomerBilledAmount(e.target.value);
      setErrTripStatus("");
    }
    if (e.target.name === "advance_paid_by_customer") {
      setAdvancePaidByCustomer(e.target.value);
      setErrTripStatus("");
    }
    setErrTripStatus("");
  };
  useEffect(() => {
    let flag = false;
    if (!tripLogs.district && selectedTrip.trip_status === "intransit") {
      flag = true;
    }
    if (
      !tripLogs.expected_time_to_destination &&
      selectedTrip.trip_status === "intransit"
    ) {
      flag = true;
    }
    if (!tripLogs.remarks) {
      flag = true;
    }
    if (!tripLogs.state && selectedTrip.trip_status === "intransit") {
      flag = true;
    }
    if (!tripLogs.time_of_log && selectedTrip.trip_status === "intransit") {
      flag = true;
    }
    setDisableCreateLogSubmitButton(flag);
  }, [tripLogs]);

  useEffect(() => {
    if (
      negativeRevenueTripApprovalAttachment?.size >
      process.env.REACT_APP_MAX_FILE_SIZE
    ) {
      setIsMaxFileSizeError(true);
    } else {
      setIsMaxFileSizeError(false);
    }
  }, [negativeRevenueTripApprovalAttachment]);

  useEffect(() => {
    setNegativeTripApprovalAttachmentFile({
      attachment_tag: "negative_revenue_trip_approval_attachment",
      attachment_type: "image",
      attachment_file: negativeRevenueTripApprovalAttachment,
    });
  }, [negativeRevenueTripApprovalAttachment]);

  useEffect(() => {
    if (validEmailRegex.test(negativeRevenueTripApprovalEmail)) {
      setIsNegativeRevenueTripApprovalEmailValid(true);
    } else {
      setIsNegativeRevenueTripApprovalEmailValid(false);
    }
  }, [negativeRevenueTripApprovalEmail]);

  const observer = useRef();
  const [value, setValue] = useState(new Date());

  const lastItem = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage]
  );

  const handleCloseFilterOptions = () => {
    setIsFilterOpen(false);
  };

  const handleClosePickupOptions = () => {
    setIsPickupOpen(false);
  };

  useEffect(() => {
    getStateList();
    if (readLoginData()) {
      if (readLoginData().USER_EMAIL) {
        setExportEmail(readLoginData().USER_EMAIL);
      }
    }
  }, []);

  const getStateList = async () => {
    // var stateList = await axiosInterceptor.get(
    //   `${apiUrl.onboardingBaseUrl}/v1/enums/permitted-states`
    // );

    // setStateList(stateList.data);
    setStateList(enums?.permitted_states ? enums.permitted_states : []);
  };

  const handleTripsExportReportApi = async () => {
    console.log("is email valid????", exportEmail);
    const isEmailValid = validEmailRegex.test(exportEmail);
    if (isEmailValid && !loadingExportButton) {
      if (exportFromDate && exportToDate) {
        if (exportTripType !== "0") {
          if (returnNumberOfDays() <= 90) {
            setLoadingExportButton(true);

            const response = await getTripExport(
              // ***************************
              exportEmail ? exportEmail : readLoginData()?.USER_EMAIL,
              exportFromDate,
              exportToDate,
              exportTripStatus,
              exportCustomer,
              exportVehicleNumber,
              exportTransporter,
              exportVehiclePayoutPeriod,
              exportPaymentStatus,
              exportSourceState,
              exportDestinationState,
              exportCreatedBy,
              exportLoadSource,
              exportTripType
            );
            if (response.status === 200) {
              setOpenToast(true);
              handleCloseExport();
              setExportToastMessage(response.data.message);
              setTripExportTripTypeError("");
              setLoadingExportButton(false);
            } else if (response.status === 401) {
              setErrTotalExport("401: Not enough permissions");
              setLoadingExportButton(false);
              setTripExportTripTypeError("");
            } else if (response.status === 400) {
              setErrTotalExport(response.data.detail);
              setLoadingExportButton(false);
              setTripExportTripTypeError("");
            } else {
              setErrTotalExport(`${response.status} : Something went wrong`);
              setLoadingExportButton(false);
              setTripExportTripTypeError("");
            }
          } else {
            setTripExportTripTypeError("Please select from date and to date");
            setTripExportTripTypeError("Please select trip type");
          }
        } else {
          setTripExportTripTypeError("Please select trip type");
        }
      } else {
        setTripExportTripTypeError("Please select from date and to date");
        if (!exportFromDate && !exportToDate) {
        }
      }
    } else {
      setErrExportEmailId("Please enter valid Email Id");
      setTripExportTripTypeError("");
    }
  };

  const handleTripReportExportApi = async () => {
    const isEmailValid = validEmailRegex.test(exportEmail);
    if (isEmailValid && !loadingExportButton) {
      if (exportFromDate && exportToDate) {
        if (exportTripType !== "0") {
          if (returnNumberOfDays() <= 90) {
            setLoadingExportButton(true);

            const response = await getTripReportExport(
              exportEmail ? exportEmail : readLoginData()?.USER_EMAIL,
              exportFromDate,
              exportToDate,
              exportTripType
            );
            if (response.status === 200) {
              setOpenToast(true);
              handleCloseExport();
              setExportToastMessage(response.data.message);
              setTripExportTripTypeError("");
              setLoadingExportButton(false);
            } else if (response.status === 401) {
              setErrTotalExport("401: Not enough permissions");
              setLoadingExportButton(false);
              setTripExportTripTypeError("");
            } else if (response.status === 400) {
              setErrTotalExport(response.data.detail);
              setLoadingExportButton(false);
              setTripExportTripTypeError("");
            } else {
              setErrTotalExport(`${response.status} : Something went wrong`);
              setLoadingExportButton(false);
              setTripExportTripTypeError("");
            }
          } else {
            setTripExportTripTypeError("Please select from date and to date");
            setTripExportTripTypeError("Please select trip type");
          }
        } else {
          setTripExportTripTypeError("Please select trip type");
        }
      } else {
        setTripExportTripTypeError("Please select from date and to date");
        if (!exportFromDate && !exportToDate) {
        }
      }
    } else {
      setErrExportEmailId("Please enter valid Email Id");
      setTripExportTripTypeError("");
    }
  };

  const handleCloseExport = () => {
    setIsExportOpen(false);
    resetFormExport();
    setTripExportTripTypeError("");
  };

  useEffect(() => {
    if (readLoginData()) {
      setUserRole(readLoginData().Role);
    }
  }, []);

  const transporterOptions = (inputValue) =>
    new Promise((resolve) => {
      const response = handleTransporterVehicles(inputValue);
      resolve(response);
    });

  const handleTransporterVehicles = async (searchValue) => {
    const response = await GetTransporterByName(searchValue);
    return response.transporter_list.map((transporter) => {
      return {
        label: transporter.name,
        value: transporter.name,
      };
    });
  };

  useEffect(() => {
    if (allTripList?.length > 0) {
      if (indexSelectedData()) {
        if (indexSelectedData().hasOwnProperty("trip")) {
          setSelectedTripIndex(indexSelectedData().trip);
          if (allTripList.length > indexSelectedData().trip) {
            setSelectedTrip(allTripList[indexSelectedData().trip]);
            dispatch(updateSelectedTrip(allTripList[indexSelectedData().trip]));
          } else {
            setSelectedTrip(allTripList[0]);
            dispatch(updateSelectedTrip(allTripList[0]));
            setSelectedTripIndex(0);
          }
        } else {
          setSelectedTrip(allTripList[0]);
          dispatch(updateSelectedTrip(allTripList[0]));
          setSelectedTripIndex(0);
        }
      } else {
        setSelectedTrip(allTripList[0]);
        dispatch(updateSelectedTrip(allTripList[0]));
        setSelectedTripIndex(0);
      }
    }
  }, [allTripList]);

  const [open, setOpen] = React.useState(false);
  const [openIntransitCompleteModal, setOpenIntransitCompleteModal] =
    useState(false);
  const [openArrivedAtDestinationModal, setOpenArrivedAtDestinationModal] =
    useState(false);
  const [openReopenModal, setOpenReopenModal] = useState(false);

  const [openVerifyDocumentModal, setOpenVerifyDocumentModal] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setIsRejectButtonClicked(false);
    setShowNegativeTripFields(false);
    resetForm();
    setOpen(false);
    setLoading(false);
    setCustomerBilledAmount("");
    setAdvancePaidByCustomer("");
    setNegativeRevenueTripApprovalEmail("");
    setNegativeRevenueTripApprovalAttachment("");
  };

  const handleOpenIntransitComplete = () => setOpenIntransitCompleteModal(true);
  const handleOpenReopenModal = () => setOpenReopenModal(true);
  const handleCloseIntransitComplete = () => {
    setIsRejectButtonClicked(false);
    setShowNegativeTripFields(false);
    resetForm();
    setOpenIntransitCompleteModal(false);
    setLoading(false);
    setCustomerBilledAmount("");
    setAdvancePaidByCustomer("");
    setNegativeRevenueTripApprovalEmail("");
    setNegativeRevenueTripApprovalAttachment("");
  };
  const handleCloseArrivedAtDestination = () => {
    setIsRejectButtonClicked(false);
    setShowNegativeTripFields(false);
    resetForm();
    setOpenArrivedAtDestinationModal(false);
    setLoading(false);
    setCustomerBilledAmount("");
    setAdvancePaidByCustomer("");
    setNegativeRevenueTripApprovalEmail("");
    setNegativeRevenueTripApprovalAttachment("");
  };

  const handleCloseVerifyDocumentModal = () => {
    resetForm();
    setOpenVerifyDocumentModal(false);
    setLoading(false);
  };

  const handleCloseReopenModal = () => {
    resetForm();
    setOpenReopenModal(false);
    setLoading(false);
  };

  const handleAckNum = (e) => {
    setAckNumber(e.target.value);
  };

  const handleCourierCompany = (e) => {
    setCourierCompany(e.target.value);
  };

  const handleInvoiceDate = (e) => {
    setDate(e.target.value);
  };
  var today = new Date().toISOString().split("T")[0];

  const isFormValid = () => {
    let isValid = true;
    const errInvoiceAckNo = {};
    const errInvoiceCourier = {};
    const errInvoiceDate = {};

    if (ackNumber == "") {
      errInvoiceAckNo.invAckNo = "Please Enter Acknowledgement number";
      isValid = false;
    }

    if (courierCompany == "") {
      errInvoiceCourier.invCourier = "Please Enter Courier Company";
      isValid = false;
    }

    if (date == "") {
      errInvoiceDate.Date = "Please Enter Date";
    }
    setErrInvoiceAckNo(errInvoiceAckNo);
    setErrInvoiceCourier(errInvoiceCourier);
    setErrInvoiceDate(errInvoiceDate);
    return isValid;
  };

  const checkInvoiceFormValidation = async (
    ackNum,
    courierCompany,
    date,
    id
  ) => {
    setInvoiceSubmitLoading(true);
    var isValid = isFormValid();
    try {
      if (isValid) {
        setInvoiceSubmitLoading(true);
        const response = await InvoiceSent(ackNum, courierCompany, date, id);
        if (response.status === 200) {
          setopenInvoiceModal(false);
          setAckNumber("");
          setDate("");
          setCourierCompany("");
          // handleStatusClick(7, "hardcopy_pod_received");
          handleFilterSearch(1);
        }
      }
    } catch (err) {
    } finally {
      setInvoiceSubmitLoading(false);
    }
  };

  const handleInvoiceSent = async (ackNum, courierCompany, date, id) => {
    setInvoiceSubmitLoading(true);
    const response = await InvoiceSent(ackNum, courierCompany, date, id);
    if (response.status == 200) {
      setInvoiceSubmitLoading(false);
      handleCloseInvoiceSent();
      handleStatusClick(7, "hardcopy_pod_received");
    } else if (response.status == 422) {
      setInvoiceSubmitLoading(false);
    } else if (response.status === 404) {
      setInvoiceSubmitLoading(false);
    } else if (response.status === 500) {
      setInvoiceSubmitLoading(false);
    }
    setInvoiceSubmitLoading(false);
  };

  const isInvoiceSubmitValid = () => {
    let isValidInvoice = true;
    const errInvAttach = {};

    if (invoiceSubmissionDate == "") {
      errInvSubDate.InvDate = "Please Enter Date";
      isValidInvoice = false;
    }

    if (invSubmissionAttachment.file == "") {
      errInvAttach.invAtt = "Please Attach Invoice";
      isValidInvoice = false;
    }
    setErrInvAttach(errInvAttach);
    return isValidInvoice;
  };

  const handleInvoiceSubmission = async (invoiceSubmissionDate, attachment) => {
    var isValidInvoice = isInvoiceSubmitValid();
    if (isValidInvoice) {
      setInvoiceSubmittedLoading(true);
      const response = await InvoiceSubmitted(
        invoiceSubmissionDate,
        attachment,
        selectedTrip.id
      );
      if (response.status == 200) {
        setInvoiceSubmittedLoading(false);
        handleCloseInvoiceSubmission();
        // handleStatusClick(7, "hardcopy_pod_received");
        handleFilterSearch(1);
      } else if (response.status == 422) {
        setInvoiceSubmittedLoading(false);
      } else if (response.status === 404) {
        setInvoiceSubmittedLoading(false);
      } else if (response.status === 500) {
        setInvoiceSubmittedLoading(false);
      } else if (response.status === 401) {
        setInvoiceSubmittedLoading(false);
      } else setInvoiceSubmittedLoading(false);
    }
  };

  const handleInvoiceRejection = async (rejectionData, remarks) => {
    if (rejectionData && remarks) {
      setInvoiceSubmittedLoading(true);
      const response = await InvoiceRejected(
        rejectionData,
        remarks,
        selectedTrip.id,
        selectedTrip?.zoho_customer_invoice_number
      );
      if (response.status == 201) {
        setInvoiceSubmittedLoading(false);
        setopenInvoiceRejectModal(false);
        setInvoiceRejectionRemarks("");
        setInvoiceRejectionDate("");
        // handleStatusClick(11, "hardcopy_pod_received");
        handleFilterSearch(1);
      } else if (response.status == 422) {
        setInvoiceSubmittedLoading(false);
      } else if (response.status === 404) {
        setInvoiceSubmittedLoading(false);
      } else if (response.status === 500) {
        setInvoiceSubmittedLoading(false);
      } else if (response.status === 401) {
        setInvoiceSubmittedLoading(false);
      } else setInvoiceSubmittedLoading(false);
    }
  };
  const handleInvoiceSubDate = (e) => {
    setInvoiceSubmissionDate(e.target.value);
  };
  const handleInvAttachment = async (e) => {
    const blobFile = await saveBlob(e.target.files[0]);
    if (blobFile.size > process.env.REACT_APP_MAX_FILE_SIZE) {
      setIsMaxFileSizeErrorInInvoiceSubmit(true);
    } else {
      setIsMaxFileSizeErrorInInvoiceSubmit(false);
    }
    setInvSubmissionAttachment({
      ...invSubmissionAttachment,
      file: blobFile,
      attachment_type: blobFile.type === "application/pdf" ? "pdf" : "image",
    });
  };

  const handleOpenFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleOpenPickup = () => {
    setIsPickupOpen(true);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };
  const handleClosePaymentToast = () => {
    setOpenPaymentApproveRejectToast(false);
  };

  const resetFormExport = () => {
    setErrTotalExport("");
    setExportFromDate("");
    setExportToDate("");
    setLoadingExportButton(false);
    setExportTripStatus("0");
    setExportCustomer("");
    setExportCustomerSearchValue("");
    setExportVehicleNumber("");
    setExportTransporter("");
    setExportVehiclePayoutPeriod("0");
    setExportPaymentStatus("0");
    setExportSourceState("0");
    setExportDestinationState("0");
    setExportCreatedBy("");
    setExportLoadSource("0");
    setExportTripType("0");
    setExportEmail("");
    setErrExportEmailId("");
  };

  const resetForm = () => {
    setTripRemarks("");
    setLoadingModal(false);
    setAttachmentTag("");
    setAttachmentType("0");
    setErrTripRemarks("");
    setErrTripStatus("");
    setErrAttachmentTag("");
    setErrAttachmentType("");
    setErrAttachmentFile("");
    setAttachmentCount([1]);
    setAttachmentListArray([
      {
        attachment_tag: "0",
        attachment_type: "",
        attachment_file: null,
      },
    ]);
  };

  const handleAddAttachment = () => {
    let attachmentTemp = [...attachmentListArray];
    attachmentTemp.push({
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    });
    setAttachmentListArray(attachmentTemp);
  };

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const returnNumberOfDays = () => {
    const date1 = new Date(exportFromDate);
    const date2 = new Date(exportToDate);
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = date2.getTime() - date1.getTime();
    const diffInDays = Math.round(diffInTime / oneDay);
    return diffInDays;
  };

  useEffect(() => {
    returnNumberOfDays();
  }, [exportFromDate, exportToDate]);

  const handleGetTripList = async (page) => {
    handleCloseFullPageLoad();
    setLoadingMore(true);
    if (page == 1) {
      setLoading(true);
    }

    const response = await GetTripList(page);
    if (response == 403) {
      localStorage.clear();
      setSessionExpires("session has expired,please login again.");
      history.push("/");
    }
    if (response == 401) {
      setIsUserNotAuthorized(true);
    }
    if (response.trip_list?.length > 0) {
      if (page > 1) {
        setAllTripList([...allTripList, ...response.trip_list]);
      } else {
        setAllTripList(response.trip_list);
      }
    }
    setIsLastPage(response.is_last_page);
    setLoadingMore(false);
    setLoading(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "auto",
    height:
      currentStatusUpdateInProgress === "approved" ||
      currentStatusUpdateInProgress === "intransit"
        ? "80vh"
        : "auto",
    border: `1px solid ${colorPalette.black}`,
    borderRadius: 1,
    boxShadow: 24,
    p: 2,
    backgroundColor: "#fff",
  };

  const Invstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "50%",
    bgcolor: "background.paper",
    border: `1px solid ${colorPalette.black}`,
    borderRadius: 1,
    boxShadow: 24,
    p: 2,
  };

  const handleApproveTrip = (trip, e, status = "approved") => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress(status);
    handleOpen();

    e.stopPropagation();
  };
  const handleApproveCompleteTrip = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentButton(e.target.innerText);
    setCurrentStatusUpdateInProgress("requested_for_completion");
    handleOpen();

    e.stopPropagation();
  };
  const handleRejectCompleteTrip = (trip, e) => {
    //
    setSelectedTripId(trip.id);
    setCurrentButton(e.target.innerText);
    setCurrentStatusUpdateInProgress("requested_for_completion");
    handleOpen();

    e.stopPropagation();
  };

  const handleSearchCustomer = async (searchValue) => {
    const response = await GetCustomerSearchList(searchValue);
    if (response) {
      setCustomerList(response?.customers_list);
    }
  };

  useEffect(() => {
    // if (customerSearchValue) {
    handleSearchCustomer(customerSearchValue);
    // }
  }, [customerSearchValue]);

  useEffect(() => {
    if (exportCustomerSearchValue) {
      handleSearchCustomer(exportCustomerSearchValue);
    }
  }, [exportCustomerSearchValue]);

  const handleCancelTrip = (trip, e) => {
    setOpenCancelModal(true);
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("cancelled");
    e.stopPropagation();
  };

  const handleTripCancel = (trip, e) => {
    console.log("sd");
    setApprovedOpenCancelModal(true);
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("cancelled");
    e.stopPropagation();
  };

  // useEffect(() => {
  //   async function fetchData() {
  //     const documentTypeData = await GetDocumentEnums();
  //     setDocumentTypeList(documentTypeData);
  //   }
  //   fetchData();
  // }, []);

  const onFileChange = (event) => {
    setTripAttachmentFile(event.target.files[0]);
  };

  const handleStartTrip = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("intransit");

    handleOpenIntransitComplete();
    e.stopPropagation();
  };

  const handleGetPaymentStatus = async () => {
    const response = await GetTripPaymentStatusEnums();
    setTripPaymentStatusList(response);
  };

  useEffect(() => {
    handleGetPaymentStatus();
  }, []);

  const handleGetTripStatus = async () => {
    const response = await GetTripStatusEnums();
    setTripStatusList(response);
  };

  const handleReopenTrip = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("reopen");
    handleOpenReopenModal();
    e.stopPropagation();
  };

  const handleArrivedAtDestination = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("arrived_at_destination");
    setOpenArrivedAtDestinationModal(true);
    e.stopPropagation();
  };

  const handleGoodsDelivered = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("goods_delivered");
    handleOpen();
    history.push(`/ftl-trips/${trip.id}/goods-delivered`);
  };

  const handlePodRecieved = (trip) => {
    setSelectedTripId(trip.id);
    localStorage.setItem("IntransiteStatus", selectedTripStatusText);
    // }
    setCurrentStatusUpdateInProgress("pod_recieved");
    handleOpen();
    history.push(`/ftl-trips/${trip.id}/pod-recieved`);
  };

  const handleDocumentVarified = (trip) => {
    setSelectedTripId(trip.id);
    localStorage.setItem("IntransiteStatus", selectedTripStatusText);
    // }
    setCurrentStatusUpdateInProgress("documents_verified");
    setOpenVerifyDocumentModal(true);
  };

  const handleDeliveryInvoicePayment = (trip) => {
    dispatch(
      updateSelectedTripInvoices(
        trip.reference_number ? trip.reference_number : []
      )
    );
    dispatch(
      updatePendingTripInvoiceList(
        trip.pending_reference_numbers ? trip.pending_reference_numbers : []
      )
    );
    setSelectedTripId(trip.id);
    localStorage.setItem("IntransiteStatus", selectedTripStatusText);
    history.push(`/faarms-trip/${trip.id}/delivery-invoice-payment`);
  };

  const handlePayTrip = (trip) => {
    setSelectedTripId(trip.id);
    history.push(`/faarms-trip/${trip.id}/record-payment`);
  };

  const [
    changedStatusAfterDisputeResolve,
    setChangedStatusAfterDisputeResolve,
  ] = useState("");

  const handleRejectTrip = (trip, e) => {
    setIsRejectButtonClicked(true);
    setCurrentStatusUpdateInProgress("rejected");
    setSelectedTripId(trip.id);
    handleOpen();
    e.stopPropagation();
  };
  const [openFullPageLoading, setOpenFullPageLoading] = React.useState(false);
  const handleCloseFullPageLoad = () => {
    setOpenFullPageLoading(false);
  };
  const handleOpenFullPageLoad = () => {
    setOpenFullPageLoading(true);
  };

  const handleFilterSearch = async (page) => {
    setLoadingFilterSearchButton(true);
    handleOpenFullPageLoad();
    const tripStatus = filterTripStatus
      ? filterTripStatus
      : selectedTripStatusText;
    const responseFilter = await GetTripListWithFilter(
      page,
      filterTripCode,
      filterPaymentStatus,
      filterVehicleNumber,
      tripStatus,
      filterReferenceNumber,
      filterCustomerId,
      filterLoadSource,
      filterEwayBillNo,
      filterTripType ? filterTripType : tripType
    );
    if (responseFilter) {
      var index = 0;
      if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterReferenceNumber ||
        filterCustomerId ||
        filterLoadSource ||
        filterEwayBillNo
      ) {
        //
        // index = 13;
        // setselectedTripStatus(13);
      } else if (tripStatus == "created") {
        index = 0;
        setselectedTripStatus(0);
      } else if (tripStatus == "approved") {
        index = 1;
        setselectedTripStatus(1);
      } else if (tripStatus == "intransit") {
        index = 2;
        setselectedTripStatus(2);
      } else if (tripStatus == "arrived_at_destination") {
        index = 3;
        setselectedTripStatus(3);
      } else if (tripStatus == "goods_delivered") {
        index = 4;
        setselectedTripStatus(4);
      } else if (tripStatus == "documents_verified") {
        index = 5;
        setselectedTripStatus(5);
      } else if (tripStatus == "trip_disputed") {
        index = 5;
        setselectedTripStatus(6);
      } else if (tripStatus == "hardcopy_pod_received") {
        index = 7;
        setselectedTripStatus(7);
      } else if (tripStatus == "invoice_generated") {
        index = 8;
        setselectedTripStatus(8);
      } else if (tripStatus == "invoice_sent") {
        index = 9;
        setselectedTripStatus(9);
      } else if (tripStatus == "invoice_submitted") {
        index = 10;
        setselectedTripStatus(10);
      } else if (tripStatus == "invoice_closed") {
        index = 11;
        setselectedTripStatus(11);
      } else if (tripStatus == "invoice_rejected") {
        index = 12;
        setselectedTripStatus(12);
      } else if (tripStatus == "rejected") {
        index = 13;
        setselectedTripStatus(13);
      } else if (tripStatus == "cancelled") {
        index = 14;
        setselectedTripStatus(14);
      }

      setSelectedTripStatusText(tripStatus);
      localStorage.setItem("FlagForApiCall", true);
      if (page > 1) {
        setAllTripList([...allTripList, ...responseFilter.trip_list]);

        setIsLastPage(responseFilter.is_last_page);
        setLoadingFilterSearchButton(false);
        handleCloseFilterOptions();
      } else {
        setAllTripList(responseFilter.trip_list);
        setLoadingFilterSearchButton(false);
        setIsLastPage(responseFilter.is_last_page);
        handleCloseFilterOptions();
      }
    }
    if (responseFilter === 401) {
      setIsUserNotAuthorized(true);
    }
    handleCloseFullPageLoad();
  };
  useEffect(() => {
    handleFilterSearch(currentPage);
  }, [selectedTripStatusText, tripType, currentPage]);

  const handleCloseFilterModal = () => {
    setIsFilterOpen(false);
  };

  const handlePickupTrip = (e, trip, index) => {
    setSelectedTripId(trip.id);
    setSelectedTrip(trip);
    dispatch(updateSelectedTrip(trip));
    setSelectedTripIndex(index);
    handleOpenPickup();
    e.stopPropagation();
  };

  const handleTripDispute = (selectedTrip) => {
    setSelectedTripId(selectedTrip?.id);
    setOpenDisputeModal(true);
  };
  const handleResolveTripDispute = (selectedTrip) => {
    setSelectedTripId(selectedTrip?.id);
    setOpenResolveDisputeModal(true);
  };

  const handleSubmitApproveReject = async () => {
    setLoadingModal(true);
    const response = await approveRejectTrip(
      currentStatusUpdateInProgress,
      tripRemarks,
      selectedTripId,
      advancePaidByCustomer,
      customerBilledAmount,
      negativeTripApprovalAttachmentFile,
      negativeRevenueTripApprovalEmail,
      negativeTrip
    );
    if (response.status == 400) {
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 401) {
      toast.error("401 Not enough permissions");
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      }
      if (tripSearchValue && selectedTripStatusText) {
        dispatch(updateTripSearchValue(""));
        handleFilterSearch(currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType ||
        selectedTripStatusText
      ) {
        handleFilterSearch(currentPage);
      } else {
        if (selectedTripStatusText) {
          handleFilterSearch(currentPage);
        }
      }
      handleClose();
    } else if (response.status === 422) {
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    }
  };
  const handleSubmitApproveCompleteRequest = async () => {
    //
    setLoadingModal(true);
    const response = await approveCompleteTripRequest(
      "completed",
      tripRemarks,
      selectedTripId
    );
    if (response.status == 400) {
      //
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 200) {
      //

      setLoadingModal(false);
      setCurrentPage(1);
      // handleGetTripList(1);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      }
      if (tripSearchValue && selectedTripStatusText) {
        dispatch(updateTripSearchValue(""));
        handleFilterSearch(currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType ||
        selectedTripStatusText
      ) {
        handleFilterSearch(currentPage);
      } else {
        if (selectedTripStatusText) {
          handleFilterSearch(currentPage);
        }

        // else {
        //   handleGetTripList(currentPage);
        // }
      }
      handleClose();
    }
  };
  const handleSubmitRejectCompleteRequest = async () => {
    setLoadingModal(true);
    const response = await rejectCompleteTripRequest(
      "rejected",
      tripRemarks,
      selectedTripId,
      checked
    );
    if (response.status == 400) {
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      // handleGetTripList(1);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      }
      if (tripSearchValue && selectedTripStatusText) {
        dispatch(updateTripSearchValue(""));
        handleFilterSearch(currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType ||
        selectedTripStatusText
      ) {
        handleFilterSearch(currentPage);
      } else {
        if (selectedTripStatusText) {
          handleFilterSearch(currentPage);
        }

        // else {
        //   handleGetTripList(currentPage);
        // }
      }
      handleClose();
    }
  };

  const handleSubmitReopenCancel = async () => {
    setLoadingModal(true);
    const response = await reopenCancelTrip(
      currentStatusUpdateInProgress,
      tripRemarks,
      selectedTripId
    );
    if (response.status == 400) {
      //
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 401) {
      toast.error("401: Not enough permissions");
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      setOpenReopenModal(false);
      setOpenCancelModal(false);
      // handleGetTripList(1);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      }
      if (tripSearchValue && selectedTripStatusText) {
        dispatch(updateTripSearchValue(""));
        handleFilterSearch(currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType ||
        selectedTripStatusText
      ) {
        handleFilterSearch(currentPage);
      }
      // else {
      //   handleGetTripList(currentPage);
      // }
      handleClose();
    }
    // setLoadingModal(false);
    // setCurrentPage(1);
    // handleGetTripList(1);

    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth", // for smoothly scrolling
    // });
    // handleClose();
  };

  const handleApproveTripCancel = async () => {
    setLoadingModal(true);
    const response = await approvedTripCancel(
      currentStatusUpdateInProgress,
      tripRemarks,
      selectedTripId
    );
    if (response.status == 400) {
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 422) {
      setErrTripStatus(response.data.detail[0].msg);
      setLoadingModal(false);
    } else if (response.status == 401) {
      setLoadingModal(false);
      toast.error("401: Not enough permissions");
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      setApprovedOpenCancelModal(false);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      }
      if (tripSearchValue && selectedTripStatusText) {
        dispatch(updateTripSearchValue(""));
        handleFilterSearch(currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType ||
        selectedTripStatusText
      ) {
        handleFilterSearch(currentPage);
      }
      handleClose();
    }
  };

  const handleVerifyDocument = async () => {
    setLoadingModal(true);
    const response = await verifyDocument(
      currentStatusUpdateInProgress,
      tripRemarks,
      selectedTripId
    );
    if (response.status == 400) {
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 401) {
      setErrTripStatus(response.data.error);
      setLoadingModal(false);
    } else if (response.status == 422) {
      setErrTripStatus(response.data.detail[0].msg);
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      setOpenVerifyDocumentModal(false);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      }
      if (tripSearchValue && selectedTripStatusText) {
        dispatch(updateTripSearchValue(""));
        handleFilterSearch(currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType ||
        selectedTripStatusText
      ) {
        handleFilterSearch(currentPage);
      }

      handleClose();
    }
  };

  const handleClearFilter = () => {
    handleGetTripList(1);
    setCurrentPage(1);
    setFilterPaymentStatus("");
    setFilterTripCode("");
    setFilterTripStatus("");
    setFilterVehicleNumber("");
    setFilterTripType("");
    setFilterReferenceNumber("");
    handleCloseFilterOptions();
    setFilterCustomerId("");
    setFilterCustomerData({});
    setFilterEwayBillNo("");
    setFilterLoadSource("");
    handleStatusClick(10, "");
  };

  const handleSubmitStart = async () => {
    setLoadingModal(true);
    // const attachmentResponse = TripAttachmentPost(
    //   tripAttachmentFile,
    //   selectedTripId,
    //   attachmentTag,
    //   attachmentType,
    //   "start_trip",
    //   attachmentListArray
    // );
    // //
    // const resolved = await Promise.all(attachmentResponse);
    // //
    // //
    const response = await startTrip(
      currentStatusUpdateInProgress,
      tripRemarks,
      selectedTripId
    );

    console.log(response, "resdfasdf");
    if (response.status == 400) {
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 422) {
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 401) {
      toast.error("401 Not enough permissions");
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      // handleGetTripList(1);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      }
      if (tripSearchValue && selectedTripStatusText) {
        dispatch(updateTripSearchValue(""));
        handleFilterSearch(currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType ||
        selectedTripStatusText
      ) {
        handleFilterSearch(currentPage);
      }
      handleCloseIntransitComplete();
    } else if (response.status === 422) {
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    }
  };

  const handleAutomaticInvoiceGeneration = async () => {
    setInvoiceAutomaticGenLoading(true);
    const response = await InvoiceGeneration(selectedTrip.id);
    //
    if (response.status == 201) {
      handleFilterSearch(currentPage);
      setInvoiceAutomaticGenLoading(false);
      setLoadingModal(false);
    } else if (response.status == 422) {
      setInvoiceAutomaticGenLoading(false);
      setLoadingModal(false);
    } else if (response.status == 400) {
      setInvoiceAutomaticGenLoading(false);
    } else setInvoiceAutomaticGenLoading(false);
  };

  const isInvoiceGenValid = () => {
    let isGenInvValid = true;
    const errZohoCustomerInvoiceNumber = {};
    if (Object.keys(zohoInvGenerationList).length === 0) {
      errZohoCustomerInvoiceNumber.invNo =
        "Please select a valid invoice number";
      isGenInvValid = false;
    }
    setErrZohoCustomerInvoiceNumber(errZohoCustomerInvoiceNumber);
    return isGenInvValid;
  };

  const checkInvGenValidation = () => {
    var isInvGenValid = isInvoiceGenValid();
    //
    if (isInvGenValid) {
      setLoadingGenerateInvoice(true);
      handleInvoiceGeneration();
    }
  };

  const handleInvoiceGeneration = async () => {
    setLoadingGenerateInvoice(true);
    const invoiceNum = zohoInvGenerationList.invoice_number;
    const invoiceId = zohoInvGenerationList.invoice_id;
    const response = await ManualInvoiceGeneration(
      invoiceNum,
      invoiceId,
      selectedTrip.id
    );
    if (response.status == 201) {
      handleFilterSearch(currentPage);
      setLoadingGenerateInvoice(false);
      setOpenInvoiceGenerationModal(false);
    } else if (response.status === 401) {
      toast.error("401: Not enough permissions");
      setLoadingGenerateInvoice(false);
    } else if (response.status == 400) {
      setLoadingGenerateInvoice(false);
    } else setLoadingGenerateInvoice(false);
  };

  const handleOpenInvoiceSent = (id) => {
    // setopenInvoiceSubmitModal(true);
    setopenInvoiceModal(true);
    setInvoiceId(id);
  };

  const handleCloseInvoiceSent = () => {
    setopenInvoiceSubmitModal(false);
    setopenInvoiceModal(false);
    setAckNumber("");
    setCourierCompany("");
    setDate("");
    setErrInvoiceAckNo("");
    setErrInvoiceCourier("");
    setErrInvoiceDate("");
    setZohoInvoiceNumberSearch("");
  };
  const handleClearApproveModalFields = () => {
    setTripRemarks("");
    setCustomerBilledAmount("");
    setAdvancePaidByCustomer("");
    setIsRejectButtonClicked(false);
    setNegativeRevenueTripApprovalAttachment("");
    setNegativeRevenueTripApprovalEmail("");
    setIsNegativeRevenueTripApprovalEmailValid("vicky");
    setIsNegativeRevenueTripApprovalAttachmentValid(true);
    setShowNegativeTripFields(false);
    setErrTripStatus("");
    setNegativeTrip(false);
  };

  const handleOpenInvoiceSubmission = () => {
    setopenInvoiceSubmitModal(true);
  };

  const handleOpenInvoiceRejectionModal = (tripDetail) => {
    setopenInvoiceRejectModal(true);
    console.log(tripDetail, "tripDetail");
  };

  useEffect(() => {
    let flag = false;
    if (!invoiceSubmissionDate) {
      flag = true;
    }
    if (!invSubmissionAttachment?.file?.name) {
      flag = true;
    }
    setDisableSubmitInvoiceModalSubmitButton(flag);
  }, [invoiceSubmissionDate, invSubmissionAttachment]);

  const handleCloseInvoiceSubmission = () => {
    setopenInvoiceSubmitModal(false);
    setopenInvoiceRejectModal(false);
    setInvoiceRejectionRemarks("");
    setInvoiceRejectionDate("");
    // setErrSubDate("");
    setErrInvAttach("");
    setInvoiceSubmissionDate("");
    setInvSubmissionAttachment({
      file: "",
      attachment_type: "",
      attachment_tag: "inv_submission_attachment",
    });
    setIsMaxFileSizeErrorInInvoiceSubmit(false);
    // setInvoiceSubmissionDate("");
  };

  const handleOpenInvoiceGeneration = () => {
    setOpenInvoiceGenerationModal(true);
    setErrZohoCustomerInvoiceNumber("");
    setZohoInvoiceNumberSearch("");
    setZohoInvGenerationList({});
    handleInvoiceClose();
  };

  const handleCloseInvoiceGeneration = () => {
    setOpenInvoiceGenerationModal(false);
    setErrZohoCustomerInvoiceNumber("");
    setZohoInvoiceNumberSearch("");
    setZohoInvGenerationList({});
    setUnauthorizeError("");
  };

  const handleGetZohoInvoiceNumberSearch = async (InvNum) => {
    const response = await GetZohoInvoiceCustomerList(InvNum);

    if (response.status === 401) {
      setUnauthorizeError("401: Not enough permission");
    }
    if (response) {
      setZohoInvGenerationList(response.data.invoices[0]);
      setzohoInvGenerationOption(response.data.invoices);
    }
  };

  useEffect(() => {
    if (zohoInvoiceNumberSearch) {
      handleGetZohoInvoiceNumberSearch(zohoInvoiceNumberSearch);
    }
  }, [zohoInvoiceNumberSearch]);

  const handleZohoInvoiceData = async (invoice) => {
    setZohoInvGenerationList(invoice);
    //
  };

  const arrivedAtDestinationHandle = async () => {
    setLoadingModal(true);
    const response = await arrivedAtDestination(
      currentStatusUpdateInProgress,
      tripRemarks,
      selectedTripId,
      arrivedAtDate
    );
    if (response.status == 400) {
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 401) {
      toast.error("401 Not enough permissions");
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      handleCloseArrivedAtDestination();
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      }
      if (tripSearchValue && selectedTripStatusText) {
        dispatch(updateTripSearchValue(""));
        handleFilterSearch(currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType ||
        selectedTripStatusText
      ) {
        handleFilterSearch(currentPage);
      }
      handleClose();
    }
  };

  const handleClick = (e) => {
    e.click();
  };

  const handleOpenTripRoute = (e, trip, index) => {
    setSelectedTripId(trip.id);
    setSelectedTrip(trip);
    dispatch(updateSelectedTrip(trip));
    setSelectedTripIndex(index);
    setOpenTripRoutes(true);
    e.stopPropagation();
  };

  const handleCloseTripRoute = () => {
    setOpenTripRoutes(false);
  };

  const handleSubmitStop = async () => {
    setLoadingModal(true);
    const attachmentResponse = await TripAttachmentPost(
      tripAttachmentFile,
      selectedTripId,
      attachmentTag,
      attachmentType,
      "end_trip"
    );
    if (attachmentResponse.status == 400) {
      //
      setErrTripStatus(attachmentResponse.data.detail);
      setLoadingModal(false);
    } else if (attachmentResponse == "success") {
      //
      const response = await stopTrip(
        currentStatusUpdateInProgress,
        tripRemarks,
        selectedTripId,
        tripDeliveredBills
      );
      if (response.status == 400) {
        //
        setErrTripStatus(response.data.detail);
        setLoadingModal(false);
      } else if (response.status == 200) {
        setLoadingModal(false);
        setCurrentPage(1);
        // handleGetTripList(1);
        if (tripSearchValue) {
          handleSearchTrip(tripSearchValue, currentPage);
        } else if (
          filterTripCode ||
          filterPaymentStatus ||
          filterVehicleNumber ||
          filterTripStatus ||
          filterReferenceNumber ||
          filterLoadSource ||
          filterCustomerId ||
          filterEwayBillNo ||
          filterTripType
        ) {
          handleFilterSearch(currentPage);
        } else {
          handleGetTripList(currentPage);
        }
        handleClose();
      }
    }

    // setLoadingModal(false);
    // setCurrentPage(1);
    // handleGetTripList(1);

    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth", // for smoothly scrolling
    // });
    // handleClose();
  };
  const beforeHandleSubmitRemarks = () => {
    if (negativeTrip && showNegativeTripFields) {
      handleSubmitRemarks();
    }
    if (
      negativeTrip &&
      (currentStatusUpdateInProgress == "approved" ||
        currentStatusUpdateInProgress == "intransit")
    ) {
      setShowNegativeTripFields(true);
    }
    if (!negativeTrip) {
      handleSubmitRemarks();
    }
  };

  const handleSubmitRemarks = async () => {
    if (
      (currentStatusUpdateInProgress == "approved" ||
        currentStatusUpdateInProgress == "rejected") &&
      !loadingModal
    ) {
      handleSubmitApproveReject();
    } else if (
      currentStatusUpdateInProgress == "requested_for_completion" &&
      !loadingModal
    ) {
      if (currentButton == "Reject") {
        handleSubmitRejectCompleteRequest();
      } else if (currentButton == "Approve") {
        handleSubmitApproveCompleteRequest();
      }
    } else if (
      currentStatusUpdateInProgress == "cancelled" &&
      approvedOpenCancelModal &&
      !loadingModal
    ) {
      handleApproveTripCancel();
    } else if (
      (currentStatusUpdateInProgress == "reopen" ||
        (currentStatusUpdateInProgress == "cancelled" &&
          !approvedOpenCancelModal)) &&
      !loadingModal
    ) {
      handleSubmitReopenCancel();
    } else if (
      currentStatusUpdateInProgress == "documents_verified" &&
      !loadingModal
    ) {
      handleVerifyDocument();
    } else if (
      currentStatusUpdateInProgress == "arrived_at_destination" &&
      !loadingModal
    ) {
      if (tripRemarks.trim()) {
        arrivedAtDestinationHandle();
      } else {
        setErrTripRemarks("Please enter the remarks");
        setErrAttachmentTag("Please enter the document type");
        setErrAttachmentType("Please select the attachment type");
        setErrAttachmentFile("Please select the attachment file");
      }
    } else if (currentStatusUpdateInProgress == "intransit" && !loadingModal) {
      if (isApprovedIntransit) {
        handleSubmitApproveReject();
      } else {
        if (tripRemarks.trim()) {
          //   if (
          //     attachmentListArray
          //       .map((item) => {
          //         if (item.attachment_file) {
          //           if (
          //             item.attachment_file.size >
          //             process.env.REACT_APP_MAX_FILE_SIZE
          //           ) {
          //             return "greater";
          //           }
          //         }
          //       })
          //       .indexOf("greater") > -1
          //   ) {
          //     return;
          //   }
          handleSubmitStart();
        } else {
          setErrTripRemarks("Please enter the remarks");
          setErrAttachmentTag("Please enter the document type");
          setErrAttachmentType("Please select the attachment type");
          setErrAttachmentFile("Please select the attachment file");
        }
      }
    } else if (currentStatusUpdateInProgress == "completed" && !loadingModal) {
      if (
        tripRemarks.trim() &&
        attachmentTag &&
        attachmentType?.length > 1 &&
        tripAttachmentFile
      ) {
        handleSubmitStop();
      } else {
        setErrTripRemarks("Please enter the remarks");
        setErrAttachmentTag("Please enter the document type");
        setErrAttachmentType("Please select the attachment type");
        setErrAttachmentFile("Please select the attachment file");
      }
    }
  };

  const handleSubmitRemarksToChangeTripStatusToDisputed = async (
    trip_status
  ) => {
    handleDisputeModalButtonLoadingOn();

    let response = await changeStatusToDisputed(
      trip_status,
      tripRemarks,
      selectedTripId,
      changedStatusAfterDisputeResolve
    );
    if (response?.status == 401) {
      toast.error("401: Not enough permissions");
    } else if (response?.status === 200) {
      handleCloseDistpute();
      setTripRemarks("");
      handleFilterSearch(currentPage);
      toast.success("Trip status changed to Disputed.");
    } else {
      setErrTripDisputeModal(response?.data?.detail);
    }
    handleDisputeModalButtonLoadingOff();
  };

  const handleResolveDisputeAndChangeStatus = async () => {
    setDisputeResolveModalLoading(true);
    let response = await resolveDisputeAndChangeStatus(
      tripRemarks,
      selectedTripId
    );
    if (response?.status == 401) {
      toast.error("401: Not enough permissions");
    } else if (response?.status === 200) {
      setOpenResolveDisputeModal(false);
      setTripRemarks("");
      setChangedStatusAfterDisputeResolve("");
      setErrTripDisputeModal("");
      handleFilterSearch(currentPage);
    } else {
      setErrTripResolveDisputeModal(response?.data?.detail);
    }
    setDisputeResolveModalLoading(false);
  };

  const handleCloseDetailsDrawer = () => {
    toggleDrawer("right", false);
    setImageUrlPdf("");
    setSecondaryDriverError("");
    setImageUrl("");
    setLoadingPaymentApprove(false);
    setLoadingPaymentRejectedApprove(false);
    setPaymentstatusError("");
    setPaymentRemarks("");
    setTripPaymentHistory([]);
    setEwayBillError("");
    setEwayBillAttachmentError("");
    setUpdatedEwayBillDetails({
      remarks: "",
      eway_bill_number: "",
      eway_bill_valid_from: "",
      eway_bill_valid_till: "",
      trip_status: "",
      eway_bill_attachment: "",
      eway_bill_attachment_tag: "",
      eway_bill_attachment_type: "",
    });
    setSecondaryDriverDetailsInput({
      first_name: "",
      last_name: "",
      mobile_number: "",
      pan_number: "",
      driving_licence_number: "",
      alternate_mobile_number: "",
    });
    setSecondaryDriverId("");
    setSecondaryDriverDetails("");
    setIsSecondaryDriverEditMode(false);
  };
  const handleCloseDetailsLog = () => {
    setErrorIncidentType("");
    setChecked(false);
    toggleDrawerLog("right", false);
    setTripLogs({
      trip_id: selectedTrip.id,
      time_of_log: "",
      expected_time_to_destination: "",
      street_address: "",
      remarks: "",
      state: "",
      district: "",
    });
    setIncidentType("");
    setChecked(false);
    setPincode("");
    setPincodeDetails({ district: "", state: "" });
    setErrorLogTime("");
    setErrorDestinationTime("");
    setErrorPincode("");
    setErrorStreet("");
    setErrorRemarks("");
  };

  const handleCloseTripPaymentHistory = () => {
    toggleDrawerTransporterPaymentHistory("right", false);
    setTransporterPaymentHistory({
      amount_paid: "",
      remarks: "",
      image: "",
      utr_reference_number: "",
    });
  };
  const handleCreateFaarmsNewTripButton = (e) => {
    localStorage.removeItem("tripType");
    history.push("/faarms-trips/create-trip");
    localStorage.setItem("IntransiteStatus", selectedTripStatusText);
    localStorage.setItem("createTripSource", "/ftl-trips");
  };
  useEffect(() => {
    localStorage.setItem("createTripSource", "/ftl-trips");
  }, []);
  const handleCreateNewTripButton = (tritpType) => {
    localStorage.removeItem("tripType");
    history.push(`/ftl-trips/create-tripV2/${tritpType}`);

    localStorage.setItem("IntransiteStatus", selectedTripStatusText);
    localStorage.setItem("createTripSource", "/ftl-trips");
  };

  // const handleCreateNewTripButton = () => {
  //   localStorage.removeItem("tripType");
  //   history.push("/faarms-trips/create-trip");
  //   localStorage.setItem("IntransiteStatus", selectedTripStatusText);
  //   localStorage.setItem("createTripSource", "/ftl-trips");
  //   // localStorage.removeItem("reloadPage");
  //   // const url = "/faarms-trips/create-trip";
  //   // window.open(url, "_blank");
  // };

  const handleDeliveredCheckboxToggle = (orderDetail, index) => {
    //
    // setTripDeliveredBills([
    //   ...tripDeliveredBills.filter(
    //     (item) => item.reference_number != orderDetail.reference_number
    //   ),
    //   {
    //     reference_number: orderDetail.reference_number,
    //     status:
    //       orderDetail.status == "delivered" ? "not-delivered" : "delivered",
    //   },
    // ]);
    // console.log("order", [
    //   ...tripDeliveredBills.filter(
    //     (item) => item.reference_number != orderDetail.reference_number
    //   ),
    //   {
    //     reference_number: orderDetail.reference_number,
    //     status:
    //       orderDetail.status == "delivered" ? "not-delivered" : "delivered",
    //   },
    // ]);

    let tripDeliveredBillsTemp = [...tripDeliveredBills];
    tripDeliveredBillsTemp[index]["status"] =
      tripDeliveredBillsTemp[index]["status"] == "delivered"
        ? "not-delivered"
        : "delivered";
    //
    setTripDeliveredBills(tripDeliveredBillsTemp);
  };

  const handleSelectTrip = (trip, index) => {
    //
    setSelectedTrip(trip);

    dispatch(updateSelectedTrip(trip));
    handleOpenDetails();
    setSelectedTripIndex(index);
    let encrypted_data = CryptoJS.AES.encrypt(
      JSON.stringify({ ...indexSelectedData(), trip: index }),
      apiUrl.SALT
    ).toString();
    localStorage.setItem("indexClicked", encrypted_data);
    // console.log(
    //   "selected trip--completed list",
    //   trip.reference_number.map((item) => ({
    //     reference_number: item,
    //     status: "delivered",
    //   }))
    // );
    if (trip.reference_number) {
      setTripDeliveredBills(
        trip.reference_number.map((item) => ({
          reference_number: item,
          status: "delivered",
        }))
      );
    } else {
      setTripDeliveredBills([]);
    }
  };

  const handleEditTrip = (trip) => {
    dispatch(updateTripDetails(trip));
    dispatch(updateTripSearchValue(""));
    localStorage.setItem("IntransiteStatus", selectedTripStatusText);
    history.push(
      `/ftl-trips/create-tripV2/Customer_${trip.trip_type}/${trip.id}/update-trip`
    );
  };

  const handleOpenLogCreate = () => {
    //
    toggleDrawerLog("right", true);
  };
  const handleOpenDetails = () => {
    //
    toggleDrawer("right", true);
  };
  const handleOpenDetailsDrawer = () => {
    //
    toggleDrawer("right", true);
    setTripDetailsHeading("Trip Details");
  };

  const handleOpenTransporterPaymentHistoryDrawer = () => {
    toggleDrawerTransporterPaymentHistory("right", true);
    setTripDetailsHeading("Transporter Payment History");
  };
  const toggleDrawerLog = (anchor, open) => {
    setStateLog({ ...stateLog, [anchor]: open });
  };
  const toggleDrawerTransporterPaymentHistory = (anchor, open) => {
    setStateTripPaymentHistory({ ...tripPaymentHistory, [anchor]: open });
  };
  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
    setImageUrlPdf("");
    setImageUrl("");
  };
  const toggleDetailsDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    if (tripSearchValue) {
      handleSearchTrip(tripSearchValue, currentPage);
      localStorage.setItem("FlagForApiCall", true);
    }
  }, [currentPage, tripSearchValue, filterTripStatus]);

  useEffect(() => {
    if (tripSearchValue == "" && selectedTripStatusText) {
      //
      //
      if (selectedTripStatusText == "intransit") {
        handleStatusClick(4, "intransit");
      } else if (selectedTripStatusText == "created") {
        //
        handleStatusClick(0, "created");
      } else if (selectedTripStatusText == "reopen") {
        handleStatusClick(7, "reopen");
      } else if (selectedTripStatusText == "rejected") {
        handleStatusClick(6, "rejected");
      } else if (selectedTripStatusText == "approved") {
        handleStatusClick(1, "approved");
      } else if (selectedTripStatusText == "requested_for_completion") {
        handleStatusClick(2, "requested_for_completion");
      } else if (selectedTripStatusText == "trip_disputed") {
        handleStatusClick(3, "trip_disputed");
      }

      handleFilterSearch(currentPage);
    }
    if (
      tripSearchValue == "" &&
      selectedTripStatusText?.length < 1 &&
      JSON.parse(localStorage.getItem("FlagForApiCall")) == true
    ) {
      //
      //
      //

      handleGetTripList(currentPage);
    }
  }, [tripSearchValue]);

  const handleAttachmentChange = (e, index) => {
    e.preventDefault();
    let key = e.target.name;
    let value;
    if (key == "attachment_file") {
      value = e.target.files[0];
    } else {
      value = e.target.value;
    }
    //
    //

    let attachmentArrayTemp = [...attachmentListArray];
    attachmentArrayTemp[index][key] = value;
    setAttachmentListArray(attachmentArrayTemp);
  };

  const handleApproveStartTrip = (e) => {
    // setApproveStartAttachment();
  };

  const handleSearchTrip = async (values, page) => {
    // const value = event.target.value;
    //
    // setCurrentPage(1);
    // setSearchValue(value);

    // if (searchValue) {
    //   getOemListData(1);
    // }
    if (values && values?.length >= 1) {
      try {
        setLoading(true);
        const productResponse = await axiosInterceptor.get(
          `${apiUrl.ftlTripV1BaseUrl}?trip_code=${values}&current_page=${page}`
        );
        //
        if (productResponse.data.trip_list?.length > 0) {
          setAllTripList(productResponse.data.trip_list);
          setIsLastPage(productResponse.data.is_last_page);
          setSelectedTrip(productResponse.data.trip_list[0]);
          dispatch(updateSelectedTrip(productResponse.data.trip_list[0]));
          setSelectedTripIndex(0);
        } else {
          setAllTripList([]);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        setAllTripList([]);
      }
    }
    // else if (value.length == 0) {
    //   getOemListData(currentPage);
    // }
  };
  const vertical = "bottom";
  const horizontal = "right";
  const [tripDetailsHeading, setTripDetailsHeading] = useState("");
  const [tripAdvanceCost, setTripAdvanceCost] = useState("");
  const [tripCostTotal, setTripCostTotal] = useState("");
  const [tripHistoryList, setTripHistoryList] = useState([]);
  const [tripAttachmentList, setTripAttachmentList] = useState([]);
  const [loadingAttachmentList, setLoadingAttachmentList] = useState(false);
  const [quantityDeliveryFromHistory, setQuantityDeliveryFromHistory] =
    useState([]);
  const [paymentCompleteInvoiceList, setPaymentCompleteInvoiceList] = useState(
    []
  );
  const [attachmentsDisputeHistory, setAttachmentsDisputeHistory] = useState(
    {}
  );
  const [deliveryPaymentList, setDeliveryPaymentList] = useState([]);
  const [tripPaymentHistory, setTripPaymentHistory] = useState([]);
  const [loadingPaymentHistory, setLoadingPaymentHistory] = useState(false);
  const [deliverItemList, setDeliverItemList] = useState([]);
  const [paymentHistoryArray, setPaymentHistoryArray] = useState([]);
  //   //
  const handleTripDetails = (head) => {
    setTripDetailsHeading(head);
    setTripPaymentHistory([]);
  };
  const handleTripDeliveryDetails = (head) => {
    setTripDetailsHeading(head);
    getDeliverItems(selectedTrip.trip_code);
    getInvoicePaymentHistoryApi(selectedTrip.id);
    setTripPaymentHistory([]);
  };
  const handleTripPaymentHistoryDetails = (head) => {
    setTripDetailsHeading(head);
    if (head == "Payment History") {
      getPaymentHistory(selectedTrip.id);
    }
  };
  const handleTripHistoryDetails = (head) => {
    setTripDetailsHeading(head);
    setTripPaymentHistory([]);
    // getDeliverItems(selectedTrip.trip_code);
    getTripHistoryDetails(selectedTrip.trip_code);
  };
  const handleTripAttachmentsDetails = (head) => {
    setTripDetailsHeading(head);
    setTripPaymentHistory([]);
    getTripAttachmentList(selectedTrip.id);
  };
  const handleDriverDetails = (head) => {
    // increment(head);
    setTripDetailsHeading(head);
    setTripPaymentHistory([]);
    getDriverDetails(selectedTrip.id);
  };

  const handleSecondaryDriverSelect = (e) => {
    setSecondaryDriverDetailsInput(e);
    setSecondaryDriverId(e.id);
    setSecondaryDriverError("");
  };

  const driverOptions = (inputValue) =>
    new Promise((resolve) => {
      var response = handleFetchDrivers(inputValue);
      setDriverListOptions(response);
      resolve(response);
    });

  const handleFetchDrivers = async (searchValue) => {
    const response = await GetDrivers(searchValue);

    return response.drivers_list.map((item) => {
      return {
        value: item.driving_licence_number,
        label: (
          <div>
            <div>
              <span className="create-trip-select-list-right-text">
                {item.first_name + " "}
                {item.last_name ? item.last_name : ""}
              </span>
            </div>
            <div>
              <span className="create-trip-select-list-left-text">
                DL no :{" "}
              </span>
              <span className="create-trip-select-list-right-text">
                {item.driving_licence_number}
              </span>
            </div>
            <div>
              <span className="create-trip-select-list-left-text">
                Mobile :{" "}
              </span>
              <span className="create-trip-select-list-right-text">
                {item.mobile_number}
              </span>
            </div>
          </div>
        ),
        first_name: item.first_name,
        last_name: item.last_name,
        mobile_number: item.mobile_number,
        alternate_mobile_number: item.alternate_mobile_number,
        pan_number: item.pan_number,
        driving_licence_number: item.driving_licence_number,
        id: item.id,
      };
    });
  };

  const handleCostDetails = (head) => {
    setTripDetailsHeading(head);
    getTripCostDetails();
    setTripPaymentHistory([]);
  };
  const handleLogDetails = (head) => {
    setTripDetailsHeading(head);
    getTripLogDetails(selectedTrip.id);
    setTripLogs({ ...tripLogs, trip_id: selectedTrip.id });
    getTripsLogIncidentType();
    setTripPaymentHistory([]);
  };

  const handlePaymentHistoryDetails = (head) => {
    setTripDetailsHeading(head);
    getTripPaymentHistoryDetails(selectedTrip.id);
  };

  const getAttachmentListWithTag = async (id, tag) => {
    setEwayBillDrawerLoading(true);
    try {
      const response = await axiosInterceptor.get(
        `${apiUrl.ftlTripAttachmentsUrl}?trip_id=${id}&tag=${tag}`
      );
      if (response.status === 200) {
        setFilteredAttachmentsWithTag(response.data.trip_attachments_list);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setEwayBillDrawerLoading(false);
    }
  };

  const handleEwayBillDetails = (head) => {
    setTripDetailsHeading(head);
    getTripEwayBillDetails(selectedTrip.id);
  };

  const handleTripListAndvanceSearch = (e) => {
    const { name, value } = e.target;
    if (name === "vehicle_number") {
      if (value && !allRegex.vehicleNumberRegex.test(value)) {
        setAdvanceSearchError({
          ...advanceSearchError,
          vehicle_number_error: "Please enter valid vehicle number",
        });
      } else {
        setAdvanceSearchError({
          vehicle_number_error: "",
          lr_number_error: "",
        });
      }
      setFilterVehicleNumber(value);
    } else if (name === "lr_number") {
      if (value && !allRegex.alphanumaricRegex.test(value)) {
        setAdvanceSearchError({
          ...advanceSearchError,
          lr_number_error: "Please enter valid LR number",
        });
      } else {
        setAdvanceSearchError({
          vehicle_number_error: "",
          lr_number_error: "",
        });
      }
      setFilterReferenceNumber(e.target.value);
    } else if (name === "eway-bill") {
      if (value && !allRegex.ewayBillRegex.test(value)) {
        setAdvanceSearchError({
          ...advanceSearchError,
          eway_bill_no_error: "Please enter valid Eway Bill number",
        });
      } else {
        setAdvanceSearchError({
          vehicle_number_error: "",
          lr_number_error: "",
          eway_bill_no_error: "",
        });
      }
      setFilterEwayBillNo(e.target.value);
    }
  };

  useEffect(() => {
    if (selectedTrip.trip_cost) {
      if (selectedTrip.trip_cost?.length > 0) {
        //trip cost
        const tripCostTemp = selectedTrip.trip_cost.filter(
          (item) => item.cost_type === "trip_cost"
        );
        if (tripCostTemp?.length > 0) {
          setTripCostTotal(tripCostTemp[0].cost_amount);
        } else {
          setTripCostTotal(0);
        }

        //trip advance
        const tripAdvanceTemp = selectedTrip.trip_cost.filter(
          (item) => item.cost_type === "advance"
        );
        if (tripAdvanceTemp?.length > 0) {
          setTripAdvanceCost(tripAdvanceTemp[0].cost_amount);
        } else {
          setTripAdvanceCost(0);
        }
      } else {
        setTripCostTotal(0);
        setTripAdvanceCost(0);
      }
    }
  }, [selectedTrip]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openInvoice = Boolean(anchorEl);
  const handleInvoiceClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleInvoiceClose = () => {
    setAnchorEl(null);
  };

  const getDriverDetails = async (id) => {
    try {
      setLoadingAttachmentList(true);
      const response = await GetDriverDetails(id);

      setDriverDetails(response.primary_driver_data);
      setSecondaryDriverDetails(response.secondary_driver_data);
      dispatch(updateDriverDetails(response));
    } catch (error) {
      console.log("Error while fetching driver details " + error);
    } finally {
      setLoadingAttachmentList(false);
    }
  };

  const handleUpdateDriver = async () => {
    try {
      setSecondaryButtonLoading(true);
      const respnse = await UpdateDriverPost(
        selectedTrip.id,
        secondaryDriverId,
        secondaryDriverDetailsInput.mobile_number
      );

      if (respnse.status === 200) {
        getDriverDetails(selectedTrip.id);
        setSecondaryDriverDetailsInput({
          first_name: "",
          last_name: "",
          mobile_number: "",
          pan_number: "",
          driving_licence_number: "",
          alternate_mobile_number: "",
        });
        setSecondaryDriverId("");
        setSecondaryDriverDetails("");
        setIsSecondaryDriverEditMode(false);
        setSecondaryDriverError("");
      } else if (respnse.status >= 400) {
        setSecondaryDriverError(
          Array.isArray(respnse.data.detail)
            ? respnse.data.detail[0].msg
            : respnse.data.detail
        );
      }
    } catch (error) {
    } finally {
      setSecondaryButtonLoading(false);
    }
  };
  let tokensData = readToken();
  const [imageUrl, setImageUrl] = useState("");
  const [imageUrlPdf, setImageUrlPdf] = useState("");

  const downloadDriverDocs = async (id) => {
    try {
      const response = await showOrDownlaodAttachmentsUtility(
        id,
        "driver-licence",
        "downloadOnly"
      );

      if (response.status === 401) {
        return toast.error("401: Not enough permissions");
      }
    } catch (error) {}
  };

  const showDriverDocs = async (id) => {
    try {
      setLoadingImage(true);
      const response = await showOrDownlaodAttachmentsUtility(
        id,
        "driver-licence"
      );
      if (response.status === 401) {
        return toast.error("401: Not enough permissions");
      } else if (
        response.file &&
        (response.attachmentType === "image/png" ||
          response.attachmentType === "image/jpeg")
      ) {
        setImageUrlPdf("");
        setImageUrl(response.file);
      } else {
        setImageUrl("");
        setImageUrlPdf(response.file);
      }
    } catch (error) {
    } finally {
      setLoadingImage(false);
    }
  };

  const getTripHistoryDetails = async (trip_code) => {
    const response = await GetTripHistory(trip_code);
    setTripHistoryList(response.trip_history_list);

    setQuantityDeliveryFromHistory(
      response.trip_history_list
        .map((item) => {
          try {
            return JSON.parse(item.remarks)[0];
          } catch (e) {
            return null;
          }
        })
        .filter((item) => item != null)
    );
  };
  const getTripAttachmentList = async (trip_id) => {
    setLoadingAttachmentList(true);
    const response = await GetTripAttachments(trip_id);
    setTripAttachmentList(response.trip_attachments_list);
    setLoadingAttachmentList(false);
  };

  const download = async (id) => {
    try {
      const response = await showOrDownlaodAttachmentsUtility(
        id,
        "ftl-trip-attachment",
        "downloadOnly"
      );

      if (response.status === 401) {
        return toast.error("401: Not enough permissions");
      }
    } catch (error) {}
  };

  const showTransporterPaymentImage = async (id) => {
    try {
      setLoadingImage(true);
      const { file, attachmentType } = await showOrDownlaodAttachmentsUtility(
        id,
        "ftl-transporter-payment"
      );
      if (
        file &&
        (attachmentType === "image/png" || attachmentType === "image/jpeg")
      ) {
        setTransporterAttachmentPdf("");
        setTransporterPaymentHistoryImage(file);
      } else {
        setTransporterAttachmentPdf(file);
        setTransporterPaymentHistoryImage("");
      }
    } catch (error) {
      setTransporterAttachmentPdf("");
      setTransporterPaymentHistoryImage(INFOICON);
    } finally {
      setLoadingImage(false);
    }
  };

  const showDownloadPdf = async (id) => {
    setLoadingImage(true);
    fetch(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/ftl-trip-attachment/${prefixSalt()}-${convertToBase64(id)}`,
      // { responseType: "application/pdf" },
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
          Accept: "application/pdf",
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setImageUrl("");
        setLoadingImage(false);
        var file = window.URL.createObjectURL(blob);
        setImageUrlPdf(file);
        // setTransporterImageUrl("");
      })
      .catch((err) => {
        setLoadingImage(false);
      });
  };

  // ==========================
  const showTripAttachments = async (id) => {
    try {
      // { file, attachmentType }
      setLoadingImage(true);
      const response = await showOrDownlaodAttachmentsUtility(
        id,
        "ftl-trip-attachment"
      );
      console.log(response.status, "ftl attachment error");
      if (response.status === 401) {
        return toast.error("401: Not enough permissions");
      } else if (
        response.file &&
        (response.attachmentType === "image/png" ||
          response.attachmentType === "image/jpeg")
      ) {
        setImageUrlPdf("");
        setImageUrl(response.file);
      } else {
        setImageUrl("");
        setImageUrlPdf(response.file);
      }
    } catch (error) {
    } finally {
      setLoadingImage(false);
    }
  };

  // ==========
  const showPaymentImageDownloadUrl = async (id) => {
    setLoadingImage(true);
    fetch(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/invoice-payment-history/${prefixSalt()}-${convertToBase64(
        id
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setImageUrlPdf("");
        setLoadingImage(false);
        var file = window.URL.createObjectURL(blob);
        setImageUrl(file);

        // window.location.assign(file);
        // window.open(file, "_blank");
      })
      .catch((err) => {
        setLoadingImage(false);
      });
  };
  const [imageError, setImageError] = useState("");
  const showPaymentAttachments = async (id) => {
    setLoadingImage(true);
    fetch(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/invoice-payment-history/${prefixSalt()}-${convertToBase64(
        id
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    ).then((res) => {
      if (res.status == 401) {
        setLoadingImage(false);
        setImageError(res.statusText);
      }
      if (res.status == 403) {
        localStorage.clear();
        window.location.reload();
      }
      if (res.headers.get("content-type") === "application/pdf") {
        showDownloadPdf(id);
        setImageError("");
        return;
      }
      if (
        res.headers.get("content-type") === "image/png" ||
        res.headers.get("content-type") === "image/jpeg"
      ) {
        showPaymentImageDownloadUrl(id);
        setImageError("");
        res.blob();
        return;
      }
    });
  };
  const downloadImagePayment = async (id) => {
    fetch(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/invoice-payment-history/${prefixSalt()}-${convertToBase64(
        id
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = window.document.createElement("a");
          link.href = url;
          link.download = "image.png";
          link.click();
        });

        if (response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      })
      // .then((res) => res.blob())
      // .then((blob) => {
      //   var file = window.URL.createObjectURL(blob);

      //   window.open(file, "_blank");
      // })
      .catch((err) => {});
  };
  // const downloadPaymentAttachment = async (id) => {
  //   fetch(
  //     `${
  //       apiUrl.onboardingBaseUrl
  //     }/v1/image/invoice-payment-history/${prefixSalt()}-${convertToBase64(
  //       id
  //     )}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
  //         "x-app-token": `${tokensData}`,
  //       },
  //     }
  //   ).then((response) => {
  //     if (response.headers.get("content-type") === "application/pdf") {
  //       downloadPdf(id);
  //       return;
  //     }
  //     if (
  //       response.headers.get("content-type") === "image/png" ||
  //       response.headers.get("content-type") === "image/jpeg"
  //     ) {
  //       downloadImagePayment(id);
  //       return;
  //     }
  //     // response.arrayBuffer().then(function (buffer) {
  //     //   const url = window.URL.createObjectURL(new Blob([buffer]));
  //     //   const link = window.document.createElement("a");
  //     //   link.href = url;
  //     //   link.download = "image.png";
  //     //   link.click();
  //     // });
  //   });
  //   // .then((res) => res.blob())
  //   // .then((blob) => {
  //   //   var file = window.URL.createObjectURL(blob);

  //   //   window.open(file, "_blank");
  //   // })
  // };
  const getInvoicePaymentHistoryApi = async (tripId) => {
    const invoiceResponse = await GetTripInvoicePaymentByTripId(tripId);

    if (invoiceResponse) {
      setDeliveryPaymentList(invoiceResponse.invoice_payments_history_list);
      const dupliceRemovedInvoiceList = [
        ...new Set(
          invoiceResponse.invoice_payments_history_list.map(
            (item) => item.reference_number
          )
        ),
      ];
      if (dupliceRemovedInvoiceList?.length > 0) {
        setPaymentCompleteInvoiceList(dupliceRemovedInvoiceList);
      } else {
        setPaymentCompleteInvoiceList([]);
      }
    } else {
      setPaymentCompleteInvoiceList([]);
    }
  };

  const getPaymentHistory = async (tripId) => {
    setLoadingPaymentHistory(true);
    const response = await GetTripPaymentHistory(tripId);

    if (response.status === 200) {
      setLoadingPaymentHistory(false);
      setTripPaymentHistory(response.data.invoice_payments_history_list);
    }
    if (response.status == 400) {
      setLoadingPaymentHistory(false);
    }
  };
  const getDeliverItems = async (tripCode) => {
    setPaymentLoading(true);
    const response = await GetTripItemsForDeliveryInfo(tripCode);

    // deliverItemTemp.push(response);
    // setDeliverItemList(deliverItemTemp);

    if (response) {
      setPaymentLoading(false);
      setPaymentHistoryArray(response?.invoice_payment_history);
      setDeliverItemList(
        selectedTrip.reference_number.map((item) => {
          return {
            reference_number: item,
            items_list: response.trip_items_list.filter(
              (items) => items.reference_number === item
            ),
          };
        })
      );
    }
  };

  const [openAlert, setOpenAlert] = React.useState(true);

  const handleEwayUpdateInputchange = async (e, ewayBillNo, validFrom) => {
    const { name, value, files } = e.target;

    let obj = updatedEwayBillDetails;

    obj = {
      ...obj,
      eway_bill_number: ewayBillNo,
      eway_bill_valid_from: validFrom,
      trip_status: selectedTrip.trip_status,
    };

    if (name === "eway_bill_valid_till") {
      const momentDate = moment(value, "YYYY-MM-DD", true);

      if (momentDate.isValid()) {
        const today = moment().startOf("day");

        if (momentDate.isSameOrAfter(today)) {
          setEwayBillError("");
          obj = {
            ...obj,
            eway_bill_valid_till: value,
          };
        } else {
          setEwayBillError(
            "Eway bill till date should be today or future date"
          );
        }
      } else {
        obj = {
          ...obj,
          eway_bill_valid_till: "",
        };
      }
    } else if (name === "eway_bill_attachment") {
      const newFile = await saveBlob(files[0]);
      if (newFile.size > process.env.REACT_APP_MAX_FILE_SIZE) {
        setEwayBillAttachmentError(
          `File size should be less then ${
            process.env.REACT_APP_MAX_FILE_SIZE / 1000000
          }MB`
        );
      } else {
        setEwayBillAttachmentError("");

        obj = {
          ...obj,
          eway_bill_attachment_type:
            newFile.type === "application/pdf" ? "pdf" : "image",
          eway_bill_attachment_tag: "e_way_bill",
          eway_bill_attachment: newFile,
        };
      }
    } else {
      obj = {
        ...obj,
        [name]: value,
      };
    }

    setUpdatedEwayBillDetails(obj);
  };

  const isEwayBillValid = () => {
    return Object.values(updatedEwayBillDetails).every((value) => value !== "");
  };

  const handleUpdateEwayBill = async () => {
    try {
      if (!isEwayBillValid()) {
        return;
      }
      setEwayBillError("");
      setEwayBillAttachmentError("");
      setEwayBillButtonLoading(true);
      const response = await EwayBillChange(
        updatedEwayBillDetails,
        selectedTrip.id
      );

      if (response.status === 200) {
        getTripEwayBillDetails(selectedTrip.id);
        getAttachmentListWithTag(selectedTrip.id, "e_way_bill");
      }
      if (response.status === 401) {
        toast.error("401: Not enough permissions");
      }
      if (response.status >= 400 && response.status !== 422) {
        setEwayBillError(response.data.detail);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setEwayBillButtonLoading(false);
    }
  };

  // ===========================status tabs=========================================

  const handleStatusClick = (index, status) => {
    setselectedTripStatus(index);
    setSelectedTripStatusText(status);
    localStorage.setItem("FlagForApiCall", true);
    setCurrentPage(1);
  };

  useEffect(() => {
    const selectedTripStatusFromLocalStorage = localStorage.getItem(
      "SELECTED_TRIP_STATUS"
    );
    if (selectedTripStatusFromLocalStorage) {
      setSelectedTripStatusText(selectedTripStatusFromLocalStorage);
      setselectedTripStatus(localStorage.getItem("SELECTED_TRIP_STATUS_INDEX"));
      localStorage.setItem("FlagForApiCall", true);
    }
  }, []);

  const handleChange = (event) => {
    setTripType(event.target.value);
    setCurrentPage(1);
  };

  const handleDrawerButton = (tab) => {
    if (Object.keys(selectedTrip).length === 0) return;
    if (tab === "Trip Details") {
      handleTripDetails(tab);
    } else if (tab === "Driver") {
      handleDriverDetails(tab);
    } else if (tab === "Trip History") {
      handleTripHistoryDetails(tab);
    } else if (tab === "Attachments") {
      handleTripAttachmentsDetails(tab);
    } else if (tab === "Trip Costs") {
      handleCostDetails(tab);
    } else if (tab === "Log Details") {
      handleLogDetails(tab);
    } else if (tab === "Transporter Payment History") {
      handlePaymentHistoryDetails(tab);
    } else if (tab === "E Way Bill") {
      handleEwayBillDetails(tab);
      getAttachmentListWithTag(selectedTrip.id, "e_way_bill");
    }
  };

  // ===============================================================================

  const handleCheckbox = (event) => {
    setChecked(event.target.checked);
  };
  function capitalizeLetter(str) {
    var i,
      splittedArray = str.split("_");
    for (i = 0; i < splittedArray?.length; i++) {
      splittedArray[i] =
        splittedArray[i].charAt(0).toUpperCase() + splittedArray[i].slice(1);
    }
    return splittedArray.join(" ");
  }
  // console.log("login======", readLoginData().Role);
  const getDisputeHistory = async (id) => {
    setLoadingImage(true);
    const response = await getTripAttachmentsDispute(id);
    setAttachmentsDisputeHistory(response);
    setLoadingImage(false);
  };
  const handleDropdownItemClick = (e) => {};
  const onChange = (value, dateString) => {
    setErrorLogTime("");
    //
    setTripLogs({
      ...tripLogs,
      time_of_log: moment(value.$d).format("YYYY-MM-DD HH:mm:ss"),
    });
  };
  const onChangeDestination = (value, dateString) => {
    setErrorDestinationTime("");
    setTripLogs({
      ...tripLogs,
      expected_time_to_destination: moment(value.$d).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    });
    //
    // console.log(
    //   "Formatted Selected Time: ",
    //   dayjs(moment(value.$d).format("YYYY-MM-DD HH:mm:ss"))
    // );
  };

  const onOk = (value) => {};
  const onOkDestination = (value) => {};
  // const items = [
  //   {
  //     label: (
  //       <span
  //         onClick={() => {
  //           handleCreateNewTripButton("Customer_first_mile");
  //         }}
  //       >
  //         First Mile
  //       </span>
  //     ),
  //     key: "Customer_first_mile",
  //   },
  //   {
  //     label: (
  //       <span
  //         onClick={() => {
  //           handleCreateNewTripButton("Customer_middle_mile");
  //         }}
  //       >
  //         Middle Mile
  //       </span>
  //     ),
  //     key: "Customer_middle_mile",
  //   },
  //   {
  //     label: (
  //       <span
  //         onClick={() => {
  //           handleCreateNewTripButton("Customer_last_mile");
  //         }}
  //       >
  //         Last Mile
  //       </span>
  //     ),
  //     key: "Customer_last_mile",
  //   },
  // ];
  const items2 = [
    {
      label: (
        <span
          onClick={() => {
            handleOpenExportReport("first_mile");
          }}
        >
          First Mile
        </span>
      ),
      key: "Customer_first_mile",
    },
    {
      label: (
        <span
          onClick={() => {
            handleOpenExportReport("middle_mile");
          }}
        >
          Middle Mile
        </span>
      ),
      key: "Customer_middle_mile",
    },
    {
      label: (
        <span
          onClick={() => {
            handleOpenExportReport("last_mile");
          }}
        >
          Last Mile
        </span>
      ),
      key: "Customer_last_mile",
    },
  ];

  const handleSettings = () => {
    toggTripSettingsDrawer("right", true);
  };
  const handleCloseBtn = () => {
    toggTripSettingsDrawer("right", false);
  };
  const handleGoToTripSettings = () => {
    history.push("/ftl-trips/trip-settings");
  };
  const getTripCostDetails = async () => {
    setLoadingTripCost(true);
    const response = await GetTripCostApi(selectedTrip?.id);

    if (response.status == 200) {
      setLoadingTripCost(false);
      setTriCostDetails(
        response.data?.filter(
          (data) =>
            data.cost_type !== "advance" && data.cost_type !== "trip_cost"
        )
      );
    }
  };
  const [loadingPaymentApprove, setLoadingPaymentApprove] = useState(false);
  const [loadingLogsList, setLoadingLogsList] = useState([]);
  const [loadingPaymentHistoryList, setLoadingPaymentHistoryList] = useState(
    []
  );
  const [succesfulMsg, setSuccesfulMsg] = useState(false);
  const [negativeTrip, setNegativeTrip] = useState(false);
  const [showNegativeTripFields, setShowNegativeTripFields] = useState(false);

  const [loadingPaymentApproveReject, setLoadingPaymentApproveReject] =
    useState(false);
  const [loadingPaymentRejectedApprove, setLoadingPaymentRejectedApprove] =
    useState(false);
  const [loadingPaymentRejected, setLoadingPaymentRejected] = useState(false);
  const [paymentstatusError, setPaymentstatusError] = useState("");
  const handleRecommendRejectPayment = async (status, id) => {
    if (status == "recommended") {
      setLoadingPaymentApprove(true);
    } else if (status == "approved") {
      setLoadingPaymentApproveReject(true);
    } else if (status == "rejected") {
      setLoadingPaymentRejected(true);
    }

    //
    const response = await RecommendRejectPayment(status, paymentRemarks, id);
    //
    if (response.status == 200) {
      handleTripPaymentHistoryDetails("Payment History");
      // getPaymentHistory(selectedTrip.id);
      setLoadingPaymentApprove(false);
      setPaymentstatusError("");
      setPaymentRemarks("");
      setLoadingPaymentRejectedApprove(false);
      setLoadingPaymentApproveReject(false);
      setLoadingPaymentRejected(false);
      setOpenPaymentApproveRejectToast(true);
      setSuccesfulMsg(`Payment ${status} successfully.`);
      // setPaymentRemarks("");
    } else if (response.status == 400) {
      setLoadingPaymentApprove(false);
      setLoadingPaymentRejectedApprove(false);
      setLoadingPaymentApproveReject(false);
      setLoadingPaymentRejected(false);
      setPaymentstatusError(response.data.detail);
    } else if (response.status == 401) {
      setLoadingPaymentApprove(false);
      setLoadingPaymentRejectedApprove(false);
      setLoadingPaymentRejected(false);
      setLoadingPaymentApproveReject(false);
      setPaymentstatusError(response.data.detail);
    }

    //
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const negativeTripEvaluator = () => {
    let tripCostAmount = +selectedTrip?.trip_cost?.filter(
      (item) => item.cost_type == "trip_cost"
    )[0]?.cost_amount;
    let billedAmountByCustomer = +customerBilledAmount;

    if (tripCostAmount <= billedAmountByCustomer) {
      setNegativeTrip(false);
    } else {
      setNegativeTrip(true);
    }
  };

  useEffect(() => {
    negativeTripEvaluator();
  }, [selectedTrip, customerBilledAmount]);
  //======================logs==================================
  const getTripLogDetails = async (id) => {
    setLoadingLogsList(true);
    const response = await GetTripLog(id);
    if (response.status == 200) {
      setLoadingLogsList(false);
      setTripLogList(response.data);
    }
    //
  };

  const getTripPaymentHistoryDetails = async (id) => {
    setLoadingPaymentHistoryList(true);
    const response = await GetTransporterPaymentHistory(id);
    if (response.status == 200) {
      setLoadingPaymentHistoryList(false);
      setTransporterPaymentHistoryList(response.data);
    }
  };

  const getTripEwayBillDetails = async (id) => {
    setEwayBillDrawerLoading(true);
    const response = await GetEwayBill(id);
    if (response.status == 200) {
      setEwayBillDrawerLoading(false);
      setTripEwayBillDetails(response.data.ftl_trip_eway_bills_list);
    }
  };

  const calculateRelativeTime = (date) => {
    const endOfDay = moment(date).endOf("day");
    const timeDifference = endOfDay.diff(moment());

    if (timeDifference > 0) {
      return moment.duration(timeDifference).humanize(true);
    } else {
      return moment(date, "YYYY-MM-DD").from(moment().subtract(1, "days"));
    }
  };

  const [isClickedCreateLogs, setIsClickedCreateLogs] = useState(true);
  const [isClickedViewLogs, setIsClickedViewLogs] = useState(false);

  const handleCreateLogs = () => {
    setIsClickedCreateLogs(true);
    setIsClickedViewLogs(false);
  };
  const handleViewLogs = () => {
    setIsClickedViewLogs(true);
    setIsClickedCreateLogs(false);
  };

  const handleGetAddressFromPincode = async (pincode) => {
    // if (pincode.length == 6) {
    // setTripLogs({ ...tripLogs, pincode: pincode });
    // }

    const response = await getVillagesList(pincode);
    console.log("the ressss", response);
    if (response.messages) {
      setNotServiceableError(response.messages);
      return;
    }
    if (response.pincode) {
      setTripLogs({
        ...tripLogs,
        state: response.state,
        district: response.district,
        // sub_district: response.sub_district,
        // postoffice: response.office[0].name,
      });
    }
    //
    // if (edit) {
    //   setVillagesList(response.office[0].villages);
    // } else {

    // setVillagesList(response.office[0].villages);
    // }
  };
  const [loadingLogsButton, setLoadingLogsButton] = useState(false);
  const [tripLogsError, setTripLogsError] = useState("");
  const createLogs = async () => {
    const isValid = formValidation();

    if (isValid) {
      setLoadingLogsButton(true);
      const response = await TripLogsPost(
        tripLogs.trip_id,
        tripLogs.time_of_log,
        tripLogs.district,
        tripLogs.state,
        pincode,
        tripLogs.street_address,
        tripLogs.expected_time_to_destination,
        tripLogs.remarks,
        checked,
        incidentType ? incidentType : null,
        selectedTrip.trip_status
      );
      if (response.status == 200) {
        setLoadingLogsButton(false);
        toggleDrawerLog("right", false);
        setTripLogs({
          trip_id: selectedTrip.id,
          time_of_log: "",
          expected_time_to_destination: "",
          street_address: "",
          remarks: "",
          state: "",
          district: "",
        });
        // setTripLogs({ ...tripLogs, trip_id: selectedTrip.id });
        setPincode("");
        getTripLogDetails(selectedTrip.id);
        setIncidentType("");
        setChecked(false);
        setErrorIncidentType("");
      } else if (response.status === 401) {
        toast.error("401: Not enough permissions");
        setLoadingLogsButton(false);
      } else if (response.status == 422) {
        setLoadingLogsButton(false);
        setTripLogsError("field is missing");
        // toggleDrawerLog("right", false);
      }
    }
  };
  const createHistory = async () => {
    setLoadingLogsButton(true);
    const response = await TransporterPaymentHistoryPost(
      selectedTrip.id,
      selectedTrip.transporter_id,
      transporterPaymentHistory.utr_reference_number,
      transporterPaymentHistory.amount_paid,
      transporterPaymentHistory.remarks,
      transporterPaymentHistory.image
    );
    if (response.status === 200) {
      setLoadingLogsButton(false);
      getTripPaymentHistoryDetails(selectedTrip.id);
      toggleDrawerTransporterPaymentHistory("right", false);
      setTransporterPaymentHistory({
        amount_paid: "",
        remarks: "",
        image: "",
        utr_reference_number: "",
      });
    } else if (response.status === 401) {
      toast.error("401: Not enough permissions");
      setLoadingLogsButton(false);
    } else {
      setLoadingLogsButton(false);
    }
  };

  const [errorLogTime, setErrorLogTime] = useState("");
  const [errorDestinationTime, setErrorDestinationTime] = useState("");
  const [errorPincode, setErrorPincode] = useState("");
  const [errorStreet, setErrorStreet] = useState("");
  const [errorRemarks, setErrorRemarks] = useState("");
  const [errorIncidentType, setErrorIncidentType] = useState("");
  const formValidation = () => {
    const errorLogTime = {};
    const errorDestinationTime = {};
    const errorPincode = {};
    const errorStreet = {};
    const errorRemarks = {};
    const errorIncidentType = {};

    let isValid = true;
    if (
      tripLogs.time_of_log == "" &&
      selectedTrip.trip_status === "intransit"
    ) {
      errorLogTime.errorTripLogsname = "Enter Log date and time.";
      isValid = false;
    }
    // if (tripLogs.street_address == "") {
    //   errorStreet.errorTripstreet = "Enter Street address.";
    //   isValid = false;
    // }
    if (
      tripLogs.expected_time_to_destination == "" &&
      selectedTrip.trip_status === "intransit"
    ) {
      errorDestinationTime.errorExpectedDate =
        "Enter expected time to destination .";
      isValid = false;
    }
    if (pincode == "" && selectedTrip.trip_status === "intransit") {
      errorPincode.errorTripPincode = "Enter Pincode.";
      isValid = false;
    }
    if (tripLogs.remarks == "") {
      errorRemarks.errorTriplogsremarks = "Enter Remarks.";
      isValid = false;
    }
    if (checked) {
      if (incidentType == "") {
        errorIncidentType.errorTriplogIncidentType = "Select Incident Type.";
        isValid = false;
      }
    }
    setErrorLogTime(errorLogTime);
    setErrorDestinationTime(errorDestinationTime);
    setErrorPincode(errorPincode);
    setErrorStreet(errorStreet);
    setErrorRemarks(errorRemarks);
    setErrorIncidentType(errorIncidentType);

    return isValid;
  };
  const [triLogsIncidentTypeList, setTriLogsIncidentTypeList] = useState([]);
  const getTripsLogIncidentType = async () => {
    const response = await GetTripLogEnum();
    if (response.status == 200) {
      setTriLogsIncidentTypeList(response.data);
    }
    //
  };

  const fetchTeams = async (searchValue, status, page) => {
    try {
      const response = await getTeams(searchValue, status, page);
      if (response.status === 200) {
        setTeamsList(response.data.teams_list);
      } else if (response.status === 401) {
        toast.error("401: Not enough permissions to fetch teams list");
      }
    } catch (error) {
      console.log("Getting error while fetching teams list," + error);
    }
  };

  useEffect(() => {
    if (teamsSearchTerm.length > 2 || teamsSearchTerm == "") {
      fetchTeams(teamsSearchTerm, true, 1);
    }
  }, [teamsSearchTerm]);

  // useEffect(() => {getTripsLogIncidentType()},[])
  return (
    <Trip_list_main_container
      className="trip-list-main-container"
      mode={darkMode}
    >
      <ToastContainer style={{ marginTop: "40px" }} theme="light" />
      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Trips" />
        </div>
      ) : (
        <div className="trip-list-sidebar">
          <SideBarMain />
        </div>
      )}
      <Drawer
        anchor={"right"}
        open={tripSettingsState["right"]}
        onClose={() => {
          toggTripSettingsDrawer("right", false);
        }}
      >
        <ToastContainer style={{ marginTop: "40px" }} />
        <Trip_settings_wrapper className="trip-settings-wrapper">
          <div className="trip-settings-head">
            <div>Trip Settings</div>
            <div>
              <CloseIcon
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                  cursor: "pointer",
                }}
                onClick={handleCloseBtn}
              />
            </div>
          </div>
          <div className="settings_list_container">
            <div
              className="settings_lists"
              onClick={() => {
                handleGoToTripSettings();
              }}
            >
              <div>Consignor whitelisting</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ArrowForwardOutlinedIcon />
              </div>
            </div>
          </div>
        </Trip_settings_wrapper>
        {/* <h6>JWSDJSDJDJSDSJDSJDSJDSD</h6> */}
      </Drawer>

      <Modal
        open={openTripDocs}
        onClose={handleCloseImage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={imageModalStyle}>
          {loadingImage ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <img src={imageloader} alt="" width="20%" height="20%" />
              <div>Loading...</div>
            </div>
          ) : imageError ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <DangerousIcon style={{ color: "red" }} />
              {/* <img src={imageloader} alt="" width="20%" height="20%" /> */}
              <div
                style={{
                  color: "red",
                  // displa: "flex",
                  // flexDirection: "column",
                  // alignItems: "center",
                  // justifyContent: "center",
                  width: "100%",
                }}
              >
                <span>{imageError}</span>
              </div>
            </div>
          ) : (
            <>
              {imageUrlPdf ? (
                <embed
                  src={imageUrlPdf}
                  type="application/pdf"
                  style={pdfEmbedStyle}
                ></embed>
              ) : imageUrl ? (
                <TransformWrapper
                  initialScale={1}
                  initialPositionX={0}
                  initialPositionY={0}
                >
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                      <div
                        style={{
                          margin: "10px 0px",
                          position: "absolute",
                          top: "3px",
                          right: "13px",
                          zIndex: 99,
                          display: "flex",
                          flexDirection: "column",
                          background: "#fff",
                          boxShadow:
                            "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
                        }}
                      >
                        <button
                          style={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "none",
                            border: "none",
                            color: "#26cff0",
                            cursor: "pointer",
                            borderBottom: "0.5px solid #26cff0",
                          }}
                          onClick={() => zoomIn()}
                        >
                          <Add />
                        </button>
                        <button
                          style={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "none",
                            border: "none",
                            color: "#26cff0",
                            cursor: "pointer",
                            borderBottom: "0.5px solid #26cff0",
                          }}
                          onClick={() => zoomOut()}
                        >
                          <Remove />
                        </button>
                        <button
                          style={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "none",
                            border: "none",
                            color: "#26cff0",
                            cursor: "pointer",
                            borderBottom: "0.5px solid #26cff0",
                          }}
                          onClick={() =>
                            setRotateImageValue((prevData) => prevData + 90)
                          }
                        >
                          <Rotate90DegreesCw />
                        </button>
                        <button
                          style={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "none",
                            border: "none",
                            color: "#26cff0",
                            cursor: "pointer",
                          }}
                          onClick={() => resetTransform()}
                        >
                          <RestartAltIcon />
                        </button>
                      </div>
                      <TransformComponent
                        wrapperStyle={{ maxWidth: "70vw", maxHeight: "70vh" }}
                      >
                        <img
                          src={imageUrl}
                          alt=""
                          style={{
                            rotate: `${rotateImageValue}deg`,
                            maxWidth: "70vw",
                            maxHeight: "70vh",
                            objectFit: "contain",
                          }}
                          width={isMobile ? "100%" : "auto"}
                          height={isMobile ? "200px" : "600px"}
                        />
                      </TransformComponent>
                    </>
                  )}
                </TransformWrapper>
              ) : null}
            </>
          )}
        </Box>
      </Modal>

      <Modal
        open={openDisputeDetailsModal}
        onClose={() => setOpenDisputeDetailModal(false)}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {loadingImage ? (
            <div
              style={{
                width: "450px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100px",
              }}
            >
              loading data...
            </div>
          ) : (
            <div
              style={{
                width: "450px",
                minHeight: "100%",
                wordBreak: "break-word",
              }}
            >
              <div className="mini-details-grey-text_dispute_top">
                <span className="mini-details-grey-text_dispute">
                  <span className="mini-details-grey-text_dispute_key">
                    Created On:
                  </span>
                  {attachmentsDisputeHistory.created_on
                    ? moment
                        .utc(attachmentsDisputeHistory.created_on)
                        .local()
                        .format("DD-MM-YYYY hh:mm a")
                    : "-"}
                </span>
              </div>
              <div className="mini-details-grey-text_dispute_top_remarks">
                <span className="mini-details-grey-text_dispute">
                  <div className="mini-details-grey-text_dispute_key">
                    Remark:
                  </div>
                  {attachmentsDisputeHistory.remarks
                    ? attachmentsDisputeHistory.remarks
                    : "-"}
                </span>
              </div>
              <div className="mini-details-grey-text_dispute_bottom_container">
                <span className="mini-details-grey-text_dispute">
                  <div className="mini-details-grey-text_dispute_key">
                    Trip Status:
                  </div>

                  {capitalizeLetter(
                    attachmentsDisputeHistory?.trip_status
                      ? attachmentsDisputeHistory.trip_status
                          .split("")
                          .map((item, index) => {
                            if (index == 0) {
                              return item.toUpperCase();
                            } else {
                              return item;
                            }
                          })
                          .join("")
                      : "-"
                  )}
                </span>
                <span className="mini-details-grey-text_dispute">
                  <div className="mini-details-grey-text_dispute_key_createdby">
                    Created by:
                  </div>

                  {attachmentsDisputeHistory.created_by
                    ? attachmentsDisputeHistory.created_by
                    : "-"}
                </span>
              </div>
            </div>
          )}
        </Box>
      </Modal>

      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={99999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong backButtonLink="/ftl-trips" buttonText="Close" />
        </div>
      </ModalAntd>

      <Snackbar
        open={openToast}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseToast}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          {exportToastMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openPaymentApproveRejectToast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClosePaymentToast}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleClosePaymentToast}
          severity={
            succesfulMsg
              ? succesfulMsg.includes("rejected")
                ? "error"
                : "success"
              : "success"
          }
          sx={{ width: "100%" }}
        >
          {succesfulMsg}
        </Alert>
      </Snackbar>
      <Dialog
        onClose={handleCloseFullPageLoad}
        onClick={(e) => e.stopPropagation()}
        open={openFullPageLoading}
        style={{ backgroundColor: "none" }}
        // disabled={loading}
      >
        <div
          style={{
            padding: ".5rem 1rem",
            // display: "flex",
            width: "200x",
            height: "150px",
            // flexDirection: "column",
          }}
        >
          <div>
            <img
              src={success}
              alt=""
              width="120px"
              height="90px"
              // style={{ marginTop: "200px" }}
            />
            <div style={{ color: "grey" }}>loading data...</div>
          </div>
        </div>
      </Dialog>

      {/*Filter modal starts*/}
      <StyledModal
        open={isFilterOpen}
        onClose={handleCloseFilterOptions}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={filterBoxStyle}>
          <Trip_list_filter_main className="trip-list-filter-main">
            {isMobile ? (
              <div className="trip-list-filter-head">
                <span className="trip-list-filter-main-text">
                  Select filter options
                </span>{" "}
                <Close onClick={handleCloseFilterModal} />
              </div>
            ) : (
              <div className="trip-list-filter-head">
                <span
                  className="trip-list-filter-main-text"
                  style={{ marginLeft: "5px" }}
                >
                  Select filter options
                </span>{" "}
                <Close
                  onClick={handleCloseFilterModal}
                  style={{ cursor: "pointer" }}
                />
              </div>
            )}

            <div className="trip-list-filter-container">
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Trip code</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip code"
                    className="trip-list-filter-inputfield"
                    value={filterTripCode}
                    onChange={(e) => setFilterTripCode(e.target.value)}
                    variant="standard"
                  />
                </div>
              </div>

              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Vehicle number</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    name="vehicle_number"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-filter-inputfield"
                    variant="standard"
                    value={filterVehicleNumber}
                    onChange={(e) => handleTripListAndvanceSearch(e)}
                  />
                </div>
              </div>
              {advanceSearchError.vehicle_number_error !== "" ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  {advanceSearchError.vehicle_number_error}
                </div>
              ) : null}
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Trip status</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip status"
                    variant="standard"
                    className="trip-list-filter-inputfield"
                    select
                    value={filterTripStatus}
                    onChange={(e) => setFilterTripStatus(e.target.value)}
                  >
                    {tripStatusList.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </TextField>
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Trip Type</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip status"
                    variant="standard"
                    className="trip-list-filter-inputfield"
                    select
                    value={filterTripType}
                    onChange={(e) => {
                      setFilterTripType(e.target.value);
                      setTripExportTripTypeError("");
                    }}
                  >
                    {tripTypeList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">LR Number</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    name="lr_number"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-filter-inputfield"
                    variant="standard"
                    value={filterReferenceNumber}
                    onChange={(e) => handleTripListAndvanceSearch(e)}
                  />
                </div>
              </div>
              {advanceSearchError.lr_number_error !== "" ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  {advanceSearchError.lr_number_error}
                </div>
              ) : null}
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Customer Name</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    // value={
                    //   Object.keys(selectedCustomer).length > 0
                    //     ? selectedCustomer
                    //     : ""
                    // }
                    defaultValue={
                      Object.keys(filterCustomerData)?.length > 0
                        ? filterCustomerData
                        : { name: "" }
                    }
                    onChange={(event, newValue) => {
                      // setValue(newValue);
                      if (newValue) {
                        setFilterCustomerId(newValue?.id);
                        setFilterCustomerData(newValue);
                        setCustomerSearchValue(newValue.name);
                        setCustomerList([]);
                        // if (!newValue.name.toLowerCase().includes("faarms")) {
                        //   setLoadSource("manual");
                        // }
                        //
                      } else {
                        // setSelectedCustomer({});
                      }
                      //
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        setCustomerSearchValue(newInputValue);
                      }
                    }} // sx={{ width: 300 }}
                    style={{ width: "90%" }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                  />
                  {/* <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    select
                    className="trip-list-filter-inputfield"
                    variant="standard"
                    value={filterCustomerId}
                    onChange={(e) => setFilterCustomerId(e.target.value)}
                  >
                    {customerList.length > 0
                      ? customerList.map((item) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })
                      : null}
                  </TextField> */}
                </div>
              </div>

              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">E-Way Bill No.</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    name="eway-bill"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-filter-inputfield"
                    variant="standard"
                    value={filterEwayBillNo}
                    onChange={(e) => handleTripListAndvanceSearch(e)}
                  />
                </div>
              </div>
              {advanceSearchError.eway_bill_no_error !== "" ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  {advanceSearchError.eway_bill_no_error}
                </div>
              ) : null}
              <div className="trip-list-filter-button-row">
                <button
                  className="trip-list-filter-button"
                  onClick={handleClearFilter}
                >
                  Clear
                </button>
                <button
                  className="trip-list-filter-button"
                  onClick={() => {
                    handleFilterSearch(1);
                    setCurrentPage(1);
                  }}
                  disabled={Object.values(advanceSearchError).some(
                    (item) => item.trim() !== ""
                  )}
                >
                  {loadingFilterSearchButton ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Search"
                  )}
                </button>
              </div>
            </div>
          </Trip_list_filter_main>
        </Box>
      </StyledModal>
      {/*Filter modal ends*/}

      {/*Pickup modal starts*/}
      <StyledModal
        open={isPickupOpen}
        onClose={handleClosePickupOptions}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={pickupBoxStyle}>
          <Trip_Pickup_modal
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="trip-pickup-modal-head"
                style={{ fontSize: "16px", fontWeight: "600" }}
              >
                <div> Trip Pickup </div>{" "}
                <div style={{ marginLeft: "4px" }}>
                  {" "}
                  ( Trip code : {selectedTrip?.trip_code} )
                </div>
              </div>
              <div>
                <Close
                  onClick={handleClosePickupOptions}
                  style={{ cursor: "pointer" }}
                />{" "}
              </div>
            </div>
            <TripPickup
              tripId={selectedTrip?.id}
              tripCode={selectedTrip?.trip_code}
            />
          </Trip_Pickup_modal>
        </Box>
      </StyledModal>
      {/*Pickup modal ends*/}

      {/*Trip routes modal starts*/}
      <StyledModal
        open={openTripRoutes}
        onClose={handleCloseTripRoute}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={tripRoutesBoxStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="trip-pickup-modal-head"
                style={{ fontSize: "16px", fontWeight: "600" }}
              >
                <div> Trip Routes </div>{" "}
                <div style={{ marginLeft: "4px" }}>
                  {" "}
                  ( Trip code : {selectedTrip?.trip_code} )
                </div>
              </div>
              <div>
                <Close
                  onClick={handleCloseTripRoute}
                  style={{ cursor: "pointer" }}
                />{" "}
              </div>
            </div>
            <div>
              <div className="trip-list-address">
                <div className="trip-location-list">
                  {selectedTrip.source?.map((source) => {
                    return <div className="trip-location-text">From</div>;
                  })}
                  {/* <div className="trip-location-text">From</div> */}
                  {selectedTrip.destination?.map((destinations) => {
                    return <div className="trip-location-text">To</div>;
                  })}
                </div>
                <div className="trip-list-path">
                  {/* <div className="trip-circle"></div>
                  <div className="mini-trip-rectangle"></div> */}
                  {selectedTrip.source?.map((source, index) => {
                    return (
                      <>
                        {index == 0 ? null : (
                          <>
                            <div className="trip-circle"></div>
                            <div
                              className="mini-trip-rectangle"
                              style={{
                                backgroundColor: "grey",
                                height: "40px",
                                width: "2px",
                              }}
                            ></div>
                          </>
                        )}
                      </>
                    );
                  })}
                  {selectedTrip.destination?.map((destinations) => {
                    return (
                      <>
                        <div className="trip-circle"></div>
                        <div
                          className="mini-trip-rectangle"
                          style={{
                            backgroundColor: "grey",
                            height: "40px",
                            width: "2px",
                          }}
                        ></div>
                      </>
                    );
                  })}
                  <div className="trip-circle"></div>
                </div>
                <div className="trip-location-list">
                  {/* <div className="trip-location-text"> */}
                  {selectedTrip.source?.map((source) => {
                    return (
                      <div className="trip-location-text">
                        {source.village === source.district
                          ? source.village
                          : `${source.village},
                          ${source.district}`}{" "}
                        , {source.state}, {source.postcode}
                      </div>
                    );
                  })}

                  {selectedTrip.destination?.map((destinations) => {
                    return (
                      <div className="trip-location-text">
                        {destinations.village === destinations.district
                          ? destinations.district
                          : `${destinations.village}, ${destinations.district}`}
                        , {destinations.state}, {destinations.postcode}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>
      {/*Trip routes modal ends*/}

      {/*Export report modal starts*/}
      <StyledModal
        open={isExportOpen}
        onClose={handleCloseExport}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={exportBoxStyle}>
          <div className="trip-list-export-main">
            <div
              className="trip-list-filter-head"
              style={{ margin: "5px 0px" }}
            >
              <span className="trip-list-filter-main-text">
                Trip Export Report
              </span>{" "}
              <Close
                onClick={handleCloseExport}
                style={{ cursor: "pointer" }}
              />
            </div>

            <div className="trip-list-export-container">
              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Email Id : </h4>
                  <TextField
                    id="standard-basic"
                    style={{ width: "98%" }}
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    placeholder="Enter email address"
                    className="trip-list-export-inputfield"
                    value={exportEmail}
                    onChange={(e) => setExportEmail(e.target.value)}
                  />
                  <div className="trip-list-error">
                    {validEmailRegex.test(exportEmail)
                      ? null
                      : errExportEmailId}
                  </div>
                </div>
              </div>
              <div className="trip-list-export-options-head">
                <h4
                  className="trip-list-export-text"
                  style={{ fontSize: "15px" }}
                >
                  Filter Options (Optional)
                </h4>
              </div>
              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">From Date</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    type="date"
                    // placeholder="Enter payment status"
                    className="trip-list-export-inputfield"
                    value={exportFromDate}
                    onChange={(e) => setExportFromDate(e.target.value)}
                  />
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">To Date</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    size="small"
                    type="date"
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    value={exportToDate}
                    onChange={(e) => setExportToDate(e.target.value)}
                  />
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Trip status</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    placeholder="Enter trip status"
                    variant="outlined"
                    className="trip-list-export-inputfield"
                    select
                    size="small"
                    value={exportTripStatus}
                    onChange={(e) => setExportTripStatus(e.target.value)}
                  >
                    <MenuItem disabled value={"0"}>
                      Select
                    </MenuItem>
                    {tripStatusList.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Customer</h4>{" "}
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    defaultValue={
                      Object.keys(filterCustomerData)?.length > 0
                        ? filterCustomerData
                        : { name: "" }
                    }
                    onChange={(event, newValue) => {
                      // setValue(newValue);
                      if (newValue) {
                        setExportCustomer(newValue?.name);
                        setExportCustomerSearchValue(newValue.name);
                        setCustomerList([]);
                        // if (!newValue.name.toLowerCase().includes("faarms")) {
                        //   setLoadSource("manual");
                        // }
                        //
                      } else {
                        // setSelectedCustomer({});
                      }
                      //
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        setExportCustomerSearchValue(newInputValue);
                      }
                    }} // sx={{ width: 300 }}
                    style={{ width: "90%" }}
                    // sx={{ padding: "0px" }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                  {/* <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    select
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    size="small"
                    value={exportCustomer}
                    onChange={(e) => setExportCustomer(e.target.value)}
                  >
                    <MenuItem disabled value={"0"}>
                      Select
                    </MenuItem>
                    {customerList.length > 0
                      ? customerList.map((item) => {
                          return (
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                          );
                        })
                      : null}
                  </TextField> */}
                </div>
              </div>
              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Vehicle number</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    placeholder="Enter vehicle number"
                    className="trip-list-export-inputfield"
                    value={exportVehicleNumber}
                    onChange={(e) => setExportVehicleNumber(e.target.value)}
                  />
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Transporter</h4>{" "}
                  <AsyncSelect
                    className="trip-list-export-inputfield"
                    defaultOptions
                    // key={loadSource}
                    placeholder="search..."
                    loadOptions={(inputValue) => transporterOptions(inputValue)}
                    // value={
                    //   vehicleNumber !== "0"
                    //     ? { value: vehicleNumber, label: vehicleNumber }
                    //     : ""
                    // }
                    // value={vehicleOptions.filter(
                    //   (option) => option.value === vehicleNumber
                    // )}
                    // value={purchaseOrderId}
                    // onInputChange={(newValue) =>
                    //   console.log("purchase order", newValue)
                    // }
                    // onChange={(e) => {
                    //   setVehicleNumber(e.value);
                    //   setVehiclePayoutPeriod(e.payout_period);
                    //   if (e.payout_period == "monthly_wise") {
                    //     setPerKmRate(e.extra_per_km_rate);
                    //   } else {
                    //     setPerKmRate("");
                    //   }
                    // }}
                    // loadOptions={(inputValue) =>
                    //   vehicleOptions(inputValue, loadSource)
                    // }
                  />
                  {/* <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    select
                    size="small"
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    value={exportTransporter}
                    onChange={(e) => setExportTransporter(e.target.value)}
                  >
                    <MenuItem>dnnsd</MenuItem>
                  </TextField> */}
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">
                    Vehicle payout period
                  </h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip status"
                    variant="outlined"
                    className="trip-list-export-inputfield"
                    select
                    size="small"
                    value={exportVehiclePayoutPeriod}
                    onChange={(e) =>
                      setExportVehiclePayoutPeriod(e.target.value)
                    }
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {vehiclePayoutPeriodList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                {/* <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Payment Status</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    select
                    size="small"
                    value={exportPaymentStatus}
                    onChange={(e) => setExportPaymentStatus(e.target.value)}
                  >
                    <MenuItem value="0" disabled>
                      Select
                    </MenuItem>
                    {tripPaymentStatusList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div> */}
              </div>
              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Source state</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    select
                    // placeholder="Enter payment status"
                    className="trip-list-export-inputfield"
                    value={exportSourceState}
                    onChange={(e) => setExportSourceState(e.target.value)}
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {stateList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Destination state</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    size="small"
                    select
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    value={exportDestinationState}
                    onChange={(e) => setExportDestinationState(e.target.value)}
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {stateList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Created By</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    placeholder="Enter created by"
                    variant="outlined"
                    className="trip-list-export-inputfield"
                    size="small"
                    value={exportCreatedBy}
                    onChange={(e) => setExportCreatedBy(e.target.value)}
                  />
                </div>
                {/* <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Load Source</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    size="small"
                    select
                    value={exportLoadSource}
                    onChange={(e) => setExportLoadSource(e.target.value)}
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {loadSourceList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div> */}
              </div>

              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Trip type</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="outlined"
                    disabled="true"
                    size="small"
                    select
                    // placeholder="Enter payment status"
                    className="trip-list-export-inputfield"
                    value={exportTripType}
                    onChange={(e) => setExportTripType(e.target.value)}
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {tripTypeList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container"></div>
                <div className="trip-list-export-input-container"></div>
                <div className="trip-list-export-input-container"></div>
              </div>
              <div className="trip-list-filter-button-row">
                {errTotalExport ? (
                  <Alert severity="error" style={{ width: "100%" }}>
                    {errTotalExport}
                  </Alert>
                ) : null}
              </div>
              <div className="trip-list-filter-button-row">
                {returnNumberOfDays() > 90 ? (
                  <Alert severity="error" style={{ width: "100%" }}>
                    Trips report cannot be exported beyond 90 days
                  </Alert>
                ) : null}
              </div>

              <div className="trip-list-filter-button-row">
                {tripExportTripTypeError ? (
                  <Alert severity="error" style={{ width: "100%" }}>
                    {tripExportTripTypeError}
                  </Alert>
                ) : null}
              </div>

              <div className="trip-list-filter-button-row">
                <button
                  className="trip-list-filter-button"
                  onClick={handleCloseExport}
                >
                  Cancel
                </button>
                <button
                  className="trip-list-export-button"
                  onClick={() => {
                    handleTripsExportReportApi();
                  }}
                >
                  {loadingExportButton ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Export"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>
      {/*Export report modal ends*/}

      {/* ---------------approve reject modal--------------------------------- */}
      {approveRejectModal({
        open,
        handleClose,
        style,
        tripRemarks,
        setTripRemarks,
        errTripRemarks,
        setFaarmsAndConnectIndiaMailError,
        faarmsAndConnectIndiaMailError,
        currentStatusUpdateInProgress,
        showNegativeTripFields,
        customerBilledAmount,
        handleCustomerBilledAmountAndAdvancePaidByCustomerAtStatusChange,
        negativeRevenueTripApprovalEmail,
        setNegativeRevenueTripApprovalEmail,
        isNegativeRevenueTripApprovalEmailValid,
        advancePaidByCustomer,
        isMobile,
        colorPalette,
        setNegativeRevenueTripApprovalAttachment,
        typography,
        negativeRevenueTripApprovalAttachment,
        errTripStatus,
        selectedTrip,
        isRejectButtonClicked,
        beforeHandleSubmitRemarks,
        loadingModal,
        handleSubmitRemarks,
        handleClearApproveModalFields,
        isMaxFileSizeError,
        handleAttachmentChange,
        documentTypeList,
        isApprovedIntransit,
        handleApproveStartTrip,
        teamsList,
        setTeamsSearchTerm,
        teamsSearchTerm,
      })}

      {intransitCompleteModal({
        openIntransitCompleteModal,
        handleOpenIntransitComplete,
        handleCloseIntransitComplete,
        style,
        tripRemarks,
        setTripRemarks,
        errTripRemarks,
        attachmentListArray,
        handleAttachmentChange,
        handleAddAttachment,
        documentTypeList,
        isMobile,
        handleSubmitRemarks,
        loadingModal,
        currentStatusUpdateInProgress,
        errTripStatus,
      })}

      {arrivedAtDestinationModal({
        setArrivedAtDate,
        openArrivedAtDestinationModal,
        handleCloseArrivedAtDestination,
        style,
        tripRemarks,
        setTripRemarks,
        errTripRemarks,
        currentStatusUpdateInProgress,
        arrivedAtDate,
        handleSubmitRemarks,
        loadingModal,
        errTripStatus,
        selectedTrip,
      })}

      {verifyDocumentModal({
        openVerifyDocumentModal,
        handleCloseVerifyDocumentModal,
        style,
        tripRemarks,
        setTripRemarks,
        errTripRemarks,
        currentStatusUpdateInProgress,
        handleSubmitRemarks,
        loadingModal,
        errTripStatus,
      })}

      {tripReopenModal({
        openReopenModal,
        handleCloseReopenModal,
        style,
        tripRemarks,
        setTripRemarks,
        errTripRemarks,
        currentStatusUpdateInProgress,
        handleSubmitRemarks,
        loadingModal,
        errTripStatus,
      })}

      {tripDisputeModal({
        handleCloseDistpute,
        style,
        openDisputeModal,
        handleSubmitRemarks,
        tripRemarks,
        loadingModal,
        setTripRemarks,
        errTripStatus,
        errTripRemarks,
        handleSubmitRemarksToChangeTripStatusToDisputed,
        loadingDisputeModalButton,
        errTripDisputeModal,
        setChangedStatusAfterDisputeResolve,
        changedStatusAfterDisputeResolve,
      })}

      {tripResolveModal({
        openResolveDisputeModal,
        setOpenResolveDisputeModal,
        style,
        tripRemarks,
        setTripRemarks,
        checked,
        handleCheckbox,
        handleResolveDisputeAndChangeStatus,
        loadingDisputeModalButton,
        errTripResolveDisputeModal,
        disputeResolveModalLoading,
      })}

      {tripCancleModal({
        openCancelModal,
        setOpenCancelModal,
        tripRemarks,
        setTripRemarks,
        errTripRemarks,
        style,
        isMobile,
        handleSubmitRemarks,
        loadingModal,
        currentStatusUpdateInProgress,
        errTripStatus,
      })}

      {approvedTripCancelModal({
        approvedOpenCancelModal,
        setApprovedOpenCancelModal,
        openCancelModal,
        setOpenCancelModal,
        tripRemarks,
        setTripRemarks,
        errTripRemarks,
        style,
        isMobile,
        handleSubmitRemarks,
        loadingModal,
        currentStatusUpdateInProgress,
        errTripStatus,
        setErrTripStatus,
      })}
      {/* Invoice Sent Modal starts */}
      <Modal
        open={OpenInvoiceModal}
        onClose={handleCloseInvoiceSent}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="invoice-submission-wrapper">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h3>Invoice Sent</h3>
              </div>
              <div>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={handleCloseInvoiceSent}
                />
              </div>
            </div>

            <br />
            <div className="invoice-input">
              <div>
                <span>
                  Delivery Acknowledgement Number
                  <sup style={{ color: "red" }}>*</sup>
                </span>
                <input
                  type="text"
                  style={{ width: "100%", padding: "10px", marginTop: "10px" }}
                  value={ackNumber}
                  placeholder="Please enter Acknowledgement number"
                  onChange={(e) => handleAckNum(e)}
                />
              </div>

              {Object.keys(errInvoiceAckNo).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errInvoiceAckNo[key]}</h6>
                );
              })}

              <br />
              <div>
                <span>
                  Courier Company<sup style={{ color: "red" }}>*</sup>{" "}
                </span>
                <input
                  type="text"
                  style={{ width: "100%", marginTop: "10px", padding: "10px" }}
                  value={courierCompany}
                  placeholder="Please enter Courier Company"
                  onChange={(e) => handleCourierCompany(e)}
                />
              </div>

              {Object.keys(errInvoiceCourier).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errInvoiceCourier[key]}</h6>
                );
              })}
              <br />
              <div className="">
                <span>
                  Date of Invoice Sent<sup style={{ color: "red" }}>*</sup>{" "}
                </span>
                <input
                  type="date"
                  value={date}
                  style={{ width: "100%", marginTop: "10px", padding: "10px" }}
                  onChange={(e) => handleInvoiceDate(e)}
                  max={today}
                />
              </div>

              {Object.keys(errInvoiceDate).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errInvoiceDate[key]}</h6>
                );
              })}

              <br />
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <ButtonRectangle
                  onClick={() =>
                    checkInvoiceFormValidation(
                      ackNumber,
                      courierCompany,
                      date,
                      invoiceId
                    )
                  }
                  disabled={ackNumber && date && courierCompany ? false : true}
                  style={
                    ackNumber && date && courierCompany
                      ? { cursor: "pointer" }
                      : { cursor: "not-allowed", backgroundColor: "#454545" }
                  }
                >
                  {invoiceSubmitLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </ButtonRectangle>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      {/* Invoice sent Modal ends */}

      {/* Invoice Submission Modal starts */}
      <Modal
        open={openInvoiceSubmitModal}
        onClose={handleCloseInvoiceSubmission}
      >
        <Box sx={style}>
          <div className="invoice-submission-wrapper">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h3>Submit Invoice</h3>
              </div>
              <div>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={handleCloseInvoiceSubmission}
                />
              </div>
            </div>

            <br />
            <div className="invoice-input">
              <div className="">
                <span>
                  Date of Invoice Submission
                  <sup style={{ color: "red" }}>*</sup>{" "}
                </span>
                <TextField
                  size="small"
                  autoComplete="off"
                  // className="goods-delivery-input-field"
                  variant="outlined"
                  type="date"
                  value={invoiceSubmissionDate}
                  // name="invoice_submission"
                  style={{ width: "100%", marginTop: "10px" }}
                  onChange={(e) => handleInvoiceSubDate(e)}
                  // max={today}
                />
              </div>
              {/* {Object.keys(errInvSubDate).map((key) => {
                return (
                  <h6 style={{ color: "#E06666", fontSize: "small" }}>
                    {errInvSubDate[key]}
                  </h6>
                );
              })}
              <br />

              })} */}
              {/* <br /> */}

              <div className="">
                <span>
                  Submit Invoice Acknowlegdement Attachment
                  <sup style={{ color: "red" }}>*</sup>{" "}
                </span>
                <TextField
                  size="small"
                  disabled
                  autoComplete="off"
                  style={{ width: "100%", marginTop: "10px" }}
                  variant="outlined"
                  // value={}
                  placeholder="Acknowlegdement Attachment"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          color: colorPalette.primaryColor,
                          fontWeight: "600",
                        }}
                      >
                        <button
                          className="upload_button"
                          style={{
                            width: "40px",
                            height: "40px",
                            marginRight: "-14px",
                          }}
                        >
                          <input
                            type="file"
                            id="Acknowlegdement Attachment"
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={(e) => handleInvAttachment(e)}
                            hidden
                            // value={}
                          />
                          <label htmlFor={"Acknowlegdement Attachment"}>
                            <img
                              src={UploadImage}
                              alt=""
                              width="20px"
                              height="20px"
                            />
                          </label>
                        </button>
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                <span className="file_name">
                  {invSubmissionAttachment.file.name}
                </span>
              </div>
              {Object.keys(errInvAttach).map((key) => {
                return (
                  <h6 style={{ color: "#E06666", fontSize: "small" }}>
                    {errInvAttach[key]}
                  </h6>
                );
              })}
              <div style={{ fontSize: "12px", color: "red" }}>
                {isMaxFileSizeErrorInInvoiceSubmit
                  ? `File size is too large.(< ${
                      process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                    }MB)`
                  : ""}
              </div>
              <br />
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <ButtonRectangle
                  onClick={() =>
                    handleInvoiceSubmission(
                      invoiceSubmissionDate,
                      invSubmissionAttachment.file
                    )
                  }
                  disabled={
                    disableSubmitInvoiceModalSubmitButton ||
                    isMaxFileSizeErrorInInvoiceSubmit === true
                  }
                  style={
                    !disableSubmitInvoiceModalSubmitButton &&
                    !isMaxFileSizeErrorInInvoiceSubmit
                      ? { cursor: "pointer", margin: "10px 0px" }
                      : {
                          cursor: "not-allowed",
                          background: "#454545",
                          margin: "10px 0px",
                        }
                  }
                >
                  {invoiceSubmittedLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </ButtonRectangle>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      {/* Invoice Submission Modal ends */}

      {/* Invoice Rejection Modal starts */}
      <Modal
        open={openInvoiceRejectModal}
        onClose={handleCloseInvoiceSubmission}
      >
        <Box sx={style}>
          <div className="invoice-submission-wrapper">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h3>Submit Invoice</h3>
              </div>
              <div>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={handleCloseInvoiceSubmission}
                />
              </div>
            </div>

            <br />
            <div className="invoice-input">
              <div className="">
                <span>
                  Invoice Number :
                  <span style={{ fontWeight: "500" }}>
                    {" "}
                    {selectedTrip.zoho_customer_invoice_number}
                  </span>
                </span>
              </div>
              <br />
              <div className="">
                <span>
                  Date of Invoice Rejection
                  <sup style={{ color: "red" }}>*</sup>{" "}
                </span>
                <TextField
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  type="date"
                  value={invoiceRejectionDate}
                  style={{ width: "100%", marginTop: "10px" }}
                  onChange={(e) => setInvoiceRejectionDate(e.target.value)}
                />
              </div>
              <br />
              <div className="">
                <span>
                  Rejection reason
                  <sup style={{ color: "red" }}>*</sup>{" "}
                </span>
                <br />
                <textarea
                  name="invoice-rejection"
                  id="invoice-rejection"
                  style={{ width: "100%", padding: "8px" }}
                  rows="5"
                  value={invoiceRejectionRemarks}
                  onChange={(e) => setInvoiceRejectionRemarks(e.target.value)}
                ></textarea>
              </div>

              <br />
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <ButtonRectangle
                  onClick={() =>
                    handleInvoiceRejection(
                      invoiceRejectionDate,
                      invoiceRejectionRemarks
                    )
                  }
                  disabled={!(invoiceRejectionRemarks && invoiceRejectionDate)}
                  style={
                    invoiceRejectionRemarks && invoiceRejectionDate
                      ? { cursor: "pointer", margin: "10px 0px" }
                      : {
                          cursor: "not-allowed",
                          background: "#454545",
                          margin: "10px 0px",
                        }
                  }
                >
                  {invoiceSubmittedLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Submit"
                  )}
                </ButtonRectangle>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      {/* Invoice Rejection Modal ends */}

      {/*Manual Invoice Generation Modal start */}
      <Modal
        open={openInvoiceGenerationModal}
        onClose={handleCloseInvoiceGeneration}
      >
        <Box sx={Invstyle}>
          <div className="invoice-submission-wrapper">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h3>Invoice Generation</h3>
              </div>
              <div>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={handleCloseInvoiceGeneration}
                />
              </div>
            </div>

            <br />

            <div className="">
              <span>
                Zoho Customer Invoice Number
                <sup style={{ color: "red" }}>*</sup>{" "}
              </span>
              <Autocomplete
                disableClearable
                disablePortal
                options={zohoInvGenerationOption}
                style={{ width: "100%", marginTop: "10px" }}
                onBlur={() => {
                  setzohoInvGenerationOption([]);
                }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setZohoInvoiceNumberSearch(newValue.invoice_number);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setZohoInvoiceNumberSearch(e.target.value);
                    }}
                    value={zohoInvoiceNumberSearch}
                    placeholder="Search zoho customer invoice number"
                  />
                )}
                getOptionLabel={(option) => option.invoice_number}
              />
            </div>
            {Object.keys(errZohoCustomerInvoiceNumber).map((key) => {
              return (
                <h6 style={{ color: "#E06666", fontSize: "small" }}>
                  {errZohoCustomerInvoiceNumber[key]}
                </h6>
              );
            })}
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ButtonRectangle
                disabled={zohoInvoiceNumberSearch == "" ? true : false}
                style={
                  zohoInvoiceNumberSearch == ""
                    ? { cursor: "not-allowed", backgroundColor: "#454545" }
                    : { cursor: "pointer" }
                }
                onClick={() => checkInvGenValidation()}
              >
                {loadingGenerateInvoice ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Submit"
                )}
              </ButtonRectangle>
              {unauthorizeError ? (
                <span
                  style={{
                    padding: "8px",
                    borderRadius: "4px",
                    fontSize: "12px",
                    backgroundColor: colorPalette.secondaryColor,
                  }}
                >
                  {unauthorizeError}
                </span>
              ) : null}
            </div>
          </div>
        </Box>
      </Modal>
      {/*Manual Invoice Generation Modal end */}

      {/* =============================new details design=================================================== */}
      <Drawer
        anchor={"right"}
        open={stateLog["right"]}
        onClose={() => {
          setPincodeDetails({ district: "", state: "" });
          toggleDrawerLog("right", false);
          setPincode("");
          setErrorLogTime("");
          setErrorDestinationTime("");
          setErrorIncidentType("");

          setErrorPincode("");
          setErrorStreet("");
          setErrorRemarks("");
          setIncidentType("");
          setChecked(false);
          setTripLogs({
            trip_id: selectedTrip.id,
            time_of_log: "",
            expected_time_to_destination: "",
            street_address: "",
            remarks: "",
            state: "",
            district: "",
          });
          // setTripLogs({ ...tripLogs, trip_id: selectedTrip.id });
        }}
      >
        <Trip_list_drawer_top_row
          className="trip-list-drawer-top-row"
          style={{ width: isMobile ? "" : "600px" }}
          mode={darkMode}
        >
          <div className="details_drawer_topbar_log">
            <div style={{ color: "green", fontWeight: "bold" }}>Create Log</div>
            <Close
              onClick={handleCloseDetailsLog}
              style={{
                color: darkMode ? colorPaletteDark.white : colorPalette.black,
                cursor: "pointer",
              }}
            />
            {/* Trip details */}
            {/* </span> */}
          </div>
          <div className="create_log_container">
            {selectedTrip.trip_status === "intransit" && (
              <>
                <div className="log_dates">
                  <div className="log_inputs_row_dates">
                    {" "}
                    <div>
                      Enter Date and Time of Log{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </div>
                    <Space direction="vertical" size={12}>
                      <DatePicker
                        className="log_input_fields_date"
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        // value={dayjs(tripLogs.time_of_log)}
                        showTime={{
                          defaultValue: moment("00:00:00", "HH:mm:ss"),
                          format: "HH:mm",
                        }}
                        onChange={onChange}
                        onOk={onOk}
                      />
                    </Space>
                    {Object.keys(errorLogTime).map((key) => {
                      return (
                        <div
                          style={{
                            color: "#E06666",
                            fontSize: "10px",
                            marginTop: "2px",
                          }}
                        >
                          {errorLogTime[key]}
                        </div>
                      );
                    })}
                  </div>
                  <div className="log_inputs_row_dates">
                    {" "}
                    <div>
                      Expected Time To Destination{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </div>
                    <Space direction="vertical" size={12}>
                      <DatePicker
                        className="log_input_fields_date"
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                        showTime={{
                          defaultValue: moment("00:00:00", "HH:mm:ss"),
                          format: "HH:mm",
                        }}
                        // value={tripLogs.expected_time_to_destination}
                        onChange={onChangeDestination}
                        onOk={onOkDestination}
                      />
                    </Space>
                    {Object.keys(errorDestinationTime).map((key) => {
                      return (
                        <div
                          style={{
                            color: "#E06666",
                            fontSize: "10px",
                            marginTop: "2px",
                          }}
                        >
                          {errorDestinationTime[key]}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="log_dates">
                  <div
                    className="log_inputs_row_dates"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div style={{}}>Is Incident Reported ?</div>
                    {/* <span>Is Incident Reported ?</span>{" "} */}
                    {/* <Checkbox onChange={onChange} />{" "}
                     */}
                    <div>
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckbox}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>
                  </div>
                  <div className="log_inputs_row_dates">
                    <div>Select Incident Type</div>
                    <TextField
                      style={{ height: "35px", width: "100%" }}
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      select
                      disabled={checked ? false : true}
                      value={incidentType}
                      onChange={(e) => {
                        setIncidentType(e.target.value);
                        setErrorIncidentType("");
                      }}
                      color="success"
                      size="small"
                      // onChange={handleChange}
                      // input={<OutlinedInput label="Name" />}
                      // MenuProps={MenuProps}
                    >
                      {triLogsIncidentTypeList?.map((name) => (
                        <MenuItem key={name} value={name}>
                          {capitalizeLetter(name)}
                        </MenuItem>
                      ))}
                    </TextField>
                    {Object.keys(errorIncidentType).map((key) => {
                      return (
                        <div
                          style={{
                            color: "#E06666",
                            fontSize: "10px",
                            marginTop: "2px",
                          }}
                        >
                          {errorIncidentType[key]}
                        </div>
                      );
                    })}
                  </div>
                </div>
                <Pincode
                  parentStyle={{ display: "flex", flexDirection: "column" }}
                  // handlePincode={handlePincodeDestination}
                  // pincodeError={errorAddressPincode}
                  inputDesign={{ marginTop: 25, width: "100%" }}
                  lastPincode={pincode}
                  inputWidth={""}
                />
                {/* <div className="log_dates">
                  <div className="log_inputs_row_dates">
                    {" "}
                    <div>
                      Pincode <sup style={{ color: "red" }}>*</sup>
                    </div>
                    <Input
                      // min={7}
                      // // max={10}
                      // onWheel={(e) => e.target.blur()}
                      className="log_input_fields_date"
                      placeholder="Enter Pincode"
                      type="number"
                      value={pincode}
                      count={{
                        show: true,
                        max: 7,
                      }}
                      onChange={
                        (e) => {
                          if (e.target.value.length <= 6) {
                            setErrorPincode("");
                            setPincode(e.target.value);
                            // setTripLogs({ ...tripLogs, pincode: e.target.value });
                            if (e.target.value.length == 6) {
                              handleGetAddressFromPincode(e.target.value);
                            }
                          }
                        }
                        // // if (e.target.value.length <= 6) {
                        //   if (e.target.value.length == 6) {

                        //   }
                        // }

                        // if (e.target.value.length <= 6) {
                        // if (e.target.value.length === 6) {

                        // }
                        // setNotServiceableError("");
                        // }

                        // });
                      }
                      onKeyPress={(event) => {
                        if (!/^[0-9.]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {Object.keys(errorPincode).map((key) => {
                      return (
                        <div
                          style={{
                            color: "#E06666",
                            fontSize: "10px",
                            marginTop: "2px",
                          }}
                        >
                          {errorPincode[key]}
                        </div>
                      );
                    })}
                  </div>
                  <div className="log_inputs_row_dates">
                    {" "}
                    <div>
                      District <sup style={{ color: "red" }}>*</sup>
                    </div>
                    <Input
                      className="log_input_fields_date"
                      placeholder="Enter District"
                      disabled
                      value={tripLogs.district}
                    />
                  </div>
                </div> */}
                <div className="log_dates">
                  <div className="log_inputs_row_dates">
                    {" "}
                    <div>Street Address</div>
                    <Input
                      className="log_input_fields_date"
                      placeholder="Enter Street address"
                      value={tripLogs.street_address}
                      onChange={(e) => {
                        setErrorStreet("");
                        setTripLogs({
                          ...tripLogs,
                          street_address: e.target.value,
                        });
                      }}
                    />
                    {Object.keys(errorStreet).map((key) => {
                      return (
                        <div
                          style={{
                            color: "#E06666",
                            fontSize: "10px",
                            marginTop: "2px",
                          }}
                        >
                          {errorStreet[key]}
                        </div>
                      );
                    })}
                  </div>
                  <div className="log_inputs_row_dates">
                    {" "}
                    <div>
                      State <sup style={{ color: "red" }}>*</sup>
                    </div>
                    <Input
                      className="log_input_fields_date"
                      placeholder="Enter State"
                      disabled
                      value={tripLogs.state}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="log_inputs_row">
              {" "}
              <div>
                Remarks <sup style={{ color: "red" }}>*</sup>
              </div>
              <TextArea
                className="log_input_fields"
                rows={6}
                placeholder="Enter yout Remarks"
                value={tripLogs.remarks}
                rules={[
                  {
                    required: true,
                    message: "The name is required.",
                  },
                  {
                    message: "this is custom",
                    validator: (_, value) => {
                      if (/^[a-zA-Z0-9]+$/.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
                onChange={(e) => {
                  if (e.target.value.length <= 200) {
                    setErrorRemarks("");
                    setTripLogs({
                      ...tripLogs,
                      remarks: e.target.value,
                    });
                  } else {
                    setErrorRemarks({
                      ...errorRemarks,
                      errorRemark: "Max remark length: 200 characters.",
                    });
                  }
                }}
                // maxLength={6}
              />
              {Object.keys(errorRemarks).map((key) => {
                return (
                  <div
                    style={{
                      color: "#E06666",
                      fontSize: "10px",
                      marginTop: "2px",
                    }}
                  >
                    {errorRemarks[key]}
                  </div>
                );
              })}
            </div>
            <div className="log_inputs_row">
              <ButtonRectangle
                // style={{ margin: "10px 0px" }}
                onClick={createLogs}
                disabled={loadingLogsButton || disableCreateLogSubmitButton}
                style={
                  !disableCreateLogSubmitButton
                    ? { cursor: "pointer", margin: "10px 0px" }
                    : {
                        cursor: "not-allowed",
                        background: "#454545",
                        margin: "10px 0px",
                      }
                }
              >
                {loadingLogsButton ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Submit"
                )}
              </ButtonRectangle>
            </div>
          </div>
        </Trip_list_drawer_top_row>
      </Drawer>

      {/* =======================transporter payment history ========================= */}
      <Drawer
        anchor={"right"}
        open={stateTripPaymentHistory["right"]}
        onClose={() => {
          toggleDrawerTransporterPaymentHistory("right", false);
          setTransporterPaymentHistory({
            amount_paid: "",
            remarks: "",
            image: "",
            utr_reference_number: "",
          });
        }}
      >
        <Trip_list_drawer_top_row
          className="trip-list-drawer-top-row"
          style={{ width: isMobile ? "" : "600px" }}
          mode={darkMode}
        >
          <div className="details_drawer_topbar_log">
            <div style={{ color: "green", fontWeight: "bold" }}>
              Transporter Payment History
            </div>
            <Close
              onClick={handleCloseTripPaymentHistory}
              style={{
                color: darkMode ? colorPaletteDark.white : colorPalette.black,
                cursor: "pointer",
              }}
            />
          </div>
          <div className="create_log_container">
            <div className="log_dates">
              <div className="log_inputs_row_dates">
                <div>
                  UTR Reference Number<sup style={{ color: "red" }}>*</sup>
                </div>

                <Input
                  className="log_input_fields_date"
                  placeholder="Enter UTR Reference Number"
                  value={transporterPaymentHistory.utr_reference_number}
                  type="text"
                  onChange={(e) => {
                    setErrorStreet("");
                    setTransporterPaymentHistory({
                      ...transporterPaymentHistory,
                      utr_reference_number: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="log_inputs_row_dates">
                {" "}
                <div>
                  Amount Paid <sup style={{ color: "red" }}>*</sup>
                </div>
                <Input
                  className="log_input_fields_date"
                  placeholder="Enter Amount"
                  type="number"
                  value={transporterPaymentHistory.amount_paid}
                  onChange={(e) => {
                    setErrorStreet("");
                    setTransporterPaymentHistory({
                      ...transporterPaymentHistory,
                      amount_paid: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="log_dates">
              <div className="log_inputs_row_dates" style={{ width: "100%" }}>
                <div>
                  Attachment <sup style={{ color: "red" }}>*</sup>
                </div>

                <Input
                  className="log_input_fields_date"
                  placeholder="Upload Attachment"
                  disabled
                  suffix={
                    <>
                      <input
                        type="file"
                        id="transporter_payment_attachment"
                        style={{ display: "none" }}
                        onChange={async (e) => {
                          setErrorStreet("");
                          const newFile = await saveBlob(e.target.files[0]);
                          setTransporterPaymentHistory({
                            ...transporterPaymentHistory,
                            image: newFile,
                          });
                        }}
                      />
                      <label
                        for="transporter_payment_attachment"
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FileUpload
                          style={{
                            fontSize: 26,
                            color: "#1677ff",
                            cursor: "pointer",
                          }}
                        />
                      </label>
                    </>
                  }
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    {transporterPaymentHistory.image
                      ? transporterPaymentHistory.image.name
                      : null}
                  </span>

                  <span
                    style={{
                      fontSize: "12px",
                      color: "red",
                    }}
                  >
                    {transporterPaymentHistory.image.size >
                    process.env.REACT_APP_MAX_FILE_SIZE
                      ? `File size is too large.(< ${
                          process.env.REACT_APP_MAX_FILE_SIZE / 1000000
                        }MB)`
                      : null}
                  </span>
                </div>
                {/* {Object.keys(errorStreet).map((key) => {
                  return (
                    <div
                      style={{
                        color: "#E06666",
                        fontSize: "10px",
                        marginTop: "2px",
                      }}
                    >
                      {errorStreet[key]}
                    </div>
                  );
                })} */}
              </div>
            </div>
            <div className="log_dates">
              <div className="log_inputs_row_dates" style={{ width: "100%" }}>
                {" "}
                <div>
                  Remarks <sup style={{ color: "red" }}>*</sup>
                </div>
                <TextArea
                  className="log_input_fields"
                  rows={6}
                  minLength={10}
                  placeholder="Enter your Remarks"
                  value={transporterPaymentHistory.remarks}
                  rules={[
                    {
                      required: true,
                      message: "Remarks is required.",
                    },
                    {
                      message: "this is custom",
                      validator: (_, value) => {
                        if (/^[a-zA-Z0-9]+$/.test(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject("Some message here");
                        }
                      },
                    },
                  ]}
                  onChange={(e) => {
                    setErrorStreet("");
                    setTransporterPaymentHistory({
                      ...transporterPaymentHistory,
                      remarks: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div
              className="log_inputs_row"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "100px",
              }}
            >
              <ButtonRectangle
                // style={{ margin: "10px 0px" }}
                onClick={createHistory}
                disabled={
                  loadingLogsButton ||
                  disableHistorySaveButton ||
                  transporterPaymentHistory.image.size >
                    process.env.REACT_APP_MAX_FILE_SIZE
                }
                style={
                  !disableHistorySaveButton &&
                  transporterPaymentHistory.image.size <
                    process.env.REACT_APP_MAX_FILE_SIZE
                    ? { cursor: "pointer", margin: "10px 0px" }
                    : {
                        cursor: "not-allowed",
                        background: "#454545",
                        margin: "10px 0px",
                      }
                }
              >
                {loadingLogsButton ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Submit"
                )}
              </ButtonRectangle>
            </div>
          </div>
        </Trip_list_drawer_top_row>
      </Drawer>

      {/* =======================transporter payment history ========================= */}

      <Drawer
        anchor={"right"}
        open={state["right"]}
        // style={{ height: "100vh" }}
        onClose={() => {
          toggleDrawer("right", false);
          setLoadingPaymentApprove(false);
          setLoadingPaymentRejectedApprove(false);
          setPaymentstatusError("");
          setPaymentRemarks("");
          setTripPaymentHistory([]);
          setEwayBillError("");
          setEwayBillAttachmentError("");
          setUpdatedEwayBillDetails({
            remarks: "",
            eway_bill_number: "",
            eway_bill_valid_from: "",
            eway_bill_valid_till: "",
            trip_status: "",
            eway_bill_attachment: "",
            eway_bill_attachment_tag: "",
            eway_bill_attachment_type: "",
          });
          setSecondaryDriverDetailsInput({
            first_name: "",
            last_name: "",
            mobile_number: "",
            pan_number: "",
            driving_licence_number: "",
            alternate_mobile_number: "",
          });
          setSecondaryDriverError("");
          setSecondaryDriverId("");
          setSecondaryDriverDetails("");
          setIsSecondaryDriverEditMode(false);
        }}
      >
        <div style={{ width: "100vw", display: "flex", overflow: "hidden" }}>
          {!isMobile && (
            <div style={{ width: "68%" }}>
              <MultiTripInvoice
                tripListData={allTripList}
                selectedTrip={selectedTrip}
                setSelectedTrip={setSelectedTrip}
                selectedTripStatusText={selectedTripStatusText}
                handleTripDetails={handleTripDetails}
                handleInvoiceClick={handleInvoiceClick}
                anchorEl={anchorEl}
                openInvoice={openInvoice}
                handleInvoiceClose={handleInvoiceClose}
                handleOpenInvoiceGeneration={handleOpenInvoiceGeneration}
                handleAutomaticInvoiceGeneration={
                  handleAutomaticInvoiceGeneration
                }
                invoiceAutomaticGenLoading={invoiceAutomaticGenLoading}
                customerListFromProps={customerList}
                handleFilterSearch={handleFilterSearch}
              />
            </div>
          )}

          <Trip_list_drawer_top_row
            className="trip-list-drawer-top-row"
            style={{ width: isMobile ? "" : "32%" }}
            mode={darkMode}
          >
            <div className="details_drawer_topbar">
              <div style={{ display: "flex", alignItems: "center" }}>
                {selectedTrip?.negative_revenue_trip_approval_email !== null ? (
                  <Tooltip
                    placement="bottomLeft"
                    title={"Negative revenue trip"}
                    arrow={true}
                    color="#FF7B7B"
                    zIndex={999999}
                  >
                    <img
                      src={negetiveGraph}
                      alt="negative trip"
                      style={{ marginRight: "10px" }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    placement="bottomLeft"
                    title={"Positive revenue trip"}
                    arrow={true}
                    zIndex={999999}
                    color="#18a762"
                  >
                    <img
                      src={positiveGraph}
                      alt="Positive trip"
                      style={{ marginRight: "10px" }}
                    />
                  </Tooltip>
                )}
                <span className="trip-list-trip-details-drawer-head-v2">
                  {tripDetailsHeading}{" "}
                  <Popover
                    placement="bottom"
                    title={"People"}
                    content={
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <div style={{ fontWeight: "500" }}>
                            Operations SPOC
                          </div>
                          <div style={{ color: "blue" }}>
                            -{" "}
                            {
                              selectedTrip?.trip_additional_data
                                ?.operations_spoc
                            }
                          </div>
                        </div>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            gap: "5px",
                          }}
                        >
                          <div style={{ fontWeight: "500" }}>POD Receiver</div>
                          <div
                            style={{
                              color: "blue",
                            }}
                          >
                            -{" "}
                            {
                              selectedTrip?.trip_additional_data
                                ?.name_of_person_receiving_pod
                            }
                            <br />-{" "}
                            {
                              selectedTrip?.trip_additional_data
                                ?.email_of_person_receiving_pod
                            }{" "}
                            <br />-{" "}
                            {
                              selectedTrip?.trip_additional_data
                                ?.mobile_number_of_person_receiving_pod
                            }
                          </div>
                        </div>{" "}
                      </>
                    }
                    zIndex={99999}
                    trigger={"click"}
                  >
                    <span style={{ cursor: "pointer" }}>
                      <PeopleAlt />
                    </span>
                  </Popover>
                </span>
              </div>
              <Close
                onClick={handleCloseDetailsDrawer}
                style={{
                  color: darkMode ? colorPaletteDark.white : colorPalette.black,
                }}
              />
            </div>
            <div className="tl-new-right_drawer">
              {selectedTrip.trip_status == "created" ? (
                <div className="trip-list-right-most-single-container_drawer flex-start">
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangle
                      // className="trip-list-approve-btn"
                      onClick={(e) => {
                        handleApproveTrip(selectedTrip, e);
                        setIsApprovedIntransit(false);
                      }}
                    >
                      <CheckCircleOutlineIcon
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      Approve
                    </ButtonRectangle>
                  </div>
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangle
                      // className="trip-list-approve-btn"
                      onClick={(e) => {
                        handleApproveTrip(selectedTrip, e, "intransit");
                        setIsApprovedIntransit(true);
                      }}
                    >
                      <CheckCircleOutlineIcon
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      Approve & Start
                    </ButtonRectangle>
                  </div>
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      // className="trip-list-reject-btn"
                      onClick={(e) => handleRejectTrip(selectedTrip, e)}
                    >
                      <CancelIcon
                        style={{
                          fontSize: "16px",
                          color: "#262626",
                          marginRight: "5px",
                        }}
                      />
                      Reject
                    </ButtonRectangleSecondary>
                  </div>
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      className="trip-list-edit-btn_v2"
                      onClick={() => handleEditTrip(selectedTrip)}
                      // onClick={(e) => handleApproveTrip(selectedTrip, e)}
                    >
                      <Edit style={{ fontSize: "16px", marginRight: "5px" }} />
                      Edit
                    </ButtonRectangleSecondary>
                  </div>
                </div>
              ) : (selectedTrip.trip_status == "created" ||
                  selectedTrip.trip_status == "reopen") &&
                userRole == "Logistics Incharge" ? (
                <div style={{ display: "flex" }} className="trip-list-waiting">
                  <h5 style={{ color: "grey" }}>waiting for the approval</h5>
                </div>
              ) : selectedTrip.trip_status == "rejected" ? (
                <div className="trip-list-right-most-single-container_drawer">
                  <div className="trip-list-right-most-btn-container">
                    {/* <ButtonRectangle
                    className="trip-list-approve-btn_v2"
                    onClick={(e) => handleReopenTrip(selectedTrip, e)}
                  >
                    <RestartAltIcon style={{ fontSize: "16px" }} />
                    Reopen
                  </ButtonRectangle> */}
                    {/* <button
                    className="trip-list-reject-btn_v2"
                    onClick={(e) => handleCancelTrip(selectedTrip, e)}
                  >
                    <CancelIcon
                      style={{
                        fontSize: "16px",
                        color: "#262626",
                        marginRight: "5px",
                      }}
                    />
                    Cancel
                  </button> */}
                    {/* <button
                    onClick={() => handleEditTrip(selectedTrip)}
                    className="trip-list-edit-btn_v2"
                    // onClick={(e) => handleApproveTrip(selectedTrip, e)}
                  >
                    <Edit style={{ fontSize: "16px" }} />
                    Edit
                  </button> */}
                  </div>
                  <div className="trip-list-right-most-btn-container"></div>
                  {/* <div
                  onClick={() => handleEditTrip(selectedTrip)}
                  className="trip-list-right-most-edit_drawer"
                >
                  <Edit />
                </div> */}
                  {/* <div className="trip-list-right-most-btn-container">
                  <ButtonRectangle
                    // className="trip-list-approve-btn"
                    onClick={(e) => handleReopenTrip(selectedTrip, e)}
                  >
                    Reopen
                  </ButtonRectangle>
                </div> */}

                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      className="trip-list-edit-btn_v2"
                      onClick={() => handleEditTrip(selectedTrip)}
                      // onClick={(e) => handleApproveTrip(selectedTrip, e)}
                    >
                      <Edit style={{ fontSize: "16px", marginRight: "5px" }} />
                      Resolve
                    </ButtonRectangleSecondary>
                  </div>
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      // className="trip-list-reject-btn"
                      onClick={(e) => handleCancelTrip(selectedTrip, e)}
                    >
                      Cancel
                    </ButtonRectangleSecondary>
                  </div>
                </div>
              ) : selectedTrip.trip_status == "reopen" ? (
                <div className="trip-list-right-most-single-container_drawer">
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangle
                      // className="trip-list-approve-btn"
                      onClick={(e) => handleApproveTrip(selectedTrip, e)}
                    >
                      <CheckCircleOutlineIcon
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      Approve
                    </ButtonRectangle>
                  </div>
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      // className="trip-list-approve-btn"
                      onClick={() => handleEditTrip(selectedTrip)}
                    >
                      <Edit style={{ fontSize: "16px", marginRight: "5px" }} />
                      Edit
                    </ButtonRectangleSecondary>
                    {/* <ButtonRectangleSecondary
                    onClick={() => handleEditTrip(selectedTrip)}
                    className="trip-list-right-most-edit_drawer"
                  >
                    <Edit />
                  </ButtonRectangleSecondary> */}
                  </div>
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      // className="trip-list-reject-btn"
                      onClick={(e) => handleRejectTrip(selectedTrip, e)}
                    >
                      <CancelIcon
                        style={{
                          fontSize: "16px",
                          color: "#262626",
                          marginRight: "5px",
                        }}
                      />
                      Reject
                    </ButtonRectangleSecondary>
                  </div>
                </div>
              ) : selectedTrip.trip_status == "approved" ? (
                <div className="trip-list-right-most-single-container_drawer">
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangle
                      // className="trip-list-approve-btn"
                      onClick={(e) => {
                        handleStartTrip(selectedTrip, e);
                        setIsApprovedIntransit(false);
                      }}
                    >
                      <PlayCircleOutlineIcon
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      Start
                    </ButtonRectangle>
                  </div>
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      onClick={(e) => handleTripCancel(selectedTrip, e)}
                    >
                      <CancelIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "5px",
                        }}
                      />
                      Cancel
                    </ButtonRectangleSecondary>
                  </div>
                  {selectedTrip.load_source === "trip_invoice" &&
                  (selectedTrip?.trip_entity_mapping_details?.length > 0
                    ? selectedTrip?.trip_entity_mapping_details
                        .map((item) => item.status === "picked")
                        .indexOf(false) >= 0
                    : false) ? (
                    <div className="trip-list-right-most-btn-container">
                      <ButtonRectangleSecondary
                        // className="trip-list-reject-btn"
                        onClick={(e) =>
                          handlePickupTrip(e, selectedTrip, selectedTripIndex)
                        }
                      >
                        <LocalShippingOutlinedIcon
                          style={{ fontSize: "16px", marginRight: "5px" }}
                        />
                        Pickup
                      </ButtonRectangleSecondary>
                    </div>
                  ) : null}
                </div>
              ) : selectedTrip.trip_status == "intransit" ? (
                <div className="trip-list-right-most-single-container_drawer">
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangle
                      // className="trip-list-approve-btn"
                      onClick={() => handleArrivedAtDestination(selectedTrip)}
                    >
                      <AssignmentTurnedInIcon
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      Arrived at Destination
                    </ButtonRectangle>
                  </div>
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      onClick={(e) => handleTripCancel(selectedTrip, e)}
                    >
                      <CancelIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "5px",
                        }}
                      />
                      Cancel
                    </ButtonRectangleSecondary>
                  </div>
                  {selectedTrip.load_source === "trip_invoice" &&
                  selectedTrip?.pending_reference_numbers?.length > 0 ? (
                    <div className="trip-list-right-most-btn-container">
                      <ButtonRectangleSecondary
                        className="trip-list-approve-btn_v2"
                        onClick={() =>
                          handleDeliveryInvoicePayment(selectedTrip)
                        }
                      >
                        Delivery
                      </ButtonRectangleSecondary>
                    </div>
                  ) : null}
                  {selectedTrip.load_source === "trip_invoice" &&
                  (selectedTrip?.trip_entity_mapping_details?.length > 0
                    ? selectedTrip?.trip_entity_mapping_details
                        .map((item) => item.status === "picked")
                        .indexOf(false) >= 0
                    : false) ? (
                    <div className="trip-list-right-most-btn-container">
                      <ButtonRectangleSecondary
                        // className="trip-list-reject-btn"
                        onClick={(e) =>
                          handlePickupTrip(e, selectedTrip, selectedTripIndex)
                        }
                      >
                        <LocalShippingOutlinedIcon
                          style={{ fontSize: "16px", marginRight: "5px" }}
                        />
                        Pickup
                      </ButtonRectangleSecondary>
                    </div>
                  ) : null}
                </div>
              ) : selectedTrip.trip_status == "arrived_at_destination" ? (
                <div className="trip-list-right-most-single-container_drawer">
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangle
                      // className="trip-list-approve-btn"
                      onClick={() => handleGoodsDelivered(selectedTrip)}
                    >
                      <AssignmentTurnedInIcon
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      Goods Delivered
                    </ButtonRectangle>
                  </div>
                  {selectedTrip.load_source === "trip_invoice" &&
                  selectedTrip?.pending_reference_numbers?.length > 0 ? (
                    <div className="trip-list-right-most-btn-container">
                      <ButtonRectangleSecondary
                        className="trip-list-approve-btn_v2"
                        onClick={() =>
                          handleDeliveryInvoicePayment(selectedTrip)
                        }
                      >
                        Delivery
                      </ButtonRectangleSecondary>
                    </div>
                  ) : null}
                  {selectedTrip.load_source === "trip_invoice" &&
                  (selectedTrip?.trip_entity_mapping_details?.length > 0
                    ? selectedTrip?.trip_entity_mapping_details
                        .map((item) => item.status === "picked")
                        .indexOf(false) >= 0
                    : false) ? (
                    <div className="trip-list-right-most-btn-container">
                      <ButtonRectangleSecondary
                        // className="trip-list-reject-btn"
                        onClick={(e) =>
                          handlePickupTrip(e, selectedTrip, selectedTripIndex)
                        }
                      >
                        <LocalShippingOutlinedIcon
                          style={{ fontSize: "16px", marginRight: "5px" }}
                        />
                        Pickup
                      </ButtonRectangleSecondary>
                    </div>
                  ) : null}
                </div>
              ) : selectedTrip.trip_status == "goods_delivered" ? (
                <div className="trip-list-right-most-single-container_drawer">
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangle
                      className="trip-list-complete-btn_v2"
                      onClick={() => handleDocumentVarified(selectedTrip)}
                    >
                      <AssignmentTurnedInIcon
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      Mark Documents Verified
                    </ButtonRectangle>
                  </div>
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      className="trip-list-complete-btn_v2"
                      onClick={() => handleTripDispute(selectedTrip)}
                      style={{ padding: "3px" }}
                    >
                      <PanToolIcon
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      Raise Dispute
                    </ButtonRectangleSecondary>
                  </div>
                </div>
              ) : selectedTrip.trip_status == "documents_verified" ? (
                <div className="trip-list-right-most-single-container_drawer">
                  {selectedTrip.zoho_customer_invoice_status ===
                  "invoice_generated" ? (
                    <div className="trip-list-right-most-btn-container">
                      <ButtonRectangleSecondary
                        className="trip-list-complete-btn_v2"
                        onClick={() => handleOpenInvoiceSent(selectedTrip.id)}
                        style={{ paddingLeft: "10px", paddingRight: "10px" }}
                      >
                        Sent Invoice
                      </ButtonRectangleSecondary>
                    </div>
                  ) : null}

                  {selectedTrip.zoho_customer_invoice_status ===
                  "invoice_sent" ? (
                    <>
                      <div className="trip-list-right-most-btn-container">
                        <ButtonRectangleSecondary
                          className="trip-list-complete-btn_v2"
                          onClick={() =>
                            handleOpenInvoiceSubmission(selectedTrip.id)
                          }
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          Submit Invoice
                        </ButtonRectangleSecondary>
                      </div>
                      <div className="trip-list-right-most-btn-container">
                        <ButtonRectangleSecondary
                          className="trip-list-complete-btn_v2"
                          onClick={
                            () => handleOpenInvoiceRejectionModal(selectedTrip)
                            // handleOpenInvoiceSubmission(selectedTrip.id)
                          }
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          Reject Invoice
                        </ButtonRectangleSecondary>
                      </div>
                    </>
                  ) : null}
                  {selectedTrip.zoho_customer_invoice_status ===
                    "invoice_generated" ||
                  selectedTrip.zoho_customer_invoice_status ===
                    "invoice_sent" ? (
                    <>
                      <div className="trip-list-right-most-btn-container">
                        <ButtonRectangle
                          className="trip-list-complete-btn_v2"
                          onClick={() => handleOpenInvoiceGeneration()}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          Re-Attach Invoice
                        </ButtonRectangle>
                      </div>
                    </>
                  ) : selectedTrip.zoho_customer_invoice_status !==
                      "invoice_submitted" && isMobile ? (
                    <div className="trip-list-right-most-btn-container">
                      <div>
                        <ButtonRectangleSecondary
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleInvoiceClick}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          Generate Invoice
                        </ButtonRectangleSecondary>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openInvoice}
                          onClose={handleInvoiceClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem onClick={handleOpenInvoiceGeneration}>
                            Manual{" "}
                          </MenuItem>
                          <MenuItem
                            onClick={handleAutomaticInvoiceGeneration}
                            style={{ justifyContent: "center" }}
                          >
                            {invoiceAutomaticGenLoading ? (
                              <>
                                <CircularProgress size={20} />
                              </>
                            ) : (
                              "Automatic"
                            )}
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                  ) : null}

                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangle
                      className="trip-list-complete-btn_v2"
                      onClick={() => handlePodRecieved(selectedTrip)}
                    >
                      <AssignmentTurnedInIcon
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      Mark H/c POD Received
                    </ButtonRectangle>
                  </div>
                </div>
              ) : selectedTrip.trip_status == "trip_disputed" ? (
                <div className="trip-list-right-most-single-container_drawer">
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangle
                      className="trip-list-complete-btn_v2"
                      onClick={() => handleResolveTripDispute(selectedTrip)}
                      style={{ padding: "3px" }}
                    >
                      <ApprovalIcon
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      Resolve Dispute
                    </ButtonRectangle>
                  </div>
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      onClick={(e) => handleTripCancel(selectedTrip, e)}
                    >
                      <CancelIcon
                        style={{
                          fontSize: "16px",
                          marginRight: "5px",
                        }}
                      />
                      Cancel
                    </ButtonRectangleSecondary>
                  </div>
                </div>
              ) : selectedTrip.trip_status == "hardcopy_pod_received" &&
                selectedTrip.zoho_customer_invoice_status == "not_invoice" &&
                isMobile ? (
                <div className="trip-list-right-most-single-container_drawer">
                  <div className="trip-list-right-most-btn-container">
                    <div>
                      <ButtonRectangleSecondary
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleInvoiceClick}
                        style={{ paddingLeft: "10px", paddingRight: "10px" }}
                      >
                        Generate Invoice
                      </ButtonRectangleSecondary>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openInvoice}
                        onClose={handleInvoiceClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        <MenuItem onClick={handleOpenInvoiceGeneration}>
                          Manual{" "}
                        </MenuItem>
                        <MenuItem
                          onClick={handleAutomaticInvoiceGeneration}
                          style={{ justifyContent: "center" }}
                        >
                          {invoiceAutomaticGenLoading ? (
                            <>
                              <CircularProgress size={20} />
                            </>
                          ) : (
                            "Automatic"
                          )}
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                </div>
              ) : selectedTrip.trip_status == "cancelled" ? (
                <div className="trip-list-right-most-single-container"></div>
              ) : selectedTrip.trip_status == "hardcopy_pod_received" &&
                selectedTrip.zoho_customer_invoice_status ==
                  "invoice_generated" ? (
                <div className="trip-list-right-most-single-container_drawer">
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangle
                      className="trip-list-complete-btn_v2"
                      onClick={() => handleOpenInvoiceGeneration()}
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    >
                      Re-Attach Invoice
                    </ButtonRectangle>
                  </div>
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      className="trip-list-complete-btn_v2"
                      onClick={() => handleOpenInvoiceSent(selectedTrip.id)}
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    >
                      Sent Invoice
                    </ButtonRectangleSecondary>
                  </div>
                </div>
              ) : selectedTrip.trip_status == "hardcopy_pod_received" &&
                selectedTrip.zoho_customer_invoice_status == "invoice_sent" ? (
                <div className="trip-list-right-most-single-container_drawer">
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangle
                      className="trip-list-complete-btn_v2"
                      onClick={() => handleOpenInvoiceGeneration()}
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    >
                      ReAttach Invoice
                    </ButtonRectangle>
                  </div>
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      className="trip-list-complete-btn_v2"
                      onClick={() =>
                        handleOpenInvoiceSubmission(selectedTrip.id)
                      }
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    >
                      Submit Invoice
                    </ButtonRectangleSecondary>
                  </div>
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      className="trip-list-complete-btn_v2"
                      onClick={
                        () => handleOpenInvoiceRejectionModal(selectedTrip)
                        // handleOpenInvoiceSubmission(selectedTrip.id)
                      }
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    >
                      Reject Invoice
                    </ButtonRectangleSecondary>
                  </div>
                </div>
              ) : selectedTrip.zoho_customer_invoice_status ===
                  "invoice_rejected" && isMobile ? (
                <div className="trip-list-right-most-btn-container">
                  <div>
                    <ButtonRectangle
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleInvoiceClick}
                      style={{ paddingLeft: "10px", paddingRight: "10px" }}
                    >
                      Generate Invoice
                    </ButtonRectangle>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openInvoice}
                      onClose={handleInvoiceClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleOpenInvoiceGeneration}>
                        Manual{" "}
                      </MenuItem>
                      <MenuItem
                        onClick={handleAutomaticInvoiceGeneration}
                        style={{ justifyContent: "center" }}
                      >
                        {invoiceAutomaticGenLoading ? (
                          <>
                            <CircularProgress size={20} />
                          </>
                        ) : (
                          "Automatic"
                        )}
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="details_view_main_conatiner">
              <div className="details_view_conatainer">
                {Object.keys(selectedTrip).length ? (
                  tripDetailsHeading == "Trip Details" ? (
                    <div className="mini-details-tab-container_v2">
                      {selectedTrip.trip_additional_data?.arrived_on && (
                        <Box sx={{ width: "100%", marginBottom: "10px" }}>
                          {/* Stepper content placed above */}
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "0 60px",
                            }}
                          >
                            <div style={{ width: "150px", fontSize: "12px" }}>
                              Arrived
                            </div>
                            <div
                              style={{
                                width: "150px",
                                fontSize: "12px",
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              Goods Delivered{" "}
                              {selectedTrip?.trip_additional_data
                                ?.is_goods_damaged !== null &&
                                selectedTrip?.trip_additional_data
                                  ?.is_goods_shortage !== null && (
                                  <span>
                                    <Tooltip
                                      placement="bottom"
                                      title={
                                        selectedTrip?.trip_additional_data
                                          ?.is_goods_damaged &&
                                        selectedTrip?.trip_additional_data
                                          ?.is_goods_shortage
                                          ? "Goods damage and Goods shortage reported"
                                          : selectedTrip?.trip_additional_data
                                              ?.is_goods_damaged
                                          ? "Goods Damage reported"
                                          : selectedTrip?.trip_additional_data
                                              ?.is_goods_shortage
                                          ? "Goods shortage reported"
                                          : "No damage or shortage reported"
                                      }
                                      arrow={true}
                                      color="#474747"
                                      zIndex={999999}
                                    >
                                      {!selectedTrip?.trip_additional_data
                                        ?.is_goods_damaged &&
                                      !selectedTrip?.trip_additional_data
                                        ?.is_goods_shortage ? (
                                        <DoneAll
                                          style={{
                                            fontSize: "20px",
                                            color: colorPalette.primaryColor,
                                          }}
                                        />
                                      ) : (
                                        <CloseIcon
                                          style={{
                                            fontSize: "20px",
                                            color: colorPalette.red,
                                          }}
                                        />
                                      )}
                                    </Tooltip>
                                  </span>
                                )}
                            </div>
                            <div
                              style={{
                                width: "150px",
                                fontSize: "12px",
                                textAlign: "right",
                              }}
                            >
                              POD Received
                            </div>
                          </div>

                          {/* Stepper component */}

                          <Stepper
                            activeStep={
                              selectedTrip?.trip_additional_data
                                ?.date_of_hardcopy_pod_received
                                ? 3
                                : selectedTrip?.trip_additional_data
                                    ?.unloaded_on
                                ? 2
                                : 1
                            }
                            disabled={
                              !selectedTrip?.trip_additional_data?.arrived_on
                                ? true
                                : false
                            }
                            alternativeLabel
                          >
                            <Step key={1}>
                              <StepLabel>
                                <div>
                                  {selectedTrip?.trip_additional_data
                                    ?.arrived_on &&
                                    moment(
                                      selectedTrip?.trip_additional_data
                                        ?.arrived_on
                                    ).format("DD-MM-YYYY")}
                                </div>
                              </StepLabel>
                            </Step>
                            <Step key={2}>
                              <StepLabel
                                StepIconComponent={
                                  !selectedTrip?.trip_additional_data
                                    ?.unloaded_on && PanoramaFishEye
                                }
                              >
                                <div>
                                  {selectedTrip?.trip_additional_data
                                    ?.unloaded_on &&
                                    moment(
                                      selectedTrip?.trip_additional_data
                                        ?.unloaded_on
                                    ).format("DD-MM-YYYY")}
                                </div>
                              </StepLabel>
                            </Step>
                            <Step key={3}>
                              <StepLabel
                                StepIconComponent={
                                  !selectedTrip?.trip_additional_data
                                    ?.date_of_hardcopy_pod_received &&
                                  PanoramaFishEye
                                }
                              >
                                <div>
                                  {selectedTrip?.trip_additional_data
                                    ?.date_of_hardcopy_pod_received &&
                                    moment(
                                      selectedTrip?.trip_additional_data
                                        ?.date_of_hardcopy_pod_received
                                    ).format("DD-MM-YYYY")}
                                </div>
                              </StepLabel>
                            </Step>
                          </Stepper>
                        </Box>
                      )}

                      <div className="tl-new-status_drawer">
                        <span
                          style={{
                            // fontSize: "8px",
                            color: "white",
                            padding: "2px 4px",
                            background:
                              selectedTrip.trip_status === "completed"
                                ? "#016938"
                                : selectedTrip.trip_status === "cancelled"
                                ? "red"
                                : selectedTrip.trip_status === "trip_disputed"
                                ? "#C4B454"
                                : "#65c368",
                            borderTopLeftRadius: "3px",
                            borderBottomLeftRadius: "3px",
                          }}
                        >
                          {capitalizeLetter(
                            selectedTrip.trip_status.toUpperCase()
                          )}
                          {/* {selectedTrip.trip_status.toUpperCase()}{" "} */}
                        </span>
                        <span
                          style={{
                            color: "white",
                            padding: "2px 4px",
                            background: "orange",
                            borderTopRightRadius: "3px",
                            borderBottomRightRadius: "3px",
                          }}
                        >
                          {selectedTrip.trip_type
                            ? selectedTrip.trip_type
                                .split("_")
                                .join(" ")
                                .toUpperCase()
                            : selectedTrip.vehicle_freight_type
                            ? selectedTrip.vehicle_freight_type.toUpperCase()
                            : " - "}
                        </span>
                      </div>
                      {selectedTrip.trip_additional_data
                        ?.will_billing_party_pay_detention_charges ||
                      selectedTrip.trip_additional_data
                        ?.will_transporter_receive_detention_charges ? (
                        <div
                          style={{
                            width: "100%",
                            padding: "5px 15px",
                          }}
                        >
                          {selectedTrip.trip_additional_data
                            ?.will_billing_party_pay_detention_charges ? (
                            <div
                              style={{
                                backgroundColor: "#ffcb6b",
                                padding: "3px",
                                display: "flex",
                                alignItems: "self-start",
                                gap: "10px",
                              }}
                            >
                              <div style={{ marginTop: "3px" }}>**</div> Billing
                              party needs to pay detention charges
                            </div>
                          ) : null}
                          {selectedTrip.trip_additional_data
                            ?.will_transporter_receive_detention_charges ? (
                            <div
                              style={{
                                backgroundColor: "#ffcb6b",
                                padding: "3px",
                                display: "flex",
                                alignItems: "self-start",
                                gap: "10px",
                              }}
                            >
                              <div style={{ marginTop: "3px" }}>**</div>{" "}
                              Transporter needs to receive detention charges
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      <TripListDrawerDetails
                        tripDetail={selectedTrip}
                        tripCostTotal={tripCostTotal}
                        tripAdvanceCost={tripAdvanceCost}
                      />
                    </div>
                  ) : tripDetailsHeading == "Driver" ? (
                    loadingAttachmentList ? (
                      <div
                        style={{
                          width: "100%",
                          height: "80vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Loading...
                      </div>
                    ) : (
                      <>
                        <div
                          className="mini-details-tab-container_v2"
                          style={{ height: "260px" }}
                        >
                          <h3 style={{ marginLeft: "15px" }}>Primary Driver</h3>
                          <div className="mini-details-single-list">
                            <h4 className="mini-details-single-list-left">
                              First Name
                            </h4>
                            <h4 className="mini-details-single-list-right">
                              :{" "}
                              {driverDetails?.first_name
                                ? driverDetails?.first_name
                                : "-"}
                            </h4>{" "}
                          </div>

                          <div className="mini-details-single-list">
                            <h4 className="mini-details-single-list-left">
                              Last name
                            </h4>
                            <h4 className="mini-details-single-list-right">
                              :{" "}
                              {driverDetails?.last_name
                                ? driverDetails?.last_name
                                : "-"}
                            </h4>{" "}
                          </div>
                          <div className="mini-details-single-list">
                            <h4 className="mini-details-single-list-left">
                              Mobile number
                            </h4>
                            <h4 className="mini-details-single-list-right">
                              :{" "}
                              {driverDetails?.mobile_number ? (
                                <CopyText text={driverDetails.mobile_number} />
                              ) : (
                                "-"
                              )}
                            </h4>{" "}
                          </div>

                          <div className="mini-details-single-list">
                            <h4 className="mini-details-single-list-left">
                              DL number
                            </h4>
                            <h4 className="mini-details-single-list-right">
                              :{" "}
                              {driverDetails?.driving_licence_number ? (
                                <CopyText
                                  text={driverDetails.driving_licence_number}
                                />
                              ) : (
                                "-"
                              )}
                            </h4>{" "}
                          </div>
                          <div className="mini-details-single-list">
                            <h4 className="mini-details-single-list-left">
                              Alternate number
                            </h4>
                            <h4 className="mini-details-single-list-right">
                              :{" "}
                              {driverDetails?.alternate_number ? (
                                <CopyText
                                  text={driverDetails.alternate_number}
                                />
                              ) : (
                                "-"
                              )}
                            </h4>{" "}
                          </div>
                          <div
                            className="mini-details-single-attachment_download"
                            style={{ marginLeft: "5px" }}
                          >
                            {" "}
                            <ul
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                backgroundColor: `${
                                  darkMode
                                    ? colorPaletteDark.primaryColor
                                    : colorPalette.white
                                }`,
                                color: `${
                                  darkMode
                                    ? colorPaletteDark.white
                                    : colorPalette.black
                                }`,
                              }}
                            >
                              <li
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  fontWeight: "500",
                                  backgroundColor: `${
                                    darkMode
                                      ? colorPaletteDark.primaryColor
                                      : colorPalette.white
                                  }`,
                                  marginLeft: "5px",
                                }}
                              >
                                <div className="trip_buttons_download">
                                  <img
                                    src={fileIcons}
                                    alt=""
                                    width="35px"
                                    height="35px"
                                    style={{
                                      cursor: "pointer",
                                      backgroundColorcolor: `${
                                        darkMode
                                          ? colorPaletteDark.secondaryColor
                                          : ""
                                      }`,
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: `${
                                        darkMode
                                          ? colorPaletteDark.white
                                          : colorPalette.black
                                      }`,
                                      marginLeft: "5px",
                                    }}
                                  >
                                    Driving Licence
                                  </span>
                                </div>

                                <div className="trip_buttons_download">
                                  <img
                                    src={showIcons}
                                    alt=""
                                    width="35px"
                                    height="35px"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      handleOpenImage();
                                      showDriverDocs(driverDetails?.id);
                                    }}
                                  />
                                  <img
                                    src={downloadIcons}
                                    alt=""
                                    width="35px"
                                    height="35px"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      downloadDriverDocs(driverDetails?.id)
                                    }
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        {/* Secondary Driver */}
                        {secondaryDriverDetails ? (
                          <div className="mini-details-tab-container_v2">
                            <h3 style={{ marginLeft: "15px" }}>
                              Secondary Driver
                            </h3>
                            <div className="mini-details-single-list">
                              <h4 className="mini-details-single-list-left">
                                First Name
                              </h4>
                              <h4 className="mini-details-single-list-right">
                                :{" "}
                                {secondaryDriverDetails.first_name
                                  ? secondaryDriverDetails.first_name
                                  : "-"}
                              </h4>{" "}
                            </div>

                            <div className="mini-details-single-list">
                              <h4 className="mini-details-single-list-left">
                                Last name
                              </h4>
                              <h4 className="mini-details-single-list-right">
                                :{" "}
                                {secondaryDriverDetails.last_name
                                  ? secondaryDriverDetails.last_name
                                  : "-"}
                              </h4>{" "}
                            </div>
                            <div className="mini-details-single-list">
                              <h4 className="mini-details-single-list-left">
                                Mobile number
                              </h4>
                              <h4 className="mini-details-single-list-right">
                                :{" "}
                                {secondaryDriverDetails.mobile_number ? (
                                  <CopyText
                                    text={secondaryDriverDetails.mobile_number}
                                  />
                                ) : (
                                  "-"
                                )}
                              </h4>{" "}
                            </div>

                            <div className="mini-details-single-list">
                              <h4 className="mini-details-single-list-left">
                                DL number
                              </h4>
                              <h4 className="mini-details-single-list-right">
                                :{" "}
                                {secondaryDriverDetails.driving_licence_number ? (
                                  <CopyText
                                    text={
                                      secondaryDriverDetails.driving_licence_number
                                    }
                                  />
                                ) : (
                                  "-"
                                )}
                              </h4>{" "}
                            </div>
                            <div className="mini-details-single-list">
                              <h4 className="mini-details-single-list-left">
                                Alternate number
                              </h4>
                              <h4 className="mini-details-single-list-right">
                                :{" "}
                                {secondaryDriverDetails.alternate_number ? (
                                  <CopyText
                                    text={
                                      secondaryDriverDetails.alternate_number
                                    }
                                  />
                                ) : (
                                  "-"
                                )}
                              </h4>{" "}
                            </div>
                            <div
                              className="mini-details-single-attachment_download"
                              style={{ marginLeft: "5px" }}
                            >
                              {" "}
                              <ul
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  backgroundColor: `${
                                    darkMode
                                      ? colorPaletteDark.primaryColor
                                      : colorPalette.white
                                  }`,
                                  color: `${
                                    darkMode
                                      ? colorPaletteDark.white
                                      : colorPalette.black
                                  }`,
                                }}
                              >
                                <li
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    fontWeight: "500",
                                    backgroundColor: `${
                                      darkMode
                                        ? colorPaletteDark.primaryColor
                                        : colorPalette.white
                                    }`,
                                    marginLeft: "5px",
                                  }}
                                >
                                  <div className="trip_buttons_download">
                                    <img
                                      src={fileIcons}
                                      alt=""
                                      width="35px"
                                      height="35px"
                                      style={{
                                        cursor: "pointer",
                                        backgroundColorcolor: `${
                                          darkMode
                                            ? colorPaletteDark.secondaryColor
                                            : ""
                                        }`,
                                      }}
                                    />
                                    <span
                                      style={{
                                        color: `${
                                          darkMode
                                            ? colorPaletteDark.white
                                            : colorPalette.black
                                        }`,
                                        marginLeft: "5px",
                                      }}
                                    >
                                      Driving Licence
                                    </span>
                                  </div>

                                  <div className="trip_buttons_download">
                                    <img
                                      src={showIcons}
                                      alt=""
                                      width="35px"
                                      height="35px"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleOpenImage();
                                        showDriverDocs(
                                          secondaryDriverDetails.id
                                        );
                                      }}
                                    />
                                    <img
                                      src={downloadIcons}
                                      alt=""
                                      width="35px"
                                      height="35px"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        downloadDriverDocs(
                                          secondaryDriverDetails.id
                                        )
                                      }
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        ) : isSecondaryDriverEditMode ? (
                          <div
                            style={{
                              padding: "0 20px 0 5px",
                              width: "100%",
                              display: "flex",
                              gap: "10px",
                              flexDirection: "column",
                            }}
                          >
                            <AsyncSelect
                              className="secondary-driver-select"
                              styles={{ width: "100%" }}
                              defaultOptions
                              key={secondaryDriverId}
                              placeholder="Search with Name, DL or Mobile No."
                              value={
                                secondaryDriverId
                                  ? {
                                      value: secondaryDriverId,
                                      label:
                                        secondaryDriverDetailsInput.first_name,
                                    }
                                  : ""
                              }
                              onChange={(e) => {
                                setSecondaryDriverDetailsInput({
                                  first_name: e.first_name,
                                  last_name: e.last_name,
                                  dl: e.driving_licence_number,
                                  mobile: e.mobile_number,
                                });
                                handleSecondaryDriverSelect(e);
                              }}
                              loadOptions={(inputValue, callback) => {
                                if (
                                  inputValue.trim() ||
                                  driverListOptions.length === 0
                                ) {
                                  driverOptions(inputValue).then((options) => {
                                    const validOptions = Array.isArray(options)
                                      ? options
                                      : [];
                                    callback(validOptions);
                                  });
                                } else {
                                  const validOptions = Array.isArray(
                                    driverListOptions
                                  )
                                    ? driverListOptions
                                    : [];
                                  callback(validOptions);
                                }
                              }}
                            />
                            {secondaryDriverError ? (
                              <span style={{ fontSize: "12px", color: "red" }}>
                                {secondaryDriverError}
                              </span>
                            ) : null}
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <ButtonRectangle
                                disabled={
                                  !secondaryDriverDetailsInput.mobile_number
                                }
                                onClick={handleUpdateDriver}
                              >
                                {secondaryButtonLoading
                                  ? "Loading..."
                                  : "Add Driver"}
                              </ButtonRectangle>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              padding: "0 20px",
                            }}
                          >
                            <ButtonRectangle
                              onClick={() => setIsSecondaryDriverEditMode(true)}
                            >
                              Add Secondary Driver
                            </ButtonRectangle>
                          </div>
                        )}
                      </>
                    )
                  ) : tripDetailsHeading == "Trip History" ? (
                    <div
                      className="mini-details-tab-container-history"
                      style={{ padding: "1rem .5rem" }}
                    >
                      <div className="mini-details-tab-left-timeline">
                        {tripHistoryList.map((item, index) => {
                          return (
                            <>
                              {/* {tripHistoryList.length - 1 == index ? null : ( */}
                              <>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                                <div className="mini-trip-rectangle-timeline"></div>
                              </>
                              {/* )} */}
                            </>
                          );
                        })}
                      </div>
                      <div>
                        {tripHistoryList.map((item) => {
                          return (
                            <div className="mini-details-single-history">
                              <h3 className="mini-details-history-head">
                                <div className="mini-trip-circle"></div>{" "}
                                <span
                                  className="mini-details-black-text-header"
                                  style={{
                                    color: `${
                                      darkMode
                                        ? "#48d7a4"
                                        : item.trip_status.toLowerCase() ===
                                            "rejected" ||
                                          item.trip_status.toLowerCase() ===
                                            "cancelled"
                                        ? "red"
                                        : "green"
                                    }
                                `,
                                  }}
                                >
                                  {capitalizeLetter(
                                    item.trip_status
                                      ? item.trip_status
                                          .split("")
                                          .map((item, index) => {
                                            if (index == 0) {
                                              return item.toUpperCase();
                                            } else {
                                              return item;
                                            }
                                          })
                                          .join("")
                                      : "-"
                                  )}
                                </span>
                                {/* {item.trip_status
                              ? item.trip_status
                                  .split("")
                                  .map((item, index) => {
                                    if (index == 0) {
                                      return item.toUpperCase();
                                    } else {
                                      return item;
                                    }
                                  })
                                  .join("")
                              : "-"} */}
                              </h3>
                              <div className="mini-details-single-list">
                                {/* <h4 className="mini-details-history-left">created</h4> */}
                                <h4 className="mini-details-history-right">
                                  <div
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "120px auto",
                                    }}
                                  >
                                    <div className="mini-details-black-text">
                                      Remarks :
                                    </div>
                                    <div
                                      style={{ marginLeft: "5px" }}
                                      className="mini-details-grey-text"
                                    >
                                      {" "}
                                      {item.remarks ? item.remarks : "-"}
                                    </div>
                                  </div>
                                </h4>{" "}
                              </div>
                              <div className="mini-details-single-list">
                                {/* <h4 className="mini-details-history-left">created</h4> */}
                                <h4 className="mini-details-history-right">
                                  <span className="mini-details-black-text">
                                    {" "}
                                    Last Modified :{" "}
                                  </span>
                                  <span className="mini-details-grey-text">
                                    {item.last_modified_by
                                      ? item.last_modified_by
                                      : "-"}
                                  </span>
                                </h4>{" "}
                              </div>

                              <div className="mini-details-single-list">
                                <h4 className="mini-details-history-right">
                                  <span className="mini-details-black-text">
                                    Created on :{" "}
                                  </span>
                                  <span className="mini-details-grey-text">
                                    {item.created_on
                                      ? moment
                                          .utc(item.created_on)
                                          .local()
                                          .format("DD-MM-YYYY hh:mm a")
                                      : "-"}
                                  </span>
                                </h4>{" "}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : tripDetailsHeading == "Attachments" ? (
                    <div className="mini-details-attachment-list_trip">
                      {tripAttachmentList?.length > 0 ? (
                        tripAttachmentList?.map((item) => {
                          return (
                            <div className="mini-details-single-attachment_download">
                              {" "}
                              <ul
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  padding: "5px 5px",
                                  alignItems: "center",
                                  backgroundColor: `${
                                    darkMode
                                      ? colorPaletteDark.secondaryColor
                                      : colorPalette.white
                                  }`,
                                  justifyContent: "space-around",
                                }}
                              >
                                <li
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: `${
                                      darkMode
                                        ? colorPaletteDark.primaryColor
                                        : colorPalette.white
                                    }`,
                                    color: `${
                                      darkMode
                                        ? colorPaletteDark.white
                                        : colorPalette.black
                                    }`,
                                    fontWeight: "400",
                                    fontSize: "12px",
                                  }}
                                >
                                  <div
                                    className="trip_buttons_download"
                                    style={{
                                      padding: `${darkMode ? "5px" : "0px"}`,
                                    }}
                                  >
                                    <img
                                      src={fileIcons}
                                      alt=""
                                      width="35px"
                                      height="35px"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        download(item.id);
                                      }}
                                    />
                                    <span
                                      style={{
                                        color: `${
                                          darkMode
                                            ? colorPaletteDark.white
                                            : colorPalette.black
                                        }`,
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {" "}
                                      {item.tag
                                        .split("_")
                                        .join(" ")
                                        .split("")
                                        .map((item, index) => {
                                          if (index == 0) {
                                            return item.toUpperCase();
                                          } else {
                                            return item;
                                          }
                                        })
                                        .join("")}
                                    </span>
                                  </div>

                                  <div className="trip_buttons_download">
                                    {item.trip_dispute_id !== null ? (
                                      <ReportGmailerrorredOutlinedIcon
                                        style={{
                                          fontSize: "18px",
                                          cursor: "pointer",
                                          color: "#D8AE2D",
                                          fontWeight: "bold",
                                        }}
                                        onClick={() => {
                                          setOpenDisputeDetailModal(true);
                                          getDisputeHistory(
                                            item.trip_dispute_id
                                          );
                                        }}
                                      />
                                    ) : null}

                                    <img
                                      src={showIcons}
                                      alt=""
                                      width="35px"
                                      height="35px"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        handleOpenImage();
                                        showTripAttachments(item.id);
                                      }}
                                    />

                                    <img
                                      src={downloadIcons}
                                      alt=""
                                      width="35px"
                                      height="35px"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        // handleOpenImage();
                                        download(item.id);
                                      }}
                                    />
                                    {/* <a
                                  href="javascript:void(0)"
                                  onClick={() => download(item.id)}
                                  className="attachment_btn"
                                  style={{ fontSize: "10px" }}
                                >
                                  <FeedIcon />{" "}
                                </a> */}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          );
                        })
                      ) : loadingAttachmentList ? (
                        <div className="mini-details-no-attachment">
                          Loading data...
                        </div>
                      ) : (
                        <div className="mini-details-no-attachment">
                          <img
                            src={NoDetailsFound}
                            alt=""
                            width="80px"
                            height="80px"
                          />
                          No attachment found!
                        </div>
                      )}
                    </div>
                  ) : tripDetailsHeading == "Trip Costs" ? (
                    // <div className="mini-details-tab-container-history">
                    <div className="trip-cost-details-container">
                      {/* <div style={{ fontWeight: "bold" }}>Trip Cost Details</div> */}
                      {triCostDetails?.length > 0 ? (
                        triCostDetails?.map((data) => (
                          <div className="trip-cost-keys">
                            <span>
                              {data.cost_type
                                ? data.cost_type
                                    .split("_")
                                    .join(" ")
                                    .toUpperCase()
                                : null}
                            </span>{" "}
                            <span>
                              ₹{" "}
                              {data?.cost_amount >= 0
                                ? data.cost_amount
                                : null("")}
                            </span>
                          </div>
                        ))
                      ) : // : triCostDetails.length == 0 ? (
                      //   <div
                      //     style={{
                      //       display: "flex",
                      //       width: "100%",
                      //       alignItems: "center",
                      //       justifyContent: "center",
                      //       marginTop: "30px",
                      //     }}
                      //   >
                      //     No Trip Cost found!
                      //   </div>
                      // )
                      loadingTripCost ? (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "30px",
                          }}
                        >
                          Loading data...
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "30px",
                          }}
                        >
                          No Trip Cost found!
                        </div>
                      )}
                    </div>
                  ) : tripDetailsHeading == "Log Details" ? (
                    <div className="trip-cost-details-container">
                      <div className="log_list_container">
                        {loadingLogsList ? (
                          <div className="log_list_name_container">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              Loading data...
                            </div>
                          </div>
                        ) : tripLogList.length > 0 ? (
                          <Timeline
                            // loadingLogsList
                            // tripLogList
                            items={
                              tripLogList.length > 0
                                ? tripLogList.map((logdata) => ({
                                    color: "#076b38",
                                    dot: <OpenInNewOutlinedIcon />,
                                    children: (
                                      <>
                                        <div className="log_list_name_container">
                                          {" "}
                                          <h4>{logdata?.created_by}</h4>{" "}
                                          <div>
                                            {" "}
                                            {logdata.expected_time_to_destination
                                              ? moment
                                                  .utc(logdata.time_of_log)
                                                  .format("DD-MM-YYYY hh:mm a")
                                              : logdata.created_on
                                              ? moment
                                                  .utc(logdata.created_on)
                                                  .utcOffset("+05:30")
                                                  .format("DD-MM-YYYY hh:mm a")
                                              : " - "}
                                          </div>
                                        </div>
                                        <div className="other_log_content">
                                          <p className="log_content_values">
                                            <RateReviewOutlinedIcon
                                              style={{
                                                marginRight: "10px",
                                                fontSize: "15px",
                                              }}
                                            />
                                            {logdata.remarks}
                                          </p>
                                          {(logdata.street_address ||
                                            logdata.district ||
                                            logdata.state ||
                                            logdata.pincode) && (
                                            <p className="log_content_values">
                                              <LocationOnOutlinedIcon
                                                style={{
                                                  marginRight: "10px",
                                                  fontSize: "15px",
                                                }}
                                              />
                                              {logdata.street_address},
                                              {logdata.district},{logdata.state}
                                              ,{logdata.pincode}
                                            </p>
                                          )}

                                          {logdata.expected_time_to_destination && (
                                            <p className="log_content_values">
                                              <AccessTimeOutlinedIcon
                                                style={{
                                                  marginRight: "10px",
                                                  fontSize: "15px",
                                                }}
                                              />
                                              {logdata.expected_time_to_destination
                                                ? moment
                                                    .utc(
                                                      logdata.expected_time_to_destination
                                                    )
                                                    .format(
                                                      "DD-MM-YYYY hh:mm a"
                                                    )
                                                : " - "}
                                            </p>
                                          )}

                                          {logdata?.incident_type ? (
                                            <p
                                              className="log_content_values"
                                              style={{
                                                backgroundColor: "#FBE8BC",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <img
                                                src={crashIcon}
                                                style={{
                                                  marginRight: "10px",
                                                }}
                                                alt=""
                                                height="20px"
                                                width="20px"
                                              />
                                              {capitalizeLetter(
                                                logdata?.incident_type
                                              )}
                                            </p>
                                          ) : null}
                                        </div>
                                      </>
                                    ),
                                  }))
                                : null
                            }
                          />
                        ) : tripLogList.length == 0 ? (
                          <div className="log_list_name_container">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              No data Found !
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="log-container">
                        <ButtonRectangleSecondary
                          onClick={() => {
                            handleOpenLogCreate();
                          }}
                        >
                          Create Log
                        </ButtonRectangleSecondary>
                      </div>
                    </div>
                  ) : tripDetailsHeading == "Transporter Payment History" ? (
                    <div className="trip-cost-details-container">
                      <div className="log_list_container">
                        {loadingPaymentHistoryList ? (
                          <div className="log_list_name_container">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              Loading data...
                            </div>
                          </div>
                        ) : transporterPaymentHistoryList.length > 0 ? (
                          <Timeline
                            // loadingLogsList
                            // tripLogList
                            items={
                              transporterPaymentHistoryList.length > 0
                                ? transporterPaymentHistoryList.map(
                                    (paymentHistoryData) => ({
                                      color: "#076b38",
                                      dot: <OpenInNewOutlinedIcon />,
                                      children: (
                                        <>
                                          <div className="log_list_name_container">
                                            {" "}
                                            <h4>
                                              {paymentHistoryData?.created_by}
                                            </h4>{" "}
                                            <div>
                                              {" "}
                                              {paymentHistoryData.created_on
                                                ? moment
                                                    .utc(
                                                      paymentHistoryData.created_on
                                                    )
                                                    .format(
                                                      "DD-MM-YYYY hh:mm a"
                                                    )
                                                : " - "}
                                            </div>
                                          </div>
                                          <div className="other_transporter_payment_content">
                                            {/* <div className="tranpsporter_payment_text"> */}
                                            <div className="log_content_values">
                                              <span
                                                style={{ fontWeight: "500" }}
                                              >
                                                Amount Paid :
                                              </span>{" "}
                                              {paymentHistoryData.amount_paid}
                                            </div>
                                            <div className="log_content_values">
                                              <span
                                                style={{ fontWeight: "500" }}
                                              >
                                                UTR Number :
                                              </span>{" "}
                                              {
                                                paymentHistoryData.utr_reference_number
                                              }
                                            </div>
                                            <div className="log_content_values">
                                              <div
                                                style={{
                                                  color: "#454545",
                                                  cursor: "pointer",
                                                  fontSize: "12px",
                                                }}
                                                onClick={() => {
                                                  setTransporterPaymentAttachmentModal(
                                                    true
                                                  );
                                                  showTransporterPaymentImage(
                                                    paymentHistoryData.id
                                                  );
                                                }}
                                              >
                                                {/* Image */}
                                                <Description />
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                position: "relative",
                                                width: "450px",
                                                textAlign: "justify",
                                              }}
                                              className="log_content_values"
                                            >
                                              <div
                                                style={{
                                                  fontWeight: "500",
                                                  width: "15%",
                                                }}
                                              >
                                                Remarks :
                                              </div>{" "}
                                              <div
                                                style={{
                                                  width: "85%",
                                                }}
                                              >
                                                {" "}
                                                {paymentHistoryData.remarks}
                                              </div>
                                            </div>
                                          </div>
                                          {/* </div> */}
                                        </>
                                      ),
                                    })
                                  )
                                : null
                            }
                          />
                        ) : tripLogList.length == 0 ? (
                          <div className="log_list_name_container">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              No data Found !
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="log-container">
                        <ButtonRectangleSecondary
                          onClick={() => {
                            handleOpenTransporterPaymentHistoryDrawer();
                          }}
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          Create Transporter Payment History
                        </ButtonRectangleSecondary>
                      </div>
                    </div>
                  ) : tripDetailsHeading == "E Way Bill" ? (
                    <div className="trip-cost-details-container">
                      <div className="log_list_container">
                        {ewayBillDrawerLoading ? (
                          <div className="log_list_name_container">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              Loading data...
                            </div>
                          </div>
                        ) : tripEwayBillDetails.length > 0 ? (
                          tripEwayBillDetails.map((ewayBill) => (
                            <div className="ewayBillContainer">
                              <div className="ewayBillHeader">
                                <div className="first-div">
                                  E-Way Bill No :-{" "}
                                  <span className="ewayBillNumber">
                                    {ewayBill.eway_bill_number.toUpperCase()}
                                  </span>
                                </div>
                                <div>
                                  Trip Created on :-{" "}
                                  <span>
                                    {moment(ewayBill.created_on).format(
                                      "DD-MM-YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="ewayBillDetails">
                                <div className="statusContainer">
                                  <div className="statusGreenBar"></div>
                                  <div className="statusItem">
                                    <div className="statusCircle"></div>
                                    {"Valid From"} :-{" "}
                                    <div>{ewayBill.eway_bill_valid_from}</div>
                                  </div>
                                  <div className="statusItem">
                                    <div className="statusCircle"></div>
                                    {"Valid Till"} :-{" "}
                                    <div>{ewayBill.eway_bill_valid_till}</div>
                                  </div>
                                </div>
                                <div
                                  className="actionContainer"
                                  onClick={() => {
                                    handleOpenImage();
                                    showTripAttachments(
                                      filteredAttachmentsWithTag[
                                        filteredAttachmentsWithTag.length - 1
                                      ]?.id
                                    );
                                  }}
                                >
                                  <Description />
                                </div>
                              </div>
                              {moment(ewayBill.eway_bill_valid_till) <
                              moment(Date.now()) ? (
                                <div className="warningMessage">
                                  <>
                                    <div>
                                      Your E-Way bill expired{" "}
                                      {calculateRelativeTime(
                                        moment(ewayBill.eway_bill_valid_till)
                                      )}
                                      .{" "}
                                    </div>
                                  </>
                                </div>
                              ) : null}

                              {moment(ewayBill.eway_bill_valid_till) <
                              moment(Date.now()) ? (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginTop: "20px",
                                  }}
                                >
                                  <div style={{ width: "100%" }}>
                                    <div className="eway-form-div">
                                      <span>New E-Way Bill Till Date : </span>
                                      <input
                                        type="date"
                                        name="eway_bill_valid_till"
                                        onChange={(e) =>
                                          handleEwayUpdateInputchange(
                                            e,
                                            ewayBill.eway_bill_number,
                                            ewayBill.eway_bill_valid_from
                                          )
                                        }
                                        style={{
                                          backgroundColor: "transparent",
                                          width: "250px",
                                          border: "0.5px solid #454545",
                                          padding: "5px 10px",
                                          borderBottom: "1px solid #454545",
                                          outline: "none",
                                        }}
                                      />
                                    </div>

                                    <div
                                      className="eway-form-div"
                                      // style={{
                                      //   width: "100%",
                                      //   display: "flex",
                                      //   alignItems: "center",
                                      //   justifyContent: "space-between",
                                      //   marginBottom: "10px",
                                      // }}
                                    >
                                      <span>Attachment: </span>
                                      <input
                                        type="file"
                                        name="eway_bill_attachment"
                                        onChange={(e) =>
                                          handleEwayUpdateInputchange(
                                            e,
                                            ewayBill.eway_bill_number,
                                            ewayBill.eway_bill_valid_from
                                          )
                                        }
                                        style={{
                                          backgroundColor: "transparent",
                                          width: "250px",
                                          border: "none",
                                          padding: "5px 0px",
                                          outline: "none",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <textarea
                                        name="remarks"
                                        id="eway-remarks"
                                        placeholder="Remarks"
                                        onChange={(e) =>
                                          handleEwayUpdateInputchange(
                                            e,
                                            ewayBill.eway_bill_number,
                                            ewayBill.eway_bill_valid_from
                                          )
                                        }
                                        value={updatedEwayBillDetails.remarks}
                                        style={{
                                          width: "100%",
                                          height: "70px",
                                          padding: "5px",
                                          resize: "none",
                                        }}
                                      ></textarea>
                                    </div>
                                    <div className="renew-main-div">
                                      <div
                                        style={{
                                          fontSize: "13px",
                                          color: "red",
                                        }}
                                      >
                                        {ewayBillError
                                          ? ewayBillError
                                          : ewayBillAttachmentError
                                          ? ewayBillAttachmentError
                                          : ""}
                                      </div>
                                      {isEwayBillValid() &&
                                      ewayBillError === "" &&
                                      ewayBillAttachmentError === "" ? (
                                        <div
                                          className="renewNowLink"
                                          onClick={() => handleUpdateEwayBill()}
                                        >
                                          {ewayBillButtonLoading
                                            ? "Loading..."
                                            : "Renew Now"}
                                        </div>
                                      ) : (
                                        <div
                                          className="renewNowLink"
                                          style={{
                                            backgroundColor: "#454545",
                                            color: "white",
                                            cursor: "not-allowed",
                                          }}
                                        >
                                          Renew Now
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          ))
                        ) : tripLogList.length == 0 ? (
                          <div className="log_list_name_container">
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              No data Found !
                            </div>
                          </div>
                        ) : null}
                        {/* {console.log(moment("2024-01-05") > moment())} */}
                      </div>
                    </div>
                  ) : null
                ) : (
                  <div
                    className="mini-details-tab-container_v2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    <div style={{ margin: "auto" }}>No Trip Selected</div>
                  </div>
                )}
              </div>
              <div className="details_icons_conatainer">
                <div
                  className={
                    tripDetailsHeading == "Trip Details"
                      ? "details_icons_img_container active"
                      : "details_icons_img_container"
                  }
                >
                  <button
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      backgroundColor: `${
                        darkMode
                          ? colorPaletteDark.secondaryColor
                          : colorPalette.white
                      }`,
                      height: "80%",
                    }}
                  >
                    {tripDetailsHeading == "Trip Details" ? (
                      <img
                        src={tripDetalsIconActive}
                        alt=""
                        width="30px"
                        height="30px"
                        aria-label="Trip Details"
                        className="details_icons"
                        // onKeydown={useKeyPress}
                        onClick={() => handleDrawerButton("Trip Details")}
                      />
                    ) : (
                      <img
                        // onKeydown={useKeyPress}
                        src={tripDetalsIcon}
                        alt=""
                        width="30px"
                        aria-label="Trip Details"
                        height="30px"
                        className="details_icons"
                        onClick={() => handleDrawerButton("Trip Details")}
                      />
                    )}
                  </button>
                </div>
                <div
                  className={
                    tripDetailsHeading == "Driver"
                      ? "details_icons_img_container active"
                      : "details_icons_img_container"
                  }
                  onKeyDownCapture="Driver"
                >
                  <button
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      backgroundColor: `${
                        darkMode
                          ? colorPaletteDark.secondaryColor
                          : colorPalette.white
                      }`,
                      height: "80%",
                    }}
                  >
                    {tripDetailsHeading == "Driver" ? (
                      <img
                        src={tripDriverIconActive}
                        alt=""
                        width="30px"
                        height="30px"
                        // activeClassName="details_icons"
                        className={
                          tripDetailsHeading == "Driver"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() => handleDrawerButton("Driver")}
                      />
                    ) : (
                      <img
                        src={tripDriverIcon}
                        alt=""
                        width="30px"
                        height="30px"
                        // activeClassName="details_icons"
                        className={
                          tripDetailsHeading == "Driver"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() => handleDrawerButton("Driver")}
                      />
                    )}
                  </button>
                </div>
                <div
                  className={
                    tripDetailsHeading == "Trip History"
                      ? "details_icons_img_container active"
                      : "details_icons_img_container"
                  }
                >
                  <button
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      backgroundColor: `${
                        darkMode
                          ? colorPaletteDark.secondaryColor
                          : colorPalette.white
                      }`,
                      height: "80%",
                    }}
                  >
                    {tripDetailsHeading == "Trip History" ? (
                      <img
                        src={tripHistoryIconActive}
                        alt=""
                        width="30px"
                        height="30px"
                        className={
                          tripDetailsHeading == "Trip History"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() => handleDrawerButton("Trip History")}
                      />
                    ) : (
                      <img
                        src={tripHistoryIcon}
                        alt=""
                        width="30px"
                        height="30px"
                        className={
                          tripDetailsHeading == "Trip History"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() => handleDrawerButton("Trip History")}
                      />
                    )}
                  </button>
                </div>
                <div
                  className={
                    tripDetailsHeading == "Attachments"
                      ? "details_icons_img_container active"
                      : "details_icons_img_container"
                  }
                >
                  <button
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      backgroundColor: `${
                        darkMode
                          ? colorPaletteDark.secondaryColor
                          : colorPalette.white
                      }`,
                      height: "80%",
                    }}
                  >
                    {tripDetailsHeading == "Attachments" ? (
                      <img
                        src={tripAttachmentIconActive}
                        alt=""
                        width="30px"
                        height="30px"
                        className={
                          tripDetailsHeading == "Attachments"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() => handleDrawerButton("Attachments")}
                      />
                    ) : (
                      <img
                        src={tripAttachmentIcon}
                        alt=""
                        width="30px"
                        height="30px"
                        className={
                          tripDetailsHeading == "Attachments"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() => handleDrawerButton("Attachments")}
                      />
                    )}
                  </button>
                </div>

                <div
                  className={
                    tripDetailsHeading == "Trip Costs"
                      ? "details_icons_img_container active"
                      : "details_icons_img_container"
                  }
                >
                  <button
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      backgroundColor: `${
                        darkMode
                          ? colorPaletteDark.secondaryColor
                          : colorPalette.white
                      }`,
                      height: "80%",
                    }}
                  >
                    {" "}
                    {tripDetailsHeading == "Trip Costs" ? (
                      <img
                        src={tripCostIconActive}
                        alt=""
                        width="30px"
                        height="30px"
                        className={
                          tripDetailsHeading == "Trip Costs"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() => handleDrawerButton("Trip Costs")}
                      />
                    ) : (
                      <img
                        src={tripCostIcon}
                        alt=""
                        width="30px"
                        height="30px"
                        className={
                          tripDetailsHeading == "Trip Costs"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() => handleDrawerButton("Trip Costs")}
                      />
                    )}
                  </button>
                </div>
                <div
                  className={
                    tripDetailsHeading == "Log Details"
                      ? "details_icons_img_container active"
                      : "details_icons_img_container"
                  }
                >
                  <button
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      backgroundColor: `${
                        darkMode
                          ? colorPaletteDark.secondaryColor
                          : colorPalette.white
                      }`,
                      height: "80%",
                    }}
                  >
                    {" "}
                    {tripDetailsHeading == "Log Details" ? (
                      <img
                        src={tripLogIconActive}
                        alt=""
                        width="30px"
                        height="30px"
                        className={
                          tripDetailsHeading == "Log Details"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() => handleDrawerButton("Log Details")}
                      />
                    ) : (
                      <img
                        src={tripLogIcon}
                        alt=""
                        width="30px"
                        height="30px"
                        className={
                          tripDetailsHeading == "Log Details"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() => handleDrawerButton("Log Details")}
                      />
                    )}
                  </button>
                </div>

                <div
                  className={
                    tripDetailsHeading == "Transporter Payment History"
                      ? "details_icons_img_container active"
                      : "details_icons_img_container"
                  }
                >
                  <button
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      backgroundColor: `${
                        darkMode
                          ? colorPaletteDark.secondaryColor
                          : colorPalette.white
                      }`,
                      height: "80%",
                    }}
                  >
                    {" "}
                    {tripDetailsHeading == "Transporter Payment History" ? (
                      <img
                        src={tripPaymentIconActive}
                        alt=""
                        width="30px"
                        height="30px"
                        className={
                          tripDetailsHeading == "Transporter Payment History"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() =>
                          handleDrawerButton("Transporter Payment History")
                        }
                      />
                    ) : (
                      <img
                        src={tripPaymentIcon}
                        alt=""
                        width="30px"
                        height="30px"
                        className={
                          tripDetailsHeading == "Transporter Payment History"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() =>
                          handleDrawerButton("Transporter Payment History")
                        }
                      />
                    )}
                  </button>
                </div>

                <div
                  className={
                    tripDetailsHeading == "E Way Bill"
                      ? "details_icons_img_container active"
                      : "details_icons_img_container"
                  }
                >
                  <button
                    style={{
                      width: "100%",
                      border: "none",
                      outline: "none",
                      backgroundColor: `${
                        darkMode
                          ? colorPaletteDark.secondaryColor
                          : colorPalette.white
                      }`,
                      height: "80%",
                    }}
                  >
                    {" "}
                    {tripDetailsHeading == "E Way Bill" ? (
                      <img
                        src={tripEwayBillIconActive}
                        alt=""
                        width="30px"
                        height="30px"
                        className={
                          tripDetailsHeading == "E Way Bill"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() => {
                          handleDrawerButton("E Way Bill");
                        }}
                      />
                    ) : (
                      <img
                        src={tripEwayBillIcon}
                        alt=""
                        width="30px"
                        height="30px"
                        className={
                          tripDetailsHeading == "E Way Bill"
                            ? "details_icons active"
                            : "details_icons"
                        }
                        onClick={() => {
                          handleDrawerButton("E Way Bill");
                        }}
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Trip_list_drawer_top_row>
        </div>
      </Drawer>

      <Modal
        open={transporterPaymentAttachmentModal}
        onClose={() => setTransporterPaymentAttachmentModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            height: isMobile ? "50vh" : "",
            width: isMobile ? "90vw" : "",
            overflowY: isMobile ? "scroll" : "hidden",
            paddingBottom: isMobile ? "80px" : "",
            backgroundColor: darkMode ? "black" : "white",
          }}
        >
          {loadingImage ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <img src={imageloader} alt="" width="30%" height="30%" />
              <div>Loading...</div>
            </div>
          ) : (
            <>
              {transporterAttachmentPdf ? (
                <embed
                  src={transporterAttachmentPdf}
                  type="application/pdf"
                  style={pdfEmbedStyle}
                ></embed>
              ) : transporterPaymentHistoryImage ? (
                <TransformWrapper
                  initialScale={1}
                  initialPositionX={0}
                  initialPositionY={0}
                >
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                      <div
                        style={{
                          margin: "10px 0px",
                          position: "absolute",
                          top: "3px",
                          right: "13px",
                          zIndex: 99,
                          display: "flex",
                          flexDirection: "column",
                          background: "#fff",
                          boxShadow:
                            "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
                        }}
                      >
                        <button
                          style={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "none",
                            border: "none",
                            color: "#26cff0",
                            cursor: "pointer",
                            borderBottom: "0.5px solid #26cff0",
                          }}
                          onClick={() => zoomIn()}
                        >
                          <Add />
                        </button>
                        <button
                          style={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "none",
                            border: "none",
                            color: "#26cff0",
                            cursor: "pointer",
                            borderBottom: "0.5px solid #26cff0",
                          }}
                          onClick={() => zoomOut()}
                        >
                          <Remove />
                        </button>
                        <button
                          style={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "none",
                            border: "none",
                            color: "#26cff0",
                            cursor: "pointer",
                            borderBottom: "0.5px solid #26cff0",
                          }}
                          onClick={() =>
                            setRotateImageValue((prevData) => prevData + 90)
                          }
                        >
                          <Rotate90DegreesCw />
                        </button>
                        <button
                          style={{
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "none",
                            border: "none",
                            color: "#26cff0",
                            cursor: "pointer",
                          }}
                          onClick={() => resetTransform()}
                        >
                          <RestartAltIcon />
                        </button>
                      </div>
                      <TransformComponent>
                        <img
                          src={transporterPaymentHistoryImage}
                          alt=""
                          style={{
                            rotate: `${rotateImageValue}deg`,
                            maxWidth: "70vw",
                            maxHeight: "70vh",
                            objectFit: "contain",
                          }}
                          width={isMobile ? "100%" : "auto"}
                          height={isMobile ? "200px" : "600px"}
                        />
                      </TransformComponent>
                    </>
                  )}
                </TransformWrapper>
              ) : null}
            </>
          )}
        </Box>
      </Modal>
      {/* ================================================================================================== */}
      {/* reject remarks modal ends */}
      <div className="trip-list-right-container">
        {isMobile ? null : <Topbar />}

        <div className="trip-list-inner-left">
          <div
            className="trip-list-whole"
            style={
              Object.keys(selectedTrip)?.length > 0 || isMobile
                ? { width: "100%" }
                : { width: "90%" }
            }
          >
            <div className="trip-list-search-row">
              <div className="trip-list-search-container">
                <input
                  className="trip-list-search"
                  placeholder="search trip code"
                  value={tripSearchValue}
                  onChange={(event) => {
                    dispatch(updateTripSearchValue(event.target.value));
                    setCurrentPage(1);
                  }}
                />
                {filterTripCode ||
                filterPaymentStatus ||
                filterVehicleNumber ||
                filterTripStatus ||
                filterReferenceNumber ||
                filterCustomerId ||
                filterLoadSource ||
                filterEwayBillNo ||
                filterTripType ? (
                  <span
                    style={{
                      fontSize: "10px",
                      color: "red",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Filter applied
                  </span>
                ) : null}

                <img
                  src={FILTER_ICON}
                  onClick={handleOpenFilter}
                  alt=""
                  className="trip-list-filter-icon"
                />

                <Search />
              </div>
              <div className="trip-list-button-container">
                <Switch
                  onChange={() => tripListVersionToggle(newFtlListVersion)}
                  style={
                    newFtlListVersion
                      ? { backgroundColor: colorPalette.primaryColor }
                      : { backgroundColor: "#4e4e4e" }
                  }
                  defaultChecked={newFtlListVersion}
                  // checked={newFtlListVersion}
                />
                <a
                  onClick={() => {
                    handleCreateNewTripButton("Customer_first_mile");
                  }}
                >
                  <ButtonRectangle
                    disabled={isUserNotAuthorized}
                    style={
                      isUserNotAuthorized
                        ? { cursor: "not-allowed" }
                        : { cursor: "pointer" }
                    }
                  >
                    Create Trip
                    {/* <KeyboardArrowDownIcon /> */}
                  </ButtonRectangle>
                </a>

                <CascadingMenu
                  setExportEmail={setExportEmail}
                  setExportFromDate={setExportFromDate}
                  setExportToDate={setExportToDate}
                  handleOpenExportReport={handleOpenExportReport}
                  handleTripsExportReportApi={handleTripsExportReportApi}
                  handleTripReportExportApi={handleTripReportExportApi}
                  setExportTripType={setExportTripType}
                />
                {!isMobile && (
                  <>
                    <a
                      // className="coverage-button"
                      target="_blank"
                      className="create-new-trip-button_analytics"
                      href="/analytics"
                      onClick={() => {
                        localStorage.removeItem("tripType");
                      }}
                    >
                      {/* <QueryStatsIcon />  */}
                      <img src={mapIcon} width={"20px"} height={"20px"} />
                      <div className="map-tooltip">Trip Analytics</div>
                    </a>
                  </>
                )}
                <a
                  // className="coverage-button"
                  // target="_blank"
                  className="create-new-trip-button_analytics"
                  // href="/analytics"
                >
                  {/* <QueryStatsIcon />  */}
                  <SettingsOutlinedIcon onClick={handleSettings} />
                  {/* <img src={mapIcon} width={"20px"} height={"20px"} /> */}
                  {/* <div className="map-tooltip">Trip Settings</div> */}
                </a>
              </div>
            </div>
            <div className="trips_status_container">
              {/* <div>
                <FormControl
                  sx={{ m: 1, minWidth: 120, color: "green" }}
                  size="small"
                >
                  {" "}
                  <Select
                    value={tripType}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="trip-select-box"
                  >
                    <MenuItem
                      className="trip-select-box-value"
                      value=""
                      style={{ color: "green" }}
                    >
                      Select Trip Type
                    </MenuItem>
                    {tripTypeList?.map((item) => {
                      return (
                        <MenuItem
                          className="trip-select-box-value"
                          value={item}
                        >
                          {item.replaceAll("_", " ").toUpperCase()}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div> */}
              <div
                // key={index}
                className="trip_status_tab"
                style={{
                  borderBottom:
                    selectedTripStatus == 15
                      ? `5px solid ${colorPalette.primaryColor}`
                      : `5px solid ${colorPalette.secondaryWhite}`,

                  color:
                    selectedTripStatus == 15
                      ? `${colorPalette.primaryColor}`
                      : `${colorPalette.paragraphSecondaryolor}`,
                }}
                onClick={() => {
                  // handleFilterSearch(1);
                  handleStatusClick(15, "");
                  selectedTripStatusText("");
                  localStorage.setItem("SELECTED_TRIP_STATUS", "");
                  localStorage.setItem("SELECTED_TRIP_STATUS_INDEX", 13);
                }}
              >
                <h4 className="trip_status_tab_text">
                  All
                  {/* {status.charAt(0).toUpperCase() + status.slice(1)} */}
                </h4>
              </div>
              {tripStatusList.map((status, index) => (
                <div
                  key={index}
                  className="trip_status_tab"
                  style={{
                    borderBottom:
                      selectedTripStatus == index
                        ? `5px solid ${colorPalette.primaryColor}`
                        : `5px solid ${colorPalette.secondaryWhite}`,
                    // borderRadius: "8px",
                    color:
                      selectedTripStatus == index
                        ? `${colorPalette.primaryColor}`
                        : `${colorPalette.paragraphSecondaryolor}`,
                  }}
                  onClick={() => {
                    handleStatusClick(index, status);
                    localStorage.setItem("SELECTED_TRIP_STATUS", status);
                    localStorage.setItem("SELECTED_TRIP_STATUS_INDEX", index);
                  }}
                >
                  <div className="trip_status_tab_text">
                    {capitalizeLetter(status)}
                  </div>
                </div>
              ))}
            </div>

            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: isMobile ? "100%" : "85vw",
                  // backgroundColor: "grey",
                }}
                className="trip-list-middle-container_v2"
              >
                <img
                  src={success}
                  alt=""
                  width="120px"
                  height="90px"
                  style={{ marginTop: "200px" }}
                />
                <h6 style={{ color: "grey" }}>loading data...</h6>
              </div>
            ) : isUserNotAuthorized ? (
              <NotAuthorized />
            ) : allTripList?.length !== 0 ? (
              newFtlListVersion ? (
                <FtlTripListV3
                  tripList={allTripList}
                  myRef={lastItem}
                  setSelectedTrip={setSelectedTrip}
                  selectedTrip={selectedTrip}
                  toggleDrawerMenu={handleOpenDetailsDrawer}
                />
              ) : (
                <div className="trip-list-middle-container_v2">
                  {allTripList?.map((trip, index) => {
                    return (
                      <div
                        className="tl-new-single-container_v2"
                        id={
                          index == selectedTripIndex
                            ? "selectedIndexTrip"
                            : `null${index}`
                        }
                        style={{
                          border:
                            selectedTripIndex == index
                              ? `2px solid ${colorPalette.primaryColor} `
                              : null,
                          backgroundColor:
                            trip.trip_payment_status === "paid"
                              ? `${
                                  darkMode
                                    ? colorPaletteDark.lightGreen
                                    : colorPalette.lightGreen
                                }`
                              : null,
                        }}
                        onClick={() => {
                          handleSelectTrip(trip, index);
                          handleOpenDetailsDrawer();
                        }}
                        ref={selectedTripIndex == index ? myRef : null}
                      >
                        <div className="tl-new-main-left">
                          <div className="tl-new-left">
                            <div className="tl-new-inner-div">
                              <h4 className="tl-new-tripcode">
                                {trip.trip_code ? (
                                  <CopyText text={trip.trip_code} />
                                ) : (
                                  ""
                                )}
                              </h4>
                            </div>
                            <div className="tl-new-inner-div">
                              <h4
                                className="tl-new-vehicle"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <LocalShippingOutlinedIcon
                                  style={{
                                    color: `${colorPalette.primaryColor}`,
                                  }}
                                />{" "}
                                &nbsp;
                                {"   "}
                                {trip.vehicle_number
                                  ? trip.vehicle_number
                                  : " - "}{" "}
                                {/* <span className="tl-new-payterm">
                                (
                                {trip.vehicle_payout_period
                                  ? trip.vehicle_payout_period.split("_")[0]
                                  : ""}
                                )
                              </span> */}
                              </h4>
                            </div>
                            <div className="tl-new-inner-div">
                              <div className="tl-new-vehicle">
                                <span
                                  style={{
                                    fontWeight: tripCardLabel.fontWeight,
                                    fontFamily: tripCardLabel.fontFamily,
                                  }}
                                >
                                  {" "}
                                  consignor :{" "}
                                </span>
                                {trip.customers_details.name
                                  ? trip.customers_details.name
                                  : " - "}{" "}
                              </div>
                            </div>
                            <div className="tl-new-inner-div">
                              <div className="tl-new-vehicle">
                                <span
                                  style={{
                                    fontWeight: tripCardLabel.fontWeight,
                                    fontFamily: tripCardLabel.fontFamily,
                                  }}
                                >
                                  {" "}
                                  consignee :{" "}
                                </span>
                                <span></span>
                                {trip.consignee ? trip.consignee : " - "}{" "}
                              </div>
                            </div>
                            <div className="tl-new-inner-div">
                              <div className="tl-new-vehicle">
                                <span
                                  style={{
                                    fontWeight: tripCardLabel.fontWeight,
                                    fontFamily: tripCardLabel.fontFamily,
                                  }}
                                >
                                  {" "}
                                  Bill To :{" "}
                                </span>
                                {trip.bill_to_party_details?.name
                                  ? trip.bill_to_party_details.name
                                  : " - "}{" "}
                              </div>
                            </div>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                              className="tl-new-inner-div"
                            >
                              <div
                                onClick={(e) =>
                                  handleOpenTripRoute(e, trip, index)
                                }
                              >
                                <img
                                  style={{
                                    cursor: "pointer",
                                    marginRight: "5px",
                                    backgroundColor: darkMode
                                      ? colorPaletteDark.white
                                      : "",
                                    borderRadius: "50%",
                                  }}
                                  src={INFOICON}
                                  alt="info icon"
                                />
                              </div>
                              <div className="tl-new-payterm">
                                {trip.destination?.length > 0
                                  ? trip.destination.length == 1 &&
                                    trip.source.length == 1
                                    ? "Single stop"
                                    : "Multiple stop"
                                  : null}
                              </div>
                            </div>
                          </div>
                          <div className="tl-new-middle">
                            <div className="tl-new-inner-div">
                              {allTripList.length == index + 1 ? (
                                <div className="tl-new-status" ref={lastItem}>
                                  <span
                                    style={{
                                      // fontSize: "8px",
                                      color: "white",
                                      padding: "2px 4px",
                                      background:
                                        trip.trip_status === "completed"
                                          ? `${colorPalette.primaryColor}`
                                          : trip.trip_status === "cancelled"
                                          ? "red"
                                          : colorPalette.secondaryGreen,
                                      borderTopLeftRadius: "3px",
                                      borderBottomLeftRadius: "3px",
                                    }}
                                  >
                                    {capitalizeLetter(
                                      trip.trip_status.toUpperCase()
                                    )}
                                    {/* {trip.trip_status.toUpperCase()}{" "} */}
                                  </span>
                                  <span
                                    style={{
                                      color: "white",
                                      padding: "2px 4px",
                                      background: "orange",
                                      borderTopRightRadius: "3px",
                                      borderBottomRightRadius: "3px",
                                    }}
                                  >
                                    {trip.trip_type
                                      ? trip.trip_type
                                          .split("_")
                                          .join(" ")
                                          .toUpperCase()
                                      : trip.vehicle_freight_type
                                      ? trip.vehicle_freight_type.toUpperCase()
                                      : " - "}
                                  </span>
                                </div>
                              ) : (
                                <div className="tl-new-status">
                                  <span
                                    style={{
                                      color: "white",
                                      padding: "2px 4px",
                                      background:
                                        trip.trip_status === "completed"
                                          ? `${colorPalette.primaryColor}`
                                          : trip.trip_status === "cancelled"
                                          ? "red"
                                          : colorPalette.secondaryGreen,
                                      borderTopLeftRadius: "3px",
                                      borderBottomLeftRadius: "3px",
                                    }}
                                  >
                                    {capitalizeLetter(
                                      trip.trip_status.toUpperCase()
                                    )}
                                    {/* {trip.trip_status.toUpperCase()}{" "} */}
                                  </span>
                                  <span
                                    style={{
                                      color: "white",

                                      padding: "2px 4px",
                                      background: "orange",
                                      borderTopRightRadius: "3px",
                                      borderBottomRightRadius: "3px",
                                    }}
                                  >
                                    {trip.trip_type
                                      ? trip.trip_type
                                          .split("_")
                                          .join(" ")
                                          .toUpperCase()
                                      : trip.vehicle_freight_type
                                      ? trip.vehicle_freight_type.toUpperCase()
                                      : " - "}
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className="tl-new-inner-div">
                              <div
                                className="tl-new-modifiedby"
                                style={{
                                  fontWeight: tripCardLabel.fontWeight,
                                  fontFamily: tripCardLabel.fontFamily,
                                }}
                              >
                                Billed Amount :{" "}
                                <span
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "10px",
                                  }}
                                >
                                  {trip?.trip_customer_invoice?.length > 0
                                    ? `Rs. ${trip.trip_customer_invoice[0]?.customer_billed_amount}`
                                    : " - "}
                                </span>
                              </div>
                            </div>
                            <div className="tl-new-inner-div">
                              <div
                                className="tl-new-modifiedby"
                                style={{
                                  fontWeight: tripCardLabel.fontWeight,
                                  fontFamily: tripCardLabel.fontFamily,
                                }}
                              >
                                Customer Advance :{" "}
                                <span
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "10px",
                                  }}
                                >
                                  {trip?.trip_customer_invoice?.length > 0
                                    ? `Rs. ${trip.trip_customer_invoice[0]?.advance_paid_by_customer}`
                                    : " - "}
                                </span>
                              </div>
                            </div>
                            <div className="tl-new-inner-div">
                              <div
                                className="tl-new-modifiedby"
                                style={{
                                  fontWeight: tripCardLabel.fontWeight,
                                  fontFamily: tripCardLabel.fontFamily,
                                }}
                              >
                                Transport cost :{" "}
                                <span
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "10px",
                                  }}
                                >
                                  {trip?.trip_cost?.length > 0
                                    ? trip?.trip_cost.filter(
                                        (item) => item.cost_type === "trip_cost"
                                      ).length > 0
                                      ? `Rs. ${
                                          trip?.trip_cost.filter(
                                            (item) =>
                                              item.cost_type === "trip_cost"
                                          )[0].cost_amount
                                        }`
                                      : " - "
                                    : " - "}
                                </span>
                              </div>
                            </div>
                            <div className="tl-new-inner-div">
                              <div
                                className="tl-new-modifiedby"
                                style={{
                                  fontWeight: tripCardLabel.fontWeight,
                                  fontFamily: tripCardLabel.fontFamily,
                                }}
                              >
                                Transport advance :{" "}
                                <span
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "10px",
                                  }}
                                >
                                  {trip?.trip_cost?.length > 0
                                    ? trip?.trip_cost.filter(
                                        (item) => item.cost_type === "advance"
                                      ).length > 0
                                      ? `Rs. ${
                                          trip?.trip_cost.filter(
                                            (item) =>
                                              item.cost_type === "advance"
                                          )[0].cost_amount
                                        }`
                                      : " - "
                                    : " - "}
                                </span>
                              </div>
                            </div>
                            <div className="tl-new-inner-div">
                              <div
                                className="tl-new-modifiedby"
                                style={{
                                  fontWeight: tripCardLabel.fontWeight,
                                  fontFamily: tripCardLabel.fontFamily,
                                }}
                              >
                                Payment Terms :{" "}
                                <span
                                  style={{
                                    fontWeight: "600",
                                    fontSize: "10px",
                                  }}
                                >
                                  {trip.payment_terms
                                    ? trip.payment_terms.includes("to_pay")
                                      ? "TO PAY"
                                      : trip.payment_terms
                                          .split("_")
                                          .join(" ")
                                          .toUpperCase()
                                    : " - "}
                                </span>
                              </div>
                            </div>
                            {/* <div className="tl-new-inner-div">
                            <h4 className="tl-new-modifiedby">
                              {trip.trip_status
                                .split("")
                                .map((item, index) => {
                                  if (index == 0) {
                                    return item.toUpperCase();
                                  } else {
                                    return item;
                                  }
                                })
                                .join("") === "Intransit"
                                ? "Started"
                                : trip.trip_status
                                    .split("")
                                    .map((item, index) => {
                                      if (index == 0) {
                                        return item.toUpperCase();
                                      } else {
                                        return item;
                                      }
                                    })
                                    .join("")}{" "}
                              by :{" "}
                              {trip.last_modified_by
                                ? trip.last_modified_by
                                : " - "}
                            </h4>
                          </div> */}
                            <div className="tl-new-inner-div">
                              <h4
                                className="tl-new-modifiedby"
                                style={{
                                  fontWeight: tripCardLabel.fontWeight,
                                  fontFamily: tripCardLabel.fontFamily,
                                }}
                              >
                                Created Date :{" "}
                                {trip.created_on
                                  ? moment
                                      .utc(trip.created_on)
                                      .local()
                                      .format("DD-MM-YYYY hh:mm a")
                                  : " - "}
                              </h4>
                            </div>
                          </div>
                        </div>
                        {/* {console.log("userRole>>>", userRole)} */}
                        {/* {isMobile ? (
                        <div className="tl-new-right">
                          {trip.trip_status == "created" ? (
                            <div className="trip-list-right-most-single-container">
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangle
                                  // className="trip-list-approve-btn"
                                  onClick={(e) => handleApproveTrip(trip, e)}
                                >
                                  Approve
                                </ButtonRectangle>
                              </div>
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangleSecondary
                                  // className="trip-list-reject-btn"
                                  onClick={(e) => handleRejectTrip(trip, e)}
                                >
                                  Reject
                                </ButtonRectangleSecondary>
                              </div>
                              <div
                                onClick={() => handleEditTrip(trip)}
                                className="trip-list-right-most-edit"
                              >
                                <Edit />
                              </div>
                            </div>
                          ) : (trip.trip_status == "created" ||
                              trip.trip_status == "reopen") &&
                            userRole == "Logistics Incharge" ? (
                            <div
                              style={{ display: "flex" }}
                              className="trip-list-waiting"
                            >
                              <h5 style={{ color: "grey" }}>
                                waiting for the approval
                              </h5>
                            </div>
                          ) : trip.trip_status == "rejected" ? (
                            <div className="trip-list-right-most-single-container">
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangle
                                  // className="trip-list-approve-btn"
                                  onClick={(e) => handleReopenTrip(trip, e)}
                                >
                                  <RestartAltIcon
                                    style={{ fontSize: "16px", color: "white" }}
                                  />
                                  Reopen
                                </ButtonRectangle>
                              </div>
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangleSecondary
                                  // className="trip-list-reject-btn"
                                  onClick={(e) => handleCancelTrip(trip, e)}
                                >
                                  Cancel
                                </ButtonRectangleSecondary>
                              </div>
                              <div
                                onClick={() => handleEditTrip(trip)}
                                className="trip-list-right-most-edit"
                              >
                                <Edit />
                              </div>
                            </div>
                          ) : trip.trip_status == "reopen" ? (
                            <div className="trip-list-right-most-single-container">
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangle
                                  // className="trip-list-approve-btn"
                                  onClick={(e) => handleApproveTrip(trip, e)}
                                >
                                  Approve
                                </ButtonRectangle>
                              </div>
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangleSecondary
                                  // className="trip-list-reject-btn"
                                  onClick={(e) => handleRejectTrip(trip, e)}
                                >
                                  Reject
                                </ButtonRectangleSecondary>
                              </div>
                              <div
                                onClick={() => handleEditTrip(trip)}
                                className="trip-list-right-most-edit"
                              >
                                <Edit />
                              </div>
                            </div>
                          ) : trip.trip_status == "approved" ? (
                            <div className="trip-list-right-most-single-container">
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangle
                                  // className="trip-list-approve-btn"
                                  onClick={(e) => handleStartTrip(trip, e)}
                                >
                                  Start
                                </ButtonRectangle>
                              </div>
                              {console.log(
                                "pickup status>>>>>>>>>>",
                                !trip?.trip_entity_mapping_details
                                  .map((item) => item.status === "picked")
                                  .indexOf(false) >= 0,
                                trip.trip_code
                              )}
                              {trip.load_source === "trip_invoice" &&
                              (trip?.trip_entity_mapping_details?.length > 0
                                ? trip?.trip_entity_mapping_details
                                    .map((item) => item.status === "picked")
                                    .indexOf(false) >= 0
                                : false) ? (
                                <div className="trip-list-right-most-btn-container">
                                  <ButtonRectangleSecondary
                                    // className="trip-list-reject-btn"
                                    onClick={(e) =>
                                      handlePickupTrip(e, trip, index)
                                    }
                                  >
                                    Pickup
                                  </ButtonRectangleSecondary>
                                </div>
                              ) : null}
                            </div>
                          ) : trip.trip_status == "intransit" ? (
                            <div className="trip-list-right-most-single-container">
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangle
                                  // className="trip-list-approve-btn"
                                  onClick={() => handleArrivedAtDestination(trip)}
                                >
                                  Complete
                                </ButtonRectangle>
                              </div>
                              {trip.load_source === "trip_invoice" &&
                              trip.pending_reference_numbers.length > 0 ? (
                                <div className="trip-list-right-most-btn-container">
                                  <button
                                    className="trip-list-approve-btn"
                                    onClick={() =>
                                      handleDeliveryInvoicePayment(trip)
                                    }
                                  >
                                    Delivery
                                  </button>
                                </div>
                              ) : null}
                              {trip.load_source === "trip_invoice" &&
                              (trip?.trip_entity_mapping_details?.length > 0
                                ? trip?.trip_entity_mapping_details
                                    .map((item) => item.status === "picked")
                                    .indexOf(false) >= 0
                                : false) ? (
                                <div className="trip-list-right-most-btn-container">
                                  <ButtonRectangleSecondary
                                    // className="trip-list-reject-btn"
                                    onClick={(e) =>
                                      handlePickupTrip(e, trip, index)
                                    }
                                  >
                                    Pickup
                                  </ButtonRectangleSecondary>
                                </div>
                              ) : null}
                            </div>
                          ) : trip.trip_status == "cancelled" ? (
                            <div className="trip-list-right-most-single-container"></div>
                          ) : null}
                        </div>
                      ) : null} */}
                        {/* <div className="tl-new-right">
                        {trip.trip_status == "created" ? (
                          <div className="trip-list-right-most-single-container">
                            <div
                              onClick={() => handleEditTrip(trip)}
                              className="trip-list-right-most-edit"
                            >
                              <Edit />
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={(e) => handleApproveTrip(trip, e)}
                              >
                                Approve
                              </button>
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-reject-btn"
                                onClick={(e) => handleRejectTrip(trip, e)}
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                        ) : (trip.trip_status == "created" ||
                            trip.trip_status == "reopen") &&
                          userRole == "Logistics Incharge" ? (
                          <div
                            style={{ display: "flex" }}
                            className="trip-list-waiting"
                          >
                            <h5 style={{ color: "grey" }}>
                              waiting for the approval
                            </h5>
                          </div>
                        ) : trip.trip_status == "rejected" ? (
                          <div className="trip-list-right-most-single-container">
                            <div
                              onClick={() => handleEditTrip(trip)}
                              className="trip-list-right-most-edit"
                            >
                              <Edit />
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={(e) => handleReopenTrip(trip, e)}
                              >
                                Reopen
                              </button>
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-reject-btn"
                                onClick={(e) => handleCancelTrip(trip, e)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : trip.trip_status == "reopen" ? (
                          <div className="trip-list-right-most-single-container">
                            <div
                              onClick={() => handleEditTrip(trip)}
                              className="trip-list-right-most-edit"
                            >
                              <Edit />
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={(e) => handleApproveTrip(trip, e)}
                              >
                                Approve
                              </button>
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-reject-btn"
                                onClick={(e) => handleRejectTrip(trip, e)}
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                        ) : trip.trip_status == "approved" ? (
                          <div className="trip-list-right-most-single-container">
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={(e) => handleStartTrip(trip, e)}
                              >
                                Start
                              </button>
                            </div>
                            {console.log(
                              "pickup status>>>>>>>>>>",
                              !trip?.trip_entity_mapping_details
                                .map((item) => item.status === "picked")
                                .indexOf(false) >= 0,
                              trip.trip_code
                            )}
                            {trip.load_source === "trip_invoice" &&
                            (trip?.trip_entity_mapping_details?.length > 0
                              ? trip?.trip_entity_mapping_details
                                  .map((item) => item.status === "picked")
                                  .indexOf(false) >= 0
                              : false) ? (
                              <div className="trip-list-right-most-btn-container">
                                <button
                                  className="trip-list-reject-btn"
                                  onClick={(e) =>
                                    handlePickupTrip(e, trip, index)
                                  }
                                >
                                  Pickup
                                </button>
                              </div>
                            ) : null}
                          </div>
                        ) : trip.trip_status == "intransit" ? (
                          <div className="trip-list-right-most-single-container">
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={() => handleArrivedAtDestination(trip)}
                              >
                                Complete
                              </button>
                            </div>
                            {trip.load_source === "trip_invoice" &&
                            trip.pending_reference_numbers.length > 0 ? (
                              <div className="trip-list-right-most-btn-container">
                                <button
                                  className="trip-list-reject-btn"
                                  onClick={() =>
                                    handleDeliveryInvoicePayment(trip)
                                  }
                                >
                                  Delivery
                                </button>
                              </div>
                            ) : null}
                            {trip.load_source === "trip_invoice" &&
                            (trip?.trip_entity_mapping_details?.length > 0
                              ? trip?.trip_entity_mapping_details
                                  .map((item) => item.status === "picked")
                                  .indexOf(false) >= 0
                              : false) ? (
                              <div className="trip-list-right-most-btn-container">
                                <button
                                  className="trip-list-reject-btn"
                                  onClick={(e) =>
                                    handlePickupTrip(e, trip, index)
                                  }
                                >
                                  Pickup
                                </button>
                              </div>
                            ) : null}
                          </div>
                        ) : trip.trip_status == "cancelled" ? (
                          <div className="trip-list-right-most-single-container"></div>
                        ) : null}
                      </div> */}
                      </div>
                    );
                  })}

                  {isLastPage && !isUserNotAuthorized ? null : (
                    <div className="trip-list-loading-more_v2">
                      Loading more..Please wait..!!!
                    </div>
                  )}
                </div>
              )
            ) : (
              <div className="trip-list-middle-container_v2">
                {openFullPageLoading ? null : (
                  <h4
                    style={{
                      textAlign: "center",
                      marginTop: "30%",
                      marginLeft: "1%",
                      width: "100%",
                      color: darkMode ? "white" : "",
                    }}
                  >
                    No Trip Found! Please create one
                  </h4>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Trip_list_main_container>
  );
}

export default TripListV2;
