import apiUrl from "../../url/apiUrl.js";
import axios from "axios";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter.js";
import axiosInterceptor from "../../config/https.js";

export const getWarehouseList = async (customerId, page) => {
  try {
    const warehouseListResponse = await axiosInterceptor.get(
      `${apiUrl.customersBaseUrlV2}/${prefixSalt()}-${convertToBase64(
        customerId
      )}/warehouses?current_page=${page}`
    );
    if (warehouseListResponse.status == 200) {
      return warehouseListResponse.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const getVillagesList = async (pincode) => {
  try {
    const villagesListResponse = await axiosInterceptor.get(
      `${apiUrl.vilagePincodeUrl}/${prefixSalt()}-${convertToBase64(pincode)}`
    );
    if (villagesListResponse.status == 200) {
      return villagesListResponse.data;
    }
  } catch (e) {
    if (e.response.status === 400) {
      return e.response.status;
    }
  }
};

export const createWarehousePost = (customerId, warehouseDetails) => {
  let data = JSON.stringify(warehouseDetails);

  var config = {
    method: "post",
    url: `${apiUrl.customersBaseUrlV2}/${prefixSalt()}-${convertToBase64(
      customerId
    )}/warehouses`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response.status);
        }
      })
      .catch(function (error) {
        resolve(error.response);
      });
  });
};

export const updateWarehousePut = (
  customerId,
  warehouseId,
  warehouseDetails
) => {
  let data = JSON.stringify(warehouseDetails);

  var config = {
    method: "put",
    url: `${apiUrl.customersBaseUrlV2}/${prefixSalt()}-${convertToBase64(
      customerId
    )}/warehouses/${prefixSalt()}-${convertToBase64(warehouseId)}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response.status);
        }
      })
      .catch(function (error) {
        resolve(error.response);
      });
  });
};

export const getUserTypeEnums = async () => {
  try {
    const warehouseListResponse = await axiosInterceptor.get(
      `${apiUrl.userTypeEnumUrl}`
    );
    if (warehouseListResponse.status == 200) {
      return warehouseListResponse.data;
    }
  } catch (e) {}
};
