import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../../themes/theme";

export const GoodsDeliveredMainSection = styled.div`
  width: "100%";
  height: "100vh";
  display: "flex";
  .goods-delivered-loading-image {
    width: 85%;
    position: absolute;
  }

  .checkbox {
    transform: scale(1.5);
    margin-right: 10px;
  }

  .flex-item-center {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }
  .goods-delivered-form {
    width: 85%;
    position: absolute;
    left: 16%;
    padding: 2%;
  }
  .goods-delivery-input-grid {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 40% 40%;
    grid-template-rows: auto;
    gap: 40px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px;
    padding: 10px;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
    overflow-x: hidden;
    .goods-delivery-input-grid {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin-left: -15%;
      justify-content: flex-start;
      gap: 20px;
    }

    .trip-list-modal-row {
      margin: 0;
      padding: 0;

      .trip-list-modal-input-group {
        margin: 0;
        padding: 0;
      }
    }
    .goods-delivered-form {
      width: 80%;
    }
    .goods-delivered-form-header {
      margin-left: -15%;
    }
  }
  .goods-delivery-input {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .goods-delivered-form-header {
    display: flex;
    padding: 5px 5px 5px 0;
    align-items: center;
    margin-bottom: 2%;
  }
  .complete-trip-head-text {
    font-weight: ${FontWeight.semibold};
    font-size: larger;
    color: ${colorPalette.primaryColor};
  }
  .goods-delivery-input-span {
    font-weight: ${FontWeight.bold};
    font-size: ${typography.heading5};
    margin-bottom: 2px;
  }

  .file_name {
    font-size: ${typography.paragraph_secondary};
  }
  sup {
    color: red;
  }
  .full-width {
    grid-column: 1 / span 2; /* This makes the div span both columns */
  }
  .good-delivery-save-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .complete-trip-head {
    width: 100%;
  }
`;
