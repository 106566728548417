import React, { useEffect } from "react";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import UploadImage from "../../../assets/upload.svg";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import success from "../../../assets/dashboard_icons/icons8-truck.gif";
import { ArrowBack, Add, Edit, Close } from "@mui/icons-material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CircularProgress,
  Drawer,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { colorPalette } from "../../../themes/theme";
import { ButtonRectangle } from "../../../themes/components/button";
import axiosInterceptor from "../../../config/https";
import apiUrl from "../../../url/apiUrl";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PodRecievedStyle } from "./podRecievedStyle";
import { useSelector } from "react-redux";
import ReactSelect from "react-select";
import { getTeams } from "../../teams/TeamsService.jsx";
import { saveBlob } from "../../../utility/saveBlobImage.js";

const PodRecieved = () => {
  const history = useHistory();
  const { tripId } = useParams();
  const [approxCostDetails, setApproxCostDetails] = useState({
    z: "",
  });
  const [approxCostDetailsArr, setApproxCostDetailsArr] = useState([]);

  const [loadingPage, setLoadingPage] = useState(false);
  const [podSoftCopyAttachment, setPodSoftCopyAttachment] = useState({
    file: "",
    attachment_type: "",
    attachment_tag: "pod_softcopy",
  });
  const [errPodHardCopy, setErrPodHardCopy] = useState("");

  const [odometerReadingAttachment, setOdometerReadingAttachment] = useState({
    file: "",
    attachment_type: "",
    attachment_tag: "odometer_reading",
  });
  const [podRecievedFormData, setPodRecievedFormData] = useState({
    remarks: "",
    name_of_person_receiving_pod: "",
    email_of_person_receiving_pod: "",
    mobile_number_of_person_receiving_pod: "",
    date_of_hardcopy_pod_received: "",
  });

  const [showSaveButton, setShowSaveButton] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState({});
  const [emailErrorMessage, setEmailErrorMessage] = useState({});
  const [mobileErrorMessage, setMobileErrorMessage] = useState({});
  const [isNameValid, setIsNameValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [teamsSearchTerm, setTeamsSearchTerm] = useState("");
  const [selectedTeam, setSelectedTeam] = useState({});
  const [teamsList, setTeamsList] = useState([]);
  const [maxFileSizeError, setMaxFileSizeError] = useState(false);
  const [podHardCopyAttachment, setPodHardCopyAttachment] = useState({
    file: "",
    attachment_type: "",
    attachment_tag: "pod_hardcopy",
  });
  const [attachmentListArray, setAttachmentListArray] = useState([
    {
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    },
  ]);
  const [
    attachmentListArrayModifiedForApiCall,
    setAttachmentListArrayModifiedForApiCall,
  ] = useState([]);

  const selectedTripFromRedux = useSelector((state) => state.trip.selectedTrip);

  useEffect(() => {
    if (
      podRecievedFormData.date_of_hardcopy_pod_received &&
      podRecievedFormData.mobile_number_of_person_receiving_pod !== "" &&
      podRecievedFormData.email_of_person_receiving_pod !== "" &&
      podSoftCopyAttachment.name_of_person_receiving_pod !== "" &&
      podHardCopyAttachment.file
    ) {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }, [podRecievedFormData, podHardCopyAttachment]);

  const handlePodRecievedFormInput = (e) => {
    const { name, value } = e.target;
    setPodRecievedFormData({ ...podRecievedFormData, [name]: value });
  };

  const handleGoToTrips = () => {
    localStorage.setItem("FlagForApiCall", false);
    history.push("/ftl-trips");
  };

  const validateField = (field) => {
    if (field.target.name === "name_of_person_receiving_pod") {
      const nameRegex = /^[a-zA-Z0-9) (.-]+$/g;
      if (nameRegex.test(field.target.value)) {
        setNameErrorMessage({ ...nameErrorMessage, nameError: "" });
        setIsNameValid(true);
      } else {
        setNameErrorMessage({
          ...nameErrorMessage,
          nameError: `Only alphabets, numbers and special characters . , - ( ) are allowed`,
        });
        setIsNameValid(false);
      }
    }
    if (field.target.name === "mobile_number_of_person_receiving_pod") {
      if (field.target.value.length !== 10) {
        setMobileErrorMessage({
          ...mobileErrorMessage,
          invalidMobile: "Please Enter Valid Mobile Number",
        });
        setIsMobileValid(false);
      } else {
        setMobileErrorMessage({ ...mobileErrorMessage, invalidMobile: "" });
        setIsMobileValid(true);
      }
    }
    if (
      field.target.name === "email_of_person_receiving_pod" &&
      field.target.value !== ""
    ) {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      if (!emailRegex.test(field.target.value)) {
        setEmailErrorMessage({
          ...emailErrorMessage,
          invalidEmail: "Please Enter Valid Email",
        });
        setIsEmailValid(false);
      } else {
        setEmailErrorMessage({ ...emailErrorMessage, invalidEmail: "" });
        setIsEmailValid(true);
      }
    }
  };

  useEffect(() => {
    let array = [];
    attachmentListArray.map((ele, index) => {
      let num = index + 3;
      let tagname = "tag_" + num;
      let attachmenttype = "attachment_type_" + num;
      let file = "file_" + num;
      if (ele.attachment_file) {
        array.push({
          [tagname]: ele.attachment_tag,
          [attachmenttype]:
            ele?.attachment_file?.type === "image/jpeg" ||
            ele?.attachment_file?.type === "image/png"
              ? "image"
              : "pdf",
          [file]: ele.attachment_file,
        });
      }
    });
    setAttachmentListArrayModifiedForApiCall(array);
  }, [attachmentListArray]);

  useEffect(() => {
    let maxfileSize;
    attachmentListArray.map((attachment) => {
      console.log(
        attachment.attachment_file?.size,
        "attachment.attachment_file"
      );
      if (
        attachment.attachment_file?.size > process.env.REACT_APP_MAX_FILE_SIZE
      ) {
        return (maxfileSize = true);
      }
      setMaxFileSizeError(maxfileSize);
    });
  }, [attachmentListArray]);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 800px)"
  ).matches;

  const handleFormSubmit = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("trip_status", "hardcopy_pod_received");

    formData.append("remarks", podRecievedFormData.remarks);
    formData.append(
      "date_of_hardcopy_pod_received",
      podRecievedFormData.date_of_hardcopy_pod_received
    );
    formData.append(
      "name_of_person_receiving_pod",
      podRecievedFormData.name_of_person_receiving_pod
    );
    formData.append(
      "email_of_person_receiving_pod",
      podRecievedFormData.email_of_person_receiving_pod
    );
    formData.append(
      "mobile_number_of_person_receiving_pod",
      podRecievedFormData.mobile_number_of_person_receiving_pod
    );
    formData.append("file", podHardCopyAttachment.file);
    formData.append("tag", podHardCopyAttachment.attachment_tag);
    formData.append("attachment_type", podHardCopyAttachment.attachment_type);

    var config = {
      method: "patch",
      url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
        tripId
      )}/hardcopy-pod-received`,
      headers: {
        "Content-Type": "Multipart/form-data",
      },
      data: formData,
    };

    return new Promise((resolve, reject) => {
      axiosInterceptor(config)
        .then(function (response) {
          if (response.status == 200) {
            resolve(response);
            history.push("/ftl-trips");
            toast.success("Status changed successfully!");
            setIsLoading(false);
          }
        })
        .catch(function (e) {
          if (e.response.status === 403) {
            localStorage.clear();
            window.location.reload();
          } else if (e.response.status === 401) {
            toast.error("401: Not enough permissions");
            setIsLoading(false);
            resolve(e.response);
          }
          toast.error(e.response.data.detail);
          resolve(e.response);
          setIsLoading(false);
          //
        });
    });
  };

  const fetchTeams = async (searchValue, status, page) => {
    try {
      const response = await getTeams(searchValue, status, page);
      if (response.status === 200) {
        setTeamsList(response.data.teams_list);
      } else if (response.status === 401) {
        toast.error("401: Not enough permissions to fetch teams list");
      }
    } catch (error) {
      console.log("Getting error while fetching teams list," + error);
    }
  };

  useEffect(() => {
    if (teamsSearchTerm.length > 2 || teamsSearchTerm == "") {
      fetchTeams(teamsSearchTerm, true, 1);
    }
  }, [teamsSearchTerm]);

  const handleOnFileChange = async (e) => {
    const newFile = await saveBlob(e.target.files[0]);

    if (newFile.size > process.env.REACT_APP_MAX_FILE_SIZE) {
      setMaxFileSizeError(true);
    } else {
      setMaxFileSizeError(false);
    }
    if (e.target.name === "pod_hardcopy") {
      setPodHardCopyAttachment({
        ...podHardCopyAttachment,
        file: newFile,
        attachment_type: newFile.type === "application/pdf" ? "pdf" : "image",
      });
    }
  };

  return (
    <PodRecievedStyle>
      <ToastContainer style={{ marginTop: "40px" }} />
      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Goods Delivered" />
        </div>
      ) : (
        <div
          style={{ width: "15%", height: "100vh", position: "fixed" }}
          className="complete-trip-sidebar"
        >
          <SideBarMain />
        </div>
      )}

      {loadingPage ? (
        <div className="goods-delivered-loading-image">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              // backgroundColor: "grey",
            }}
          >
            {" "}
            <img
              src={success}
              alt=""
              width="120px"
              height="90px"
              style={{ marginTop: "200px" }}
            />
            <h6 style={{ color: "grey" }}>loading data...</h6>
          </div>
        </div>
      ) : (
        <div className="goods-delivered-form">
          <div className="goods-delivered-form-header">
            <ArrowBack
              style={{ cursor: "pointer" }}
              onClick={handleGoToTrips}
            />{" "}
            <h4 className="complete-trip-head-text">POD Recieved</h4>
          </div>
          <div className="goods-delivery-input-grid">
            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Name of Person Receiving POD<sup>*</sup>
              </span>
              <ReactSelect
                value={
                  selectedTeam
                    ? {
                        value: selectedTeam.value,
                        label: selectedTeam.name,
                      }
                    : ""
                }
                onChange={(event) => {
                  console.log(event);
                  setSelectedTeam(event);
                  setPodRecievedFormData({
                    ...podRecievedFormData,
                    name_of_person_receiving_pod: event.name,
                    email_of_person_receiving_pod: event.email,
                    mobile_number_of_person_receiving_pod: event.mobile_number,
                  });
                }}
                onInputChange={(event) => {
                  setTeamsSearchTerm(event);
                }}
                options={teamsList.map((team) => ({
                  value: team.email,
                  name: team.name,
                  email: team.email,
                  mobile_number: team.mobile_number,
                  label: (
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "grid",
                          width: "100%",
                          alignItems: "center",
                          gridTemplateColumns: "60px auto",
                        }}
                      >
                        <div style={{ fontWeight: "500", color: "#454545" }}>
                          Name:
                        </div>
                        <div>{team.name}</div>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          width: "100%",
                          alignItems: "center",
                          gridTemplateColumns: "60px auto",
                        }}
                      >
                        <div style={{ fontWeight: "500", color: "#454545" }}>
                          Phone:
                        </div>
                        <div>{team.mobile_number}</div>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          width: "100%",
                          alignItems: "center",
                          gridTemplateColumns: "60px auto",
                        }}
                      >
                        <div style={{ fontWeight: "500", color: "#454545" }}>
                          Email:
                        </div>
                        <div>{team.email}</div>
                      </div>
                    </div>
                  ),
                }))}
                placeholder="Search Person Receiving POD"
              />
              {Object.keys(nameErrorMessage).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{nameErrorMessage[key]}</h6>
                );
              })}
            </div>
            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Date of POD Received<sup>*</sup>
              </span>
              <TextField
                size="small"
                autoComplete="off"
                className="goods-delivery-input-field"
                variant="outlined"
                type="date"
                inputProps={{
                  max: new Date().toISOString().split("T")[0],
                  min: selectedTripFromRedux?.trip_additional_data?.unloaded_on.split(
                    "T"
                  )[0],
                }}
                value={podRecievedFormData.date_of_hardcopy_pod_received}
                name="date_of_hardcopy_pod_received"
                onChange={(e) => {
                  handlePodRecievedFormInput(e);
                }}
              />
            </div>
            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                POD Hard Copy<sup>*</sup>
              </span>
              <TextField
                size="small"
                id="dropdown"
                disabled
                autoComplete="off"
                className="input_field_form "
                variant="outlined"
                placeholder="Upload POD Soft Copy"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        cursor: "pointer",
                        color: colorPalette.primaryColor,
                        fontWeight: "600",
                      }}
                    >
                      <button
                        className="upload_button"
                        style={{
                          width: "40px",
                          height: "40px",
                          marginRight: "-14px",
                        }}
                      >
                        <input
                          type="file"
                          name="pod_hardcopy"
                          id="pod_hardcopy"
                          accept="image/png, image/jpeg, application/pdf"
                          hidden
                          onChange={(e) => handleOnFileChange(e)}
                          style={{
                            backgroundColor: colorPalette.secondaryWhite,
                          }}
                        />
                        <label htmlFor="pod_hardcopy">
                          <img
                            src={UploadImage}
                            alt=""
                            width="20px"
                            height="20px"
                          />
                        </label>
                      </button>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              <span className="file_name">
                {podHardCopyAttachment.file.name}
              </span>
              {Object.keys(errPodHardCopy).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errPodHardCopy[key]}</h6>
                );
              })}
            </div>

            <div className="goods-delivery-input">
              <span className="goods-delivery-input-span">
                Remarks<sup>*</sup>
              </span>
              <textarea
                name="remarks"
                id="remarks"
                cols="30"
                rows="2"
                style={{
                  width: "100%",
                  border: "1px solid grey",
                  padding: "15px",
                  marginBottom: "10px",
                }}
                onChange={(e) => handlePodRecievedFormInput(e)}
                value={podRecievedFormData.remarks}
              ></textarea>
            </div>

            <div className="good-delivery-save-button full-width">
              <ButtonRectangle
                onClick={handleFormSubmit}
                style={
                  ({ width: "20%" },
                  (showSaveButton && podRecievedFormData.remarks) ||
                  maxFileSizeError
                    ? { cursor: "pointer" }
                    : { cursor: "not-allowed", backgroundColor: "#454545" })
                }
                disabled={
                  !(showSaveButton && podRecievedFormData.remarks) ||
                  isLoading ||
                  maxFileSizeError
                }
              >
                {isLoading ? (
                  <CircularProgress size={25} color="inherit" />
                ) : (
                  "Save"
                )}
              </ButtonRectangle>
            </div>
          </div>
        </div>
      )}
    </PodRecievedStyle>
  );
};

export default PodRecieved;
