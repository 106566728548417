import axios from "axios";
import { readToken, readLoginData } from "../helper/dataDecryptionHelper";
// import { readAuthToken, readToken } from "../utils/LoginDataHelper";
import apiUrl from "../url/apiUrl";
import CryptoJS from "crypto-js";

export const axiosInstance = axios.create({
  baseURL: apiUrl.interceptorBaseUrl,
});

// const website_url = window.location.origin;
// const website_url =
//   "http://logistics.faarms.com.s3-website.ap-south-1.amazonaws.com";
const website_url = localStorage.getItem("website_url");
// const website_url =
//   "http://logistics.faarms.com.s3-website.ap-south-1.amazonaws.com";
// // const website_url = "https://leapsupply.in/customer";
// const website_url = "https://customers.leapsupply.in";

function b64EncodeUnicode(str) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode("0x" + p1);
    })
  );
}

const axiosInterceptor = axios.create({});

axiosInterceptor.interceptors.request.use((config) => {
  //
  let tokensData = readToken();
  config.headers["x-app-token"] = `${tokensData}`;
  config.headers["Authorization"] = `Bearer ${readLoginData().AUTH_TOKEN}`;
  // if (!config.url.includes("http://13.234.204.225:8000/pincode-services")) {
  //   config.headers["cache-control"] = "no-cache";
  // }
  config.headers["originated_by"] = "leap_supply";
  config.headers["cache-control"] = "no-cache";
  return config;
});

axiosInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status == 401) {
        localStorage.removeItem("x_token");
        let headerData = JSON.stringify({
          app_version: process.env.REACT_APP_VERSION,
          website_url: website_url,
          client: process.env.REACT_APP_CLIENT,
          // app_name: "Admin Panel",
        });
        const encodedString = b64EncodeUnicode(headerData);
        let apiResponse = await axios.get(`${apiUrl.userServicesBaseUrl}`, {
          headers: { data: encodedString },
        });

        if (apiResponse.status == 200) {
          const xToken = {
            X_TOKEN: apiResponse.data.token,
          };

          let encrypted_data = CryptoJS.AES.encrypt(
            JSON.stringify(xToken),
            apiUrl.SALT
          ).toString();

          localStorage.setItem("x_token", encrypted_data);
        } else if (apiResponse.status === 403) {
          localStorage.clear();
          window.location.reload();
        }

        error.config.headers["x-app-token"] = `${apiResponse.data.token}`;
        // config.headers["Authorization"] = `Bearer${readAuthToken()}`;
        return axios(error.config);
      } else if (error.response.status === 403) {
        localStorage.clear();
        window.location.reload();
      } else if (error.response.status === 422) {
        sessionStorage.setItem("IS_400_ERROR", "true");
        sessionStorage.setItem(
          "ERROR_DETAIL_OBJ",
          JSON.stringify({
            endpoint: error.config.url,
            errorFromApi: error.response.data,
            method: error.config.method,
            statusCode: error.response.status,
          })
        );
        return Promise.reject(error);
      } else if (
        error.response.status === 400 &&
        (error.response.data.detail
          .split(" ")
          .some((item) => item === "Something") ||
          error.response.data.detail
            .split(" ")
            .some((item) => item === "something"))
      ) {
        sessionStorage.setItem("IS_400_ERROR", "true");
        sessionStorage.setItem(
          "ERROR_DETAIL_OBJ",
          JSON.stringify({
            endpoint: error.config.url,
            errorFromApi: error.response.data,
            method: error.config.method,
            statusCode: error.response.status,
          })
        );
        return Promise.reject(error);
      }
    } else if (error.message === "Network Error") {
      sessionStorage.setItem("IS_400_ERROR", "true");
      sessionStorage.setItem(
        "ERROR_DETAIL_OBJ",
        JSON.stringify({
          endpoint: error.config.url,
          errorFromApi: "Internal Server Error",
          method: error.config.method,
          statusCode: 500,
        })
      );
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default axiosInterceptor;
