import React from "react";
import apiUrl from "../../url/apiUrl.js";
import axios from "axios";
import axiosInterceptor from "../../config/https.js";

// export const getTripPayoutTypeMonthly = async (month, vehicle_number) => {
//   try {
//     const tripListResponse = await axios.get(
//       `${apiUrl.onboardingBaseUrl}/v1/payments/monthly-wise?trip_month=${month}&vehicle_number=${vehicle_number}`
//     );
//
//     if (tripListResponse.status == 200) {
//       return tripListResponse.data;
//     }
//   } catch (e) {
//     if (e.response.status === 403 || e.response.status === 401) {
//       localStorage.clear();
//       window.location.reload();
//     }
//
//   }
// };
export const getTripPayoutTypeMonthly = async (month, vehicle_number, page) => {
  try {
    const tripListResponse = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/payments/monthly-wise-vehicles-list?trip_month=${month}&vehicle_number=${vehicle_number}&current_page=${page}&page_size=20`
    );

    if (tripListResponse.status == 200) {
      return tripListResponse.data;
    }
  } catch (e) {
    // if (e.response.status === 403) {
    //   localStorage.clear();
    //   window.location.reload();
    // }
  }
};
// export const getTripPayoutTypeMonthlyNumber = async (month, vehicle_number) => {
//   try {
//     const tripListResponseNo = await axios.get(
//       `${apiUrl.onboardingBaseUrl}/v1/payments/monthly-wise-vehicle-payment-details?trip_month=${month}&vehicle_number=${vehicle_number}`
//     );
//
//     if (tripListResponseNo.status == 200) {
//       return tripListResponseNo.data;
//     }
//   } catch (e) {
//     if (e.response.status === 403 || e.response.status === 401) {
//       localStorage.clear();
//       window.location.reload();
//     }
//
//   }
// };
export const getTripWiseList = async (page, tripcode, paymentStatus) => {
  try {
    const tripWiseListResponse = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/payments/trip_wise?trip_code=${tripcode}&trip_payment_status=${paymentStatus}&current_page=${page}&page_size=20`
    );

    if (tripWiseListResponse.status == 200) {
      return tripWiseListResponse.data;
    }
  } catch (e) {
    // if (e.response.status === 403) {
    //   localStorage.clear();
    //   window.location.reload();
    // }
  }
};
