import React from "react";
import { OurPresenceStyle } from "./our_presence.js";

const Our_Presence = () => {
  return (
    <OurPresenceStyle className="our_presence" id="presence">
      <div className="our_presence_heading">Our Presence</div>
      <div className="our_presence_map">
        <img src="/assets/landingPageAssets/map.svg" alt="" />
      </div>
    </OurPresenceStyle>
  );
};

export default Our_Presence;
