import React, { useEffect, useState } from 'react'
import axiosInterceptor from "../../config/https.js";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter.js";
import apiUrl from '../../url/apiUrl.js';
import { MenuItem, TextField } from '@mui/material';
import {
    CreateNewUser,
    WareHouse_list_main_container,
    Warehouse_list_warehouse_drawer_container,
} from "../../pages/warehouses/warehouse-list.js";


const Pincode = ({
    parentStyle,
    handlePincode,
    pincodeError,
    inputDesign,
    lastPincode,
    pincodeIndex,
    inputWidth,
    countryName
}) => {
    const [countryListArray, setCountryListArray] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("0");
    const [errorCountry, setErrorCountry] = useState("");
    const [pincode, setPincode] = useState("");
    const [errorPincode, setErrorPincode] = useState("");
    const [maxLength, setMaxLength] = useState(6);


    const callCountryListApi = async () => {
        localStorage.removeItem('COUNTRY_LIST');
        try {
            const countryListResponse = await axiosInterceptor.get(
                `${apiUrl.countryBaseUrl}`
            );

            if (countryListResponse.status == 200) {
                let countryList = countryListResponse.data.items;
                localStorage.setItem('COUNTRY_LIST', JSON.stringify(countryList));
                let filterArray = countryList.filter((item) => item.name.toLowerCase() === "india");
                setMaxLength(filterArray[0].pincode_length);
                // setSelectedCountry(JSON.stringify(filterArray[0]))
                setCountryListArray(countryList)
                return countryListResponse;
            }
        } catch (e) {
            if (e.response.status === 403) {
                localStorage.clear();
                window.location.reload();
            }
            return e.response;
        }
    };

    useEffect(() => {
        checkCountryListLocallyStorage()
    }, [])


    const checkCountryListLocallyStorage = async () => {
        let data = localStorage.getItem("COUNTRY_LIST");
        if (data) {
            data = JSON.parse(data);
            setCountryListArray(data)
            let filterArray = data.filter((item) => item.name.toLowerCase() === "india");
            // setSelectedCountry(JSON.stringify(filterArray[0]))
        } else {
            await callCountryListApi()
        }
    }

    useEffect(() => {
        if (lastPincode) {
            setPincode(lastPincode)
        }
        if (pincodeError) {
            setErrorPincode(pincodeError)
        }

    }, [lastPincode, pincodeError]);

    const onChangeCountry = (country) => {
        setSelectedCountry(country)
        let data = JSON.parse(country);
        setMaxLength(data.pincode_length)
        setPincode("")
        setErrorPincode("")
        setErrorCountry("");

    };

    const sendData = (pincode, erroMsg) => {
        handlePincode(pincode, erroMsg, pincodeIndex)
    }


    const onChangePincode = (pincode) => {
        let selectedData = JSON.parse(selectedCountry)
        setPincode(pincode);
        if (pincode) {
            if (selectedData) {
                let pincodeType = selectedData.pincode_characters_type.toLowerCase();
                let pincodeLength = selectedData.pincode_length;
                let regex = "";
                regex = /^[0-9]+$/
                if (pincodeType === "numeric") {
                    if (regex.test(pincode) && pincodeLength === pincode.length) {
                        sendData(pincode, "")
                        setErrorPincode("");
                    } else {
                        sendData(pincode, "Please enter valid  pincode")
                        setErrorPincode("Please enter valid  pincode");
                    }

                } else if (pincodeType === "alphanumeric") {
                    regex = /^[a-zA-Z0-9]+$/
                    if (regex.test(pincode) && pincodeLength === pincode.length) {
                        sendData(pincode, "")
                        setErrorPincode("");
                    } else {
                        sendData(pincode, "Please enter valid pincode")
                        setErrorPincode("Please enter valid pincode");
                    }
                } else if (pincodeType === "alphabets") {
                    regex = /^[a-zA-Z]+$/
                    if (regex.test(pincode) && pincodeLength === pincode.length) {
                        sendData(pincode, "")
                        setErrorPincode("");
                    } else {
                        sendData(pincode, "Please enter valid pincode")
                        setErrorPincode("Please enter valid pincode");
                    }
                }
            } else {
                setErrorPincode("Please enter valid pincode")
            }
        } else {
            setErrorPincode("")
        }


    }

    return (
        <div style={parentStyle} >
            <div className="warehouse-list-drawer-row" style={{ width: "100%", }}>
                <div className="warehouse-list-input-group" style={{ width: "100%" }}>
                    <h4 className="warehouse-list-input-label">
                        Country <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                        size="small"
                        style={{ width: inputWidth ? inputWidth : "80%" }}
                        autoComplete="off"
                        className="warehouse-list-input"
                        select
                        variant="outlined"
                        placeholder="Enter warehouse name"
                        value={selectedCountry}
                        onChange={(e) => {
                            onChangeCountry(e.target.value)
                        }}
                    >
                        <MenuItem value="0" disabled>
                            Select Country
                        </MenuItem>
                        {countryListArray.map((country) => {
                            return <MenuItem value={JSON.stringify(country)}>{country.name}</MenuItem>;
                        })}
                    </TextField>
                    <div className="warehouse-error">
                        {/* {createWarehouseDetails.village.length >= 2
                        ? null
                        : errWarehouseDetails.errVillage} */}
                    </div>
                </div>
            </div>
            <div className="warehouse-list-drawer-row" style={inputDesign}>
                <div className="warehouse-list-input-group" style={{ width: "100%" }}>
                    <h4 className="warehouse-list-input-label">
                        Pincode <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                        size="small"
                        style={{ width: inputWidth ? inputWidth : "80%" }}
                        autoComplete="off"
                        defaultValue={lastPincode}
                        inputProps={{ maxLength: maxLength }}
                        className="warehouse-list-input"
                        variant="outlined"
                        placeholder="Enter Pincode"
                        disabled={!selectedCountry || selectedCountry === "0"}
                        // type="number"
                        onWheel={(e) => e.target.blur()}
                        value={pincode}
                        onChange={(e) => {
                            onChangePincode(e.target.value)
                        }}
                    />
                    <div className="" style={{ color: 'red' }}>
                        {errorPincode ? errorPincode : null}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Pincode