import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../../themes/theme";

export const AcceptQuotesDrawer = styled.div`
  width: 100%;
  padding: 20px;

  .accept_drawer_heading {
    font-size: 20px;
    font-weight: ${FontWeight.semibold};
  }

  .add_remark {
    width: 100%;
    height: 200px;
    margin-top: 20px;

    textarea {
      width: 100%;
      height: 100%;
      resize: none;
      padding: 20px;
      border: 1px solid #e5e5e5;

      :focus {
        border-color: #999;
      }
    }
  }

  .accept_button {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: end;

    button {
      width: 130px;
      padding: 14px;
      cursor: pointer;
      background-color: ${colorPalette.primaryColor};
      border: none;
      border-radius: 100px;
      font-weight: ${FontWeight.semibold};
      color: white;
    }
  }
`;

export const RightbarIndentStyle = styled.div`
  height: 100%;
  width: 100%;
  padding: 20px;
  box-shadow: ${colorPalette.boxShadowPrimary};
  overflow-y: scroll;
  background-color: white;

  .indent_address_container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .indent_heading {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: ${typography.paragraph};
      font-weight: ${FontWeight.bold};
      color: ${colorPalette.primaryColor};
      text-transform: capitalize;

      .indent_edit {
        font-weight: ${FontWeight.regular};
        color: ${colorPalette.paragraphColor};
        height: 100%;
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
      }
    }

    .indent_address_details {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .from,
    .to {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      position: relative;

      .indent_address_icon {
        color: ${colorPalette.paragraphColor};
      }

      .indent_address {
        width: 100%;
        padding: 15px 10px;
        color: ${colorPalette.paragraphSecondaryolor};
        font-size: ${typography.paragraph};
        border: 1px solid ${colorPalette.paragraphColor};
        border-radius: 6px;
      }
    }

    .from .location_dotted_line {
      position: absolute;
      top: 35px;
      left: 11px;
      width: 5px;
      height: 100%;
      /* background-color: ${colorPalette.paragraphColor}; */
      border-left: 2px dashed ${colorPalette.paragraphColor};
    }
  }

  .date_and_quantity {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.semibold};
    font-size: ${typography.paragraph};
    margin-top: 10px;
    background: rgba(76, 217, 100, 0.1);
    padding: 10px;
  }

  .indents_details {
    width: 100%;
    padding: 10px;
    margin-top: 10px;

    .indent_details_heading {
      margin-top: 10px;
      width: 100%;
      font-weight: ${FontWeight.semibold};
      font-size: ${typography.heading5};
      margin-bottom: 10px;
    }

    .indent_details_discription {
      width: 100%;
      background: rgba(76, 217, 100, 0.08);
      border-radius: 6px;
      padding: 10px;
      font-size: ${typography.heading5};
      overflow-x: scroll;

      .indent_detail_left_right {
        width: 100%;
        display: grid;
        grid-template-columns: 130px 10px 100px;
        color: ${colorPalette.paragraphSecondaryolor};
        gap: 10px;
        font-weight: ${FontWeight.medium};
        margin-bottom: 5px;
      }
    }
  }

  .attachements {
    margin-top: 20px;
    width: 100%;
    background-color: #4cd96414;
    padding: 14px 20px;
    display: grid;
    gap: 20px;
    grid-template-columns: 40px auto 40px;
    .attachements_icon {
      /* height: 100%; */
      img {
        width: 80%;
        height: 80%;
        object-fit: contain;
      }
    }

    .attachements_heading {
      width: 100%;
      .attachement_title {
        color: ${colorPalette.paragraphSecondaryolor};
        font-weight: ${FontWeight.semibold};
        font-size: ${typography.heading5};
        cursor: pointer;
      }

      .img_name {
        font-size: ${typography.paragraph};
        color: ${colorPalette.paragraphSecondaryolor};
      }
    }

    .attachements_button {
      height: 80%;
      margin: auto 0;

      img {
        height: 70%;
        width: 70%;
        object-fit: contain;
        cursor: pointer;
      }
    }
  }

  .sent_section_message,
  .unfulfilled_section_message {
    width: 100%;
    background-color: #ffd12d33;
    padding: 10px;
    font-size: ${typography.heading5};
    display: flex;
    gap: 10px;
    color: ${colorPalette.paragraphSecondaryolor};
  }

  .fulfilled_section {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 300px;

    .quotation_heading {
      font-size: ${typography.heading5};
      font-weight: ${FontWeight.semibold};
    }
    .quotation_card {
      width: 100%;
      margin-top: 10px;
      background-color: #4cd96414;
      padding: 10px;
      font-size: ${typography.heading5};
      color: ${colorPalette.paragraphSecondaryolor};

      .card_title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: ${FontWeight.medium};
      }

      .card_message {
        width: 100%;
        margin-top: 10px;
      }
    }

    .quotation_button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 20px;

      button {
        padding: 10px 20px;
        font-weight: ${FontWeight.semibold};
        border: 1px solid ${colorPalette.transparent};
        cursor: pointer;
        margin-top: 10px;
        width: 100px;
        border-radius: 100px;
      }

      .accept {
        background-color: ${colorPalette.primaryColor};
        color: ${colorPalette.white};
      }

      .reject {
        color: ${colorPalette.primaryRed};
        border-color: ${colorPalette.primaryRed};
        background-color: ${colorPalette.transparent};
      }
    }
  }
`;
