import React from "react";
import pagenotfound from "../../assets/errorpage/some_thing_went_wrong.png";
import { NavLink } from "react-router-dom";
import { PageNotFoundStyle } from "./pagenotfound.js";

function PageNotFound() {
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  return (
    <PageNotFoundStyle className="pagenotfound-main">
      <div className="text-container">
        <div className="errorpage-text-container">
          <h3 className="errorpage-text">Something Went Wrong</h3>
          <h5 className="errorpage-text-subheading">
            There has been an internal error, Please try again
          </h5>
        </div>
        <div className="errorpage-buttondiv">
          <button className="gohome-button">
            {localStorage.getItem("website_url") ===
            process.env.REACT_APP_CUSTOMER_WEBSITE_URL ? (
              <NavLink
                to="/customer/customer-indents"
                className="link sidebarSingle"
                style={{ color: "white" }}
              >
                Go Home
              </NavLink>
            ) : localStorage.getItem("website_url") ===
              process.env.REACT_APP_WEBSITE_URL ? (
              <NavLink
                to="/dashboard"
                className="link sidebarSingle"
                style={{ color: "white" }}
              >
                {" "}
                Go Home
              </NavLink>
            ) : (
              <NavLink
                to="/"
                className="link sidebarSingle"
                style={{ color: "white" }}
              >
                {" "}
                Go Home
              </NavLink>
            )}
          </button>
        </div>
      </div>
      <div className="image-main-conatiner">
        <img
          style={{
            width: isMobile ? "80%" : "100%",
            height: isMobile ? "100%" : "100%",
            display: isMobile ? "none" : "block",

            // backgroundColor: "red",
          }}
          src={pagenotfound}
          alt=""
        />
      </div>
    </PageNotFoundStyle>
  );
}

export default PageNotFound;
