import { React, useContext, useEffect, useState } from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import { onboardRegisterPost } from "./OnboardServices";
import axios from "axios";
import Alert from "@mui/material/Alert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Component_style } from "../../themes/component_style";
import { useHistory } from "react-router-dom";
import apiUrl from "../../url/apiUrl";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { onboardData, updateCarrierId } from "../../redux/onboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { getCarrierListById, onboardRegisterPut } from "./OnboardServices";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Input, InputAdornment } from "@mui/material";
import UploadImage from "../../assets/upload.svg";
import axiosInterceptor from "../../config/https";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";
import OnboardingPublic from "./OnboardPublic";
import { MyContext } from "../../components/enumContext/EnumContextComponent.jsx";
function Onboarding() {
  const dispatch = useDispatch();
  let history = useHistory();
  const [name, setName] = useState("");
  const [carrierType, setCarrierType] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [pan, setPan] = useState("");

  const [gstin, setGstin] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [bankAccNumber, setBankAccNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [holderName, setHolderName] = useState("");
  const [accType, setAccType] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");

  const [village, setVillage] = useState("");
  const [errorpincodeNotAvailable, setErrorpincodeNotAvailable] = useState("");

  const [landmark, setLandmark] = useState("");
  const [villageList, setVillageList] = useState([]);

  const [bankAccTypeList, setBankAccTypeList] = useState([]);
  const [active, setActive] = useState("");
  const [editMode, setEditMode] = useState(false);

  const [circularProgress, setCircularProgress] = useState(false);
  const [dataExistMsg, setDataExistMsg] = useState("");
  const [submitButtonEnable, setSubmitButtonEnable] = useState(false);
  const carrierId = useSelector((state) => state.onboard.carriedIdEdit);
  const [termsMessage, setTermsMessage] = useState("");
  const { enums } = useContext(MyContext);

  // ++++++++++++===acc type+++++++++++++
  const getAccType = async () => {
    // var address = await axiosInterceptor.get(
    //   `${apiUrl.onboardingBaseUrl}/v1/enums/bank-account-type`
    // );

    // setBankAccTypeList(address.data);
    setBankAccTypeList(enums?.bank_account_type ? enums.bank_account_type : []);
  };
  useEffect(() => {
    getAccType();
  }, [enums]);
  //++++++++++++++++++++++++++

  const getCarrierDataByIdEdit = async (ids) => {
    const carrierResponseDataEdit = await getCarrierListById(ids);

    setName(carrierResponseDataEdit.name);
    setCarrierType(carrierResponseDataEdit.transporter_type);
    setPhoneNumber(carrierResponseDataEdit.mobile);
    setEmail(carrierResponseDataEdit.email);
    setPan(carrierResponseDataEdit.pan_number);
    setGstin(carrierResponseDataEdit.gstin_number);
    setAadhar(carrierResponseDataEdit.aadhar_number);
    setBankAccNumber(carrierResponseDataEdit.bank_account_number);
    setHolderName(carrierResponseDataEdit.account_holder_name);
    setBankName(carrierResponseDataEdit.bank_name);
    setIfsc(carrierResponseDataEdit.ifsc_code);
    setAccType(carrierResponseDataEdit.bank_account_type);
    setLandmark(carrierResponseDataEdit.landmark);
    setActive(carrierResponseDataEdit.is_active);
    setPincode(carrierResponseDataEdit.address.postcode);
    setVillage(carrierResponseDataEdit.address.village);
    setLandmark(carrierResponseDataEdit.address.landmark);
    setEditMode(true);

    // setName(carrierResponseDataEdit.state);
    // setName(carrierResponseDataEdit.district);
    // setName(carrierResponseDataEdit.village);
    // setName(carrierResponseDataEdit.landmark);

    // setCarrierListDataById(carrierResponseData);
  };
  // useEffect(() => {
  //   if (carrierId) {
  //     getCarrierDataByIdEdit(carrierId);
  //   }
  //   return () => {
  //     dispatch(updateCarrierId(""));
  //   };
  // }, []);

  //+++++++++++++address from pincode+++++++++++++

  const getAddressFromPincode = async (pincode) => {
    try {
      var address = await axiosInterceptor.get(
        `${apiUrl.vilagePincodeUrl}/${prefixSalt()}-${convertToBase64(pincode)}`
      );

      setState(address.data.state);
      setDistrict(address.data.district);
      setVillageList(address.data.area);
    } catch (e) {
      if (e.response.status == 400) {
        setErrorpincodeNotAvailable("Pincode is not Serviceable.");
        setErrorpincode("");
      }
    }

    // setNewVillagesList(address.data.office[0].villages);
    // }
  };

  useEffect(() => {
    if (pincode.length == 6) {
      getAddressFromPincode(pincode);
    }
  }, [pincode]);

  // +++++++++++++++++++++++++++++++++++++++

  // POST FOR ONBOARD=================
  const handleSubmit = async () => {
    setTermsMessage("");

    const isValid = formValidation();

    if (isValid) {
      setCircularProgress(true);
      if (editMode == true) {
        const responseEdit = await onboardRegisterPut(
          name,
          carrierType,
          phoneNumber,
          email,
          carrierType == "registered_transporter" ? gstin : "",
          bankAccNumber,
          bankName,
          ifsc,
          holderName,
          accType,
          active,
          carrierId
        );

        // dispatch(onboardData(response.data));
        if (responseEdit.status == 200) {
          history.push("/transporters");
          dispatch(updateCarrierId(""));
        } else if (responseEdit.response.status == 400) {
          setDataExistMsg(responseEdit.response.data.detail);
          setCircularProgress(false);
        }
      } else {
        const response = await onboardRegisterPost(
          name,
          carrierType,
          phoneNumber,
          email,
          pan,
          carrierType == "registered_transporter" ? gstin : "",
          aadhar,
          bankAccNumber,
          bankName,
          ifsc,
          holderName,
          accType,
          pincode,
          state,
          district,
          village,
          landmark,
          aadharFront,
          aadharBack,
          panCard,
          signature
        );

        if (response.status == 200) {
          history.push("/onboard-success");
          dispatch(onboardData(response.data));
          setCircularProgress(false);
        } else if (response.response.status == 400) {
          setDataExistMsg(response.response.data.detail);
          setCircularProgress(false);
        }
      }

      // setLoadingOnboard(true);

      // setLoadingComplaintButton(false);
    }
    setCircularProgress(false);
  };
  // if (submitButtonEnable) {
  //   setTermsMessage("");
  // }
  const handleTerms = () => {
    setTermsMessage("Please accept Terms and Conditons to proceed further.");
  };

  //================================
  //++++++++++++=form validation++++++++++++++++++++++
  const [errorName, setErrorName] = useState({});
  const [carrierTypeError, setCarrierTypeError] = useState({});
  const [emailError, setEmailError] = useState({});
  const [errorPan, setErrorPan] = useState({});
  const [errorBankName, setErrorBankName] = useState({});
  const [errorgstin, setErrorgstin] = useState({});
  const [erroraadhar, setErroraadhar] = useState({});
  const [errorbankAccNumber, setErrorbankAccNumber] = useState({});
  const [errorifsc, setErrorifsc] = useState({});
  const [errorholderName, setErrorholderName] = useState({});
  const [erroraccType, setErroraccType] = useState({});
  const [errorpincode, setErrorpincode] = useState("");
  const [errorstate, setErrorstate] = useState({});
  const [errorlandmark, setErrorlandmark] = useState({});
  const [errorvillage, setErrorVillage] = useState({});
  const [errorphoneNumber, setErrorphoneNumber] = useState({});
  const [aadharFront, setAadharFront] = useState();
  const [aadharBack, setAadharBack] = useState();
  const [panCard, setPanCard] = useState();
  const [signature, setSignature] = useState();
  const formValidation = () => {
    const errorName = {};
    const carrierTypeError = {};
    const emailError = {};
    const errorPan = {};
    const errorBankName = {};
    const erroraadhar = {};
    const errorgstin = {};
    const errorifsc = {};
    const errorholderName = {};
    const errorbankAccNumber = {};
    const erroraccType = {};
    const errorpincode = {};
    const errorstate = {};
    const errordistrict = {};
    const errorvillage = {};
    const errorphoneNumber = {};
    const errorlandmark = {};
    let isValid = true;

    // if (name.trim().length < 5) {
    //   errorName.shortName = "first name too short";
    //   isValid = false;
    // }
    if (name == "") {
      errorName.emptyName = "Name is mandatory";
      isValid = false;
    } else if (name.length <= 3) {
      errorName.emptyLength = "Name should be min. 3 characters long.";
    }
    if (carrierType == "") {
      carrierTypeError.emptyType = "please select carrier type";
      isValid = false;
    }
    // if (email == "") {
    //   emailError.missingemail = "email is empty.";
    //   isValid = false;
    // }
    // const regexpEmail =
    //   /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;

    // if (email == "") {
    //   emailError.missingemail = "email is missing.";
    //   isValid = false;
    // } else if (!regexpEmail.test(email)) {
    //   emailError.missingChar = "Invalid Email";
    //   isValid = false;
    // }
    const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (!panRegex.test(pan)) {
      errorPan.panerrorChar = "Invalid Pan";
      isValid = false;
    }
    // const bankRegex = /^[A-Za-z\s]+$/;
    // if (bankName == "") {
    //   errorBankName.missingBankName = "Bank Name is mandatory";
    //   isValid = false;
    // } else if (!bankRegex.test(bankName)) {
    //   errorBankName.errorbankname = "Please input alphabet characters only";
    //   isValid = false;
    // }
    const phoneRegex = /^[6-9]{1}[0-9]{9}$/;
    if (phoneNumber.length < 10) {
      errorphoneNumber.phonemissing = "Phone Number should be of 10 digit.";
    } else if (!phoneRegex.test(phoneNumber)) {
      errorphoneNumber.phonemissingFormat = "Invalid Mobile Number";
    }
    if (carrierType !== "registered_transporter") {
      if (aadhar.length < 12) {
        erroraadhar.aadharlength = "Invalid Aadhar Number";
        isValid = false;
      }
    }

    // if (gstin.length < 15) {
    //   errorgstin.gsterror = "Invalid GSTIN number.";
    // }
    const gstinRegex =
      /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z|1-9|A-J|S]{1}[0-9A-Z]{1})$/;

    if (gstin == "" && carrierType == "registered_transporter") {
      errorgstin.errorgstinNumMiss = "GSTIN number is missing.";
      isValid = false;
    } else if (
      !gstinRegex.test(gstin) &&
      carrierType == "registered_transporter"
    ) {
      errorgstin.errorgstinNum = "Invalid GSTIN format";
    }
    if (ifsc == "") {
      errorifsc.ifscError = "IFSC code is mandatory";
    }
    // if (holderName.length == "") {
    //   errorholderName.holdernameerror = "Account holder name is required.";
    // }
    // if (bankAccNumber == "") {
    //   errorbankAccNumber.accNumber = "A/c number is required.";
    // }
    // if (accType == "") {
    //   erroraccType.errorTypeMissing = "A/c type is required.";
    //   isValid = false;
    // }
    if (pincode.length < 6 || pincode == "") {
      errorpincode.errorPincodes = "Pincode is Requied.";
      isValid = false;
    }
    if (village == "") {
      errorvillage.villageError = "Village is required.";
      isValid = false;
    }
    // if (landmark == "") {
    //   errorlandmark.landmarkerror = "Landmark is required.";
    // }
    setErrorName(errorName);
    setCarrierTypeError(carrierTypeError);
    // setEmailError(emailError);
    setErrorPan(errorPan);
    setErrorBankName(errorBankName);
    setErrorphoneNumber(errorphoneNumber);
    setErroraadhar(erroraadhar);
    setErrorgstin(errorgstin);
    setErrorifsc(errorifsc);
    setErrorholderName(errorholderName);
    setErrorbankAccNumber(errorbankAccNumber);
    setErroraccType(erroraccType);
    setErrorpincode(errorpincode);
    setErrorVillage(errorvillage);
    // setErrorlandmark(errorlandmark);
    return isValid;
  };

  // document attachments

  const onFileChange = (event) => {
    if (event.target.id === "aadhar-front") {
      setAadharFront(event.target.files[0]);
    } else if (event.target.id === "aadhar-back") {
      setAadharBack(event.target.files[0]);
    } else if (event.target.id === "pan-card") {
      setPanCard(event.target.files[0]);
    } else if (event.target.id === "signature") {
      setSignature(event.target.files[0]);
    }
  };

  //+++++++++++++++++++++++++++++++++

  const carrier = ["registered_transporter", "individual_transporter"];
  //
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const handleBack = () => {
    setEditMode(false);
  };
  return (
    <Component_style className="main_onboard_container">
      <div className="main_onboard_wrapper">
        {isMobile ? null : (
          <div className="main_onboard_Left_box">
            <SideBarMain />
          </div>
        )}
        <OnboardingPublic />
      </div>
    </Component_style>
  );
}

export default Onboarding;
