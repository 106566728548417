import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../../themes/theme";
import supplyChainImage from "../../../assets/homepage/supply-chain.png";

export const SuppyChainStyle = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  padding: 50px 100px;
  display: flex;
  flex-direction: column;

  .supplyChainBG {
    position: absolute;
    width: 70%;
    height: 500px;
    right: -600px;
    top: 200px;
    background-image: url("/public/assets/landingPageAssets/supply-chain.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .supplyChainHeading {
    font-size: 30px;
    font-weight: ${FontWeight.bold};
  }

  .supplyChainCards {
    width: 80%;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .supplyChainSingleCard {
    width: 250px;
    height: 300px;
    background-color: ${colorPalette.white};
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 10px;
    padding: 20px;
    position: relative;
    overflow: hidden;
    z-index: 3;
    transition: all 0.5s ease;
    cursor: pointer;
  }

  .supplyChainSingleCard:hover:before {
    width: 1000px;
    height: 1000px;
    top: -100px;
    left: -150%;
  }

  .supplyChainSingleCard:hover {
    color: white;
  }

  .supplyChainSingleCard:hover .card-dis {
    color: white;
  }

  .supplyChainSingleCard::before {
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    top: 70%;
    left: -35%;
    background-color: ${colorPalette.primaryColor};
    z-index: -1;
    transition: all 0.5s ease;
  }

  .supplyChainSingleCard .card-title {
    width: 100%;
    display: flex;
    font-size: ${typography.heading3};
    font-weight: ${FontWeight.bold};
    gap: 30px;
    z-index: 4;
  }

  .supplyChainSingleCard .card-dis {
    width: 100%;
    margin-top: 20px;
    color: ${colorPalette.paragraphColor};
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.semibold};
    line-height: 20px;
    z-index: 20;
    transition: all 0.5s ease;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 50px 20px;

    .supplyChainHeading {
      font-size: ${typography.heading2};
    }

    .supplyChainCards {
      width: 100%;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    .supplyChainSingleCard {
      margin-top: 20px;
    }
  }

  @media only screen and (max-width: 1200px) {
    width: 100%;

    .supplyChainCards {
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;
export const LeapsupplyChain = styled.div`
  position: absolute;
  width: 70%;
  height: 500px;
  right: -600px;
  top: 200px;
  background-image: url(${supplyChainImage});
  background-repeat: no-repeat;
  background-size: contain;
`;
