import styled from "styled-components";
import { colorPalette, colorPaletteDark, typography } from "../../themes/theme";

export const Change_password = styled.div`
  .changepassword-main {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .arrow-div {
    /* flex: 1; */
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    /* background-color: aqua; */
    padding: 0rem 1rem;
    justify-content: flex-start;
  }
`;

export const Change_password_form = styled.div`
  /* .change-password-form { */
  /* flex: 11; */
  margin-top: 8rem;
  padding: 0.4rem 0.8rem;
  background-color: ${colorPalette.white};
  /* background-color: antiquewhite; */
  height: 70%;
  width: 27%;
  z-index: 3;
  box-shadow: ${colorPalette.boxShadowPrimary};
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border: 1px solid green;
  /* } */
  .changepassword-text {
    width: 100%;
    font-size: ${typography.heading3};
    display: flex;
    padding: 1rem 0rem;
    align-items: center;
    justify-content: center;
  }
  .changepassword-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 2rem;
  }
  .change_password_botton {
    margin-top: 2rem;
  }
`;
