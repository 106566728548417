import styled from "styled-components";

export const TripListDrawerCardStyle = styled.div`
  width: 100%;
  height: auto;
  padding: 10px;

  .card-heading {
    font-size: 25px;
    font-weight: 500;
    color: #4e4e4e;
  }

  .seprator {
    width: 100%;
    height: 3px;
    background-color: #e3e3e3;
  }

  table,
  th,
  td {
    border: 0.5px solid #a9adab;
    border-collapse: collapse;
  }
  .data-table {
    width: 100%;
    margin-top: 10px;
    font-size: 14px;

    .table-head {
      padding: 6px;
      background-color: #cde1d6;
    }

    .table-body {
      padding: 6px;
      /* border: 0.5px solid #454545;
      border-collapse: collapse; */
    }
  }
`;
