import { Modal as ModalAntd, Popover } from "antd";
import React from "react";
import SomethingWentWrong from "../../components/somethingWentWrong/SomethingWentWrong";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { DashboardStyle, ProfilePopOverStyle } from "./DashboardStyle.js";
import { readLoginData } from "../../helper/dataDecryptionHelper.js";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale,
} from "chart.js";

import { Bar, Doughnut } from "react-chartjs-2";
import { useEffect } from "react";
import { getDashboardData } from "./DashboardServices.jsx";
import { useState } from "react";
import polygonIcon from "../../assets/dashboard_icons/Polygon 1.svg";
import darkThemeIcon from "../../assets/dashboard_icons/theam_icon.svg";
import lightThemeIcon from "../../assets/dashboard_icons/theam_icon_light.svg";
import profileIcon from "../../assets/dashboard_icons/user.svg";
import { useContext } from "react";
import { DarkModeContext } from "../../context/darkModeContext.js";
import { updateOnFlag, updateOnFlagUpdate } from "../../redux/indentSlice";
import { ConvertToIndiaNumberSystem } from "../../helper/ConvertToIndiaNumberSystem.js";
import AnimateNumber from "../../helper/AnimateNumber.jsx";
import LockIcon from "@mui/icons-material/Lock";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.js";

const Dashboard = () => {
  const { toggle, darkMode } = useContext(DarkModeContext);
  let history = useHistory();
  const dispatch = useDispatch();
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    LinearScale,
    BarElement,
    CategoryScale
  );
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  const [dashboardData, setDashboardData] = useState({});
  const [open, setOpen] = React.useState(false);

  const data = {
    labels: [
      "Created",
      "Approved",
      "Intransit",
      "Arrived At Destination",
      "Goods Delivered",
      // "Documents Verified",
      "H/C POD Recieved",
      "Disputed",
      "Invoice Generated",
      "Invoice Sent",
      "Invoice Submitted",
      // "Invoice Closed",
      "Rejected",
      // "Cancelled",
    ],
    datasets: [
      {
        label: "Trips",
        backgroundColor: "#0FBC9E",
        borderRadius: 3,
        borderColor: "#0FBC9E",
        borderWidth: 1,
        data: dashboardData
          ? [
              dashboardData?.ftl_trip_summary?.total_ftl_created_trip_count,
              dashboardData?.ftl_trip_summary?.total_ftl_approved_trip_count,
              dashboardData?.ftl_trip_summary?.total_ftl_intransit_trip_count,
              dashboardData?.ftl_trip_summary
                ?.total_ftl_arrived_at_destination_trip_count,
              dashboardData?.ftl_trip_summary
                ?.total_ftl_goods_delivered_trip_count,
              // dashboardData?.ftl_trip_summary?.total_ftl_approved_trip_count,
              dashboardData?.ftl_trip_summary
                ?.total_ftl_hardcopy_pod_received_trip_count,
              dashboardData?.ftl_trip_summary?.total_ftl_disputed_trip_count,

              dashboardData?.ftl_trip_summary
                ?.total_ftl_invoice_generated_trip_count,
              dashboardData?.ftl_trip_summary
                ?.total_ftl_invoice_sent_trip_count,
              dashboardData?.ftl_trip_summary
                ?.total_ftl_invoice_submitted_trip_count,
              dashboardData?.ftl_trip_summary?.total_ftl_rejected_trip_count,
            ]
          : [],
        gap: "20px",
      },
    ],
  };

  // Configuration options
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: "rgb(255, 99, 132)",
        },
      },
      title: {
        display: true,
        text: "Trips",
        font: {
          size: 18,
          weight: "bold",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            size: 9,
            weight: "bold",
          },
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
  };

  const faarmsData = {
    labels: [
      "Created",
      "Approved",
      "Intransit",
      "Request for completion",
      "Trip Disputed",
      "Trip Rejected",
    ],
    datasets: [
      {
        label: "Trips",
        backgroundColor: "#0FBC9E",
        borderRadius: 3,
        borderColor: "#0FBC9E",
        borderWidth: 1,
        data: dashboardData
          ? [
              dashboardData?.faarms_trip_summary
                ?.total_faarms_created_trip_count,
              dashboardData?.faarms_trip_summary
                ?.total_faarms_approved_trip_count,
              dashboardData?.faarms_trip_summary?.total_faarms_intransit_trip,
              dashboardData?.faarms_trip_summary?.faarms_request_for_completion,
              dashboardData?.faarms_trip_summary?.total_faarms_disputed_trip,
              dashboardData?.faarms_trip_summary
                ?.total_faarms_rejected_trip_count,
            ]
          : null,
        gap: "20px",
      },
    ],
  };

  const pieChartData = {
    labels: dashboardData?.payment_receivables_summary?.customers
      .sort(
        (a, b) => b.current_outstanding_amount - a.current_outstanding_amount
      )
      .slice(0, 5)
      ?.map((item) => item.name),
    datasets: [
      {
        data: dashboardData
          ? dashboardData?.payment_receivables_summary?.customers
              .sort(
                (a, b) =>
                  b.current_outstanding_amount - a.current_outstanding_amount
              )
              .slice(0, 5)
              .map((item) => item.current_outstanding_amount)
          : [],
        backgroundColor: [
          "#0FBC9E",
          "#FFA500",
          "#B492D3",
          "#FFB892",
          "#FEE1A3",
        ],
        hoverBackgroundColor: [
          "#02ac90",
          "#e69600",
          "#a670d8",
          "#ff9d68",
          "#ffd06c",
        ],
      },
    ],
  };

  const pieChartOption = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: "70%",
    plugins: { legend: false },

    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        beginAtZero: true,
      },
    },
  };

  const fetchDashboardData = async () => {
    try {
      const response = await getDashboardData();

      if (response) {
        setDashboardData(response);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    if (
      localStorage.getItem("website_url") ===
      process.env.REACT_APP_CUSTOMER_WEBSITE_URL
    ) {
      history.push("/customer/login");
      localStorage.clear();
      window.location.reload();
    } else {
      history.push("/login");
      localStorage.clear();
      window.location.reload();
    }
  };

  const handleClickOpenChangePassword = () => {
    history.push("/change-passwords");
    dispatch(updateOnFlagUpdate(false));
    dispatch(updateOnFlag(false));
  };

  return (
    <DashboardStyle mode={darkMode}>
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong backButtonLink="/dashboard" buttonText="Close" />
        </div>
      </ModalAntd>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you really want to Logout?"}
        </DialogTitle>
        <DialogContent style={{ width: "390px" }}>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout}>Yes</Button>
          <Button onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Dashboard" />
        </div>
      ) : (
        <div className="dashboard-left">
          <SideBarMain />
        </div>
      )}

      <div className="dashboard-right">
        <div>
          <div className="dashboard-username">
            <div className="username">
              Hello, {readLoginData().USER_NAME.toUpperCase()}
            </div>
            <div className="dark-mode-and-profile">
              <div className="dark-mode-button" onClick={() => toggle()}>
                {darkMode ? (
                  <img src={lightThemeIcon} alt="dark mode" />
                ) : (
                  <img src={darkThemeIcon} alt="dark mode" />
                )}
              </div>
              <Popover
                placement="bottomRight"
                title={"Profile"}
                trigger={["click"]}
                content={
                  <ProfilePopOverStyle mode={darkMode}>
                    <div className="user-name-text">
                      <AccountCircleIcon
                        style={{ cursor: "pointer", color: "#a4a4a4" }}
                      />
                      <span>
                        {readLoginData()
                          ? readLoginData().USER_NAME.toUpperCase()
                          : "Guest"}
                      </span>
                    </div>
                    <div className="user-name-text">
                      <EmailIcon
                        style={{ cursor: "pointer", color: "#a4a4a4" }}
                      />
                      <span>
                        {readLoginData() ? readLoginData().USER_EMAIL : ""}
                      </span>
                    </div>
                    <div
                      className="user-name-text cursor-pointer"
                      onClick={handleClickOpenChangePassword}
                    >
                      <LockIcon
                        style={{ cursor: "pointer", color: "#a4a4a4" }}
                      />
                      <span>Change Password</span>
                    </div>
                    <div
                      className="user-name-text cursor-pointer"
                      onClick={handleClickOpen}
                    >
                      <LogoutIcon
                        style={{ cursor: "pointer", color: "#a4a4a4" }}
                      />
                      <span>Logout</span>
                    </div>
                  </ProfilePopOverStyle>
                }
              >
                <div className="dark-mode-button-user">
                  <img src={profileIcon} alt="dark mode" />
                </div>
              </Popover>
            </div>
          </div>
          <div className="dashboard-main-card">
            <div className="dashobard-total-card">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                  <div
                    className="dashboard-graph transporter-vehicle-main-card"
                    style={{ display: "flex", height: "10%" }}
                  >
                    <div className="transporter-card minicard">
                      <div className="card-title">Transporters</div>
                      <div className="card-number">
                        <div className="total-number">
                          {dashboardData?.transporters_summary
                            ?.total_approved_transporters_count ? (
                            <AnimateNumber
                              targetNumber={
                                dashboardData.transporters_summary
                                  .total_approved_transporters_count
                              }
                              duration={1000}
                            />
                          ) : (
                            "00"
                          )}
                        </div>
                        {dashboardData?.transporters_summary
                          ?.total_transporters_onboarded_mtd === 0 ? null : (
                          <div className="today-number">
                            {
                              <AnimateNumber
                                targetNumber={
                                  dashboardData?.transporters_summary
                                    ?.total_transporters_onboarded_mtd
                                }
                                duration={1000}
                              />
                            }{" "}
                            <img src={polygonIcon} alt="up" />
                          </div>
                        )}
                      </div>
                    </div>{" "}
                    <div className="vehicle-card minicard">
                      <div className="card-title">Vehicles</div>
                      <div className="card-number">
                        <div className="total-number">
                          {dashboardData?.vehicles_summary
                            ?.total_vehicles_count ? (
                            <AnimateNumber
                              targetNumber={
                                dashboardData.vehicles_summary
                                  .total_vehicles_count
                              }
                              duration={1000}
                            />
                          ) : (
                            "00"
                          )}
                        </div>
                        {dashboardData?.vehicles_summary
                          ?.total_vehicles_onboarded_mtd !== 0 ? (
                          <div className="today-number">
                            {
                              <AnimateNumber
                                targetNumber={
                                  dashboardData?.vehicles_summary
                                    ?.total_vehicles_onboarded_mtd
                                }
                                duration={1000}
                              />
                            }{" "}
                            <img src={polygonIcon} alt="up" />{" "}
                          </div>
                        ) : null}
                      </div>
                    </div>{" "}
                    <div className="customer-card minicard">
                      <div className="card-title">Customers</div>
                      <div className="card-number">
                        <div className="total-number">
                          {dashboardData?.customers_summary
                            ?.total_customers_count ? (
                            <AnimateNumber
                              targetNumber={
                                dashboardData.customers_summary
                                  .total_customers_count
                              }
                              duration={1000}
                            />
                          ) : (
                            "00"
                          )}
                        </div>
                        {dashboardData?.customers_summary
                          ?.total_customers_onboarded_mtd !== 0 ? (
                          <div className="today-number">
                            {
                              <AnimateNumber
                                targetNumber={
                                  dashboardData?.customers_summary
                                    ?.total_customers_onboarded_mtd
                                }
                                duration={1000}
                              />
                            }{" "}
                            <img src={polygonIcon} alt="up" />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashobard-card-main-section">
                  <div className="dashboard-total-mini-card">
                    <div className="card-title">Trips</div>
                    <div className="card-number">
                      {dashboardData?.total_trip_count ? (
                        <AnimateNumber
                          targetNumber={dashboardData.total_trip_count}
                          duration={1000}
                        />
                      ) : (
                        "00"
                      )}
                    </div>
                    <div className="card-helper-text"></div>
                  </div>
                  <div className="dashboard-total-mini-card">
                    <div className="card-title">FTL Trips</div>
                    <div className="card-number">
                      {dashboardData?.ftl_trip_summary?.total_ftl_trip_count ? (
                        <AnimateNumber
                          targetNumber={
                            dashboardData.ftl_trip_summary.total_ftl_trip_count
                          }
                          duration={1000}
                        />
                      ) : (
                        "00"
                      )}
                    </div>
                    <div className="card-helper-text"></div>
                  </div>
                  <div className="dashboard-total-mini-card">
                    <div className="card-title">Faarms Trips</div>
                    <div className="card-number">
                      {dashboardData?.faarms_trip_summary?.faarms_total_trip ? (
                        <AnimateNumber
                          targetNumber={
                            dashboardData.faarms_trip_summary.faarms_total_trip
                          }
                          duration={1000}
                        />
                      ) : (
                        "00"
                      )}
                    </div>
                    <div className="card-helper-text"></div>
                  </div>
                </div>
              </div>

              <div
                className="dashboard-total-mini-card"
                style={isMobile ? { width: "100vw" } : { width: "400px" }}
              >
                <div className="card-title">
                  <div>Payment Receivable </div>
                </div>
                <div className="dashboard-pie-chart">
                  <div
                    className="pie-chart"
                    style={{ width: "50%", height: "150px" }}
                  >
                    {dashboardData?.payment_receivables_summary
                      ?.total_pending_amount ? (
                      <Doughnut
                        data={pieChartData}
                        options={pieChartOption}
                        plugins={[
                          {
                            id: "text",
                            beforeDraw: function (chart, a, b) {
                              var width = chart.width,
                                height = chart.height,
                                ctx = chart.ctx;

                              ctx.restore();
                              var fontSize = (height / 120).toFixed(2);
                              ctx.font = fontSize + "em sans-serif";
                              ctx.textBaseline = "middle";
                              ctx.fillStyle = "red";

                              var text = `₹${(
                                  dashboardData?.payment_receivables_summary
                                    ?.total_pending_amount / 10000000
                                ).toFixed(2)}Cr.`,
                                textX = Math.round(
                                  (width - ctx.measureText(text).width) / 2
                                ),
                                textY = height / 2;

                              ctx.fillText(text, textX, textY);
                              ctx.save();
                            },
                          },
                        ]}
                      />
                    ) : null}
                  </div>
                  <div className="payment-receivable-amount">
                    <div
                      className="payment-helper-text"
                      style={
                        darkMode ? { color: "white" } : { color: "#454545" }
                      }
                    >
                      From{" "}
                      <span style={{ fontWeight: "600", fontSize: "1.4rem" }}>
                        {
                          dashboardData?.payment_receivables_summary?.customers
                            ?.length
                        }
                      </span>{" "}
                      customers
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashobard-graph-and-card">
              <div className="dashboard-graph">
                <div className="graph-title">FTL Trips</div>
                <div className="ftl-graph graph-div">
                  <Bar
                    data={data}
                    options={options}
                    style={{ width: "100%" }}
                  />
                </div>
              </div>

              {/* <div className="dashboard-graph transporter-vehicle-main-card">
                <div className="transporter-card minicard">
                  <div className="card-title">Total Transporters</div>
                  <div className="card-number">
                    <div className="total-number">
                      {dashboardData?.transporters_summary
                        ?.total_approved_transporters_count ? (
                        <AnimateNumber
                          targetNumber={
                            dashboardData.transporters_summary
                              .total_approved_transporters_count
                          }
                          duration={1000}
                        />
                      ) : (
                        "00"
                      )}
                    </div>
                    {dashboardData?.transporters_summary
                      ?.total_transporters_onboarded_mtd === 0 ? null : (
                      <div className="today-number">
                        {
                          <AnimateNumber
                            targetNumber={
                              dashboardData?.transporters_summary
                                ?.total_transporters_onboarded_mtd
                            }
                            duration={1000}
                          />
                        }{" "}
                        <img src={polygonIcon} alt="up" />
                      </div>
                    )}
                  </div>
                </div>{" "}
                <div className="vehicle-card minicard">
                  <div className="card-title">Total Vehicles</div>
                  <div className="card-number">
                    <div className="total-number">
                      {dashboardData?.vehicles_summary?.total_vehicles_count ? (
                        <AnimateNumber
                          targetNumber={
                            dashboardData.vehicles_summary.total_vehicles_count
                          }
                          duration={1000}
                        />
                      ) : (
                        "00"
                      )}
                    </div>
                    {dashboardData?.vehicles_summary
                      ?.total_vehicles_onboarded_mtd !== 0 ? (
                      <div className="today-number">
                        {
                          <AnimateNumber
                            targetNumber={
                              dashboardData?.vehicles_summary
                                ?.total_vehicles_onboarded_mtd
                            }
                            duration={1000}
                          />
                        }{" "}
                        <img src={polygonIcon} alt="up" />{" "}
                      </div>
                    ) : null}
                  </div>
                </div>{" "}
                <div className="customer-card minicard">
                  <div className="card-title">Total Customers</div>
                  <div className="card-number">
                    <div className="total-number">
                      {dashboardData?.customers_summary
                        ?.total_customers_count ? (
                        <AnimateNumber
                          targetNumber={
                            dashboardData.customers_summary
                              .total_customers_count
                          }
                          duration={1000}
                        />
                      ) : (
                        "00"
                      )}
                    </div>
                    {dashboardData?.customers_summary
                      ?.total_customers_onboarded_mtd !== 0 ? (
                      <div className="today-number">
                        {
                          <AnimateNumber
                            targetNumber={
                              dashboardData?.customers_summary
                                ?.total_customers_onboarded_mtd
                            }
                            duration={1000}
                          />
                        }{" "}
                        <img src={polygonIcon} alt="up" />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div> */}
            </div>

            <div className="dashobard-graph-and-card">
              <div className="dashboard-graph">
                <div className="graph-title">Top 10 payment receivable</div>
                <div className="customer-receivable-table">
                  <div className="customer-table table-1">
                    <div className="table-head">Customer</div>
                    <div className="table-head">Amount</div>
                    {dashboardData?.payment_receivables_summary?.customers
                      .sort(
                        (a, b) =>
                          b.current_outstanding_amount -
                          a.current_outstanding_amount
                      )
                      .slice(0, 5)
                      ?.map((item) => (
                        <>
                          <div style={{ fontSize: "14px" }}>{item.name}</div>
                          <div style={{ fontSize: "14px" }}>
                            {ConvertToIndiaNumberSystem(
                              item.current_outstanding_amount
                            )}
                          </div>
                        </>
                      ))}
                  </div>
                  <div className="customer-table table-2">
                    <div className="table-head">Customer</div>
                    <div className="table-head">Amount</div>
                    {dashboardData?.payment_receivables_summary?.customers
                      .sort(
                        (a, b) =>
                          b.current_outstanding_amount -
                          a.current_outstanding_amount
                      )
                      .slice(5, 10)
                      ?.map((item) => (
                        <>
                          <div style={{ fontSize: "14px" }}>{item.name}</div>
                          <div style={{ fontSize: "14px" }}>
                            {ConvertToIndiaNumberSystem(
                              item.current_outstanding_amount
                            )}
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardStyle>
  );
};

export default Dashboard;
