import React, { useState, useEffect } from "react";
import LEAP_SUPPLY from "../../../assets/logo/leap_supply_logo.svg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DASHBOARD_GREEN from "../../../assets/logisticsCustomer/customer-dashboard.svg";
import DASHBOARD_WHITE from "../../../assets/logisticsCustomer/dashboard_white.svg";
import INDENT_GREEN from "../../../assets/logisticsCustomer/indent_green.svg";
import { Alert, Snackbar } from "@mui/material";
import { NavLink, useHistory } from "react-router-dom";
import changePassword from "../../../assets/dashboard_icons/change_password.svg";
import logout from "../../../assets/dashboard_icons/logout_icon.svg";
import INDENT_WHITE from "../../../assets/logisticsCustomer/indent_white.svg";
import QUOTES_WHITE from "../../../assets/logisticsCustomer/quotes_white.svg";
import QUOTES_GREEN from "../../../assets/logisticsCustomer/quotes_green.svg";
import TRIP_WHITE from "../../../assets/logisticsCustomer/trips_white.svg";
import TRIP_GREEN from "../../../assets/logisticsCustomer/trips_green.svg";

import "./customerSidebar.css";
import {
  readCustomerData,
  readLoginData,
} from "../../../helper/dataDecryptionHelper";
import { CircularProgress, Menu, TextField } from "@mui/material";
//modal dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import { readLoginData } from "../../helper/dataDecryptionHelper";
import { argon2id } from "hash-wasm";
import { changeUserPassword } from "../../../pages/login/LoginServices";
import { colorPalette } from "../../../themes/theme";

function CustomerSidebar() {
  const is_temporary_password =
    readLoginData() !== undefined && readLoginData().temporary_password
      ? readLoginData().temporary_password
      : null;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const customer = readCustomerData();

  const history = useHistory();
  const [currentPath, setCurrentPath] = useState("");
  const [changedPassword, setChangedPassword] = useState("");
  const [loginData, setLoginData] = useState();
  const [successMsg, setSuccessMsg] = useState("");
  const [misMatchedPassword, setMisMatchedPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (history.location.pathname) {
      setCurrentPath(history.location.pathname);
    }
  }, []);

  const handleLogout = () => {
    if (
      localStorage.getItem("website_url") ===
      process.env.REACT_APP_CUSTOMER_WEBSITE_URL
    ) {
      history.push("/customer/login");
      // localStorage.clear();
      localStorage.removeItem("CUSTOMER_DATA");
      localStorage.removeItem("x_token");
      localStorage.removeItem("website_url");
      localStorage.removeItem("LOGIN_DATA");
      window.location.reload();
    } else {
      history.push("/login");
      localStorage.clear();
      window.location.reload();
    }
    // localStorage.clear();

    // history.push("/login");
  };
  const [open, setOpen] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenChangePassword = () => {
    history.push("/customer/change-passwords");
  };

  useEffect(() => {
    if (is_temporary_password) {
      if (
        localStorage.getItem("website_url") ===
        process.env.REACT_APP_CUSTOMER_WEBSITE_URL
      ) {
        history.push("/customer/change-passwords");
      } else {
        history.push("/change-passwords");
      }
      // history.push("/change-passwords");
    }
  }, [is_temporary_password]);
  // useEffect(() => {
  //   if (is_temporary_password) {
  //     handleClickOpenChangePassword();
  //   }
  // }, [is_temporary_password]);
  //Modal Related code ends
  async function argon2HashPassword(user, userPassword) {
    // let decodedUserName = atob(user);
    //
    // setUserNameFromApi(ddecodedUserName);
    const salt = user;
    const hash = await argon2id({
      password: userPassword,
      salt,
      parallelism: 1,
      iterations: 2,
      memorySize: 16,
      hashLength: 16,
      outputType: "encoded",
    });
    //
    return hash;
  }
  // useEffect(async () => {
  //   const argon = await argon2HashPassword(
  //     loginData.USER_NAME,
  //     changedPassword
  //   );
  //
  //   // argon2HashPassword(loginData.USER_NAME, changedPassword);
  // }, [changedPassword]);

  const handlePasswordChange = async () => {
    const argon = await argon2HashPassword(
      loginData.USER_NAME,
      changedPassword
    );
    if (confirmPassword !== changedPassword) {
      setMisMatchedPassword("Password does not matches.");
    }

    if (argon && confirmPassword == changedPassword) {
      setLoading(true);
      const response = await changeUserPassword(argon);

      if (response.status == 200) {
        setLoading(false);
        setSuccessMsg(`Password has been changed successfully.`);
        setOpenSnackbar(true);
        setTimeout(() => {
          // localStorage.clear();
          localStorage.removeItem("CUSTOMER_DATA");
          localStorage.removeItem("x_token");
          localStorage.removeItem("website_url");
          localStorage.removeItem("LOGIN_DATA");
          window.location.reload();
        }, 2000);

        // setRegistredEmail("");
        // setWrongEmailMsg("");
      }
      if (response == 422) {
        setSuccessMsg("");
        setLoading(false);
        // setWrongEmailMsg("Please enter correct email id.");
      }
    }
    // setLoading(true);
  };
  const [state, setState] = React.useState({
    right: false,
  });
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "left",
  });
  const { vertical, horizontal } = snackbarPosition;
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="customer-sidebar-main">
      <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
            Open alert dialog
          </Button> */}
        <Dialog
          open={openChangePassword}
          onClose={handleCloseChangePassword}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ width: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <span>Change Password</span>
            </div>
          </DialogTitle>
          <DialogContent style={{ width: "500px", height: "180px" }}>
            <DialogContentText
              id="alert-dialog-description"
              style={{ marginTop: "20PX" }}
            >
              <TextField
                id="outlined-basic"
                label="Enter New password"
                variant="outlined"
                fullWidth
                value={changedPassword}
                onChange={(e) => {
                  setChangedPassword(e.target.value);
                  // setErrLogin("");
                }}
              />
              <TextField
                id="outlined-basic"
                label="Confirm password"
                variant="outlined"
                style={{ marginTop: "15px" }}
                fullWidth
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setMisMatchedPassword("");
                  // setErrLogin("");
                }}
              />
              {misMatchedPassword ? (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {misMatchedPassword}
                </span>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0px",
            }}
          >
            <span
              onClick={handlePasswordChange}
              className="change_password_botton"
            >
              {loading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                "Change Password"
              )}
            </span>
            {/* {is_temporary_password ? null : (
                <Button onClick={handleCloseChangePassword} autoFocus>
                  Discard
                </Button>
              )} */}
          </DialogActions>
        </Dialog>
      </div>
      <div>
        <img
          src={LEAP_SUPPLY}
          alt="Leap-supply-logo"
          width="100%"
          height="60%"
        />
      </div>
      {/* <NavLink
        to="/customer-dashboard"
        activeClassName="customerSidebar-active"
        className="link"
      >
        <div className="customer-sidebar-menuContain">
          {currentPath === "customer-dashboard" ? (
            <div className="customer-sidebar-menuborder"></div>
          ) : (
            <div className="customer-sidebar-menuborder-white"></div>
          )}

          <img
            className="customer-sidebar-menuLogo"
            src={
              currentPath === "customer-dashboard"
                ? DASHBOARD_GREEN
                : DASHBOARD_WHITE
            }
            alt="Dashboard"
          />
          <h5
            className="customer-sidebar-menutext"
            style={
              currentPath === "customer-dashboard"
                ? { color:colorPalette.primaryColor, fontWeight: "600" }
                : null
            }
          >
            {" "}
            Dashboard
          </h5>
        </div>
      </NavLink> */}
      <NavLink
        to="/customer/customer-indents"
        activeClassName="customerSidebar-active"
        className="link"
      >
        <div className="customer-sidebar-menuContain">
          {currentPath === "/customer/customer-indents" ? (
            <div className="customer-sidebar-menuborder"></div>
          ) : (
            <div className="customer-sidebar-menuborder-white"></div>
          )}
          <img
            className="customer-sidebar-menuLogo"
            src={
              currentPath === "/customer/customer-indents"
                ? INDENT_GREEN
                : INDENT_WHITE
            }
            alt="Indent"
          />
          <h5
            className="customer-sidebar-menutext"
            style={
              currentPath === "/customer/customer-indents"
                ? { color: colorPalette.primaryColor, fontWeight: "600" }
                : null
            }
          >
            {" "}
            Indents
          </h5>
        </div>
      </NavLink>
      <NavLink
        to="/customer/customer-quotes"
        activeClassName="customerSidebar-active"
        className="link"
      >
        <div className="customer-sidebar-menuContain">
          {currentPath === "/customer/customer-quotes" ? (
            <div className="customer-sidebar-menuborder"></div>
          ) : (
            <div className="customer-sidebar-menuborder-white"></div>
          )}
          <img
            className="customer-sidebar-menuLogo"
            src={
              currentPath === "/customer/customer-quotes"
                ? QUOTES_GREEN
                : QUOTES_WHITE
            }
            alt="Quote"
          />
          <h5
            className="customer-sidebar-menutext"
            style={
              currentPath === "/customer/customer-quotes"
                ? { color: colorPalette.primaryColor, fontWeight: "600" }
                : null
            }
          >
            {" "}
            Quotes
          </h5>
        </div>
      </NavLink>

      {/* <NavLink
        to="/customer-trips"
        activeClassName="customerSidebar-active"
        className="link"
      >
        <div className="customer-sidebar-menuContain">
          {currentPath === "customer-trips" ? (
            <div className="customer-sidebar-menuborder"></div>
          ) : (
            <div className="customer-sidebar-menuborder-white"></div>
          )}
          <img
            className="customer-sidebar-menuLogo"
            src={currentPath === "customer-trips" ? TRIP_GREEN : TRIP_WHITE}
            alt="Trips"
          />
          <h5
            className="customer-sidebar-menutext"
            style={
              currentPath === "customer-trips"
                ? { color:colorPalette.primaryColor, fontWeight: "600" }
                : null
            }
          >
            {" "}
            Trips
          </h5>
        </div>
      </NavLink> */}

      <div className="logout">
        <div className="customer-details">
          <AccountCircleIcon />
          <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
            {customer !== undefined ? customer.customer_name : null}
          </span>
        </div>
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div className="changePassword">
            <img src={changePassword} alt="" />{" "}
            <span onClick={handleClickOpenChangePassword}>Change Password</span>
          </div>
          <div className="logout-div" onClick={handleLogout}>
            <img src={logout} alt="" />{" "}
            <span onClick={handleClickOpen}>Logout</span>
          </div>
        </div>
        {/* <div className="logout-button">Logout</div> */}
      </div>
    </div>
  );
}

export default CustomerSidebar;
