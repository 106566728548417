import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useState } from "react";

const vertical = "bottom";
const horizontal = "right";

function Success({ message }) {
  const [openToast, setOpenToast] = useState(false);
  const handleCloseToast = () => {
    setOpenToast(false);
  };
  return (
    <div>
      <Snackbar
        open={openToast}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseToast}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Success;
