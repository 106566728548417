import styled from "styled-components";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "./theme";
import { useContext } from "react";
import { DarkModeContext } from "../context/darkModeContext";

export const Component_style = styled.div`
  width: 100%;
  height: 100vh;
  /* overflow: scroll; */
  .main_onboard_wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loader_container {
    width: 100%;
    height: 300px;
    position: absolute;
    top: 100;
    bottom: 100;
    left: 0;
    /* height: 300px; */
    display: flex;
    color: #9a9896;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  textarea {
    color: ${(props) =>
      props.mode ? colorPaletteDark.black : colorPalette.black};
  }
  .vehicle-list-monthly-div-container {
  }
  .vehicle-monthly-img {
    flex: 1;
    margin-left: 90%;
    margin-top: 5px;

    display: flex;
  }
  .async_dropdown {
    z-index: 999999;
  }
  .form_oem_customer {
    width: 100%;
    padding: 1rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0rem;
    overflow-y: scroll;
    /* background-color: red; */
    /* height: 100%; */
    /* background-color: red; */
  }
  .vehicle-expiry-text {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
    color: ${colorPalette.paragraphColor};
    margin-left: 2px;
  }
  .vehicle-expiry-check-container {
    display: flex;
  }
  .vehile-expiry-single-container {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin: 5px;
  }
  .vehicle-monthly-img-img {
    width: 24px;
    height: 24px;
  }
  .vehicle-list-monthly-text {
    font-size: ${typography.heading5};
    margin: 2px;
    color: ${colorPalette.primaryColor};
  }
  .right-wrong-icon-img {
    width: 15px;
    height: 15px;
  }
  .trip-list-filter-main_filter_transporter {
    padding: 10px;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  }
  .trip-list-filter-main {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  }
  .success_onboard_container {
    width: 100%;
    height: 100vh;
  }
  .success_onboard_wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .success_text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${typography.heading2};
    font-family: ${FontFamily.primaryFontFamily};
    color: ${colorPalette.primaryColor};
  }
  .details {
    margin-top: 2rem;
    font-size: ${typography.heading4};
    font-family: ${FontFamily.primaryFontFamily};
    border: 1px solid ${colorPalette.primaryColor};
    /* background-color: ${colorPalette.primaryColor}; */
    padding: 0.5rem 2rem;
    color: ${colorPalette.primaryColor};
    cursor: pointer;
  }

  .main_onboard_Left_box {
    flex: 1.7;
    background-color: ${colorPalette.secondaryWhite};
    height: 100%;
    box-shadow: ${colorPalette.boxShadowPrimary};
    margin: 0 auto;
  }
  .main_onboard_Right_box {
    flex: 9.5;
    /* position: sticky;
  overflow: scroll; */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.secondaryWhite};
    width: 90%;
  }
  .vehicle-input-attachment {
    width: 100%;
  }
  .progress_container {
    width: 60%;
    height: 8%;
    /* border-radius: 2rems; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colorPalette.primaryColor};
  }
  .form_container {
    position: sticky;
    overflow: scroll;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    /* background-color: #5b1111; */
  }
  .form_container_vehicle {
    position: sticky;
    overflow: scroll;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    /* background-color: #422929; */
  }
  .mobile_documents {
    flex: 1;
    margin: 5px 5px;
  }
  .form_wrapper {
    width: 100%;
    height: 100%;
    padding: 2rem;
    margin: 0 auto;
    /* background-color: ${colorPalette.primaryColor}; */
  }
  .form_wrapper_vehicle {
    width: 100%;
    height: 100%;
    padding: 1rem;
    margin: 0 auto;
  }
  .transporter-container {
    width: 80%;
    margin: 0 auto;
    height: auto;
    padding: 20px 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: white;
    border-radius: 7px;
    margin-bottom: 20px;
  }
  .form_row {
    width: 90%;
    height: 15%;
    display: flex;
    margin: 0 auto;
    /* background-color: blueviolet; */
    /* align-items: center; */
    justify-content: space-between;
  }
  .form_row_vehicle {
    width: 90%;
    /* height: 15%; */
    display: flex;
    margin: 10px auto;
    /* background-color: blueviolet; */
    /* align-items: center; */
    justify-content: space-between;
  }
  .form_row_vehicle_back {
    width: 94%;
    height: 10%;
    display: flex;
    margin: 0 auto;
    /* background-color: red; */
    /* background-color: blueviolet; */
    /* align-items: center; */
    justify-content: space-between;
  }
  .form_row_pic {
    width: 90%;
    /* height: 20%; */

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto;
    /* margin-bottom: 3rem; */
    /* background-color: blueviolet; */
    /* align-items: center; */
  }
  .form_label {
    font-family: ${FontFamily.primaryFontFamily};
    font-weight: ${FontWeight.bold};
    margin-bottom: 0.4rem;
    font-size: ${typography.heading5};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .form_label_oem {
    font-family: ${FontFamily.primaryFontFamily};
    font-weight: ${FontWeight.bold};
    margin-bottom: 0.4rem;
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .form_label_customers {
    font-family: ${FontFamily.primaryFontFamily};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading5};
    margin-bottom: 0.4rem;
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .MuiInputBase-input {
    background: ${(props) =>
      props.mode
        ? colorPaletteDark.white
        : colorPalette.secondaryWhite}!important;
    color: ${(props) =>
      props.mode ? colorPaletteDark.black : colorPalette.black}!important;
  }
  textarea {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.white : ""}!important;
    border: ${(props) =>
      props.mode ? "1px solid #076b38" : "1px solid black"};
  }
  .card_container_transporter {
    display: grid;
    grid-template-columns: 32.5% 32.5% 32.5%;
    grid-auto-rows: min-content;
    height: 80vh;
    /* width: 98%; */
    /* width: 100%; */
    /* padding: 0px 30px; */
    position: sticky;
    overflow-y: scroll;
    /* grid-template-rows: 50%; */
    gap: 10px;
  }
  .progress_container {
    width: 75%;

    /* background-color: grey; */
  }
  .progress {
    width: 2rem;
    height: 2rem;
    border: 5px solid ${colorPalette.primaryColor};
    margin-bottom: 0.8rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
  .progress1 {
    width: 2rem;
    height: 2rem;
    margin-bottom: 0.8rem;
    border: 5px solid ${colorPalette.paragraphSecondaryolor};
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
  .progress2 {
    width: 2rem;
    height: 2rem;
    margin-bottom: 0.8rem;
    border: 5px solid ${colorPalette.paragraphSecondaryolor};
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
  .progress_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .progress_text {
    font-weight: ${FontWeight.medium};
    font-family: ${FontFamily.primaryFontFamily};
    /* font-family: ${FontFamily.primaryFontFamily}; */
  }
  .aadhar_vehicles_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .btn_div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: ${colorPalette.primaryColor}; */
    /* background-color: ${colorPalette.primaryColor};
 margin-top: -3%; */
  }
  .btn {
    width: 30%;
    margin: 1rem auto;
    padding: 1.6rem 3rem;
    font-size: ${typography.heading4};
    font-weight: ${FontWeight.bold};
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    color: ${colorPalette.white};
    outline: none;
    border: none;
    border-radius: 2rem;
    background-color: ${colorPalette.primaryColor};
    -webkit-border-radius: 2rem;
    -moz-border-radius: 2rem;
    -ms-border-radius: 2rem;
    -o-border-radius: 2rem;
  }
  .btn_manufacturer {
    width: 30%;
    margin: 1rem auto;
    padding: 0.8rem 0rem;
    font-size: ${typography.paragraph};
    font-weight: ${FontWeight.bold};
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    color: ${colorPalette.primaryColor};
    border-radius: 1rem;
    /* border: 1px solid ${colorPalette.primaryColor} ; */
    /* background-color: ${colorPalette.primaryColor}; */
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
  }

  .progress_container_details {
    width: 98%;
    height: 100%;
    display: flex;
    align-items: center;
    /* background-color: red; */
    justify-content: space-between;
  }
  .Left_details_wrapper {
    flex: 8;
    height: 90%;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.secondaryWhite};
  }
  .right_details_wrapper {
    flex: 4;
    height: 100%;
    /* overflow-y: scroll; */
    margin: 0 0.8rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
    box-shadow: ${colorPalette.boxShadowPrimary};
    background-color: ${(props) =>
      props.mode
        ? colorPaletteDark.secondaryColor
        : colorPalette.secondaryWhite};
  }
  .right_details_wrapper::-webkit-scrollbar {
    display: none;
  }

  .search_container {
    /* flex: 6; */
    width: 100%;
    height: 10%;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search_div {
    position: relative;
    width: 70%;
    height: 2.5rem;
    background-color: ${colorPalette.white};
    display: flex;
    padding: 0 0.8rem;
    margin-right: 2rem;
    /* background-color: red; */
    margin-left: 3rem;
    /* justify-content: center; */
    align-items: center;
    border-radius: 8px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
  .search_input {
    width: 100%;
    background-color: ${colorPalette.white};
    height: 100%;
    outline: none;
    border-radius: 1rem;
    border: none;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
  }
  .card_container {
    width: 100%;
    height: 85%;
  }
  .card_container-oem {
    overflow-y: scroll;
    height: 60vh;
    position: sticky;
    /* height: 320px; */
    overflow-y: scroll;
  }
  .card_container_vehicles {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-auto-rows: min-content;
    /* width: 100%; */
    /* width: 100%; */
    /* padding: 0px 30px; */
    position: sticky;
    height: 70vh;
    overflow-y: scroll;
    /* overflow: hidden; */
  }

  .card_container_manufacturer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: min-content;
    gap: 10px;
    justify-content: center;
    height: 80vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
  }
  .card_container_manufacturer::-webkit-scrollbar {
    display: none;
  }
  .card_manufacturer_customers {
    display: grid;
    background-color: red;
    /*grid-template-columns: 65px auto;
  */
    width: 100%;
    /*height: 110px;
  
  */
    margin: 0.5rem auto;
    padding: 5px;
    cursor: pointer;
    border-radius: 0.2rem;
    box-shadow: ${colorPalette.boxShadowPrimary};
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
    -webkit-border-radius: 0.2rem;
    -moz-border-radius: 0.2rem;
    -ms-border-radius: 0.2rem;
    -o-border-radius: 0.2rem;
    /*overflow: hidden;
  */
  }

  .button_div_customers {
    height: 100%;
    width: 100%;
    display: flex;
    /* align-items: center; */
    position: relative;
  }

  .card_customer_credit_contain {
    font-size: ${typography.paragraph};
    color: ${colorPalette.paragraphSecondaryolor};
    /* margin: 3px 0px; */
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: space-between; */
  }

  .card_customer_credit_key {
    display: flex;
    color: ${(props) => (props.mode ? "#d8d8d8" : colorPalette.black)};
    /* color: #d8d8d8; */
  }

  .card_customer_credit_value {
    font-weight: 600;
  }
  .card_manufacturer_customers {
    display: grid;
    /*grid-template-columns: 65px auto;
  */
    width: 100%;
    /*height: 110px;
  
  */
    /* background-color: red; */
    margin: 0.5rem auto;
    padding: 10px;
    cursor: pointer;
    border-radius: 0.2rem;
    box-shadow: ${colorPalette.boxShadowPrimary};
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
    -webkit-border-radius: 0.2rem;
    -moz-border-radius: 0.2rem;
    -ms-border-radius: 0.2rem;
    -o-border-radius: 0.2rem;
    /*overflow: hidden;
  */
  }

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 30%;
    margin: 1rem auto;
    padding: 0.6rem;
    border-radius: 0.5rem;
    box-shadow: ${colorPalette.boxShadowPrimary};
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
    -webkit-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
  }

  .card_manufacturer {
    display: flex;
    align-items: center;
    justify-content: center;
    /* display: grid;
  grid-template-columns: 65px auto; */
    /* justify-content: start; */
    /* justify-content: center; */
    /* align-items: flex-start; */
    /* flex-direction: column; */
    width: 100%;
    height: 110px;
    margin: 1rem auto;
    padding: 10px;
    border-radius: 0.5rem;
    box-shadow: ${colorPalette.boxShadowPrimary};
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
    overflow: hidden;
  }
  .image_div {
    width: 200px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image_div_oem {
    width: 100px;
    height: 100px;
  }

  .image_div_oem img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .image_div_customer {
    width: 100px;
    height: 100px;
  }

  .image_div_customer img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .image_div img {
    /* width: 100%;
  height: 100%; */
    width: 120px;
    height: 120px;
    object-fit: cover;
    /* border-radius: 50%; */
  }

  .image_div_manufacturer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image_div_manufacturer img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
  }
  .image_div_vendors {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image_div_vendors img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    object-fit: cnta;
    box-shadow: ${colorPalette.boxShadowPrimary};
  }
  .content_div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .content_div_mobile {
    height: 100%;
    display: flex;
    /* font-size: 2rem; */
    justify-content: center;
    /* flex-direction: ; */
    align-items: center;
  }

  .content_div_onboard {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .content_div_image {
    flex: 1;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .content_div_manufacturer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .capacity {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 0.6rem;
  }

  .card_customer_code {
    font-size: ${typography.paragraph};
    font-weight: ${FontWeight.bold};
    color: ${colorPalette.primaryColor};
  }
  .name_text {
    width: 100%;
    color: ${colorPalette.black};
    overflow: hidden;
    font-family: ${FontFamily.primaryFontFamily};
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    justify-content: space-between;
    align-items: center;
    font-size: ${typography.heading3};
    font-weight: ${FontWeight.medium};
  }
  .name_text_vendors {
    width: 100%;
    color: ${colorPalette.black};
    overflow: hidden;
    font-family: ${FontFamily.primaryFontFamily};
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    justify-content: space-between;
    align-items: center;
    font-size: ${typography.paragraph_secondary};
    font-weight: ${FontWeight.medium};
  }
  .name_text_customer {
    width: 100%;
    /* color: ${colorPalette.black}; */
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    overflow: hidden;
    font-family: ${FontFamily.primaryFontFamily};
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    justify-content: space-between;
    align-items: center;
    font-size: ${typography.paragraph};
    font-weight: ${FontWeight.medium};
  }

  .name_text_onboard {
    font-size: ${typography.heading4};
  }

  .name_text_vehicle {
    font-size: ${typography.heading4};
  }

  .name_text_manufacturer {
    font-size: ${typography.heading4};
    width: 100%;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .name_text_customer_web {
    font-size: ${typography.paragraph};
    width: 100%;
  }

  .capacity_text {
    font-family: ${FontFamily.primaryFontFamily};
    font-size: ${typography.heading5};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.paragraphColor};
  }

  .weight {
    font-family: ${FontFamily.primaryFontFamily};
    font-size: ${typography.heading5};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.paragraphColor};
    /* margin-left: 0.5rem; */
  }
  .state_text {
    font-family: ${FontFamily.primaryFontFamily};
    font-size: ${typography.heading5};
    color: ${colorPalette.paragraphColor};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .state {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 0.6rem;
  }
  .driver {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 0.6rem;
  }
  .driver_name {
    font-family: ${FontFamily.primaryFontFamily};
    font-size: ${typography.heading5};
    color: ${colorPalette.paragraphColor};
  }
  .drivers_text {
    font-family: ${FontFamily.primaryFontFamily};
    font-size: ${typography.heading5};
    color: ${colorPalette.paragraphSecondaryolor};
    margin-left: 0.5rem;
  }
  .button_div {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
  .button_div_manufacturer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button_div_vendors {
    height: 100%;
    width: 100%;
    display: flex;
    /* align-items: center; */
    position: relative;
  }

  .card_customer_button {
    position: absolute;
    width: 100%;
    /* height: 100%; */
    right: 0;
    bottom: 0;
    text-align: right;
    font-size: ${typography.heading4};
    width: 100%;
    z-index: 99;
    margin-right: 5px;
    margin-bottom: 2px;
    font-size: ${typography.paragraph};
  }

  .card_customer_button a {
    padding: 3px 12px;
    border: 1.5px solid ${colorPalette.primaryColor};
    border-radius: 5px;
    color: ${colorPalette.primaryColor};
  }

  .vehicle_text {
    font-family: ${FontFamily.primaryFontFamily};
    font-size: ${typography.paragraph_secondary};
    color: ${colorPalette.paragraphColor};
    /* margin-left: 0.5rem; */
  }
  .vehicle_type {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
  }
  .btn {
    /* margin-top: 1rem; */
    width: 70%;
    background-color: ${colorPalette.primaryColor};
    /* margin-top: 4rem; */
    margin: 0 auto;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: medium;
    font-weight: 400;
    border-radius: 0.5rem;
    /* font-size: ${typography.paragraph}; */
  }
  .btn_disable {
    margin-top: 1rem;
    width: 70%;
    background-color: ${colorPalette.secondaryGreen};
    /*margin-top: 4rem;
  */
    margin: 0 auto;
    font-size: medium;
    font-weight: 400;
    border-radius: 0.5rem;
    height: 3rem;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: ${typography.paragraph}; */
    -webkit-border-radius: 0.5rem;
    -moz-border-radius: 0.5rem;
    -ms-border-radius: 0.5rem;
    -o-border-radius: 0.5rem;
    margin-bottom: 60px;
  }
  .btn_vehicle {
    margin-top: 1rem;
    border-radius: 4px;

    height: 2rem;
    color: ${colorPalette.white};
    padding: 0 0.5rem;
    background-color: ${colorPalette.primaryColor};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: ${typography.paragraph};
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
  .oem_div {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
    /* background-color: aqua; */
    margin-top: 3rem;
  }
  .oem_div_model {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
    height: 90vh;
    margin: 0 auto;
    /* background-color: aqua; */
    /* margin-top: 1rem; */
  }
  .form_oem {
    width: 100%;
    padding: 1rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    /* background-color: red; */
  }
  .form_oem_customer {
    width: 100%;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    /* background-color: red; */
  }
  .right_details_wrapper {
  }

  .form_oem input,
  .form_oem textarea,
  .form_oem button {
    font-size: ${typography.heading5};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }

  .form_oem_model {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2rem;
    overflow-y: scroll;
    height: 95vh;
    /* background-color: red; */
  }
  .oem_btn {
    width: 95%;
    margin: 0 auto;
    outline: none;
    border: none;
    background-color: ${colorPalette.primaryColor};
    padding: 0.6rem 0rem;
    color: ${colorPalette.white};
    cursor: pointer;
  }
  .oem_btn_model {
    width: 85%;
    margin: 0 auto;
    outline: none;
    border: none;
    background-color: ${colorPalette.primaryColor};
    padding: 0.6rem 0rem;
    color: ${colorPalette.white};
    cursor: pointer;
    margin-right: 2.3rem;
  }
  .back {
    color: ${colorPalette.primaryColor};
    height: 30px;
    font-size: 3rem;
    margin-right: 1rem;
  }
  .back_btnDiv {
    width: 100%;
    display: flex;
    padding: 0.5rem 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    /* background-color: red; */
  }
  .back_btnDiv_vehicle {
    width: 100%;
    display: flex;
    padding: 0.5rem 0rem;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    background-color: ${colorPalette.red};
  }
  .navLink {
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: black */
    text-decoration: none;
  }
  .edit_btn {
    text-align: center;
    width: 100%;
    cursor: pointer;
  }
  /* .vehicle_card {
  width: 100%;
  background-color: red;
  height: 100%;
} */
  .upload_permit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .input_field_form {
    width: 25rem;
  }
  #docs_upload {
    margin-left: 3.5rem;
    padding: 1rem 0rem;
    font-weight: ${FontWeight.bold};
    color: ${colorPalette.paragraphSecondaryolor};
  }
  #permit_field {
    margin-top: 4rem;
  }
  .trip-list-filter-container_transporter {
    margin: 10px;
    width: 100%;
  }

  .transporter-nav {
    width: 100%;
    height: 80px;
    background-color: ${colorPalette.primaryColor};
  }

  .transporter-nav img {
    width: 300px;
    height: 100%;
    padding: 10px;
  }

  @media only screen and (max-width: 499px) {
    .trip-list-filter-main_filter_transporter {
      padding: 10px;
      width: 90vw;
      background-color: ${colorPalette.white};
    }
    .name_text {
      width: 100%;
      /* height: 38px; */
      /* background-color: ${colorPalette.primaryColor}; */
      color: ${colorPalette.black};
      overflow: hidden;
      font-family: ${FontFamily.primaryFontFamily};
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      justify-content: space-between;
      align-items: center;
      font-size: ${typography.paragraph};
      font-weight: ${FontWeight.medium};
    }
    .card_container_manufacturer {
      width: 100%;
      /* background-color: red; */
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: min-content;
      gap: 10px;
      justify-content: center;
      /* grid-template-rows: auto; */
      /* height: 85%; */
      /* position: sticky; */
      height: 100vh;
      overflow-y: scroll;
      /* margin-top: 1.4rem; */
      /* background-color: aquamarine; */
    }
    card_manufacturer {
      display: flex;
      align-items: center;
      justify-content: center;
      /* display: grid;
  grid-template-columns: 65px auto; */
      /* justify-content: start; */
      /* justify-content: center; */
      /* align-items: flex-start; */
      /* flex-direction: column; */
      width: 100%;
      height: 110px;
      margin: 1rem auto;
      padding: 10px;
      border-radius: 0.5rem;
      box-shadow: ${colorPalette.boxShadowPrimary};
      background-color: ${colorPalette.white};
      -webkit-border-radius: 0.5rem;
      -moz-border-radius: 0.5rem;
      -ms-border-radius: 0.5rem;
      -o-border-radius: 0.5rem;
      overflow: hidden;
    }
    .vehicle-input-attachment {
      width: 100%;
    }
    .trip-list-filter-container_transporter {
      margin: 10px;
      width: 100%;
    }
    .photo_back {
      margin-top: 0rem;
    }
    #permit_field {
      margin-top: 0rem;
    }
    .mobile_documents {
      margin: 1rem 0rem;
      width: 100%;
    }
    .card_container_transporter {
      display: grid;
      grid-template-columns: 95%;
      grid-auto-rows: min-content;
      height: 80vh;

      /* width: 100vw; */
      /* padding: 0px 30px; */
      position: sticky;
      overflow-y: scroll;
      /* grid-template-rows: 50%; */
      gap: 10px;
    }
    .btn_mobile {
      text-decoration: none;
      /*width: 30%;
    */ /*margin: 2rem auto;
    */
      padding: 0.8rem 1rem;
      font-size: 0.6rem;
      font-weight: ${FontWeight.bold};
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      color: ${colorPalette.white};
      border-radius: 0.6rem;
      background-color: ${colorPalette.primaryColor};
    }
    .main_onboard_Right_box {
      flex: 9.5;
      /* position: sticky;
    overflow: scroll; */
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      background-color: ${colorPalette.secondaryWhite};
      width: 95%;
      /* background-color: red; */
    }
    #text_mobile_onboard {
      font-size: 0.6rem;
    }
    .progress_container_details {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      /* background-color: beige; */
      justify-content: space-between;
    }
    .oem_div {
      display: flex;
      flex-direction: column;
      width: 85%;
      margin: 0 auto;
      /* background-color: aqua; */
      margin-top: 3rem;
    }
    .main_onboard_wrapper {
      flex-direction: column;
      margin: 0px;
      height: auto;
    }
    .search_container {
      /* flex: 12; */
      width: 100%;
      /* align-items: center; */
      height: 10%;
      margin-top: 1rem;
      /* margin-right: .5rem; */
      margin-bottom: 1rem;
      /* background-color: beige;s */
      display: flex;
      align-items: center;
      justify-content: center;
      /* background-color: blue; */
    }
    #search_container_transporter {
      /* flex: 12; */
      /* margin-right: 1rem; */
      /* width: 100vw; */
      /* align-items: center; */
      height: 10%;
      margin-top: -0.2rem;
      margin-bottom: -0.2rem;
      /* background-color: beige; */
      display: flex;
      margin-right: 1rem;
      align-items: center;
      justify-content: center;
      /* background-color: blue; */
    }

    .search_div {
      /* position: relative; */
      /* width: 0%; */
      /* align-items: center; */
      height: 2.5rem;
      background-color: ${colorPalette.secondaryWhite};
      display: flex;
      padding: 0 0.8rem;
      display: flex;
      align-items: center;
      /* margin-right: 2rem; */
      /* background-color: red; */
      /* margin-left: 3rem; */
      /* justify-content: center; */
      align-items: center;
      border-radius: 8px;
      box-shadow: ${colorPalette.boxShadowPrimary};
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      /* background-color: red; */
    }
    #search_div_onboard {
      /* position: relative; */
      width: 100%;
      /* align-items: center; */
      height: 2.5rem;
      /* margin-right: 6.5rem; */
      background-color: ${colorPalette.secondaryWhite};
      display: flex;
      padding: 0 0.8rem;
      display: flex;
      align-items: center;
      margin-left: 1.8rem;
      /* background-color: ${colorPalette.primaryColor}; */
      /* margin-right: 2rem; */
      /* background-color: red; */
      /* margin-left: 3rem; */
      /* justify-content: center; */
      align-items: center;
      border-radius: 8px;
      box-shadow: ${colorPalette.boxShadowPrimary};
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      /* background-color: red; */
    }
    .search_input {
      width: 100%;
      background-color: ${colorPalette.secondaryWhite};
      height: 100%;
      outline: none;
      border-radius: 1rem;
      /* margin-right: -1rem; */
      border: none;
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      -ms-border-radius: 1rem;
      -o-border-radius: 1rem;
    }
    #search_input_onboard {
      width: 20rem;
      /* margin-right: -1rem; */

      /* margin: 0 auto; */
      background-color: ${colorPalette.secondaryWhite};
      height: 100%;
      outline: none;
      border-radius: 1rem;
      border: none;
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      -ms-border-radius: 1rem;
      -o-border-radius: 1rem;
    }
    .card_container {
      width: 100%;
      height: 95%;
      position: sticky;
      /* height: 320px; */
      overflow-y: scroll;
      /* margin-top: 1.4rem; */
      /* background-color: aquamarine; */
    }
    .card_container-oem {
      width: 100%;
      height: 95%;
      position: sticky;
      /* height: 320px; */
      overflow-y: scroll;
      /* margin-top: 1.4rem; */
      /* background-color: aquamarine; */
    }
    .mobile_custoemr_editdiv {
      display: flex;
      align-items: center;
      width: 25%;
      justify-content: space-between;
    }

    .add_manufacturer {
      cursor: pointer;
      border: none;
      border-radius: 10px;
      background-color: ${colorPalette.primaryColor};
      outline: none;
      font-weight: ${FontWeight.bold};
      padding: 0.5rem 1rem;
      color: ${colorPalette.white};
      margin-right: 1rem;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }
    .card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 95%;
      height: 25%;

      margin: 1rem auto;
      padding: 0.6rem;
      border-radius: 0.5rem;
      box-shadow: ${colorPalette.boxShadowPrimary};
      background-color: ${(props) =>
        props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
      -webkit-border-radius: 0.5rem;
      -moz-border-radius: 0.5rem;
      -ms-border-radius: 0.5rem;
      -o-border-radius: 0.5rem;
    }
    .Left_details_wrapper {
      width: 100%;
      height: 100%;
      background-color: ${(props) =>
        props.mode
          ? colorPaletteDark.primaryColor
          : colorPalette.secondaryWhite};
    }
    #Left_details_wrapper_onboard {
      width: 100%;
      height: 100vh;

      background-color: ${colorPalette.secondaryWhite};
    }
    .form_oem {
      width: 90%;
      margin-left: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin-top: 2rem;
      /* background-color: red; */
    }

    .right_details_wrapper {
      width: 100vw;
      height: 100vh;
      /* background-color: red; */
    }
    #form_model {
      width: 85vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    #model_div {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      margin-top: 3rem;
    }
    .capacity_text_mobile {
      font-family: ${FontFamily.primaryFontFamily};
      font-size: ${typography.paragraph_secondary};
      color: ${colorPalette.paragraphColor};
    }

    .weight_mobile {
      font-family: ${FontFamily.primaryFontFamily};
      font-size: ${typography.paragraph_secondary};
      color: ${colorPalette.paragraphSecondaryolor};
      margin-left: 0.5rem;
    }
    .state_text {
      font-family: ${FontFamily.primaryFontFamily};
      font-size: ${typography.heading5};
      color: ${colorPalette.paragraphColor};
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .state {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 0.6rem;
    }
    .driver {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 0.6rem;
    }
    .driver_name {
      font-family: ${FontFamily.primaryFontFamily};
      font-size: ${typography.heading5};
      color: ${colorPalette.paragraphColor};
    }
    .drivers_text {
      font-family: ${FontFamily.primaryFontFamily};
      font-size: ${typography.heading5};
      color: ${colorPalette.paragraphSecondaryolor};
      margin-left: 0.5rem;
    }

    .transporter-container {
      width: 100%;
    }

    .form_wrapper {
      width: 100%;
      height: 100%;
      padding: 0;
      /* padding: 2rem; */
      /* margin: 0 auto; */
      /* background-color: ${colorPalette.primaryColor}; */
    }

    .form_row {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      align-items: center;
      /* background-color: blueviolet; */
      /* align-items: center; */
      justify-content: center;
    }
    .form_row_vehicle {
      width: 90%;
      height: 15%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px auto;
      /* background-color: blueviolet; */
      /* align-items: center; */

      justify-content: center;
    }
    .form_container {
      position: sticky;
      overflow: scroll;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      background-color: ${colorPalette.secondaryWhite};
    }
    .input_field_form {
      width: 18rem;
    }
    .form_label {
      font-family: ${FontFamily.primaryFontFamily};
      font-weight: ${FontWeight.bold};
      margin-bottom: 0.4rem;
      margin-top: 1rem;
    }
    .form_label_oem {
      font-family: ${FontFamily.primaryFontFamily};
      font-weight: ${FontWeight.bold};
      margin-bottom: 0.4rem;
      margin-top: 1rem;
      width: 97%;
      display: flex;
      justify-content: flex-start;
    }
    .form_label_customers {
      margin-bottom: 0.4rem;
      margin-top: 1rem;
      width: 90%;
      display: flex;
      justify-content: flex-start;
    }
    .capacity_text_model {
      color: ${colorPalette.paragraphColor};
    }
    #button {
      margin-top: 2rem;
    }
    #docs_upload {
      margin-left: -0.5rem;
      font-weight: ${FontWeight.bold};
      text-align: center;
      color: brown;
      /* marginLeft: 1rem, */
      /* fontWeight: "bold", */
      /* color: "brown",s */
    }
    .form_row_pic {
      width: 90%;
      height: 15%;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0 auto;
      /* margin-bottom: 3rem; */
      /* background-color: blueviolet; */
      /* align-items: center; */
    }
    .photo_back {
      margin-left: 0%;
      margin-top: 5rem;
    }
    .btn_div {
      width: 100%;
      margin-top: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      /* background-color: ${colorPalette.primaryColor};
 margin-top: -3%; */
    }
    .onboard_btnDiv_mobile {
      width: 82%;
      margin: 0 auto;
      margin-bottom: 1rem;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }
    .button_mobile {
      /* background-color: red; */
      padding: 0.3rem 0.4rem;
      background-color: ${colorPalette.primaryColor};
      outline: none;
      border: none;
      /* margin-right: ; */
    }
    .onboard_mobile_text {
      font-size: ${typography.paragraph};
      color: ${colorPalette.white};
      outline: none;
      border: none;
    }
    /* .capacity_text{
  font-family: ${FontFamily.primaryFontFamily};
  font-size: 0.7rem;
  color: #413f3f;
}
.weight{
  font-family: ${FontFamily.primaryFontFamily};
  font-size: 0.5rem;
  color: #696969;
  margin-left: 0.5rem;
} */
  }
`;
