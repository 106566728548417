import React from "react";
import { IndentInfoStyle } from "./indentInfoStyle";

function IndentInfoCard() {
  return (
    <IndentInfoStyle>
      <div className="indentInfo-container">IndentInfoCard</div>
    </IndentInfoStyle>
  );
}

export default IndentInfoCard;
