import React, { useState, useEffect } from "react";
import UploadImage from "../../../assets/upload_icon.svg";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import success from "../../../assets/dashboard_icons/icons8-truck.gif";
import AddIcon from "@mui/icons-material/Add";
import {
  LocationOn,
  ArrowBack,
  Close,
  AddCircle,
  Add,
  Cancel,
  Search,
  Scale,
} from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import { Button, Modal } from "antd";
import axios from "axios";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
// import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import * as moment from "moment";
import AsyncSelect from "react-select/async";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import MONTHLYICON from "../../../assets/monthly_icon.svg";
import TRIPWISEICON from "../../../assets/trip_wise_icon.svg";
import TICKICON from "../../../assets/green_tick.svg";
import WRONGICON from "../../../assets/wrong.svg";
import {
  TextField,
  MenuItem,
  CircularProgress,
  InputAdornment,
  TextareaAutosize,
  Checkbox,
  Dialog,
} from "@mui/material";
import {
  GetAddressFromPincode,
  CreateTripPost,
  GetUnitList,
  GetAvailableVehiclesList,
  GetPurchaseOrders,
  GetInvoices,
  UpdateTripPut,
  GetVehicles,
  GetDrivers,
  CreateDriverPost,
  GetDocumentEnums,
  GetCustomersList,
  getZohoInvoiceItemsDetails,
  GetEntityTypeEnums,
  GetCustomerSearchList,
  GetCustomerById,
  getSettingsListByCustomerId,
} from "./createTripServices";
import { Box, Drawer } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  updateDriverDetails,
  updateTripDetails,
} from "../../../redux/tripSlice";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import apiUrl from "../../../url/apiUrl";
import { TripAttachmentPost } from "../tripList/tripListServices";
import { getWarehouseList } from "../../warehouses/warehousesServices";
import { getWarehouseUsersList } from "../../../components/warehouseMiniCard/warehouseMiniService";
import { getTripDetailsFromId } from "../deliveryInvoicePayment/DeliveryInvoiceService";
import { useParams } from "react-router-dom";
// import { vendorsPost } from "../../vendors/VendorsServices";
import {
  Create_Trip_Main_Container,
  Create_trip_drawer_container,
  Create_trip_vehicle_single,
  Summary_container,
} from "./create-tripv2.js";
import { colorPalette } from "../../../themes/theme";
import { customersPost } from "../../customers/CustomersServices";
import Topbar from "../../../components/topbar/Topbar.jsx";
import axiosInterceptor from "../../../config/https";

import styled from "styled-components";

// const StyledModal = styled(ModalUnstyled)`
//   position: fixed;
//   z-index: 1300;
//   right: 0;
//   bottom: 0;
//   top: 0;
//   left: 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;
const filterBoxStyle = {
  width: "90vw",
  height: "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  // marginLeft: isMobile ? 1 : -34,
  // marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};

function CreateTripV2() {
  const [checked, setChecked] = useState(false);
  const [checkedAttachmentsNotRequired, setCheckedAttachmentsNotRequired] =
    useState(false);


  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleChangeCheckedAttachments = (event) => {
    setCheckedAttachmentsNotRequired(event.target.checked);
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const handleOpenFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };
  const handleCloseFilterOptions = () => {
    setIsFilterOpen(false);
  };
  const [openSummary, setOpenSummary] = useState(false);

  useEffect(() => {
    if (openSummary == false) {
      setErrCreateTripFinal("");
    }
  }, [openSummary]);
  const history = useHistory();
  const { tripId, trip_type } = useParams();
  const removedStringForTripType = (trip_type) => {
    if (trip_type === "Faarms_first_mile" || trip_type === "Faarms_last_mile") {
      return trip_type.replace("Faarms_", "");
    } else if (
      trip_type === "Customer_first_mile" ||
      trip_type === "Customer_middle_mile" ||
      trip_type === "Customer_last_mile"
    ) {
      return trip_type.replace("Customer_", "");
    }
  };
  const removedStringForCustomerType = (trip_type) => {
    if (trip_type === "Faarms_first_mile") {
      return trip_type.replace("_first_mile", "");
    } else if (trip_type === "Faarms_last_mile") {
      return trip_type.replace("_last_mile", "");
    } else if (trip_type === "Customer_first_mile") {
      return trip_type.replace("_first_mile", "");
    } else if (trip_type === "Customer_last_mile") {
      return trip_type.replace("_last_mile", "");
    } else if (trip_type === "Customer_middle_mile") {
      return trip_type.replace("_middle_mile", "");
    }
  };
  // useEffect(() => {
  //   removedStringForTripType(trip_type ? trip_type : "");
  // }, [trip_type]);

  const [vehicleDetails, setVehicleDetails] = useState({
    manufacturer: "",
    model: "",
    payout: "",
    status: "",
  });

  const [transporterDetails, setTransporterDetails] = useState({
    transporter: "",
    code: "",
    mobile: "",
    district: "",
    state: "",
  });

  const [selectedDriverDetails, setSelectedDriverDetails] = useState({
    first_name: "",
    last_name: "",
    dl: "",
    mobile: "",
  });
  const [tripSettingsList, setTripSettingsList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [errorAddressName, setErrorAddressName] = useState("");
  const [editAddressDeleteIndex, setEditAddressDeleteIndex] = useState("");
  const [errorAddressNumber, setErrorAddressNumber] = useState("");
  const [errorAddressPincode, setErrorAddressPincode] = useState("");
  const [errorAddressVillage, setErrorAddressVillage] = useState("");
  const dispatch = useDispatch();
  const [sourceVillagesList, setSourceVillagesList] = useState([]);
  const [toPay, setToPay] = useState("");
  const [tripfor, setTripFor] = useState("");
  const [toBilled, setToBilled] = useState("");
  const [drivingPhoto, setDrivingPhoto] = useState(null);
  const [driverPanImage, setDriverPanImage] = useState();
  const [permission, setPermission] = useState("");
  const [driverId, setDriverId] = useState("");
  const [invoiceTotalQuantity, setInvoiceTotalQuantity] = useState([]);
  const [wareHouseList, setWareHouseList] = useState([]);
  const [totalQty, setTotalQty] = useState("");
  //
  const [wareHouse, setWareHouse] = useState({});

  const [referenceNumberSearchValue, setReferenceNumberSearchValue] =
    useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [destinationVillagesList, setDestinationVillagesList] = useState([]);
  const [sourceDetails, setSourceDetails] = useState([
    {
      concern_person_name: "",
      concern_person_mobile_number: "",
      postcode: "",
      type: "source",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    },
  ]);
  const [addressDetails, setAddressDetails] = useState({
    district: "",
    sub_district: "",
    state: "",
    postoffice: "",
    type: "",
  });
  const [selectedIndex, setSelectedIndex] = useState("");
  const [destinationAddressList, setDestinationAddressList] = useState([]);
  const [editId, setEditId] = useState("");
  //
  const [sourceAddressList, setSourceAddressList] = useState([]);
  const [addSourceAddressListForEdit, setAddSourceAddressListForEdit] =
    useState([]);
  const [
    addDestinationAddressListForEdit,
    setAddDestinationAddressListForEdit,
  ] = useState([]);
  const [deletedIdArray, setDeletedIdArray] = useState([]);
  const [deletedIdArrayForDestination, setDeletedIdArrayForDestination] =
    useState([]);

  const handleRemoveAddress = (data, indexToBeDeleted) => {
    const filteredArray = sourceAddressList.filter(
      (item, index) => index !== indexToBeDeleted
    );
    const filteredNewArray = addSourceAddressListForEdit.filter(
      (item, index) => index == indexToBeDeleted
    );
    addSourceAddressListForEdit.pop(filteredNewArray);
    setSourceAddressList(filteredArray);
    if (data.id) {
      setDeletedIdArray([...deletedIdArray, { id: data.id }]);
    }
  };
  const [newSourceDetails, setNewSourceDetails] = useState({
    // id: editMode ? editId : "",
    concern_person_name: "",
    concern_person_mobile_number: "",
    postcode: "",
    type: "source",
    village: "0",
    postoffice: "",
    sub_district: "",
    district: "",
    state: "",
    landmark: "",
    latitude: "",
    longitude: "",
  });

  function changehandle(e) {
    setNewSourceDetails({
      ...newSourceDetails,
      [e.target.name]: e.target.value,
    });
  }

  const [changedEditAddress, setChangedEditAddress] = [];

  function handleSubmitSourceAddress() {
    let {
      concern_person_name,
      concern_person_mobile_number,
      postcode,
      type,
      village,
      postoffice,
      sub_district,
      district,
      landmark,
      latitude,
      longitude,
    } = newSourceDetails;

    if (concern_person_name == "") {
      setErrorAddressName("Name is required");
    }
    if (concern_person_mobile_number == "") {
      setErrorAddressNumber("mobile number is required.");
    }
    if (postcode == "") {
      setErrorAddressPincode("Pincode is required.");
    }
    if (village == "0") {
      setErrorAddressVillage("Village is required.");
    }
    if (
      concern_person_name &&
      concern_person_mobile_number &&
      postcode &&
      village
    ) {
      // if ((selectedIndex == 0 || selectedIndex > 0) && editId) {
      //
      //   let temp = [...sourceAddressList];
      //
      //   temp[selectedIndex] = {
      //     concern_person_name,
      //     concern_person_mobile_number,
      //     postcode,
      //     village,
      //     ...addressDetails,
      //     landmark,
      //     latitude,
      //     longitude,
      //     id: editId,
      //   };

      //   setSourceAddressList(temp);
      // } else {

      // if(editMode)
      setSourceAddressList([
        ...sourceAddressList,
        {
          concern_person_name,
          concern_person_mobile_number,
          postcode,
          village,
          ...addressDetails,
          // postoffice,
          // sub_district,
          // district,
          // state,
          landmark,
          latitude,
          longitude,
        },
      ]);
      // }

      toggleDrawer("right", false);
    }
    if (editMode) {
      setAddSourceAddressListForEdit([
        ...addSourceAddressListForEdit,
        {
          concern_person_name,
          concern_person_mobile_number,
          postcode,
          village,
          ...addressDetails,
          // postoffice,
          // sub_district,
          // district,
          // state,
          landmark,
          latitude,
          longitude,
        },
      ]);

      // const filteredAddress = sourceAddressList.filter(
      //   (item, index) => index !== indexDelete
      // );
      // setSourceAddressList(filteredAddress);

      toggleDrawer("right", false);
    }
    //
    setNewSourceDetails({
      concern_person_name: "",
      concern_person_mobile_number: "",
      postcode: "",
      type: "",
      village: "",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    });
  }

  const [loadingButton, setLoadingButton] = useState(false);
  const [addressDetailsDestination, setAddressDetailsDestination] = useState({
    district: "",
    sub_district: "",
    state: "",
    postoffice: "",
    type: "",
  });

  const handleRemoveAddressDestination = (data, indexToBeDeleted) => {
    const filteredDestination = destinationAddressList.filter(
      (item, index) => index !== indexToBeDeleted
    );
    setDestinationAddressList(filteredDestination);

    const filteredNewArrayDestination = addDestinationAddressListForEdit.filter(
      (item, index) => index == indexToBeDeleted
    );
    addDestinationAddressListForEdit.pop(filteredNewArrayDestination);
    if (data.id) {
      setDeletedIdArrayForDestination([
        ...deletedIdArrayForDestination,
        { id: data.id },
      ]);
    }
  };
  const [destinationDetails, setDestinationDetails] = useState([
    {
      concern_person_name: "",
      concern_person_mobile_number: "",
      postcode: "",
      type: "destination",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    },
  ]);
  const [newdestinationDetails, setNewDestinationDetails] = useState([
    {
      concern_person_name: "",
      concern_person_mobile_number: "",
      postcode: "",
      type: "destination",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    },
  ]);
  function changehandleDestination(e) {
    setNewDestinationDetails({
      ...newdestinationDetails,
      [e.target.name]: e.target.value,
    });
  }

  function changhandleSubmitDestination() {
    let {
      concern_person_name,
      concern_person_mobile_number,
      postcode,
      type,
      village,
      postoffice,
      sub_district,
      district,
      landmark,
      latitude,
      longitude,
    } = newdestinationDetails;

    if (concern_person_name == "") {
      setErrorAddressName("Name is required");
    }
    if (concern_person_mobile_number == "") {
      setErrorAddressNumber("mobile number is required.");
    }
    if (postcode == "") {
      setErrorAddressPincode("Pincode is required.");
    }
    if (village == "") {
      setErrorAddressVillage("Village is required.");
    }
    if (
      concern_person_name &&
      concern_person_mobile_number &&
      postcode &&
      village
    ) {
      setDestinationAddressList([
        ...destinationAddressList,
        {
          concern_person_name,
          concern_person_mobile_number,
          postcode,
          village,
          ...addressDetailsDestination,
          // postoffice,
          // sub_district,
          // district,
          // state,
          landmark,
          latitude,
          longitude,
        },
      ]);

      toggleDrawer("right", false);
    }
    if (editMode) {
      setAddDestinationAddressListForEdit([
        ...addDestinationAddressListForEdit,
        {
          concern_person_name,
          concern_person_mobile_number,
          postcode,
          village,
          ...addressDetailsDestination,
          // postoffice,
          // sub_district,
          // district,
          // state,
          landmark,
          latitude,
          longitude,
        },
      ]);

      // const filteredAddress = sourceAddressList.filter(
      //   (item, index) => index !== indexDelete
      // );
      // setSourceAddressList(filteredAddress);

      toggleDrawer("right", false);
    }
    //
    setNewDestinationDetails({
      concern_person_name: "",
      concern_person_mobile_number: "",
      postcode: "",
      type: "",
      village: "",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "",
    });
    // setErrorAddressName("");
  }

  // ==============================edit address logic source=======================

  const [deletedList, setDeletedList] = useState([]);
  // let newTemp = [];
  const newSourceAddressForEdit = {
    new: addSourceAddressListForEdit,
    changed: [],
    deleted: deletedIdArray,
    unchanged: sourceAddressList.filter((data) => data.id),
  };
  const newDestinationAddressForEdit = {
    new: addDestinationAddressListForEdit,
    changed: [],
    deleted: deletedIdArrayForDestination,
    unchanged: destinationAddressList.filter((data) => data.id),
  };

  // ===============================================================================
  const [driverDetails, setDriverDetails] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    pan_number: "",
    driving_licence_number: "",
    alternate_mobile_number: "",
  });

  const [vehicleNumber, setVehicleNumber] = useState("0");
  const [transporterNameId, setTransporterNameId] = useState("");

  const [selectedTransporterName, setSelectedTransporterName] = useState("");

  const [availableVehicleList, setAvailableVehicleList] = useState([]);
  const [currentCustomerField, setCurrentCustomerField] = useState("");
  const [consignorId, setConsignorId] = useState("");
  const [vehiclePayoutPeriod, setVehiclePayoutPeriod] = useState("");
  const [loadSource, setLoadSource] = useState("manual");
  // const [perKmRate, setPerKmRate] = useState("");

  const [loadUnit, setLoadUnit] = useState("ton");

  const [invoiceId, setInvoiceId] = useState([]);
  const [searchInvoiceLoading, setSearchInvoiceLoading] = useState(false);
  const [perKmRate, setPerKmRate] = useState("");
  const [tripStartMeterReading, setTripStartMeterReading] = useState("");
  // const [errValueOfGoods, setErrValueOfGoods] = useState("");

  const [searchResultsList, setSearchResultsList] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState({});
  const [selectedWarehouseUser, setSelectedWarehouseUser] = useState({});
  const [errTotalDriver, setErrTotalDriver] = useState("");
  const [errCustomerFinal, setErrCustomerFinal] = useState("");
  const [addCustomerData, setAddCustomerData] = useState({});

  const [purchaseOrderId, setPurchaseOrderId] = useState([]);

  const [zohoId, setZohoId] = useState("");
  const [perBagCost, setPerBagCost] = useState("");
  // const [paymentOptions, setPaymentOptions] = useState({
  //   reference_id: "",
  //   payment_mode: "0",
  // });
  function capitalizeLetter(str) {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags;
  }
  const [errSourceAddress, setErrSourceAddress] = useState({
    errPersonName: "",
    errPersonMobileNumber: "",
    errPostcode: "",
    errVillage: "",
  });
  const [errTrip, setErrTrip] = useState({
    errLoadSource: "",
    errReferenceId: "",
    errVehicleNumber: "",
    errTransporter: "",
    errLoadUnit: "",
    errTripDistance: "",
    errPaymentStatus: "",
    errPaymentTerms: "",
    errTotalCost: "",
    errAdvanceCost: "",
    errTotalQty: "",
    errCustomer: "",
    errConsignee: "",
    errBillToParty: "",
    errTripStartMeterReading: "",
    errTripStartMeterReadingAttachment: "",
    errPerKmRate: "",
    errValueOfGoods: "",
    errCustomerBilledAmount: "",
    errCustomerAdvanceAmount: "",
    errTripType: "",
    errLrNumber: "",
    errEwayNumber: "",
    errEwayFrom: "",
    errEwayTo: "",
    quantity_in_tonnes: "",
    no_of_pieces: "",
    customer_invoice: "",
  });

  const [paymentTerms, setPaymentTerms] = useState("");
  const [errDestinationAddress, setErrDestinationAddress] = useState({
    errPersonName: "",
    errPersonMobileNumber: "",
    errPostcode: "",
    errVillage: "",
  });
  const [attachmentListArray, setAttachmentListArray] = useState([
    {
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    },
  ]);

  const [ewayBillAttachment, setEwayBillAttachment] = useState(null);

  const [tripStartMeterReadingAttachment, setTripStartMeterReadingAttachment] =
    useState({
      attachment_tag: "trip_start_meter_reading_attachment",
      attachment_type: "",
      attachment_file: null,
    });

  const [customerInvoiceAttachment, setCustomerInvoiceAttachment] = useState();
  const [customerInvoiceNo, setCustomerInvoiceNo] = useState("");

  const [currentStatusUpdateInProgress, setCurrentStatusUpdateInProgress] =
    useState("");
  // const [errPaymentOptions, setErrPaymentOptions] = useState({
  //   errReferenceId: "",
  //   errPaymentMode: "",
  // });
  const [searchPurchaseOrders, setSearchPurchaseOrders] = useState("");
  const [currentDestinationId, setCurrentDestinationId] = useState(null);
  const [currentSourceId, setCurrentSourceId] = useState(null);
  const [tripStatus, setTripStatus] = useState("created");
  const [tripDistance, setTripDistance] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("0");
  const [advanceByCustomer, setAdvanceByCustomer] = useState("");
  const [billedByCustomer, setBilledByCustomer] = useState("");
  const [errSourceFinal, setErrSourceFinal] = useState("");
  const [errDestinationFinal, setErrDestinationFinal] = useState("");
  const [errSourceArray, setErrSourceArray] = useState([]);
  const [errDestinationArray, setErrDestinationArray] = useState([]);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [errDriverFinal, setErrDriverFinal] = useState("");
  const [costKeys, setCostKeys] = useState([]);
  // const [errEwayBill, setErrEwayBill] = useState({
  //   errEwayNumber: "",
  //   errEwayFrom: "",
  //   errEwayTo: "",
  // });
  const [valueOfGoods, setValueOfGoods] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [invoiceButtonPressed, setInvoiceButtonPressed] = useState(false);
  const [totalQuantityForPBC, setTotalQuantityForPBC] = useState([]);
  const [pbcCostPurchase, setPbcCostPurchase] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedConsignee, setSelectedConsignee] = useState({});
  const [selectedBillToParty, setSelectedBillToParty] = useState({});

  const [driverLoadingButton, setDriverLoadingButton] = useState(false);
  const [customerLoadingButton, setCustomerLoadingButton] = useState(false);
  const [errCreateTripFinal, setErrCreateTripFinal] = useState("");
  const [ewayBillNumber, setEwayBillNumber] = useState("");
  const [qunatityInTonnes, setQuantityInTonnes] = useState("");

  const [bhadaChiti, setBhadaChiti] = useState();
  const [biltyCopy, setBiltyCopy] = useState();

  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState("");
  const [ewayBillFrom, setEwayBillFrom] = useState("");
  const [ewayBillTo, setEwayBillTo] = useState("");
  const [tripCost, setTripCost] = useState([
    {
      cost_type: "advance",
      cost_amount: "",
    },
    {
      cost_type: "trip_cost",
      cost_amount: "",
    },
  ]);

  const [errDriverDetails, setErrDriverDetails] = useState({
    errFirstName: "",
    errMobileNumber: "",
    errPanNumber: "",
    errDLNumber: "",
    errPanDocs: "",
    errDLDocs: "",
  });

  const [errCustomerDetails, setCustomerDetails] = useState({
    errName: "",
    errLogo: "",
  });
  const [allChargesList, setAllChargesList] = useState([
    "labour_charges",
    "freight_charges",
    "toll_charges",
    "night_halt_charges",
    "others_expenses",
    "trip_cost",
  ]);

  const [entityTypeList, setEntityTypeList] = useState([]);

  // const entityTypeList = [
  //   "transfer_order",
  //   "vendor_credit_notes",
  //   "sales_return",
  //   "others",
  // ];
  // const [approxCostDetails, setApproxCostDetails] = useState({
  //   z: "",
  // });
  const [tripIdEdit, setTripIdEdit] = useState(null);
  const [errPincodeNotServiceable, setErrPincodeNotServiceable] = useState("");
  const [loadSourceList, setLoadSourceList] = useState([
    "trip_purchase_order",
    "trip_invoice",
  ]);
  const [openToast, setOpenToast] = useState(false);
  const [loadingDataForEdit, setLoadingDataForEdit] = useState(true);
  const [entityType, setEntityType] = useState("0");
  const [paymentModeList, setPaymentModeList] = useState(["online", "offline"]);
  const [invoiceItemsDetails, setInvoiceItemsDetails] = useState([]);
  const [loadUnitList, setLoadUnitList] = useState([]);
  const [tripType, setTripType] = useState(removedStringForTripType(trip_type));

  const [costTypeList, setCostTypeList] = useState([
    "approx_trip_cost",
    "actual_trip_cost",
  ]);
  const [attachmentTypeList, setAttachmentTypeList] = useState([
    "image",
    "pdf",
  ]);
  const [currentTab, setCurrentTab] = useState(null);
  const [warehouseUserList, setWarehouseUserList] = useState([]);
  const [referenceNumberManual, setReferenceNumberManual] = useState("");
  const [customerSearchValue, setCustomerSearchValue] = useState("");
  const [consigneeSearchValue, setConsigneeSearchValue] = useState("");
  const [billToSearchValue, setBillToSearchValue] = useState("");

  const [paymentStatusList, setPaymentStatusList] = useState([
    "unpaid",
    "partially_paid",
  ]);

  const tripDetailsFromRedux = useSelector((state) => state.trip.tripDetails);

  const driverDetailsFromRedux = useSelector(
    (state) => state.trip.driverDetails
  );

  const handleSourceFocus = (e) => {
    e.preventDefault();
    setErrSourceFinal("");
    // toggleDrawer("right", true)(e);
    setCurrentTab("source");
  };

  const resetFormDriver = () => {
    setErrDriverDetails({
      errFirstName: "",
      errMobileNumber: "",
      errPanNumber: "",
      errDLNumber: "",
      errPanDocs: "",
      errDLDocs: "",
    });
    setDrivingPhoto(null);
    setDriverDetails({
      first_name: "",
      last_name: "",
      mobile_number: "",
      pan_number: "",
      driving_licence_number: "",
      alternate_mobile_number: "",
    });
  };

  const minDate = () => {
    const today = new Date().toISOString().split("T")[0];
    return today;
  };

  const getTripDetailsFromApi = async (tripId) => {
    const response = await getTripDetailsFromId(tripId);

    dispatch(updateTripDetails(response));
    dispatch(updateDriverDetails(response.driver));
  };

  useEffect(() => {
    if (tripId) {
      getTripDetailsFromApi(tripId);
    }
  }, [tripId]);

  const handleAttachmentChange = (e, index) => {
    e.preventDefault();
    //
    let key = e.target.name;
    let value;
    if (key == "attachment_file") {
      value = e.target.files[0];
      //
    } else {
      value = e.target.value;
    }
    //
    //

    let attachmentArrayTemp = [...attachmentListArray];

    if (index == 0 && loadSource != "trip_invoice") {
      attachmentArrayTemp[0]["attachment_tag"] = "bilty_copy";
    }
    attachmentArrayTemp[index][key] = value;
    setAttachmentListArray(attachmentArrayTemp);
    //
    //
  };

  const handlePress = (e) => {
    setCurrentCustomerField(e.target.name);
    //
  };

  const getTripSettingsByCustomer = async (id, tripType) => {
    if (currentCustomerField == "consignor") {
      const response = await getSettingsListByCustomerId(
        parseInt(id),
        tripType
      );
      if (response) {
        // const tripTypeSettings = response.trip_settings_list
        //
        setTripSettingsList(response.whitelisted_consignors_list);
      }
    }
  };

  useEffect(() => {
    if (consignorId && removedStringForTripType(trip_type)) {
      getTripSettingsByCustomer(
        consignorId,
        removedStringForTripType(trip_type)
      );
    }
  }, [consignorId, removedStringForTripType(trip_type)]);

  const getWareHouseListApi = async () => {
    var wareHouseList = await getWarehouseList(selectedCustomer.id, 1);
    setWareHouseList(wareHouseList.warehouses_list);
    //

    // setState(address.data.state);
    // setDistrict(address.data.district);
    // setVillageList(address.data.office[0].villages);

    // setNewVillagesList(address.data.office[0].villages);
    // }
  };

  const vertical = "bottom";
  const horizontal = "right";

  useEffect(() => {
    if (!(vehiclePayoutPeriod == "monthly_wise")) {
      let tempperKm =
        (tripCost.length > 0 ? tripCost[1].cost_amount : 0) / tripDistance;
      setPerKmRate(tempperKm.toFixed(2));
    }
  }, [tripDistance, tripCost]);

  useEffect(() => {
    //
    if (Object.keys(selectedCustomer).length > 0) {
      //
      getWareHouseListApi();
    }
  }, [selectedCustomer]);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.title,
  });
  //

  useEffect(() => {
    // setLoadingDataForEdit(true);
    if (editMode == true && !transporterNameId && !driverId) {
      handleOpenFullPageLoad();
    }

    if (Object.keys(tripDetailsFromRedux).length > 0) {
      if (editMode == true) {
        handleCloseFullPageLoad();
      }
      setCurrentCustomerField("consignor");
      setConsignorId(tripDetailsFromRedux.customer_id);
      setDestinationAddressList(tripDetailsFromRedux.destination);
      setSourceAddressList(tripDetailsFromRedux.source);
      setEditMode(true);
      // setSourceDetails(tripDetailsFromRedux.source);
      setTripIdEdit(tripDetailsFromRedux.id);
      setSelectedCustomer(tripDetailsFromRedux.customers_details);
      setSelectedConsignee(
        tripDetailsFromRedux.consignee_details
          ? tripDetailsFromRedux.consignee_details
          : {}
      );
      setSelectedBillToParty(tripDetailsFromRedux.bill_to_party_details);
      // setApproxCostDetails(tripDetailsFromRedux.approx_trip_cost);
      setLoadSource(tripDetailsFromRedux.load_source);
      // setDestinationDetails(tripDetailsFromRedux.destination);
      setPaymentStatus(tripDetailsFromRedux.payments_status);
      setPaymentTerms(tripDetailsFromRedux.payment_terms);

      setTripType(
        tripDetailsFromRedux.trip_type ? tripDetailsFromRedux.trip_type : ""
      );

      setPerKmRate(tripDetailsFromRedux.per_km_rate);
      setValueOfGoods(tripDetailsFromRedux.value_of_goods);
      setExpectedDeliveryDate(tripDetailsFromRedux.expected_delivery_date);
      setQuantityInTonnes(tripDetailsFromRedux.quantity_in_tonnes);
      setCustomerInvoiceNo(tripDetailsFromRedux.customer_invoice);
      if (tripDetailsFromRedux.trip_eway_bill) {
        setEwayBillNumber(
          tripDetailsFromRedux.trip_eway_bill?.eway_bill_number
        );
        setEwayBillFrom(
          tripDetailsFromRedux.trip_eway_bill?.eway_bill_valid_from
        );
        setEwayBillTo(
          tripDetailsFromRedux.trip_eway_bill?.eway_bill_valid_till
        );
      }
      // setEwayBillNumber(tripDetailsFromRedux.value_of_goods);
      setVehiclePayoutPeriod(tripDetailsFromRedux.vehicle_payout_period);
      setTripStartMeterReadingAttachment({
        ...tripStartMeterReadingAttachment,
        attachment_file: "sample-text",
      });
      setTripStartMeterReading(tripDetailsFromRedux.trip_start_meter_reading);
      if (tripDetailsFromRedux.trip_cost.length > 1) {
        //
        let tripCostTemp = [
          ...tripDetailsFromRedux.trip_cost.map((item) => ({
            ...item,
            cost_amount: `${item.cost_amount}`,
          })),
        ];

        //
        if (tripCostTemp[0]["cost_type"] == "trip_cost") {
          tripCostTemp.reverse();
          //
        }
        setTripCost([...tripCostTemp]);
        // console.log(
        //   "trip cost type in useeffect",
        //   tripCostTemp[0]["cost_type"]
        // );
      } else if (tripDetailsFromRedux.trip_cost.length == 1) {
        //
        setTripCost([
          {
            cost_type: "advance",
            cost_amount: "",
          },
          ...tripDetailsFromRedux.trip_cost.map((item) => ({
            ...item,
            cost_amount: `${item.cost_amount}`,
          })),
        ]);
      } else {
        //

        setTripCost([...tripDetailsFromRedux.trip_cost]);
      }

      setTotalQty(tripDetailsFromRedux.quantity);
      // setPaymentOptions(tripDetailsFromRedux.trip_payment);
      setDriverDetails(driverDetailsFromRedux);
      setDriverId(driverDetailsFromRedux.id);

      if (tripDetailsFromRedux.trip_customer_invoice.length > 0) {
        setAdvanceByCustomer(
          tripDetailsFromRedux.trip_customer_invoice[0].advance_paid_by_customer
        );
        setBilledByCustomer(
          tripDetailsFromRedux.trip_customer_invoice[0].customer_billed_amount
        );
      }

      // setAvailableVehicleList([
      //   { vehicle_number: tripDetailsFromRedux.vehicle_number },
      // ]);
      setLoadUnit(
        loadUnitList.length > 0
          ? loadUnitList.filter(
            (item) => item.id == tripDetailsFromRedux.load_unit_id
          )[0].unit_code
          : null
      );

      // console.log(
      //   "load unit id",
      //   loadUnitList.length > 0
      //     ? loadUnitList.filter(
      //         (item) => item.id == tripDetailsFromRedux.load_unit_id
      //       )[0].unit_code
      //     : null
      // );

      // setPaymentOptions({payment_mode:})

      setVehicleNumber(tripDetailsFromRedux.vehicle_number);
      setReferenceNumberManual(tripDetailsFromRedux.reference_number_for_trip);
      setEntityType(tripDetailsFromRedux.entity_type);
      setSelectedTransporterName(tripDetailsFromRedux.transporter_name);
      setTransporterNameId(tripDetailsFromRedux.transporter_id);
      setTripDistance(tripDetailsFromRedux.estimated_kms);
      if (tripDetailsFromRedux.load_source == "trip_purchase_order") {
        setPurchaseOrderId(tripDetailsFromRedux.reference_number);
        //
      } else if (tripDetailsFromRedux.load_source == "trip_invoice") {
        setInvoiceId(tripDetailsFromRedux.reference_number);
        //
        // setInvoiceId([
        //   {
        //     reference_number: tripDetailsFromRedux.reference_number,
        //     reference_id: tripDetailsFromRedux.purchaseorder_id,
        //   },
        // ]);
      }
      setCustomerInvoiceNo(tripDetailsFromRedux.customer_invoice);
      setExpectedDeliveryDate(tripDetailsFromRedux.expected_delivery_date);
      setQuantityInTonnes(tripDetailsFromRedux.quantity_in_tonnes);
    }
  }, [tripDetailsFromRedux, driverDetailsFromRedux, loadUnitList]);

  useEffect(() => {
    toggleDrawer("right", true);
  }, [currentTab]);

  const handleDriverSelect = (e) => {
    setDriverDetails(e);
    setDriverId(e.id);
    setErrDriverFinal("");
    //
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const handleAddCustomer = () => {
    setCurrentTab("customer");
    toggleDrawer("right", true);
  };

  const handleAddAttachment = (e) => {
    let attachmentTemp = [...attachmentListArray];
    attachmentTemp.push({
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    });
    attachmentTemp.push({
      attachment_tag: "1",
      attachment_type: "",
      attachment_file: null,
    });
    setAttachmentListArray(attachmentTemp);
    e.preventDefault();
  };

  const handleGetCustomerSearch = async (searchTerm) => {
    const response = await GetCustomerSearchList(searchTerm);

    if (Object.keys(tripDetailsFromRedux).length > 0) {
      setCustomerList(
        response.customers_list
          .filter((item) => item.name !== "faarms")
          .filter((item) => item.name !== "FAARMS")
          .filter((item) => item.name !== "Faarms")
      );
    } else {
      // if (currentCustomerField == "consignor") {
      //   selectedCustomer(selectedCustomer.id);
      //   // setConsignorId(response.customers_list[0].id);
      // }
      setCustomerList(
        response.customers_list
          .filter((item) => item.name !== "faarms")
          .filter((item) => item.name !== "FAARMS")
          .filter((item) => item.name !== "Faarms")
      );
    }
  };

  // const handleGetCustomerSearch = async (searchTerm) => {
  //   const response = await GetCustomerSearchList(searchTerm);
  //   setCustomerList(response.customers_list);
  // };

  useEffect(() => {
    if (customerSearchValue) {
      handleGetCustomerSearch(customerSearchValue);
    } else {
      // handleGetCustomerSearch("");
      setCustomerList([]);
    }
  }, [customerSearchValue]);

  useEffect(() => {
    if (consigneeSearchValue) {
      handleGetCustomerSearch(consigneeSearchValue);
    } else {
      // handleGetCustomerSearch("");
      setCustomerList([]);
    }
  }, [consigneeSearchValue]);

  useEffect(() => {
    if (billToSearchValue) {
      handleGetCustomerSearch(billToSearchValue);
    } else {
      // handleGetCustomerSearch("");
      setCustomerList([]);
    }
  }, [billToSearchValue]);

  // const handleSourceBlur = (e) => {
  //   e.preventDefault();
  //   toggleDrawer("right", false);
  //   setCurrentTab(null);
  // };

  const handleDriverFocus = (e) => {
    e.preventDefault();
    setErrDriverFinal("");
    setCurrentTab("driver");
  };

  // const focusAsync = () => {
  //
  //   const asyncRef!.focus();
  // };

  const handleSearchButtonInvoices = () => {
    if (referenceNumberSearchValue.length > 5) {
      if (loadSource === "trip_invoice") {
        handleFetchInvoices(referenceNumberSearchValue);
      } else if (loadSource === "trip_purchase_order") {
        handleFetchPurchaseOrders(referenceNumberSearchValue);
      }
    }

    // setIsMenuOpen(true);
    // setInvoiceButtonPressed(true);
  };

  //

  const handleChangeTripMeterReadingAttachment = (e) => {
    setTripStartMeterReadingAttachment({
      ...tripStartMeterReadingAttachment,
      attachment_file: e.target.files[0],
    });
  };

  const handleChangeCustomerInvoiceAttachment = (e) => {
    setCustomerInvoiceAttachment(e.target.files[0]);
  };

  const handleGetCustomerById = async (customerId) => {
    const response = await GetCustomerById(customerId);
    if (response) {
      return response;
    }
  };

  let filtredAllMandatory = tripSettingsList.filter(
    (data) => data.behaviour == "mandatory"
  );
  let newArray = filtredAllMandatory.map((data) => data.fields);
  const mandatoryFields = newArray.flat();

  let filtredAllOptional = tripSettingsList.filter(
    (data) => data.behaviour == "optional"
  );
  let newArrayOptional = filtredAllOptional.map((data) => data.fields);
  const optionalFields = newArrayOptional.flat();

  // console.log(finalArray.includes("customer_billed_amount"));

  useEffect(() => {
    if (mandatoryFields.length > 0) {
      setChecked(false);
    }
  }, [mandatoryFields]);

  const handleFetchCustomersList = async (tripDetailsFromRedux) => {
    const customersReponse = await GetCustomersList();
    setCustomerList(customersReponse.customers_list);
    if (Object.keys(tripDetailsFromRedux).length > 0) {
      const selectedCustomerTemp = customersReponse.customers_list.filter(
        (customer) => customer.id === tripDetailsFromRedux.customer_id
      );
      if (selectedCustomerTemp.length > 0) {
        setSelectedCustomer(selectedCustomerTemp[0]);
      }

      const selectedConsigneeTemp = customersReponse.customers_list.filter(
        (customer) => customer.id === tripDetailsFromRedux.customer_id
      );
      if (selectedCustomerTemp.length > 0) {
        setSelectedCustomer(selectedCustomerTemp[0]);
      }
    }
  };

  // useEffect(() => {
  //
  //   setCostKeys(Object.keys(approxCostDetails));
  // }, [approxCostDetails]);

  useEffect(() => {
    return () => {
      dispatch(updateTripDetails({}));
      dispatch(updateDriverDetails({}));
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      const unitData = await GetUnitList();
      setLoadUnitList(unitData.unit_list);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (totalQuantityForPBC.length > 0) {
      //
      const totalTemp = totalQuantityForPBC
        .map((item) => Object.values(item)[0])
        .reduce((a, b) => a + b);
      //

      // console.log(
      //   "array of values",
      //   (tripCost[1].cost_amount / totalTemp).toFixed(2)
      // );
      if (tripCost.length > 0) {
        setPbcCostPurchase((tripCost[1].cost_amount / totalTemp).toFixed(2));
      } else {
        setPbcCostPurchase("0");
      }
    } else if (totalQty > 0) {
      if (tripCost.length > 0) {
        setPbcCostPurchase((tripCost[1].cost_amount / totalQty).toFixed(2));
      } else {
        setPbcCostPurchase("0");
      }
    } else {
      setPbcCostPurchase("0.0");
    }
  }, [totalQuantityForPBC, tripCost, totalQty]);

  //
  //

  useEffect(() => {
    async function fetchData() {
      const documentTypeData = await GetDocumentEnums();
      const filteredEnum = documentTypeData
        .filter((data) => data !== "e_way_bill")
        .filter((data) => data !== "trip_start_meter_reading_attachment")
        .filter((data) => data !== "bhada_chithi_attachment");
      setDocumentTypeList(filteredEnum);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const entityTypeData = await GetEntityTypeEnums();
      setEntityTypeList(entityTypeData);
    }
    fetchData();
  }, []);

  // useEffect(() => {
  //   if (Object.keys(tripDetailsFromRedux).length > 0) {
  //     handleFetchCustomersList(tripDetailsFromRedux);
  //   }
  // }, [tripDetailsFromRedux]);

  const checkValidationAll = async () => {
    if (!driverId) {
      setErrDriverFinal("Please enter driver details");
    }

    const tripTypeValid = tripType;

    let tripAdvanceValid = false;
    if (vehiclePayoutPeriod == "monthly_wise") {
      tripAdvanceValid = true;
    } else {
      tripAdvanceValid =
        tripCost[0].cost_amount === ""
          ? true
          : parseInt(tripCost[0].cost_amount) == 0
            ? false
            : parseInt(tripCost[0].cost_amount) < 0
              ? false
              : parseInt(tripCost[0].cost_amount) <=
                parseInt(tripCost[1].cost_amount)
                ? true
                : false;
    }
    // let iscustomerAmountValidation = false;
    // if (advanceByCustomer && billedByCustomer) {
    //   iscustomerAmountValidation = advanceByCustomer && billedByCustomer;
    // }
    let isEwayBillValid = true;

    if (mandatoryFields.length > 0) {
      isEwayBillValid =
        mandatoryFields.length > 0
          ? ewayBillNumber && ewayBillFrom && ewayBillTo && editMode
            ? true
            : ewayBillAttachment && ewayBillFrom <= ewayBillTo
          : false;

      // ewayBillFrom &&
      // ewayBillNumber &&
      // ewayBillTo &&
      // ewayBillFrom <= ewayBillTo;
    } else {
      isEwayBillValid = true;
    }

    const fileSizeValid = tripStartMeterReadingAttachment.attachment_file
      ? tripStartMeterReadingAttachment.attachment_file.size <
        process.env.REACT_APP_MAX_FILE_SIZE
        ? true
        : false
      : false;

    const isCustomerValid = Object.keys(selectedCustomer).length > 0;
    const isConsigneeValid =
      Object.keys(selectedCustomer).length > 0
        ? selectedCustomer?.name?.toLowerCase().includes("faarms")
          ? true
          : Object.keys(selectedConsignee).length > 0
        : true;

    const isBillToPartyValid =
      Object.keys(selectedCustomer).length > 0
        ? selectedCustomer?.name?.toLowerCase().includes("faarms")
          ? true
          : Object.keys(selectedBillToParty).length > 0
        : true;

    //

    return (
      tripTypeValid &&
      driverId &&
      transporterNameId &&
      driverDetails.mobile_number.length === 10 &&
      tripAdvanceValid &&
      // (mandatoryFields.length > 0
      //   ? ewayBillNumber && ewayBillFrom && ewayBillTo && ewayBillAttachment
      //   : true) &&
      advanceByCustomer &&
      billedByCustomer &&
      tripStartMeterReading &&
      tripStartMeterReadingAttachment.attachment_file &&
      perKmRate &&
      (!fileSizeValid && editMode ? true : fileSizeValid) &&
      valueOfGoods &&
      referenceNumberManual &&
      isEwayBillValid &&
      isConsigneeValid &&
      isCustomerValid &&
      isBillToPartyValid
    );
  };

  //

  useEffect(() => {
    if (totalQuantityForPBC.length > 0) {
      const totalTemp = totalQuantityForPBC
        .map((item) => Object.values(item)[0])
        .reduce((a, b) => a + b);
      setTotalQty(totalTemp);
    } else {
      setTotalQty("");
    }
  }, [totalQuantityForPBC]);

  const handleAddDriver = () => {
    toggleDrawer("right", true);
    setErrDriverFinal("");
    setCurrentTab("driver");
  };

  const handleRemoveInvoice = (invoice) => {
    setInvoiceId(
      invoiceId.filter((item) => {
        return item != invoice;
      })
    );
    invoiceQuantityRemove(invoice[1]);
  };

  const handleRemovePurchaseOrder = (invoice) => {
    setPurchaseOrderId(
      purchaseOrderId.filter((item) => {
        return item != invoice;
      })
    );
    let totalQuantityTemp = totalQuantityForPBC;

    setTotalQuantityForPBC(
      totalQuantityTemp.filter((item) => !item.hasOwnProperty(invoice))
    );
  };

  const handleGetWarehouseUsers = async (warehouseId) => {
    const response = await getWarehouseUsersList(warehouseId, 1);

    setWarehouseUserList(response.warehouse_users_list);
  };

  const handleSelectWarehouseUser = async (
    warehouseUser,
    index,
    addressType
  ) => {
    setSelectedWarehouseUser(warehouseUser);
    if (addressType == "source") {
      let warehouseUserTemp = {};
      warehouseUserTemp["concern_person_name"] = `${warehouseUser.first_name} ${warehouseUser.last_name ? warehouseUser.last_name : ""
        }`;
      warehouseUserTemp["concern_person_mobile_number"] =
        warehouseUser.mobile_number;
      let sourceDetailsTemp = [...sourceDetails];
      sourceDetailsTemp[index] = {
        ...sourceDetailsTemp[index],
        ...warehouseUserTemp,
      };
      setSourceDetails([...sourceDetailsTemp]);
    } else {
      let warehouseUserTemp = {};
      warehouseUserTemp["concern_person_name"] = `${warehouseUser.first_name} ${warehouseUser.last_name ? warehouseUser.last_name : ""
        }`;
      warehouseUserTemp["concern_person_mobile_number"] =
        warehouseUser.mobile_number;
      let destinationDetailsTemp = [...destinationDetails];
      destinationDetailsTemp[index] = {
        ...destinationDetailsTemp[index],
        ...warehouseUserTemp,
      };
      setDestinationDetails([...destinationDetailsTemp]);
    }
  };

  const handleSelectWarehouse = async (
    warehouseDetails,
    index,
    addressType
  ) => {
    handleGetWarehouseUsers(warehouseDetails.id);
    setSelectedWarehouse(warehouseDetails);

    if (addressType == "source") {
      let sourceDetailsTemp = [...sourceDetails];
      warehouseDetails["type"] = "source";
      sourceDetailsTemp[index] = {
        ...sourceDetailsTemp[index],
        ...warehouseDetails,
      };
      setSourceDetails([...sourceDetailsTemp]);
      const response = await GetAddressFromPincode(warehouseDetails.postcode);
      // handleGetAddressSource(warehouseDetails.postcode, index);
      setSourceVillagesList(response.office[0].villages);
    } else {
      let destinationDetailsTemp = [...destinationDetails];
      destinationDetailsTemp[index] = {
        ...destinationDetailsTemp[index],
        ...warehouseDetails,
      };
      warehouseDetails["type"] = "destination";
      setDestinationDetails([...destinationDetailsTemp]);
      const response = await GetAddressFromPincode(warehouseDetails.postcode);
      // handleGetAddressSource(warehouseDetails.postcode, index);
      setDestinationVillagesList(response.office[0].villages);
      // handleGetAddressDestination(warehouseDetails.postcode, index);
    }
  };
  const [errorInaddressSource, setErrorInaddressSource] = useState("");
  const [errorInaddressDestination, setErrorInaddressDestination] =
    useState("");
  //
  const handleCreateTrip = async () => {
    const isTripValid = await TripSchema.isValid(TripFormData);
    //
    if (isTripValid && (await checkValidationAll())) {
      if (
        attachmentListArray
          .map((item) => {
            if (item.attachment_file) {
              if (
                item.attachment_file.size > process.env.REACT_APP_MAX_FILE_SIZE
              ) {
                return "greater";
              }
            }
          })
          .indexOf("greater") > -1
      ) {
        return;
      }
      setLoadingButton(true);

      if (!loadingButton) {
        if (editMode) {
          if (
            newSourceAddressForEdit.unchanged.length == 0 &&
            newSourceAddressForEdit.new.length == 0
          ) {
            setErrorInaddressSource("Please Enter Source.");
            setLoadingButton(false);
          } else if (
            newDestinationAddressForEdit.unchanged.length == 0 &&
            newDestinationAddressForEdit.new.length == 0
          ) {
            setErrorInaddressDestination("Please Enter Destination.");
            setLoadingButton(false);
          } else {
            const response = await UpdateTripPut(
              // sourceAddressList,
              newSourceAddressForEdit,
              newDestinationAddressForEdit,
              vehicleNumber,
              transporterNameId,
              tripDistance,
              tripIdEdit,
              selectedCustomer.id,
              driverId,
              paymentTerms,
              totalQty,
              tripCost,
              loadSource,
              perKmRate,
              tripStartMeterReading,
              advanceByCustomer,
              billedByCustomer,
              valueOfGoods,
              ewayBillNumber,
              ewayBillFrom,
              ewayBillTo,
              ewayBillAttachment,
              tripType,
              selectedConsignee?.id
                ? selectedConsignee?.id
                : selectedCustomer?.id,
              selectedBillToParty?.id
                ? selectedBillToParty?.id
                : selectedCustomer?.id,
              customerInvoiceAttachment,
              customerInvoiceNo,
              qunatityInTonnes,
              bhadaChiti,
              biltyCopy,
              tripStartMeterReadingAttachment || editMode,
              expectedDeliveryDate
            );
            //
            if (response?.status === 400) {
              setLoadingButton(false);
              setErrCreateTripFinal(response.data.detail);
            } else if (response?.status === 200) {
              localStorage.setItem("FlagForApiCall", false);
              // localStorage.setItem("reloadPage", true);
              // localStorage.removeItem("localStorageTripList");
              history.push("/old-trips");
              setLoadingButton(false);
            } else if (response.status == 422) {
              if (response.data.detail.length > 0) {
                setErrCreateTripFinal(
                  `Error 422 : ${response.data.detail[0].msg}`
                );
              }
              setLoadingButton(false);
            } else {
              setLoadingButton(false);
              setErrCreateTripFinal(`ERROR : ${response?.status}`);
            }
          }
          //
        } else {
          // approxCostDetails["cost_type"] = "approx_trip_cost";
          const response = await CreateTripPost(
            sourceAddressList,
            destinationAddressList,
            driverDetails,
            // approxCostDetails,
            vehicleNumber,
            transporterNameId,
            driverId,
            driverDetails.mobile_number,
            loadSource,
            loadUnit,
            loadSource == "trip_invoice"
              ? invoiceId.length > 0
                ? invoiceId.map((item) => ({
                  reference_number: item[0],
                  reference_id: item[1],
                }))
                : []
              : loadSource == "trip_purchase_order"
                ? purchaseOrderId.length > 0
                  ? purchaseOrderId.map((item) => ({
                    reference_number: item[0],
                    reference_id: item[1],
                  }))
                  : []
                : referenceNumberManual.length > 0
                  ? referenceNumberManual.split(",").map((item) => {
                    if (item) {
                      return { reference_id: item, reference_number: item };
                    }
                  })
                  : [],

            tripStatus,
            tripDistance,
            paymentStatus,
            // vehiclePayoutPeriod == "monthly_wise"
            //   ? "none"
            tripType === "middle_mile"
              ? "to_be_billed"
              : tripType === "last_mile"
                ? "to_payee"
                : paymentTerms,
            tripCost,
            totalQty,
            selectedCustomer.id,
            advanceByCustomer,
            billedByCustomer,
            tripStartMeterReading,
            tripStartMeterReadingAttachment,
            perKmRate,
            !selectedCustomer.name.toLowerCase().includes("faarms")
              ? "others"
              : entityType,
            valueOfGoods,
            ewayBillNumber,
            ewayBillFrom,
            ewayBillTo,
            ewayBillAttachment,
            tripType,
            selectedConsignee?.id
              ? selectedConsignee?.id
              : selectedCustomer?.id,
            selectedCustomer,
            selectedBillToParty?.id
              ? selectedBillToParty?.id
              : selectedCustomer?.id,
            customerInvoiceAttachment,
            expectedDeliveryDate,
            customerInvoiceNo,
            qunatityInTonnes,
            bhadaChiti,
            attachmentListArray,
            biltyCopy
          );
          if (response == "failed") {
            setLoadingButton(false);
          } else if (response.status == 201) {
            localStorage.setItem("FlagForApiCall", false);
            // localStorage.setItem("reloadPage", true);
            // localStorage.removeItem("localStorageTripList");

            // const attachmentResponse = TripAttachmentPost(
            //   "kk",
            //   response.data.id,
            //   "lso",
            //   "lsp",
            //   "start_trip",
            //   attachmentListArray
            // );
            //
            // const resolved = await Promise.all(attachmentResponse);
            history.push("/old-trips");
            // const url = "/trips";
            // window.open(url, "_blank");
            setLoadingButton(false);
          } else if (response.status == 401) {
            setPermission("Not authorized to create trip");
            setLoadingButton(false);
          } else if (response.status == 400) {
            setErrCreateTripFinal(response.data.detail);
            setLoadingButton(false);
          } else if (response.status == 422) {
            if (response.data.detail.length > 0) {
              setErrCreateTripFinal(
                `Error 422 : ${response.data.detail[0].msg}`
              );
            }

            setLoadingButton(false);
          } else {
            setErrCreateTripFinal(`Error : ${response.status}`);
            setLoadingButton(false);
          }
        }
      }
    } else {
      setErrTrip({
        errLoadSource: "Please select source",
        errReferenceId: "Please select reference Id",
        errVehicleNumber: "Please select vehicle",
        errTransporter: "Please select Transporter",
        errLoadUnit: "Please select load unit",
        errTripDistance: "Please enter valid trip distance",
        errPaymentTerms: "Please enter valid payment terms",
        errTotalCost: "Please enter valid cost",
        errAdvanceCost:
          " Advance amount should not be greater than cost amount",
        errTotalQty: "Please enter valid quantity",
        errCustomer: "Please select valid consignor",
        errConsignee: "Please select valid consignee",
        errBillToParty: "Please select valid bill to party",
        errTripStartMeterReading: "Please enter valid meter reading",
        errTripStartMeterReadingAttachment: "Please select valid attachment",
        errPerKmRate: "Please enter valid per Km Rate",
        errValueOfGoods: "Please enter valid value of goods",
        errCustomerBilledAmount: "please enter billed amount",
        errCustomerAdvanceAmount: "please enter Advance amount",
        errTripType: "Please select valid trip type",
        errLrNumber: "Please enter LR Number",
        errEwayNumber: "Please enter valid E-way number",
        errEwayFrom: "Please enter valid E-way from date",
        errEwayTo: "Please enter valid E-way till date",
        quantity_in_tonnes: "Please enter valide quantity",
        no_of_pieces: "Please enter valid No. of pieces",
        customer_invoice: "Please enter valid customer invoice no.",

        // errPaymentStatus: "Please select payment status",
      });
      // setErrEwayBill({
      //   errEwayNumber: "Please enter valid E-way number",
      //   errEwayFrom: "Please enter valid E-way from date",
      //   errEwayTo: "Please enter valid E-way till date",
      // });
    }
  };

  const handleGetAddressSource = async (pincode) => {
    //
    const response = await GetAddressFromPincode(pincode);
    console.log("the ressss", response)

    if (response) {
      setAddressDetails({
        district: response.district,
        sub_district: response.district,
        state: response.state,
        postoffice: response.office[0].name,
        type: "source",
      });
      // const source = sourceDetails;
      // source[index]["district"] = response.district;
      // source[index]["sub_district"] = response.sub_district;
      // source[index]["state"] = response.state;
      // source[index]["postoffice"] = response.office[0].name;
      // source[index]["postcode"] = response.pincode;

      setSourceVillagesList(response.area);
    } else {
      setErrPincodeNotServiceable("This Pincode not serviceable");
    }
  };

  const handleGoBack = () => {
    localStorage.setItem("FlagForApiCall", false);
    history.push("/old-trips");
  };

  const SourceAddressSchema = yup.object().shape({
    concern_person_name: yup.string().required(),
    concern_person_mobile_number: yup
      .number()
      .min(6000000000)
      .max(9999999999)
      .required(),
    postcode: yup.number().min(100000).max(999999).required(),
    village: yup.string().min(2).required(),
  });

  const DestinationAddressSchema = yup.object().shape({
    concern_person_name: yup.string().required(),
    concern_person_mobile_number: yup
      .number()
      .min(6000000000)
      .max(9999999999)
      .required(),
    postcode: yup.number().min(100000).max(999999).required(),
    village: yup.string().min(2).required(),
  });

  const DriverSchema = yup.object().shape({
    first_name: yup.string().required(),
    mobile_number: yup.number().min(6000000000).max(9999999999).required(),

    driving_licence_number: yup.string().min(6).required(),
    dl_docs: yup.mixed().required(),
  });
  const TripSchema = yup.object().shape({
    trip_source: yup.string().min(2),
    vehicle_number: yup.string().min(2).required(),
    trip_distance: yup.number().min(1),
    load_unit: yup.string().min(2).required(),
    payment_terms: yup.string().min(2).required(),
    trip_cost: yup.number().min(10).required(),
  });

  const TripFormData = {
    trip_source: loadSource,
    load_unit: loadUnit,
    vehicle_number: vehicleNumber,
    trip_distance: tripDistance,
    payment_terms:
      vehiclePayoutPeriod == "monthly_wise"
        ? "none"
        : tripType === "middle_mile"
          ? "to_be_billed"
          : tripType === "last_mile"
            ? "to_payee"
            : paymentTerms,
    trip_cost:
      vehiclePayoutPeriod == "monthly_wise"
        ? "1000"
        : tripCost.length > 1
          ? tripCost[1].cost_amount
          : null,
  };

  const handleOnChangeDestination = (e, index) => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;

    const destination = [...destinationDetails];

    destination[index][name] = value;

    setDestinationDetails(destination);
    setErrDestinationFinal("");
  };

  const handleOnChangeSource = (e, index) => {
    e.preventDefault();

    const name = e.target.name;
    const value = e.target.value;

    const source = [...sourceDetails];

    // source[index][name] = value;
    //
    setSourceDetails(source);
    setErrSourceFinal("");
  };

  const invoiceQuantityUpdate = async (id) => {
    const invoiceItems = await getZohoInvoiceItemsDetails(id);

    const totalQty = invoiceItems.total_items
      .map((item) => item.item_quantity)
      .reduce((a, b) => a + b);

    const invoiceQtyTemp = [...invoiceTotalQuantity];
    invoiceQtyTemp.push(totalQty);
    setInvoiceTotalQuantity(invoiceQtyTemp);
    setTotalQty(invoiceQtyTemp.reduce((a, b) => a + b));
  };

  const invoiceQuantityRemove = async (id) => {
    const invoiceItems = await getZohoInvoiceItemsDetails(id);

    const totalQtyTemp = invoiceItems.total_items
      .map((item) => item.item_quantity)
      .reduce((a, b) => a + b);

    const invoiceQtyTemp = [...invoiceTotalQuantity];
    //remove from array
    const indexTemp = invoiceQtyTemp.indexOf(totalQtyTemp);
    if (indexTemp > -1) {
      invoiceQtyTemp.splice(indexTemp, 1);
    }

    //remove from array
    // invoiceQtyTemp.push(totalQtyTemp);
    setInvoiceTotalQuantity(invoiceQtyTemp);

    if (invoiceQtyTemp.length > 0) {
      setTotalQty(invoiceQtyTemp.reduce((a, b) => a + b));
    } else {
      setTotalQty(0);
    }

    // console.log(
    //   "totall",
    //   invoiceQtyTemp.reduce((a, b) => a + b)
    // );
  };

  const sourceFormData = {
    concern_person_name: sourceDetails.concern_person_name,
    concern_person_mobile_number: sourceDetails.concern_person_mobile_number,
    postcode: sourceDetails.postcode,
    village: sourceDetails.village,
  };

  const handleDestinationSave = async () => {
    const destinationFormData = {
      concern_person_name:
        destinationDetails[currentDestinationId - 1].concern_person_name,
      concern_person_mobile_number:
        destinationDetails[currentDestinationId - 1]
          .concern_person_mobile_number,
      postcode: destinationDetails[currentDestinationId - 1].postcode,
      village: destinationDetails[currentDestinationId - 1].village,
    };
    const isDestinationValid = await DestinationAddressSchema.isValid(
      destinationFormData
    );
    if (isDestinationValid) {
      toggleDrawer("right", false);
    } else {
      setErrDestinationAddress({
        errPersonName: "Please enter valid name",
        errPersonMobileNumber: "Please enter valid mobile number",
        errPostcode: "Please enter valid pincode",
        errVillage: "Please select village",
      });
    }
  };

  const handleSourceSave = async () => {
    const sourceFormData = {
      concern_person_name:
        sourceDetails[currentSourceId - 1].concern_person_name,
      concern_person_mobile_number:
        sourceDetails[currentSourceId - 1].concern_person_mobile_number,
      postcode: sourceDetails[currentSourceId - 1].postcode,
      village: sourceDetails[currentSourceId - 1].village,
    };
    const isSourceValid = await SourceAddressSchema.isValid(sourceFormData);
    if (isSourceValid) {
      toggleDrawer("right", false);
      // setSourceDetails([]);
    } else {
      setErrSourceAddress({
        errPersonName: "Please enter valid name",
        errPersonMobileNumber: "Please enter valid mobile number",
        errPostcode: "Please enter valid pincode",
        errVillage: "Please select village",
      });
    }
    //
  };

  const driverFormData = {
    first_name: driverDetails.first_name,
    mobile_number: driverDetails.mobile_number,
    // pan_number: driverDetails.pan_number,
    driving_licence_number: driverDetails.driving_licence_number,
    // pan_docs: driverPanImage,
    dl_docs: drivingPhoto,
  };
  const handleDrivinPan = (event) => {
    setDriverPanImage(event.target.files[0]);
  };

  const handleSourceRemove = (index) => {
    let sourceDetailsTemp = [...sourceDetails];
    sourceDetailsTemp.splice(index, 1);
    setSourceDetails(sourceDetailsTemp);
  };

  const handleDestinationRemove = (index) => {
    let destinationDetailsTemp = [...destinationDetails];
    destinationDetailsTemp.splice(index, 1);
    setDestinationDetails(destinationDetailsTemp);
  };

  const handleDrivingPhoto = (event) => {
    setDrivingPhoto(event.target.files[0]);
  };

  const handleCustomerLogo = (event) => {
    setAddCustomerData({ ...addCustomerData, logo: event.target.files[0] });
  };

  const handleDriverSave = async () => {
    const isDriverValid = await DriverSchema.isValid(driverFormData);
    const isDLSizeValid = drivingPhoto
      ? drivingPhoto.size > process.env.REACT_APP_MAX_FILE_SIZE
        ? false
        : true
      : true;

    if (isDriverValid && isDLSizeValid) {
      setDriverLoadingButton(true);

      const driverPostResponse = await CreateDriverPost(
        driverDetails.first_name,
        driverDetails.last_name,
        driverDetails.mobile_number,
        driverDetails.driving_licence_number,
        driverDetails.pan_number,
        driverPanImage,
        drivingPhoto,
        driverDetails.alternate_mobile_number
      );
      if (driverPostResponse.status === 200) {
        setDriverId(driverPostResponse.data.id);
        toggleDrawer("right", false);
        setDriverLoadingButton(false);
      } else if (driverPostResponse.status === 400) {
        setDriverLoadingButton(false);
        setErrTotalDriver(driverPostResponse.data.detail);
      } else {
        setDriverLoadingButton(false);
        setErrTotalDriver(`ERROR : ${driverPostResponse.status}`);
      }
      //
    } else {
      setErrDriverDetails({
        errFirstName: "Please enter valid first name",
        errMobileNumber: "Please enter valid mobile number",
        errPanNumber: "Please enter valid PAN number",
        errDLNumber: "Please enter valid DL number",
        errPanDocs: "Please upload valid PAN image/pdf",
        errDLDocs: "Please upload valid DL image/pdf",
      });
    }
  };

  const handleCustomerSave = async () => {
    // const isDriverValid = await DriverSchema.isValid(driverFormData);
    const isLogoSizeValid = addCustomerData
      ? addCustomerData.size > process.env.REACT_APP_MAX_FILE_SIZE
        ? false
        : true
      : true;
    //
    if (addCustomerData?.name && addCustomerData?.logo && isLogoSizeValid) {
      setCustomerLoadingButton(true);

      const customerPostResponse = await customersPost(
        addCustomerData?.name,
        addCustomerData?.logo,
        "image",
        addCustomerData?.description
      );
      if (customerPostResponse === 200) {
        handleCloseCustomerDrawer();
        setAddCustomerData({});
        // toggleDrawer("right", false);

        setCustomerLoadingButton(false);
      } else if (customerPostResponse?.status === 400) {
        setCustomerLoadingButton(false);
        setErrCustomerFinal(customerPostResponse.data.detail);
      } else {
        setCustomerLoadingButton(false);
        setErrCustomerFinal(`ERROR : ${customerPostResponse?.status}`);
      }
      //
    } else {
      setCustomerDetails({
        errName: "Please enter valid name",
        errLogo: "Please select valid logo file",
      });
    }
  };

  //

  const handleSubmitDestination = (e) => {
    // handleShow();
    setDestinationDetails([
      ...destinationDetails,
      {
        concern_person_name: "",
        concern_person_mobile_number: "",
        postcode: "",
        type: "destination",
        village: "0",
        postoffice: "",
        sub_district: "",
        district: "",
        state: "",
        landmark: "",
        latitude: "",
        longitude: "",
      },
    ]);
    // e.stopPropagation();
  };

  const handleAddSource = (e) => {
    e.stopPropagation();
    setSourceDetails([
      ...sourceDetails,
      {
        concern_person_name: "",
        concern_person_mobile_number: "",
        postcode: "",
        type: "source",
        village: "0",
        postoffice: "",
        sub_district: "",
        district: "",
        state: "",
        landmark: "",
        latitude: "",
        longitude: "",
      },
    ]);
  };

  const handleFetchPurchaseOrders = async (searchValue) => {
    setSearchInvoiceLoading(true);
    const response = await GetPurchaseOrders(searchValue);
    setSearchInvoiceLoading(false);

    setSearchResultsList(
      response.purchaseorders.map((item) => {
        return {
          value: [item.purchaseorder_number, item.purchaseorder_id],
          label: item.purchaseorder_number,
          quantity: item.total_ordered_quantity,
        };
      })
    );
  };

  const handleFetchVehicles = async (searchValue, source) => {
    const response = await GetVehicles(searchValue, source);

    if (Object.keys(tripDetailsFromRedux).length > 0) {
      return [
        {
          value: tripDetailsFromRedux.vehicle_number,
          label: tripDetailsFromRedux.vehicle_number,
        },
        ...response.vehicle_list.map((item) => {
          const dateToday = moment().format("YYYY-MM-DD");

          let startDate = moment(dateToday, "YYYY-MM-DD");
          let expireDateRC = moment(
            item.registration_certificate_expiry_date,
            "YYYY-MM-DD"
          );
          let expireDateInsurance = moment(
            item.insurance_certificate_expiry_date,
            "YYYY-MM-DD"
          );
          let expireDatePermit = moment(item.permit_expiry_date, "YYYY-MM-DD");
          let expireDatePollution = moment(
            item.pollution_control_certificate_expiry_date,
            "YYYY-MM-DD"
          );
          let insuranceExpires = expireDateInsurance.diff(startDate, "days");
          let permitExpires = expireDatePermit.diff(startDate, "days");
          let pollutionExpires = expireDatePollution.diff(startDate, "days");
          let rcExpires = expireDateRC.diff(startDate, "days");
          return {
            value: item.vehicle_number,
            payout_period: item.payout_period,
            status: item.status,
            vehicle_manufacturer_name: item.vehicle_manufacturer_name,
            vehicle_type: item.vehicle_model_list.vehicle_type,
            extra_per_km_rate: item.extra_per_km_rate,
            insuranceExpires: expireDateInsurance.diff(startDate, "days"),
            permitExpires: expireDatePermit.diff(startDate, "days"),
            pollutionExpires: expireDatePollution.diff(startDate, "days"),
            rcExpires: expireDateRC.diff(startDate, "days"),
            label: (
              <div>
                <div>{item.vehicle_number}</div>
                <div>{item.vehicle_manufacturer_name}</div>
              </div>
            ),
          };
        }),
      ];
    }

    // console.log("doksdos", moment().format("MM"));
    return response.vehicle_list.map((item) => {
      const dateToday = moment().format("YYYY-MM-DD");

      let startDate = moment(dateToday, "YYYY-MM-DD");
      let expireDateRC = moment(
        item.registration_certificate_expiry_date,
        "YYYY-MM-DD"
      );
      let expireDateInsurance = moment(
        item.insurance_certificate_expiry_date,
        "YYYY-MM-DD"
      );
      let expireDatePermit = moment(item.permit_expiry_date, "YYYY-MM-DD");
      let expireDatePollution = moment(
        item.pollution_control_certificate_expiry_date,
        "YYYY-MM-DD"
      );
      let insuranceExpires = expireDateInsurance.diff(startDate, "days");
      let permitExpires = expireDatePermit.diff(startDate, "days");
      let pollutionExpires = expireDatePollution.diff(startDate, "days");
      let rcExpires = expireDateRC.diff(startDate, "days");
      return {
        value: item.vehicle_number,
        payout_period: item.payout_period,
        status: item.status,
        vehicle_manufacturer_name: item.vehicle_manufacturer_name,
        vehicle_type: item.vehicle_model_list.vehicle_type,
        extra_per_km_rate: item.extra_per_km_rate,
        insuranceExpires: expireDateInsurance.diff(startDate, "days"),
        permitExpires: expireDatePermit.diff(startDate, "days"),
        pollutionExpires: expireDatePollution.diff(startDate, "days"),
        rcExpires: expireDateRC.diff(startDate, "days"),
        label: (
          <Create_trip_vehicle_single
            style={{ display: "flex", width: "100%" }}
          // className="create-trip-vehicle-single"
          >
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <span className="create-trip-vehicle-number-text">
                    {item.vehicle_number}
                  </span>
                </div>
                <div>
                  {item.payout_period &&
                    item.payout_period == "monthly_wise" ? (
                    <div className="create-trip-monthly-container">
                      <img
                        src={MONTHLYICON}
                        className="create-trip-monthly-img"
                        alt=""
                      />
                      <h4 className="create-trip-monthly-text">Monthly</h4>
                    </div>
                  ) : (
                    <div className="create-trip-monthly-container">
                      <img
                        src={TRIPWISEICON}
                        className="create-trip-monthly-img"
                        alt=""
                      />
                      <h4 className="create-trip-monthly-text">Tripwise</h4>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginLeft: isMobile ? "10px" : "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "12px",
                    color: "white",
                    padding: "1px 4px",
                    background:
                      item.status === "available"
                        ? colorPalette.primaryColor
                        : item.status === "blocked"
                          ? "#FF6666"
                          : item.status === "in_transit"
                            ? "#E5B35D"
                            : "#65c368",
                    borderTopLeftRadius: "3px",
                    borderBottomLeftRadius: "3px",
                  }}
                >
                  {item.status ? capitalizeLetter(item.status) : "--"}
                </div>
              </div>

              <div>
                <span className="create-trip-sub-text">
                  {item.vehicle_manufacturer_name} (
                  {item.vehicle_model_list.vehicle_type})
                </span>
              </div>
              <div>
                <span className="create-trip-sub-text">
                  {item.transporters_details}{" "}
                </span>
              </div>
              <div className="vehicle-expiry-check-container">
                <div className="vehile-expiry-single-container">
                  <div>
                    <img
                      src={rcExpires >= 0 ? TICKICON : WRONGICON}
                      className="create-trip-right-wrong-img"
                      alt=""
                    />
                  </div>
                  <div className="vehicle-expiry-text"> RC</div>
                </div>
                <div className="vehile-expiry-single-container">
                  <div>
                    <img
                      src={insuranceExpires >= 0 ? TICKICON : WRONGICON}
                      alt=""
                      className="create-trip-right-wrong-img"
                    />
                  </div>
                  <div className="vehicle-expiry-text"> Insurance</div>
                </div>
                <div className="vehile-expiry-single-container">
                  <div>
                    <img
                      src={permitExpires >= 0 ? TICKICON : WRONGICON}
                      alt=""
                      className="create-trip-right-wrong-img"
                    />
                  </div>
                  <div className="vehicle-expiry-text"> Permit</div>
                </div>
                <div className="vehile-expiry-single-container">
                  <div>
                    <img
                      src={pollutionExpires >= 0 ? TICKICON : WRONGICON}
                      alt=""
                      className="create-trip-right-wrong-img"
                    />
                  </div>
                  <div className="vehicle-expiry-text"> Pollution</div>
                </div>
              </div>
            </div>
          </Create_trip_vehicle_single>
        ),
      };
    });
  };
  function isNumber(value) {
    return /^[0-9]*$/.test(value);
  }
  const handleFetchTransporter = async (values) => {
    const integerValue = isNumber(values);

    try {
      const productResponse = await axiosInterceptor.get(
        `${apiUrl.onboardingBaseUrl}/v3/transporter?name=${integerValue == false ? values.trim() : ""
        }&mobile=${integerValue ? values : ""}&status=approved&is_active=true`
      );

      return productResponse.data.transporter_list.map((item) => {
        return {
          value: item.id,
          transporter_code: item.transporter_code,
          name: item.name,
          mobile: item.mobile,
          // district: item.address.district,
          // state: item.address.state,

          label: (
            <div
              style={{ display: "flex" }}
              className="create-trip-vehicle-single"
            >
              <div>
                <div>
                  <div>
                    <span className="create-trip-transporter-number-text">
                      {item.transporter_code}
                    </span>
                  </div>
                </div>

                <div>
                  <span className="create-trip-sub-text">
                    <PersonOutlineOutlinedIcon className="trip_transporter_icons" />
                    {item.name}
                  </span>
                </div>
                <div>
                  <span className="create-trip-sub-text">
                    {" "}
                    <PhoneAndroidOutlinedIcon className="trip_transporter_icons" />
                    {item.mobile}{" "}
                  </span>
                </div>
                {/* <div>
                  <span className="create-trip-sub-text">
                    <LocationOnOutlinedIcon className="trip_transporter_icons" />{" "}
                    {item.address.district},{item.address.state}
                  </span>
                </div> */}
              </div>
            </div>
          ),
        };
      });
    } catch (error) {
      if (error.response.status === 403) {
        localStorage.clear();
        window.location.reload();
      }
      //
    }
  };
  const transportersOptions = (inputValue) =>
    new Promise((resolve) => {
      const response = handleFetchTransporter(inputValue);
      //

      resolve(response);
    });
  const handleFetchDrivers = async (searchValue) => {
    const response = await GetDrivers(searchValue);

    return response.drivers_list.map((item) => {
      return {
        value: item.driving_licence_number,
        label: (
          <div>
            <div>
              <span className="create-trip-select-list-right-text">
                {item.first_name + " "}
                {item.last_name ? item.last_name : ""}
              </span>
            </div>
            <div>
              <span className="create-trip-select-list-left-text">
                DL no :{" "}
              </span>
              <span className="create-trip-select-list-right-text">
                {item.driving_licence_number}
              </span>
            </div>
            <div>
              <span className="create-trip-select-list-left-text">
                Mobile :{" "}
              </span>
              <span className="create-trip-select-list-right-text">
                {item.mobile_number}
              </span>
            </div>
          </div>
        ),
        first_name: item.first_name,
        last_name: item.last_name,
        mobile_number: item.mobile_number,
        alternate_mobile_number: item.alternate_mobile_number,
        pan_number: item.pan_number,
        driving_licence_number: item.driving_licence_number,
        id: item.id,
      };
    });
  };

  const handleClearDriver = () => {
    setDriverDetails({
      first_name: "",
      last_name: "",
      mobile_number: "",
      pan_number: "",
      driving_licence_number: "",
      alternate_mobile_number: "",
    });
    setDriverId("");
  };

  const handleTripCostChange = (e, index) => {
    let key = e.target.name;
    let value = e.target.value;

    let tripCostTemp = [...tripCost];
    tripCostTemp[index][key] = value;
    setTripCost([...tripCostTemp]);

    setErrTrip({ ...errTrip, errTotalCost: "", errAdvanceCost: "" });
  };

  const handleCloseDriverDrawer = () => {
    toggleDrawer("right", false);
    setErrorAddressName("");
    setErrorAddressNumber("");
    setErrorAddressPincode("");
    setErrorAddressVillage("");
    setCustomerDetails({
      errName: "",
      errLogo: "",
    });
    setErrDriverDetails({
      errFirstName: "",
      errMobileNumber: "",
      errPanNumber: "",
      errDLNumber: "",
      errPanDocs: "",
      errDLDocs: "",
    });
  };

  const handleCloseCustomerDrawer = () => {
    toggleDrawer("right", false);
  };

  const handleInvoiceChange = async (e) => {
    //   console.log("existing>>",invoiceId,"new>>>",e.value)
    // console.log("inoice dup>>>",invoiceId.filter((invoice)=>invoice[0]===e.value[0]))
    const duplicateInvoice = invoiceId.filter(
      (invoice) => invoice[0] === e.value[0]
    );
    if (duplicateInvoice.length === 0) {
      setInvoiceId([...invoiceId, e.value]);
    } else {
      setErrorMsg(`Invoice Number ${e.value[0]} already added`);
      setOpenToast(true);
      // console.log("Invoice already added")
    }

    if (
      invoiceId.length > destinationDetails.length - 1 &&
      loadSource == "trip_invoice"
    ) {
      // handleAddDestination();
    }
    const tempIndex = invoiceId.length;

    handleGetAddressDestination(
      e.address.zipcode,
      tempIndex,
      e.address.attention,
      e.contactNumber,
      e.address.address
    );
    invoiceQuantityUpdate(e.value[1]);
    setSearchResultsList([]);
    setReferenceNumberSearchValue("");
    e.stopPropagation();
  };

  const handleClearReferenceSearch = () => {
    setReferenceNumberSearchValue("");
    setSearchResultsList([]);
  };

  // useEffect(async () => {
  //   invoiceQuantityUpdate();
  // }, [invoiceId]);

  const handleFetchInvoices = async (searchValue) => {
    setSearchInvoiceLoading(true);
    const response = await GetInvoices(searchValue);

    setSearchInvoiceLoading(false);
    setSearchResultsList(
      response.invoices
        .filter(
          (invoice) =>
            invoice.status.toLowerCase() === "draft" ||
            invoice.status.toLowerCase() === "overdue" ||
            invoice.status.toLowerCase() === "sent"
        )
        .map((item) => {
          return {
            value: [item.invoice_number, item.invoice_id],
            // value: item.invoice_number,
            label: item.invoice_number,
            address: item.shipping_address,
            contactNumber: item.custom_field_hash.cf_customer_contact,
          };
        })
    );

    return response.invoices
      .filter(
        (invoice) =>
          invoice.status.toLowerCase() === "draft" ||
          invoice.status.toLowerCase() === "overdue" ||
          invoice.status.toLowerCase() === "sent"
      )
      .map((item) => {
        return {
          value: [item.invoice_number, item.invoice_id],
          // value: item.invoice_number,
          label: item.invoice_number,
          address: item.shipping_address,
          contactNumber: item.custom_field_hash.cf_customer_contact,
        };
      });
  };

  const handleGetAddressDestination = async (pincode) => {
    const response = await GetAddressFromPincode(pincode);
    console.log("the ressss", response)
    if (response) {
      setAddressDetailsDestination({
        district: response.district,
        sub_district: response.sub_district,
        state: response.state,
        postoffice: response.office[0].name,
        type: "destination",
      });
      // const source = sourceDetails;
      // source[index]["district"] = response.district;
      // source[index]["sub_district"] = response.sub_district;
      // source[index]["state"] = response.state;
      // source[index]["postoffice"] = response.office[0].name;
      // source[index]["postcode"] = response.pincode;
      setDestinationVillagesList(response.office[0].villages);
    } else {
      setErrPincodeNotServiceable("This Pincode not serviceable");
    }
    // if (response) {
    //   const destination = [...destinationDetails];
    //   if (index >= destination.length) {
    //     destination.push({
    //       concern_person_name: "",
    //       concern_person_mobile_number: "",
    //       postcode: "",
    //       type: "destination",
    //       village: "0",
    //       postoffice: "",
    //       sub_district: "",
    //       district: "",
    //       state: "",
    //       landmark: "",
    //       latitude: "",
    //       longitude: "",
    //     });
    //   }
    //
    //
    //   destination[index]["district"] = response.district;
    //   destination[index]["sub_district"] = response.sub_district;
    //   destination[index]["state"] = response.state;
    //   destination[index]["postoffice"] = response.office[0].name;
    //   destination[index]["postcode"] = response.pincode;
    //   if (city || phone || name) {
    //     destination[index]["concern_person_name"] = name;
    //     destination[index]["concern_person_mobile_number"] = phone;
    //     destination[index]["village"] = city;
    //   }

    //   setDestinationVillagesList(response.office[0].villages);
    //   setDestinationDetails(destination);
    //
    // } else {
    //   setErrPincodeNotServiceable("This Pincode not serviceable");
    // }
  };

  //Drawer starts

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
    // if (currentTab == "driver" && open == false) {
    //   resetFormDriver();
    // }
  };

  //Drawer ends

  //react-select starts//
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      const response = handleFetchPurchaseOrders(inputValue);
      resolve(response);
    });

  const promiseOptionsInvoices = (inputValue) =>
    new Promise((resolve) => {
      //
      let response = [];
      if (invoiceButtonPressed) {
        response = handleFetchInvoices(inputValue);
      }

      resolve(response);
    });
  //react-select-ends//

  const vehicleOptions = (inputValue, source) =>
    new Promise((resolve) => {
      const response = handleFetchVehicles(inputValue, source);

      resolve(response);
    });

  const driverOptions = (inputValue) =>
    new Promise((resolve) => {
      var response = handleFetchDrivers(inputValue);

      resolve(response);
    });

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  function capitalizeLetter(str) {
    var i,
      splittedArray = str?.split("_");
    for (i = 0; i < splittedArray?.length; i++) {
      splittedArray[i] =
        splittedArray[i].charAt(0).toUpperCase() + splittedArray[i].slice(1);
    }
    return splittedArray?.join(" ");
  }
  const handleSource = () => {
    // setCurrentSourceId(index + 1);

    toggleDrawer("right", true);
    setCurrentTab(`source`);
    // handleAddSource();
  };
  const handleDestination = () => {
    // setCurrentSourceId(index + 1);

    toggleDrawer("right", true);
    setCurrentTab(`destination`);
    // handleAddSource();
  };
  // const [selectedCustomer, setSelectedCustomer] = useState({});
  // const [selectedConsignee, setSelectedConsignee] = useState({});
  // const [selectedBillToParty
  // console.log(
  //   "the all values-----",
  //   selectedCustomer.name,
  //   selectedConsignee.name,
  //   selectedBillToParty.name,
  //   billToSearchValue,
  //   customerSearchValue,
  //   consigneeSearchValue,
  //   destinationAddressList,
  //   sourceAddressList,
  //   referenceNumberManual,
  //   transporterNameId,
  //   driverId,
  //   vehicleNumber
  // );
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const clearEwayBillData = () => {
    if (checked == true) {
      setEwayBillNumber("");
      setEwayBillFrom("");
      setEwayBillTo("");
      setEwayBillAttachment(null);
    }
  };
  useEffect(() => {
    clearEwayBillData(checked);
  }, [checked]);

  //
  const handleClick = (v, index) => {
    setNewSourceDetails(v);
    setEditId(v.id);
    setSelectedIndex(index);
    // setDeletedIdArray([...myNewArray, v.id]);

    // setEditAddressDeleteIndex(indexDelete);
    toggleDrawer("right", true);
    setCurrentTab(`source`);
  };
  const handleCloseFullPageLoad = () => {
    setLoadingDataForEdit(false);
  };
  const handleOpenFullPageLoad = () => {
    setLoadingDataForEdit(true);
  };
  const [show, setShow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  //====================== trip-settings code========================================
  // const trip_settings_list= [{
  //   "id": 8,
  //   "default_behaviour": "mandatory",
  //   "trip_type": "first_mile",
  //   "whitelisted_consignor": {
  //     "id": 156,
  //     "name": "Rajeev"
  //   },
  //   "feature": {
  //     "name": "payment_terms",
  //     "fields": ["advance_paid_by_customer", "customer_billed_amount"]
  //   }
  // },
  //   {
  //   "id": 8,
  //   "default_behaviour": "mandatory",
  //   "trip_type": "first_mile",
  //   "whitelisted_consignor": {
  //     "id": 156,
  //     "name": "Rajeev"
  //   },
  //   "feature": {
  //     "name": "reference_number",
  //     "fields": ["reference_number","transporter_id"]
  //   }
  // },
  // {
  //   "id": 8,
  //   "default_behaviour": "optional",
  //   "trip_type": "first_mile",
  //   "whitelisted_consignor": {
  //     "id": 156,
  //     "name": "Rajeev"
  //   },
  //   "feature": {
  //     "name": "eway_bill",
  //     "fields": ["eway_bill_number", "eway_bill_valid_till", "eway_bill_valid_from","shdhsdhshd"]
  //   }
  // }
  // ]

  // =================================================================================
  useEffect(() => {
    if (
      removedStringForTripType(trip_type) === "first_mile" &&
      selectedCustomer?.id === selectedBillToParty?.id
    ) {
      setPaymentTerms("to_be_billed");
    } else if (
      removedStringForTripType(trip_type) === "first_mile" &&
      selectedConsignee?.id === selectedBillToParty?.id
    ) {
      setPaymentTerms("to_payee");
    } else if (
      removedStringForTripType(trip_type) === "first_mile" &&
      selectedConsignee?.id !== selectedBillToParty?.id
    ) {
      setPaymentTerms("to_payee");
    } else {
      setPaymentTerms("");
    }
    // if {
    //   setPaymentTerms("");
    // }
  }, [
    selectedCustomer?.id,
    selectedBillToParty?.id,
    selectedConsignee?.id,
    removedStringForTripType(trip_type),
  ]);

  return (
    <Create_Trip_Main_Container
      className="create-trip-main-container"
    // style={{ backgroundColor: "red" }}
    >
      {editMode ? (
        <Dialog
          onClose={handleCloseFullPageLoad}
          onClick={(e) => e.stopPropagation()}
          open={loadingDataForEdit}
          style={{ backgroundColor: "none" }}
        // disabled={loading}
        >
          <div
            style={{
              padding: ".5rem 1rem",
              // display: "flex",
              width: "200x",
              height: "150px",
              // flexDirection: "column",
            }}
          >
            <div>
              <img
                src={success}
                alt=""
                width="120px"
                height="90px"
              // style={{ marginTop: "200px" }}
              />
              <div style={{ color: "grey" }}>loading data...</div>
            </div>
          </div>
        </Dialog>
      ) : null}

      <Modal
        // title="Modal 1000px width"
        centered
        open={openSummary}
        onOk={() => setOpenSummary(false)}
        onCancel={() => setOpenSummary(false)}
        footer={null}
        width={1200}
        height={1000}
      >
        <Summary_container className="summary_container">
          {/* <div > */}
          <div className="summary_header">
            <h3>Trip Summary</h3>
            <button
              className="edit_summary_button"
              onClick={() => {
                setOpenSummary(false);
                setErrCreateTripFinal("");
              }}
            >
              <ModeEditOutlineOutlinedIcon style={{ fontSize: "15px" }} />
              Edit
            </button>
          </div>
          <div className="summary_details_container">
            <div className="summary_details_trip_type">
              <h3>Trip Details</h3>
              <div className="trip_type_summary">
                <h3>
                  {" "}
                  <LocationOnOutlinedIcon
                    style={{
                      color: "#0FBC9E",
                    }}
                  />
                  {capitalizeLetter(removedStringForTripType(trip_type))} Trip
                </h3>
              </div>
            </div>
            <div className="summary_content_wrapper1">
              <div className="summary_customer_address">
                <div className="summary_customer_wrapper_address">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      padding: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    ></div>
                    <div>
                      <div className="trip-list-address">
                        <div className="trip-location-list">
                          {sourceAddressList?.map((source) => {
                            return (
                              <div className="trip-location-text">From</div>
                            );
                          })}
                          {/* <div className="trip-location-text">From</div> */}
                          {destinationAddressList?.map((destinations) => {
                            return <div className="trip-location-text">To</div>;
                          })}
                        </div>
                        <div className="trip-list-path">
                          {/* <div className="trip-rectangle-summary"></div> */}
                          <div className="trip-circle"></div>
                          <div
                            className="mini-trip-rectangle"
                            style={{
                              backgroundColor: "grey",
                              height: "10px",
                              width: "2px",
                            }}
                          ></div>
                          {sourceAddressList?.map((source, index) => {
                            return (
                              <>
                                {index == 0 ? null : (
                                  <>
                                    {/* <div className="trip-circle"></div> */}
                                    <div className="trip-circle"></div>
                                    <div
                                      className="mini-trip-rectangle"
                                      style={{
                                        backgroundColor: "grey",
                                        height: "10px",
                                        width: "2px",
                                      }}
                                    ></div>
                                  </>
                                )}
                              </>
                            );
                          })}

                          {/* <div className="trip-rectangle-summary"></div> */}
                          {destinationAddressList?.map(
                            (destinations, index) => {
                              return (
                                <>
                                  {index == 0 ? null : (
                                    <>
                                      {/* <div className="trip-circle"></div> */}
                                      <div className="trip-circle"></div>
                                      <div
                                        className="mini-trip-rectangle"
                                        style={{
                                          backgroundColor: "grey",
                                          height: "10px",
                                          width: "2px",
                                        }}
                                      ></div>
                                    </>
                                  )}
                                </>
                              );
                            }
                          )}
                          <div className="trip-circle"></div>
                        </div>
                        <div className="trip-location-list">
                          {/* <div className="trip-location-text"> */}
                          {sourceAddressList?.map((source) => {
                            return (
                              <div className="trip-location-text-summary">
                                {source.village === source.district
                                  ? source.village
                                  : `${source.village},
                          ${source.district}`}{" "}
                                , {source.state}, {source.postcode}
                              </div>
                            );
                          })}

                          {destinationAddressList?.map((destinations) => {
                            return (
                              <div className="trip-location-text-summary">
                                {destinations.village === destinations.district
                                  ? destinations.district
                                  : `${destinations.village}, ${destinations.district}`}
                                , {destinations.state}, {destinations.postcode}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summary_customer">
                <div className="summary_customer_wrapper">
                  <div className="summary_details_text_customer_details">
                    <div className="customer_tag">Consignor:</div>{" "}
                    <div className="customer_value">
                      {selectedCustomer.name}
                    </div>
                  </div>
                  <div className="summary_details_text_customer_details">
                    {" "}
                    <div className="customer_tag">Consignee:</div>
                    <div className="customer_value">
                      {selectedConsignee.name}
                    </div>
                  </div>
                  <div className="summary_details_text_customer_details">
                    <div className="customer_tag">Bill to party:</div>{" "}
                    <div className="customer_value">
                      {selectedBillToParty.name}
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="summary_content_wrapper2">
              <div className="summary_vehicle">
                <div className="summary_content_heading">Vehicle details</div>
                <hr
                  className="summary_underline2"
                  style={{
                    backgroundColor: "#0FBC9E",
                    height: "3px",
                    outline: "none",
                  }}
                />
                <div className="summary_details_text"> {vehicleNumber} </div>
                <div>
                  {capitalizeLetter(vehicleDetails?.manufacturer)}{" "}
                  {capitalizeLetter(vehicleDetails?.model)}
                </div>
                <div> {capitalizeLetter(vehicleDetails?.payout)}</div>
                <div style={{ color: "transparent" }}>
                  E-way bill attachment : Attached
                </div>
                <div style={{ color: "transparent" }}>
                  E-way bill attachment : Attached
                </div>
              </div>
              <div className="summary_Driver">
                <div className="summary_content_heading">Driver details</div>
                <hr
                  className="summary_underline2"
                  style={{
                    backgroundColor: "#0FBC9E",
                    height: "3px",
                    outline: "none",
                  }}
                />
                <div className="summary_details_text">
                  {selectedDriverDetails.first_name
                    ? selectedDriverDetails.first_name
                    : driverDetails.first_name}{" "}
                  {selectedDriverDetails.last_name
                    ? selectedDriverDetails.last_name
                    : driverDetails.last_name}
                </div>
                <div>
                  DL no :{" "}
                  {selectedDriverDetails.dl
                    ? selectedDriverDetails.dl
                    : driverDetails.driving_licence_number}
                </div>
                <div>
                  Mobile :{" "}
                  {selectedDriverDetails.mobile
                    ? selectedDriverDetails.mobile
                    : driverDetails.mobile_number}
                </div>
                <div style={{ color: "transparent" }}>
                  E-way bill attachment : Attached
                </div>
                <div style={{ color: "transparent" }}>
                  E-way bill attachment : Attached
                </div>
              </div>
              <div className="summary_Driver">
                <div className="summary_content_heading">
                  Transporter details
                </div>
                <hr
                  className="summary_underline2"
                  style={{
                    backgroundColor: "#0FBC9E",
                    height: "3px",
                    outline: "none",
                  }}
                />
                <div className="summary_details_text">
                  {transporterDetails.transporter}
                </div>
                <div>Code : {transporterDetails.code}</div>
                <div>Mobile : {transporterDetails.mobile}</div>
                <div>
                  LR Number :{" "}
                  {referenceNumberManual ? referenceNumberManual : ""}
                </div>

                <div>
                  Address :{transporterDetails.district},
                  {transporterDetails.state}
                </div>
              </div>
            </div>
            <div className="summary_content_wrapper3">
              <div className="summary_vehicle">
                <div className="summary_content_heading">Basic details</div>
                <hr
                  className="summary_underline2"
                  style={{
                    backgroundColor: "#0FBC9E",
                    height: "3px",
                    outline: "none",
                  }}
                />

                {/* <div className="summary_details_text">
                  Load Unit :{loadUnit ? loadUnit.toUpperCase() : ""}{" "}
                </div> */}
                <div className="summary_details_text">
                  Quantity in tonnes :
                  {qunatityInTonnes ? qunatityInTonnes : "--"}{" "}
                </div>
                <div>Number of pieces : {totalQty}</div>
                <div>Value of goods :₹{valueOfGoods}</div>
                <div>Estimate distance : {tripDistance}Kms</div>
                <div>Per Km rate : ₹{perKmRate}/km</div>
                <div>Trip start meter rate : {tripStartMeterReading}</div>
                <div style={{ color: "transparent", visibility: "hidden" }}>
                  for box size : {referenceNumberManual}
                </div>
              </div>
              <div className="summary_Driver">
                <div className="summary_content_heading">Payment details</div>
                <hr
                  className="summary_underline2"
                  style={{
                    backgroundColor: "#0FBC9E",
                    height: "3px",
                    outline: "none",
                  }}
                />
                {/* billedByCustomer && advanceByCustomer && tripCost.length */}
                <div className="summary_details_text">
                  Payment terms :
                  {tripType === "last_mile" || tripType === "middle_mile"
                    ? "To be billed"
                    : capitalizeLetter(paymentTerms)}
                </div>
                <div>
                  Total transport cost :{" "}
                  {tripCost
                    .filter((data) => data.cost_type === "trip_cost")
                    .map(
                      (cost) =>
                        `${cost.cost_amount ? `₹${cost.cost_amount}` : "--"}`
                    )}
                </div>
                <div>
                  Transporter advance :
                  {tripCost
                    .filter((data) => data.cost_type === "advance")
                    .map(
                      (cost) =>
                        `${cost.cost_amount ? `₹${cost.cost_amount}` : "--"}`
                    )}
                </div>
                <div>Advance paid by customer :₹{advanceByCustomer}</div>
                <div>Customer billed amount :₹{billedByCustomer}</div>
                <div style={{ color: "transparent", visibility: "hidden" }}>
                  for box size :{" "}
                </div>
                <div style={{ color: "transparent", visibility: "hidden" }}>
                  for box size :{" "}
                </div>

                {/* <div style={{ color: "transparent" }}>
                  E-way bill attachment : Attached
                </div> */}
              </div>
              <div className="summary_Driver">
                <div className="summary_content_heading">
                  E-way bill details and attachments
                </div>
                <hr
                  className="summary_underline2"
                  style={{
                    backgroundColor: "#0FBC9E",
                    height: "3px",
                    outline: "none",
                  }}
                />
                {/* ewayBillNumber &&
            ewayBillTo &&
            ewayBillAttachment?.name &&
            ewayBillFrom */}
                <div className="summary_details_text">
                  E-way bill number :{ewayBillNumber ? ewayBillNumber : "--"}
                </div>
                <div>
                  E-way bill form :{ewayBillFrom ? ewayBillFrom : "--"}{" "}
                </div>
                <div>E-way bill till : {ewayBillTo ? ewayBillTo : "--"} </div>
                <div>
                  E-way bill attachment :{" "}
                  {ewayBillAttachment?.name ? (
                    <span style={{ color: "green" }}>Attached</span>
                  ) : checked ? (
                    <span style={{ color: "#F2C846" }}>Not required.</span>
                  ) : (
                    <span style={{ color: "red" }}>Not attached.</span>
                  )}
                </div>
                <div>
                  Start meter attachment :{" "}
                  {tripStartMeterReadingAttachment.attachment_file?.name ? (
                    <span style={{ color: "green" }}>Attached</span>
                  ) : (
                    <span style={{ color: "red" }}>Not Attached</span>
                  )}
                </div>
                {/* <div style={{ color: "transparent" }}>
                  E-way bill till : 26 jan 2023{" "}
                </div> */}
                <div>
                  Bhada Chiti attachment :{" "}
                  {bhadaChiti?.name ? (
                    <span style={{ color: "green" }}>Attached</span>
                  ) : (
                    <span style={{ color: "red" }}>Not Attached</span>
                  )}
                </div>
                <div>
                  Customer Invoice No. :{" "}
                  {customerInvoiceNo ? customerInvoiceNo : "-"}
                </div>
              </div>
            </div>
            <div className="summary_content_wrapper4">
              <div>
                {" "}
                {errCreateTripFinal ? (
                  <div className="create-trip-alert">
                    <Alert severity="error">{errCreateTripFinal}</Alert>
                  </div>
                ) : null}
                {/* <div className="create-trip-button-container"> */}
                {/* </div> */}
              </div>
            </div>
            <div className="summary_content_wrapper4">
              <div>
                {/* <div className="create-trip-button-container"> */}
                <button
                  className="create-trip-button-summary"
                  onClick={() => {
                    handleCreateTrip();
                    // setErrDriverFinal("");
                  }}
                >
                  {loadingButton ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Create Trip"
                  )}
                </button>
                {/* </div> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {permission ? (
                    <Alert severity="error">{permission}</Alert>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Summary_container>
      </Modal>

      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Create trip" />
        </div>
      ) : (
        <div className="create-trip-sidebar-container">
          <SideBarMain />
        </div>
      )}

      <div
        className="create-trip-right-container"
      // style={{ backgroundColor: "red" }}
      >
        {/* <Button type="primary" onClick={() => setOpenSummary(true)}>
          Next
        </Button> */}
        {/* {isMobile ? null : <Topbar />} */}
        <div className="create_trip_top_Section">
          <span
            style={{
              fontSize: "20px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              // marginBottom: "30px",
              fontWeight: "500",
              opacity: show ? "1" : "0",
              transition: show ? "all 1s" : "",
              visibility: show ? "visible" : "hidden",
            }}
          >
            <ArrowBack
              onClick={handleGoBack}
              style={{
                color: colorPalette.primaryColor,
                marginRight: "14px",
                cursor: "pointer",
              }}
            />
            {editMode ? "Update Trip" : "Create Trip"}
          </span>
          <span
            className="trip_type_text"
            style={{
              opacity: show ? "1" : "0",
              transition: show ? "all 1s" : "",
              visibility: show ? "visible" : "hidden",
            }}
          >
            <LocationOnOutlinedIcon />{" "}
            {capitalizeLetter(
              removedStringForTripType(trip_type)
                ? removedStringForTripType(trip_type)
                : ""
            )}
          </span>
        </div>

        {/* <div className="create-trip-group-card"> */}
        <div className="create-trip-from-head"></div>

        <Snackbar
          open={openToast}
          autoHideDuration={8000}
          anchorOrigin={{ vertical, horizontal }}
          onClose={handleCloseToast}
        >
          <Alert
            variant="filled"
            elevation={6}
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%" }}
          >
            {errorMsg ? errorMsg : ""}
          </Alert>
        </Snackbar>
        {/* <StyledModal
          open={isFilterOpen}
          onClose={handleCloseFilterOptions}
          BackdropComponent={Backdrop}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={filterBoxStyle}>
            <Summary_container className="summary_container">
              <div className="summary_header">
                <h5>Trip Summary</h5>
                <button>Edit</button>
              </div>
            </Summary_container>
          </Box>
        </StyledModal> */}
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              className="summary_container"
              style={{ backgroundColor: "red" }}
            >
              <Summary_container className="summary_header">
                <h5>Trip Summary</h5>
                <button>Edit</button>
              </Summary_container>
            </div>
          </Box>
        </Modal> */}
        <div className="create-trip-from-body">
          <div
            className="trip_top_container"
            style={{
              opacity: show ? "1" : "0",
              transition: show ? "all 1s" : "",
              visibility: show ? "visible" : "hidden",
            }}
          // id="topbar"
          >
            <div className="create-trip-from-row">
              <div
                className="create-trip-input-container-customer-type"
                style={{
                  width: selectedCustomer?.name
                    ?.toLowerCase()
                    .includes("faarms")
                    ? "30%"
                    : "100%",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  {Object.keys(selectedCustomer).length > 0
                    ? "Consignor"
                    : "Consignor"}{" "}
                  <sup style={{ color: "red" }}>*</sup>
                </h4>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    label="customer"
                    // value={
                    //   Object.keys(selectedCustomer).length > 0
                    //     ? selectedCustomer
                    //     : "SEARCH"
                    // }
                    defaultValue={
                      tripDetailsFromRedux?.customers_details
                        ? tripDetailsFromRedux?.customers_details
                        : { name: "" }
                    }
                    onChange={(event, newValue) => {
                      //
                      // setValue(newValue);
                      if (newValue) {
                        setSelectedCustomer(newValue);
                        setConsignorId(newValue.id);
                        setCustomerSearchValue(newValue.name);
                        setCustomerList([]);
                        if (!newValue.name.toLowerCase().includes("faarms")) {
                          setLoadSource("manual");
                        }
                      } else {
                        setSelectedCustomer({});

                        setConsignorId("");
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        setCustomerSearchValue(newInputValue);
                      }
                    }} // sx={{ width: 300 }}
                    style={{
                      width: "100%",
                    }}
                    onKeyPress={(e) => {
                      //
                      handlePress(e);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search consignor"
                        name="consignor"
                      />
                    )}
                  />
                  {/* <button
                    onClick={handleAddCustomer}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      border: "none",
                      // height: "100%",
                      cursor: "pointer",
                      marginLeft: "0px",
                      fontSize: "13px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0px",
                      background: "#D7F2C9",
                      color: colorPalette.primaryColor,
                      // flexDirection: "column",
                      padding: "2px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      // height: "100%",
                    }}
                  >
                    <AddCircle
                      style={{
                        fontSize: "13px",
                        height: "100%",
                        color: colorPalette.primaryColor,
                      }}
                    />
                    Add
                  </button> */}
                </div>
                <div className="create-trip-error">
                  {Object.keys(selectedCustomer).length > 0
                    ? null
                    : errTrip.errCustomer}
                </div>
              </div>
              {/* {Object.keys(selectedCustomer).length > 0 ? (
                selectedCustomer?.name
                  ?.toLowerCase()
                  .includes("faarms") ? null : (
                  <> */}
              {/* <div className="create-trip-from-row"> */}
              <div className="create-trip-input-container-customer-type">
                <h4 className="create-trip-input-head">
                  {" "}
                  Consignee <sup style={{ color: "red" }}>*</sup>
                </h4>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    defaultValue={
                      tripDetailsFromRedux?.consignee_details
                        ? tripDetailsFromRedux?.consignee_details
                        : { name: "" }
                    }
                    onChange={(event, newValue) => {
                      // setValue(newValue);
                      if (newValue) {
                        setSelectedConsignee(newValue);
                        setConsigneeSearchValue(newValue.name);
                        setCustomerList([]);
                      } else {
                        setSelectedConsignee({});
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        setConsigneeSearchValue(newInputValue);
                      }
                    }} // sx={{ width: 300 }}
                    style={{ width: "100%" }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Search consignee" />
                    )}
                  />
                  {/* <button
                    onClick={handleAddCustomer}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      border: "none",
                      // height: "100%",
                      cursor: "pointer",
                      marginLeft: "0px",
                      fontSize: "13px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0px",
                      background: "#D7F2C9",
                      color: colorPalette.primaryColor,
                      // flexDirection: "column",
                      padding: "2px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }}
                  >
                    <AddCircle
                      style={{
                        fontSize: "13px",
                        height: "100%",
                        color: colorPalette.primaryColor,
                      }}
                    />
                    Add
                  </button> */}
                </div>

                <div className="create-trip-error">
                  {Object.keys(selectedConsignee).length > 0
                    ? null
                    : errTrip.errConsignee}
                </div>
              </div>

              <div className="create-trip-input-container-customer-type">
                <h4 className="create-trip-input-head">
                  {" "}
                  Bill To Party <sup style={{ color: "red" }}>*</sup>
                </h4>
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    defaultValue={
                      tripDetailsFromRedux?.bill_to_party_details
                        ? tripDetailsFromRedux?.bill_to_party_details
                        : { name: "" }
                    }
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setSelectedBillToParty(newValue);
                        setBillToSearchValue(newValue.name);
                        setCustomerList([]);
                      } else {
                        setSelectedBillToParty({});
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        setBillToSearchValue(newInputValue);
                      }
                    }}
                    style={{ width: "100%" }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Search consigner" />
                    )}
                  />

                  {/* <button
                    onClick={handleAddCustomer}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                      border: "none",
                      // height: "100%",
                      cursor: "pointer",
                      marginLeft: "0px",
                      fontSize: "13px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0px",
                      background: "#D7F2C9",
                      color: colorPalette.primaryColor,
                      // flexDirection: "column",
                      padding: "2px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                    }}
                  >
                    <AddCircle
                      style={{
                        fontSize: "13px",
                        height: "100%",
                        color: colorPalette.primaryColor,
                      }}
                    />
                    Add
                  </button> */}
                </div>
                <div className="create-trip-error">
                  {Object.keys(selectedBillToParty).length > 0
                    ? null
                    : errTrip.errBillToParty}
                </div>
              </div>
            </div>

            <div className="create-trip-from-row">
              {/* {sourceDetails.length == index + 1 ? ( */}
              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Select vehicle<sup style={{ color: "red" }}>*</sup>
                </h4>
                <AsyncSelect
                  className="create-trip-input"
                  style={{
                    width: isMobile ? "100%" : "",
                  }}
                  defaultOptions
                  key={loadSource}
                  placeholder="search vehicle number..."
                  value={
                    vehicleNumber !== "0"
                      ? { value: vehicleNumber, label: vehicleNumber }
                      : ""
                  }
                  onChange={(e) => {
                    setVehicleDetails({
                      manufacturer: e.vehicle_manufacturer_name,
                      model: e.vehicle_type,
                      payout: e.payout_period,
                      status: e.status,
                    });

                    setVehicleNumber(e.value);
                    setVehiclePayoutPeriod(e.payout_period);
                    if (e.payout_period == "monthly_wise") {
                      setPerKmRate(e.extra_per_km_rate);
                    } else {
                      let tempperKm =
                        (tripCost.length > 0 ? tripCost[1].cost_amount : 0) /
                        tripDistance;
                      setPerKmRate(tempperKm.toFixed(2));
                    }
                  }}
                  loadOptions={(inputValue) =>
                    vehicleOptions(inputValue, loadSource)
                  }
                />

                <div className="create-trip-error">
                  {vehicleNumber == "0" ? errTrip.errVehicleNumber : null}
                </div>
                <div className="create-trip-error">
                  {vehicleDetails.status == "blocked" ||
                    vehicleDetails.status == "in_transit" ||
                    vehicleDetails.status == "not_available"
                    ? "Vehicle is not available."
                    : null}
                </div>
              </div>

              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Select Driver<sup style={{ color: "red" }}>*</sup>
                </h4>
                <div className="create-trip-driver-input">
                  <AsyncSelect
                    className="create-trip-input-driver"
                    defaultOptions
                    key={driverId}
                    placeholder="Search Name or DL No."
                    value={
                      driverId
                        ? { value: driverId, label: driverDetails.first_name }
                        : ""
                    }
                    // value={driverDetails}
                    onChange={(e) => {
                      setSelectedDriverDetails({
                        first_name: e.first_name,
                        last_name: e.last_name,
                        dl: e.driving_licence_number,
                        mobile: e.mobile_number,
                      });
                      //
                      // setDriverDetails(e);
                      handleDriverSelect(e);
                    }}
                    loadOptions={driverOptions}
                  />
                  {driverDetails.first_name ? (
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={handleClearDriver}
                    />
                  ) : (
                    <div
                      // className="create-trip-addd"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        // justifyItems: "center",
                        border: "none",
                        width: isMobile ? "18%" : "12%",
                        height: "38px",
                        cursor: "pointer",
                        marginLeft: "0px",
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "center",
                        // alignItems: "center",
                        borderRadius: "0px",
                        background: "#D7F2C9",
                        color: colorPalette.primaryColor,
                        // flexDirection: "column",
                        padding: "2px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        borderTopRightRadius: "5px",
                        borderBottomRightRadius: "5px",
                      }}
                      onClick={handleAddDriver}
                    >
                      {" "}
                      <AddCircle
                        style={{
                          fontSize: "13px",
                          // height: "100%",
                          color: colorPalette.primaryColor,
                        }}
                      />
                      <span>Add</span>{" "}
                    </div>
                  )}
                </div>
                <div className="create-trip-error">
                  {errDriverFinal ? errDriverFinal : null}
                </div>
              </div>
            </div>
            <div className="create-trip-from-row">
              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Select Transporter<sup style={{ color: "red" }}>*</sup>
                </h4>
                <AsyncSelect
                  className="create-trip-input"
                  defaultOptions
                  key={transporterNameId}
                  placeholder="search transporter..."
                  value={
                    transporterNameId
                      ? {
                        value: transporterNameId,
                        label: selectedTransporterName,
                      }
                      : ""
                  }
                  onChange={(e) => {
                    setTransporterDetails({
                      transporter: e.name,
                      code: e.transporter_code,
                      mobile: e.mobile,
                      district: e.district,
                      state: e.state,
                    });
                    //
                    setTransporterNameId(e.value);
                    setSelectedTransporterName(e.name);
                  }}
                  loadOptions={(inputValue) =>
                    handleFetchTransporter(inputValue)
                  }
                />

                <div className="create-trip-error">
                  {!transporterNameId ? errTrip.errTransporter : null}
                </div>
              </div>

              {selectedCustomer.name == "faarms" ||
                selectedCustomer.name == "Faarms" ? null : (
                <div
                  className="create-trip-input-container"
                  style={{
                    width: isMobile ? "100%" : "",
                    padding: isMobile ? "0 10px" : "",
                  }}
                >
                  <h4 className="create-trip-input-head">
                    {" "}
                    LR Number <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    style={{ backgroundColor: "white" }}
                    className="create-trip-input"
                    variant="outlined"
                    // color="white"
                    disabled={editMode ? true : false}
                    // style={{ width: "91%" }}
                    placeholder="Enter LR Number"
                    value={referenceNumberManual}
                    onChange={(e) => setReferenceNumberManual(e.target.value)}
                  />
                  <div className="create-trip-error">
                    {!referenceNumberManual ? errTrip.errLrNumber : null}
                  </div>
                </div>
              )}
            </div>
            <div className="create-trip-from-row-grid">
              {/* {sourceDetails.map((item, index) => {
                return (
                  <> */}
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  Trip source <sup style={{ color: "red" }}>*</sup>
                </h4>

                <div className="address_container">
                  <div
                    className="add_address_button"
                    onClick={() => {
                      handleSource();
                      setErrorInaddressSource("");
                    }}
                  >
                    <AddIcon style={{ color: "#0d6f3b" }} />
                    <span>Add Source</span>
                  </div>

                  <div className="address_list_container">
                    {sourceAddressList.length > 0
                      ? sourceAddressList.map((data, index) => (
                        <div
                          className="single_address_box"
                        // key={index}
                        // value={data}
                        // onClick={() => handleClick(data, index)}
                        >
                          <span
                            className="delete_address"
                            onClick={() => {
                              handleRemoveAddress(data, index);
                            }}
                          >
                            <CancelOutlinedIcon
                              style={{ fontSize: "14px" }}
                            />
                          </span>
                          <div className="address_line">{data.village},</div>{" "}
                          <div className="address_line">
                            {data.sub_district},
                          </div>
                          <div className="address_line">{data.district},</div>
                          <div className="address_line">{data.state},</div>
                          <div className="address_line">{data.postcode}.</div>
                        </div>
                      ))
                      : null}
                  </div>
                </div>
                <div className="create-trip-error">
                  {errSourceFinal ? errSourceFinal : null}
                </div>
                <div className="create-trip-error">
                  {errorInaddressSource ? errorInaddressSource : null}
                </div>
              </div>

              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  Trip Destination <sup style={{ color: "red" }}>*</sup>
                </h4>

                <div className="address_container">
                  <div
                    className="add_address_button"
                    onClick={() => {
                      handleDestination();
                      setErrorInaddressDestination("");
                    }}
                  >
                    <AddIcon style={{ color: "#0d6f3b" }} />
                    <span>Add Destination</span>
                  </div>

                  <div className="address_list_container">
                    {destinationAddressList.length > 0
                      ? destinationAddressList.map((data, index) => (
                        <div className="single_address_box">
                          <span
                            className="delete_address"
                            onClick={() => {
                              handleRemoveAddressDestination(data, index);
                            }}
                          >
                            <CancelOutlinedIcon
                              style={{ fontSize: "14px" }}
                            />
                          </span>
                          <div className="address_line">{data.village},</div>{" "}
                          <div className="address_line">
                            {data.sub_district},
                          </div>
                          <div className="address_line">{data.district},</div>
                          <div className="address_line">{data.state},</div>
                          <div className="address_line">{data.postcode}.</div>
                        </div>
                      ))
                      : null}
                  </div>
                </div>
                <div className="create-trip-error">
                  {errSourceFinal ? errSourceFinal : null}
                </div>
                <div className="create-trip-error">
                  {errorInaddressDestination ? errorInaddressDestination : null}
                </div>
              </div>
            </div>
          </div>

          <div
            className={`trip_second_container`}
            style={{
              opacity:
                selectedCustomer.name &&
                  selectedConsignee.name &&
                  selectedBillToParty.name &&
                  destinationAddressList.length > 0 &&
                  sourceAddressList.length > 0 &&
                  referenceNumberManual &&
                  transporterNameId &&
                  driverId &&
                  vehicleNumber
                  ? "1"
                  : "0",
              transition: "all 1s",
              // animation: "fadeIn 500ms ease-out backwards",
              visibility:
                selectedCustomer.name &&
                  selectedConsignee.name &&
                  selectedBillToParty.name &&
                  destinationAddressList.length > 0 &&
                  sourceAddressList.length > 0 &&
                  referenceNumberManual &&
                  transporterNameId &&
                  driverId &&
                  vehicleNumber
                  ? "visible"
                  : "hidden",
            }}
          >
            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  {" "}
                  Trip start meter reading (with attachment){" "}
                  <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  id="dropdown"
                  autoComplete="off"
                  className="create-trip-input dropdown-container"
                  variant="outlined"
                  placeholder="Enter meter reading"
                  name="attachment_tag"
                  // style={{ backgroundColor: "red" }}
                  value={tripStartMeterReading}
                  onChange={(e) => setTripStartMeterReading(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          // backgroundColor: "red",
                          color: colorPalette.primaryColor,
                          fontWeight: "600",
                        }}
                      // onClick={handleAddAttachment}
                      >
                        <button
                          className="upload_button"
                          style={{ backgroundColor: "#D7F2C9" }}
                        >
                          <input
                            // name="upload"
                            name="attachment_file"
                            id={`upload`}
                            type="file"
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={(e) => {
                              handleChangeTripMeterReadingAttachment(e);
                            }}
                            hidden
                            style={{
                              backgroundColor: colorPalette.secondaryWhite,
                            }}
                          />
                          <label
                            htmlFor={`upload`}
                            style={{
                              backgroundColor: "#D7F2C9",
                              // height: "100%",
                            }}
                          >
                            <img
                              src={UploadImage}
                              alt=""
                              // width="20px"
                              // height="100%"
                              style={{
                                backgroundColor: "#D7F2C9",
                                // width: "100%",
                                // height: "100%",
                              }}
                            />
                          </label>
                        </button>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="create-trip-attachment-name">
                  {tripStartMeterReadingAttachment.attachment_file != null
                    ? tripStartMeterReadingAttachment.attachment_file.name
                    : null}
                </div>
                <div className="create-trip-error">
                  {tripStartMeterReadingAttachment.attachment_file
                    ? tripStartMeterReadingAttachment.attachment_file.size >
                      process.env.REACT_APP_MAX_FILE_SIZE
                      ? `File size is too large.( <  ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                      : null
                    : null}
                </div>

                <div className="create-trip-error">
                  {tripStartMeterReading
                    ? null
                    : errTrip.errTripStartMeterReading}
                </div>
                <div className="create-trip-error">
                  {tripStartMeterReadingAttachment.attachment_file
                    ? null
                    : errTrip.errTripStartMeterReadingAttachment}
                </div>
              </div>

              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Value of goods <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  variant="outlined"
                  name="cost_amount"
                  id="trip_cost"
                  placeholder="Enter value of goods"
                  className="create-trip-input"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  style={{ backgroundColor: "white" }}
                  value={valueOfGoods}
                  onChange={(e) => setValueOfGoods(e.target.value)}
                />
                <div className="create-trip-error">
                  {valueOfGoods > 0 ? null : errTrip.errValueOfGoods}
                </div>
              </div>
            </div>

            <div className="create-trip-from-row">
              <div className="create-trip-input-container">
                <h4 className="create-trip-input-head">
                  Customer Invoice No.
                  <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  id="dropdown"
                  autoComplete="off"
                  className="create-trip-input dropdown-container"
                  variant="outlined"
                  placeholder="Enter Customer Invoice no"
                  name="attachment_tag"
                  value={customerInvoiceNo}
                  onChange={(e) => setCustomerInvoiceNo(e.target.value)}
                  style={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          color: colorPalette.primaryColor,
                          fontWeight: "600",
                        }}
                      >
                        <button
                          className="upload_button"
                          style={{ backgroundColor: "#D7F2C9" }}
                        >
                          <input
                            name="attachment_file"
                            id={`upload_customer_invoice`}
                            type="file"
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={(e) => {
                              handleChangeCustomerInvoiceAttachment(e);
                            }}
                            hidden
                            style={{
                              backgroundColor: colorPalette.secondaryWhite,
                            }}
                          />
                          <label
                            htmlFor={`upload_customer_invoice`}
                            style={{
                              backgroundColor: "#D7F2C9",
                              // height: "100%",
                            }}
                          >
                            <img
                              src={UploadImage}
                              alt=""
                              style={{
                                backgroundColor: "#D7F2C9",
                              }}
                            />
                          </label>
                        </button>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="create-trip-attachment-name">
                  {customerInvoiceAttachment != null
                    ? customerInvoiceAttachment.name
                    : null}
                </div>
                <div className="create-trip-error">
                  {customerInvoiceAttachment
                    ? customerInvoiceAttachment.size >
                      process.env.REACT_APP_MAX_FILE_SIZE
                      ? `File size is too large.( < ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                      : null
                    : null}
                </div>

                <div className="create-trip-error">
                  {customerInvoiceNo ? null : errTrip.customer_invoice}
                </div>
                {/* <div className="create-trip-error">
                  {customerInvoiceAttachment
                    ? null
                    : errTrip.errTripStartMeterReadingAttachment}
                </div> */}
              </div>

              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h4 className="create-trip-input-head">
                    Expected Delivery Date
                  </h4>
                  <sup style={{ color: "red" }}>*</sup>
                </div>

                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  disabled={false}
                  placeholder="Enter expected delivery date"
                  type="date"
                  inputProps={{
                    min: minDate(),
                  }}
                  value={expectedDeliveryDate}
                  onChange={(e) => setExpectedDeliveryDate(e.target.value)}
                />
                {/* <div className="create-trip-error">
                  {expectedDeliveryDate && expectedDeliveryDate <= ewayBillTo
                    ? null
                    : errTrip.errEwayFrom}
                </div> */}
              </div>
            </div>

            <div className="create-trip-from-row">
              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Estimated distance (in kms){" "}
                  <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Enter Estimated Distance"
                  style={{ backgroundColor: "white" }}
                  type="number"
                  // onwheel="return false;"
                  onWheel={(e) => e.target.blur()}
                  value={tripDistance}
                  onChange={(e) => setTripDistance(e.target.value)}
                />
                <div className="create-trip-error">
                  {tripDistance > 0 ? null : errTrip.errTripDistance}
                </div>
              </div>

              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  No. of Pieces <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  style={{ backgroundColor: "white" }}
                  variant="outlined"
                  placeholder="Enter Number of pieces"
                  disabled={loadSource !== "trip_invoice" ? false : true}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={totalQty}
                  onChange={(e) => setTotalQty(e.target.value)}
                />
                <div className="create-trip-error">
                  {totalQty > 0 ? null : errTrip.no_of_pieces}
                </div>
              </div>
            </div>

            <div className="create-trip-from-row">
              {/* <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Load unit <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  select
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  style={{ backgroundColor: "white" }}
                  placeholder="load unit"
                  value={loadUnit}
                  onChange={(e) => setLoadUnit(e.target.value)}
                >
                  <MenuItem value="0" disabled>
                    Select load unit
                  </MenuItem>
                  {loadUnitList.map((item) => {
                    return (
                      <MenuItem value={item.unit_code}>{item.name}</MenuItem>
                    );
                  })}
                </TextField>
                <div className="create-trip-error">
                  {loadUnit == "0" ? errTrip.errLoadUnit : null}
                </div>
              </div> */}

              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Quantity in tonnes <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Enter Quantity in tonnes"
                  style={{ backgroundColor: "white" }}
                  type="number"
                  // onwheel="return false;"
                  onWheel={(e) => e.target.blur()}
                  value={qunatityInTonnes}
                  onChange={(e) => setQuantityInTonnes(e.target.value)}
                />
                <div className="create-trip-error">
                  {qunatityInTonnes ? null : errTrip.quantity_in_tonnes}
                </div>
              </div>

              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <h4 className="create-trip-input-head">
                  {" "}
                  Per KM Rate <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="create-trip-input"
                  variant="outlined"
                  placeholder="Enter Per KM Rate"
                  type="number"
                  style={{ backgroundColor: "white" }}
                  onWheel={(e) => e.target.blur()}
                  disabled={true}
                  value={perKmRate}
                // onChange={(e) => setPerKmRate(e.target.value)}
                />
                <div className="create-trip-error">
                  {perKmRate > 0 ? null : errTrip.errPerKmRate}
                </div>
              </div>
            </div>

            {/* qunatity in tonnes  */}

            <div className="create-trip-from-row"></div>

            {/* </div> */}
          </div>
          {/* ) : null} */}
          {/* {valueOfGoods &&
          tripStartMeterReading &&
          tripStartMeterReadingAttachment.attachment_file &&
          tripDistance &&
          totalQty > 0 &&
          valueOfGoods ? ( */}
          <div
            className="trip_second_container"
            style={{
              opacity:
                (selectedCustomer.name &&
                  selectedConsignee.name &&
                  selectedBillToParty.name &&
                  destinationAddressList.length > 0 &&
                  sourceAddressList.length > 0 &&
                  referenceNumberManual &&
                  transporterNameId &&
                  driverId &&
                  vehicleNumber &&
                  valueOfGoods &&
                  tripStartMeterReading &&
                  (tripStartMeterReadingAttachment.attachment_file ||
                    editMode) &&
                  tripDistance &&
                  totalQty > 0 &&
                  (customerInvoiceAttachment?.name || editMode) &&
                  customerInvoiceNo &&
                  expectedDeliveryDate &&
                  valueOfGoods &&
                  qunatityInTonnes) ||
                  editMode
                  ? "1"
                  : "0",
              transition: "all 1s",
              visibility:
                (selectedCustomer.name &&
                  selectedConsignee.name &&
                  selectedBillToParty.name &&
                  destinationAddressList.length > 0 &&
                  sourceAddressList.length > 0 &&
                  referenceNumberManual &&
                  transporterNameId &&
                  driverId &&
                  vehicleNumber &&
                  valueOfGoods &&
                  tripStartMeterReading &&
                  (tripStartMeterReadingAttachment.attachment_file ||
                    editMode) &&
                  tripDistance &&
                  totalQty > 0 &&
                  (customerInvoiceAttachment?.name || editMode) &&
                  customerInvoiceNo &&
                  expectedDeliveryDate &&
                  valueOfGoods &&
                  qunatityInTonnes) ||
                  editMode
                  ? "visible"
                  : "hidden",
            }}
          >
            <div className="create-trip-from-row">
              {vehiclePayoutPeriod == "monthly_wise" &&
                loadSource == "trip_invoice" ? null : (
                <div
                  className="create-trip-input-container"
                  style={{
                    width: isMobile ? "100%" : "",
                    padding: isMobile ? "0 10px" : "",
                  }}
                >
                  <div
                    className="create-trip-drivers-box"
                  // style={{ backgroundColor: "blue", width: "60%" }}
                  >
                    {vehiclePayoutPeriod == "monthly_wise" ? null : (
                      <div
                        className="create-trip-input-container"
                        style={{
                          width: isMobile ? "100%" : "",
                          padding: isMobile ? "0 10px" : "",
                        }}
                      >
                        <h4 className="create-trip-input-head">
                          {" "}
                          Payment Terms
                          {/* {tripSettingsList.filter((data) => data.feature.name == "payment_terms")[0].default_behaviour=="optional"?():} */}
                          <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          onChange={(e) => setPaymentTerms(e.target.value)}
                          value={
                            // if (
                            //   removedStringForTripType(trip_type) === "first_mile" &&
                            //   selectedCustomer?.id === selectedBillToParty?.id
                            // ) {
                            //   setPaymentTerms("to_be_billed");
                            // } else if (
                            //   removedStringForTripType(trip_type) === "first_mile" &&
                            //   selectedConsignee?.id === selectedBillToParty?.id
                            // ) {
                            //   setPaymentTerms("to_payee");
                            // } else {
                            //   setPaymentTerms("");
                            // }
                            tripType === "last_mile" ||
                              tripType === "middle_mile"
                              ? "to_be_billed"
                              : paymentTerms
                          }
                        >
                          <div style={{ display: "flex" }}>
                            <FormControlLabel
                              value="to_payee"
                              control={<Radio />}
                              label="To Pay"
                              onChange={(e) => {
                                setToPay(e.target.value);
                              }}
                              disabled={
                                // if (
                                //   removedStringForTripType(trip_type) === "first_mile" &&
                                //   selectedCustomer?.id === selectedBillToParty?.id
                                // ) {
                                //   setPaymentTerms("to_be_billed");
                                // } else if (
                                //   removedStringForTripType(trip_type) === "first_mile" &&
                                //   selectedConsignee?.id === selectedBillToParty?.id
                                // ) {
                                //   setPaymentTerms("to_payee");
                                // } else {
                                //   setPaymentTerms("");
                                // }
                                removedStringForTripType(trip_type) ===
                                  "first_mile" &&
                                  selectedConsignee?.id !==
                                  selectedBillToParty?.id
                                  ? true
                                  : removedStringForTripType(trip_type) ===
                                    "first_mile" &&
                                    selectedCustomer?.id ===
                                    selectedBillToParty?.id
                                    ? true
                                    : removedStringForTripType(trip_type) ===
                                      "first_mile" &&
                                      selectedConsignee?.id ===
                                      selectedBillToParty?.id
                                      ? true
                                      : tripType === "last_mile" ||
                                        tripType === "middle_mile"
                                        ? true
                                        : false
                              }
                            />

                            <FormControlLabel
                              value="to_be_billed"
                              control={<Radio />}
                              label="To be billed"
                              onChange={(e) => {
                                setToBilled(e.target.value);
                                setToPay("");
                              }}
                              disabled={
                                removedStringForTripType(trip_type) ===
                                  "first_mile" &&
                                  selectedConsignee?.id !==
                                  selectedBillToParty?.id
                                  ? true
                                  : removedStringForTripType(trip_type) ===
                                    "first_mile" &&
                                    selectedConsignee?.id ===
                                    selectedBillToParty?.id
                                    ? true
                                    : removedStringForTripType(trip_type) ===
                                      "first_mile" &&
                                      selectedCustomer?.id ===
                                      selectedBillToParty?.id
                                      ? true
                                      : tripType === "last_mile" ||
                                        tripType === "middle_mile"
                                        ? true
                                        : false
                              }
                            />
                          </div>
                        </RadioGroup>
                        <div className="create-trip-error">
                          {paymentTerms
                            ? paymentTerms.length > 2 ||
                              vehiclePayoutPeriod == "monthly_wise"
                              ? null
                              : errTrip.errPaymentTerms
                            : null}
                        </div>
                      </div>
                    )}

                    <div
                      className="create-trip-input-container"
                    // style={{
                    //   width: isMobile ? "100%" : "",
                    //   padding: isMobile ? "0 10px" : "",
                    // }}
                    >
                      {/* <h4 className="create-trip-input-head"> Cost details:</h4> */}
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          flexDirection: isMobile ? "column" : "row",
                          // flexDirection: "column",
                          justifyContent: "space-between",
                          // backgroundColor: "red",
                        }}
                      >
                        {vehiclePayoutPeriod == "monthly_wise" ? null : (
                          <>
                            <div
                              className="create-trip-cost-input-contain"
                              style={{ marginTop: ".5rem" }}
                            >
                              <div>
                                Total transport cost(₹){" "}
                                <sup
                                  style={{
                                    color: "red",
                                    position: "relative",
                                  }}
                                >
                                  *
                                </sup>{" "}
                              </div>
                              <TextField
                                size="small"
                                variant="outlined"
                                name="cost_amount"
                                id="trip_cost"
                                placeholder="Enter total amount"
                                className="create-trip-cost-input"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                value={
                                  tripCost.length > 0
                                    ? tripCost[1].cost_amount
                                    : null
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      ₹{" "}
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => handleTripCostChange(e, 1)}
                              />
                              <div className="create-trip-error">
                                {tripCost.length > 0
                                  ? tripCost[1].cost_amount > 10 ||
                                    vehiclePayoutPeriod == "monthly_wise"
                                    ? null
                                    : errTrip.errTotalCost
                                  : null}
                              </div>
                            </div>
                            <div
                              className="create-trip-cost-input-contain"
                              style={{
                                display: "flex",

                                margin: ".5rem 0rem",
                                // justifyContent: "center",
                              }}
                            >
                              <div>
                                Transport Advance(₹){" "}
                                <sup
                                  style={{
                                    color: "white",
                                    position: "relative",
                                  }}
                                >
                                  *
                                </sup>{" "}
                              </div>
                              <TextField
                                size="small"
                                variant="outlined"
                                name="cost_amount"
                                id="trip_cost"
                                placeholder="Enter advance amount"
                                value={
                                  tripCost.length > 0
                                    ? tripCost[0].cost_amount
                                    : null
                                }
                                onChange={(e) => handleTripCostChange(e, 0)}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                className="create-trip-cost-input"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {" "}
                                      ₹{" "}
                                    </InputAdornment>
                                  ),
                                }}
                              />

                              <div className="create-trip-error">
                                {tripCost.length > 0
                                  ? tripCost[0].cost_amount === ""
                                    ? true
                                    : parseInt(tripCost[0].cost_amount) < 0
                                      ? errTrip.errAdvanceCost
                                      : parseInt(tripCost[0].cost_amount) == 0
                                        ? errTrip.errAdvanceCost
                                        : parseInt(tripCost[0].cost_amount) <=
                                          parseInt(tripCost[1].cost_amount)
                                          ? true
                                          : errTrip.errAdvanceCost
                                  : null}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          // width: "100%",
                          alignItems: "center",
                          flexDirection: isMobile ? "column" : "row",
                          // flexDirection: "column",
                          justifyContent: "space-between",
                          // backgroundColor: "red",
                        }}
                      >
                        {loadSource == "manual" ||
                          loadSource == "trip_purchase_order" ? (
                          <>
                            {" "}
                            <div
                              className="create-trip-cost-input-contain"
                            // style={{
                            //   display: "flex",

                            //   margin: ".5rem 0rem",
                            // }}
                            >
                              <div style={{ width: "max-content" }}>
                                Advance Paid By Customer(₹){" "}
                                <sup
                                  style={{
                                    color: "red",
                                    position: "relative",
                                  }}
                                >
                                  *
                                </sup>
                              </div>
                              <TextField
                                size="small"
                                autoComplete="off"
                                className="create-trip-cost-input"
                                variant="outlined"
                                // style={{ width: "100%" }}
                                placeholder="Enter Amount"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                value={advanceByCustomer}
                                onChange={(e) => {
                                  setAdvanceByCustomer(e.target.value);
                                  setErrTrip({
                                    ...errTrip,
                                    errCustomerAdvanceAmount: "",
                                  });
                                }}
                              />
                              {/* <div className="create-trip-error">
                                {" "}
                                {advanceByCustomer > 0 &&
                                optionalFields.includes(
                                  "advance_paid_by_customer"
                                )
                                  ? null
                                  : errTrip.errCustomerAdvanceAmount}
                              </div> */}
                            </div>
                            <div
                              className="create-trip-cost-input-contain"
                            // style={{
                            //   display: "flex",

                            //   margin: ".5rem 0rem",

                            // }}
                            >
                              <div style={{ width: "max-content" }}>
                                Customer Billed Amount(₹){" "}
                                <sup
                                  style={{
                                    color: "red",
                                    position: "relative",
                                  }}
                                >
                                  *
                                </sup>
                              </div>
                              <TextField
                                size="small"
                                autoComplete="off"
                                className="create-trip-cost-input"
                                variant="outlined"
                                placeholder="Enter Amount"
                                type="number"
                                // style={{ width: "100%" }}
                                onWheel={(e) => e.target.blur()}
                                value={billedByCustomer}
                                onChange={(e) => {
                                  setBilledByCustomer(e.target.value);
                                  setErrTrip({
                                    ...errTrip,
                                    errCustomerBilledAmount: "",
                                  });
                                }}
                              />
                              {/* <div className="create-trip-error">
                                {" "}
                                {billedByCustomer > 0
                                  ? null
                                  : errTrip.errCustomerBilledAmount}
                              </div> */}
                            </div>
                          </>
                        ) : null}

                        {/* <div
                          className="create-trip-cost-input-contain"
                          style={{
                            display: "flex",

                            margin: "2rem 0rem",
                          }}
                        >
                          {pbcCostPurchase > 0 ? (
                            <div>
                              <span style={{ fontWeight: "500" }}>
                                {" "}
                                Per bag cost :
                              </span>{" "}
                              <sup
                                style={{ color: "white", position: "relative" }}
                              >
                                *
                              </sup>{" "}
                              ₹{" "}
                              {`${
                                pbcCostPurchase > 0 ? pbcCostPurchase : "0.00"
                              }`}
                            </div>
                          ) : null}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* ) : null} */}
          {/* {vehiclePayoutPeriod == "monthly_wise" ?  */}

          {/* <div
            className="trip_second_container"
            style={{
              opacity:
                selectedCustomer.name &&
                selectedConsignee.name &&
                selectedBillToParty.name &&
                destinationAddressList.length > 0 &&
                sourceAddressList.length > 0 &&
                referenceNumberManual &&
                transporterNameId &&
                driverId &&
                vehicleNumber &&
                valueOfGoods &&
                tripStartMeterReading &&
                tripStartMeterReadingAttachment.attachment_file &&
                tripDistance &&
                totalQty > 0 &&
                billedByCustomer &&
                advanceByCustomer &&
                valueOfGoods
                  ? "1"
                  : "0",
              transition: "all 1s",
              // animation: "fadeIn 500ms ease-out backwards",
              visibility:
                selectedCustomer.name &&
                selectedConsignee.name &&
                selectedBillToParty.name &&
                destinationAddressList.length > 0 &&
                sourceAddressList.length > 0 &&
                referenceNumberManual &&
                transporterNameId &&
                driverId &&
                vehicleNumber &&
                valueOfGoods &&
                tripStartMeterReading &&
                tripStartMeterReadingAttachment.attachment_file &&
                tripDistance &&
                totalQty > 0 &&
                vehiclePayoutPeriod == "monthly_wise" &&
                billedByCustomer &&
                advanceByCustomer &&
                valueOfGoods
                  ? "visible"
                  : "hidden",
            }}
          >
            {Object.keys(selectedCustomer).length > 0 ? (
              selectedCustomer?.name.toLowerCase().includes("faarms") ? null : (
                <div className="create-trip-eway-box">
                  <div className="create-trip-from-row">
                    <div
                      className="create-trip-input-container"
                      style={{
                        width: isMobile ? "100%" : "",
                        padding: isMobile ? "0 10px" : "",
                      }}
                    >
                      <h4 className="create-trip-input-head">
                        {" "}
                        E-Way Bill Number
                      </h4>
                      <TextField
                        size="small"
                        autoComplete="off"
                        disabled={checked ? true : false}
                        className="create-trip-input"
                        variant="outlined"
                        placeholder="Enter e-way bill no."
                        value={ewayBillNumber}
                        onChange={(e) => setEwayBillNumber(e.target.value)}
                      />
                      <div className="create-trip-error">
                        {ewayBillNumber ? null : errEwayBill.errEwayNumber}
                      </div>
                    </div>

                    <div
                      className="create-trip-input-container"
                      style={{
                        width: isMobile ? "100%" : "",
                        padding: isMobile ? "0 10px" : "",
                      }}
                    >
                      <h4 className="create-trip-input-head">
                        {" "}
                        E-Way Bill Valid From
                      </h4>
                      <TextField
                        size="small"
                        autoComplete="off"
                        className="create-trip-input"
                        variant="outlined"
                        disabled={checked ? true : false}
                        placeholder="Enter total quantity"
                        // disabled={loadSource !== "trip_invoice" ? false : true}
                        type="date"
                        value={ewayBillFrom}
                        onChange={(e) => setEwayBillFrom(e.target.value)}
                      />
                      <div className="create-trip-error">
                        {ewayBillFrom && ewayBillFrom <= ewayBillTo
                          ? null
                          : errEwayBill.errEwayFrom}
                      </div>
                    </div>
                  </div>
                  <div className="create-trip-from-row">
                    <div
                      className="create-trip-input-container"
                      style={{
                        width: isMobile ? "100%" : "",
                        padding: isMobile ? "0 10px" : "",
                      }}
                    >
                      <h4 className="create-trip-input-head">
                        {" "}
                        E-Way Bill Valid Till
                      </h4>
                      <TextField
                        size="small"
                        autoComplete="off"
                        disabled={checked ? true : false}
                        className="create-trip-input"
                        variant="outlined"
                        placeholder="Enter total quantity"
                        type="date"
                        value={ewayBillTo}
                        onChange={(e) => setEwayBillTo(e.target.value)}
                      />
                      <div className="create-trip-error">
                        {ewayBillTo && ewayBillFrom <= ewayBillTo
                          ? null
                          : errEwayBill.errEwayTo}
                      </div>
                    </div>
                    <div
                      className="trip-list-modal-input-group"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <h4 className="create-trip-input-head">
                        E-Way Bill Attachment{" "}
                      </h4>

                      <TextField
                        size="small"
                        id="dropdown"
                        select
                        autoComplete="off"
                        style={isMobile ? { width: "85%" } : { width: "100%" }}
                        className="create-trip-input-attachment dropdown-container"
                        variant="outlined"
                        placeholder="Enter document type"
                        name="attachment_tag"
                        disabled={true}
                        value={"e_way_bill"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button
                                className="upload_button"
                                style={{ backgroundColor: "#D7F2C9" }}
                              >
                                <input
                                  name="attachment_file"
                                  id={`upload-eway`}
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={(e) => {
                                    setEwayBillAttachment(e.target.files[0]);
                                  }}
                                  hidden
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label
                                  htmlFor={`upload-eway`}
                                 
                                >
                                  <img
                                    src={UploadImage}
                                    alt=""
                                  
                                    style={{
                                      backgroundColor: "#D7F2C9",
                                    }}
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      >
                        <MenuItem value="0" disabled>
                          Select document type
                        </MenuItem>

                        {documentTypeList.map((item) => {
                          return (
                            <MenuItem value={item}>
                              {item
                                .split("_")
                                .join(" ")
                                .split("")
                                .map((item, index) => {
                                  if (index == 0) {
                                    return item.toUpperCase();
                                  } else {
                                    return item;
                                  }
                                })
                                .join("")}
                            </MenuItem>
                          );
                        })}
                      </TextField>

                      <div className="create-trip-attachment-name">
                        {ewayBillAttachment != null
                          ? ewayBillAttachment.name
                          : null}
                      </div>
                      <div className="error-trip">
                        {ewayBillAttachment != null
                          ? ewayBillAttachment.size >
                            process.env.REACT_APP_MAX_FILE_SIZE
                            ? `File size is too large.( < ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                            : null
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="create-trip-from-row-ewaybill">
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    E-way bill details not required.
                  </div>
                 
                </div>
              )
            ) : null}
          </div> */}
          {/* // ) : null */}
          {/* ) : billedByCustomer && advanceByCustomer && tripCost.length > 0 ? ( */}
          {editMode ? (
            <div className="trip_second_container">
              {Object.keys(selectedCustomer).length > 0 ? (
                selectedCustomer?.name
                  .toLowerCase()
                  .includes("faarms") ? null : (
                  <div className="create-trip-eway-box">
                    <div className="create-trip-from-row">
                      <div
                        className="create-trip-input-container"
                        style={{
                          width: isMobile ? "100%" : "",
                          padding: isMobile ? "0 10px" : "",
                          // backgroundColor: "red",
                        }}
                      >
                        <h4 className="create-trip-input-head">
                          {" "}
                          E-Way Bill Number
                          {mandatoryFields.length > 0 ? (
                            <sup
                              style={{
                                color: "red",
                                position: "relative",
                              }}
                            >
                              *
                            </sup>
                          ) : null}
                        </h4>
                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          disabled={checked ? true : false}
                          placeholder="Enter e-way bill no."
                          value={ewayBillNumber}
                          onChange={(e) => setEwayBillNumber(e.target.value)}
                        />
                        <div className="create-trip-error">
                          {ewayBillNumber || editMode
                            ? null
                            : errTrip.errEwayNumber}
                        </div>
                      </div>

                      <div
                        className="create-trip-input-container"
                        style={{
                          width: isMobile ? "100%" : "",
                          padding: isMobile ? "0 10px" : "",
                          // backgroundColor: "red",
                        }}
                      >
                        <h4 className="create-trip-input-head">
                          {" "}
                          E-Way Bill Valid From
                          {mandatoryFields.length > 0 ? (
                            <sup
                              style={{
                                color: "red",
                                position: "relative",
                              }}
                            >
                              *
                            </sup>
                          ) : null}
                        </h4>

                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          disabled={checked ? true : false}
                          placeholder="Enter total quantity"
                          // disabled={loadSource !== "trip_invoice" ? false : true}
                          type="date"
                          value={ewayBillFrom}
                          onChange={(e) => setEwayBillFrom(e.target.value)}
                        />
                        <div className="create-trip-error">
                          {(ewayBillFrom && ewayBillFrom <= ewayBillTo) ||
                            editMode
                            ? null
                            : errTrip.errEwayFrom}
                        </div>
                      </div>
                    </div>
                    <div className="create-trip-from-row">
                      <div
                        className="create-trip-input-container"
                        style={{
                          width: isMobile ? "100%" : "",
                          padding: isMobile ? "0 10px" : "",
                          // backgroundColor: "red",
                        }}
                      >
                        <h4 className="create-trip-input-head">
                          {" "}
                          E-Way Bill Valid Till
                          {mandatoryFields.length > 0 ? (
                            <sup
                              style={{
                                color: "red",
                                position: "relative",
                              }}
                            >
                              *
                            </sup>
                          ) : null}
                        </h4>

                        <TextField
                          size="small"
                          autoComplete="off"
                          className="create-trip-input"
                          variant="outlined"
                          placeholder="Enter total quantity"
                          type="date"
                          disabled={checked ? true : false}
                          value={ewayBillTo}
                          onChange={(e) => setEwayBillTo(e.target.value)}
                        />
                        <div className="create-trip-error">
                          {(ewayBillTo && ewayBillFrom <= ewayBillTo) ||
                            editMode
                            ? null
                            : errTrip.errEwayTo}
                        </div>
                      </div>
                      <div
                        className="trip-list-modal-input-group"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <h4 className="create-trip-input-head">
                          E-Way Bill Attachment{" "}
                          {mandatoryFields.length > 0 ? (
                            <sup
                              style={{
                                color: "red",
                                position: "relative",
                              }}
                            >
                              *
                            </sup>
                          ) : null}
                        </h4>

                        <TextField
                          size="small"
                          id="dropdown"
                          select
                          autoComplete="off"
                          style={
                            isMobile ? { width: "85%" } : { width: "100%" }
                          }
                          className="create-trip-input-attachment dropdown-container"
                          variant="outlined"
                          placeholder="Enter document type"
                          name="attachment_tag"
                          disabled={true}
                          value={"e_way_bill"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{
                                  cursor: "pointer",
                                  color: colorPalette.primaryColor,
                                  fontWeight: "600",
                                }}
                              >
                                <button
                                  className="upload_button"
                                  style={{ backgroundColor: "#D7F2C9" }}
                                >
                                  <input
                                    name="attachment_file"
                                    id={`upload-eway`}
                                    type="file"
                                    accept="image/png, image/jpeg, application/pdf"
                                    onChange={(e) => {
                                      setEwayBillAttachment(e.target.files[0]);
                                    }}
                                    hidden
                                    style={{
                                      backgroundColor:
                                        colorPalette.secondaryWhite,
                                    }}
                                  />
                                  <label
                                    htmlFor={`upload-eway`}
                                  // style={{
                                  //   backgroundColor:
                                  //     colorPalette.secondaryWhite,
                                  // }}
                                  >
                                    <img
                                      src={UploadImage}
                                      alt=""
                                      // width="20px"
                                      // height="20px"
                                      style={{
                                        backgroundColor: "#D7F2C9",
                                      }}
                                    />
                                  </label>
                                </button>
                              </InputAdornment>
                            ),
                          }}
                        >
                          <MenuItem value="0" disabled>
                            Select document type
                          </MenuItem>

                          {documentTypeList.map((item) => {
                            return (
                              <MenuItem value={item}>
                                {item
                                  .split("_")
                                  .join(" ")
                                  .split("")
                                  .map((item, index) => {
                                    if (index == 0) {
                                      return item.toUpperCase();
                                    } else {
                                      return item;
                                    }
                                  })
                                  .join("")}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                        {/* <div className="create-trip-error">
                          {!ewayBillAttachment.name ? null : errTrip.errEwayTo}
                        </div> */}
                        <div className="create-trip-attachment-name">
                          {ewayBillAttachment != null
                            ? ewayBillAttachment.name
                            : null}
                        </div>
                        <div className="error-trip">
                          {ewayBillAttachment
                            ? ewayBillAttachment.size >
                              process.env.REACT_APP_MAX_FILE_SIZE
                              ? `File size is too large.( < ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                              : null
                            : null}
                        </div>
                      </div>
                    </div>
                    {editMode ? null : (
                      <div className="create-trip-from-row-ewaybill">
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        E-way bill details not required.
                      </div>
                    )}

                    {/* <div
                    className="trip-list-modal-row"
                    style={{ margin: "5px 10px" }}
                  >
                 
                  </div> */}
                  </div>
                )
              ) : null}
            </div>
          ) : null}

          <div
            className="trip_second_container"
            style={{
              opacity:
                selectedCustomer.name &&
                  selectedConsignee.name &&
                  selectedBillToParty.name &&
                  destinationAddressList.length > 0 &&
                  sourceAddressList.length > 0 &&
                  referenceNumberManual &&
                  transporterNameId &&
                  driverId &&
                  vehicleNumber &&
                  valueOfGoods &&
                  tripStartMeterReading &&
                  tripStartMeterReadingAttachment.attachment_file &&
                  customerInvoiceNo &&
                  customerInvoiceAttachment?.name &&
                  tripDistance &&
                  totalQty > 0 &&
                  (vehiclePayoutPeriod == "monthly_wise"
                    ? advanceByCustomer && billedByCustomer
                    : advanceByCustomer &&
                    billedByCustomer &&
                    (tripCost.filter((data) => data.cost_type == "trip_cost")[0]
                      ?.cost_amount !== ""
                      ? true
                      : false)) &&
                  valueOfGoods
                  ? "1"
                  : "0",
              display: editMode ? "none" : "",
              transition: "all 1s",
              // animation: "fadeIn 500ms ease-out backwards",
              visibility:
                selectedCustomer.name &&
                  selectedConsignee.name &&
                  selectedBillToParty.name &&
                  destinationAddressList.length > 0 &&
                  sourceAddressList.length > 0 &&
                  referenceNumberManual &&
                  transporterNameId &&
                  driverId &&
                  vehicleNumber &&
                  valueOfGoods &&
                  tripStartMeterReading &&
                  tripStartMeterReadingAttachment.attachment_file &&
                  customerInvoiceNo &&
                  customerInvoiceAttachment?.name &&
                  tripDistance &&
                  totalQty > 0 &&
                  (vehiclePayoutPeriod == "monthly_wise"
                    ? advanceByCustomer && billedByCustomer
                    : advanceByCustomer &&
                    billedByCustomer &&
                    (tripCost.filter((data) => data.cost_type == "trip_cost")[0]
                      ?.cost_amount !== ""
                      ? true
                      : false)) &&
                  // (tripCost.filter((data) => data.cost_type == "trip_cost")[0]
                  //   ?.cost_amount !== ""
                  //   ? true
                  //   : false) > 0
                  valueOfGoods
                  ? "visible"
                  : "hidden",
            }}
          >
            {Object.keys(selectedCustomer).length > 0 ? (
              selectedCustomer?.name.toLowerCase().includes("faarms") ? null : (
                <div className="create-trip-eway-box">
                  <div className="create-trip-from-row">
                    <div
                      className="create-trip-input-container"
                      style={{
                        width: isMobile ? "100%" : "",
                        padding: isMobile ? "0 10px" : "",
                        // backgroundColor: "red",
                      }}
                    >
                      <h4 className="create-trip-input-head">
                        {" "}
                        E-Way Bill Number
                        {mandatoryFields.length > 0 ? (
                          <sup
                            style={{
                              color: "red",
                              position: "relative",
                            }}
                          >
                            *
                          </sup>
                        ) : null}
                      </h4>
                      <TextField
                        size="small"
                        autoComplete="off"
                        className="create-trip-input"
                        variant="outlined"
                        disabled={checked ? true : false}
                        placeholder="Enter e-way bill no."
                        value={ewayBillNumber}
                        onChange={(e) => setEwayBillNumber(e.target.value)}
                      />
                      <div className="create-trip-error">
                        {ewayBillNumber ? null : errTrip.errEwayNumber}
                      </div>
                    </div>

                    <div
                      className="create-trip-input-container"
                      style={{
                        width: isMobile ? "100%" : "",
                        padding: isMobile ? "0 10px" : "",
                        // backgroundColor: "red",
                      }}
                    >
                      <h4 className="create-trip-input-head">
                        {" "}
                        E-Way Bill Valid From
                        {mandatoryFields.length > 0 ? (
                          <sup
                            style={{
                              color: "red",
                              position: "relative",
                            }}
                          >
                            *
                          </sup>
                        ) : null}
                      </h4>
                      <TextField
                        size="small"
                        autoComplete="off"
                        className="create-trip-input"
                        variant="outlined"
                        disabled={checked ? true : false}
                        placeholder="Enter total quantity"
                        // disabled={loadSource !== "trip_invoice" ? false : true}
                        type="date"
                        value={ewayBillFrom}
                        onChange={(e) => setEwayBillFrom(e.target.value)}
                      />
                      <div className="create-trip-error">
                        {ewayBillFrom && ewayBillFrom <= ewayBillTo
                          ? null
                          : errTrip.errEwayFrom}
                      </div>
                    </div>
                  </div>
                  <div className="create-trip-from-row">
                    <div
                      className="create-trip-input-container"
                      style={{
                        width: isMobile ? "100%" : "",
                        padding: isMobile ? "0 10px" : "",
                        // backgroundColor: "red",
                      }}
                    >
                      <h4 className="create-trip-input-head">
                        {" "}
                        E-Way Bill Valid Till
                        {mandatoryFields.length > 0 ? (
                          <sup
                            style={{
                              color: "red",
                              position: "relative",
                            }}
                          >
                            *
                          </sup>
                        ) : null}
                      </h4>
                      <TextField
                        size="small"
                        autoComplete="off"
                        className="create-trip-input"
                        variant="outlined"
                        placeholder="Enter total quantity"
                        type="date"
                        disabled={checked ? true : false}
                        value={ewayBillTo}
                        onChange={(e) => setEwayBillTo(e.target.value)}
                      />
                      <div className="create-trip-error">
                        {ewayBillTo && ewayBillFrom <= ewayBillTo
                          ? null
                          : errTrip.errEwayTo}
                      </div>
                    </div>
                    <div
                      className="trip-list-modal-input-group"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <h4 className="create-trip-input-head">
                        E-Way Bill Attachment{" "}
                        {mandatoryFields.length > 0 ? (
                          <sup
                            style={{
                              color: "red",
                              position: "relative",
                            }}
                          >
                            *
                          </sup>
                        ) : null}
                      </h4>

                      <TextField
                        size="small"
                        id="dropdown"
                        select
                        autoComplete="off"
                        style={isMobile ? { width: "85%" } : { width: "100%" }}
                        className="create-trip-input-attachment dropdown-container"
                        variant="outlined"
                        placeholder="Enter document type"
                        name="attachment_tag"
                        disabled={true}
                        value={"e_way_bill"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                cursor: "pointer",
                                color: colorPalette.primaryColor,
                                fontWeight: "600",
                              }}
                            >
                              <button
                                className="upload_button"
                                style={{ backgroundColor: "#D7F2C9" }}
                              >
                                <input
                                  name="attachment_file"
                                  id={`upload-eway`}
                                  type="file"
                                  accept="image/png, image/jpeg, application/pdf"
                                  onChange={(e) => {
                                    setEwayBillAttachment(e.target.files[0]);
                                  }}
                                  hidden
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                                <label
                                  htmlFor={`upload-eway`}
                                // style={{
                                //   backgroundColor:
                                //     colorPalette.secondaryWhite,
                                // }}
                                >
                                  <img
                                    src={UploadImage}
                                    alt=""
                                    // width="20px"
                                    // height="20px"
                                    style={{
                                      backgroundColor: "#D7F2C9",
                                    }}
                                  />
                                </label>
                              </button>
                            </InputAdornment>
                          ),
                        }}
                      >
                        <MenuItem value="0" disabled>
                          Select document type
                        </MenuItem>

                        {documentTypeList.map((item) => {
                          return (
                            <MenuItem value={item}>
                              {item
                                .split("_")
                                .join(" ")
                                .split("")
                                .map((item, index) => {
                                  if (index == 0) {
                                    return item.toUpperCase();
                                  } else {
                                    return item;
                                  }
                                })
                                .join("")}
                            </MenuItem>
                          );
                        })}
                      </TextField>

                      <div className="create-trip-attachment-name">
                        {ewayBillAttachment != null
                          ? ewayBillAttachment.name
                          : null}
                      </div>
                      <div className="error-trip">
                        {ewayBillAttachment
                          ? ewayBillAttachment.size >
                            process.env.REACT_APP_MAX_FILE_SIZE
                            ? `File size is too large.( < ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                            : null
                          : null}
                      </div>
                    </div>
                  </div>
                  {mandatoryFields.length > 0 ? null : (
                    <div className="create-trip-from-row-ewaybill">
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                      E-way bill details not required.
                    </div>
                  )}

                  {/* <div
                    className="trip-list-modal-row"
                    style={{ margin: "5px 10px" }}
                  >
                 
                  </div> */}
                </div>
              )
            ) : null}
          </div>
          {/* ) : null} */}

          <div
            className="create-trip-attachments-box"
            style={{
              opacity:
                (selectedCustomer.name &&
                  selectedConsignee.name &&
                  selectedBillToParty.name &&
                  destinationAddressList.length > 0 &&
                  sourceAddressList.length > 0 &&
                  referenceNumberManual &&
                  transporterNameId &&
                  driverId &&
                  vehicleNumber &&
                  valueOfGoods &&
                  tripStartMeterReading &&
                  (tripStartMeterReadingAttachment.attachment_file ||
                    editMode) &&
                  tripDistance &&
                  totalQty > 0 &&
                  billedByCustomer &&
                  advanceByCustomer &&
                  tripCost.length > 0 &&
                  valueOfGoods &&
                  ewayBillNumber &&
                  ewayBillTo &&
                  (ewayBillAttachment?.name || editMode) &&
                  customerInvoiceNo &&
                  (tripStartMeterReadingAttachment.attachment_file ||
                    editMode) &&
                  ewayBillFrom) ||
                  checked == true ||
                  editMode
                  ? "1"
                  : "0",
              transition: "all 1s",
              // animation: "fadeIn 500ms ease-out backwards",
              visibility:
                (selectedCustomer.name &&
                  selectedConsignee.name &&
                  selectedBillToParty.name &&
                  destinationAddressList.length > 0 &&
                  sourceAddressList.length > 0 &&
                  referenceNumberManual &&
                  transporterNameId &&
                  driverId &&
                  vehicleNumber &&
                  valueOfGoods &&
                  tripStartMeterReading &&
                  tripStartMeterReadingAttachment.attachment_file &&
                  tripDistance &&
                  totalQty > 0 &&
                  billedByCustomer &&
                  advanceByCustomer &&
                  tripCost.length > 0 &&
                  valueOfGoods &&
                  ewayBillNumber &&
                  ewayBillTo &&
                  ewayBillAttachment?.name &&
                  customerInvoiceNo &&
                  customerInvoiceAttachment?.name &&
                  ewayBillFrom) ||
                  checked == true ||
                  editMode
                  ? "visible"
                  : "hidden",
            }}
          >
            <h4
              style={{
                fontWeight: "500",
                margin: "10px 10px 0px 10px",
                fontSize: "18px",
              }}
            >
              Attachments{" "}
            </h4>
            <div className="create-trip-from-row">
              <div
                className="create-trip-input-container"
                style={{
                  width: isMobile ? "100%" : "",
                  padding: isMobile ? "0 10px" : "",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 10,
                  }}
                  className="trip-list-modal-attachment-container"
                >
                  <div
                    className="trip-list-modal-input-group"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                    }}
                  >
                    <h4 className="create-trip-input-head">
                      Transporter Invoice / Bhada Chithi{" "}
                      {mandatoryFields.length > 0 ? (
                        <sup
                          style={{
                            color: "red",
                            position: "relative",
                          }}
                        >
                          *
                        </sup>
                      ) : null}
                    </h4>

                    <TextField
                      size="small"
                      id="dropdown"
                      select
                      autoComplete="off"
                      style={isMobile ? { width: "85%" } : { width: "100%" }}
                      className="create-trip-input-attachment dropdown-container"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      disabled={true}
                      value={"Bhada Chiti"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          >
                            <button
                              className="upload_button"
                              style={{ backgroundColor: "#D7F2C9" }}
                            >
                              <input
                                name="attachment_file"
                                id={`upload-bhada-chiti`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={(e) => {
                                  setBhadaChiti(e.target.files[0]);
                                }}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label htmlFor={`upload-bhada-chiti`}>
                                <img
                                  src={UploadImage}
                                  alt=""
                                  style={{
                                    backgroundColor: "#D7F2C9",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>

                    <div className="create-trip-attachment-name">
                      {bhadaChiti != null ? bhadaChiti.name : null}
                    </div>
                    <div className="error-trip">
                      {bhadaChiti
                        ? bhadaChiti.size > process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size is too large.( < ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                          : null
                        : null}
                    </div>
                    <div className="create-trip-error">
                      {bhadaChiti
                        ? bhadaChiti?.type === "image/jpeg" ||
                          bhadaChiti?.type === "image/jpg" ||
                          bhadaChiti?.type === "image/png"
                          ? null
                          : "only jpeg/png format is allowed"
                        : null}
                    </div>
                  </div>

                  <div
                    className="trip-list-modal-input-group"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                    }}
                  >
                    <h4 className="create-trip-input-head">
                      Bilty Copy{" "}
                      {mandatoryFields.length > 0 ? (
                        <sup
                          style={{
                            color: "red",
                            position: "relative",
                          }}
                        >
                          *
                        </sup>
                      ) : null}
                    </h4>

                    <TextField
                      size="small"
                      id="dropdown"
                      select
                      autoComplete="off"
                      style={isMobile ? { width: "85%" } : { width: "100%" }}
                      className="create-trip-input-attachment dropdown-container"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      disabled={true}
                      value={"Bhada Chiti"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          >
                            <button
                              className="upload_button"
                              style={{ backgroundColor: "#D7F2C9" }}
                            >
                              <input
                                name="attachment_file"
                                id={`upload-bilty-copy`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={(e) => {
                                  setBiltyCopy(e.target.files[0]);
                                }}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label htmlFor={`upload-bilty-copy`}>
                                <img
                                  src={UploadImage}
                                  alt=""
                                  style={{
                                    backgroundColor: "#D7F2C9",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>

                    <div className="create-trip-attachment-name">
                      {biltyCopy != null ? biltyCopy.name : null}
                    </div>
                    <div className="error-trip">
                      {biltyCopy
                        ? biltyCopy.size > process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size is too large.( < ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                          : null
                        : null}
                    </div>
                    <div className="create-trip-error">
                      {biltyCopy
                        ? biltyCopy?.type === "image/jpeg" ||
                          biltyCopy?.type === "image/jpg" ||
                          biltyCopy?.type === "image/png"
                          ? null
                          : "only jpeg/png format is allowed"
                        : null}
                    </div>
                  </div>

                  {/* {attachmentListArray.map((item, index) => {
                        return (
                          <div key={index} className="trip-list-modal-row">
                            <div
                              className="trip-list-modal-input-group"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 5,
                                }}
                              >
                                <h4 className="create-trip-modal-head">
                                  Document type {index + 1}{" "}
                                </h4>
                                {index == 0 && loadSource != "trip_invoice" ? (
                                  <span style={{color:"red"}}>*</span>
                                ) : null}
                              </div>

                              <TextField
                                size="small"
                                id="dropdown"
                                select
                                autoComplete="off"
                                className="create-trip-input-attachment dropdown-container"
                                // style={{
                                //   width: isMobile ? "100%" : "",
                                //   padding: isMobile ? "0 10px" : "",
                                //   backgroundColor: "red",
                                // }}
                                variant="outlined"
                                placeholder="Enter document type"
                                name="attachment_tag"
                                disabled={
                                  index == 0 && loadSource != "trip_invoice"
                                    ? true
                                    : false
                                }
                                value={
                                  loadSource != "trip_invoice" && index == 0
                                    ? "bilty_copy"
                                    : item.attachment_tag
                                }
                                onChange={(e) => {
                                  handleAttachmentChange(e, index);
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      style={{
                                        cursor: "pointer",
                                        color: colorPalette.primaryColor,
                                        fontWeight: "600",
                                      }}
                                    >
                                      <button
                                        className="upload_button"
                                        style={{ backgroundColor: "#D7F2C9" }}
                                      >
                                        <input
                                          // name="upload"
                                          name="attachment_file"
                                          id={`upload${index}`}
                                          type="file"
                                          disabled={
                                            checkedAttachmentsNotRequired
                                              ? true
                                              : false
                                          }
                                          accept="image/png, image/jpeg, application/pdf"
                                          onChange={(e) => {
                                            handleAttachmentChange(e, index);
                                            handleAddAttachment(e);
                                             
                                          }}
                                          hidden
                                          style={{
                                            backgroundColor:
                                              colorPalette.secondaryWhite,
                                          }}
                                        />
                                        <label
                                          htmlFor={`upload${index}`}
                                          style={{
                                            backgroundColor: "#D7F2C9",
                                          }}
                                        >
                                          <img
                                            src={UploadImage}
                                            alt=""
                                            // width="20px"
                                            // height="20px"
                                            style={{
                                              backgroundColor: "#D7F2C9",
                                            }}
                                          />
                                        </label>
                                      </button>
                                    </InputAdornment>
                                  ),
                                }}
                              >
                                <MenuItem value="0" disabled>
                                  Select document type
                                </MenuItem>

                                {documentTypeList.map((item) => {
                                  return (
                                    <MenuItem value={item}>
                                      {item
                                        .split("_")
                                        .join(" ")
                                        .split("")
                                        .map((item, index) => {
                                          if (index == 0) {
                                            return item.toUpperCase();
                                          } else {
                                            return item;
                                          }
                                        })
                                        .join("")}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>

                              <div className="create-trip-attachment-name">
                                {item.attachment_file != null
                                  ? item.attachment_file.name
                                  : null}
                              </div>
                              <div className="error-trip">
                                {item.attachment_file
                                  ? item.attachment_file.size >
                                    process.env.REACT_APP_MAX_FILE_SIZE
                                    ? `File size is too large.( <  ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                                    : null
                                  : null}
                              </div>
                            </div>
                          </div>
                        );
                      })} */}
                  {/* {editMode ? null : (
                        <div className="create-trip-from-row-ewaybill">
                          <Checkbox
                            checked={checkedAttachmentsNotRequired}
                            onChange={handleChangeCheckedAttachments}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                          Attachments not required.
                        </div>
                      )} */}

                  {isMobile ? null : (
                    <div className="trip-list-modal-row">
                      <div className="trip-list-modal-input-group"></div>
                      <div className="trip-list-modal-input-group"></div>
                      <div className="trip-list-modal-input-group"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {editMode ? (
          <>
            <div className="create-trip-button-container-error">
              {" "}
              <div>
                {" "}
                {errCreateTripFinal ? (
                  <div className="create-trip-alert">
                    <Alert severity="error">{errCreateTripFinal}</Alert>
                  </div>
                ) : null}
                {/* <div className="create-trip-button-container"> */}
                {/* </div> */}
              </div>{" "}
            </div>
            <div className="create-trip-button-container">
              <button
                className="create-trip-button-next-summary"
                onClick={() => {
                  handleCreateTrip();
                  setErrDriverFinal("");
                }}
              >
                {loadingButton ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Update Trip"
                )}
              </button>
              {/* </div> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {permission ? (
                  <Alert severity="error">{permission}</Alert>
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <>
            {/* {errCreateTripFinal ? (
              <div className="create-trip-alert">
                <Alert severity="error">{errCreateTripFinal}</Alert>
              </div>
            ) : null} */}
            <div
              className="create-trip-button-container"
              style={{
                opacity:
                  (selectedCustomer.name &&
                    selectedConsignee.name &&
                    selectedBillToParty.name &&
                    destinationAddressList.length > 0 &&
                    sourceAddressList.length > 0 &&
                    referenceNumberManual &&
                    transporterNameId &&
                    driverId &&
                    vehicleNumber &&
                    valueOfGoods &&
                    tripStartMeterReading &&
                    tripStartMeterReadingAttachment.attachment_file &&
                    tripDistance &&
                    totalQty > 0 &&
                    billedByCustomer &&
                    advanceByCustomer &&
                    tripCost.length > 0 &&
                    valueOfGoods &&
                    ((ewayBillNumber &&
                      ewayBillTo &&
                      ewayBillAttachment?.name &&
                      ewayBillFrom) ||
                      checked == true) &&
                    bhadaChiti?.name &&
                    biltyCopy?.name) ||
                    tripType === "last_mile" ||
                    tripType === "middle_mile"
                    ? //   attachmentListArray[0].attachment_file) ||
                    // checkedAttachmentsNotRequired ||
                    // checked == true
                    "1"
                    : "0",
                transition: "all 1s",
                // animation: "fadeIn 500ms ease-out backwards",
                visibility:
                  (selectedCustomer.name &&
                    selectedConsignee.name &&
                    selectedBillToParty.name &&
                    destinationAddressList.length > 0 &&
                    sourceAddressList.length > 0 &&
                    referenceNumberManual &&
                    transporterNameId &&
                    driverId &&
                    vehicleNumber &&
                    valueOfGoods &&
                    tripStartMeterReading &&
                    tripStartMeterReadingAttachment.attachment_file &&
                    tripDistance &&
                    totalQty > 0 &&
                    billedByCustomer &&
                    advanceByCustomer &&
                    tripCost.length > 0 &&
                    valueOfGoods &&
                    ((ewayBillNumber &&
                      ewayBillTo &&
                      ewayBillAttachment?.name &&
                      ewayBillFrom) ||
                      checked == true) &&
                    ((bhadaChiti?.name &&
                      biltyCopy?.name &&
                      biltyCopy?.type === "image/jpeg") ||
                      biltyCopy?.type === "image/jpg" ||
                      biltyCopy?.type === "image/png") &&
                    (bhadaChiti?.type === "image/jpeg" ||
                      bhadaChiti?.type === "image/jpg" ||
                      bhadaChiti?.type === "image/png")) ||
                    tripType === "last_mile" ||
                    tripType === "middle_mile"
                    ? //   attachmentListArray[0].attachment_file) ||
                    // checkedAttachmentsNotRequired ||
                    "visible"
                    : "hidden",
              }}
            >
              <button
                className="create-trip-button-next-summary"
                onClick={() => setOpenSummary(true)}
              >
                Next <NavigateNextIcon />
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {permission ? <Alert severity="error">{permission}</Alert> : null}
            </div>
          </>
        )}
      </div>
      <div className="create-trip-right-most-container">
        {/*Source details start*/}
        {currentTab == "source" ? (
          // sourceDetails
          //     .filter((item, indexs) => indexs == currentSourceId - 1)
          //     .map((item, index) => {
          //       return (
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => {
              toggleDrawer("right", false);
              setErrorAddressName("");
              setErrorAddressNumber("");
              setErrorAddressPincode("");
              setErrorAddressVillage("");
              selectedIndex("");
              setEditId("");
            }}
          >
            <Create_trip_drawer_container className="create-trip-drawer-container">
              <div
                className="create-trip-right-most-head"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Add Trip source
                <Close
                  color="action"
                  onClick={handleCloseDriverDrawer}
                  style={{ marginRight: "10px" }}
                />
              </div>

              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Concern person name <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    name="concern_person_name"
                    placeholder="Enter the name"
                    value={newSourceDetails.concern_person_name}
                    // value={item.concern_person_name}
                    // onChange={(e) => {
                    //   handleOnChangeSource(e);
                    // }}
                    onChange={(e) => {
                      changehandle(e);
                      setErrorAddressName("");
                    }}
                  />
                  <div className="create-trip-error">
                    {errorAddressName ? errorAddressName : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Concern person Mobile Number{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="concern_person_mobile_number"
                    placeholder="Enter the mobile number"
                    value={newSourceDetails.concern_person_mobile_number}
                    // value={item.concern_person_mobile_number}
                    // onChange={(e) => {
                    //   if (e.target.value.length <= 10) {
                    //     handleOnChangeSource(e);
                    //   }
                    // }}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        changehandle(e);
                        setErrorAddressNumber("");
                      }
                    }}
                  />
                  <div className="create-trip-error">
                    {errorAddressNumber ? errorAddressNumber : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Pincode <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    placeholder="Enter Pincode"
                    name="postcode"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={newSourceDetails.postcode}
                    // value={item.postcode}
                    onChange={(e) => {
                      if (e.target.value.length <= 6) {
                        setErrPincodeNotServiceable("");
                        changehandle(e);
                        setErrorAddressPincode("");
                        if (e.target.value.length == 6) {
                          handleGetAddressSource(e.target.value);
                        }
                      }
                    }}
                  // onChange={(e) => {
                  //   changehandle(e);
                  // }}
                  />
                  <div className="create-trip-error">
                    {errPincodeNotServiceable ? errPincodeNotServiceable : null}
                    {errorAddressPincode ? errorAddressPincode : null}
                  </div>
                </div>
              </div>
              {/* concern_person_name: "",
      concern_person_mobile_number: "",
      postcode: "",
      type: "source",
      village: "0",
      postoffice: "",
      sub_district: "",
      district: "",
      state: "",
      landmark: "",
      latitude: "",
      longitude: "", */}
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Village <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    className="create-trip-input"
                    id="outlined-select-currency"
                    name="village"
                    select
                    size="small"
                    value={newSourceDetails.village}
                    // onChange={(e) => handleOnChangeSource(e)}
                    onChange={(e) => {
                      changehandle(e);
                      setErrorAddressVillage("");
                    }}

                  // label="Select"
                  // value={currency}
                  // onChange={handleChange}
                  >
                    <MenuItem value="0" disabled>
                      Select Village
                    </MenuItem>
                    {sourceVillagesList.map((village, index) => {
                      return (
                        <MenuItem key={index} value={village}>
                          {village}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <div className="create-trip-error">
                    {errorAddressVillage ? errorAddressVillage : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Sub District <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    name="sub_district"
                    placeholder="sub district"
                    value={addressDetails.sub_district}
                    disabled
                  />
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    District <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    placeholder="District"
                    name="district"
                    value={addressDetails.district}
                    disabled
                  />
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    State <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    name="state"
                    placeholder="state"
                    value={addressDetails.state}
                    disabled
                  />
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head"> Landmark</h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    placeholder="Enter the landmark"
                    name="landmark"
                    value={newSourceDetails.landmark}
                    // onChange={(e) => handleOnChangeSource(e)}
                    onChange={changehandle}
                  />
                </div>
              </div>
              <div className="create-trip-input-container">
                <button
                  className="create-trip-save-btn"
                  onClick={handleSubmitSourceAddress}
                >
                  Save
                </button>
              </div>
            </Create_trip_drawer_container>
          </Drawer>
        ) : null}
        {/*Source details end*/}
        {/*Destination details start*/}
        {currentTab == "destination" ? (
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => {
              toggleDrawer("right", false);
              setErrorAddressName("");
              setErrorAddressNumber("");
              setErrorAddressPincode("");
              setErrorAddressVillage("");
            }}
          >
            <Create_trip_drawer_container className="create-trip-drawer-container">
              <div
                className="create-trip-right-most-head"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Add Trip destination
                <Close
                  color="action"
                  onClick={handleCloseDriverDrawer}
                  style={{ marginRight: "10px" }}
                />
              </div>
              {/* const [errorAddressName, setErrorAddressName] = useState("");
  const [errorAddressNumber, setErrorAddressNumber] = useState("");
  const [errorAddressPincode, setErrorAddressPincode] = useState("");
  const [errorAddressVillage, setErrorAddressVillage] = useState(""); */}
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Concern person name <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    name="concern_person_name"
                    placeholder="Enter the name"
                    value={newdestinationDetails.concern_person_name}
                    onChange={(e) => {
                      changehandleDestination(e);
                      setErrorAddressName("");
                    }}
                  />
                  <div className="create-trip-error">
                    {errorAddressName ? errorAddressName : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Concern person Mobile Number{" "}
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name="concern_person_mobile_number"
                    placeholder="Enter the mobile number"
                    value={newdestinationDetails.concern_person_mobile_number}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) {
                        changehandleDestination(e);
                        setErrorAddressNumber("");
                      }
                    }}
                  />
                  <div className="create-trip-error">
                    {errorAddressNumber ? errorAddressNumber : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Pincode <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    name="postcode"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    placeholder="Enter Pincode"
                    value={newdestinationDetails.postcode}
                    onChange={(e) => {
                      if (e.target.value.length <= 6) {
                        setErrPincodeNotServiceable("");
                        changehandleDestination(e);
                        setErrorAddressPincode("");
                        if (e.target.value.length == 6) {
                          handleGetAddressDestination(e.target.value);
                        }
                      }
                    }}
                  />
                  <div className="create-trip-error">
                    {errPincodeNotServiceable ? errPincodeNotServiceable : null}
                    {errorAddressPincode ? errorAddressPincode : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Village <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    className="create-trip-input"
                    id="outlined-select-currency"
                    select
                    size="small"
                    name="village"
                    value={newdestinationDetails.village}
                    onChange={(e) => {
                      changehandleDestination(e);
                      setErrorAddressVillage("");
                    }}
                  // label="Select"
                  // value={currency}
                  // onChange={handleChange}
                  >
                    <MenuItem value="0" disabled>
                      Select Village
                    </MenuItem>
                    {destinationVillagesList.map((village, index) => {
                      return (
                        <MenuItem key={index} value={village}>
                          {village}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  <div className="create-trip-error">
                    {errorAddressVillage ? errorAddressVillage : null}
                  </div>
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    Sub District <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    placeholder="sub district"
                    value={addressDetailsDestination.sub_district}
                    disabled
                  />
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    District <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    placeholder="District"
                    value={addressDetailsDestination.district}
                    disabled
                  />
                </div>
              </div>
              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head">
                    {" "}
                    State <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    placeholder="state"
                    value={addressDetailsDestination.state}
                    disabled
                  />
                </div>
              </div>

              <div className="create-trip-from-row">
                <div className="create-trip-input-container">
                  <h4 className="create-trip-input-head"> Landmark</h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    name="landmark"
                    placeholder="Enter the landmark"
                    value={addressDetailsDestination.landmark}
                    onChange={changehandleDestination}
                  />
                </div>
              </div>

              <div className="create-trip-input-container">
                <button
                  className="create-trip-save-btn"
                  onClick={() => changhandleSubmitDestination()}
                >
                  Save
                </button>
              </div>
            </Create_trip_drawer_container>
          </Drawer>
        ) : null}

        {currentTab == "driver" ? (
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => {
              toggleDrawer("right", false);
              setErrDriverDetails({
                errFirstName: "",
                errMobileNumber: "",
                errPanNumber: "",
                errDLNumber: "",
                errPanDocs: "",
                errDLDocs: "",
              });
            }}
          >
            <Create_trip_drawer_container className="create-trip-drawer-container">
              <div
                className="create-trip-right-most-head"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4>Driver's details:</h4>{" "}
                <Close
                  onClick={handleCloseDriverDrawer}
                  style={{ marginRight: "20px", cursor: "pointer" }}
                />
              </div>
              <div className="create-trip-from-body">
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      First name <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter first name"
                      value={driverDetails.first_name}
                      onChange={(e) => {
                        setDriverDetails({
                          ...driverDetails,
                          first_name: e.target.value,
                        });
                      }}
                    />
                    <div className="create-trip-error">
                      {!driverDetails.first_name
                        ? errDriverDetails.errFirstName
                        : null}
                    </div>
                  </div>
                </div>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head"> Last name</h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter last name"
                      value={driverDetails.last_name}
                      onChange={(e) => {
                        setDriverDetails({
                          ...driverDetails,
                          last_name: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Mobile number <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter the mobile number"
                      value={driverDetails.mobile_number}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          setDriverDetails({
                            ...driverDetails,
                            mobile_number: e.target.value,
                          });
                        }
                      }}
                    />
                    <div className="create-trip-error">
                      {driverDetails.mobile_number < 6000000000
                        ? errDriverDetails.errMobileNumber
                        : null}
                    </div>
                  </div>
                </div>

                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Alternate Mobile number{" "}
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Enter the mobile number"
                      value={driverDetails.alternate_mobile_number}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          setDriverDetails({
                            ...driverDetails,
                            alternate_mobile_number: e.target.value,
                          });
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Driving license number{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter the driving licence number"
                      value={driverDetails.driving_licence_number}
                      onChange={(e) => {
                        setDriverDetails({
                          ...driverDetails,
                          driving_licence_number: e.target.value,
                        });
                      }}
                    />
                    <div className="create-trip-error">
                      {driverDetails.driving_licence_number.length >= 6
                        ? null
                        : errDriverDetails.errDLNumber}
                    </div>
                  </div>
                </div>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    {/* <h4 className="create-trip-input-head">
                      {" "}
                      Upload Driving License{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4> */}
                    <TextField
                      size="small"
                      id="dropdown"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      disabled
                      // disabled={index == 0 ? true : false}
                      value={"Upload Driving license"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          // onClick={handleAddAttachment}
                          >
                            <button className="upload_button">
                              <input
                                // name="upload"
                                name="attachment_file"
                                id={`uploaddl`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={handleDrivingPhoto}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label
                                htmlFor={`uploaddl`}
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              >
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                    // backgroundColor: "red",
                                    // width: "100%",
                                    // height: "100%",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <input
                      type="file"
                      className="create-trip-input"
                      accept="image/png, image/jpeg, application/pdf"
                      onChange={handleDrivingPhoto}
                    /> */}
                    <div className="create-trip-dl-name">
                      {drivingPhoto ? drivingPhoto.name : null}
                    </div>
                    <div className="create-trip-error">
                      {!drivingPhoto ? errDriverDetails.errDLDocs : null}
                    </div>
                    <div className="create-trip-error">
                      {drivingPhoto
                        ? drivingPhoto.size >
                          process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size should be less than ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB`
                          : null
                        : null}
                    </div>
                  </div>
                </div>
                <div>
                  {errTotalDriver ? (
                    <Alert severity="error">{errTotalDriver}</Alert>
                  ) : null}
                </div>
                <div className="create-trip-input-container">
                  <button
                    className="create-trip-save-btn"
                    onClick={() => handleDriverSave()}
                  >
                    {driverLoadingButton ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Create_trip_drawer_container>
          </Drawer>
        ) : null}

        {currentTab === "customer" ? (
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => {
              toggleDrawer("right", false);
              setCustomerDetails({
                errName: "",
                errLogo: "",
              });
            }}
          >
            <Create_trip_drawer_container className="create-trip-drawer-container">
              <div
                className="create-trip-right-most-head"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h4>Add Customer </h4>{" "}
                <Close
                  onClick={handleCloseDriverDrawer}
                  style={{ marginRight: "20px", cursor: "pointer" }}
                />
              </div>
              <div className="create-trip-from-body">
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Name <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter customer name"
                      // value={addCustomerData?.name}
                      onChange={(e) => {
                        setAddCustomerData({
                          ...addCustomerData,
                          name: e.target.value,
                        });
                      }}
                    />
                    <div className="create-trip-error">
                      {!addCustomerData.name
                        ? errCustomerDetails.errName
                        : null}
                    </div>
                  </div>
                </div>

                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head">
                      {" "}
                      Upload Customer's logo{" "}
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>
                    <TextField
                      size="small"
                      id="dropdown"
                      autoComplete="off"
                      className="create-trip-input"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      disabled
                      // disabled={index == 0 ? true : false}
                      value={"Upload Customer's logo"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          // onClick={handleAddAttachment}
                          >
                            <button className="upload_button">
                              <input
                                // name="upload"
                                name="attachment_file"
                                id={`uploadcustlogo`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={handleCustomerLogo}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label
                                htmlFor={`uploadcustlogo`}
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              >
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                    // backgroundColor: "red",
                                    // width: "100%",
                                    // height: "100%",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <div className="create-trip-dl-name">
                      {addCustomerData?.logo
                        ? addCustomerData?.logo?.name
                        : null}
                    </div>
                    <div className="create-trip-error">
                      {!addCustomerData.logo
                        ? errCustomerDetails.errLogo
                        : null}
                    </div>
                    <div className="create-trip-error">
                      {drivingPhoto
                        ? drivingPhoto.size >
                          process.env.REACT_APP_MAX_FILE_SIZE
                          ? `File size should be less than ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB`
                          : null
                        : null}
                    </div>
                  </div>
                </div>
                <div className="create-trip-from-row">
                  <div className="create-trip-input-container">
                    <h4 className="create-trip-input-head"> Description</h4>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      // value={remarks}
                      placeholder="Write description.."
                      style={{
                        width: "80%",
                        // height: 100,
                        // marginBottom: "2rem",
                        padding: ".4rem",
                      }}
                      onChange={(e) => {
                        setAddCustomerData({
                          ...addCustomerData,
                          description: e.target.value,
                        });
                      }}
                    />
                    <div className="create-trip-error">
                      {!addCustomerData.description
                        ? errCustomerDetails.errDescription
                        : null}
                    </div>
                  </div>
                </div>
                <div>
                  {errCustomerFinal ? (
                    <Alert severity="error">{errCustomerFinal}</Alert>
                  ) : null}
                </div>
                <div className="create-trip-input-container">
                  <button
                    className="create-trip-save-btn"
                    onClick={() => handleCustomerSave()}
                  >
                    {customerLoadingButton ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </div>
            </Create_trip_drawer_container>
          </Drawer>
        ) : null}

        {/*Driver's details end*/}
      </div>
    </Create_Trip_Main_Container>
  );
}

export default CreateTripV2;
