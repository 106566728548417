import styled from "styled-components";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";

export const Mini_detais_Card_style = styled.div`
  /* .mini-details-card-main-container { */
  width: 370px;
  /* height: 95vh; */
  /* margin: 10px; */

  background: ${colorPalette.white};
  box-shadow: ${colorPalette.boxShadowPrimary};
  border-radius: 14px;
  /* } */
  .mini-delivery-payment-details-attachment {
    padding: 10px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mini-details-tab-container-history-quoations {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  }
  .mini-details-attachment-list {
    /* margin: 10px; */
    background-color: ${colorPalette.white};
    overflow: scroll;
    height: 83vh;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
  }
  .mini-card-not-found {
    width: 100%;
    display: flex;
    margin-top: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mini-details-no-attachment {
    width: 100%;
    /* background-color: red; */
    margin-top: 50%;
    /* margin-left: 50%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* justify-content: center; */
  }
  .mini-delivery-payment-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${typography.paragraph};
    font-weight: ${FontWeight.medium};
  }
  .mini-details-attachment-single {
    margin: 10px;
  }
  .mini-card-delivery-info-container {
    margin: 5px;
  }
  .mini-card-delivery-left {
    margin: 0px 3px;
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph};
  }
  .mini-card-delivery-right {
    color: ${colorPalette.paragraphSecondaryolor};
    /* position: absolute;
  left: 50%; */
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph};
  }
  .mini-card-row-delivery {
    margin: 5px 0px;

    display: flex;
    justify-content: space-between;
  }
  .mini-details-history-head {
    font-weight: ${FontWeight.bold};
    font-size: ${typography.heading4};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.paragraphColor};
    /* color: ${colorPalette.paragraphColor}; */
  }
  .mini-details-black-text {
    color: ${colorPalette.black};
    margin-left: 1vw;
    font-weight: ${FontWeight.regular};
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.lightBlack
        : colorPalette.paragraphSecondaryolor};
  }
  .mini-details-grey-text {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.paragraphColor};
    font-weight: ${FontWeight.semibold};
    margin-left: 1vw;
  }
  .mini-details-tab-container-history {
    height: 83vh;
    overflow: scroll;
    display: flex;
  }
  .mini-details-tab-left-timeline {
  }
  .mini-trip-rectangle-timeline {
    width: 2px;
    height: 10px;
    margin: 2px 0px;
    position: relative;
    left: 0.6vw;
    top: 1vh;
    background-color: ${(props) =>
      props.mode
        ? colorPaletteDark.white
        : colorPalette.paragraphSecondaryolor};
    /* background-color: ${colorPalette.paragraphSecondaryolor}; */
  }
  .mini-details-history-right {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
  }
  .mini-details-history-left {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
  }
  .mini-details-tab-container {
    height: 83vh;
    overflow: scroll;
    position: relative;
  }
  .trip-location-text {
    font-size: ${typography.paragraph};
    font-weight: ${FontWeight.medium};
    color: ${colorPalette.paragraphSecondaryolor};
  }
  .mini-trip-circle {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border-radius: 50%;
    z-index: 1000;
    background-color: ${(props) =>
      props.mode
        ? colorPaletteDark.white
        : colorPalette.paragraphSecondaryolor};
  }
  .mini-trip-rectangle {
    width: 2px;
    height: 30px;
    background-color: ${colorPalette.paragraphColor};
  }
  .mini-details-img {
    width: 100%;
    background-color: ${colorPalette.paragraphSecondaryolor};
    height: 30%;
  }

  .MuiTabs-indicator {
    background-color: ${colorPalette.primaryColor} !important;
    color: black !important;
  }
  .mini-details-single-list {
    margin: 10px 5px;
    display: flex;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: ${typography.paragraph} !important;
    font-weight: ${FontWeight.semibold} !important;
  }
  .mini-details-single-list-left {
    font-size: ${typography.heading5};
    margin: 0px 10px;
  }
  .mini-details-single-list-right {
    font-size: ${typography.heading5};
    margin: 0px 10px;
    position: absolute;
    color: ${colorPalette.paragraphSecondaryolor};
    left: 45%;
  }
  .mini-details-single-attachment {
    padding: 8px;
    margin: 10px 0px;
    display: flex;
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 10px;
    width: 95%;
    margin: -8px 0;
  }

  .mini-card-delivery-table {
    font-family: ${FontFamily.primaryFontFamily};
    border-collapse: collapse;
    width: 100%;
  }

  .mini-card-delivery-table-td {
    border: 1px solid ${colorPalette.paragraphSecondaryolor};
    text-align: left;
    padding: 8px;
  }
  .mini-card-delivery-table-th {
    border: 1px solid ${colorPalette.paragraphSecondaryolor};
    text-align: left;
    padding: 8px;
    font-size: ${typography.heading5};
  }
  .mini-card-table-text-left {
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph_secondary};
  }
  .mini-card-table-text-right {
    font-weight: ${FontWeight.medium};
    color: ${colorPalette.paragraphSecondaryolor};
    font-size: ${typography.paragraph_secondary};
  }

  /* .mini-card-delivery-table-tr:nth-child(even) {
  background-color: #dddddd;
} */

  @media only screen and (max-width: 499px) {
    /* .mini-details-card-main-container { */
    width: 96vw;
    height: 95vh;
    /* } */
    .mini-details-tab-container-history {
      height: 80vh;
    }
    .mini-details-tab-container {
      height: 80vh;
    }

    .mini-details-single-list-right {
      font-size: ${typography.heading5};
      margin: 0px 10px;
      position: absolute;
      color: ${colorPalette.paragraphColor};
      left: 50%;
      font-weight: ${FontWeight.medium};
    }

    .mini-details-grey-text {
      font-weight: ${FontWeight.medium};
      margin-left: 2vw;
    }
    .mini-trip-rectangle-timeline {
      top: 1vh;
      left: 2vw;
    }
    .mini-details-black-text {
      margin-left: 10px;
    }
  }
`;
