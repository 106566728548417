import React from "react";
import apiUrl from "../../url/apiUrl.js";
import axios from "axios";
import axiosInterceptor from "../../config/https.js";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter.js";

export const vehiclesRegisterPost = (
  // registration,
  national_permit,
  state_permit,
  permited_state,
  permit_number,
  vehicle_number,
  // status,
  permit_expires,
  carrier_id,
  vehicle_model_id,
  oem_id,
  insurance_certificate,
  registration_certificate,
  vehicle_front_picture,
  vehicle_back_picture,
  pollution_control_certificate,
  insurance_type,
  reg_type,
  front_type,
  back_type,
  pollution_type,
  registration_certificate_expiry_date,
  pollution_control_certificate_expiry_date,
  insurance_certificate_expiry_date,
  freight_type,
  payout_period,
  basic_payout_amount,
  extra_per_km_rate,
  fixed_kms,
  payout_cutoff_day_of_month
) => {
  // let data = JSON.stringify({
  //   registration: `${registration}`,
  //   is_national_permit: `${national_permit}`,
  //   is_state_permit: `${!national_permit}`,
  //   permitted_states_list: permited_state,
  //   permit_number: `${permit_number}`,
  //   vehicle_number: `${vehicle_number}`,
  //   status: `${status}`,
  //   permit_expiry_date: `${permit_expires} 00:00:00`,
  //   carrier_id: `${carrier_id}`,
  //   vehicle_model_id: `${vehicle_model_id}`,
  //   vehicle_manufacturer_id: `${oem_id}`,
  // });
  let data = new FormData();
  // data.append("registration", registration);
  data.append("is_national_permit", national_permit);
  data.append("is_state_permit", !national_permit);
  data.append("permitted_states_list", permited_state);
  data.append("permit_number", permit_number);
  data.append("vehicle_number", vehicle_number);
  // data.append("status", status);
  data.append("permit_expiry_date", `${permit_expires}`);
  data.append("carrier_id", carrier_id);
  data.append("vehicle_model_id", vehicle_model_id);
  data.append("vehicle_manufacturer_id", oem_id);
  data.append("insurance_certificate", insurance_certificate);
  data.append("registration_certificate", registration_certificate);
  data.append("vehicle_front_picture", vehicle_front_picture);
  data.append("vehicle_back_picture", vehicle_back_picture);
  data.append("pollution_control_certificate", pollution_control_certificate);
  data.append("tag_1", "insurance");
  data.append("tag_2", "registration certificate");
  data.append("tag_3", "vehicle_front_picture");
  data.append("tag_4", "vehicle_back_picture");
  data.append("tag_5", "pollution_control_certificate");
  data.append("attachment_type_1", insurance_type);
  data.append("attachment_type_2", reg_type);
  data.append("attachment_type_3", front_type);
  data.append("attachment_type_4", back_type);
  data.append("attachment_type_5", pollution_type);
  data.append(
    "registration_certificate_expiry_date",
    `${registration_certificate_expiry_date}`
  );
  data.append(
    "pollution_control_certificate_expiry_date",
    `${pollution_control_certificate_expiry_date}`
  );
  data.append(
    "insurance_certificate_expiry_date",
    `${insurance_certificate_expiry_date}`
  );
  data.append("freight_type", freight_type);
  data.append("payout_period", payout_period);
  data.append("basic_payout_amount", basic_payout_amount);
  data.append("extra_per_km_rate", extra_per_km_rate);
  data.append("fixed_kms", fixed_kms);
  data.append("payout_cutoff_day_of_month", payout_cutoff_day_of_month);

  var config = {
    method: "post",
    // url: `${
    //   apiUrl.onboardingBaseUrl
    // }/v1/transporter/${prefixSalt()}-${convertToBase64(carrier_id)}/vehicle`,
    url: `${apiUrl.onboardingBaseUrl}/v1/vehicle`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error);
      });
  });
};

export const vehiclesRegisterPut = (
  // registration,
  national_permit,
  state_permit,
  permited_state,
  permit_number,
  vehicle_number,
  status,
  permit_expires,
  // carrier_id,
  vehicle_model_id,
  oem_id,
  carrierId,
  vehicle_id,
  freight_type,
  payout_period,
  basic_payout_amount,
  extra_per_km_rate,
  fixed_kms,
  payout_cutoff_day_of_month
) => {
  // --form 'is_active="true"' \
  // --form 'status="available"' \
  // --form 'is_state_permit="true"' \
  // --form 'permitted_states="bihar,assam"' \
  // --form 'vehicle_manufacturer_id="1"' \
  // --form 'vehicle_model_id="2"'

  let data = new FormData();
  data.append("is_national_permit", national_permit);
  data.append("is_state_permit", !national_permit);
  data.append("permitted_states", permited_state);
  data.append("permit_number", permit_number);
  data.append("vehicle_number", vehicle_number);
  data.append("status", status);
  data.append("permit_expiry_date", `${permit_expires}`);
  // data.append("transporter_id", carrier_id);
  data.append("vehicle_model_id", vehicle_model_id);
  data.append("vehicle_manufacturer_id", oem_id);
  data.append("freight_type", freight_type);
  data.append("payout_period", payout_period);
  data.append("basic_payout_amount", basic_payout_amount);
  data.append("extra_per_km_rate", extra_per_km_rate);
  data.append("fixed_kms", fixed_kms);
  data.append("payout_cutoff_day_of_month", payout_cutoff_day_of_month);
  var config = {
    method: "put",
    // url: `${
    //   apiUrl.onboardingBaseUrl
    // }/v1/transporter/${prefixSalt()}-${convertToBase64(
    //   carrierId
    // )}/vehicle/${prefixSalt()}-${convertToBase64(vehicle_id)}`,
    url: `${
      apiUrl.onboardingBaseUrl
    }/v1/vehicle/${prefixSalt()}-${convertToBase64(vehicle_id)}`,

    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error);
      });
  });
};

export const vehiclesPermitPost = (
  permit_file,
  permit_numbers,
  permit_expiry_date,
  media_type,
  permited_state,
  carrierId,
  vehicle_id
) => {
  let data = new FormData();
  data.append("permit_number", permit_numbers);
  // data.append("permit_expiry_date_str", `${permit_expiry_date}`);
  data.append("file", permit_file);
  data.append("tag", permited_state ? permited_state : "null");
  data.append("document_type", "Permit");
  data.append("media_type", media_type);

  var config = {
    method: "post",
    // url: `${
    //   apiUrl.onboardingBaseUrl
    // }/v1/transporter/${prefixSalt()}-${convertToBase64(
    //   carrierId
    // )}/vehicle/${prefixSalt()}-${convertToBase64(vehicle_id)}/attachment`,
    url: `${
      apiUrl.onboardingBaseUrl
    }/v1/vehicle/${prefixSalt()}-${convertToBase64(vehicle_id)}/attachments`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 201) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error);
      });
  });
};

// export const getVehiclesDetails = async () => {
//   try {
//     const onboardListResponse = await axios.get(
//       `${apiUrl.onboardingBaseUrl}/v1/carrier/1/vehicle`
//     );

//     if (vehiclesDataResponse.status == 200) {
//       return onboardListResponse.response.data;
//     }
//   } catch (e) {
//
//   }
// };

export const getVehiclesModels = async (manufacturerId, input) => {
  try {
    const vehiclesResponse = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/vehicle-manufacturer/${prefixSalt()}-${convertToBase64(
        manufacturerId
      )}/vehicle-model?name=${input}&is_active=true`
    );

    if (vehiclesResponse.status == 200) {
      return vehiclesResponse.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const getVehiclesModelById = async (manufacturerId, modelId) => {
  try {
    const vehiclesResponse = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/vehicle-manufacturer/${prefixSalt()}-${convertToBase64(
        manufacturerId
      )}/vehicle-model/${prefixSalt()}-${convertToBase64(modelId)}`
    );

    if (vehiclesResponse.status == 200) {
      return vehiclesResponse.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const getVehicleManufacturerById = async (manufacturerId) => {
  try {
    const oemResponse = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/vehicle-manufacturer/${prefixSalt()}-${convertToBase64(
        manufacturerId
      )}`
    );

    if (oemResponse.status == 200) {
      return oemResponse.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const getOemId = async (inputs) => {
  try {
    const oemResponse = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/vehicle-manufacturer?name=${inputs}&is_active=true`
    );

    if (oemResponse.status == 200) {
      return oemResponse.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};
export const getVehiclesList = async (page) => {
  try {
    const vehiclesListData = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/vehicle?current_page=${page}`
    );

    if (vehiclesListData.status == 200) {
      return vehiclesListData.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};
export const getVehiclesListById = async (id) => {
  try {
    const vehiclesListById = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/vehicle/${prefixSalt()}-${convertToBase64(
        id
      )}`
    );

    if (vehiclesListById.status == 200) {
      return vehiclesListById.data;
    }
  } catch (e) {}
};

export const verifyRc = async (rcNumber) => {
  try {
    const data = { rc_number: rcNumber };
    const response = await axiosInterceptor.post(`${apiUrl.rcVerifyUrl}`, data);

    return response;
  } catch (error) {
    throw error.response;
  }
};
