import React from 'react'
import { NotAuthorizedStyle } from './NotAuthorizedStyle'
import { Link } from 'react-router-dom/cjs/react-router-dom'

const NotAuthorized = () => {
  return (
    <NotAuthorizedStyle className="unauthorized-container">
    <div className="unauthorized-content">
      <h1>Oops! You are not authorized</h1>
      <p className="message">
        Looks like you don't have the necessary permissions to
        access this page.
      </p>
      <p className="message">
        Please contact the administrator for assistance.
      </p>
      <Link to="/dashboard">
        <button className="dashboard-button">
          Go to Dashboard
        </button>
      </Link>
    </div>
  </NotAuthorizedStyle>
  )
}

export default NotAuthorized