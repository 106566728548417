import apiUrl from "../url/apiUrl";
const CryptoJS = require("crypto-js");

export const readLoginData = () => {
  let encrypted_data = "";
  if (localStorage.getItem("LOGIN_DATA")) {
    encrypted_data = localStorage.getItem("LOGIN_DATA");
  }

  let decryptedLoginData;
  if (encrypted_data) {
    const bytes = CryptoJS.AES.decrypt(encrypted_data, apiUrl.SALT);
    decryptedLoginData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return decryptedLoginData;
  } else {
    return;
  }
};

export const readCustomerData = () => {
  let encrypted_data = "";
  if (localStorage.getItem("CUSTOMER_DATA")) {
    encrypted_data = localStorage.getItem("CUSTOMER_DATA");
  }

  let decryptedLoginData;
  if (encrypted_data) {
    const bytes = CryptoJS.AES.decrypt(encrypted_data, apiUrl.SALT);
    decryptedLoginData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return decryptedLoginData;
  } else {
    return;
  }
};

export const indexSelectedData = () => {
  let encrypted_data = "";
  if (localStorage.getItem("indexClicked")) {
    encrypted_data = localStorage.getItem("indexClicked");
  }

  let decryptedLoginData;
  if (encrypted_data) {
    //
    const bytes = CryptoJS.AES.decrypt(encrypted_data, apiUrl.SALT);
    decryptedLoginData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return decryptedLoginData;
  } else {
    return;
  }
};
const readXToken = () => {
  let encrypted_data = localStorage.getItem("x_token");

  let decryptedXtokenData;

  if (encrypted_data) {
    const bytes = CryptoJS.AES.decrypt(encrypted_data, apiUrl.SALT);
    decryptedXtokenData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  return decryptedXtokenData;
};
export const readToken = () => {
  let decryptedData = readXToken();
  if (decryptedData) {
    return decryptedData.X_TOKEN;
  } else {
    return;
  }
};
// export const TripIndexSelectedData = () => {
//   let encrypted_data = "";
//   if (localStorage.getItem("TripIndexClicked")) {
//     encrypted_data = localStorage.getItem("TripIndexClicked");
//   }

//   let decryptedLoginData;
//   if (encrypted_data) {
//     //
//     const bytes = CryptoJS.AES.decrypt(encrypted_data, apiUrl.SALT);
//     decryptedLoginData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//     //
//     return decryptedLoginData;
//   } else {
//     return;
//   }
// };
