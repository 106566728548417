import {
  React,
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import { InputAdornment } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
import { Drawer } from "@mui/material";
import loader from "../../assets/dashboard_icons/icons8-truck.gif";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Carrier from "../../assets/dashboard_icons/icon-0.8s-215px.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { onboardRegisterPost } from "../onboard/OnboardServices";
import axios from "axios";
import UploadImage from "../../assets/upload.svg";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { Component_style } from "../../themes/component_style";
import { useHistory } from "react-router-dom";
import apiUrl from "../../url/apiUrl";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { onboardData } from "../../redux/onboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { getCarrierList } from "../onboard/OnboardServices";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { oemPost, vehicleManufacturerPut } from "./OemServices";
import { getOemList } from "./OemServices";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { vehiclesRegisterPut } from "../vehicles/VehiclesServices";
import { searchManufacturer } from "../../redux/searchSlice";
import ImageComponents from "../../components/imageComponent/ImageCompoents";
import axiosInterceptor from "../../config/https";
import { colorPalette, colorPaletteDark } from "../../themes/theme";
import { ButtonRectangle } from "../../themes/components/button";
import Topbar from "../../components/topbar/Topbar.jsx";
import { DarkModeContext } from "../../context/darkModeContext.js";
import NotAuthorized from "../../components/notAuthorized/NotAuthorized.jsx";
import SomethingWentWrong from "../../components/somethingWentWrong/SomethingWentWrong.jsx";
import { Modal as ModalAntd } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { saveBlob } from "../../utility/saveBlobImage.js";

function Oem() {
  const [oem, setOem] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState("");
  const [oemResponse, setOemResponse] = useState({});
  const [oemData, setOemData] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isUserNotAuthorized, setIsUserNotAuthorized] = useState(false);

  const [errorOemName, setErrorOemName] = useState("");
  const [errorPhoto, setErrorPhoto] = useState({});

  const [id, setId] = useState("");

  const [loadingMore, setLoadingMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const searchTerm = useSelector(
    (data) => data.searchValues.searchManufacturer
  );
  const [loadingButton, setLoadingButton] = useState(false);
  // const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const [state, setState] = useState({ right: false });
  const observer = useRef();
  const [manufacturerPhoto, setManufacturerPhoto] = useState({});

  const lastItem = useCallback(
    (node) => {
      // if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage]
  );

  const handleOem = async () => {
    const isValid = formValidation();
    setLoadingButton(true);

    if (isValid) {
      setErrorOemName("");
      if (editMode) {
        const responseEdit = await vehicleManufacturerPut(oem, id);

        // dispatch(onboardData(response.data));
        if (responseEdit.status == 200) {
          setEditMode(false);
          getOemListData(1);
          setCurrentPage(1);
          setOem("");
          setSuccess("Manufacturer Updated successfully!");

          // dispatch(updateCarrierId(""));
        } else if (responseEdit.response.status == 400) {
          // setDataExistMsg(responseEdit.response.data.detail);
          // setCircularProgress(false);
        } else if (responseEdit.response.status == 401) {
          toast.error("401: Not enough permissions");
        }
      } else {
        const oemResponse = await oemPost(
          oem,
          manufacturerPhoto,
          manufacturerPhoto.type == "application/pdf" ? "pdf" : "image"
        );

        if (oemResponse == 200) {
          setSuccess("Manufacturer created successfully!");
          setErrorMsg("");
          getOemListData(1);
          setCurrentPage(1);
          setLoadingButton(false);
          // toggleDrawer("right", false);
          //   setSuccess("");
          setOem("");
          setManufacturerPhoto({});
        }
        if (oemResponse.response.status == 400) {
          setErrorMsg("Manufacturer already exist!");
          setSuccess("");
          setOem("");
          setLoadingButton(false);
        } else if (oemResponse.response.status == 401) {
          toast.error("401: Not enough permissions");
        }
      }
    }
    setLoadingButton(false);
    // if(oemResponse.state==)
  };

  const formValidation = () => {
    const errorOemName = {};
    const errorPhoto = {};
    let isValid = true;
    if (oem == "") {
      errorOemName.errorOem = "Manufacturer name is mandatory.";
      isValid = false;
    }
    if (editMode == false) {
      if (manufacturerPhoto.name == undefined) {
        errorPhoto.Photomissing = "Please Upload Logo";
        isValid = false;
      }
    }

    setErrorOemName(errorOemName);
    setErrorPhoto(errorPhoto);
    return isValid;
  };

  const getOemListData = async (current) => {
    setLoading(true);
    const oemList = await getOemList(current);
    console.log(oemList, "oem list +++===>");
    if (oemList >= 400) {
      if (oemList === 401) {
        setIsUserNotAuthorized(true);
      }
    } else if (oemList) {
      if (current > 1) {
        setOemData([...oemData, ...oemList.vehicle_manufacturer_list]);
        setIsLastPage(oemList.is_last_page);
      } else {
        setOemData(oemList.vehicle_manufacturer_list);
        setIsLastPage(oemList.is_last_page);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    if (searchTerm) {
      handleSearchOem(searchTerm, currentPage);
      dispatch(searchManufacturer(searchTerm));
    } else {
      dispatch(searchManufacturer(""));
      getOemListData(currentPage);
    }
  }, [currentPage, searchTerm]);
  useEffect(() => {
    if (searchTerm.length > 0) {
      dispatch(searchManufacturer(searchTerm));
    }
  }, [searchTerm]);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleSearchOem = async (values, page) => {
    // const value = event.target.value;
    //
    // setCurrentPage(1);
    // setSearchValue(value);

    // if (searchValue) {
    //   getOemListData(1);
    // }
    if (values && values.length >= 1) {
      try {
        // setLoading(true);
        const productResponse = await axiosInterceptor.get(
          `${apiUrl.onboardingBaseUrl
          }/v1/vehicle-manufacturer?name=${values.trim()}&current_page=${page}`
        );

        if (productResponse.data.vehicle_manufacturer_list.length > 0) {
          setOemData(productResponse.data.vehicle_manufacturer_list);
          setIsLastPage(productResponse.data.is_last_page);
        } else {
          setOemData([]);
        }

        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        setOemData([]);
      }
    }
    // else if (value.length == 0) {
    //   getOemListData(currentPage);
    // }
  };
  const handlecancelSearch = () => {
    dispatch(searchManufacturer(""));
    getOemListData(1);
    setCurrentPage(1);
  };
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const handleOpenMenu = () => {
    toggleDrawer("right", true);
    setSuccess("");
    setErrorMsg("");
    setErrorPhoto({});
    setErrorOemName("");
    setManufacturerPhoto({});
  };
  const handleCloseBtn = () => {
    toggleDrawer("right", false);
    setErrorPhoto({});
    setOem("");
    setSelectedIndex(null);
  };

  const handleSelectManufacturer = (data, index) => {
    setOem(data.name);
    setId(data.id);

    setSelectedManufacturer(data);
    setSelectedIndex(index);
    setEditMode(true);
    if (isMobile) {
      handleOpenMenu();
    }
  };

  const onFileChange = async (event) => {
    const newFile = await saveBlob(event.target.files[0]);
    setManufacturerPhoto(newFile);
    setErrorPhoto({});
    setErrorPhoto("");
  };
  const { toggle, darkMode } = useContext(DarkModeContext);

  return (
    <Component_style className="main_onboard_container" mode={darkMode}>
      <ToastContainer position="bottom-right" />
      <Drawer
        className="drawer"
        anchor={"right"}
        open={state["right"]}
        onClose={() => toggleDrawer("right", false)}
      >
        <div className="right_details_wrapper">
          <div className="oem_div" style={{ padding: "10px" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {selectedIndex !== null ? (
                <h5 style={{ color: colorPalette.primaryColor }}>
                  Update Manufacturer
                </h5>
              ) : (
                <h5 style={{ color: colorPalette.primaryColor }}>
                  Add New Manufacturer
                </h5>
              )}

              <CloseIcon onClick={handleCloseBtn} />
            </div>
            {/* <h6 style={{ textAlign: "center", fontSize: "1rem" }}>
                  Create OEM
                </h6> */}
            <div className="form_oem ">
              <h4 className="form_label_oem">
                Name
                <sup style={{ color: "red" }}>*</sup>
              </h4>

              <TextField
                id="outlined-basic"
                placeholder="Enter Manufacturer Name"
                variant="outlined"
                sx={{
                  width: "30ch",
                  height: "6ch",
                }}
                value={oem}
                color="success"
                size="small"
                onChange={(e) => {
                  setOem(e.target.value);
                  setErrorOemName("");
                }}
              />
              {Object.keys(errorOemName).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorOemName[key]}</h6>
                );
              })}
              <h4 className="form_label_oem">
                Upload Manufacturer Logo
                <sup style={{ color: "red" }}>*</sup>
              </h4>
              <TextField
                size="small"
                id="outlined-basic"
                disabled
                sx={{
                  width: "30ch",
                  height: "6ch",
                }}
                // style={{ width: "14rem" }}
                // select
                autoComplete="off"
                // className="vehicle-input-attachment dropdown-container "
                variant="outlined"
                placeholder="Upload Logo"
                name="attachment_tag"
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        cursor: "pointer",
                        color: `${colorPalette.primaryColor}`,
                        fontWeight: "600",
                      }}
                    >
                      <button className="upload_button">
                        <input
                          // name="upload"
                          name="attachment_file"
                          id="insurance"
                          type="file"
                          accept="image/png, image/jpeg, application/pdf"
                          onChange={onFileChange}
                          hidden
                          style={{
                            backgroundColor: colorPalette.secondaryWhite,
                          }}
                        />
                        <label
                          htmlFor="insurance"
                          style={{
                            backgroundColor: colorPalette.secondaryWhite,
                          }}
                        >
                          <img
                            src={UploadImage}
                            alt=""
                            width="20px"
                            height="20px"
                            style={{
                              backgroundColor: colorPalette.secondaryWhite,
                            }}
                          />
                        </label>
                      </button>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              <span style={{ fontSize: ".8rem", display: "block" }}>
                {manufacturerPhoto ? manufacturerPhoto.name : null}
              </span>
              {Object.keys(errorPhoto).map((key) => {
                return (
                  <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                    {errorPhoto[key]}
                  </h6>
                );
              })}
              {manufacturerPhoto ? (
                manufacturerPhoto.size > process.env.REACT_APP_MAX_FILE_SIZE ? (
                  <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                    Please upload file size less than {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
                  </h6>
                ) : null
              ) : null}
              {(manufacturerPhoto
                ? manufacturerPhoto.size < process.env.REACT_APP_MAX_FILE_SIZE
                : null) || editMode == true ? (
                <ButtonRectangle
                  // className="oem_btn"
                  onClick={handleOem}
                  style={{ marginTop: "20px" }}
                >
                  {loadingButton ? (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  ) : selectedIndex !== null ? (
                    "UPDATE MANUFACTURER"
                  ) : (
                    "ADD MANUFACTURER"
                  )}{" "}
                </ButtonRectangle>
              ) : (
                <ButtonRectangle
                  // className="oem_btn"
                  onClick={handleOem}
                  style={{
                    marginTop: "20px",
                    backgroundColor: "white",
                    border: "1px solid black",
                    color: "black",
                  }}
                >
                  {loadingButton ? (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  ) : selectedIndex !== null ? (
                    "UPDATE MANUFACTURER"
                  ) : (
                    "ADD MANUFACTURER"
                  )}{" "}
                </ButtonRectangle>
              )}
              {/* <button
                className="oem_btn"
                onClick={handleOem}
                style={{ marginTop: "1rem" }}
              >
                {
                {loadingButton ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : selectedIndex !== null ? (
                  "UPDATE MANUFACTURER"
                ) : (
                  "ADD MANUFACTURER"
                )}
              </button> */}
              {success.length > 0 ? (
                <h6
                  style={{
                    marginTop: "1rem",
                    color: colorPalette.primaryColor,
                  }}
                >
                  <Alert severity="success">{success}</Alert>
                </h6>
              ) : errorMsg.length > 0 ? (
                <h6 style={{ marginTop: "1rem", color: "red" }}>
                  <Alert severity="error">{errorMsg}</Alert>
                  {/* {errorMsg} */}
                </h6>
              ) : null}
            </div>
          </div>
        </div>
      </Drawer>
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={9999999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong backButtonLink="/dashboard" />
        </div>
      </ModalAntd>
      <div className="main_onboard_wrapper">
        {isMobile ? (
          <div className="trip-list-mobile-header">
            <MobileHeader headerTitle="Vehicles Manufacturers" />
          </div>
        ) : (
          <div className="main_onboard_Left_box">
            <SideBarMain />
          </div>
        )}
        <div className="main_onboard_Right_box">
          {isMobile ? null : <Topbar />}
          <div className="progress_container_details">
            <div className="Left_details_wrapper">
              <div className="search_container">
                <div className="search_div" id="search_div_onboard">
                  <input
                    type="text"
                    name=""
                    id="search_input_onboard"
                    onChange={(event) => {
                      dispatch(searchManufacturer(event.target.value));
                      setCurrentPage(1);
                    }}
                    value={searchTerm}
                    placeholder="search"
                    className="search_input"
                  />
                  {searchTerm && !oemData.length == 0 ? (
                    <CloseIcon
                      onClick={handlecancelSearch}
                      style={{ color: "grey", cursor: "pointer" }}
                    />
                  ) : (
                    <SearchIcon style={{ color: "grey", cursor: "pointer" }} />
                  )}
                  {/* <SearchIcon style={{ color: "grey", cursor: "pointer" }} /> */}
                </div>
              </div>
              {isMobile ? (
                <>
                  {/* <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    > */}
                  <div className="onboard_btnDiv">
                    <button
                      onClick={handleOpenMenu}
                      className="add_manufacturer"
                    >
                      Create
                    </button>
                  </div>
                  <hr style={{ marginTop: ".4rem", color: "grey" }} />
                </>
              ) : null}
              {isMobile ? (
                <div className="card_container">
                  {isUserNotAuthorized ? (
                    <NotAuthorized />
                  ) : oemData.length !== 0 ? (
                    <>
                      {oemData.map((data, index) => (
                        <>
                          {" "}
                          <div
                            className="card"
                            key={index}
                          // onClick={() =>
                          //   handleSelectManufacturer(data, index)
                          // }
                          >
                            <div className="content_div_mobile">
                              <div className="name">
                                {/* <h6 className="capacity_text">Name:</h6> */}
                                {oemData.length == index + 1 ? (
                                  <h6 className="name_text" ref={lastItem}>
                                    {" "}
                                    {data.name}
                                  </h6>
                                ) : (
                                  <h6 className="name_text"> {data.name}</h6>
                                )}
                              </div>
                            </div>
                            {isMobile ? (
                              // <div className="button_div">
                              <div
                                style={{
                                  display: "flex",
                                  // flexDirection: "column",
                                  height: "100%",
                                  alignItem: "center",
                                  justifyContent: "space-around",
                                }}
                              >
                                <EditIcon
                                  style={{ margin: "0 auto" }}
                                  onClick={() =>
                                    handleSelectManufacturer(data, index)
                                  }
                                />
                                <NavLink
                                  to={`/vehicles-manufacturers/oemdetails/${data.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <h6
                                    style={{
                                      marginRight: ".2rem",
                                      marginLeft: ".8rem",
                                      padding: ".3rem .7rem",
                                      color: "white",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    {/* Details{" "} */}
                                    <ArrowForwardIosIcon
                                      style={{
                                        fontSize: ".9rem",
                                        fontWeight: "bolder",
                                        color: "green",
                                        // marginLeft: ".4rem",
                                      }}
                                    />
                                  </h6>
                                </NavLink>
                              </div>
                            ) : (
                              // </div>
                              // </div>
                              <div className="button_div">
                                <div>
                                  <NavLink
                                    style={{ textDecoration: "none" }}
                                    to={`/vehicles-manufacturers/oemdetails/${data.id}`}
                                  >
                                    <h6 className="btn">
                                      Details{" "}
                                      <ArrowForwardIosIcon
                                        style={{
                                          fontSize: ".8rem",
                                          marginLeft: ".4rem",
                                        }}
                                      />
                                    </h6>
                                  </NavLink>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ))}
                    </>
                  ) : oemData.length !== 0 ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "200%",
                        marginLeft: "6rem",
                      }}
                    >
                      <h4
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginLeft: "10%",
                          fontSize: "1rem",
                          color: darkMode ? "white" : "",
                        }}
                      >
                        No Manufacturer Found!
                      </h4>
                    </div>
                  ) : loading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        // backgroundColor: "grey",
                      }}
                    >
                      <Box sx={{ width: 600, height: 500 }}>
                        <Skeleton
                          style={{ height: "150px" }}
                          animation="wave"
                        />
                        <Skeleton
                          style={{ height: "150px" }}
                          animation="wave"
                        />
                        <Skeleton
                          style={{ height: "150px" }}
                          animation="wave"
                        />
                        {/* <Skeleton animation="wave" /> */}
                        {/* <Skeleton animation={false} /> */}
                      </Box>{" "}
                      {/* <img
                      src={loader}
                      alt=""
                      width="120px"
                      height="90px"
                      style={{ marginTop: "200px" }}
                    />
                    <h6 style={{ color: "grey" }}>loading data...</h6> */}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h6 style={{ color: darkMode ? "white" : "" }}>
                        {" "}
                        No Manufacturer Found!
                      </h6>
                    </div>
                  )}
                </div>
              ) : (
                <div className="card_container_manufacturer">
                  {isUserNotAuthorized ? (
                    <div style={{ width: "50vw" }}>
                      <NotAuthorized />
                    </div>
                  ) : oemData.length !== 0 ? (
                    <>
                      {oemData.map((data, index) => (
                        <>
                          {" "}
                          <div
                            className="card_manufacturer"
                            style={
                              index === selectedIndex
                                ? { border: "2px solid #016938" }
                                : null
                            }
                            onClick={() =>
                              handleSelectManufacturer(data, index)
                            }
                            key={index}
                          >
                            {
                              isMobile
                                ? null
                                : // <div className="image_div">
                                //   <img src={Carrier} alt="" />
                                // </div>
                                null
                              // <div className="image_div_manufacturer">
                              //   <ImageComponents
                              //     imageId={data.id}
                              //     source="vendorsList"
                              //     name={data.name}
                              //   />
                              // </div>
                            }

                            {/* <div className="content_div_manufacturer">
                              <div className="name">
                       
                                {oemData.length == index + 1 ? (
                                  <h6 className="name_text" ref={lastItem}>
                                    {" "}
                                    {data.name}
                                  </h6>
                                ) : (
                                  <h6 className="name_text"> {data.name}</h6>
                                )}
                              </div>
                            </div> */}
                            {isMobile ? (
                              // <div className="button_div">
                              <div>
                                <NavLink
                                  to={`/vehicles-manufacturers/oemdetails/${data.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <h6
                                    style={{
                                      marginRight: ".5rem",
                                      backgroundColor:
                                        colorPalette.primaryColor,
                                      padding: ".5rem .8rem",
                                      color: "white",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    Details{" "}
                                    <ArrowForwardIosIcon
                                      style={{
                                        fontSize: "1rem",
                                        // marginLeft: ".4rem",
                                      }}
                                    />
                                  </h6>
                                </NavLink>
                              </div>
                            ) : (
                              // </div>
                              // </div>
                              <div className="button_div_manufacturer">
                                <div className="name" style={{ width: "100%" }}>
                                  {oemData.length == index + 1 ? (
                                    <h6
                                      className="name_text_manufacturer"
                                      ref={lastItem}
                                    >
                                      {data.name}
                                    </h6>
                                  ) : (
                                    <h6 className="name_text_manufacturer">
                                      {" "}
                                      {data.name}
                                    </h6>
                                  )}
                                </div>
                                <div>
                                  <NavLink
                                    style={{ textDecoration: "none" }}
                                    to={`/vehicles-manufacturers/oemdetails/${data.id}`}
                                  >
                                    <h6 className="btn_manufacturer">
                                      <ArrowForwardIcon
                                        style={{
                                          fontSize: "25px",
                                          fontWeight: "bolder",
                                          // width: "2rem",s
                                          marginLeft: "1rem",
                                        }}
                                      />
                                      {/* <ArrowForwardIosIcon
                                        style={{
                                          fontSize: ".8rem",
                                          marginLeft: ".4rem",
                                        }} */}
                                    </h6>
                                  </NavLink>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ))}
                    </>
                  ) : oemData.length == 0 && !loading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "200%",
                        marginLeft: "6rem",
                      }}
                    >
                      <h4
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginLeft: "10%",
                          fontSize: "1rem",
                          color: darkMode ? "white" : "",
                        }}
                      >
                        No Manufacturer Found!
                      </h4>
                    </div>
                  ) : loading && oemData.length == 0 ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: "100%",
                        // backgroundColor: "grey",
                      }}
                    >
                      <Box sx={{ width: 600, height: 500 }}>
                        <Skeleton
                          style={{ height: "150px" }}
                          animation="wave"
                        />
                        <Skeleton
                          style={{ height: "150px" }}
                          animation="wave"
                        />
                        <Skeleton
                          style={{ height: "150px" }}
                          animation="wave"
                        />
                        {/* <Skeleton animation="wave" /> */}
                        {/* <Skeleton animation={false} /> */}
                      </Box>{" "}
                      {/* <img
                    src={loader}
                    alt=""
                    width="120px"
                    height="90px"
                    style={{ marginTop: "200px" }}
                  />
                  <h6 style={{ color: "grey" }}>loading data...</h6> */}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "200%",
                      }}
                    >
                      <h6
                        style={{
                          textAlign: "center",
                          width: "100%",
                          marginLeft: "10%",
                          fontSize: ".6rem",
                          color: `${darkMode ? "white" : "black"}`,
                        }}
                      >
                        No data found,Please add one.
                      </h6>
                    </div>
                  )}
                </div>
              )}
            </div>
            {isMobile ? null : (
              <div className="right_details_wrapper">
                <div className="oem_div">
                  {/* <h6 style={{ textAlign: "center", fontSize: "1rem" }}>
                  Create OEM
                </h6> */}
                  <div className="form_oem">
                    <h4 className="form_label_oem">
                      Name
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      id="outlined-basic"
                      placeholder="Enter Manufacturer Name"
                      variant="outlined"
                      sx={{
                        width: "100%",
                        height: "4ch",
                        color: darkMode
                          ? colorPaletteDark.white
                          : colorPalette.white,
                        border: "1px solid white",
                      }}
                      value={oem}
                      color="success"
                      size="small"
                      onChange={(e) => {
                        setOem(e.target.value);
                        setErrorOemName("");
                      }}
                    />
                    {Object.keys(errorOemName).map((key) => {
                      return (
                        <h6 style={{ color: "#E06666" }}>
                          {errorOemName[key]}
                        </h6>
                      );
                    })}
                    <h4
                      className="form_label_oem"
                      style={{ marginTop: "20px" }}
                    >
                      Upload Manufacturer Logo
                      <sup style={{ color: "red" }}>*</sup>
                    </h4>

                    <TextField
                      size="small"
                      id="outlined-basic"
                      disabled
                      sx={{
                        width: "100%",
                        height: "4ch",
                        border: "1px solid white",
                      }}
                      // style={{ width: "14rem" }}
                      // select
                      autoComplete="off"
                      // className="vehicle-input-attachment dropdown-container "
                      variant="outlined"
                      placeholder="Upload Logo"
                      name="attachment_tag"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: colorPalette.primaryColor,
                              fontWeight: "600",
                            }}
                          >
                            <button className="upload_button">
                              <input
                                // name="upload"
                                name="attachment_file"
                                id="insurance"
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={onFileChange}
                                hidden
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              />
                              <label
                                htmlFor="insurance"
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                }}
                              >
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                  style={{
                                    backgroundColor:
                                      colorPalette.secondaryWhite,
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    ></TextField>
                    <span
                      style={{
                        fontSize: ".8rem",
                        display: "block",
                        marginBottom: "1rem",
                        color: `${darkMode ? "white" : "black"}`,
                        // marginTop: "-6px",
                      }}
                    >
                      {manufacturerPhoto ? manufacturerPhoto.name : null}
                    </span>
                    {Object.keys(errorPhoto).map((key) => {
                      return (
                        <h6
                          style={{
                            color: "#E06666",
                            marginBottom: "1rem",
                            marginTop: "-10px",
                          }}
                        >
                          {errorPhoto[key]}
                        </h6>
                      );
                    })}
                    {manufacturerPhoto ? (
                      manufacturerPhoto.size >
                        process.env.REACT_APP_MAX_FILE_SIZE ? (
                        <h6 style={{ color: "#E06666", marginBottom: ".8rem" }}>
                          Please upload file size less than {process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB.
                        </h6>
                      ) : null
                    ) : null}
                    {(manufacturerPhoto
                      ? manufacturerPhoto.size <
                      process.env.REACT_APP_MAX_FILE_SIZE
                      : null) || editMode == true ? (
                      <ButtonRectangle
                        // className="oem_btn"
                        onClick={handleOem}
                        style={{
                          marginTop: "20px",
                          width: "100%",
                          height: "40px",
                        }}
                      >
                        {loadingButton ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "white" }}
                          />
                        ) : selectedIndex !== null ? (
                          "UPDATE MANUFACTURER"
                        ) : (
                          "ADD MANUFACTURER"
                        )}{" "}
                      </ButtonRectangle>
                    ) : (
                      <ButtonRectangle
                        // className="oem_btn"
                        onClick={handleOem}
                        style={{
                          marginTop: "20px",
                          backgroundColor: colorPalette.secondaryGreen,
                          border: "1px solid transparent",
                          color: colorPalette.white,
                          width: "100%",
                          height: "40px",
                        }}
                      >
                        {loadingButton ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "white" }}
                          />
                        ) : selectedIndex !== null ? (
                          "UPDATE MANUFACTURER"
                        ) : (
                          "ADD MANUFACTURER"
                        )}{" "}
                      </ButtonRectangle>
                    )}

                    {success.length > 0 ? (
                      <h6
                        style={{
                          marginTop: "1rem",
                          color: colorPalette.primaryColor,
                        }}
                      >
                        <Alert severity="success">{success}</Alert>
                      </h6>
                    ) : errorMsg.length > 0 ? (
                      <h6 style={{ marginTop: "1rem", color: "red" }}>
                        <Alert severity="error">{errorMsg}</Alert>
                        {/* {errorMsg} */}
                      </h6>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Component_style>
  );
}

export default Oem;
