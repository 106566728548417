import React from "react";
import apiUrl from "../../url/apiUrl.js";
import axios from "axios";
import axiosInterceptor from "../../config/https.js";

export const MonthlyPaymentRecordPost = (
  payment_file,
  entity_id,
  entity_type,
  trip_month,
  payment_made_by_cash,
  amount,
  transaction_ref,
  image
) => {
  let data = new FormData();
  if (payment_file) {
    data.append("file", payment_file);
  }
  data.append("entity_id", `${entity_id}`);
  data.append("entity_type", `${entity_type}`);
  data.append("trip_month", `${trip_month}`);
  data.append("payment_made_by_cash", payment_made_by_cash);
  data.append("amount", `${amount}`);
  data.append("transaction_reference_id", `${transaction_ref}`);
  data.append("image", image);

  var config = {
    method: "post",
    url: `${apiUrl.onboardingBaseUrl}/v1/trip-payment/generic-payment`,
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        // if (error.response.status === 403) {
        //   localStorage.clear();
        //   window.location.reload();
        // }
        resolve(error.response);
      });
  });
};

export const getMonthlyPaymentRecords = async (vNumber, tMonth) => {
  try {
    const paymentResponse = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/trip-payment/vehicle-payment?vehicle_number=${vNumber}&trip_month=${tMonth}`
    );

    if (paymentResponse.status == 200) {
      return paymentResponse;
    }
  } catch (e) {
    // if (e.response.status === 403) {
    //   localStorage.clear();
    //   window.location.reload();
    // }

    return e.response.status;
  }
};
