import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../themes/theme";

export const PageNotFoundStyle = styled.div`
  /* .pagenotfound-main { */
  display: flex;
  width: 100vw;
  height: 100vh;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  /* } */
  .text-container {
    width: 40%;
  }
  .errorpage-text-container {
    padding: 0rem 2rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .errorpage-text {
    font-size: 2.5rem;
    font-weight: ${FontWeight.bold};
    width: 100%;
    padding: 2rem 0rem;
    /* text-align: ce; */

    /* line-height: 2rem; */
    color: ${colorPalette.paragraphSecondaryolor};
  }
  .errorpage-text-subheading {
    font-size: ${typography.heading4};
    font-weight: ${FontWeight.bold};
    width: 100%;
    /* text-align: center; */
    padding: 0rem 0rem;
    /* line-height: 2rem; */
    color: ${colorPalette.paragraphColor};
  }
  .image-main-conatiner {
    display: block;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* background-color: ${colorPalette.primaryColor}; */
  }
  .gohome-button {
    border: 2px solid ${colorPalette.primaryColor};
    border-radius: 10px;
    background-color: ${colorPalette.primaryColor};
    padding: 0.4rem 2rem;
    color: white;

    /* background-color: white; */
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
  }
  .errorpage-buttondiv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem 2rem;
  }
  @media only screen and (max-width: 499px) {
    /* .pagenotfound-main { */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* } */
    .image-main-conatiner {
      display: none;
      width: 60%;
      height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      /* background-color: ${colorPalette.primaryColor}; */
    }
    .errorpage-text-container {
      padding: 0rem 0rem;
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    .errorpage-text {
      font-size: ${typography.heading2};
      font-weight: ${FontWeight.bold};
      width: 100%;
      text-align: center;
      padding: 2rem 0rem;
      /* text-align: ce; */

      /* line-height: 2rem; */
      color: ${colorPalette.paragraphSecondaryolor};
    }
    .errorpage-text-subheading {
      font-size: ${typography.paragraph};
      font-weight: ${FontWeight.bold};
      width: 100%;
      text-align: center;
      padding: 0rem 0rem;
      /* line-height: 2rem; */
      color: ${colorPalette.paragraphColor};
    }
    .text-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 50%;
    }
  }
`;
