import {
  React,
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import * as moment from "moment";
import { Drawer } from "@mui/material";
import FILTER_ICON from "../../assets/filter_icon.svg";

import monthly_icons from "../../assets/monthly_icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Carrier from "../../assets/dashboard_icons/icon-0.8s-215px.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { onboardRegisterPost } from "../onboard/OnboardServices";
import axios from "axios";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
// import { useHistory } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { Component_style } from "../../themes/component_style";
// import "./vehicles.css";
import { useHistory } from "react-router-dom";
import apiUrl from "../../url/apiUrl";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import {
  updateRoutesAddVehicle,
  updateVehicleId,
} from "../../redux/onboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { getCarrierList } from "../onboard/OnboardServices";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { getVehiclesList } from "./VehiclesServices";
import { updateCarrierId } from "../../redux/onboardSlice";
import MiniDetailsCard from "../../components/miniDetailsCard/MiniDetailsCard";
import CarrierMiniCard from "../../components/carrierMiniCard/CarrierMiniCard.jsx";
import MiniVehiclesCard from "../../components/vehiclesMiniCard/MiniVehiclesCard";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { searchVehicles } from "../../redux/searchSlice";
import MONTHLYICON from "../../assets/monthly_icon.svg";
import TRIPWISEICON from "../../assets/trip_wise_icon.svg";
import TICKICON from "../../assets/green_tick.svg";
import WRONGICON from "../../assets/wrong.svg";
import { ModalUnstyled } from "@mui/base";
import { styled } from "@mui/system";
import CopyText from "../../components/copyText/CopyText";
import axiosInterceptor from "../../config/https";
import ImageComponents from "../../components/imageComponent/ImageCompoents";
import CommonDrawer from "../../components/commonDrawer/CommonDrawer.jsx";
import { VehicleListStyle } from "./VehicleListStyle";
import { colorPalette, colorPaletteDark } from "../../themes/theme";
import Topbar from "../../components/topbar/Topbar.jsx";
import { DarkModeContext } from "../../context/darkModeContext.js";
import { Modal as ModalAntd } from "antd";
import {
  ButtonPrimary2,
  ButtonPrimary3,
  ButtonRectangle,
  ButtonRectangleSecondary,
} from "../../themes/components/button.js";
import NotAuthorized from "../../components/notAuthorized/NotAuthorized.jsx";
import SomethingWentWrong from "../../components/somethingWentWrong/SomethingWentWrong.jsx";
import { useDebounce } from "../../hooks/useDebounce.js";

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

const filterBoxStyle = {
  width: isMobile ? "90vw" : "36%",
  height: isMobile ? "auto" : "65%",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -34,
  marginTop: isMobile ? -10 : -20,
  border: "1px solid gray",
  borderRadius: "10px",
};

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${isMobile ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.5)`};
  -webkit-tap-highlight-color: transparent;
`;

function VehiclesList() {
  const { toggle, darkMode } = useContext(DarkModeContext);

  const [value, setValue] = useState(0);
  const vehicleStatus = ["available", "in_transit", "blocked"];

  const [carrierListData, setCarrierListData] = useState([]);
  const [vehiclesListData, setVehiclesListData] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState({});
  const [cardClicked, setCardClicked] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    localStorage.getItem("selectedIndex")
      ? localStorage.getItem("selectedIndex")
      : 10
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [commonDrawerOpen, setCommonDrawerOpen] = useState(false);
  const [isUserNotAuthorized, setIsUserNotAuthorized] = useState(false);
  const [vehicleType, setVehicleType] = useState(
    localStorage.getItem("selectedTransporter")
      ? localStorage.getItem("selectedTransporter")
      : ""
  );
  // const [searchValue, setSearchValue] = useState("");
  const [state, setState] = useState({ right: false });

  const searchTerm = useSelector((data) => data.searchValues.searchVehicles);
  const deboucedSearchTerm = useDebounce(searchTerm);
  const dispatch = useDispatch();

  const observer = useRef();
  const lastItem = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage]
  );

  let history = useHistory();

  const getExpireStatus = (vehicleList) => {
    const dateToday = moment().format("YYYY-MM-DD");
  };

  const handleOpenFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleCloseFilterOptions = () => {
    setIsFilterOpen(false);
  };

  const getVehicleLists = async (current) => {
    setLoadingMore(true);
    const vehicleResponse = await getVehiclesList(current);

    if (current > 1) {
      setVehiclesListData([
        ...vehiclesListData,
        ...vehicleResponse.vehicle_list.map((vehicle) => {
          const dateToday = moment().format("YYYY-MM-DD");
          let startDate = moment(dateToday, "YYYY-MM-DD");
          let expireDateRC = moment(
            vehicle.registration_certificate_expiry_date,
            "YYYY-MM-DD"
          );
          let expireDateInsurance = moment(
            vehicle.insurance_certificate_expiry_date,
            "YYYY-MM-DD"
          );
          let expireDatePermit = moment(
            vehicle.permit_expiry_date,
            "YYYY-MM-DD"
          );
          let expireDatePollution = moment(
            vehicle.pollution_control_certificate_expiry_date,
            "YYYY-MM-DD"
          );

          return {
            ...vehicle,
            insuranceExpires: expireDateInsurance.diff(startDate, "days"),
            permitExpires: expireDatePermit.diff(startDate, "days"),
            pollutionExpires: expireDatePollution.diff(startDate, "days"),
            rcExpires: expireDateRC.diff(startDate, "days"),
          };
        }),
      ]);
      setIsLastPage(vehicleResponse.is_last_page);
    } else {
      setVehiclesListData(
        vehicleResponse.vehicle_list.map((vehicle) => {
          const dateToday = moment().format("YYYY-MM-DD");

          let startDate = moment(dateToday, "YYYY-MM-DD");
          let expireDateRC = moment(
            vehicle.registration_certificate_expiry_date,
            "YYYY-MM-DD"
          );
          let expireDateInsurance = moment(
            vehicle.insurance_certificate_expiry_date,
            "YYYY-MM-DD"
          );
          let expireDatePermit = moment(
            vehicle.permit_expiry_date,
            "YYYY-MM-DD"
          );
          let expireDatePollution = moment(
            vehicle.pollution_control_certificate_expiry_date,
            "YYYY-MM-DD"
          );

          return {
            ...vehicle,
            insuranceExpires: expireDateInsurance.diff(startDate, "days"),
            permitExpires: expireDatePermit.diff(startDate, "days"),
            pollutionExpires: expireDatePollution.diff(startDate, "days"),
            rcExpires: expireDateRC.diff(startDate, "days"),
          };
        })
      );
      setIsLastPage(vehicleResponse.is_last_page);
      getExpireStatus(vehicleResponse.vehicle_list);
    }
    setLoadingMore(false);
  };

  // useEffect(() => {
  //   // if (searchTerm) {
  //   //   handleSearchVehicle(searchTerm, currentPage);
  //   //   dispatch(searchVehicles(searchTerm));
  //   // } else {
  //   getVehicleLists(currentPage);
  //   // }
  // }, [currentPage, searchTerm]);
  //

  const handleSelectCard = (vehicles, index) => {
    handleOpenCommonDrawer();

    setSelectedVehicles(vehicles);
    setCardClicked(index);
    if (isMobile) {
      handleOpenMenu();
    }
  };
  // const handleAddVehicle = (event, id) => {
  //   event.stopPropagation();
  //   history.push("/vehicles-onboard");
  //   dispatch(updateCarrierId(id));
  // };

  useEffect(() => {
    if (isMobile !== true) {
      if (vehiclesListData.length > 0) {
        // handleSelectCard(vehiclesListData[0]);
      }
      setCardClicked(0);
    } else {
      return;
    }
  }, [vehiclesListData]);

  const handleVehicleId = (id, carrier) => {
    dispatch(updateVehicleId({ id: id, carrier: carrier }));
    history.push(`/vehicles-onboard`);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchVehicle = async (values, page) => {
    // const value = event.target.value;

    // setCurrentPage(1);
    // setSearchValue(values);

    // if (searchValue) {
    //   getVehicleLists(1);
    // }
    if (values && values.length >= 1) {
      try {
        setLoadingMore(true);
        const productResponse = await axiosInterceptor.get(
          `${
            apiUrl.onboardingBaseUrl
          }/v1/vehicle?vehicle_number=${values.trim()}&current_page=${page}`
        );

        if (productResponse) {
          if (page > 1) {
            setVehiclesListData([
              ...vehiclesListData,
              ...productResponse.data.vehicle_list.map((vehicle) => {
                const dateToday = moment().format("YYYY-MM-DD");
                let startDate = moment(dateToday, "YYYY-MM-DD");
                let expireDateRC = moment(
                  vehicle.registration_certificate_expiry_date,
                  "YYYY-MM-DD"
                );
                let expireDateInsurance = moment(
                  vehicle.insurance_certificate_expiry_date,
                  "YYYY-MM-DD"
                );
                let expireDatePermit = moment(
                  vehicle.permit_expiry_date,
                  "YYYY-MM-DD"
                );
                let expireDatePollution = moment(
                  vehicle.pollution_control_certificate_expiry_date,
                  "YYYY-MM-DD"
                );

                return {
                  ...vehicle,
                  insuranceExpires: expireDateInsurance.diff(startDate, "days"),
                  permitExpires: expireDatePermit.diff(startDate, "days"),
                  pollutionExpires: expireDatePollution.diff(startDate, "days"),
                  rcExpires: expireDateRC.diff(startDate, "days"),
                };
              }),
            ]);
            setIsLastPage(productResponse.data.is_last_page);
          } else {
            setVehiclesListData(
              productResponse.data.vehicle_list.map((vehicle) => {
                const dateToday = moment().format("YYYY-MM-DD");
                let startDate = moment(dateToday, "YYYY-MM-DD");
                let expireDateRC = moment(
                  vehicle.registration_certificate_expiry_date,
                  "YYYY-MM-DD"
                );
                let expireDateInsurance = moment(
                  vehicle.insurance_certificate_expiry_date,
                  "YYYY-MM-DD"
                );
                let expireDatePermit = moment(
                  vehicle.permit_expiry_date,
                  "YYYY-MM-DD"
                );
                let expireDatePollution = moment(
                  vehicle.pollution_control_certificate_expiry_date,
                  "YYYY-MM-DD"
                );

                return {
                  ...vehicle,
                  insuranceExpires: expireDateInsurance.diff(startDate, "days"),
                  permitExpires: expireDatePermit.diff(startDate, "days"),
                  pollutionExpires: expireDatePollution.diff(startDate, "days"),
                  rcExpires: expireDateRC.diff(startDate, "days"),
                };
              })
            );
            setIsLastPage(productResponse.data.is_last_page);
          }
        } else {
          setVehiclesListData([]);
        }
        setLoadingMore(false);
      } catch (error) {
        // setLoading(false);
        setVehiclesListData([]);
      }
    }
    // else if (value.length == 0) {
    //   getCarrierData(currentPage);
    // }
    setLoadingMore(false);
  };
  const handlecancelSearch = () => {
    dispatch(searchVehicles(""));
    getVehicleLists(1);
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const handleOpenMenu = () => {
    toggleDrawer("right", true);
    // setSuccess("");
    // setErrorMsg("");
  };
  const handleCloseBtn = () => {
    toggleDrawer("right", false);
  };

  const handleSearchFilter = async (status, vehicle, page) => {
    setLoadingMore(true);
    let appendUrl = "";
    if (status) {
      appendUrl = appendUrl + `&status=${status}`;
    }
    if (vehicle) {
      appendUrl = appendUrl + `&vehicle_number=${vehicle}`;
    }
    // if (values && values.length >= 3) {
    try {
      // setLoading(true);
      const productResponse = await axiosInterceptor.get(
        `${apiUrl.onboardingBaseUrl}/v1/vehicle?current_page=${page}${appendUrl}`
      );
      // if (vehicle && !status) {
      //   handleClickVehicleType(10, "");
      //   setCurrentPage(1);
      // }
      // console.log(productResponse.status, "product response ====>")
      if (productResponse.status === 401) {
        setIsUserNotAuthorized(true);
      }
      if (productResponse) {
        if (page > 1) {
          setVehiclesListData([
            ...vehiclesListData,
            ...productResponse.data.vehicle_list.map((vehicle) => {
              const dateToday = moment().format("YYYY-MM-DD");
              let startDate = moment(dateToday, "YYYY-MM-DD");
              let expireDateRC = moment(
                vehicle.registration_certificate_expiry_date,
                "YYYY-MM-DD"
              );
              let expireDateInsurance = moment(
                vehicle.insurance_certificate_expiry_date,
                "YYYY-MM-DD"
              );
              let expireDatePermit = moment(
                vehicle.permit_expiry_date,
                "YYYY-MM-DD"
              );
              let expireDatePollution = moment(
                vehicle.pollution_control_certificate_expiry_date,
                "YYYY-MM-DD"
              );

              return {
                ...vehicle,
                insuranceExpires: expireDateInsurance.diff(startDate, "days"),
                permitExpires: expireDatePermit.diff(startDate, "days"),
                pollutionExpires: expireDatePollution.diff(startDate, "days"),
                rcExpires: expireDateRC.diff(startDate, "days"),
              };
            }),
          ]);
          setIsLastPage(productResponse.data.is_last_page);
        } else {
          setVehiclesListData(
            productResponse.data.vehicle_list.map((vehicle) => {
              const dateToday = moment().format("YYYY-MM-DD");
              let startDate = moment(dateToday, "YYYY-MM-DD");
              let expireDateRC = moment(
                vehicle.registration_certificate_expiry_date,
                "YYYY-MM-DD"
              );
              let expireDateInsurance = moment(
                vehicle.insurance_certificate_expiry_date,
                "YYYY-MM-DD"
              );
              let expireDatePermit = moment(
                vehicle.permit_expiry_date,
                "YYYY-MM-DD"
              );
              let expireDatePollution = moment(
                vehicle.pollution_control_certificate_expiry_date,
                "YYYY-MM-DD"
              );

              return {
                ...vehicle,
                insuranceExpires: expireDateInsurance.diff(startDate, "days"),
                permitExpires: expireDatePermit.diff(startDate, "days"),
                pollutionExpires: expireDatePollution.diff(startDate, "days"),
                rcExpires: expireDateRC.diff(startDate, "days"),
              };
            })
          );
          setIsLastPage(productResponse.data.is_last_page);
        }
      } else {
        setVehiclesListData([]);
      }
      // if (productResponse.data.transporter_list.length > 0) {
      //   setCarrierListData(productResponse.data.transporter_list);
      //   setIsLastPage(productResponse.data.is_last_page);
      //   // setLoadingMore(t);
      //   handleCloseFilterOptions();
      //   setFilterApplied("filter applied");
      // } else {
      //   setCarrierListData([]);
      //   handleCloseFilterOptions();
      //   setLoadingMore(false);
      // }
    } catch (error) {
      //
      if (error.response.status) {
        localStorage.clear();
        window.location.reload();
      }
      // setCarrierListData([]);
      setLoadingMore(false);
    }
    setLoadingMore(false);
    // }
  };
  useEffect(() => {
    // if()
    handleSearchFilter(vehicleType, deboucedSearchTerm, currentPage);
  }, [vehicleType, deboucedSearchTerm, currentPage]);
  const handleClickVehicleType = (index, tranporterName) => {
    setSelectedIndex(index);
    setVehicleType(tranporterName);
  };
  function capitalizeLetter(str) {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags;
  }
  const handleOpenCommonDrawer = () => {
    setCommonDrawerOpen(true);
  };
  const handleCloseCommonDrawer = () => {
    setCommonDrawerOpen(false);
  };
  useEffect(() => {
    if (!localStorage.getItem("selectedTransporter")) {
      handleClickVehicleType(10, "");
    }
    handleClickVehicleType(selectedIndex, vehicleType);
  }, []);
  const handleAddVehicle = (ids) => {
    history.push(`/vehicles-onboard`);
    dispatch(updateCarrierId(ids));
    dispatch(updateVehicleId({ carrier: ids }));
    // dispatch(updateRoutesAddVehicle("from_carrier_add_vehicle"));
  };
  return (
    <VehicleListStyle className="main_onboard_container" mode={darkMode}>
      {/*Filter modal starts*/}
      <StyledModal
        open={isFilterOpen}
        onClose={handleCloseFilterOptions}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={filterBoxStyle}>
          <div className="trip-list-filter-main">
            {isMobile ? (
              <div className="trip-list-filter-head">
                <span className="trip-list-filter-main-text">
                  Select filter options
                </span>{" "}
                <CloseIcon onClick={handleCloseFilterOptions} />
              </div>
            ) : (
              <div className="trip-list-filter-head">
                <span
                  className="trip-list-filter-main-text"
                  style={{ marginLeft: "5px" }}
                >
                  Select filter options
                </span>{" "}
                <CloseIcon
                  onClick={handleCloseFilterOptions}
                  style={{ cursor: "pointer" }}
                />
              </div>
            )}

            <div className="trip-list-filter-container">
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Trip code</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip code"
                    className="trip-list-filter-inputfield"
                    // value={filterTripCode}
                    // onChange={(e) => setFilterTripCode(e.target.value)}
                    variant="standard"
                  />
                </div>
              </div>

              <div className="trip-list-filter-button-row">
                <button
                  className="trip-list-filter-button"
                  // onClick={handleClearFilter}
                >
                  Clear
                </button>
                <button
                  className="trip-list-filter-button"
                  onClick={() => {
                    // handleFilterSearch(1);
                    setCurrentPage(1);
                  }}
                >
                  {/* {loadingFilterSearchButton ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Search"
                  )} */}
                </button>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>

      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={9999999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong backButtonLink="/dashboard" />
        </div>
      </ModalAntd>
      {/*Filter modal ends*/}
      {/* <Drawer
        className="drawer"
        anchor={"right"}
        open={state["right"]}
        onClose={() => toggleDrawer("right", false)}
      >
        {" "}
        <div className="right_details_wrapper">
          <div
            style={{
              display: "flex",
              width: "100%",
              // marginTop: ".5rem",
              width: "90%",
              margin: "0 auto",
              justifyContent: "space-between",
              // backgroundColor: "red",
              padding: ".5rem .5rem",
            }}
          >
            <h5 style={{ color: colorPalette.primaryColor, fontSize: ".9rem" }}>
              vehicles details
            </h5>
            <CloseIcon onClick={handleCloseBtn} />
          </div>
          <MiniVehiclesCard vehicleDetails={selectedVehicles} />
        </div>{" "}
      </Drawer> */}
      <div className="main_onboard_wrapper">
        {isMobile ? (
          <div className="trip-list-mobile-header">
            <MobileHeader headerTitle="Vehicles" />
          </div>
        ) : (
          <div className="main_onboard_Left_box">
            <SideBarMain />
          </div>
        )}

        <div className="main_onboard_Right_box">
          {isMobile ? null : <Topbar />}
          <div className="progress_container_details">
            <div className="Left_details_wrapper">
              <div className="search_container_vehicle">
                <div className="search_div" id="search_div_onboard">
                  <input
                    type="text"
                    name=""
                    id="search_input_onboard"
                    onChange={(event) => {
                      dispatch(searchVehicles(event.target.value));
                      setCurrentPage(1);
                    }}
                    value={searchTerm}
                    placeholder="search vehicle number"
                    className="search_input"
                  />
                  {/* <img
                    src={FILTER_ICON}
                    alt=""
                    style={{ margin: "2px" }}
                    onClick={handleOpenFilter}
                  /> */}
                  {searchTerm && !vehiclesListData.length == 0 ? (
                    <CloseIcon
                      onClick={handlecancelSearch}
                      style={{ color: "grey", cursor: "pointer" }}
                    />
                  ) : (
                    <SearchIcon style={{ color: "grey", cursor: "pointer" }} />
                  )}

                  {/* <SearchIcon style={{ color: "grey", cursor: "pointer" }} /> */}
                </div>
                <ButtonRectangle
                  onClick={() => handleAddVehicle(1)}
                  // className="add_manufacturer"
                >
                  <AddIcon style={{ fontSize: isMobile ? "10px" : "" }} />
                  Vehicle
                </ButtonRectangle>
              </div>
              <div className="vehicle_tabs">
                <div
                  className="vehicle_singletab"
                  onClick={() => {
                    handleClickVehicleType(10, "");
                    setCurrentPage(1);
                    localStorage.removeItem("selectedTransporter");
                    localStorage.removeItem("selectedIndex");
                  }}
                  style={{
                    borderBottom:
                      selectedIndex == 10
                        ? `3px solid ${colorPalette.primaryColor}`
                        : `3px solid ${colorPalette.secondaryWhite}`,
                    fontWeight: selectedIndex == 10 ? "bold" : "normal",
                    // borderRadius: "8px",
                    color:
                      selectedIndex == 10
                        ? colorPalette.primaryColor
                        : colorPalette.paragraphColor,
                  }}
                >
                  All
                </div>
                {vehicleStatus.map((vehicle, index) => (
                  <div
                    className="vehicle_singletab"
                    key={index}
                    onClick={() => {
                      handleClickVehicleType(index, vehicle);
                      setCurrentPage(1);
                      localStorage.setItem("selectedTransporter", vehicle);
                      localStorage.setItem("selectedIndex", index);
                    }}
                    style={{
                      borderBottom:
                        selectedIndex == index
                          ? `3px solid ${colorPalette.primaryColor}`
                          : `3px solid ${colorPalette.secondaryWhite}`,
                      fontWeight: selectedIndex == index ? "bold" : "normal",
                      // borderRadius: "8px",
                      color:
                        selectedIndex == index
                          ? colorPalette.primaryColor
                          : colorPalette.paragraphColor,
                    }}
                  >
                    {capitalizeLetter(vehicle)}
                    {/* // {vehicle
                    //   .replace("_", " ")
                    //   .match(/\b(\w+)\b/g)[0]
                    //   .charAt(0)
                    //   .toUpperCase() +
                    //   vehicle
                    //     .replace("_", " ")
                    //     .match(/\b(\w+)\b/g)[0]
                    //     .slice(1)} */}
                  </div>
                ))}
              </div>
              <div className="card_container_vehicles">
                {isUserNotAuthorized ? (
                  <NotAuthorized />
                ) : vehiclesListData.length !== 0 ? (
                  <>
                    {vehiclesListData.map((data, index) => (
                      <>
                        {" "}
                        <div
                          className="card_vehicles"
                          key={index}
                          index={index}
                          onClick={() => handleSelectCard(data, index)}
                          style={{
                            border:
                              index === cardClicked
                                ? "2px solid green"
                                : "none",
                            cursor: "pointer",
                          }}
                        >
                          <div className="content_div_vehicle">
                            <div
                              // className="name"
                              style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                                padding: "0 0.6rem",
                              }}
                            >
                              {/* <h6 className="capacity_text">Vehicle Number:</h6> */}
                              <div>
                                {vehiclesListData.length == index + 1 ? (
                                  <h6
                                    className="name_text_vehicle"
                                    ref={lastItem}
                                  >
                                    {" "}
                                    {<CopyText text={data.vehicle_number} />}
                                  </h6>
                                ) : (
                                  <h6 className="name_text_vehicle">
                                    {" "}
                                    {<CopyText text={data.vehicle_number} />}
                                  </h6>
                                )}
                              </div>
                              <div className="vehicle_trip_payout">
                                <div className="tl-new-status_drawer">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      // fontSize: "8px",
                                      color: "white",
                                      padding: "1px 4px",
                                      background:
                                        data.status === "available"
                                          ? colorPalette.primaryColor
                                          : data.status === "blocked"
                                          ? "#FF6666"
                                          : data.status === "in_transit"
                                          ? "#E5B35D"
                                          : "#65c368",
                                      borderTopLeftRadius: "3px",
                                      borderBottomLeftRadius: "3px",
                                    }}
                                  >
                                    {capitalizeLetter(data.status)}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      // width: "100%",
                                      justifyContent: "center",
                                      color: "white",
                                      padding: "1px 4px",
                                      background: colorPalette.primaryCodeColor,
                                      borderTopRightRadius: "3px",
                                      borderBottomRightRadius: "3px",
                                    }}
                                  >
                                    {data.payout_period &&
                                    data.payout_period == "monthly_wise" ? (
                                      // <div className="vehicle-list-monthly-div-container">
                                      //   <div className="vehicle-monthly-img">
                                      <div
                                        className="vehicle-list-monthly-text"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        Monthly
                                      </div>
                                    ) : (
                                      //   </div>
                                      // </div>
                                      // <div className="vehicle-list-monthly-div-container">
                                      //   <div className="vehicle-monthly-img">
                                      <div className="vehicle-list-monthly-text">
                                        Tripwise
                                      </div>
                                      //   </div>
                                      // </div>
                                    )}
                                  </div>
                                </div>
                                {/* {data.payout_period &&
                                data.payout_period == "monthly_wise" ? (
                                  <div className="vehicle-list-monthly-div-container">
                                    <div className="vehicle-monthly-img">
                                      <h4 className="vehicle-list-monthly-text">
                                        Monthly
                                      </h4>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="vehicle-list-monthly-div-container">
                                    <div className="vehicle-monthly-img">
                                      <h4 className="vehicle-list-monthly-text">
                                        Tripwise
                                      </h4>
                                    </div>
                                  </div>
                                )} */}
                              </div>

                              {/* <h6 className="capacity_text">Vehicle Number:</h6>
                              <h6 className="name_text">
                                {" "}
                                {data.vehicle_number}
                              </h6> */}
                            </div>
                            <div className="capacity_vehicle">
                              {/* <h6 className="capacity_text">Manufacturer : </h6> */}
                              <h6 className="weight_vehicle">
                                {" "}
                                {data.vehicle_manufacturer_name
                                  ? data.vehicle_manufacturer_name
                                  : "N.A"}{" "}
                                (
                                {data.vehicle_model_list
                                  ? data.vehicle_model_list.vehicle_type
                                      .split("_")
                                      .join(" ")
                                      .split("")
                                      .map((item, index) => {
                                        if (index == 0) {
                                          return item.toUpperCase();
                                        } else {
                                          return item;
                                        }
                                      })
                                      .join("")
                                  : " - "}
                                )
                              </h6>
                            </div>

                            <div className="capacity">
                              {data.is_national_permit ? (
                                <>
                                  {" "}
                                  <h6 className="capacity_text">
                                    National Permit
                                  </h6>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <h6 className="capacity_text">
                                    Permit State :{" "}
                                  </h6>
                                  <h6 className="weight_vehicle">
                                    {" "}
                                    {data.permitted_states
                                      ? data.permitted_states.toUpperCase()
                                      : "N.A"}
                                  </h6>
                                </>
                              )}
                            </div>
                            <div className="capacity">
                              <h6 className="capacity_text">
                                Max. load capacity :{" "}
                              </h6>
                              <h6 className="weight_vehicle">
                                {" "}
                                {data.vehicle_model_list
                                  ? data.vehicle_model_list
                                      .maximum_load_capacity
                                  : "N.A"}
                                <span style={{ marginLeft: "3px" }}>Ton</span>
                              </h6>
                            </div>
                            <div className="capacity">
                              <h6 className="capacity_text">
                                No. of wheels :{" "}
                              </h6>
                              <h6 className="weight_vehicle">
                                {" "}
                                {data.vehicle_model_list
                                  ? data.vehicle_model_list.wheels
                                  : "N.A"}
                              </h6>
                            </div>

                            {/* <div className="state">
                              <h6 className="state_text">
                                <AddLocationIcon />
                                <span
                                  style={{
                                    color: "#696969",
                                    marginLeft: " 0.5rem",
                                  }}
                                >
                                  Punjab{" "}
                                </span>{" "}
                              </h6>
                            </div> */}
                            <div>
                              {/* <div className="driver">
                                <h6 className="driver_name_vehicle">Status:</h6>
                                {data.status == "available" ? (
                                  <h6
                                    className="weight_vehicle"
                                    style={{ color: "green" }}
                                  >
                                    {data.status
                                      .toUpperCase()
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()}
                                  </h6>
                                ) : (
                                  <h6
                                    className="weight_vehicle"
                                    style={{ color: "red" }}
                                  >
                                    {data.status
                                      .toUpperCase()
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()}
                                  </h6>
                                )}
                              </div> */}
                            </div>
                            <div className="vehicle-expiry-check-container_vehicles">
                              <div className="vehile-expiry-single-container">
                                {/* <div> */}
                                <img
                                  src={
                                    data.rcExpires >= 0 ? TICKICON : WRONGICON
                                  }
                                  alt=""
                                  className="right-wrong-icon-img"
                                />
                                {/* </div> */}
                                <div className="vehicle-expiry-text">RC</div>
                              </div>
                              <div className="vehile-expiry-single-container">
                                {/* <div> */}
                                <img
                                  src={
                                    data.insuranceExpires >= 0
                                      ? TICKICON
                                      : WRONGICON
                                  }
                                  alt=""
                                  className="right-wrong-icon-img"
                                />
                                {/* </div> */}
                                <div className="vehicle-expiry-text">
                                  {" "}
                                  Insurance
                                </div>
                              </div>
                              <div className="vehile-expiry-single-container">
                                {/* <div> */}
                                <img
                                  src={
                                    data.permitExpires >= 0
                                      ? TICKICON
                                      : WRONGICON
                                  }
                                  alt=""
                                  className="right-wrong-icon-img"
                                />
                                {/* </div> */}
                                <div className="vehicle-expiry-text">
                                  {" "}
                                  Permit
                                </div>
                              </div>
                              <div className="vehile-expiry-single-container">
                                {/* <div> */}
                                <img
                                  src={
                                    data.pollutionExpires >= 0
                                      ? TICKICON
                                      : WRONGICON
                                  }
                                  alt=""
                                  className="right-wrong-icon-img"
                                />
                                {/* </div> */}
                                <div className="vehicle-expiry-text">
                                  {" "}
                                  Pollution
                                </div>
                              </div>
                              {/* <div className="vehile-expiry-single-container">
                                {data.status == "in_transit" ? (
                                  <div
                                    className="vehicle-expiry-text"
                                    style={{ color: "#E5B35D" }}
                                  >
                                    {capitalizeLetter(data.status)}
                                  </div>
                                ) : data.status == "available" ? (
                                  <div
                                    className="vehicle-expiry-text"
                                    style={{ color: "green" }}
                                  >
                                    {capitalizeLetter(data.status)}
                                  </div>
                                ) : data.status == "blocked" ? (
                                  <div
                                    className="vehicle-expiry-text"
                                    style={{ color: "#FF6666" }}
                                  >
                                    {capitalizeLetter(data.status)}
                                  </div>
                                ) : null}
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : loadingMore ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "80vw",
                      height: "70vh",
                      // backgroundColor: "grey",
                    }}
                  >
                    <img
                      src={success}
                      alt=""
                      width="120px"
                      height="90px"
                      style={{ marginTop: "100px" }}
                    />
                    <h6 style={{ color: "grey" }}>loading data...</h6>
                  </div>
                ) : vehiclesListData.length == 0 ? (
                  <div
                    // className="card_container_vehicles"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "80vw",
                      height: "70vh",
                      // marginLeft: "6rem",
                    }}
                  >
                    <h4
                      style={{
                        textAlign: "center",
                        width: "100%",
                        // marginLeft: "10%",
                        fontSize: "1rem",
                        color: darkMode
                          ? colorPaletteDark.white
                          : colorPalette.black,
                      }}
                    >
                      No Vehicle Found!
                    </h4>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      // backgroundColor: "grey",
                    }}
                  >
                    <h6 style={{ color: "grey" }}>No data found!</h6>

                    {/* <img
                    src={success}
                    alt=""
                    width="120px"
                    height="90px"
                    style={{ marginTop: "200px" }}
                  />
                  <h6 style={{ color: "grey" }}>loading data...</h6> */}
                  </div>
                )}
                {/* {!loadingMore ? (
                  <div className="trip-list-loading-more">
                    Loading more..Please wait..!!!
                  </div>
                ) : null} */}
              </div>
            </div>
            {/* {isMobile ? null : ( */}

            <CommonDrawer
              commonDrawerOpen={commonDrawerOpen}
              handleCloseCommonDrawer={handleCloseCommonDrawer}
              carrierDetails={selectedVehicles}
              cardClicked=""
              clickTab=""
              toggleCommonDrawer=""
              drawerState=""
              // vehicleDrawer={true}
            />

            {/* // )} */}
          </div>
        </div>
      </div>
    </VehicleListStyle>
  );
}

export default VehiclesList;
