import React from "react";
import apiUrl from "../../../url/apiUrl";
import axios from "axios";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import axiosInterceptor from "../../../config/https";

export const getTripItems = async (id, reference_number) => {
  try {
    const tripListResponse = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl
      }/v1/faarms/trip/${prefixSalt()}-${convertToBase64(
        id
      )}/trip-items?reference_number=${reference_number}&invoice_payment_history_requested=true`
    );
    //
    if (tripListResponse.status == 200) {
      return tripListResponse.data;
    }
  } catch (e) {
    if (e.response.status == 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const getTripDetailsFromId = async (id) => {
  try {
    const tripResponse = await axiosInterceptor.get(
      `${apiUrl.faarmsTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(id)}`
    );

    if (tripResponse.status == 200) {
      return tripResponse.data;
    }
  } catch (e) {
    if (e.response.status == 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const TripPaymentPost = (
  paymentMode,
  amount,
  zohoInvoiceNumber,
  file,
  transactionId,
  itemsData,
  deliveryPaymentArray
) => {
  let data = new FormData();
  // data.append("payment_mode", paymentMode);
  data.append("total_amount", amount);
  // let properDeliveryArray = deliveryPaymentArray((ele) => {
  //   if (ele.zoho_books_account_name === "STATE BANK OF INDIA") {
  //     return {
  //       ...ele,
  //       ledger_name_in_zoho: "STATE BANK OF INDIA-39538519674(540403)",
  //     };
  //   }
  //   if (ele.zoho_books_account_name === "STATE BANK OF INDIA") {
  //     return {
  //       ...ele,
  //       ledger_name_in_zoho: "STATE BANK OF INDIA-39538519674(540403)",
  //     };
  //   }
  //   if (ele.zoho_books_account_name === "STATE BANK OF INDIA") {
  //     return {
  //       ...ele,
  //       ledger_name_in_zoho: "STATE BANK OF INDIA-39538519674(540403)",
  //     };
  //   }
  //   if (ele.zoho_books_account_name === "STATE BANK OF INDIA") {
  //     return {
  //       ...ele,
  //       ledger_name_in_zoho: "STATE BANK OF INDIA-39538519674(540403)",
  //     };
  //   }
  //   if (ele.zoho_books_account_name === "STATE BANK OF INDIA") {
  //     return {
  //       ...ele,
  //       ledger_name_in_zoho: "STATE BANK OF INDIA-39538519674(540403)",
  //     };
  //   }
  // });

  if (deliveryPaymentArray[0].particular_attachment) {
    data.append(
      "particular_attachments_list",
      deliveryPaymentArray[0].particular_attachment
    );
  }

  if (deliveryPaymentArray.length > 1) {
    if (deliveryPaymentArray[1].particular_attachment) {
      data.append(
        "particular_attachments_list",
        deliveryPaymentArray[1].particular_attachment
      );
    }
  }
  if (deliveryPaymentArray.length > 2) {
    if (deliveryPaymentArray[2].particular_attachment) {
      data.append(
        "particular_attachments_list",
        deliveryPaymentArray[2].particular_attachment
      );
    }
  }
  if (deliveryPaymentArray.length > 3) {
    if (deliveryPaymentArray[3].particular_attachment) {
      data.append(
        "particular_attachments_list",
        deliveryPaymentArray[3].particular_attachment
      );
    }
  }
  if (deliveryPaymentArray.length > 4) {
    if (deliveryPaymentArray[4].particular_attachment) {
      data.append(
        "particular_attachments_list",
        deliveryPaymentArray[4].particular_attachment
      );
    }
  }
  if (deliveryPaymentArray.length > 5) {
    if (deliveryPaymentArray[5].particular_attachment) {
      data.append(
        "particular_attachments_list",
        deliveryPaymentArray[5].particular_attachment
      );
    }
  }
  if (deliveryPaymentArray.length > 6) {
    if (deliveryPaymentArray[6].particular_attachment) {
      data.append(
        "particular_attachments_list",
        deliveryPaymentArray[6].particular_attachment
      );
    }
  }
  if (deliveryPaymentArray.length > 7) {
    if (deliveryPaymentArray[7].particular_attachment) {
      data.append(
        "particular_attachments_list",
        deliveryPaymentArray[7].particular_attachment
      );
    }
  }
  if (deliveryPaymentArray.length > 8) {
    if (deliveryPaymentArray[8].particular_attachment) {
      data.append(
        "particular_attachments_list",
        deliveryPaymentArray[8].particular_attachment
      );
    }
  }
  if (deliveryPaymentArray.length > 9) {
    if (deliveryPaymentArray[9].particular_attachment) {
      data.append(
        "particular_attachments_list",
        deliveryPaymentArray[9].particular_attachment
      );
    }
  }
  data.append(
    "particulars_str",
    JSON.stringify(
      deliveryPaymentArray
        .filter((data) => data.particular_payment_mode !== "0")
        .map((item, index) => {
          if (item.particular_payment_mode === "cash") {
            return {
              particular_payment_mode: item.particular_payment_mode,
              particular_amount: parseFloat(item.particular_amount),
              particular_transaction_reference_id:
                item.particular_transaction_reference_id,
              zoho_books_account_name: item.zoho_books_account_name,
              zoho_books_account_id: item.zoho_books_account_id,
              account_code: item.account_code,
            };
          } else {
            return {
              particular_payment_mode: item.particular_payment_mode,
              particular_amount: parseFloat(item.particular_amount),
              particular_transaction_reference_id:
                item.particular_transaction_reference_id,
              zoho_books_account_name: item.zoho_books_account_name,
              zoho_books_account_id: item.zoho_books_account_id,
              account_code: item.account_code,
            };
          }
        })
    )
  );

  data.append("zoho_invoice_number", zohoInvoiceNumber);
  // if (transactionId) {
  //   data.append("transaction_reference_id", transactionId);
  // }
  // if (file) {
  //   data.append("file", file);
  // }
  if (itemsData) {
    data.append("item_details", JSON.stringify(itemsData));
  }

  console.log(JSON.stringify(data), "dataxx");

  var config = {
    method: "post",
    url: `${apiUrl.faarmsTripPaymentV1Url}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        resolve(error.response);
      });
  });
};

export const getPaymentHistoryByInvoiceNumber = async (invoiceNumber) => {
  try {
    const tripResponse = await axiosInterceptor.get(
      `${apiUrl.tripPaymentUrl}?zoho_invoice_number=${invoiceNumber}`
    );

    if (tripResponse.status == 200) {
      return tripResponse.data;
    }
  } catch (e) { }
};
export const getPaymentZohobankAccount = async (invoiceNumber) => {
  try {
    const tripResponse = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/zoho/bank-accounts`
      // `https://api.leapsupply.in/logistics-services/v1/zoho/bank-accounts` //prod url
      // `http://3.110.219.1:8000/logistics-services/v1/zoho/bank-accounts` // need to change after testing
    );

    if (tripResponse.status == 200) {
      return tripResponse.data;
    }
  } catch (e) { }
};

export const getInvoicePaymentModes = async () => {
  try {
    const tripResponse = await axiosInterceptor.get(
      `${apiUrl.paymentModeEnumUrl}`
    );

    if (tripResponse.status == 200) {
      return tripResponse.data;
    }
  } catch (e) { }
};

export const getTripItemsWithDiscount = async (trip_code) => {
  try {
    const tripListResponse = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/faarms/trip/trip-items?trip_code=${trip_code}&invoice_payment_history_requested=true`
    );

    if (tripListResponse.status == 200) {
      return tripListResponse.data;
    }
  } catch (e) {
    if (e.response.status == 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const invoiceSyncZoho = (referenceNumber, referenceId, tripId) => {
  let data = JSON.stringify({
    reference_number: referenceNumber,
    remarks: "test",
  });

  var config = {
    method: "patch",
    url: `${apiUrl.faarmsTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/resync-invoice`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status >= 200 && response.status <= 300) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status == 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
        //
      });
  });
};
