import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../../themes/theme";

export const NavbarStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background-color: ${colorPalette.white};
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 150px auto;
  padding: 15px 70px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  .logo {
    width: 100%;
  }

  .logo img {
    width: 100%;
  }

  .menu ul {
    display: flex;
    align-items: center;
    gap: 50px;

    list-style: none;
  }

  .menu li {
    cursor: pointer;
    font-size: ${typography.heading4};
    font-weight: ${FontWeight.semibold};
  }

  .menu li a {
    color: ${colorPalette.paragraphColor};
    text-decoration: none;
  }

  .menu .login-btn a {
    text-decoration: none;
    color: white;
    padding: 12px 35px;
    border-radius: 50px;
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.bold};
    background-color: ${colorPalette.primaryColor};
    cursor: pointer;
    border: 2px solid ${colorPalette.primaryColor};
    transition: all 0.2s ease;
  }

  .login-btn a:hover {
    background: transparent;
    border: 2px solid ${colorPalette.primaryColor};
    color: ${colorPalette.primaryColor};
  }

  .menu_bar_icon {
    display: none;
  }
  .menu .login-btn_customer a {
    text-decoration: none;
    color: ${colorPalette.primaryColor};
    padding: 12px 35px;
    border-radius: 50px;
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.bold};
    background-color: ${colorPalette.primaryColor};
    cursor: pointer;
    border: 2px solid ${colorPalette.primaryColor};
    transition: all 0.2s ease;
  }
  /* .signIn_customer{
  text-decoration: none;
  color: white;
  padding: 12px 35px;
  border-radius: 50px;
  font-size: ${typography.heading5};
  font-weight: ${FontWeight.bold};
  background-color: white;
  cursor: pointer;
  border: 2px solid ${colorPalette.primaryColor};
  transition: all 0.2s ease;
}
.signIn_customer:hover {
  background: ${colorPalette.primaryColor};
  border: 2px solid ${colorPalette.primaryColor};
  color: #fcfefd;
} */
  /* .signIn_customer a:hover {

  color: #fcfefd;
} */
  @media only screen and (max-width: 1210px) {
    grid-template-columns: auto auto;
    padding: 15px 20px;

    .menu {
      /* display: none; */
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 75px;
      left: -100%;
      background-color: ${colorPalette.paragraphColor};
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 999;
      transition: all 0.3s ease;
    }

    .menu-active {
      left: 0;
      transition: all 0.3s ease;
    }

    .menu ul {
      display: flex;
      flex-direction: column;
      color: white;
      margin-top: -150px;
    }

    .menu ul li {
      margin-bottom: 20px;
    }

    .menu li a {
      color: ${colorPalette.white};
    }

    .logo {
      width: 150px;
    }

    .logo img {
      width: 100%;
    }

    .menu_bar_icon {
      display: block;
    }

    .menu_bar_icon .menu_icon {
      font-size: 45px;
    }
  }
`;
