import React from "react";
import apiUrl from "../../url/apiUrl.js";
import axios from "axios";
import axiosInterceptor from "../../config/https.js";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter.js";

export const oemPost = (name, file, type) => {
  let data = new FormData();

  data.append("name", name);
  data.append("file", file);
  data.append("image", type);
  // let data = JSON.stringify({
  //   name: `${name}`,
  // });
  var config = {
    method: "post",
    url: `${apiUrl.onboardingBaseUrl}/v1/vehicle-manufacturer`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        // if (response.status == 200) {
        resolve(response.status);
        // }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error);
      });
  });
};

export const vehicleModelPost = (
  model_name,
  model_number,
  wheels,
  vehicle_type,
  max_capacity,
  min_capacity,
  class_model,
  unit,
  oemId
) => {
  let data = JSON.stringify({
    name: `${model_name}`,
    model_number: `${model_number}`,
    wheels: `${wheels}`,
    vehicle_type: `${vehicle_type}`,
    maximum_load_capacity: `${max_capacity}`,
    normal_load_capacity: `${min_capacity}`,
    class_model: `${class_model}`,
    unit: `${"ton"}`,
  });
  var config = {
    method: "post",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v1/vehicle-manufacturer/${prefixSalt()}-${convertToBase64(
      oemId
    )}/vehicle-model`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        // if (response.status == 200) {
        resolve(response.status);
        // }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error);
      });
  });
};

export const getOemList = async (page) => {
  try {
    const oemListResponse = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/vehicle-manufacturer?current_page=${page}`
    );

    if (oemListResponse.status == 200) {
      return oemListResponse.data;
    }
  } catch (e) {
    return e.response.status;
  }
};
export const getModelList = async (page, oemId) => {
  try {
    const modelListResponse = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/vehicle-manufacturer/${prefixSalt()}-${convertToBase64(
        oemId
      )}/vehicle-model?current_page=${page}`
    );

    if (modelListResponse.status == 200) {
      return modelListResponse.data;
    }
  } catch (e) {}
};

export const vehicleManufacturerPut = (name, id) => {
  let data = new FormData();
  data.append("name", name);
  var config = {
    method: "put",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v1/vehicle-manufacturer/${prefixSalt()}-${convertToBase64(id)}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error);
      });
  });
};

export const vehicleModelPut = (
  model_name,
  model_number,
  wheels,
  vehicle_type,
  max_capacity,
  min_capacity,
  class_model,
  unit,
  manufactuturerId,
  vehicleModelId
) => {
  let data = JSON.stringify({
    name: `${model_name}`,
    model_number: `${model_number}`,
    wheels: `${wheels}`,
    vehicle_type: `${vehicle_type}`,
    maximum_load_capacity: `${max_capacity}`,
    normal_load_capacity: `${min_capacity}`,
    class_model: `${class_model}`,
    unit: `${unit}`,
  });
  var config = {
    method: "put",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v1/vehicle-manufacturer/${prefixSalt()}-${convertToBase64(
      manufactuturerId
    )}/vehicle-model/${prefixSalt()}-${convertToBase64(vehicleModelId)}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error);
      });
  });
};
