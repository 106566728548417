import React, { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ScrollToTop from "react-scroll-to-top";
import { NavbarStyle } from "./navbar";
import {
  ButtonPrimary,
  ButtonSecondary,
} from "../../../themes/components/button";
import { DarkModeSwitch } from "../../../themes/theme";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const handleMobileMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const ScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <NavbarStyle id="home">
      <div className="logo" onClick={ScrollToTop} style={{ cursor: "pointer" }}>
        <img src="/assets/landingPageAssets/logo.png" alt="logo" />
      </div>
      <div
        className={isMenuOpen ? "menu" : "menu menu-active"}
        onClick={handleMobileMenu}
        x
      >
        <ul>
          {/* <li style={{ width: "30px" }}>
            <DarkModeSwitch />
          </li> */}
          <li onClick={handleMobileMenu}>
            <a href="#services">SERVICES</a>
          </li>
          <li onClick={handleMobileMenu}>
            <a href="#our_platform">OUR PLATFORM</a>
          </li>
          <li onClick={handleMobileMenu}>
            <a href="#presence">PRESENCE</a>
          </li>
          <li onClick={handleMobileMenu}>
            <a href="#partnership">PARTNERSHIP</a>
          </li>
          <li onClick={handleMobileMenu}>
            <ButtonPrimary>
              <Link
                style={{ color: "inherit", textDecoration: "none" }}
                to="/login"
              >
                Sign In
              </Link>
            </ButtonPrimary>
          </li>
          <li onClick={handleMobileMenu}>
            <ButtonSecondary>
              <Link to="/Customer/login">Customer Portal</Link>
            </ButtonSecondary>
          </li>
        </ul>
      </div>
      <div className="menu_bar_icon" onClick={handleMobileMenu}>
        {isMenuOpen ? (
          <MenuIcon className="menu_icon" />
        ) : (
          <CloseIcon className="menu_icon" />
        )}
      </div>
    </NavbarStyle>
  );
};

export default Navbar;
