import styled from "styled-components";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  typography,
} from "../../../themes/theme";

export const Delivery_invoice_payment_main_container = styled.div`
  .payment-history-headings {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .paid-amount-div {
    position: relative;
    height: 30px;
  }
  .paid-amount-sum {
    position: absolute;
    /* top: 0; */
    left: 0;
    font-size: 8px;
  }
  .payment-history-subheadings-text {
    font-size: 10px;
    font-weight: 300;
  }
  .payment-history-subheadings {
    font-size: 10px;
    font-weight: bold;
  }
  .mini-card-delivery-info-container-payment-history {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .text-fields-payment-history {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .inoice-details-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .invoice-details-text {
    font-size: 10px;
    font-weight: bold;
  }
  .payment-history-buttons-main {
    width: 100%;
    /* height: 50px; */
    display: flex;
    padding: 5px 0px;
    margin-top: 10px;
    /* background-color: red; */
    align-items: center;
    /* flex-direction: column; */
    justify-content: flex-start;
  }

  /* .delivery-invoice-payment-main-container { */
  display: flex;
  /* background-color: red; */
  /* } */
  .delivery-add-payment-button {
    position: absolute;

    top: -10px;
    /* background-color: red; */
    right: -10px;
    cursor: pointer;
  }
  .sync-button-delivery {
    border: none;
    padding: 10px;
    color: ${colorPalette.white};
    background-color: ${colorPalette.primaryColor};
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${FontWeight.medium};
    width: 100px;
  }
  /* .delivery-add-payment-button:hover {
  background-color: ${colorPalette.primaryColor};
  color: white;
} */

  .delivery-invoice-right-top {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin: 10px 5px;
    /* width: 100%; */
  }
  .delivery-error {
    font-size: ${typography.paragraph};
    color: red;
  }
  .delivery-invoice-alert {
    margin: 10px 0px;
  }
  .delivery-invoice-payment-right-main-container {
    flex: 9.5;
  }
  .delivery-invoice-payment-sidebar-container {
    flex: 1.7;
    /* height: 100%; */
    /* background-color: red; */
  }
  .delivery-invoice-head-text {
    font-size: ${typography.heading4};
    font-weight: ${FontWeight.medium};
    margin-left: 10px;
  }
  .delivery-invoice-right-body-container {
    margin: 15px 20px;
    /* width: 100%; */
  }
  .delivery-invoice-input {
    /* flex: 1; */
    width: 100%;
  }
  .delivery-invoice-input-container {
    width: 82%;
  }
  .delivery-invoice-row {
    width: 100%;
    margin: 10px 0px;
    display: flex;
    /* justify-content: flex-end; */
    align-items: flex-end;
  }

  .delivery-invoice-items-container {
    border: 1px solid ${colorPalette.black};
    border-radius: 10px;
    padding: 2%;
    width: 80%;
    margin: 1% 2%;
  }
  .delivery-invoice-items-container-history {
    border: 1px solid ${colorPalette.black};
    border-radius: 10px;
    padding: 2%;
    width: 80%;
    margin: 1% 2%;
  }
  .table-head-row {
    padding: 10px;
  }
  /* .column1 {
 
  display: flex;
  justify-content: center;
} */
  .column2 {
    flex: 1;
  }
  .column3 {
    flex: 1;
  }
  .column4 {
    flex: 1;
  }
  .column5 {
    flex: 1;
  }
  .column6 {
    flex: 1;
  }
  #invoice-items {
    font-family: ${FontFamily.primaryFontFamily};
    border-collapse: collapse;
    width: 100%;
  }

  #invoice-items td,
  #invoice-items th {
    /* border: 1px solid #ddd; */
    padding: 8px;
    font-size: ${typography.heading5};
  }

  #invoice-items th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: white;
    color: ${colorPalette.black};
    border-bottom: 1px solid ${colorPalette.black};
    font-weight: ${FontWeight.medium};
  }
  .total-amount {
    font-weight: ${FontWeight.medium};
  }
  .button-recordpayment {
    padding: 0.5rem 2rem;
    background-color: ${colorPalette.primaryColor};
    color: white;
    outline: none;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
    font-weight: ${FontWeight.medium};
  }
  .button-recordpayment_offline {
    padding: 0.5rem 2rem;
    /* background-color: ${colorPalette.primaryColor}; */
    color: ${colorPalette.primaryColor};
    background-color: white;
    outline: none;
    border: 1px solid ${colorPalette.primaryColor};
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
    font-weight: ${FontWeight.medium};
  }
  .button-payment-addd {
    /* padding: 0.2rem 0.2rem;
  color: ${colorPalette.primaryColor};
  background-color: white;
  outline: none;
  border: 1px solid ${colorPalette.primaryColor};
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
  font-weight: ${FontWeight.medium}; */
  }

  .delivery-invoice-image-name {
    font-size: ${typography.heading5};
    margin: 5px;
  }

  .delivery-add-container {
    position: absolute;
    right: 0;
    top: 24px;
  }

  .payment-mode {
    margin-top: 2rem;
    width: 80%;
    padding: 0.5rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .radio-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem 0rem;
  }
  .online {
    margin-left: 1rem;
  }
  .cash {
    margin-left: 1rem;
  }
  .delivery-invoice-quantity-input {
    padding: 5px;
    width: 50%;
  }
  @media only screen and (max-width: 480px) {
    .delivery-invoice-quantity-input {
      padding: 5px;
      width: 80%;
    }
    .delivery-add-container {
      right: -20px;
    }

    /* .delivery-invoice-payment-main-container { */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* } */
    .delivery-invoice-items-container {
      border: 1px solid black;
      border-radius: 10px;
      padding: 2%;
      width: 95%;
      overflow: scroll;
      margin: 0 auto;
    }
    .delivery-add-payment-button {
      width: 40px;
      height: 40px;
      padding: 5px;
      position: absolute;
      top: -10px;
      /* background-color: red; */
      right: -10px;
      cursor: pointer;
    }
    .delivery-invoice-input {
      /* flex: 1; */
      width: 99%;
    }

    .table-head-row {
      /* background-color: ${colorPalette.primaryColor}; */
      width: 100%;
    }

    .column1 {
      flex: 1;
      width: 30%;
      /* padding: 0rem 1rem; */
      /* height: 5%; */
      background-color: white;
      position: fixed;
      z-index: 999;
    }
    .column2 {
      flex: 1;
      margin-left: 10rem;
      /* position: fixed; */
      /* width: 30%; */
      /* margin-right: 40%; */
    }
    .column3 {
      flex: 1;
    }
    .column4 {
      flex: 1;
    }
    .column5 {
      flex: 1;
      font-size: ${typography.paragraph_secondary};
    }
    .column6 {
      flex: 1;
    }
    #invoice-items {
      font-family: ${FontFamily.primaryFontFamily};
      border-collapse: collapse;
      width: 200%;
      /* width: 80%; */
      margin: 0 auto;
      overflow: scroll;
      /* background-color: antiquewhite; */
    }

    #invoice-items td,
    #invoice-items th {
      /* border: 1px solid #ddd; */
      padding: 10px;
      font-size: ${typography.heading5};
      position: inherit;
    }
    .thead_heading {
      width: 100%;
      overflow: scroll;
    }
    .body {
      overflow-y: scroll;
      height: 100px;
      /* background-color: red; */
    }

    /* #invoice-items tr:nth-child(even) {
  background-color: #f2f2f2;
}

#invoice-items tr:hover {
  background-color: #ddd;
} */

    #invoice-items th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: white;
      color: rgb(0, 0, 0);
      border-bottom: 1px solid black;
      font-weight: ${FontWeight.medium};
    }
  }
`;

export const Payment_drawer = styled.div`
  /* .payment-drawer { */
  width: 55vw;
  /* } */

  .payment-wrapper {
    width: 95%;
    height: 100%;
    padding: 0.5rem;
    margin: 0 auto;
    /* background-color: ${colorPalette.primaryColor}; */
  }

  .delivery-invoice-close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1%;
  }

  .payment-headings {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .delivery-invoice-input-head {
    font-weight: ${FontWeight.medium};
  }

  .payment-total {
    margin-top: 0rem;
    width: 90%;
    padding: 0rem 0rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .table-main-container {
    width: 100%;
    padding: 0rem 0rem;
    margin-top: 10px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table-head {
    width: 100%;
    padding: 0.5rem 0.2rem;
    background-color: #d6d6d6;
    color: black;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
  }
  .table-head-data {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-right: 1px solid black; */
    /* background-color: red; */
  }
  .table-head-data-fields {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-right: 1px solid black; */
    /* background-color: red; */
  }
  .button-upload {
    position: relative;
    cursor: pointer;
    font: inherit;
    font-weight: lighter;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* min-height: 48px; */
    min-width: 48px;
  }
  .table-value {
    width: 100%;
    height: 50px;
    margin-top: 5px;
    padding: 0.5rem 0.2rem;
    background-color: white;
    border: 1px solid #d6d6d6;
    color: black;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .table-box {
    /* flex: 1; */
    /* width: 100%; */
    /* height: 100%; */
    /* border: 1px solid #d6d6d6; */
  }

  .delivery-invoice-payment-mode {
    margin: 10px 0px;
  }

  .delivery-add-container-cash {
    position: absolute;
    right: 0;
    top: 33px;
  }

  .delivery-add-payment-button {
    cursor: pointer;
    position: absolute;
    right: -25px;
    cursor: pointer;
  }

  .delivery-right-payment-button {
    cursor: pointer;
    position: absolute;
    right: -25px;
    cursor: pointer;
  }

  .delivery-invoice-image-name {
    font-size: ${typography.heading5};
    margin: 5px;
  }

  .delivery-error {
    font-size: ${typography.paragraph};
    color: red;
  }

  .delivery-add-container {
    position: absolute;
    right: 0;
    top: 24px;
  }

  .button-recordpayment {
    padding: 0.5rem 2rem;
    background-color: ${colorPalette.primaryColor};
    color: white;
    outline: none;
    border: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
    font-weight: ${FontWeight.medium};
  }

  @media only screen and (max-width: 480px) {
    /* .payment-drawer { */
    width: 100vw;
    /* } */

    .delivery-add-container-cash {
      right: -22px;
    }

    .delivery-add-container {
      right: -20px;
    }
  }
`;
