import React from "react";
import apiUrl from "../../url/apiUrl.js";
import axios from "axios";
import axiosInterceptor from "../../config/https.js";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter.js";

export const PaymentRecordPost = (
  payment_file,
  trip_id,
  amount,
  transaction_ref
) => {
  let data = new FormData();
  if (payment_file) {
    data.append("file", payment_file);
  }
  data.append("trip_id", `${trip_id}`);
  data.append("amount", `${amount}`);
  data.append("transaction_reference_id", `${transaction_ref}`);

  var config = {
    method: "post",
    url: `${apiUrl.onboardingBaseUrl}/v1/trip-payment`,
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (!error.response) {
          resolve(error);
        } else {
          resolve(error.response);
        }
        //
      });
  });
};

export const getPaymentRecords = async (trip_id) => {
  try {
    const paymentResponse = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/trip-payment/${prefixSalt()}-${convertToBase64(trip_id)}`
    );

    if (paymentResponse.status == 200) {
      return paymentResponse;
    }
    if (paymentResponse.status == 400) {
      return;
    }
  } catch (e) {
    // if (!e.response) {
    //   return e;
    // }
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    if (e.response.status === 400) {
      // return null;
    }
    //
    // resolve(e.response);
    return e.response;
  }
};

export const getPaymentAttachments = async (trip_id) => {
  try {
    const paymentAttachResponse = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/trip-payment/${prefixSalt()}-${convertToBase64(trip_id)}`
    );

    if (paymentAttachResponse.status == 200) {
      return paymentAttachResponse;
    }
  } catch (e) {}
};
export const getTripCost = async (trip_id) => {
  try {
    const tripCost = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/trip/${prefixSalt()}-${convertToBase64(
        trip_id
      )}/costs`
    );

    if (tripCost.status == 200) {
      return tripCost;
    }
  } catch (e) {}
};

export const getTripDetailsById = async (trip_id) => {
  try {
    const tripDetails = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/trip/${prefixSalt()}-${convertToBase64(
        trip_id
      )}`
    );

    if (tripDetails.status == 200) {
      return tripDetails;
    }
  } catch (e) {}
};
export const getbankDetailsByVehicleNumber = async (vehicle_number) => {
  try {
    const bankDetails = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/bank-details?vehicle_number=${vehicle_number}`
    );

    if (bankDetails.status == 200) {
      return bankDetails;
    }
  } catch (e) {}
};
