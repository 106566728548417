import React, { useContext, useEffect, useState } from "react";
import DatamapsIndia from "react-datamaps-india";
import apiUrl from "../../url/apiUrl";
import axiosInterceptor from "../../config/https";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Stack } from "react-bootstrap";
import { Switch, TextField } from "@mui/material";
import moment from "moment";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { DataMap_header } from "./datamap.js";
import { DarkModeContext } from "../../context/darkModeContext";

const Datamap = () => {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const [mapData, setMapData] = useState();
  const [formatedData, setFormatedData] = useState();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [serviceableState, setServiceableState] = useState();
  const [serviceableStateData, setServiceableStateData] = useState();
  const [uniqueServiceablePincode, setUniqueServiceablePincode] = useState();

  const history = useHistory();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    // tooltips: {
    //   callbacks: {
    //     label: function (tooltipItem) {
    //
    //       return "$" + " and so worth it !";
    //     },
    //   },
    // },
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      // title: {
      //   display: true,
      //   text: "Chart.js Bar Chart",
      // },
    },
    // tooltips: {
    //   mode: "index",
    //   intersect: false,
    // },
    // hover: {
    //   mode: "index",
    //   intersect: false,
    // },
    maintainAspectRatio: false,
  };

  const labels = [""];

  const data = {
    labels,
    datasets: [
      {
        label: "Deliveries",
        data: [serviceableStateData],
        backgroundColor: "#076B38",
      },
      {
        label: "States",
        data: [serviceableState],
        backgroundColor: "#0ca254",
      },
      {
        label: "Pincode",
        data: [uniqueServiceablePincode],
        backgroundColor: "#10f57e",
      },
    ],
  };

  const getTripData = async () => {
    const response = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/summary/analytics?from_date=${
        fromDate ? fromDate : ""
      }&to_date=${toDate ? toDate : ""}`
    );

    if (response.status === 200) {
      setMapData(response.data.analytics.states);
      dataFormate(response.data.analytics.states);
      var data = dataFormate(response.data.analytics.states);
      setFormatedData(dataFormate(response.data.analytics.states));
    }
  };

  useEffect(() => {
    getTripData();
  }, [fromDate, toDate]);

  useEffect(() => {
    if (mapData) {
      const state = [];
      var stateData = 0;
      var unique_pincode = 0;
      mapData.map((d) => {
        state.push(d.name);
        stateData = stateData + d.trips_count;
        unique_pincode = unique_pincode + d.unique_pincodes_served.length;
      });

      setUniqueServiceablePincode(unique_pincode);
      setServiceableState(state.length);
      setServiceableStateData(stateData);
    }
  }, [mapData]);

  const dataFormate = (mapData) => {
    const dataObj = {};
    var res = mapData.map((d) => {
      if (d.name !== null) {
        var stateName = d.name
          .toLowerCase()
          .split(" ")
          .map((word) => {
            return word[0].toUpperCase() + word.substring(1);
          })
          .join(" ");
        return (dataObj[stateName] = {
          value: d.trips_count,
        });
      }
    });

    return dataObj;
  };

  return (
    <DataMap_header className="datamap-header" mode={darkMode}>
      <div className="datamap-navbar">
        <div className="back_button_map">
          <ArrowBackIcon onClick={() => history.push("/ftl-trips")} /> Trip
          details Map view
        </div>
        <div className="mode_icons">
          {!darkMode ? (
            <>
              <span className="mode_text">Light </span>{" "}
              <DarkModeIcon className="darkModeIcon" />
            </>
          ) : (
            <>
              <span className="mode_text">Light </span>{" "}
              <WbSunnyIcon className="lightModeIcon" />
            </>
          )}
          <Switch onClick={toggle} />
        </div>
      </div>
      <div className="map-section-main">
        <div className="map-section">
          <DatamapsIndia
            regionData={formatedData}
            hoverComponent={({ value }) => {
              return (
                <>
                  <p>State : {value.name}</p>
                  <p>
                    {value.value
                      ? `Trip Counts : ${value.value}`
                      : `Trip Count : 0`}
                  </p>
                </>
              );
            }}
            mapLayout={{
              title: "",
              legendTitle: "Number of deliveries",
              startColor: "#076b3781",
              endColor: "#076B38",
              hoverTitle: "Count",
              noDataColor: "#f5f5f5",
              borderColor: "#8D8D8D",
              hoverBorderColor: "#FFD53E",
              hoverColor: "#FFD53E",
            }}
          />
          `Trip Counts :
        </div>
        <div className="details-section">
          <div className="date-picker">
            <div>
              {/* <span>From Date : </span>{" "} */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                {/* <Stack spacing={2}> */}
                <DatePicker
                  size="small"
                  openTo="year"
                  views={["year", "month"]}
                  // label="select month"
                  value={fromDate}
                  onChange={(newValue) => {
                    setFromDate(moment(newValue).format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled
                      label="From Month"
                      // placeholder="To Month"
                      helperText={null}
                      size="small"

                      // style={{}}
                    />
                  )}
                />
                {/* </Stack> */}
              </LocalizationProvider>
            </div>
            <div
            // style={{
            //   // backgroundColor: "red",
            //   display: "flex",
            //   justifyContent: "flex-start",
            //   width: "200px",
            //   backgroundColor: "red",
            // }}
            >
              {/* <span>To Date : </span>{" "} */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={1}>
                  <DatePicker
                    // size="small"
                    openTo="year"
                    views={["year", "month"]}
                    label="select trip month"
                    value={toDate}
                    onChange={(newValue) => {
                      setToDate(moment(newValue).format("YYYY-MM-DD"));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled
                        label="To Month"
                        helperText={null}
                        size="small"
                        // style={{ padding: "0px 2rem" }}
                      />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </div>
          </div>
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Basic example"
              // value={value}
              onChange={(newValue) => {
                // setValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider> */}
          <div className="bar-chart">
            <Bar options={options} data={data} />
          </div>
        </div>
      </div>
    </DataMap_header>
  );
};

export default Datamap;
