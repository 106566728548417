import styled from "styled-components";
import { colorPalette, colorPaletteDark } from "../../themes/theme";

export const Transporter_Drawer = styled.div`
  width: 100%;
  height: 90vh;
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

  .transporter_drawer_headings {
    padding: 0.8rem 1rem;
    /* padding: 0px .5rem; */
    color: ${colorPalette.primaryColor};
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-size: 1.2rem;
    font-weight: bold;
    box-shadow: ${colorPalette.boxShadowPrimary};
    /* position: fixed; */

    .greyText {
      color: ${(props) => (props.mode ? "white" : "grey")};
    }

    span {
      color: ${(props) => (props.mode ? "#CACACA" : "grey")};
    }

    a {
      display: flex;
      align-items: center;
      margin-right: 0;
    }
  }

  .transporter_drawer_content_container {
    width: 100%;
    height: 100%;
    /* margin-top: 3rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  }

  .close_button_edit {
    display: flex;
    align-items: center;
    width: 100px;

    justify-content: space-between;
  }
  .image_button_container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    /* margin-right: 10px; */
    /* width: 100%; */
  }
  .bank_holder_name {
    font-size: 0.8rem;
  }
  .mini-details-single-attachment_commondrawer {
    /* background-color: red; */
    padding: 0px 10px;
    margin: 10px 5px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;

    /* border: 1px solid #474747; */
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 95%;
    /* margin: -8px 0; */
  }
  .details_icons_commondrawer {
    margin: 0.2rem 0rem;
    cursor: pointer;
    /* width: 100%; */
  }
  .vehicle_button_add_drawer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .transporter_drawer_headings {
    padding: 0.8rem 1rem;
    /* padding: 0px .5rem; */
    color: #076b38;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    /* background-color: #016938; */
    font-size: 1.2rem;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    /* position: fixed; */
  }

  .transporter_drawer_content {
    flex: 10;
    height: 90vh;
    /* overflow: scroll; */
    /* height:90%; */
    /* background-color: aqua; */
  }

  .mini-details-carrier-tab-container_drawer {
    /* background-color: red; */
    overflow: scroll;
    position: relative;
    height: 88vh;
    padding: 0px 0.5rem;
  }

  .mini-details-carrier-single-list {
    margin: 1.5rem 5px;
    display: flex;
    overflow-wrap: hidden;
    /* background-color: rebeccapurple; */
  }

  .mini-details-carrier-single-list-left_drawer {
    font-size: 12px;
    margin: 0px 5px;
    font-family: "poppins";
    position: relative;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }

  .mini-details-carrier-single-list-right_drawer {
    font-size: 12px;
    margin: 0px 20px;
    position: absolute;
    font-family: "poppins";
    width: 100%;
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.paragraphSecondaryolor};
    left: 45%;
    font-weight: 500;
    font-size: 12px;
    /* word-wrap: break-word; */
    /* overflow: hidden; */
    /* overflow-wrap: break-word; */
  }

  .mini-details-single-attachment_commondrawer {
    /* background-color: red; */
    padding: 0px 10px;
    margin: 10px 5px;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 10px;
    width: 95%;
    /* margin: -8px 0; */
  }

  .vehicle_button_add_drawer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .bank_holder {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: "poppins";
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    /* justify-content: flex-start; */
  }

  .accodion_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
  }
  .accordion_main {
    width: 100%;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.lightBlack : colorPalette.white};
  }
  .mini-details-carrier-tab-container-bank-drawer {
    /* background-color: red; */
    overflow: scroll;
    position: relative;
    height: 80vh;
    padding: 0.5rem 0.4rem;

    /* width: 100%; */
  }

  .btn_card_vehicle_drawer {
    margin-top: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
  }

  .bankDetails {
    width: 120%;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    /* padding: .5rem 0rem; */
    /* background-color: blueviolet; */
  }

  .image_button_container {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
  }

  .attachment_btn_commonDrawer {
    /* border: 1px solid ${colorPalette.primaryColor}; */
    text-decoration: none;
    padding: 0.3rem 0.2rem;
    color: ${colorPalette.primaryColor};
    transition: ease all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .transporter_drawer_tabs {
    flex: 2;
    height: 90vh;
    width: 100%;
    box-shadow: ${colorPalette.boxShadowPrimary};
    /* background-color: bisque; */
  }

  .icons_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    list-style: none;
  }

  .icons_list {
    width: 100%;
    /* padding: .5rem 0rem; */
    display: flex;
    align-items: center;
    /* background-color: ${colorPalette.primaryColor}; */
    justify-content: center;
    cursor: pointer;
  }

  .icons_list.active {
    background-color: ${colorPalette.secondaryWhite};
    color: ${colorPalette.primaryColor};
    width: 100%;
    border-right: 5px solid ${colorPalette.primaryCodeColor};
    border-left: none;
  }

  .details_icons_commondrawer {
    margin: 0.2rem 0rem;
    cursor: pointer;
    /* width: 100%; */
  }

  .bank_button_add_drawer {
    width: 80%;
  }

  .MuiTabs-indicator {
    background-color: ${colorPalette.primaryColor} !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  @media only screen and (max-width: 480px) {
    .accodion_heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
    }

    .mini-details-carrier-tab-container-bank-drawer {
      /* background-color: red; */
      overflow: scroll;
      position: relative;
      height: 80vh;
      padding: 0.5rem 0.4rem;

      /* width: 100%; */
    }
    .accordion_main {
      width: 300px;
      background-color: ${(props) =>
        props.mode ? colorPaletteDark.lightBlack : colorPalette.white};
    }
    .mini-details-carrier-single-list-right_drawer {
      font-size: 12px;
      margin: 0px 5px;
      position: absolute;
      color: ${colorPalette.paragraphColor};
      left: 55%;
    }

    .mini-details-carrier-single-list-left {
      font-size: 12px;
      margin: 0px 5px;
      position: relative;
    }

    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
      font-size: 10px !important;
      font-weight: 600 !important;
    }
  }
`;

export const Bank_Container = styled.div`
  width: 28vw;
  /* height: 100%; */
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

  .bank_wrapper {
    width: 90%;
    height: 100%;
    margin: 0 auto;
    /* background-color: red; */
  }
  .error_bank_details {
    width: 100%;
    background-color: #ffd8d8;
    padding: 5px;
    font-size: 12px;
    color: red;
  }
  .bank_headings_drawer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0rem;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }

  .bank-drawer-row {
    padding: 0.5rem 0rem;
  }
  .warehouse-list-input-label-drawer {
    margin: 2px 0px;
    font-weight: 500;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }

  .bank-input {
    margin: 5px 0px;
    width: 90%;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.white};
  }

  .bank_button {
    padding: 0.8rem 3rem;
    width: 90%;
    margin: 2rem auto;
    background-color: ${colorPalette.primaryColor};
    border: none;
    outline: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: white;
    cursor: pointer;
  }
  @media only screen and (max-width: 480px) {
    width: 100vw;
    height: 100%;
  }
`;


