import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  carrierId: {},
  carriedIdEdit: "",
  vehicleId: {},
  routes: "",
  routesAddVehicles: "",
  selectedVehicleEdit: {},
};

const onboardSlice = createSlice({
  name: "onboard",
  initialState,
  reducers: {
    onboardData(state, action) {
      state.carrierId = action.payload;
    },
    updateCarrierId(state, action) {
      state.carriedIdEdit = action.payload;
    },
    updateVehicleId(state, action) {
      state.vehicleId = action.payload;
    },
    updateRoutes(state, action) {
      state.routes = action.payload;
      //
    },
    updateRoutesAddVehicle(state, action) {
      state.routesAddVehicles = action.payload;
      //
    },
    updateSelectedVehicleEdit(state, action) {
      state.selectedVehicleEdit = action.payload;
    },
  },
});

export const {
  onboardData,
  updateCarrierId,
  updateVehicleId,
  updateRoutes,
  updateRoutesAddVehicle,
  updateSelectedVehicleEdit,
} = onboardSlice.actions;
export default onboardSlice.reducer;
