import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { readLoginData } from "../../helper/dataDecryptionHelper";

function ProtectedRoutes({ component: Component, ...restOfProps }) {
  //   const [isAuthenticated, setIsAuthenticated] = useState("");

  let isAuthenticated = "";

  if (readLoginData()) {
    isAuthenticated = readLoginData().IS_LOGGED_IN;

    // setIsAuthenticated(isAuth);
  } else {
    isAuthenticated = false;
  }

  //
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default ProtectedRoutes;
