import styled from "styled-components";

export const NoInternetStyle = styled.div`
  /* .noInternet { */
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 499px) {
    width: 80vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  /* } */
`;
