import styled from "@emotion/styled";
import { FontWeight, colorPalette, typography } from "../../../themes/theme";

export const CustomerQuotesStyle = styled.div`
  .customerQuotes-container {
    background-color: ${colorPalette.secondaryWhite};
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    @media only screen and (max-width: 480px) {
      /* background: reds; */
      background-color: ${colorPalette.secondaryWhite};
      width: 100%;
      height: 100vh;
      overflow-y: hidden;
    }
  }
  .remarks-quotaions {
    white-space: nowrap;
    overflow: hidden;
    width: 150px;
    text-overflow: ellipsis;
  }
  .top-container {
    background: ${colorPalette.white};
    width: 100%;
    // height: 10vh;
    // margin-top: 50px;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 480px) {
      /* background: reds; */
    }
  }
  .tab-menu-option {
    margin: 0px 5%;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${colorPalette.paragraphColor};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading3};
    line-height: 27px;
    cursor: pointer;
  }
  .active-tab-option {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
  }
  .edit-button {
    background: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    border: none;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
  }
  .tab-bottom-rectangle {
    background: ${colorPalette.primaryColor};
    border-radius: 5px 5px 0px 0px;
    height: 4px;
    width: 130%;
  }
  .quotesList-container {
    height: 75vh;
    overflow: scroll;
    @media only screen and (max-width: 480px) {
      height: 75vh;
      width: 100%;
      overflow: scroll;
      /* background: red; */
    }
  }
  .tab-menuOption-contain {
    display: flex;
    margin: 0px 20px 0px 20px;
  }
  .customerQuotes-main-body {
    width: 100%;
    display: grid;
    grid-template-columns: auto 400px;
    @media only screen and (max-width: 480px) {
      width: 100%;
      display: grid;
      grid-template-columns: 100%;
    }
  }
  .customerQuotes-main-body-left {
    width: 100%;
  }
  .customerQuotes-search-contain {
    border-radius: 8px;
    background: ${colorPalette.white};
    width: 80%;
    margin: 20px;
    padding: 6px;
    display: flex;
    justify-content: center;
  }
  .quotes-circle {
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background: ${colorPalette.black};
  }
  .customerQuotes-search-input {
    outline: none;
    border: none;
    width: 97%;
    height: 40px;
  }
  .quotes-card-container {
    padding: 15px;
    border-radius: 8px;
    width: 80%;
    box-shadow: ${colorPalette.boxShadowPrimary};
    margin: 20px;
    background-color: ${colorPalette.white};
    border: 2px solid ${colorPalette.transparent};
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      padding: 15px;
      border-radius: 8px;
      width: 90%;
      box-shadow: ${colorPalette.boxShadowPrimary};
      margin: 20px;
      background-color: ${colorPalette.white};
      border: 2px solid ${colorPalette.transparent};
      cursor: pointer;
    }
  }

  .quotes-selected {
    border: 2px solid ${colorPalette.primaryColor};
  }
  .quotes-number-text {
    color: ${colorPalette.black};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.heading5};
  }
  .quotes-amount-text {
    color: ${colorPalette.paragraphColor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
  }
  .quotes-card-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0px;
  }

  .value_and_amount {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .quotes_value,
    .quotes_amount {
      .quotes_value_heading,
      .quotes_amount_heading {
        font-size: ${typography.heading5};
        font-weight: ${FontWeight.medium};
        color: ${colorPalette.paragraphSecondaryolor};
      }

      .quotes_value_discription,
      .quotes_amount_discription {
        font-size: ${typography.heading5};
        font-weight: ${FontWeight.semibold};
        color: ${colorPalette.paragraphColor};
      }
    }
  }
  .remarks_and_action_button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .remarks {
      font-size: ${typography.heading5};
      color: ${colorPalette.paragraphColor};
      @media only screen and (max-width: 480px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .quotes_action_button {
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        width: 120px;
        padding: 10px;
        border-radius: 100px;
        cursor: pointer;
        font-weight: ${FontWeight.semibold};
        border: 1px solid ${colorPalette.primaryColor};
        @media only screen and (max-width: 480px) {
          width: 80px;
          padding: 8px;
          border-radius: 100px;
          cursor: pointer;
          font-weight: ${FontWeight.semibold};
          border: 1px solid ${colorPalette.primaryColor};
        }
      }

      .accept_quotes {
        background-color: ${colorPalette.primaryColor};
        color: white;
      }

      .reject_quotes {
        color: ${colorPalette.primaryRed};
      }
    }
  }

  .loadingMore {
    width: 100%;
    height: 40px;
    text-align: center;
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading5};
    color: ${colorPalette.paragraphColor};
    /* background-color: red; */
  }
`;
