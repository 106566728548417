import React from "react";
import { Link, useHistory } from "react-router-dom";
import "./header.js";
import { HeaderImage, HeaderStyle } from "./header.js";
import {
  ButtonPrimary2,
  ButtonSecondary2,
  ButtonTertiary2,
} from "../../../themes/components/button.js";
import styled from "styled-components";

const Header = () => {
  const history = useHistory();
  return (
    <HeaderStyle className="header">
      <HeaderImage className="bg"></HeaderImage>
      <div className="header-content">
        <div className="header-title">
          One point solution for <span>logistics</span> management.
        </div>
        <div className="header-subtitle">
          We take care of all First mile, Middle mile, Last mile and
          warehousing.
        </div>
        <div className="header-btn">
          <ButtonPrimary2 onClick={() => history.push("/login")}>
            <Link
              style={{ color: "inherit", textDecoration: "none" }}
              to="/login"
            >
              Sign In
            </Link>
          </ButtonPrimary2>
          {/* <a href="#know_more"> */}
          <ButtonTertiary2 className="signIn_customer">
            <Link
              to="/customer/login"
              style={{ textDecoration: "none", color: "inherit" }}
              className="customer-portal"
            >
              Customer Portal
            </Link>
          </ButtonTertiary2>
          <ButtonPrimary2
          //  className="become-transporter"
          >
            <Link
              to="/transporters-onboard"
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
              className="customer-portal"
              // target="_blank"
            >
              Become Partner
            </Link>
          </ButtonPrimary2>
          {/* </a> */}
        </div>
      </div>
    </HeaderStyle>
  );
};

export default Header;
