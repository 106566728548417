import React, { useEffect, useState } from "react";
import { useRef } from "react";
import "./delivery-invoice-payment.js";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import { ArrowBack, Close, Add } from "@mui/icons-material";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import UploadImage from "../../../assets/upload.svg";
import { useHistory, useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as moment from "moment";
import { paymentDetails, itemDetails } from "../../../redux/paymentSlice";
import {
  updatePendingTripInvoiceList,
  updateSelectedTrip,
} from "../../../redux/tripSlice.js";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import Snackbar from "@mui/material/Snackbar";

import {
  TextField,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  CircularProgress,
  Alert,
  createStyles,
  makeStyles,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { Drawer } from "@mui/material";
import {
  getInvoicePaymentModes,
  getPaymentZohobankAccount,
  getTripItems,
  getTripItemsWithDiscount,
  invoiceSyncZoho,
  TripPaymentPost,
} from "./DeliveryInvoiceService";
import { useSelector, useDispatch } from "react-redux";
import { getTripDetailsFromId } from "./DeliveryInvoiceService";
import { readLoginData } from "../../../helper/dataDecryptionHelper";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Delivery_invoice_payment_main_container,
  Payment_drawer,
} from "./delivery-invoice-payment.js";
import { colorPalette } from "../../../themes/theme.js";

function DeliveryInvoicePayment() {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { tripId } = useParams();

  const history = useHistory();
  const [state, setState] = useState({ right: false });

  const data = readLoginData();
   
  const vertical = "bottom";
  const horizontal = "right";

  const [itemsPriceList, setItemsPriceList] = useState([]);
  // const [totalAmount, setTotalAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("cash");
  const [paymentAttachment, setPaymentAttachment] = useState(null);
  const [expanded, setExpanded] = React.useState(false);

  const [transactionId, setTransactionId] = useState("");
  const [tripCostTotal, setTripCostTotal] = useState("");
  const [perBagCost, setPerBagCost] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const invoiceList = useSelector((state) => state.trip.pendingTripInvoiceList);
  const [selectedInvoice, setSelectedInvoice] = useState("0");
  const [selectedReferenceId, setSelectedReferenceId] = useState("");

  const [tripData, setTripData] = useState([]);

  const [errDeliveryInvoice, setErrDeliveryInvoice] = useState("");
  const [errDeliveryPayment, setErrDeliveryPayment] = useState("");
  const [currentImage, setCurrentImage] = useState([]);
  const [errTransactionId, setErrTransactionId] = useState("");
  const [invoicePaymentModeList, setInvoicePaymentModeList] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [selectedDiscountDetails, setSelectedDiscountDetails] = useState({});
  const [selectedDeliveryCharges, setSelectedDeliveryCharges] = useState({});
  const [allDiscountArray, setAllDiscountArray] = useState([]);
  const [allDeliveryChargesArray, setAllDeliveryChargesArray] = useState([]);

  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loadingPaymentHistory, setLoadingPaymentHistory] = useState(false);

  const getTripStatus = () => {
    const tripPickedStaus = allDeliveryChargesArray?.find(
      (data) => data.additional_data.reference_number == selectedInvoice
    );
    return tripPickedStaus?.status;
  };

  useEffect(() => {
    if (selectedInvoice) {
      getTripStatus();
    }
  }, [selectedInvoice]);

  const [loadingSync, setLoadingSync] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [zohoBankAccountList, setZohoBankAccountList] = useState([]);
  const [syncToastMessage, setSyncToastMessage] = useState({
    message: "",
    status: "",
  });

  const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState("");
  const [deliveryPaymentDetailsArray, setDeliveryPaymentDetailsArray] =
    useState([
      {
        particular_payment_mode: "0",
        particular_amount: "",
        particular_transaction_reference_id: "",
        particular_attachment: null,
        zoho_books_account_name: "0",
      },
    ]);

  const [errDeliveryPaymentArray, setErrDeliveryPaymentArray] = useState([
    {
      err_particular_payment_mode: "",
      err_particular_amount: "",
      err_particular_transaction_reference_id: "",
    },
  ]);
  const { selectedTrip } = useSelector((state) => state.trip);

  const reduceSum = (a, b) => {
    return (
      a.item_rate * a.unloaded_quantity + b.item_rate * b.unloaded_quantity
    );
  };

  // =========================================================================
  // const paymentHistory = [
  //   {
  //     id: 377,
  //     zoho_invoice_id: 356,
  //     zoho_books_account_name: "Petty Cash",
  //     zoho_books_account_id: "1457992000000000459",
  //     amount: 50.0,
  //     payment_mode: "cash",
  //     transaction_reference_id: "50",
  //     image: "AM1005501696480367_payments_cash_1696481562.jpeg",
  //     path: "invoice-payment-history/AM1005501696480367/",
  //     bucket: "faarms-logistics-dev",
  //     remarks: "",
  //     is_active: true,
  //     created_on: "2023-10-05T04:52:42.683395",
  //     modified_on: "2023-10-05T04:56:37.150762",
  //     submitted_by: "",
  //     recommended_by: null,
  //     approved_by: null,
  //     reference_number: "INV-000022",
  //     customer_payment_status: "rejected",
  //   },
  //   {
  //     id: 378,
  //     zoho_invoice_id: 356,
  //     zoho_books_account_name: "Petty Cash",
  //     zoho_books_account_id: "1457992000000000459",
  //     amount: 50.0,
  //     payment_mode: "credit_card",
  //     transaction_reference_id: "oiu89",
  //     image: "AM1005501696480367_payments_credit_card_1696481562.jpeg",
  //     path: "invoice-payment-history/AM1005501696480367/",
  //     bucket: "faarms-logistics-dev",
  //     remarks: "",
  //     is_active: true,
  //     created_on: "2023-10-05T04:52:42.880582",
  //     modified_on: "2023-10-05T05:00:11.843417",
  //     submitted_by: "",
  //     recommended_by: null,
  //     approved_by: null,
  //     reference_number: "INV-000022",
  //     customer_payment_status: "rejected",
  //   },
  //   {
  //     id: 379,
  //     zoho_invoice_id: 356,
  //     zoho_books_account_name: "Petty Cash",
  //     zoho_books_account_id: "1457992000000000459",
  //     amount: 20.0,
  //     payment_mode: "cash",
  //     transaction_reference_id: "1234fd",
  //     image: "AM1005501696480367_payments_cash_1696482058.jpeg",
  //     path: "invoice-payment-history/AM1005501696480367/",
  //     bucket: "faarms-logistics-dev",
  //     remarks: "",
  //     is_active: true,
  //     created_on: "2023-10-05T05:00:59.146234",
  //     modified_on: "2023-10-05T05:03:54.655891",
  //     submitted_by: "rajeev123@yopmail.com",
  //     recommended_by: "rajeev123@yopmail.com",
  //     approved_by: "rajeev123@yopmail.com",
  //     reference_number: "INV-000022",
  //     customer_payment_status: "approved",
  //   },
  //   {
  //     id: 380,
  //     zoho_invoice_id: 356,
  //     zoho_books_account_name: "Petty Cash",
  //     zoho_books_account_id: "1457992000000000459",
  //     amount: 30.0,
  //     payment_mode: "cash",
  //     transaction_reference_id: "1234fd",
  //     image: "AM1005501696480367_payments_cash_1696482058.jpeg",
  //     path: "invoice-payment-history/AM1005501696480367/",
  //     bucket: "faarms-logistics-dev",
  //     remarks: "",
  //     is_active: true,
  //     created_on: "2023-10-05T05:00:59.146234",
  //     modified_on: "2023-10-05T05:03:54.655891",
  //     submitted_by: "rajeev123@yopmail.com",
  //     recommended_by: "rajeev123@yopmail.com",
  //     approved_by: "rajeev123@yopmail.com",
  //     reference_number: "INV-000022",
  //     customer_payment_status: "approved",
  //   },
  //   {
  //     id: 390,
  //     zoho_invoice_id: 356,
  //     zoho_books_account_name: "Petty Cash",
  //     zoho_books_account_id: "1457992000000000459",
  //     amount: 10.0,
  //     payment_mode: "cash",
  //     transaction_reference_id: "1234fd",
  //     image: "AM1005501696480367_payments_cash_1696482058.jpeg",
  //     path: "invoice-payment-history/AM1005501696480367/",
  //     bucket: "faarms-logistics-dev",
  //     remarks: "",
  //     is_active: true,
  //     created_on: "2023-10-05T05:00:59.146234",
  //     modified_on: "2023-10-05T05:03:54.655891",
  //     submitted_by: "rajeev123@yopmail.com",
  //     recommended_by: "rajeev123@yopmail.com",
  //     approved_by: "rajeev123@yopmail.com",
  //     reference_number: "INV-000022",
  //     customer_payment_status: "approved",
  //   },
  //   // {
  //   //   id: 391,
  //   //   zoho_invoice_id: 356,
  //   //   zoho_books_account_name: "Petty Cash",
  //   //   zoho_books_account_id: "1457992000000000459",
  //   //   amount: 40.0,
  //   //   payment_mode: "cash",
  //   //   transaction_reference_id: "1234fd",
  //   //   image: "AM1005501696480367_payments_cash_1696482058.jpeg",
  //   //   path: "invoice-payment-history/AM1005501696480367/",
  //   //   bucket: "faarms-logistics-dev",
  //   //   remarks: "",
  //   //   is_active: true,
  //   //   created_on: "2023-10-05T05:00:59.146234",
  //   //   modified_on: "2023-10-05T05:03:54.655891",
  //   //   submitted_by: "rajeev123@yopmail.com",
  //   //   recommended_by: "rajeev123@yopmail.com",
  //   //   approved_by: "rajeev123@yopmail.com",
  //   //   reference_number: "INV-000022",
  //   //   customer_payment_status: "approved",
  //   // },
  // ];

  const approvedPayment = paymentHistory.filter(
    (item) => item.customer_payment_status !== "rejected"
  );

  const calulateApprovedPaymentTotal = approvedPayment.reduce((acc, item) => {
    return acc + item.amount;
  }, 0);

  // =============================================================================

  const isPaymentFormValid = (paymentArray) => {
    if (
      // paymentArray[0].zoho_books_account_name == "" ||
      paymentArray[0].particular_payment_mode == "0" ||
      paymentArray[0].particular_amount == "" ||
      paymentArray[0].particular_attachment == null ||
      paymentArray[0].particular_transaction_reference_id == ""
    ) {
      // return false;
      setErrDeliveryPayment("*Please Fill All Required Fields.");
    } else {
      // bank account validation
      // const isBankValid = paymentArray
      //   .filter((data) => data.particular_amount !== "")
      //   .map((item) => {
      //     if (item.zoho_books_account_name === "0") {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   });

      // if (isBankValid.indexOf(false) >= 0) {
      //   setErrDeliveryPayment("Please select Bank Account.");
      //   return false;
      // }

      // if (isPaymentModeValid.indexOf(false) >= 0) {
      //   setErrDeliveryPayment("Please select payment mode");
      //   return false;
      // }
      //amount validation
      const isAmountValid = paymentArray
        .filter((data) => data.particular_amount !== "")
        .map((item) => parseFloat(item.particular_amount))
        .reduce((a, b) => a + b);

      if (
        totalAmountAfterDiscount - calulateApprovedPaymentTotal !=
        isAmountValid
      ) {
        setErrDeliveryPayment("Entered amount is not equal to total amount");
        return false;
      }

      //payment mode validation
      // const isPaymentModeValid = paymentArray
      //   .filter((data) => data.particular_amount !== "")
      //   .map((item) => {
      //     if (item.particular_payment_mode === "0") {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   });
      // //
      // if (isPaymentModeValid.indexOf(false) >= 0) {
      //   setErrDeliveryPayment("Please select payment mode");
      //   return false;
      // }

      //reference id validation

      // const isReferenceIdValid = paymentArray
      //   .filter((data) => data.particular_amount !== "")
      //   .map((item) => {
      //     if (item.particular_payment_mode === "cash") {
      //       return true;
      //     } else if (item.particular_transaction_reference_id) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   });

      // if (isReferenceIdValid.indexOf(false) >= 0) {
      //   setErrDeliveryPayment("Please enter valid reference number");
      //   return false;
      // }

      // image size valid
      const isImageSizeValid = paymentArray
        .filter((data) => data.particular_amount !== "")
        .map((item) => {
          if (!item.particular_attachment) {
            return;
          } else if (
            item.particular_attachment.size >
            process.env.REACT_APP_MAX_FILE_SIZE
          ) {
            return false;
          } else {
            return true;
          }
        });

      if (isImageSizeValid.indexOf(false) >= 0) {
        // setValidImageSize(false);
        setErrDeliveryPayment("Please select file with size less than 2MB");
        return false;
      }

      // attachment valid
      const isAttachmentValid = paymentArray
        .filter((data) => data.particular_amount !== "")
        .map((item) => {
          if (
            item.particular_attachment.type == "image/png" ||
            item.particular_attachment.type == "image/jpeg" ||
            item.particular_attachment.type == "image/jpg"
          ) {
            return true;
          } else {
            return false;
          }
        });

      if (isAttachmentValid.indexOf(false) >= 0) {
        // setValidImageFormat(false);
        setErrDeliveryPayment("Only Image/png files are allowed");
        return false;
      }

      // comment in the end

      return true;
    }
  };

  const resetForm = () => {
    setTransactionId("");
    setPaymentAttachment(null);
    setErrDeliveryPayment("");
    setDeliveryPaymentDetailsArray([
      {
        particular_payment_mode: "0",
        particular_amount: "",
        particular_transaction_reference_id: "",
        particular_attachment: null,
      },
    ]);
    setErrDeliveryPaymentArray([
      {
        err_particular_payment_mode: "",
        err_particular_amount: "",
        err_particular_transaction_reference_id: "",
      },
    ]);
  };

  // const [invoiceList, setInvoiceList] = useState([
  //   "PB-22-23-0001441",
  //   "PB-22-23-0001440",
  // ]);

  useEffect(() => {
    if (Object.keys(selectedTrip).length === 0) {
      getTripDetails(tripId);
    }
  }, [selectedTrip, tripId]);

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const getTripDetails = async (id) => {
    const response = await getTripDetailsFromId(id);

    dispatch(updateSelectedTrip(response));
    dispatch(updatePendingTripInvoiceList(response.pending_reference_numbers));
  };

  //

  const handleSyncZoho = async () => {
    setLoadingSync(true);
    const response = await invoiceSyncZoho(
      selectedInvoice,
      selectedReferenceId,
      tripId
    );

    if (response) {
      if (response.status >= 200 && response.status <= 300) {
        const response1 = await getTripItemsDiscount(selectedTrip?.trip_code);
        // if (response1.status >= 200 && response1.status <= 300) {
        //   const response2 = await getDeliverydata(selectedInvoice);
        // }
        setOpenToast(true);
        setSyncToastMessage({
          message: "Resync Successful",
          status: "success",
        });
      } else if (response.status === 400) {
        setOpenToast(true);
        setSyncToastMessage({
          message: response?.data?.detail
            ? response.data.detail
            : "Bad request",
          status: "error",
        });
      } else if (response.status === 401) {
        setOpenToast(true);
        setSyncToastMessage({
          message: "Not enough permissions",
          status: "error",
        });
      } else {
        setOpenToast(true);
        setSyncToastMessage({
          message: `${response?.status} : ERROR`,
          status: "error",
        });
      }
    }
    setLoadingSync(false);
  };

  const handleChangeDeliveryPayment = (e, index) => {
    const key = e.target.name;
    const value = e.target.value;
    let deliveryPaymentArrayTemp = [...deliveryPaymentDetailsArray];
    deliveryPaymentArrayTemp[index][key] = value;
    setDeliveryPaymentDetailsArray(deliveryPaymentArrayTemp);

    setErrDeliveryPayment("");
  };
  const handleChangeBankName = (e, index, bankList) => {
    const key = e.target.name;
    const value = e.target.value;
    let bankPaymentArrayTemp = [...deliveryPaymentDetailsArray];
    bankPaymentArrayTemp[index][key] = value;
    setDeliveryPaymentDetailsArray(bankPaymentArrayTemp);
    const filtredBankList = bankList.filter(
      (data) => data.account_name == value
    );
    if (filtredBankList.length > 0) {
      let bankPaymentArrayTempId = [...deliveryPaymentDetailsArray];
      bankPaymentArrayTempId[index]["zoho_books_account_id"] =
        filtredBankList[0].account_id;
      setDeliveryPaymentDetailsArray(bankPaymentArrayTempId);
    }
    //

    // setErrDeliveryPayment("");
  };

  const handleAddDeliveryPayment = () => {
    let paymentArrayTemp = [...deliveryPaymentDetailsArray];
    paymentArrayTemp.push({
      particular_payment_mode: "0",
      particular_amount: "",
      particular_transaction_reference_id: "",
      particular_attachment: null,
    });
    setDeliveryPaymentDetailsArray(paymentArrayTemp);
    setValidImageFormat(false);
    setValidImageSize(false);
  };

  const handleRemovePayment = (index) => {
    let paymentArrayTemp = [...deliveryPaymentDetailsArray];
    paymentArrayTemp.splice(index, 1);
    setDeliveryPaymentDetailsArray(paymentArrayTemp);
  };

  useEffect(() => {
    getInvoicePaymentList();
  }, []);

  const getInvoicePaymentList = async () => {
    const response = await getInvoicePaymentModes();
    setInvoicePaymentModeList(response);
  };

  const handleChangeUnloadedQuantity = (e, index) => {
    let tripItemsTemp = [...tripData];

    tripItemsTemp[index]["blocked_quantity_for_invoice_payment"] = parseInt(
      e.target.value
    );
    // tripItemsTemp[index]["unloaded_quantity"] = parseInt(0);

    setTripData(tripItemsTemp);

    setErrDeliveryInvoice("");
  };

  // useEffect(() => {
  //   if (invoiceList.length === 0) {
  //     getTripDetails(tripId);
  //   }
  // }, [invoiceList, tripId]);

  useEffect(() => {
    if (selectedTrip.trip_cost) {
      if (selectedTrip.trip_cost.length > 0) {
        //trip cost
        const tripCostTemp = selectedTrip.trip_cost.filter(
          (item) => item.cost_type === "trip_cost"
        );
        if (
          tripCostTemp.length > 0 &&
          selectedTrip.blocked_quantity_for_invoice_payment
        ) {
          setTripCostTotal(tripCostTemp[0].cost_amount);
          setPerBagCost(
            (
              tripCostTemp[0].cost_amount /
              selectedTrip.blocked_quantity_for_invoice_payment
            ).toFixed(2)
          );
        } else if (tripCostTemp.length > 0 && selectedTrip.quantity) {
          setTripCostTotal(tripCostTemp[0].cost_amount);
          setPerBagCost(
            (tripCostTemp[0].cost_amount / selectedTrip.quantity).toFixed(2)
          );
        }
      }
    }
  }, [selectedTrip]);

  const handleGoToTrips = () => {
    if (localStorage.getItem("createTripSource") == "/ftl-trips") {
      history.push("/ftl-trips");
    } else {
      history.push("/faarms-trip");
    }
  };

  const handleOpenMenu = () => {
    if (totalAmountAfterDiscount > 0) {
      toggleDrawer("right", true);
    } else {
      setErrDeliveryInvoice("Please select valid quantity");
    }
  };

  const handlePayNow = async () => {
    //
    if (isPaymentFormValid(deliveryPaymentDetailsArray)) {
      setLoadingButton(true);

      const response = await TripPaymentPost(
        paymentMode,
        // totalAmountAfterDiscount,
        totalAmountAfterDiscount - calulateApprovedPaymentTotal,
        selectedInvoice,
        paymentAttachment,
        transactionId,
        // tripData,
        // tripData.forEach((object) => {
        //   object.unloaded_quantity = 0;
        // }),
        tripData.map(function (item) {
          delete item.unloaded_quantity;
          return item;
        }),
        deliveryPaymentDetailsArray
      );
      if (response.status === 200) {
        setLoadingButton(false);
        resetForm();
        setPaymentStatus(true);
        setTripData([]);
        // setTotalAmount("");
        handleClosePaymentDrawer();
        getTripDetails(tripId);
        setValidImageSize(false);
        setValidImageFormat(false);
        getDeliverydata(selectedInvoice);
        // dispatch(updatePendingTripInvoiceList(["MP-22-23-0001695"]));
      } else if (response.status === 401) {
        setLoadingButton(false);
        setErrDeliveryPayment("Not enough permissions");
      } else if (response.status === 400) {
        setLoadingButton(false);
        setErrDeliveryPayment(response.data.detail);
      } else if (response.status === 404) {
        setLoadingButton(false);
        setErrDeliveryPayment(response.data.detail);
      } else {
        setLoadingButton(false);
        setErrDeliveryPayment(`${response.status} : ERROR`);
      }
    } else {
      setErrTransactionId("Please enter valid Transaction Id");
    }
  };

  const toggleDrawer = (anchor, open) => {
    //
    setState({ ...state, [anchor]: open });
  };
  const [validImageSize, setValidImageSize] = useState(false);
  const [validImageFormat, setValidImageFormat] = useState(false);

  const handlePaymentAttachment = (event, index) => {
    //
    const filtred = deliveryPaymentDetailsArray.filter(
      (item) => item.index == index
    );

    let deliveryPaymentTemp = [...deliveryPaymentDetailsArray];
    deliveryPaymentTemp[index]["particular_attachment"] = event.target.files[0];
    // setPaymentAttachment(event.target.files[0]);
    setDeliveryPaymentDetailsArray([...deliveryPaymentTemp]);
    setErrDeliveryPayment("");
    setCurrentImage([...deliveryPaymentTemp]);

    const isImageSizeValid = deliveryPaymentTemp
      .filter((data) => data.particular_amount !== "")
      .map((item) => {
        if (!item.particular_attachment) {
          return;
        } else if (
          item.particular_attachment.size > process.env.REACT_APP_MAX_FILE_SIZE
        ) {
          return false;
        } else {
          return true;
        }
      });

    if (isImageSizeValid.indexOf(false) >= 0) {
      setValidImageSize(false);
      setErrDeliveryPayment("Please select file with size less than 2MB");
      return false;
    } else {
      setValidImageSize(true);
    }

    // attachment valid
    const isAttachmentValid = deliveryPaymentTemp
      .filter((data) => data.particular_amount !== "")
      .map((item) => {
        if (
          item.particular_attachment.type == "image/png" ||
          item.particular_attachment.type == "image/jpeg" ||
          item.particular_attachment.type == "image/jpg"
        ) {
          return true;
        } else {
          return false;
        }
      });

    if (isAttachmentValid.indexOf(false) >= 0) {
      setValidImageFormat(false);
      setErrDeliveryPayment("Only Image/png files are allowed");
      return false;
    } else {
      setValidImageFormat(true);
    }
  };

  useEffect(() => {
    if (tripData.length > 0) {
      setItemsPriceList(
        tripData.map((item) =>
          item.blocked_quantity_for_invoice_payment
            ? item.blocked_quantity_for_invoice_payment
            : 0 * item.item_rate
        )
      );

      if (
        selectedDiscountDetails.discount_amount &&
        tripData
          .map(
            (item) =>
              (item.blocked_quantity_for_invoice_payment
                ? item.blocked_quantity_for_invoice_payment
                : 0) * item.item_rate
          )
          .reduce((a, b) => a + b)
      ) {
        setTotalAmountAfterDiscount(
          tripData
            .map(
              (item) =>
                (item.blocked_quantity_for_invoice_payment
                  ? item.blocked_quantity_for_invoice_payment
                  : 0) * item.item_rate
            )
            .reduce((a, b) => a + b) +
            (selectedDeliveryCharges.delivery_charges
              ? selectedDeliveryCharges.delivery_charges
              : 0) -
            selectedDiscountDetails.discount_amount
        );
      } else if (
        !tripData
          .map(
            (item) =>
              (item.blocked_quantity_for_invoice_payment
                ? item.blocked_quantity_for_invoice_payment
                : 0) * item.item_rate
          )
          .reduce((a, b) => a + b)
      ) {
        setTotalAmountAfterDiscount(
          tripData
            .map(
              (item) =>
                (item.blocked_quantity_for_invoice_payment
                  ? item.blocked_quantity_for_invoice_payment
                  : 0) * item.item_rate
            )
            .reduce((a, b) => a + b)
        );
      } else {
        setTotalAmountAfterDiscount(
          tripData
            .map(
              (item) =>
                (item.blocked_quantity_for_invoice_payment
                  ? item.blocked_quantity_for_invoice_payment
                  : 0) * item.item_rate
            )
            .reduce((a, b) => a + b) +
            (selectedDeliveryCharges.delivery_charges
              ? selectedDeliveryCharges.delivery_charges
              : 0)
        );
      }
    }
  }, [tripData, selectedDiscountDetails, selectedDeliveryCharges]);

  const handleClosePaymentDrawer = () => {
    toggleDrawer("right", false);
    resetForm();
    setValidImageSize(false);
    setValidImageFormat(false);
  };

  const getDeliverydata = async (getInvoice) => {
    setLoadingPaymentHistory(true);
    const responseDelivery = await getTripItems(tripId, getInvoice);

    if (responseDelivery) {
      setLoadingPaymentHistory(false);
      setPaymentHistory(responseDelivery.invoice_payment_history);
      setTripData(
        responseDelivery.trip_items.map((item) => ({
          ...item,
          blocked_quantity_for_invoice_payment: item.item_qty,
        }))
      );
      const filteredData = allDiscountArray.filter((item) => {
        return item.reference_number === getInvoice;
      });
      const filteredDeliveryData = allDeliveryChargesArray.filter((item) => {
        return item.additional_data.reference_number === getInvoice;
      });
      if (filteredData.length > 0) {
        setSelectedDiscountDetails(filteredData[0]);
      }
      if (filteredDeliveryData.length > 0) {
        setSelectedDeliveryCharges(filteredDeliveryData[0]);
      }
      // setSelectedDeliveryCharges()
      if (responseDelivery.trip_items.length > 0) {
        setSelectedReferenceId(
          responseDelivery.trip_items[0]?.reference_source_id
        );
      }
    }
  };

  const getTripItemsDiscount = async (tripCode) => {
    const response = await getTripItemsWithDiscount(tripCode);
    if (response) {
      setAllDiscountArray(response.trip_invoice_discounts);
      setAllDeliveryChargesArray(response.trip_entity_mapping);
      // setPaymentHistory(response.invoice_payment_history_list);
    }
    // if (response) {
    //   setTripData(
    //     response.map((item) => ({
    //       ...item,
    //       unloaded_quantity: item.item_qty,
    //     }))
    //   );
    // }
  };

  useEffect(() => {
    if (selectedTrip.trip_code) {
      getTripItemsDiscount(selectedTrip.trip_code);
    }
  }, [selectedTrip.trip_code, selectedInvoice]);

  const handleInvoice = (e) => {
    setSelectedInvoice(e.target.value);
    setErrDeliveryInvoice("");

    // getDeliverydata();
  };
  useEffect(() => {
    if (selectedInvoice != "0") {
      getDeliverydata(selectedInvoice);
    }
  }, [selectedInvoice, allDiscountArray]);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  // const  = Math.floor(Date.now() / 1000);

  let timeStamp = "";
  let newTimeStamp = "";
  let unique = "";

  if (Object.keys(selectedTrip).length > 0) {
    timeStamp = Math.floor(Date.now() / 1000);
    newTimeStamp = JSON.stringify(timeStamp).slice(-5);

    unique = `${selectedTrip.trip_code}_${selectedTrip.reference_number[0]
      .split("-")
      .join("_")}${newTimeStamp}`;
  }

  //

  const handleOnlinePayment = () => {
    dispatch(
      itemDetails({
        module: "logistics_web",
        app_redirect_success_url: "/payment-success",
        app_redirect_failure_url: "/payment-failure",
        auth_token: data.AUTH_TOKEN,
        total: totalAmountAfterDiscount,
        data: tripData,
      })
    );
    dispatch(
      paymentDetails({
        app: `Last-mile-delivery-payment-for-invoice-${selectedTrip.reference_number}`,
        trip_code: `${selectedTrip.trip_code}`,
        invoice_id: `${selectedTrip.reference_number}`,
        biller_name: "Faarms",
        uniqueId: unique,
        total: totalAmountAfterDiscount,
      })
    );
    history.push("/faarms-payment");
  };
  const hiddenFileInput = useRef(null);

  const zohoBankAccountId = async () => {
    const res = await getPaymentZohobankAccount();
    if (res) {
      setZohoBankAccountList(res.bankaccounts);
    }
  };
  useEffect(() => {
    zohoBankAccountId();
  }, []);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    // const fileUploaded = event.target.files[0];
    // handleFile(fileUploaded);                   // ADDED
  };

  useEffect(() => {
    if (validImageSize && validImageFormat) {
      handleAddDeliveryPayment();
    }
  }, [validImageSize, validImageFormat]);
  function capitalizeLetter(str) {
    var i,
      splittedArray = str.split("_");
    for (i = 0; i < splittedArray.length; i++) {
      splittedArray[i] =
        splittedArray[i].charAt(0).toUpperCase() + splittedArray[i].slice(1);
    }
    return splittedArray.join(" ");
  }

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Delivery_invoice_payment_main_container className="delivery-invoice-payment-main-container">
      <Drawer
        className="drawer"
        anchor={"right"}
        open={state["right"]}
        onClose={handleClosePaymentDrawer}
      >
        <Payment_drawer className="payment-drawer">
          <div className="payment-wrapper">
            <div className="delivery-invoice-close">
              <Close
                style={{ cursor: "pointer" }}
                onClick={handleClosePaymentDrawer}
              />{" "}
            </div>
            <div className="payment-headings">
              <h4 className="delivery-invoice-input-head">
                Payment details for Invoice ({selectedInvoice})
              </h4>
            </div>
            <div className="payment-total">
              <h4 className="delivery-invoice-input-head">
                Total Amount :{" "}
                <span style={{ fontFamily: "sans-serif" }}> ₹ </span>
                {totalAmountAfterDiscount - calulateApprovedPaymentTotal}
              </h4>
            </div>
            {/* new design========================== */}
            <div className="table-main-container">
              <div className="table-head">
                <div className="table-head-data">Account</div>
                <div className="table-head-data">Mode</div>
                <div className="table-head-data">Amount(₹)</div>
                <div className="table-head-data">Reference Id</div>
                <div className="table-head-data">Document</div>
              </div>
            </div>
            {/* ============end======================= */}

            {/* <div
            // className="delivery-invoice-payment-mode"
            // style={{ flex: "1" }}
            >
              <h4 className="delivery-invoice-input-head">
                Select Bank Account
              </h4>
              <TextField
                size="small"
                id="dropdown"
                autoComplete="off"
                select
                name="particular_payment_mode"
                className="create-trip-input"
                style={{ width: "50%" }}
                variant="outlined"
                placeholder="Enter transaction reference id"
                value={item.particular_payment_mode}
                onChange={(e) => {
                  handleChangeDeliveryPayment(e, index);
                  setTransactionId(e.target.value);
                }}
              >
                <MenuItem disabled value="0">
                  select
                </MenuItem>
                {zohoBankAccountList.map((item) => {
                  return (
                    <MenuItem value={item}>
                      {item?.account_name.split("_").join(" ").toUpperCase()}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div> */}
            {deliveryPaymentDetailsArray.map((item, index) => {
              return (
                <>
                  <div className="table-value" key={index}>
                    <div className="table-head-data-fields">
                      <TextField
                        size="small"
                        id="dropdown"
                        autoComplete="off"
                        select
                        name="zoho_books_account_name"
                        // className="create-trip-input"
                        style={{ width: isMobile ? "10ch" : "18ch" }}
                        variant="outlined"
                        // placeholder="Enter transaction reference id"
                        value={item.zoho_books_account_name}
                        onChange={(e) => {
                          handleChangeBankName(e, index, zohoBankAccountList);
                          // setTransactionId(e.target.value);
                        }}
                      >
                        <MenuItem
                          disabled
                          value="0"
                          // style={{ fontSize: "12px" }}
                        >
                          select
                        </MenuItem>
                        {zohoBankAccountList.map((item) => {
                          return (
                            <MenuItem value={item.account_name}>
                              {item?.account_name
                                .split("_")
                                .join(" ")
                                .toUpperCase()}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                    <div className="table-head-data-fields">
                      {" "}
                      <TextField
                        size="small"
                        id="dropdown"
                        autoComplete="off"
                        select
                        name="particular_payment_mode"
                        // className="create-trip-input"
                        style={{ width: "80%" }}
                        variant="outlined"
                        placeholder="Enter transaction reference id"
                        value={item.particular_payment_mode}
                        onChange={(e) => {
                          handleChangeDeliveryPayment(e, index);
                          // if (item.particular_payment_mode == "cash") {
                          //   handleAddDeliveryPayment();
                          // }
                          // setTransactionId(e.target.value);
                        }}
                      >
                        <MenuItem disabled value="0">
                          select
                        </MenuItem>
                        {invoicePaymentModeList.map((item) => {
                          return (
                            <MenuItem value={item}>
                              {item.split("_").join(" ").toUpperCase()}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                    <div className="table-head-data-fields">
                      <TextField
                        size="small"
                        id="dropdown"
                        autoComplete="off"
                        // style={{ width: "96%" }}
                        style={{ width: "80%" }}
                        // className="create-trip-input"
                        variant="outlined"
                        type="number"
                        name="particular_amount"
                        placeholder="Enter Amount"
                        inputProps={{
                          sx: {
                            "&::placeholder": {
                              color: "#A4A4A4",
                              fontSize: "12px",
                              opacity: 1, // otherwise firefox shows a lighter color
                            },
                          },
                        }}
                        onKeyPress={(event) => {
                          if (!/^[0-9.]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={item.particular_amount}
                        onChange={(e) => {
                          handleChangeDeliveryPayment(e, index);

                          // setTransactionId(e.target.value);
                        }}
                      />
                    </div>
                    <div className="table-head-data-fields">
                      {/* {item.particular_payment_mode == "cash" ? null : ( */}
                      <TextField
                        size="small"
                        id="dropdown"
                        autoComplete="off"
                        name="particular_transaction_reference_id"
                        // className="create-trip-input"
                        style={{ width: "80%" }}
                        variant="outlined"
                        // sx={{
                        //   label: { fontSize: "5px" },
                        //   placeholder: { fontSize: "5px" },
                        // }}
                        placeholder="Enter transaction reference id"
                        inputProps={{
                          sx: {
                            "&::placeholder": {
                              color: "#A4A4A4",
                              fontSize: "12px",
                              opacity: 1, // otherwise firefox shows a lighter color
                            },
                          },
                        }}
                        value={item.particular_transaction_reference_id}
                        onChange={(e) => {
                          handleChangeDeliveryPayment(e, index);
                          // setTransactionId(e.target.value);
                        }}
                      />
                      {/* )} */}
                    </div>
                    <div className="table-head-data-fields">
                      {/* {item.particular_payment_mode == "cash" ? null : ( */}
                      <>
                        {" "}
                        <button className="button-upload" onClick={handleClick}>
                          <UploadIcon /> Upload
                          {item.particular_attachment !== null ? (
                            <CheckIcon
                              className="delivery-right-payment-button"
                              style={{
                                color: "green",
                                fontSize: "20px",
                                marginLeft: "10px",
                                fontWeight: "bolder",
                                borderRadius: "50%",
                              }}
                            />
                          ) : null}
                        </button>
                        <input
                          type="file"
                          key={index}
                          onChange={(e) => {
                            handlePaymentAttachment(e, index);
                            if (validImageSize && validImageFormat) {
                              handleAddDeliveryPayment();
                            }
                            // isPaymentFormValid(deliveryPaymentDetailsArray);
                            // if (isPaymentFormValid) {
                            //   handleAddDeliveryPayment();
                            // }

                            // handleAddDeliveryPayment();
                          }}
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                        />
                      </>
                      {/* // )} */}
                    </div>
                    {index !== 0 ? (
                      // <button
                      //   className="delivery-add-payment-button"
                      //   onClick={() => handleRemovePayment(index)}
                      // >
                      <span
                        className="delivery-add-payment-button"
                        style={{ fontWeight: "bold" }}
                        onClick={() => handleRemovePayment(index)}
                      >
                        <CancelOutlinedIcon />
                      </span>
                    ) : // </button>
                    null}{" "}
                  </div>

                  {/* <button
                    className="delivery-add-payment-button"
                    onClick={handleAddDeliveryPayment}
                  >
                    {" "}
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      <Add />
                    </span>{" "}
                  </button> */}
                </>
              );
            })}
            {/* <div className="delivery-invoice-payment-mode">
              <h4>Payment mode</h4>
              <TextField
                size="small"
                id="dropdown"
                autoComplete="off"
                select
                className="create-trip-input"
                variant="outlined"
                placeholder="Enter transaction reference id"
                value={transactionId}
                onChange={(e) => {
                  setErrTransactionId("");
                  setTransactionId(e.target.value);
                }}
              >
                <MenuItem>select</MenuItem>
              </TextField>
            </div>
            
            <div
              style={{ display: "flex", width: "100%", position: "relative" }}
            >
              <div style={{ width: "100%" }}>
                <h4>Reference Id</h4>
                <div style={{ width: "100%" }}>
                  <TextField
                    size="small"
                    id="dropdown"
                    autoComplete="off"
                    className="create-trip-input"
                    variant="outlined"
                    placeholder="Enter transaction reference id"
                    value={transactionId}
                    onChange={(e) => {
                      setErrTransactionId("");
                      setTransactionId(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{
                            cursor: "pointer",
                            color: colorPalette.primaryColor,
                            fontWeight: "600",
                          }}
                          // onClick={handleAddAttachment}
                        >
                          <button className="upload_button">
                            <input
                              // name="upload"
                              name="attachment_file"
                              id={`uploaddl`}
                              type="file"
                              accept="image/png, image/jpeg, application/pdf"
                              onChange={handlePaymentAttachment}
                              hidden
                              style={{
                                backgroundColor: colorPalette.secondaryWhite,
                              }}
                            />
                            <label
                              htmlFor={`uploaddl`}
                              style={{
                                backgroundColor: colorPalette.secondaryWhite,
                              }}
                            >
                              <img
                                src={UploadImage}
                                alt=""
                                width="20px"
                                height="20px"
                                style={{
                                  backgroundColor: colorPalette.secondaryWhite,
                                  // backgroundColor: "red",
                                  // width: "100%",
                                  // height: "100%",
                                }}
                              />
                            </label>
                          </button>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {transactionId ? null : (
                    <div className="delivery-error">{errTransactionId}</div>
                  )}
                  <div className="delivery-invoice-image-name">
                    {paymentAttachment ? paymentAttachment.name : null}
                  </div>
                  <div className="delivery-error">
                    {paymentAttachment
                      ? paymentAttachment.size > 2000000
                        ? "File size should be less than 2MB"
                        : null
                      : null}
                  </div>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  top: "24px",
                  // bottom: "0",
                }}
              >
                <button
                  className="delivery-add-payment-button"
                  style={{
                    width: "60px",
                    height: "40px",
                    padding: "7px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <span style={{ fontWeight: "bold" }}> + Add</span>{" "}
                </button>
              </div>
            </div> */}
            {errDeliveryPayment ? (
              <div style={{ width: "100%" }}>
                <Alert severity="error"> {errDeliveryPayment}</Alert>
              </div>
            ) : null}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <button
                className="button-recordpayment"
                style={{ width: "150px", margin: "5px" }}
                onClick={handlePayNow}
                disabled={loadingButton ? true : false}
              >
                {loadingButton ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </Payment_drawer>
      </Drawer>
      {isMobile ? null : (
        <div className="delivery-invoice-payment-sidebar-container">
          <SideBarMain />
        </div>
      )}
      <div className="delivery-invoice-payment-right-main-container">
        {isMobile ? (
          <div className="trip-list-mobile-header">
            <MobileHeader headerTitle="Delivery Status" />
          </div>
        ) : null}
        <div className="delivery-invoice-right-top">
          <ArrowBack style={{ cursor: "pointer" }} onClick={handleGoToTrips} />{" "}
          <span className="delivery-invoice-head-text">
            {" "}
            Delivery Invoice payment
          </span>
        </div>
        <Snackbar
          open={openToast}
          autoHideDuration={8000}
          anchorOrigin={{ vertical, horizontal }}
          onClose={handleCloseToast}
        >
          <Alert
            variant="filled"
            elevation={6}
            onClose={handleCloseToast}
            severity={syncToastMessage?.status}
            sx={{ width: "100%" }}
          >
            {syncToastMessage?.message ? syncToastMessage?.message : " - "}
          </Alert>
        </Snackbar>
        <div className="delivery-invoice-right-body-container">
          <div className="delivery-invoice-row">
            <div className="delivery-invoice-input-container">
              <h4 className="delivery-invoice-input-head">
                Trip code :{" "}
                {selectedTrip.trip_code ? selectedTrip.trip_code : " - "}
              </h4>
              <h4 className="delivery-invoice-input-head">
                Vehicle Number :{" "}
                {selectedTrip.vehicle_number
                  ? selectedTrip.vehicle_number
                  : " - "}
              </h4>
              <h4 className="delivery-invoice-input-head">
                Per bag cost : Rs. {perBagCost ? perBagCost : " - "}
              </h4>
            </div>
          </div>

          <div className="delivery-invoice-row" style={{ display: "flex" }}>
            <div className="delivery-invoice-input-container">
              <h4 className="delivery-invoice-input-head">Select Invoice</h4>
              <TextField
                size="small"
                select
                autoComplete="off"
                className="delivery-invoice-input"
                variant="outlined"
                value={selectedInvoice}
                onChange={(e) => handleInvoice(e)}
              >
                <MenuItem value="0" disabled>
                  Select invoice
                </MenuItem>
                {invoiceList.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </TextField>
            </div>
            <div style={{ marginLeft: "10px" }}>
              {selectedInvoice != "0" ? (
                <button
                  className="sync-button-delivery"
                  onClick={handleSyncZoho}
                >
                  {loadingSync ? (
                    <CircularProgress size={25} color="inherit" />
                  ) : (
                    <>
                      <SyncIcon /> <span>Sync</span>
                    </>
                  )}
                </button>
              ) : null}
            </div>
          </div>
        </div>
        {getTripStatus() !== "picked" && getTripStatus() !== undefined ? (
          <div className="delivery-invoice-right-body-container">
            <div className="delivery-invoice-row" style={{ display: "flex" }}>
              <h4
                style={{
                  color: "red",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                }}
              >
                <ErrorOutlineIcon
                  style={{ color: "red", marginRight: "5px" }}
                />{" "}
                Delivery of items cannot be done before pickup.
              </h4>
            </div>
          </div>
        ) : null}

        <div className="delivery-invoice-right-body-container">
          <div className="delivery-invoice-row" style={{ display: "flex" }}>
            {" "}
            <h4 className="delivery-invoice-input-head">Payment History</h4>
          </div>
        </div>
        {/* {selectedInvoice !== "0" ? ( */}
        {paymentHistory.length > 0 ? (
          <div
            className="delivery-invoice-items-container-history"
            style={{ height: "250px", overflowY: "scroll" }}
          >
            {paymentHistory.map((item, index) => {
              return (
                <Accordion
                  key={index}
                  style={{
                    marginTop: "10px",
                    width: "98%",
                    marginLeft: "5px",
                  }}
                  onClick={(e) => {
                    // setLoadingPaymentApprove(false);
                    // setLoadingPaymentRejectedApprove(false);
                    // setPaymentstatusError("");
                    // setPaymentRemarks("");
                  }}
                  expanded={expanded === index}
                  onChange={handleChangeAccordion(index)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "#E2F1DB" }}
                  >
                    <Typography className="payment-history-headings">
                      <div className="payment-history-subheadings">
                        <div className="payment-history-subheadings-text">
                          Mode:
                        </div>
                        <div style={{ maxWidth: "30px" }}>
                          {" "}
                          {item.payment_mode
                            ? capitalizeLetter(item.payment_mode)
                            : null}
                        </div>
                      </div>
                      <div className="payment-history-subheadings">
                        <div className="payment-history-subheadings-text">
                          Amount(₹):
                        </div>
                        {item.amount ? item.amount : null}
                      </div>
                      <div className="payment-history-subheadings">
                        <div className="payment-history-subheadings-text">
                          Created on:
                        </div>
                        {item.created_on
                          ? moment(item.created_on).format("DD MMM YYYY")
                          : null}
                      </div>
                      <div className="payment-history-subheadings">
                        <div className="payment-history-subheadings-text">
                          Status:
                        </div>
                        {item.customer_payment_status == "submitted" ? (
                          <div style={{ color: "#BF9000" }}>
                            {" "}
                            {item.customer_payment_status
                              ? item.customer_payment_status.toUpperCase()
                              : "N.A"}
                          </div>
                        ) : item.customer_payment_status == "recommended" ? (
                          <div style={{ color: "#C082A1" }}>
                            {" "}
                            {item.customer_payment_status
                              ? item.customer_payment_status.toUpperCase()
                              : "N.A"}
                          </div>
                        ) : item.customer_payment_status == "submitted" ? (
                          <div style={{ color: "#134F5C" }}>
                            {" "}
                            {item.customer_payment_status
                              ? item.customer_payment_status.toUpperCase()
                              : "N.A"}
                          </div>
                        ) : item.customer_payment_status == "approved" ? (
                          <div style={{ color: "#2C5E17" }}>
                            {" "}
                            {item.customer_payment_status
                              ? item.customer_payment_status.toUpperCase()
                              : "N.A"}
                          </div>
                        ) : item.customer_payment_status == "rejected" ? (
                          <div style={{ color: "red" }}>
                            {" "}
                            {item.customer_payment_status
                              ? item.customer_payment_status.toUpperCase()
                              : "N.A"}
                          </div>
                        ) : (
                          "N.A"
                        )}
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mini-card-delivery-info-container-payment-history">
                      <div className="text-fields-payment-history">
                        {/* <label htmlFor="">Remarks</label> */}
                        {item.customer_payment_status == "approved" ||
                        item.customer_payment_status == "rejected" ? (
                          <>
                            {" "}
                            <div className="inoice-details-div">
                              {/* <div className="invoice-details-text">
                                <div className="payment-history-subheadings-text">
                                  Invoice:
                                </div>
                                {item.reference_number
                                  ? item.reference_number
                                  : "--"}
                              </div> */}
                              <div className="invoice-details-text">
                                {/* Invoice: */}
                                <div className="payment-history-subheadings-text">
                                  Reference Id:
                                </div>{" "}
                                {item.transaction_reference_id
                                  ? item.transaction_reference_id
                                  : "--"}
                              </div>
                              <div className="invoice-details-text">
                                {/* Account: */}
                                <div className="payment-history-subheadings-text">
                                  Account:
                                </div>
                                {item.zoho_books_account_name
                                  ? item.zoho_books_account_name
                                  : "--"}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="inoice-details-div">
                              {/* <div className="invoice-details-text">
                                <div className="payment-history-subheadings-text">
                                  Invoice:
                                </div>{" "}
                             
                                {item.reference_number
                                  ? item.reference_number
                                  : "--"}
                              </div> */}
                              <div className="invoice-details-text">
                                <div className="payment-history-subheadings-text">
                                  Reference Id:
                                </div>{" "}
                                {/* Invoice: */}
                                {item.transaction_reference_id
                                  ? item.transaction_reference_id
                                  : "--"}
                              </div>
                              <div className="invoice-details-text">
                                {/* Account: */}
                                <div className="payment-history-subheadings-text">
                                  Account:
                                </div>{" "}
                                {item.zoho_books_account_name
                                  ? item.zoho_books_account_name
                                  : "--"}
                              </div>
                            </div>

                            {/* {paymentstatusError ? (
                                        <div
                                          style={{
                                            fontSize: "10px",
                                            color: "red",
                                          }}
                                        >
                                          Error: {paymentstatusError}
                                        </div>
                                      ) : null} */}
                          </>
                        )}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        ) : loadingPaymentHistory == true ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <CircularProgress size={15} />
            <div>Loading data...</div>
          </div>
        ) : (
          <div
            className="delivery-invoice-items-container-history"
            style={{
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                // width: "200%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                // height: "4rem",
                // backgroundColor: "red",
              }}
            >
              <div style={{ fontSize: "12px" }}>No payment history found.</div>
            </div>
          </div>
        )}
        {/* ) : null} */}
        <div className="delivery-invoice-right-body-container">
          <div className="delivery-invoice-row" style={{ display: "flex" }}>
            {" "}
            <h4 className="delivery-invoice-input-head">Delivered Items</h4>
          </div>
        </div>
        <div className="delivery-invoice-items-container">
          <table id="invoice-items">
            <thead className="thead_heading">
              <tr className="table-head-row">
                <th className="column1">Item</th>
                <th className="column2">Actual qty</th>
                <th className="column3">Delivered qty</th>
                <th className="column4">Rate</th>
                <th className="column5">Total</th>
              </tr>
            </thead>
            {tripData.length > 0 ? (
              tripData.map((data, index) => (
                <tbody key={data.id} className="body">
                  <tr className="rows">
                    <td className="column1">{data.item_name}</td>

                    <td className="column2">{data.item_qty}</td>
                    <td className="column3" style={{ width: "20%" }}>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        value={data.blocked_quantity_for_invoice_payment}
                        onChange={(e) => {
                          if (data.item_qty >= e.target.value) {
                            handleChangeUnloadedQuantity(e, index);
                          }
                        }}
                        className="delivery-invoice-quantity-input"
                      />
                    </td>
                    <td className="column4">
                      <span
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        {" "}
                        ₹{" "}
                      </span>
                      {data.item_rate}
                    </td>
                    <td className="column5">
                      <span
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        ₹{" "}
                      </span>{" "}
                      {data.blocked_quantity_for_invoice_payment
                        ? data.item_rate *
                          data.blocked_quantity_for_invoice_payment
                        : "0.00"}
                    </td>
                  </tr>
                </tbody>
              ))
            ) : (
              <div
                style={{
                  // width: "200%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "4rem",
                  // backgroundColor: "red",
                }}
              >
                <h6 style={{ fontSize: "12px" }}>
                  {paymentStatus
                    ? "Payment recorded for this invoice"
                    : "Please select one invoice from the above list."}
                </h6>
              </div>
            )}
            {isMobile ? null : selectedInvoice !== "0" ? (
              <>
                <thead>
                  <tr style={{ borderTop: "1px solid black" }}>
                    <td className="column1"></td>
                    <td className="column2"></td>
                    <td className="column3"></td>
                    {/* <td className="total-amount">Discounted amount </td>
                  <td className="total-amount">sdsdsds</td> */}
                    <td className="total-amount">
                      {selectedDeliveryCharges?.delivery_charges &&
                      totalAmountAfterDiscount ? (
                        <>
                          <div>Delivery charges</div>
                          <hr></hr>
                        </>
                      ) : null}
                      {selectedDiscountDetails.discount_amount &&
                      totalAmountAfterDiscount ? (
                        <>
                          <div>Discount amount</div>
                          <hr></hr>
                        </>
                      ) : null}
                      <div>Total amount</div>{" "}
                    </td>

                    <td className="total-amount">
                      {selectedDeliveryCharges.delivery_charges &&
                      totalAmountAfterDiscount ? (
                        <div style={{ marginLeft: "0px" }}>
                          <div>
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: "14px",
                                marginLeft: "5px",
                              }}
                            >
                              {" "}
                              ₹{" "}
                            </span>
                            {selectedDeliveryCharges.delivery_charges
                              ? selectedDeliveryCharges.delivery_charges
                              : " - "}{" "}
                            (+)
                          </div>
                          <hr></hr>
                        </div>
                      ) : null}
                      {selectedDiscountDetails.discount_amount &&
                      totalAmountAfterDiscount ? (
                        <div style={{ marginLeft: "0px" }}>
                          <div>
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: "14px",
                                marginLeft: "5px",
                              }}
                            >
                              {" "}
                              ₹{" "}
                            </span>
                            {selectedDiscountDetails.discount_amount
                              ? selectedDiscountDetails.discount_amount
                              : " - "}
                            (-)
                          </div>
                          <hr></hr>
                        </div>
                      ) : null}
                      {loadingPaymentHistory ? (
                        <CircularProgress size={12} />
                      ) : (
                        <div>
                          <span
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                              marginLeft: "5px",
                            }}
                          >
                            {" "}
                            ₹{" "}
                          </span>
                          {totalAmountAfterDiscount}
                        </div>
                      )}
                    </td>
                  </tr>
                </thead>
                {selectedInvoice !== "0" ? (
                  <>
                    <thead>
                      <tr style={{ borderTop: "1px solid black" }}>
                        <td className="column1"></td>
                        <td className="column2"></td>
                        <td className="column3"></td>
                        {/* <td className="total-amount">Discounted amount </td>
                  <td className="total-amount">sdsdsds</td> */}
                        <td className="total-amount">
                          <div className="paid-amount-div">
                            Recorded Amount{" "}
                            <div className="paid-amount-sum">
                              (Submitted+Recommended+Approved)
                            </div>
                          </div>{" "}
                        </td>

                        <td className="total-amount">
                          {loadingPaymentHistory ? (
                            <CircularProgress size={12} />
                          ) : (
                            <div>
                              <span
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: "14px",
                                  marginLeft: "5px",
                                }}
                              >
                                {" "}
                                ₹{" "}
                              </span>
                              {calulateApprovedPaymentTotal
                                ? calulateApprovedPaymentTotal
                                : "0.00"}
                            </div>
                          )}
                        </td>
                      </tr>
                    </thead>
                    <thead>
                      <tr style={{ borderTop: "1px solid black" }}>
                        <td className="column1"></td>
                        <td className="column2"></td>
                        <td className="column3"></td>
                        {/* <td className="total-amount">Discounted amount </td>
                  <td className="total-amount">sdsdsds</td> */}
                        <td className="total-amount">
                          <div>Balance</div>{" "}
                        </td>

                        <td className="total-amount">
                          {loadingPaymentHistory ? (
                            <CircularProgress size={12} />
                          ) : (
                            <div style={{ color: "red" }}>
                              <span
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: "14px",
                                  marginLeft: "5px",
                                }}
                              >
                                {" "}
                                ₹{" "}
                              </span>
                              {totalAmountAfterDiscount -
                                calulateApprovedPaymentTotal}
                            </div>
                          )}
                        </td>
                      </tr>
                    </thead>
                  </>
                ) : null}
              </>
            ) : null}
          </table>

          {!isMobile &&
          selectedInvoice !== "0" &&
          getTripStatus() == "picked" &&
          totalAmountAfterDiscount - calulateApprovedPaymentTotal > 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "95%",
                  marginTop: "1rem",
                }}
              >
                {/* <button
                  className="button-recordpayment"
                  style={{ marginRight: "1rem" }}
                  onClick={handleOnlinePayment}
                >
                  Pay Online
                </button> */}
                <button
                  className="button-recordpayment_offline"
                  onClick={handleOpenMenu}
                >
                  Record Cash Payment
                </button>
              </div>
            </>
          ) : null}
        </div>
        <div
          style={{
            width: "100%",
            height: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {isMobile ? (
            <thead style={{ marginRight: "2rem", marginTop: "12px" }}>
              <tr style={{ borderTop: "1px solid black" }}>
                <td className="column1"></td>
                <td className="column2"></td>
                <td className="column3"></td>

                <td className="total-amount">
                  {" "}
                  {selectedDiscountDetails.discount_amount &&
                  totalAmountAfterDiscount ? (
                    <>
                      <div>Discount amount</div>
                      <hr></hr>
                    </>
                  ) : null}
                  <div>Total amount</div>{" "}
                </td>
                <td className="total-amount">
                  {selectedDiscountDetails.discount_amount &&
                  totalAmountAfterDiscount ? (
                    <div style={{ marginLeft: "0px" }}>
                      <div>
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: "14px",
                            marginLeft: "5px",
                          }}
                        >
                          {" "}
                          ₹{" "}
                        </span>
                        {selectedDiscountDetails.discount_amount
                          ? selectedDiscountDetails.discount_amount
                          : " - "}
                      </div>
                      <hr></hr>
                    </div>
                  ) : null}

                  <div>
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      {" "}
                      ₹{" "}
                    </span>
                    {totalAmountAfterDiscount}
                  </div>
                </td>
              </tr>
            </thead>
          ) : null}
        </div>
        {/* DeliveryInvoicePayment */}
      </div>{" "}
      {isMobile &&
      selectedInvoice !== "0" &&
      getTripStatus() == "picked" &&
      totalAmountAfterDiscount - calulateApprovedPaymentTotal > 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "95%",
            marginTop: "1rem",
          }}
        >
          {/* <button
            className="button-recordpayment"
            style={{ marginRight: "1rem" }}
            onClick={handleOnlinePayment}
          >
            Pay Online
          </button> */}
          <button
            className="button-recordpayment_offline"
            onClick={handleOpenMenu}
          >
            Record Cash Payment
          </button>
        </div>
      ) : null}
    </Delivery_invoice_payment_main_container>
  );
}

export default DeliveryInvoicePayment;
