import React from "react";
import { PartnershipStyle } from "./partnership";

const Partnership = () => {
  return (
    <PartnershipStyle className="partnership" id="partnership">
      <div className="partnership_heading">Partnership and Associations</div>
      <div className="partnership_company">
        <img src="/assets/landingPageAssets/Slide7.svg" alt="" />
      </div>
      <div className="partnership_company_mobile">
        <img src="/assets/landingPageAssets/Partners.png" alt="" />
      </div>
    </PartnershipStyle>
  );
};

export default Partnership;
