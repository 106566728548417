import {
  React,
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import SearchIcon from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import { Drawer } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import loader from "../../assets/dashboard_icons/icons8-truck.gif";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Carrier from "../../assets/dashboard_icons/icon-0.8s-215px.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { onboardRegisterPost } from "../onboard/OnboardServices";
import axios from "axios";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import { Component_style } from "../../themes/component_style";
import { useHistory } from "react-router-dom";
import apiUrl from "../../url/apiUrl";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { onboardData } from "../../redux/onboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { getCarrierList } from "../onboard/OnboardServices";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
// import { oemPost } from "./OemServices";
import { getModelList, vehicleModelPost, vehicleModelPut } from "./OemServices";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { searchVehicleModel } from "../../redux/searchSlice";
import axiosInterceptor from "../../config/https";
import { colorPalette, colorPaletteDark } from "../../themes/theme";
import { ButtonRectangle } from "../../themes/components/button";
import Topbar from "../../components/topbar/Topbar.jsx";
import { DarkModeContext } from "../../context/darkModeContext.js";
import { MyContext } from "../../components/enumContext/EnumContextComponent.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SomethingWentWrong from "../../components/somethingWentWrong/SomethingWentWrong.jsx";
import { Modal as ModalAntd } from "antd";
// import { getVehiclesList } from "./VehiclesServices";
function VehicleModel() {
  const { id } = useParams();

  // const History=useHistory()
  const [oem, setOem] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState("");
  //   const [oemResponse, setOemResponse] = useState({});
  const [modelData, setModelData] = useState([]);

  const [name, setName] = useState("");
  const [model, setModel] = useState("");
  const [wheels, setWheels] = useState("");
  const [vehicleType, setVehicleType] = useState("");

  const [maxCap, setMaxCap] = useState("");
  const [minCap, setMinCap] = useState("");

  const [classModel, setClassModel] = useState("");
  const [unit, setUnit] = useState("");
  const [vehicleTypeList, setVehicleTypeList] = useState([]);

  const [classTypeList, setClassTypeList] = useState([]);

  const [msgForModel, setMsgForModel] = useState("");
  const [errorMsgForModel, setErrorMsgForModel] = useState("");
  const [errorModelName, setErrorModelName] = useState("");
  const [errorModel, setErrorModel] = useState("");
  const [errorWheels, setErrorWheels] = useState("");
  const [errorVehicleType, setErrorVehicleType] = useState("");
  const [errorMax, setErrorMax] = useState("");
  const [errorMin, setErrorMin] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [errorClassModel, setErrorClassModel] = useState("");
  const [errorUnit, setErrorUnit] = useState("");

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [unitData, setUnitData] = useState([{ name: "ton" }]);

  const [loadingMore, setLoadingMore] = useState(true);
  const [vehicleId, setVehicleId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [units, setUnits] = useState("");
  const { enums } = useContext(MyContext);

  const searchTerm = useSelector(
    (data) => data.searchValues.searchVehicleModel
  );
  const [state, setState] = useState({ right: false });

  // const [searchValue, setSearchValue] = useState("");

  const observer = useRef();
  const lastItem = useCallback(
    (node) => {
      // if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage]
  );

  const getVehicleType = async () => {
    // var vehicle = await axiosInterceptor.get(
    //   `${apiUrl.onboardingBaseUrl}/v1/enums/vehicle-type`
    // );

    // setVehicleTypeList(vehicle.data);
    setVehicleTypeList(enums?.vehicle_type ? enums.vehicle_type : []);
  };
  useEffect(() => {
    getVehicleType();
    getClassType();
  }, [enums]);
  const getClassType = async () => {
    // var classType = await axiosInterceptor.get(
    //   `${apiUrl.onboardingBaseUrl}/v1/enums/class-type`
    // );

    // setClassTypeList(classType.data);
    setClassTypeList(enums?.vehicle_class_type ? enums.vehicle_class_type : []);
  };

  const handleSubmit = async () => {
    const isValid = formValidation();

    //
    //
    //
    // const isValid = formValidation();
    setLoadingBtn(true);
    if (isValid) {
      if (editMode) {
        const responseModel = await vehicleModelPut(
          name,
          model,
          wheels,
          vehicleType,
          maxCap,
          minCap,
          classModel,
          1,
          id,
          vehicleId
        );
        if (responseModel.status == 200) {
          getModelListData(currentPage, id);
          setName("");
          setModel("");
          setWheels("");
          setVehicleType("");
          setMaxCap("");
          setMinCap("");
          setClassModel("");
          setUnits("");
          setEditMode(false);
          setErrorModelName("");
          setErrorModel("");
          setErrorWheels("");
          setErrorVehicleType("");
          setErrorMax("");
          setErrorMin("");
          setErrorClassModel("");
          setErrorUnit("");
          setMsgForModel("Model Updated successfully!");
        } else if (responseModel.response.status == 401) {
          toast.error("401: Not enough permissions");
        }
      } else {
        const vehicleTyperesponse = await vehicleModelPost(
          name,
          model,
          wheels,
          vehicleType,
          maxCap,
          minCap,
          classModel,
          units.id,
          id
        );

        // dispatch(onboardData(response.data));
        if (vehicleTyperesponse == 200) {
          getModelListData(currentPage, id);
          setName("");
          setModel("");
          setWheels("");
          setVehicleType("");
          setMaxCap("");
          setMinCap("");
          setClassModel("");
          setUnits("");

          setErrorModelName("");
          setErrorModel("");
          setErrorWheels("");
          setErrorVehicleType("");
          setErrorMax("");
          setErrorMin("");
          setErrorClassModel("");
          setErrorUnit("");
          setMsgForModel("Model created successfully!");

          setLoadingBtn(false);
        }
        if (vehicleTyperesponse.response.status == 400) {
          setErrorMsgForModel("Model already exist!");
          setLoadingBtn(false);
        } else if (vehicleTyperesponse.response.status == 401) {
          toast.error("401: Not enough permissions");
        }
      }
    }
    setLoadingBtn(false);
  };

  const formValidation = () => {
    const errorModelName = {};
    const errorModel = {};
    const errorWheels = {};
    const errorVehicleType = {};
    const errorMax = {};
    const errorMin = {};
    const errorClassModel = {};
    const errorUnit = {};

    let isValid = true;
    if (name == "") {
      errorModelName.errorName = "Name is mandatory.";
      isValid = false;
    }
    if (model == "") {
      errorModel.errorModelText = "Model is mandatory.";
      isValid = false;
    }
    if (wheels == "") {
      errorWheels.errorwheelstext = "Wheels is mandatory.";
      isValid = false;
    }
    if (vehicleType == "") {
      errorVehicleType.errorType = "Type is mandatory.";
      isValid = false;
    }
    const maxCapacity = /^[0-9]+$/;
    if (maxCap == "") {
      errorMax.errorMax = "Field is mandatory.";
      isValid = false;
    } else if (!maxCapacity.test(maxCap)) {
      errorMax.errorMax1 = "Please Enter digits only";
    }
    const minCapacity = /^[0-9]+$/;
    if (minCap == "") {
      errorMin.errorMin = "Field is mandatory.";
      isValid = false;
    } else if (!minCapacity.test(minCap)) {
      errorMin.errorMin2 = "Please Enter digits only";
    }
    if (classModel == "") {
      errorClassModel.errorClassModel = "Field is mandatory.";
      isValid = false;
    }
    if (Object.keys(units).length == 0) {
      errorUnit.errorunitText = "Unit is mandatory.";
      isValid = false;
    }
    setErrorModelName(errorModelName);
    setErrorModel(errorModel);
    setErrorWheels(errorWheels);
    setErrorVehicleType(errorVehicleType);
    setErrorMax(errorMax);
    setErrorMin(errorMin);
    setErrorClassModel(errorClassModel);
    setErrorUnit(errorUnit);
    return isValid;
  };

  const getModelListData = async (current, ids) => {
    setLoading(true);
    const modelList = await getModelList(current, ids);

    if (current > 1) {
      setModelData([...modelData, ...modelList.model_list]);
      setIsLastPage(modelList.is_last_page);
    } else {
      setModelData(modelList.model_list);
      setIsLastPage(modelList.is_last_page);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (searchTerm) {
      handleSearchVehicleModel(searchTerm, currentPage);
      dispatch(searchVehicleModel(searchTerm));
    } else {
      dispatch(searchVehicleModel(""));
      getModelListData(currentPage, id);
    }
  }, [currentPage, id, searchTerm]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const handleSearchVehicleModel = async (values, page) => {
    // const value = event.target.value;
    //
    // setCurrentPage(1);
    // setSearchValue(value);

    // if (searchValue) {
    //   getModelListData(1);
    // }
    if (values && values.length >= 1) {
      try {
        // setLoading(true);
        const productResponse = await axiosInterceptor.get(
          `${apiUrl.onboardingBaseUrl}/v1/vehicle-manufacturer/${id}/vehicle-model?name=${values}&current_page=${page}`
        );

        if (productResponse.data.model_list.length > 0) {
          setModelData(productResponse.data.model_list);
          setIsLastPage(productResponse.data.is_last_page);
        } else {
          setModelData([]);
        }

        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        setModelData([]);
      }
    }
  };
  const handlecancelSearch = () => {
    dispatch(searchVehicleModel(""));
    getModelListData(1);
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  const handleOpenMenu = () => {
    toggleDrawer("right", true);

    setErrorUnit("");
    setMsgForModel("");
  };
  const handleCloseBtn = () => {
    toggleDrawer("right", false);
  };
  const handleEdit = (data) => {
    console.log(data, "edit data ====>");
    setEditMode(true);
    setName(data.name);
    setModel(data.model_number);
    setWheels(data.wheels);
    setVehicleType(data.vehicle_type);
    setMaxCap(data.maximum_load_capacity);
    setMinCap(data.normal_load_capacity);
    setClassModel(data.class_model);
    setUnits(data.unit_name);
    setVehicleId(data.id);
    if (isMobile) {
      handleOpenMenu();
    }
  };
  const { toggle, darkMode } = useContext(DarkModeContext);

  return (
    <Component_style className="main_onboard_container" mode={darkMode}>
      <ToastContainer position="bottom-right" />
      <Drawer
        className="drawer"
        anchor={"right"}
        open={state["right"]}
        onClose={() => toggleDrawer("right", false)}
      >
        <div
          className="right_details_wrapper"
          id="modal_wrapper"
          style={{ width: "90vw", padding: "10px" }}
        >
          <div className="oem_div" id="model_div">
            <div
              style={{
                display: "flex",
                width: "80%",
                margin: "0 auto",
                justifyContent: "space-between",
                padding: "10px 5px",
                // marginLeft: "1.5rem",
              }}
            >
              <h5 style={{ color: colorPalette.primaryColor }}>
                Add Vehicle Model
              </h5>
              <CloseIcon
                onClick={handleCloseBtn}
                // style={{ marginRight: "10%" }}
              />
            </div>
            {/* <h6 style={{ textAlign: "center", fontSize: "1rem" }}>
                  Create Vehicle Model
                </h6> */}
            <div className="form_oem " id="form_model">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <h4 className="form_label">
                    Name
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    type="text"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{
                      width: "13ch",
                      height: "6ch",
                    }}
                    value={name}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setName(e.target.value);
                      setErrorModelName("");
                    }}
                  />
                  {Object.keys(errorModelName).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", marginTop: "-14%" }}>
                        {errorModelName[key]}
                      </h6>
                    );
                  })}
                </div>
                <div>
                  <h4 className="form_label">
                    Model Number
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    type="Number"
                    onWheel={(e) => e.target.blur()}
                    id="outlined-basic"
                    variant="outlined"
                    sx={{
                      width: "13ch",
                      height: "6ch",
                    }}
                    value={model}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setModel(e.target.value);
                      setErrorModel("");
                    }}
                  />
                  {Object.keys(errorModel).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", marginTop: "-14%" }}>
                        {errorModel[key]}
                      </h6>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <h4 className="form_label">
                    No.of Wheels
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>

                  <TextField
                    type="Number"
                    onWheel={(e) => e.target.blur()}
                    id="outlined-basic"
                    variant="outlined"
                    sx={{
                      width: "13ch",
                      height: "6ch",
                    }}
                    value={wheels}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setWheels(e.target.value);
                      setErrorWheels("");
                    }}
                  />
                  {Object.keys(errorWheels).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", marginTop: "-14%" }}>
                        {errorWheels[key]}
                      </h6>
                    );
                  })}
                </div>
                <div>
                  <h4 className="form_label">
                    Vehicle Type
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>

                  <TextField
                    id="outlined-select-currency"
                    select
                    variant="outlined"
                    sx={{
                      width: "13ch",
                      height: "6ch",
                    }}
                    value={vehicleType}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setVehicleType(e.target.value);
                      setErrorVehicleType("");
                    }}
                  >
                    {vehicleTypeList.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option.split("_").join(" ").toUpperCase()}
                      </MenuItem>
                    ))}
                  </TextField>
                  {Object.keys(errorVehicleType).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", marginTop: "-14%" }}>
                        {errorVehicleType[key]}
                      </h6>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <h4 className="form_label">
                    Max Load
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>

                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{
                      width: "13ch",
                      height: "6ch",
                    }}
                    value={maxCap}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setMaxCap(e.target.value);
                      setErrorMax("");
                    }}
                  />
                  {Object.keys(errorMax).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", marginTop: "-14%" }}>
                        {errorMax[key]}
                      </h6>
                    );
                  })}
                </div>
                <div>
                  <h4 className="form_label">
                    Load Capacity
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>

                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{
                      width: "13ch",
                      height: "6ch",
                    }}
                    value={minCap}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setMinCap(e.target.value);
                      setErrorMin("");
                    }}
                  />
                  {Object.keys(errorMin).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", marginTop: "-14%" }}>
                        {errorMin[key]}
                      </h6>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <h4 className="form_label">
                    Class Model
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>

                  <TextField
                    id="outlined-basic"
                    select
                    variant="outlined"
                    sx={{
                      width: "13ch",
                      height: "6ch",
                    }}
                    value={classModel}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setClassModel(e.target.value);
                      setErrorClassModel("");
                    }}
                  >
                    {classTypeList.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option.split("_").join(" ").toUpperCase()}
                      </MenuItem>
                    ))}
                  </TextField>
                  {Object.keys(errorClassModel).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", marginTop: "-14%" }}>
                        {errorClassModel[key]}
                      </h6>
                    );
                  })}
                </div>
                <div>
                  <h4 className="form_label">
                    Unit
                    <sup style={{ color: "red" }}>*</sup>
                  </h4>

                  <TextField
                    // type="Number"
                    id="outlined-basic"
                    select
                    variant="outlined"
                    sx={{
                      width: "13ch",
                      height: "6ch",
                    }}
                    value={units}
                    defaultValue={"ton"}
                    color="success"
                    size="small"
                    onChange={(e) => {
                      setUnits(e.target.value);
                      setErrorUnit("");
                    }}
                  >
                    {unitData.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option.name.toUpperCase()}
                      </MenuItem>
                    ))}
                  </TextField>
                  {Object.keys(errorUnit).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", marginTop: "-14%" }}>
                        {errorUnit[key]}
                      </h6>
                    );
                  })}
                </div>
              </div>
              {editMode ? (
                <ButtonRectangle
                  // className="oem_btn"
                  style={{
                    marginTop: "2rem",
                    width: "90%",
                    height: "40px",
                    marginBottom: "20px",
                  }}
                  onClick={handleSubmit}
                >
                  {loadingBtn ? (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  ) : (
                    "Update Vehicle Model"
                  )}{" "}
                </ButtonRectangle>
              ) : (
                <ButtonRectangle
                  // className="oem_btn"
                  style={{
                    marginTop: "2rem",
                    width: "90%",
                    height: "40px",
                    marginBottom: "20px",
                  }}
                  onClick={handleSubmit}
                >
                  {loadingBtn ? (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  ) : (
                    "Create Vehicle Model"
                  )}{" "}
                </ButtonRectangle>
              )}

              {msgForModel.length > 0 ? (
                <h6
                  style={{
                    marginTop: "1rem",
                    color: colorPalette.primaryColor,
                  }}
                >
                  <Alert severity="success">{msgForModel}</Alert>
                </h6>
              ) : errorMsgForModel.length > 0 ? (
                <h6 style={{ marginTop: "1rem", color: "red" }}>
                  <Alert severity="error"> {errorMsgForModel}</Alert>
                </h6>
              ) : null}
            </div>
          </div>
        </div>
      </Drawer>
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={99999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong
            backButtonLink={`/vehicles-manufacturers/oemdetails/${id}`}
            buttonText="Close"
          />
        </div>
      </ModalAntd>

      <div className="main_onboard_wrapper">
        {isMobile ? (
          <div className="trip-list-mobile-header">
            <MobileHeader headerTitle="Vehicle Models" />
          </div>
        ) : (
          <div className="main_onboard_Left_box">
            <SideBarMain />
          </div>
        )}

        <div className="main_onboard_Right_box">
          {isMobile ? null : <Topbar />}
          <div className="progress_container_details">
            <div className="Left_details_wrapper">
              {/* <ArrowBackIcon
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",

                  color: "green",
                }}
                className="arrow"
              /> */}
              <div className="search_container">
                <div className="search_div" id="search_div_onboard">
                  <input
                    type="text"
                    name=""
                    id="search_input_onboard"
                    onChange={(event) => {
                      dispatch(searchVehicleModel(event.target.value));
                      setCurrentPage(1);
                    }}
                    value={searchTerm}
                    placeholder="search"
                    className="search_input"
                  />
                  {searchTerm && !modelData.length == 0 ? (
                    <CloseIcon
                      onClick={handlecancelSearch}
                      style={{ color: "grey", cursor: "pointer" }}
                    />
                  ) : (
                    <SearchIcon style={{ color: "grey", cursor: "pointer" }} />
                  )}

                  {/* <SearchIcon style={{ color: "grey", cursor: "pointer" }} /> */}
                </div>
              </div>

              {isMobile ? (
                <>
                  <div className="onboard_btnDiv">
                    <button
                      onClick={handleOpenMenu}
                      className="add_manufacturer"
                    >
                      Create
                    </button>
                  </div>
                </>
              ) : null}
              {!isMobile ? (
                <h3 style={{ color: colorPalette.primaryColor }}>
                  Vehicle Models
                </h3>
              ) : null}
              <div
                style={{ borderTop: "1px solid gray" }}
                className="card_container-oem"
              >
                {modelData.length !== 0 ? (
                  <>
                    {modelData.map((data, index) => (
                      <>
                        {" "}
                        <div className="card" key={index}>
                          {isMobile ? null : (
                            <div className="image_div">
                              <div>
                                <img src={Carrier} alt="" width="150px" />
                              </div>
                            </div>
                          )}
                          {isMobile ? (
                            <>
                              {" "}
                              <div className="content_div">
                                <div className="capacity">
                                  <h6 className="capacity_text_model">Name:</h6>
                                  {modelData.length == index + 1 ? (
                                    <h6
                                      className="weight_mobile"
                                      ref={lastItem}
                                    >
                                      {data.name}
                                    </h6>
                                  ) : (
                                    <h6 className="weight_mobile">
                                      {data.name}
                                    </h6>
                                  )}
                                  {/* <h6 className="weight"> {data.name}</h6> */}
                                </div>
                                <div className="capacity">
                                  <h6 className="capacity_text_mobile">
                                    Vehicle Type:
                                  </h6>
                                  <h6 className="weight_mobile">
                                    {data.vehicle_type
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()}
                                  </h6>
                                </div>
                                <div className="capacity">
                                  <h6 className="capacity_text_mobile">
                                    Capacity:
                                  </h6>
                                  <h6 className="weight_mobile">
                                    {data.maximum_load_capacity}
                                    <span style={{ marginLeft: "5px" }}>
                                      {data.unit_name.toUpperCase()}
                                    </span>
                                  </h6>
                                </div>
                              </div>
                              <div className="content_div">
                                <div className="capacity">
                                  <h6 className="capacity_text_mobile">
                                    Wheels:
                                  </h6>
                                  <h6 className="weight_mobile">
                                    {data.wheels}
                                  </h6>
                                </div>
                                <div className="capacity">
                                  <h6 className="capacity_text_mobile">
                                    Model Number:
                                  </h6>
                                  <h6 className="weight_mobile">
                                    {data.model_number
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()}
                                  </h6>
                                </div>
                                <div className="capacity">
                                  <h6 className="capacity_text_mobile">
                                    Class:
                                  </h6>
                                  <h6 className="weight_mobile">
                                    {data.class_model
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()}
                                  </h6>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="content_div">
                                <div className="capacity">
                                  <h6 className="capacity_text">Name:</h6>
                                  {modelData.length == index + 1 ? (
                                    <h6 className="weight" ref={lastItem}>
                                      {data.name}
                                    </h6>
                                  ) : (
                                    <h6 className="weight">{data.name}</h6>
                                  )}
                                  {/* <h6 className="weight"> {data.name}</h6> */}
                                </div>
                                <div className="capacity">
                                  <h6 className="capacity_text">
                                    Vehicle Type:
                                  </h6>
                                  <h6 className="weight">
                                    {data.vehicle_type
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()}
                                  </h6>
                                </div>
                                <div className="capacity">
                                  <h6 className="capacity_text">Capacity:</h6>
                                  <h6 className="weight">
                                    {data.maximum_load_capacity}
                                    <span style={{ marginLeft: "5px" }}>
                                      {data.unit_name.toUpperCase()}
                                    </span>
                                  </h6>
                                </div>
                              </div>

                              <div className="content_div">
                                <div className="capacity">
                                  <h6 className="capacity_text">Wheels:</h6>
                                  <h6 className="weight">{data.wheels}</h6>
                                </div>
                                <div className="capacity">
                                  <h6 className="capacity_text">
                                    Model Number:
                                  </h6>
                                  <h6 className="weight">
                                    {data.model_number
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()}
                                  </h6>
                                </div>
                                <div className="capacity">
                                  <h6 className="capacity_text">Class:</h6>
                                  <h6 className="weight">
                                    {data.class_model
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()}
                                  </h6>
                                </div>
                              </div>
                            </>
                          )}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "center",
                              height: "100%",
                              width: "2rem",
                            }}
                          >
                            <EditIcon
                              style={{
                                cursor: "pointer",
                                color: darkMode
                                  ? colorPaletteDark.white
                                  : colorPalette.black,
                              }}
                              onClick={() => handleEdit(data)}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                ) : loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      // backgroundColor: "grey",
                    }}
                  >
                    {" "}
                    <img
                      src={loader}
                      alt=""
                      width="120px"
                      height="90px"
                      style={{ marginTop: "200px" }}
                    />
                    <h6 style={{ color: "grey" }}>loading data...</h6>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      height: "300px",
                      alignItems: "center",
                      justifyContent: "center",
                      color: `${darkMode ? "white" : "black"}`,
                    }}
                  >
                    <h6>No data found,Please add one.</h6>
                  </div>
                )}
              </div>
            </div>

            {/* {
    "name": "tata",
    "model_number":  "test-model-1",
    "wheels":"4",
    "vehicle_type": "cargo_auto",
    "maximum_load_capacity": 678.9,
    "normal_load_capacity": 77.8,
    "class_model": "light_duty",
    "unit": 1
} */}
            {isMobile ? null : (
              <div className="right_details_wrapper">
                <div className="oem_div_model">
                  {/* <h6 style={{ textAlign: "center", fontSize: "1rem" }}>
                  Create Vehicle Model
                </h6> */}
                  <div className="form_oem_model ">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <h4 className="form_label">
                          Name
                          <sup style={{ color: "red" }}>*</sup>
                        </h4>

                        <TextField
                          type="text"
                          id="outlined-basic"
                          variant="outlined"
                          // sx={{
                          //   width: "15ch",
                          //   height: "6ch",
                          // }}
                          sx={{
                            input: {
                              color: darkMode
                                ? colorPaletteDark.white
                                : colorPalette.black,
                            },
                          }}
                          style={{
                            width: "100%",
                          }}
                          value={name}
                          // color="success"
                          size="small"
                          onChange={(e) => {
                            setName(e.target.value);
                            setErrorModelName("");
                          }}
                        />
                        {Object.keys(errorModelName).map((key) => {
                          return (
                            <h6 style={{ color: "#E06666" }}>
                              {errorModelName[key]}
                            </h6>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <h4
                          className="form_label"
                          style={{ marginTop: ".5rem" }}
                        >
                          Model Number
                          <sup style={{ color: "red" }}>*</sup>
                        </h4>

                        <TextField
                          type="Number"
                          onWheel={(e) => e.target.blur()}
                          id="outlined-basic"
                          variant="outlined"
                          value={model}
                          style={{
                            width: "100%",
                          }}
                          sx={{
                            input: {
                              color: darkMode
                                ? colorPaletteDark.white
                                : colorPalette.black,
                            },
                          }}
                          color="success"
                          size="small"
                          onChange={(e) => {
                            setModel(e.target.value);
                            setErrorModel("");
                          }}
                        />
                        {Object.keys(errorModel).map((key) => {
                          return (
                            <h6 style={{ color: "#E06666" }}>
                              {errorModel[key]}
                            </h6>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <h4 className="form_label">
                          No.of Wheels
                          <sup style={{ color: "red" }}>*</sup>
                        </h4>

                        <TextField
                          type="Number"
                          onWheel={(e) => e.target.blur()}
                          id="outlined-basic"
                          variant="outlined"
                          style={{
                            width: "100%",
                          }}
                          sx={{
                            input: {
                              color: darkMode
                                ? colorPaletteDark.white
                                : colorPalette.black,
                            },
                          }}
                          value={wheels}
                          color="success"
                          size="small"
                          onChange={(e) => {
                            setWheels(e.target.value);
                            setErrorWheels("");
                          }}
                        />
                        {Object.keys(errorWheels).map((key) => {
                          return (
                            <h6 style={{ color: "#E06666" }}>
                              {errorWheels[key]}
                            </h6>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <h4 className="form_label">
                          Vehicle Type
                          <sup style={{ color: "red" }}>*</sup>
                        </h4>
                        <TextField
                          id="outlined-select-currency"
                          select
                          variant="outlined"
                          style={{
                            width: "100%",
                          }}
                          sx={{
                            input: {
                              color: darkMode
                                ? colorPaletteDark.white
                                : colorPalette.black,
                            },
                          }}
                          value={vehicleType}
                          color="success"
                          size="small"
                          onChange={(e) => {
                            setVehicleType(e.target.value);
                            setErrorVehicleType("");
                          }}
                        >
                          {vehicleTypeList.map((option, index) => (
                            <MenuItem
                              key={index}
                              value={option}
                              style={{
                                backgroundColor: darkMode
                                  ? colorPaletteDark.black
                                  : colorPalette.white,
                              }}
                            >
                              <span
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.white
                                    : colorPalette.black,

                                  // width: "300px",
                                  // padding: ;
                                }}
                              >
                                {" "}
                                {option.split("_").join(" ").toUpperCase()}
                              </span>
                            </MenuItem>
                          ))}
                        </TextField>
                        {Object.keys(errorVehicleType).map((key) => {
                          return (
                            <h6 style={{ color: "#E06666" }}>
                              {errorVehicleType[key]}
                            </h6>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <h4 className="form_label">
                          Max Load Capacity
                          <sup style={{ color: "red" }}>*</sup>
                        </h4>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          style={{
                            width: "100%",
                          }}
                          sx={{
                            input: {
                              color: darkMode
                                ? colorPaletteDark.white
                                : colorPalette.black,
                            },
                          }}
                          value={maxCap}
                          color="success"
                          size="small"
                          onChange={(e) => {
                            setMaxCap(e.target.value);
                            setErrorMax("");
                          }}
                        />
                        {Object.keys(errorMax).map((key) => {
                          return (
                            <h6 style={{ color: "#E06666" }}>
                              {errorMax[key]}
                            </h6>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <h4 className="form_label">
                          Load Capacity
                          <sup style={{ color: "red" }}>*</sup>
                        </h4>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          style={{
                            width: "100%",
                          }}
                          sx={{
                            input: {
                              color: darkMode
                                ? colorPaletteDark.white
                                : colorPalette.black,
                            },
                          }}
                          value={minCap}
                          color="success"
                          size="small"
                          onChange={(e) => {
                            setMinCap(e.target.value);
                            setErrorMin("");
                          }}
                        />
                        {Object.keys(errorMin).map((key) => {
                          return (
                            <h6 style={{ color: "#E06666" }}>
                              {errorMin[key]}
                            </h6>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <h4 className="form_label">
                          Class Model
                          <sup style={{ color: "red" }}>*</sup>
                        </h4>

                        <TextField
                          id="outlined-basic"
                          select
                          variant="outlined"
                          style={{
                            width: "100%",
                          }}
                          sx={{
                            input: {
                              color: darkMode
                                ? colorPaletteDark.white
                                : colorPalette.black,
                            },
                          }}
                          value={classModel}
                          color="success"
                          size="small"
                          onChange={(e) => {
                            setClassModel(e.target.value);
                            setErrorClassModel("");
                          }}
                        >
                          {classTypeList.map((option, index) => (
                            <MenuItem
                              key={index}
                              value={option}
                              style={{
                                backgroundColor: darkMode
                                  ? colorPaletteDark.black
                                  : colorPalette.white,
                              }}
                            >
                              <span
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.white
                                    : colorPalette.black,

                                  // width: "300px",
                                  // padding: ;
                                }}
                              >
                                {" "}
                                {option.split("_").join(" ").toUpperCase()}
                              </span>
                            </MenuItem>
                          ))}
                        </TextField>
                        {Object.keys(errorClassModel).map((key) => {
                          return (
                            <h6 style={{ color: "#E06666" }}>
                              {errorClassModel[key]}
                            </h6>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <h4 className="form_label">
                          Unit
                          <sup style={{ color: "red" }}>*</sup>
                        </h4>

                        <TextField
                          // type="Number"
                          id="outlined-basic"
                          select
                          variant="outlined"
                          style={{
                            width: "100%",
                          }}
                          sx={{
                            input: {
                              color: darkMode
                                ? colorPaletteDark.white
                                : colorPalette.black,
                            },
                          }}
                          value={units}
                          color="success"
                          size="small"
                          onChange={(e) => {
                            setUnits(e.target.value);
                            setErrorUnit("");
                          }}
                        >
                          {unitData.map((option, index) => (
                            <MenuItem
                              key={index}
                              value={option}
                              style={{
                                backgroundColor: darkMode
                                  ? colorPaletteDark.black
                                  : colorPalette.white,
                              }}
                            >
                              <span
                                style={{
                                  color: darkMode
                                    ? colorPaletteDark.white
                                    : colorPalette.black,
                                }}
                              >
                                {option.name.toUpperCase()}
                              </span>
                            </MenuItem>
                          ))}
                        </TextField>
                        {Object.keys(errorUnit).map((key) => {
                          return (
                            <h6 style={{ color: "#E06666" }}>
                              {errorUnit[key]}
                            </h6>
                          );
                        })}
                      </div>
                    </div>
                    {editMode ? (
                      <ButtonRectangle
                        // className="oem_btn_model"
                        style={{
                          marginTop: "2rem",
                          width: "100%",
                          height: "40px",
                          padding: "10px 2px",
                          marginBottom: "20px",
                        }}
                        onClick={handleSubmit}
                      >
                        {loadingBtn ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "white" }}
                          />
                        ) : (
                          "Update Vehicle Model"
                        )}{" "}
                      </ButtonRectangle>
                    ) : (
                      <ButtonRectangle
                        // className="oem_btn_model"
                        style={{
                          marginTop: "2rem",
                          width: "100%",
                          height: "70px",
                          padding: "10px 2px",
                          marginBottom: "20px",
                        }}
                        onClick={handleSubmit}
                      >
                        {loadingBtn ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "white" }}
                          />
                        ) : (
                          "Create Vehicle Model"
                        )}{" "}
                      </ButtonRectangle>
                    )}

                    {msgForModel.length > 0 ? (
                      <h6
                        style={{
                          marginTop: "1rem",
                          color: colorPalette.primaryColor,
                        }}
                      >
                        <Alert severity="success">{msgForModel}</Alert>
                      </h6>
                    ) : errorMsgForModel.length > 0 ? (
                      <h6 style={{ marginTop: "1rem", color: "red" }}>
                        <Alert severity="error"> {errorMsgForModel}</Alert>
                      </h6>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Component_style>
  );
}

export default VehicleModel;
