import React from "react";
import { OurServicesStyle, ServicesImage } from "./our_services";

const Our_Services = () => {
  return (
    <OurServicesStyle className="ourServices" id="services">
      <ServicesImage></ServicesImage>
      <div className="our-service-card-section">
        <div className="our_services_heading">Our Services</div>
        <div className="our-service-card">
          <div className="card-heading">Across 24 states in India</div>
          <div className="card-dis">
            We provide a transport services accross all the pincode in 24 states
            for First mile.
          </div>
        </div>
        <div className="our-service-card">
          <div className="card-heading">Easy Management</div>
          <div className="card-dis">
            We take care of all your cargo transportation for first mile and
            last mile delivery
          </div>
        </div>
        <div className="our-service-card">
          <div className="card-heading">Instant Payment System</div>
          <div className="card-dis">
            Our platform enables easy payment system for all the trip.
          </div>
        </div>
      </div>
    </OurServicesStyle>
  );
};

export default Our_Services;
