import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import UploadImage from "../../assets/upload.svg";
import { Alert, CircularProgress } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SEARCH_GRAY from "../../assets/search_gray.svg";
import FILTER_GRAY from "../../assets/filter_gray.svg";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import { Close } from "@mui/icons-material";
import {
  createQuoteApi,
  getIndentList,
  updateIndentStatusApi,
} from "./indentServices";
import { ModalUnstyled } from "@mui/base";
import { styled } from "@mui/system";

import { Drawer, InputAdornment, TextField, Box } from "@mui/material";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import CreateQuoteDrawer from "../../components/createQuoteDrawer/CreateQuoteDrawer";
import CopyText from "../../components/copyText/CopyText";
import {
  IndentSidebarStyle,
  Indent_drawer_container_style,
  Indent_right_container,
} from "./indentPage.js";
import { colorPalette, colorPaletteDark } from "../../themes/theme";
import {
  ButtonRectangle,
  ButtonRectangleSecondary,
} from "../../themes/components/button";
import Topbar from "../../components/topbar/Topbar.jsx";
import { DarkModeContext } from "../../context/darkModeContext.js";
// import CreateQuoteDrawer from "../../components/createQuoteDrawer/CreateQuoteDrawer";

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

const tripRoutesBoxStyle = {
  width: isMobile ? "90vw" : "40%",
  height: isMobile ? "auto" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -24,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};

const exportBoxStyle = {
  width: isMobile ? "85%" : "70%",
  height: isMobile ? "80vh" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  // marginTop: isMobile ? -10 : -20,
  border: "1px solid gray",
  borderRadius: "10px",
};

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${isMobile ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.5)`};
  -webkit-tap-highlight-color: transparent;
`;

function IndentsPage() {
  const { toggle, darkMode } = useContext(DarkModeContext);

  const [indentList, setIndentList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentTab, setCurrentTab] = useState("sent");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerOpenFulfill, setDrawerOpenFulfill] = useState(false);

  const [remarksReject, setRemarksReject] = useState("");
  const [selectedIndentId, setSelectedIndentId] = useState("");
  const [addQuoteDrawerOpen, setAddQuoteDrawerOpen] = useState(false);
  const [quoteAmount, setQuoteAmount] = useState("");
  const [advanceAmount, setAdvanceAmount] = useState("");
  const [remarksAddQuote, setRemarksAddQuote] = useState("");
  const [quoteImage, setQuoteImage] = useState(null);
  const [loadingSaveQuote, setLoadingSaveQuote] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);

  const [addQuoteSuccessToast, setAddQuoteSuccessToast] = useState(false);
  const [addQuoteErrorToast, setAddQuoteErrorToast] = useState(false);
  const [quoteToastError, setQuoteToastError] = useState("");
  const [loadingPage, setLoadingPage] = useState(true);
  const [openTripRoutes, setOpenTripRoutes] = useState(false);
  const [selectedIndent, setSelectedIndent] = useState({});
  const [indentSuccessToast, setIndentSuccessToast] = useState("");
  const [unauthorisedMsg, setUnauthorisedMsg] = useState("");

  const handleCloseToast = () => {
    setQuoteToastError("");
    setAddQuoteSuccessToast(false);
    setAddQuoteErrorToast(false);
  };

  const handleCloseTripRoute = () => {
    setOpenTripRoutes(false);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setDrawerOpenFulfill(false);
    setAddQuoteDrawerOpen(false);
    resetForm();
    seteErrorQuotAmount("");
    setErrorAdvance("");
    setErrorAttachments("");
  };

  // const handleCloseDrawer = () => {
  //   setDrawerOpen(false);
  //   setAddQuoteDrawerOpen(false);
  //   resetForm();
  // };

  const handleOpenRoutes = (indent) => {
    setSelectedIndent(indent);
    setOpenTripRoutes(true);
  };

  const vertical = "bottom";
  const horizontal = "right";

  const handleOpenAddQuote = (indentId) => {
    setSelectedIndentId(indentId);
    setAddQuoteDrawerOpen(true);
    seteErrorQuotAmount("");
    setErrorAdvance("");
    setErrorAttachments("");
  };

  const resetForm = () => {
    setSelectedIndentId("");
    setRemarksReject("");
    setAddQuoteDrawerOpen(false);
    setQuoteAmount("");
    setAdvanceAmount("");
    setRemarksAddQuote("");
    setQuoteImage(null);
    setLoadingSaveQuote(false);
    setLoadingReject(false);
    setQuoteToastError("");
  };

  const handleCreateQuote = async () => {
    const isValid = formValidation();
    if (isValid) {
      setQuoteToastError("");
      setLoadingSaveQuote(true);
      const response = await createQuoteApi(
        selectedIndentId,
        quoteAmount,
        advanceAmount,
        remarksAddQuote,
        "something",
        quoteImage ? quoteImage : ""
      );
      setLoadingSaveQuote(false);
      if (response.status === 200) {
        seteErrorQuotAmount("");
        setErrorAdvance("");
        setErrorAttachments("");
        handleCloseDrawer();
        setAddQuoteSuccessToast(true);
        setIndentSuccessToast("Quotation is successfully created!");
        resetForm();
      } else if (response.status === 400) {
        setQuoteToastError(response.data.detail);
      } else {
        setQuoteToastError(`${response.status}`);
      }
    }
  };

  const handleSubmitStatus = async (indentId, status, remarks) => {
    setQuoteToastError("");

    setLoadingReject(true);
    const response = await updateIndentStatusApi(indentId, status, remarks);
    setLoadingReject(false);

    if (response.status === 200) {
      handleGetIndentList(currentPage, currentTab);
      handleCloseDrawer();
      setIndentSuccessToast("Indent is successfully rejected!");

      setAddQuoteSuccessToast(true);
      resetForm();
    } else if (response.status === 400) {
      setQuoteToastError(response.data.detail);
    } else {
      setQuoteToastError(`${response.status}`);
    }
  };

  const handleChangeTab = (tab) => {
    if (!loadingPage) {
      setCurrentPage(1);
      setIndentList([]);
      setLoadingPage(true);
      setCurrentTab(tab);
    }
  };

  const handleChangeAddQuoteImage = (e) => {
    setQuoteImage(e.target.files[0]);
  };

  const handleOpenRemarks = (indentId) => {
    setDrawerOpen(true);
    setSelectedIndentId(indentId);
  };

  const handleOpenRemarksFulfill = (indentId) => {
    setDrawerOpenFulfill(true);
    setSelectedIndentId(indentId);
  };

  const handleGetIndentList = async (currentPage, status) => {
    if (currentPage == 1) {
      setLoadingPage(true);
    }
    setLoadingMore(true);
    const response = await getIndentList(currentPage, status);
    if (currentPage > 1) {
      // if (response?.status === 200) {

      setIndentList([...indentList, ...response?.data?.indent_list]);
      setIsLastPage(response?.data.is_last_page);
      setLoadingPage(false);
      setLoadingMore(false);
      setUnauthorisedMsg("");
      // }
    } else {
      if (response?.status === 200) {
        setIndentList(response?.data?.indent_list);
        setIsLastPage(response?.data?.is_last_page);
        setLoadingPage(false);
        setLoadingMore(false);
        setUnauthorisedMsg("");
      }
      if (response?.status === 401) {
        setUnauthorisedMsg("401: " + response.data.error);
        setLoadingPage(false);
      }
      //
    }
  };

  useEffect(() => {
    handleGetIndentList(currentPage, currentTab);
  }, [currentPage, currentTab]);

  // pagination code

  const observer = useRef();
  const lastIndent = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage(currentPage + 1);
          setLoadingMore(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage, currentPage]
  );
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const [errorQuotAmount, seteErrorQuotAmount] = useState("");
  const [errorAdvance, setErrorAdvance] = useState("");
  const [errorAttachments, setErrorAttachments] = useState("");

  const formValidation = () => {
    const errorQuotAmount = {};
    const errorAdvance = {};
    // const errorAttachments = {};

    let isValid = true;
    if (quoteAmount == "") {
      errorQuotAmount.erroramount = "Quotation Amount is mandatory.";
      isValid = false;
    }
    if (advanceAmount == "") {
      errorAdvance.errorAdvanceAmt = "Advance Amount is mandatory.";
      isValid = false;
    }
    // if (quoteImage == null) {
    //   errorAttachments.errorAttach = "Attachment is manadatory.";
    //   isValid = false;
    // }

    seteErrorQuotAmount(errorQuotAmount);
    setErrorAdvance(errorAdvance);
    // setErrorAttachments(errorAttachments);
    return isValid;
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      {isMobile ? null : (
        <IndentSidebarStyle className="indent-sidebar">
          <SideBarMain />{" "}
        </IndentSidebarStyle>
      )}

      <Snackbar
        open={addQuoteSuccessToast}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseToast}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          {indentSuccessToast}
        </Alert>
      </Snackbar>

      {/*Trip routes modal starts*/}
      <StyledModal
        open={openTripRoutes}
        onClose={handleCloseTripRoute}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={tripRoutesBoxStyle}
          style={{
            backgroundColor: darkMode ? colorPaletteDark.secondaryColor : "",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10px",
              backgroundColor: darkMode
                ? colorPaletteDark.secondaryColor
                : colorPalette.white,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="trip-pickup-modal-head"
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: darkMode ? colorPaletteDark.white : "",
                }}
              >
                <div> Routes </div>{" "}
                <div style={{ marginLeft: "4px" }}>
                  {" "}
                  ( Indent code :{" "}
                  {selectedIndent?.indent_code
                    ? selectedIndent?.indent_code
                    : " - "}{" "}
                  )
                </div>
              </div>
              <div>
                <Close
                  onClick={handleCloseTripRoute}
                  style={{
                    cursor: "pointer",
                    color: darkMode ? colorPaletteDark.white : "",
                  }}
                />{" "}
              </div>
            </div>
            <div>
              <div className="trip-list-address">
                <div className="trip-location-list">
                  {selectedIndent.source?.map((source) => {
                    return (
                      <div
                        className="trip-location-text"
                        style={{
                          color: darkMode ? colorPaletteDark.white : "",
                        }}
                      >
                        From
                      </div>
                    );
                  })}
                  {/* <div className="trip-location-text">From</div> */}
                  {selectedIndent.destination?.map((destinations) => {
                    return (
                      <div
                        className="trip-location-text"
                        style={{
                          color: darkMode ? colorPaletteDark.white : "",
                        }}
                      >
                        To
                      </div>
                    );
                  })}
                </div>
                <div className="trip-list-path">
                  {/* <div className="trip-circle"></div>
                  <div className="trip-rectangle"></div> */}
                  {selectedIndent.source?.map((source, index) => {
                    return (
                      <>
                        {index == 0 ? null : (
                          <>
                            <div className="trip-circle"></div>
                            <div
                              className="mini-trip-rectangle"
                              style={{ marginTop: "30px" }}
                            ></div>
                          </>
                        )}
                      </>
                    );
                  })}
                  {selectedIndent.destination?.map((destinations) => {
                    return (
                      <>
                        <div className="trip-circle"></div>
                        <div
                          className="mini-trip-rectangle"
                          style={{ marginTop: "30px" }}
                        ></div>
                      </>
                    );
                  })}
                  <div
                    className="trip-circle"
                    // style={{ marginTop: "10px" }}
                  ></div>
                </div>
                <div className="trip-location-list">
                  {/* <div className="trip-location-text"> */}
                  {selectedIndent.source?.map((source) => {
                    return (
                      <div
                        className="trip-location-text"
                        style={{
                          color: darkMode ? colorPaletteDark.white : "",
                        }}
                      >
                        {source.village === source.district
                          ? source.village
                          : `${source.village},
                          ${source.district}`}{" "}
                        , {source.state}, {source.postcode}
                      </div>
                    );
                  })}

                  {selectedIndent.destination?.map((destinations) => {
                    return (
                      <div
                        className="trip-location-text"
                        style={{
                          color: darkMode ? colorPaletteDark.white : "",
                        }}
                      >
                        {destinations.village === destinations.district
                          ? destinations.district
                          : `${destinations.village}, ${destinations.district}`}
                        , {destinations.state}, {destinations.postcode}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>
      {/*Trip routes modal ends*/}

      <Indent_right_container
        className="indent-right-container"
        mode={darkMode}
      >
        {isMobile ? null : <Topbar />}
        <div className="indent-head-container">
          <div className="indent-head-row">
            {isMobile ? (
              <div className="trip-list-mobile-header">
                <MobileHeader headerTitle="Indents" />
              </div>
            ) : (
              <span className="indent-head-text"> Indents</span>
            )}

            {/* <button
              className="indent-add-quote-btn"
              onClick={() => handleOpenAddQuote()}
            >
              Add quote
            </button> */}
          </div>

          <div className="indent-tab-menuOption-contain">
            <div
              className={"indent-tab-menu-option"}
              style={
                currentTab === "sent"
                  ? { color: colorPalette.primaryColor, fontWeight: "600" }
                  : null
              }
              onClick={() => handleChangeTab("sent")}
            >
              Sent
              {currentTab === "sent" ? (
                <div className="indent-tab-bottom-rectangle"></div>
              ) : null}
            </div>
            <div
              className="indent-tab-menu-option"
              style={
                currentTab === "unfulfilled"
                  ? { color: colorPalette.primaryColor, fontWeight: "600" }
                  : null
              }
              onClick={() => handleChangeTab("unfulfilled")}
            >
              Unfulfilled
              {currentTab === "unfulfilled" ? (
                <div className="indent-tab-bottom-rectangle"></div>
              ) : null}
            </div>
            <div
              className="indent-tab-menu-option"
              style={
                currentTab === "quotaion-accepted"
                  ? { color: colorPalette.primaryColor, fontWeight: "600" }
                  : null
              }
              onClick={() => handleChangeTab("quotaion-accepted")}
            >
              Fulfilled
              {currentTab === "quotaion-accepted" ? (
                <div className="indent-tab-bottom-rectangle"></div>
              ) : null}
            </div>
          </div>
        </div>
        <div></div>

        {/* search bar  */}

        {/* <div className="indent-search-row">
          <div className="indent-search-container">
            <img
              src={SEARCH_GRAY}
              alt="search"
              style={{ marginRight: "5px" }}
            />
            <input placeholder="search" className="indent-search-input" />
            <img src={FILTER_GRAY} alt="filter" />
          </div>
        </div> */}

        {/* search bar  */}

        {loadingPage ? (
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : unauthorisedMsg ? (
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "red",
            }}
          >
            <span
              style={{
                // border: "1px solid red",
                padding: "1rem 3rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <ReportIcon /> {unauthorisedMsg}
            </span>
          </div>
        ) : indentList.length === 0 ? (
          <div
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: darkMode ? colorPaletteDark.white : colorPalette.black,
            }}
          >
            No indent found
          </div>
        ) : (
          <div className="indent-list-container">
            <div className="indent-grid-main-container">
              {indentList.map((indent) => {
                return (
                  <div
                    className="indent-single-item-container"
                    ref={lastIndent}
                  >
                    <div className="indent-single-top-row">
                      <div className="indent-single-top-left">
                        <div className="indent-number-text">
                          <CopyText
                            text={
                              indent?.indent_code ? indent?.indent_code : " - "
                            }
                          />
                        </div>
                        <div className="indent-customer-text">
                          <PersonOutlineOutlinedIcon
                            style={{
                              color: darkMode
                                ? colorPaletteDark.paragraphSecondaryolor
                                : "rgba(87, 87, 87, 1)",
                              fontSize: "16px",
                            }}
                          />{" "}
                          <span className="indent-customer-namee">
                            {indent?.customer_name
                              ? indent?.customer_name
                              : " - "}
                          </span>
                        </div>
                      </div>
                      <div className="indent-single-top-right">
                        <div className="indent-date-text">
                          {indent?.created_on
                            ? moment
                                .utc(indent?.created_on)
                                .local()
                                .format("DD MMM YYYY hh:mm a")
                            : " - "}
                        </div>
                      </div>
                    </div>
                    <div className="indent-single-middle-row">
                      <div className="indent-single-middle-in">
                        <div className="indent-slim-text">Invoice value</div>
                        <div className="indent-bold-text">
                          Rs.{" "}
                          {indent?.value_of_goods
                            ? indent?.value_of_goods
                            : " - "}
                        </div>
                      </div>
                      <div className="indent-single-middle-in">
                        <div className="indent-slim-text">Goods qty</div>
                        <div className="indent-bold-text">
                          {indent?.quantity_of_goods
                            ? indent?.quantity_of_goods
                            : " - "}{" "}
                          Ton
                        </div>
                      </div>
                      <div className="indent-single-middle-in">
                        <div className="indent-slim-text">Vehicle type</div>
                        <div className="indent-bold-text">
                          {indent?.vehicle_type
                            ? indent?.vehicle_type
                                .replace(/_/g, " ")
                                .toUpperCase()
                            : " - "}
                        </div>
                      </div>

                      <div className="indent-single-middle-in">
                        <div className="indent-slim-text">Type of material</div>
                        <div className="indent-bold-text">
                          <div
                            style={{
                              width: "50px",
                              height: "20px",
                              textoverflow: "ellipsis",
                              whitespace: "nowrap",
                              overflow: "hidden",
                            }}
                          >
                            {indent?.material_type
                              ? indent?.material_type
                              : " - "}
                          </div>
                        </div>
                      </div>

                      {/* <div className="indent-single-middle-in">
                      <div className="indent-slim-text">Quote amount</div>
                      <div className="indent-bold-text">₹ 50000</div>
                    </div> */}
                    </div>
                    <div className="indent-single-bottom-row">
                      <div style={{ display: "flex" }}>
                        <div
                          className="indent-single-stop-text"
                          onClick={() => handleOpenRoutes(indent)}
                        >
                          {indent?.destination?.length > 0
                            ? indent?.destination?.length == 1 &&
                              indent?.source?.length == 1
                              ? "Single stop"
                              : "Multiple stop"
                            : null}
                        </div>{" "}
                        <InfoOutlinedIcon
                          style={{
                            color: `${colorPalette.primaryColor}`,
                            fontSize: "20px",
                            marginLeft: "2px",
                            cursor: "pointer",
                            display: isMobile ? "none" : "block",
                          }}
                          onClick={() => handleOpenRoutes(indent)}
                        />
                      </div>

                      {indent?.indent_status === "sent" ? (
                        <div className="indent-buttons">
                          <ButtonRectangleSecondary
                            className="indent-reject-btn"
                            style={{
                              marginRight: "10px",
                            }}
                            onClick={() => handleOpenRemarks(indent?.id)}
                          >
                            Reject
                          </ButtonRectangleSecondary>
                          <ButtonRectangle
                            // className="indent-add-quote-btn"
                            onClick={() => handleOpenAddQuote(indent?.id)}
                          >
                            Add Quote
                          </ButtonRectangle>
                        </div>
                      ) : indent?.indent_status === "fulfilled" ? (
                        <div>
                          {/* <button
                            className="indent-reject-btn"
                            onClick={() => handleOpenRemarks(indent?.id)}
                          >
                            Reject
                          </button> */}
                          {/* <button
                            className="indent-add-quote-btn"
                            onClick={() => handleOpenAddQuote(indent?.id)}
                          >
                            Add quote
                          </button> */}
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
              {loadingMore ? (
                <div className="loading_more_data">Loading More ...</div>
              ) : null}
            </div>
          </div>
        )}
      </Indent_right_container>
      <Drawer
        open={drawerOpen}
        anchor={"right"}
        onClose={() => handleCloseDrawer()}
        PaperProps={{
          sx: {
            width: isMobile ? 340 : 440,
          },
        }}
      >
        <Indent_drawer_container_style
          className="indent-drawer-container"
          mode={darkMode}
        >
          <div className="indent-remarks-text">Remarks</div>
          <div className="indent-remarks-inputfield-conatiner">
            <textarea
              className="indent-remarks-textarea"
              name="accept_remark"
              placeholder="Add Remark"
              value={remarksReject}
              onChange={(e) => setRemarksReject(e.target.value)}
            ></textarea>
          </div>
          <div>
            {quoteToastError ? (
              <Alert severity="error">
                {quoteToastError ? quoteToastError : ""}
              </Alert>
            ) : null}
          </div>
          <div className="indent-remarks-submit-btn-cover">
            <button
              onClick={() =>
                handleSubmitStatus(
                  selectedIndentId,
                  "unfulfilled",
                  remarksReject
                )
              }
              className="indent-remarks-submit-btn"
              style={{ width: "100px", height: "42px" }}
              disabled={loadingReject == true ? true : false}
            >
              {loadingReject ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Reject"
              )}
            </button>
          </div>
        </Indent_drawer_container_style>
      </Drawer>
      <Drawer
        open={drawerOpenFulfill}
        anchor={"right"}
        onClose={() => handleCloseDrawer()}
        PaperProps={{
          sx: {
            width: 440,
          },
        }}
      >
        <Indent_drawer_container_style className="indent-drawer-container">
          <div className="indent-remarks-text">Remarks</div>
          <div className="indent-remarks-inputfield-conatiner">
            <textarea
              className="indent-remarks-textarea"
              name="accept_remark"
              placeholder="Add Remark"
              value={remarksReject}
              onChange={(e) => setRemarksReject(e.target.value)}
            ></textarea>
          </div>
          <div>
            {quoteToastError ? (
              <Alert severity="error">
                {quoteToastError ? quoteToastError : ""}
              </Alert>
            ) : null}
          </div>
          <div className="indent-remarks-submit-btn-cover">
            <button
              onClick={() =>
                handleSubmitStatus(selectedIndentId, "fulfilled", remarksReject)
              }
              className="indent-save-quote-btn"
              style={{ width: "100px", height: "42px" }}
            >
              {loadingReject ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Fulfill"
              )}
            </button>
          </div>
        </Indent_drawer_container_style>
      </Drawer>
      <CreateQuoteDrawer
        addQuoteDrawerOpen={addQuoteDrawerOpen}
        setDrawerClose={setAddQuoteDrawerOpen}
        handleCloseDrawer={handleCloseDrawer}
        selectedIndentId={selectedIndentId}
        resetForm={resetForm}
      />
      {/* <Drawer
        open={addQuoteDrawerOpen}
        anchor={"right"}
        onClose={() => handleCloseDrawer()}
        PaperProps={{
          sx: {
            width: isMobile ? 340 : 440,
          },
        }}
      >
        <div className="indent-drawer-container">
          <div className="indent-drawer-head-text">Add Quotation</div>
          <div className="indent-input-container">
            <div className="indent-input-text">
              Quotation Amount <sup style={{ color: "red" }}>*</sup>{" "}
            </div>
            <div className="input-div">
              <TextField
                size="small"
                autoComplete="off"
                className="indent-inputfield"
                variant="outlined"
                // style={{ width: "100%" }}
                placeholder="Enter quotation amount"
                value={quoteAmount}
                onChange={(e) => {
                  setQuoteAmount(e.target.value);
                  seteErrorQuotAmount("");
                }}
                onInput={(e) => {
                  e.target.value = parseInt(
                    e.target.value === "0" ? "1" : e.target.value
                  );
                }}
                type="number"
                onWheel={(e) => e.target.blur()}
              />{" "}
              {Object.keys(errorQuotAmount).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorQuotAmount[key]}</h6>
                );
              })}
            </div>
          </div>
          <div className="indent-input-container">
            <div className="indent-input-text">
              Advance Amount <sup style={{ color: "red" }}>*</sup>
            </div>
            <div>
              <TextField
                size="small"
                autoComplete="off"
                className="indent-inputfield"
                variant="outlined"
                // style={{ width: "91%" }}
                placeholder="Enter advance amount"
                value={advanceAmount}
                onChange={(e) => {
                  setAdvanceAmount(e.target.value);
                  setErrorAdvance("");
                }}
                onInput={(e) => {
                  e.target.value = parseInt(
                    e.target.value === "0" ? "1" : e.target.value
                  );
                }}
                type="number"
                onWheel={(e) => e.target.blur()}
              />{" "}
              {Object.keys(errorAdvance).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorAdvance[key]}</h6>
                );
              })}
            </div>
          </div>
          <div className="indent-input-container">
            <div className="indent-input-text">Quote attachment</div>
            <div>
              {" "}
              <TextField
                size="small"
                id="dropdown"
                autoComplete="off"
                className="indent-inputfield"
                variant="outlined"
                placeholder="Quotation attachment"
                name="attachment_tag"
                disabled={true}
                // value={tripStartMeterReading}
                // onChange={(e) => setTripStartMeterReading(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        cursor: "pointer",
                        color: "#016938",
                        fontWeight: "600",
                      }}
                      // onClick={handleAddAttachment}
                    >
                      <button className="upload_button">
                        <input
                          // name="upload"
                          name="attachment_file"
                          id={`upload`}
                          type="file"
                          accept="image/png, image/jpeg, application/pdf"
                          onChange={(e) => {
                            handleChangeAddQuoteImage(e);

                            setErrorAttachments("");
                          }}
                          hidden
                          style={{
                            backgroundColor: "#e8f9e0",
                          }}
                        />
                        <label
                          htmlFor={`upload`}
                          style={{
                            backgroundColor: "#e8f9e0",
                          }}
                        >
                          <img
                            src={UploadImage}
                            alt=""
                            width="20px"
                            height="20px"
                            style={{
                              backgroundColor: "#e8f9e0",
                              // width: "100%",
                              // height: "100%",
                            }}
                          />
                        </label>
                      </button>
                    </InputAdornment>
                  ),
                }}
              />
              {Object.keys(errorAttachments).map((key) => {
                return (
                  <h6 style={{ color: "#E06666" }}>{errorAttachments[key]}</h6>
                );
              })}
            </div>
            <div className="create-trip-dl-name">
              {quoteImage ? quoteImage.name : null}
            </div>
          </div>
          <div className="indent-input-container">
            <div className="indent-input-text">Remarks</div>
            <div
              className="indent-remarks-inputfield-conatiner"
              style={{ margin: "0px" }}
            >
              <textarea
                className="indent-remarks-textarea"
                name="accept_remark"
                placeholder="Add Remark"
                value={remarksAddQuote}
                onChange={(e) => setRemarksAddQuote(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div>
            {quoteToastError ? (
              <Alert severity="error">
                {quoteToastError ? quoteToastError : ""}
              </Alert>
            ) : null}
          </div>
          <div
            className="indent-remarks-submit-btn-cover"
            style={{ width: "93%" }}
          >
            <button
              onClick={() => handleCreateQuote()}
              className="indent-save-quote-btn"
              style={
                quoteAmount >= advanceAmount
                  ? { backgroundColor: "#076b38" }
                  : { backgroundColor: "#076b38bd" }
              }
              disabled={quoteAmount >= advanceAmount ? false : true}
            >
              {loadingSaveQuote ? (
                <CircularProgress size={19} color="inherit" />
              ) : (
                "Save Quote"
              )}
            </button>
          </div>
        </div>
      </Drawer> */}
    </div>
  );
}

export default IndentsPage;
