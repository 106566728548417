import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import success from "../../../assets/dashboard_icons/icons8-truck.gif";
import { ArrowBack, Add, Edit, Close } from "@mui/icons-material";
import {
  TextField,
  MenuItem,
  CircularProgress,
  Box,
  Drawer,
  InputAdornment,
  Alert,
} from "@mui/material";
import {
  TripAttachmentPost,
  stopTrip,
  GetCompleteTripDocumentEnums,
  purchaseOrderItemsDeliveredPost,
  requestCompleteTrip,
  getTripCompleteRequestDetails,
} from "../completeTrip/completeTripServices";
import * as moment from "moment";
import "../completeTrip/complete-trip.css";
import { useSelector, useDispatch } from "react-redux";
import UploadImage from "../../../assets/upload.svg";
import { updateSelectedTrip } from "../../../redux/tripSlice.js";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import { GetDocumentEnums } from "../createTrip/createTripServices";
import {
  getTripDetailsFromId,
  getTripItems,
} from "../deliveryInvoicePayment/DeliveryInvoiceService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getTripDetailsById } from "../../recordPayment/recordPaymentServices";
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
// import { Complete_Trip_Main_Container } from "../completeTrip/completeTrip";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 180,
  bgcolor: "background.paper",
  boxShadow: 45,
  p: 2,
};
function CompleteTripRequest() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tripId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [pendingInvoiceMsgp, setPendingInvoiceMsg] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [invoicePaymentHistoryList, setInvoicePaymentHistoryList] = useState(
    []
  );
  const deliveredInvoicesList = invoicePaymentHistoryList.filter(
    (data) => data.status !== "approved"
  );

  const remoedDuplicateInvoice = deliveredInvoicesList.map(
    (data) => data.reference_number
  );
  const remoedDuplicateInvoiceStatus = deliveredInvoicesList.map(
    (data) => data.status
  );

  const undeliveredInvoices = deliveredInvoicesList.filter(
    (data) => data.status == "submitted" || data.status == "recommended"
  );

  const undeliveredInvoicesInique = undeliveredInvoices.map(
    (data) => data.reference_number
  );

  const finalFiltredArrayOfPendingInvoices = [
    ...new Set(undeliveredInvoicesInique),
  ];
  const finalFiltredArrayOfPendingInvoicesStatus = [
    ...new Set(remoedDuplicateInvoiceStatus),
  ];


  useEffect(() => {
    if (
      finalFiltredArrayOfPendingInvoicesStatus.includes("submitted") ||
      finalFiltredArrayOfPendingInvoicesStatus.includes("recommended")
    ) {
      setPendingInvoiceMsg(
        `Please Approve or Reject Payment for these invoices ${finalFiltredArrayOfPendingInvoices.map(
          (data) => data
        )}`
      );
      // return;
    }
    if (
      finalFiltredArrayOfPendingInvoicesStatus.includes("approved") ||
      finalFiltredArrayOfPendingInvoicesStatus.includes("rejected")
    ) {
      return;
    }
    // if (finalFiltredArrayOfPendingInvoicesStatus.includes("recommended")) {
    //   setPendingInvoiceMsg(
    //     `Please clear payments for all the pending invoices ${finalFiltredArrayOfPendingInvoices.map(
    //       (data) => data
    //     )}`
    //   );
    //   // return;
    //

    //   // return;
    // }
    // else {
    //   setPendingInvoiceMsg(
    //     `Please clear payments for all the pending invoices ${finalFiltredArrayOfPendingInvoices.map(
    //       (data) => data
    //     )}`
    //   );
    // }
  }, [deliveredInvoicesList]);

  const [tripRemarks, setTripRemarks] = useState("");
  const [tripDeliveredBills, setTripDeliveredBills] = useState([]);
  const [unloadedQuantity, setUnloadedQuantity] = useState("");
  const [costKeys, setCostKeys] = useState(["z"]);
  const [tripCompletionRequestData, setTripCompletionRequestData] = useState(
    {}
  );
  const [pendingReferenceNumbers, setPendingReferenceNumbers] = useState([]);
  const [attachmentCount, setAttachmentCount] = useState([1]);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [errRemarks, setErrRemarks] = useState("");
  const [errTripCost, setErrTripCost] = useState("");
  const [tripCompleteMeterReading, setTripCompleteMeterReading] = useState("");
  const [tripCostTotal, setTripCostTotal] = useState("");
  const [errUnloadedQuantity, setErrUnloadedQuantity] = useState("");
  const [unloadedQuantityValidationArray, setUnloadedQuantityValidationArray] =
    useState([]);
  const [errTripAttachment, setErrTripAttachment] = useState("");
  const [data, setData] = useState({});

  const [allChargesList, setAllChargesList] = useState([
    "labour_charges",
    "night_halt_charges",
    "toll_charges",
    "others_expenses",
  ]);
  const [approxCostDetails, setApproxCostDetails] = useState({
    z: "",
  });

  const [currentTab, setCurrentTab] = useState(null);

  const { selectedTrip } = useSelector((state) => state.trip);
  const { pendingTripInvoiceList } = useSelector((state) => state.trip);

  const [attachmentDetailsList, setAttachmentDetailsList] = useState([
    {
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    },
  ]);
  const [newTripCost, setNewTripCost] = useState([]);
  const [otherExpenseType, setOtherExpenseType] = useState("");
  const [purchaseOrderAllItems, setPurchaseOrderAllItems] = useState([]);
  const [perBagCost, setPerBagCost] = useState("");
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [errTripCompleteMeterReading, setErrTripCompleteMeterReading] =
    useState("");
  const [
    errTripCompleteMeterReadingAttachment,
    setErrTripCompleteMeterReadingAttachment,
  ] = useState("");
  const [errCompleteTrip, setErrCompleteTrip] = useState("");
  const [
    tripCompleteMeterReadingAttachment,
    setTripCompleteMeterReadingAttachment,
  ] = useState({
    attachment_tag: "trip_complete_meter_reading_attachment",
    attachment_type: "",
    attachment_file: null,
  });

  const handleChangeTripMeterReadingAttachment = (e) => {
    setTripCompleteMeterReadingAttachment({
      ...tripCompleteMeterReadingAttachment,
      attachment_file: e.target.files[0],
    });
  };

  const getTripCompeltionRequestedData = async (id) => {
    handleOpen();
    const response = await getTripCompleteRequestDetails(id);

    if (response.status == 200) {
      setUnloadedQuantity(response.data.unloaded_quantity);
      setTripCompleteMeterReading(response.data.trip_complete_meter_reading);
      //
      setTripCompletionRequestData(response.data);
      setInvoicePaymentHistoryList(response.data.invoice_payment_history);
      const tripCostRequested = response.data.trip_cost;

      if (response.data.trip_cost.length > 0) {
        const filtredData = response.data.trip_cost.filter(
          (data) =>
            data.cost_type !== "trip_cost" && data.cost_type !== "advance"
        );
        const temp = {};
        filtredData.map((item) => (temp[item.cost_type] = item.cost_amount));

        setApproxCostDetails(temp);
        setCostKeys(Object.keys(temp));
        handleClose();
      }
      if (response.data.trip_history.length > 0) {
        const filtredDataRemarks = response.data.trip_history.filter(
          (data) => data.trip_status == "requested_for_completion"
        );
        if (filtredDataRemarks.length > 0) {
          setTripRemarks(filtredDataRemarks[0].remarks);
        }
        //
        // const temp = {};
        // filtredData.map((item) => (temp[item.cost_type] = item.cost_amount));
        handleClose();
        // setApproxCostDetails(temp);
      }
    }
    handleClose();
  };
  useEffect(() => {
    getTripCompeltionRequestedData(tripId);
  }, [tripId]);

  const getAllItemsPurchaseOrder = async (selectedTrip) => {
    if (
      selectedTrip.reference_number &&
      selectedTrip.load_source === "trip_purchase_order"
    ) {
      const response = selectedTrip.reference_number.map(async (item) => {
        const resp = await getTripItems(tripId, item);

        return resp;
      });
      const resolved = await Promise.all(response);

      setPurchaseOrderAllItems(resolved);
    }
    setLoadingPage(false);
  };

  useEffect(() => {
    if (Object.keys(selectedTrip).length > 0) {
      getAllItemsPurchaseOrder(selectedTrip);
    }
  }, [selectedTrip]);

  const getTripDetailsByIdApi = async (tripId) => {
    const response = await getTripDetailsById(tripId);

    dispatch(updateSelectedTrip(response.data));
  };

  useEffect(() => {
    if (Object.keys(selectedTrip).length === 0) {
      getTripDetailsByIdApi(tripId);
    }
  }, [tripId]);

  useEffect(() => {
    if (selectedTrip.reference_number) {
      setTripDeliveredBills(
        selectedTrip.reference_number.map((item) => ({
          reference_number: item,
          status:
            selectedTrip.pending_reference_numbers.indexOf(item) === -1
              ? "delivered"
              : "not-delivered",
        }))
      );
    }
    // if (selectedTrip.pending_reference_numbers) {
    //   setPendingReferenceNumbers(selectedTrip.pending_reference_numbers);
    // }
  }, [selectedTrip]);

  const tripDetails = async (trip) => {
    // handleOpen();
    const tripDetailsData = await getTripDetailsFromId(trip);
    dispatch(updateSelectedTrip(tripDetailsData));
    // handleClose();
  };

  useEffect(() => {
    tripDetails(tripId);
  }, [tripId]);

  const handleGoToTrips = () => {
    localStorage.setItem("FlagForApiCall", false);
    // localStorage.setItem("doNotReloadPage", true);
    history.push("/old-trips");
  };

  const handleRemoveCost = (costType) => {
    let tempApproxCost = approxCostDetails;
    delete tempApproxCost[costType];

    setApproxCostDetails(tempApproxCost);
    setCostKeys(Object.keys(tempApproxCost));
  };

  //Drawer starts

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  //Drawer ends

  const handleAttachmentChange = (e, index) => {
    e.preventDefault();
    let key = e.target.name;
    let value;
    if (key == "attachment_file") {
      value = e.target.files[0];
    } else {
      value = e.target.value;
    }

    let attachmentArrayTemp = [...attachmentDetailsList];
    attachmentArrayTemp[index][key] = value;
    setAttachmentDetailsList(attachmentArrayTemp);
  };

  const onFileChange = (event) => {
    // setTripAttachmentFile(event.target.files[0]);
  };

  const handleAddNewCost = () => {
    const tempCost = approxCostDetails;

    tempCost["z"] = "";
    setApproxCostDetails(tempCost);
    setCostKeys(Object.keys(tempCost));
  };

  const handleTripCostUpdate = () => {
    setNewTripCost();
  };

  const isQuantityValid = () => { };

  const isCompleteTripValid = () => {
    const tripCostTemp =
      Object.keys(approxCostDetails).filter((item) => item !== "z").length > 0;
    let attachmentValid = false;
    if (
      attachmentDetailsList.filter((item) => item.attachment_file != null)
        .length > 0
    ) {
      attachmentValid = true;
    }
    let isMeterReadingAttachmentValid = false;
    if (tripCompleteMeterReadingAttachment.attachment_file) {
      if (tripCompleteMeterReadingAttachment.attachment_file.size < 1000000) {
        isMeterReadingAttachmentValid = true;
      }
    }

    if (selectedTrip.load_source == "manual") {
      return (
        tripRemarks.trim() &&
        attachmentValid &&
        unloadedQuantity &&
        tripCompleteMeterReading &&
        tripCompleteMeterReadingAttachment.attachment_file &&
        isMeterReadingAttachmentValid
      );
    } else {
      return (
        tripRemarks.trim() &&
        attachmentValid &&
        tripCompleteMeterReading &&
        tripCompleteMeterReadingAttachment.attachment_file &&
        isMeterReadingAttachmentValid
      );
    }
  };

  useEffect(() => {
    async function fetchData() {
      const documentTypeData = await GetCompleteTripDocumentEnums();
      setDocumentTypeList(documentTypeData);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedTrip.trip_cost) {
      if (selectedTrip.trip_cost.length > 0) {
        //trip cost
        const tripCostTemp = selectedTrip.trip_cost.filter(
          (item) => item.cost_type === "trip_cost"
        );
        if (tripCostTemp.length > 0 && selectedTrip.unloaded_quantity) {
          setTripCostTotal(tripCostTemp[0].cost_amount);
          setPerBagCost(
            (
              tripCostTemp[0].cost_amount / selectedTrip.unloaded_quantity
            ).toFixed(2)
          );
        } else if (tripCostTemp.length > 0 && selectedTrip.quantity) {
          setTripCostTotal(tripCostTemp[0].cost_amount);
          setPerBagCost(
            (tripCostTemp[0].cost_amount / selectedTrip.quantity).toFixed(2)
          );
        }
      }
    }
  }, [selectedTrip]);

  const handlePurchaseOrderItemsDeliveredPost = async (itemId, itemDetails) => {
    const response = await itemDetails.map(
      async (item) => await purchaseOrderItemsDeliveredPost(itemId, item)
    );
    const resolved = await Promise.all(response);
    return resolved;
  };

  const handleSubmitTripComplete = async () => {
    if (isCompleteTripValid() && !loadingModal) {
      if (
        attachmentDetailsList
          .map((item) => {
            if (item.attachment_file) {
              if (item.attachment_file.size > 1000000) {
                return "greater";
              }
            }
          })
          .indexOf("greater") > -1
      ) {
        return;
      }
      setLoadingModal(true);

      const PoResponse = await handlePurchaseOrderItemsDeliveredPost(
        selectedTrip.id,
        purchaseOrderAllItems
      );

      for (let i = 0; i < PoResponse.length; i++) {
        // if (PoResponse[i].status === 200) {

        // }
        if (PoResponse[i].status === 400) {
          setErrCompleteTrip(PoResponse[i].data.detail);
          setLoadingModal(false);
          return;
        } else if (PoResponse[i].status === 422) {
          setErrCompleteTrip("Unloaded Quantity is not valid");
          setLoadingModal(false);
          return;
        }
      }

      // const POItemsApiResponse = PoResponse.map((item) => item);
      // setNewTripCost(
      //   Object.keys(approxCostDetails).map((item) => ({
      //     cost_type: item,
      //     cost_amount: approxCostDetails[item],
      //     trip_id: selectedTrip.id,
      //   }))
      // );
      // const attachmentResponse = TripAttachmentPost(
      //   "jhgf",
      //   selectedTrip.id,
      //   "mn",
      //   ",kjnb",
      //   "end_trip",
      //   attachmentDetailsList
      // );

      // const resolved = await Promise.all(attachmentResponse);

      const response = await requestCompleteTrip(
        "requested_for_completion",
        tripRemarks,
        selectedTrip.id,
        tripDeliveredBills,
        Object.keys(approxCostDetails)
          .filter((item) => item !== "z")
          .map((item) => ({
            cost_type: item,
            cost_amount: approxCostDetails[item],
            trip_id: selectedTrip.id,
          })),
        unloadedQuantity,
        tripCompleteMeterReading,
        tripCompleteMeterReadingAttachment,
        "end_trip",
        attachmentDetailsList[0]
      );
      if (response.status == 400) {
        setLoadingModal(false);
        setErrCompleteTrip(response.data.detail);
      } else if (response.status == 200) {
        if (attachmentDetailsList.length > 1) {
          const attachmentListTemp = [...attachmentDetailsList];
          attachmentListTemp.shift();
          const attachmentResponse = TripAttachmentPost(
            "jhgf",
            selectedTrip.id,
            "mn",
            ",kjnb",
            "end_trip",
            attachmentListTemp
          );

          const resolved = await Promise.all(attachmentResponse);
        }

        localStorage.setItem("FlagForApiCall", false);
        setLoadingModal(false);
        history.push("/old-trips");
      } else if (response.status == 422) {
        setLoadingModal(false);
        setErrCompleteTrip(
          `${response?.data?.detail[0]?.loc?.slice(-1)} : ${response?.data?.detail[0]?.msg
          }`
        );
      } else {
        setLoadingModal(false);
        setErrCompleteTrip(`${response.status} : ERROR`);
      }
      //
    } else {
      setErrRemarks("Please enter valid remarks");
      setErrTripCost("Please enter valid cost");
      setErrTripAttachment("Please upload valid attachment");
      setErrUnloadedQuantity("Please enter valid unloaded quantity");
      setErrTripCompleteMeterReading("Please enter valid meter reading");
      setErrTripCompleteMeterReadingAttachment(
        "Please select valid attachment"
      );
    }
  };

  const ValidateUnloadedQuantity = () => {
    const resposnee = purchaseOrderAllItems.map((itemList) => {
      return itemList.map((item) => {
        if (item.unloaded_quantity) {
          return true;
        } else {
          return false;
        }
      });
    });
    setUnloadedQuantityValidationArray(resposnee);
  };

  const handleDeliveredQtyChange = (indexs, index, e) => {
    if (e.target.value >= 0) {
      const purchaseOrderItemsTemp = [...purchaseOrderAllItems];
      purchaseOrderItemsTemp[indexs][index]["unloaded_quantity"] =
        e.target.value;
      purchaseOrderItemsTemp[indexs][index]["status"] = "delivered";
      setPurchaseOrderAllItems(purchaseOrderItemsTemp);
    }
    ValidateUnloadedQuantity();
  };

  const handleAddAttachment = () => {
    var attachmentTemp = [...attachmentDetailsList];
    attachmentTemp.push({
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    });
    setAttachmentDetailsList(attachmentTemp);
  };

  const handleDeliveredCheckboxToggle = (orderDetail, index) => {
    let tripDeliveredBillsTemp = [...tripDeliveredBills];
    tripDeliveredBillsTemp[index]["status"] =
      tripDeliveredBillsTemp[index]["status"] == "delivered"
        ? "not-delivered"
        : "delivered";

    setTripDeliveredBills(tripDeliveredBillsTemp);
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  const uploadInputRef = useRef(null);

  return (
    <div className="complete-trip-main-container">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img
            src={success}
            alt=""
            width="120px"
            height="90px"
          // style={{ marginTop: "200px" }}
          />
          <div style={{ color: "black" }}>loading data...</div>
        </Box>
      </Modal>
      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Complete trip Request" />
        </div>
      ) : (
        <div className="complete-trip-sidebar">
          <SideBarMain />
        </div>
      )}
      {loadingPage ? (
        <div className="complete-trip-right-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              // backgroundColor: "grey",
            }}
          >
            {" "}
            <img
              src={success}
              alt=""
              width="120px"
              height="90px"
              style={{ marginTop: "200px" }}
            />
            <h6 style={{ color: "grey" }}>loading data...</h6>
          </div>
        </div>
      ) : (
        <div className="complete-trip-right-container">
          <div className="complete-trip-head">
            <ArrowBack
              style={{ cursor: "pointer" }}
              onClick={handleGoToTrips}
            />{" "}
            <h4 className="complete-trip-head-text">
              Enter details for complete trip request
            </h4>
          </div>
          <div className="complete-trip-form-container">
            <div className="complete-trip-row">
              <h4 className="complete-trip-head-text">
                Trip code :{" "}
                {selectedTrip.trip_code ? selectedTrip.trip_code : " - "}
              </h4>
            </div>
            <div className="complete-trip-row">
              <h4 className="complete-trip-head-text">
                Vehicle number :{" "}
                {selectedTrip.vehicle_number
                  ? selectedTrip.vehicle_number
                  : " - "}
              </h4>
            </div>
            <div className="complete-trip-row">
              <h4 className="complete-trip-head-text">
                Per bag cost : Rs. {perBagCost ? perBagCost : " - "}
              </h4>
            </div>
          </div>
          <div className="complete-trip-form-container">
            <div className="complete-trip-row">
              <div className="complete-trip-input-container">
                <h4 className="complete-trip-input-head">
                  {" "}
                  Please enter the remarks (max. 300 characters)
                  <sup style={{ color: "red" }}>*</sup>
                  {/* <span className="create-trip-destination-remove">
                        <Close />
                      </span> */}
                </h4>

                <textarea
                  className="complete-trip-textarea"
                  value={tripRemarks}
                  onChange={(e) => {
                    setTripRemarks(e.target.value);
                    setErrCompleteTrip("");
                  }}
                  rows={5}
                />
                <div className="complete-trip-error">
                  {tripRemarks.trim() ? null : errRemarks}
                </div>
              </div>
            </div>
            <div className="trip-list-reference-list">
              <h4 className="complete-trip-head">
                {selectedTrip.load_source && tripDeliveredBills.length > 0
                  ? selectedTrip.load_source == "trip_invoice"
                    ? "Delivered Invoices"
                    : null
                  : null}
              </h4>
              <div className="complete-trip-invoice-grid">
                {selectedTrip.load_source == "trip_invoice"
                  ? tripDeliveredBills.map((item, index) => {
                    return (
                      <div className="complete-trip-single-reference">
                        {item.status == "delivered" ? (
                          <CheckCircleIcon
                            style={{ color: "green", fontSize: "1.2rem" }}
                          />
                        ) : (
                          <input
                            id={item.reference_number}
                            name={item.reference_number}
                            type="checkbox"
                            value={item.reference_number}
                            disabled={
                              selectedTrip.load_source === "trip_invoice"
                                ? true
                                : false
                            }
                            onClick={() =>
                              handleDeliveredCheckboxToggle(item, index)
                            }
                            checked={
                              item.status == "delivered" ? true : false
                            }
                          />
                        )}

                        <label
                          for={item.reference_number}
                          className="complete-trip-reference-number"
                        >
                          {item.reference_number}
                        </label>
                      </div>
                    );
                  })
                  : null}
              </div>
            </div>
            {pendingInvoiceMsgp ? (
              <div
                className="complete-trip-row"
                style={{
                  color: "red",
                  backgroundColor: "#F1CCCC",
                  padding: "5px",
                  width: "70%",
                  marginLeft: "10px",
                }}
              >
                {pendingInvoiceMsgp ? pendingInvoiceMsgp : null}
                {/* {pendingReferenceNumbers.map((invoice) => invoice)} */}
              </div>
            ) : null}
            {selectedTrip.load_source === "trip_purchase_order" ? (
              <div>
                {purchaseOrderAllItems.length > 0
                  ? purchaseOrderAllItems.map((itemList, indexs) => {
                    return (
                      <div className="trip-list-reference-list-table">
                        <h4 className="complete-trip-head">
                          Purchase order number :{" "}
                          {itemList[0].reference_number}
                        </h4>
                        <div className="complete-trip-invoice-grid">
                          <table id="invoice-items-complete">
                            <thead className="thead_heading-complete">
                              <tr className="table-head-row-complete">
                                <th className="column001">Items</th>
                                <th className="column003">Delivered qty</th>
                              </tr>
                            </thead>
                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              {/* {isMobile ? null : <hr></hr>} */}
                            </div>
                            <tbody className="body">
                              {itemList.map((item, index) => {
                                return (
                                  <tr>
                                    <td className="column001">
                                      {item.item_name}
                                    </td>

                                    <td className="column003">
                                      <input
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        className="delivery-invoice-quantity-input-complete"
                                        onChange={(e) =>
                                          handleDeliveredQtyChange(
                                            indexs,
                                            index,
                                            e
                                          )
                                        }
                                      />
                                      {/* <div className="complete-trip-error">
                                          Enter valid quantity
                                        </div> */}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    );
                  })
                  : null}
              </div>
            ) : null}
            <div className="complete-trip-cost">
              <h4 className="complete-trip-head">Trip Cost details </h4>
              {Object.keys(approxCostDetails).filter((item) => item !== "z")
                .length > 0 ? (
                <div className="complete-trip-drivers-box-row-cost">
                  <div className="complete-trip-cost-left-side">
                    {Object.keys(approxCostDetails)
                      .filter((item) => item != "z")
                      .filter((item) => item != "id")
                      .filter((item) => item != "cost_type")
                      .filter((item) => item != "modified_on")
                      .filter((item) => item != "created_on")
                      .filter((item) => item != "is_active")
                      .map((item) => {
                        return (
                          <div
                            className="complete-trip-drivers-box-row"
                          // style={{ justifyContent: "space-evenly" }}
                          >
                            <h4 className="complete-trip-drivers-left">
                              {item
                                .split("_")
                                .join(" ")
                                .split("")
                                .map((item, index) => {
                                  if (index == 0) {
                                    return item.toUpperCase();
                                  } else {
                                    return item;
                                  }
                                })
                                .join("")}{" "}
                            </h4>
                            <h4 className="complete-trip-drivers-right">
                              {" "}
                              :
                              {/* <span style={{ fontFamily: "sans-serif" }}> */}{" "}
                              Rs. {/* </span> */}
                              {approxCostDetails[item]
                                ? approxCostDetails[item]
                                : " - "}
                            </h4>
                          </div>
                        );
                      })}
                  </div>
                  <div className="complete-trip-drivers-box-row">
                    <button
                      className="complete-trip-edit-cost-btn"
                      onClick={() => {
                        setCurrentTab("cost");
                        toggleDrawer("right", true);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          cursor: "pointer",
                        }}
                      >
                        <Edit />{" "}
                        <span style={{ fontWeight: "500", margin: "0px 10px" }}>
                          Edit
                        </span>
                      </div>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="complete-trip-cost-input">
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="complete-trip-input-cost"
                    variant="outlined"
                    onFocus={() => setCurrentTab("cost")}
                    onClick={() => toggleDrawer("right", true)}
                    placeholder="Enter cost details"
                    value={""}
                  // onChange={(e) => {
                  //   setTripDistance(e.target.value);
                  // }}
                  />
                  {/* <div className="complete-trip-error">
                    {Object.keys(approxCostDetails).filter(
                      (item) => item !== "z"
                    ).length > 0
                      ? null
                      : errTripCost}
                  </div> */}
                </div>
              )}
            </div>
            <div className="complete-trip-cost">
              <div className="complete-trip-cost-input">
                <h4 className="complete-trip-head">
                  Trip Complete Meter Reading (with attachment){" "}
                  <sup style={{ color: "red" }}>*</sup>
                </h4>
                <TextField
                  size="small"
                  autoComplete="off"
                  className="complete-trip-input-cost"
                  variant="outlined"
                  placeholder="Enter meter reading"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  value={tripCompleteMeterReading}
                  onChange={(e) => {
                    setTripCompleteMeterReading(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: "pointer",
                          color: "#016938",
                          fontWeight: "600",
                        }}
                      // onClick={handleAddAttachment}
                      >
                        <button className="upload_button">
                          <input
                            // name="upload"
                            name="attachment_file"
                            id={`upload`}
                            type="file"
                            accept="image/png, image/jpeg, application/pdf"
                            onChange={(e) => {
                              handleChangeTripMeterReadingAttachment(e);
                            }}
                            hidden
                            style={{
                              backgroundColor: "#e8f9e0",
                            }}
                          />
                          <label
                            htmlFor={`upload`}
                            style={{
                              backgroundColor: "#e8f9e0",
                            }}
                          >
                            <img
                              src={UploadImage}
                              alt=""
                              width="20px"
                              height="20px"
                              style={{
                                backgroundColor: "#e8f9e0",
                                // width: "100%",
                                // height: "100%",
                              }}
                            />
                          </label>
                        </button>
                      </InputAdornment>
                    ),
                  }}
                />

                <div className="create-trip-attachment-name">
                  {tripCompleteMeterReadingAttachment.attachment_file != null
                    ? tripCompleteMeterReadingAttachment.attachment_file.name
                    : null}
                </div>
                <div className="complete-trip-error">
                  {tripCompleteMeterReading
                    ? null
                    : errTripCompleteMeterReading}
                </div>
                <div className="complete-trip-error">
                  {tripCompleteMeterReadingAttachment.attachment_file
                    ? null
                    : errTripCompleteMeterReadingAttachment}
                </div>
                <div className="complete-trip-error">
                  {tripCompleteMeterReadingAttachment.attachment_file
                    ? tripCompleteMeterReadingAttachment.attachment_file.size >
                      1000000
                      ? `File size should be less than ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB`
                      : null
                    : null}
                </div>
              </div>
            </div>
            {selectedTrip.load_source === "manual" ? (
              <div className="complete-trip-cost">
                <div className="complete-trip-cost-input">
                  <h4 className="complete-trip-head">
                    Unloaded Quantity <sup style={{ color: "red" }}>*</sup>
                  </h4>
                  <TextField
                    size="small"
                    autoComplete="off"
                    className="complete-trip-input-cost"
                    variant="outlined"
                    placeholder="Enter unloaded quantity"
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={unloadedQuantity}
                    onChange={(e) => {
                      setUnloadedQuantity(e.target.value);
                      setErrCompleteTrip("");
                    }}
                  />
                  <div className="complete-trip-error">
                    {unloadedQuantity > 0 ? null : errUnloadedQuantity}
                  </div>
                </div>
              </div>
            ) : null}

            {/* <div className="complete-trip-row"> */}
            <div className="complete-trip-input-container">
              <h4 className="complete-trip-head">
                {" "}
                Add attachments <sup style={{ color: "red" }}>*</sup>
              </h4>

              {attachmentDetailsList.map((item, index) => {
                return (
                  // <div key={index} className="complete-trip-modal-row">
                  <div key={index} className="complete-trip-modal-input-group">
                    <h4 className="complete-trip-modal-head">
                      Document type {index + 1}
                    </h4>

                    <TextField
                      size="small"
                      id="dropdown"
                      select
                      autoComplete="off"
                      className="complete-trip-input-attachment"
                      variant="outlined"
                      placeholder="Enter document type"
                      name="attachment_tag"
                      // disabled={index == 0 ? true : false}
                      value={item.attachment_tag}
                      onChange={(e) => {
                        handleAttachmentChange(e, index);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{
                              cursor: "pointer",
                              color: "#016938",
                              fontWeight: "600",
                            }}
                          // onClick={handleAddAttachment}
                          >
                            <button className="upload_button">
                              <input
                                // name="upload"
                                name="attachment_file"
                                id={`upload${index}`}
                                type="file"
                                accept="image/png, image/jpeg, application/pdf"
                                onChange={(e) => {
                                  handleAttachmentChange(e, index);
                                  handleAddAttachment(e);
                                }}
                                hidden
                                style={{
                                  backgroundColor: "#e8f9e0",
                                }}
                              />
                              <label
                                htmlFor={`upload${index}`}
                                style={{
                                  backgroundColor: "#e8f9e0",
                                }}
                              >
                                <img
                                  src={UploadImage}
                                  alt=""
                                  width="20px"
                                  height="20px"
                                  style={{
                                    backgroundColor: "#e8f9e0",
                                    // width: "100%",
                                    // height: "100%",
                                  }}
                                />
                              </label>
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    >
                      <MenuItem value="0" disabled>
                        Select document type
                      </MenuItem>

                      {documentTypeList.map((item) => {
                        return (
                          <MenuItem value={item}>
                            {item
                              .split("_")
                              .join(" ")
                              .split("")
                              .map((item, index) => {
                                if (index == 0) {
                                  return item.toUpperCase();
                                } else {
                                  return item;
                                }
                              })
                              .join("")}
                          </MenuItem>
                        );
                      })}
                    </TextField>

                    <div className="complete-trip-attachment-name">
                      {item.attachment_file != null
                        ? item.attachment_file.name
                        : null}
                    </div>
                    <div className="complete-trip-error">
                      {index === 0 && item.attachment_file === null
                        ? errTripAttachment
                        : null}
                    </div>
                    <div className="error-trip">
                      {item.attachment_file
                        ? item.attachment_file.size > 1000000
                          ? `File size is too large.( < ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                          : null
                        : null}
                    </div>
                  </div>
                );
              })}
              {/* </div> */}
            </div>
            <div
              className="complete-trip-input-container"
              style={{ width: "70%" }}
            >
              {errCompleteTrip ? (
                <Alert severity="error">{errCompleteTrip}</Alert>
              ) : null}
            </div>

            <div className="complete-trip-row">
              <div className="complete-trip-button-container">
                <button
                  className="complete-trip-add-attachment-submit"
                  onClick={handleSubmitTripComplete}
                  disabled={
                    finalFiltredArrayOfPendingInvoicesStatus.includes(
                      "submitted"
                    ) ||
                      finalFiltredArrayOfPendingInvoicesStatus.includes(
                        "recommended"
                      )
                      ? true
                      : false
                  }
                  style={{
                    color: "white",
                    backgroundColor: `${finalFiltredArrayOfPendingInvoicesStatus.includes(
                      "submitted"
                    ) ||
                      finalFiltredArrayOfPendingInvoicesStatus.includes(
                        "recommended"
                      )
                      ? "#CFDEC8"
                      : "#016938"
                      }`,
                    width: "200px",
                  }}
                >
                  {loadingModal ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    "Submit for Approval"
                  )}
                </button>
              </div>
            </div>
          </div>
          {/*Cost details start*/}
          {currentTab == "cost" ? (
            <>
              <Drawer
                anchor={"right"}
                open={state["right"]}
                onClose={() => toggleDrawer("right", false)}
              >
                <div
                  className="create-trip-drawer-container"
                // style={{ backgroundColor: "red", width: "500px" }}
                >
                  <div
                    className="create-trip-right-most-head"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4>Trip cost :</h4>
                    <Close
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() => toggleDrawer("right", false)}
                    />
                  </div>

                  {costKeys
                    .filter((item) => item != "id")
                    .filter((item) => item != "cost_type")
                    .filter((item) => item != "modified_on")
                    .filter((item) => item != "created_on")
                    .filter((item) => item != "is_active")
                    .map((item, index) => {
                      return (
                        <>
                          <div className="create-trip-from-row">
                            <div className="create-trip-input-container">
                              {" "}
                              <TextField
                                size="small"
                                select
                                autoComplete="off"
                                className="create-trip-input"
                                variant="outlined"
                                placeholder="load source"
                                value={item}
                                onChange={(e) => {
                                  const temp = approxCostDetails;
                                  temp[e.target.value] = temp[item];
                                  delete temp[item];
                                  setApproxCostDetails(temp);
                                  setCostKeys(Object.keys(temp));
                                  setErrCompleteTrip("");
                                }}
                              >
                                <MenuItem value="z" disabled>
                                  Select Cost
                                </MenuItem>
                                {allChargesList.map((item) => {
                                  return (
                                    <MenuItem value={item}>
                                      {item
                                        .split("_")
                                        .join(" ")
                                        .split("")
                                        .map((item, index) => {
                                          if (index == 0) {
                                            return item.toUpperCase();
                                          } else {
                                            return item;
                                          }
                                        })
                                        .join("")}
                                    </MenuItem>
                                  );
                                })}
                              </TextField>
                              {/* {item == "others_expenses" ? (
                                <div
                                  className="create-trip-input-container"
                                  style={{ marginLeft: "0px" }}
                                >
                                  <TextField
                                    className="create-trip-input"
                                    variant="outlined"
                                    autoComplete="off"
                                    style={
                                      isMobile
                                        ? { width: "95%" }
                                        : { margin: "5px 0px", width: "85%" }
                                    }
                                    value={otherExpenseType}
                                    onChange={(e) =>
                                      setOtherExpenseType(e.target.value)
                                    }
                                    size="small"
                                    placeholder="Enter cost type"
                                  />
                                </div>
                              ) : null} */}
                            </div>
                            <div
                              className="create-trip-input-container"
                              style={{ display: "flex" }}
                            >
                              <TextField
                                size="small"
                                autoComplete="off"
                                className="create-trip-input"
                                variant="outlined"
                                placeholder={`Enter charges`}
                                defaultValue={approxCostDetails[item]}
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      style={{ fontFamily: "sans-serif" }}
                                    >
                                      {" "}
                                      ₹{" "}
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  const tempCost = approxCostDetails;

                                  tempCost[item] = e.target.value;

                                  setApproxCostDetails(tempCost);
                                }}
                              />
                              {isMobile ? (
                                <div className="complete-trip-cost-remove">
                                  {" "}
                                  <Close
                                    onClick={() => handleRemoveCost(item)}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              ) : null}
                            </div>
                            {isMobile ? null : (
                              <div className="complete-trip-cost-remove">
                                {" "}
                                <Close
                                  onClick={() => handleRemoveCost(item)}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      );
                    })}

                  <div className="create-trip-add-cost-row">
                    <button
                      className="create-trip-add-cost"
                      onClick={handleAddNewCost}
                    >
                      Add new cost
                    </button>
                  </div>

                  <div className="create-trip-input-container">
                    <button
                      className="create-trip-save-btn"
                      onClick={() => toggleDrawer("right", false)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Drawer>
            </>
          ) : null}
          {/*Cost details end*/}
        </div>
      )}
    </div>
  );
}

export default CompleteTripRequest;
