import axiosInterceptor from "../../config/https";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";
import apiUrl from "../../url/apiUrl";

export const getEsclationMatrix = async () => {
  let url = `${apiUrl.escalationMatrixBaseUrl}`;
  try {
    const response = await axiosInterceptor.get(url);
    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const saveEscalation = (escalationObj) => {
  let data = JSON.stringify({
    target_trip_status: escalationObj.target_trip_status,
    resolution_time_in_hours: escalationObj.resolution_time_in_hours,
    responsible_person: escalationObj.responsible_person_email,
    remarks: "",
    level1_responsible_person: escalationObj.level1_responsible_person_email,
    level1_resolution_time_in_hours:
      escalationObj.level1_resolution_time_in_hours,
    level2_responsible_person: escalationObj.level2_responsible_person_email,
    level2_resolution_time_in_hours:
      escalationObj.level2_resolution_time_in_hours,
    level3_responsible_person: escalationObj.level3_responsible_person_email,
    level3_resolution_time_in_hours:
      escalationObj.level3_resolution_time_in_hours,
  });
  var config = {
    method: "POST",
    url: `
        ${apiUrl.escalationMatrixBaseUrl}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    timeout: 30000,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (!error.response) {
          resolve(error);
        }
        resolve(error.response);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      });
  });
};

export const editEscalation = (escalationObj, id) => {
  let data = JSON.stringify({
    target_trip_status: escalationObj.target_trip_status,
    resolution_time_in_hours: escalationObj.resolution_time_in_hours,
    responsible_person: escalationObj.responsible_person_email,
    remarks: "",
    level1_responsible_person: escalationObj.level1_responsible_person_email,
    level1_resolution_time_in_hours:
      escalationObj.level1_resolution_time_in_hours,
    level2_responsible_person: escalationObj.level2_responsible_person_email,
    level2_resolution_time_in_hours:
      escalationObj.level2_resolution_time_in_hours,
    level3_responsible_person: escalationObj.level3_responsible_person_email,
    level3_resolution_time_in_hours:
      escalationObj.level3_resolution_time_in_hours,
  });
  var config = {
    method: "put",
    url: `${apiUrl.escalationMatrixBaseUrl}/${prefixSalt()}-${convertToBase64(
      id
    )}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    timeout: 30000,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (!error.response) {
          resolve(error);
        }
        resolve(error.response);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      });
  });
};
