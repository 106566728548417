import styled from "styled-components";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  colorPaletteDark,
} from "./themes/theme";

export const AppStyle = styled.div`
  .Mui-selected {
    background-color: ${colorPalette.primaryColor} !important;
    color: white !important;
    font-family: inherit;
    font-weight: ${FontWeight.regular};
  }

  p,
  li,
  a,
  input {
    color: ${colorPalette.paragraphColor};
  }
`;
export const AppStyleDarkMode = styled.div`
  .Mui-selected {
    background-color: ${colorPaletteDark.primaryColor} !important;
    color: white !important;
    font-family: inherit;
    font-weight: ${FontWeight.regular};
  }

  p,
  li,
  a,
  input {
    color: ${colorPalette.paragraphColor};
  }
`;
