import axios from "axios";
import axiosInterceptor, { axiosInstance } from "../../../config/https";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import apiUrl from "../../../url/apiUrl";
// import axiosInterceptor from "../../../config/httpsForCustomer";

export const createIndentPost = async (
  indentSource,
  indentDestination,
  indentPickupDate,
  indentVehicleType,
  indentQuantity,
  indentValue,
  indentMaterialType,
  indentAttachment,
  attachmentName,
  attachmentType,
  customerId,
  indentstatus,
  numberofVehicles,
  remarks
) => {
  try {
    const data = new FormData();
    data.append("sources", indentSource);
    data.append("destinations", indentDestination);
    data.append("pickup_date", indentPickupDate);
    data.append("vehicle_type", indentVehicleType);
    data.append("quantity_of_goods", indentQuantity);
    data.append("image", indentAttachment);
    data.append("material_type", indentMaterialType);
    data.append("value_of_goods", indentValue);
    data.append("document_name", attachmentName);
    data.append("customer_id", customerId);
    data.append("number_of_vehicles", numberofVehicles);
    data.append("document_type", attachmentType);
    data.append("indent_status", indentstatus);
    data.append("currency_code", process.env.REACT_APP_CURRECY_CODE);
    data.append("unit_id", "1");
    data.append("remark", remarks);

    const response = await axiosInterceptor.post(apiUrl.indentBaseUrl, data, {
      headers: { "Content-type": "multipart/form-data" },
    });

    return response;
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
  }
};

export const changeIndentPut = async (
  // indentSource,
  // indentDestination,
  indentPickupDate,
  indentVehicleType,
  indentQuantity,
  indentValue,
  indentMaterialType,
  // indentAttachment,
  // attachmentName,
  customerId,
  indentStatus,
  numberofVehicles,
  remarks,
  id
) => {
  try {
    const data = new FormData();
    // data.append("sources", indentSource);
    // data.append("destinations", indentDestination);
    data.append("pickup_date", indentPickupDate);
    data.append("vehicle_type", indentVehicleType);
    data.append("quantity_of_goods", indentQuantity);
    // data.append("image", indentAttachment);
    data.append("material_type", indentMaterialType);
    data.append("value_of_goods", indentValue);
    // data.append("document_name", attachmentName);
    data.append("customer_id", customerId);
    data.append("number_of_vehicles", numberofVehicles);
    data.append("indent_status", indentStatus);
    data.append("indent_id", id);
    // data.append("document_type", "image");

    //hard coded values
    data.append("currency_code", process.env.REACT_APP_CURRECY_CODE);
    data.append("unit_id", 1);
    // data.append("document_type", "image");
    data.append("remark", remarks);

    const response = await axiosInterceptor.put(
      `${apiUrl.indentBaseUrl}/${prefixSalt()}-${convertToBase64(id)}`,
      data,
      {
        headers: { "Content-type": "multipart/form-data" },
      }
    );

    return response;
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
  }
};

export const fetchAddressFromPincodeApi = async (pincode) => {
  try {
    const response = await axiosInterceptor.get(`${apiUrl.vilagePincodeUrl}/${prefixSalt()}-${convertToBase64(pincode)}`);
    if (response) {
      return response;
    }
  } catch (e) {
    return e.response;
  }
};
export const fetchIndentById = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.indentBaseUrl}/${prefixSalt()}-${convertToBase64(id)}`
    );
    if (response) {
      if (response.status === 200) {
        return response.data;
      }
    }
  } catch (e) {
    if (e.response.status === 403) {
      // localStorage.clear();
      localStorage.removeItem("CUSTOMER_DATA");
      localStorage.removeItem("x_token");
      localStorage.removeItem("website_url");
      localStorage.removeItem("LOGIN_DATA");
      window.location.reload();
    }
  }
};
export const indentAttachmentsPost = (
  document_type,
  image,
  document_name,
  id
) => {
  let data = new FormData();
  data.append("document_type", document_type);
  // data.append("permit_expiry_date_str", `${permit_expiry_date}`);
  data.append("image", image);
  data.append("document_name", document_name);

  var config = {
    method: "post",
    url: `${apiUrl.indentBaseUrl}/${prefixSalt()}-${convertToBase64(
      id
    )}/attachments`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          // localStorage.clear();
          localStorage.removeItem("CUSTOMER_DATA");
          localStorage.removeItem("x_token");
          localStorage.removeItem("website_url");
          localStorage.removeItem("LOGIN_DATA");
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};
export const indentAttachmentsPut = (
  document_type,
  image,
  document_name,
  id,
  attachmentId
) => {
  let data = new FormData();
  data.append("document_type", document_type);
  // data.append("permit_expiry_date_str", `${permit_expiry_date}`);
  data.append("image", image);
  data.append("document_name", document_name);

  var config = {
    method: "put",
    url: `${apiUrl.indentBaseUrl}/${prefixSalt()}-${convertToBase64(
      id
    )}/attachments/${prefixSalt()}-${convertToBase64(attachmentId)}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          // localStorage.clear();
          localStorage.removeItem("CUSTOMER_DATA");
          localStorage.removeItem("x_token");
          localStorage.removeItem("website_url");
          localStorage.removeItem("LOGIN_DATA");
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};

export const fetchIndentAttchmentsId = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.indentBaseUrl}/${prefixSalt()}-${convertToBase64(
        id
      )}/attachments`
    );
    if (response) {
      if (response.status === 200) {
        return response;
      }
    }
  } catch (e) {
    if (e.response.status === 403) {
      // localStorage.clear();
      localStorage.removeItem("CUSTOMER_DATA");
      localStorage.removeItem("x_token");
      localStorage.removeItem("website_url");
      localStorage.removeItem("LOGIN_DATA");
      window.location.reload();
    }
  }
};

export const fetchAssignmentNames = async (firstName) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.userListServicesBaseUrl}/users?role_name=Logistics Executive&first_name=${firstName}`
    );
    if (response) {
      return response;
    }
  } catch (e) {
    return e.response;
  }
};

export const createAssignee = (indentId, name, email) => {
  let data = JSON.stringify({
    indent_id: `${indentId}`,
    assigned_to_name: `${name}`,
    assigned_to: `${email}`,
    // assigned_by:
  });
  var config = {
    method: "post",
    url: `${apiUrl.onboardingBaseUrl}/v1/assignment`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        // if (error.response.status === 401) {
        //   localStorage.clear();
        //   window.location.reload();
        // }
        resolve(error.response);
      });
  });
};
export const changeAssignee = (indentId, email, id) => {
  let data = JSON.stringify({
    indent_code: `${indentId}`,
    assigned_to: `${email}`,
    // assigned_by: `${email}`,
    // assigned_by:
  });
  var config = {
    method: "put",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v1/assignment/${prefixSalt()}-${convertToBase64(id)}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        // if (error.response.status === 401) {
        //   localStorage.clear();
        //   window.location.reload();
        // }
        resolve(error.response);
      });
  });
};

export const indentExport = async (email) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.indentBaseUrl}/report-export?email=${email}`
    );
    console.log("response api", response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
