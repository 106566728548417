import styled from "styled-components";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../../themes/theme";

export const Trip_settings_main_Container = styled.div`
  flex: 9.5;
  height: 99vh;
  position: relative;
  overflow: scroll;
  .trip-settings-topbar-customer-add {
    background-color: red;
  }
  .trip-settings-topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
  }
  .trip-settins-table-container {
    width: 100%;
    height: 80vh;
    overflow-y: scroll;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .trip-settins-table {
    position: relative;
    width: 95%;
    height: 100%;
    /* background-color: blue; */
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #dddddd;
  }
  .loader_container {
    width: 100%;
    height: 300px;
    position: absolute;
    top: 100;
    bottom: 100;
    left: 0;
    /* height: 300px; */
    display: flex;
    color: #9a9896;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .loading_more_data_trip_settings {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${colorPalette.white};
    padding: 10px;
    font-size: ${typography.heading4};
    font-weight: ${FontWeight.semibold};
    color: ${colorPalette.primaryColor};
    width: 100%;
    text-align: center;
  }

  @media only screen and (max-width: 499px) {
    .trip-settings-table-headers {
      font-size: 10px;
    }
  }
`;
export const Trip_settings_wrapper_drawer = styled.div`
  width: 300px;
  height: 100vh;
  /* padding: 5px 5px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .trip-settings-head-drawer {
    padding: 5px 8px;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .whitelisted_customer_list {
    width: 100%;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    align-items: center;

    justify-content: space-between;
  }
  .trip-settings-row {
    width: 100%;
    /* z-index: -2; */
  }
  .trip-settings-row_add_customer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .trip-settings-row-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0px;
  }
  .settings_list_container {
    padding: 10px;
    width: 100%;
    overflow-y: scroll;
    /* background-color: red; */
  }
  .input_field_trip_settings {
    width: 100%;
    margin: 5px 0px;
    /* opacity: 0.33; */
  }
`;
export const Trip_settings_wrapper_drawer_whitelistedcustomer_list = styled.div`
  width: 350px;
  height: 80vh;

  /* padding: 5px 5px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .trip-settings-head-drawer {
    padding: 5px 8px;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .whitelisted_customer_list {
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    padding: 3px 3px;
    justify-content: space-between;
  }
  .trip-settings-row {
    width: 100%;
    /* z-index: -2; */
  }
  .trip-settings-row_add_customer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .trip-settings-row-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0px;
  }
  .settings_list_container {
    padding: 10px;
    width: 100%;
    overflow-y: scroll;
    /* background-color: red; */
  }
  .input_field_trip_settings {
    width: 100%;
    margin: 5px 0px;
    /* opacity: 0.33; */
  }
`;

export const Trip_settings_wrapper_drawer_bottom = styled.div`
  width: 100%;
  height: 20vh;
  background-color: #dddddd;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: rgba(221, 221, 221, 0.27) 0px 0px 0.75em,
    rgba(90, 125, 188, 0.05) 0px 0.75em 1em;
  .error_trip_settings {
    background-color: #ffe0e0;
    color: red;
    padding: 5px 1px;
    font-size: 12px;
    margin: 5px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .trip-settings-topbar-customer-list-add {
    display: flex;
    width: 100%;
    /* align-items: center; */
    flex-direction: column;
    /* justify-content: space-between; */
    width: 100%;
    /* padding: 10px; */
  }
`;
