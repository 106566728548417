import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../../themes/theme";

export const Trip_pickup_main_container = styled.div`
  /* .trip-pickup-main-container { */
  width: 100%;
  height: 61vh;
  overflow-y: scroll;
  display: flex;
  /* margin: 10px; */
  /* align-items: center; */
  justify-content: center;
  /* } */

  .trip-pickup-left-text {
    color: black;
    font-weight: ${FontWeight.semibold};
    font-size: ${typography.heading5};
    /* margin: 5px; */
  }
  .trip-pickup-right-text {
    color: ${colorPalette.paragraphColor};
    font-weight: ${FontWeight.semibold};
    font-size: ${typography.heading5};
    /* margin: 5px; */
    /* position: absolute;
  left: 30%; */
  }
  .trip-pickup-order-text {
    /* position: relative; */
    margin: 5px 0px;
  }
  .trip-pickup-single {
    background-color: ${colorPalette.white};
    border-radius: 5px;
    /* box-shadow: 0px 0px 10px -8px rgba(0, 0, 0, 0.25); */
    display: flex;
    justify-content: space-between;
    box-shadow: ${colorPalette.boxShadowPrimary};

    margin: 10px 0px;
    padding: 20px 30px;
    width: 100%;
  }
  .trip-pickup-attach-icon {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colorPalette.secondaryWhite};
    border-radius: 50%;
  }
  .trip-pickup-single-left {
    padding: 10px 0px;
  }
  .trip-pickup-single-right {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  .trip-pickup-sidebar {
    flex: 1.7;
    width: 100%;
    height: 100vh;
  }
  .trip-pickup-single-middle {
    padding: 10px 20px;
  }
  .trip-pickup-statuss {
    display: flex;
    margin: 8x 0px;
    align-items: center;
  }
  .trip-pickup-date {
    color: ${colorPalette.paragraphColor};
    font-weight: ${FontWeight.regular};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
    margin-left: 10px;
  }
  .trip-pickup-order-text {
    color: ${colorPalette.paragraphColor};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph};
    line-height: 18px;
  }
  .trip-pickup-status-text {
  }
  .trip-picker-btn {
    border-radius: 20px;
    background: ${colorPalette.primaryColor};
    width: 130px;
    color: ${colorPalette.white};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 15px;
    border: none;
    padding: 12px;
    cursor: pointer;
  }
  .trip-pickup-otp-input {
    outline: none;
    border: 1px solid ${colorPalette.paragraphSecondaryolor};
    border-radius: 4px;
    padding: 7px;
    width: 130px;
  }
  .trip-pickup-otp-input:focus {
    border: 1px solid ${colorPalette.paragraphSecondaryolor};
  }
  .trip-pickup-invoice-text {
    color: ${colorPalette.paragraphColor};
    font-weight: ${FontWeight.semibold};
    font-size: ${typography.heading4};
    line-height: 21px;
  }
  .trip-pickup-right-container {
    /* flex: 9.5; */
    width: 98%;
    /* height: 65vh; */
    /* background-color: #f9f9f9; */
    padding: 20px 0px;
    position: sticky;
    /* display: flex; */
    /* overflow-y: scroll; */
  }
  @media only screen and (max-width: 499px) {
    /* .trip-pickup-main-container { */
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    /* height: 100vh; */
    /* } */
    .trip-pickup-sidebar {
      flex: 0;
      height: 0px;
    }
    .trip-pickup-mobile-header {
      width: 100%;
      height: 50px;
    }
    .trip-pickup-right-container {
      width: 100%;
      padding: 5px;
    }
    .trip-pickup-single {
      margin: 10px;
      padding: 10px;
      width: 95%;

      flex-direction: column;
      /* align-items: flex-start; */
    }
    .trip-pickup-date {
      font-size: ${typography.paragraph_secondary};
      line-height: 15px;
    }

    .trip-picker-btn {
      width: 60%;
    }
    .trip-pickup-otp-input {
      width: 60%;
    }
    /* .trip-pickup-single-right {
    justify-content: center;
  } */
  }
  @media only screen and (max-width: 350px) {
    .trip-pickup-single {
      width: 100%;
    }
  }
`;
