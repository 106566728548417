import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Alert, Snackbar } from "@mui/material";
import { argon2id, argon2Verify } from "hash-wasm";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import apiUrl from "../../url/apiUrl";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import AccountCircle from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Button from "@mui/material/Button";
import PasswordIcon from "@mui/icons-material/Password";
import { forgetPassword, getCustomersInfo, LoginPost } from "./LoginServices";
import Input from "@mui/material/Input";
import { CircularProgress } from "@mui/material";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Link, useHistory } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MailIcon from "@mui/icons-material/Mail";
import apiUrl from "../../url/apiUrl";
import logo from "../../assets/logo/leap_supply_logo.svg";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { readToken } from "../../helper/dataDecryptionHelper";
import { Login_Container } from "./login";
import { colorPalette } from "../../themes/theme";
import { ButtonRectangle } from "../../themes/components/button";

const CryptoJS = require("crypto-js");

function ForgetPassword() {
  let history = useHistory();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [registredEmail, setRegistredEmail] = useState("");

  const [successMsg, setSuccessMsg] = useState("");
  const [wrongEmailMsg, setWrongEmailMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [errLogin, setErrLogin] = useState("");

  //   const [errorEmail, setErrorEmail] = useState("");

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const goToCustomer = () => {
    history.push("/customer-login");
  };
  const [state, setState] = React.useState({
    right: false,
  });
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "left",
  });
  const { vertical, horizontal } = snackbarPosition;
  const handleForgetPassword = async () => {
    const isValid = formValidation();
    if (isValid) {
      setLoading(true);
      const response = await forgetPassword(registredEmail);

      if (response.status == 200) {
        setLoading(false);
        setSuccessMsg(`A password has been sent to ${registredEmail}.`);
        setRegistredEmail("");
        setWrongEmailMsg("");
      }
      if (response == 422) {
        setSuccessMsg("");
        setLoading(false);
        setWrongEmailMsg("Please enter correct email id.");
      }
    }
    setOpenSnackbar(true);
    // setLoading(false);
    //
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const [errorEmail, setErrorEmail] = useState("");

  const formValidation = () => {
    const errorEmail = {};
    let isValid = true;
    if (registredEmail == "") {
      errorEmail.emptyemail = "Please Enter registered email id.";
      isValid = false;
    }

    setErrorEmail(errorEmail);

    return isValid;
  };
  const backToLogin = () => {
    //
    if (
      localStorage.getItem("loginApp") ===
      process.env.REACT_APP_CUSTOMER_APP_NAME
    ) {
      history.push("/customer/login");
    } else {
      history.push("/login");
    }
  };
  return (
    <Login_Container className="login_container">
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={4000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        key={vertical + horizontal}
      >
        <Alert severity="success">{successMsg}</Alert>
      </Snackbar>
      <div className="blur">
        <div className="login_left"></div>
        <div className="login_right">
          <div className="login_form-forget-password">
            <div className="logo_container">
              {isMobile ? null : (
                <img src={logo} alt="" className="logo_leap" />
              )}
            </div>
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#016938"
              fill-opacity="1"
              d="M0,256L40,240C80,224,160,192,240,154.7C320,117,400,75,480,90.7C560,107,640,181,720,181.3C800,181,880,107,960,117.3C1040,128,1120,224,1200,245.3C1280,267,1360,213,1400,186.7L1440,160L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"
            ></path>
          </svg> */}
            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#016938"
                fill-opacity="1"
                d="M0,288L80,272C160,256,320,224,480,208C640,192,800,192,960,197.3C1120,203,1280,213,1360,218.7L1440,224L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
              ></path>
            </svg> */}
            {/* 
            <div className="headings_div">
              <h5 className="headings">FAARMS GLOBAL</h5>{" "}
            </div> */}

            {/* <div className="login_box">
                <h5 className="login_text_mobile">
                  Please Enter 10 Digit Mobile Number To Login.
                </h5>{" "}
              </div> */}

            <div className="login_input">
              <div className="headings_login-forget">
                <h5 className="login_text_forget_password">FORGOT PASSWORD</h5>{" "}
              </div>
              <div className="subheading-email">
                <span style={{ fontSize: ".8rem" }}>
                  Please Enter Your Registered Email Id.
                </span>
              </div>
              <TextField
                id="standard-size-normal"
                className="login-input-field"
                placeholder="Enter Email"
                // defaultValue="Enter Mobile Number"
                variant="standard"
                type="text"
                value={registredEmail}
                onChange={(e) => {
                  setRegistredEmail(e.target.value);
                  setErrLogin("");
                  setWrongEmailMsg("");
                }}
                // onInput={(e) => {
                //
                //   if (typeof e.target.value == "number") {
                //
                //     e.target.value = Math.max(0, parseInt(e.target.value))
                //       .toString()
                //       .slice(0, 4);
                //   }
                // }}
              />
              {registredEmail.length > 0 ? null : (
                <>
                  {" "}
                  {Object.keys(errorEmail).map((key) => {
                    return (
                      <h6 style={{ color: "#E06666", margin: "4px" }}>
                        {errorEmail[key]}
                      </h6>
                    );
                  })}
                </>
              )}
            </div>

            {/* <div className="login_input">
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="standard-size-normal"
                  placeholder="Enter OTP received."
                  variant="standard"
                  type="number"
                  className="input_mobile"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 4);
                  }}
                />
              </Box>
            </div> */}
            <div className="error-message">{errLogin ? errLogin : null}</div>
            <div className="error-message">
              {wrongEmailMsg ? wrongEmailMsg : null}
            </div>

            {/* {successMsg ? <span>{successMsg}</span> : null} */}

            <div className="button_container_forget_password">
              <ButtonRectangle
                variant="contained"
                // color="success"
                onClick={handleForgetPassword}
                style={{
                  backgroundColor: colorPalette.primaryColor,
                  borderRadius: "20px",
                  padding: ".5rem 0rem",
                }}
                className="login-input-field"
              >
                {loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  "RESET PASSWORD"
                )}
              </ButtonRectangle>
              <div className="back_to_home_button">
                <KeyboardBackspaceIcon />

                {/* <Link to="/login" onClick={backToLogin}>
                  Back To Login
                </Link> */}
                <Link onClick={backToLogin}>Back To Login</Link>
              </div>
            </div>
            <div
              style={{
                width: "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // margin: "1.5rem auto",
              }}
            >
              {errorMsg ? <h6 style={{ color: "red" }}>{errorMsg}</h6> : null}
            </div>
            {/* <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "1.5rem auto",
              }}
            >
              <span className="cutomer-login-button" onClick={goToCustomer}>
                Login as Customer
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </Login_Container>
  );
}

export default ForgetPassword;
