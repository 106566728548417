import apiUrl from "../../url/apiUrl";
import axios from "axios";
import axiosInterceptor from "../../config/https";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";

export const bankDetailsPost = (
  account_number,
  bank_name,
  holder_name,
  account_type,
  ifsc_code,
  entity_id,
  entity_type,
  is_verified,
  is_primary,
  file
) => {
  let data = new FormData();

  data.append("bank_account_number", account_number);
  data.append("bank_name", bank_name);
  data.append("account_holder_name", holder_name);
  data.append("bank_account_type", account_type);
  data.append("ifsc_code", ifsc_code);
  data.append("entity_id", entity_id);
  data.append("entity_type", entity_type);
  data.append("is_verified", is_verified);
  data.append("is_primary", is_primary);
  if (file) {
    data.append("file", file);
  }

  var config = {
    method: "post",
    url: `${apiUrl.onboardingBaseUrl}/v1/bank-details`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        // if (response.status == 200) {
        resolve(response.status);
        // }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};

export const bankDetailsPut = (
  account_number,
  bank_name,
  holder_name,
  account_type,
  ifsc_code,
  entity_id,
  entity_type,
  is_verified,
  //   is_primary,
  file,
  id
) => {
  let data = new FormData();

  data.append("bank_account_number", account_number);
  data.append("bank_name", bank_name);
  data.append("account_holder_name", holder_name);
  data.append("bank_account_type", account_type);
  data.append("ifsc_code", ifsc_code);
  data.append("entity_id", entity_id);
  data.append("entity_type", entity_type);
  data.append("is_verified", is_verified);
  //   data.append("is_primary", is_primary);
  data.append("file", file);

  var config = {
    method: "put",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v1/bank-details/${prefixSalt()}-${convertToBase64(id)}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        // if (response.status == 200) {
        resolve(response.status);
        // }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};

export const GetBankDetails = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/bank-details`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};
export const GetEntityDetails = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/enums/entity-type`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};
export const GetBankDetailsById = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/bank-details/${prefixSalt()}-${convertToBase64(id)}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
  }
};
export const bankDetailsPatch = (id, is_primary) => {
  let data = JSON.stringify({
    is_primary: is_primary,
  });
  var config = {
    method: "patch",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v1/bank-details/is_primary/${prefixSalt()}-${convertToBase64(id)}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response) {
          resolve(response.data);
        }
      })
      .catch(function (error) {
        resolve(error);
      });
  });
};
