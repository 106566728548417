import axiosInterceptor from "../../../config/https";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import apiUrl from "../../../url/apiUrl";

export const fetchCustomerQuotesList = async (currentTab, currentPage) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.quotationBaseUrl}/${currentTab}?current_page=${currentPage}`
    );
    if (response) {
      if (response.status === 200) {
        return response.data;
      }
    }
  } catch (e) {
    if (e.response.status === 403) {
      // localStorage.clear();
      localStorage.removeItem("CUSTOMER_DATA");
      localStorage.removeItem("x_token");
      localStorage.removeItem("website_url");
      localStorage.removeItem("LOGIN_DATA");
      window.location.reload();
    }
  }
};

export const getIndentDetailsById = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/indent/${prefixSalt()}-${convertToBase64(
        id
      )}`
    );
    if (response.status === 200) {
      return response;
    }
  } catch (err) {}
};

export const quotaionAcceptPatch = async (
  quotation_id,
  quotation_status,
  remarks
) => {
  let data = JSON.stringify({
    quotation_id: quotation_id,
    // modified_by: modified_by,
    quotation_status: quotation_status,
    remarks: remarks,
  });

  var config = {
    method: "patch",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v1/quotations/${prefixSalt()}-${convertToBase64(
      quotation_id
    )}/accepted-denied`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status >= 200 && response.status <= 300) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status == 403) {
          // localStorage.clear();
          localStorage.removeItem("CUSTOMER_DATA");
          localStorage.removeItem("x_token");
          localStorage.removeItem("website_url");
          localStorage.removeItem("LOGIN_DATA");
          window.location.reload();
        }
        resolve(error.response);
        //
      });
  });
};

export const getQuotesAttachementById = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/quotations/${prefixSalt()}-${convertToBase64(id)}`
    );

    if (response.status === 200) {
      return response;
    }
  } catch (err) {}
};

export const getAttachementImageQuotes = async (id) => {
  try {
    const response = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/quotations/${prefixSalt()}-${convertToBase64(id)}`,
      { responseType: "blob" }
    );
    if (response) {
      if (response.status === 200) {
        const imgUrl = URL.createObjectURL(response.data);
        return imgUrl;
      }
    }
  } catch (err) {}
};
