import React from "react";
import apiUrl from "../../url/apiUrl.js";
import axios from "axios";
import axiosInterceptor from "../../config/https.js";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter.js";
import { readToken } from "../../helper/dataDecryptionHelper.js";

export const onboardRegisterPost = (
  name,
  carrier_type,
  mobile_number,
  e_mail,
  pan_number,
  gstin,
  aadhar_number,
  bank_acc,
  bank_name,
  ifsc_code,
  holder_name,
  acc_type,
  pincode,
  state,
  district,
  village,
  landmark,
  aadharFront,
  aadharBack,
  panCard,
  signature,
  cancelCheque,
  moreThan10VehicleAttachment,
  gstRegistrationCertificate,
  nonGstRegistrationCertificate,
  msmeCertificate,
  tdsFile
) => {
  let data = new FormData();

  data.append("name", name);
  data.append("transporter_type", carrier_type);
  data.append("mobile", mobile_number);
  data.append("email", e_mail);
  data.append("pan_number", pan_number);
  data.append("gstin_number", gstin);
  data.append("aadhar_number", aadhar_number);
  data.append("bank_account_number", bank_acc);
  data.append("asset_name", name);
  data.append("bank_name", bank_name);
  data.append("ifsc_code", ifsc_code);
  data.append("account_holder_name", holder_name);
  data.append("bank_account_type", acc_type);
  data.append("village", village);
  data.append("district", district);
  data.append("state", state);
  data.append("postcode", pincode);
  data.append("landmark", landmark);
  if (aadharFront.name) {
    data.append("aadhaar_front_attachment", aadharFront);
  }
  if (aadharBack.name) {
    data.append("aadhaar_back_attachment", aadharBack);
  }
  data.append("pan_attachment", panCard);
  data.append("signature_attachment", signature);
  data.append("cancel_cheque_attachemnt", cancelCheque);

  if (moreThan10VehicleAttachment) {
    data.append("tags", "below_10_vehicles_self_declaration");
    data.append("optional_images", moreThan10VehicleAttachment);
  }

  if (tdsFile?.name) {
    data.append("tds_non_deduction_declaration_attachment", tdsFile);
  }

  if (gstRegistrationCertificate) {
    data.append("tags", "gst_registration_certificate");
    data.append("optional_images", gstRegistrationCertificate);
  }

  if (nonGstRegistrationCertificate) {
    data.append("tags", "non_gst_registration_self_declaration");
    data.append("optional_images", nonGstRegistrationCertificate);
  }

  if (msmeCertificate) {
    data.append("tags", "msme_certificate");
    data.append("optional_images", msmeCertificate);
  }

  // let data = JSON.stringify({
  //   name: `${name}`,
  //   transporter_type: `${carrier_type}`,
  //   mobile: `${mobile_number}`,
  //   email: `${e_mail}`,
  //   pan_number: `${pan_number}`,
  //   gstin_number: `${gstin}`,
  //   aadhar_number: `${aadhar_number}`,
  //   bank_account_number: `${bank_acc}`,
  //   bank_name: `${bank_name}`,
  //   ifsc_code: `${ifsc_code}`,
  //   account_holder_name: `${holder_name}`,
  //   bank_account_type: `${acc_type}`,
  //   address: {
  //     village: `${village}`,
  //     district: `${district}`,
  //     state: `${state}`,
  //     postcode: `${pincode}`,
  //     landmark: `${landmark}`,
  //   },
  // });
  var config = {
    method: "post",
    url: `${apiUrl.onboardingBaseUrl}/v4/transporter`,
    headers: {
      "Content-Type": "application/json",
      "x-app-token": readToken(),
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }

        resolve(error);
      });
  });
};

export const onboardRegisterPut = (
  transporterCode,
  name,
  carrier_type,
  mobile_number,
  e_mail,
  pan_number,
  gstin,
  aadhar_number,
  bank_acc,
  bank_name,
  ifsc_code,
  holder_name,
  acc_type,
  pincode,
  state,
  district,
  village,
  landmark,
  aadharFront,
  aadharBack,
  panCard,
  signature,
  cancelCheque,
  moreThan10VehicleAttachment,
  gstRegistrationCertificate,
  nonGstRegistrationCertificate,
  msmeCertificate,
  carrierId,
  tdsFile
) => {
  // let data = JSON.stringify({
  //   name: `${name}`,
  //   transporter_type: `${carrier_type}`,
  //   mobile: `${mobile_number}`,
  //   email: `${e_mail}`,
  //   gstin_number: `${gstin}`,
  //   bank_account_number: `${bank_acc}`,
  //   bank_name: `${bank_name}`,
  //   ifsc_code: `${ifsc_code}`,
  //   account_holder_name: `${holder_name}`,
  //   bank_account_type: `${acc_type}`,
  //   is_active: is_active,
  // });
  let data = new FormData();

  data.append("name", name);
  data.append("transporter_code", transporterCode);
  data.append("transporter_type", carrier_type);
  data.append("mobile", mobile_number);
  data.append("email", e_mail);
  data.append("pan_number", pan_number);
  data.append("gstin_number", gstin);
  data.append("aadhar_number", aadhar_number);
  data.append("bank_account_number", bank_acc);
  data.append("asset_name", name);
  data.append("bank_name", bank_name);
  data.append("ifsc_code", ifsc_code);
  data.append("account_holder_name", holder_name);
  data.append("bank_account_type", acc_type);
  data.append("village", village);
  data.append("district", district);
  data.append("state", state);
  data.append("postcode", pincode);
  data.append("landmark", landmark);
  if (aadharFront.name) {
    data.append("optional_images", aadharFront);
    data.append("tags", "aadhaar_front");
  }
  if (aadharBack.name) {
    data.append("optional_images", aadharBack);
    data.append("tags", "aadhaar_back");
    // data.append("adhar_back_attachment", aadharBack);
  }
  if (panCard?.name) {
    data.append("optional_images", panCard);
    data.append("tags", "pan");
  }
  if (signature?.name) {
    data.append("optional_images", signature);
    data.append("tags", "signature");
  }
  if (cancelCheque?.name) {
    data.append("optional_images", cancelCheque);
    data.append("tags", "cancel_cheque");
  }

  if (tdsFile?.name) {
    data.append("tds_non_deduction_declaration_attachment", tdsFile);
  }

  // data.append("pan_attachment", panCard);
  // data.append("signature_attachment", signature);
  // data.append("cancel_cheque_attachemnt", cancelCheque);

  if (moreThan10VehicleAttachment?.name) {
    data.append("optional_images", moreThan10VehicleAttachment);
    data.append("tags", "below_10_vehicles_self_declaration");
  }

  if (gstRegistrationCertificate?.name) {
    data.append("optional_images", gstRegistrationCertificate);
    data.append("tags", "gst_registration_certificate");
  }

  if (nonGstRegistrationCertificate?.name) {
    data.append("optional_images", nonGstRegistrationCertificate);
    data.append("tags", "non_gst_registration_self_declaration");
  }

  if (msmeCertificate?.name) {
    data.append("optional_images", msmeCertificate);
    data.append("tags", "msme_certificate");
  }

  var config = {
    method: "put",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v4/transporter/${prefixSalt()}-${convertToBase64(carrierId)}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error);
      });
  });
};

export const GetOnboardList = async (carrier_type, name, pincode, district) => {
  try {
    const onboardListResponse = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}?transporter_type=${carrier_type}?name=${name}?is_active=true?pincode=${pincode}?district=${district}`
    );

    if (onboardListResponse.status == 200) {
      return onboardListResponse.response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const getCarrierList = async (page) => {
  try {
    const carrierList = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v4/transporter?current_page=${page}`
    );

    if (carrierList.status == 200) {
      return carrierList.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const getCarrierListById = async (id) => {
  try {
    const carrierListById = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v4/transporter/${prefixSalt()}-${convertToBase64(id)}`
    );

    if (carrierListById.status == 200) {
      return carrierListById.data;
    }
  } catch (e) {}
};

export const handleTransporterStatusRecommended = async (id, remarks) => {
  let data = JSON.stringify({
    status: `recommended`,
    remarks: `${remarks}`,
  });
  var config = {
    method: "PATCH",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v3/transporter/${prefixSalt()}-${convertToBase64(id)}/status-recommended`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error);
      });
  });
};

export const handleTransporterStatusApproved = async (id, remarks) => {
  let data = JSON.stringify({
    status: `approved`,
    remarks: `${remarks}`,
  });
  var config = {
    method: "PATCH",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v3/transporter/${prefixSalt()}-${convertToBase64(id)}/status-approved`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error);
      });
  });
};

export const handleTransporterStatusReject = async (id, remarks) => {
  let data = JSON.stringify({
    status: `rejected`,
    remarks: `${remarks}`,
  });
  var config = {
    method: "PATCH",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v3/transporter/${prefixSalt()}-${convertToBase64(id)}/status-rejected`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error);
      });
  });
};

export const handleTransporterIsActive = async (id, remarks) => {
  let data = JSON.stringify({
    is_active: false,
    remarks: `${remarks}`,
  });
  var config = {
    method: "PATCH",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v3/transporter/is-active/${prefixSalt()}-${convertToBase64(id)}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error);
      });
  });
};
