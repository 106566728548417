import styled from "styled-components";
import {
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";

export const IndentSidebarStyle = styled.div`
  flex: 1.7;
  width: 100%;
  height: 100vh;

  @media only screen and (max-width: 480px) {
    flex: 1.7;
    width: 100%;
    height: 100vh;
  }
`;

export const Indent_right_container = styled.div`
  /* .indent-right-container { */
  flex: 9.5;
  width: 80%;
  height: 100vh;

  background-color: ${(props) =>
    props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
  /* padding: 20px 0px; */
  /* position: sticky; */
  overflow: scroll;
  display: flex;
  flex-direction: column;
  /* } */

  .input-div {
    /* border-color: ${colorPalette.primaryColor}; */
    border-color: white;
  }
  .indent-head-text {
    font-weight: ${FontWeight.bold};
    font-size: 32px;
    line-height: 48px;
    color: ${colorPalette.primaryColor};
  }
  .indent-head-container {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
  }
  .indent-head-row {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
    display: flex;
    justify-content: space-between;
    padding: 1% 3%;
  }
  .indent-search-row {
    padding: 10px;
  }
  .indent-single-item-container {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
    padding: 12px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 10px;
  }
  .indent-customer-namee {
    margin-left: 6px;
    max-width: 140px;
    /* width: ; */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .indent-number-text {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.heading5};
    line-height: 21px;
  }

  .indent-customer-text {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.paragraphSecondaryolor};
    /* color: ${colorPalette.paragraphSecondaryolor}; */

    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph};
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 3px 0px;
  }
  .indent-grid-main-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px 30px;
    /* width: 90%; */
    /* justify-content: center; */
  }
  .indent-list-container {
    padding: 25px;
  }
  .indent-date-text {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
  }
  .indent-slim-text {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
  }
  .indent-bold-text {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 15px;
  }
  .indent-single-top-row {
    display: flex;
    justify-content: space-between;
    margin: 3px 0px;
  }
  .indent-add-quote-btn {
    padding: 8px 16px;
    border: none;
    background: ${colorPalette.primaryColor};
    margin-left: 10px;
    border-radius: 50px;
    color: ${colorPalette.white};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
    cursor: pointer;
    width: 110px;
    /* height: 30px; */
  }
  .indent-save-quote-btn {
    padding: 8px 16px;
    border: none;
    background: ${colorPalette.primaryColor};
    margin-left: 10px;
    border-radius: 50px;
    color: ${colorPalette.white};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
    cursor: pointer;
    width: 110px;
    height: 40px;
  }
  .indent-tab-menuOption-contain {
    display: flex;
    margin: 10px 15px;
  }
  .indent-search-input {
    border: none;
    width: 94%;
    outline: none;
  }
  .indent-search-container {
    background: ${colorPalette.white};
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    width: 70%;
  }
  .indent-tab-menu-option {
    margin: 0px 2%;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading4};
    line-height: 27px;
    cursor: pointer;
  }
  .indent-tab-bottom-rectangle {
    background: ${colorPalette.primaryColor};
    border-radius: 5px 5px 0px 0px;
    height: 4px;
    width: 100%;
  }
  .indent-active-tab {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.semibold};
    border: 2px solid ${colorPalette.primaryColor};
    border-radius: 5px 5px 0px 0px;
  }
  .indent-single-middle-row {
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
  }
  .indent-single-bottom-row {
    display: grid;
    grid-template-columns: auto 220px;
    justify-content: space-between;
    margin-top: 10px;
  }
  .indent-buttons {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .indent-reject-btn {
    border: 1px solid ${colorPalette.primaryRed};
    background: ${colorPalette.white};
    color: ${colorPalette.primaryRed};
  }
  .single-stop {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 18px;
  }

  .indent-single-stop-text {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 18px;
  }

  .loading_more_data {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${colorPalette.white};
    padding: 10px;
    font-size: ${typography.heading4};
    font-weight: ${FontWeight.semibold};
    color: ${colorPalette.primaryColor};
    width: 100%;
    text-align: center;
  }
  @media only screen and (max-width: 480px) {
    .input-div {
      width: 100%;
    }
    .indent-right-container {
      flex: 9.5;
      width: 100vw;
      height: 100vh;
      background-color: ${colorPalette.secondaryWhite};
      /* padding: 20px 0px; */
      /* position: sticky; */
      overflow: scroll;
      display: flex;
      flex-direction: column;
    }
    .indent-head-text {
      font-weight: ${FontWeight.bold};
      font-size: 32px;
      line-height: 48px;
      color: ${colorPalette.primaryColor};
    }
    .indent-head-container {
      background: ${colorPalette.white};
    }
    .indent-head-row {
      background: ${colorPalette.white};
      display: flex;
      justify-content: space-between;

      padding: 0% 0%;
    }
    .indent-search-row {
      padding: 10px;
    }
    .indent-single-item-container {
      background: ${colorPalette.white};
      padding: 12px;
      box-shadow: ${colorPalette.boxShadowPrimary};
    }
    .indent-customer-namee {
      margin-left: 6px;
      max-width: 140px;
      /* width: ; */
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .indent-number-text {
      color: ${colorPalette.paragraphSecondaryolor};
      line-height: 21px;
    }

    .indent-customer-text {
      color: ${colorPalette.paragraphSecondaryolor};
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 3px 0px;
    }
    .indent-grid-main-container {
      display: grid;
      grid-template-columns: auto;
      gap: 30px 30px;
      /* height: 100vh; */
      /* width: 90%; */
      /* justify-content: center; */
    }
    .indent-list-container {
      padding: 25px;
    }
    .indent-date-text {
      color: ${colorPalette.paragraphSecondaryolor};

      line-height: 15px;
    }
    .indent-slim-text {
      color: ${colorPalette.paragraphSecondaryolor};
      line-height: 15px;
    }
    .indent-bold-text {
      color: ${colorPalette.paragraphColor};
      line-height: 15px;
    }
    .indent-single-top-row {
      display: flex;
      justify-content: space-between;
      margin: 3px 0px;
    }
    .indent-add-quote-btn {
      padding: 8px 16px;
      border: none;
      background: ${colorPalette.primaryColor};
      margin-left: 10px;
      border-radius: 50px;
      color: ${colorPalette.white};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph_secondary};
      line-height: 15px;
      cursor: pointer;
      width: 110px;
      /* height: 30px; */
    }
    .indent-save-quote-btn {
      padding: 8px 16px;
      border: none;
      background: ${colorPalette.primaryColor};
      margin-left: 10px;
      border-radius: 50px;
      color: ${colorPalette.white};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph_secondary};
      line-height: 15px;
      cursor: pointer;
      width: 110px;
      height: 40px;
    }
    .indent-tab-menuOption-contain {
      display: flex;
      margin: 10px 15px;
    }
    .indent-search-input {
      border: none;
      width: 94%;
      outline: none;
    }
    .indent-search-container {
      background: ${colorPalette.white};
      padding: 15px;
      display: flex;
      justify-content: flex-start;
      width: 70%;
    }
    .indent-tab-menu-option {
      margin: 0px 2%;
      margin-bottom: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: ${colorPalette.paragraphSecondaryolor};

      line-height: 27px;
      cursor: pointer;
    }
    .indent-tab-bottom-rectangle {
      background: ${colorPalette.primaryColor};
      border-radius: 5px 5px 0px 0px;
      height: 4px;
      width: 100%;
    }
    .indent-active-tab {
      color: ${colorPalette.primaryColor};
      font-weight: ${FontWeight.semibold};
      border: 2px solid ${colorPalette.primaryColor};
      border-radius: 5px 5px 0px 0px;
    }
    .indent-single-middle-row {
      display: flex;
      justify-content: space-between;
      margin-top: 7px;
    }
    .indent-single-bottom-row {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    .indent-reject-btn {
      border: 1px solid ${colorPalette.primaryRed};
      background: ${colorPalette.white}fff;
      border-radius: 50px;
      color: ${colorPalette.primaryRed};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph_secondary};
      line-height: 15px;
      padding: 8px 16px;
      cursor: pointer;
    }

    .single-stop {
      color: ${colorPalette.primaryColor};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph};
      line-height: 18px;
    }
    .indent-single-stop-text {
      color: ${colorPalette.primaryColor};
      line-height: 18px;
    }

    .loading_more_data {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: white;
      padding: 10px;
      font-size: ${typography.heading4};
      font-weight: ${FontWeight.semibold};
      color: ${colorPalette.primaryColor};
      width: 100%;
      text-align: center;
    }
  }
`;

export const Indent_drawer_container = styled.div`
  padding: 20px;
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  color: ${colorPalette.paragraphSecondaryolor};

  /* .input-div input {
    border-color: ${colorPalette.primaryColor};
  } */

  .indent-input-text {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .indent-remarks-text {
    font-size: ${typography.heading3};
    font-weight: ${FontWeight.semibold};
  }
  .indent-remarks-inputfield-conatiner {
    width: 100%;
    height: 200px;
    margin-top: 20px;
  }
  .indent-input-container {
    margin: 10px 0px;
  }
  .indent-inputfield {
    width: 93%;
    color: ${colorPalette.paragraphSecondaryolor};
    background-color: ${colorPalette.white};
    border-color: ${colorPalette.primaryColor};
  }

  input,
  textarea {
    color: ${colorPalette.paragraphSecondaryolor};
    background-color: ${colorPalette.white};
  }

  .indent-drawer-head-text {
    font-size: 22px;
    font-weight: ${FontWeight.semibold};
    color: ${colorPalette.primaryColor};
    margin-bottom: 10%;
  }

  .indent-remarks-textarea {
    width: 93%;
    height: 100%;
    /* resize: none; */
    padding: 20px;
    border: 1px solid ${colorPalette.secondaryWhite};
  }
  .indent-remarks-textarea:focus {
    border-color: #999;
  }
  .indent-remarks-submit-btn-cover {
    width: 100%;
    padding: 18px 0px;
    display: flex;
    justify-content: flex-end;
  }
  .indent-remarks-submit-btn {
    /* width: 20%; */
    background: ${colorPalette.white};
    border: 1px solid ${colorPalette.primaryRed};
    border-radius: 50px;
    color: ${colorPalette.primaryRed};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 18px;
    padding: 8px 23px;
    cursor: pointer;
  }
`;

export const Indent_drawer_container_style = styled.div`
  /* .indent-drawer-container { */
  display: flex;
  align-items: flex-start;
  /* justify-content: center; */
  flex-direction: column;
  padding: 0px 20px;
  width: 100%;
  height: 100vh;
  color: ${colorPalette.paragraphColor};
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  /* } */
  .indent-input-text {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .indent-remarks-text {
    font-size: ${typography.heading3};
    font-weight: ${FontWeight.semibold};
    padding: 10px 5px;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .indent-remarks-inputfield-conatiner {
    width: 100%;
    height: 200px;
    margin-top: 20px;
  }
  .indent-input-container {
    margin: 10px 0px;
    width: 100%;
  }
  .indent-inputfield {
    width: 100%;
  }
  .indent-drawer-head-text {
    font-size: 22px;
    font-weight: ${FontWeight.semibold};
    color: ${colorPalette.primaryColor};
    width: 100%;
    /* background-color: ${colorPalette.primaryColor}; */
    padding: 10px 0px;
    margin-bottom: 10%;
  }

  .indent-remarks-textarea {
    width: 93%;
    height: 100%;
    /* resize: none; */
    padding: 20px;
    border: 1px solid ${colorPalette.secondaryColor};
    background-color: ${colorPalette.secondaryWhite};
    color: ${colorPalette.paragraphColor};
  }
  .indent-remarks-textarea:focus {
    border-color: ${colorPalette.paragraphSecondaryolor};
  }
  .indent-remarks-submit-btn-cover {
    width: 100%;
    padding: 18px 0px;
    display: flex;
    justify-content: flex-end;
  }
  .indent-remarks-submit-btn {
    /* width: 20%; */
    background: ${colorPalette.white};
    border: 1px solid ${colorPalette.primaryRed};
    border-radius: 50px;
    color: ${colorPalette.primaryRed};
    font-weight: ${FontWeight.bold};
    margin-right: 20px;
    font-size: ${typography.paragraph};
    line-height: 18px;
    padding: 8px 23px;
    cursor: pointer;
  }
`;
