import styled from "styled-components";
import {
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";

export const Quotation_Page_Style = styled.div`
  .quotes-wrapper {
    width: 100%;
    display: flex;
  }

  .quote-sidebar {
    flex: 1.7;
    width: 100%;
    height: 100vh;
  }

  .quote-right-container {
    flex: 9.5;
    width: 80%;
    height: 100vh;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};

    /* padding: 20px 0px; */
    /* position: sticky; */
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }
  .quotation-remarks-reopen {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2rem 0rem;
    font-weight: ${FontWeight.bold};
  }
  .quotation-remarks-reopen-button-div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem 0rem;
  }
  .indent-selected {
    border: 2px solid ${colorPalette.primaryColor};
  }
  .quotation-remarks-reopen-button {
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    padding: 0.5rem 1rem;
    outline: none;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .quotation-remarks-reopen-textarea {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2rem 0rem;
  }
  /* .mini-details-tab-left-timeline {
 width: 100%;
} */
  .quote-head-text {
    font-weight: ${FontWeight.bold};
    font-size: 32px;
    line-height: 48px;
    color: ${colorPalette.primaryColor};
  }
  .quote-head-container {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
  }
  .quote-head-row {
    background: ${colorPalette.white};
    display: flex;
    justify-content: space-between;
    padding: 1% 3%;
  }
  .quote-search-row {
    padding: 10px;
  }
  .quote-customer-namee {
    margin-left: 6px;
    max-width: 140px;
    /* width: ; */
    text-overflow: ellipsis;
    ${colorPalette.white}-space: nowrap;
    overflow: hidden;
  }
  .quote-single-item-container {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

    padding: 12px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    cursor: pointer;
    border-radius: 10px;
  }
  .quote-number-text {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.heading5};
    line-height: 21px;
  }

  .quote-customer-text {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.black};
    /* color: ${colorPalette.paragraphSecondaryolor}; */
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph};
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 3px 0px;
  }
  .quote-remarks-display {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.black};
    font-size: ${typography.paragraph_secondary};
    font-weight: ${FontWeight.regular};
    line-height: 15px;
    margin-top: 8px;
  }
  .quote-grid-main-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px 30px;
  }
  .quote-list-container {
    padding: 25px;
  }
  .quote-date-text {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.black};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
  }
  .quote-slim-text {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.black};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
  }
  .quote-single-middle-in-created-by {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  .quote-bold-text {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.paragraphSecondaryolor
        : colorPalette.black};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 15px;
  }
  .remarks {
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
    text-overflow: ellipsis;
    font-size: ${typography.paragraph_secondary};
  }
  .quote-single-top-row {
    display: flex;
    justify-content: space-between;
  }
  .quote-add-quote-btn {
    padding: 8px 16px;
    border: none;
    background: ${colorPalette.primaryColor};
    margin-right: 10px;
    border-radius: 50px;
    color: ${colorPalette.white};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
    cursor: pointer;
    width: 80px;
  }
  .quote-add-history-btn {
    padding: 8px 16px;
    border: 1px solid ${colorPalette.primaryColor};
    /* background: ${colorPalette.primaryColor}; */
    margin-right: 10px;
    border-radius: 50px;
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
    cursor: pointer;
    width: 100%;
  }
  .quote-save-quote-btn {
    padding: 8px 16px;
    border: none;
    background: ${colorPalette.primaryColor};
    margin-left: 10px;
    border-radius: 50px;
    color: ${colorPalette.white};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph_secondary};
    line-height: 15px;
    cursor: pointer;
    width: 110px;
    height: 40px;
  }
  .quote-tab-menuOption-contain {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    padding: 0.4rem 0rem;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};

    margin: 10px 15px;
  }
  .quote-tab-menuOption-contain::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  .quote-search-input {
    border: none;
    width: 94%;
    outline: none;
  }
  .quote-search-container {
    background: ${colorPalette.white};
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    width: 70%;
  }
  .quote-tab-menu-option {
    margin: 0px 2%;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading4};
    line-height: 27px;
    cursor: pointer;
  }
  .quote-tab-bottom-rectangle {
    background: ${colorPalette.primaryColor};
    border-radius: 5px 5px 0px 0px;
    height: 4px;
    width: 100%;
  }
  .quote-active-tab {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.semibold};
    border: 2px solid ${colorPalette.primaryColor};
    border-radius: 5px 5px 0px 0px;
  }
  .quote-single-middle-row {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem 0rem;
  }
  .quote-single-bottom-row {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .button-draft {
    display: flex;
    align-items: center;
  }
  .quote-reject-btn {
    border: 1px solid ${colorPalette.primaryRed};
    background: ${colorPalette.white};
    color: ${colorPalette.primaryRed};
  }
  .single-stop {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 18px;
  }
  .quote-single-stop-text {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 18px;
  }
  .quote-single-last-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0.3rem 0rem;
    gap: 95px;
  }
  .indent-head-text {
    font-weight: ${FontWeight.bold};
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};

    font-size: 32px;
    line-height: 48px;
    color: ${colorPalette.primaryColor};
  }
  @media only screen and (max-width: 480px) {
    .quote-sidebar {
      flex: 1.7;
      width: 100%;
      height: 100vh;
    }
    .quote-single-last-row {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      padding: 0.3rem 0rem;
      gap: 70px;
    }
    .quote-right-container {
      flex: 9.5;
      /* width: 100%; */
      height: 100vh;
      background-color: ${colorPalette.secondaryWhite};
      /* padding: 20px 0px; */
      /* position: sticky; */
      overflow: scroll;
      display: flex;
      flex-direction: column;
    }
    .quote-head-text {
      font-weight: ${FontWeight.bold};
      font-size: 32px;
      line-height: 48px;
      color: ${colorPalette.primaryColor};
    }
    .quote-head-container {
      background: ${colorPalette.white};
    }
    .quote-head-row {
      background: ${colorPalette.white};
      display: flex;
      justify-content: space-between;
      padding: 1% 3%;
    }
    .quote-search-row {
      padding: 10px;
    }
    .quote-customer-namee {
      margin-left: 6px;
      max-width: 140px;
      /* width: ; */
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .quote-single-item-container {
      background: ${colorPalette.white};
      padding: 12px;
      box-shadow: ${colorPalette.boxShadowPrimary};
    }
    .quote-number-text {
      color: ${colorPalette.paragraphSecondaryolor};
      line-height: 21px;
    }

    .quote-customer-text {
      color: ${colorPalette.paragraphSecondaryolor};
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 3px 0px;
    }
    .quote-remarks-display {
      color: ${colorPalette.paragraphSecondaryolor};
      font-size: ${typography.paragraph_secondary};
      font-weight: ${FontWeight.regular};
      line-height: 15px;
      margin-top: 8px;
    }
    .quote-grid-main-container {
      display: grid;
      grid-template-columns: auto;
      gap: 30px 30px;
    }
    .quote-list-container {
      padding: 25px;
    }
    .quote-date-text {
      color: ${colorPalette.paragraphSecondaryolor};

      line-height: 15px;
    }
    .quote-slim-text {
      color: ${colorPalette.paragraphSecondaryolor};
      line-height: 15px;
    }
    .quote-bold-text {
      color: ${colorPalette.paragraphSecondaryolor};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph};
      line-height: 15px;
    }
    .quote-single-top-row {
      display: flex;
      justify-content: space-between;
    }
    .button-draft {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .quote-add-quote-btn {
      padding: 8px 16px;
      border: none;
      background: ${colorPalette.primaryColor};
      margin-right: 10px;
      border-radius: 50px;
      color: ${colorPalette.white};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph_secondary};
      line-height: 15px;
      cursor: pointer;
      width: 80px;
    }
    .quote-save-quote-btn {
      padding: 8px 16px;
      border: none;
      background: ${colorPalette.primaryColor};
      margin-left: 10px;
      border-radius: 50px;
      color: ${colorPalette.white};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph_secondary};
      line-height: 15px;
      cursor: pointer;
      width: 110px;
      height: 40px;
    }
    .quote-tab-menuOption-contain {
      display: flex;
      width: 100%;
      margin: 10px 0px;
    }
    .quote-search-input {
      border: none;
      width: 94%;
      outline: none;
    }
    .quote-search-container {
      background: ${colorPalette.white};
      padding: 15px;
      display: flex;
      justify-content: flex-start;
      width: 70%;
    }
    .quote-tab-menu-option {
      margin: 0px 2%;
      margin-bottom: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: ${colorPalette.paragraphSecondaryolor};
      line-height: 27px;
      cursor: pointer;
    }
    .quote-tab-bottom-rectangle {
      background: ${colorPalette.primaryColor};
      border-radius: 5px 5px 0px 0px;
      height: 4px;
      width: 100%;
    }
    .quote-active-tab {
      color: ${colorPalette.primaryColor};
      font-weight: ${FontWeight.semibold};
      border: 2px solid ${colorPalette.primaryColor};
      border-radius: 5px 5px 0px 0px;
    }

    .quote-single-bottom-row {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    .quote-reject-btn {
      border: 1px solid ${colorPalette.primaryRed};
      background: ${colorPalette.white};
      border-radius: 50px;
      color: ${colorPalette.primaryRed};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph_secondary};
      line-height: 15px;
      padding: 8px 16px;
      cursor: pointer;
    }
    .single-stop {
      color: ${colorPalette.primaryColor};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph};
      line-height: 18px;
    }
    .quote-single-stop-text {
      color: ${colorPalette.primaryColor};
      line-height: 18px;
    }
  }
`;

export const QuotationDrawer = styled.div`
  /* .quote-drawer-container { */
  padding: 20px;
  /* } */
  .quote-input-text {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .quote-remarks-text {
    font-size: ${typography.heading3};
    font-weight: ${FontWeight.semibold};
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .quote-remarks-inputfield-conatiner {
    width: 100%;
    height: 200px;
    margin-top: 20px;
  }
  .quote-input-container {
    margin: 10px 0px;
  }
  .quote-inputfield {
    width: 93%;
  }
  .quote-drawer-head-text {
    font-size: 22px;
    font-weight: ${FontWeight.semibold};
    color: ${colorPalette.primaryColor};
    margin-bottom: 10%;
  }

  .quote-remarks-reject-btn {
    color: ${colorPalette.primaryRed};
    border-color: ${colorPalette.primaryRed};

    :hover {
      background-color: transparent;
    }
  }

  .quote-remarks-textarea {
    width: 93%;
    height: 100%;
    /* resize: none; */
    padding: 20px;
    border: 1px solid ${colorPalette.secondaryWhite};
  }
  .quote-remarks-textarea:focus {
    border-color: ${colorPalette.paragraphColor};
  }
  .quote-remarks-submit-btn-cover {
    width: 100%;
    padding: 18px 0px;
    display: flex;
    justify-content: flex-end;
  }
  .quote-remarks-submit-btn {
    /* width: 20%; */
    background: ${colorPalette.white};
    border: 1px solid ${colorPalette.primaryRed};
    border-radius: 50px;
    color: ${colorPalette.primaryRed};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 18px;
    padding: 8px 23px;
    cursor: pointer;
  }

  @media only screen and (max-width: 480px) {
    /* .quote-drawer-container { */
    padding: 20px;
    /* } */
    .quote-input-text {
      font-size: ${typography.heading5};
      font-weight: ${FontWeight.medium};
    }
    .quote-remarks-text {
      font-size: ${typography.heading3};
      font-weight: ${FontWeight.semibold};
    }
    .quote-remarks-inputfield-conatiner {
      width: 100%;
      height: 200px;
      margin-top: 20px;
    }
    .quote-input-container {
      margin: 10px 0px;
    }
    .quote-inputfield {
      width: 93%;
    }
    .quote-drawer-head-text {
      font-size: 22px;
      font-weight: ${FontWeight.semibold};
      color: ${colorPalette.primaryColor};
      margin-bottom: 10%;
    }

    .quote-remarks-textarea {
      width: 93%;
      height: 100%;
      /* resize: none; */
      padding: 20px;
      border: 1px solid ${colorPalette.secondaryWhite};
    }
    .quote-remarks-textarea:focus {
      border-color: ${colorPalette.primaryRed};
    }
    .quote-remarks-submit-btn-cover {
      width: 100%;
      padding: 18px 0px;
      display: flex;
      justify-content: flex-end;
    }
    .quote-remarks-submit-btn {
      /* width: 20%; */
      background: ${colorPalette.white};
      border: 1px solid ${colorPalette.primaryRed};
      border-radius: 50px;
      color: ${colorPalette.primaryRed};
      font-weight: ${FontWeight.bold};
      font-size: ${typography.paragraph};
      line-height: 18px;
      padding: 8px 23px;
      cursor: pointer;
    }
  }
`;

export const QuotationHistory = styled.div`
  .mini-details-tab-container-history-quoations {
    height: 85vh;
    overflow: scroll;
    display: flex;
    margin-top: 0.5rem;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
    /* align-items: center;
  justify-content: center; */
  }
  .history-conatiner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0rem;
    font-size: ${typography.heading2};
  }
`;
