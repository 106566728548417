import React, { useEffect, useState } from "react";
import { MultiTripInvoiceStyle } from "./multiTripStyle";
import { Select, Table } from "antd";
import { GetTripListWithFilter } from "../../pages/ftl_trips/ftl_tripListV2/tripListServices";
import { useDebounce } from "../../hooks/useDebounce";
import { GetCustomerSearchList } from "../../pages/ftl_trips/createTrip/createTripServices";
import { Input } from "antd";
import {
  ButtonRectangle,
  ButtonRectangleSecondary,
} from "../../themes/components/button";
import { generateInvoiceForMultiTripForSameBillToParyId } from "./multiTripInvoiceServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SearchOutlined } from "@ant-design/icons";
import { CircularProgress, Menu, MenuItem } from "@mui/material";
import { Modal as ModalAntd } from "antd";
import { colorPalette } from "../../themes/theme";

const MultiTripInvoice = ({
  tripListData,
  selectedTrip,
  setSelectedTrip,
  selectedTripStatusText,
  handleTripDetails,
  handleInvoiceClick,
  anchorEl,
  openInvoice,
  handleInvoiceClose,
  handleOpenInvoiceGeneration,
  handleAutomaticInvoiceGeneration,
  invoiceAutomaticGenLoading,
  customerListFromProps,
  handleFilterSearch,
}) => {
  console.log(customerListFromProps, "customerListFromProps");

  const [tripListFormattedData, setTripListFormattedData] = useState([]);
  const [selectedRowKeysState, setSelectedRowKeysState] = useState("");
  const [tripSearchTerm, setTripSearchTerm] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [customerSearchTerm, setCustomerSearchTerm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTripListLoading, setIsTripListLoading] = useState(false);
  const [formattedCustomerList, setFormattedCustomerList] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [isCheckBoxVisible, setIsCheckBoxVisible] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [
    selectedTripForInvoiceGeneration,
    setSelectedTripForInvoiceGeneration,
  ] = useState([]);
  console.log(
    selectedTripForInvoiceGeneration,
    "selectedTripForInvoiceGeneration"
  );
  const debouncedSearchTerm = useDebounce(tripSearchTerm || "");
  const debouncedCustomerSearchTerm = useDebounce(customerSearchTerm || "");
  const columns = [
    {
      title: "Trip Code",
      dataIndex: "trip_code",
      render: (text, item) => (
        <div
          style={{
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            setSelectedTrip(item);
            handleTripDetails("Trip Details");
          }}
        >
          <a>{text}</a>
        </div>
      ),
    },
    {
      title: "Bill To party",
      dataIndex: "bill_to_party_name",
    },
    {
      title: "Vehicle",
      dataIndex: "vehicle_number",
      width: "140px",
    },
    {
      title: "Transporter",
      dataIndex: "transporter_name",
    },

    {
      title: "Billed Amount",
      dataIndex: "customer_billed_amt",
    },

    {
      title: "",
      dataIndex: "actions",
      width: "150px",
      render: (text, item) => (
        <div
          style={
            isCheckBoxVisible && selectedTrip?.id === item.id
              ? { display: "flex" }
              : {}
          }
          className="actions"
        >
          <ButtonRectangleSecondary
            id="basic-button"
            onClick={(e) => {
              setSelectedTrip(item);
              handleInvoiceClick(e);
            }}
            style={{ paddingLeft: "10px", paddingRight: "10px" }}
          >
            Generate Invoice
          </ButtonRectangleSecondary>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openInvoice}
            onClose={handleInvoiceClose}
            style={{ boxShadow: "none" }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleOpenInvoiceGeneration}>Manual </MenuItem>
            <MenuItem
              onClick={handleAutomaticInvoiceGeneration}
              style={{ justifyContent: "center" }}
            >
              {invoiceAutomaticGenLoading ? (
                <>
                  <CircularProgress size={20} />
                </>
              ) : (
                "Automatic"
              )}
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  useEffect(() => {
    try {
      const formattedData = tripListData.map((item) => ({
        ...item,
        key: item.trip_code,
        bill_to_party_name: item.bill_to_party_details.name,
        customer_billed_amt:
          item.trip_customer_invoice[0].customer_billed_amount,
      }));
      setTripListFormattedData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [tripListData]);

  const getFilterTripList = async (page, searchValue, customerId) => {
    setIsTripListLoading(true);
    try {
      const response = await GetTripListWithFilter(
        page,
        "", // tripCode
        "", // paymentStatus
        "", // vehicleNumber
        selectedTripStatusText === "" ? "" : selectedTrip?.trip_status, // tripStatus
        "", // referenceNumber
        "", // customerId
        "", // loadSource
        "", // ewayBillNo
        "", // tripType
        searchValue, // tripSearchValue,
        customerId //billToParyId
      );
      if (response) {
        setTripListFormattedData(
          response.trip_list.map((item) => ({
            ...item,
            key: item.trip_code,
            bill_to_party_name: item.bill_to_party_details.name,
            customer_billed_amt:
              item.trip_customer_invoice[0].customer_billed_amount,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsTripListLoading(false);
    }
  };

  useEffect(() => {
    if (tripSearchTerm === null && selectedCustomerId === null) return;
    getFilterTripList(1, debouncedSearchTerm, selectedCustomerId);
  }, [debouncedSearchTerm, selectedCustomerId]);

  const handleSearchCustomer = async (searchValue) => {
    if (customerSearchTerm === null) {
      setCustomerList(customerListFromProps);
    } else {
      const response = await GetCustomerSearchList(searchValue);
      if (response) {
        setCustomerList(response?.customers_list);
      }
    }
  };

  useEffect(() => {
    handleSearchCustomer(debouncedCustomerSearchTerm);
  }, [debouncedCustomerSearchTerm]);

  useEffect(() => {
    if (
      selectedTripStatusText === "documents_verified" ||
      selectedTripStatusText === "hardcopy_pod_received" ||
      selectedTrip?.zoho_customer_invoice_status === "invoice_rejected"
    ) {
      setIsCheckBoxVisible(true);
    } else {
      setIsCheckBoxVisible(false);
    }
  }, [selectedTripStatusText]);

  const generateInvoice = async () => {
    try {
      const response = await generateInvoiceForMultiTripForSameBillToParyId(
        selectedTripForInvoiceGeneration[0]?.bill_to_party_id,
        selectedRowKeysState
      );

      if (response.status === 201) {
        toast.success("Invoice generated successfully");
        getFilterTripList(1, debouncedSearchTerm, selectedCustomerId);
        handleFilterSearch(1);
        setIsConfirmModalOpen(false);
      }
    } catch (error) {
      setIsConfirmModalOpen(false);

      if (error.response.status === 401) {
        toast.error("401: Not enough permissions");
      } else if (error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error("Inoive generation failed");
      }
    }
  };

  const handleSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeysState(selectedRowKeys);
    setSelectedTripForInvoiceGeneration(selectedRows);
  };

  // formate customer list as per antd select option requirenment
  const formatCustomerList = () => {
    const formattedCustomerList = customerList?.map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
    }));
    setFormattedCustomerList(formattedCustomerList?.slice(0, 7));
  };

  useEffect(() => formatCustomerList(), [customerList]);

  const rowSelection = {
    onChange: handleSelectChange,
    getCheckboxProps: (record) => ({
      disabled:
        selectedTripForInvoiceGeneration.length > 0 &&
        record.bill_to_party_id !==
          selectedTripForInvoiceGeneration[
            selectedTripForInvoiceGeneration.length - 1
          ]?.bill_to_party_id,
    }),
    selectedRowKeys: selectedRowKeysState,
    hideSelectAll: true,
  };

  const rowClassName = (record) => {
    return `${selectedTrip.id === record.id ? "ant-table-row-selected" : ""}`;
  };

  return (
    <MultiTripInvoiceStyle isCheckBoxVisible={isCheckBoxVisible}>
      <ToastContainer style={{ marginTop: "40px" }} theme="light" />
      <ModalAntd
        title="Confirm"
        open={isConfirmModalOpen}
        onOk={generateInvoice}
        closeIcon={false}
        zIndex={9999}
        onCancel={() => setIsConfirmModalOpen(false)}
        okText="Ok"
        cancelText="Cancel"
      >
        <div>
          <div>Are you sure you want to generate multi-trip invoice?</div>
          <div style={{ marginTop: "10px" }}>
            <div
              style={{ color: colorPalette.primaryColor, fontWeight: "700" }}
            >
              Selected Trips -
            </div>
            {selectedTripForInvoiceGeneration.map((item) => (
              <div>{item.trip_code}</div>
            ))}
          </div>
        </div>
      </ModalAntd>
      <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
        <div className="search-filter-section">
          <div className="serach-section">
            <Input
              placeholder="Search with trip code"
              size="large"
              onChange={(e) => setTripSearchTerm(e.target.value)}
              prefix={<SearchOutlined />}
            />
          </div>
          <div className="serach-section">
            <Select
              showSearch
              style={{ width: "100%" }}
              size="large"
              placeholder="Search to Select bill to party"
              optionFilterProp="children"
              filterOption={(input, option) => {
                setCustomerSearchTerm(input);
                return (option?.label.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                );
              }}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              loading={isLoading}
              value={selectedCustomerId}
              allowClear
              onChange={(e) => setSelectedCustomerId(e)}
              dropdownStyle={{ zIndex: "9999" }}
              options={formattedCustomerList}
            />
          </div>
        </div>

        {selectedTripStatusText !== "" && isCheckBoxVisible ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              height: "50px",
              width: "600px",
            }}
          >
            <div style={{ width: "200px" }}>
              {selectedRowKeysState.length > 0 &&
              selectedRowKeysState.length <= 10 ? (
                <ButtonRectangle
                  onClick={() => {
                    setIsConfirmModalOpen(true);
                  }}
                >
                  Generate Multi-Trip Invoice
                </ButtonRectangle>
              ) : (
                <>
                  <ButtonRectangle disabled style={{ cursor: "not-allowed" }}>
                    Generate Multi-Trip Invoice
                  </ButtonRectangle>
                </>
              )}
            </div>

            {selectedRowKeysState.length >= 11 && (
              <div
                style={{
                  fontSize: "12px",
                  marginTop: "10px",
                  color: colorPalette.red,
                }}
              >
                Maximum 10 trips are allowed for multi-trip invoice generation.
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="table-main-section">
        <Table
          rowSelection={
            isCheckBoxVisible && {
              type: "checkbox",
              ...rowSelection,
            }
          }
          onRow={(record) => ({
            onClick: () => {
              setSelectedTrip(record);
              handleTripDetails("Trip Details");
            },
          })}
          rowClassName={rowClassName}
          loading={isTripListLoading}
          columns={columns}
          dataSource={tripListFormattedData}
          scroll={{ x: "100%", y: 550 }}
        />
      </div>
    </MultiTripInvoiceStyle>
  );
};

export default MultiTripInvoice;
