import React from "react";
import apiUrl from "../../url/apiUrl.js";
import axios from "axios";
import axiosInterceptor from "../../config/https.js";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter.js";

export const getWarehouseUsersList = async (warehouseId, page) => {
  try {
    const warehouseListResponse = await axiosInterceptor.get(
      `${apiUrl.warehouseUrl}/${prefixSalt()}-${convertToBase64(
        warehouseId
      )}/warehouse-users`
    );
    if (warehouseListResponse.status == 200) {
      return warehouseListResponse.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const wareHouseUserPost = (
  id,
  first_name,
  mobile_number,
  designations,
  emp_code,
  last_name,
  email,
  user_type,
  user_name,
  department
) => {
  let data = JSON.stringify({
    warehouse_id: `${id}`,
    first_name: `${first_name}`,
    mobile_number: `${mobile_number}`,
    designations: `${designations}`,
    emp_code: `${emp_code}`,
    last_name: `${last_name}`,
    email: `${email}`,
    user_type: `${user_type}`,
    user_name: `${user_name}`,
    department: `${department ? department : ""}`,
  });
  var config = {
    method: "post",
    url: `${apiUrl.warehouseUrlV2}/${prefixSalt()}-${convertToBase64(
      id
    )}/warehouse-users`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status === 201) {
          resolve(response.status);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};

export const WarehouseUserActivePatch = (
  warehouseId,
  warehouseUserId,
  isActive
) => {
  let data = JSON.stringify({
    is_active: isActive,
  });
  var config = {
    method: "patch",
    url: `${apiUrl.warehouseUrl}/${prefixSalt()}-${convertToBase64(
      warehouseId
    )}/warehouse-users/is_active/${prefixSalt()}-${convertToBase64(
      warehouseUserId
    )}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status === 200) {
          resolve(response.status);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error);
      });
  });
};

export const updateWarehouseUserPut = (
  id,
  first_name,
  mobile_number,
  designations,
  emp_code,
  last_name,
  email,
  user_type,
  warehouseUserId,
  userName,
  isActive,
  department
) => {
  let data = JSON.stringify({
    warehouse_id: `${id}`,
    first_name: `${first_name}`,
    mobile_number: `${mobile_number}`,
    designations: `${designations}`,
    emp_code: `${emp_code}`,
    last_name: `${last_name}`,
    email: `${email}`,
    user_type: `${user_type}`,
    user_name: `${userName}`,
    is_active: isActive,
    department: `${department ? department : ""}`,
  });
  var config = {
    method: "put",
    url: `${apiUrl.warehouseUrlV2}/${prefixSalt()}-${convertToBase64(
      id
    )}/warehouse-users/${prefixSalt()}-${convertToBase64(warehouseUserId)}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(async function (response) {
        if (response.status === 200) {
          const activeResponse = await WarehouseUserActivePatch(
            id,
            warehouseUserId,
            isActive
          );
          if (activeResponse === 200) {
            resolve(response.status);
          }
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};
