import styled from "styled-components";
import { FontWeight, colorPalette } from "../../../themes/theme";

export const OurPlatformStyle = styled.div`
  width: 100%;
  padding: 40px 100px;
  scroll-margin-top: 80px;

  .platform-heading {
    font-size: 30px;
    font-weight: ${FontWeight.bold};
  }

  .platform-card-section {
    width: 100%;
    display: flex;
    align-items: center;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    margin-top: 30px;
    column-gap: 2%;
  }

  .platform-card {
    padding: 20px;
    width: 250px;
    border-radius: 8px;
    background: ${colorPalette.white};
    box-shadow: ${colorPalette.boxShadowPrimary};
    margin-top: 30px;
    transition: all 0.2s ease;
  }

  .platform-card:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .platform-card-icon {
    width: 70px;
    height: 70px;
    background-color: ${colorPalette.secondaryColor};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .platform-card-text {
    font-weight: ${FontWeight.semibold};
    font-size: 20px;
    line-height: 30px;
    margin-top: 40px;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
    padding: 40px 20px;
    /* margin-top: 60px; */

    .platform-card-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;
