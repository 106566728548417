import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../../themes/theme";
// import supplyChainImage from "../../../assets/homepage/LogisticsFlow.gif";
import ourServices from "../../../assets/homepage/services.gif";

export const OurServicesStyle = styled.div`
  width: 100%;
  height: 100vh;
  padding: 20px 100px;
  position: relative;
  z-index: 3;
  scroll-margin-top: 80px;
  display: flex;
  flex-direction: column;

  .our_services_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("/public/assets/landingPageAssets/Logistics\ website_1.gif");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .our_services_heading {
    font-weight: ${FontWeight.bold};
    font-size: 30px;
    margin-bottom: 20px;
    width: 100%;
  }

  .our-service-card-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* float: right; */
    z-index: 4;
  }

  .our-service-card {
    width: 100%;
    max-width: 450px;
    padding: 10px 20px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 8px;
    margin-top: 20px;
    z-index: 5;
    background-color: ${colorPalette.white};

    border-left: 5px solid ${colorPalette.primaryColor};
  }

  .our-service-card .card-heading {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.bold};
  }

  .our-service-card .card-dis {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
    color: ${colorPalette.paragraphColor};
    margin-top: 20px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 20px 20px;

    .our_services_bg {
      display: none;
    }

    .our-service-card-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      float: right;
      z-index: 4;
    }

    .our-service-card {
      border-left: 5px solid ${colorPalette.primaryColor};
      margin-top: 0;
      margin-bottom: 20px;
    }

    .our_services_heading {
      font-weight: ${FontWeight.bold};
      font-size: 30px;
    }

    .partnership_company_mobile {
      display: block;
    }
  }
`;
export const ServicesImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${ourServices});
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
