import React, { useContext, useEffect, useState } from "react";

import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
// import apiUrl from "../../url/apiUrl";
import UPLOAD from "../../assets/upload.svg";
import { ArrowBack } from "@mui/icons-material";
// import { getTransporterDetails } from "./recordPaymentServices";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import DeleteIcon from "@mui/icons-material/Delete";
import FeedIcon from "@mui/icons-material/Feed";
import Alert from "@mui/material/Alert";
import { NavLink, useHistory } from "react-router-dom";
import {
  PaymentRecordPost,
  getPaymentRecords,
  getPaymentAttachments,
  getTripCost,
  getTripDetails,
  getTripDetailsById,
  getbankDetailsByVehicleNumber,
} from "../recordPayment/recordPaymentServices";
import { updateSelectedTrip, updateTripDetail } from "../../redux/tripSlice";
import { useSelector, useDispatch } from "react-redux";
import apiUrl from "../../url/apiUrl";
import { CircularProgress, TextField, InputAdornment } from "@mui/material";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useParams } from "react-router-dom";
import { paymentDetails } from "../../redux/paymentSlice";
import { Record_payment_main_container } from "./record-payment.js";
import { colorPalette, colorPaletteDark } from "../../themes/theme";
import { DarkModeContext } from "../../context/darkModeContext.js";
import Topbar from "../../components/topbar/Topbar.jsx";

function RecordPayment() {
  const { tripId } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();
  const [amount, setAmount] = useState("");

  const [refId, setRefId] = useState("");
  const [document, setDocument] = useState();
  const [unuthorized, setUnuthorized] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [tripCostData, setTripCostData] = useState([]);
  const [tripCostTotal, setTripCostTotal] = useState("");
  const [perBagCost, setPerBagCost] = useState("");
  const [balance, setBalance] = useState("");
  const [exceedMsg, setExceedMsg] = useState("");
  const [transporterDetails, setTransporterDetails] = useState({});
  const [errPaymentAmount, setErrPaymentAmount] = useState("");
  const [errPaymentRefId, setErrPaymentRefId] = useState("");
  const [transporterBankDetails, setTransporterBankDetails] = useState([]);
  const { selectedTrip } = useSelector((state) => state.trip);

  const handleGoToTrips = () => {
    history.push("/payments");
  };

  // const getTransporterDetailsFromApi = async (vehicleNumber) => {
  //   const response = await getTransporterDetails(vehicleNumber);
  //
  //   setTransporterDetails(response.data);
  // };

  const [paymentListAttachments, setPaymentListAttachments] = useState();

  const handleSaveDetails = async () => {
    if (amount > 0 && refId.length > 2) {
      setLoading(true);
      if (amount <= balance) {
        const response = await PaymentRecordPost(
          document,
          tripId,
          amount,
          refId
        );

        if (response.status == 200) {
          const getResponse = await getPaymentRecords(tripId);

          if (getResponse.status == 200) {
            setPaymentHistory(getResponse.data);
            setRefId("");
            setDocument("");
            setAmount("");
            setErrPaymentAmount("");
            setErrPaymentRefId("");
            setLoading(false);
            setSuccessMsg("Payment updated successfully.");
            setExceedMsg("");
          }
        } else if (response.status == 400) {
          setExceedMsg(response.data.detail);
          setSuccessMsg("");
          setLoading(false);
        } else if (response.status == 401) {
          setUnuthorized("Unauthorized Access!");
          setSuccessMsg("");
          setLoading(false);
          // setSuccessMsg("");
        } else {
          //
          setExceedMsg(`Error : ${response?.status}`);
          setLoading(false);
          setSuccessMsg("");
        }
      } else {
        setExceedMsg("Entered amount is more than the balance.");
        setSuccessMsg("");
        // setExceedMsg("");
        setLoading(false);
      }
    } else {
      setErrPaymentAmount("Please enter valid Amount");
      setErrPaymentRefId("Please enter valid Reference Id");
    }
  };

  const getTripDetailsByIdApi = async (tripId) => {
    const response = await getTripDetailsById(tripId);

    dispatch(updateSelectedTrip(response.data));
    // getTransporterDetailsFromApi(response.data.vehicle_number);
  };

  useEffect(() => {
    if (Object.keys(selectedTrip).length === 0) {
      getTripDetailsByIdApi(tripId);
    } else {
      // getTransporterDetailsFromApi(selectedTrip.vehicle_number);
    }
  }, [tripId]);

  useEffect(() => {
    if (selectedTrip.trip_cost) {
      if (selectedTrip.trip_cost.length > 0) {
        //trip cost
        const tripCostTemp = selectedTrip.trip_cost.filter(
          (item) => item.cost_type === "trip_cost"
        );
        if (tripCostTemp.length > 0 && selectedTrip.unloaded_quantity) {
          setTripCostTotal(tripCostTemp[0].cost_amount);
          setPerBagCost(
            (
              tripCostTemp[0].cost_amount / selectedTrip.unloaded_quantity
            ).toFixed(2)
          );
        } else if (tripCostTemp.length > 0 && selectedTrip.quantity) {
          setTripCostTotal(tripCostTemp[0].cost_amount);
          setPerBagCost(
            (tripCostTemp[0].cost_amount / selectedTrip.quantity).toFixed(2)
          );
        }
      }
    }
  }, [selectedTrip]);

  useEffect(async () => {
    const recordData = await getPaymentRecords(tripId);

    if (recordData.status === 400) {
      setPaymentHistory([]);
      setLoadingPage(false);
    } else {
      setPaymentHistory(recordData.data);
      setLoadingPage(false);
    }
  }, [tripId]);

  useEffect(() => {
    getBalance(tripCostData, paymentHistory);
  }, [tripCostData, paymentHistory]);

  const bankDetails = async (vehicle) => {
    const response = await getbankDetailsByVehicleNumber(vehicle);

    setTransporterBankDetails(response.data.bank_details_list);
  };
  useEffect(() => {
    if (Object.keys(selectedTrip).length > 0) {
      bankDetails(selectedTrip.vehicle_number);
    }
  }, [selectedTrip.vehicle_number]);
  const tripTotalCost = async (tripIds) => {
    const costResponse = await getTripCost(tripIds);

    setTripCostData(costResponse.data);
  };

  useEffect(() => {
    tripTotalCost(tripId);
  }, [tripId]);

  const handleDocument = (event) => {
    setDocument(event.target.files[0]);
  };

  const handleSingleData = async (id) => {
    const getAttachment = await getPaymentAttachments(id);
    setPaymentListAttachments(getAttachment.data);
  };

  const reducer = (accumulator, curr) => accumulator + curr;

  const getBalance = () => {
    let total = 0;
    let totalHistory = 0;
    let advance = 0;
    if (tripCostData.length > 0) {
      total = tripCostData
        .filter((advance) => advance.cost_type !== "advance")
        .filter((cost) => cost.cost_type !== "per_bag_cost")
        .map((data) => data.cost_amount)
        .reduce(reducer);
      let advanceTemp = tripCostData.filter(
        (data) => data.cost_type == "advance"
      );
      if (advanceTemp.length > 0) {
        advance = advanceTemp[0].cost_amount;
      }

      if (paymentHistory.length > 0) {
        totalHistory = paymentHistory
          .map((data) => data.amount)
          .reduce(reducer);
      }
    }
    setBalance(total - totalHistory);
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  const { toggle, darkMode } = useContext(DarkModeContext);

  return (
    <Record_payment_main_container
      className="record-payment-main-container"
      mode={darkMode}
    >
      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Payment record" />
        </div>
      ) : (
        <div className="record-payment-sidebar">
          <SideBarMain />
        </div>
      )}

      <div className="record-payment-right-main-container">
        {isMobile ? null : <Topbar />}
        {loadingPage ? (
          <div className="record-payment-loading-container">
            <img src={success} alt="" width="120px" height="90px" />
            <h6 style={{ color: "grey" }}>loading data...</h6>
          </div>
        ) : (
          <>
            <div className="record-payment-top-row">
              {" "}
              <ArrowBack
                style={{ cursor: "pointer" }}
                onClick={handleGoToTrips}
              />{" "}
              <span
                style={{
                  marginLeft: "10px",
                  fontWeight: "500",
                  fontSize: "17px",
                }}
              >
                Payment Details
              </span>
            </div>
            <div className="record-payment-content-container">
              <div className="record-payment-inner-left-container">
                <div
                  className="record-payment-inner-left-container_vehicles_details"
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   // backgroundColor: "red",
                  //   width: "100%",
                  //   borderRadius: "10px",
                  //   padding: ".2rem .2rem",
                  //   width: "80%",
                  //   boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",

                  // }}
                >
                  <div style={{ flex: "1" }}>
                    <div className="record-payment-row">
                      {" "}
                      <span
                        style={{
                          fontWeight: "500",
                          marginLeft: "10px",
                          color: darkMode
                            ? colorPaletteDark.white
                            : colorPalette.black,
                        }}
                      >
                        {" "}
                        Trip code :{" "}
                      </span>{" "}
                      <span>
                        {selectedTrip.trip_code
                          ? selectedTrip.trip_code
                          : " - "}
                      </span>
                    </div>
                    <div className="record-payment-row">
                      {" "}
                      <span style={{ fontWeight: "500", marginLeft: "10px" }}>
                        {" "}
                        Vehicle Number :{" "}
                      </span>{" "}
                      <span>
                        {selectedTrip.vehicle_number
                          ? selectedTrip.vehicle_number
                          : " - "}
                      </span>
                    </div>
                    <div className="record-payment-row">
                      {" "}
                      <span style={{ fontWeight: "500", marginLeft: "10px" }}>
                        {" "}
                        Driver's Mobile No :{" "}
                      </span>{" "}
                      <span>
                        {selectedTrip.driver_mobile_for_trip
                          ? selectedTrip.driver_mobile_for_trip
                          : " - "}
                      </span>
                    </div>
                    <div className="record-payment-row">
                      {" "}
                      <span style={{ fontWeight: "500", marginLeft: "10px" }}>
                        {" "}
                        Payment Terms :{" "}
                      </span>{" "}
                      <span>
                        {selectedTrip.payment_terms
                          ? selectedTrip.payment_terms === "to_be_billed"
                            ? "To be billed"
                            : selectedTrip.payment_terms === "to_payee"
                            ? "To Pay"
                            : " - "
                          : " - "}
                      </span>
                    </div>
                    <div className="record-payment-row">
                      {" "}
                      <span style={{ fontWeight: "500", marginLeft: "10px" }}>
                        {" "}
                        Per bag cost :{" "}
                      </span>{" "}
                      <span>Rs. {perBagCost ? perBagCost : " - "}</span>
                    </div>
                  </div>
                </div>
                {transporterBankDetails.length > 0 ? (
                  <div className="record-payment-row_bank">
                    <h4
                      style={{
                        fontSize: ".8rem",
                        marginLeft: "10px",
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      Transporter's Primary Bank Account Details
                      <CheckCircleIcon
                        style={{
                          color: colorPalette.primaryColor,
                          fontSize: "15px",
                          marginLeft: "5px",
                        }}
                      />
                    </h4>
                    {transporterBankDetails.length > 0
                      ? transporterBankDetails
                          .filter(
                            (data) =>
                              data.entity_type == "transporter" &&
                              data.is_primary == true
                          )
                          .map((bank, index) => (
                            <div
                              className="record-payment-row"
                              style={{ margin: ".5rem 0rem" }}
                              key={index}
                            >
                              <h5
                                style={{
                                  fontWeight: "500",
                                  marginLeft: "10px",
                                  position: "relative",
                                }}
                              >
                                A/c Holder Name :
                                <span className="bank_attributes">
                                  : {bank.account_holder_name}
                                </span>{" "}
                              </h5>
                              <h5
                                style={{
                                  fontWeight: "500",
                                  marginLeft: "10px",
                                  position: "relative",
                                }}
                              >
                                A/c Number :
                                <span className="bank_attributes">
                                  : {bank.bank_account_number}
                                </span>{" "}
                              </h5>
                              <h5
                                style={{
                                  fontWeight: "500",
                                  marginLeft: "10px",
                                  position: "relative",
                                }}
                              >
                                A/c Type :
                                <span className="bank_attributes">
                                  :{" "}
                                  {bank.bank_account_type
                                    .split("_")
                                    .join(" ")
                                    .toUpperCase()}
                                </span>{" "}
                              </h5>
                              <h5
                                style={{
                                  fontWeight: "500",
                                  marginLeft: "10px",
                                  position: "relative",
                                }}
                              >
                                Bank Name :
                                <span className="bank_attributes">
                                  : {bank.bank_name}
                                </span>{" "}
                              </h5>
                              <h5
                                style={{
                                  fontWeight: "500",
                                  marginLeft: "10px",
                                  position: "relative",
                                }}
                              >
                                Ifsc Code :
                                <span className="bank_attributes">
                                  : {bank.ifsc_code}
                                </span>{" "}
                              </h5>
                            </div>
                          ))
                      : null}
                  </div>
                ) : null}
                {Object.keys(selectedTrip).length > 0 ? (
                  <>
                    <div className="record-payment-row_bank">
                      <h4
                        style={{
                          fontSize: ".8rem",
                          marginLeft: "10px",
                          marginTop: "10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        Customers's Payment Details:
                      </h4>
                      <div
                        className="record-payment-row"
                        style={{ margin: ".5rem 0rem" }}
                        // key={index}
                      >
                        <h5
                          style={{
                            fontWeight: "500",
                            marginLeft: "10px",
                            position: "relative",
                          }}
                        >
                          Advance Paid by Customer:
                          {selectedTrip.trip_customer_invoice !== undefined ? (
                            <span className="bank_attributes">
                              : ₹
                              {selectedTrip.trip_customer_invoice.length > 0
                                ? selectedTrip.trip_customer_invoice[0]
                                    .advance_paid_by_customer
                                : " -"}
                            </span>
                          ) : null}
                        </h5>
                        <h5
                          style={{
                            fontWeight: "500",
                            marginLeft: "10px",
                            position: "relative",
                          }}
                        >
                          Customer Billed Amount :
                          {selectedTrip.trip_customer_invoice !== undefined ? (
                            <span className="bank_attributes">
                              : ₹{" "}
                              {selectedTrip.trip_customer_invoice.length > 0
                                ? selectedTrip.trip_customer_invoice[0]
                                    .customer_billed_amount
                                : " -"}
                            </span>
                          ) : null}
                        </h5>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="record-payment-data-box">
                  <h6
                    style={{
                      color: colorPalette.black,
                      fontSize: "17px",
                      margin: "5px 0px",
                      fontWeight: "500",
                    }}
                  >
                    TRIP COST DETAILS
                  </h6>
                  {tripCostData.length > 0
                    ? tripCostData
                        .filter((datas) => datas.cost_type !== "advance")
                        .filter((cost) => cost.cost_type != "per_bag_cost")
                        .map((data, index) => (
                          <div className="record-payment-box-in" key={index}>
                            <div className="record-payment-box-left">
                              <div style={{ fontSize: "14px" }}>
                                {" "}
                                {data.cost_type
                                  .split("_")
                                  .join(" ")
                                  .toUpperCase()}{" "}
                                :{" "}
                              </div>
                            </div>
                            <div className="record-payment-box-right">
                              <div>Rs. {data.cost_amount} </div>
                            </div>
                          </div>
                        ))
                    : null}

                  <hr></hr>
                  <div className="record-payment-box-in">
                    <div className="record-payment-box-left">
                      <div style={{ fontWeight: "500", fontSize: "15px" }}>
                        {" "}
                        TOTAL :{" "}
                      </div>
                    </div>
                    <div className="record-payment-box-right">
                      <div style={{ fontWeight: "500", fontSize: "15px" }}>
                        Rs.
                        {tripCostData.length > 0
                          ? tripCostData
                              .filter(
                                (cost) => cost.cost_type !== "per_bag_cost"
                              )
                              .filter((datas) => datas.cost_type !== "advance")
                              .map((data) => data.cost_amount)
                              .reduce(reducer)
                          : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="record-payment-data-box">
                  <h6
                    style={{
                      color: colorPalette.black,
                      fontSize: "17px",
                      margin: "5px 0px",
                      fontWeight: "500",
                    }}
                  >
                    PAYMENT HISTORY
                  </h6>
                  <div style={{ height: "100px", overflow: "scroll" }}>
                    {paymentHistory.length > 0 ? (
                      paymentHistory.map((data, index) => (
                        <div
                          className="partial-paid"
                          key={index}
                          onClick={() => {
                            handleSingleData(data.id);
                          }}
                        >
                          <div className="single-item">
                            <div className="payment-reference">
                              {" "}
                              <h5>Payment Ref.id:</h5>
                              <span
                                style={{ marginLeft: "10px", fontSize: "15px" }}
                              >
                                {data.transaction_reference_id}
                              </span>
                            </div>

                            <div className="payment-reference">
                              {" "}
                              <h5>Paid On:</h5>
                              <span
                                style={{ marginLeft: "10px", fontSize: "12px" }}
                              >
                                {data.created_on.slice(0, 10)}
                              </span>
                            </div>
                          </div>
                          <div className="record-payment-docs">
                            <div
                              style={{ marginRight: "15px", fontWeight: "500" }}
                            >
                              <div> Rs.{data.amount} </div>
                            </div>
                            {/* <div style={{ marginRight: "15px" }}>
                          <a
                            href={`${apiUrl.onboardingBaseUrl}/v1/image/trip-payment/${data.id}`}
                            download
                            target="_blank"
                            className="attachment_btn"
                            // onClick={() => {
                            //   handleSingleData(data.id);
                            // }}
                          >
                            Doc
                          </a>
                        </div> */}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: colorPalette.primaryColor,
                        }}
                      >
                        <h5>No payment history found</h5>
                      </div>
                    )}
                  </div>

                  <div className="record-payment-history-bottom-container">
                    {" "}
                    <div className="record-payment-balance-div">
                      {balance > 0 ? (
                        <>
                          <h5>BALANCE : </h5>
                          <span
                            style={{
                              marginLeft: "10px",
                              fontSize: "15px",
                              color: "red",
                              fontWeight: "500",
                            }}
                          >
                            Rs. {balance}
                          </span>
                        </>
                      ) : (
                        <h4 style={{ color: colorPalette.primaryColor }}>
                          NO DUE AMOUNT
                        </h4>
                      )}
                    </div>
                    {/* <div className="record-payment-balance-div">
                  <h5>Advance:</h5>
                  <span>
                    {tripCostData
                      ? tripCostData
                          .filter((cost) => cost.cost_type == "advance")
                          .map((data) => (
                            <span
                              style={{ color: "#016938", marginLeft: "1rem" }}
                            >
                              Rs.{data.cost_amount}
                            </span>
                          ))
                      : null}
                  </span>
                </div> */}
                  </div>
                </div>
              </div>
              {isMobile ? (
                <div className="record-payment-inner-middle">
                  <div className="record-payment-line"></div>
                </div>
              ) : (
                <div className="record-payment-inner-middle">
                  <div className="record-payment-line"></div>
                </div>
              )}

              {balance <= 0 ? null : (
                <div className="record-payment-inner-right-container">
                  <span className="record-payment-payment-details-head">
                    Enter Payment Details
                  </span>
                  <div className="record-payment-row">
                    <div className="record-payment-input-container">
                      <h4 className="record-payment-input-head">
                        Enter the amount :
                      </h4>
                      <TextField
                        className="record-payment-inputfield"
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        size="small"
                        placeholder="Enter the amount"
                        value={amount}
                        autoComplete="off"
                        onChange={(e) => {
                          setAmount(e.target.value);
                          setExceedMsg("");
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ fontFamily: "sans-serif" }}
                            >
                              {" "}
                              ₹{" "}
                            </InputAdornment>
                          ),
                        }}
                      />

                      <div className="record-payment-error">
                        {amount > 0 ? null : errPaymentAmount}
                      </div>
                    </div>
                    <div className="record-payment-input-container-reference">
                      <h4 className="record-payment-input-head">
                        Reference details :
                      </h4>
                      {/* <div className="record-payment-reference-input"> */}
                      <TextField
                        size="small"
                        className="record-payment-inputfield"
                        placeholder="Enter Reference Id"
                        value={refId}
                        autoComplete="off"
                        onChange={(e) => {
                          setRefId(e.target.value);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{
                                fontFamily: "sans-serif",
                                color: "transparent",
                              }}
                            >
                              {" "}
                              ₹{" "}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <div className="record-payment-error">
                        {refId.length > 3 ? null : errPaymentRefId}
                      </div>

                      {/* <input
                          className="record-payment-inputfield"
                          placeholder="Enter Reference id"
                          value={refId}
                          onChange={(e) => {
                            setRefId(e.target.value);
                          }}
                        /> */}
                      {/* <div className="record-payment-right-upload">
                      <input
                        type="file"
                        accept="image/png, image/jpeg, application/pdf"
                        id="file"
                        aria-label="File browser example"
                        className="custom-file-input"
                        onChange={handleDocument}
                      />
                     
                    </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="record-payment-alert-container">
                    {successMsg ? (
                      <Alert
                        severity="success"
                        style={isMobile ? { width: "95%" } : { width: "70%" }}
                      >
                        <h4 style={{ textAlign: "center" }}>{successMsg}</h4>
                      </Alert>
                    ) : null}
                    {unuthorized ? (
                      <Alert
                        severity="warning"
                        style={isMobile ? { width: "95%" } : { width: "70%" }}
                      >
                        <h5 style={{ textAlign: "center" }}>{unuthorized}</h5>
                      </Alert>
                    ) : null}
                    {exceedMsg ? (
                      <Alert
                        severity="warning"
                        style={isMobile ? { width: "95%" } : { width: "70%" }}
                      >
                        <h4 style={{ textAlign: "center" }}>{exceedMsg}</h4>
                      </Alert>
                    ) : null}
                  </div>
                  <button
                    className="update-payment"
                    onClick={handleSaveDetails}
                  >
                    {loading ? (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    ) : (
                      "UPDATE PAYMENT"
                    )}
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Record_payment_main_container>
  );
}

export default RecordPayment;
