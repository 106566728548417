import styled from "styled-components";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";

export const CustomersModal = styled.div`
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  width: 30vw;
  height: 100vh;

  .entity-table {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 2.5fr 3fr 4fr 2fr;
    grid-template-rows: 23px;
    margin: 10px auto;
    padding: 4px 8px;
  }
  .table-head {
    background-color: rgba(0, 0, 0, 0.1);
  }

  @media only screen and (max-width: 480px) {
    width: 85vw;
  }
  .customer-onboarded-details-container {
    padding: 10px 5px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  }
  .customer-details-values {
    color: ${colorPalette.paragraphColor};
    font-size: ${typography.heading5};
    font-family: ${FontFamily.primaryFontFamily};
    margin-left: 5px;
  }
  .customer-details-values-description {
    color: ${colorPalette.paragraphColor};
    font-size: ${typography.heading5};
    font-family: ${FontFamily.primaryFontFamily};
  }

  .customer-details-values-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .customer-details-onboarded-heading {
    width: 100%;
    /* background-color: red; */
    display: flex;
    align-items: center;
    padding: 0px 0px 10px 0px;
    justify-content: space-between;
  }
  .oem_div_customer {
    display: flex;
    padding: 10px;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }

  .form_create_customer {
    width: 100%;
    /* background-color: red; */
    padding: 0rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 0.5rem;
    /* background-color: red; */
  }
  .form_label_customers {
    font-family: ${FontFamily.primaryFontFamily};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading5};
    /* margin-bottom: 0.1rem; */
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
`;
