import styled from "styled-components";
import { colorPalette } from "../../themes/theme";

export const MultiTripInvoiceStyle = styled.div`
  width: 100%;
  height: 100vh;
  padding: 25px;
  overflow: hidden;

  // table action button style

  .ant-table-row {
    position: relative;
  }

  .actions {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    width: 130px;
    transform: translate(-50%, -50%) !important;
    display: none;
  }

  .ant-table-row:hover .actions {
    display: ${(props) =>
      props.isCheckBoxVisible ? "block !important" : "none !important"};
  }

  .search-filter-section {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;

    .serach-section {
      width: 60%;
      display: flex;
      position: relative;

      align-items: center;
      justify-content: space-between;

      .drop-down {
        position: absolute;
        top: 40px;
        left: 0;
        width: 100%;
        height: 20px;
        background-color: ${colorPalette.white};
        z-index: 99;

        div {
          background-color: ${colorPalette.white};
          cursor: pointer;
          padding: 10px;
        }
      }

      .ant-input-search-button {
        border-radius: 4px !important;
        background-color: ${colorPalette.primaryColor} !important;

        .anticon-search {
          color: ${colorPalette.white} !important;
        }
      }
      input {
        border-radius: 4px;
      }

      /* input {
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        outline: none;
        border-radius: 4px;
        border: 1px solid #1b1b1b;
        width: 80%;
      } */
    }
  }
  .table-main-section {
    margin-top: 20px;
  }
`;
