import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../themes/theme";

export const Record_payment_main_container = styled.div`
  /* .record-payment-main-container { */
  display: flex;
  width: 100vw;
  height: 100%;
  /* } */
  .record-payment-sidebar {
    flex: 1.7;
  }
  .record-payment-error {
    color: red;
    font-size: ${typography.paragraph};
    margin: 2px;
  }
  .bank_attributes {
    position: absolute;
    left: 200px;
  }
  .record-payment-inner-middle {
    /* background-color: red; */
    width: 20px;
    flex: 1;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .record-payment-line {
    background-color: ${colorPalette.secondaryWhite};
    width: 2px;
    height: 100vh;
  }
  .record-payment-alert-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 1rem 0px;
  }
  .record-payment-right-main-container {
    height: 100%;
  }
  .record-payment-inner-left-container {
    flex: 8;
  }
  .record-payment-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
  }
  .record-payment-inner-right-container {
    flex: 7;
  }
  .record-payment-input-head {
    font-weight: ${FontWeight.regular};
    font-size: ${typography.heading5};
    margin-top: 10px;
  }
  .record-payment-inputfield {
    padding: 5px;
    margin-top: 0.5rem;
    display: flex;
    width: 70%;
    /* align-items: center; */
  }

  .record-payment-payment-details-head {
    font-weight: ${FontWeight.medium};
    align-self: flex-start;
    color: ${colorPalette.black};
    font-size: ${typography.heading4};
  }
  .record-payment-reference-input {
    /* border: 1px solid black; */
    width: 70%;
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
    /* margin-top: .5rem; */
  }
  .record-payment-right-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
    padding: 10px;
    margin-left: 5%;
  }
  .record-payment-top-row {
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    width: 90%;
    /* align-items: center; */
  }
  .record-payment-content-container {
    display: flex;
    margin: 15px;
  }
  .record-payment-box-in {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .record-payment-data-box {
    background-color: ${colorPalette.secondaryWhite};
    border-radius: 5px;
    width: 80%;
    padding: 10px;
    margin-top: 10px;
  }
  .record-payment-data-box_history {
    background-color: ${colorPalette.secondaryWhite};
    border-radius: 5px;
    width: 90%;
    padding: 10px;
    margin-top: 10px;
    box-shadow: ${colorPalette.boxShadowPrimary};
  }
  .record-payment-data-box_history_details {
    background-color: ${colorPalette.secondaryWhite};
    border-radius: 5px;
    width: 90%;
    height: 180px;
    padding: 10px;
    margin-top: 10px;
    overflow-y: scroll;
    /* box-shadow: ${colorPalette.boxShadowPrimary}; */
  }
  .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: url("../../assets/upload.svg");
    display: inline-block;
    background: linear-gradient(
      top,
      ${colorPalette.secondaryWhite},
      ${colorPalette.secondaryWhite}
    );
    border: 1px solid ${colorPalette.paragraphColor};
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px ${colorPalette.white};
    font-weight: ${FontWeight.bold};
    font-size: 10pt;
  }
  .record-payment-row {
    width: 100%;
  }
  .record-payment-row_bank {
    margin: 0.5rem 0rem;
    width: 80%;
    border-radius: 20px;
    /*background-color: rebeccapurple;
    */
    padding: 0.5rem 0rem;
    box-shadow: ${colorPalette.boxShadowPrimary};
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
  .partial-paid {
    /* overflow-y: scroll; */
    display: flex;
    /* height: 200px; */
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .payment-reference {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .record-payment-balance-div {
    display: flex;
    align-items: center;
    margin: 0px 10px;
  }
  .record-payment-history-bottom-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .record-payment-attachments {
    margin-top: 2rem;
    width: 400px;
    /* background-color: red; */
  }
  .record-payment-attachment-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    /* margin-top: 10px; */

    /* justify-content: space-between; */
  }
  .record-payment-attachment-list-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
  .update-payment {
    background-color: ${colorPalette.primaryColor};
    padding: 1rem 1rem;
    outline: none;
    width: 10rem;
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: ${colorPalette.white};

    /* margin-left: 16rem; */
    border-radius: 30px;
    cursor: pointer;
  }
  .update-payment-save {
    background-color: ${colorPalette.primaryColor};
    padding: 0.5rem 1.5rem;
    outline: none;
    border: none;
    color: ${colorPalette.white};
    margin-top: 1rem;
    margin-left: 19rem;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
  }
  .record-payment-balance {
    margin-top: 1rem;
    display: flex;
    align-items: center;
  }
  .single-item {
    padding: 0.5rem 0.5rem;
    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
  }
  .single-item:hover {
    background-color: ${colorPalette.secondaryGreen};
    cursor: pointer;
    /* color: white; */

    transition: all ease 0.4s;
    -webkit-transition: all ease 0.4s;
    -moz-transition: all ease 0.4s;
    -ms-transition: all ease 0.4s;
    -o-transition: all ease 0.4s;
  }
  /* +++++++++++++++++++++++++++++++++ */
  .monthly_payment_container {
    margin-top: 1rem;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: rebeccapurple; */
  }
  .container1 {
    flex: 6;
    display: flex;
    flex-direction: column;
    /* box-shadow: ${colorPalette.boxShadowPrimary}; */
    align-items: center;
    height: 100%;
    /* background-color: #96d4a2; */
  }
  .container2 {
    padding: 1rem 1rem;
    flex: 6;
    box-shadow: ${colorPalette.boxShadowPrimary};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    /* background-color: #468652; */
  }
  .monthly_payments_details {
    /* background-color: #9ec9a6; */
    width: 90%;
    /* height: 100%; */
    padding: 0.5rem 1rem;
    margin: 0 auto;
    /* height: 100%; */
    border-radius: 10px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .values_payments {
    position: absolute;
    left: 14rem;
    width: 100%;
    /* padding: .3rem 0rem; */
  }
  .details_keys {
    display: flex;
    width: 60%;
    position: relative;
    padding: 1px 0px;
    /* margin: 0 auto; */
    align-items: flex-start;
    justify-content: space-between;
  }
  /* .values_payments_keys{
  position: relative;
} */
  /* ++++++++++++++++++++++++++++++++ */
  @media only screen and (max-width: 499px) {
    .monthly_payment_container {
      margin-top: 5%;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      /* background-color: rebeccapurple; */
    }
    /* .record-payment-right-main-container {
      flex: 0;
      width: 100%;
 
    } */
    /* .values_payments_keys{
      position: relative;
    } */
    .values_payments {
      position: absolute;
      left: 12rem;
      width: 100%;
      /* padding: .3rem 0rem; */
    }
    .details_keys {
      display: flex;
      position: relative;
      width: 60%;
      padding: 0.3rem 0rem;
      /* margin: 0 auto; */
      align-items: flex-start;
      justify-content: space-between;
    }
    .bank_attributes {
      position: absolute;
      left: 11rem;
      width: 100%;
    }
    .record-payment-row_bank {
      margin: 0.5rem 0rem;
      width: 100%;
      border-radius: 20px;
      /*background-color: rebeccapurple;
      */
      padding: 0.5rem 0rem;
      box-shadow: ${colorPalette.boxShadowPrimary};
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      -ms-border-radius: 20px;
      -o-border-radius: 20px;
    }
    /* .record-payment-main-container { */
    height: 100%;
    width: 100vw;
    flex-direction: column;
    /* } */
    .record-payment-content-container {
      flex-direction: column;
    }
    .record-payment-data-box {
      width: 95%;
    }
    .record-payment-inner-left-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .record-payment-balance-div {
      margin: 0px;
    }
    .record-payment-history-bottom-container {
      justify-content: space-around;
    }
    .record-payment-inner-right-container {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 15px;
    }
    .record-payment-row {
      width: 95%;
    }
    .record-payment-input-head {
      font-size: ${typography.heading5};
    }
    .record-payment-payment-details-head {
      margin-left: 2.5%;
    }
    .record-payment-reference-input {
      flex-direction: column;
      align-items: center;
      /* margin-left: 5%; */
      width: 100%;
      justify-content: center;
    }
    .record-payment-input-container-reference {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }
    .update-payment {
      margin: 10px 0px;
    }
    .record-payment-inputfield {
      width: 100%;
    }
    .record-payment-right-upload {
      margin: 1% 0px;
      width: 100%;
    }
    .record-payment-alert-container {
      margin-left: 2.5%;
    }
    .record-payment-inner-middle {
      /* background-color: red; */
      width: 93vw;
      flex: 1;
      height: 1vh;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px 0px;
    }
    .record-payment-line {
      background-color: ${colorPalette.secondaryWhite};
      width: 2px;
      height: 0.3vh;
      width: 93vw;
    }
    .monthly_payments_details {
      /* background-color: #9ec9a6; */
      width: 95%;
      padding: 0.5rem 1rem;
      margin: 0 auto;
      /* height: 10%; */
      border-radius: 10px;
      box-shadow: ${colorPalette.boxShadowPrimary};
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }
    .container2 {
      padding: 1rem 0.3rem;
      flex: 6;
      box-shadow: ${colorPalette.boxShadowPrimary};
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      /* background-color: #468652; */
    }

    .record-payment-data-box_history_details {
      background-color: ${colorPalette.secondaryWhite};
      border-radius: 5px;
      width: 95%;
      height: 300px;
      padding: 5px;
      margin-top: 10px;
      overflow-y: scroll;
      /* box-shadow: ${colorPalette.boxShadowPrimary}; */
    }
  }
`;
