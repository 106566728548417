import apiUrl from "../../../url/apiUrl";
import axios from "axios";
import axiosInterceptor from "../../../config/https";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import { useState } from "react";
import { da } from "date-fns/locale";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { readLoginData } from "../../../helper/dataDecryptionHelper";

export const GetTripList = async (page) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.ftlTripV1BaseUrl}?current_page=${page}&page_size=20`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response.status;
    // throw e;
  }
};

export const GetTransporterByName = async (name) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.transporterBaseUrl}?name=${name}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response.status;
    // throw e;
  }
};

export const getTripExport = async (
  exportEmail,
  exportFromDate,
  exportToDate,
  exportTripStatus,
  exportCustomer,
  exportVehicleNumber,
  exportTransporter,
  exportVehiclePayoutPeriod,
  exportPaymentStatus,
  exportSourceState,
  exportDestinationState,
  exportCreatedBy,
  exportLoadSource,
  exportTripType
) => {
  let appendUrl = "";
  if (exportFromDate && exportToDate) {
    appendUrl =
      appendUrl + `&to_date=${exportToDate}` + `&from_date=${exportFromDate}`;
  }
  if (exportTripStatus && exportTripStatus != "0") {
    appendUrl = appendUrl + `&trip_status=${exportTripStatus}`;
  }
  if (exportCustomer && exportCustomer != "0") {
    appendUrl = appendUrl + `&customer_name=${exportCustomer}`;
  }
  if (exportVehicleNumber && exportVehicleNumber != "0") {
    appendUrl = appendUrl + `&vehicle_number=${exportVehicleNumber}`;
  }
  if (exportTransporter && exportTransporter != "0") {
    appendUrl = appendUrl + `&transporter_name=${exportTransporter}`;
  }
  if (exportVehiclePayoutPeriod && exportVehiclePayoutPeriod != "0") {
    appendUrl =
      appendUrl + `&vehicle_payout_period=${exportVehiclePayoutPeriod}`;
  }
  if (exportPaymentStatus && exportPaymentStatus != "0") {
    appendUrl = appendUrl + `&payment_status=${exportPaymentStatus}`;
  }

  if (exportSourceState && exportSourceState != "0") {
    appendUrl = appendUrl + `&source_state=${exportSourceState}`;
  }
  if (exportDestinationState && exportDestinationState != "0") {
    appendUrl = appendUrl + `&destination_state=${exportDestinationState}`;
  }
  if (exportCreatedBy && exportCreatedBy != "0") {
    appendUrl = appendUrl + `&created_by=${exportCreatedBy}`;
  }
  if (exportLoadSource && exportLoadSource != "0") {
    appendUrl = appendUrl + `&load_source=${exportLoadSource}`;
  }
  if (exportTripType && exportTripType != "0") {
    appendUrl = appendUrl + `&trip_type=${exportTripType}`;
  }
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.ftlTripExportBaseUrl}?mailing_address=${exportEmail}${appendUrl}`
    );

    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
    // throw e;
  }
};

export const getTripReportExport = async (
  exportEmail,
  exportFromDate,
  exportToDate,
  exportTripType
) => {
  let appendUrl = "";
  if (exportFromDate && exportToDate) {
    appendUrl =
      appendUrl + `&to_date=${exportToDate}` + `&from_date=${exportFromDate}`;
  }
  if (exportTripType && exportTripType != "0") {
    appendUrl = appendUrl + `&trip_type=${exportTripType}`;
  }
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.ftlTripReportExportBaseUrl}?mailing_address=${exportEmail}${appendUrl}`
    );

    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
    // throw e;
  }
};

export const getTripAttachmentsDispute = async (disputeId) => {
  try {
    const response = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/ftl/trip-dispute/${prefixSalt()}-${convertToBase64(disputeId)}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response.status;
    // throw e;
  }
};

export const approveRejectTrip = (
  tripStatus,
  remarks,
  tripId,
  advancePaidByCustomer,
  customerBilledAmount,
  negativeTripApprovalAttachmentFile,
  negativeRevenueTripApprovalEmail,
  negativeTrip
) => {
  const formData = new FormData();
  formData.append("trip_status", tripStatus);
  formData.append("remarks", remarks);
  if (tripStatus !== "rejected") {
    formData.append("advance_paid_by_customer", advancePaidByCustomer);
    formData.append("customer_billed_amount", customerBilledAmount);
  }
  if (negativeTrip && tripStatus !== "rejected") {
    formData.append(
      "negative_revenue_trip_approval_email",
      negativeRevenueTripApprovalEmail
    );
    formData.append(
      "negative_revenue_trip_approval_attachment",
      negativeTripApprovalAttachmentFile.attachment_file
    );
    formData.append(
      "negative_revenue_trip_approval_attachment_type",
      negativeTripApprovalAttachmentFile.attachment_type
    );
    formData.append(
      "negative_revenue_trip_approval_attachment_tag",
      negativeTripApprovalAttachmentFile.attachment_tag
    );
  }

  var config = {
    method: "patch",
    url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/approve-start-reject`,
    headers: {
      "Content-Type": "Multipart/form-data",
    },
    data: formData,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};
export const changeStatusToDisputed = (
  trip_status,
  remarks,
  tripId,
  changedStatusAfterDisputeResolve
) => {
  let data = {
    trip_status: trip_status,
    remarks: remarks,
    trip_status_to_be_resolved_in: changedStatusAfterDisputeResolve,
  };

  var config = {
    method: "patch",
    url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/trip-dispute`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};

export const resolveDisputeAndChangeStatus = (tripRemarks, selectedTripId) => {
  let data = {
    trip_status: "trip_disputed",
    remarks: tripRemarks,
  };

  var config = {
    method: "patch",
    url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      selectedTripId
    )}/resolve-trip-dispute`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};

export const arrivedAtDestination = (
  tripStatus,
  remarks,
  tripId,
  arrivedOn
) => {
  const formData = new FormData();
  formData.append("trip_status", tripStatus);
  formData.append("remarks", remarks);
  formData.append("arrived_on", arrivedOn + "T00:00:00.000000");
  var config = {
    method: "patch",
    url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/arrived-at-destination`,
    headers: {
      "Content-Type": "Multipart/form-data",
    },
    data: formData,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};

export const reopenCancelTrip = (tripStatus, remarks, tripId) => {
  const data = new FormData();
  data.append("trip_status", tripStatus);
  data.append("remarks", remarks);
  var config = {
    method: "patch",
    url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/reopen-cancel`,
    headers: {
      "Content-Type": "Multipart/form-data",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
        // resolve(error);
        //
      });
  });
};

export const approvedTripCancel = (tripStatus, remarks, tripId) => {
  const data = JSON.stringify({
    trip_status: tripStatus,
    remarks: remarks,
  });
  var config = {
    method: "patch",
    url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/cancel-approved-trip`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};

export const verifyDocument = (tripStatus, remarks, tripId) => {
  const data = JSON.stringify({
    trip_status: tripStatus,
    remarks: remarks,
    is_all_documents_verified: true,
  });

  var config = {
    method: "patch",
    url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/documents-verified`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};

export const startTrip = (tripStatus, remarks, tripId) => {
  const formData = new FormData();
  formData.append("trip_status", tripStatus);
  formData.append("remarks", remarks);
  var config = {
    method: "patch",
    url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/start`,
    headers: {
      "Content-Type": "Multipart/form-data",
    },
    data: formData,
    timeout: 60000,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (!error.response) {
          resolve(error);
        }
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
        // resolve(error);
        //
      });
  });
};

export const stopTrip = (tripStatus, remarks, tripId, DeliveredBills) => {
  let data = JSON.stringify({
    trip_status: tripStatus,
    remarks: remarks,
    order_details: DeliveredBills,
  });
  var config = {
    method: "put",
    url: `${apiUrl.tripBaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/complete`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
        // resolve(error);
        //
      });
  });
};

export const completeTrip = (tripStatus, remarks, tripId) => {
  let data = JSON.stringify({
    trip_status: tripStatus,
    remarks: remarks,
  });
  var config = {
    method: "patch",
    url: `${apiUrl.tripBaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/complete`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
          toast.error("Error");
        }
        resolve(error.response);
        // resolve(error);
        //
      });
  });
};

export const GetZohoInvoiceCustomerList = async (InvNum) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.tripZohoBaseUrl}/zoho/ftl-invoice?search=${InvNum}`
    );

    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    } else {
      return e.response;
    }
  }
};

export const ManualInvoiceGeneration = (invoiceNum, invoiceId, tripId) => {
  let formData = new FormData();
  formData.append("zoho_customer_invoice_number", invoiceNum);
  formData.append("zoho_customer_invoice_id", invoiceId);

  var config = {
    method: "POST",
    url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/invoice/attach-manual`,
    headers: {
      "Content-Type": "application/json",
    },
    data: formData,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status === 201) {
          resolve(response);
          toast.success("Invoice Generated Successfully");
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
          toast.error(error.response.data.error);
        } else if (error.response.status === 400) {
          toast.error(error.response.data.error);
        } else if (error.response.status === 401) {
          toast.error(error.response.data.error);
        } else {
          toast.error(error.response.data.error);
        }
        resolve(error.response);
      });
  });
};

export const InvoiceGeneration = (tripId) => {
  let data = JSON.stringify({
    id: tripId,
  });

  var config = {
    method: "post",
    url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/invoice/generate`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status === 201) {
          resolve(response);
          toast.success("Invoice Generated Successfully");
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
          toast.error(error.response.data.detail);
        } else if (error.response.status === 400) {
          toast.error(error.response.data.detail);
        } else if (error.response.status === 401) {
          toast.error("401: Not enough permissions");
        } else {
          toast.error(error.response.data.detail);
        }
        resolve(error.response);
      });
  });
};

export const InvoiceSubmitted = (invoiceSubmissionDate, attachment, tripId) => {
  let formData = new FormData();
  formData.append("file", attachment);
  formData.append("date_of_invoice_submitted", invoiceSubmissionDate);

  var config = {
    method: "patch",
    url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/invoice/submitted`,
    headers: {
      "Content-Type": "application/json",
    },
    data: formData,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
          toast.success("Invoice Submitted Successfully");
        }
      })
      .catch(function (error) {
        if (error.response.status === 422) {
          toast.error(error.response.data.error);
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
          toast.error(error.response.data.error);
        } else if (error.response.status === 404) {
          toast.error(error.response.data.error);
        } else if (error.response.status === 401) {
          toast.error(error.response.data.error);
        } else {
          toast.error(error.response.data.error);
        }
        resolve(error.response);
      });
  });
};

export const InvoiceRejected = (
  rejectedDate,
  remarks,
  tripId,
  invoiceNumber
) => {
  const data = {
    ftl_trip_id: tripId,
    rejected_invoice_number: invoiceNumber,
    rejection_reason: remarks,
    invoice_rejection_date: rejectedDate,
  };

  var config = {
    method: "POST",
    url: `${apiUrl.onboardingBaseUrl}/v1/ftl/rejected-invoices`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 201) {
          resolve(response);
          toast.success("Invoice rejected scessfully");
        }
      })
      .catch(function (error) {
        if (error.response.status === 422) {
          toast.error(error.response.data.error);
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
          toast.error(error.response.data.error);
        } else if (error.response.status === 404) {
          toast.error(error.response.data.error);
        } else if (error.response.status === 401) {
          toast.error(error.response.data.error);
        } else {
          toast.error(error.response.data.detail);
        }
        resolve(error.response);
      });
  });
};

export const InvoiceSent = (
  ackNumber,
  courierCompany,
  invoiceClosedDate,
  tripId
) => {
  let data = JSON.stringify({
    delivery_acknowledgment_number: ackNumber,
    courier_company: courierCompany,
    date_of_invoice_sent: invoiceClosedDate,
  });

  var config = {
    method: "patch",
    url: `${apiUrl.ftlTripV1BaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/invoice/sent`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
          toast.success("Invoice Sent Successfully");
        }
      })
      .catch(function (error) {
        if (error.response.status === 422) {
          toast.error("Unprocessable Entity");
        } else if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
          toast.error("Error Code: 403");
        } else if (error.response.status === 404) {
          toast.error("Error Code: 404");
        } else if (error.response.status === 401) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Error");
        }
        resolve(error.response);
      });
  });
};

export const TripAttachmentPost = (
  image,
  tripId,
  tag,
  attachmentType,
  attachAt,
  attachmentList
) => {
  return attachmentList.map(async (attachment) => {
    let data = new FormData();
    if (!attachment.attachment_file) {
      return;
    }

    data.append("file_1", attachment.attachment_file);
    if (attachment.attachment_tag != "0") {
      data.append("tag_1", attachment.attachment_tag);
    } else {
      data.append("tag_1", "others");
    }

    data.append(
      "attachment_type_1",
      attachment.attachment_file == "application/pdf" ? "pdf" : "image"
    );
    data.append("attach_at", attachAt);
    data.append("trip_id", tripId);

    var config = {
      method: "post",
      url: `${apiUrl.ftlTripAttachmentsUrl}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
    return new Promise((resolve, reject) => {
      axiosInterceptor(config)
        .then(function (response) {
          if (response.status == 201) {
            resolve("success");
            // return 'success'
          }
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            localStorage.clear();
            window.location.reload();
          }

          resolve(error.response);
          // resolve(error);
          //
        });
    });
  });
};

export const GetTripListWithFilter = async (
  page,
  tripCode,
  paymentStatus,
  vehicleNumber,
  tripStatus,
  referenceNumber,
  customerId,
  loadSource,
  ewayBillNo,
  tripType,
  tripSearchValue,
  billToPartyId = ""
) => {
  let appendUrl = "";
  if (tripCode) {
    appendUrl = appendUrl + `&trip_code=${tripCode}`;
  } else if (tripSearchValue) {
    appendUrl = appendUrl + `&trip_code=${tripSearchValue}`;
  }
  if (paymentStatus) {
    appendUrl = appendUrl + `&trip_payment_status=${paymentStatus}`;
  }
  if (vehicleNumber) {
    appendUrl = appendUrl + `&vehicle_number=${vehicleNumber}`;
  }
  if (billToPartyId) {
    appendUrl = appendUrl + `&bill_to_party_id=${billToPartyId}`;
  }
  if (tripStatus) {
    if (
      tripStatus == "invoice_generated" ||
      tripStatus == "invoice_submitted" ||
      tripStatus == "invoice_closed" ||
      tripStatus == "invoice_sent" ||
      tripStatus == "invoice_rejected"
    ) {
      appendUrl = appendUrl + `&zoho_customer_invoice_status=${tripStatus}`;
    } else {
      appendUrl =
        appendUrl +
        `&trip_status=${tripStatus}&zoho_customer_invoice_status=not_invoice`;
    }
  }

  if (tripType) {
    appendUrl = appendUrl + `&trip_type=${tripType}`;
  }
  if (referenceNumber) {
    appendUrl = appendUrl + `&reference_number=${referenceNumber}`;
  }
  if (loadSource) {
    appendUrl = appendUrl + `&load_source=${loadSource}`;
  }
  if (customerId) {
    appendUrl = appendUrl + `&customer_id=${customerId}`;
  }
  if (ewayBillNo) {
    appendUrl = appendUrl + `&eway_bill_number=${ewayBillNo}`;
  }
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.ftlTripV1BaseUrl}?current_page=${page}&page_size=20${appendUrl}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    return e.response.status;
    // throw e;
  }
};

export const GetTripStatusEnums = async () => {
  try {
    const response = await axiosInterceptor.get(`${apiUrl.tripStatusEnumUrl}`);

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    return e.response.status;
    // throw e;
  }
};

export const GetTripPaymentStatusEnums = async () => {
  // try {
  //   const response = await axiosInterceptor.get(
  //     `${apiUrl.paymentStatusEnumUrl}`
  //   );
  //   if (response.status == 200) {
  //     return response.data;
  //   }
  // } catch (e) {
  //   if (e.response.status === 403) {
  //     localStorage.clear();
  //     window.location.reload();
  //   }
  //   return e.response.status;
  //   // throw e;
  // }
};

export const GetCustomerListApi = async () => {
  try {
    const response = await axiosInterceptor.get(`${apiUrl.customerListUrl}`);

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    return e.response.status;
    // throw e;
  }
};
export const GetTripCostApi = async (tripId) => {
  try {
    const response = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/ftl/trip/${prefixSalt()}-${convertToBase64(tripId)}/costs`
    );

    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    return e.response;
    // throw e;
  }
};

export const RecommendRejectPayment = async (status, remarks, id) => {
  let data = JSON.stringify({
    customer_payment_status: status,
    remarks: remarks,
  });
  var config = {
    method: "patch",
    url: `${
      apiUrl.onboardingBaseUrl
    }/v3/invoice-payment-history/${prefixSalt()}-${convertToBase64(id)}/${
      status == "approved"
        ? "approved"
        : status == "rejected"
        ? "rejected"
        : status == "recommended"
        ? "recommended"
        : ""
    }`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
        // resolve(error);
        //
      });
  });
};

export const GetTripLog = async (tripId) => {
  try {
    const response = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/trip-logs/${prefixSalt()}-${convertToBase64(tripId)}/logs`
    );

    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    return e.response;
    // throw e;
  }
};

export const GetTransporterPaymentHistory = async (tripId) => {
  try {
    const response = await axiosInterceptor.get(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/ftl/transporter-payments/${prefixSalt()}-${convertToBase64(
        tripId
      )}/payments`
    );

    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    return e.response;
    // throw e;
  }
};

export const GetEwayBill = async (tripId) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.ewayBillBaseUrl}?current_page=1&page_size=20&trip_id=${tripId}`
    );

    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    return e.response;
  }
};

export const TripLogsPost = (
  trip_id,
  time_of_log,
  district,
  state,
  pincode,
  street_address,
  expected_time_to_destination,
  remarks,
  is_incident_reported,
  incident_type,
  tripStatus
) => {
  let data = {};
  tripStatus === "intransit"
    ? (data = JSON.stringify({
        trip_id: `${trip_id}`,
        time_of_log: `${time_of_log}`,
        district: `${district}`,
        state: `${state}`,
        pincode: `${pincode}`,
        street_address: `${street_address}`,
        expected_time_to_destination: `${expected_time_to_destination}`,
        remarks: `${remarks}`,
        is_incident_reported: is_incident_reported,
        incident_type: incident_type,
        trip_status: tripStatus,
      }))
    : (data = JSON.stringify({
        trip_id: `${trip_id}`,
        remarks: `${remarks}`,
        trip_status: tripStatus,
      }));
  var config = {
    method: "post",
    url: `${apiUrl.onboardingBaseUrl}/v1/trip-logs`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        // if (response.status == 200) {
        resolve(response);
        // }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};

export const TransporterPaymentHistoryPost = (
  trip_id,
  transporter_id,
  utr_reference_number,
  amount_paid,
  remarks,
  image
) => {
  const formData = new FormData();
  formData.append("trip_id", trip_id);
  formData.append("transporter_id", transporter_id);
  formData.append("utr_reference_number", utr_reference_number);
  formData.append("amount_paid", amount_paid);
  formData.append("remarks", remarks);
  formData.append("image", image);
  var config = {
    method: "POST",
    url: `${apiUrl.onboardingBaseUrl}/v1/ftl/transporter-payments`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};
export const GetTripLogEnum = async (tripId) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/enums/trip-log-incident-type`
    );

    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    return e.response;
    // throw e;
  }
};

export const EwayBillChange = (ewayBillData, trip_id) => {
  const data = new FormData();

  data.append("remarks", ewayBillData.remarks);
  data.append("eway_bill_valid_till", ewayBillData.eway_bill_valid_till);
  data.append("trip_status", ewayBillData.trip_status);
  data.append("eway_bill_number", ewayBillData.eway_bill_number);
  if (ewayBillData.eway_bill_valid_from) {
    data.append("eway_bill_valid_from", ewayBillData.eway_bill_valid_from);
  }
  if (ewayBillData.eway_bill_attachment) {
    data.append("eway_bill_attachment", ewayBillData.eway_bill_attachment);
    data.append(
      "eway_bill_attachment_tag",
      ewayBillData.eway_bill_attachment_tag
    );
    data.append(
      "eway_bill_attachment_type",
      ewayBillData.eway_bill_attachment_type
    );
  }
  var config = {
    method: "PATCH",
    url: `${apiUrl.ftlTripV1BaseUrl}/$${prefixSalt()}-${convertToBase64(
      trip_id
    )}/eway-bill-change`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};
