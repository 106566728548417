// 1. Create a context
import React, { createContext, useContext, useEffect, useState } from 'react';
import axiosInterceptor from '../../config/https';
import apiUrl from '../../url/apiUrl';

const MyContext = createContext();

const MyEnumProvider = ({ children }) => {
    const [enums, setEnums] = useState([]);

    // useEffect(() => {
    //     getEnums()
    // }, [])

    // const getEnums = async () => {
    //     var enums = await axiosInterceptor.get(
    //         `${apiUrl.onboardingBaseUrl}/v1/enums/details`
    //     );

    //     enums.data ? setEnums(enums.data) : setEnums([])
    // };

    const contextValue = {
        enums,
        setEnums
    };

    return <MyContext.Provider value={contextValue}>{children}</MyContext.Provider>;
};

export { MyContext, MyEnumProvider }