import React, { useEffect, useState } from "react";
import TRUCK_IMAGE from "../../assets/dashboard_icons/icon-0.8s-215px.png";
import { ReactReduxContext, useDispatch } from "react-redux";
import * as moment from "moment";
import FeedIcon from "@mui/icons-material/Feed";
import apiUrl from "../../url/apiUrl";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import {
  GetDriverDetails,
  GetTripHistory,
  GetTripAttachments,
  GetTripInvoicePaymentByTripId,
  GetTripItemsForDeliveryInfo,
} from "./miniDetailsCardServices";
import { getTripItems } from "../../pages/ftl_trips/deliveryInvoicePayment/DeliveryInvoiceService";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { updateDriverDetails } from "../../redux/tripSlice";
import { Close } from "@mui/icons-material";
import CopyText from "../copyText/CopyText";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";
import { readLoginData, readToken } from "../../helper/dataDecryptionHelper";
import NoDetailsFound from "../../assets/tripIcons/no_data_found.png";
import { Mini_detais_Card_style } from "./mini-details-card.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MiniDetailsCard({ tripDetails }) {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [driverDetails, setDriverDetails] = useState({});

  const [costDetails, setCostDetails] = useState({});
  const [tripCostTotal, setTripCostTotal] = useState("");
  const [tripAdvanceCost, setTripAdvanceCost] = useState("");
  const [tripHistoryList, setTripHistoryList] = useState([]);
  const [quantityDeliveryFromHistory, setQuantityDeliveryFromHistory] =
    useState([]);
  const [paymentCompleteInvoiceList, setPaymentCompleteInvoiceList] = useState(
    []
  );
  const [tripAttachmentList, setTripAttachmentList] = useState([]);
  const [deliverItemList, setDeliverItemList] = useState([]);
  const [deliveryPaymentList, setDeliveryPaymentList] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // let deliverItemTemp = [];

  const getDeliverItems = async (tripCode) => {
    const response = await GetTripItemsForDeliveryInfo(tripCode);
    // deliverItemTemp.push(response);
    // setDeliverItemList(deliverItemTemp);

    setDeliverItemList(
      tripDetails.reference_number.map((item) => {
        return {
          reference_number: item,
          items_list: response.trip_items_list.filter(
            (items) => items.reference_number === item
          ),
        };
      })
    );
    //
    //   "response delivered intems editted>>?>>>",
    //   tripDetails.reference_number.map((item) => {
    //     return {
    //       reference_number: item,
    //       items_list: response.filter(
    //         (items) => items.reference_number === item
    //       ),
    //     };
    //   })
    // );
  };

  useEffect(() => {
    if (tripDetails.reference_number.length > 0) {
      // tripDetails.reference_number.map((item) =>
      //   getDeliverItems(tripDetails.id, item)
      // );
      getDeliverItems(tripDetails.trip_code);
    }
  }, [tripDetails.trip_code]);

  const getInvoicePaymentHistoryApi = async (tripId) => {
    const invoiceResponse = await GetTripInvoicePaymentByTripId(tripId);
    if (invoiceResponse) {
      setDeliveryPaymentList(invoiceResponse.invoice_payments_history_list);
      const dupliceRemovedInvoiceList = [
        ...new Set(
          invoiceResponse.invoice_payments_history_list.map(
            (item) => item.reference_number
          )
        ),
      ];
      if (dupliceRemovedInvoiceList.length > 0) {
        setPaymentCompleteInvoiceList(dupliceRemovedInvoiceList);
      } else {
        setPaymentCompleteInvoiceList([]);
      }
    } else {
      setPaymentCompleteInvoiceList([]);
    }
  };

  useEffect(() => {
    getInvoicePaymentHistoryApi(tripDetails.id);
  }, [tripDetails.id]);

  useEffect(() => {
    if (tripDetails.driver_id) {
      getDriverDetails(tripDetails.driver_id);
    }
    if (tripDetails.trip_code) {
      getTripHistoryDetails(tripDetails.trip_code);
    }
    if (tripDetails.id) {
      getTripAttachmentList(tripDetails.id);
    }
  }, [
    tripDetails.driver_id,
    tripDetails.trip_code,
    tripDetails.trip_history_details,
    tripDetails.id,
  ]);

  const getDriverDetails = async (id) => {
    const response = await GetDriverDetails(id);

    setDriverDetails(response);
    dispatch(updateDriverDetails(response));
  };

  const getTripHistoryDetails = async (trip_code) => {
    // if (tripDetails.trip_history_details.length > 0) {
    //   setTripHistoryList(tripDetails.trip_history_details);
    // }
    const response = await GetTripHistory(trip_code);
    setTripHistoryList(response.trip_history_list);

    setQuantityDeliveryFromHistory(
      response.trip_history_list
        .map((item) => {
          try {
            return JSON.parse(item.remarks)[0];
          } catch (e) {
            return null;
          }
        })
        .filter((item) => item != null)
    );
  };

  useEffect(() => {
    if (tripDetails.trip_cost) {
      if (tripDetails.trip_cost.length > 0) {
        //trip cost
        const tripCostTemp = tripDetails.trip_cost.filter(
          (item) => item.cost_type === "trip_cost"
        );
        if (tripCostTemp.length > 0) {
          setTripCostTotal(tripCostTemp[0].cost_amount);
        } else {
          setTripCostTotal(0);
        }

        //trip advance
        const tripAdvanceTemp = tripDetails.trip_cost.filter(
          (item) => item.cost_type === "advance"
        );
        if (tripAdvanceTemp.length > 0) {
          setTripAdvanceCost(tripAdvanceTemp[0].cost_amount);
        } else {
          setTripAdvanceCost(0);
        }
      } else {
        setTripCostTotal(0);
        setTripAdvanceCost(0);
      }
    }
  }, [tripDetails]);

  const getTripAttachmentList = async (trip_id) => {
    const response = await GetTripAttachments(trip_id);
    setTripAttachmentList(response.trip_attachments_list);
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  let tokensData = readToken();
  const download = async (id) => {
    fetch(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/trip-attachment/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.download = "image.png";
          link.click();
        });
        if (response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      })
      .catch((err) => {});
  };
  const downloadDriverDocs = async (id) => {
    fetch(
      `${
        apiUrl.onboardingBaseUrl
      }/v1/image/driver-licence/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.download = "image.png";
          link.click();
        });
        if (response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      })
      .catch((err) => {});
  };
  return (
    <Mini_detais_Card_style className="mini-details-card-main-container">
      {/* {isMobile ? null : <div className="mini-details-img"></div>} */}

      {/* <img src={Carrier} alt="" width="100%" /> */}

      <div className="mini-details-body">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons={false}
            >
              <Tab label="Trip details" {...a11yProps(0)} />
              <Tab label="Driver" {...a11yProps(1)} />
              <Tab label="Trip history" {...a11yProps(2)} />
              <Tab label="Attachments" {...a11yProps(3)} />
              <Tab label="Delivery info" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="mini-details-tab-container">
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">Trip code</h4>
                <h4 className="mini-details-single-list-right">
                  :{" "}
                  {tripDetails.trip_code ? (
                    <CopyText text={tripDetails.trip_code} />
                  ) : (
                    "-"
                  )}
                </h4>{" "}
              </div>
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">Customer</h4>
                <h4 className="mini-details-single-list-right">
                  :{" "}
                  {tripDetails.customers_details
                    ? tripDetails.customers_details.name
                    : "-"}
                </h4>{" "}
              </div>
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">Source</h4>
                <h4 className="mini-details-single-list-right">
                  :{" "}
                  {tripDetails.load_source
                    ? tripDetails.load_source.split("_").join(" ")
                    : "-"}
                </h4>{" "}
              </div>

              {tripDetails.customers_details?.name
                .toLowerCase()
                .includes("faarms") ? (
                <div className="mini-details-single-list">
                  <h4 className="mini-details-single-list-left">
                    {tripDetails.load_source
                      ? tripDetails.load_source == "trip_purchase_order"
                        ? "Purchase order Id"
                        : tripDetails.load_source == "trip_invoice"
                        ? "Invoice Id"
                        : "Reference Number"
                      : null}{" "}
                    {tripDetails.reference_number
                      ? tripDetails.reference_number.length > 0
                        ? tripDetails.reference_number.map((item, index) => {
                            return (
                              <div
                                style={{
                                  color: "white",
                                  // border: "none",
                                  // outline: "none",
                                }}
                              >
                                {tripDetails.reference_number.length - 1 !=
                                index ? (
                                  <>
                                    {"uyt"} <br></br>
                                  </>
                                ) : null}
                              </div>
                            );
                          })
                        : "-"
                      : null}
                  </h4>
                  <h4 className="mini-details-single-list-right">
                    :{" "}
                    {tripDetails.reference_number
                      ? tripDetails.reference_number.length > 0
                        ? tripDetails.reference_number.map((item, index) => {
                            return (
                              <>
                                {tripDetails.reference_number.length - 1 ==
                                index ? (
                                  <>
                                    {item}
                                    <br></br>
                                  </>
                                ) : (
                                  <>
                                    {item} , <br></br>
                                  </>
                                )}
                              </>
                            );
                          })
                        : "-"
                      : null}
                  </h4>{" "}
                </div>
              ) : null}
              {tripDetails.customers_details?.name
                .toLowerCase()
                .includes("faarms") && tripDetails?.load_source == "manual" ? (
                <div className="mini-details-single-list">
                  <h4 className="mini-details-single-list-left">Entity Type</h4>
                  <h4 className="mini-details-single-list-right">
                    :{" "}
                    {tripDetails.entity_type
                      ? tripDetails.entity_type.split("_").join(" ")
                      : "-"}
                  </h4>{" "}
                </div>
              ) : null}

              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">Trip status</h4>
                <h4 className="mini-details-single-list-right">
                  : {tripDetails.trip_status ? tripDetails.trip_status : "-"}
                </h4>{" "}
              </div>
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">Invoice value</h4>
                <h4 className="mini-details-single-list-right">
                  :{" "}
                  {tripDetails.value_of_goods
                    ? tripDetails.value_of_goods
                    : "-"}
                </h4>{" "}
              </div>

              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">
                  Estimated distance
                </h4>
                <h4 className="mini-details-single-list-right">
                  :{" "}
                  {tripDetails.estimated_kms ? tripDetails.estimated_kms : "-"}{" "}
                  Kms
                </h4>{" "}
              </div>
              {tripDetails.trip_status == "completed" ? (
                <div className="mini-details-single-list">
                  <h4 className="mini-details-single-list-left">
                    Actual distance
                  </h4>
                  <h4 className="mini-details-single-list-right">
                    :{" "}
                    {tripDetails.trip_start_meter_reading &&
                    tripDetails.trip_complete_meter_reading
                      ? tripDetails.trip_complete_meter_reading -
                        tripDetails.trip_start_meter_reading
                      : " - "}{" "}
                    {/* {tripDetails.estimated_kms
                      ? tripDetails.estimated_kms
                      : "-"} */}
                    Kms
                  </h4>{" "}
                </div>
              ) : null}

              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">
                  Total quantity
                </h4>
                <h4 className="mini-details-single-list-right">
                  : {tripDetails.quantity ? tripDetails.quantity : "-"}{" "}
                </h4>{" "}
              </div>
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">
                  Unloaded quantity
                </h4>
                <h4 className="mini-details-single-list-right">
                  :{" "}
                  {tripDetails.unloaded_quantity
                    ? tripDetails.unloaded_quantity
                    : "-"}{" "}
                </h4>{" "}
              </div>
              {tripDetails.trip_eway_bill ? (
                <>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      E-Way Bill No.
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {tripDetails.trip_eway_bill.eway_bill_number ? (
                        <CopyText
                          text={tripDetails.trip_eway_bill.eway_bill_number}
                        />
                      ) : (
                        "-"
                      )}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      E-Way Bill From
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :
                      {tripDetails.trip_eway_bill.eway_bill_valid_from
                        ? tripDetails.trip_eway_bill.eway_bill_valid_from
                        : "-"}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      E-Way Bill Till
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :
                      {tripDetails.trip_eway_bill.eway_bill_valid_till
                        ? tripDetails.trip_eway_bill.eway_bill_valid_till
                        : "-"}
                    </h4>{" "}
                  </div>
                </>
              ) : null}

              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">Per Bag Cost</h4>
                <h4 className="mini-details-single-list-right">
                  : Rs.{" "}
                  {tripCostTotal && tripDetails.unloaded_quantity
                    ? (tripCostTotal / tripDetails.unloaded_quantity).toFixed(2)
                    : tripCostTotal && tripDetails.quantity
                    ? (tripCostTotal / tripDetails.quantity).toFixed(2)
                    : " - "}
                </h4>{" "}
              </div>
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">Payment Terms</h4>
                <h4 className="mini-details-single-list-right">
                  :{" "}
                  {tripDetails.payment_terms
                    ? tripDetails.payment_terms === "to_be_billed"
                      ? "To be billed"
                      : tripDetails.payment_terms === "to_payee"
                      ? "To Pay"
                      : " - "
                    : " - "}{" "}
                </h4>{" "}
              </div>
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">
                  Transport Cost
                </h4>
                <h4 className="mini-details-single-list-right">
                  : Rs. {tripCostTotal ? tripCostTotal : " - "}
                </h4>{" "}
              </div>
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">
                  Transport Advance
                </h4>
                <h4 className="mini-details-single-list-right">
                  : Rs. {tripAdvanceCost ? tripAdvanceCost : " - "}
                </h4>{" "}
              </div>
              {tripDetails.trip_customer_invoice.length > 0 ? (
                <>
                  {" "}
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Advance(Customer)
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      : Rs.{" "}
                      {tripDetails.trip_customer_invoice
                        ? tripDetails.trip_customer_invoice[0]
                            .advance_paid_by_customer
                        : " - "}
                    </h4>{" "}
                  </div>{" "}
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Customer Billed Amt.
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      : Rs.{" "}
                      {tripDetails.trip_customer_invoice
                        ? tripDetails.trip_customer_invoice[0]
                            .customer_billed_amount
                        : " - "}
                    </h4>{" "}
                  </div>
                </>
              ) : null}
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">
                  Start meter reading
                </h4>
                <h4 className="mini-details-single-list-right">
                  :{" "}
                  {tripDetails.trip_start_meter_reading
                    ? tripDetails.trip_start_meter_reading
                    : " - "}
                </h4>{" "}
              </div>
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">
                  Complete meter<br></br> reading
                </h4>
                <h4 className="mini-details-single-list-right">
                  :{" "}
                  {tripDetails.trip_complete_meter_reading
                    ? tripDetails.trip_complete_meter_reading
                    : " - "}
                </h4>{" "}
              </div>
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">Per Km Rate</h4>
                <h4 className="mini-details-single-list-right">
                  : Rs{" "}
                  {tripDetails.per_km_rate ? tripDetails.per_km_rate : " - "}
                </h4>{" "}
              </div>
              <hr></hr>

              <div className="trip-list-address">
                <div className="trip-location-list">
                  {tripDetails.source.map((source) => {
                    return <div className="trip-location-text">From</div>;
                  })}
                  {/* <div className="trip-location-text">From</div> */}
                  {tripDetails.destination.map((destinations) => {
                    return <div className="trip-location-text">To</div>;
                  })}
                </div>
                <div className="trip-list-path">
                  {/* <div className="trip-circle"></div>
                  <div className="trip-rectangle"></div> */}
                  {tripDetails.source.map((source, index) => {
                    return (
                      <>
                        {index == 0 ? null : (
                          <>
                            <div className="trip-circle"></div>
                            <div className="mini-trip-rectangle"></div>
                          </>
                        )}
                      </>
                    );
                  })}
                  {tripDetails.destination.map((destinations) => {
                    return (
                      <>
                        <div className="trip-circle"></div>
                        <div className="mini-trip-rectangle"></div>
                      </>
                    );
                  })}
                  <div className="trip-circle"></div>
                </div>
                <div className="trip-location-list">
                  {/* <div className="trip-location-text"> */}
                  {tripDetails.source.map((source) => {
                    return (
                      <div className="trip-location-text">
                        {source.village === source.district
                          ? source.village
                          : `${source.village},
                          ${source.district}`}{" "}
                        , {source.state}, {source.postcode}
                      </div>
                    );
                  })}

                  {tripDetails.destination.map((destinations) => {
                    return (
                      <div className="trip-location-text">
                        {destinations.village === destinations.district
                          ? destinations.district
                          : `${destinations.village}, ${destinations.district}`}
                        , {destinations.state}, {destinations.postcode}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="mini-details-tab-container">
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">First Name</h4>
                <h4 className="mini-details-single-list-right">
                  : {driverDetails.first_name ? driverDetails.first_name : "-"}
                </h4>{" "}
              </div>

              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">Last name</h4>
                <h4 className="mini-details-single-list-right">
                  : {driverDetails.last_name ? driverDetails.last_name : "-"}
                </h4>{" "}
              </div>
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">Mobile number</h4>
                <h4 className="mini-details-single-list-right">
                  :{" "}
                  {driverDetails.mobile_number ? (
                    <CopyText text={driverDetails.mobile_number} />
                  ) : (
                    "-"
                  )}
                </h4>{" "}
              </div>
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">Pan number</h4>
                <h4 className="mini-details-single-list-right">
                  :{" "}
                  {driverDetails.pan_number ? (
                    <CopyText text={driverDetails.pan_number} />
                  ) : (
                    "-"
                  )}
                </h4>{" "}
              </div>
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">DL number</h4>
                <h4 className="mini-details-single-list-right">
                  :{" "}
                  {driverDetails.driving_licence_number ? (
                    <CopyText text={driverDetails.driving_licence_number} />
                  ) : (
                    "-"
                  )}
                </h4>{" "}
              </div>
              <div className="mini-details-single-list">
                <h4 className="mini-details-single-list-left">
                  Alternate number
                </h4>
                <h4 className="mini-details-single-list-right">
                  :{" "}
                  {driverDetails.alternate_number ? (
                    <CopyText text={driverDetails.alternate_number} />
                  ) : (
                    "-"
                  )}
                </h4>{" "}
              </div>
              <div
                className="mini-details-single-attachment"
                style={{ marginLeft: "5px" }}
              >
                {" "}
                <ul
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <li
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: "500",
                      marginLeft: "5px",
                    }}
                  >
                    Driving Licence
                    <a
                      href="javascript:void(0)"
                      onClick={() => downloadDriverDocs(driverDetails.id)}
                      className="attachment_btn"
                      style={{ fontSize: "10px" }}
                    >
                      <FeedIcon
                      // style={{
                      //   marginRight: "10px",
                      // }}
                      />{" "}
                    </a>
                    {/* <a
                      href={`${apiUrl.onboardingBaseUrl}/v1/image/driver-licence/${driverDetails.id}`}
                      download
                      target="_blank"
                      className="attachment_btn"
                    >
                      <FeedIcon
                        style={{
                          marginRight: "10px",
                        }}
                      />{" "}
                    </a> */}
                  </li>
                </ul>
              </div>
              {/* <div
                className="mini-details-single-attachment"
                style={{ marginLeft: "5px" }}
              >
                {" "}
                <ul
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <li
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: "500",
                      marginLeft: "5px",
                    }}
                  >
                    Pan Card
                    <a
                      href={`${apiUrl.onboardingBaseUrl}/v1/image/driver-pan/${driverDetails.id}`}
                      download
                      target="_blank"
                      className="attachment_btn"
                    >
                      <FeedIcon
                        style={{
                          marginRight: "10px",
                        }}
                      />{" "}
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="mini-details-tab-container-history">
              <div className="mini-details-tab-left-timeline">
                {tripHistoryList.map((item, index) => {
                  return (
                    <>
                      {/* {tripHistoryList.length - 1 == index ? null : ( */}
                      <>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                        <div className="mini-trip-rectangle-timeline"></div>
                      </>
                      {/* )} */}
                    </>
                  );
                })}
              </div>
              <div>
                {tripHistoryList.map((item) => {
                  return (
                    <div className="mini-details-single-history">
                      <h3 className="mini-details-history-head">
                        <div className="mini-trip-circle"></div>{" "}
                        {item.trip_status
                          ? item.trip_status
                              .split("")
                              .map((item, index) => {
                                if (index == 0) {
                                  return item.toUpperCase();
                                } else {
                                  return item;
                                }
                              })
                              .join("")
                          : "-"}
                      </h3>
                      <div className="mini-details-single-list">
                        {/* <h4 className="mini-details-history-left">created</h4> */}
                        <h4 className="mini-details-history-right">
                          <span className="mini-details-black-text">
                            {" "}
                            Remarks :{" "}
                          </span>
                          <span className="mini-details-grey-text">
                            {" "}
                            {item.remarks ? item.remarks : "-"}
                          </span>
                        </h4>{" "}
                      </div>
                      <div className="mini-details-single-list">
                        {/* <h4 className="mini-details-history-left">created</h4> */}
                        <h4 className="mini-details-history-right">
                          <span className="mini-details-black-text">
                            {" "}
                            Last Modified :{" "}
                          </span>
                          <span className="mini-details-grey-text">
                            {item.last_modified_by
                              ? item.last_modified_by
                              : "-"}
                          </span>
                        </h4>{" "}
                      </div>
                      {/* <div className="mini-details-single-list">
                   
                        <h4 className="mini-details-history-right">
                          <span className="mini-details-black-text">
                            {" "}
                            Created by :{" "}
                          </span>
                          <span className="mini-details-grey-text">
                            {item.created_by ? item.created_by : "-"}
                          </span>
                        </h4>{" "}
                      </div> */}
                      <div className="mini-details-single-list">
                        <h4 className="mini-details-history-left">
                          {/* created by */}
                        </h4>
                        <h4 className="mini-details-history-right">
                          <span className="mini-details-black-text">
                            Created on :{" "}
                          </span>
                          <span className="mini-details-grey-text">
                            {item.created_on
                              ? moment
                                  .utc(item.created_on)
                                  .local()
                                  .format("DD-MM-YYYY hh:mm a")
                              : "-"}
                          </span>
                        </h4>{" "}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <div className="mini-details-attachment-list">
              {tripAttachmentList.length > 0 ? (
                tripAttachmentList.map((item) => {
                  return (
                    <div className="mini-details-single-attachment">
                      {" "}
                      <ul
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <li
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            margin: "5px",
                            fontWeight: "500",
                          }}
                        >
                          {item.tag
                            .split("_")
                            .join(" ")
                            .split("")
                            .map((item, index) => {
                              if (index == 0) {
                                return item.toUpperCase();
                              } else {
                                return item;
                              }
                            })
                            .join("")}
                          <a
                            href="javascript:void(0)"
                            onClick={() => download(item.id)}
                            className="attachment_btn"
                            style={{ fontSize: "10px" }}
                          >
                            <FeedIcon
                            // style={{
                            //   marginRight: "10px",
                            // }}
                            />{" "}
                          </a>
                          {/* <a
                            href={`${
                              apiUrl.onboardingBaseUrl
                            }/v1/image/trip-attachment/${prefixSalt()}-${convertToBase64(
                              item.id
                            )}`}
                            download
                            target="_blank"
                            className="attachment_btn"
                          >
                            <FeedIcon
                              style={{
                                marginRight: "10px",
                              }}
                            />{" "}
                          </a> */}
                        </li>
                      </ul>
                    </div>
                  );
                })
              ) : (
                <div className="mini-details-no-attachment">
                  <img src={NoDetailsFound} alt="" width="80px" height="80px" />
                  No attachment found!
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <div className="mini-details-tab-container-history">
              <div style={{ width: "100%" }}>
                {paymentCompleteInvoiceList.length > 0 ? (
                  paymentCompleteInvoiceList.map((item) => {
                    return (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{item ? item : null}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="mini-card-delivery-info-container">
                            <div
                              style={{
                                fontWeight: "500",
                                fontSize: "13px",
                              }}
                            >
                              DELIVERED ITEMS :{" "}
                            </div>
                            <table className="mini-card-delivery-table">
                              <tr className="mini-card-delivery-table-tr">
                                <th className="mini-card-delivery-table-th">
                                  Sl. No.
                                </th>
                                <th className="mini-card-delivery-table-th">
                                  particulars
                                </th>
                              </tr>
                              {deliverItemList.length > 0
                                ? deliverItemList.filter(
                                    (items) => items.reference_number === item
                                  ).length > 0
                                  ? deliverItemList
                                      .filter(
                                        (items) =>
                                          items.reference_number === item
                                      )[0]
                                      .items_list.map((single_item, index) => {
                                        return (
                                          <tr className="mini-card-delivery-table-tr">
                                            <td className="mini-card-delivery-table-td">
                                              <span className="mini-card-table-text-left">
                                                {" "}
                                                {index + 1}
                                              </span>
                                            </td>
                                            <td className="mini-card-delivery-table-td">
                                              <span className="mini-card-table-text-left">
                                                {" "}
                                                ITEM :
                                              </span>{" "}
                                              <span className="mini-card-table-text-right">
                                                {single_item.item_name}
                                              </span>
                                              <br></br>
                                              <span className="mini-card-table-text-left">
                                                QTY :
                                              </span>{" "}
                                              <span className="mini-card-table-text-right">
                                                {single_item.item_qty}
                                              </span>
                                              <br></br>
                                              <span className="mini-card-table-text-left">
                                                {" "}
                                                UNLOADED QTY :
                                              </span>{" "}
                                              <span className="mini-card-table-text-right">
                                                {single_item.unloaded_quantity}
                                              </span>
                                              <br></br>
                                              <span className="mini-card-table-text-left">
                                                RATE :{" "}
                                              </span>{" "}
                                              <span className="mini-card-table-text-right">
                                                Rs. {single_item.item_rate}
                                              </span>
                                            </td>
                                          </tr>
                                        );
                                      })
                                  : null
                                : null}
                            </table>
                          </div>
                          <br></br>
                          <hr></hr>
                          <br></br>
                          <div className="mini-card-delivery-info-container">
                            <h4
                              style={{
                                fontWeight: "500",
                                fontSize: "13px",
                              }}
                            >
                              PAYMENT DETAILS :{" "}
                            </h4>
                            {deliveryPaymentList
                              .filter(
                                (items) => items.reference_number === item
                              )
                              .map((itemss) => {
                                return (
                                  <>
                                    <div className="mini-delivery-payment-details">
                                      <h5>
                                        {itemss.payment_mode
                                          ? itemss.payment_mode
                                              .toUpperCase()
                                              .split("_")
                                              .join(" ")
                                          : null}
                                      </h5>
                                      <h5>
                                        Rs.{" "}
                                        {itemss.amount ? itemss.amount : null}
                                      </h5>
                                    </div>
                                    <div
                                      className="mini-card-row-delivery"
                                      // style={{ flexDirection: "column" }}
                                    >
                                      <h5 className="mini-card-delivery-left">
                                        Reference Id :
                                      </h5>
                                      <h5
                                        className="mini-card-delivery-right"
                                        // style={{ position: "relat" }}
                                      >
                                        {" "}
                                        {itemss.transaction_reference_id
                                          ? itemss.transaction_reference_id
                                          : null}
                                      </h5>
                                    </div>
                                    <div className="mini-details-single-attachment">
                                      {" "}
                                      <ul
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-around",
                                        }}
                                      >
                                        <li
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            margin: "5px",
                                            fontWeight: "500",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {"Attachment"}
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() => download(itemss.id)}
                                            className="attachment_btn"
                                            style={{ fontSize: "10px" }}
                                          >
                                            <FeedIcon
                                            // style={{
                                            //   marginRight: "10px",
                                            // }}
                                            />{" "}
                                          </a>
                                          {/* <a
                                            href={`${
                                              apiUrl.onboardingBaseUrl
                                            }/v1/image/invoice-payment-history/${prefixSalt()}-${convertToBase64(
                                              itemss.id
                                            )}`}
                                            download
                                            target="_blank"
                                            className="attachment_btn"
                                          >
                                            <FeedIcon
                                              style={{
                                                marginRight: "10px",
                                              }}
                                            />{" "}
                                          </a> */}
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <div className="mini-card-not-found">
                    <img
                      src={NoDetailsFound}
                      alt=""
                      width="80px"
                      height="80px"
                    />
                    No details found!
                  </div>
                )}
                {/* {quantityDeliveryFromHistory.length > 0 ? (
                  quantityDeliveryFromHistory.map((item) => {
                    return (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            {item["Invoice Number"]
                              ? item["Invoice Number"]
                              : null}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="mini-card-delivery-info-container">
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Payment mode :
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                {item.payment_mode ? item.payment_mode : null}
                              </h5>
                            </div>
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Total Amount{" "}
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                : Rs. {item.amount ? item.amount : null}
                              </h5>
                            </div>
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Actual quantity
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                :{" "}
                                {item["Total Quantity"]
                                  ? item["Total Quantity"]
                                  : null}
                              </h5>
                            </div>
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Unloaded quantity{" "}
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                :{" "}
                                {item["Unloaded Quantity"]
                                  ? item["Unloaded Quantity"]
                                  : null}
                              </h5>
                            </div>
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Status
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                {" "}
                                : {item["Status"] ? item["Status"] : null}
                              </h5>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <div className="mini-card-not-found">No details found!</div>
                )} */}
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>
    </Mini_detais_Card_style>
  );
}

// {
//   "is_active": true,
//   "id": 1,
//   "last_name": null,
//   "alternate_number": null,
//   "driving_licence_number": "AH7860051",
//   "ccreated_on": "2022-03-11T13:13:17.704420",
//   "modified_on": "2022-03-11T13:13:17.707110",
//   "first_name": "sonali",
//   "mobile_number": "9534939031",
//   "pan_number": "AAAPZ0071C"
// },

// {
//   "source_id": 9,
//   "approx_cost_id": 5,
//   "actual_cost_id": null,
//   "id": 5,
//   "payments_status": "paid",
//   "load_source": "trip_purchase_order",
//   "created_on": "2022-03-14T11:25:26.316874",
//   "load_unit_id": null,
//   "vehicle_number": "KA-29-2125",
//   "driver_id": 5,
//   "destination_id": 10,
//   "is_active": true,
//   "modified_on": "2022-03-14T11:25:26.319086",
//   "trip_distance_in_km": 78.0,
//   "trip_code": "KU0314251647257126",
//   "zoho_id": "670003",
//   "remarks": null,
//   "trip_status": "created",
//   "attachment_count": 0
// },

export default MiniDetailsCard;
