import React, { useCallback, useEffect, useRef } from "react";
import CustomerSidebar from "../../../components/logisticsCustomerComponents/CustomerSidebar/CustomerSidebar";
import { CustomerIndentStyle } from "./customerIndentStyle";
import EDIT_WHITE from "../../../assets/logisticsCustomer/edit_white.svg";
import SEARCH_GRAY from "../../../assets/logisticsCustomer/search_gray.svg";

import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Box,
  CircularProgress,
  Drawer,
  Modal,
  Snackbar,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  fetchIndentListApi,
  getAttachementById,
  markIndentPatch,
} from "./customerIndentServices";
import moment from "moment";
import IndentInfoCard from "../../../components/logisticsCustomerComponents/IndentInfoCard/IndentInfoCard";
import RIghtbarIndent from "../../../components/logisticsCustomerComponents/rightbarIndent/RIghtbarIndent";
import { AcceptQuotesDrawer } from "../../../components/logisticsCustomerComponents/rightbarIndent/rightbarIndentStyle";
import {
  indentId,
  updateOnFlag,
  updateOnFlagUpdate,
} from "../../../redux/indentSlice";
import { useDispatch } from "react-redux";
import CopyText from "../../../components/copyText/CopyText";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import MobileHeaderCustomer from "../../../components/MobileHeader/MobileHeaderCustomer";
const styleQuotationBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  borderRadius: "10px",
  // border: "1px solid #fff",
  boxShadow: 24,
  p: 0,
};
function CustomerIndentsPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const indentSuccess = useSelector((state) => state.indent.flagOn);
  const indentSuccessUpdate = useSelector((state) => state.indent.flagOnUpdate);
  const [currentTab, setCurrentTab] = useState("draft-indents");
  const [allIndentData, setAllIndentData] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [selectedIndent, setSelectedIndent] = useState();
  const [loadingMore, setLoadingMore] = useState(true);
  const [loadingForMarkAsSent, setLoadingForMarkAsSent] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [DrawerOpen, setDrawerOpen] = useState(false);
  const [markSentRemark, setMarkSentRemark] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [errMessage, setErrMessage] = useState("");
  const [errType, setErrType] = useState();
   
  const handleChangeTab = (tabName) => {
    if (!loadingPage) {
      setAllIndentData([]);
      setLoadingPage(true);
      setCurrentTab(tabName);
      setCurrentPage(1);
    }
  };
  const getIndentsList = async (currentTab, currentPage) => {
    if (currentPage == 1) {
      setLoadingPage(true);
    }
    setLoadingMore(true);
    const response = await fetchIndentListApi(currentTab, currentPage);

    if (currentPage > 1) {
      setAllIndentData([...allIndentData, ...response?.indent_list]);
      setLoadingMore(false);
      setIsLastPage(response?.is_last_page);
    } else {
      setAllIndentData(response?.indent_list);
      setSelectedIndent(response?.indent_list[0]);
      setLoadingPage(false);
      setLoadingMore(false);
      setIsLastPage(response?.is_last_page);
    }
  };

  useEffect(() => {
    getIndentsList(currentTab, currentPage);
  }, [currentTab, currentPage]);

  const getStopType = (indentData) => {
    if (indentData?.source?.length > 1) {
      return "Multiple stop";
    }
    if (indentData?.destination?.length > 1) {
      return "Multiple stop";
    }
    return "Single stop";
  };

  const handleCreateIndent = () => {
    dispatch(indentId({}));
    dispatch(updateOnFlagUpdate(false));
    dispatch(updateOnFlag(false));
    history.push("/customer/create-indent");
  };

  const handleMarkIndentAsSent = async () => {
    setLoadingForMarkAsSent(true);
    const response = await markIndentPatch(selectedIndent?.id, markSentRemark);
    if (response.status >= 200 && response.status < 300) {
      setOpenSnackbarSentSucccess(true);
      setLoadingForMarkAsSent(false);
      // setErrMessage("Successfully Mark as Sent");
      setOpenSnackbar(true);
      setErrType("success");
      setMarkSentRemark("");
      setDrawerOpen(false);
      getIndentsList(currentTab, currentPage);
    } else {
      setLoadingForMarkAsSent(false);
      // setErrMessage("Error");
      setOpenSnackbar(true);
      setErrType("error");
    }
  };

  // snackbar

  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "left",
  });

  const { vertical, horizontal } = snackbarPosition;

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // pagination code

  const observer = useRef();
  const lastIndent = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage(currentPage + 1);
          setLoadingMore(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage, currentPage]
  );
  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;
  // const handleQuotes = () => {
  //   history.push("/customer/customer-quotes");
  // };
  const [open, setOpen] = React.useState(false);
  const [quotationDetails, setQuotationDetails] = useState({});
   
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openSnackbarSuccess, setOpenSnackbarSuccess] = useState(false);
  const [openSnackbarSentSucccess, setOpenSnackbarSentSucccess] =
    useState(false);
  const handleCloseSnackbarSuccess = () => {
    setOpenSnackbarSuccess(false);
  };
  const handleCloseSnackbarSent = () => {
    setOpenSnackbarSentSucccess(false);
  };
  useEffect(() => {
    if (indentSuccess == true) {
      setOpenSnackbarSuccess(true);
    } else {
      return;
    }
  }, []);
  useEffect(() => {
    if (indentSuccessUpdate == true) {
      setOpenSnackbarSentSucccess(true);
    } else {
      return;
    }
  }, []);

  return (
    <CustomerIndentStyle>
      <Snackbar
        open={openSnackbarSuccess}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseSnackbarSuccess}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseSnackbarSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Indent created successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSnackbarSentSucccess}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseSnackbarSent}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseSnackbarSent}
          severity="success"
          sx={{ width: "100%" }}
        >
          Indent Updated successfully!
        </Alert>
      </Snackbar>
      <Drawer
        open={DrawerOpen}
        anchor={"right"}
        onClose={() => {
          setDrawerOpen(false);
          setMarkSentRemark("");
        }}
        PaperProps={{
          sx: {
            width: isMobile ? 320 : 440,
          },
        }}
      >
        <AcceptQuotesDrawer className="accept_drawer">
          <div className="accept_drawer_heading">Remarks</div>
          <div className="add_remark">
            <textarea
              name="accept_remark"
              placeholder="Add Remark"
              value={markSentRemark}
              onChange={(e) => setMarkSentRemark(e.target.value)}
            ></textarea>
          </div>
          <div className="accept_button">
            <button
              onClick={() => handleMarkIndentAsSent()}
              disabled={loadingForMarkAsSent == true ? true : false}
            >
              {loadingForMarkAsSent ? (
                <CircularProgress size={20} style={{ color: "white" }} />
              ) : (
                "Mark Sent"
              )}
            </button>
          </div>
        </AcceptQuotesDrawer>
      </Drawer>
      {/* <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={4000}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        key={vertical + horizontal}
      >
        <Alert severity={errType}>{errMessage}</Alert>
      </Snackbar> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={styleQuotationBox}
          style={{ width: isMobile ? "300px" : "700px" }}
        >
          <div className="quotation-details-headings">
            {" "}
            <h4 style={{ textAlign: "center", padding: "10px 0px" }}>
              Quotation Details
            </h4>{" "}
          </div>

          <div
            style={{
              padding: "15px 50px",
              display: "flex",
              width: "100%",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "flex-start" : "center",
              fontSize: isMobile ? "10px" : "14px",
              justifyContent: "space-between",
            }}
          >
            <span className="quotes-text">
              {" "}
              Quotation Code:{" "}
              <span style={{ fontWeight: "bold" }}>
                <CopyText text={quotationDetails?.quotation_code} />{" "}
              </span>{" "}
            </span>
            <span>
              Created On:{" "}
              <span style={{ fontWeight: "bold" }}>
                {/* {moment(quotationDetails?.created_on).format("DD MMM YYYY")} */}
                {moment
                  .utc(quotationDetails?.created_on)
                  .local()
                  .format("DD MMM YYYY hh:mm a")}
              </span>
            </span>
          </div>

          <div
            style={{
              padding: "15px 50px",
              display: "flex",
              width: "100%",
              flexDirection: isMobile ? "column" : "row",
              alignItems: isMobile ? "flex-start" : "center",
              fontSize: isMobile ? "10px" : "14px",
              justifyContent: "space-between",
            }}
          >
            <span>
              <span>
                Quotation Amount:{" "}
                <span style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  Rs. {quotationDetails?.quotation_amount}
                </span>
              </span>
            </span>
            <span>
              <span>
                Advance Amount:
                <span style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  Rs. {quotationDetails?.advance_amount}
                </span>
              </span>
            </span>
          </div>
        </Box>
      </Modal>
      <div style={{ display: "flex" }}>
        {isMobile ? null : <CustomerSidebar />}

        <div className="customerIndent-container">
          {
            isMobile ? (
              <div className="trip-list-mobile-header">
                <MobileHeaderCustomer headerTitle="Indents" />
              </div>
            ) : null
            // <span className="indent-head-text"> Indents</span>
          }
          {isMobile ? (
            <div className="mobile_button">
              {" "}
              <button className="edit-button" onClick={handleCreateIndent}>
                <img src={EDIT_WHITE} alt="edit" /> Create Indent
              </button>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              height: isMobile ? "40px" : "112px",
              background: "#fff",
            }}
          >
            <div className="top-container">
              <div className="tab-menuOption-contain">
                <div
                  className="tab-menu-option"
                  style={
                    currentTab === "draft-indents"
                      ? { color: "#076b38", fontWeight: "600" }
                      : null
                  }
                  onClick={() => handleChangeTab("draft-indents")}
                >
                  Draft
                  {currentTab === "draft-indents" ? (
                    <div className="tab-bottom-rectangle"></div>
                  ) : null}
                </div>
                <div
                  className="tab-menu-option"
                  style={
                    currentTab === "sent-indents"
                      ? { color: "#076b38", fontWeight: "600" }
                      : null
                  }
                  onClick={() => handleChangeTab("sent-indents")}
                >
                  Sent
                  {currentTab === "sent-indents" ? (
                    <div className="tab-bottom-rectangle"></div>
                  ) : null}
                </div>
                <div
                  className="tab-menu-option"
                  style={
                    currentTab === "unfulfilled-indents"
                      ? { color: "#076b38", fontWeight: "600" }
                      : null
                  }
                  onClick={() => handleChangeTab("unfulfilled-indents")}
                >
                  Unfulfilled
                  {currentTab === "unfulfilled-indents" ? (
                    <div className="tab-bottom-rectangle"></div>
                  ) : null}
                </div>
                <div
                  className="tab-menu-option"
                  style={
                    currentTab === "quotaion-accepted-indents"
                      ? { color: "#076b38", fontWeight: "600", width: "350px" }
                      : { width: "350px" }
                  }
                  onClick={() => handleChangeTab("quotaion-accepted-indents")}
                >
                  Fulfilled
                  {currentTab === "quotaion-accepted-indents" ? (
                    <div className="tab-bottom-rectangle"></div>
                  ) : null}
                </div>
              </div>
              <div className="create-indent">
                {
                  isMobile ? null : (
                    <button
                      className="edit-button"
                      onClick={handleCreateIndent}
                    >
                      <img src={EDIT_WHITE} alt="edit" /> Create Indent
                    </button>
                  )
                  // <span className="indent-head-text"> Indents</span>
                }
              </div>
            </div>
          </div>
          <div className="customerIndent-main-body">
            <div className="customerIndent-main-body-left">
              {/* <div className="customerIndent-search-row">
                <div className="customerIndent-search-contain">
                  <img
                    src={SEARCH_GRAY}
                    alt="search"
                    style={{ margin: "3px" }}
                  />
                  <input
                    className="customerIndent-search-input"
                    placeholder="Search"
                  />
                </div>
              </div> */}
              {loadingPage ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <CircularProgress />{" "}
                </div>
              ) : !loadingPage && allIndentData.length === 0 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  No Indents found!
                </div>
              ) : (
                <div className="indentList-container">
                  {allIndentData?.map((indent) => {
                    return (
                      <div
                        className={
                          selectedIndent.id === indent.id
                            ? "intend-card-container indent-selected"
                            : "intend-card-container"
                        }
                        ref={lastIndent}
                        onClick={() => setSelectedIndent(indent)}
                      >
                        <div className="intend-card-top">
                          <div className="intend-number-text">
                            <CopyText
                              text={
                                indent?.indent_code
                                  ? indent?.indent_code
                                  : " - "
                              }
                            />
                            {/* {indent?.indent_code ? indent?.indent_code : " - "} */}
                          </div>
                          <div className="intend-amount-text">
                            {indent?.pickup_date ? (
                              <>
                                <span style={{ color: "black" }}>
                                  Pickup Date :{" "}
                                </span>
                                <span>
                                  {moment
                                    .utc(indent?.pickup_date)
                                    .local()
                                    .format("DD MMM YYYY")}
                                </span>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div>
                          <div>
                            <div className="trip-list-address">
                              <div className="trip-location-list">
                                {indent?.source?.length > 0
                                  ? indent?.source
                                      ?.filter((source, index) => index === 0)
                                      ?.map((source) => {
                                        return (
                                          <div className="trip-location-text">
                                            From
                                          </div>
                                        );
                                      })
                                  : null}
                                {indent?.destination?.length > 0
                                  ? indent?.destination
                                      ?.filter((source, index) => index === 0)
                                      ?.map((destinations) => {
                                        return (
                                          <div className="trip-location-text">
                                            To
                                          </div>
                                        );
                                      })
                                  : null}
                              </div>
                              <div className="trip-list-path">
                                {indent?.source?.length > 0
                                  ? indent?.source
                                      ?.filter((source, index) => index === 0)
                                      ?.map((source, index) => {
                                        return (
                                          <>
                                            {index == 0 ? null : (
                                              <>
                                                <div className="trip-circle"></div>
                                                <div className="mini-trip-rectangle"></div>
                                              </>
                                            )}
                                          </>
                                        );
                                      })
                                  : null}
                                {indent?.destination?.length > 0
                                  ? indent?.destination
                                      ?.filter((source, index) => index === 0)
                                      ?.map((destinations) => {
                                        return (
                                          <>
                                            <div className="trip-circle"></div>
                                            <div className="mini-trip-rectangle"></div>
                                          </>
                                        );
                                      })
                                  : null}
                                <div className="trip-circle"></div>
                              </div>
                              <div className="trip-location-list">
                                {/* <div className="trip-location-text"> */}
                                {indent?.source?.length > 0
                                  ? indent?.source
                                      ?.filter((source, index) => index === 0)
                                      ?.map((source) => {
                                        return (
                                          <div className="trip-location-text">
                                            {source.village === source.district
                                              ? source.village
                                              : `${source.village},
                                    ${source.district}`}{" "}
                                            , {source.state}, {source.postcode}
                                          </div>
                                        );
                                      })
                                  : null}

                                {indent?.destination?.length > 0
                                  ? indent?.destination
                                      ?.filter((source, index) => index === 0)
                                      ?.map((destinations) => {
                                        return (
                                          <div className="trip-location-text">
                                            {destinations.village ===
                                            destinations.district
                                              ? destinations.district
                                              : `${destinations.village}, ${destinations.district}`}
                                            , {destinations.state},{" "}
                                            {destinations.postcode}
                                          </div>
                                        );
                                      })
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div className="indent-stop-text">
                            {getStopType(indent)}
                          </div>
                          <div className="indents-button-divs">
                            {currentTab == "draft-indents" &&
                            indent?.id == selectedIndent.id ? (
                              <button
                                className="indent-status-button"
                                onClick={() => {
                                  setDrawerOpen(true);
                                }}
                              >
                                Mark sent
                              </button>
                            ) : (
                              <div className="indent_card_price">
                                ₹{indent?.value_of_goods}
                              </div>
                            )}
                            {currentTab === "sent-indents" &&
                            indent?.id == selectedIndent.id ? (
                              <div>
                                {indent?.quotations == null ? null : (
                                  <div className="unfulfilled_message">
                                    <p id="sent-idents-quotes">
                                      <button
                                        className="quotaion-button"
                                        onClick={() => {
                                          handleOpen();
                                          setQuotationDetails(
                                            indent?.quotations
                                          );
                                        }}
                                      >
                                        View Quotation
                                      </button>
                                    </p>
                                  </div>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {loadingMore ? (
                    <div className="loadingMore">Loading More ...</div>
                  ) : null}
                </div>
              )}
            </div>
            {isMobile ? null : (
              <RIghtbarIndent
                selectedIndent={selectedIndent}
                currentTab={currentTab}
                // quotations={allIndentData.quotations}
              />
            )}
          </div>
        </div>
      </div>
    </CustomerIndentStyle>
  );
}

export default CustomerIndentsPage;
