// ErrorPage.js

import React from "react";
import { SomethingWentWrongStyle } from "./SomethingWentWrongStyle";
import ErrorPng from "../../assets/error-icon-3.png";
import { readLoginData } from "../../helper/dataDecryptionHelper";
import { ButtonRectangle } from "../../themes/components/button";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Collapse } from "antd";
const { Panel } = Collapse;

const SomethingWentWrong = ({ backButtonLink, buttonText = "Go Back" }) => {
  const errorFromAPIData = JSON.parse(
    sessionStorage.getItem("ERROR_DETAIL_OBJ")
  );

  const regex = /\/v(\d+)\//;
  const match = errorFromAPIData?.endpoint.match(regex);

  var remainingString;
  if (match) {
    const versionNumber = match[1];
    remainingString = errorFromAPIData?.endpoint.split(
      `/v${versionNumber}/`
    )[1];
  } else if (
    errorFromAPIData?.endpoint.includes("https://services.faarms.com/utils")
  ) {
    remainingString = errorFromAPIData?.endpoint.split("/utils")[1];
  } else {
    console.log("Pattern not found.");
  }

  return (
    <SomethingWentWrongStyle className="error-container">
      <div className="error-content">
        <img src={ErrorPng} alt="Error Png" className="error-png" />
        {errorFromAPIData?.statusCode === 422 && (
          <h2>
            We regret to inform you that your submission cannot be processed at
            this time due to incomplete information.
            {/* Please review the following
            required fields and provide the necessary information: */}
          </h2>
        )}
        {errorFromAPIData?.statusCode === 400 && (
          <>
            {" "}
            <h2>There's been an unexpected error. Please try again later.</h2>
            <p>
              If the problem persists, contact our tech support team at
              <a href="mailto:ithelpdesk@faarms.com">ithelpdesk@faarms.com</a>.
            </p>
          </>
        )}
        {errorFromAPIData?.statusCode === 500 && (
          <>
            <h2>Internal Server Error</h2>
            <h4>
              We're sorry, but there was an unexpected error on our server. Our
              team has been alerted, and we're working to fix it. Please try
              again later.
            </h4>
            <p>
              If the problem persists, contact our tech support team at
              <a href="mailto:ithelpdesk@faarms.com"> ithelpdesk@faarms.com</a>.
            </p>
          </>
        )}

        <Link to={backButtonLink}>
          <ButtonRectangle
            className="button-close"
            style={{ marginBottom: "10px" }}
            onClick={() => {
              sessionStorage.setItem("IS_400_ERROR", "false");
              sessionStorage.setItem("ERROR_DETAIL_OBJ", {});
            }}
          >
            {buttonText}
          </ButtonRectangle>
        </Link>
        <Collapse
          defaultActiveKey={["1"]}
          accordion
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "10px",
          }}
        >
          <Panel
            header="Developer Options"
            key="1"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div className="developer_container">
              <div className="developer_content">
                <div className="text-bold">Endpoint :</div>
                <div>/{remainingString}</div>
              </div>
              <div className="developer_content">
                <div className="text-bold">Method :</div>
                <div>{errorFromAPIData?.method.toUpperCase()}</div>
              </div>
              <div className="developer_content">
                <div className="text-bold">Message :</div>
                {errorFromAPIData?.statusCode === 400 && (
                  <div>
                    {errorFromAPIData?.statusCode}{" "}
                    {errorFromAPIData?.errorFromApi?.detail
                      ? errorFromAPIData.errorFromApi.detail
                      : errorFromAPIData?.errorFromApi}
                  </div>
                )}
                {errorFromAPIData?.statusCode === 422 && (
                  <div>
                    {"422 "}
                    {Array.isArray(errorFromAPIData?.errorFromApi?.detail)
                      ? errorFromAPIData?.errorFromApi?.detail?.map(
                          (item1, index) => (
                            <div>
                              {index + 1}
                              {". "}
                              {item1.loc.map((item) => (
                                <>
                                  <span
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item.split("_").join(" ")}{" "}
                                  </span>
                                  - {item1.msg}
                                </>
                              ))}
                            </div>
                          )
                        )
                      : errorFromAPIData?.errorFromApi?.detail}
                  </div>
                )}
                {errorFromAPIData?.statusCode === 500 && (
                  <div>
                    {"500 "}
                    {errorFromAPIData?.errorFromApi || "Internal Server Error"}
                  </div>
                )}
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </SomethingWentWrongStyle>
  );
};

export default SomethingWentWrong;
