import { createContext, useEffect, useState } from "react";

export const FtlTripListContext = createContext();

export const FtlTripListProvider = ({ children }) => {
  const [newFtlListVersion, setNewFtlListVersion] = useState(
    JSON.parse(localStorage.getItem("isNewFtlListVersion")) || false
  );

  const tripListVersionToggle = () => {
    setNewFtlListVersion(!newFtlListVersion);
  };

  useEffect(() => {
    localStorage.setItem("isNewFtlListVersion", newFtlListVersion);
  }, [newFtlListVersion]);

  return (
    <FtlTripListContext.Provider
      value={{ newFtlListVersion, tripListVersionToggle }}
    >
      {children}
    </FtlTripListContext.Provider>
  );
};
