import React from "react";
import apiUrl from "../../url/apiUrl.js";
import axios from "axios";
import { readToken } from "../../helper/dataDecryptionHelper.js";
import axiosInterceptor from "../../config/https.js";
import { argon2id } from "hash-wasm";
// import axiosInterceptor from "../../config/httpsForCustomer.js";

async function argon2HashPassword(user, userPassword) {
  // let decodedUserName = atob(user);
  //
  // setUserNameFromApi(ddecodedUserName);
  const salt = user;
  const hash = await argon2id({
    password: userPassword,
    salt,
    parallelism: 1,
    iterations: 2,
    memorySize: 16,
    hashLength: 16,
    outputType: "encoded",
  });
  //
  return hash;
}

export const LoginPost = (mobile) => {
  let data = JSON.stringify({
    mobile_number: `${mobile}`,
  });
  var config = {
    method: "post",
    url: `${apiUrl.onboardingBaseUrl}/v1/`,
    headers: {
      "Content-Type": "application/json",
      "x-app-token": readToken(),
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        resolve(error);
      });
  });
};

export const getCustomersInfo = async (page) => {
  try {
    const customerResponse = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/customers/profile`
    );

    if (customerResponse.status == 200) {
      return customerResponse;
    }
  } catch (e) {}
};

export const forgetPassword = (email) => {
  let data = JSON.stringify({
    email: `${email}`,
  });
  var config = {
    method: "put",
    url: `${apiUrl.loginUserVerify}/forgot-password`,
    headers: {
      "Content-Type": "application/json",
      // "x-app-token": readToken(),
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        resolve(error.response.status);
      });
  });
};

export const changeUserPassword = async (
  logindata,
  currentPassword,
  newpassword
) => {
  //
  let myData = {};
  if (currentPassword) {
    const argon = await argon2HashPassword(logindata, currentPassword);

    myData["last_password"] = argon;
  }
  let data = JSON.stringify({
    ...myData,
    password: `${newpassword}`,
  });
  var config = {
    method: "put",
    url: `${apiUrl.loginUserVerify}/change-password`,
    headers: {
      "Content-Type": "application/json",
      "x-app-token": readToken(),
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        resolve(error.response);
      });
  });
};
