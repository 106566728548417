import styled from "styled-components";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";

export const Sidebar_style = styled.div`
  width: 100%;
  height: 100%;

  .changePassword {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .new_badge {
    width: 100%;
    padding: 0.3rem 0.4rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .sidebar-categories {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 0.2rem 0.5rem;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.paragraphColor};
    /* color: ${colorPalette.paragraphColor}; */
  }
  .change_password_botton {
    /*border: 1px solid green;
  */
    color: ${colorPalette.secondaryWhite};
    background-color: ${colorPalette.primaryColor};
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  .logout-div {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .sidebar_onboard_wrapper {
    width: 100%;
    height: 100%;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: ${colorPalette.white};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colorPalette.paragraphSecondaryolor};
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: ${colorPalette.boxShadowPrimary};
  }

  ::-webkit-scrollbar-corner {
    background: ${colorPalette.white};
  }
  .sidebar_onboard_Left_box {
    background-color: ${colorPalette.paragraphSecondaryolor};
    flex: 2;
    height: 70%;
  }
  .sidebar_onboard_Right_box {
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

    flex: 8;
    height: 100%;
    box-shadow: ${colorPalette.boxShadowPrimary};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .sidebarToggle_onboard_container {
    background-color: ${colorPalette.white};
    height: 100%;
    width: 100%;
  }
  .sidebarToggle_onboard_wrapper {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .logo_logistics {
    width: 100%;
    height: 4rem;
    display: flex;
  }
  .logo_div {
    width: 100%;
    height: 100%;
  }
  .company_name_div {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .logo {
    width: 100%;
    object-fit: contain;
  }
  .name {
    font-family: ${FontFamily.primaryFontFamily};
    color: ${colorPalette.primaryColor};
    font-size: ${typography.heading2};
    margin-left: 0.5rem;
  }
  .onboarding {
    margin-top: 2rem;
    width: 90%;
    height: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  /* .active{
  background-color: red;
}
.inactive{
  background-color: blue;
} */
  /* .onboarding_name {
  font-family: ${FontFamily.primaryFontFamily} 
  color: #fcf8f8;
  padding: 0.5rem 1.5rem;
  font-size: ${typography.heading3};
  width: 100%;
  text-align: left;
  text-align: start;
  background-color: #067014;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
} */
  .vehicles {
    font-family: ${FontFamily.primaryFontFamily};
    padding: 0.5rem 0.5rem;
    font-size: 0.8rem;
    text-align: left;
    cursor: pointer;
    text-decoration: none;
    font-weight: ${FontWeight.medium};
  }
  .sidebarSingle {
    display: flex;
  }
  .sidebarListItem {
    width: 100%;
    text-align: left;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    display: flex;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .sidebarListItem.active,
  .sidebarListItem:hover {
    background-color: ${colorPalette.secondaryWhite};
    color: ${colorPalette.primaryColor};
    border-left: 5px solid ${colorPalette.primaryColor};
  }

  .link {
    text-decoration: none;
    /* color: ${colorPalette.black}; */
    color: ${(props) =>
      props.mode ? colorPaletteDark.lightBlack : colorPalette.black};
    display: flex;
    align-items: center;
  }
  .sidebar-top-container {
    width: 100%;
  }
  .btn_logout {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    margin-bottom: 25px;
    color: ${colorPalette.paragraphColor};
    width: 100%;
    padding: 1rem 1rem;
    border: none;
    outline: none;
  }
  .btn_logout_div {
    width: 8rem;
    height: 10%;
    background-color: ${colorPalette.secondaryGreen};
  }
  .button_logout {
    background-color: ${colorPalette.white};
    outline: none;
    border: 1px solid ${colorPalette.primaryColor};
    margin-top: 0.8rem;
    padding: 0.2rem 1rem;
    color: ${colorPalette.primaryColor};
    cursor: pointer;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
  .button_logout:hover {
    transition: all 0.5s ease;
    background-color: ${colorPalette.primaryRed};
    color: ${colorPalette.white};
    border: none;
  }
  .user_name {
    color: ${colorPalette.primaryColor};
    font-size: ${typography.heading4};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .sidebar-mobile-navbar-container {
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    width: 0%;
    height: 0px;
  }

  @media only screen and (max-width: 499px) {
    .sidebar_onboard_wrapper {
      width: 100%;
    }
    .btn_logout {
      padding: 10px;
      margin-bottom: 5rem;
    }
    .logo_logistics {
      width: 100%;
      height: 4.5rem;
      display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
    }
    .onboarding {
      margin-top: 2rem;
      width: 100%;
      height: 10%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
    .vehicles {
      padding: 0.8rem 0.4rem;
    }
  }
`;
