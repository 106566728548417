import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { readLoginData } from "./helper/dataDecryptionHelper";

function CustomerLoggedInRoutes({ component: Component, ...restOfProps }) {
  // const loginApp =
  //   readLoginData() !== undefined ? readLoginData().app_name : "";

  const loginApp = localStorage.getItem("loginApp");

  let isAuthenticated = "";

  if (readLoginData()) {
    isAuthenticated = readLoginData().IS_LOGGED_IN;
  } else {
    isAuthenticated = false;
  }
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated && loginApp === "leap-supply-customers" ? (
          <Redirect to="/customer/customer-indents" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default CustomerLoggedInRoutes;
