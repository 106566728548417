import styled from "styled-components";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  typography,
} from "../../themes/theme";

export const DetailOnboardStyle = styled.div`

/* .details_onboard_container { */
  width: 100%;
  height: 100vh;

.card_container_transporter{
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-auto-rows:min-content;
  height: 80vh;
  /* width: 95vw; */
  /* background-color: #0FBC9E; */
  /* width: 98%; */
  /* width: 100%; */
  /* padding: 0px 30px; */
  position: sticky;
  overflow-y: scroll;
  /* grid-template-rows: 50%; */
  gap: 10px;
 
}
.search_container_transporter {
  /* flex: 6; */
  width: 100%;
  height: 10%;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  /* background-color: beige; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.transporter_tabs{
  width: 100%;
  display: flex;
  align-items: center;
  padding: .8rem .2rem;
  
  /* background-color: #016938; */
  justify-content: flex-start;
}
.transporter_singletab{
  padding: .1rem 1rem;
  cursor: pointer;
  /* border: 1px solid green; */
}
.content_div_onboard_list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  /* background-color: #016938; */
  justify-content: flex-start;
  /* gap: 10px; */
}
.name_transporter {
  font-family: "poppins";
  /* color: #016938; */
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  font-size:12px;
  padding: 0 0.6rem;
  /* background-color: #016938; */
  /* margin-left: 0.5rem; */
}
.name_text_onboard_transporter{
  /* font-size: 12px;
  display: flex; */
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;
/* line-height: 24px; */
display: flex;
color: #000000
  /* align-items: center;
  width: 100%; */
  /* justify-content: space-between; */
}
.name_text_onboard_transporter_code{

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.165px;
  
  color: #0FBC9E;
  /* font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 14px;

display: flex;
color: #000000 */

}

.name_text_onboard_transporter_aadhar{
  font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
display: flex;
color: #575757;
  /* font-weight: bold; */
}


.card_transporter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /*height: 100%;
  *//*margin: 1rem auto;
  */padding: 0.6rem;
  border-radius: 0.2rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  /*box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  */background-color: white;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  -ms-border-radius: 0.2rem;
  -o-border-radius: 0.2rem;
  /*background-color: #016938;
  */
}
.details_onboard_wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details_onboard_Left_box {
  flex: 1.7;
  background-color: ${colorPalette.secondaryWhite};
  height: 100%;
  box-shadow: ${colorPalette.boxShadowPrimary};
  margin: 0 auto;
}
.details_onboard_Right_box {
  flex: 9.5;
  /* position: sticky;
    overflow: scroll; */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 90%;
}
.vehicles_container_details {
  width: 95%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: red; */
}
.vehicleName {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.arrow_icon {
  font-size: 2rem;
  color: ${colorPalette.primaryColor};
  font-weight: ${FontWeight.bold};
}
.name_vehicle {
  font-size: 1.3rem;
  font-family: ${FontFamily.primaryFontFamily};
  margin-left: 1rem;
}
.vehicleId {
  display: flex;
  padding: 0 2rem;
  /* width: 4rem; */
  align-items: center;
  justify-content: center;
  /* background-color: red; */
}
.vehicle_id {
  margin-right: 0.5rem;
  font-size: ${typography.heading4};
}
.id_value {
  font-size: ${typography.heading4};
  color: grey;
}
.main_details_container {
  width: 95%;
  margin: 0 auto;
  padding: 1rem 0rem;
}
.Mui-selected {
  background-color: ${colorPalette.primaryColor} !important;
  color: white !important;
  font-family: ${FontFamily.primaryFontFamily};
}
.tab {
  width: 20%;
  font-family: ${FontFamily.primaryFontFamily} !important;
}
.tab1 {
  width: 20%;
  font-family: ${FontFamily.primaryFontFamily} !important;
}
.tab2 {
  width: 20%;
  font-family: ${FontFamily.primaryFontFamily} !important;
}
.tab3 {
  width: 20%;
  font-family: ${FontFamily.primaryFontFamily} !important;
}
.tab4 {
  width: 20%;
  font-family: ${FontFamily.primaryFontFamily} !important;
}
.customer_container {
  width: 100%;
  padding: 1rem 0rem;
  position: sticky;
  height: 500px;
  overflow-y: scroll;
  /* background-color: bisque; */
}
.details_name {
  margin-top: 0.5rem;
  padding: 0 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 10%;
  position: relative;
}
.details_name_head {
  font-size: ${typography.heading4};
}
.details_name_text {
  font-size: ${typography.heading4};
  color: grey;
  margin-left: 3rem;
  position: absolute;
  left: 20rem;
}
.vehicle_card {
  box-shadow: ${colorPalette.boxShadowPrimary};
  width: 80%;
  display: flex;
  /* background-color: #99cc99; */
  height: 35%;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;

}
.vehicles_container {
  width: 100%;
  padding: 1rem 0rem;
  position: sticky;
  height: 500px;
  overflow-y: scroll;
  /* background-color: bisque; */
}
.vehicle_left {
  flex: 6;
  padding: 1rem;
  /* background-color: aliceblue; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.vehicle_right {
  flex: 6;
  padding: 1rem;
  /* background-color: aliceblue; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.vehicles_div {
  /* height: 90%; */
  padding: 0.2rem 0rem;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.vehicles_attributes {
  font-size: ${typography.paragraph};
}
.vehicles_value {
  font-size: ${typography.paragraph};
  color: grey;
  margin-left: 0.5rem;
}
.onboard_btnDiv {
  display: flex;
  flex: 4;
  /* background-color: red; */
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-right: 3rem;
}
.onboard_btn {
  background-color: ${colorPalette.primaryColor};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease all;
  padding: 0.5rem 1rem;
  color: white;
  text-decoration: none;
  /* margin-right: 5.8rem; */
  outline: none;
  border: none;
  box-shadow: ${colorPalette.boxShadowPrimary};
  cursor: pointer;
}
.onboard_btn:hover {
  background-color: ${colorPalette.secondaryGreen};
  color: black;
  border-radius: 1rem;
  transform: scale(1.1);
  transition: 0.3s ease all;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
}

.button {
  display: inline-block;
  border-radius: 4px;
  background-color: ${colorPalette.primaryColor};
  border: none;
  color: ${colorPalette.white};
  text-align: center;
  font-size: ${typography.heading4};
  padding: 0.5rem;
  width: 180px;
  transition: all 0.5s;
  cursor: pointer;
  /* margin: 5px; */
  margin-right: 1rem;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}
.btn_card_vehicle_details {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  margin-right: 2rem;
  height: 2rem;
  border: 2px solid black;
  /*background-color: #9e194a;
  s*/
  color: ${colorPalette.primaryColor};
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
  transition: 0.4s ease all;
  -webkit-transition: 0.4s ease all;
  -moz-transition: 0.4s ease all;
  -ms-transition: 0.4s ease all;
  -o-transition: 0.4s ease all;
}
.btn_card_vehicle_details:hover {
  background-color: ${colorPalette.primaryColor};
  color: ${colorPalette.white};
  outline: none;
  border: none;
  transition: 0.8s ease all;
  -webkit-transition: 0.8s ease all;
  -moz-transition: 0.8s ease all;
  -ms-transition: 0.8s ease all;
  -o-transition: 0.8s ease all;
  transform: translate(1.2);
  -webkit-transform: translate(1.2);
  -moz-transform: translate(1.2);
  -ms-transform: translate(1.2);
  -o-transform: translate(1.2);
}

@media only screen and (max-width: 499px) {
  /* .details_onboard_container { */
  width: 100%;
  height: 100vh;
  /* } */
  .details_onboard_wrapper {
    flex-direction: column;
    height: auto;
  }
  .details_onboard_wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .details_onboard_Left_box {
    flex: 1.7;
    background-color: ${colorPalette.secondaryWhite};
    height: 100%;
    box-shadow: ${colorPalette.boxShadowPrimary};
    margin: 0 auto;
  }
  .details_onboard_Right_box {
    /* flex: 12; */
    /* position: sticky;
      overflow: scroll; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    /* background-color: ${colorPalette.primaryColor}; */
  }
  .vehicles_container_details {
    width: 98%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: red;s */
    /* margin-top: -12rem;
    margin-bottom: -1rem; */
  }
  .vehicleName {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .arrow_icon {
    font-size: 2rem;
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
  }
  .name_vehicle {
    font-size: ${typography.heading5};
    font-family: ${FontFamily.primaryFontFamily};
    margin-left: 1rem;
  }
  .vehicleId {
    display: flex;
    padding: 0 2rem;
    /* width: 4rem; */
    align-items: center;
    justify-content: center;
    /* background-color: red; */
  }
  .vehicle_id {
    margin-right: 0.5rem;
    font-size: ${typography.heading5};
  }
  .id_value {
    font-size: ${typography.heading4};
    color: grey;
  }
  .main_details_container {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0rem;
  }
  .Mui-selected {
    background-color: ${colorPalette.primaryColor} !important;
    color: white !important;
    font-family: ${FontFamily.primaryFontFamily};
  }
  .tab {
    width: 50% !important;
    font-family: ${FontFamily.primaryFontFamily} !important;
  }
  .tab1 {
    width: 20%;
    font-family: ${FontFamily.primaryFontFamily} !important;
  }
  .tab2 {
    width: 20%;
    font-family: ${FontFamily.primaryFontFamily} !important;
  }
  .tab3 {
    width: 100% !important;
    margin-left: 2rem !important;
    font-family: ${FontFamily.primaryFontFamily} !important;
  }
  .tab4 {
    width: 20%;
    font-family: ${FontFamily.primaryFontFamily} !important;
  }
  .customer_container {
    width: 100%;
    padding: 1rem 0rem;
    position: sticky;
    height: 500px;
    overflow-y: scroll;
    background-color: bisque;
  }

  .details_name {
    margin-top: 0.5rem;
    padding: 0 3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 10%;
    position: relative;
  }
  .details_name_head {
    font-size: ${typography.heading4};
  }
  .details_name_text {
    font-size: ${typography.heading4};
    color: grey;
    margin-left: 3rem;
    position: absolute;
    left: 20rem;
  }
  .vehicle_card {
    box-shadow: ${colorPalette.boxShadowPrimary};
    width: 100%;
    display: flex;
    /* background-color: #99cc99; */
    height: 35%;
    align-items: center;
    justify-content: center;
    /* margin: 1rem auto; */
    /* background-color: rgb(220, 184, 184);s */
    /* height: 20%; */
  }
  .vehicles_container {
    width: 100%;
    /* margin-left: -2rem; */
    padding: 1rem 0rem;
    margin: 0 auto;
    position: sticky;
    height: 500px;
    overflow-y: scroll;
    /* background-color: bisque;s */
  }
  .vehicle_left {
    /* margin-left: 3rem; */
    flex: none;
    width: 50%;
    padding: 1rem;
    /* background-color: aliceblue; */

    /* display: flex;
    flex-direction: column;
    align-items: flex-start */
  }
  .vehicle_right {
    flex: none;
    width: 50%;
    /* background-color: aliceblue; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .vehicles_div {
    /* height: 90%; */
    padding: 0.2rem 0rem;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .vehicles_attributes {
    font-size: ${typography.paragraph_secondary};
    /* width: 100%; */
    /* background-color: red ; */
    /* color: #000000; */
  }
  .vehicles_value {
    font-size: ${typography.paragraph_secondary};
    color: grey;
    margin-left: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .onboard_btnDiv {
    display: flex;
    flex: 2;
    /* background-color: red; */
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-right: 0rem;
  }
  .onboard_btn {
    background-color: ${colorPalette.primaryColor};
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease all;
    padding: 0.5rem 1rem;
    color: ${colorPalette.white};
    text-decoration: none;
    /* margin-right: 5.8rem; */
    outline: none;
    border: none;
    box-shadow: ${colorPalette.boxShadowPrimary};
    cursor: pointer;
  }
  .onboard_btn:hover {
    background-color:${colorPalette.secondaryGreen};
    color: ${colorPalette.black};
    border-radius: 1rem;
    transform: scale(1.1);
    transition: 0.3s ease all;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    -ms-border-radius: 1rem;
    -o-border-radius: 1rem;
  }

  .button {
    display: inline-block;
    border-radius: 4px;
    background-color: ${colorPalette.primaryColor};
    border: none;
    color: ${colorPalette.white};
    text-align: center;
    font-size: ${typography.heading4};
    padding: 0.5rem;
    width: 180px;
    transition: all 0.5s;
    cursor: pointer;
    /* margin: 5px; */
    margin-right: 1rem;
  }

  .button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  .button span:after {
    content: "\00bb";
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }

  .button:hover span {
    padding-right: 25px;
  }

  .button:hover span:after {
    opacity: 1;
    right: 0;
  }
  .btn_card_vehicle_details {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    margin-right: 1rem;
    height: 2rem;
    font-size: ${typography.paragraph_secondary};
    border: 2px solid black;
    /*background-color: #9e194a;
    s*/
    color: ${colorPalette.primaryColor};
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    cursor: pointer;
    transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -ms-transition: 0.4s ease all;
    -o-transition: 0.4s ease all;
  }
  .btn_card_vehicle_details:hover {
    background-color: ${colorPalette.primaryColor};
    color: white;
    outline: none;
    border: none;
    transition: 0.8s ease all;
    -webkit-transition: 0.8s ease all;
    -moz-transition: 0.8s ease all;
    -ms-transition: 0.8s ease all;
    -o-transition: 0.8s ease all;
    transform: translate(1.2);
    -webkit-transform: translate(1.2);
    -moz-transform: translate(1.2);
    -ms-transform: translate(1.2);
    -o-transform: translate(1.2);
  }
}`;
/* =========== */
