import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import "./delivery-invoice-payment.js";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import UploadIcon from "@mui/icons-material/Upload";
import { ArrowBack, Close, Add } from "@mui/icons-material";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import UploadImage from "../../../assets/upload.svg";
import { useHistory, useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as moment from "moment";
import { paymentDetails, itemDetails } from "../../../redux/paymentSlice";
import {
  updatePendingTripInvoiceList,
  updateSelectedTrip,
} from "../../../redux/tripSlice.js";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import Snackbar from "@mui/material/Snackbar";
// ---------------------imports for mui table start----------------------
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

//----------------------imports for mui table end------------------------

import {
  TextField,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputAdornment,
  CircularProgress,
  Alert,
  createStyles,
  makeStyles,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { Drawer } from "@mui/material";
import {
  getInvoicePaymentModes,
  getPaymentZohobankAccount,
  getTripItems,
  getTripItemsWithDiscount,
  invoiceSyncZoho,
  TripPaymentPost,
} from "./DeliveryInvoiceService";
import { useSelector, useDispatch } from "react-redux";
import { getTripDetailsFromId } from "./DeliveryInvoiceService";
import { readLoginData } from "../../../helper/dataDecryptionHelper";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Delivery_invoice_payment_main_container,
  Payment_drawer,
} from "./delivery-invoice-payment.js";
import { colorPalette } from "../../../themes/theme.js";
import { object } from "yup";
import { MyContext } from "../../../components/enumContext/EnumContextComponent.jsx";

function DeliveryInvoicePayment() {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { tripId } = useParams();

  const history = useHistory();
  const [state, setState] = useState({ right: false });

  const data = readLoginData();
  const { enums } = useContext(MyContext);

  const vertical = "bottom";
  const horizontal = "right";

  const [itemsPriceList, setItemsPriceList] = useState([]);
  // const [totalAmount, setTotalAmount] = useState("");
  const [paymentMode, setPaymentMode] = useState("cash");
  const [paymentAttachment, setPaymentAttachment] = useState(null);
  const [expanded, setExpanded] = React.useState(false);

  const [transactionId, setTransactionId] = useState("");
  const [tripCostTotal, setTripCostTotal] = useState("");
  const [perBagCost, setPerBagCost] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const invoiceList = useSelector((state) => state.trip.pendingTripInvoiceList);
  const [selectedInvoice, setSelectedInvoice] = useState("0");
  const [selectedReferenceId, setSelectedReferenceId] = useState("");

  const [tripData, setTripData] = useState([]);

  const [errDeliveryInvoice, setErrDeliveryInvoice] = useState("");
  const [errDeliveryPayment, setErrDeliveryPayment] = useState("");
  const [currentImage, setCurrentImage] = useState([]);
  const [errTransactionId, setErrTransactionId] = useState("");
  const [selectedBankObj, setSelectedBankObj] = useState([]);
  const [invoicePaymentModeList, setInvoicePaymentModeList] = useState([]);

  const [paymentStatus, setPaymentStatus] = useState(false);
  const [selectedDiscountDetails, setSelectedDiscountDetails] = useState({});
  const [selectedDeliveryCharges, setSelectedDeliveryCharges] = useState({});
  const [allDiscountArray, setAllDiscountArray] = useState([]);
  const [allDeliveryChargesArray, setAllDeliveryChargesArray] = useState([]);

  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loadingPaymentHistory, setLoadingPaymentHistory] = useState(false);
  const [selectedBankModes, setSelectedBankModes] = useState([]);
  const [showSaveButton, setShowSaveButton] = useState(false);

  const runningEnvironment = process.env.REACT_APP_NODE_ENV;

  console.log(runningEnvironment, "runningEnvironment");

  const getTripStatus = () => {
    const tripPickedStaus = allDeliveryChargesArray.find(
      (data) => data.additional_data.reference_number == selectedInvoice
    );
    return tripPickedStaus?.status;
  };

  useEffect(() => {
    if (selectedInvoice) {
      getTripStatus();
    }
  }, [selectedInvoice]);

  const [loadingSync, setLoadingSync] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [zohoBankAccountList, setZohoBankAccountList] = useState([]);
  const [mappedAccountListAndModes, setMappedAccountListAndModes] = useState(
    []
  );

  const [syncToastMessage, setSyncToastMessage] = useState({
    message: "",
    status: "",
  });

  const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState("");
  const [deliveryPaymentDetailsArray, setDeliveryPaymentDetailsArray] =
    useState([
      {
        particular_payment_mode: "",
        particular_amount: "",
        particular_transaction_reference_id: "",
        particular_attachment: null,
        zoho_books_account_name: "",
        ledger_name_in_zoho: "",
        account_id: "",
        account_code: "",
      },
    ]);

  const [errDeliveryPaymentArray, setErrDeliveryPaymentArray] = useState([
    {
      err_particular_payment_mode: "",
      err_particular_amount: "",
      err_particular_transaction_reference_id: "",
    },
  ]);
  const { selectedTrip } = useSelector((state) => state.trip);

  const reduceSum = (a, b) => {
    return (
      a.item_rate * a.unloaded_quantity + b.item_rate * b.unloaded_quantity
    );
  };

  // =========================================================================
  // const paymentHistory = [
  //   {
  //     id: 377,
  //     zoho_invoice_id: 356,
  //     zoho_books_account_name: "Petty Cash",
  //     zoho_books_account_id: "1457992000000000459",
  //     amount: 50.0,
  //     payment_mode: "cash",
  //     transaction_reference_id: "50",
  //     image: "AM1005501696480367_payments_cash_1696481562.jpeg",
  //     path: "invoice-payment-history/AM1005501696480367/",
  //     bucket: "faarms-logistics-dev",
  //     remarks: "",
  //     is_active: true,
  //     created_on: "2023-10-05T04:52:42.683395",
  //     modified_on: "2023-10-05T04:56:37.150762",
  //     submitted_by: "",
  //     recommended_by: null,
  //     approved_by: null,
  //     reference_number: "INV-000022",
  //     customer_payment_status: "rejected",
  //   },
  //   {
  //     id: 378,
  //     zoho_invoice_id: 356,
  //     zoho_books_account_name: "Petty Cash",
  //     zoho_books_account_id: "1457992000000000459",
  //     amount: 50.0,
  //     payment_mode: "credit_card",
  //     transaction_reference_id: "oiu89",
  //     image: "AM1005501696480367_payments_credit_card_1696481562.jpeg",
  //     path: "invoice-payment-history/AM1005501696480367/",
  //     bucket: "faarms-logistics-dev",
  //     remarks: "",
  //     is_active: true,
  //     created_on: "2023-10-05T04:52:42.880582",
  //     modified_on: "2023-10-05T05:00:11.843417",
  //     submitted_by: "",
  //     recommended_by: null,
  //     approved_by: null,
  //     reference_number: "INV-000022",
  //     customer_payment_status: "rejected",
  //   },
  //   {
  //     id: 379,
  //     zoho_invoice_id: 356,
  //     zoho_books_account_name: "Petty Cash",
  //     zoho_books_account_id: "1457992000000000459",
  //     amount: 20.0,
  //     payment_mode: "cash",
  //     transaction_reference_id: "1234fd",
  //     image: "AM1005501696480367_payments_cash_1696482058.jpeg",
  //     path: "invoice-payment-history/AM1005501696480367/",
  //     bucket: "faarms-logistics-dev",
  //     remarks: "",
  //     is_active: true,
  //     created_on: "2023-10-05T05:00:59.146234",
  //     modified_on: "2023-10-05T05:03:54.655891",
  //     submitted_by: "rajeev123@yopmail.com",
  //     recommended_by: "rajeev123@yopmail.com",
  //     approved_by: "rajeev123@yopmail.com",
  //     reference_number: "INV-000022",
  //     customer_payment_status: "approved",
  //   },
  //   {
  //     id: 380,
  //     zoho_invoice_id: 356,
  //     zoho_books_account_name: "Petty Cash",
  //     zoho_books_account_id: "1457992000000000459",
  //     amount: 30.0,
  //     payment_mode: "cash",
  //     transaction_reference_id: "1234fd",
  //     image: "AM1005501696480367_payments_cash_1696482058.jpeg",
  //     path: "invoice-payment-history/AM1005501696480367/",
  //     bucket: "faarms-logistics-dev",
  //     remarks: "",
  //     is_active: true,
  //     created_on: "2023-10-05T05:00:59.146234",
  //     modified_on: "2023-10-05T05:03:54.655891",
  //     submitted_by: "rajeev123@yopmail.com",
  //     recommended_by: "rajeev123@yopmail.com",
  //     approved_by: "rajeev123@yopmail.com",
  //     reference_number: "INV-000022",
  //     customer_payment_status: "approved",
  //   },
  //   {
  //     id: 390,
  //     zoho_invoice_id: 356,
  //     zoho_books_account_name: "Petty Cash",
  //     zoho_books_account_id: "1457992000000000459",
  //     amount: 10.0,
  //     payment_mode: "cash",
  //     transaction_reference_id: "1234fd",
  //     image: "AM1005501696480367_payments_cash_1696482058.jpeg",
  //     path: "invoice-payment-history/AM1005501696480367/",
  //     bucket: "faarms-logistics-dev",
  //     remarks: "",
  //     is_active: true,
  //     created_on: "2023-10-05T05:00:59.146234",
  //     modified_on: "2023-10-05T05:03:54.655891",
  //     submitted_by: "rajeev123@yopmail.com",
  //     recommended_by: "rajeev123@yopmail.com",
  //     approved_by: "rajeev123@yopmail.com",
  //     reference_number: "INV-000022",
  //     customer_payment_status: "approved",
  //   },
  //   // {
  //   //   id: 391,
  //   //   zoho_invoice_id: 356,
  //   //   zoho_books_account_name: "Petty Cash",
  //   //   zoho_books_account_id: "1457992000000000459",
  //   //   amount: 40.0,
  //   //   payment_mode: "cash",
  //   //   transaction_reference_id: "1234fd",
  //   //   image: "AM1005501696480367_payments_cash_1696482058.jpeg",
  //   //   path: "invoice-payment-history/AM1005501696480367/",
  //   //   bucket: "faarms-logistics-dev",
  //   //   remarks: "",
  //   //   is_active: true,
  //   //   created_on: "2023-10-05T05:00:59.146234",
  //   //   modified_on: "2023-10-05T05:03:54.655891",
  //   //   submitted_by: "rajeev123@yopmail.com",
  //   //   recommended_by: "rajeev123@yopmail.com",
  //   //   approved_by: "rajeev123@yopmail.com",
  //   //   reference_number: "INV-000022",
  //   //   customer_payment_status: "approved",
  //   // },
  // ];

  const approvedPayment = paymentHistory.filter(
    (item) => item.customer_payment_status !== "rejected"
  );

  const calulateApprovedPaymentTotal = approvedPayment.reduce((acc, item) => {
    return acc + item.amount;
  }, 0);

  // =============================================================================

  const isPaymentFormValid = (paymentArray) => {
    if (
      // paymentArray[0].zoho_books_account_name == "" ||
      paymentArray[0].particular_payment_mode == "0" ||
      paymentArray[0].particular_amount == "" ||
      paymentArray[0].particular_attachment == null ||
      paymentArray[0].particular_transaction_reference_id == ""
    ) {
      // return false;
      setErrDeliveryPayment("*Please Fill All Required Fields.");
    } else {
      // bank account validation
      // const isBankValid = paymentArray
      //   .filter((data) => data.particular_amount !== "")
      //   .map((item) => {
      //     if (item.zoho_books_account_name === "0") {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   });

      // if (isBankValid.indexOf(false) >= 0) {
      //   setErrDeliveryPayment("Please select Bank Account.");
      //   return false;
      // }

      // if (isPaymentModeValid.indexOf(false) >= 0) {
      //   setErrDeliveryPayment("Please select payment mode");
      //   return false;
      // }
      //amount validation
      const isAmountValid = paymentArray
        .filter((data) => data.particular_amount !== "")
        .map((item) => parseFloat(item.particular_amount))
        .reduce((a, b) => a + b);

      if (
        totalAmountAfterDiscount - calulateApprovedPaymentTotal !=
        isAmountValid
      ) {
        setErrDeliveryPayment("Entered amount is not equal to total amount");
        return false;
      }

      //payment mode validation
      // const isPaymentModeValid = paymentArray
      //   .filter((data) => data.particular_amount !== "")
      //   .map((item) => {
      //     if (item.particular_payment_mode === "0") {
      //       return false;
      //     } else {
      //       return true;
      //     }
      //   });
      // //
      // if (isPaymentModeValid.indexOf(false) >= 0) {
      //   setErrDeliveryPayment("Please select payment mode");
      //   return false;
      // }

      //reference id validation

      // const isReferenceIdValid = paymentArray
      //   .filter((data) => data.particular_amount !== "")
      //   .map((item) => {
      //     if (item.particular_payment_mode === "cash") {
      //       return true;
      //     } else if (item.particular_transaction_reference_id) {
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   });

      // if (isReferenceIdValid.indexOf(false) >= 0) {
      //   setErrDeliveryPayment("Please enter valid reference number");
      //   return false;
      // }

      // image size valid
      const isImageSizeValid = paymentArray
        .filter((data) => data.particular_amount !== "")
        .map((item) => {
          if (!item.particular_attachment) {
            return;
          } else if (
            item.particular_attachment.size >
            process.env.REACT_APP_MAX_FILE_SIZE
          ) {
            return false;
          } else {
            return true;
          }
        });

      if (isImageSizeValid.indexOf(false) >= 0) {
        // setValidImageSize(false);
        setErrDeliveryPayment("Please select file with size less than 2MB");
        return false;
      }

      // attachment valid
      const isAttachmentValid = paymentArray
        .filter((data) => data.particular_amount !== "")
        .map((item) => {
          if (
            item.particular_attachment.type == "image/png" ||
            item.particular_attachment.type == "image/jpeg" ||
            item.particular_attachment.type == "image/jpg"
          ) {
            return true;
          } else {
            return false;
          }
        });

      if (isAttachmentValid.indexOf(false) >= 0) {
        // setValidImageFormat(false);
        setErrDeliveryPayment("Only Image/png files are allowed");
        return false;
      }

      // comment in the end

      return true;
    }
  };

  const resetForm = () => {
    setTransactionId("");
    setPaymentAttachment(null);
    setErrDeliveryPayment("");
    setDeliveryPaymentDetailsArray([
      {
        particular_payment_mode: "",
        particular_amount: "",
        particular_transaction_reference_id: "",
        particular_attachment: null,
        zoho_books_account_name: "",
        account_code: "",
        ledger_name_in_zoho: "",
      },
    ]);
    setErrDeliveryPaymentArray([
      {
        err_particular_payment_mode: "",
        err_particular_amount: "",
        err_particular_transaction_reference_id: "",
      },
    ]);
  };

  // const [invoiceList, setInvoiceList] = useState([
  //   "PB-22-23-0001441",
  //   "PB-22-23-0001440",
  // ]);

  useEffect(() => {
    if (Object.keys(selectedTrip).length === 0) {
      getTripDetails(tripId);
    }
  }, [selectedTrip, tripId]);

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const getTripDetails = async (id) => {
    const response = await getTripDetailsFromId(id);

    dispatch(updateSelectedTrip(response));
    dispatch(updatePendingTripInvoiceList(response.pending_reference_numbers));
  };

  //

  const handleSyncZoho = async () => {
    setLoadingSync(true);
    const response = await invoiceSyncZoho(
      selectedInvoice,
      selectedReferenceId,
      tripId
    );

    if (response) {
      if (response.status >= 200 && response.status <= 300) {
        const response1 = await getTripItemsDiscount(selectedTrip?.trip_code);
        await getDeliverydata(selectedInvoice);
        // if (response1.status >= 200 && response1.status <= 300) {
        //   const response2 = await getDeliverydata(selectedInvoice);
        // }
        setOpenToast(true);
        setSyncToastMessage({
          message: "Resync Successful",
          status: "success",
        });
      } else if (response.status === 400) {
        setOpenToast(true);
        setSyncToastMessage({
          message: response?.data?.detail
            ? response.data.detail
            : "Bad request",
          status: "error",
        });
      } else if (response.status === 401) {
        setOpenToast(true);
        setSyncToastMessage({
          message: "401: Not enough permissions",
          status: "error",
        });
      } else {
        setOpenToast(true);
        setSyncToastMessage({
          message: `${response?.status} : ERROR`,
          status: "error",
        });
      }
    }
    setLoadingSync(false);
  };

  const handleChangeDeliveryPayment = (e, index) => {
    const key = e.target.name;
    const value = e.target.value;
    let deliveryPaymentArrayTemp = [...deliveryPaymentDetailsArray];
    deliveryPaymentArrayTemp[index][key] = value;
    setDeliveryPaymentDetailsArray(deliveryPaymentArrayTemp);

    setErrDeliveryPayment("");
  };
  const handleChangeBankName = (e, index, bankList, bankStaticData) => {
    const key = e.target.name;
    let newArr = [];
    const value = e.target.value;
    let bankPaymentArrayTemp = [...deliveryPaymentDetailsArray];
    bankPaymentArrayTemp[index][key] = value;
    setDeliveryPaymentDetailsArray(bankPaymentArrayTemp);
    const filtredBankList = bankList.filter(
      (data) => data.account_name == value
    );
    if (filtredBankList.length > 0) {
      let bankPaymentArrayTempId = [...deliveryPaymentDetailsArray];
      bankPaymentArrayTempId[index]["zoho_books_account_id"] =
        filtredBankList[0].account_id;
      setDeliveryPaymentDetailsArray(bankPaymentArrayTempId);
    }

    const selectedBankObj = bankStaticData.filter(
      (option) => option.bankName === value
    );
    setSelectedBankObj(selectedBankObj);
    const selectedBankObjFronMappedBankObj = mappedAccountListAndModes.filter(
      (option) => option.zoho_books_account_name === value
    );

    newArr = deliveryPaymentDetailsArray.map((ele) => {
      if (ele.zoho_books_account_name === selectedBankObj[0].bankName) {
        return {
          ...ele,
          ["ledger_name_in_zoho"]: selectedBankObj[0].ledger_name_in_zoho,
          ["account_code"]: selectedBankObjFronMappedBankObj[0].account_code,
        };
      } else {
        return ele;
      }
    });

    if (newArr.length) {
      setDeliveryPaymentDetailsArray(newArr);
    }
  };

  const handleAddDeliveryPayment = () => {
    let paymentArrayTemp = [...deliveryPaymentDetailsArray];
    paymentArrayTemp.push({
      particular_payment_mode: "0",
      particular_amount: "",
      particular_transaction_reference_id: "",
      particular_attachment: null,
    });
    setDeliveryPaymentDetailsArray(paymentArrayTemp);
    setValidImageFormat(false);
    setValidImageSize(false);
  };

  const handleRemovePayment = (index) => {
    let paymentArrayTemp = [...deliveryPaymentDetailsArray];
    paymentArrayTemp.splice(index, 1);
    setDeliveryPaymentDetailsArray(paymentArrayTemp);
  };

  useEffect(() => {
    getInvoicePaymentList();
  }, []);

  const getInvoicePaymentList = async () => {
    const response = await getInvoicePaymentModes();
    setInvoicePaymentModeList(response);
  };

  const handleChangeUnloadedQuantity = (e, index) => {
    let tripItemsTemp = [...tripData];

    tripItemsTemp[index]["blocked_quantity_for_invoice_payment"] = parseInt(
      e.target.value
    );
    // tripItemsTemp[index]["unloaded_quantity"] = parseInt(0);

    setTripData(tripItemsTemp);

    setErrDeliveryInvoice("");
  };

  // useEffect(() => {
  //   if (invoiceList.length === 0) {
  //     getTripDetails(tripId);
  //   }
  // }, [invoiceList, tripId]);

  useEffect(() => {
    if (selectedTrip.trip_cost) {
      if (selectedTrip.trip_cost.length > 0) {
        //trip cost
        const tripCostTemp = selectedTrip.trip_cost.filter(
          (item) => item.cost_type === "trip_cost"
        );
        if (
          tripCostTemp.length > 0 &&
          selectedTrip.blocked_quantity_for_invoice_payment
        ) {
          setTripCostTotal(tripCostTemp[0].cost_amount);
          setPerBagCost(
            (
              tripCostTemp[0].cost_amount /
              selectedTrip.blocked_quantity_for_invoice_payment
            ).toFixed(2)
          );
        } else if (tripCostTemp.length > 0 && selectedTrip.quantity) {
          setTripCostTotal(tripCostTemp[0].cost_amount);
          setPerBagCost(
            (tripCostTemp[0].cost_amount / selectedTrip.quantity).toFixed(2)
          );
        }
      }
    }
  }, [selectedTrip]);

  const handleGoToTrips = () => {
    history.push("/faarms-trips");
  };

  const handleOpenMenu = () => {
    if (totalAmountAfterDiscount > 0) {
      toggleDrawer("right", true);
    } else {
      setErrDeliveryInvoice("Please select valid quantity");
    }
  };

  const handlePayNow = async () => {
    //
    if (isPaymentFormValid(deliveryPaymentDetailsArray)) {
      setLoadingButton(true);

      const response = await TripPaymentPost(
        paymentMode,
        // totalAmountAfterDiscount,
        totalAmountAfterDiscount - calulateApprovedPaymentTotal,
        selectedInvoice,
        paymentAttachment,
        transactionId,
        // tripData,
        // tripData.forEach((object) => {
        //   object.unloaded_quantity = 0;
        // }),
        tripData.map(function (item) {
          delete item.unloaded_quantity;
          return item;
        }),
        deliveryPaymentDetailsArray
      );
      if (response.status === 200) {
        setLoadingButton(false);
        resetForm();
        setPaymentStatus(true);
        setTripData([]);
        // setTotalAmount("");
        handleClosePaymentDrawer();
        getTripDetails(tripId);
        setValidImageSize(false);
        setValidImageFormat(false);
        getDeliverydata(selectedInvoice);
        // dispatch(updatePendingTripInvoiceList(["MP-22-23-0001695"]));
      } else if (response.status === 401) {
        setLoadingButton(false);
        setErrDeliveryPayment("401: Not enough permissions");
      } else if (response.status === 400) {
        setLoadingButton(false);
        setErrDeliveryPayment(response.data.detail);
      } else if (response.status === 404) {
        setLoadingButton(false);
        setErrDeliveryPayment(response.data.detail);
      } else {
        setLoadingButton(false);
        setErrDeliveryPayment(`${response.status} : ERROR`);
      }
    } else {
      setErrTransactionId("Please enter valid Transaction Id");
    }
  };

  const toggleDrawer = (anchor, open) => {
    //
    setState({ ...state, [anchor]: open });
  };
  const [validImageSize, setValidImageSize] = useState(false);
  const [validImageFormat, setValidImageFormat] = useState(false);

  const handlePaymentAttachment = (event, index) => {
    //
    // const filtred = deliveryPaymentDetailsArray.filter(
    //   (item) => item.index == index
    // );
    console.log(index, "dfdfd");

    let deliveryPaymentTemp = [...deliveryPaymentDetailsArray];
    deliveryPaymentTemp[index]["particular_attachment"] = event.target.files[0];
    // setPaymentAttachment(event.target.files[0]);
    setDeliveryPaymentDetailsArray([...deliveryPaymentTemp]);
    setErrDeliveryPayment("");
    setCurrentImage([...deliveryPaymentTemp]);

    const isImageSizeValid = deliveryPaymentTemp
      .filter((data) => data.particular_amount !== "")
      .map((item) => {
        if (!item.particular_attachment) {
          return;
        } else if (
          item.particular_attachment.size > process.env.REACT_APP_MAX_FILE_SIZE
        ) {
          return false;
        } else {
          return true;
        }
      });

    if (isImageSizeValid.indexOf(false) >= 0) {
      setValidImageSize(false);
      setErrDeliveryPayment("Please select file with size less than 2MB");
      return false;
    } else {
      setValidImageSize(true);
    }

    // attachment valid
    const isAttachmentValid = deliveryPaymentTemp
      .filter((data) => data.particular_amount !== "")
      .map((item) => {
        if (
          item.particular_attachment.type == "image/png" ||
          item.particular_attachment.type == "image/jpeg" ||
          item.particular_attachment.type == "image/jpg"
        ) {
          return true;
        } else {
          return false;
        }
      });

    if (isAttachmentValid.indexOf(false) >= 0) {
      setValidImageFormat(false);
      setErrDeliveryPayment("Only Image/png files are allowed");
      return false;
    } else {
      setValidImageFormat(true);
    }
  };

  useEffect(() => {
    if (tripData.length > 0) {
      setItemsPriceList(
        tripData.map((item) =>
          item.blocked_quantity_for_invoice_payment
            ? item.blocked_quantity_for_invoice_payment
            : 0 * item.item_rate
        )
      );

      if (
        selectedDiscountDetails.discount_amount &&
        tripData
          .map(
            (item) =>
              (item.blocked_quantity_for_invoice_payment
                ? item.blocked_quantity_for_invoice_payment
                : 0) * item.item_rate
          )
          .reduce((a, b) => a + b)
      ) {
        setTotalAmountAfterDiscount(
          tripData
            .map(
              (item) =>
                (item.blocked_quantity_for_invoice_payment
                  ? item.blocked_quantity_for_invoice_payment
                  : 0) * item.item_rate
            )
            .reduce((a, b) => a + b) +
            (selectedDeliveryCharges.delivery_charges
              ? selectedDeliveryCharges.delivery_charges
              : 0) -
            selectedDiscountDetails.discount_amount
        );
      } else if (
        !tripData
          .map(
            (item) =>
              (item.blocked_quantity_for_invoice_payment
                ? item.blocked_quantity_for_invoice_payment
                : 0) * item.item_rate
          )
          .reduce((a, b) => a + b)
      ) {
        setTotalAmountAfterDiscount(
          tripData
            .map(
              (item) =>
                (item.blocked_quantity_for_invoice_payment
                  ? item.blocked_quantity_for_invoice_payment
                  : 0) * item.item_rate
            )
            .reduce((a, b) => a + b)
        );
      } else {
        setTotalAmountAfterDiscount(
          tripData
            .map(
              (item) =>
                (item.blocked_quantity_for_invoice_payment
                  ? item.blocked_quantity_for_invoice_payment
                  : 0) * item.item_rate
            )
            .reduce((a, b) => a + b) +
            (selectedDeliveryCharges.delivery_charges
              ? selectedDeliveryCharges.delivery_charges
              : 0)
        );
      }
    }
  }, [tripData, selectedDiscountDetails, selectedDeliveryCharges]);

  const handleClosePaymentDrawer = () => {
    toggleDrawer("right", false);
    resetForm();
    setValidImageSize(false);
    setValidImageFormat(false);
  };

  const getDeliverydata = async (getInvoice) => {
    setLoadingPaymentHistory(true);
    const responseDelivery = await getTripItems(tripId, getInvoice);

    if (responseDelivery) {
      setLoadingPaymentHistory(false);
      setPaymentHistory(responseDelivery.invoice_payment_history);
      setTripData(
        responseDelivery.trip_items.map((item) => ({
          ...item,
          blocked_quantity_for_invoice_payment: item.item_qty,
        }))
      );
      const filteredData = allDiscountArray.filter((item) => {
        return item.reference_number === getInvoice;
      });
      const filteredDeliveryData = allDeliveryChargesArray.filter((item) => {
        return item.additional_data.reference_number === getInvoice;
      });
      if (filteredData.length > 0) {
        setSelectedDiscountDetails(filteredData[0]);
      }
      if (filteredDeliveryData.length > 0) {
        setSelectedDeliveryCharges(filteredDeliveryData[0]);
      }
      // setSelectedDeliveryCharges()
      if (responseDelivery.trip_items.length > 0) {
        setSelectedReferenceId(
          responseDelivery.trip_items[0]?.reference_source_id
        );
      }
    }
  };

  const getTripItemsDiscount = async (tripCode) => {
    const response = await getTripItemsWithDiscount(tripCode);
    if (response) {
      setAllDiscountArray(response.trip_invoice_discounts);
      setAllDeliveryChargesArray(response.trip_entity_mapping);
      // setPaymentHistory(response.invoice_payment_history_list);
    }
    // if (response) {
    //   setTripData(
    //     response.map((item) => ({
    //       ...item,
    //       unloaded_quantity: item.item_qty,
    //     }))
    //   );
    // }
  };

  useEffect(() => {
    if (selectedTrip.trip_code) {
      getTripItemsDiscount(selectedTrip.trip_code);
    }
  }, [selectedTrip.trip_code, selectedInvoice]);

  const handleInvoice = (e) => {
    setSelectedInvoice(e.target.value);
    setErrDeliveryInvoice("");

    // getDeliverydata(); ///////////////////////////////////////////////////////////////////////
  };
  useEffect(() => {
    if (selectedInvoice != "0") {
      getDeliverydata(selectedInvoice);
    }
  }, [selectedInvoice, allDiscountArray.length > 0]);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  // const  = Math.floor(Date.now() / 1000);

  let timeStamp = "";
  let newTimeStamp = "";
  let unique = "";

  if (Object.keys(selectedTrip).length > 0) {
    timeStamp = Math.floor(Date.now() / 1000);
    newTimeStamp = JSON.stringify(timeStamp).slice(-5);

    unique = `${selectedTrip.trip_code}_${selectedTrip.reference_number[0]
      .split("-")
      .join("_")}${newTimeStamp}`;
  }

  //

  const handleOnlinePayment = () => {
    dispatch(
      itemDetails({
        module: "logistics_web",
        app_redirect_success_url: "/payment-success",
        app_redirect_failure_url: "/payment-failure",
        auth_token: data.AUTH_TOKEN,
        total: totalAmountAfterDiscount,
        data: tripData,
      })
    );
    dispatch(
      paymentDetails({
        app: `Last-mile-delivery-payment-for-invoice-${selectedTrip.reference_number}`,
        trip_code: `${selectedTrip.trip_code}`,
        invoice_id: `${selectedTrip.reference_number}`,
        biller_name: "Faarms",
        uniqueId: unique,
        total: totalAmountAfterDiscount,
      })
    );
    history.push("/faarms-payment");
  };
  const hiddenFileInput = useRef(null);

  const zohoBankAccountId = async () => {
    const res = await getPaymentZohobankAccount();
    if (res) {
      setZohoBankAccountList(res.bankaccounts);
      // setZohoBankAccountList([
      //   {
      //     account_id: "575059000025861441",
      //     account_name: "Airtel Payment  Bank Account",
      //     account_code: "540410",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000029763319",
      //     account_name: "Axis Bank-922020044250574",
      //     account_code: "540405",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000021078985",
      //     account_name: "BBPS - Payment Gateway",
      //     account_code: "",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 54000.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 54000.0,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000000213149",
      //     account_name: "Cash on hand",
      //     account_code: "542500",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000005934413",
      //     account_name: "CC AVENUE Account",
      //     account_code: "540409",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000009762057",
      //     account_name: "Cluster Account - HDFC",
      //     account_code: "540801",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "xxxx9847",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000009762101",
      //     account_name: "Cluster Account - ICICI",
      //     account_code: "540802",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "xxxx3502",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000009762145",
      //     account_name: "Cluster Account - SBI",
      //     account_code: "540803",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "xxxx9674",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000000213821",
      //     account_name: "Credit Cards- HDFC Bank",
      //     account_code: "540406",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "credit_card",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000030428699",
      //     account_name: "DBS BANK",
      //     account_code: "",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 100000.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 100000.0,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000000213195",
      //     account_name: "Dummy Bank Accounts",
      //     account_code: "540800",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000000213081",
      //     account_name: "HDFC BANK-50200048769847",
      //     account_code: "540401",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "xxxx9847",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 199477.9,
      //     bank_balance: 0.0,
      //     bcy_balance: 199477.9,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000005683407",
      //     account_name: "Head Office-Petty Cash",
      //     account_code: "",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000005565441",
      //     account_name: "ICICI Bank-141705003502",
      //     account_code: "540404",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "xxxx3502",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: -63991.46,
      //     bank_balance: 9059421.2,
      //     bcy_balance: -63991.46,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "2022-01-16",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000000213811",
      //     account_name: "IDFC-10056589835",
      //     account_code: "540402",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "xxxx9835",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 234390.95,
      //     bank_balance: 0.0,
      //     bcy_balance: 234390.95,
      //     bank_name: "IDFC FIRST Bank",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000011676573",
      //     account_name: "Interest accurued on FD",
      //     account_code: "",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000004250855",
      //     account_name: "Logistics Payment(Sales Account)",
      //     account_code: "111",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 16388.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 16388.0,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000000213829",
      //     account_name: "Paytm Accounts",
      //     account_code: "540405",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "xxxx3412",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000000000459",
      //     account_name: "Petty Cash",
      //     account_code: "",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000213021",
      //     account_name: "Petty Cash - Baran",
      //     account_code: "P17",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000204965",
      //     account_name: "Petty cash - Bhatinda Warehouse",
      //     account_code: "P01",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000204977",
      //     account_name: "Petty cash - Bisouli Warehouse",
      //     account_code: "P05",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000213025",
      //     account_name: "Petty Cash - Deoli",
      //     account_code: "P18",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000001710873",
      //     account_name: "Petty Cash - Jind",
      //     account_code: "18",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000001710663",
      //     account_name: "Petty Cash - Jodhpur",
      //     account_code: "",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000213009",
      //     account_name: "Petty cash - Kaithal Warehouse",
      //     account_code: "P13",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000204969",
      //     account_name: "Petty cash - Karnal Warehouse",
      //     account_code: "P03",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000213017",
      //     account_name: "Petty Cash - Kekri Warehouse",
      //     account_code: "P16",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000204981",
      //     account_name: "Petty cash - Kota Warehouse",
      //     account_code: "P06",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000001124343",
      //     account_name: "Petty Cash - Muzaffar Nagar WH",
      //     account_code: "P19",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000001710895",
      //     account_name: "Petty Cash - Narwana",
      //     account_code: "P-19",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000001710849",
      //     account_name: "Petty Cash - Patiala",
      //     account_code: "",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000204973",
      //     account_name: "Petty cash - Rudrapur Warehouse",
      //     account_code: "P04",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000204989",
      //     account_name: "Petty cash -Abohar Warehouse",
      //     account_code: "PO8",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000204985",
      //     account_name: "Petty cash -Barara Warehouse",
      //     account_code: "P07",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000213013",
      //     account_name: "Petty Cash -Phillibhit Warehouse",
      //     account_code: "P-15",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000204993",
      //     account_name: "Petty cash-Bareilly Warehouse",
      //     account_code: "P09",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000213001",
      //     account_name: "Petty cash-Chomu warehouse",
      //     account_code: "P11",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000213005",
      //     account_name: "Petty cash-jhalawar Warehouse RJ",
      //     account_code: "P12",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000213865",
      //     account_name: "Petty Cash-Muktsar Warehouse",
      //     account_code: "P14",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000204997",
      //     account_name: "Petty cash_Malout Warehouse",
      //     account_code: "P10",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      //   {
      //     account_id: "575059000000213875",
      //     account_name: "Phonepe Business Account",
      //     account_code: "540407",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 4790.76,
      //     bank_balance: 0.0,
      //     bcy_balance: 4790.76,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000005487956",
      //     account_name: "Razorpay",
      //     account_code: "540408",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 99854.93,
      //     bank_balance: 0.0,
      //     bcy_balance: 99854.93,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000000213825",
      //     account_name: "STATE BANK OF INDIA-39538519674",
      //     account_code: "540403",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "bank",
      //     account_number: "xxxx9674",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 59406.38,
      //     bank_balance: 0.0,
      //     bcy_balance: 59406.38,
      //     bank_name: "",
      //     routing_number: "",
      //     is_primary_account: false,
      //     is_paypal_account: false,
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //     statement_suggestion_count: 0,
      //     partner_bank_source_formatted: "",
      //     partner_bank_source: "",
      //     is_beta_feed: false,
      //     consent_info: {
      //       consent_remaining_days: "",
      //       is_consent_expired: "",
      //     },
      //     feed_status: "",
      //   },
      //   {
      //     account_id: "575059000000000456",
      //     account_name: "Undeposited Funds",
      //     account_code: "",
      //     currency_id: "575059000000000064",
      //     currency_code: "INR",
      //     account_type: "cash",
      //     uncategorized_transactions: 0,
      //     total_unprinted_checks: 0,
      //     is_active: true,
      //     balance: 0.0,
      //     bank_balance: 0.0,
      //     bcy_balance: 0.0,
      //     bank_name: "",
      //     feeds_last_refresh_date: "",
      //     is_direct_paypal: false,
      //   },
      // ]);
    }
  };
  useEffect(() => {
    zohoBankAccountId();
  }, []);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    // const fileUploaded = event.target.files[0];
    // handleFile(fileUploaded);                   // ADDED
  };

  useEffect(() => {
    if (validImageSize && validImageFormat) {
      handleAddDeliveryPayment();
    }
  }, [validImageSize, validImageFormat]);
  function capitalizeLetter(str) {
    var i,
      splittedArray = str.split("_");
    for (i = 0; i < splittedArray.length; i++) {
      splittedArray[i] =
        splittedArray[i].charAt(0).toUpperCase() + splittedArray[i].slice(1);
    }
    return splittedArray.join(" ");
  }

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const bankStaticData =
    runningEnvironment === "development"
      ? [
          {
            bankName: "STATE BANK OF INDIA-39538519674",
            id: 1,
            ledger_name_in_zoho: "STATE BANK OF INDIA-39538519674(540403)",
            modeOfPayment: [
              "cash",
              "upi",
              "net_banking",
              "bank_transfer",
              "neft",
              "rtgs",
              "cheque",
              "imps",
            ],
          },
          {
            bankName: "HDFC BANK-50200048769847",
            id: 2,
            ledger_name_in_zoho: "HDFC BANK-50200048769847(540401)",
            modeOfPayment: [
              "upi",
              "cash",
              "net_banking",
              "bank_transfer",
              "neft",
              "rtgs",
              "cheque",
              "imps",
            ],
          },
          {
            bankName: "ICICI Bank-141705003502",
            id: 3,
            ledger_name_in_zoho: "ICICI Bank-141705003502(540404)",
            modeOfPayment: [
              "cash",
              "upi",
              "net_banking",
              "bank_transfer",
              "neft",
              "rtgs",
              "cheque",
              "imps",
            ],
          },
          {
            bankName: "Phonepe Business Account",
            id: 4,
            ledger_name_in_zoho: " Phonepe Business Account(540407)",
            modeOfPayment: ["phonepe", "upi"],
          },
          {
            bankName: "Razorpay",
            id: 5,
            ledger_name_in_zoho: "Razorpay(540408)",
            modeOfPayment: ["credit_card", "upi", "net_banking"],
          },
          {
            bankName: "Paytm Accounts",
            id: 6,
            ledger_name_in_zoho: "Paytm Accounts(540405)",
            modeOfPayment: ["paytm", "other_upi"],
          },
          {
            bankName: "Airtel Payment  Bank Account",
            id: 7,
            ledger_name_in_zoho: "Airtel Payment Bank Account(540410)",
            modeOfPayment: ["cash"],
          },
          {
            bankName: "Petty Cash",
            id: 8,
            ledger_name_in_zoho: "Petty Accounts(12345)",
            modeOfPayment: ["paytm", "other_upi", "test-1", "test-2"],
          },
        ]
      : [
          {
            bankName: "STATE BANK OF INDIA-39538519674",
            id: 1,
            ledger_name_in_zoho: "STATE BANK OF INDIA-39538519674(540403)",
            modeOfPayment: [
              "cash",
              "upi",
              "net_banking",
              "bank_transfer",
              "neft",
              "rtgs",
              "cheque",
              "imps",
            ],
          },
          {
            bankName: "HDFC BANK-50200048769847",
            id: 2,
            ledger_name_in_zoho: "HDFC BANK-50200048769847(540401)",
            modeOfPayment: [
              "upi",
              "cash",
              "net_banking",
              "bank_transfer",
              "neft",
              "rtgs",
              "cheque",
              "imps",
            ],
          },
          {
            bankName: "ICICI Bank-141705003502",
            id: 3,
            ledger_name_in_zoho: "ICICI Bank-141705003502(540404)",
            modeOfPayment: [
              "cash",
              "upi",
              "net_banking",
              "bank_transfer",
              "neft",
              "rtgs",
              "cheque",
              "imps",
            ],
          },
          {
            bankName: "Phonepe Business Account",
            id: 4,
            ledger_name_in_zoho: " Phonepe Business Account(540407)",
            modeOfPayment: ["phonepe", "upi"],
          },
          {
            bankName: "Razorpay",
            id: 5,
            ledger_name_in_zoho: "Razorpay(540408)",
            modeOfPayment: ["credit_card", "upi", "net_banking"],
          },
          {
            bankName: "Paytm Accounts",
            id: 6,
            ledger_name_in_zoho: "Paytm Accounts(540405)",
            modeOfPayment: ["paytm", "other_upi"],
          },
          {
            bankName: "Airtel Payment  Bank Account",
            id: 7,
            ledger_name_in_zoho: "Airtel Payment Bank Account(540410)",
            modeOfPayment: ["cash"],
          },
        ];

  useEffect(() => {
    let arr = [];
    if (zohoBankAccountList.length)
      zohoBankAccountList.map((bank) => {
        bankStaticData.map((staticBank) => {
          if (bank.account_name === staticBank.bankName) {
            let sendableObject = {
              particular_payment_mode: staticBank.modeOfPayment,
              particular_amount: "",
              particular_transaction_reference_id: "",
              zoho_books_account_name: bank.account_name,
              zoho_books_account_id: bank?.account_id,
              ledger_name_in_zoho: staticBank.ledger_name_in_zoho,
              account_code: bank?.account_code,
            };
            arr.push(sendableObject);
          }
        });
      });
    setMappedAccountListAndModes(arr);
  }, [zohoBankAccountList]);

  useEffect(() => {
    let array = [];
    deliveryPaymentDetailsArray.map((ele) => {
      bankStaticData.map((e) => {
        if (ele.zoho_books_account_name === e.bankName) {
          array.push(e.modeOfPayment);
        }
      });
    });

    setSelectedBankModes(array);

    deliveryPaymentDetailsArray.map((object, index) => {
      let flag = true;
      if (index === 0) {
        const isValid = Object.values(object).every(
          (value) => value !== null && value !== undefined && value !== ""
        );
        flag = isValid;
      } else {
        let isAnyFieldValid = false;

        if (object.zoho_books_account_name) {
          isAnyFieldValid = true;
        }
        if (
          object.particular_payment_mode &&
          object.particular_payment_mode !== "0"
        ) {
          isAnyFieldValid = true;
        }
        if (object.particular_attachment) {
          isAnyFieldValid = true;
        }
        if (object.particular_amount) {
          isAnyFieldValid = true;
        }
        if (object.particular_transaction_reference_id) {
          isAnyFieldValid = true;
        }

        if (isAnyFieldValid) {
          const isValid = Object.values(object).every(
            (value) => value !== null && value !== undefined && value !== ""
          );
          flag = isValid;
        } else {
          flag = true;
        }
      }
      setShowSaveButton(flag);
    });
  }, [deliveryPaymentDetailsArray]);

  console.log(deliveryPaymentDetailsArray, "deliveryPaymentDetailsArray");

  return (
    <Delivery_invoice_payment_main_container className="delivery-invoice-payment-main-container">
      <Drawer
        className="drawer"
        anchor={"right"}
        open={state["right"]}
        onClose={handleClosePaymentDrawer}
      >
        <Payment_drawer className="payment-drawer">
          <div className="payment-wrapper">
            <div className="delivery-invoice-close">
              <Close
                style={{ cursor: "pointer" }}
                onClick={handleClosePaymentDrawer}
              />{" "}
            </div>
            <div className="payment-headings">
              <h4 className="delivery-invoice-input-head">
                Payment details for Invoice ({selectedInvoice})
              </h4>
            </div>
            <div className="payment-total">
              <h4 className="delivery-invoice-input-head">
                Total Amount :{" "}
                <span style={{ fontFamily: "sans-serif" }}> ₹ </span>
                {totalAmountAfterDiscount - calulateApprovedPaymentTotal}
              </h4>
            </div>
            {/* new design========================== */}
            <div className="table-main-container">
              <div className="table-head">
                <div className="table-head-data">Account</div>
                <div className="table-head-data">Mode</div>
                <div className="table-head-data">
                  {isMobile ? "Amt(₹)" : "Amount(₹)"}
                </div>
                <div className="table-head-data">
                  {isMobile ? "Ref Id" : "Reference Id"}
                </div>
                <div className="table-head-data">
                  {isMobile ? "Docs" : "Document"}
                </div>
              </div>
            </div>
            {/* ============end======================= */}

            {/* <div
            // className="delivery-invoice-payment-mode"
            // style={{ flex: "1" }}
            >
              <h4 className="delivery-invoice-input-head">
                Select Bank Account
              </h4>
              <TextField
                size="small"
                id="dropdown"
                autoComplete="off"
                select
                name="particular_payment_mode"
                className="create-trip-input"
                style={{ width: "50%" }}
                variant="outlined"
                placeholder="Enter transaction reference id"
                value={item.particular_payment_mode}
                onChange={(e) => {
                  handleChangeDeliveryPayment(e, index);
                  setTransactionId(e.target.value);
                }}
              >
                <MenuItem disabled value="0">
                  select
                </MenuItem>
                {zohoBankAccountList.map((item) => {
                  return (
                    <MenuItem value={item}>
                      {item?.account_name.split("_").join(" ").toUpperCase()}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div> */}
            {deliveryPaymentDetailsArray.map((item, index) => {
              return (
                <>
                  <div className="table-value" key={index}>
                    <div className="table-head-data-fields">
                      <TextField
                        size="small"
                        id="dropdown"
                        autoComplete="off"
                        select
                        name="zoho_books_account_name"
                        // className="create-trip-input"
                        style={{ width: isMobile ? "11ch" : "18ch" }}
                        variant="outlined"
                        // placeholder="Enter transaction reference id"
                        value={item.zoho_books_account_name}
                        onChange={(e) => {
                          handleChangeBankName(
                            e,
                            index,
                            zohoBankAccountList,
                            bankStaticData
                          );
                          // setTransactionId(e.target.value);
                        }}
                      >
                        <MenuItem
                          disabled
                          value="0"
                          // style={{ fontSize: "12px" }}
                        >
                          select
                        </MenuItem>
                        {mappedAccountListAndModes.map((item) => {
                          return (
                            <MenuItem value={item.zoho_books_account_name}>
                              {item?.zoho_books_account_name
                                .split("_")
                                .join(" ")
                                .replace(/[0-9-]/g, "")
                                .toUpperCase()}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                    <div className="table-head-data-fields">
                      {" "}
                      <TextField
                        size="small"
                        id="dropdown"
                        autoComplete="off"
                        select
                        name="particular_payment_mode"
                        // className="create-trip-input"
                        style={{ width: isMobile ? "9.5ch" : "18ch" }}
                        variant="outlined"
                        placeholder="Enter transaction reference id"
                        value={item.particular_payment_mode}
                        onChange={(e) => {
                          handleChangeDeliveryPayment(e, index);
                          // if (item.particular_payment_mode == "cash") {
                          //   handleAddDeliveryPayment();
                          // }
                          // setTransactionId(e.target.value);
                        }}
                      >
                        <MenuItem disabled value="0">
                          select
                        </MenuItem>
                        {selectedBankModes[index]?.map((item) => {
                          return (
                            <MenuItem value={item}>
                              {item.split("_").join(" ").toUpperCase()}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                    <div className="table-head-data-fields">
                      <TextField
                        size="small"
                        id="dropdown"
                        autoComplete="off"
                        // style={{ width: "96%" }}
                        style={{ width: isMobile ? "9.5ch" : "18ch" }}
                        // className="create-trip-input"
                        variant="outlined"
                        type="number"
                        name="particular_amount"
                        placeholder="Enter Amount"
                        inputProps={{
                          sx: {
                            "&::placeholder": {
                              color: "#A4A4A4",
                              fontSize: "12px",
                              opacity: 1, // otherwise firefox shows a lighter color
                            },
                          },
                        }}
                        onKeyPress={(event) => {
                          if (!/^[0-9.]+$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={item.particular_amount}
                        onChange={(e) => {
                          handleChangeDeliveryPayment(e, index);

                          // setTransactionId(e.target.value);
                        }}
                      />
                    </div>
                    <div className="table-head-data-fields">
                      <TextField
                        size="small"
                        id="dropdown"
                        autoComplete="off"
                        name="particular_transaction_reference_id"
                        // className="create-trip-input"
                        style={{ width: isMobile ? "9ch" : "18ch" }}
                        variant="outlined"
                        // sx={{
                        //   label: { fontSize: "5px" },
                        //   placeholder: { fontSize: "5px" },
                        // }}
                        placeholder="Enter transaction reference id"
                        inputProps={{
                          sx: {
                            "&::placeholder": {
                              color: "#A4A4A4",
                              fontSize: "12px",
                              opacity: 1, // otherwise firefox shows a lighter color
                            },
                          },
                        }}
                        value={item.particular_transaction_reference_id}
                        onChange={(e) => {
                          handleChangeDeliveryPayment(e, index);
                          // setTransactionId(e.target.value);
                        }}
                      />
                    </div>
                    <div className="table-head-data-fields">
                      <>
                        {" "}
                        {isMobile ? (
                          <div className="button-upload" onClick={handleClick}>
                            <UploadIcon /> {isMobile ? "" : "Upload"}
                            {item.particular_attachment !== null ? (
                              <CheckIcon
                                className="delivery-right-payment-button"
                                style={
                                  isMobile
                                    ? { paddingRight: "5px" }
                                    : {
                                        color: "green",
                                        fontSize: "20px",
                                        marginLeft: "10px",
                                        fontWeight: "bolder",
                                        borderRadius: "50%",
                                      }
                                }
                              />
                            ) : null}
                            {index !== 0 ? (
                              <span
                                className="delivery-add-payment-button"
                                style={{ fontWeight: "bold" }}
                                onClick={() => handleRemovePayment(index)}
                              >
                                <CancelOutlinedIcon style={{ margin: "2px" }} />
                              </span>
                            ) : null}
                          </div>
                        ) : (
                          <>
                            <button
                              className="button-upload"
                              onClick={handleClick}
                            >
                              <UploadIcon /> {isMobile ? "" : "Upload"}
                              {item.particular_attachment !== null ? (
                                <CheckIcon
                                  className="delivery-right-payment-button"
                                  style={
                                    isMobile
                                      ? {}
                                      : {
                                          color: "green",
                                          fontSize: "20px",
                                          marginLeft: "10px",
                                          fontWeight: "bolder",
                                          borderRadius: "50%",
                                        }
                                  }
                                />
                              ) : null}
                            </button>
                            {index !== 0 ? (
                              <span
                                className="delivery-add-payment-button"
                                style={{ fontWeight: "bold" }}
                                onClick={() => handleRemovePayment(index)}
                              >
                                <CancelOutlinedIcon style={{ margin: "2px" }} />
                              </span>
                            ) : null}
                          </>
                        )}
                        <input
                          type="file"
                          key={index}
                          onChange={(e) => {
                            handlePaymentAttachment(e, index);
                            if (validImageSize && validImageFormat) {
                              handleAddDeliveryPayment();
                            }
                          }}
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                        />
                      </>
                    </div>
                  </div>
                </>
              );
            })}
            {errDeliveryPayment ? (
              <div style={{ width: "100%" }}>
                <Alert severity="error"> {errDeliveryPayment}</Alert>
              </div>
            ) : null}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <button
                className="button-recordpayment"
                style={
                  !showSaveButton
                    ? {
                        cursor: "not-allowed",
                        backgroundColor: "#454545",
                        width: "150px",
                        margin: "5px",
                      }
                    : {
                        cursor: "pointer",
                        width: "150px",
                        margin: "5px",
                      }
                }
                // style={{ width: "150px", margin: "5px" }}
                onClick={handlePayNow}
                disabled={loadingButton || !showSaveButton ? true : false}
              >
                {loadingButton ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </Payment_drawer>
      </Drawer>
      {isMobile ? null : (
        <div className="delivery-invoice-payment-sidebar-container">
          <SideBarMain />
        </div>
      )}
      <div className="delivery-invoice-payment-right-main-container">
        {isMobile ? (
          <div className="trip-list-mobile-header">
            <MobileHeader headerTitle="Delivery Status" />
          </div>
        ) : null}
        <div className="delivery-invoice-right-top">
          <ArrowBack style={{ cursor: "pointer" }} onClick={handleGoToTrips} />{" "}
          <span className="delivery-invoice-head-text">
            {" "}
            Delivery Invoice payment
          </span>
        </div>
        <Snackbar
          open={openToast}
          autoHideDuration={8000}
          anchorOrigin={{ vertical, horizontal }}
          onClose={handleCloseToast}
        >
          <Alert
            variant="filled"
            elevation={6}
            onClose={handleCloseToast}
            severity={syncToastMessage?.status}
            sx={{ width: "100%" }}
          >
            {syncToastMessage?.message ? syncToastMessage?.message : " - "}
          </Alert>
        </Snackbar>
        <div className="delivery-invoice-right-body-container">
          <div className="delivery-invoice-row">
            <div className="delivery-invoice-input-container">
              <h4 className="delivery-invoice-input-head">
                Trip code :{" "}
                {selectedTrip.trip_code ? selectedTrip.trip_code : " - "}
              </h4>
              <h4 className="delivery-invoice-input-head">
                Vehicle Number :{" "}
                {selectedTrip.vehicle_number
                  ? selectedTrip.vehicle_number
                  : " - "}
              </h4>
              <h4 className="delivery-invoice-input-head">
                Per bag cost : Rs. {perBagCost ? perBagCost : " - "}
              </h4>
            </div>
          </div>

          <div className="delivery-invoice-row" style={{ display: "flex" }}>
            <div className="delivery-invoice-input-container">
              <h4 className="delivery-invoice-input-head">Select Invoice</h4>
              <TextField
                size="small"
                select
                autoComplete="off"
                className="delivery-invoice-input"
                variant="outlined"
                value={selectedInvoice}
                onChange={(e) => handleInvoice(e)}
              >
                <MenuItem value="0" disabled>
                  Select invoice
                </MenuItem>
                {invoiceList.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </TextField>
            </div>
            <div style={{ marginLeft: "10px" }}>
              {selectedInvoice != "0" ? (
                <button
                  className="sync-button-delivery"
                  onClick={handleSyncZoho}
                >
                  {loadingSync ? (
                    <CircularProgress size={25} color="inherit" />
                  ) : (
                    <>
                      <SyncIcon /> <span>Sync</span>
                    </>
                  )}
                </button>
              ) : null}
            </div>
          </div>
        </div>
        {getTripStatus() !== "picked" && getTripStatus() !== undefined ? (
          <div className="delivery-invoice-right-body-container">
            <div className="delivery-invoice-row" style={{ display: "flex" }}>
              <h4
                style={{
                  color: "red",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                }}
              >
                <ErrorOutlineIcon
                  style={{ color: "red", marginRight: "5px" }}
                />{" "}
                Delivery of items cannot be done before pickup.
              </h4>
            </div>
          </div>
        ) : null}

        <div className="delivery-invoice-right-body-container">
          <div className="delivery-invoice-row" style={{ display: "flex" }}>
            {" "}
            <h4 className="delivery-invoice-input-head">Payment History</h4>
          </div>
        </div>
        {/* {selectedInvoice !== "0" ? ( */}
        {paymentHistory.length > 0 ? (
          <div
            className="delivery-invoice-items-container-history"
            style={
              isMobile
                ? { height: "250px", width: "95%", overflowY: "scroll" }
                : { height: "250px", overflowY: "scroll" }
            }
          >
            {paymentHistory.map((item, index) => {
              return (
                <Accordion
                  key={index}
                  style={{
                    marginTop: "10px",
                    width: "98%",
                    marginLeft: "5px",
                  }}
                  onClick={(e) => {
                    // setLoadingPaymentApprove(false);
                    // setLoadingPaymentRejectedApprove(false);
                    // setPaymentstatusError("");
                    // setPaymentRemarks("");
                  }}
                  expanded={expanded === index}
                  onChange={handleChangeAccordion(index)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "#E2F1DB" }}
                  >
                    <Typography className="payment-history-headings">
                      <div className="payment-history-subheadings">
                        <div className="payment-history-subheadings-text">
                          Mode:
                        </div>
                        <div style={{ maxWidth: "30px" }}>
                          {" "}
                          {item.payment_mode
                            ? capitalizeLetter(item.payment_mode)
                            : null}
                        </div>
                      </div>
                      <div className="payment-history-subheadings">
                        <div className="payment-history-subheadings-text">
                          Amount(₹):
                        </div>
                        {item.amount ? item.amount : null}
                      </div>
                      <div className="payment-history-subheadings">
                        <div className="payment-history-subheadings-text">
                          Created on:
                        </div>
                        {item.created_on
                          ? moment(item.created_on).format("DD MMM YYYY")
                          : null}
                      </div>
                      <div className="payment-history-subheadings">
                        <div className="payment-history-subheadings-text">
                          Status:
                        </div>
                        {item.customer_payment_status == "submitted" ? (
                          <div style={{ color: "#BF9000" }}>
                            {" "}
                            {item.customer_payment_status
                              ? item.customer_payment_status.toUpperCase()
                              : "N.A"}
                          </div>
                        ) : item.customer_payment_status == "recommended" ? (
                          <div style={{ color: "#C082A1" }}>
                            {" "}
                            {item.customer_payment_status
                              ? item.customer_payment_status.toUpperCase()
                              : "N.A"}
                          </div>
                        ) : item.customer_payment_status == "submitted" ? (
                          <div style={{ color: "#134F5C" }}>
                            {" "}
                            {item.customer_payment_status
                              ? item.customer_payment_status.toUpperCase()
                              : "N.A"}
                          </div>
                        ) : item.customer_payment_status == "approved" ? (
                          <div style={{ color: "#2C5E17" }}>
                            {" "}
                            {item.customer_payment_status
                              ? item.customer_payment_status.toUpperCase()
                              : "N.A"}
                          </div>
                        ) : item.customer_payment_status == "rejected" ? (
                          <div style={{ color: "red" }}>
                            {" "}
                            {item.customer_payment_status
                              ? item.customer_payment_status.toUpperCase()
                              : "N.A"}
                          </div>
                        ) : (
                          "N.A"
                        )}
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mini-card-delivery-info-container-payment-history">
                      <div className="text-fields-payment-history">
                        {/* <label htmlFor="">Remarks</label> */}
                        {item.customer_payment_status == "approved" ||
                        item.customer_payment_status == "rejected" ? (
                          <>
                            {" "}
                            <div className="inoice-details-div">
                              {/* <div className="invoice-details-text">
                                <div className="payment-history-subheadings-text">
                                  Invoice:
                                </div>
                                {item.reference_number
                                  ? item.reference_number
                                  : "--"}
                              </div> */}
                              <div className="invoice-details-text">
                                {/* Invoice: */}
                                <div className="payment-history-subheadings-text">
                                  Reference Id:
                                </div>{" "}
                                {item.transaction_reference_id
                                  ? item.transaction_reference_id
                                  : "--"}
                              </div>
                              <div className="invoice-details-text">
                                {/* Account: */}
                                <div className="payment-history-subheadings-text">
                                  Account:
                                </div>
                                {item.zoho_books_account_name
                                  ? item.zoho_books_account_name
                                  : "--"}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="inoice-details-div">
                              {/* <div className="invoice-details-text">
                                <div className="payment-history-subheadings-text">
                                  Invoice:
                                </div>{" "}
                             
                                {item.reference_number
                                  ? item.reference_number
                                  : "--"}
                              </div> */}
                              <div className="invoice-details-text">
                                <div className="payment-history-subheadings-text">
                                  Reference Id:
                                </div>{" "}
                                {/* Invoice: */}
                                {item.transaction_reference_id
                                  ? item.transaction_reference_id
                                  : "--"}
                              </div>
                              <div className="invoice-details-text">
                                {/* Account: */}
                                <div className="payment-history-subheadings-text">
                                  Account:
                                </div>{" "}
                                {item.zoho_books_account_name
                                  ? item.zoho_books_account_name
                                  : "--"}
                              </div>
                            </div>

                            {/* {paymentstatusError ? (
                                        <div
                                          style={{
                                            fontSize: "10px",
                                            color: "red",
                                          }}
                                        >
                                          Error: {paymentstatusError}
                                        </div>
                                      ) : null} */}
                          </>
                        )}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        ) : loadingPaymentHistory == true ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <CircularProgress size={15} />
            <div>Loading data...</div>
          </div>
        ) : (
          <div
            className="delivery-invoice-items-container-history"
            style={
              isMobile
                ? {
                    height: "50px",
                    width: "95%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }
                : {
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }
            }
          >
            <div
              style={{
                // width: "200%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                // height: "4rem",
                // backgroundColor: "red",
              }}
            >
              <div style={{ fontSize: "12px" }}>No payment history found.</div>
            </div>
          </div>
        )}
        {/* ) : null} */}
        <div className="delivery-invoice-right-body-container">
          <div className="delivery-invoice-row" style={{ display: "flex" }}>
            {" "}
            <h4 className="delivery-invoice-input-head">Delivered Items</h4>
          </div>
        </div>
        <div className="delivery-invoice-items-container">
          <table id="invoice-items">
            <thead className="thead_heading">
              <tr className="table-head-row">
                <th className="column1">Item</th>
                <th className="column2">Actual qty</th>
                <th className="column3">Delivered qty</th>
                <th className="column4">Rate</th>
                <th className="column5">Total</th>
              </tr>
            </thead>
            {tripData.length > 0 ? (
              tripData.map((data, index) => (
                <tbody key={data.id} className="body">
                  <tr className="rows">
                    <td className="column1">{data.item_name}</td>

                    <td className="column2">{data.item_qty}</td>
                    <td className="column3" style={{ width: "20%" }}>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        value={data.blocked_quantity_for_invoice_payment}
                        onChange={(e) => {
                          if (data.item_qty >= e.target.value) {
                            handleChangeUnloadedQuantity(e, index);
                          }
                        }}
                        className="delivery-invoice-quantity-input"
                      />
                    </td>
                    <td className="column4">
                      <span
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        {" "}
                        ₹{" "}
                      </span>
                      {data.item_rate}
                    </td>
                    <td className="column5">
                      <span
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        ₹{" "}
                      </span>{" "}
                      {data.blocked_quantity_for_invoice_payment
                        ? data.item_rate *
                          data.blocked_quantity_for_invoice_payment
                        : "0.00"}
                    </td>
                  </tr>
                </tbody>
              ))
            ) : (
              <div
                style={{
                  // width: "200%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "4rem",
                  // backgroundColor: "red",
                }}
              >
                <h6 style={{ fontSize: "12px" }}>
                  {paymentStatus
                    ? "Payment recorded for this invoice"
                    : "Please select one invoice from the above list."}
                </h6>
              </div>
            )}
            {isMobile ? null : selectedInvoice !== "0" ? (
              <>
                <thead>
                  <tr style={{ borderTop: "1px solid black" }}>
                    <td className="column1"></td>
                    <td className="column2"></td>
                    <td className="column3"></td>
                    {/* <td className="total-amount">Discounted amount </td>
                  <td className="total-amount">sdsdsds</td> */}
                    <td className="total-amount">
                      {selectedDeliveryCharges?.delivery_charges &&
                      totalAmountAfterDiscount ? (
                        <>
                          <div>Delivery charges</div>
                          <hr></hr>
                        </>
                      ) : null}
                      {selectedDiscountDetails.discount_amount &&
                      totalAmountAfterDiscount ? (
                        <>
                          <div>Discount amount</div>
                          <hr></hr>
                        </>
                      ) : null}
                      <div>Total amount</div>{" "}
                    </td>

                    <td className="total-amount">
                      {selectedDeliveryCharges.delivery_charges &&
                      totalAmountAfterDiscount ? (
                        <div style={{ marginLeft: "0px" }}>
                          <div>
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: "14px",
                                marginLeft: "5px",
                              }}
                            >
                              {" "}
                              ₹{" "}
                            </span>
                            {selectedDeliveryCharges.delivery_charges
                              ? selectedDeliveryCharges.delivery_charges
                              : " - "}{" "}
                            (+)
                          </div>
                          <hr></hr>
                        </div>
                      ) : null}
                      {selectedDiscountDetails.discount_amount &&
                      totalAmountAfterDiscount ? (
                        <div style={{ marginLeft: "0px" }}>
                          <div>
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: "14px",
                                marginLeft: "5px",
                              }}
                            >
                              {" "}
                              ₹{" "}
                            </span>
                            {selectedDiscountDetails.discount_amount
                              ? selectedDiscountDetails.discount_amount
                              : " - "}
                            (-)
                          </div>
                          <hr></hr>
                        </div>
                      ) : null}
                      {loadingPaymentHistory ? (
                        <CircularProgress size={12} />
                      ) : (
                        <div>
                          <span
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: "14px",
                              marginLeft: "5px",
                            }}
                          >
                            {" "}
                            ₹{" "}
                          </span>
                          {totalAmountAfterDiscount}
                        </div>
                      )}
                    </td>
                  </tr>
                </thead>
                {selectedInvoice !== "0" ? (
                  <>
                    <thead>
                      <tr style={{ borderTop: "1px solid black" }}>
                        <td className="column1"></td>
                        <td className="column2"></td>
                        <td className="column3"></td>
                        {/* <td className="total-amount">Discounted amount </td>
                  <td className="total-amount">sdsdsds</td> */}
                        <td className="total-amount">
                          <div className="paid-amount-div">
                            Recorded Amount{" "}
                            <div className="paid-amount-sum">
                              (Submitted+Recommended+Approved)
                            </div>
                          </div>{" "}
                        </td>

                        <td className="total-amount">
                          {loadingPaymentHistory ? (
                            <CircularProgress size={12} />
                          ) : (
                            <div>
                              <span
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: "14px",
                                  marginLeft: "5px",
                                }}
                              >
                                {" "}
                                ₹{" "}
                              </span>
                              {calulateApprovedPaymentTotal
                                ? calulateApprovedPaymentTotal
                                : "0.00"}
                            </div>
                          )}
                        </td>
                      </tr>
                    </thead>
                    <thead>
                      <tr style={{ borderTop: "1px solid black" }}>
                        <td className="column1"></td>
                        <td className="column2"></td>
                        <td className="column3"></td>
                        {/* <td className="total-amount">Discounted amount </td>
                  <td className="total-amount">sdsdsds</td> */}
                        <td className="total-amount">
                          <div>Balance</div>{" "}
                        </td>

                        <td className="total-amount">
                          {loadingPaymentHistory ? (
                            <CircularProgress size={12} />
                          ) : (
                            <div style={{ color: "red" }}>
                              <span
                                style={{
                                  fontFamily: "sans-serif",
                                  fontSize: "14px",
                                  marginLeft: "5px",
                                }}
                              >
                                {" "}
                                ₹{" "}
                              </span>
                              {totalAmountAfterDiscount -
                                calulateApprovedPaymentTotal}
                            </div>
                          )}
                        </td>
                      </tr>
                    </thead>
                  </>
                ) : null}
              </>
            ) : null}
          </table>

          {!isMobile &&
          selectedInvoice !== "0" &&
          getTripStatus() == "picked" &&
          totalAmountAfterDiscount - calulateApprovedPaymentTotal > 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "95%",
                  marginTop: "1rem",
                }}
              >
                {/* <button
                  className="button-recordpayment"
                  style={{ marginRight: "1rem" }}
                  onClick={handleOnlinePayment}
                >
                  Pay Online
                </button> */}
                <button
                  className="button-recordpayment_offline"
                  onClick={handleOpenMenu}
                  style={{ marginBottom: "30px" }}
                >
                  Record Cash Payment
                </button>
              </div>
            </>
          ) : null}
        </div>
        <div
          style={{
            width: "100%",
            height: "2rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {isMobile ? (
            <thead style={{ marginRight: "2rem", marginTop: "12px" }}>
              <tr style={{ borderTop: "1px solid black" }}>
                <td className="column1"></td>
                <td className="column2"></td>
                <td className="column3"></td>

                <td className="total-amount">
                  {" "}
                  {selectedDiscountDetails.discount_amount &&
                  totalAmountAfterDiscount ? (
                    <>
                      <div>Discount amount</div>
                      <hr></hr>
                    </>
                  ) : null}
                  <div>Total amount</div>{" "}
                </td>
                <td className="total-amount">
                  {selectedDiscountDetails.discount_amount &&
                  totalAmountAfterDiscount ? (
                    <div style={{ marginLeft: "0px" }}>
                      <div>
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: "14px",
                            marginLeft: "5px",
                          }}
                        >
                          {" "}
                          ₹{" "}
                        </span>
                        {selectedDiscountDetails.discount_amount
                          ? selectedDiscountDetails.discount_amount
                          : " - "}
                      </div>
                      <hr></hr>
                    </div>
                  ) : null}

                  <div>
                    <span
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      {" "}
                      ₹{" "}
                    </span>
                    {totalAmountAfterDiscount}
                  </div>
                </td>
              </tr>
            </thead>
          ) : null}
        </div>
        {/* DeliveryInvoicePayment */}
      </div>{" "}
      {isMobile &&
      selectedInvoice !== "0" &&
      getTripStatus() == "picked" &&
      totalAmountAfterDiscount - calulateApprovedPaymentTotal > 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "95%",
            marginTop: "1rem",
          }}
        >
          {/* <button
            className="button-recordpayment"
            style={{ marginRight: "1rem" }}
            onClick={handleOnlinePayment}
          >
            Pay Online
          </button> */}
          <button
            className="button-recordpayment_offline"
            onClick={handleOpenMenu}
            style={{ marginBottom: "30px" }}
          >
            Record Cash Payment
          </button>
        </div>
      ) : null}
    </Delivery_invoice_payment_main_container>
  );
}

export default DeliveryInvoicePayment;
