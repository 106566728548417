import React, { useEffect, useState, useRef, useCallback } from "react";
import SideBarMain from "../../../components/sideBarMain/SideBarMain.jsx";
import VerifiedSharpIcon from "@mui/icons-material/VerifiedSharp";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import CancelIcon from "@mui/icons-material/Cancel";
import DangerousIcon from "@mui/icons-material/Dangerous";
// import Snackbar from "@mui/material/Snackbar";
import {
  approveRejectTrip,
  GetTripList,
  GetTripListWithFilter,
  GetTripPaymentStatusEnums,
  GetTripStatusEnums,
  reopenCancelTrip,
  startTrip,
  stopTrip,
  TripAttachmentPost,
  GetCustomerListApi,
  GetTransporterByName,
  getTripExport,
  getTripAttachmentsDispute,
  GetTripCostApi,
  RecommendRejectPayment,
} from "./tripListServices";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Checkbox from "@mui/material/Checkbox";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Modal as ModalAntd } from "antd";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import TRUCK_IMAGE from "../../../assets/dashboard_icons/icon-0.8s-215px.png";

// import "./trip-listv2.css";
import imageloader from "../../../assets/tripIcons/ZKZg.gif";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import showIcons from "../../../assets/tripIcons/view.svg";
import downloadIcons from "../../../assets/tripIcons/download.svg";
import fileIcons from "../../../assets/tripIcons/Group 343.svg";
import CancelIcon from "@mui/icons-material/Cancel";
// import "./trip-listv2.css";
import MONTHLYICON from "../../../assets/monthly_icon.svg";
import TripPickup from "../tripPickup/TripPickup";
import Typography from "@mui/material/Typography";
import NoDetailsFound from "../../../assets/tripIcons/no_data_found.png";
import FeedIcon from "@mui/icons-material/Feed";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
// import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
// import { useKey } from "react-use";
import CloseIcon from "@mui/icons-material/Close";
import TRIPWISEICON from "../../../assets/trip_wise_icon.svg";
import INFOICON from "../../../assets/infoIcon.svg";
import TripDetails from "../../../assets/tripIcons/newIcons/trip_details.svg";
import TripDetailsSelected from "../../../assets/tripIcons/newIcons/trip_details-1.svg";
import DriverDetails from "../../../assets/tripIcons/newIcons/driver_details.svg";
import DriverDetailsSelected from "../../../assets/tripIcons/newIcons/driver_details-1.svg";
import TripHistoryDetails from "../../../assets/tripIcons/newIcons/trip_history_icon-1.svg";
import TripHistoryDetailsSelected from "../../../assets/tripIcons/newIcons/trip_history_icon(1).svg";
import TripDeliverDetails from "../../../assets/tripIcons/newIcons/delivery_info_icon.svg";
import TripDeliverDetailsSelected from "../../../assets/tripIcons/newIcons/delivery_info_icon-1.svg";
import TripAttachmentsDetails from "../../../assets/tripIcons/newIcons/trip_attachment_icon(1).svg";
import TripInvoicePaymentDetailsSelected from "../../../assets/tripIcons/newIcons/active.svg";
import TripInvoicePaymentDetails from "../../../assets/tripIcons/newIcons/inactive.svg";
import TripAttachmentsDetailsSelected from "../../../assets/tripIcons/newIcons/trip_attachment_icon-1.svg";
import TripCostDetails from "../../../assets/tripIcons/newIcons/tripcost.svg";
import TripCostDetailsSelected from "../../../assets/tripIcons/newIcons/tripcostselected.svg";
import AccordionDetails from "@mui/material/AccordionDetails";
import mapIcon from "../../../assets/map_icon.svg";
import { styled } from "@mui/system";
import Snackbar from "@mui/material/Snackbar";
import { GetCustomerSearchList } from "../createTrip/createTripServices";

import success from "../../../assets/dashboard_icons/icons8-truck.gif";
import { NavLink, useHistory } from "react-router-dom";
import { Search, Close, Edit, Add, Download } from "@mui/icons-material";
import MiniDetailsCard from "../../../components/miniDetailsCard/MiniDetailsCard";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FILTER_ICON from "../../../assets/filter_icon.svg";
import UploadImage from "../../../assets/upload.svg";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { fontTheme, tripCardLabel } from "../../../helper/themeClass";
import {
  CircularProgress,
  TextField,
  MenuItem,
  Alert,
  Drawer,
  InputAdornment,
  Menu,
  Autocomplete,
  Accordion,
  AccordionSummary,
  Stack,
  IconButton,
  Collapse,
  Dialog,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  Select,
  selectClasses,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  reduxStoreTripList,
  updateDriverDetails,
  updatePendingTripInvoiceList,
  updateSelectedTrip,
  updateSelectedTripInvoices,
  updateTripDetails,
  updateTripSearchValue,
} from "../../../redux/tripSlice";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import apiUrl from "../../../url/apiUrl";
import MobileHeader from "../../../components/MobileHeader/MobileHeader";
import {
  indexSelectedData,
  readLoginData,
  readToken,
} from "../../../helper/dataDecryptionHelper";
// import { readLoginData } from "../../../helper/dataDecryptionHelper";
import { GetDocumentEnums } from "../createTrip/createTripServices";
import * as moment from "moment";
import { ModalUnstyled } from "@mui/base";
import AsyncSelect from "react-select/async";
import CopyText from "../../../components/copyText/CopyText";
import axiosInterceptor from "../../../config/https";
import {
  GetDriverDetails,
  GetTripAttachments,
  GetTripHistory,
  GetTripInvoicePaymentByTripId,
  GetTripItemsForDeliveryInfo,
  GetTripPaymentHistory,
} from "../../../components/miniDetailsCard/miniDetailsCardServices";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";
import {
  Trip_Pickup_modal,
  Trip_list_drawer_top_row,
  Trip_list_filter_main,
  Trip_list_main_container,
  Trip_list_modal_container,
  Trip_settings_wrapper,
} from "./trip-listv2";
import { colorPalette, colorPaletteDark } from "../../../themes/theme";
import {
  ButtonRectangle,
  ButtonRectangleSecondary,
} from "../../../themes/components/button";
import {
  approveCompleteTripRequest,
  rejectCompleteTripRequest,
} from "../completeTrip/completeTripServices";
import Topbar from "../../../components/topbar/Topbar.jsx";
import { useContext } from "react";
import { DarkModeContext } from "../../../context/darkModeContext.js";
import { MyContext } from "../../../components/enumContext/EnumContextComponent.jsx";
import { Link } from "react-router-dom/cjs/react-router-dom.js";
import { UserNotAuthorizedStyle } from "../../ftl_trips/ftl_tripListV2/trip-listv2.js";
import NotAuthorized from "../../../components/notAuthorized/NotAuthorized.jsx";
import SomethingWentWrong from "../../../components/somethingWentWrong/SomethingWentWrong.jsx";

const CryptoJS = require("crypto-js");
// import {updateTrip}

// {
//   "source_id": 9,
//   "approx_cost_id": 5,
//   "actual_cost_id": null,
//   "id": 5,
//   "payments_status": "paid",
//   "load_source": "trip_purchase_order",
//   "created_on": "2022-03-14T11:25:26.316874",
//   "load_unit_id": null,
//   "vehicle_number": "KA-29-2125",
//   "driver_id": 5,
//   "destination_id": 10,
//   "is_active": true,
//   "modified_on": "2022-03-14T11:25:26.319086",
//   "trip_distance_in_km": 78.0,
//   "trip_code": "KU0314251647257126",
//   "zoho_id": "670003",
//   "remarks": null,
//   "trip_status": "created",
//   "attachment_count": 0
// },

// const dummyInvoiceList = [
//   "UP-22-23-0000389",
//   "UP-22-23-0000386",
//   "UP-22-23-0000364",
//   "UK-22-23-0000297",
// ];

const isMobile = window.matchMedia(
  "only screen and (max-width: 499px)"
).matches;

const filterBoxStyle = {
  width: isMobile ? "95vw" : "36%",
  height: isMobile ? "75vh" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -34,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};

const pickupBoxStyle = {
  width: isMobile ? "90vw" : "55%",
  height: isMobile ? "auto" : "73vh",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -24,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};

const tripRoutesBoxStyle = {
  width: isMobile ? "90vw" : "50%",
  height: isMobile ? "auto" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  marginLeft: isMobile ? 1 : -24,
  marginTop: isMobile ? -10 : -10,
  border: "1px solid gray",
  borderRadius: "10px",
};

const exportBoxStyle = {
  width: isMobile ? "85%" : "70%",
  height: isMobile ? "80vh" : "auto",
  bgcolor: "background.paper",
  p: 2,
  px: 4,
  pb: 3,
  // marginTop: isMobile ? -10 : -20,
  border: "1px solid gray",
  borderRadius: "10px",
};

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${isMobile ? `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.5)`};
  -webkit-tap-highlight-color: transparent;
`;
const progressSx = {
  borderRadius: "50%",
  boxShadow: `inset 0 0 0 0`,
};
// const styles = (theme) => ({z-index: -1;});
function TripListV2() {
  const { toggle, darkMode } = useContext(DarkModeContext);

  const dispatch = useDispatch();
  const history = useHistory();
  // const [count, set] = useState("");
  //
  // const increment = (xyee) => {
  //   set(xyee);
  // };
  // console.log("the counttttttt================", increment());
  // const deIncrement = (xyee) => {
  //
  //   set(xyee);
  // };
  // useKey("ArrowUp", increment);
  // useKey("ArrowDown", deIncrement);
  const validEmailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
  const [allTripList, setAllTripList] = useState([]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [openTripDocs, setOpenTripDocs] = useState(false);
  const [checked, setChecked] = useState(false);
  const handleOpenImage = () => setOpenTripDocs(true);
  const [loadingImage, setLoadingImage] = useState(false);
  const handleCloseImage = () => setOpenTripDocs(false);
  //
  const [selectedTrip, setSelectedTrip] = useState({});

  const [selectedTripIndex, setSelectedTripIndex] = useState(null);
  const [tripRemarks, setTripRemarks] = useState("");
  const [selectedTripStatus, setselectedTripStatus] = useState(10);
  const [selectedTripStatusText, setSelectedTripStatusText] = useState(
    localStorage.getItem("IntransiteStatus")
      ? localStorage.getItem("IntransiteStatus")
      : ""
  );
  const [paymentRemarks, setPaymentRemarks] = useState("");
  const [currentButton, setCurrentButton] = useState("");
  const [selectedTripId, setSelectedTripId] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const [attachmentTag, setAttachmentTag] = useState("builty copy");
  const [errTotalExport, setErrTotalExport] = useState("");
  const [errTripRemarks, setErrTripRemarks] = useState("");
  const [errAttachmentTag, setErrAttachmentTag] = useState("");
  const [errAttachmentType, setErrAttachmentType] = useState("");
  const [errAttachmentFile, setErrAttachmentFile] = useState("");
  const [filterTripCode, setFilterTripCode] = useState("");
  const [filterPaymentStatus, setFilterPaymentStatus] = useState("");
  const [filterVehicleNumber, setFilterVehicleNumber] = useState("");
  const [filterCustomerId, setFilterCustomerId] = useState("");
  const [filterCustomerData, setFilterCustomerData] = useState({});
  const [filterLoadSource, setFilterLoadSource] = useState("");
  const [filterReferenceNumber, setFilterReferenceNumber] = useState("");
  const [filterEwayBillNo, setFilterEwayBillNo] = useState("");
  const [tripExportTripTypeError, setTripExportTripTypeError] = useState("");

  const [loadingFilterSearchButton, setLoadingFilterSearchButton] =
    useState(false);
  const [filterTripStatus, setFilterTripStatus] = useState("");
  const [tripType, setTripType] = React.useState(
    localStorage.getItem("tripType") ? localStorage.getItem("tripType") : ""
  );
  const [currentPage, setCurrentPage] = useState(1);
  localStorage.setItem("tripType", tripType);
  const [userRole, setUserRole] = useState("");
  const [loadingExportButton, setLoadingExportButton] = useState(false);
  const [tripDeliveredBills, setTripDeliveredBills] = useState([]);
  const [tripAttachmentFile, setTripAttachmentFile] = useState(null);
  const [errTripStatus, setErrTripStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isPickupOpen, setIsPickupOpen] = useState(false);

  const [isExportOpen, setIsExportOpen] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [exportToastMessage, setExportToastMessage] = useState("");

  const [tripSettingsState, setTripSettingsState] = useState({ right: false });

  const toggTripSettingsDrawer = (anchor, open) => {
    setTripSettingsState({ ...tripSettingsState, [anchor]: open });
  };

  const [sessionExpires, setSessionExpires] = useState("");
  const [tripStatusList, setTripStatusList] = useState([
    "created",
    "approved",
    "requested_for_completion",
    "trip_disputed",
    "intransit",
    "completed",
    "rejected",
    "reopen",
    "cancelled",
    ,
  ]);
  const [tripTypeList, setTripTypeList] = useState([
    "first_mile",
    "middle_mile",
    "last_mile",
  ]);
  const [filterTripType, setFilterTripType] = useState("");

  const [loadSourceList, setLoadSourceList] = useState([
    "trip_invoice",
    "trip_purchase_order",
    "manual",
  ]);
  const [isUserNotAuthorized, setIsUserNotAuthorized] = useState(false);
  const [tripPaymentStatusList, setTripPaymentStatusList] = useState([]);
  //
  const [attachmentTypeList, setAttachmentTypeList] = useState([
    "image",
    "pdf",
  ]);

  const [attachmentListArray, setAttachmentListArray] = useState([
    {
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    },
  ]);
  const [errAttachmentSize, setErrAttachmentSize] = useState([]);
  const [attachmentType, setAttachmentType] = useState("0");
  const [state, setState] = useState({ right: false });
  const [loadingApproveButton, setLoadingApproveButton] = useState(false);
  const [currentStatusUpdateInProgress, setCurrentStatusUpdateInProgress] =
    useState("");
  const { tripSearchValue } = useSelector((state) => state.trip);
  const { reduxTripList } = useSelector((state) => state.trip);
  //

  //pagination starts

  const [isLastPage, setIsLastPage] = useState(false);
  const [triCostDetails, setTriCostDetails] = useState([]);
  const [loadingTripCost, setLoadingTripCost] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [customerList, setCustomerList] = useState([]);
  const [currentSelectedTripStatus, setCurrentSelectedTripStatus] =
    useState("");
  const [openDisputeModal, setOpenDisputeModal] = useState(false);
  const handleOpenDistpute = () => setOpenDisputeModal(true);
  const handleCloseDistpute = () => setOpenDisputeModal(false);
  // const [searchValue, setSearchValue] = useState("");
  const [attachmentCount, setAttachmentCount] = useState([1]);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [exportFromDate, setExportFromDate] = useState("");
  const [exportToDate, setExportToDate] = useState("");
  const [exportTripStatus, setExportTripStatus] = useState("0");
  const [exportCustomer, setExportCustomer] = useState("");
  const [exportVehicleNumber, setExportVehicleNumber] = useState("");
  const [exportTransporter, setExportTransporter] = useState("");
  const [exportVehiclePayoutPeriod, setExportVehiclePayoutPeriod] =
    useState("0");
  const [exportPaymentStatus, setExportPaymentStatus] = useState("0");
  const [driverDetails, setDriverDetails] = useState({});
  const [exportSourceState, setExportSourceState] = useState("0");
  const [exportDestinationState, setExportDestinationState] = useState("0");
  const [exportCreatedBy, setExportCreatedBy] = useState("");
  const [exportLoadSource, setExportLoadSource] = useState("0");
  const [exportEmail, setExportEmail] = useState("");
  const [exportTripType, setExportTripType] = useState("0");

  const [errExportEmailId, setErrExportEmailId] = useState("");
  const [customerSearchValue, setCustomerSearchValue] = useState("");
  const [exportCustomerSearchValue, setExportCustomerSearchValue] =
    useState("");

  const [openToast, setOpenToast] = useState(false);
  const [openPaymentApproveRejectToast, setOpenPaymentApproveRejectToast] =
    useState(false);
  const [vehiclePayoutPeriodList, setVehiclePayoutPeriodList] = useState([
    "trip_wise",
    "monthly_wise",
  ]);
  const [openTripRoutes, setOpenTripRoutes] = useState(false);
  const { enums } = useContext(MyContext);

  // const tripTypeList = ["first_mile", "middle_mile", "last_mile"];

  const handleOpenExportReport = () => {
    setIsExportOpen(true);
    if (readLoginData()) {
      // console.log("readLoginData>>>>", readLoginData().USER_EMAIL);
      if (readLoginData().USER_EMAIL) {
        setExportEmail(readLoginData().USER_EMAIL);
      }
    }
  };
  // const [paymentHistoryListcurrentPage, setPaymentHistoryListcurrentPage] =
  //   useState(1);
  // const [isPaymentHistoryLastPage, setIsPaymentHistoryLastPage] =
  //   useState(false);
  // const [loadingMorePaymentHistory, setLoadingMorePaymentHistory] =
  //   useState(false);
  // const lastPaymentHistoryList = useCallback(
  //   (node) => {
  //     if (loadingMorePaymentHistory) return;
  //     if (observer.current) observer.current.disconnect();
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && !isPaymentHistoryLastPage) {
  //         setPaymentHistoryListcurrentPage(paymentHistoryListcurrentPage + 1);
  //         setLoadingMorePaymentHistory(false);
  //       }
  //     });
  //     if (node) observer.current.observe(node);
  //   },
  //   [
  //     loadingMorePaymentHistory,
  //     isPaymentHistoryLastPage,
  //     paymentHistoryListcurrentPage,
  //   ]
  // );

  const observer = useRef();

  const lastItem = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLastPage) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, isLastPage]
  );

  // const isMobile = window.matchMedia(
  //   "only screen and (max-width: 499px)"
  // ).matches;

  const handleCloseFilterOptions = () => {
    setIsFilterOpen(false);
  };

  const handleClosePickupOptions = () => {
    setIsPickupOpen(false);
  };

  useEffect(() => {
    getStateList();
  }, [enums]);

  const getStateList = async () => {
    // var stateList = await axiosInterceptor.get(
    //   `${apiUrl.onboardingBaseUrl}/v1/enums/permitted-states`
    // );
    //

    // setStateList(stateList.data);
    setStateList(enums?.permitted_states ? enums.permitted_states : []);

    // setNewVillagesList(address.data.office[0].villages);
    // }
  };

  const handleTripsExportReportApi = async () => {
    const isEmailValid = validEmailRegex.test(exportEmail);

    if (isEmailValid && !loadingExportButton) {
      if (exportFromDate && exportToDate) {
        if (exportTripType !== "0") {
          if (returnNumberOfDays() <= 90) {
            setLoadingExportButton(true);
            const response = await getTripExport(
              exportEmail,
              exportFromDate,
              exportToDate,
              exportTripStatus,
              exportCustomer,
              exportVehicleNumber,
              exportTransporter,
              exportVehiclePayoutPeriod,
              exportPaymentStatus,
              exportSourceState,
              exportDestinationState,
              exportCreatedBy,
              exportLoadSource,
              exportTripType
            );
            if (response.status === 200) {
              setOpenToast(true);
              handleCloseExport();
              setExportToastMessage(response.data.message);
              setTripExportTripTypeError("");
              // alert(response.data.message);
              setLoadingExportButton(false);
            } else if (response.status === 400) {
              setErrTotalExport(response.data.detail);
              setLoadingExportButton(false);
              setTripExportTripTypeError("");
            } else {
              setErrTotalExport(`${response.status} : Something went wrong`);
              setLoadingExportButton(false);
              setTripExportTripTypeError("");
            }
          } else {
            setTripExportTripTypeError("Please select from date and to date.");
            setTripExportTripTypeError("Please select trip type");
          }
        } else {
          setTripExportTripTypeError("Please select trip type");
        }
      } else {
        setTripExportTripTypeError("Please select from date and to date");
        if (!exportFromDate && !exportToDate) {
        }
      }
    } else {
      setErrExportEmailId("Please enter valid Email Id");
      setTripExportTripTypeError("");
    }
  };

  const handleCloseExport = () => {
    setIsExportOpen(false);
    resetFormExport();
    setTripExportTripTypeError("");
  };

  useEffect(() => {
    if (readLoginData()) {
      setUserRole(readLoginData().Role);
    }
  }, []);
  // console.log("the data==========", readLoginData());
  // const transporterOptions=()=>{

  // }

  const transporterOptions = (inputValue) =>
    new Promise((resolve) => {
      const response = handleTransporterVehicles(inputValue);
      resolve(response);
    });

  const handleTransporterVehicles = async (searchValue) => {
    const response = await GetTransporterByName(searchValue);
    return response.transporter_list.map((transporter) => {
      return {
        label: transporter.name,
        value: transporter.name,
      };
    });
  };
  //pagination ends

  useEffect(() => {
    if (allTripList?.length > 0) {
      if (indexSelectedData()) {
        if (indexSelectedData().hasOwnProperty("trip")) {
          setSelectedTripIndex(indexSelectedData().trip);
          if (allTripList.length > indexSelectedData().trip) {
            setSelectedTrip(allTripList[indexSelectedData().trip]);
            dispatch(updateSelectedTrip(allTripList[indexSelectedData().trip]));
          } else {
            setSelectedTrip(allTripList[0]);
            dispatch(updateSelectedTrip(allTripList[0]));
            setSelectedTripIndex(0);
          }
        } else {
          setSelectedTrip(allTripList[0]);
          dispatch(updateSelectedTrip(allTripList[0]));
          setSelectedTripIndex(0);
        }
      } else {
        setSelectedTrip(allTripList[0]);
        dispatch(updateSelectedTrip(allTripList[0]));
        setSelectedTripIndex(0);
      }
    }
  }, [allTripList]);

  // useEffect(() => {
  //   if (selectedTripIndex > 0 && allTripList.length > 0) {
  //     history.push("/trips#selectedIndexTrip");
  //   }
  // }, [selectedTripIndex, allTripList]);

  //modal related starts

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    resetForm();
    setOpen(false);
    // handleStatusClick(10, "");
    // handleOpenFullPageLoad();
    setLoading(false);
    // dispatch(updateTripSearchValue(""));
    // localStorage.setItem("FlagForApiCall", true);
    // handleOpen
    handleCloseDetailsDrawer();
    //     (currentStatusUpdateInProgress == "approved" ||
    //     currentStatusUpdateInProgress == "rejected") &&
    //   !loadingModal
    // ) {
    //   handleSubmitApproveReject();
    // } else if (
    //   (currentStatusUpdateInProgress == "reopen" ||
    //     currentStatusUpdateInProgress == "cancelled") &&
    // if (currentStatusUpdateInProgress == "approved") {
    //   handleStatusClick(1, "approved");
    // }
    // if (currentStatusUpdateInProgress == "rejected") {
    //   handleStatusClick(3, "rejected");
    //   setOpen(false);
    // }
    // if (currentStatusUpdateInProgress == "reopen") {
    //   handleStatusClick(4, "reopen");
    // }
    // if (currentStatusUpdateInProgress == "cancelled") {
    //   handleStatusClick(6, "cancelled");
    // }
  };

  const handleOpenFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleOpenPickup = () => {
    setIsPickupOpen(true);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };
  const handleClosePaymentToast = () => {
    setOpenPaymentApproveRejectToast(false);
  };

  const resetFormExport = () => {
    setErrTotalExport("");
    setExportFromDate("");
    setExportToDate("");
    setLoadingExportButton(false);
    setExportTripStatus("0");
    setExportCustomer("");
    setExportCustomerSearchValue("");
    setExportVehicleNumber("");
    setExportTransporter("");
    setExportVehiclePayoutPeriod("0");
    setExportPaymentStatus("0");
    setExportSourceState("0");
    setExportDestinationState("0");
    setExportCreatedBy("");
    setExportLoadSource("0");
    setExportTripType("0");
    setExportEmail("");
    setErrExportEmailId("");
  };

  const resetForm = () => {
    setTripRemarks("");
    setLoadingModal(false);
    setAttachmentTag("");
    setAttachmentType("0");
    setErrTripRemarks("");
    setErrTripStatus("");
    setErrAttachmentTag("");
    setErrAttachmentType("");
    setErrAttachmentFile("");
    setAttachmentCount([1]);
    setAttachmentListArray([
      {
        attachment_tag: "0",
        attachment_type: "",
        attachment_file: null,
      },
    ]);
  };

  //modal related ends

  const handleAddAttachment = () => {
    let attachmentTemp = [...attachmentListArray];
    attachmentTemp.push({
      attachment_tag: "0",
      attachment_type: "",
      attachment_file: null,
    });
    setAttachmentListArray(attachmentTemp);
  };

  //scroll to selected trip code start
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  //scroll to selected trip code end

  const returnNumberOfDays = () => {
    const date1 = new Date(exportFromDate);
    const date2 = new Date(exportToDate);
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = date2.getTime() - date1.getTime();
    const diffInDays = Math.round(diffInTime / oneDay);
    return diffInDays;
  };

  useEffect(() => {
    returnNumberOfDays();
  }, [exportFromDate, exportToDate]);

  const handleGetTripList = async (page) => {
    handleCloseFullPageLoad();
    setLoadingMore(true);
    if (page == 1) {
      setLoading(true);
    }

    const response = await GetTripList(page);
    console.log("the lovcalstorage===", response);
    if (response == 403) {
      localStorage.clear();
      setSessionExpires("session has expired,please login again.");
      history.push("/");
    }
    if (response == 401) {
      setIsUserNotAuthorized(true);
      setAllTripList([]);
    }
    if (response.trip_list.length > 0) {
      if (page > 1) {
        setAllTripList([...allTripList, ...response.trip_list]);
      } else {
        setAllTripList(response.trip_list);
      }
    }
    setIsLastPage(response.is_last_page);
    setLoadingMore(false);
    setLoading(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "auto", // width:
    //   currentStatusUpdateInProgress == "intransit" ||
    //   currentStatusUpdateInProgress == "completed"
    //     ? "50%"
    //     : "35%",
    // height:
    //   currentStatusUpdateInProgress == "intransit" ||
    //   currentStatusUpdateInProgress == "completed"
    //     ? "72%"
    //     : "50%",
    bgcolor: "background.paper",
    border: `1px solid ${colorPalette.black}`,
    borderRadius: 1,
    boxShadow: 24,
    p: 2,
  };

  const handleApproveTrip = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("approved");
    handleOpen();

    e.stopPropagation();
  };
  const handleApproveCompleteTrip = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentButton(e.target.innerText);
    setCurrentStatusUpdateInProgress("requested_for_completion");
    handleOpen();

    e.stopPropagation();
  };
  const handleRejectCompleteTrip = (trip, e) => {
    //
    setSelectedTripId(trip.id);
    setCurrentButton(e.target.innerText);
    setCurrentStatusUpdateInProgress("requested_for_completion");
    handleOpen();

    e.stopPropagation();
  };

  // const handleGetCustomerList = async () => {
  //   const response = await GetCustomerListApi();
  //   if (response) {
  //
  //     setCustomerList(response.customers_list);
  //   }
  // };

  // useEffect(() => {
  //   handleGetCustomerList();
  // }, []);

  const handleSearchCustomer = async (searchValue) => {
    const response = await GetCustomerSearchList(searchValue);
    if (response) {
      setCustomerList(response?.customers_list);
    }
    //
  };

  useEffect(() => {
    if (customerSearchValue) {
      handleSearchCustomer(customerSearchValue);
    }
  }, [customerSearchValue]);

  useEffect(() => {
    if (exportCustomerSearchValue) {
      handleSearchCustomer(exportCustomerSearchValue);
    }
  }, [exportCustomerSearchValue]);

  const handleCancelTrip = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("cancelled");
    handleOpen();
    e.stopPropagation();
  };

  useEffect(() => {
    async function fetchData() {
      const documentTypeData = await GetDocumentEnums();
      setDocumentTypeList(documentTypeData);
    }
    fetchData();
  }, []);

  const onFileChange = (event) => {
    setTripAttachmentFile(event.target.files[0]);
  };

  const handleStartTrip = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("intransit");

    handleOpen();
    e.stopPropagation();
  };

  const handleGetPaymentStatus = async () => {
    const response = await GetTripPaymentStatusEnums();
    setTripPaymentStatusList(response);
  };

  useEffect(() => {
    handleGetPaymentStatus();
  }, []);

  const handleGetTripStatus = async () => {
    const response = await GetTripStatusEnums();
    setTripStatusList(response);
  };

  // useEffect(() => {
  //   handleGetTripStatus();
  // }, []);

  const handleReopenTrip = (trip, e) => {
    setSelectedTripId(trip.id);
    setCurrentStatusUpdateInProgress("reopen");
    handleOpen();
    e.stopPropagation();
  };

  const handleCompleteTrip = (trip) => {
    setSelectedTripId(trip.id);
    // if (selectedTripStatusText == "intransit") {
    localStorage.setItem("IntransiteStatus", selectedTripStatusText);
    // }
    setCurrentStatusUpdateInProgress("completed");
    handleOpen();
    // history.push(`/trips/${trip.id}/complete-trip`);
    history.push(`/old-trips/${trip.id}/trip-complete-request`);
  };
  const handleDisputedCompleteTrip = (trip) => {
    setSelectedTripId(trip.id);
    // if (selectedTripStatusText == "intransit") {
    localStorage.setItem("IntransiteStatus", selectedTripStatusText);
    // }
    setCurrentStatusUpdateInProgress("completed");
    handleOpen();
    // history.push(`/trips/${trip.id}/complete-trip`);
    history.push(`/old-trips/${trip.id}/trip-complete-request`);
  };

  const handleDeliveryInvoicePayment = (trip) => {
    dispatch(
      updateSelectedTripInvoices(
        trip.reference_number ? trip.reference_number : []
      )
    );
    dispatch(
      updatePendingTripInvoiceList(
        trip.pending_reference_numbers ? trip.pending_reference_numbers : []
      )
    );
    setSelectedTripId(trip.id);
    localStorage.setItem("IntransiteStatus", selectedTripStatusText);
    history.push(`/trips/${trip.id}/delivery-invoice-payment`);
  };

  const handlePayTrip = (trip) => {
    setSelectedTripId(trip.id);
    // setCurrentStatusUpdateInProgress("completed");
    // handleOpen();
    // /trips/:tripId/
    history.push(`/trips/${trip.id}/record-payment`);
  };

  const handleRejectTrip = (trip, e) => {
    setCurrentStatusUpdateInProgress("rejected");
    setSelectedTripId(trip.id);
    handleOpen();
    e.stopPropagation();
  };
  // handleOpenFullPageLoad();
  const [openFullPageLoading, setOpenFullPageLoading] = React.useState(false);
  const handleCloseFullPageLoad = () => {
    setOpenFullPageLoading(false);
  };
  const handleOpenFullPageLoad = () => {
    setOpenFullPageLoading(true);
  };

  const handleFilterSearch = async (page) => {
    setLoadingFilterSearchButton(true);
    handleOpenFullPageLoad();
    const tripStatusData = filterTripStatus
      ? filterTripStatus
      : selectedTripStatusText;

    const responseFilter = await GetTripListWithFilter(
      page,
      filterTripCode,
      filterPaymentStatus,
      filterVehicleNumber,
      filterTripStatus ? filterTripStatus : selectedTripStatusText,
      filterReferenceNumber,
      filterCustomerId,
      filterLoadSource,
      filterEwayBillNo,
      filterTripType ? filterTripType : tripType
    );
    console.log(responseFilter, "response filter ++++++++===>");
    if (responseFilter === 401) {
      setIsUserNotAuthorized(true);
      setAllTripList([]);
    }
    if (responseFilter) {
      if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterReferenceNumber ||
        filterCustomerId ||
        filterLoadSource ||
        filterEwayBillNo
      ) {
        // handleStatusClick(10, "");
      }
      if (tripStatusData == "created") {
        // handleStatusClick(0, "created");
        setselectedTripStatus(0);
      }
      if (tripStatusData == "approved") {
        // handleStatusClick(1, "approved");
        setselectedTripStatus(1);
      }
      if (tripStatusData == "completed") {
        // handleStatusClick(5, "completed");
        setselectedTripStatus(5);
      }
      if (tripStatusData == "rejected") {
        // handleStatusClick(6, "rejected");
        setselectedTripStatus(6);
      }
      if (tripStatusData == "reopen") {
        // handleStatusClick(7, "reopen");
        setselectedTripStatus(7);
      }
      if (tripStatusData == "intransit") {
        // handleStatusClick(4, "intransit");
        setselectedTripStatus(4);
      }
      if (tripStatusData == "cancelled") {
        // handleStatusClick(8, "cancelled");
        setselectedTripStatus(8);
      }
      if (tripStatusData == "requested_for_completion") {
        // handleStatusClick(2, "requested_for_completion");
        setselectedTripStatus(2);
      }
      if (tripStatusData == "trip_disputed") {
        // handleStatusClick(3, "trip_disputed");
        setselectedTripStatus(3);
      }

      setSelectedTripStatusText(tripStatusData);
      if (page > 1) {
        setAllTripList([...allTripList, ...responseFilter.trip_list]);
        setIsLastPage(responseFilter.is_last_page);
        setLoadingFilterSearchButton(false);
        handleCloseFilterOptions();
      } else {
        setAllTripList(responseFilter.trip_list);
        setLoadingFilterSearchButton(false);
        setIsLastPage(responseFilter.is_last_page);
        handleCloseFilterOptions();
      }
      // setLoading(false);
    }
    handleCloseFullPageLoad();
  };
  useEffect(() => {
    handleFilterSearch(currentPage);
  }, [selectedTripStatusText, tripType, currentPage]);

  const handleCloseFilterModal = () => {
    setIsFilterOpen(false);
  };

  const handlePickupTrip = (e, trip, index) => {
    setSelectedTripId(trip.id);
    setSelectedTrip(trip);
    dispatch(updateSelectedTrip(trip));
    setSelectedTripIndex(index);
    // history.push(`/trips/${trip?.id}/trip-pickup/${trip?.trip_code}`);
    handleOpenPickup();
    e.stopPropagation();
  };

  // const handleSubmitApproveRemarks = async () => {
  //   setLoadingModal(true);
  //   const response = await approveRejectTrip(
  //     "approved",
  //     tripRemarks,
  //     selectedTripId
  //   );
  //   setLoadingModal(false);
  //   handleClose();
  //
  // };

  // const handleSubmitReject = async () => {
  //   setLoadingModal(true);
  //   const response = await approveRejectTrip(
  //     currentStatusUpdateInProgress,
  //     tripRemarks,
  //     selectedTripId
  //   );
  //   setLoadingModal(false);
  //   handleClose();
  // };

  //   "order_details": [
  //     {
  //         "reference_number": "898678",
  //         "status": "delivered"
  //     },
  //      {
  //         "reference_number": "12345",
  //         "status": "delivered"
  //     }
  // ]

  // const handleSubmitApproveReject = async () => {
  //   setLoadingModal(true);
  //   const response = await approveRejectTrip(
  //     currentStatusUpdateInProgress,
  //     tripRemarks,
  //     selectedTripId
  //   );
  //   if (response.status == 400) {
  //     //
  //     setErrTripStatus(response.data.detail);
  //     setLoadingModal(false);
  //   } else if (response.status == 200) {
  //     setLoadingModal(false);
  //     setCurrentPage(1);
  //     // handleGetTripList(1);
  //     if (tripSearchValue) {
  //       handleSearchTrip(tripSearchValue, currentPage);
  //     } else if (
  //       filterTripCode ||
  //       filterPaymentStatus ||
  //       filterVehicleNumber ||
  //       filterTripStatus ||
  //       filterReferenceNumber ||
  //       filterLoadSource ||
  //       filterCustomerId ||
  //       filterEwayBillNo ||
  //       filterTripType
  //     ) {
  //       handleFilterSearch(currentPage);
  //     } else {
  //       handleGetTripList(currentPage);
  //     }
  //     handleClose();
  //   }
  // };
  const handleSubmitApproveReject = async () => {
    setLoadingModal(true);
    const response = await approveRejectTrip(
      currentStatusUpdateInProgress,
      tripRemarks,
      selectedTripId
    );
    if (response.status == 400) {
      //
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      // handleGetTripList(1);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      }
      if (tripSearchValue && selectedTripStatusText) {
        dispatch(updateTripSearchValue(""));
        handleFilterSearch(currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType ||
        selectedTripStatusText
      ) {
        handleFilterSearch(currentPage);
      } else {
        if (selectedTripStatusText) {
          handleFilterSearch(currentPage);
        }

        // else {
        //
        //   handleGetTripList(currentPage);
        // }
      }
      handleClose();
    }
  };
  const handleSubmitApproveCompleteRequest = async () => {
    setLoadingModal(true);
    const response = await approveCompleteTripRequest(
      "completed",
      tripRemarks,
      selectedTripId
    );
    if (response.status == 400) {
      //
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      // handleGetTripList(1);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      }
      if (tripSearchValue && selectedTripStatusText) {
        dispatch(updateTripSearchValue(""));
        handleFilterSearch(currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType ||
        selectedTripStatusText
      ) {
        handleFilterSearch(currentPage);
      } else {
        if (selectedTripStatusText) {
          handleFilterSearch(currentPage);
        }

        // else {
        //
        //   handleGetTripList(currentPage);
        // }
      }
      handleClose();
    }
  };
  const handleSubmitRejectCompleteRequest = async () => {
    //
    setLoadingModal(true);
    const response = await rejectCompleteTripRequest(
      "rejected",
      tripRemarks,
      selectedTripId,
      checked
    );
    if (response.status == 400) {
      //
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      // handleGetTripList(1);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      }
      if (tripSearchValue && selectedTripStatusText) {
        dispatch(updateTripSearchValue(""));
        handleFilterSearch(currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType ||
        selectedTripStatusText
      ) {
        handleFilterSearch(currentPage);
      } else {
        if (selectedTripStatusText) {
          handleFilterSearch(currentPage);
        }

        // else {
        //
        //   handleGetTripList(currentPage);
        // }
      }
      handleClose();
    }
  };

  const handleSubmitReopenCancel = async () => {
    setLoadingModal(true);
    const response = await reopenCancelTrip(
      currentStatusUpdateInProgress,
      tripRemarks,
      selectedTripId
    );
    if (response.status == 400) {
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      // handleGetTripList(1);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      }
      if (tripSearchValue && selectedTripStatusText) {
        dispatch(updateTripSearchValue(""));
        handleFilterSearch(currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType ||
        selectedTripStatusText
      ) {
        handleFilterSearch(currentPage);
      }
      // else {
      //   handleGetTripList(currentPage);
      // }
      handleClose();
    }
    // setLoadingModal(false);
    // setCurrentPage(1);
    // handleGetTripList(1);

    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth", // for smoothly scrolling
    // });
    // handleClose();
  };

  const handleClearFilter = () => {
    handleGetTripList(1);
    setCurrentPage(1);
    setFilterPaymentStatus("");
    setFilterTripCode("");
    setFilterTripStatus("");
    setFilterVehicleNumber("");
    setFilterTripType("");
    setFilterReferenceNumber("");
    handleCloseFilterOptions();
    setFilterCustomerId("");
    setFilterCustomerData({});
    setFilterEwayBillNo("");
    setFilterLoadSource("");
    handleStatusClick(10, "");
  };

  const handleSubmitStart = async () => {
    setLoadingModal(true);
    const attachmentResponse = TripAttachmentPost(
      tripAttachmentFile,
      selectedTripId,
      attachmentTag,
      attachmentType,
      "start_trip",
      attachmentListArray
    );
    //
    const resolved = await Promise.all(attachmentResponse);
    //
    //
    const response = await startTrip(
      currentStatusUpdateInProgress,
      tripRemarks,
      selectedTripId
    );
    if (response.status == 400) {
      //
      setErrTripStatus(response.data.detail);
      setLoadingModal(false);
    } else if (response.status == 200) {
      setLoadingModal(false);
      setCurrentPage(1);
      // handleGetTripList(1);
      if (tripSearchValue) {
        handleSearchTrip(tripSearchValue, currentPage);
      }
      if (tripSearchValue && selectedTripStatusText) {
        dispatch(updateTripSearchValue(""));
        handleFilterSearch(currentPage);
      } else if (
        filterTripCode ||
        filterPaymentStatus ||
        filterVehicleNumber ||
        filterTripStatus ||
        filterReferenceNumber ||
        filterLoadSource ||
        filterCustomerId ||
        filterEwayBillNo ||
        filterTripType ||
        selectedTripStatusText
      ) {
        handleFilterSearch(currentPage);
      }
      // else {
      //   handleGetTripList(currentPage);
      // }
      handleClose();
    }
    // else {
    //   if (selectedTripStatusText) {
    //

    //     handleFilterSearch(currentPage);
    //   }
    //   // setErrTripStatus("Something went wrong");
    //   setLoadingModal(false);
    // }
  };

  const handleClick = (e) => {
    e.click();
  };

  const handleOpenTripRoute = (e, trip, index) => {
    setSelectedTripId(trip.id);
    setSelectedTrip(trip);
    dispatch(updateSelectedTrip(trip));
    setSelectedTripIndex(index);
    setOpenTripRoutes(true);
    e.stopPropagation();
  };

  const handleCloseTripRoute = () => {
    setOpenTripRoutes(false);
  };

  const handleSubmitStop = async () => {
    setLoadingModal(true);
    const attachmentResponse = await TripAttachmentPost(
      tripAttachmentFile,
      selectedTripId,
      attachmentTag,
      attachmentType,
      "end_trip"
    );
    if (attachmentResponse.status == 400) {
      //
      setErrTripStatus(attachmentResponse.data.detail);
      setLoadingModal(false);
    } else if (attachmentResponse == "success") {
      //
      const response = await stopTrip(
        currentStatusUpdateInProgress,
        tripRemarks,
        selectedTripId,
        tripDeliveredBills
      );
      if (response.status == 400) {
        //
        setErrTripStatus(response.data.detail);
        setLoadingModal(false);
      } else if (response.status == 200) {
        setLoadingModal(false);
        setCurrentPage(1);
        // handleGetTripList(1);
        if (tripSearchValue) {
          handleSearchTrip(tripSearchValue, currentPage);
        } else if (
          filterTripCode ||
          filterPaymentStatus ||
          filterVehicleNumber ||
          filterTripStatus ||
          filterReferenceNumber ||
          filterLoadSource ||
          filterCustomerId ||
          filterEwayBillNo ||
          filterTripType
        ) {
          handleFilterSearch(currentPage);
        } else {
          handleGetTripList(currentPage);
        }
        handleClose();
      }
    }

    // setLoadingModal(false);
    // setCurrentPage(1);
    // handleGetTripList(1);

    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth", // for smoothly scrolling
    // });
    // handleClose();
  };

  const handleSubmitRemarks = async () => {
    //
    // console.log(
    //   "status in progress>><<",
    //   tripRemarks,
    //   attachmentTag,
    //   attachmentType,
    //   tripAttachmentFile
    // );
    if (
      (currentStatusUpdateInProgress == "approved" ||
        currentStatusUpdateInProgress == "rejected") &&
      !loadingModal
    ) {
      handleSubmitApproveReject();
    } else if (
      currentStatusUpdateInProgress == "requested_for_completion" &&
      !loadingModal
    ) {
      if (currentButton == "Reject") {
        handleSubmitRejectCompleteRequest();
      } else if (currentButton == "Approve") {
        handleSubmitApproveCompleteRequest();
      }
    } else if (
      (currentStatusUpdateInProgress == "reopen" ||
        currentStatusUpdateInProgress == "cancelled") &&
      !loadingModal
    ) {
      handleSubmitReopenCancel();
    } else if (currentStatusUpdateInProgress == "intransit" && !loadingModal) {
      if (tripRemarks.trim()) {
        if (
          attachmentListArray
            .map((item) => {
              if (item.attachment_file) {
                if (
                  item.attachment_file.size >
                  process.env.REACT_APP_MAX_FILE_SIZE
                ) {
                  return "greater";
                }
              }
            })
            .indexOf("greater") > -1
        ) {
          return;
        }

        // console.log(
        //   "attchment list file size",
        //   attachmentListArray.map((item) => {
        //     if (item.attachment_file) {
        //       if (item.attachment_file.size > 1000000) {
        //         return "greater";
        //       }
        //     }
        //   })
        // );

        //
        handleSubmitStart();
      } else {
        setErrTripRemarks("Please enter the remarks");
        setErrAttachmentTag("Please enter the document type");
        setErrAttachmentType("Please select the attachment type");
        setErrAttachmentFile("Please select the attachment file");
      }
    } else if (currentStatusUpdateInProgress == "completed" && !loadingModal) {
      if (
        tripRemarks.trim() &&
        attachmentTag &&
        attachmentType.length > 1 &&
        tripAttachmentFile
      ) {
        handleSubmitStop();
      } else {
        setErrTripRemarks("Please enter the remarks");
        setErrAttachmentTag("Please enter the document type");
        setErrAttachmentType("Please select the attachment type");
        setErrAttachmentFile("Please select the attachment file");
      }
    }
  };

  const handleCloseDetailsDrawer = () => {
    toggleDrawer("right", false);
    setImageUrlPdf("");
    setImageUrl("");
    setLoadingPaymentApprove(false);
    setLoadingPaymentRejectedApprove(false);
    setPaymentstatusError("");
    setPaymentRemarks("");
    setTripPaymentHistory([]);
  };

  const handleCreateFaarmsNewTripButton = (e) => {
    localStorage.removeItem("tripType");
    history.push("/old-trips/create-trip");
    localStorage.setItem("IntransiteStatus", selectedTripStatusText);
    localStorage.setItem("createTripSource", "/old-trips");
  };
  useEffect(() => {
    localStorage.setItem("createTripSource", "/old-trips");
  }, []);
  const handleCreateNewTripButton = (tritpType) => {
    localStorage.removeItem("tripType");
    history.push(`/old-trips/create-tripV2/${tritpType}`);

    localStorage.setItem("IntransiteStatus", selectedTripStatusText);
    localStorage.setItem("createTripSource", "/old-trips");
  };

  // const handleCreateNewTripButton = () => {
  //   localStorage.removeItem("tripType");
  //   history.push("/trips/create-trip");
  //   localStorage.setItem("IntransiteStatus", selectedTripStatusText);
  //   localStorage.setItem("createTripSource", "/old-trips");
  //   // localStorage.removeItem("reloadPage");
  //   // const url = "/trips/create-trip";
  //   // window.open(url, "_blank");
  // };

  const handleDeliveredCheckboxToggle = (orderDetail, index) => {
    //
    // setTripDeliveredBills([
    //   ...tripDeliveredBills.filter(
    //     (item) => item.reference_number != orderDetail.reference_number
    //   ),
    //   {
    //     reference_number: orderDetail.reference_number,
    //     status:
    //       orderDetail.status == "delivered" ? "not-delivered" : "delivered",
    //   },
    // ]);
    // console.log("order", [
    //   ...tripDeliveredBills.filter(
    //     (item) => item.reference_number != orderDetail.reference_number
    //   ),
    //   {
    //     reference_number: orderDetail.reference_number,
    //     status:
    //       orderDetail.status == "delivered" ? "not-delivered" : "delivered",
    //   },
    // ]);

    let tripDeliveredBillsTemp = [...tripDeliveredBills];
    tripDeliveredBillsTemp[index]["status"] =
      tripDeliveredBillsTemp[index]["status"] == "delivered"
        ? "not-delivered"
        : "delivered";
    //
    setTripDeliveredBills(tripDeliveredBillsTemp);
  };

  const handleSelectTrip = (trip, index) => {
    //
    setSelectedTrip(trip);

    dispatch(updateSelectedTrip(trip));
    handleOpenDetails();
    setSelectedTripIndex(index);
    let encrypted_data = CryptoJS.AES.encrypt(
      JSON.stringify({ ...indexSelectedData(), trip: index }),
      apiUrl.SALT
    ).toString();
    localStorage.setItem("indexClicked", encrypted_data);
    // console.log(
    //   "selected trip--completed list",
    //   trip.reference_number.map((item) => ({
    //     reference_number: item,
    //     status: "delivered",
    //   }))
    // );
    if (trip.reference_number) {
      setTripDeliveredBills(
        trip.reference_number.map((item) => ({
          reference_number: item,
          status: "delivered",
        }))
      );
    } else {
      setTripDeliveredBills([]);
    }
  };

  const handleEditTrip = (trip) => {
    if (
      trip.customers_details.name === "faarms" ||
      trip.customers_details.name === "Faarms" ||
      trip.customers_details.name === "FAARMS"
    ) {
      dispatch(updateTripDetails(trip));
      dispatch(updateTripSearchValue(""));
      localStorage.setItem("IntransiteStatus", selectedTripStatusText);
      history.push(`/old-trip/${trip.id}/update-trip`);
    } else {
      dispatch(updateTripDetails(trip));
      dispatch(updateTripSearchValue(""));
      localStorage.setItem("IntransiteStatus", selectedTripStatusText);
      history.push(
        `/old-trips/create-tripV2/Customer_${trip.trip_type}/${trip.id}/update-trip`
      );
    }
  };
  // const handleEditTrip = (trip) => {
  //   //
  //   dispatch(updateTripDetails(trip));
  //   dispatch(updateTripSearchValue(""));
  //   localStorage.setItem("IntransiteStatus", selectedTripStatusText);
  //   history.push(`/trips/${trip.id}/update-trip`);
  // };

  const handleOpenDetails = () => {
    //
    toggleDrawer("right", true);
  };
  const handleOpenDetailsDrawer = () => {
    //
    toggleDrawer("right", true);
    setTripDetailsHeading("Trip Details");
  };

  const toggleDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
    setImageUrlPdf("");
    setImageUrl("");
  };
  const toggleDetailsDrawer = (anchor, open) => {
    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    handleSearchTrip(tripSearchValue, currentPage);
    localStorage.setItem("FlagForApiCall", true);
  }, [currentPage, tripSearchValue, filterTripStatus]);

  useEffect(() => {
    if (tripSearchValue == "" && selectedTripStatusText) {
      if (selectedTripStatusText == "intransit") {
        handleStatusClick(4, "intransit");
      } else if (selectedTripStatusText == "created") {
        //
        handleStatusClick(0, "created");
      } else if (selectedTripStatusText == "reopen") {
        handleStatusClick(7, "reopen");
      } else if (selectedTripStatusText == "rejected") {
        handleStatusClick(6, "rejected");
      } else if (selectedTripStatusText == "approved") {
        handleStatusClick(1, "approved");
      } else if (selectedTripStatusText == "requested_for_completion") {
        handleStatusClick(2, "requested_for_completion");
      } else if (selectedTripStatusText == "trip_disputed") {
        handleStatusClick(3, "trip_disputed");
      }

      //  else {
      handleFilterSearch(currentPage);
      // }
      // console.log(
      //   "the clickeddddddd",
      //   selectedTripStatus,
      //   selectedTripStatusText
      // );

      // handleStatusClick(2, "completed");
      // handleStatusClick(selectedTripStatus, selectedTripStatusText);
    }
    if (
      tripSearchValue == "" &&
      selectedTripStatusText.length < 1 &&
      JSON.parse(localStorage.getItem("FlagForApiCall")) == true
    ) {
      //
      // handleGetTripList(currentPage);
    }
  }, [tripSearchValue]);

  const handleAttachmentChange = (e, index) => {
    e.preventDefault();
    let key = e.target.name;
    let value;
    if (key == "attachment_file") {
      value = e.target.files[0];
    } else {
      value = e.target.value;
    }
    //
    //

    let attachmentArrayTemp = [...attachmentListArray];
    attachmentArrayTemp[index][key] = value;
    setAttachmentListArray(attachmentArrayTemp);
  };

  const handleSearchTrip = async (values, page) => {
    try {
      setLoading(true);
      const productResponse = await axiosInterceptor.get(
        `${apiUrl.tripV8BaseUrl}?trip_code=${values}&current_page=${page}`
      );
      console.log(productResponse, "product response in trip list");
      if (productResponse.status === 401) {
        setIsUserNotAuthorized(true);
        setAllTripList([]);
      }
      if (productResponse.data.trip_list.length > 0) {
        setAllTripList(productResponse.data.trip_list);
        setIsLastPage(productResponse.data.is_last_page);
        setSelectedTrip(productResponse.data.trip_list[0]);
        dispatch(updateSelectedTrip(productResponse.data.trip_list[0]));
        setSelectedTripIndex(0);
      } else {
        setAllTripList([]);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAllTripList([]);
    }
  };
  const vertical = "bottom";
  const horizontal = "right";
  const [tripDetailsHeading, setTripDetailsHeading] = useState("");
  const [tripAdvanceCost, setTripAdvanceCost] = useState("");
  const [tripCostTotal, setTripCostTotal] = useState("");
  const [tripHistoryList, setTripHistoryList] = useState([]);
  const [tripAttachmentList, setTripAttachmentList] = useState([]);
  const [loadingAttachmentList, setLoadingAttachmentList] = useState(false);
  const [quantityDeliveryFromHistory, setQuantityDeliveryFromHistory] =
    useState([]);
  const [paymentCompleteInvoiceList, setPaymentCompleteInvoiceList] = useState(
    []
  );
  const [attachmentsDisputeHistory, setAttachmentsDisputeHistory] = useState(
    {}
  );
  const [deliveryPaymentList, setDeliveryPaymentList] = useState([]);
  const [tripPaymentHistory, setTripPaymentHistory] = useState([]);
  const [loadingPaymentHistory, setLoadingPaymentHistory] = useState(false);
  const [deliverItemList, setDeliverItemList] = useState([]);
  const [paymentHistoryArray, setPaymentHistoryArray] = useState([]);
  //
  const handleTripDetails = (head) => {
    //
    // increment(head);
    // deIncrement(head);
    setTripDetailsHeading(head);
    setTripPaymentHistory([]);
  };
  const handleTripDeliveryDetails = (head) => {
    setTripDetailsHeading(head);
    getDeliverItems(selectedTrip.trip_code);
    getInvoicePaymentHistoryApi(selectedTrip.id);
    setTripPaymentHistory([]);
  };
  const handleTripPaymentHistoryDetails = (head) => {
    setTripDetailsHeading(head);
    if (head == "Payment History") {
      getPaymentHistory(selectedTrip.id);
    }
  };
  const handleTripHistoryDetails = (head) => {
    setTripDetailsHeading(head);
    setTripPaymentHistory([]);
    // getDeliverItems(selectedTrip.trip_code);
    getTripHistoryDetails(selectedTrip.trip_code);
  };
  const handleTripAttachmentsDetails = (head) => {
    setTripDetailsHeading(head);
    setTripPaymentHistory([]);
    getTripAttachmentList(selectedTrip.id);
  };
  const handleDriverDetails = (head) => {
    // increment(head);
    setTripDetailsHeading(head);
    setTripPaymentHistory([]);
    getDriverDetails(selectedTrip.driver_id);
  };
  const handleCostDetails = (head) => {
    setTripDetailsHeading(head);
    getTripCostDetails();
    setTripPaymentHistory([]);
  };

  useEffect(() => {
    if (selectedTrip.trip_cost) {
      if (selectedTrip.trip_cost.length > 0) {
        //trip cost
        const tripCostTemp = selectedTrip.trip_cost.filter(
          (item) => item.cost_type === "trip_cost"
        );
        if (tripCostTemp.length > 0) {
          setTripCostTotal(tripCostTemp[0].cost_amount);
        } else {
          setTripCostTotal(0);
        }

        //trip advance
        const tripAdvanceTemp = selectedTrip.trip_cost.filter(
          (item) => item.cost_type === "advance"
        );
        if (tripAdvanceTemp.length > 0) {
          setTripAdvanceCost(tripAdvanceTemp[0].cost_amount);
        } else {
          setTripAdvanceCost(0);
        }
      } else {
        setTripCostTotal(0);
        setTripAdvanceCost(0);
      }
    }
  }, [selectedTrip]);
  //   useEffect(() => {
  //     if (selectedTrip.driver_id) {
  //       getDriverDetails(selectedTrip.driver_id);
  //     }
  //     if (selectedTrip.trip_code) {
  //       getTripHistoryDetails(selectedTrip.trip_code);
  //     }
  //     if (selectedTrip.id) {
  //       getTripAttachmentList(selectedTrip.id);
  //     }
  //   }, [
  //     selectedTrip.driver_id,
  //     selectedTrip.trip_code,
  //     selectedTrip.trip_history_details,
  //     selectedTrip.id,
  //   ]);

  const getDriverDetails = async (id) => {
    const response = await GetDriverDetails(id);

    setDriverDetails(response.primary_driver_data);
    dispatch(updateDriverDetails(response));
  };
  let tokensData = readToken();
  const [imageUrl, setImageUrl] = useState("");
  const [imageUrlPdf, setImageUrlPdf] = useState("");
  const downloadDriverPdf = async (id) => {
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/driver-licence/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    ).then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "document.pdf";
        link.click();
      });
      if (response.status === 403) {
        localStorage.clear();
        window.location.reload();
      }
    });
  };
  const downloadDriverImage = async (id) => {
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/driver-licence/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    ).then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "image.png";
        link.click();
      });
      if (response.status === 403) {
        localStorage.clear();
        window.location.reload();
      }
    });
  };
  const downloadDriverDocs = async (id) => {
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/driver-licence/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    ).then((res) => {
      //
      if (res.headers.get("content-type") === "application/pdf") {
        // setImageUrl("");
        setImageUrl("");
        //
        downloadDriverPdf(id);
        return;
      }
      if (
        res.headers.get("content-type") === "image/png" ||
        res.headers.get("content-type") === "image/jpeg"
      ) {
        setImageUrlPdf("");
        //
        downloadDriverImage(id);
        return;
      }
    });
    // .then((response) => {
    //   response.arrayBuffer().then(function (buffer) {
    //     const url = window.URL.createObjectURL(new Blob([buffer]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.download = "image.png";
    //     link.click();
    //   });
    //   if (response.status === 403) {
    //     localStorage.clear();
    //     window.location.reload();
    //   }
    // });
  };
  const showDriverDocument = async (id) => {
    setLoadingImage(true);
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/driver-licence/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setImageUrl("");
        setLoadingImage(false);
        var file = window.URL.createObjectURL(blob);
        setImageUrlPdf(file);
      })
      .catch((err) => {
        setLoadingImage(false);
      });
  };
  const showDriverDocumentImage = async (id) => {
    setLoadingImage(true);
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/driver-licence/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setLoadingImage(false);
        var file = window.URL.createObjectURL(blob);
        setImageUrl(file);
      })
      .catch((err) => {
        setLoadingImage(false);
      });
  };
  const showDriverDocs = async (id) => {
    setLoadingImage(true);
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/driver-licence/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    ).then((res) => {
      if (res.headers.get("content-type") === "application/pdf") {
        // setImageUrl("");
        setImageUrl("");

        showDriverDocument(id);
        return;
      }
      if (
        res.headers.get("content-type") === "image/png" ||
        res.headers.get("content-type") === "image/jpeg"
      ) {
        setImageUrlPdf("");

        showDriverDocumentImage(id);
        return;
      }
    });
    // .then((res) => res.blob())
    // .then((blob) => {
    //   setLoadingImage(false);
    //   var file = window.URL.createObjectURL(blob);
    //   setImageUrl(file);

    // })
    // .catch((err) => {
    //   setLoadingImage(false);
    // });
  };

  const getTripHistoryDetails = async (trip_code) => {
    // if (tripDetails.trip_history_details.length > 0) {
    //   setTripHistoryList(tripDetails.trip_history_details);
    // }
    const response = await GetTripHistory(trip_code);
    setTripHistoryList(response.trip_history_list);

    setQuantityDeliveryFromHistory(
      response.trip_history_list
        .map((item) => {
          try {
            return JSON.parse(item.remarks)[0];
          } catch (e) {
            return null;
          }
        })
        .filter((item) => item != null)
    );
  };
  const getTripAttachmentList = async (trip_id) => {
    setLoadingAttachmentList(true);
    const response = await GetTripAttachments(trip_id);
    setTripAttachmentList(response.trip_attachments_list);
    setLoadingAttachmentList(false);
  };
  //   let tokensData = readToken();
  const downloadImage = async (id) => {
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/trip-attachment/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = window.document.createElement("a");
          link.href = url;
          link.download = "image.png";
          link.click();
        });

        if (response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      })
      // .then((res) => res.blob())
      // .then((blob) => {
      //   var file = window.URL.createObjectURL(blob);

      //   window.open(file, "_blank");
      // })
      .catch((err) => { });
  };
  const downloadPdf = async (id) => {
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/trip-attachment/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = window.document.createElement("a");
          link.href = url;
          link.download = "document.pdf";
          link.click();
        });

        if (response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      })
      // .then((res) => res.blob())
      // .then((blob) => {
      //   var file = window.URL.createObjectURL(blob);

      //   window.open(file, "_blank");
      // })
      .catch((err) => { });
  };
  const download = async (id) => {
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/trip-attachment/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    ).then((response) => {
      //
      if (response.status === 403) {
        localStorage.clear();
        window.location.reload();
      }
      if (response.headers.get("content-type") === "application/pdf") {
        downloadPdf(id);
        return;
      }
      if (
        response.headers.get("content-type") === "image/png" ||
        response.headers.get("content-type") === "image/jpeg"
      ) {
        downloadImage(id);
        return;
      }
      // response.arrayBuffer().then(function (buffer) {
      //   const url = window.URL.createObjectURL(new Blob([buffer]));
      //   const link = window.document.createElement("a");
      //   link.href = url;
      //   link.download = "image.png";
      //   link.click();
      // });
    });
    // .then((res) => res.blob())
    // .then((blob) => {
    //   var file = window.URL.createObjectURL(blob);

    //   window.open(file, "_blank");
    // })
  };

  const showDownloadPdf = async (id) => {
    setLoadingImage(true);
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/trip-attachment/${prefixSalt()}-${convertToBase64(id)}`,
      // { responseType: "application/pdf" },
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
          Accept: "application/pdf",
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setImageUrl("");
        setLoadingImage(false);
        var file = window.URL.createObjectURL(blob);
        setImageUrlPdf(file);
        // setTransporterImageUrl("");
      })
      .catch((err) => {
        setLoadingImage(false);
      });
  };

  const showImageDownloadUrl = async (id) => {
    setLoadingImage(true);
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/trip-attachment/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setImageUrlPdf("");
        setLoadingImage(false);
        var file = window.URL.createObjectURL(blob);
        setImageUrl(file);
      })
      .catch((err) => {
        setLoadingImage(false);
      });
  };
  const showTripAttachments = async (id) => {
    setLoadingImage(true);
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/trip-attachment/${prefixSalt()}-${convertToBase64(id)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    ).then((res) => {
      if (res.status === 403) {
        localStorage.clear();
        window.location.reload();
      }
      if (res.headers.get("content-type") === "application/pdf") {
        showDownloadPdf(id);
        return;
      }
      if (
        res.headers.get("content-type") === "image/png" ||
        res.headers.get("content-type") === "image/jpeg"
      ) {
        showImageDownloadUrl(id);
        res.blob();
        return;
      }
    });
  };

  const showPaymentImageDownloadUrl = async (id) => {
    setLoadingImage(true);
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/invoice-payment-history/${prefixSalt()}-${convertToBase64(
        id
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setImageUrlPdf("");
        setLoadingImage(false);
        var file = window.URL.createObjectURL(blob);
        setImageUrl(file);

        // window.location.assign(file);
        // window.open(file, "_blank");
      })
      .catch((err) => {
        setLoadingImage(false);
      });
  };
  const [imageError, setImageError] = useState("");
  const showPaymentAttachments = async (id) => {
    setLoadingImage(true);
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/invoice-payment-history/${prefixSalt()}-${convertToBase64(
        id
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    ).then((res) => {
      if (res.status == 401) {
        setLoadingImage(false);
        setImageError(res.statusText);
      }
      if (res.status == 403) {
        localStorage.clear();
        window.location.reload();
      }
      if (res.headers.get("content-type") === "application/pdf") {
        showDownloadPdf(id);
        setImageError("");
        return;
      }
      if (
        res.headers.get("content-type") === "image/png" ||
        res.headers.get("content-type") === "image/jpeg"
      ) {
        showPaymentImageDownloadUrl(id);
        setImageError("");
        res.blob();
        return;
      }
    });
  };
  const downloadImagePayment = async (id) => {
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/invoice-payment-history/${prefixSalt()}-${convertToBase64(
        id
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    )
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = window.document.createElement("a");
          link.href = url;
          link.download = "image.png";
          link.click();
        });

        if (response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      })
      // .then((res) => res.blob())
      // .then((blob) => {
      //   var file = window.URL.createObjectURL(blob);

      //   window.open(file, "_blank");
      // })
      .catch((err) => { });
  };
  const downloadPaymentAttachment = async (id) => {
    fetch(
      `${apiUrl.onboardingBaseUrl
      }/v1/image/invoice-payment-history/${prefixSalt()}-${convertToBase64(
        id
      )}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${readLoginData().AUTH_TOKEN}`,
          "x-app-token": `${tokensData}`,
        },
      }
    ).then((response) => {
      if (response.headers.get("content-type") === "application/pdf") {
        downloadPdf(id);
        return;
      }
      if (
        response.headers.get("content-type") === "image/png" ||
        response.headers.get("content-type") === "image/jpeg"
      ) {
        downloadImagePayment(id);
        return;
      }
      // response.arrayBuffer().then(function (buffer) {
      //   const url = window.URL.createObjectURL(new Blob([buffer]));
      //   const link = window.document.createElement("a");
      //   link.href = url;
      //   link.download = "image.png";
      //   link.click();
      // });
    });
    // .then((res) => res.blob())
    // .then((blob) => {
    //   var file = window.URL.createObjectURL(blob);

    //   window.open(file, "_blank");
    // })
  };
  const getInvoicePaymentHistoryApi = async (tripId) => {
    const invoiceResponse = await GetTripInvoicePaymentByTripId(tripId);
    //
    //   "invoice reposnseee;:::LL",
    //   invoiceResponse.invoice_payments_history_list.map((item) => {
    //     return {
    //       reference_number: item.transaction_reference_id
    //         .split("-")
    //         .splice(1)
    //         .join("-"),
    //       ...item,
    //     };
    //   })
    // );
    if (invoiceResponse) {
      setDeliveryPaymentList(invoiceResponse.invoice_payments_history_list);
      const dupliceRemovedInvoiceList = [
        ...new Set(
          invoiceResponse.invoice_payments_history_list.map(
            (item) => item.reference_number
          )
        ),
      ];
      if (dupliceRemovedInvoiceList.length > 0) {
        setPaymentCompleteInvoiceList(dupliceRemovedInvoiceList);
      } else {
        setPaymentCompleteInvoiceList([]);
      }
    } else {
      setPaymentCompleteInvoiceList([]);
    }
  };

  const getPaymentHistory = async (tripId) => {
    setLoadingPaymentHistory(true);
    const response = await GetTripPaymentHistory(tripId);

    if (response.status === 200) {
      setLoadingPaymentHistory(false);
      setTripPaymentHistory(response.data.invoice_payments_history_list);
    }
    if (response.status == 400) {
      setLoadingPaymentHistory(false);
    }
  };
  const getDeliverItems = async (tripCode) => {
    setPaymentLoading(true);
    const response = await GetTripItemsForDeliveryInfo(tripCode);

    // deliverItemTemp.push(response);
    // setDeliverItemList(deliverItemTemp);

    if (response) {
      setPaymentLoading(false);
      setPaymentHistoryArray(response?.invoice_payment_history);
      setDeliverItemList(
        selectedTrip.reference_number.map((item) => {
          return {
            reference_number: item,
            items_list: response.trip_items_list.filter(
              (items) => items.reference_number === item
            ),
          };
        })
      );
    }
  };

  //   useEffect(() => {
  //     if (selectedTrip.reference_number.length > 0) {
  //       // tripDetails.reference_number.map((item) =>
  //       //   getDeliverItems(tripDetails.id, item)
  //       // );
  //       getDeliverItems(selectedTrip.trip_code);
  //     }
  //   }, [selectedTrip.trip_code]);
  // const vertical = "bottom";
  // const horizontal = "right";
  const [openAlert, setOpenAlert] = React.useState(true);

  // ===========================status tabs=========================================

  const handleStatusClick = (index, status) => {
    //
    setselectedTripStatus(index);
    setSelectedTripStatusText(status);
    // localStorage.removeItem("IntransiteStatus");
    // localStorage.removeItem("IntransiteStatus");
    localStorage.setItem("FlagForApiCall", true);
    // setCurrentSelectedTripStatus(e.target.innerHtml);
    // setFilterTripStatus(status);
    setCurrentPage(1);
  };

  //

  const handleChange = (event) => {
    setTripType(event.target.value);
    setCurrentPage(1);
    //
  };
  const handleKeyPress = (event, tab) => {
    //
    if (event.type == "keyup" && tab == "Trip Details") {
      handleTripDetails("Trip Details");
    }
    if (event.type == "keyup" && tab == "Driver") {
      handleDriverDetails(tab);
    }
    if (event.type == "keyup" && tab == "Trip History") {
      handleTripHistoryDetails(tab);
    }
    if (event.type == "keyup" && tab == "Attachments") {
      handleTripAttachmentsDetails(tab);
    }
    if (event.type == "keyup" && tab == "Delivery Info") {
      handleTripDeliveryDetails(tab);
    }
    if (event.type == "keyup" && tab == "Payment History") {
      handleTripPaymentHistoryDetails(tab);
    }

    if (event.type == "keyup" && tab == "Trip Costs") {
      handleCostDetails(tab);
    }
  };
  // useEffect(()=>{
  //   handleKeyPress(e)
  // })
  // ===============================================================================

  const handleCheckbox = (event) => {
    setChecked(event.target.checked);
  };
  function capitalizeLetter(str) {
    var i,
      splittedArray = str.split("_");
    for (i = 0; i < splittedArray.length; i++) {
      splittedArray[i] =
        splittedArray[i].charAt(0).toUpperCase() + splittedArray[i].slice(1);
    }
    return splittedArray.join(" ");
  }
  // console.log("login======", readLoginData().Role);
  const getDisputeHistory = async (id) => {
    setLoadingImage(true);
    const response = await getTripAttachmentsDispute(id);
    setAttachmentsDisputeHistory(response);
    setLoadingImage(false);
  };
  const handleDropdownItemClick = (e) => { };
  const items = [
    {
      label: <span onClick={handleCreateFaarmsNewTripButton}>Faarms Trip</span>,
      key: "Faarms_first_mile",
    },
    {
      label: (
        <span
          onClick={() => {
            handleCreateNewTripButton("Customer_first_mile");
          }}
        >
          First Mile
        </span>
      ),
      key: "Customer_first_mile",
    },
    {
      label: (
        <span
          onClick={() => {
            handleCreateNewTripButton("Customer_middle_mile");
          }}
        >
          Middle Mile
        </span>
      ),
      key: "Customer_middle_mile",
    },
    {
      label: (
        <span
          onClick={() => {
            handleCreateNewTripButton("Customer_last_mile");
          }}
        >
          Last Mile
        </span>
      ),
      key: "Customer_last_mile",
    },
  ];
  const handleSettings = () => {
    toggTripSettingsDrawer("right", true);
  };
  const handleCloseBtn = () => {
    toggTripSettingsDrawer("right", false);
  };
  const handleGoToTripSettings = () => {
    history.push("/old-trips/trip-settings");
  };
  const getTripCostDetails = async () => {
    setLoadingTripCost(true);
    const response = await GetTripCostApi(selectedTrip?.id);

    if (response.status == 200) {
      setLoadingTripCost(false);
      setTriCostDetails(
        response.data?.filter(
          (data) =>
            data.cost_type !== "advance" && data.cost_type !== "trip_cost"
        )
      );
    }
  };
  const [loadingPaymentApprove, setLoadingPaymentApprove] = useState(false);
  const [succesfulMsg, setSuccesfulMsg] = useState(false);
  const [loadingPaymentApproveReject, setLoadingPaymentApproveReject] =
    useState(false);
  const [loadingPaymentRejectedApprove, setLoadingPaymentRejectedApprove] =
    useState(false);
  const [loadingPaymentRejected, setLoadingPaymentRejected] = useState(false);
  const [paymentstatusError, setPaymentstatusError] = useState("");
  const handleRecommendRejectPayment = async (status, id) => {
    if (status == "recommended") {
      setLoadingPaymentApprove(true);
    } else if (status == "approved") {
      setLoadingPaymentApproveReject(true);
    } else if (status == "rejected") {
      setLoadingPaymentRejected(true);
    }

    //
    const response = await RecommendRejectPayment(status, paymentRemarks, id);

    if (response.status == 200) {
      handleTripPaymentHistoryDetails("Payment History");
      // getPaymentHistory(selectedTrip.id);
      setLoadingPaymentApprove(false);
      setPaymentstatusError("");
      setPaymentRemarks("");
      setLoadingPaymentRejectedApprove(false);
      setLoadingPaymentApproveReject(false);
      setLoadingPaymentRejected(false);
      setOpenPaymentApproveRejectToast(true);
      setSuccesfulMsg(`Payment ${status} successfully.`);
      // setPaymentRemarks("");
    } else if (response.status == 400) {
      setLoadingPaymentApprove(false);
      setLoadingPaymentRejectedApprove(false);
      setLoadingPaymentApproveReject(false);
      setLoadingPaymentRejected(false);
      setPaymentstatusError(response.data.detail);
    } else if (response.status == 401) {
      setLoadingPaymentApprove(false);
      setLoadingPaymentRejectedApprove(false);
      setLoadingPaymentRejected(false);
      setLoadingPaymentApproveReject(false);
      setPaymentstatusError(response.data.detail);
    }

    //
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Trip_list_main_container
      className="trip-list-main-container"
      mode={darkMode}
    >
      {isMobile ? (
        <div className="trip-list-mobile-header">
          <MobileHeader headerTitle="Trips" />
        </div>
      ) : (
        <div className="trip-list-sidebar">
          <SideBarMain />
        </div>
      )}
      <Drawer
        anchor={"right"}
        open={tripSettingsState["right"]}
        onClose={() => {
          toggTripSettingsDrawer("right", false);
        }}
      >
        <Trip_settings_wrapper className="trip-settings-wrapper">
          <div className="trip-settings-head">
            <div>Trip Settings</div>
            <div>
              <CloseIcon
                style={{
                  color: darkMode ? colorPaletteDark.white : "",
                  cursor: "pointer",
                }}
                onClick={handleCloseBtn}
              />
            </div>
          </div>
          <div className="settings_list_container">
            <div
              className="settings_lists"
              onClick={() => {
                handleGoToTripSettings();
              }}
            >
              <div>Consignor whitelisting</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ArrowForwardOutlinedIcon />
              </div>
            </div>
          </div>
        </Trip_settings_wrapper>
        {/* <h6>JWSDJSDJDJSDSJDSJDSJDSD</h6> */}
      </Drawer>
      <Modal
        open={openDisputeModal}
        onClose={handleCloseDistpute}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {loadingImage ? (
            <div
              style={{
                width: "450px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100px",
              }}
            >
              loading data...
            </div>
          ) : (
            <div
              style={{
                width: "450px",
                minHeight: "100%",
                wordBreak: "break-word",
              }}
            >
              <div className="mini-details-grey-text_dispute_top">
                <span className="mini-details-grey-text_dispute">
                  <span className="mini-details-grey-text_dispute_key">
                    Created On:
                  </span>
                  {attachmentsDisputeHistory.created_on
                    ? moment
                      .utc(attachmentsDisputeHistory.created_on)
                      .local()
                      .format("DD-MM-YYYY hh:mm a")
                    : "-"}
                </span>
              </div>
              <div className="mini-details-grey-text_dispute_top_remarks">
                <span className="mini-details-grey-text_dispute">
                  <div className="mini-details-grey-text_dispute_key">
                    Remark:
                  </div>
                  {/* dffddddddddddddddddddddddddddddddddddddddddddhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd */}
                  {attachmentsDisputeHistory.remarks
                    ? attachmentsDisputeHistory.remarks
                    : "-"}
                </span>
              </div>
              <div className="mini-details-grey-text_dispute_bottom_container">
                <span className="mini-details-grey-text_dispute">
                  <div className="mini-details-grey-text_dispute_key">
                    Trip Status:
                  </div>

                  {capitalizeLetter(
                    attachmentsDisputeHistory?.trip_status
                      ? attachmentsDisputeHistory.trip_status
                        .split("")
                        .map((item, index) => {
                          if (index == 0) {
                            return item.toUpperCase();
                          } else {
                            return item;
                          }
                        })
                        .join("")
                      : "-"
                  )}
                </span>
                <span className="mini-details-grey-text_dispute">
                  <div className="mini-details-grey-text_dispute_key_createdby">
                    Created by:
                  </div>

                  {attachmentsDisputeHistory.created_by
                    ? attachmentsDisputeHistory.created_by
                    : "-"}
                </span>
              </div>
              {/* <div className="mini-details-grey-text_dispute_top_remarks">
              
              </div> */}
            </div>

            // <div
            //   className="mini-details-tab-container-history_dispute"
            //   style={{ padding: "1rem .5rem" }}
            // >
            //   <div className="mini-details-tab-left-timeline">
            //     <>
            //       <div className="mini-trip-rectangle-timeline"></div>
            //       <div className="mini-trip-rectangle-timeline"></div>
            //       <div className="mini-trip-rectangle-timeline"></div>
            //       <div className="mini-trip-rectangle-timeline"></div>
            //       <div className="mini-trip-rectangle-timeline"></div>
            //       <div className="mini-trip-rectangle-timeline"></div>
            //       <div className="mini-trip-rectangle-timeline"></div>
            //       <div className="mini-trip-rectangle-timeline"></div>
            //       <div className="mini-trip-rectangle-timeline"></div>
            //       <div className="mini-trip-rectangle-timeline"></div>
            //       <div className="mini-trip-rectangle-timeline"></div>
            //       <div className="mini-trip-rectangle-timeline"></div>
            //     </>
            //   </div>
            //   <div>
            //     <div className="mini-details-single-history">
            //       <h3 className="mini-details-history-head">
            //         <div className="mini-trip-circle"></div>{" "}
            //         {capitalizeLetter(
            //           attachmentsDisputeHistory?.trip_status
            //             ? attachmentsDisputeHistory.trip_status
            //                 .split("")
            //                 .map((item, index) => {
            //                   if (index == 0) {
            //                     return item.toUpperCase();
            //                   } else {
            //                     return item;
            //                   }
            //                 })
            //                 .join("")
            //             : "-"
            //         )}
            //       </h3>
            //       <div className="mini-details-single-list">
            //         <h4 className="mini-details-history-right">
            //           <span className="mini-details-black-text">
            //             {" "}
            //             Remarks :{" "}
            //           </span>
            //           <span className="mini-details-grey-text">
            //             {" "}
            //             {attachmentsDisputeHistory.remarks
            //               ? attachmentsDisputeHistory.remarks
            //               : "-"}
            //           </span>
            //         </h4>{" "}
            //       </div>
            //       <div className="mini-details-single-list">
            //         <h4 className="mini-details-history-right">
            //           <span className="mini-details-black-text">
            //             {" "}
            //             Last Modified :{" "}
            //           </span>
            //           <span className="mini-details-grey-text">
            //             {attachmentsDisputeHistory.modified_on
            //               ? moment
            //                   .utc(attachmentsDisputeHistory.modified_on)
            //                   .local()
            //                   .format("DD-MM-YYYY hh:mm a")
            //               : "-"}
            //           </span>
            //         </h4>{" "}
            //       </div>

            //       <div className="mini-details-single-list">
            //         <h4 className="mini-details-history-left">

            //         </h4>
            //         <h4 className="mini-details-history-right">
            //           <span className="mini-details-black-text">
            //             Created on :{" "}
            //           </span>
            //           <span className="mini-details-grey-text">
            //             {attachmentsDisputeHistory.created_on
            //               ? moment
            //                   .utc(attachmentsDisputeHistory.created_on)
            //                   .local()
            //                   .format("DD-MM-YYYY hh:mm a")
            //               : "-"}
            //           </span>
            //         </h4>{" "}
            //       </div>
            //       <div className="mini-details-single-list">
            //         <h4 className="mini-details-history-left">

            //         </h4>
            //         <h4 className="mini-details-history-right">
            //           <span className="mini-details-black-text">
            //             Created by :{" "}
            //           </span>
            //           <span className="mini-details-grey-text">
            //             {attachmentsDisputeHistory.created_by
            //               ? attachmentsDisputeHistory.created_by
            //               : "-"}
            //           </span>
            //         </h4>{" "}
            //       </div>
            //     </div>
            //   </div>
            // </div>
          )}
        </Box>
      </Modal>
      <ModalAntd
        open={sessionStorage.getItem("IS_400_ERROR") === "true"}
        closable={false}
        footer={null}
        width={isMobile ? "95vw" : "50vw"}
        zIndex={9999999}
      >
        <div style={{ width: "100%", height: "100%" }}>
          <SomethingWentWrong backButtonLink="/dashboard" />
        </div>
      </ModalAntd>
      <Modal
        open={openTripDocs}
        onClose={handleCloseImage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {loadingImage ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <img src={imageloader} alt="" width="20%" height="20%" />
              <div>Loading...</div>
            </div>
          ) : imageError ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <DangerousIcon style={{ color: "red" }} />
              {/* <img src={imageloader} alt="" width="20%" height="20%" /> */}
              <div
                style={{
                  color: "red",
                  // displa: "flex",
                  // flexDirection: "column",
                  // alignItems: "center",
                  // justifyContent: "center",
                  width: "100%",
                }}
              >
                <span>{imageError}</span>
              </div>
            </div>
          ) : (
            <>
              {imageUrlPdf ? (
                <embed
                  src={imageUrlPdf}
                  type="application/pdf"
                  width="500px"
                  height="600px"
                ></embed>
              ) : imageUrl ? (
                <TransformWrapper
                  initialScale={1}
                  initialPositionX={0}
                  initialPositionY={0}
                >
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                      <div style={{ margin: "10px 0px" }}>
                        <button
                          style={{ padding: "3px" }}
                          onClick={() => zoomIn()}
                        >
                          Zoom In
                        </button>
                        <button
                          style={{ padding: "3px", marginLeft: "5px" }}
                          onClick={() => zoomOut()}
                        >
                          Zoom Out
                        </button>
                        <button
                          style={{ padding: "3px", marginLeft: "5px" }}
                          onClick={() => resetTransform()}
                        >
                          Reset
                        </button>
                      </div>
                      <TransformComponent>
                        <img
                          src={imageUrl}
                          alt=""
                          // style={{ objectFit: "cover" }}
                          width={isMobile ? "100%" : "600px"}
                          height={isMobile ? "200px" : "600px"}
                        />
                      </TransformComponent>
                    </>
                  )}
                </TransformWrapper>
              ) : null}
            </>
          )}
        </Box>
      </Modal>

      <Snackbar
        open={openToast}
        autoHideDuration={8000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleCloseToast}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleCloseToast}
          severity="success"
          sx={{ width: "100%" }}
        >
          {exportToastMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openPaymentApproveRejectToast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical, horizontal }}
        onClose={handleClosePaymentToast}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleClosePaymentToast}
          severity={
            succesfulMsg
              ? succesfulMsg.includes("rejected")
                ? "error"
                : "success"
              : "success"
          }
          sx={{ width: "100%" }}
        >
          {succesfulMsg}
        </Alert>
      </Snackbar>
      <Dialog
        onClose={handleCloseFullPageLoad}
        onClick={(e) => e.stopPropagation()}
        open={openFullPageLoading}
        style={{ backgroundColor: "none" }}
      // disabled={loading}
      >
        <div
          style={{
            padding: ".5rem 1rem",
            // display: "flex",
            width: "200x",
            height: "150px",
            // flexDirection: "column",
          }}
        >
          <div>
            <img
              src={success}
              alt=""
              width="120px"
              height="90px"
            // style={{ marginTop: "200px" }}
            />
            <div style={{ color: "grey" }}>loading data...</div>
          </div>
        </div>
      </Dialog>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: -1 }}
        open={openFullPageLoading}
        onClick={handleCloseFullPageLoad}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {/*Filter modal starts*/}
      <StyledModal
        open={isFilterOpen}
        onClose={handleCloseFilterOptions}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={filterBoxStyle}>
          <Trip_list_filter_main className="trip-list-filter-main">
            {isMobile ? (
              <div className="trip-list-filter-head">
                <span className="trip-list-filter-main-text">
                  Select filter options
                </span>{" "}
                <Close onClick={handleCloseFilterModal} />
              </div>
            ) : (
              <div className="trip-list-filter-head">
                <span
                  className="trip-list-filter-main-text"
                  style={{ marginLeft: "5px" }}
                >
                  Select filter options
                </span>{" "}
                <Close
                  onClick={handleCloseFilterModal}
                  style={{ cursor: "pointer" }}
                />
              </div>
            )}

            <div className="trip-list-filter-container">
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Trip code</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip code"
                    className="trip-list-filter-inputfield"
                    value={filterTripCode}
                    onChange={(e) => setFilterTripCode(e.target.value)}
                    variant="standard"
                  />
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Payment status</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="standard"
                    select
                    // placeholder="Enter payment status"
                    className="trip-list-filter-inputfield"
                    value={filterPaymentStatus}
                    onChange={(e) => setFilterPaymentStatus(e.target.value)}
                  >
                    {tripPaymentStatusList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Vehicle number</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-filter-inputfield"
                    variant="standard"
                    value={filterVehicleNumber}
                    onChange={(e) => setFilterVehicleNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Trip status</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip status"
                    variant="standard"
                    className="trip-list-filter-inputfield"
                    select
                    value={filterTripStatus}
                    onChange={(e) => setFilterTripStatus(e.target.value)}
                  >
                    {tripStatusList.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </TextField>
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Trip Type</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip status"
                    variant="standard"
                    className="trip-list-filter-inputfield"
                    select
                    value={filterTripType}
                    onChange={(e) => {
                      setFilterTripType(e.target.value);
                      setTripExportTripTypeError("");
                    }}
                  >
                    {tripTypeList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">LR Number</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-filter-inputfield"
                    variant="standard"
                    value={filterReferenceNumber}
                    onChange={(e) => setFilterReferenceNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Customer Name</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    // value={
                    //   Object.keys(selectedCustomer).length > 0
                    //     ? selectedCustomer
                    //     : ""
                    // }
                    defaultValue={
                      Object.keys(filterCustomerData).length > 0
                        ? filterCustomerData
                        : { name: "" }
                    }
                    onChange={(event, newValue) => {
                      // setValue(newValue);
                      if (newValue) {
                        setFilterCustomerId(newValue?.id);
                        setFilterCustomerData(newValue);
                        setCustomerSearchValue(newValue.name);
                        setCustomerList([]);
                        // if (!newValue.name.toLowerCase().includes("faarms")) {
                        //   setLoadSource("manual");
                        // }
                        //
                      } else {
                        // setSelectedCustomer({});
                      }
                      //
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        setCustomerSearchValue(newInputValue);
                      }
                    }} // sx={{ width: 300 }}
                    style={{ width: "90%" }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} variant="standard" />
                    )}
                  />
                  {/* <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    select
                    className="trip-list-filter-inputfield"
                    variant="standard"
                    value={filterCustomerId}
                    onChange={(e) => setFilterCustomerId(e.target.value)}
                  >
                    {customerList.length > 0
                      ? customerList.map((item) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })
                      : null}
                  </TextField> */}
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">Load Source</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-filter-inputfield"
                    select
                    variant="standard"
                    value={filterLoadSource}
                    onChange={(e) => setFilterLoadSource(e.target.value)}
                  >
                    {loadSourceList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div className="trip-list-filter-row">
                <span className="trip-list-filter-text">E-Way Bill No.</span>
                <div className="trip-list-filter-input-right">
                  {" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-filter-inputfield"
                    variant="standard"
                    value={filterEwayBillNo}
                    onChange={(e) => setFilterEwayBillNo(e.target.value)}
                  />
                </div>
              </div>
              <div className="trip-list-filter-button-row">
                <button
                  className="trip-list-filter-button"
                  onClick={handleClearFilter}
                >
                  Clear
                </button>
                <button
                  className="trip-list-filter-button"
                  onClick={() => {
                    handleFilterSearch(1);
                    setCurrentPage(1);
                  }}
                >
                  {loadingFilterSearchButton ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Search"
                  )}
                </button>
              </div>
            </div>
          </Trip_list_filter_main>
        </Box>
      </StyledModal>
      {/*Filter modal ends*/}

      {/*Pickup modal starts*/}
      <StyledModal
        open={isPickupOpen}
        onClose={handleClosePickupOptions}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={pickupBoxStyle}>
          <Trip_Pickup_modal
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="trip-pickup-modal-head"
                style={{ fontSize: "16px", fontWeight: "600" }}
              >
                <div> Trip Pickup </div>{" "}
                <div style={{ marginLeft: "4px" }}>
                  {" "}
                  ( Trip code : {selectedTrip?.trip_code} )
                </div>
              </div>
              <div>
                <Close
                  onClick={handleClosePickupOptions}
                  style={{ cursor: "pointer" }}
                />{" "}
              </div>
            </div>
            <TripPickup
              tripId={selectedTrip?.id}
              tripCode={selectedTrip?.trip_code}
            />
          </Trip_Pickup_modal>
        </Box>
      </StyledModal>
      {/*Pickup modal ends*/}

      {/*Trip routes modal starts*/}
      <StyledModal
        open={openTripRoutes}
        onClose={handleCloseTripRoute}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={tripRoutesBoxStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                className="trip-pickup-modal-head"
                style={{ fontSize: "16px", fontWeight: "600" }}
              >
                <div> Trip Routes </div>{" "}
                <div style={{ marginLeft: "4px" }}>
                  {" "}
                  ( Trip code : {selectedTrip?.trip_code} )
                </div>
              </div>
              <div>
                <Close
                  onClick={handleCloseTripRoute}
                  style={{ cursor: "pointer" }}
                />{" "}
              </div>
            </div>
            <div>
              <div className="trip-list-address">
                <div className="trip-location-list">
                  {selectedTrip.source?.map((source) => {
                    return <div className="trip-location-text">From</div>;
                  })}
                  {/* <div className="trip-location-text">From</div> */}
                  {selectedTrip.destination?.map((destinations) => {
                    return <div className="trip-location-text">To</div>;
                  })}
                </div>
                <div className="trip-list-path">
                  {/* <div className="trip-circle"></div>
                  <div className="mini-trip-rectangle"></div> */}
                  {selectedTrip.source?.map((source, index) => {
                    return (
                      <>
                        {index == 0 ? null : (
                          <>
                            <div className="trip-circle"></div>
                            <div
                              className="mini-trip-rectangle"
                              style={{
                                backgroundColor: "grey",
                                height: "40px",
                                width: "2px",
                              }}
                            ></div>
                          </>
                        )}
                      </>
                    );
                  })}
                  {selectedTrip.destination?.map((destinations) => {
                    return (
                      <>
                        <div className="trip-circle"></div>
                        <div
                          className="mini-trip-rectangle"
                          style={{
                            backgroundColor: "grey",
                            height: "40px",
                            width: "2px",
                          }}
                        ></div>
                      </>
                    );
                  })}
                  <div className="trip-circle"></div>
                </div>
                <div className="trip-location-list">
                  {/* <div className="trip-location-text"> */}
                  {selectedTrip.source?.map((source) => {
                    return (
                      <div className="trip-location-text">
                        {source.village === source.district
                          ? source.village
                          : `${source.village},
                          ${source.district}`}{" "}
                        , {source.state}, {source.postcode}
                      </div>
                    );
                  })}

                  {selectedTrip.destination?.map((destinations) => {
                    return (
                      <div className="trip-location-text">
                        {destinations.village === destinations.district
                          ? destinations.district
                          : `${destinations.village}, ${destinations.district}`}
                        , {destinations.state}, {destinations.postcode}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>
      {/*Trip routes modal ends*/}

      {/* customer_name
      created_by
      load_source
      trip_status
      payment_status
      trip_cost
      item_sku
      reference_number
      vehicle_number
      vehicle_payout_period
      transporter_name
      destination_state
      source_state
      from_date
      to_date */}

      {/*Export report modal starts*/}
      <StyledModal
        open={isExportOpen}
        onClose={handleCloseExport}
        BackdropComponent={Backdrop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={exportBoxStyle}>
          <div className="trip-list-export-main">
            <div
              className="trip-list-filter-head"
              style={{ margin: "5px 0px" }}
            >
              <span className="trip-list-filter-main-text">
                Trip Export Report
              </span>{" "}
              <Close
                onClick={handleCloseExport}
                style={{ cursor: "pointer" }}
              />
            </div>

            <div className="trip-list-export-container">
              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Email Id : </h4>
                  <TextField
                    id="standard-basic"
                    style={{ width: "98%" }}
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    placeholder="Enter email address"
                    className="trip-list-export-inputfield"
                    value={exportEmail}
                    onChange={(e) => setExportEmail(e.target.value)}
                  />
                  <div className="trip-list-error">
                    {validEmailRegex.test(exportEmail)
                      ? null
                      : errExportEmailId}
                  </div>
                </div>
              </div>
              <div className="trip-list-export-options-head">
                <h4
                  className="trip-list-export-text"
                  style={{ fontSize: "15px" }}
                >
                  Filter Options (Optional)
                </h4>
              </div>
              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">From Date</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    type="date"
                    // placeholder="Enter payment status"
                    className="trip-list-export-inputfield"
                    value={exportFromDate}
                    onChange={(e) => setExportFromDate(e.target.value)}
                  />
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">To Date</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    size="small"
                    type="date"
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    value={exportToDate}
                    onChange={(e) => setExportToDate(e.target.value)}
                  />
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Trip status</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    placeholder="Enter trip status"
                    variant="outlined"
                    className="trip-list-export-inputfield"
                    select
                    size="small"
                    value={exportTripStatus}
                    onChange={(e) => setExportTripStatus(e.target.value)}
                  >
                    <MenuItem disabled value={"0"}>
                      Select
                    </MenuItem>
                    {tripStatusList.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Customer</h4>{" "}
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={customerList}
                    defaultValue={
                      Object.keys(filterCustomerData).length > 0
                        ? filterCustomerData
                        : { name: "" }
                    }
                    onChange={(event, newValue) => {
                      // setValue(newValue);
                      if (newValue) {
                        setExportCustomer(newValue?.name);
                        setExportCustomerSearchValue(newValue.name);
                        setCustomerList([]);
                        // if (!newValue.name.toLowerCase().includes("faarms")) {
                        //   setLoadSource("manual");
                        // }
                        //
                      } else {
                        // setSelectedCustomer({});
                      }
                      //
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (event?.type === "change") {
                        setExportCustomerSearchValue(newInputValue);
                      }
                    }} // sx={{ width: 300 }}
                    style={{ width: "90%" }}
                    // sx={{ padding: "0px" }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                  {/* <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    select
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    size="small"
                    value={exportCustomer}
                    onChange={(e) => setExportCustomer(e.target.value)}
                  >
                    <MenuItem disabled value={"0"}>
                      Select
                    </MenuItem>
                    {customerList.length > 0
                      ? customerList.map((item) => {
                          return (
                            <MenuItem value={item.name}>{item.name}</MenuItem>
                          );
                        })
                      : null}
                  </TextField> */}
                </div>
              </div>
              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Vehicle number</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    placeholder="Enter vehicle number"
                    className="trip-list-export-inputfield"
                    value={exportVehicleNumber}
                    onChange={(e) => setExportVehicleNumber(e.target.value)}
                  />
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Transporter</h4>{" "}
                  <AsyncSelect
                    className="trip-list-export-inputfield"
                    defaultOptions
                    // key={loadSource}
                    placeholder="search..."
                    loadOptions={(inputValue) => transporterOptions(inputValue)}
                  // value={
                  //   vehicleNumber !== "0"
                  //     ? { value: vehicleNumber, label: vehicleNumber }
                  //     : ""
                  // }
                  // value={vehicleOptions.filter(
                  //   (option) => option.value === vehicleNumber
                  // )}
                  // value={purchaseOrderId}
                  // onInputChange={(newValue) =>
                  //   console.log("purchase order", newValue)
                  // }
                  // onChange={(e) => {
                  //   setVehicleNumber(e.value);
                  //   setVehiclePayoutPeriod(e.payout_period);
                  //   if (e.payout_period == "monthly_wise") {
                  //     setPerKmRate(e.extra_per_km_rate);
                  //   } else {
                  //     setPerKmRate("");
                  //   }
                  // }}
                  // loadOptions={(inputValue) =>
                  //   vehicleOptions(inputValue, loadSource)
                  // }
                  />
                  {/* <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    select
                    size="small"
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    value={exportTransporter}
                    onChange={(e) => setExportTransporter(e.target.value)}
                  >
                    <MenuItem>dnnsd</MenuItem>
                  </TextField> */}
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">
                    Vehicle payout period
                  </h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter trip status"
                    variant="outlined"
                    className="trip-list-export-inputfield"
                    select
                    size="small"
                    value={exportVehiclePayoutPeriod}
                    onChange={(e) =>
                      setExportVehiclePayoutPeriod(e.target.value)
                    }
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {vehiclePayoutPeriodList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Payment Status</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    select
                    size="small"
                    value={exportPaymentStatus}
                    onChange={(e) => setExportPaymentStatus(e.target.value)}
                  >
                    <MenuItem value="0" disabled>
                      Select
                    </MenuItem>
                    {tripPaymentStatusList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Source state</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    select
                    // placeholder="Enter payment status"
                    className="trip-list-export-inputfield"
                    value={exportSourceState}
                    onChange={(e) => setExportSourceState(e.target.value)}
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {stateList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Destination state</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    size="small"
                    select
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    value={exportDestinationState}
                    onChange={(e) => setExportDestinationState(e.target.value)}
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {stateList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Created By</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    placeholder="Enter created by"
                    variant="outlined"
                    className="trip-list-export-inputfield"
                    size="small"
                    value={exportCreatedBy}
                    onChange={(e) => setExportCreatedBy(e.target.value)}
                  />
                </div>
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Load Source</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    // placeholder="Enter vehicle number"
                    className="trip-list-export-inputfield"
                    variant="outlined"
                    size="small"
                    select
                    value={exportLoadSource}
                    onChange={(e) => setExportLoadSource(e.target.value)}
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {loadSourceList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>

              <div className="trip-list-export-row">
                <div className="trip-list-export-input-container">
                  <h4 className="trip-list-export-text">Trip type</h4>{" "}
                  <TextField
                    id="standard-basic"
                    // label="Standard"
                    variant="outlined"
                    size="small"
                    select
                    // placeholder="Enter payment status"
                    className="trip-list-export-inputfield"
                    value={exportTripType}
                    onChange={(e) => setExportTripType(e.target.value)}
                  >
                    <MenuItem disabled value="0">
                      Select
                    </MenuItem>
                    {tripTypeList.map((item) => {
                      return (
                        <MenuItem value={item}>
                          {item.split("_").join(" ")}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
                <div className="trip-list-export-input-container"></div>
                <div className="trip-list-export-input-container"></div>
                <div className="trip-list-export-input-container"></div>
              </div>
              <div className="trip-list-filter-button-row">
                {errTotalExport ? (
                  <Alert severity="error" style={{ width: "100%" }}>
                    {errTotalExport}
                  </Alert>
                ) : null}
              </div>
              <div className="trip-list-filter-button-row">
                {returnNumberOfDays() > 90 ? (
                  <Alert severity="error" style={{ width: "100%" }}>
                    Trips report cannot be exported beyond 90 days
                  </Alert>
                ) : null}
              </div>

              <div className="trip-list-filter-button-row">
                {tripExportTripTypeError ? (
                  <Alert severity="error" style={{ width: "100%" }}>
                    {tripExportTripTypeError}
                  </Alert>
                ) : null}
              </div>

              <div className="trip-list-filter-button-row">
                <button
                  className="trip-list-filter-button"
                  onClick={handleCloseExport}
                >
                  Cancel
                </button>
                <button
                  className="trip-list-export-button"
                  onClick={() => {
                    handleTripsExportReportApi();
                  }}
                >
                  {loadingExportButton ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Export"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Box>
      </StyledModal>
      {/*Export report modal ends*/}

      {/* reject remarks modal starts */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Trip_list_modal_container className="trip-list-modal-container">
            <div className="trip-list-close">
              <Close style={{ cursor: "pointer" }} onClick={handleClose} />
            </div>
            <h4 className="trip-list-modal-head">
              Please enter the remarks (max. 300 characters) :
            </h4>
            <textarea
              className="trip-list-modal-text-input"
              value={tripRemarks}
              onChange={(e) => {
                if (e.target.value.length <= 300) {
                  setTripRemarks(e.target.value);
                }
              }}
              rows={5}
            />
            <div className="trip-list-error">
              {tripRemarks.trim() ? null : errTripRemarks}
            </div>
            {currentStatusUpdateInProgress == "requested_for_completion" &&
              currentButton == "Reject" ? (
              <div className="checkbox_container">
                <Checkbox
                  checked={checked}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                  style={{ padding: "0" }}
                  onChange={handleCheckbox}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <span className="checkbox_text">
                  Delete end trip attachments.
                </span>
              </div>
            ) : null}

            {currentStatusUpdateInProgress == "intransit" ||
              currentStatusUpdateInProgress == "completed" ? (
              <div className="trip-list-modal-attachment-container">
                {attachmentListArray.map((item, index) => {
                  return (
                    <div key={index} className="trip-list-modal-row">
                      <div className="trip-list-modal-input-group">
                        <h4 className="trip-list-modal-head">
                          Document type {index + 1}
                        </h4>
                        {/*new attchment*/}
                        <TextField
                          size="small"
                          id="dropdown"
                          select
                          autoComplete="off"
                          className="trip-list-input-attachment dropdown-container"
                          variant="outlined"
                          placeholder="Enter document type"
                          name="attachment_tag"
                          // disabled={index == 0 ? true : false}
                          value={item.attachment_tag}
                          onChange={(e) => {
                            handleAttachmentChange(e, index);
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{
                                  cursor: "pointer",
                                  color: `${colorPalette.primaryColor}`,
                                  fontWeight: "600",
                                }}
                              // onClick={handleAddAttachment}
                              >
                                <button className="upload_button">
                                  <input
                                    // name="upload"
                                    name="attachment_file"
                                    id={`upload${index}`}
                                    type="file"
                                    accept="image/png, image/jpeg, application/pdf"
                                    onChange={(e) => {
                                      handleAttachmentChange(e, index);
                                      handleAddAttachment(e);
                                      //
                                    }}
                                    hidden
                                    style={{
                                      backgroundColor: `${colorPalette.secondaryWhite}`,
                                    }}
                                  />
                                  <label
                                    htmlFor={`upload${index}`}
                                    style={{
                                      backgroundColor: `${colorPalette.secondaryWhite}`,
                                    }}
                                  >
                                    <img
                                      src={UploadImage}
                                      alt=""
                                      width="20px"
                                      height="20px"
                                      style={{
                                        backgroundColor: `${colorPalette.secondaryWhite}`,
                                        // width: "100%",
                                        // height: "100%",
                                      }}
                                    />
                                  </label>
                                </button>
                              </InputAdornment>
                            ),
                          }}
                        >
                          <MenuItem value="0" disabled>
                            Select document type
                          </MenuItem>
                          {/* {console.log("load unit", loadUnitList)} */}
                          {documentTypeList.map((item) => {
                            return (
                              <MenuItem value={item}>
                                {item
                                  .split("_")
                                  .join(" ")
                                  .split("")
                                  .map((item, index) => {
                                    if (index == 0) {
                                      return item.toUpperCase();
                                    } else {
                                      return item;
                                    }
                                  })
                                  .join("")}
                              </MenuItem>
                            );
                          })}
                        </TextField>

                        <div className="trip-list-attachment-name">
                          {item.attachment_file != null
                            ? item.attachment_file.name
                            : null}
                        </div>
                        <div className="error-trip">
                          {item.attachment_file
                            ? item.attachment_file.size >
                              process.env.REACT_APP_MAX_FILE_SIZE
                              ? `File size is too large.( < ${process.env.REACT_APP_MAX_FILE_SIZE / 1000000}MB)`
                              : null
                            : null}
                        </div>
                        {/*new attchment*/}
                      </div>
                    </div>
                  );
                })}
                {isMobile ? null : (
                  <div className="trip-list-modal-row">
                    <div className="trip-list-modal-input-group"></div>
                    <div className="trip-list-modal-input-group"></div>
                    <div className="trip-list-modal-input-group"></div>
                  </div>
                )}
              </div>
            ) : null}
            {errTripStatus ? (
              <Alert severity="error">{errTripStatus}</Alert>
            ) : null}

            <div className="trip-list-modal-btn-grp">
              {" "}
              <button
                className="trip-list-submit-btn"
                onClick={handleSubmitRemarks}
              >
                {loadingModal ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </Trip_list_modal_container>
        </Box>
      </Modal>
      {/* =============================new details design=================================================== */}
      <Drawer
        anchor={"right"}
        open={state["right"]}
        // style={{ height: "100vh" }}
        onClose={() => {
          toggleDrawer("right", false);
          setLoadingPaymentApprove(false);
          setLoadingPaymentRejectedApprove(false);
          setPaymentstatusError("");
          setPaymentRemarks("");
          setTripPaymentHistory([]);
        }}
      >
        <Trip_list_drawer_top_row
          className="trip-list-drawer-top-row"
          style={{ width: isMobile ? "" : "600px" }}
          mode={darkMode}
        >
          <div className="details_drawer_topbar">
            <span className="trip-list-trip-details-drawer-head-v2">
              {tripDetailsHeading}
            </span>

            <Close
              onClick={handleCloseDetailsDrawer}
              style={{
                color: darkMode ? colorPaletteDark.white : colorPalette.black,
              }}
            />
            {/* Trip details */}
            {/* </span> */}
          </div>
          {/* <div> */}
          <div className="tl-new-right_drawer">
            {selectedTrip.trip_status == "created" ? (
              <div className="trip-list-right-most-single-container_drawer">
                <div className="trip-list-right-most-btn-container">
                  <ButtonRectangle
                    // className="trip-list-approve-btn"
                    onClick={(e) => handleApproveTrip(selectedTrip, e)}
                  >
                    <CheckCircleOutlineIcon
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    Approve
                  </ButtonRectangle>
                </div>
                <div className="trip-list-right-most-btn-container">
                  <ButtonRectangleSecondary
                    // className="trip-list-reject-btn"
                    onClick={(e) => handleRejectTrip(selectedTrip, e)}
                  >
                    <CancelIcon
                      style={{
                        fontSize: "16px",
                        color: "#262626",
                        marginRight: "5px",
                      }}
                    />
                    Reject
                  </ButtonRectangleSecondary>
                </div>
                <div className="trip-list-right-most-btn-container">
                  <ButtonRectangleSecondary
                    className="trip-list-edit-btn_v2"
                    onClick={() => handleEditTrip(selectedTrip)}
                  // onClick={(e) => handleApproveTrip(selectedTrip, e)}
                  >
                    <Edit style={{ fontSize: "16px", marginRight: "5px" }} />
                    Edit
                  </ButtonRectangleSecondary>
                </div>
              </div>
            ) : (selectedTrip.trip_status == "created" ||
              selectedTrip.trip_status == "reopen") &&
              userRole == "Logistics Incharge" ? (
              <div style={{ display: "flex" }} className="trip-list-waiting">
                <h5 style={{ color: "grey" }}>waiting for the approval</h5>
              </div>
            ) : selectedTrip.trip_status == "rejected" ? (
              <div className="trip-list-right-most-single-container_drawer">
                <div className="trip-list-right-most-btn-container">
                  {/* <button
                    className="trip-list-approve-btn_v2"
                    onClick={(e) => handleReopenTrip(selectedTrip, e)}
                  >
                    <RestartAltIcon style={{ fontSize: "16px" }} />
                    Reopen
                  </button> */}
                  {/* <button
                    className="trip-list-reject-btn_v2"
                    onClick={(e) => handleCancelTrip(selectedTrip, e)}
                  >
                    <CancelIcon
                      style={{
                        fontSize: "16px",
                        color: "#262626",
                        marginRight: "5px",
                      }}
                    />
                    Cancel
                  </button> */}
                  {/* <button
                    onClick={() => handleEditTrip(selectedTrip)}
                    className="trip-list-edit-btn_v2"
                    // onClick={(e) => handleApproveTrip(selectedTrip, e)}
                  >
                    <Edit style={{ fontSize: "16px" }} />
                    Edit
                  </button> */}
                </div>
                <div className="trip-list-right-most-btn-container"></div>
                {/* <div
                  onClick={() => handleEditTrip(selectedTrip)}
                  className="trip-list-right-most-edit_drawer"
                >
                  <Edit />
                </div> */}
                {/* <div className="trip-list-right-most-btn-container">
                  <ButtonRectangle
                    // className="trip-list-approve-btn"
                    onClick={(e) => handleReopenTrip(selectedTrip, e)}
                  >
                    Reopen
                  </ButtonRectangle>
                </div> */}
                <div className="trip-list-right-most-btn-container">
                  <ButtonRectangleSecondary
                    // className="trip-list-reject-btn"
                    onClick={(e) => handleCancelTrip(selectedTrip, e)}
                  >
                    Cancel
                  </ButtonRectangleSecondary>
                </div>
                <div className="trip-list-right-most-btn-container">
                  <ButtonRectangleSecondary
                    className="trip-list-edit-btn_v2"
                    onClick={() => handleEditTrip(selectedTrip)}
                  // onClick={(e) => handleApproveTrip(selectedTrip, e)}
                  >
                    <Edit style={{ fontSize: "16px", marginRight: "5px" }} />
                    Edit
                  </ButtonRectangleSecondary>
                </div>
              </div>
            ) : selectedTrip.trip_status == "reopen" ? (
              <div className="trip-list-right-most-single-container_drawer">
                <div className="trip-list-right-most-btn-container">
                  <ButtonRectangle
                    // className="trip-list-approve-btn"
                    onClick={(e) => handleApproveTrip(selectedTrip, e)}
                  >
                    <CheckCircleOutlineIcon
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    Approve
                  </ButtonRectangle>
                </div>
                <div className="trip-list-right-most-btn-container">
                  <ButtonRectangleSecondary
                    // className="trip-list-reject-btn"
                    onClick={(e) => handleRejectTrip(selectedTrip, e)}
                  >
                    <CancelIcon
                      style={{
                        fontSize: "16px",
                        color: "#262626",
                        marginRight: "5px",
                      }}
                    />
                    Reject
                  </ButtonRectangleSecondary>
                </div>
              </div>
            ) : selectedTrip.trip_status == "approved" ? (
              <div className="trip-list-right-most-single-container_drawer">
                <div className="trip-list-right-most-btn-container">
                  <ButtonRectangle
                    // className="trip-list-approve-btn"
                    onClick={(e) => handleStartTrip(selectedTrip, e)}
                  >
                    <PlayCircleOutlineIcon
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    Start
                  </ButtonRectangle>
                </div>

                {selectedTrip.load_source === "trip_invoice" &&
                  (selectedTrip?.trip_entity_mapping_details?.length > 0
                    ? selectedTrip?.trip_entity_mapping_details
                      .map((item) => item.status === "picked")
                      .indexOf(false) >= 0
                    : false) ? (
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      // className="trip-list-reject-btn"
                      onClick={(e) =>
                        handlePickupTrip(e, selectedTrip, selectedTripIndex)
                      }
                    >
                      <LocalShippingOutlinedIcon
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      Pickup
                    </ButtonRectangleSecondary>
                  </div>
                ) : null}
              </div>
            ) : selectedTrip.trip_status == "intransit" ? (
              <div className="trip-list-right-most-single-container_drawer">
                <div className="trip-list-right-most-btn-container">
                  <ButtonRectangle
                    // className="trip-list-approve-btn"
                    onClick={() => handleCompleteTrip(selectedTrip)}
                  >
                    <AssignmentTurnedInIcon
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    Request for Completion
                  </ButtonRectangle>
                </div>
                {selectedTrip.load_source === "trip_invoice" &&
                  selectedTrip.pending_reference_numbers.length > 0 ? (
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      className="trip-list-approve-btn_v2"
                      onClick={() => handleDeliveryInvoicePayment(selectedTrip)}
                    >
                      Delivery
                    </ButtonRectangleSecondary>
                  </div>
                ) : null}
                {selectedTrip.load_source === "trip_invoice" &&
                  (selectedTrip?.trip_entity_mapping_details?.length > 0
                    ? selectedTrip?.trip_entity_mapping_details
                      .map((item) => item.status === "picked")
                      .indexOf(false) >= 0
                    : false) ? (
                  <div className="trip-list-right-most-btn-container">
                    <ButtonRectangleSecondary
                      // className="trip-list-reject-btn"
                      onClick={(e) =>
                        handlePickupTrip(e, selectedTrip, selectedTripIndex)
                      }
                    >
                      <LocalShippingOutlinedIcon
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      Pickup
                    </ButtonRectangleSecondary>
                  </div>
                ) : null}
              </div>
            ) : selectedTrip.trip_status == "trip_disputed" ? (
              <div className="trip-list-right-most-single-container_drawer">
                <div className="trip-list-right-most-btn-container">
                  <ButtonRectangle
                    className="trip-list-complete-btn_v2"
                    onClick={() => handleDisputedCompleteTrip(selectedTrip)}
                  >
                    <AssignmentTurnedInIcon
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    Request for Completion
                  </ButtonRectangle>
                  {/* {selectedTrip.load_source === "trip_invoice" &&
                  selectedTrip.pending_reference_numbers.length > 0 ? (
                    <button
                      className="trip-list-approve-btn_v2"
                      onClick={() => handleDeliveryInvoicePayment(selectedTrip)}
                    >
                      Delivery
                    </button>
                  ) : null} */}
                  {/* {selectedTrip.load_source === "trip_invoice" &&
                  (selectedTrip?.trip_entity_mapping_details?.length > 0
                    ? selectedTrip?.trip_entity_mapping_details
                        .map((item) => item.status === "picked")
                        .indexOf(false) >= 0
                    : false) ? (
                    // <d className="trip-list-right-most-btn-container">
                    <button
                      className="trip-list-pickup-btn"
                      onClick={(e) =>
                        handlePickupTrip(e, selectedTrip, selectedTripIndex)
                      }
                    >
                      <LocalShippingOutlinedIcon
                        style={{ fontSize: "16px", marginRight: "5px" }}
                      />
                      Pickup
                    </button>
                  ) : null} */}
                </div>
              </div>
            ) : selectedTrip.trip_status == "cancelled" ? (
              <div className="trip-list-right-most-single-container"></div>
            ) : selectedTrip.trip_status == "requested_for_completion" &&
              (readLoginData().Role == "Logistics Manager" ||
                readLoginData().Role == "Logistics Admin" ||
                readLoginData().Role == "Manager" ||
                readLoginData().Role == "Admin" ||
                readLoginData().Role == "SuperAdmin") ? (
              <div className="trip-list-right-most-single-container_drawer">
                <div className="trip-list-right-most-btn-container">
                  <ButtonRectangle
                    className="trip-list-approve-btn_v2"
                    onClick={(e) => handleApproveCompleteTrip(selectedTrip, e)}
                  >
                    <CheckCircleOutlineIcon
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    Approve
                  </ButtonRectangle>
                </div>
                <div className="trip-list-right-most-btn-container">
                  <ButtonRectangleSecondary
                    className="trip-list-reject-btn_v2"
                    onClick={(e) => handleRejectCompleteTrip(selectedTrip, e)}
                  >
                    <CancelIcon
                      style={{
                        fontSize: "16px",
                        color: "#262626",
                        marginRight: "5px",
                      }}
                    />
                    Reject
                  </ButtonRectangleSecondary>
                </div>
                {/* <button
                    className="trip-list-edit-btn_v2"
                    onClick={() => handleEditTrip(selectedTrip)}
                 
                  >
                    <Edit style={{ fontSize: "16px", marginRight: "5px" }} />
                    Edit
                  </button> */}
              </div>
            ) : // </div>
              null}
          </div>
          {/* <button>hiiiii</button> */}
          {/* </div> */}
          <div className="details_view_main_conatiner">
            <div className="details_view_conatainer">
              {tripDetailsHeading == "Trip Details" ? (
                <div className="mini-details-tab-container_v2">
                  <div className="tl-new-status_drawer">
                    <span
                      style={{
                        // fontSize: "8px",
                        color: "white",
                        padding: "2px 4px",
                        background:
                          selectedTrip.trip_status === "completed"
                            ? "#016938"
                            : selectedTrip.trip_status === "cancelled"
                              ? "red"
                              : selectedTrip.trip_status === "trip_disputed"
                                ? "#C4B454"
                                : "#65c368",
                        borderTopLeftRadius: "3px",
                        borderBottomLeftRadius: "3px",
                      }}
                    >
                      {capitalizeLetter(selectedTrip.trip_status.toUpperCase())}
                      {/* {selectedTrip.trip_status.toUpperCase()}{" "} */}
                    </span>
                    <span
                      style={{
                        color: "white",
                        padding: "2px 4px",
                        background: "orange",
                        borderTopRightRadius: "3px",
                        borderBottomRightRadius: "3px",
                      }}
                    >
                      {selectedTrip.trip_type
                        ? selectedTrip.trip_type
                          .split("_")
                          .join(" ")
                          .toUpperCase()
                        : selectedTrip.vehicle_freight_type
                          ? selectedTrip.vehicle_freight_type.toUpperCase()
                          : " - "}
                    </span>
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">Trip code</h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.trip_code ? (
                        <CopyText text={selectedTrip.trip_code} />
                      ) : (
                        "-"
                      )}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">Consignor</h4>
                    <h4
                      className="mini-details-single-list-right"
                      style={{
                        width: "400px",
                        overflow: "scroll",
                      }}
                    >
                      :{" "}
                      {selectedTrip.customers_details
                        ? selectedTrip.customers_details.name
                        : " - "}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">Consignee</h4>
                    <h4
                      className="mini-details-single-list-right"
                      style={{
                        width: "400px",
                        overflow: "scroll",
                      }}
                    >
                      :{" "}
                      {selectedTrip.consignee_details
                        ? selectedTrip.consignee_details.name
                        : " - "}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">Bill To</h4>
                    <h4
                      className="mini-details-single-list-right"
                      style={{
                        width: "400px",
                        overflow: "scroll",
                      }}
                    >
                      :{" "}
                      {selectedTrip.bill_to_party_details
                        ? selectedTrip.bill_to_party_details.name
                        : " - "}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">LR Number</h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.reference_number_for_trip
                        ? selectedTrip.reference_number_for_trip
                        : "-"}
                    </h4>{" "}
                  </div>

                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Expected Delivery Date
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.expected_delivery_date
                        ? selectedTrip.expected_delivery_date
                        : "-"}
                    </h4>{" "}
                  </div>

                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Customer Invoice No.
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.customer_invoice
                        ? selectedTrip.customer_invoice
                        : "-"}
                    </h4>{" "}
                  </div>

                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Number of Pieces
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.number_of_pieces
                        ? selectedTrip.number_of_pieces
                        : "-"}
                    </h4>{" "}
                  </div>

                  {/* <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">Customer</h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.customers_details
                        ? selectedTrip.customers_details.name
                        : "-"}
                    </h4>{" "}
                  </div> */}
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">Source</h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.load_source
                        ? selectedTrip.load_source.split("_").join(" ")
                        : "-"}
                    </h4>{" "}
                  </div>

                  {selectedTrip.customers_details?.name
                    .toLowerCase()
                    .includes("faarms") ? (
                    <div className="mini-details-single-list">
                      <h4 className="mini-details-single-list-left">
                        {selectedTrip.load_source
                          ? selectedTrip.load_source == "trip_purchase_order"
                            ? "Purchase order Id"
                            : selectedTrip.load_source == "trip_invoice"
                              ? "Invoice Id"
                              : "Reference Number"
                          : null}{" "}
                        {selectedTrip.reference_number
                          ? selectedTrip.reference_number.length > 0
                            ? selectedTrip.reference_number.map(
                              (item, index) => {
                                return (
                                  <div
                                    style={{
                                      color: colorPalette.secondaryWhite,
                                    }}
                                  >
                                    {selectedTrip.reference_number.length -
                                      1 !=
                                      index ? (
                                      <>
                                        {"uyt"} <br></br>
                                      </>
                                    ) : null}
                                  </div>
                                );
                              }
                            )
                            : "-"
                          : null}
                      </h4>
                      <h4 className="mini-details-single-list-right">
                        :{" "}
                        {selectedTrip.reference_number
                          ? selectedTrip.reference_number.length > 0
                            ? selectedTrip.reference_number.map(
                              (item, index) => {
                                return (
                                  <>
                                    {selectedTrip.reference_number.length -
                                      1 ==
                                      index ? (
                                      <>
                                        {item}
                                        <br></br>
                                      </>
                                    ) : (
                                      <>
                                        {item} , <br></br>
                                      </>
                                    )}
                                  </>
                                );
                              }
                            )
                            : "-"
                          : null}
                      </h4>{" "}
                    </div>
                  ) : null}
                  {selectedTrip.customers_details?.name
                    .toLowerCase()
                    .includes("faarms") &&
                    selectedTrip?.load_source == "manual" ? (
                    <div className="mini-details-single-list">
                      <h4 className="mini-details-single-list-left">
                        Entity Type
                      </h4>
                      <h4 className="mini-details-single-list-right">
                        :{" "}
                        {selectedTrip.entity_type
                          ? selectedTrip.entity_type.split("_").join(" ")
                          : "-"}
                      </h4>{" "}
                    </div>
                  ) : null}

                  {/* <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Trip status
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.trip_status
                        ? selectedTrip.trip_status
                        : "-"}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">Trip Type</h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.trip_type
                        ? selectedTrip.trip_type.split("_").join(" ")
                        : " - "}
                    </h4>{" "}
                  </div> */}
                  {/* <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">LR Number</h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.reference_number_for_trip
                        ? selectedTrip.reference_number_for_trip
                        : "-"}
                    </h4>{" "}
                  </div> */}
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Value of goods
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.value_of_goods
                        ? selectedTrip.value_of_goods
                        : "-"}
                    </h4>{" "}
                  </div>

                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Estimated distance
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.estimated_kms
                        ? selectedTrip.estimated_kms
                        : "-"}{" "}
                      Kms
                    </h4>{" "}
                  </div>
                  {selectedTrip.trip_status == "completed" ? (
                    <div className="mini-details-single-list">
                      <h4 className="mini-details-single-list-left">
                        Actual distance
                      </h4>
                      <h4 className="mini-details-single-list-right">
                        :{" "}
                        {selectedTrip.trip_start_meter_reading &&
                          selectedTrip.trip_complete_meter_reading
                          ? selectedTrip.trip_complete_meter_reading -
                          selectedTrip.trip_start_meter_reading
                          : " - "}{" "}
                        {/* {selectedTrip.estimated_kms
                      ? selectedTrip.estimated_kms
                      : "-"} */}
                        Kms
                      </h4>{" "}
                    </div>
                  ) : null}

                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Total quantity
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      : {selectedTrip.quantity ? selectedTrip.quantity : "-"}{" "}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Unloaded quantity
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.unloaded_quantity
                        ? selectedTrip.unloaded_quantity
                        : "-"}{" "}
                    </h4>{" "}
                  </div>
                  {selectedTrip.trip_eway_bill ? (
                    <>
                      <div className="mini-details-single-list">
                        <h4 className="mini-details-single-list-left">
                          E-Way Bill No.
                        </h4>
                        <h4 className="mini-details-single-list-right">
                          :{" "}
                          {selectedTrip.trip_eway_bill.eway_bill_number ? (
                            <CopyText
                              text={
                                selectedTrip.trip_eway_bill.eway_bill_number
                              }
                            />
                          ) : (
                            "-"
                          )}
                        </h4>{" "}
                      </div>
                      <div className="mini-details-single-list">
                        <h4 className="mini-details-single-list-left">
                          E-Way Bill From
                        </h4>
                        <h4 className="mini-details-single-list-right">
                          :
                          {selectedTrip.trip_eway_bill.eway_bill_valid_from
                            ? selectedTrip.trip_eway_bill.eway_bill_valid_from
                            : "-"}
                        </h4>{" "}
                      </div>
                      <div className="mini-details-single-list">
                        <h4 className="mini-details-single-list-left">
                          E-Way Bill Till
                        </h4>
                        <h4 className="mini-details-single-list-right">
                          :
                          {selectedTrip.trip_eway_bill.eway_bill_valid_till
                            ? selectedTrip.trip_eway_bill.eway_bill_valid_till
                            : "-"}
                        </h4>{" "}
                      </div>
                    </>
                  ) : null}

                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Per Bag Cost
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      : Rs.{" "}
                      {tripCostTotal && selectedTrip.unloaded_quantity != 0
                        ? (
                          tripCostTotal / selectedTrip.unloaded_quantity
                        ).toFixed(2)
                        : tripCostTotal && selectedTrip.quantity
                          ? (tripCostTotal / selectedTrip.quantity).toFixed(2)
                          : " - "}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Payment Terms
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.payment_terms
                        ? selectedTrip.payment_terms === "to_be_billed"
                          ? "To be billed"
                          : selectedTrip.payment_terms === "to_payee"
                            ? "To Pay"
                            : " - "
                        : " - "}{" "}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Transport Cost
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      : Rs. {tripCostTotal ? tripCostTotal : " - "}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Transport Advance
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      : Rs. {tripAdvanceCost ? tripAdvanceCost : " - "}
                    </h4>{" "}
                  </div>
                  {selectedTrip.trip_customer_invoice.length > 0 ? (
                    <>
                      {" "}
                      <div className="mini-details-single-list">
                        <h4 className="mini-details-single-list-left">
                          Advance(Customer)
                        </h4>
                        <h4 className="mini-details-single-list-right">
                          : Rs.{" "}
                          {selectedTrip.trip_customer_invoice
                            ? selectedTrip.trip_customer_invoice[0]
                              .advance_paid_by_customer
                            : " - "}
                        </h4>{" "}
                      </div>{" "}
                      <div className="mini-details-single-list">
                        <h4 className="mini-details-single-list-left">
                          Customer Billed Amt.
                        </h4>
                        <h4 className="mini-details-single-list-right">
                          : Rs.{" "}
                          {selectedTrip.trip_customer_invoice
                            ? selectedTrip.trip_customer_invoice[0]
                              .customer_billed_amount
                            : " - "}
                        </h4>{" "}
                      </div>
                    </>
                  ) : null}

                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Start meter reading
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.trip_start_meter_reading
                        ? selectedTrip.trip_start_meter_reading
                        : " - "}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Complete meter<br></br> reading
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {selectedTrip.trip_complete_meter_reading
                        ? selectedTrip.trip_complete_meter_reading
                        : " - "}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Per Km Rate
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      : Rs{" "}
                      {selectedTrip.per_km_rate
                        ? selectedTrip.per_km_rate
                        : " - "}
                    </h4>{" "}
                  </div>
                  <hr></hr>

                  <div className="trip-list-address">
                    <div className="trip-location-list">
                      {selectedTrip.source.map((source) => {
                        return (
                          <div
                            className="trip-location-text"
                            style={{ color: darkMode ? "white" : "" }}
                          >
                            From
                          </div>
                        );
                      })}
                      {/* <div className="trip-location-text">From</div> */}
                      {selectedTrip.destination.map((destinations) => {
                        return (
                          <div
                            className="trip-location-text"
                            style={{ color: darkMode ? "white" : "" }}
                          >
                            To
                          </div>
                        );
                      })}
                    </div>
                    <div className="trip-list-path">
                      {/* <div className="trip-circle"></div>
                  <div className="trip-rectangle"></div> */}
                      {selectedTrip.source.map((source, index) => {
                        return (
                          <>
                            {index == 0 ? null : (
                              <>
                                <div className="trip-circle"></div>
                                <div className="mini-trip-rectangle"></div>
                              </>
                            )}
                          </>
                        );
                      })}
                      {selectedTrip.destination.map((destinations) => {
                        return (
                          <>
                            <div className="trip-circle"></div>
                            <div className="mini-trip-rectangle"></div>
                          </>
                        );
                      })}
                      <div className="trip-circle"></div>
                    </div>
                    <div className="trip-location-list">
                      {/* <div className="trip-location-text"> */}
                      {selectedTrip.source.map((source) => {
                        return (
                          <div
                            className="trip-location-text"
                            style={{ color: darkMode ? "white" : "" }}
                          >
                            {source.village === source.district
                              ? source.village
                              : `${source.village},
                          ${source.district}`}{" "}
                            , {source.state}, {source.postcode}
                          </div>
                        );
                      })}

                      {selectedTrip.destination.map((destinations) => {
                        return (
                          <div
                            className="trip-location-text"
                            style={{ color: darkMode ? "white" : "" }}
                          >
                            {destinations.village === destinations.district
                              ? destinations.district
                              : `${destinations.village}, ${destinations.district}`}
                            , {destinations.state}, {destinations.postcode}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : tripDetailsHeading == "Driver" ? (
                <div className="mini-details-tab-container_v2">
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      First Name
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {driverDetails.first_name
                        ? driverDetails.first_name
                        : "-"}
                    </h4>{" "}
                  </div>

                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">Last name</h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {driverDetails.last_name ? driverDetails.last_name : "-"}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Mobile number
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {driverDetails.mobile_number ? (
                        <CopyText text={driverDetails.mobile_number} />
                      ) : (
                        "-"
                      )}
                    </h4>{" "}
                  </div>
                  {/* <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Pan number
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {driverDetails.pan_number ? (
                        <CopyText text={driverDetails.pan_number} />
                      ) : (
                        "-"
                      )}
                    </h4>{" "}
                  </div> */}
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">DL number</h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {driverDetails.driving_licence_number ? (
                        <CopyText text={driverDetails.driving_licence_number} />
                      ) : (
                        "-"
                      )}
                    </h4>{" "}
                  </div>
                  <div className="mini-details-single-list">
                    <h4 className="mini-details-single-list-left">
                      Alternate number
                    </h4>
                    <h4 className="mini-details-single-list-right">
                      :{" "}
                      {driverDetails.alternate_number ? (
                        <CopyText text={driverDetails.alternate_number} />
                      ) : (
                        "-"
                      )}
                    </h4>{" "}
                  </div>
                  <div
                    className="mini-details-single-attachment_download"
                    style={{ marginLeft: "5px" }}
                  >
                    {" "}
                    <ul
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: `${darkMode
                          ? colorPaletteDark.primaryColor
                          : colorPalette.white
                          }`,
                        color: `${darkMode ? colorPaletteDark.white : colorPalette.black
                          }`,
                      }}
                    >
                      <li
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          fontWeight: "500",
                          backgroundColor: `${darkMode
                            ? colorPaletteDark.primaryColor
                            : colorPalette.white
                            }`,
                          marginLeft: "5px",
                        }}
                      >
                        <div className="trip_buttons_download">
                          <img
                            src={fileIcons}
                            alt=""
                            width="35px"
                            height="35px"
                            style={{
                              cursor: "pointer",
                              backgroundColorcolor: `${darkMode ? colorPaletteDark.secondaryColor : ""
                                }`,
                            }}
                          // onClick={() => {
                          //   handleOpenImage();
                          //   showTripAttachments(item.id);
                          // }}
                          />
                          <span
                            style={{
                              color: `${darkMode
                                ? colorPaletteDark.white
                                : colorPalette.black
                                }`,
                              marginLeft: "5px",
                            }}
                          >
                            Driving Licence
                          </span>
                        </div>

                        <div className="trip_buttons_download">
                          <img
                            src={showIcons}
                            alt=""
                            width="35px"
                            height="35px"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleOpenImage();
                              showDriverDocs(driverDetails.id);
                            }}
                          />
                          <img
                            src={downloadIcons}
                            alt=""
                            width="35px"
                            height="35px"
                            style={{ cursor: "pointer" }}
                            onClick={() => downloadDriverDocs(driverDetails.id)}
                          />
                        </div>
                        {/* <a
                          href="javascript:void(0)"
                          onClick={() => downloadDriverDocs(driverDetails.id)}
                          className="attachment_btn"
                          style={{ fontSize: "10px" }}
                        >
                          <FeedIcon
                        
                          />
                        </a> */}
                      </li>
                    </ul>
                  </div>
                  {/* <div
                className="mini-details-single-attachment"
                style={{ marginLeft: "5px" }}
              >
                {" "}
                <ul
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <li
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      fontWeight: "500",
                      marginLeft: "5px",
                    }}
                  >
                    Pan Card
                    <a
                      href={`${apiUrl.onboardingBaseUrl}/v1/image/driver-pan/${driverDetails.id}`}
                      download
                      target="_blank"
                      className="attachment_btn"
                    >
                      <FeedIcon
                        style={{
                          marginRight: "10px",
                        }}
                      />{" "}
                    </a>
                  </li>
                </ul>
              </div> */}
                </div>
              ) : tripDetailsHeading == "Trip History" ? (
                <div
                  className="mini-details-tab-container-history"
                  style={{ padding: "1rem .5rem" }}
                >
                  <div className="mini-details-tab-left-timeline">
                    {tripHistoryList.map((item, index) => {
                      return (
                        <>
                          {/* {tripHistoryList.length - 1 == index ? null : ( */}
                          <>
                            <div className="mini-trip-rectangle-timeline"></div>
                            <div className="mini-trip-rectangle-timeline"></div>
                            <div className="mini-trip-rectangle-timeline"></div>
                            <div className="mini-trip-rectangle-timeline"></div>
                            <div className="mini-trip-rectangle-timeline"></div>
                            <div className="mini-trip-rectangle-timeline"></div>
                            <div className="mini-trip-rectangle-timeline"></div>
                            <div className="mini-trip-rectangle-timeline"></div>
                            <div className="mini-trip-rectangle-timeline"></div>
                            <div className="mini-trip-rectangle-timeline"></div>
                            <div className="mini-trip-rectangle-timeline"></div>
                            <div className="mini-trip-rectangle-timeline"></div>
                          </>
                          {/* )} */}
                        </>
                      );
                    })}
                  </div>
                  <div>
                    {tripHistoryList.map((item) => {
                      return (
                        <div className="mini-details-single-history">
                          <h3 className="mini-details-history-head">
                            <div className="mini-trip-circle"></div>{" "}
                            <span
                              className="mini-details-black-text"
                              style={{
                                color: `${darkMode ? "#48d7a4" : ""}`,
                              }}
                            >
                              {capitalizeLetter(
                                item.trip_status
                                  ? item.trip_status
                                    .split("")
                                    .map((item, index) => {
                                      if (index == 0) {
                                        return item.toUpperCase();
                                      } else {
                                        return item;
                                      }
                                    })
                                    .join("")
                                  : "-"
                              )}
                            </span>
                            {/* {item.trip_status
                              ? item.trip_status
                                  .split("")
                                  .map((item, index) => {
                                    if (index == 0) {
                                      return item.toUpperCase();
                                    } else {
                                      return item;
                                    }
                                  })
                                  .join("")
                              : "-"} */}
                          </h3>
                          <div className="mini-details-single-list">
                            {/* <h4 className="mini-details-history-left">created</h4> */}
                            <h4 className="mini-details-history-right">
                              <span className="mini-details-black-text">
                                {" "}
                                Remarks :{" "}
                              </span>
                              <span className="mini-details-grey-text">
                                {" "}
                                {item.remarks ? item.remarks : "-"}
                              </span>
                            </h4>{" "}
                          </div>
                          <div className="mini-details-single-list">
                            {/* <h4 className="mini-details-history-left">created</h4> */}
                            <h4 className="mini-details-history-right">
                              <span className="mini-details-black-text">
                                {" "}
                                Last Modified :{" "}
                              </span>
                              <span className="mini-details-grey-text">
                                {item.last_modified_by
                                  ? item.last_modified_by
                                  : "-"}
                              </span>
                            </h4>{" "}
                          </div>

                          <div className="mini-details-single-list">
                            <h4 className="mini-details-history-left">
                              {/* created by */}
                            </h4>
                            <h4 className="mini-details-history-right">
                              <span className="mini-details-black-text">
                                Created on :{" "}
                              </span>
                              <span className="mini-details-grey-text">
                                {item.created_on
                                  ? moment
                                    .utc(item.created_on)
                                    .local()
                                    .format("DD-MM-YYYY hh:mm a")
                                  : "-"}
                              </span>
                            </h4>{" "}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : tripDetailsHeading == "Attachments" ? (
                <div className="mini-details-attachment-list_trip">
                  {tripAttachmentList.length > 0 ? (
                    tripAttachmentList.map((item) => {
                      return (
                        <div className="mini-details-single-attachment_download">
                          {" "}
                          <ul
                            style={{
                              width: "100%",
                              display: "flex",
                              padding: "5px 5px",
                              alignItems: "center",
                              backgroundColor: `${darkMode
                                ? colorPaletteDark.secondaryColor
                                : colorPalette.white
                                }`,

                              // borderLeft: item.trip_dispute_id
                              //   ? "5px solid #fde989"
                              //   : "white",
                              // borderBottom: item.trip_dispute_id
                              //   ? "5px solid #fde989"
                              //   : "white",

                              // backgroundColor: item.trip_dispute_id
                              //   ? "#fde989"
                              //   : "white",
                              justifyContent: "space-around",
                            }}
                          >
                            <li
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                backgroundColor: `${darkMode
                                  ? colorPaletteDark.primaryColor
                                  : colorPalette.white
                                  }`,
                                color: `${darkMode
                                  ? colorPaletteDark.white
                                  : colorPalette.black
                                  }`,
                                // backgroundColor: item.trip_dispute_id
                                //   ? "yellow"
                                //   : "white",

                                // margin: "10px",
                                fontWeight: "400",
                                fontSize: "12px",
                              }}
                            >
                              <div
                                className="trip_buttons_download"
                                style={{
                                  padding: `${darkMode ? "5px" : "0px"}`,
                                }}
                              >
                                <img
                                  src={fileIcons}
                                  alt=""
                                  width="35px"
                                  height="35px"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    // handleOpenImage();
                                    download(item.id);
                                  }}
                                />
                                <span
                                  style={{
                                    color: `${darkMode
                                      ? colorPaletteDark.white
                                      : colorPalette.black
                                      }`,
                                    marginLeft: "5px",
                                  }}
                                >
                                  {" "}
                                  {item.tag
                                    .split("_")
                                    .join(" ")
                                    .split("")
                                    .map((item, index) => {
                                      if (index == 0) {
                                        return item.toUpperCase();
                                      } else {
                                        return item;
                                      }
                                    })
                                    .join("")}
                                </span>
                              </div>

                              <div className="trip_buttons_download">
                                {item.trip_dispute_id !== null ? (
                                  <ReportGmailerrorredOutlinedIcon
                                    style={{
                                      fontSize: "18px",
                                      cursor: "pointer",
                                      color: "#D8AE2D",
                                      fontWeight: "bold",
                                    }}
                                    onClick={() => {
                                      handleOpenDistpute();
                                      getDisputeHistory(item.trip_dispute_id);
                                    }}
                                  />
                                ) : null}

                                <img
                                  src={showIcons}
                                  alt=""
                                  width="35px"
                                  height="35px"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleOpenImage();
                                    showTripAttachments(item.id);
                                  }}
                                />

                                <img
                                  src={downloadIcons}
                                  alt=""
                                  width="35px"
                                  height="35px"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    // handleOpenImage();
                                    download(item.id);
                                  }}
                                />
                                {/* <a
                                  href="javascript:void(0)"
                                  onClick={() => download(item.id)}
                                  className="attachment_btn"
                                  style={{ fontSize: "10px" }}
                                >
                                  <FeedIcon />{" "}
                                </a> */}
                              </div>
                            </li>
                          </ul>
                        </div>
                      );
                    })
                  ) : loadingAttachmentList ? (
                    <div className="mini-details-no-attachment">
                      Loading data...
                    </div>
                  ) : (
                    <div className="mini-details-no-attachment">
                      <img
                        src={NoDetailsFound}
                        alt=""
                        width="80px"
                        height="80px"
                      />
                      No attachment found!
                    </div>
                  )}
                </div>
              ) : tripDetailsHeading == "Delivery Info" ? (
                <div className="mini-details-tab-container-history">
                  <div style={{ width: "100%" }}>
                    {paymentCompleteInvoiceList.length > 0 &&
                      paymentHistoryArray.length > 0 ? (
                      paymentCompleteInvoiceList.map((item) => {
                        return (
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>{item ? item : null}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="mini-card-delivery-info-container">
                                <div
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "13px",
                                  }}
                                >
                                  DELIVERED ITEMS :{" "}
                                </div>
                                <table className="mini-card-delivery-table">
                                  <tr className="mini-card-delivery-table-tr">
                                    <th className="mini-card-delivery-table-th">
                                      Sl. No.
                                    </th>
                                    <th className="mini-card-delivery-table-th">
                                      particulars
                                    </th>
                                  </tr>
                                  {deliverItemList.length > 0
                                    ? deliverItemList.filter(
                                      (items) =>
                                        items.reference_number === item
                                    ).length > 0
                                      ? deliverItemList
                                        .filter(
                                          (items) =>
                                            items.reference_number === item
                                        )[0]
                                        .items_list.map(
                                          (single_item, index) => {
                                            return (
                                              <tr className="mini-card-delivery-table-tr">
                                                <td className="mini-card-delivery-table-td">
                                                  <span className="mini-card-table-text-left">
                                                    {" "}
                                                    {index + 1}
                                                  </span>
                                                </td>
                                                <td className="mini-card-delivery-table-td">
                                                  <span className="mini-card-table-text-left">
                                                    {" "}
                                                    ITEM :
                                                  </span>{" "}
                                                  <span className="mini-card-table-text-right">
                                                    {single_item.item_name}
                                                  </span>
                                                  <br></br>
                                                  <span className="mini-card-table-text-left">
                                                    QTY :
                                                  </span>{" "}
                                                  <span className="mini-card-table-text-right">
                                                    {single_item.item_qty}
                                                  </span>
                                                  <br></br>
                                                  <span className="mini-card-table-text-left">
                                                    {" "}
                                                    UNLOADED QTY :
                                                  </span>{" "}
                                                  <span className="mini-card-table-text-right">
                                                    {
                                                      single_item.unloaded_quantity
                                                    }
                                                  </span>
                                                  <br></br>
                                                  <span className="mini-card-table-text-left">
                                                    {" "}
                                                    BLOCKED QTY :
                                                  </span>{" "}
                                                  <span className="mini-card-table-text-right">
                                                    {
                                                      single_item.blocked_quantity_for_invoice_payment
                                                    }
                                                  </span>
                                                  <br></br>
                                                  <span className="mini-card-table-text-left">
                                                    RATE :{" "}
                                                  </span>{" "}
                                                  <span className="mini-card-table-text-right">
                                                    Rs.{" "}
                                                    {single_item.item_rate}
                                                  </span>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      : null
                                    : null}
                                </table>
                              </div>
                              <br></br>
                              <hr></hr>
                              <br></br>
                              <div className="mini-card-delivery-info-container">
                                <h4
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "13px",
                                  }}
                                >
                                  PAYMENT DETAILS :{" "}
                                </h4>
                                {/* <div className="table-main-container"> */}
                                <hr style={{ marginTop: "2px" }} />
                                <div
                                  className="table-head-delivery-info"
                                  style={{
                                    marginTop: "10px",
                                  }}
                                >
                                  {/* <div className="table-head-data">Account</div> */}
                                  <div
                                    className="table-head-data"
                                    style={{ fontSize: "13px" }}
                                  >
                                    Mode
                                  </div>
                                  <div
                                    className="table-head-data"
                                    style={{ fontSize: "13px" }}
                                  >
                                    Amount(₹)
                                  </div>
                                  <div
                                    className="table-head-data"
                                    style={{ fontSize: "13px" }}
                                  >
                                    Reference Id
                                  </div>
                                  <div
                                    className="table-head-data"
                                    style={{ fontSize: "13px" }}
                                  >
                                    Document
                                  </div>
                                </div>
                                <hr style={{ marginTop: "5px" }} />
                                {/* </div> */}
                                {paymentHistoryArray
                                  .filter(
                                    (items) => items.reference_number === item
                                  )
                                  .map((itemss, index) => {
                                    return (
                                      <>
                                        <div className="mini-delivery-payment-details">
                                          {/* <h5>Payment Mode:</h5> */}
                                          <div style={{ maxWidth: "20px" }}>
                                            <h5>
                                              {" "}
                                              {itemss.payment_mode
                                                ? itemss.payment_mode
                                                  .toUpperCase()
                                                  .split("_")
                                                  .join(" ")
                                                : null}
                                            </h5>
                                          </div>
                                          <div>
                                            <h5>
                                              {itemss.amount
                                                ? itemss.amount
                                                : null}
                                            </h5>
                                          </div>
                                          <div style={{ maxWidth: "20px" }}>
                                            <h5
                                              className="mini-card-delivery-right"
                                            // style={{ position: "relat" }}
                                            >
                                              {" "}
                                              {itemss.transaction_reference_id
                                                ? itemss.transaction_reference_id
                                                : "--"}
                                            </h5>
                                          </div>

                                          <div
                                            style={{
                                              // width: "100%",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <img
                                              src={showIcons}
                                              alt=""
                                              width="35px"
                                              height="35px"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                handleOpenImage();
                                                showPaymentAttachments(
                                                  itemss.id
                                                );
                                              }}
                                            />
                                            <img
                                              src={downloadIcons}
                                              alt=""
                                              width="35px"
                                              height="35px"
                                              style={{ cursor: "pointer" }}
                                              onClick={
                                                () =>
                                                  downloadPaymentAttachment(
                                                    itemss.id
                                                  )
                                                // download(itemss.id)
                                              }
                                            />
                                            {/* <a
                                                  href="javascript:void(0)"
                                                  onClick={
                                                    () =>
                                                      downloadPaymentAttachment(
                                                        itemss.id
                                                      )
                                                    // download(itemss.id)
                                                  }
                                                  className="attachment_btn"
                                                  style={{ fontSize: "10px" }}
                                                >

                                                  <FeedIcon
                                                    style={{
                                                      color: "green",
                                                    }}
                                                  />{" "}
                                                </a> */}
                                          </div>

                                          {/* {index == 0 ? (
                                            <div>Attachment:</div>
                                          ) : null} */}
                                        </div>
                                        {/* <div
                                          className="mini-card-row-delivery"
                                          // style={{ flexDirection: "column" }}
                                        >
                                          <h5 className="mini-card-delivery-left">
                                            Reference Id :
                                          </h5>
                                          <h5
                                            className="mini-card-delivery-right"
                                            // style={{ position: "relat" }}
                                          >
                                            {" "}
                                            {itemss.transaction_reference_id
                                              ? itemss.transaction_reference_id
                                              : null}
                                          </h5>
                                        </div> */}
                                        {/* <div className="mini-details-single-attachment_download">
                                          {" "}
                                          <ul
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-around",
                                            }}
                                          >
                                            <li
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                margin: "5px",
                                                fontWeight: "500",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {"Attachment"}
                                              <div
                                                style={{
                                              
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <img
                                                  src={showIcons}
                                                  alt=""
                                                  width="35px"
                                                  height="35px"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    handleOpenImage();
                                                    showPaymentAttachments(
                                                      itemss.id
                                                    );
                                                  }}
                                                />
                                                <img
                                                  src={downloadIcons}
                                                  alt=""
                                                  width="35px"
                                                  height="35px"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={
                                                    () =>
                                                      downloadPaymentAttachment(
                                                        itemss.id
                                                      )
                                                
                                                  }
                                                />
                                          
                                              </div>

                                         
                                            </li>
                                          </ul>
                                        </div> */}
                                      </>
                                    );
                                  })}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })
                    ) : paymentLoading ? (
                      <div className="mini-card-not-found">
                        <span
                          style={{
                            color: darkMode
                              ? colorPaletteDark.white
                              : colorPalette.black,
                          }}
                        >
                          Loading data...{" "}
                        </span>
                      </div>
                    ) : (
                      <div className="mini-card-not-found">
                        <img
                          src={NoDetailsFound}
                          alt=""
                          width="80px"
                          height="80px"
                        />
                        <span
                          style={{
                            color: darkMode
                              ? colorPaletteDark.white
                              : colorPalette.black,
                          }}
                        >
                          No details found!{" "}
                        </span>
                      </div>
                    )}
                    {/* {quantityDeliveryFromHistory.length > 0 ? (
                  quantityDeliveryFromHistory.map((item) => {
                    return (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            {item["Invoice Number"]
                              ? item["Invoice Number"]
                              : null}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="mini-card-delivery-info-container">
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Payment mode :
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                {item.payment_mode ? item.payment_mode : null}
                              </h5>
                            </div>
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Total Amount{" "}
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                : Rs. {item.amount ? item.amount : null}
                              </h5>
                            </div>
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Actual quantity
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                :{" "}
                                {item["Total Quantity"]
                                  ? item["Total Quantity"]
                                  : null}
                              </h5>
                            </div>
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Unloaded quantity{" "}
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                :{" "}
                                {item["Unloaded Quantity"]
                                  ? item["Unloaded Quantity"]
                                  : null}
                              </h5>
                            </div>
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Status
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                {" "}
                                : {item["Status"] ? item["Status"] : null}
                              </h5>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <div className="mini-card-not-found">No details found!</div>
                )} */}
                  </div>
                </div>
              ) : tripDetailsHeading == "Payment History" ? (
                <div className="mini-details-tab-container-history">
                  <div style={{ width: "100%" }}>
                    {tripPaymentHistory.length > 0 ? (
                      tripPaymentHistory.map((item, index) => {
                        return (
                          <Accordion
                            key={index}
                            style={{
                              marginTop: "10px",
                              width: "98%",
                              marginLeft: "5px",
                            }}
                            onClick={(e) => {
                              // setLoadingPaymentApprove(false);
                              // setLoadingPaymentRejectedApprove(false);
                              // setPaymentstatusError("");
                              // setPaymentRemarks("");
                            }}
                            expanded={expanded === index}
                            onChange={handleChangeAccordion(index)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              style={{ backgroundColor: "#E2F1DB" }}
                            >
                              <Typography className="payment-history-headings">
                                <div
                                  className="payment-history-subheadings"
                                  style={{
                                    width: "60px",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  <div className="payment-history-subheadings-text">
                                    Mode:
                                  </div>
                                  {item.payment_mode
                                    ? capitalizeLetter(item.payment_mode)
                                    : null}
                                </div>
                                <div className="payment-history-subheadings">
                                  <div className="payment-history-subheadings-text">
                                    Amount(₹):
                                  </div>
                                  {item.amount ? item.amount : null}
                                </div>
                                <div className="payment-history-subheadings">
                                  <div className="payment-history-subheadings-text">
                                    Created on:
                                  </div>
                                  {item.created_on
                                    ? moment(item.created_on).format(
                                      "DD MMM YYYY"
                                    )
                                    : null}
                                </div>
                                <div
                                  className="payment-history-subheadings"
                                  style={{
                                    width: "60px",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  <div className="payment-history-subheadings-text">
                                    Status:
                                  </div>
                                  {item.customer_payment_status ==
                                    "submitted" ? (
                                    <div
                                      style={{
                                        color: "#BF9000",
                                      }}
                                    >
                                      {" "}
                                      {item.customer_payment_status
                                        ? item.customer_payment_status.toUpperCase()
                                        : "N.A"}
                                    </div>
                                  ) : item.customer_payment_status ==
                                    "recommended" ? (
                                    <div
                                      style={{
                                        color: "#C082A1",
                                        // textOverflow: "clip",
                                        // maxWidth: "30px",
                                        // wordWrap: "normal",
                                        // overflowWrap: "break-word",
                                      }}
                                    >
                                      {" "}
                                      {item.customer_payment_status
                                        ? item.customer_payment_status.toUpperCase()
                                        : "N.A"}
                                    </div>
                                  ) : item.customer_payment_status ==
                                    "submitted" ? (
                                    <div style={{ color: "#134F5C" }}>
                                      {" "}
                                      {item.customer_payment_status
                                        ? item.customer_payment_status.toUpperCase()
                                        : "N.A"}
                                    </div>
                                  ) : item.customer_payment_status ==
                                    "approved" ? (
                                    <div style={{ color: "#2C5E17" }}>
                                      {" "}
                                      {item.customer_payment_status
                                        ? item.customer_payment_status.toUpperCase()
                                        : "N.A"}
                                    </div>
                                  ) : item.customer_payment_status ==
                                    "rejected" ? (
                                    <div style={{ color: "red" }}>
                                      {" "}
                                      {item.customer_payment_status
                                        ? item.customer_payment_status.toUpperCase()
                                        : "N.A"}
                                    </div>
                                  ) : (
                                    "N.A"
                                  )}
                                </div>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className="mini-card-delivery-info-container-payment-history">
                                <div className="text-fields-payment-history">
                                  {/* <label htmlFor="">Remarks</label> */}
                                  {item.customer_payment_status == "approved" ||
                                    item.customer_payment_status == "rejected" ? (
                                    <>
                                      {" "}
                                      <div className="inoice-details-div">
                                        <div className="invoice-details-text">
                                          {/* Invoice: */}
                                          <div className="payment-history-subheadings-text">
                                            Invoice:
                                          </div>
                                          {item.reference_number
                                            ? item.reference_number
                                            : "--"}
                                        </div>
                                        <div className="invoice-details-text">
                                          {/* Invoice: */}
                                          <div className="payment-history-subheadings-text">
                                            Reference Id:
                                          </div>{" "}
                                          {item.transaction_reference_id
                                            ? item.transaction_reference_id
                                            : "--"}
                                        </div>
                                        <div className="invoice-details-text">
                                          {/* Account: */}
                                          <div className="payment-history-subheadings-text">
                                            Account:
                                          </div>
                                          {item.zoho_books_account_name
                                            ? item.zoho_books_account_name
                                            : "--"}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="inoice-details-div">
                                        <div className="invoice-details-text">
                                          <div className="payment-history-subheadings-text">
                                            Invoice:
                                          </div>{" "}
                                          {/* Invoice: */}
                                          {item.reference_number
                                            ? item.reference_number
                                            : "--"}
                                        </div>
                                        <div className="invoice-details-text">
                                          <div className="payment-history-subheadings-text">
                                            Reference Id:
                                          </div>{" "}
                                          {/* Invoice: */}
                                          {item.transaction_reference_id
                                            ? item.transaction_reference_id
                                            : "--"}
                                        </div>
                                        <div className="invoice-details-text">
                                          {/* Account: */}
                                          <div className="payment-history-subheadings-text">
                                            Account:
                                          </div>{" "}
                                          {item.zoho_books_account_name
                                            ? item.zoho_books_account_name
                                            : "--"}
                                        </div>
                                      </div>
                                      <textarea
                                        name="remarks"
                                        id=""
                                        placeholder="Enter Remarks"
                                        cols=""
                                        key={index}
                                        // style="font-size:12pt"
                                        rows="2"
                                        value={paymentRemarks}
                                        onChange={(e) => {
                                          setPaymentRemarks(e.target.value);
                                        }}
                                        style={{
                                          padding: "5px",
                                          width: "100%",
                                          marginTop: "5px",
                                          fontSize: "12px",
                                        }}
                                      ></textarea>
                                      {paymentstatusError ? (
                                        <div
                                          style={{
                                            fontSize: "10px",
                                            color: "red",
                                          }}
                                        >
                                          Error: {paymentstatusError}
                                        </div>
                                      ) : null}
                                    </>
                                  )}

                                  <div className="payment-history-buttons-main">
                                    {item.customer_payment_status ==
                                      "submitted" ? (
                                      <>
                                        {" "}
                                        <button
                                          disabled={
                                            loadingPaymentApprove ? true : false
                                          }
                                          className="payment-history-buttons"
                                          onClick={() => {
                                            handleRecommendRejectPayment(
                                              "recommended",
                                              item.id
                                            );
                                          }}
                                        >
                                          {loadingPaymentApprove ? (
                                            <>
                                              <CircularProgress
                                                size={12}
                                              // color="green"
                                              />
                                            </>
                                          ) : (
                                            "Recommend"
                                          )}
                                        </button>
                                        <button
                                          className="payment-history-buttons-reject"
                                          disabled={
                                            loadingPaymentRejected
                                              ? true
                                              : false
                                          }
                                          onClick={() => {
                                            handleRecommendRejectPayment(
                                              "rejected",
                                              item.id
                                            );
                                          }}
                                        >
                                          {loadingPaymentRejected ? (
                                            <>
                                              <CircularProgress
                                                size={12}
                                                color="error"
                                              />
                                            </>
                                          ) : (
                                            "Reject"
                                          )}
                                        </button>
                                      </>
                                    ) : item.customer_payment_status ==
                                      "recommended" ? (
                                      <>
                                        {" "}
                                        <button
                                          disabled={
                                            loadingPaymentApproveReject
                                              ? true
                                              : false
                                          }
                                          className="payment-history-buttons"
                                          onClick={() => {
                                            handleRecommendRejectPayment(
                                              "approved",
                                              item.id
                                            );
                                          }}
                                        >
                                          {loadingPaymentApproveReject ? (
                                            <>
                                              <CircularProgress
                                                size={12}

                                              // color="green"
                                              />
                                            </>
                                          ) : (
                                            "Approve"
                                          )}
                                        </button>
                                        <button
                                          className="payment-history-buttons-reject"
                                          disabled={
                                            loadingPaymentRejected
                                              ? true
                                              : false
                                          }
                                          onClick={() => {
                                            handleRecommendRejectPayment(
                                              "rejected",
                                              item.id
                                            );
                                          }}
                                        >
                                          {loadingPaymentRejected ? (
                                            <>
                                              <CircularProgress
                                                size={12}
                                                color="error"
                                              // color="green"
                                              />
                                            </>
                                          ) : (
                                            "Reject"
                                          )}
                                        </button>
                                      </>
                                    ) : item.customer_payment_status ==
                                      "approved" ? (
                                      <>
                                        <VerifiedSharpIcon color="success" />{" "}
                                        <div
                                          style={{
                                            color: "green",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Approved
                                        </div>
                                        {/* <button
                                          disabled={
                                            loadingPaymentApproveReject
                                              ? true
                                              : false
                                          }
                                          style={{ display: "none" }}
                                          className="payment-history-buttons"
                                          onClick={() => {
                                            handleRecommendRejectPayment(
                                              "approved",
                                              item.id
                                            );
                                          }}
                                        >
                                          {loadingPaymentApproveReject ? (
                                            <>
                                              <CircularProgress
                                                size={12}
                                                // color="green"
                                              />
                                            </>
                                          ) : (
                                            "Approve"
                                          )}
                                        </button>
                                        <button
                                          className="payment-history-buttons-reject"
                                          style={{ display: "none" }}
                                          disabled={
                                            loadingPaymentRejectedApprove
                                              ? true
                                              : false
                                          }
                                          onClick={() => {
                                            handleRecommendRejectPayment(
                                              "rejected",
                                              item.id
                                            );
                                          }}
                                        >
                                          {loadingPaymentRejectedApprove ? (
                                            <>
                                              <CircularProgress
                                                size={12}
                                                // color="green"
                                              />
                                            </>
                                          ) : (
                                            "Reject"
                                          )}
                                        </button> */}
                                      </>
                                    ) : item.customer_payment_status ==
                                      "rejected" ? (
                                      <>
                                        <CancelIcon style={{ color: "red" }} />{" "}
                                        <div
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          Rejected
                                        </div>
                                      </>
                                    ) : null}

                                    <ul
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        padding: "5px 5px",
                                        alignItems: "center",
                                        backgroundColor: `${darkMode
                                          ? colorPaletteDark.secondaryColor
                                          : colorPalette.white
                                          }`,

                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <li
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          backgroundColor: `${darkMode
                                            ? colorPaletteDark.primaryColor
                                            : colorPalette.white
                                            }`,
                                          color: `${darkMode
                                            ? colorPaletteDark.white
                                            : colorPalette.black
                                            }`,

                                          fontWeight: "400",
                                          fontSize: "12px",
                                        }}
                                      >
                                        <div
                                          className="trip_buttons_download"
                                          style={{
                                            padding: `${darkMode ? "5px" : "0px"
                                              }`,
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: `${darkMode
                                                ? colorPaletteDark.white
                                                : colorPalette.black
                                                }`,
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {" "}
                                            {/* {item.tag
                                    .split("_")
                                    .join(" ")
                                    .split("")
                                    .map((item, index) => {
                                      if (index == 0) {
                                        return item.toUpperCase();
                                      } else {
                                        return item;
                                      }
                                    })
                                    .join("")} */}
                                          </span>
                                        </div>

                                        <div
                                          className="trip_buttons_download"
                                          style={{ position: "relative" }}
                                        >
                                          <div
                                            style={{
                                              fontSize: "10px",
                                              position: "absolute",
                                              top: "-8px",
                                            }}
                                          >
                                            Attachment
                                          </div>
                                          <img
                                            src={showIcons}
                                            alt=""
                                            width="35px"
                                            height="35px"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              handleOpenImage();
                                              showPaymentAttachments(item.id);
                                            }}
                                          />

                                          <img
                                            src={downloadIcons}
                                            alt=""
                                            width="35px"
                                            height="35px"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              // handleOpenImage();
                                              downloadPaymentAttachment(
                                                item.id
                                              );
                                            }}
                                          />
                                          {/* <a
                                  href="javascript:void(0)"
                                  onClick={() => download(item.id)}
                                  className="attachment_btn"
                                  style={{ fontSize: "10px" }}
                                >
                                  <FeedIcon />{" "}
                                </a> */}
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })
                    ) : loadingPaymentHistory ? (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "30px",
                        }}
                      >
                        Loading data...
                      </div>
                    ) : (
                      <div className="mini-card-not-found">
                        <img
                          src={NoDetailsFound}
                          alt=""
                          width="80px"
                          height="80px"
                        />
                        <span
                          style={{
                            color: darkMode
                              ? colorPaletteDark.white
                              : colorPalette.black,
                          }}
                        >
                          No details found!{" "}
                        </span>
                      </div>
                    )}
                    {/* {quantityDeliveryFromHistory.length > 0 ? (
                  quantityDeliveryFromHistory.map((item) => {
                    return (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>
                            {item["Invoice Number"]
                              ? item["Invoice Number"]
                              : null}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="mini-card-delivery-info-container">
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Payment mode :
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                {item.payment_mode ? item.payment_mode : null}
                              </h5>
                            </div>
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Total Amount{" "}
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                : Rs. {item.amount ? item.amount : null}
                              </h5>
                            </div>
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Actual quantity
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                :{" "}
                                {item["Total Quantity"]
                                  ? item["Total Quantity"]
                                  : null}
                              </h5>
                            </div>
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Unloaded quantity{" "}
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                :{" "}
                                {item["Unloaded Quantity"]
                                  ? item["Unloaded Quantity"]
                                  : null}
                              </h5>
                            </div>
                            <div className="mini-card-row-delivery">
                              <h5 className="mini-card-delivery-left">
                                Status
                              </h5>
                              <h5 className="mini-card-delivery-right">
                                {" "}
                                : {item["Status"] ? item["Status"] : null}
                              </h5>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                ) : (
                  <div className="mini-card-not-found">No details found!</div>
                )} */}
                  </div>
                </div>
              ) : tripDetailsHeading == "Trip Costs" ? (
                // <div className="mini-details-tab-container-history">
                <div className="trip-cost-details-container">
                  {/* <div style={{ fontWeight: "bold" }}>Trip Cost Details</div> */}
                  {triCostDetails.length > 0 ? (
                    triCostDetails.map((data) => (
                      <div className="trip-cost-keys">
                        <span>
                          {data.cost_type
                            ? data.cost_type.split("_").join(" ").toUpperCase()
                            : null}
                        </span>{" "}
                        <span>
                          ₹{" "}
                          {data?.cost_amount >= 0 ? data.cost_amount : null("")}
                        </span>
                      </div>
                    ))
                  ) : // : triCostDetails.length == 0 ? (
                    //   <div
                    //     style={{
                    //       display: "flex",
                    //       width: "100%",
                    //       alignItems: "center",
                    //       justifyContent: "center",
                    //       marginTop: "30px",
                    //     }}
                    //   >
                    //     No Trip Cost found!
                    //   </div>
                    // )
                    loadingTripCost ? (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "30px",
                        }}
                      >
                        Loading data...
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "30px",
                        }}
                      >
                        No Trip Cost found!
                      </div>
                    )}
                </div>
              ) : // </div>
                null}
            </div>
            <div className="details_icons_conatainer">
              {/* import TripDetails from "../../../assets/tripIcons/history.png";
import DriverDetails from "../../../assets/tripIcons/driver.png";
import TripHistoryDetails from "../../../assets/tripIcons/history.png";
import TripDeliverDetails from "../../../assets/tripIcons/delivery.png";
import TripAttachmentsDetails from "../../../assets/tripIcons/attach-paperclip-symbol.png"; */}
              <div
                className={
                  tripDetailsHeading == "Trip Details"
                    ? "details_icons_img_container active"
                    : "details_icons_img_container"
                }
                // onKeyDownCapture="trip_details"
                onKeyUp={(e) => handleKeyPress(e, "Trip Details")}
              >
                <button
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    backgroundColor: `${darkMode
                      ? colorPaletteDark.secondaryColor
                      : colorPalette.white
                      }`,
                    height: "80%",
                  }}
                >
                  {tripDetailsHeading == "Trip Details" ? (
                    <img
                      src={TripDetailsSelected}
                      alt=""
                      width="30px"
                      height="30px"
                      aria-label="Trip Details"
                      className="details_icons"
                      // onKeydown={useKeyPress}
                      onClick={() => handleTripDetails("Trip Details")}
                    />
                  ) : (
                    <img
                      // onKeydown={useKeyPress}
                      src={TripDetails}
                      alt=""
                      width="30px"
                      aria-label="Trip Details"
                      height="30px"
                      className="details_icons"
                      onClick={() => handleTripDetails("Trip Details")}
                    />
                  )}
                </button>
                {/* {count} */}
                {/* <button onKeyPress={useKeyPress}></button> */}

                {/* <img
                  src={TripDetails}
                  alt=""
                  width="30px"
                  height="30px"
                  className="details_icons"
                  onClick={() => handleTripDetails("Trip Details")}
                /> */}
              </div>
              <div
                className={
                  tripDetailsHeading == "Driver"
                    ? "details_icons_img_container active"
                    : "details_icons_img_container"
                }
                onKeyDownCapture="Driver"
                onKeyUp={(e) => handleKeyPress(e, "Driver")}
              >
                <button
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    backgroundColor: `${darkMode
                      ? colorPaletteDark.secondaryColor
                      : colorPalette.white
                      }`,
                    height: "80%",
                  }}
                >
                  {tripDetailsHeading == "Driver" ? (
                    <img
                      src={DriverDetails}
                      alt=""
                      width="30px"
                      height="30px"
                      // activeClassName="details_icons"
                      className={
                        tripDetailsHeading == "Driver"
                          ? "details_icons active"
                          : "details_icons"
                      }
                      onClick={() => handleDriverDetails("Driver")}
                    />
                  ) : (
                    <img
                      src={DriverDetailsSelected}
                      alt=""
                      width="30px"
                      height="30px"
                      // activeClassName="details_icons"
                      className={
                        tripDetailsHeading == "Driver"
                          ? "details_icons active"
                          : "details_icons"
                      }
                      onClick={() => handleDriverDetails("Driver")}
                    />
                  )}
                </button>
              </div>
              <div
                className={
                  tripDetailsHeading == "Trip History"
                    ? "details_icons_img_container active"
                    : "details_icons_img_container"
                }
                onKeyUp={(e) => handleKeyPress(e, "Trip History")}
              >
                <button
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    backgroundColor: `${darkMode
                      ? colorPaletteDark.secondaryColor
                      : colorPalette.white
                      }`,
                    height: "80%",
                  }}
                >
                  {tripDetailsHeading == "Trip History" ? (
                    <img
                      src={TripHistoryDetails}
                      alt=""
                      width="30px"
                      height="30px"
                      className={
                        tripDetailsHeading == "Trip History"
                          ? "details_icons active"
                          : "details_icons"
                      }
                      onClick={() => handleTripHistoryDetails("Trip History")}
                    />
                  ) : (
                    <img
                      src={TripHistoryDetailsSelected}
                      alt=""
                      width="30px"
                      height="30px"
                      className={
                        tripDetailsHeading == "Trip History"
                          ? "details_icons active"
                          : "details_icons"
                      }
                      onClick={() => handleTripHistoryDetails("Trip History")}
                    />
                  )}
                </button>
              </div>
              <div
                className={
                  tripDetailsHeading == "Attachments"
                    ? "details_icons_img_container active"
                    : "details_icons_img_container"
                }
                onKeyUp={(e) => handleKeyPress(e, "Attachments")}
              >
                <button
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    backgroundColor: `${darkMode
                      ? colorPaletteDark.secondaryColor
                      : colorPalette.white
                      }`,
                    height: "80%",
                  }}
                >
                  {tripDetailsHeading == "Attachments" ? (
                    <img
                      src={TripAttachmentsDetailsSelected}
                      alt=""
                      width="30px"
                      height="30px"
                      className={
                        tripDetailsHeading == "Attachments"
                          ? "details_icons active"
                          : "details_icons"
                      }
                      onClick={() =>
                        handleTripAttachmentsDetails("Attachments")
                      }
                    />
                  ) : (
                    <img
                      src={TripAttachmentsDetails}
                      alt=""
                      width="30px"
                      height="30px"
                      className={
                        tripDetailsHeading == "Attachments"
                          ? "details_icons active"
                          : "details_icons"
                      }
                      onClick={() =>
                        handleTripAttachmentsDetails("Attachments")
                      }
                    />
                  )}
                </button>
              </div>

              <div
                className={
                  tripDetailsHeading == "Delivery Info"
                    ? "details_icons_img_container active"
                    : "details_icons_img_container"
                }
                onKeyUp={(e) => handleKeyPress(e, "Delivery Info")}
              >
                <button
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    backgroundColor: `${darkMode
                      ? colorPaletteDark.secondaryColor
                      : colorPalette.white
                      }`,
                    height: "80%",
                  }}
                >
                  {" "}
                  {tripDetailsHeading == "Delivery Info" ? (
                    <img
                      src={TripDeliverDetailsSelected}
                      alt=""
                      width="30px"
                      height="30px"
                      className={
                        tripDetailsHeading == "Delivery Info"
                          ? "details_icons active"
                          : "details_icons"
                      }
                      onClick={() => handleTripDeliveryDetails("Delivery Info")}
                    />
                  ) : (
                    <img
                      src={TripDeliverDetails}
                      alt=""
                      width="30px"
                      height="30px"
                      className={
                        tripDetailsHeading == "Delivery Info"
                          ? "details_icons active"
                          : "details_icons"
                      }
                      onClick={() => handleTripDeliveryDetails("Delivery Info")}
                    />
                  )}
                </button>
              </div>
              <div
                className={
                  tripDetailsHeading == "Payment History"
                    ? "details_icons_img_container active"
                    : "details_icons_img_container"
                }
              // onKeyUp={(e) => handleKeyPress(e, "Payment History")}
              >
                <button
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    backgroundColor: `${darkMode
                      ? colorPaletteDark.secondaryColor
                      : colorPalette.white
                      }`,
                    height: "80%",
                  }}
                >
                  {" "}
                  {tripDetailsHeading == "Payment History" ? (
                    <img
                      src={TripInvoicePaymentDetailsSelected}
                      alt=""
                      width="30px"
                      height="30px"
                      className={
                        tripDetailsHeading == "Payment History"
                          ? "details_icons active"
                          : "details_icons"
                      }
                      onClick={() =>
                        handleTripPaymentHistoryDetails("Payment History")
                      }
                    />
                  ) : (
                    <img
                      src={TripInvoicePaymentDetails}
                      alt=""
                      width="30px"
                      height="30px"
                      className={
                        tripDetailsHeading == "Payment History"
                          ? "details_icons active"
                          : "details_icons"
                      }
                      onClick={() =>
                        handleTripPaymentHistoryDetails("Payment History")
                      }
                    />
                  )}
                </button>
              </div>
              <div
                className={
                  tripDetailsHeading == "Trip Costs"
                    ? "details_icons_img_container active"
                    : "details_icons_img_container"
                }
                onKeyUp={(e) => handleKeyPress(e, "Trip Costs")}
              >
                <button
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    backgroundColor: `${darkMode
                      ? colorPaletteDark.secondaryColor
                      : colorPalette.white
                      }`,
                    height: "80%",
                  }}
                >
                  {" "}
                  {tripDetailsHeading == "Trip Costs" ? (
                    <img
                      src={TripCostDetailsSelected}
                      alt=""
                      width="30px"
                      height="30px"
                      className={
                        tripDetailsHeading == "Trip Costs"
                          ? "details_icons active"
                          : "details_icons"
                      }
                      onClick={() => handleCostDetails("Trip Costs")}
                    />
                  ) : (
                    <img
                      src={TripCostDetails}
                      alt=""
                      width="30px"
                      height="30px"
                      className={
                        tripDetailsHeading == "Trip Costs"
                          ? "details_icons active"
                          : "details_icons"
                      }
                      onClick={() => handleCostDetails("Trip Costs")}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </Trip_list_drawer_top_row>
      </Drawer>
      {/* ================================================================================================== */}
      {/* reject remarks modal ends */}
      <div className="trip-list-right-container">
        {isMobile ? null : <Topbar />}
        {/* {isMobile ? null : (
          <Stack
            sx={{
              width: "100%",
              height: "10px",
              padding: "0px 0px",
              marginBottom: "2rem",
              display: openAlert == true ? "block" : "none",

             
            }}
            spacing={2}
          
          >
            <Collapse in={openAlert}>
              <Alert
               
                style={{ backgroundColor: "#fde989", padding: "0rem 2rem" }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                   
                  >
                
                  </IconButton>
                }
                sx={{ padding: "0 2" }}
              >
                Go back to Classic{" "}
                <NavLink
                  style={{
                    color: "#379feb",
                    marginLeft: ".2rem",
                    fontWeight: "bold",
                  }}
                  to="/trips"
                  onClick={() => {
                    localStorage.removeItem("IntransiteStatus");
                    localStorage.removeItem("tripType");
                  }}
                >
                  Trips
                </NavLink>{" "}
                Page.
              </Alert>
            </Collapse>
          </Stack>
        )} */}

        <div className="trip-list-inner-left">
          <div
            className="trip-list-whole"
            style={
              Object.keys(selectedTrip).length > 0 || isMobile
                ? { width: "100%" }
                : { width: "90%" }
            }
          >
            <div className="trip-list-search-row">
              <div className="trip-list-search-container">
                <input
                  className="trip-list-search"
                  placeholder="search trip code"
                  value={tripSearchValue}
                  onChange={(event) => {
                    dispatch(updateTripSearchValue(event.target.value));
                    setCurrentPage(1);
                  }}
                />
                {filterTripCode ||
                  filterPaymentStatus ||
                  filterVehicleNumber ||
                  filterTripStatus ||
                  filterReferenceNumber ||
                  filterCustomerId ||
                  filterLoadSource ||
                  filterEwayBillNo ||
                  filterTripType ? (
                  <span
                    style={{
                      fontSize: "10px",
                      color: "red",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Filter applied
                  </span>
                ) : null}

                <img
                  src={FILTER_ICON}
                  onClick={handleOpenFilter}
                  alt=""
                  className="trip-list-filter-icon"
                />

                <Search />
              </div>
              <div className="trip-list-button-container">
                {/* <ButtonRectangle
                  onClick={handleCreateNewTripButton}
                  style={{ marginRight: "10px" }}
                  // className="create-new-trip-button"
                >
                  New Trip
                </ButtonRectangle> */}
                {/* <Dropdown
                  menu={{
                    items: items,
                  }}
                  trigger={["click"]}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <ButtonRectangle>
                      New Trip
                      <KeyboardArrowDownIcon />
                    </ButtonRectangle>
                  </a>
                </Dropdown> */}
                <ButtonRectangleSecondary
                  onClick={handleOpenExportReport}
                  style={{ marginTop: "0" }}
                // className="export-report-trip-button"
                >
                  {/* <div className="export-button-inner"> */}
                  <span className="export-button-inner">
                    <Download style={{ fontSize: "20px" }} />
                    Export
                  </span>
                  {/* </div> */}
                </ButtonRectangleSecondary>
                <a
                  // className="coverage-button"
                  target="_blank"
                  className="create-new-trip-button_analytics"
                  href="/analytics"
                  onClick={() => {
                    localStorage.removeItem("tripType");
                  }}
                >
                  {/* <QueryStatsIcon />  */}
                  <img src={mapIcon} width={"20px"} height={"20px"} />
                  <div className="map-tooltip">Trip Analytics</div>
                </a>
                <a
                  // className="coverage-button"
                  // target="_blank"
                  className="create-new-trip-button_analytics"
                // href="/analytics"
                >
                  {/* <QueryStatsIcon />  */}
                  <SettingsOutlinedIcon onClick={handleSettings} />
                  {/* <img src={mapIcon} width={"20px"} height={"20px"} /> */}
                  {/* <div className="map-tooltip">Trip Settings</div> */}
                </a>
              </div>

              {/* <a href="#selectedIndexTrip">
                {" "}
                <button>GO TO SELECTED TRIP</button>
              </a> */}
            </div>
            <div className="trips_status_container">
              <div>
                <FormControl
                  sx={{ m: 1, minWidth: 120, color: "green" }}
                  size="small"
                >
                  {" "}
                  <Select
                    value={tripType}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="trip-select-box"
                  >
                    <MenuItem
                      className="trip-select-box-value"
                      value=""
                      style={{ color: "green" }}
                    >
                      Select Trip Type
                    </MenuItem>
                    {tripTypeList.map((item) => {
                      return (
                        <MenuItem
                          className="trip-select-box-value"
                          value={item}
                        >
                          {item.replaceAll("_", " ").toUpperCase()}
                        </MenuItem>
                      );
                    })}

                    {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
              <div
                // key={index}
                className="trip_status_tab"
                style={{
                  borderBottom:
                    selectedTripStatus == 10
                      ? `5px solid ${colorPalette.primaryColor}`
                      : `5px solid ${colorPalette.secondaryWhite}`,
                  // borderBottomLeftRadius:
                  //   selectedTripStatus == 10 ? "5px" : "0px",
                  // borderBottomRightRadius:
                  //   selectedTripStatus == 10 ? "5px" : "0PX",
                  // border-radius: ;
                  color:
                    selectedTripStatus == 10
                      ? `${colorPalette.primaryColor}`
                      : `${colorPalette.paragraphSecondaryolor}`,
                }}
                onClick={() => {
                  // handleFilterSearch(1);
                  handleStatusClick(10, "");
                  selectedTripStatusText("");
                }}
              >
                <h4 className="trip_status_tab_text">
                  All
                  {/* {status.charAt(0).toUpperCase() + status.slice(1)} */}
                </h4>
              </div>
              {tripStatusList.map((status, index) => (
                <div
                  key={index}
                  className="trip_status_tab"
                  style={{
                    borderBottom:
                      selectedTripStatus == index
                        ? `5px solid ${colorPalette.primaryColor}`
                        : `5px solid ${colorPalette.secondaryWhite}`,
                    // borderRadius: "8px",
                    color:
                      selectedTripStatus == index
                        ? `${colorPalette.primaryColor}`
                        : `${colorPalette.paragraphSecondaryolor}`,
                  }}
                  onClick={() => {
                    handleStatusClick(index, status);
                    // setCurrentPage(1);
                  }}
                >
                  <div className="trip_status_tab_text">
                    {capitalizeLetter(status)}
                    {/* {status.charAt(0).toUpperCase() + status.slice(1)} */}
                  </div>
                </div>
              ))}
            </div>

            {/* <div className="create-new-trip-row"> */}

            {/* </div> */}
            {isUserNotAuthorized ? (
              <NotAuthorized />
            ) : loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: isMobile ? "100%" : "85vw",
                  // backgroundColor: "grey",
                }}
                className="trip-list-middle-container_v2"
              >
                {" "}
                <img
                  src={success}
                  alt=""
                  width="120px"
                  height="90px"
                  style={{ marginTop: "200px" }}
                />
                <h6 style={{ color: "grey" }}>loading data...</h6>
              </div>
            ) : allTripList.length !== 0 ? (
              <div className="trip-list-middle-container_v2">
                {allTripList.map((trip, index) => {
                  // {
                  //
                  // }
                  return (
                    <div
                      className="tl-new-single-container_v2"
                      id={
                        index == selectedTripIndex
                          ? "selectedIndexTrip"
                          : `null${index}`
                      }
                      style={{
                        border:
                          selectedTripIndex == index
                            ? `2px solid ${colorPalette.primaryColor} `
                            : null,
                        backgroundColor:
                          trip.trip_payment_status === "paid"
                            ? `${darkMode
                              ? colorPaletteDark.lightGreen
                              : colorPalette.lightGreen
                            }`
                            : null,
                      }}
                      onClick={() => {
                        handleSelectTrip(trip, index);
                        handleOpenDetailsDrawer();
                      }}
                      ref={selectedTripIndex == index ? myRef : null}
                    >
                      <div className="tl-new-main-left">
                        <div className="tl-new-left">
                          <div className="tl-new-inner-div">
                            <h4 className="tl-new-tripcode">
                              {trip.trip_code ? (
                                <CopyText text={trip.trip_code} />
                              ) : (
                                ""
                              )}
                            </h4>
                          </div>
                          <div className="tl-new-inner-div">
                            <h4
                              className="tl-new-vehicle"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <LocalShippingOutlinedIcon
                                style={{
                                  color: `${colorPalette.primaryColor}`,
                                }}
                              />{" "}
                              &nbsp;
                              {"   "}
                              {trip.vehicle_number
                                ? trip.vehicle_number
                                : " - "}{" "}
                              <span className="tl-new-payterm">
                                (
                                {trip.vehicle_payout_period
                                  ? trip.vehicle_payout_period.split("_")[0]
                                  : ""}
                                )
                              </span>
                            </h4>
                          </div>
                          <div className="tl-new-inner-div">
                            <div className="tl-new-vehicle">
                              <span
                                style={{
                                  fontWeight: tripCardLabel.fontWeight,
                                  fontFamily: tripCardLabel.fontFamily,
                                }}
                              >
                                {" "}
                                consignor :{" "}
                              </span>
                              {trip.customers_details.name
                                ? trip.customers_details.name
                                : " - "}{" "}
                            </div>
                          </div>
                          <div className="tl-new-inner-div">
                            <div className="tl-new-vehicle">
                              <span
                                style={{
                                  fontWeight: tripCardLabel.fontWeight,
                                  fontFamily: tripCardLabel.fontFamily,
                                }}
                              >
                                {" "}
                                consignee :{" "}
                              </span>
                              <span></span>
                              {trip.consignee_details?.name
                                ? trip.consignee_details.name
                                : " - "}{" "}
                            </div>
                          </div>
                          <div className="tl-new-inner-div">
                            <div className="tl-new-vehicle">
                              <span
                                style={{
                                  fontWeight: tripCardLabel.fontWeight,
                                  fontFamily: tripCardLabel.fontFamily,
                                }}
                              >
                                {" "}
                                Bill To :{" "}
                              </span>
                              {trip.bill_to_party_details?.name
                                ? trip.bill_to_party_details.name
                                : " - "}{" "}
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                            className="tl-new-inner-div"
                          >
                            <div
                              onClick={(e) =>
                                handleOpenTripRoute(e, trip, index)
                              }
                            >
                              <img
                                style={{
                                  cursor: "pointer",
                                  marginRight: "5px",
                                  backgroundColor: darkMode
                                    ? colorPaletteDark.white
                                    : "",
                                  borderRadius: "50%",
                                }}
                                src={INFOICON}
                                alt="info icon"
                              />
                            </div>
                            <div className="tl-new-payterm">
                              {trip.destination.length > 0
                                ? trip.destination.length == 1 &&
                                  trip.source.length == 1
                                  ? "Single stop"
                                  : "Multiple stop"
                                : null}
                            </div>

                            <h4
                              className="tl-new-vehicle"
                              style={
                                trip?.trip_payment_status === "unpaid"
                                  ? { color: "#E80303", marginLeft: "5px" }
                                  : trip.trip_payment_status === "paid"
                                    ? { color: "green", marginLeft: "5px" }
                                    : { color: "#F3B002", marginLeft: "5px" }
                              }
                            >
                              {trip.trip_payment_status
                                ? trip.trip_payment_status
                                  .split("_")
                                  .join(" ")
                                  .toUpperCase()
                                : "-"}
                            </h4>
                          </div>
                          {/* <div className="tl-new-inner-div">
                          
                          </div> */}
                        </div>
                        <div className="tl-new-middle">
                          <div className="tl-new-inner-div">
                            {allTripList.length == index + 1 ? (
                              <div className="tl-new-status" ref={lastItem}>
                                <span
                                  style={{
                                    // fontSize: "8px",
                                    color: "white",
                                    padding: "2px 4px",
                                    background:
                                      trip.trip_status === "completed"
                                        ? `${colorPalette.primaryColor}`
                                        : trip.trip_status === "cancelled"
                                          ? "red"
                                          : colorPalette.secondaryGreen,
                                    borderTopLeftRadius: "3px",
                                    borderBottomLeftRadius: "3px",
                                  }}
                                >
                                  {capitalizeLetter(
                                    trip.trip_status.toUpperCase()
                                  )}
                                  {/* {trip.trip_status.toUpperCase()}{" "} */}
                                </span>
                                <span
                                  style={{
                                    color: "white",
                                    padding: "2px 4px",
                                    background: "orange",
                                    borderTopRightRadius: "3px",
                                    borderBottomRightRadius: "3px",
                                  }}
                                >
                                  {trip.trip_type
                                    ? trip.trip_type
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()
                                    : trip.vehicle_freight_type
                                      ? trip.vehicle_freight_type.toUpperCase()
                                      : " - "}
                                </span>
                              </div>
                            ) : (
                              <div className="tl-new-status">
                                <span
                                  style={{
                                    color: "white",
                                    padding: "2px 4px",
                                    background:
                                      trip.trip_status === "completed"
                                        ? `${colorPalette.primaryColor}`
                                        : trip.trip_status === "cancelled"
                                          ? "red"
                                          : colorPalette.secondaryGreen,
                                    borderTopLeftRadius: "3px",
                                    borderBottomLeftRadius: "3px",
                                  }}
                                >
                                  {capitalizeLetter(
                                    trip.trip_status.toUpperCase()
                                  )}
                                  {/* {trip.trip_status.toUpperCase()}{" "} */}
                                </span>
                                <span
                                  style={{
                                    color: "white",

                                    padding: "2px 4px",
                                    background: "orange",
                                    borderTopRightRadius: "3px",
                                    borderBottomRightRadius: "3px",
                                  }}
                                >
                                  {trip.trip_type
                                    ? trip.trip_type
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()
                                    : trip.vehicle_freight_type
                                      ? trip.vehicle_freight_type.toUpperCase()
                                      : " - "}
                                </span>
                              </div>
                            )}
                          </div>

                          {/* <div className="tl-new-inner-div">
                            <h4 className="tl-new-modifiedby">
                              Per bag cost :
                              <span style={{ fontWeight: "600" }}>
                                {" "}
                                Rs.{" "}
                                {trip.trip_cost.filter(
                                  (item) => item.cost_type === "trip_cost"
                                ).length > 0
                                  ? trip.unloaded_quantity
                                    ? (
                                        trip.trip_cost.filter(
                                          (item) =>
                                            item.cost_type === "trip_cost"
                                        )[0].cost_amount /
                                        trip.unloaded_quantity
                                      ).toFixed(2)
                                    : (
                                        trip.trip_cost.filter(
                                          (item) =>
                                            item.cost_type === "trip_cost"
                                        )[0].cost_amount / trip.quantity
                                      ).toFixed(2)
                                  : " - "}
                              </span>
                            </h4>
                          </div> */}

                          <div className="tl-new-inner-div">
                            <div
                              className="tl-new-modifiedby"
                              style={{
                                fontWeight: tripCardLabel.fontWeight,
                                fontFamily: tripCardLabel.fontFamily,
                              }}
                            >
                              Billed Amount :{" "}
                              <span
                                style={{ fontWeight: "600", fontSize: "10px" }}
                              >
                                {trip?.trip_customer_invoice?.length > 0
                                  ? `Rs. ${trip.trip_customer_invoice[0]?.customer_billed_amount}`
                                  : " - "}
                              </span>
                            </div>
                          </div>
                          <div className="tl-new-inner-div">
                            <div
                              className="tl-new-modifiedby"
                              style={{
                                fontWeight: tripCardLabel.fontWeight,
                                fontFamily: tripCardLabel.fontFamily,
                              }}
                            >
                              Customer Advance :{" "}
                              <span
                                style={{ fontWeight: "600", fontSize: "10px" }}
                              >
                                {trip?.trip_customer_invoice?.length > 0
                                  ? `Rs. ${trip.trip_customer_invoice[0]?.advance_paid_by_customer}`
                                  : " - "}
                              </span>
                            </div>
                          </div>
                          <div className="tl-new-inner-div">
                            <div
                              className="tl-new-modifiedby"
                              style={{
                                fontWeight: tripCardLabel.fontWeight,
                                fontFamily: tripCardLabel.fontFamily,
                              }}
                            >
                              Transport cost :{" "}
                              <span
                                style={{ fontWeight: "600", fontSize: "10px" }}
                              >
                                {trip?.trip_cost?.length > 0
                                  ? trip?.trip_cost.filter(
                                    (item) => item.cost_type === "trip_cost"
                                  ).length > 0
                                    ? `Rs. ${trip?.trip_cost.filter(
                                      (item) =>
                                        item.cost_type === "trip_cost"
                                    )[0].cost_amount
                                    }`
                                    : " - "
                                  : " - "}
                              </span>
                            </div>
                          </div>
                          <div className="tl-new-inner-div">
                            <div
                              className="tl-new-modifiedby"
                              style={{
                                fontWeight: tripCardLabel.fontWeight,
                                fontFamily: tripCardLabel.fontFamily,
                              }}
                            >
                              Transport advance :{" "}
                              <span
                                style={{ fontWeight: "600", fontSize: "10px" }}
                              >
                                {trip?.trip_cost?.length > 0
                                  ? trip?.trip_cost.filter(
                                    (item) => item.cost_type === "advance"
                                  ).length > 0
                                    ? `Rs. ${trip?.trip_cost.filter(
                                      (item) => item.cost_type === "advance"
                                    )[0].cost_amount
                                    }`
                                    : " - "
                                  : " - "}
                              </span>
                            </div>
                          </div>
                          <div className="tl-new-inner-div">
                            <div
                              className="tl-new-modifiedby"
                              style={{
                                fontWeight: tripCardLabel.fontWeight,
                                fontFamily: tripCardLabel.fontFamily,
                              }}
                            >
                              Payment Terms :{" "}
                              <span
                                style={{ fontWeight: "600", fontSize: "10px" }}
                              >
                                {trip.payment_terms
                                  ? trip.payment_terms.includes("to_pay")
                                    ? "TO PAY"
                                    : trip.payment_terms
                                      .split("_")
                                      .join(" ")
                                      .toUpperCase()
                                  : " - "}
                              </span>
                            </div>
                          </div>
                          {/* <div className="tl-new-inner-div">
                            <h4 className="tl-new-modifiedby">
                              {trip.trip_status
                                .split("")
                                .map((item, index) => {
                                  if (index == 0) {
                                    return item.toUpperCase();
                                  } else {
                                    return item;
                                  }
                                })
                                .join("") === "Intransit"
                                ? "Started"
                                : trip.trip_status
                                    .split("")
                                    .map((item, index) => {
                                      if (index == 0) {
                                        return item.toUpperCase();
                                      } else {
                                        return item;
                                      }
                                    })
                                    .join("")}{" "}
                              by :{" "}
                              {trip.last_modified_by
                                ? trip.last_modified_by
                                : " - "}
                            </h4>
                          </div> */}
                          <div className="tl-new-inner-div">
                            <h4
                              className="tl-new-modifiedby"
                              style={{
                                fontWeight: tripCardLabel.fontWeight,
                                fontFamily: tripCardLabel.fontFamily,
                              }}
                            >
                              Created Date :{" "}
                              {trip.created_on
                                ? moment
                                  .utc(trip.created_on)
                                  .local()
                                  .format("DD-MM-YYYY hh:mm a")
                                : " - "}
                            </h4>
                          </div>
                        </div>
                      </div>
                      {/* {console.log("userRole>>>", userRole)} */}
                      {/* {isMobile ? (
                        <div className="tl-new-right">
                          {trip.trip_status == "created" ? (
                            <div className="trip-list-right-most-single-container">
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangle
                                  // className="trip-list-approve-btn"
                                  onClick={(e) => handleApproveTrip(trip, e)}
                                >
                                  Approve
                                </ButtonRectangle>
                              </div>
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangleSecondary
                                  // className="trip-list-reject-btn"
                                  onClick={(e) => handleRejectTrip(trip, e)}
                                >
                                  Reject
                                </ButtonRectangleSecondary>
                              </div>
                              <div
                                onClick={() => handleEditTrip(trip)}
                                className="trip-list-right-most-edit"
                              >
                                <Edit />
                              </div>
                            </div>
                          ) : (trip.trip_status == "created" ||
                              trip.trip_status == "reopen") &&
                            userRole == "Logistics Incharge" ? (
                            <div
                              style={{ display: "flex" }}
                              className="trip-list-waiting"
                            >
                              <h5 style={{ color: "grey" }}>
                                waiting for the approval
                              </h5>
                            </div>
                          ) : trip.trip_status == "rejected" ? (
                            <div className="trip-list-right-most-single-container">
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangle
                                  // className="trip-list-approve-btn"
                                  onClick={(e) => handleReopenTrip(trip, e)}
                                >
                                  <RestartAltIcon
                                    style={{ fontSize: "16px", color: "white" }}
                                  />
                                  Reopen
                                </ButtonRectangle>
                              </div>
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangleSecondary
                                  // className="trip-list-reject-btn"
                                  onClick={(e) => handleCancelTrip(trip, e)}
                                >
                                  Cancel
                                </ButtonRectangleSecondary>
                              </div>
                              <div
                                onClick={() => handleEditTrip(trip)}
                                className="trip-list-right-most-edit"
                              >
                                <Edit />
                              </div>
                            </div>
                          ) : trip.trip_status == "reopen" ? (
                            <div className="trip-list-right-most-single-container">
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangle
                                  // className="trip-list-approve-btn"
                                  onClick={(e) => handleApproveTrip(trip, e)}
                                >
                                  Approve
                                </ButtonRectangle>
                              </div>
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangleSecondary
                                  // className="trip-list-reject-btn"
                                  onClick={(e) => handleRejectTrip(trip, e)}
                                >
                                  Reject
                                </ButtonRectangleSecondary>
                              </div>
                              <div
                                onClick={() => handleEditTrip(trip)}
                                className="trip-list-right-most-edit"
                              >
                                <Edit />
                              </div>
                            </div>
                          ) : trip.trip_status == "approved" ? (
                            <div className="trip-list-right-most-single-container">
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangle
                                  // className="trip-list-approve-btn"
                                  onClick={(e) => handleStartTrip(trip, e)}
                                >
                                  Start
                                </ButtonRectangle>
                              </div>
                              {console.log(
                                "pickup status>>>>>>>>>>",
                                !trip?.trip_entity_mapping_details
                                  .map((item) => item.status === "picked")
                                  .indexOf(false) >= 0,
                                trip.trip_code
                              )}
                              {trip.load_source === "trip_invoice" &&
                              (trip?.trip_entity_mapping_details?.length > 0
                                ? trip?.trip_entity_mapping_details
                                    .map((item) => item.status === "picked")
                                    .indexOf(false) >= 0
                                : false) ? (
                                <div className="trip-list-right-most-btn-container">
                                  <ButtonRectangleSecondary
                                    // className="trip-list-reject-btn"
                                    onClick={(e) =>
                                      handlePickupTrip(e, trip, index)
                                    }
                                  >
                                    Pickup
                                  </ButtonRectangleSecondary>
                                </div>
                              ) : null}
                            </div>
                          ) : trip.trip_status == "intransit" ? (
                            <div className="trip-list-right-most-single-container">
                              <div className="trip-list-right-most-btn-container">
                                <ButtonRectangle
                                  // className="trip-list-approve-btn"
                                  onClick={() => handleCompleteTrip(trip)}
                                >
                                  Complete
                                </ButtonRectangle>
                              </div>
                              {trip.load_source === "trip_invoice" &&
                              trip.pending_reference_numbers.length > 0 ? (
                                <div className="trip-list-right-most-btn-container">
                                  <button
                                    className="trip-list-approve-btn"
                                    onClick={() =>
                                      handleDeliveryInvoicePayment(trip)
                                    }
                                  >
                                    Delivery
                                  </button>
                                </div>
                              ) : null}
                              {trip.load_source === "trip_invoice" &&
                              (trip?.trip_entity_mapping_details?.length > 0
                                ? trip?.trip_entity_mapping_details
                                    .map((item) => item.status === "picked")
                                    .indexOf(false) >= 0
                                : false) ? (
                                <div className="trip-list-right-most-btn-container">
                                  <ButtonRectangleSecondary
                                    // className="trip-list-reject-btn"
                                    onClick={(e) =>
                                      handlePickupTrip(e, trip, index)
                                    }
                                  >
                                    Pickup
                                  </ButtonRectangleSecondary>
                                </div>
                              ) : null}
                            </div>
                          ) : trip.trip_status == "cancelled" ? (
                            <div className="trip-list-right-most-single-container"></div>
                          ) : null}
                        </div>
                      ) : null} */}
                      {/* <div className="tl-new-right">
                        {trip.trip_status == "created" ? (
                          <div className="trip-list-right-most-single-container">
                            <div
                              onClick={() => handleEditTrip(trip)}
                              className="trip-list-right-most-edit"
                            >
                              <Edit />
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={(e) => handleApproveTrip(trip, e)}
                              >
                                Approve
                              </button>
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-reject-btn"
                                onClick={(e) => handleRejectTrip(trip, e)}
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                        ) : (trip.trip_status == "created" ||
                            trip.trip_status == "reopen") &&
                          userRole == "Logistics Incharge" ? (
                          <div
                            style={{ display: "flex" }}
                            className="trip-list-waiting"
                          >
                            <h5 style={{ color: "grey" }}>
                              waiting for the approval
                            </h5>
                          </div>
                        ) : trip.trip_status == "rejected" ? (
                          <div className="trip-list-right-most-single-container">
                            <div
                              onClick={() => handleEditTrip(trip)}
                              className="trip-list-right-most-edit"
                            >
                              <Edit />
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={(e) => handleReopenTrip(trip, e)}
                              >
                                Reopen
                              </button>
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-reject-btn"
                                onClick={(e) => handleCancelTrip(trip, e)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : trip.trip_status == "reopen" ? (
                          <div className="trip-list-right-most-single-container">
                            <div
                              onClick={() => handleEditTrip(trip)}
                              className="trip-list-right-most-edit"
                            >
                              <Edit />
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={(e) => handleApproveTrip(trip, e)}
                              >
                                Approve
                              </button>
                            </div>
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-reject-btn"
                                onClick={(e) => handleRejectTrip(trip, e)}
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                        ) : trip.trip_status == "approved" ? (
                          <div className="trip-list-right-most-single-container">
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={(e) => handleStartTrip(trip, e)}
                              >
                                Start
                              </button>
                            </div>
                            {console.log(
                              "pickup status>>>>>>>>>>",
                              !trip?.trip_entity_mapping_details
                                .map((item) => item.status === "picked")
                                .indexOf(false) >= 0,
                              trip.trip_code
                            )}
                            {trip.load_source === "trip_invoice" &&
                            (trip?.trip_entity_mapping_details?.length > 0
                              ? trip?.trip_entity_mapping_details
                                  .map((item) => item.status === "picked")
                                  .indexOf(false) >= 0
                              : false) ? (
                              <div className="trip-list-right-most-btn-container">
                                <button
                                  className="trip-list-reject-btn"
                                  onClick={(e) =>
                                    handlePickupTrip(e, trip, index)
                                  }
                                >
                                  Pickup
                                </button>
                              </div>
                            ) : null}
                          </div>
                        ) : trip.trip_status == "intransit" ? (
                          <div className="trip-list-right-most-single-container">
                            <div className="trip-list-right-most-btn-container">
                              <button
                                className="trip-list-approve-btn"
                                onClick={() => handleCompleteTrip(trip)}
                              >
                                Complete
                              </button>
                            </div>
                            {trip.load_source === "trip_invoice" &&
                            trip.pending_reference_numbers.length > 0 ? (
                              <div className="trip-list-right-most-btn-container">
                                <button
                                  className="trip-list-reject-btn"
                                  onClick={() =>
                                    handleDeliveryInvoicePayment(trip)
                                  }
                                >
                                  Delivery
                                </button>
                              </div>
                            ) : null}
                            {trip.load_source === "trip_invoice" &&
                            (trip?.trip_entity_mapping_details?.length > 0
                              ? trip?.trip_entity_mapping_details
                                  .map((item) => item.status === "picked")
                                  .indexOf(false) >= 0
                              : false) ? (
                              <div className="trip-list-right-most-btn-container">
                                <button
                                  className="trip-list-reject-btn"
                                  onClick={(e) =>
                                    handlePickupTrip(e, trip, index)
                                  }
                                >
                                  Pickup
                                </button>
                              </div>
                            ) : null}
                          </div>
                        ) : trip.trip_status == "cancelled" ? (
                          <div className="trip-list-right-most-single-container"></div>
                        ) : null}
                      </div> */}
                    </div>
                  );
                })}

                {isLastPage ? null : (
                  <div className="trip-list-loading-more_v2">
                    Loading more..Please wait..!!!
                  </div>
                )}
              </div>
            ) : (
              <div className="trip-list-middle-container_v2">
                {openFullPageLoading ? null : (
                  <h4
                    style={{
                      textAlign: "center",
                      marginTop: "30%",
                      marginLeft: "1%",
                      width: "100%",
                      color: darkMode ? "white" : "",
                    }}
                  >
                    No Trip Found! Please create one
                  </h4>
                )}
              </div>
            )}
          </div>
        </div>
        {/* {isMobile ? (
          <Drawer
            anchor={"right"}
            open={state["right"]}
            onClose={() => toggleDrawer("right", false)}
          >
            <div className="trip-list-drawer-top-row">
              <span className="trip-list-trip-details-drawer-head">
                Trip details
              </span>
              <Close onClick={handleCloseDetailsDrawer} />
            </div>

            <div className="trip-list-inner-right">
              <MiniDetailsCard tripDetails={selectedTrip} />
            </div>
          </Drawer>
        ) : 
     
        null} */}
      </div>
    </Trip_list_main_container>
  );
}

export default TripListV2;
