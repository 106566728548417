import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../../themes/theme";

export const TripIndentStyle = styled.div`

  }
  .intend-card-edit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .customerIndent-container {
    background-color: ${colorPalette.secondaryWhite};
    width: 85vw;
    height: 100vh;
    overflow-y: hidden;
    @media only screen and (max-width: 480px) {
      width: 100vw;
    }
  }
  .quotes-text {
    /* font-size: ${typography.paragraph_secondary}; */
    @media only screen and (max-width: 480px) {
      font-size: ${typography.paragraph_secondary};
      background-color: ${colorPalette.red};
    }
  }
  .trip-indent-sidebar {
    width: 15vw;
  }

  .indents-button-divs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  .quotaion-button {
    padding: 0.2rem 0.5rem;
    background-color: ${colorPalette.primaryColor};
    outline: none;
    color: ${colorPalette.white};
    border-radius: 10px;
    border: none;
    margin-left: 10px;
    cursor: pointer;
  }
  .top-container {
    background: ${colorPalette.white};
    width: 100%;
    // height: 10vh;
    // margin-top: 50px;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 480px) {
      /* background: ${colorPalette.red}; */
      width: 100%;
      // height: 10vh;
      // margin-top: 50px;
      display: flex;
      /* justify-content: space-between; */
    }
  }
  .tab-menu-option {
    margin: 0px 5%;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading3};
    line-height: 27px;
    cursor: pointer;
  }
  .active-tab-option {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
  }
  .edit-button {
    background: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    border: none;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      background: ${colorPalette.primaryColor};
      color: ${colorPalette.white};
      border: none;
      padding: 6px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      /* margin-right: 10px; */
      cursor: pointer;
    }
  }
  .mobile_button {
    @media only screen and (max-width: 480px) {
      padding: 0.4rem 0.4rem;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .tab-bottom-rectangle {
    background: ${colorPalette.primaryColor};
    border-radius: 5px 5px 0px 0px;
    height: 4px;
    width: 130%;
  }
  .tab-menuOption-contain {
    display: flex;
    margin: 0px 20px 0px 20px;
    @media only screen and (max-width: 480px) {
      width: 80%;
    }
  }
  .create-indent {
    @media only screen and (max-width: 480px) {
      width: 20%;
    }
  }
  .customerIndent-main-body {
    width: 100%;
    /* display: grid;
    grid-template-columns: auto auto;
    background-color: red; */
    @media only screen and (max-width: 480px) {
      width: 100%;
      /* display: grid; */
      /* grid-template-columns: auto; */
    }
  }
  .customerIndent-main-body-left {
    width: 100%;
    @media only screen and (max-width: 480px) {
      width: 100%;
      /* background-color:red ; */
    }
  }
  .customerIndent-search-contain {
    border-radius: 8px;
    background: ${colorPalette.white};
    width: 80%;
    margin: 20px;
    padding: 6px;
    display: flex;
    justify-content: center;
  }
  .intend-circle {
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background: ${colorPalette.black};
  }
  .customerIndent-search-input {
    outline: none;
    border: none;
    width: 97%;
    height: 40px;
  }
  .intend-card-container {
    padding: 15px;
    border-radius: 8px;
    width: 100%;
    margin: 10px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    background-color: ${colorPalette.white};
    border: 2px solid ${colorPalette.transparent};
    @media only screen and (max-width: 480px) {
      padding: 15px;
      border-radius: 8px;
      width: 100%;
      width: 97%;
      /* margin: 10px; */
      box-shadow: ${colorPalette.boxShadowPrimary};
      background-color: ${colorPalette.white};
      border: 2px solid ${colorPalette.transparent};
      /* background-color:red ; */
    }
  }

  .indent-selected {
    border: 2px solid ${colorPalette.primaryColor};
  }
  .intend-number-text {
    color: ${colorPalette.black};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.heading5};
  }
  .intend-amount-text {
    color: ${colorPalette.paragraphColor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    display:flex ;
    align-items:center ;
    justify-content:center ;
    @media only screen and (max-width: 480px) {
      font-size: ${typography.paragraph2};
    }
  }
  .intend-card-top {
    width:100% ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0px;
    @media only screen and (max-width: 480px) {
      margin: 4px 0px;
    }
  }
  .indent-stop-text {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 18px;
    width: 200px;
    @media only screen and (max-width: 480px) {
      font-size: ${typography.paragraph2};
    }
  }
  .indent-stop-document {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 18px;
  }
  .indent-status-button {
    background: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    border: none;
    padding: 10px;
    border-radius: 50px;
    display: flex;
    font-size: ${typography.paragraph_secondary};
    font-weight: ${FontWeight.bold};
    justify-content: center;
    width: 90px;
    align-items: center;
    /* margin-right: 10px; */
    cursor: pointer;
  }

  .indent_card_price {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
    color: ${colorPalette.paragraphColor};
    margin-right: 10px;
  }

  .indentList-container {
    /* height: 75vh;
    cursor: pointer;
    overflow: scroll; */
    height: 80vh;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-rows: min-content;
    /* background-color: red; */
    gap: 5px;
    /* width: 100%; */
    padding: 10px;
    position: sticky;
    overflow-y: scroll;

    @media only screen and (max-width: 480px) {
      /* height: 75vh; */
      cursor: pointer;
      /* width: 100%; */
      height: 80vh;
      /* width: 100%; */
      display: grid;
      grid-template-columns: 100%;
      grid-auto-rows: min-content;
      /* background-color: red; */
      gap: 5px;
      /* width: 100%; */
      padding: 10px;
      position: sticky;
      /* background-color:red ; */
      /* overflow-y: scroll; */

      overflow: scroll;
    }
  }

  .loadingMore {
    width: 100%;
    height: 40px;
/* display:flex ;
align-items:center ;
justify-content:center ; */
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading5};
    color: ${colorPalette.paragraphColor};
    /* background-color: ${colorPalette.red}; */
  }
`;
export const IndentDrawer = styled.div`
  width: 100%;
  padding: 20px;
  .indent-button-assignee {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0px;
  }

  .accept_drawer_heading {
    font-size: 20px;
    font-weight: ${FontWeight.semibold};
  }
  .error-assignee {
    color: red;
    padding: 5px 5px;
    margin-top: 10px;
    background-color: #fce5e5;
  }
  .add_remark {
    width: 100%;
    height: 200px;
    margin-top: 20px;

    textarea {
      width: 100%;
      height: 100%;
      resize: none;
      padding: 20px;
      border: 1px solid #e5e5e5;

      :focus {
        border-color: #999;
      }
    }
  }

  .accept_button {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: end;

    button {
      width: 130px;
      padding: 14px;
      cursor: pointer;
      background-color: ${colorPalette.primaryColor};
      border: none;
      border-radius: 100px;
      font-weight: ${FontWeight.semibold};
      color: white;
    }
  }
  .edit-assigned {
    font-size: 12px;
    color: black;
    margin-top: 420px;
  }
`;
