import { React, useEffect, useState } from "react";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Carrier from "../../assets/dashboard_icons/icon-0.8s-215px.png";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import { updateCarrierId } from "../../redux/onboardSlice";
import { onboardRegisterPost } from "../onboard/OnboardServices";
import axios from "axios";
import { DetailOnboardStyle } from "./onboardDetails";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import apiUrl from "../../url/apiUrl";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { onboardData } from "../../redux/onboardSlice";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  updateVehicleId,
  updateRoutes,
  updateRoutesAddVehicle,
} from "../../redux/onboardSlice";
import { getCarrierListById } from "../onboard/OnboardServices";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import Topbar from "../../components/topbar/Topbar.jsx";

function OnboardDetails() {
  const { id } = useParams();

  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [carrierListDataById, setCarrierListDataById] = useState({});

  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const getCarrierDataById = async (ids) => {
    setLoading(true);
    const carrierResponseData = await getCarrierListById(ids);
    //
    setCarrierListDataById(carrierResponseData);
    setLoading(false);
  };
  useEffect(() => {
    if (id) {
      getCarrierDataById(id);
    }
  }, [id]);

  const handleVehicleId = (id, carrier) => {
    //
    dispatch(updateRoutesAddVehicle("from_carrier_add_vehicle"));
    dispatch(updateVehicleId({ id: id, carrier: carrier }));
    dispatch(updateRoutes("from_carrier_details"));
    history.push(`/vehicles-onboard`);

    // dispatch(updateVehicleId({ carrier: ids }));
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleAddVehicle = (ids) => {
    history.push(`/vehicles-onboard`);
    dispatch(updateCarrierId(ids));
    dispatch(updateVehicleId({ carrier: ids }));
    dispatch(updateRoutesAddVehicle("from_carrier_add_vehicle"));
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  return (
    <DetailOnboardStyle>
      <div
        className="details_onboard_wrapper"
        style={{ width: "100%", display: "flex" }}
      >
        {isMobile ? (
          <div className="trip-list-mobile-header">
            <MobileHeader headerTitle="Vehicles List" />
          </div>
        ) : (
          <div className="details_onboard_Left_box">
            <SideBarMain />
          </div>
        )}

        <div className="details_onboard_Right_box">
          {isMobile ? null : <Topbar />}
          <div className="vehicles_container_details">
            <div className="vehicleName">
              <NavLink to="/transporters" className="navLink">
                <ArrowBackIcon className="arrow_icon" />
              </NavLink>

              <h6 className="name_vehicle">{carrierListDataById.name}</h6>
            </div>
            <div className="vehicleId">
              <h6 className="vehicle_id">Carrier Id:</h6>
              <h6 className="id_value">{id}</h6>
            </div>
          </div>
          <div className="main_details_container">
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Tabs
                fullWidth
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="all"
              >
                {/* <Tab className="tab1" label="Profile" {...a11yProps(0)} /> */}
                {/* <Tab
                  className="tab"
                  label="Carrier Details"
                  {...a11yProps(1)}
                /> */}
                {/* <Tab className="tab2" label="Vehicles" {...a11yProps(1)} /> */}
                <Tab className="tab3" label="Vehicles" {...a11yProps(0)} />
                {/* <Tab className="tab4" label="Recent Trips" /> */}
              </Tabs>
              {/* <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <h5
                  onClick={() => handleAddVehicle(id)}
                  className="btn_card_vehicle_details"
                >
                  <AddIcon />
                  Add Vehicle
                </h5>
       
              </div> */}
            </Box>
            {/* <TabPanel value={value} index={0}>
              {Object.keys(carrierListDataById).length == 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    // backgroundColor: "grey",
                  }}
                >
                  {" "}
                  <img
                    src={success}
                    alt=""
                    width="120px"
                    height="90px"
                    style={{ marginTop: "200px" }}
                  />
                  <h6 style={{ color: "grey" }}>loading data...</h6>
                </div>
              ) : (
                <div className="customer_container">
                  <div className="details_name">
                    <h6 className="details_name_head">Full Name</h6>
                    <h6 className="details_name_text">
                      : {carrierListDataById.name}
                    </h6>
                  </div>
                  <div className="details_name">
                    <h6 className="details_name_head">Carrier Type</h6>
                    <h6 className="details_name_text">
                      : {carrierListDataById.transporter_type}
                    </h6>
                  </div>
                  <div className="details_name">
                    <h6 className="details_name_head">Mobile Number</h6>
                    <h6 className="details_name_text">
                      : {carrierListDataById.mobile}
                    </h6>
                  </div>
                  <div className="details_name">
                    <h6 className="details_name_head">E-Mail</h6>
                    <h6 className="details_name_text">
                      : {carrierListDataById.email}
                    </h6>
                  </div>
                  <div className="details_name">
                    <h6 className="details_name_head">GSTIN Number</h6>
                    <h6 className="details_name_text">
                      : {carrierListDataById.gstin_number}
                    </h6>
                  </div>
                  <div className="details_name">
                    <h6 className="details_name_head">PAN Number</h6>
                    <h6 className="details_name_text">
                      : {carrierListDataById.pan_number}
                    </h6>
                  </div>
                  <div className="details_name">
                    <h6 className="details_name_head">Bank Account Number</h6>
                    <h6 className="details_name_text">
                      : {carrierListDataById.bank_account_number}{" "}
                    </h6>
                  </div>
                  <div className="details_name">
                    <h6 className="details_name_head">Bank Name</h6>
                    <h6 className="details_name_text">
                      : {carrierListDataById.bank_name}{" "}
                    </h6>
                  </div>
                  <div className="details_name">
                    <h6 className="details_name_head">Bank Account Holder</h6>
                    <h6 className="details_name_text">
                      : {carrierListDataById.account_holder_name}
                    </h6>
                  </div>
                  <div className="details_name">
                    <h6 className="details_name_head">Bank Account Type</h6>
                    <h6 className="details_name_text">
                      :{carrierListDataById.bank_account_type}{" "}
                    </h6>
                  </div>
                  <div className="details_name">
                    <h6 className="details_name_head">IFSC Code</h6>
                    <h6 className="details_name_text">
                      : {carrierListDataById.ifsc_code}
                    </h6>
                  </div>
                  <div className="details_name">
                    <h6 className="details_name_head">Aaadhar Number</h6>
                    <h6 className="details_name_text">
                      : {carrierListDataById.aadhar_number}
                    </h6>
                  </div>
                </div>
              )}
            </TabPanel> */}
            <TabPanel
              // style={{ backgroundColor: "red" }}
              value={value}
              index={0}
              className="tab_div"
            >
              {carrierListDataById.vehicles &&
              carrierListDataById.vehicles.length > 0 ? (
                <div className="vehicles_container">
                  {carrierListDataById.vehicles.map((vehicles, index) => (
                    <div className="vehicle_card" key={index}>
                      {/* {isMobile ? null : (
                        <div className="image_div">
                          <div>
                            <img src={Carrier} alt="" width="150px" />
                          </div>
                        </div>
                      )} */}

                      <div className="vehicle_left">
                        <div className="vehicles_div">
                          <h6 className="vehicles_attributes">Model Name</h6>
                          <h6 className="vehicles_value">
                            : {vehicles.model_name.toUpperCase()}
                          </h6>
                        </div>
                        <div className="vehicles_div">
                          <h6 className="vehicles_attributes">Number</h6>
                          <h6 className="vehicles_value">
                            : {vehicles.vehicle_number.toUpperCase()}
                          </h6>
                        </div>
                        <div className="vehicles_div">
                          <h6 className="vehicles_attributes"> Vehicle Type</h6>
                          <h6 className="vehicles_value">
                            :
                            {vehicles.vehicle_type
                              .split("_")
                              .join(" ")
                              .toUpperCase()}
                          </h6>
                        </div>
                        <div className="vehicles_div">
                          <h6 className="vehicles_attributes">Capcity</h6>
                          <h6 className="vehicles_value">
                            :{vehicles.maximum_load_capacity}
                            {vehicles.unit_symbol}
                          </h6>
                        </div>
                      </div>
                      <div className="vehicle_right">
                        <div className="vehicles_div">
                          <h6 className="vehicles_attributes">Permit</h6>
                          <h6 className="vehicles_value">
                            :
                            {vehicles.is_national_permit == true
                              ? "National"
                              : "State"}
                          </h6>
                        </div>
                        <div className="vehicles_div">
                          <h6 className="vehicles_attributes">
                            Permitted State
                          </h6>
                          <h6
                            style={{ marginRight: ".5rem" }}
                            className="vehicles_value"
                          >
                            :
                            {vehicles.permitted_states ? (
                              <>
                                {vehicles.permitted_states.map(
                                  (state) => state.toUpperCase() + " "
                                )}
                              </>
                            ) : (
                              "N.A"
                            )}
                          </h6>
                        </div>
                        {/* <div className="vehicles_div">
                          <h6 className="vehicles_attributes">Permit Number</h6>
                          <h6 className="vehicles_value">
                            :{vehicles.permit_number}
                          </h6>
                        </div> */}
                        <div className="vehicles_div">
                          <h6 className="vehicles_attributes">Manufacturer</h6>
                          <h6 className="vehicles_value">
                            :{vehicles.vehicle_manufacturer_name.toUpperCase()}
                          </h6>
                        </div>
                        <div className="vehicles_div">
                          <h6 className="vehicles_attributes">Registration</h6>
                          <h6 className="vehicles_value">
                            :
                            {vehicles.registration
                              ? vehicles.registration.toUpperCase()
                              : "N.A"}
                          </h6>
                        </div>
                      </div>
                      {isMobile ? null : (
                        // <div>
                        <div
                          style={{
                            color: "black",
                            display: "flex",
                            height: "100%",
                            alignItems: "flex-start",
                            justifyContent: "center",
                          }}
                        >
                          {/* <NavLink to="/transporters/vehicles-onboard"> */}
                          <h6
                            style={{
                              // marginRight: "3rem",
                              textDecoration: "none",
                              padding: "5px 5px",
                            }}
                            onClick={() =>
                              handleVehicleId(
                                vehicles.id,
                                carrierListDataById.id
                              )
                            }
                          >
                            <EditIcon
                              style={{ fontSize: "20px", cursor: "pointer" }}
                            />
                          </h6>
                          {/* </NavLink> */}
                        </div>
                        // </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    // backgroundColor: "grey",
                  }}
                >
                  {" "}
                  <img
                    src={success}
                    alt=""
                    width="120px"
                    height="90px"
                    style={{ marginTop: "200px" }}
                  />
                  <h6 style={{ color: "grey" }}>loading data...</h6>
                </div>
              ) : (
                // <div className="vehicles_container">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <h4 style={{ color: "grey" }}>No data found!</h4>
                </div>
                // </div>
              )}
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
              <div className="customer_container">
                <div className="details_name">
                  <h6 className="details_name_head">Vehicle Name</h6>
                  <h6 className="details_name_text">
                    : Ashok LeyLAND 4334 Truck
                  </h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Model Number</h6>
                  <h6 className="details_name_text">: 4334</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Vehicle Number</h6>
                  <h6 className="details_name_text">: MH 09 AR 1008</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Vehicle Type</h6>
                  <h6 className="details_name_text">: Truck</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">OEM</h6>
                  <h6 className="details_name_text">: ----</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Number of Wheels</h6>
                  <h6 className="details_name_text">: 8</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Max Load Capacity</h6>
                  <h6 className="details_name_text">: 18-20 Tonnes</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Min Load Capacity</h6>
                  <h6 className="details_name_text">: 14 Tonnes</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">National/State Permit</h6>
                  <h6 className="details_name_text">: State</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Permited State</h6>
                  <h6 className="details_name_text">: Punjab,Bihar,Goa</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Permit Number</h6>
                  <h6 className="details_name_text">: SOV5363632</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Permit Expiry Date</h6>
                  <h6 className="details_name_text">: 20 Jan 2026</h6>
                </div>
                <div className="details_name">
                  <h6 className="details_name_head">Vehicle Status</h6>
                  <h6 className="details_name_text">: Good</h6>
                </div>
              </div>
            </TabPanel> */}
            {/* <TabPanel value={value} index={3}>
              Driver Information
            </TabPanel> */}
            {/* <TabPanel value={value} index={1}>
              Recent Trip
            </TabPanel> */}
          </div>
        </div>
      </div>
    </DetailOnboardStyle>
  );
}

export default OnboardDetails;
