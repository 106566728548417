import styled from "styled-components";
import {
  FontFamily,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../themes/theme";

export const EscalationStyle = styled.div`
overflow:hidden;
width: 100%;
height: 100vh;
padding: 0;
.main_onboard_wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  }

  .trip-list-mobile-header {
    width: 100%;
    height: 50px;
  }

  .main_onboard_Left_box {
    flex: 1.7;
    background-color: ${colorPalette.secondaryWhite};
    height: 100vh;
    box-shadow: ${colorPalette.boxShadowPrimary};
    margin: 0 auto;
  }
  .main_onboard_Right_box {
    flex: 9.5;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: flex-start;
    height: 100vh;
    background-color: ${(props) =>
    props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
    width: 100%;
  }
  .add_btn_div {
    display: flex;
    justify-content: flex-end;
    margin-right: 2%;
    margin-top: 1%;
    margin-bottom: 2vh;
  }
  .table_main_div {
    /* overflow-y: hidden; */
    height: 100vh;
  };

  .ant-table-thead .ant-table-cell {
  /* background-color: #e5f2e5; */
}

.highlight-bottom-border > td {
  border-bottom: solid 1px black !important
}


  .drawer_main_div {
    padding: 20px;
    width: 25vw;
    background: red;
    height: 100%;
  }
  .custom_table_header th{
    /* border-left: 1px solid #e8e8e8 !important; */
    /* border: 1px solid #434343; */
    padding: 0;
    margin: 0;
  }
  .custom_table_header td{
    /* border-left: 0.5px solid #e8e8e8 !important; */
    padding: 0;
    margin: 0;
    
    
  }
  .ant-table{
    margin: 0 !important;
    padding: 0 !important;
    border-collapse: collapse;
  }

  .ant-table-cell,.ant-table-header{
    padding: 0;
    margin: 0;
    /* background-color: red; */
    border: 0;
    
  }
  @media only screen and (max-width: 499px) {
    .main_onboard_wrapper {
      flex-direction: column;
      margin: 0px;
      height: auto;
    }
    .main_onboard_Right_box {
      flex: 9.5;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      background-color: ${colorPalette.secondaryWhite};
      width: 95%;
    }
}
`;