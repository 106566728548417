import axiosInterceptor from "../../config/https";
import { convertToBase64, prefixSalt } from "../../helper/base64Converter";
import apiUrl from "../../url/apiUrl";
import { allRegex } from "../../utility/regex";

export const getDrivers = async (searchTerm, status, currentPage) => {
  let url = "";
  if (/^[0-9]/.test(searchTerm)) {
    url = `${apiUrl.driverBaseUrl}?mobile_number=${searchTerm}&is_active=${status}&current_page=${currentPage}`;
  } else {
    url = `${apiUrl.driverBaseUrl}?name_or_driving_licence_number=${searchTerm}&is_active=${status}&current_page=${currentPage}`;
  }
  try {
    const response = await axiosInterceptor.get(url);
    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
  }
};

export const getDriverById = async (id) => {
  try {
    const vehiclesListById = await axiosInterceptor.get(
      `${apiUrl.driverBaseUrl}/${prefixSalt()}-${convertToBase64(id)}`
    );

    if (vehiclesListById.status == 200) {
      return vehiclesListById.data;
    }
  } catch (e) {}
};

export const CreateDriver = (
  first_name,
  last_name,
  mobile_number,
  driving_licence,
  driving_licence_image,
  alternate_number
) => {
  let data = new FormData();

  data.append("first_name", `${first_name}`);
  data.append("last_name", `${last_name}`);
  data.append("mobile_number", `${mobile_number}`);
  data.append("driving_licence_number", `${driving_licence}`);
  data.append("driving_licence_image_file", driving_licence_image);
  if (alternate_number) {
    data.append("alternate_number", alternate_number);
  }

  var config = {
    method: "post",
    url: `${apiUrl.onboardingBaseUrl}/v1/drivers`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    timeout: 30000,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (!error.response) {
          resolve(error);
        }
        resolve(error.response);
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
      });
  });
};

export const verifyDlNumber = async (driverDetails) => {
  let data = JSON.stringify({
    driving_license_number: driverDetails.driving_licence_number,
    dob: driverDetails.dob,
  });
  var config = {
    method: "POST",
    url: `${apiUrl.dlVerifyUrl}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        if (error?.response?.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
      });
  });
};
