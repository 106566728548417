import Onboarding from "./pages/onboard/Onboarding";
// import "./app.js";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./app.css";
import CssBaseline from "@mui/material/CssBaseline";
import {
  DarkModeContext,
  DarkModeContextProvider,
} from "./context/darkModeContext";
import { fetchAppToken } from "./helper/AppTokenHelper";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import VehiclesDetails from "./pages/vehicles/VehiclesDetails";
import OnboardSuccess from "./pages/vehicles/OnboardSuccess";
import OnboardList from "./pages/onboardDetails/OnboardList";
import CryptoJS from "crypto-js";
import VehiclesList from "./pages/vehicles/VehiclesList";
import Vehicles from "./pages/vehicles/Vehicles";
import OnboardDetails from "./pages/onboardDetails/OnboardDetails.jsx";
import CreateTrip from "./pages/ftl_trips/createTrip/CreateTrip";
import TripList from "./pages/ftl_trips/tripList/TripList";
import Oem from "./pages/oem/Oem";
import VehicleModel from "./pages/oem/VehicleModel";
import TermsCondition from "./pages/onboard/TermsCondition";
import Login from "./pages/login/Login.jsx";
import CompleteTrip from "./pages/ftl_trips/completeTrip/CompleteTrip.jsx";
import RecordPayment from "./pages/recordPayment/RecordPayment.jsx";
import axios from "axios";
import ProtectedRoutes from "./components/protectedRoutes/ProtectedRoutes";
import LoggedInRoute from "./LoggedInRoute";
import {
  indexSelectedData,
  readLoginData,
  readToken,
} from "./helper/dataDecryptionHelper";
import Dashboard from "./pages/dashboard/Dashboard.jsx";
import DeliveryInvoicePayment from "./pages/ftl_trips/deliveryInvoicePayment/DeliveryInvoicePayment";
import Customers from "./pages/customers/Customers";
import WarehouseList from "./pages/warehouses/WarehouseList";
import VehicleSuccess from "./pages/vehicles/VehicleSuccess";
import FaarmsGlobalPayment from "./pages/FaarmsGlobalPayment/FaarmsGlobalPayment";
import PaymentSuccess from "./pages/payment-success/Payment-success";
import PaymentFailure from "./pages/payment-failure/Payment-failure";
import Payments from "./pages/payments/Payments.jsx";
import MonthlyWisePayments from "./pages/monthlyPayments/MonthlyWisePayments";
import TripPickup from "./pages/ftl_trips/tripPickup/TripPickup";
import LandingPage from "./pages/landingPage/LandingPage";
import apiUrl from "./url/apiUrl";
import OnboardingPublic from "./pages/onboard/OnboardPublic";
import CustomerDashboard from "./pages/logisticsCustomerPages/customerDashboard/CustomerDashboard";
import CustomerIndentsPage from "./pages/logisticsCustomerPages/customerIndentsPage/CustomerIndentsPage";
import CustomerQuotesPage from "./pages/logisticsCustomerPages/customerQuotesPage/CustomerQuotesPage";
import CustomerTripsPage from "./pages/logisticsCustomerPages/customerTripsPage/CustomerTripsPage";
import CreateIndentPage from "./pages/logisticsCustomerPages/createIndentPage/CreateIndentPage";
// import apiUrl from "./url/apiUrl";
import { Link, useHistory } from "react-router-dom";
import IndentsPage from "./pages/IndentsPage/IndentsPage.jsx";
import QuotationPage from "./pages/QuotationPage/QuotationPage.jsx";
import axiosInterceptor from "../src/config/https";
import { getCustomersInfo } from "./pages/login/LoginServices";
import CustomerLoggedInRotes from "./CustomerLoggedInRoutes";
import CustomerLoggedInRoutes from "./CustomerLoggedInRoutes";
import CustomerProtectedRoutes from "./components/protectedRoutes/CustomerProtectedRoutes";
import LoginAsCustomer from "./pages/login/LoginAsCustomer.jsx";
import ForgetPassword from "./pages/login/ForgetPassword";
import ChangePasswords from "./pages/changepassword/ChangePasswords";
import OnboardSuccessPublic from "./pages/vehicles/OnboardSuccessPublic";
import PaymentReceivablesPage from "./pages/paymentReceivables/PaymentReceivablesPage";
import ReceivablesDetailsPage from "./pages/receivablesDetails/ReceivablesDetailsPage";
import NoInternetConnection from "./components/noInternetConnection/NoInternetConnection";
import PageNotFound from "./pages/pagenotfound/PageNotFound.jsx";
import Datamap from "./components/datamap/Datamap.jsx";
import TripListV2 from "./pages/ftl_trips/ftl_tripListV2/FtlTripListV2.jsx";
import { AppStyle } from "./appStyle";
import CompleteTripRequest from "./pages/ftl_trips/ftl_tripListV2/FtlCompleteTripRequest";
import CreateTripV2 from "./pages/ftl_trips/createTrip/CreateTripV2";
import TripSettings from "./pages/ftl_trips/tripSettings/TripSettings";
import TripsIndentsPage from "./pages/tripIndent/customerIndentsPage/TripsIndentsPage";
import CreateTripIndentPage from "./pages/tripIndent/createIndentPage/CreateTripIndentPage";
import FaarmsTripListV2 from "./pages/faarmsTrips/faarmsTripList/FaarmsTripListV2";
import FaarmsCompleteTripRequest from "./pages/faarmsTrips/faarmsTripList/FaarmsCompleteTripRequest";
import FaarmsCreateTrip from "./pages/faarmsTrips/createTrip/FaarmsCreateTrip";
import FaarmsDeliveryInvoicePayment from "./pages/faarmsTrips/deliveryInvoicePayment/FaarmsDeliveryInvoicePayment";
import GoodsDelivered from "./pages/ftl_trips/goodsDelivered/GoodsDelivered";
import PodRecieved from "./pages/ftl_trips/PODRecieved/PodRecieved";

// ================= old trips ==================

import OldTripListV2 from "./pages/trips/tripListV2/TripListV2.jsx";
import OldCompleteTripRequest from "./pages/trips/tripListV2/CompleteTripRequest.jsx";
import OldFaarmsCreateTrip from "./pages/trips/createTrip/CreateTrip.jsx";
import OldCreateTripV2 from "./pages/trips/createTrip/CreateTripV2";
import { MyContext } from "./components/enumContext/EnumContextComponent.jsx";
import MaintenancePage from "./pages/maintenancePage/MaintenancePage.jsx";
import ErrorPage from "./components/somethingWentWrong/SomethingWentWrong.jsx";
import DriverList from "./pages/driver/DriverList.jsx";
import Teams from "./pages/teams/Teams.jsx";
import EscalationMatrix from "./pages/escalationMatrix/EscalationMatrix.jsx";
import { FtlTripListProvider } from "./context/ftlListVersionContext.js";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const { enums, setEnums } = useContext(MyContext);

  const history = useHistory();
  const [is403Error, setIs403Error] = useState(false);
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });
  const loginApp = localStorage.getItem("loginApp");
  // const loginApp = readLoginData() ? readLoginData().app_name : "Leap-Supply";
  const is_temporary_password =
    readLoginData() !== undefined && readLoginData().temporary_password
      ? readLoginData().temporary_password
      : null;
  // useEffect(() => {
  //   if (is_temporary_password) {
  //     history.push("/change-passwords");
  //   }
  // }, [is_temporary_password]);
  useEffect(() => {
    getEnums();
  }, []);

  const getEnums = async () => {
    var enums = await axiosInterceptor.get(
      `${apiUrl.onboardingBaseUrl}/v1/enums/details`
    );

    enums.status === 200 ? setEnums(enums.data) : setEnums([]);
  };

  const getCustomerData = async () => {
    const response = await getCustomersInfo();

    if (response && response?.status == 200) {
      const customer_data = {
        customer_id: response?.data?.customers_id,
        customer_name: response?.data?.first_name,
        customer_email: response?.data?.email,
      };
      //
      // getCustomerData();
      let encrypted_data = CryptoJS.AES.encrypt(
        JSON.stringify(customer_data),
        apiUrl.SALT
      ).toString();

      localStorage.setItem("CUSTOMER_DATA", encrypted_data);
    }
  };
  useEffect(() => {
    getCustomerData();
  }, []);

  useEffect(() => {
    localStorage.getItem("IS_403_ERROR") === "true"
      ? setIs403Error(true)
      : setIs403Error(false);
  }, [localStorage.getItem("IS_403_ERROR")]);
  //
  if (localStorage.getItem("login_data")) {
    localStorage.removeItem("login_data");
  }

  if (readLoginData()) {
    const auth = readLoginData().AUTH_TOKEN;
    axios.defaults.headers.common["Authorization"] = `Bearer ${auth}`;
  }

  const website_url = localStorage.getItem("website_url");

  // const website_url =
  //   "http://logistics.faarms.com.s3-website.ap-south-1.amazonaws.com";

  const fetchAppTokenData = async (url, client, version) => {
    const response = await fetchAppToken(url, client, version);

    if (response.status == 200) {
      const xToken = {
        X_TOKEN: response.data.token,
      };

      let encrypted_data = CryptoJS.AES.encrypt(
        JSON.stringify(xToken),
        // .SALT
        apiUrl.SALT
      ).toString();

      localStorage.setItem("x_token", encrypted_data);
    } else if (response == 401) {
      // window.location.reload();
    }
  };

  useEffect(() => {
    if (website_url) {
      fetchAppTokenData(
        website_url,
        process.env.REACT_APP_CLIENT,
        process.env.REACT_APP_VERSION
      );
    }
  }, [website_url]);
  // if (readLoginData().app_name) {
  //   history.push("/customer-indents");
  // }
  useEffect(() => {
    localStorage.setItem("createTripSource", "/ftl-trips");
  }, []);

  // if (is403Error) {
  //   return <h1>Login</h1>;
  // } else
  if (loginApp === "leap-supply-customers") {
    return (
      <AppStyle className="App">
        <NoInternetConnection>
          <Router>
            <Switch>
              <Route exact path="/">
                <LandingPage />
              </Route>
              <CustomerLoggedInRoutes exact path="/login" component={Login} />
              <CustomerLoggedInRotes
                exact
                path="/customer/login"
                component={LoginAsCustomer}
              />
              <Route exact path="/forget-password" component={ForgetPassword} />

              <CustomerProtectedRoutes
                exact
                path="/customer/change-passwords"
                component={ChangePasswords}
              />
              {/*Logistics Customer side routes*/}
              {/* <ProtectedRoutes
              exact
              path="/customer-dashboard"
              component={CustomerDashboard}
            /> */}
              <CustomerProtectedRoutes
                exact
                path="/customer/customer-indents"
                component={CustomerIndentsPage}
              />
              <CustomerProtectedRoutes
                exact
                path="/customer/create-indent"
                component={CreateIndentPage}
              />
              <CustomerProtectedRoutes
                exact
                path="/customer/customer-quotes"
                component={CustomerQuotesPage}
              />
              <CustomerProtectedRoutes
                exact
                path="/customer/customer-trips"
                component={CustomerTripsPage}
              />

              <CustomerProtectedRoutes path="*" component={PageNotFound}>
                {/* <RecordPayment /> */}
              </CustomerProtectedRoutes>
            </Switch>
          </Router>
        </NoInternetConnection>
      </AppStyle>
    );
  } else {
    return (
      <AppStyle className="App">
        {process.env.REACT_APP_DEVELOPMENT_MODE == "true" ? (
          <MaintenancePage />
        ) : (
          <NoInternetConnection>
            <Router>
              <Switch>
                <Route exact path="/">
                  {/* <Login /> */}
                  <LandingPage />
                </Route>
                <Route exact path="/under-maintenance">
                  {/* <Login /> */}
                  <MaintenancePage />
                </Route>

                <Route exact path="/something-went-wrong">
                  <ErrorPage />
                </Route>
                <LoggedInRoute exact path="/login" component={Login} />
                <Route
                  exact
                  path="/customer/login"
                  component={LoginAsCustomer}
                />
                <FtlTripListProvider>
                  <DarkModeContextProvider>
                    <Route
                      exact
                      path="/forget-password"
                      component={ForgetPassword}
                    />

                    <Route
                      exact
                      path="/transporter-onboard-success"
                      component={OnboardSuccessPublic}
                    />

                    <ProtectedRoutes
                      exact
                      path="/change-passwords"
                      component={ChangePasswords}
                    />

                    <Route exact path="/transporters-onboard">
                      <OnboardingPublic />
                    </Route>
                    <Route exact path="/terms-conditon">
                      <TermsCondition />
                    </Route>
                    <ProtectedRoutes
                      exact
                      path="/transporters/transporters-onboard"
                      component={Onboarding}
                    />
                    <Route exact path="/vehicles-onboard">
                      <VehiclesDetails />
                    </Route>

                    <ProtectedRoutes
                      exact
                      path="/payment-success"
                      component={PaymentSuccess}
                    />
                    <ProtectedRoutes
                      exact
                      path="/payment-failure"
                      component={PaymentFailure}
                    />
                    <ProtectedRoutes
                      exact
                      path="/vehicles-manufacturers"
                      component={Oem}
                    />
                    <ProtectedRoutes
                      exact
                      path="/ftl-trips/trip-settings"
                      component={TripSettings}
                    />
                    <ProtectedRoutes
                      exact
                      path="/faarms-payment"
                      component={FaarmsGlobalPayment}
                    />
                    {/* <DarkModeContextProvider> */}
                    <ProtectedRoutes
                      exact
                      path="/customers"
                      component={Customers}
                    />

                    <ProtectedRoutes
                      exact
                      path="/driver"
                      component={DriverList}
                    />
                    <ProtectedRoutes exact path="/teams" component={Teams} />

                    <ProtectedRoutes
                      exact
                      path="/customers/:customerId/warehouses"
                      component={WarehouseList}
                    />
                    {/* </DarkModeContextProvider> */}
                    <ProtectedRoutes
                      exact
                      path="/onboard-success"
                      component={OnboardSuccess}
                    />
                    <ProtectedRoutes
                      exact
                      path="/vehicle-success"
                      component={VehicleSuccess}
                    />
                    <ProtectedRoutes
                      exact
                      path="/payments"
                      component={Payments}
                    />
                    {/* <DarkModeContextProvider> */}
                    <ProtectedRoutes
                      exact
                      path="/dashboard"
                      component={Dashboard}
                    />
                    {/* </DarkModeContextProvider> */}
                    {/* <Route exact path="/onboard-success">
            <OnboardSuccess />
          </Route> */}
                    <ProtectedRoutes
                      exact
                      path="/transporters"
                      component={OnboardList}
                    />
                    {/* <Route exact path="/transporters">
            <OnboardList />
          </Route> */}
                    <ProtectedRoutes
                      exact
                      path="/vehicles"
                      component={VehiclesList}
                    />
                    {/* <Route exact path="/vehicles">
            <VehiclesList />
          </Route> */}
                    <ProtectedRoutes
                      exact
                      path="/transporters/details/:id"
                      component={OnboardDetails}
                    />
                    {/* <Route exact path="/transporters/details/:id">
            <OnboardDetails />
          </Route> */}
                    <ProtectedRoutes
                      exact
                      path="/vehicles/details/:carrier_id/:id"
                      component={Vehicles}
                    />
                    {/* <Route exact path="/vehicles/details/:carrier_id/:id">
            <Vehicles />
          </Route> */}
                    <ProtectedRoutes
                      exact
                      path="/vehicles-manufacturers/oemdetails/:id"
                      component={VehicleModel}
                    />
                    {/* <Route exact path="/vehicles-manufacturers/oemdetails/:id">
            <VehicleModel />
          </Route> */}
                    <ProtectedRoutes
                      exact
                      path="/details"
                      component={OnboardDetails}
                    />
                    <ProtectedRoutes
                      exact
                      path="/faarms-trips/create-trip"
                      component={FaarmsCreateTrip}
                    />
                    <ProtectedRoutes
                      exact
                      path="/ftl-trips/create-tripV2/:trip_type"
                      component={CreateTripV2}
                    />
                    <ProtectedRoutes
                      exact
                      path="/ftl-trips/create-tripV2/:trip_type/:tripId/update-trip"
                      component={CreateTripV2}
                    />
                    <ProtectedRoutes
                      exact
                      path="/faarms-trip/:tripId/update-trip"
                      component={FaarmsCreateTrip}
                    />
                    <ProtectedRoutes
                      exact
                      path="/faarms-trip"
                      component={TripList}
                    />
                    <ProtectedRoutes
                      exact
                      path="/ftl-trips"
                      component={TripListV2}
                    />

                    {/* faarms trip routes */}

                    <ProtectedRoutes
                      exact
                      path="/faarms-trips"
                      component={FaarmsTripListV2}
                    />

                    <ProtectedRoutes
                      exact
                      path="/faarms-trips/:tripId/faarms-trip-complete-request"
                      component={FaarmsCompleteTripRequest}
                    />

                    <ProtectedRoutes
                      exact
                      path="/indents"
                      component={IndentsPage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/trips-indents"
                      component={TripsIndentsPage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/create-indent"
                      component={CreateTripIndentPage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/quotations"
                      component={QuotationPage}
                    />
                    {/* <ProtectedRoutes
                  exact
                  path="/trip-indents"
                  component={TripIndentsPage}
                />
                <ProtectedRoutes
                  exact
                  path="/create-indent"
                  component={CreateIndentPage}
                /> */}
                    <ProtectedRoutes
                      exact
                      path="/payment-receivables"
                      component={PaymentReceivablesPage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/payment-receivables/:customerId/:customerName/details"
                      component={ReceivablesDetailsPage}
                    />
                    <ProtectedRoutes
                      exact
                      path="/ftl-trips/:tripId/pod-recieved"
                      component={PodRecieved}
                    />
                    <ProtectedRoutes
                      exact
                      path="/ftl-trips/:tripId/goods-delivered"
                      component={GoodsDelivered}
                    />
                    <ProtectedRoutes
                      exact
                      path="/trips/:tripId/complete-trip"
                      component={CompleteTrip}
                    />
                    <ProtectedRoutes
                      exact
                      path="/trips/:tripId/trip-complete-request"
                      component={CompleteTripRequest}
                    />
                    <ProtectedRoutes
                      exact
                      path="/trips/:tripId/trip-pickup/:tripCode"
                      component={TripPickup}
                    />
                    <ProtectedRoutes
                      exact
                      path="/payments/:tripId/record-payment"
                      component={RecordPayment}
                    />
                    {/* <ProtectedRoutes
            exact
            path="/trips/:tripId/record-payment"
            component={RecordPayment}
          /> */}
                    <ProtectedRoutes
                      exact
                      path="/Payments/:transporterId/monthlyWisePayments/:vehicleId"
                      component={MonthlyWisePayments}
                    />
                    <ProtectedRoutes
                      exact
                      path="/trips/:tripId/delivery-invoice-payment"
                      component={DeliveryInvoicePayment}
                    />
                    <ProtectedRoutes
                      exact
                      path="/faarms-trip/:tripId/faarms-delivery-invoice-payment"
                      component={FaarmsDeliveryInvoicePayment}
                    />
                    {/*Logistics Customer side routes*/}
                    {/* <ProtectedRoutes
            exact
            path="/customer-dashboard"
            component={CustomerDashboard}
          />
          <ProtectedRoutes
            exact
            path="/customer-indents"
            component={CustomerIndentsPage}
          />
          <ProtectedRoutes
            exact
            path="/customer-indents/create-indent"
            component={CreateIndentPage}
          />
          <ProtectedRoutes
            exact
            path="/customer-quotes"
            component={CustomerQuotesPage}
          />
          <ProtectedRoutes
            exact
            path="/customer-trips"
            component={CustomerTripsPage}
          /> */}
                    <ProtectedRoutes
                      exact
                      path="/analytics"
                      component={Datamap}
                    />

                    {/* ================ old trip routes ================ */}

                    <ProtectedRoutes
                      exact
                      path="/old-trips"
                      component={OldTripListV2}
                    />

                    <ProtectedRoutes
                      exact
                      path="/old-trips/:tripId/trip-complete-request"
                      component={OldCompleteTripRequest}
                    />

                    <ProtectedRoutes
                      exact
                      path="/old-trips/create-trip"
                      component={OldFaarmsCreateTrip}
                    />

                    <ProtectedRoutes
                      exact
                      path="/old-trips/create-tripV2/:trip_type/:tripId/update-trip"
                      component={OldCreateTripV2}
                    />
                    <ProtectedRoutes
                      exact
                      path="/old-trips/create-tripV2/:trip_type"
                      component={OldCreateTripV2}
                    />
                    <ProtectedRoutes
                      exact
                      path="/old-trip/:tripId/update-trip"
                      component={OldFaarmsCreateTrip}
                    />
                    <ProtectedRoutes
                      exact
                      path="/escalation-matrix"
                      component={EscalationMatrix}
                    />
                  </DarkModeContextProvider>
                </FtlTripListProvider>
              </Switch>
            </Router>
          </NoInternetConnection>
        )}
      </AppStyle>
    );
  }
}

export default App;
