import apiUrl from "../../../url/apiUrl";
import axios from "axios";
import axiosInterceptor from "../../../config/https";
import { convertToBase64, prefixSalt } from "../../../helper/base64Converter";

export const GetTripList = async (page) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.faarmsTripV1BaseUrl}?current_page=${page}&page_size=20`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response.status;
    // throw e;
  }
};

export const GetTransporterByName = async (name) => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.transporterBaseUrl}?name=${name}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response.status;
    // throw e;
  }
};

export const getTripExport = async (
  exportEmail,
  exportFromDate,
  exportToDate,
  exportTripStatus,
  exportCustomer,
  exportVehicleNumber,
  exportTransporter,
  exportVehiclePayoutPeriod,
  exportPaymentStatus,
  exportSourceState,
  exportDestinationState,
  exportCreatedBy,
  exportLoadSource,
  exportTripType
) => {
  let appendUrl = "";
  if (exportFromDate && exportToDate) {
    appendUrl =
      appendUrl + `&to_date=${exportToDate}` + `&from_date=${exportFromDate}`;
  }
  if (exportTripStatus && exportTripStatus != "0") {
    appendUrl = appendUrl + `&trip_status=${exportTripStatus}`;
  }
  if (exportCustomer && exportCustomer != "0") {
    appendUrl = appendUrl + `&customer_name=${exportCustomer}`;
  }
  if (exportVehicleNumber && exportVehicleNumber != "0") {
    appendUrl = appendUrl + `&vehicle_number=${exportVehicleNumber}`;
  }
  if (exportTransporter && exportTransporter != "0") {
    appendUrl = appendUrl + `&transporter_name=${exportTransporter}`;
  }
  if (exportVehiclePayoutPeriod && exportVehiclePayoutPeriod != "0") {
    appendUrl =
      appendUrl + `&vehicle_payout_period=${exportVehiclePayoutPeriod}`;
  }
  if (exportPaymentStatus && exportPaymentStatus != "0") {
    appendUrl = appendUrl + `&payment_status=${exportPaymentStatus}`;
  }

  if (exportSourceState && exportSourceState != "0") {
    appendUrl = appendUrl + `&source_state=${exportSourceState}`;
  }
  if (exportDestinationState && exportDestinationState != "0") {
    appendUrl = appendUrl + `&destination_state=${exportDestinationState}`;
  }
  if (exportCreatedBy && exportCreatedBy != "0") {
    appendUrl = appendUrl + `&created_by=${exportCreatedBy}`;
  }
  if (exportLoadSource && exportLoadSource != "0") {
    appendUrl = appendUrl + `&load_source=${exportLoadSource}`;
  }
  if (exportTripType && exportTripType != "0") {
    appendUrl = appendUrl + `&trip_type=${exportTripType}`;
  }
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.tripExportV6BaseUrl}?mailing_address=${exportEmail}${appendUrl}`
    );

    if (response.status == 200) {
      return response;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return e.response;
    // throw e;
  }
};

export const approveRejectTrip = (tripStatus, remarks, tripId) => {
  let data = JSON.stringify({
    trip_status: tripStatus,
    remarks: remarks,
  });
  var config = {
    method: "patch",
    url: `${apiUrl.tripBaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/approve-reject`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (e) {
        if (e.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(e.response);

        //
      });
  });
};

export const reopenCancelTrip = (tripStatus, remarks, tripId) => {
  let data = JSON.stringify({
    trip_status: tripStatus,
    remarks: remarks,
  });
  var config = {
    method: "patch",
    url: `${apiUrl.tripBaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/reopen-cancel`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
        // resolve(error);
        //
      });
  });
};

export const startTrip = (tripStatus, remarks, tripId) => {
  let data = JSON.stringify({
    trip_status: tripStatus,
    remarks: remarks,
  });
  var config = {
    method: "patch",
    url: `${apiUrl.tripBaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/start`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
    timeout: 60000,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (!error.response) {
          resolve(error);
        }
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
        // resolve(error);
        //
      });
  });
};

export const stopTrip = (tripStatus, remarks, tripId, DeliveredBills) => {
  let data = JSON.stringify({
    trip_status: tripStatus,
    remarks: remarks,
    order_details: DeliveredBills,
  });
  var config = {
    method: "put",
    url: `${apiUrl.tripBaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/complete`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
        // resolve(error);
        //
      });
  });
};

export const completeTrip = (tripStatus, remarks, tripId) => {
  let data = JSON.stringify({
    trip_status: tripStatus,
    remarks: remarks,
  });
  var config = {
    method: "patch",
    url: `${apiUrl.tripBaseUrl}/${prefixSalt()}-${convertToBase64(
      tripId
    )}/complete`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return new Promise((resolve, reject) => {
    axiosInterceptor(config)
      .then(function (response) {
        if (response.status == 200) {
          resolve(response);
        }
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          localStorage.clear();
          window.location.reload();
        }
        resolve(error.response);
        // resolve(error);
        //
      });
  });
};

export const TripAttachmentPost = (
  image,
  tripId,
  tag,
  attachmentType,
  attachAt,
  attachmentList
) => {
  return attachmentList.map(async (attachment) => {
    let data = new FormData();
    if (!attachment.attachment_file) {
      return;
    }

    data.append("file_1", attachment.attachment_file);
    if (attachment.attachment_tag != "0") {
      data.append("tag_1", attachment.attachment_tag);
    } else {
      data.append("tag_1", "others");
    }

    data.append(
      "attachment_type_1",
      attachment.attachment_file == "application/pdf" ? "pdf" : "image"
    );
    data.append("attach_at", attachAt);
    data.append("trip_id", tripId);

    var config = {
      method: "post",
      url: `${apiUrl.tripAttachmentsUrl}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: data,
    };
    return new Promise((resolve, reject) => {
      axiosInterceptor(config)
        .then(function (response) {
          if (response.status == 201) {
            resolve("success");
            // return 'success'
          }
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            localStorage.clear();
            window.location.reload();
          }

          resolve(error.response);
          // resolve(error);
          //
        });
    });
  });
};

export const GetTripListWithFilter = async (
  page,
  tripCode,
  paymentStatus,
  vehicleNumber,
  tripStatus,
  referenceNumber,
  customerId,
  loadSource,
  ewayBillNo,
  tripType
) => {
  let appendUrl = "";
  if (tripCode) {
    appendUrl = appendUrl + `&trip_code=${tripCode}`;
  }
  if (paymentStatus) {
    appendUrl = appendUrl + `&trip_payment_status=${paymentStatus}`;
  }
  if (vehicleNumber) {
    appendUrl = appendUrl + `&vehicle_number=${vehicleNumber}`;
  }
  if (tripStatus) {
    appendUrl = appendUrl + `&trip_status=${tripStatus}`;
  }
  if (tripType) {
    appendUrl = appendUrl + `&trip_type=${tripType}`;
  }
  if (referenceNumber) {
    appendUrl = appendUrl + `&reference_number=${referenceNumber}`;
  }
  if (loadSource) {
    appendUrl = appendUrl + `&load_source=${loadSource}`;
  }
  if (customerId) {
    appendUrl = appendUrl + `&customer_id=${customerId}`;
  }
  if (ewayBillNo) {
    appendUrl = appendUrl + `&eway_bill_number=${ewayBillNo}`;
  }
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.faarmsTripV1BaseUrl}?current_page=${page}&page_size=20${appendUrl}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    return e.response.status;
    // throw e;
  }
};

export const GetTripStatusEnums = async () => {
  try {
    const response = await axiosInterceptor.get(`${apiUrl.tripStatusEnumUrl}`);

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    return e.response.status;
    // throw e;
  }
};

export const GetTripPaymentStatusEnums = async () => {
  try {
    const response = await axiosInterceptor.get(
      `${apiUrl.paymentStatusEnumUrl}`
    );

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    return e.response.status;
    // throw e;
  }
};

export const GetCustomerListApi = async () => {
  try {
    const response = await axiosInterceptor.get(`${apiUrl.customerListUrl}`);

    if (response.status == 200) {
      return response.data;
    }
  } catch (e) {
    if (e.response.status === 403) {
      localStorage.clear();
      window.location.reload();
    }

    return e.response.status;
    // throw e;
  }
};
