import { React, useEffect, useState } from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import SideBarMain from "../../components/sideBarMain/SideBarMain.jsx";
import success from "../../assets/dashboard_icons/icons8-truck.gif";
import axios from "axios";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Component_style } from "../../themes/component_style";
import { useHistory } from "react-router-dom";
import apiUrl from "../../url/apiUrl";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { VehicleStyle } from "./VehicleStyle.js";
import { ButtonPrimary3 } from "../../themes/components/button.js";
import { DarkMode } from "@mui/icons-material";
function VehicleSuccess() {
  const dispatch = useDispatch();
  let history = useHistory();
  const { routesAddVehicles } = useSelector((state) => state.routesAddVehicle);
  const vehicleId = useSelector((state) => state.onboard.vehicleId);

  return (
    <VehicleStyle className="success_onboard_container" mode={DarkMode}>
      <div className="success_onboard_wrapper">
        <img src={success} alt="" width="120px" height="90px" />
        <h3 className="success_text">
          <VerifiedIcon style={{ marginRight: "1rem", color: "green" }} />
          Vehicle Onboarded Successfully!
        </h3>
        {/* {routesAddVehicles == "from_carrier_add_vehicle_to_success" ? (
          <NavLink
            to={`transporters/details/${parseInt(vehicleId.carrier)}`}
            style={{ textDecoration: "none" }}
          >
            {" "}
            <h6 className="details"> View Details</h6>
          </NavLink>
        ) : ( */}
        <NavLink to="/vehicles" style={{ textDecoration: "none" }}>
          {" "}
          <h6 className="details"> View Details</h6>
        </NavLink>
        {/* )} */}
      </div>
    </VehicleStyle>
  );
}

export default VehicleSuccess;
