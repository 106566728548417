import styled from "styled-components";

export const MaintenancepageStyle = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px;
  font-family: "Arial", sans-serif;

  h1 {
    color: #333;
  }

  p {
    color: #555;
    margin: 10px 0;
  }

  img {
    /* max-width: 100%; */
    width: 400px;
    height: 300px;
    object-fit: cover;
    margin-top: 20px;
  }

  /* CoolAnimation.css */
.cool-animation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.cool-animation-ball {
  width: 50px;
  height: 50px;
  background-color: #3498db; /* Change color as needed */
  border-radius: 50%;
  margin: 0 10px; /* Adjust spacing between balls */
  animation: bounce 1s ease-in-out infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.cool-animation-ball:nth-child(2) {
  animation-delay: 0.2s; /* Delay the animation of the second ball */
}

.cool-animation-ball:nth-child(3) {
  animation-delay: 0.4s; /* Delay the animation of the third ball */
}

`;
