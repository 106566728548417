import styled from "styled-components";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  colorPaletteDark,
  typography,
} from "../../../themes/theme";

export const Trip_list_main_container = styled.div`
  /* .trip-list-main-container { */
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* } */
  .MuiOutlinedInput-notchedOutline {
    color: green !important;
  }

  .MuiSelect-select {
    color: green !important;
    padding: 0.3rem 0.4rem !important;
    font-size: ${typography.paragraph} !important;
    font-weight: ${FontWeight.semibold} !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: green !important;
    border: 1px solid green !important;
    color: green !important;
    /* width: 6.5rem !important; */
  }
  .trips_status_container {
    min-width: 80vw;
    display: flex;
    padding: 0px 12px;
    align-items: center;
    justify-content: flex-start;
    overflow-x: scroll;
  }

  .trip-select-box {
    border-color: ${colorPalette.primaryColor};
    color: ${colorPalette.primaryColor};
  }
  .trip-select-box-value {
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.primaryColor};
  }
  .trip_status_tab_text {
    font-weight: ${FontWeight.regular};
    /* padding: 0 0.1rem; */
    width: max-content;
    font-size: ${typography.heading5};
  }
  .trip_status_tab {
    padding: 0.4rem 1rem;
    /* max-width: 100%; */
    /* border: 1px solid green; */
    /* margin-right: 2px; */
    cursor: pointer;
    /* border-bottom: 10px; */
    /* border-top-right-radius: 10px; */
    font-weight: lighter;
  }
  .trip-list-button-container {
    display: flex;
    /* width: 100%; */
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    margin-left: 150px;
    align-items: center;
  }
  .trip-list-export-button {
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    padding: 6px;
    margin: 10px;
    border-radius: 10px;
    border: none;
    width: 100px;
    /* border: 1px solid rgb(74, 73, 73); */
    cursor: pointer;
    font-weight: ${FontWeight.medium};
  }
  .trip-list-export-button:hover {
    background-color: ${colorPalette.primaryColor};
  }

  .create-new-trip-button_analytics {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colorPalette.secondaryColor};
    color: ${colorPalette.paragraphColor};
    margin-left: 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  .trip-list-monthly-img-img {
    width: 24px;
    height: 24px;
  }
  .trip-list-monthly-text {
    font-size: ${typography.heading5};
    margin: 2px;
    color: ${colorPalette.primaryColor};
  }
  .trip-list-export-text {
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading5};
  }
  .trip-list-export-row {
    display: flex;
  }
  .trip-list-export-input-container {
    flex: 1;
    margin: 5px;
    width: 20%;
  }
  .trip-list-export-inputfield {
    flex: 1;
    width: 90%;
    /* margin: 0px 5px; */
  }
  .trip-list-export-options-head {
    margin: 10px 0px;
  }
  .trip-list-monthly-img {
    flex: 1;
    margin-left: 90%;
    margin-top: 5px;

    display: flex;
  }
  .trip-list-payout-container {
    display: flex;
  }
  .trip-list-input-attachment {
    width: 90%;
  }
  .error-trip {
    font-size: ${typography.heading5};
    color: ${colorPalette.red};
  }

  .trip-list-single-top {
    display: flex;
  }
  .trip-list-single-bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .trip-list-bottom-left {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
    margin: 5px;
  }
  .trip-list-bottom-right {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
    margin: 5px;
    color: ${colorPalette.paragraphSecondaryolor};
  }
  .trip-list-bottom-right-container {
    flex: 1;
    /* width: 50%; */
    display: flex;
  }
  .trip-list-bottom-left-container {
    flex: 1;
    /* width: 50%; */
    /* display: flex; */
  }
  .trip-list-bottom-right-contact {
    font-size: ${typography.paragraph};
    font-weight: ${FontWeight.medium};
    margin: 5px;
    color: ${colorPalette.paragraphSecondaryolor};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .trip-list-last-modified {
    font-size: ${typography.paragraph_secondary};
    font-weight: ${FontWeight.medium};
    margin-left: 14px;
    width: 10%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .trip-list-loading-more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    font-weight: ${FontWeight.semibold};
    color: ${colorPalette.primaryColor};
  }
  .trip-list-loading-more_v2 {
    display: flex;
    width: 100%;
    margin-left: 50%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: ${FontWeight.semibold};
    color: ${colorPalette.primaryColor};
  }
  .trip-list-modal-container {
    max-height: 500px;
    height: 95%;
    overflow: scroll;
  }
  .trip-list-sidebar {
    flex: 1.7;
    width: 100%;
    height: 100vh;
  }
  .trip-list-edit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .trip-list-middle-container_v2 {
    /* overflow: hidden; */
    height: 80vh;
    display: grid;
    grid-template-columns: 49.5% 49.5%;
    grid-auto-rows: min-content;

    gap: 5px;
    /* width: 100%; */
    padding: 10px;
    /* background-color: ${colorPalette.red}; */
    position: sticky;
    overflow-y: scroll;
    /* scrollbar-width: thin;
    -webkit-scrollbar-width: thin; */
    border-top: 1px solid ${colorPalette.paragraphSecondaryolor};
  }

  .tl-new-single-container_v2 {
    cursor: pointer;
    box-shadow: ${colorPalette.boxShadowPrimary};
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  }
  .trip-list-inner-left {
    flex: 8;
    /* position: sticky; */
  }
  .trip-list-inner-right {
    flex: 3;
    width: 90%;
    display: flex;
    justify-content: center;
    /* background-color: ${colorPalette.white}; */
  }
  .trip-list-filter-icon {
    margin-right: 6px;
    cursor: pointer;
  }
  .trip-list-filter-icon:hover {
    background-color: ${colorPalette.secondaryWhite};
    border-radius: 5px;
    /* padding: 5px; */
  }
  .trip-list-right-container {
    flex: 9.5;
    width: 80%;
    height: 100vh;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};

    padding: 0px;
    position: sticky;
    display: flex;

    /* overflow-y: scroll; */
  }

  .trip-circle-yellow {
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background-color: ${colorPalette.secondaryColor};
    margin-right: 6px;
  }
  .trip-rectangle {
    width: 2px;
    height: 33px;
    background-color: ${colorPalette.paragraphColor};
  }
  .trip-location-text-from {
    font-size: ${typography.paragraph};
    font-weight: ${FontWeight.medium};
    color: ${colorPalette.paragraphSecondaryolor};
    margin-top: 5px;
    margin-bottom: 2px;
    /* width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  }

  .trip-list-right-most-edit {
    /* margin-right: 40%; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .trip-list-single-reference {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 3px;
  }
  .trip-list-reference-number {
    margin-left: 6px;
    font-size: ${typography.paragraph};
    font-weight: ${FontWeight.medium};
  }

  .trip-list-single-container {
    margin: 10px;
    padding: 10px;
    background-color: ${colorPalette.white};
    border-radius: 10px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    position: sticky;
    flex-direction: column;
  }
  .trip-list-search-container {
    width: 56%;
    height: 40px;
    background-color: ${colorPalette.white};
    border-radius: 5px;
    border: 1px solid ${colorPalette.paragraphColor};
    padding: 10px;
    /*background-color: ${colorPalette.red};
  */
    display: flex;
    box-shadow: ${colorPalette.boxShadowPrimary};
    /*flex-direction: column;
  */
    align-items: center;
    justify-content: flex-start;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  .trip-list-single-right {
    margin: 10px;
    flex: 4;
  }
  .trip-list-search {
    border: none;
    height: 34px;
    width: 95%;
  }
  .trip-list-search:focus {
    outline: none;
  }
  .trip-list-text {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
    margin: 5px;
  }

  .trip-list-truck-img {
    height: 100px;
    width: 100px;
  }
  .trip-list-status {
    color: ${colorPalette.primaryColor};
    padding: 7px;
    width: min-content;
    border-radius: 10px;
    font-weight: ${FontWeight.semibold};
    margin-left: 10px;
  }
  .trip-list-right-most {
    display: flex;
    margin-left: 5%;
    width: 10vw;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    justify-content: center;
  }
  .trip-list-complete-invoice-grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }

  .trip-pickup-modal-head {
    display: flex;
  }

  .trip-list-add-attachment {
    padding: 10px;
    margin: 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.medium};
    color: ${colorPalette.white};
  }
  .trip-list-modal-input {
    border: 1px solid ${colorPalette.paragraphColor};
    padding: 5px;
    width: 80%;
  }

  .trip-list-whole {
    width: 90%;
    padding-top: 15px;
  }

  .trip-list-search-row {
    /* background-color: ${colorPalette.red}; */
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-left: 20px;
    align-items: center;
    /* background-color: ${colorPalette.paragraphSecondaryolor}; */
  }
  /* .MuiTabs-flexContainer {
  height: 20px !important;
} */
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    padding: 10px !important;
    min-height: 30px !important;
  }
  .css-1ujnqem-MuiTabs-root {
    min-height: 30px !important;
  }
  .css-1aquho2-MuiTabs-indicator {
    background-color: ${colorPalette.primaryColor} !important;
  }
  .create-new-trip-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .create-new-trip-button {
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    border-radius: 5px;
    border: none;
    margin-right: auto;
    font-weight: ${FontWeight.medium};
    padding: 5px 10px;
    margin: 10px;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  .create-new-trip-button:hover {
    background-color: ${colorPalette.primaryColor};
    /* border: 1px solid black; */
  }

  .export-report-trip-button {
    background-color: ${colorPalette.secondaryWhite};
    color: ${colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.medium};
    padding: 5px 10px;
    margin: 0px 10px;
    cursor: pointer;
    border: none;
    outline: none;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  .export-button-inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .export-report-trip-button:hover {
    background-color: ${colorPalette.white};
    /* border: 1px solid black; */
  }

  .trip-list-export-main {
    padding: 10px;
    background-color: ${colorPalette.white};
    /* width: 100vw; */
  }

  .tl-new-main-left {
    display: flex;
    justify-content: space-between;
    flex: 8;
    padding: 10px;
  }
  .tl-new-single-container {
    display: flex;
    padding: 5px 14px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 5px;
    background: ${colorPalette.white};
    margin: 3px;
    justify-content: space-between;
    cursor: pointer;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  .tl-new-tripcode {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.primaryColor};

    font-weight: ${FontWeight.bold};
    font-size: ${typography.paragraph};
    line-height: 18px;
  }
  .tl-new-vehicle {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: ${FontWeight.semibold};
    font-size: ${typography.paragraph_secondary};
    line-height: 14px;
    /* font-family: Po; */
  }
  .tl-new-status {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.primaryColor};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph_secondary};
    line-height: 18px;
    /* padding: 0px 10px; */
  }
  .tl-new-modifiedby {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph_secondary};
    line-height: 14px;
  }
  .tl-new-middle {
    text-align: end;
    /* flex: 4; */
  }
  .tl-new-left {
    /* flex: 4; */
  }
  .tl-new-payterm {
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.paragraphColor};
    font-weight: ${FontWeight.semibold};
    font-size: ${typography.paragraph_secondary};
    line-height: 18px;
  }
  .tl-new-inner-div {
    margin: 3px 0px;
  }
  .css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 4px !important;
  }
  .tl-new-right {
    flex: 2;
    display: flex;
    justify-content: flex-end;
  }

  .details_icons {
    margin: 0.5rem 0rem;
    cursor: pointer;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};

    /* width: 100%; */
  }

  .trip-location-text {
    font-size: ${typography.paragraph};
    font-weight: ${FontWeight.medium};
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.white
        : colorPalette.paragraphSecondaryolor};
    margin-top: 5px;
    margin-bottom: 2px;
  }

  /* mobile responsive css*/

  @media only screen and (max-width: 499px) {
    .trips_status_container {
      width: 100%;
      display: flex;
      padding: 0px 12px;
      align-items: center;
      justify-content: flex-start;
      overflow-x: scroll;
    }
    .trip-list-middle-container_v2 {
      overflow: hidden;
      /* width: 100%; */
      height: 80vh;
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: 180px;
      grid-auto-rows: min-content;
      gap: 5px;
      padding: 10px;
      /* background-color: red; */
      position: sticky;
      overflow-y: scroll;
      border-top: 1px solid ${colorPalette.paragraphSecondaryolor};
    }
    .tl-new-single-container {
      flex-direction: column;
      /* height: 100%; */
    }

    .trip-list-sidebar {
      flex: 0;
      height: 0px;
    }

    /* .trip-list-main-container { */
    width: 100%;
    flex-direction: column;
    height: auto;
    /* } */
    .trip-list-mobile-header {
      width: 100%;
      height: 50px;
    }
    .trip-list-search-row {
      flex-direction: column;
      margin: 10px 0px;
    }
    .trip-list-button-container {
      display: flex;
      /* justify-content: flex-end; */
      align-items: center;
      width: 100%;
      margin: 0;
      margin-top: 10px;
      margin-right: 20px;
    }

    .trip-list-payout-container {
      flex-direction: column-reverse;
    }
    .create-new-trip-button {
      align-self: flex-end;
      margin-right: 15px;
    }
    .trip-list-right-container {
      width: 100%;
      padding: 5px;
    }
    .trip-list-search-container {
      width: 90%;
    }
    .trip-list-single-container {
      flex-direction: column;
    }
    .trip-list-single-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .trip-list-right-most {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
    }
    .trip-list-monthly-img {
      margin-left: 2%;
      margin-top: 0px;
    }

    .trip-list-modal-add-hidden {
      display: none;
    }
    .trip-list-modal-input {
      border: 1px solid gray;
      padding: 5px;
      width: 100%;
    }
    .trip-list-add-attachment {
      background-color: rgb(113, 6, 109);
    }

    .trip-list-single-top {
      display: flex;
      flex-direction: column;
    }
    .trip-list-single-bottom-container {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .trip-list-input-attachment {
      width: 100%;
    }
    .trip-list-filter-main {
      padding: 0px;
      height: 68vh;
      overflow: scroll;
    }
    .trip-list-export-row {
      flex-direction: column;
    }
    .trip-list-export-container {
      height: 100vh;
    }
    .trip-list-export-main {
      height: 100%;
      overflow: scroll;
    }
    .trip-list-export-input-container {
      width: 100%;
    }
    /* .box :nth-child(1) { order: 2; }
        .box :nth-child(2) { order: 3; }
        .box :nth-child(3) { order: 1; }
        .box :nth-child(4) { order: 3; }
        .box :nth-child(5) { order: 1; } */
  }
`;
export const Trip_settings_wrapper = styled.div`
  /* .trip-settings-wrapper { */
  width: 300px;
  height: 100vh;
  /* padding: 5px 5px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* } */
  .trip-settings-head {
    padding: 5px 5px;
    background-color: #076b38;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .settings_list_container {
    padding: 5px 5px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .settings_lists {
    margin-top: 10px;
    cursor: pointer;
    padding: 5px 5px;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 8px -2px rgba(9, 30, 66, 0.25),
      0px 0px 0px 1px rgba(9, 30, 66, 0.08);
    transition: 0.5s ease all;
  }
  .settings_button {
    cursor: pointer;
  }
  .settings_lists:hover {
    /* border: 1px solid green; */
    box-shadow: 0 2px 0 0 rgba(7, 107, 56, 0.6), 0 2px 0 0 rgba(7, 107, 56, 0.6),
      0 2px 0 0 rgba(7, 107, 56, 0.4), 0 2px 0 0 rgba(7, 107, 56, 0.2),
      0 2px 0 0 rgba(7, 107, 56, 0.1);
    /* box-shadow: none; */
    transition: 0.5s ease all;
  }
`;
export const Trip_list_drawer_top_row = styled.div`
  .table-head-delivery-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .payment-history-headings {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .payment-history-subheadings-text {
    font-size: 10px;
    font-weight: 300;
  }
  .payment-history-subheadings {
    font-size: 10px;
    font-weight: bold;
  }
  .mini-card-delivery-info-container-payment-history {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .text-fields-payment-history {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .inoice-details-div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .invoice-details-text {
    font-size: 10px;
    font-weight: bold;
  }
  .payment-history-buttons-main {
    width: 100%;
    /* height: 50px; */
    display: flex;
    padding: 5px 0px;
    margin-top: 10px;
    /* background-color: red; */
    align-items: center;
    /* flex-direction: column; */
    justify-content: flex-start;
  }
  .payment-history-buttons {
    outline: none;
    background-color: white;
    border: 1px solid green;
    color: green;
    width: 150px;
    padding: 5px;
    cursor: pointer;
  }
  .payment-history-buttons-reject {
    outline: none;
    margin-left: 20px;
    background-color: white;
    border: 1px solid red;
    color: red;
    padding: 5px;
    width: 150px;
    cursor: pointer;
  }
  background-color: ${(props) =>
    props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  .trip-cost-details-container {
    padding: 10px;
    width: 100%;
  }
  .trip-cost-keys {
    display: flex;
    /* margin-left: 10px; */
    padding: 5px;
    width: 100%;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  }
  .details_drawer_topbar {
    width: 100%;
    display: flex;
    height: 6%;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};

    /* right: -10px; */
    /* left: 0; */
    /* position: fixed; */
    /* margin-bottom: 2.5rem; */
    padding: 0.3rem 1rem;
    align-items: center;
    justify-content: space-between;
    /* background-color: #e8f9e0; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  }

  .mini-details-single-attachment_download {
    /* background-color: red; */
    /* padding: 8px; */
    margin: 5px 5px;

    display: flex;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    /* border: 1px solid #474747; */
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); */
    /* border-radius: 10px; */
    width: 95%;

    /* margin: -8px 0; */
  }
  .trip_buttons_download {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .trip-list-trip-details-drawer-head-v2 {
    font-weight: ${FontWeight.medium};
    color: ${colorPalette.primaryColor};
    font-size: ${typography.heading2};
  }

  .mini-details-history-head {
    font-weight: ${FontWeight.bold};
    font-size: ${typography.heading4};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.paragraphColor};
  }

  .mini-trip-circle {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    border-radius: 50%;
    z-index: 1000;
    background-color: ${(props) =>
      props.mode
        ? colorPaletteDark.white
        : colorPalette.paragraphSecondaryolor};
  }

  .mini-details-single-list {
    margin: 10px 5px;
    display: flex;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: ${typography.paragraph_secondary} !important;
    font-weight: ${FontWeight.semibold} !important;
  }
  .mini-details-single-list-left {
    font-size: ${typography.heading5};
    margin: 0px 10px;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.black};
  }
  .mini-details-single-list-right {
    font-size: ${typography.heading5};
    margin: 0px 10px;
    position: absolute;
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.white
        : colorPalette.paragraphSecondaryolor};
    /* 
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.white
        : colorPalette.paragraphSecondaryolor}; */
    left: 45%;
  }

  .mini-details-black-text {
    margin-left: 10px;
    color: ${(props) =>
      props.mode ? colorPaletteDark.lightBlack : colorPalette.black};
  }

  .mini-details-grey-text {
    color: ${(props) =>
      props.mode
        ? colorPaletteDark.white
        : colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.semibold};
    margin-left: 1vw;
  }

  .mini-details-attachment-list_trip {
    /* margin: 10px; */
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
    overflow: scroll;
    height: 83vh;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
  }

  .mini-details-single-attachment {
    /* background-color: red; */
    padding: 8px;
    margin: 10px 0px;
    display: flex;
    /* border: 1px solid ${colorPalette.paragraphColor}; */
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 10px;
    width: 95%;
  }

  .mini-details-no-attachment {
    width: 100%;
    /* background-color: red; */
    margin-top: 50%;
    /* margin-left: 50%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* justify-content: center; */
  }

  .mini-details-tab-container-history {
    height: 83vh;
    overflow: scroll;
    display: flex;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

    /* align-items: center;
  justify-content: center; */
  }

  .mini-card-delivery-info-container {
    margin: 5px;
  }

  .mini-card-delivery-table {
    font-family: ${FontFamily.primaryFontFamily};
    border-collapse: collapse;
    width: 100%;
  }

  .mini-card-delivery-table-td {
    border: 1px solid ${colorPalette.paragraphSecondaryolor};
    text-align: left;
    padding: 8px;
  }
  .mini-card-delivery-table-th {
    border: 1px solid ${colorPalette.paragraphSecondaryolor};
    text-align: left;
    padding: 8px;
    font-size: ${typography.heading5};
  }
  .mini-card-table-text-left {
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph_secondary};
  }
  .mini-card-table-text-right {
    font-weight: ${FontWeight.medium};
    color: ${colorPalette.paragraphSecondaryolor};
    font-size: ${typography.paragraph_secondary};
  }

  .mini-delivery-payment-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: ${typography.paragraph};
    font-weight: ${FontWeight.medium};
  }

  .mini-delivery-payment-details-attachment {
    padding: 10px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mini-details-attachment-list {
    /* margin: 10px; */
    background-color: ${colorPalette.white};
    overflow: scroll;
    height: 83vh;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
  }

  .mini-card-row-delivery {
    margin: 5px 0px;

    display: flex;
    justify-content: space-between;
  }
  .mini-details-history-head {
    font-weight: ${FontWeight.bold};
    font-size: ${typography.heading4};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: ${colorPalette.paragraphColor};
  }

  .mini-card-delivery-left {
    margin: 0px 3px;
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph};
  }

  .mini-card-delivery-right {
    color: ${colorPalette.paragraphSecondaryolor};
    /* position: absolute;
  left: 50%; */
    font-weight: ${FontWeight.medium};
    font-size: ${typography.paragraph};
  }

  .mini-card-not-found {
    width: 100%;
    display: flex;
    margin-top: 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .tl-new-right_drawer {
    /* flex: 2; */
    display: flex;
    width: 100%;
    /* height: 8%; */
    /* height: ; */
    /* position: fixed; */
    /* top: 20px; */
    /* padding: 2rem 0rem; */
    /* margin-top: 2.5rem; */
    /* height: 10%; */
    /* height: 100%; */
    justify-content: flex-end;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};
  }

  .trip-list-right-most-single-container_drawer {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    /* grid-template-columns: auto auto; */
  }

  .trip-list-right-most-edit_drawer {
    /* margin-right: 40%; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* background-color: ${colorPalette.primaryColor}; */
  }

  .trip-list-right-most-btn-container {
    margin: 7px;
  }

  .trip-list-approve-btn {
    padding: 8px;
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    border-radius: 2rem;
    border: none;
    width: 100px;
    cursor: pointer;
    font-weight: ${FontWeight.medium};
  }

  .trip-list-reject-btn {
    padding: 7px;
    color: ${colorPalette.primaryColor};
    background-color: ${colorPalette.white};
    border-radius: 2rem;
    border: 2px solid ${colorPalette.primaryColor};
    width: 100px;
    cursor: pointer;
    font-weight: ${FontWeight.medium};
  }

  .details_view_main_conatiner {
    width: 100%;
    height: 100%;
    /* position: fixed; */
    /* margin-top: 2.5rem; */
    display: flex;
    /* overflow: hidden; */
    /* background-color: #46ceab; */
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  }

  .details_view_conatainer {
    flex: 10.5;
    height: 100%;
    background-color: ${(props) =>
      props.mode
        ? colorPaletteDark.secondaryColor
        : colorPalette.secondaryWhite};

    /* background-color: #46ceab; */
    overflow-y: hidden;
    /* background-color: rgb(135, 73, 73); */
  }
  .tl-new-status_drawer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 10px;
    line-height: 18px;
    /* padding: 0px 10px; */
  }

  .mini-details-tab-container_v2 {
    height: 85vh;
    overflow: scroll;
    position: relative;
  }

  .mini-details-single-list {
    margin: 10px 5px;
    display: flex;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: ${typography.paragraph} !important;
    font-weight: ${FontWeight.semibold} !important;
  }
  .mini-details-single-list-left {
    font-size: ${typography.heading5};
    margin: 0px 10px;
  }
  .mini-details-single-list-right {
    font-size: ${typography.heading5};
    margin: 0px 10px;
    position: absolute;
    color: ${(props) =>
      props.mode ? colorPaletteDark.white : colorPalette.paragraphColor};
    left: 45%;
  }

  .trip-location-text-to {
    font-size: ${typography.paragraph};
    font-weight: ${FontWeight.medium};
    color: ${colorPalette.paragraphSecondaryolor};
    margin-bottom: 5px;
    margin-top: 2px;
    /* width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  }

  .trip-list-path {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    margin: 5px;
    /* justify-content: center; */
  }

  .trip-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${colorPalette.paragraphSecondaryolor};
  }

  .details_icons_conatainer {
    flex: 1.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem 0rem;
    height: 100%;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.primaryColor : colorPalette.white};

    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  }

  .details_icons_img_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .details_icons_img_container.active {
    background-color: ${(props) =>
      props.mode
        ? colorPaletteDark.secondaryColor
        : colorPalette.secondaryWhite};

    color: ${colorPalette.primaryColor};
    width: 100%;
    border-right: 5px solid #46ceab;
    /* border: 2px solid #46ceab ; */
    border-left: none;
  }

  .details_icons {
    margin: 0.5rem 0rem;
    cursor: pointer;
  }

  .trip-list-address {
    display: flex;
    margin: 5px;
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
    color: ${colorPalette.paragraphColor};
    padding: 10px;
  }

  .trip-location-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }

  .mini-details-tab-container-history {
    height: 83vh;
    overflow: scroll;
    display: flex;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};

    /* align-items: center;
  justify-content: center; */
  }

  .mini-trip-rectangle-timeline {
    width: 2px;
    height: 10px;
    margin: 2px 0px;
    position: relative;
    left: 0.6vw;
    top: 1vh;
    background-color: ${colorPalette.paragraphSecondaryolor};
  }

  .mini-trip-rectangle {
    width: 2px;
    height: 30px;
    border: 1px dashed ${colorPalette.paragraphSecondaryolor};
  }
  .trip-list-drawer-top-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    background-color: ${(props) =>
      props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
  }

  @media only screen and (max-width: 499px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    .trip-cost-details-container {
      padding: 0px 5px;
      width: 250px;
    }
    .trip-cost-keys {
      display: flex;
      /* margin-left: 10px; */
      /* padding: 5px 10px; */
      width: 100%;
      margin-top: 12px;
      align-items: center;
      font-size: 12px;
      justify-content: space-between;
    }
    .trip-list-drawer-top-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 30px;
    }

    .mini-details-tab-container-history {
      height: 80vh;
    }

    .trip-list-right-most-single-container_drawer {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      /* display: grid;
    grid-template-columns: auto auto; */
    }
    .mini-details-tab-container-history {
      height: 80vh;
      background-color: ${(props) =>
        props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
    }

    .mini-trip-rectangle-timeline {
      top: 1vh;
      left: 2vw;
    }

    padding: 0;
    width: 100vw;

    .details_view_main_conatiner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: ${(props) =>
        props.mode ? colorPaletteDark.secondaryColor : colorPalette.white};
    }

    .details_view_conatainer {
      /* flex: 10.5; */
      width: 100vw;
      height: 100%;
      background-color: ${(props) =>
        props.mode
          ? colorPaletteDark.secondaryColor
          : colorPalette.secondaryWhite};
      overflow-y: hidden;
    }
    .tl-new-status_drawer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-size: 10px;
      line-height: 18px;
    }

    .mini-details-single-list {
      position: relative;
      width: 450px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .mini-details-tab-container_v2 {
      width: 100%;
      /* display: flex; */
      /* align-items: center; */
    }

    .mini-details-single-list-left {
      margin: 0;
      white-space: nowrap;
    }
  }
`;

export const Trip_list_modal_container = styled.div`
  /* .trip-list-modal-container { */
  width: 100%;
  max-height: 80vh;
  overflow: hidden;
  /* } */
  .trip-list-close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
  }
  .trip-list-modal-head {
    font-weight: ${FontWeight.medium};
    margin: 5px 0px;
  }
  .trip-list-modal-text-input {
    width: 90%;
    font-weight: ${FontWeight.medium};
    padding: 5px;
    border: 1px solid ${colorPalette.paragraphColor};
    /* margin: 10px; */
  }
  .trip-list-error {
    color: ${colorPalette.red};
    font-size: ${typography.paragraph};
    margin-left: 5px;
  }

  .trip-list-modal-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 9px 0px;
  }
  .trip-list-modal-input-group {
    flex: 1;
    width: max-content;
  }
  .trip-list-attachment-name {
    /* float: right; */
    font-size: ${typography.paragraph_secondary};
    align-self: center;
    /* justify-self: flex-end; */
  }
  .trip-list-submit-btn {
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    border-radius: 7px;
    margin: 10px 0px;
    border: none;
    padding: 10px;
    cursor: pointer;
    width: 100px;
  }
  @media only screen and (max-width: 499px) {
    .trip-list-modal-head-hidden {
      font-weight: ${FontWeight.medium};
      font-size: ${typography.heading5};
      display: none;
    }
    .trip-list-modal-text-input {
      width: 100%;
    }

    .trip-list-modal-row {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
    }

    .trip-list-modal-input-group {
      margin: 10px 0px;
      width: 100%;
    }
    .trip-list-attachment-name {
      float: right;
      align-self: flex-end;
      margin: 3px;
      margin-right: 10px;
    }
  }
`;

export const Trip_list_filter_main = styled.div`
  /* .trip-list-filter-main { */
  padding: 10px;
  background-color: ${colorPalette.white};

  /* width: 100vw; */
  /* } */
  .trip-list-filter-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .trip-list-filter-main-text {
    font-weight: ${FontWeight.medium};
    font-size: ${typography.heading3};
  }
  .trip-list-filter-container {
    margin: 10px;
  }
  .trip-list-filter-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin: 10px 0px;
  }
  .trip-list-filter-text {
    font-weight: ${FontWeight.medium};
  }

  .trip-list-filter-input-right {
    position: absolute;
    left: 45%;
    width: 65%;
  }
  .trip-list-filter-inputfield {
    width: 90%;
  }
  .trip-list-filter-button-row {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0px;
    width: 98%;
  }
  .trip-list-filter-button {
    padding: 6px;
    margin: 10px;
    border-radius: 10px;
    border: none;
    width: 100px;
    border: 1px solid rgb(74, 73, 73);
    cursor: pointer;
    font-weight: ${FontWeight.medium};
  }

  @media only screen and (max-width: 499px) {
    .trip-list-filter-container {
      margin: 5px;
      height: 67vh;
    }

    .trip-list-filter-row {
      font-size: ${typography.heading5};
      font-weight: ${FontWeight.medium};
      padding: 8px 0px;
    }

    .trip-list-filter-input-right {
      left: 55%;
      width: 60%;
    }
  }
`;

export const Trip_Pickup_modal = styled.div`
  .trip-pickup-modal-head {
    flex-direction: column;
  }
`;
