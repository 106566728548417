import React, { useState, useEffect } from "react";
import ArticleIcon from "@mui/icons-material/Article";
import failed from "../../assets/payment_faild.svg";
import { NavLink, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getPaymentDetails } from "./PaymentFailureServices";
import { Link } from "react-router-dom";
import { Payment_success_container } from "./paymentFailure.js";
function PaymentFailure() {
  const history = useHistory();
  const [orderId, setOrderId] = useState("");
  const search = useLocation().search;
  useEffect(() => {
    const orderId = new URLSearchParams(search).get("order_id");
    setOrderId(orderId);
  }, []);

  const [orderDetails, setOrderDetails] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [itemsData, setItemsData] = useState([]);
  const [total, setTotal] = useState("");

  useEffect(() => {
    if (Object.keys(orderDetails).length > 0) {
      const rawData = orderDetails.json_obj;
      const parsedData = JSON.parse(rawData);
      setItemsData(parsedData.data);
      setTotal(parsedData.total);
    }
  }, [orderDetails]);

  //
  const getPaymentData = async (id) => {
    const response = await getPaymentDetails(id);

    if (response) {
      // const parseData = JSON.parse(response);
      if (response.order_details.length > 0) {
        setOrderDetails(response.order_details[0]);
        setPaymentData(response.payment_request[0]);
      }

      // setPaymentData(response.payment_request[0]);
    }
  };
  useEffect(() => {
    getPaymentData(orderId);
  }, [orderId]);
  return (
    <Payment_success_container className="payment_success_container">
      <div className="payment_success_wrapper">
        <div className="online_headings_success">
          <h5 className="headings_payments_failed">Payment Failed!</h5>
        </div>
        <div className="online_success_icon">
          <img src={failed} alt="" className="sucess-icons" />
        </div>

        <div className="online_transaction_id">
          <h5>
            Transation Id:{" "}
            <span className="invoice_id">
              {paymentData.faarms_transaction_id}
            </span>
          </h5>
        </div>
        <div className="delivered_heading">Delivered Items</div>
        <div className="online_delivered_container">
          {itemsData.map((data, index) => (
            <div className="online_delivered_items" key={index}>
              <h5>{data.item_name}</h5> <h5>₹{data.item_rate}</h5>
            </div>
          ))}
        </div>
        <hr style={{ marginTop: "2rem", width: "90%", margin: "2rem auto" }} />
        <div className="payment_success_invoice">
          <div className="payment_invoice_icon">
            <ArticleIcon style={{ color: "green" }} />

            <div className="invoice">
              <h6>Invoice number</h6>
              {itemsData.map((data, index) => (
                // <div className="online_delivered_items" key={index}>
                <h6 style={{ color: "grey" }}>
                  {" "}
                  {index === 0 ? data.reference_number : null}
                </h6>
                // </div>
              ))}
            </div>
          </div>
          <div className="invoice">
            <h5>₹{total}</h5>
          </div>
        </div>

        <div className="payment_success_button">
          <Link to="/">
            {" "}
            <button className="payment_button">Go Home</button>
          </Link>
        </div>
      </div>
    </Payment_success_container>
  );
}

export default PaymentFailure;
