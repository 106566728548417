import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { readLoginData } from "../../helper/dataDecryptionHelper";

function CustomerProtectedRoutes({ component: Component, ...restOfProps }) {
  //   const [isAuthenticated, setIsAuthenticated] = useState("");
  // const loginApp =
  //   readLoginData() !== undefined ? readLoginData().app_name : "";
  const loginApp = localStorage.getItem("loginApp");

  let isAuthenticated = "";

  if (readLoginData()) {
    isAuthenticated = readLoginData().IS_LOGGED_IN;
    // setIsAuthenticated(isAuth);
  } else {
    isAuthenticated = false;
  }

  //
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated && loginApp === "leap-supply-customers" ? (
          <Component {...props} />
        ) : (
          <Redirect to="/customer/login" />
        )
      }
    />
  );
}

export default CustomerProtectedRoutes;
