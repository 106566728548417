import styled from "styled-components";
import { FontWeight, colorPalette, typography } from "../../../themes/theme";
import headImage from "../../../assets/homepage/header-bg.png";

export const HeaderStyle = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${colorPalette.primaryColor};
  display: flex;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  padding: 0 100px;

  .header .bg {
    position: absolute;
    right: -150px;
    top: 0;
    width: 50%;
    height: 100vh;
    background-color: red;

    /* background-image: url("/public/assets/landingPageAssets/header-bg.png"); */
    background-size: contain;
    background-repeat: no-repeat;
  }

  .header-content {
    margin-top: 50px;
    width: 100%;
  }

  .header-title {
    width: 500px;
    font-size: 50px;
    line-height: 72px;
    font-weight: ${FontWeight.bold};
    letter-spacing: 2px;
    color: white;
  }

  .header-title span {
    color: ${colorPalette.secondaryColor};
  }

  .header-subtitle {
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.bold};
    font-size: 20px;
    line-height: 30px;
    margin-top: 40px;
  }

  /* .header-btn {
    width: 100%;
    display: flex;
    margin-top: 30px;
  }

  .header-btn button {
    width: 180px;
    padding: 15px;
    font-size: ${typography.heading4};
    font-weight: ${FontWeight.bold};
    border-radius: 10px;
    margin-right: 20px;
    cursor: pointer;
    margin-top: 30px;
    border: none;
  }

  .header-btn .signIn {
    border: 2px solid white;
    background: none;
  }

  .header-btn a {
    color: white;
    text-decoration: none;
    padding: 10px 5px;
  }

  .header-btn .knowMore {
    border: 2px solid ${colorPalette.white};
    background-color: white;
  }

  .knowMore {
    color: ${colorPalette.primaryColor};
  }
  .signIn_customer {
    border: 2px solid ${colorPalette.white};
    background-color: white;
    width: 100%;
    color: green;
  }

  .become-transporter {
    background-color: white;
    width: 100%;
    border-radius: 100px;
  }

  .become-transporter a {
    width: 100%;
    border-radius: 100px;
    font-weight: ${FontWeight.semibold};
  } */

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    padding: 50px 20px;
    .header-btn {
      width: 100%;

      display: flex;
      /* margin-top: 30px; */
    }
    /* .header-btn {
      flex-direction: column;
    }

    .header-btn button {
      width: 500px;
    }

    .header .bg {
      display: none;
    }

    .header-title {
      font-size: 35px;
      line-height: 50px;
      width: 100%;
    }

    .header-subtitle {
      font-size: ${typography.heading4};
      margin-top: 20px;
    }

    .header-btn button {
      width: 120px;
      padding: 8px;
      font-size: ${typography.heading4};
      font-weight: ${FontWeight.bold};
      border-radius: 50px;
      margin-right: 10px;
      cursor: pointer;
      margin-top: 30px;
      border: none;
    } */

    /* .knowMore {
    width: 200px;
  } */
    /* .signIn_customer {
      border: 2px solid ${colorPalette.white};
      background-color: white;

      color: green;
    }
    .customer-portal {
      font-size: ${typography.paragraph_secondary};
      padding: 2rem;
    
    } */
  }
`;
export const HeaderImage = styled.div`
  position: absolute;
  right: -150px;
  top: 0;
  width: 50%;
  height: 100vh;
  background-image: url(${headImage});
  background-size: contain;
  background-repeat: no-repeat;

  @media only screen and (max-width: 768px) {
   
      display: none;
    }
  }
`;
