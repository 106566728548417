import React, { useEffect, useState } from "react";
import "./topbar.css";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import Switch from "@mui/material/Switch";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import changePassword from "../../assets/dashboard_icons/change_password.svg";
import logout from "../../assets/dashboard_icons/logout_icon.svg";
import { useDispatch } from "react-redux";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { useParams } from "react-router-dom";
import { updateOnFlag, updateOnFlagUpdate } from "../../redux/indentSlice";
import { useHistory } from "react-router-dom";
import { readLoginData } from "../../helper/dataDecryptionHelper";
import { argon2id } from "hash-wasm";
import { changeUserPassword } from "../../pages/login/LoginServices";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MailOutlineOutlined } from "@mui/icons-material";
import { useContext } from "react";
import {
  DarkModeContext,
  DarkModeContextProvider,
} from "../../context/darkModeContext";
import { Topbar_style } from "./topBar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function Topbar() {
  const dispatch = useDispatch();
  let history = useHistory();
  const handleClickOpenChangePassword = () => {
    history.push("/change-passwords");
    dispatch(updateOnFlagUpdate(false));
    dispatch(updateOnFlag(false));
  };
  const is_temporary_password =
    readLoginData() !== undefined && readLoginData().temporary_password
      ? readLoginData().temporary_password
      : null;
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [changedPassword, setChangedPassword] = useState("");
  const [misMatchedPassword, setMisMatchedPassword] = useState("");
  const [loginData, setLoginData] = useState();
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  const handleGoHome = () => {
    history.push("/dashboard");
  };
  const handleLogout = () => {
    if (
      localStorage.getItem("website_url") ===
      process.env.REACT_APP_CUSTOMER_WEBSITE_URL
    ) {
      history.push("/customer/login");
      localStorage.clear();
      window.location.reload();
    } else {
      history.push("/login");
      localStorage.clear();
      window.location.reload();
    }
    // localStorage.clear();
    // window.location.reload();
    // history.push("/login");
  };

  useEffect(() => {
    setLoginData(readLoginData());
  }, []);

  useEffect(() => {
    if (history.location.pathname.split("/").length > 1) {
      setCurrentPath(history.location.pathname.split("/")[1]);
    }
  }, []);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 499px)"
  ).matches;

  //Modal Related code starts
  const [open, setOpen] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (is_temporary_password) {
      if (
        localStorage.getItem("website_url") ===
        process.env.REACT_APP_CUSTOMER_WEBSITE_URL
      ) {
        history.push("/customer/change-passwords");
      } else {
        history.push("/change-passwords");
      }
      // history.push("/change-passwords");
    }
  }, [is_temporary_password]);
  //Modal Related code ends
  async function argon2HashPassword(user, userPassword) {
    // let decodedUserName = atob(user);
    //
    // setUserNameFromApi(ddecodedUserName);
    const salt = user;
    const hash = await argon2id({
      password: userPassword,
      salt,
      parallelism: 1,
      iterations: 2,
      memorySize: 16,
      hashLength: 16,
      outputType: "encoded",
    });
    //
    return hash;
  }

  const handlePasswordChange = async () => {
    const argon = await argon2HashPassword(
      loginData.USER_NAME,
      changedPassword
    );
    if (confirmPassword !== changedPassword) {
      setMisMatchedPassword("Password does not matches.");
    }

    if (argon && confirmPassword == changedPassword) {
      setLoading(true);

      const response = await changeUserPassword(argon);

      if (response.status == 200) {
        setLoading(false);
        setSuccessMsg(`A password has been changed successfully.`);
        setOpenSnackbar(true);
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 2000);

        // setRegistredEmail("");
        // setWrongEmailMsg("");
      }
      if (response == 422) {
        setSuccessMsg("");
        setLoading(false);

        // setWrongEmailMsg("Please enter correct email id.");
      }
    }
    // setLoading(true);
  };
  const [state, setState] = React.useState({
    right: false,
  });
  const [snackbarPosition, setSnackbarPosition] = useState({
    vertical: "bottom",
    horizontal: "left",
  });
  const { vertical, horizontal } = snackbarPosition;
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  JSON.parse(localStorage.getItem("opneNewFeature"));
  const items = [
    {
      label: (
        <div className="logout_button_container">
          <MailOutlineOutlined
            style={{ fontSize: "16px", color: "grey", marginRight: "4px" }}
          />
          {loginData ? loginData.USER_EMAIL : "--"}
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className="logout_button_container">
          <AssignmentIndOutlinedIcon
            style={{ fontSize: "16px", color: "grey", marginRight: "4px" }}
          />
          {loginData ? loginData.Role : "--"}
        </div>
      ),
      key: "1",
    },

    {
      type: "divider",
    },

    {
      label: (
        <div
          onClick={handleClickOpenChangePassword}
          className="logout_button_container"
        >
          <img
            src={changePassword}
            alt=""
            style={{
              height: "16px",
              width: "16px",
              color: "grey",
              marginRight: "4px",
            }}
          />{" "}
          Change Password
        </div>
      ),
      key: "2",
    },
    {
      label: (
        <div onClick={handleClickOpen} className="logout_button_container">
          {" "}
          <img
            src={logout}
            alt=""
            style={{
              height: "16px",
              width: "16px",
              color: "grey",
              marginRight: "4px",
            }}
          />
          Logout
        </div>
      ),
      key: "3",
    },
  ];
  function capitalizeLetter(str) {
    var i,
      splittedArray = str.split("_");
    for (i = 0; i < splittedArray.length; i++) {
      splittedArray[i] =
        splittedArray[i].charAt(0).toUpperCase() + splittedArray[i].slice(1);
    }
    return splittedArray.join(" ");
  }
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    transition: "10s ease all",
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        transition: "10s ease all",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "white",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      // backgroundColor:
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#F3CE5A"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#F3CE5A",
      borderRadius: 20 / 2,
    },
  }));
  const { toggle, darkMode } = useContext(DarkModeContext);
  const [checked, setChecked] = useState(false);
  return (
    <Topbar_style className="topbar-container" mode={darkMode}>
      <div>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
            Open alert dialog
          </Button> */}
        <div className="mode_icons">
          {/* {darkMode ? (
            <>
              Dark <DarkModeIcon className="darkModeIcon" />
            </>
          ) : (
            <>
              Light <WbSunnyIcon className="lightModeIcon" />
            </>
          )} */}
          {/* <FormGroup> */}
          <FormControlLabel
            control={
              <MaterialUISwitch
                sx="small"
                checked={darkMode}
                onClick={() => {
                  toggle();
                  // setChecked(!darkMode);
                }}
              />
            }
            label=""
            size="small"
          />
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you really want to Logout?"}
          </DialogTitle>
          <DialogContent style={{ width: "390px" }}>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLogout}>Yes</Button>
            <Button onClick={handleClose} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
      >
        <div className="user_name_conatainer">
          {/* <Space> */}
          <div className="user-name-text">
            {capitalizeLetter(loginData ? loginData.USER_NAME : "Guest")}
          </div>
          <AccountCircleIcon style={{ color: "white", cursor: "pointer" }} />{" "}
        </div>
        {/* </Space> */}
      </Dropdown>
    </Topbar_style>
  );
}

export default Topbar;
