import styled from "styled-components";
import {
  FontFamily,
  FontWeight,
  colorPalette,
  typography,
} from "../../../themes/theme";

export const Create_Trip_Main_Container = styled.div`
  /* .create-trip-main-container { */
  /* background-color: red !important; */
  /* } */
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  /* background-color: red; */
  justify-content: center;
  /* } */
  .create-trip-source-radio {
    display: flex;
  }
  .trip_top_container {
    background-color: white;
    border-radius: 5px;
    width: 100%;

    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  /* =======================address list==================== */

  .address_container {
    /* background: #c9c9c9; */
    height: 150px;
    width: 100%;
    padding: 5px;
    display: flex;
    border: 1px solid #c9c9c9;
    justify-content: flex-start;
    align-items: center;
  }
  .add_address_button {
    /* width: 100px; */
    background: #d7f2c9;
    height: 100%;
    cusror: pointer;
    padding: 0 10px;
    border-radius: 6px;
    /* width: 100%; */
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .add_address_button span {
    color: #0d6f3b;
    font-family: ${FontFamily.primaryFontFamily};
    font-weight: ${FontWeight.medium};
  }
  .address_list_container {
    /* background: red; */
    display: flex;

    width: 400px;
    height: 100%;
    overflow-x: auto;
    align-items: center;
    gap: 10px;
    /* overflow: hidden; */
    /* white-space: nowrap; */
    padding: 0 10px;
    /* border: 1px solid #c9c9c9;
    border-radius: 6px; */
    /* width: 100%; */

    /* justify-content: space-between; */
    align-items: center;
  }
  .single_address_box {
    position: relative;
    /* background: green; */
    height: 93%;
    /* width: 300px; */
    flex: 0 0 40%;
    padding: 0 10px;
    border-radius: 6px;
    border: 1px solid #c9c9c9;
    display: flex;
    flex-direction: column;
    /* overflow: auto; */
    justify-content: flex-start;
    align-items: flex-start;
  }
  .delete_address {
    position: absolute;
    top: -10px;
    right: -10px;
    color: grey;
    cusror: pointer;
  }
  .address_line {
    color: #575757;
    font-size: 14px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  /* ========================================================== */

  .trip_second_container {
    margin: 1rem 0rem;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  .create-trip-search-results-list {
    background-color: ${colorPalette.white};
    border: 0.5px solid ${colorPalette.secondaryWhite};
    padding: 6px;
  }
  .create-trip-search-results-list:hover {
    background-color: ${colorPalette.secondaryWhite};
    cursor: pointer;
  }
  .create-trip-search-results-container {
    position: absolute;
    background-color: ${colorPalette.white};
    width: 80%;
    top: 105%;
    height: 300px;
    z-index: 9999999999;
    overflow: scroll;
  }
  .create-trip-dl-name {
    font-size: ${typography.paragraph_secondary};
    margin-left: 4px;
  }
  .create-trip-pan-name {
    font-size: ${typography.paragraph_secondary};
    margin-left: 4px;
  }
  .create-trip-attachment-name {
    /* float: right; */
    width: 100%;
    display: flex;
    font-size: ${typography.paragraph_secondary};
    align-self: center;
    justify-self: flex-start;
  }
  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    visibility: hidden !important;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    padding-right: 0px !important;
  }
  .dropdown-container {
    -moz-appearance: none;

    -webkit-appearance: none;
  }

  .dropdown-container select::-ms-expand {
    display: none;
  }
  .create-trip-alert {
    margin: 5px 5px;
    width: 100%;
  }
  .upload_button {
    /* background-color: #154738; */
    /* height: 100%; */
    width: 100%;
    /* height: ; */
    padding: 0.4rem 1.5rem;
    background-color: ${colorPalette.secondaryWhite};
    border: none;
    outline: none;
    /* background-color: red; */
    /* margin-left: 2rem; */
  }
  .upload {
    background-color: ${colorPalette.secondaryWhite};
  }
  .file_container {
    background-color: ${colorPalette.primaryColor};
  }
  .trip-list-modal-input-group-attachment {
    flex: 1;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
  }
  .create-trip-input-attachment {
    width: 60%;
  }
  .create-trip-reference-search-row {
    display: flex;
    position: relative;
  }
  .create-trip-reference-search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    /* border: 1px solid black; */
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    width: 100px;
  }
  .create-trip-reference-search-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border: 1px solid ${colorPalette.black};
    border-radius: 10px;
    margin-left: 10px;
    /* cursor: pointer; */
    width: 100px;
  }
  .create-trip-modal-head {
    font-weight: ${FontWeight.regular};
  }
  .create-trip-attachments-box {
    padding: 10px;
    /* border: 1px solid black; */
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 10px;
    background-color: ${colorPalette.secondaryWhite};
    width: 100%;
  }
  .create-trip-eway-box {
    padding: 10px;
    /* border: 1px solid black; */
    box-shadow: ${colorPalette.boxShadowPrimary};
    border-radius: 10px;
    background-color: ${colorPalette.secondaryWhite};
    width: 100%;
    margin-bottom: 10px;
  }
  .create-trip-sidebar-container {
    flex: 1.7;
    width: 100%;
    height: 100vh;
  }
  .create-trip-cost-input-contain {
    display: flex;
    /* align-item: */
    /* background-color: ${colorPalette.lightGreen}; */
    /* align-items: center; */
    flex-direction: column;
    width: 100%;
  }
  .create-trip-cost-input {
    width: 80%;
  }
  .create-trip-purchase-row-grid {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    /* background-color: #66A587; */
    /* display: grid; */
    /* grid-template-columns: ; */
    /* background-color: #66A587; */
  }
  .create-trip-invoice-list {
    padding: 5px;
    border: 1px solid ${colorPalette.paragraphColor};
    border-radius: 7px;
    margin: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 20%;
    background-color: ${colorPalette.white};
  }
  .create-trip-payment-box {
    display: flex;
    background-color: red;
    flex-direction: column;
    /* justify-content: center; */
    align-items: flex-start;
    flex: 1;
    /* padding: 6px; */
    margin: 10px 0px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: ${colorPalette.boxShadowPrimary};
    /* border: 1px solid black; */
    border-radius: 10px;
    /* width: 80%; */
  }
  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    visibility: hidden !important;
  }
  /* #dropdown {
  appearance: none;
  padding: 5px;
 
  border: none;
  font-family: inherit;
  outline: none;
} */
  .create-trip-invoice-text {
    margin: 2px;
    font-size: ${typography.paragraph};
  }
  .create-trip-right-container {
    flex: 9.5;
    width: 80%;
    height: 100vh;
    /* background-color: #cccccc; */
    background-color: ${colorPalette.lightBlack};
    padding: 16px 5px;
    position: sticky;
    overflow: scroll;
  }
  .create-trip-purchase-list {
  }
  .create-trip-destination-remove {
    margin-left: 10px;
    margin-left: auto;
    margin-right: 21%;
    /* margin-top: 30px; */
    /* display: flex */
    /* flex-direction: column; */
    /* justify-content: center; */
    /* align-items: center; */
  }
  .create-trip-add-destination-container {
    width: 100%;
  }
  .create-trip-add-destination {
    padding: 4px;
    border: 1px solid ${colorPalette.primaryColor};
    border-radius: 5px;
    background-color: ${colorPalette.primaryColor};
    width: 25%;
    color: ${colorPalette.white};
    cursor: pointer;
    margin: 10px 5px 5px 0px;
    margin-left: auto;
  }
  .create-trip-driver-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  .create-trip-addd {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* flex-direction: column; */
    cursor: pointer;
    color: ${colorPalette.primaryColor};
    font-weight: ${FontWeight.semibold};
    width: 15%;
    border: 2px solid ${colorPalette.primaryColor};
    border-radius: 5px;
    /* margin-left: 2px; */
    font-size: ${typography.paragraph_secondary};
  }
  .create-trip-select-list-right-text {
    font-weight: ${FontWeight.medium};
  }
  .create-trip-select-list-left-text {
    font-size: ${typography.heading5};
    color: ${colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.medium};
  }
  .create-trip-right-most-container {
    overflow-y: scroll;
    height: 100vh;
    padding: 40px 0px;
  }
  .create-trip-cross {
    background-color: gray;
    border-radius: 25%;
    padding: 10px;
  }
  .create-trip-from-row-grid {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .create-trip-right-most-head {
    /* background-color: ${colorPalette.secondaryWhite}; */
    color: ${colorPalette.primaryColor};
    font-size: larger;
    margin: 15px 5px;
    font-weight: ${FontWeight.semibold};
  }
  .create-trip-add-cost {
    padding: 10px;
    color: ${colorPalette.white};
    background-color: ${colorPalette.primaryColor};

    border: none;
    border-radius: 10px;
    margin: 10px;
    margin-right: 30px;
  }
  .create-trip-error {
    color: red;
    font-size: ${typography.paragraph};
    margin-left: 5px;
    margin-top: 5px;
  }
  .create-trip-add-cost-row {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* margin-right: auto; */
  }
  .create-trip-save-btn {
    padding: 10px;
    width: 80%;
    margin: 10px 0px;
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  .create-trip-from-body {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
  }

  .create-trip-edit-driver {
    padding: 10px;
    margin: 10px;
    width: 40%;
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    border-radius: 10px;
    border: none;
    cursor: pointer;
  }
  .create-trip-drivers-box-row {
    display: flex;
    margin: 15px 0px;
  }
  .create-trip-drivers-left {
    width: 42%;
    font-weight: ${FontWeight.medium};
  }
  .create-trip-drivers-right {
    /* width: 35%; */
    color: ${colorPalette.paragraphSecondaryolor};
    font-weight: ${FontWeight.medium};
  }
  .create-trip-drivers-box {
    padding: 10px 0px;
    margin: 0px 0px;
    /* border: 1px solid black; */
    /* box-shadow: ${colorPalette.boxShadowPrimary}; */
    /* background-color: ${colorPalette.lightGreen}; */
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
  .create-trip-button {
    background-color: ${colorPalette.primaryColor};
    padding: 15px 15px 15px 15px;
    border: none;
    font-weight: ${FontWeight.semibold};
    color: ${colorPalette.white};
    border-radius: 25px;
    flex: 1;
    width: 100%;
    cursor: pointer;
  }
  .create-trip-button-next-summary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 15px 15px 15px;
    border: none;
    border: 1px solid ${colorPalette.primaryColor};
    font-weight: ${FontWeight.semibold};
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    border-radius: 5px;
    font-size: 15px;
    flex: 1;
    width: 100%;
    cursor: pointer;
  }

  .create-trip-button-container {
    width: 35%;

    margin: 10px auto;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }
  .create-trip-button-container-error {
    width: 100%;

    /* margin: 5px auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .create-trip-from-head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
    font-weight: ${FontWeight.bold};
    font-size: 20px;
    color: ${colorPalette.paragraphSecondaryolor};
    margin-top: 15px;
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .create-trip-from-row {
    width: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
  }
  .create-trip-from-row-ewaybill {
    width: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    /* background-color: red; */
  }
  .create-trip-invoice-row-grid {
    /* width: 100%; */
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .create-trip-input-container {
    flex: 1;
    /* background-color: red; */
    /* width: 100%; */
    margin: 10px;
    /* margin-bottom: 20px; */
  }
  .create-trip-input-container-customer-type {
    flex: 4;
    /* background-color: ${colorPalette.primaryColor}; */
    width: 100%;
    margin: 10px;
  }
  .create-trip-driver-heaad {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .create-trip-input-source {
    width: 90%;
  }
  .create-trip-group-card {
    /* border: 1px solid black; */
    padding: 20px;
    border-radius: 10px;
    box-shadow: ${colorPalette.boxShadowPrimary};
    margin: 10px;
  }
  .create-trip-input-head {
    margin: 5px 0px;
    font-weight: ${FontWeight.medium};
  }
  .create-trip-input {
    width: 100%;
  }
  #purchase_main {
    /* background-color: #66A587; */
  }
  #create_trip_purchase_input {
    width: 100%;
  }
  #create_trip_invoice_input {
    width: 100%;
  }
  .create-trip-input-driver {
    width: 100%;
  }
  .create-trip-absolute-input {
    position: absolute;
    left: 45rem;
  }

  /* .custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Select some files";
  display: inline-block;
  background: linear-gradient(top, ${colorPalette.secondaryWhite}, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: ${FontWeight.bold};
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, ${colorPalette.secondaryWhite});
} */
  .create_trip_top_Section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
  }
  .trip_type_text {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    color: #438954;
    font-weight: bolder;
  }
  @media only screen and (max-width: 499px) {
    #vehicle_input {
      background-color: red;
      width: 100%;
    }
    .create-trip-input-driver {
      width: 100%;
      /* background-color: red; */
    }
    .trip_second_container {
      margin: 1rem 0rem;
      width: 100%;
      /* background-color: red; */
      /* background-color: white; */
      border-radius: 5px;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }
    /* .create-trip-main-container { */
    flex-direction: column;
    height: 100%;
    /* } */
    .create-trip-right-container {
      width: 100%;
    }
    .create-trip-input-container-customer-type {
      flex: 4;
      /* background-color: ${colorPalette.primaryColor}; */
      width: 100%;
      margin: 10px;
      padding: 0px 10px;
    }
    .create-trip-from-body {
      margin-left: 0px;
      display: flex;
      padding: 0px 5px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      /* background-color: red; */
    }
    .create-trip-from-row-grid {
      grid-template-columns: 100%;
    }
    /* .vehicle_driver_container {
      display: flex;
    } */
    .create-trip-from-row {
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .create-trip-input {
      width: 90%;
    }
    .create-trip-driver-input {
      width: 90%;
    }
    .create-trip-button-container {
      width: 50%;
    }
    .create-trip-absolute-input {
      left: 50%;
    }
    .create-trip-cost-input {
      /* position: absolute;
      left: 0%;
      top: 5px; */
    }
    .create-trip-right-most-container {
      height: 100%;
      padding: 40px 0px;
    }

    .create-trip-drivers-box {
      width: 100%;
    }
    .create-trip-cost-input-contain {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    .create-trip-invoice-list {
      width: 46%;
    }
    .create-trip-modal-head {
      font-weight: ${FontWeight.regular};
      font-size: ${typography.heading5};
    }
    .create-trip-payment-box {
      width: 90%;
      margin-left: 2.5%;
      padding: 10px;
    }
    .create-trip-attachments-box {
      width: 100%;
      /* margin-left: 2.5%; */
      margin-top: 10px;
    }
    .create-trip-input-attachment {
      width: 95%;
    }
    .create-trip-attachment-name {
      float: right;
      align-self: flex-end;
      /* margin: 3px; */
      /* margin-left: 30px; */
      /* background-color: red; */
    }
    .create-trip-source-radio {
      flex-direction: column;
    }
    .create-trip-reference-search {
      width: 50px;
    }
    .create-trip-reference-search-disabled {
      width: 50px;
    }
    .create-trip-search-results-container {
    }
    .create-trip-eway-box {
      margin-left: 10px;
    }
  }
`;

export const Create_trip_drawer_container = styled.div`
  /* .create-trip-drawer-container { */
  margin-left: 10px;
  width: 500px;
  /* } */

  .create-trip-right-most-head {
    /* background-color: ${colorPalette.secondaryWhite}; */
    color: ${colorPalette.primaryColor};
    font-size: larger;
    margin: 15px 5px;
    font-weight: ${FontWeight.semibold};
  }
  .create-trip-from-row {
    display: flex;
    width: 100%;
  }

  .create-trip-input-container {
    flex: 1;
    /* background-color: ${colorPalette.primaryColor}; */
    /* width: 100%; */
    margin: 10px;
    /* margin-bottom: 20px; */
  }

  .create-trip-input-head {
    margin: 5px 0px;
    font-weight: ${FontWeight.medium};
  }

  .create-trip-input {
    width: 80%;
    color: white;
  }

  .create-trip-error {
    color: red;
    font-size: ${typography.paragraph};
    margin-left: 5px;
    margin-top: 5px;
  }

  .create-trip-save-btn {
    padding: 10px;
    width: 80%;
    margin: 10px 0px;
    background-color: ${colorPalette.primaryColor};
    color: ${colorPalette.white};
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }

  @media only screen and (max-width: 499px) {
    /* .create-trip-drawer-container { */
    margin-left: 10px;
    width: auto;
    /* } */
    .create-trip-from-row {
      flex-direction: column;
    }
    .create-trip-input {
      width: 90%;
    }
  }
`;

export const Create_trip_vehicle_single = styled.div`
  border-bottom: 1px dashed ${colorPalette.paragraphSecondaryolor};
  padding: 5px;

  .create-trip-vehicle-number-text {
    font-size: ${typography.heading4};
    font-weight: ${FontWeight.medium};
  }
  .create-trip-monthly-container {
    display: flex;
  }

  .create-trip-monthly-img {
    width: 20px;
    height: 20px;
    margin-left: 30%;
    /* margin-right: 4px; */
  }

  .create-trip-monthly-text {
    font-size: ${typography.heading5};
    color: ${colorPalette.primaryColor};
    margin-left: 5px;
  }

  .create-trip-sub-text {
    font-size: ${typography.heading5};
  }

  .create-trip-right-wrong-img {
    width: 15px;
    height: 15px;
  }
  .vehile-expiry-single-container {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin: 5px;
  }

  .vehicle-expiry-check-container {
    display: flex;
  }

  .vehicle-expiry-text {
    font-size: ${typography.heading5};
    font-weight: ${FontWeight.medium};
    color: ${colorPalette.paragraphColor};
    margin-left: 2px;
  }
`;
export const Summary_container = styled.div`
  .trip-rectangle-summary {
    width: 2px;
    height: 20px;
    border: 1px dashed ${colorPalette.paragraphSecondaryolor};
  }

  }
  .trip-location-text-summary {
    font-size: ${typography.paragraph};
    font-weight: ${FontWeight.medium};
    color: ${colorPalette.paragraphSecondaryolor};
    /* width:100% ; */
 
    min-width:fit-content ;
    margin-top: 0px;
    margin-bottom: 0px;
    /* background-color:red ; */
    @media only screen and (max-width: 499px) {
      width:800px;
      min-width:min-content ;
  }
    /* min-width: min-content; */
    /* width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  }
  /* .summary_container { */
    width: 100%;
    display: flex;
    flex-direction: column;
    /* overflow: auto; */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* background-color: red; */
    padding: 10px;
  }
  .summary_header {
    width: 100%;
    display: flex;
    padding:10px 10px;
    margin-top:10px;
    /* overflow: auto; */
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10%;
    background: linear-gradient(164deg, #91FAAB 0%, #0FBC9E 100%);
    /* background-color: blue; */
  }
  .edit_summary_button{
    width:130px ;
    height:30px;
    outline:none;
    border:none;
    padding:5px;
    display: flex;
    /* overflow: auto; */
    justify-content: center;
    align-items: center;
    background: #D9D9D9; 
    border-radius:5px;
    cursor:pointer;  
  }
  .summary_details_container{
    width:95% ;
    /* background-color:red ; */
  }
  .summary_details_trip_type{
    width:100% ;
    padding:10px 5px;
    display:flex ;
    align-items:center ;
    justify-content:space-between ;
  }
  .trip_type_summary{
    display:flex ;
    align-items:center ;
    justify-content:flex-start ;

  }
  .trip_type_summary h3{
    font-size:bold ;
    display:flex ;
    align-items:center ;
    justify-content:center ;
    /* background-color:blue ; */
     background: -webkit-linear-gradient(164deg, #91FAAB 0%, #0FBC9E 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  }
  .summary_content_wrapper1{
    padding:10px 5px ;
    /* border:1px solid grey ; */
    width:100% ;
     display:flex ;
     align-items:center ;
     justify-content:space-between ;
     @media only screen and (max-width: 499px) {
      padding:10px 5px ;
    width:100% ;
     display:flex ;
     flex-direction:column ;
     align-items:flex-start ;
     justify-content:space-between ;
     /* background-color:red ; */
  }

  }
  .summary_content_wrapper2{
    width: 100%;
    height:100% ;
    padding:5px 5px ;
    gap: 10px;
    /* border:1px solid grey ; */
    width:100% ;
     display:flex ;
     align-items:center ;
     justify-content:space-between ;
     @media only screen and (max-width: 499px) {
      padding:10px 5px ;
    width:100% ;
     display:flex ;
     flex-direction:column ;
     align-items:flex-start ;
     justify-content:space-between ;
     /* background-color:blue ; */
  
  }
  }
  .summary_vehicle{
    flex:4 ;
    height:100% ;
    padding:10px 10px ;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    background-color:#FEECA842 ;
    @media only screen and (max-width: 499px) {
 
      width:100% ;

     /* background-color:red ; */
  }
  }
  .summary_Driver{
    height:100% ;
    padding:10px 10px ;
    flex:4 ;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    background-color:#FEECA842 ;
    @media only screen and (max-width: 499px) {
 
 width:100% ;

/* background-color:red ; */
}
  }
  .summary_content_heading{
    font-size:13px ;
    font-weight:bold ;
    font-family:"poppins" ;
    /* padding:15px 0px ; */
    /* border-bottom:2px solid green ; */
  }
  .summary_content_wrapper3{
     margin:10px 0px ;
    width: 100%;
    height:100% ;
    padding:5px 5px ;
    gap: 10px;
    /* border:1px solid grey ; */
    width:100% ;
     display:flex ;
     align-items:center ;
     justify-content:space-between ;
     @media only screen and (max-width: 499px) {
      padding:10px 5px ;
    width:100% ;
     display:flex ;
     flex-direction:column ;
     align-items:flex-start ;
     justify-content:space-between ;
     /* background-color:red ; */
  }
  }
  .summary_content_wrapper4{
     margin:10px 0px ;
    width: 100%;
    height:100% ;
    padding:5px 5px ;
    gap: 10px;
    /* border:1px solid grey ; */
    width:100% ;
     display:flex ;
     align-items:center ;
     justify-content:flex-end ;
  }
  .summary_underline2{
    /* margin-bottom:10px ; */
    width:30% ;
    border-top-right-radius:10px ;
    border-top-left-radius:10px ;
    font-size:10px ;
    background-color: #0FBC9E,
  }
  .summary_underline3{
    /* margin-bottom:10px ; */
    width:20% ;
    border-radius:10px ;
    font-size:10px ;
    background-color: #0FBC9E,
  }
  .summary_details_text{
    margin-top:5px ;
    display:flex ;
    width:100% ;
    align-items:center ;
    /* background-color: red; */
    justify-content:space-between ;
  }
  .summary_details_text_customer_details{
    margin-top:5px ;
    display:flex ;
    width:50% ;
    overflow-x:scroll ;
    /* flex-wrap:wrap ; */
    /* word-wrap:w ; */
    /* overflow-x: ; */
    /* word-break: break-all; */
    align-items:center ;
    /* background-color: red; */
    justify-content:space-between ;
    @media only screen and (max-width: 499px) {
      
      width:100% ;
    
}
  }
  .summary_customer{
    flex:4 ;
    /* width:100% ; */
    height:100% ;
    gap:20px ;
     display:flex ;
     align-items:center ;
     justify-content:space-between ;
  }
  .summary_customer_wrapper{
    display:flex ;
    flex-direction:column ;
    /* background-color:red ; */
    /* justify-content:flex-end ; */
    align-items:flex-end ;
  
    width:100% ;
    @media only screen and (max-width: 499px) {
      align-items:flex-start ;
      width:100% ;
      
}
  }
  .summary_customer_wrapper_address{
    display:flex ;
    flex-direction:column ;
    /* justify-content:flex-end ; */
    align-items:flex-start ;
  
    width:100% ;
    @media only screen and (max-width: 499px) {

    /* width:100% ; */
    overflow-x:scroll ;
    width:80vw;
       background-color: blue,

     /* background-color:red ; */
  }
  }
  .trip-list-address{
    @media only screen and (max-width: 499px) {

/* width:100% ; */
width:100%;
/* background-color:blue ; */

 /* background-color:red ; */
}
  }
  .summary_customer_address{
    flex:4 ;
 
    /* width:100% ; */
    height:100% ;
    /* gap:20px ; */
     display:flex ;
     align-items:center ;
     justify-content:flex-start ;
    
     @media only screen and (max-width: 499px) {


      /* background-color: blue, */


  }
  }
  .customer_tag{
    min-width:100px ;
    display:flex ;
    /* background-color: green; */
    align-items:center ;
    justify-content:flex-start ;
    color:#575757;
  }
  .customer_value{
    /* background-color: red; */
    min-width:100%;
    display:flex ;

    /* word-wrap: break-word; */
    align-items:center ;
    justify-content:flex-start ;
  }
  .create-trip-button-summary{
    background-color: ${colorPalette.primaryColor};
    /* padding: 15px 15px 15px 15px; */
    border: none;
    width:100px ;
    padding:10px 5px ;
    font-weight: ${FontWeight.semibold};
    color: ${colorPalette.white};
    border-radius: 5px;
    flex: 1;
    /* width: 100%; */
    cursor: pointer;
  }
`;
